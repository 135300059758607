@import '../../../assets/styles/img-management';

.autocomplelogin {
  max-height: 340px !important;
  position: absolute !important
}

.login {
  .login-bg {
    position: absolute;
    height: 100vh;
    width: 100vw;
    object-fit: cover;
  }

  mat-horizontal-stepper {
    width: 100%!important;
    min-width: 100%!important;
  }

  .mat-horizontal-content-container {
    padding: 0 !important;
  }

  .login-card {
    max-height: calc(100vh - 56px);
    overflow-x: hidden;
    overflow-y: auto;

    .mat-card-header {
      margin-bottom: 8px !important;
    }

    .mat-card-header-text {
      width: 100%;
    }

    .brand img {
      margin: 16px 0;
      width: 80%;

    }

    .description {
      font-size: 0.7rem;
      text-align: center;
    }

    .mat-progress-bar {
      position: absolute;
      bottom: 0;
      left: 0;
    }

    .mat-checkbox-label {
      white-space: normal !important;
    }

    .mat-card-content {

      padding: 0 16px 16px !important;
      margin-bottom: 0 !important;

      h3 {
        margin-bottom: 32px;
        margin-top: 0;

        &+h5 {
          margin-top: -24px;
          margin-bottom: 16px;
          font-weight: normal;
        }
      }

      h5 {
        margin-top: 0;
        margin-bottom: 16px;
        font-weight: normal;
      }

      h2 {
        margin: 0;

        &+h5 {
          margin-top: 16px;
        }
      }

      .mat-card-footer {
        margin: 16px 0 0 0 !important;
        min-height: 36px;
      }
    }
  }

  .tyc-container {
    max-height: calc(90vh - 394px);
    overflow: auto;
    margin-bottom: 16px;
  }

  .otp-qr {
    img {
      height: 35vh;
      width: 35vh;
      margin: 0 auto;
    }

    &+.qr-not-loaded {
      display: none !important;
    }
  }

  .otp-help {

    img {
      height: 25vh;
      width: 25vh;
      margin: 0 auto;

      &.x2 {
        height: 35vh;
        width: 35vh;
        margin: 0 auto;
      }
    }
  }

  .help-stepper {
    .align-16 {
      padding: 0 16px;
    }

    h5.align-16 {
      font-size: 15px;
    }

    .help-title {
      margin-bottom: 16px;

      h3 {
        margin: 0 !important;
      }
    }
  }
  .humanage-icon{
    background: white;
    position: fixed;
    bottom: 5px;
    right: 0;
    max-width:17vw;
    background-color: transparent;
    filter: drop-shadow(0 0 1.2px rgba(255, 255, 255, 0.8));
  }
}

@mixin login($bg-card, $accent) {

  .login {
    mat-horizontal-stepper {
      background: $bg-card !important;
    }

    .login-card {
      background: $bg-card !important;

      .mat-card-content {
        .mat-card-footer {
          a[color="accent"] {
            color: $accent;
          }
        }
      }
    }

    .help-stepper {
      h3.align-16 {
        color: $accent;
      }

      .help-options mat-icon {
        color: $accent;
      }
    }
  }
}
