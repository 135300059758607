@import 'img-management';


.welcome-card {
  .fit {
    width: 100%;
    height: 100%;
  }

  .img-container {
    @include display(f, u, u, c, c, u);
    flex-grow: 3;

  }

  .main-img {
    height: 35vh;
    width: 35vh;
    min-height: 150px;
    min-width: 150px;
    max-width: 328px;
    max-height: 328px;
    margin: 5vh;
    border-radius: 50%;
  }

  .text-container {
    padding: 0 5vh;
    text-align: center;
    @include display(f, c, u, u, c, u);
    flex-grow: 1;
  }

  .main-tittle {
    font-size: 2rem;
    text-align: center;
    font-weight: 700;
    margin-bottom: 2.5vh;
  }

  .main-text {
    font-size: 0.75rem;
    text-align: center;
    font-family: 'roboto-mono', monospace;
    font-weight: 300;
    width: 70%;
  }

  .img-divider {
    height: .2rem;
    width: 70%;
    margin: 0 auto;
  }

  .main-link {
    font-size: 1rem;
    margin: 10px;
  }
}

@mixin welcome($bg-card, $primary-side) {
  .welcome-card {
    background: $bg-card !important;

    .img-divider {
      background: $primary-side;
    }
  }
}
