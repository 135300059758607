@import 'img-management';


.employee-search-container {
  transition: min-width .2s ease;
  width: 100% !important;
  min-width: 100% !important;
  position: relative;
}

.main.w-detail {
  width: 70% !important;
  min-width: 70% !important;
  transition: min-width .2s ease;
}

mat-toolbar.employee-search-sub-toolbar {
  min-height: 92px;
  position: relative;
}

mat-toolbar.employee-search-sub-toolbar .mat-toolbar-row {
  height: 100%
}

.mat-search-field {
  border-radius: 20px;
  width: 30vw;
  min-width: 384px;
  padding-left: 8px;
  z-index: 1;
}

.mat-search-field.disabled {
  opacity: .6;
  cursor: not-allowed;
}

.mat-search-field.disabled * {
  cursor: not-allowed;
}

.mat-search-field input[type="search"] {
  border: none;
  outline: none;
  line-height: 30px;
  font-size: 16px;
  width: calc(100% - 72px);
}

.mat-search-field input[type="search"]::placeholder {
  border: none;
  line-height: 30px;
  font-size: 16px;
}

.mat-search-field .search-caret {
  font-size: 15px !important;
}

.mat-paginator {
  background: none !important;
}

mat-toolbar.employee-search-sub-toolbar app-cs-paginator .mat-paginator-container {
  padding: 0 !important
}

mat-toolbar.employee-search-sub-toolbar app-cs-paginator .mat-paginator-range-label {
  margin: 0 !important;
}

.search-adv-menu {
  border-radius: 20px !important;
  width: 30vw !important;
  min-width: 384px !important;
  margin-top: -1px;
  margin-right: -1px;
  transform-origin: center top !important;
}

.search-adv-menu .mat-menu-content {
  padding-top: 0 !important;
}

.search-adv-menu .mat-search-field {
  height: 42px;
  background: none;
  border-radius: 0;
  border: unset;
  padding-left: 8px;
}

.search-adv-menu .mat-search-field mat-icon {
  padding: 8px !important;
}

.search-adv-menu .mat-search-field input {
  padding-right: 16px;
}

.search-adv-menu h4 {
  margin-top: 8px !important;

  i {
    font-style: normal;
    font-size: 12px;
    font-weight: normal;
  }

}

.search-adv-menu .adv-menu-content {
  padding: 8px 56px 16px
}

.search-adv-menu .slide-group-container {
  padding-bottom: 16px;
  position: relative
}

.search-adv-menu .adv-menu-footer {
  padding: 8px 46px 8px
}

//estilos lista de documentos
.document-search-container {
  transition: min-width .2s ease;
  width: 100% !important;
  min-width: 100% !important;
}

.main.w-detail {
  width: 70% !important;
  min-width: 70% !important;
  transition: min-width .2s ease;
}

.document-search-sub-toolbar {
  min-height: 92px;
  position: relative;
  padding: 0 24px;
}

.document-search-sub-toolbar .mat-toolbar-row {
  height: 100%
}

.document-search-sub-toolbar app-cs-paginator .mat-paginator-container {
  padding: 0 !important
}

.leave {
  height: 0;
  opacity: 0;
  transition: all .2s ease;
}

.enter {
  height: 66px;
  opacity: 1;
  transition: all .2s ease;
}

app-advanced-employee-search {
  display: block;

  .search-adv-menu .mat-menu-content {
    padding-top: 0 !important;
    height: 70vh;
    padding: 0 !important;
  }

  .adv-menu-content.input-content {
    padding: 16px 46px;
    overflow-y: auto;
    height: auto;
    max-height: calc(100% - 52px - 42px);
  }

  .filter-line {
    height: 64px;
  }
}

.dynamic_fields-selectable {
  min-height: 56px;
  padding: 8px 0;
}

.dynamic_fields-description {
  font-size: 12px;
}

@import 'img-management';

@mixin search($bg-card, $bg-toolbar-light, $bg-glass, $divider, $text-main, $text-sub, $row-hover) {

  mat-toolbar.employee-search-sub-toolbar {
    background: $bg-card;
  }


  .mat-search-field {
    border: 1px solid $divider;
    background: $bg-toolbar-light;
  }

  .mat-search-field input[type="search"] {
    background: $bg-glass;
  }

  .mat-search-field input[type="search"]::placeholder {
    color: $text-sub;
  }

  .search-adv-menu .mat-search-field {
    border-bottom: 1px solid $divider;
  }

  mat-toolbar.document-search-sub-toolbar {
    background: $bg-card;
  }

  app-advanced-employee-search {
    .mat-chip {
      background: $row-hover !important;
    }
  }
}