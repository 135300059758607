@import '../../../../assets/styles/img-management';

app-leave {
  .inbox-toolbar {
    padding: 0 16px;
    height: 64px;

    .toolbar-line {
      padding-left: 0 !important;
    }
  }

  mat-divider.false-divider {
    width: calc(100% - 32px) !important;
    margin: -1px 16px 0 !important;
  }

  .leave_container {
    height: calc(100% - 64px);

    .leave_items {
      height: 100%;
      padding: 16px 9px 16px 16px;
      overflow: auto;
      overflow-y: overlay;

      .leave_items-item {
        height: auto;
        display: block;

        .content {
          padding: 16px;
          height: 100%;
          box-sizing: border-box;
          cursor: pointer;
          transition: all .28s cubic-bezier(0.4, 0, 0.2, .2) !important;

          &.selected {
            cursor: default !important;
          }

          .item-data {
            margin-left: 16px;
            width: calc(100% - 40px - 16px);

            b {
              font-size: 18px;
            }

            span {
              font-size: 14px;
            }
          }
        }
      }
    }

    .main-action {
      padding: 0 16px 16px;

      .main-btn {
        height: 64px;
        width: 100%;
        border-radius: 32px;

        .mat-button-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 20px;
          padding-right: 8px;

          mat-icon.mat-icon {
            min-width: 40px;
            margin-right: 8px;
          }
        }
      }
    }
  }
}

@mixin leave($bg-background, $accent, $divider, $text-main-solid, $text-side, $hover, $leave-pending, $leave-ok, $leave-not-ok, $leave-cancelled, $leave-bk-cancelled,$leave-draft, $leave-bk-draft) {
  app-leave {
    .inbox-toolbar {
      background: $bg-background;
    }
    .mat-tab-body-wrapper {
      position: relative;
      overflow: hidden;
      display: flex;
      transition: height 500ms cubic-bezier(0.35, 0, 0.25, 1);
      height: 95% !important;
  }
    .leave_container {
      background: $bg-background;

      .leave_items {
        .leave_items-item {
          &.ok {
            .content {
              .leave_item-status_icon {
                color: $leave-ok;
              }
            }
          }

          &.not-ok {
            .content {
              .leave_item-status_icon {
                color: $leave-not-ok;
              }
            }
          }

          &.pending {
            .content {
              .leave_item-status_icon {
                color: $leave-pending;
              }
            }
          }

          &.draft {
            .content {
              .leave_item-status_icon {
                color: $text-side;
              }
            }
          }

          &.cancel {
            .content {
              .leave_item-status_icon {
                color: $leave-cancelled;
              }
            }
          }

          .content {
            &:hover {
              background: $hover !important;
            }

            &.selected {
              border-left: 6px solid $accent !important;

              mat-icon {
                color: $accent !important;
              }

              .item-data {
                color: $text-main-solid !important;
              }
            }

            .leave_item-icon {
              color: $text-side;
            }

            .item-data {
              color: $text-side;
            }
          }
        }
      }

      .window-spinner {
        background: $bg-background !important;
      }
    }
  }
}
