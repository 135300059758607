@import 'img-management';

.app-page {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: relative;
}

.floating-container {
  position: absolute;
  top: 0;
  left: 0;
}

.mat-card.card-1-1 {
  height: calc(100% - 32px);
  width: calc(100% - 32px);
  padding: 0;
  overflow: hidden;
}

.card-1-1.mat-card>mat-sidenav-container>mat-sidenav {
  width: 30%;
}

.mat-avatar {
  font-size: 16px;
  width: calc(100% - 48px);
  min-width: unset !important;

  &.no-icons {
    width: 100%;
  }

  &.two-icons {
    width: calc(100% - (48px * 2));
  }

  &.three-icons {
    width: calc(100% - (48px * 3));
  }

  &.four-icons {
    width: calc(100% - (48px * 4));
  }
}

.title {
  width: calc(100% - 40px - 16px);
  height: 100%;
}

.mat-avatar h3 {
  font-size: 20px !important;
  line-height: 1.25 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.mat-avatar h4 {
  font-size: 20px !important;
  line-height: 1.25 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.mat-avatar h5 {
  font-size: 12px !important;
  line-height: 1.25 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.app-container {
  height: 100vh !important;
}

.app-toolbar {
  height: 128px !important;
}

@mixin home($bg-card-app,$bg-card) {
  .mat-card .mat-drawer-container {
    background: $bg-card !important;
  }

  .mat-drawer-container {
    background: $bg-card-app !important;
  }
}
