@import "../../../../assets/styles/img-management";

*.show-md {
  display: none !important;
}

mat-form-field {
  &.new_leave_modal-field {
    padding: 0 32px;
    box-sizing: border-box;

    .mat-form-field-infix {
      display: flex;

      mat-datepicker-toggle {
        position: absolute;
        right: 0;
        bottom: 0.5em;
      }
    }
  }
}

.title {
  .filter_range{
    margin: 0;
    font-size: 12px;
  }
}

.autocomple {
  bottom: 110.242px;
  max-height: 340px !important;
  position: absolute !important;
}

.customAccess {
  display: inline !important;
}
.UI-V2 {
  .frame-card > :nth-child(2) {
    display: flex;
    width: 100%;
  }

  .load-window.new {
    position: relative !important;
    min-height: calc(100% - 192px) !important;
    height: unset !important;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  button {
    mat-icon.mat-icon {
      width: unset;
      min-width: unset;
      min-height: unset;
    }
  }

  .icon-complex {
    position: relative;

    mat-icon {
      &.complex {
        position: absolute;
        bottom: -8px;
        right: -2px;
        font-size: 16px !important;
        height: 20px;
        width: 20px;
        min-width: unset !important;
      }
    }
  }

  .main {
    position: relative;

    .toolbar {
      padding: 16px;
      height: auto;
      display: flex;
      box-sizing: border-box;
      flex-wrap: wrap;
      row-gap: 16px;
      column-gap: 16px;
      flex-direction: row;

      .header {
        width: auto;
        overflow: hidden;

        .content {
          .module-title {
            position: relative;
            padding: 16px;
            height: 80px;
            flex-shrink: 0;

            &[hidden] {
              display: none !important;
            }

            &.selectable {
              border-radius: 8px;
              cursor: pointer;

              mat-select {
                position: absolute;
                opacity: 0;
                pointer-events: none;
                height: 100%;
              }
            }

            .info {
              margin-right: 16px;
              width: 100%;

              mat-icon + div {
                width: calc(100% - 56px);

                h3 {
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                }

                h5 {
                  font-weight: 400;
                }
              }
            }
          }

          .ou-select {
            position: relative;
            padding: 16px;
            height: 80px;
            border-radius: 8px;
            cursor: pointer;
            flex-shrink: 0;
            max-width: 40vw;

            .info {
              margin-right: 16px;

              h5 {
                font-weight: 400;
              }
            }

            mat-select {
              position: absolute;
              opacity: 0;
              pointer-events: none;
              height: 100%;
            }
          }
        }
      }

      .search-container {
        flex-grow: 1;

        .search {
          box-sizing: border-box;
          display: inline-block;
          max-width: calc(100% - 74px);
          height: 80px;
          position: relative;
          width: 100%;

          .content {
            height: 100%;
            border-radius: 40px;
            padding: 0 16px;
            border: none;
            transition: color 0.1s ease, background 0.15s ease;

            label {
              top: 100%;
              font-size: 12px;
              line-height: 1.25;
              bottom: 0;
              position: absolute;
              opacity: 0;
              transition: opacity 0.1s ease;
            }

            &:hover,
            &:active,
            &:focus,
            &:focus-within {
              transition: background 0.15s ease;

              label {
                opacity: 1;
                transition: opacity 0.1s ease;
              }
            }

            input {
              line-height: 80px;
              border: none;
              background: none;
              font-size: 18px;
              width: 100%;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }

          button {
            min-width: unset;
            border-radius: 20px;

            &.mat-button {
              .mat-button-wrapper {
                display: flex;
                align-items: center;
                height: 40px;

                mat-icon {
                  margin-right: 8px;
                }
              }
            }

            &.mat-primary .mat-icon {
              color: unset !important;
            }
          }
        }

        & > button {
          margin-right: 16px;
          margin-left: 16px;
          float: right;
          margin-top: 21px;
        }
      }
    }

    .helper {
      padding: 16px 0;
      margin: 0 16px;
      height: 56px;
      position: relative;

      mat-hint {
        height: 40px;
        padding: 16px;
      }

      .actions {
        margin-right: 16px;

        .mat-stroked-button {
          border-radius: 20px;

          .mat-button-wrapper {
            display: flex;
            align-items: center;
            height: 40px;

            mat-icon {
              margin-right: 8px;
            }
          }
        }

        .mat-icon-button {
          .mat-button-wrapper {
            display: flex;
            align-items: center;
            height: 100%;
          }
        }
      }

      mat-progress-bar {
        bottom: -4px;
      }
    }

    .load-window.new {
      height: unset !important;
      min-height: unset !important;
      top: 0;
      flex-grow: 1;
      flex-shrink: 1;
    }

    .new-table {
      min-height: calc(100% - 192px - 56px - 16px);
      margin-top: 16px;

      &[hidden] {
        display: none !important;
      }

      &.w-main-action {
        min-height: calc(100% - 192px - 56px - 97px - 16px);
        flex-grow: 1;
        flex-shrink: 1;
      }

      &.documents {
        margin-top: 16px;
        min-height: calc(100% - 192px - 56px - 16px) !important;

        .w-12 {
          width: calc(100% - 72px - 72px - 72px - 56px) !important;
          min-width: calc(100% - 72px - 72px - 72px - 56px) !important;
          position: unset !important;
        }

        .mat-header-row {
          &.sticky {
            .col-selector {
              right: 32px !important;
            }
          }
        }
      }

      app-cs-grid-control {
        datatable-header {
          border-bottom: none !important;

          .datatable-row-center {
            padding: 0 16px 0 72px;
            box-sizing: border-box;
          }

          & * {
            font-weight: 800;
          }
        }

        datatable-body-row .datatable-row-center {
          padding: 0 16px 0 72px;
          box-sizing: border-box;
        }
      }

      .mat-header-row {
        &.sticky {
          border-bottom: none;
          padding: 0 16px;

          .w-12 {
            position: relative;

            .col-selector {
              position: absolute;
              right: 16px;
            }
          }

          * {
            font-weight: 800;
          }

          .mat-header-cell {
            padding-left: 0 !important;

            & > div,
            & > span {
              overflow: hidden;
            }

            mat-icon {
              &.indication[hidden] {
                display: none !important;
              }

              &.indication {
                opacity: 0;
                min-width: unset;
              }
            }

            .sorteable {
              &:hover {
                .mat-icon {
                  &.indication {
                    opacity: 1;
                  }
                }
              }

              .sort-icon {
                position: relative !important;
              }
            }
          }
        }
      }

      .mat-row {
        padding: 0 16px;

        .mat-cell {
          padding-left: 0 !important;
        }

        .sticky-actions {
          right: 16px;
        }
      }
    }

    .main-action {
      min-height: 97px;
      bottom: 0;
      padding: 16px;
      width: calc(100% - 32px);
      margin: 0 16px;

      .main-btn {
        height: 64px;
        border-radius: 32px;

        .mat-button-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 20px;
          padding-right: 8px;

          mat-icon.mat-icon {
            min-width: 40px;
            margin-right: 8px;
          }
        }
      }
    }
  }
}

.menu-options {
  &.help {
    width: unset !important;
    max-width: unset !important;

    .primary {
      padding: 0 !important;
    }

    .accent {
      padding: 0 !important;
    }

    .container {
      padding: 0 16px 16px !important;

      .slide-group {
        mat-expansion-panel-header {
          padding: 0 8px !important;
        }

        ul {
          padding-right: 20px;
          margin: 0 0 16px;

          ul {
            margin: 0;
          }
        }

        li {
          line-height: 26px;
        }

        h5 {
          margin-bottom: 8px !important;
          margin-top: 24px;
        }
      }
    }
  }

  button {
    mat-icon.mat-icon {
      width: unset;
      min-width: unset;
      min-height: unset;
    }
  }

  .icon-complex {
    position: relative;

    mat-icon {
      &.complex {
        position: absolute;
        bottom: -8px;
        right: -2px;
        font-size: 16px !important;
        height: 20px;
        width: 20px;
        min-width: unset !important;
      }
    }
  }

  .mat-menu-content {
    padding: 0 !important;

    .container {
      max-height: calc(100vh - 32px - 112px - 68px);
      overflow-y: auto;
      padding: 16px 16px 0;

      &.col-sel {
        max-height: calc(100vh - 32px - 112px - 56px - 16px - 56px - 68px);
        overflow-y: auto;
        padding: 16px 16px 0;
      }

      .title {
        margin: 16px 0 !important;
        width: 100%;
        padding: 0 8px;

        h4,
        h6 {
          margin: 0;
        }

        h6 {
          font-weight: 400;
        }
      }

      mat-tab-group {
        width: 100%;

        mat-tab-body {
          padding-top: 16px !important;
          opacity: 0;

          &.mat-tab-body-active {
            opacity: 1 !important;
          }

          .mat-tab-body-content {
            display: flex !important;
            flex-direction: column !important;
            max-height: 100%;
            height: unset;
          }
        }
      }

      mat-list {
        .mat-subheader {
          padding: 16px 8px !important;

          mat-icon {
            margin-right: 16px;
          }
        }

        .mat-list-item {
          .mat-list-item-content {
            padding: 16px 8px !important;

            .mat-list-icon {
              padding: 0 !important;

              &.icon-complex {
                width: 40px;
                min-width: 40px;
              }
            }
          }
        }
      }

      .slide-group {
        & + .slide-group {
          margin-top: 16px !important;
        }

        mat-radio-group {
          margin-bottom: 16px;

          mat-radio-button {
            min-height: 40px;
            display: flex;
            align-items: center;
            transition: background 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);

            .mat-radio-container {
              margin: 0 18px;
            }
          }
        }

        mat-form-field {
          width: 100%;

          .mat-form-field-flex {
            border-radius: 4px 4px 0 0 !important;
            padding: 8px 8px 0 !important;
            min-height: 56px !important;
            align-items: unset !important;

            .mat-form-field-prefix {
              padding: 0 !important;
              display: flex;
              align-items: center;
            }
          }
        }

        mat-slide-toggle {
          min-height: 40px;
          display: flex !important;
          align-items: center;
          padding: 0 10px;
          transition: background 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);

          .mat-slide-toggle-content {
            padding-left: 10px;
          }
        }
      }
    }

    .footer {
      padding: 16px;
    }
  }
}

//para ordenar
.mat-paginator-container {
  padding: 0 !important;

  .mat-paginator-range-label {
    margin: 0 16px !important;
    white-space: nowrap;
  }

  .mat-icon-button{
    display: flex;
    justify-content: center;
  }
}

.main.w-detail .documents-search-sub-toolbar .mat-search-field {
  width: unset;
  min-width: unset;
}

.documents-search-sub-toolbar {
  padding: 0 24px;

  .mat-checkbox-label {
    font-size: 12px;
  }
}

// Notifications
.mat-menu-panel {
  min-height: unset !important;

  &.mat-card {
    padding: 0 !important;

    &.suffix-options {
      padding: 8px 16px !important;
      width: unset;

      mat-radio-button {
        height: 40px;
        margin: 0 !important;
        display: flex !important;
        align-items: center;
      }
    }
  }

  &.notifications-panel {
    width: 500px !important;
    max-height: 60vh !important;
    overflow-y: hidden;

    .window-spinner {
      position: absolute !important;
    }

    .mat-menu-content {
      padding: 0 !important;
      position: relative;
      height: 100%;

      .notifications-body {
        overflow-y: auto;
        height: calc(60vh - 64px - 56px);
        box-sizing: border-box;
        margin-bottom: 56px !important;
      }

      .notification {
        padding: 0 16px 0 24px;
        box-sizing: border-box;
        position: relative;
        overflow: hidden;

        &.no-notif {
          height: 100%;
          align-items: center;
          display: flex;
          justify-content: center;
        }

        .mat-progress-bar {
          bottom: -4px !important;
          left: 0 !important;
        }

        .primary {
          padding: 16px 0;
          padding-right: 16px !important;

          h3 {
            margin: 0;
          }
        }

        &.top {
          height: 64px;

          .header {
            h3 {
              font-weight: 500;
            }
          }
        }

        &.bottom {
          height: 56px;
          position: absolute;
          bottom: 0;
          width: 100%;
        }

        &.selectable {
          cursor: pointer;
          padding: 16px !important;
          margin: 8px;
          border-radius: 8px;

          width: calc(100% - 16px) !important;

          &:hover {
            .notif-hide-button {
              opacity: 1;
              right: 16px;
              pointer-events: all;
              transition: all 0.2s ease-in 0.3s;
            }
          }

          .notif-hide-button {
            opacity: 0;
            top: 4px;
            pointer-events: none;
            margin-left: 16px;
            right: -56px;
            transition: all 0.2s ease-out;
            position: absolute;
            transform: scale(0.65);
            transform-origin: top right;
          }

          h3 {
            margin: 0;
          }

          h5 {
            margin: 0;
            font-weight: 400;
          }

          .notification-on-signature {
            width: 100%;
            min-width: 250px;

            .signature-bar {
              position: relative !important;
              bottom: unset !important;
              border-radius: 4px !important;
              overflow: hidden;
              margin-top: 8px;
              height: 8px;
              border-radius: 4px;
            }

            .signature-count {
              text-align: right;
              font-size: 12px;
              margin-top: 8px;
            }
          }

          .subtitle {
            margin-left: 32px;

            h5 {
              margin: 0;
              height: 32px;
              border-radius: 18px;
              padding: 0 16px 0 8px;
              line-height: 1;

              mat-icon {
                font-size: inherit !important;
                color: inherit !important;
                width: 20px !important;
                height: 20px !important;
                min-width: unset !important;
              }
            }
          }
        }
      }
    }
  }
}

// common
.info-empty {
  height: 100%;
  min-height: 25vw !important;

  .large-icon {
    height: 120px !important;
    width: 120px !important;
    border-radius: 70px;
    font-size: 70px !important;
    margin-bottom: 16px;
  }

  h2,
  h3 {
    text-align: center;
    margin: 0;
  }

  h4,
  h5 {
    margin: 0;
    text-align: center;
    max-width: 40vw;
    font-weight: 400;
  }
}

.side {
  &.edition {
    .info-empty {
      padding: 16px 32px;
      text-align: center;

      .large-icon {
        height: 120px !important;
        width: 120px !important;
        border-radius: 70px;
        font-size: 70px !important;
        margin: 32px 16px 32px;
      }

      h2 {
        margin: 0 0 16px;
        font-size: 20px;
        text-align: center;
      }

      h4 {
        margin: 0 0 32px;
        text-align: center;
        max-width: 40vw;
      }
    }
  }
}

.mat-basic-chip {
  mat-icon.mat-icon {
    min-width: unset;
  }
}

mat-icon.mat-icon {
  min-width: 40px;
  height: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  border-radius: 25px;
  box-sizing: border-box !important;
  padding: 0;
  overflow: visible;
}

.fa-bg-new {
  position: relative;
  overflow: visible !important;
  &::after {
    position: absolute;
    background-color: tomato;
    content: "NEW";
    border-radius: 6px;
    top: 0;
    right: 1px;
    padding: 2px 4px;
    font-size: 10px;
    font-weight: 600;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    color: white;
  }
}

.mat-button-ripple.mat-ripple,
.mat-button-focus-overlay,
.mat-radio-ripple,
.mat-slide-toggle-ripple,
.mat-checkbox-ripple {
  position: absolute !important;
}

.mat-chip-list-wrapper {
  mat-chip {
    max-width: calc(100% - 16px);

    .chip-text {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    mat-icon.mat-icon {
      min-width: unset;
    }
  }
}

.menu-is-open {
  .UI-V2 {
    .toolbar {
      .header {
        .content {
          .module-title {
            flex-shrink: 1;
          }
        }
      }

      .search-container {
        .search {
          button {
            padding: 0;

            .mat-button-wrapper {
              width: 40px !important;
              overflow: hidden;
              justify-content: start;
              padding: 0;

              mat-icon {
                width: 40px !important;
                min-width: 40px !important;
                margin: 0 !important;
              }
            }
          }
        }
      }
    }

    .helper {
      .actions {
        .mat-stroked-button {
          padding: 0 !important;
          min-width: 40px;
          width: 40px;
          height: 40px;

          .mat-button-wrapper {
            width: 40px !important;
            overflow: hidden;
            justify-content: start;
            padding: 0;

            mat-icon {
              width: 40px !important;
              min-width: 40px !important;
              margin: 0 !important;
            }

            span {
              display: none !important;
            }
          }
        }
      }
    }
  }
}

.mat-menu-panel {
  min-height: unset !important;

  .mat-card {
    padding: 0 !important;
  }
}

app-documentation-signer,
app-role-user-find {
  .info-empty {
    .large-icon {
      font-size: 50px !important;
    }
  }
}

.navigation-menu {
  h4 {
    margin:16px 32px;
  }
  app-autocomplete-chip{
    margin:0 32px;
  }

}

.no-clickable {
  pointer-events: none;
}

@mixin frame(
  $bg-card,
  $bg-card-side,
  $bg-background,
  $primary,
  $primary-light,
  $accent,
  $accent-light,
  $divider,
  $text-main-solid,
  $text-main,
  $text-side,
  $text-sub,
  $primary-text,
  $hover,
  $row-odd,
  $row-even,
  $leave-ok,
  $leave-not-ok
) {
  .UI-V2 {
    .icon-complex {
      mat-icon {
        &.complex {
          color: $primary-text;
          text-shadow: -2px 0 0px $bg-card, 0 -2px 0px $bg-card,
            2px 0 0px $bg-card, 0 2px 0px $bg-card, -2px -2px 0px $bg-card,
            2px 2px 0px $bg-card, 2px -2px 0px $bg-card, -2px 2px 0px $bg-card;

          &.mat-accent {
            color: $accent;
          }
        }
      }
    }

    .main {
      .toolbar {
        .header {
          .content {
            .module-title {
              color: $primary-text;

              &.selectable {
                &:hover {
                  background: $hover;
                }
              }

              .info {
                h5 {
                  color: $text-main-solid !important;
                }
              }
            }

            .ou-select {
              color: $text-main;
              background: $primary;
            }
          }
        }

        .search-container {
          .search {
            .content {
              background: $bg-background;
              border: 1px solid $divider;

              &:hover,
              &:active,
              &:focus,
              &:focus-within {
                background: $hover !important;
              }

              input {
                color: $text-main-solid;
              }
            }
          }
        }
      }

      .helper {
        border-bottom: 1px solid $divider;

        mat-hint {
          h4 {
            color: $primary-text;
          }
        }
      }

      .new-table {
        app-cs-grid-control {
          datatable-header {
            & * {
              color: $primary-text;
            }
          }
        }

        .mat-header-row {
          &.sticky {
            * {
              color: $primary-text !important;
            }
          }
        }
      }

      .main-action {
        border-top: 1px solid $divider;
      }
    }
  }

  .menu-options {
    &.help {
      .primary {
        color: $primary-text;
      }

      .accent {
        color: $accent;
      }
    }

    .icon-complex {
      mat-icon {
        &.complex {
          color: $primary-text;
          text-shadow: -2px 0 0px $bg-card, 0 -2px 0px $bg-card,
            2px 0 0px $bg-card, 0 2px 0px $bg-card, -2px -2px 0px $bg-card,
            2px 2px 0px $bg-card, 2px -2px 0px $bg-card, -2px 2px 0px $bg-card;
        }
      }
    }

    .mat-menu-content {
      .container {
        mat-list {
          .mat-list-item {
            &:hover {
              background: $hover !important;
            }

            .disabled {
              color: $text-side !important;
            }
          }
        }

        .slide-group {
          mat-radio-group {
            mat-radio-button {
              &:hover {
                background: $hover;
              }
            }
          }

          mat-form-field {
            .mat-form-field-flex {
              background: $row-odd;
            }
          }

          mat-slide-toggle {
            &:hover {
              background: $hover;
            }
          }
        }
      }
    }
  }

  .mat-menu-panel {
    &.notifications-panel {
      .mat-menu-content {
        .notifications-body {
          background: $bg-background;
        }

        .notification {
          .primary {
            mat-icon {
              color: $primary-text;
            }

            h3 {
              color: $primary-text;
            }
          }

          &.top {
            background: $bg-background;

            .header {
              h3 {
                color: $text-main-solid;
              }
            }
          }

          &.bottom {
            background: $bg-background;
          }

          &.selectable {
            border: 1px solid $divider;

            &:nth-child(even) {
              background: $row-odd;
            }

            &:nth-child(odd) {
              background: $row-even;
            }

            &:hover {
              background: $hover;
            }

            mat-icon {
              color: $primary;
            }

            h3 {
              color: $text-main-solid;
            }

            h5 {
              color: $text-sub;
            }

            .subtitle {
              h5 {
                color: $primary-text;
                background: $bg-card-side;
              }
            }
          }
        }
      }
    }
  }

  .info-empty {
    .large-icon {
      background: $primary-light;
      color: $primary-text;
    }

    h2,
    h3 {
      color: $primary-text;
    }

    h4,
    h5 {
      color: $text-side;
    }

    &.accent {
      mat-icon {
        background: $accent-light;
        color: $accent;
      }

      h2 {
        color: $accent;
      }
    }

  .actions {
    padding: 16px;
    height: 96px;
    column-gap: 16px;

    button {

            &.ok {
              background: $leave-ok !important;
              color: $text-main;
            }

            &.not-ok {
              color: $leave-not-ok !important;
            }
      height: 100%;
      border-radius: 40px;
    }
  }
  }

  .side {
    &.edition {
      .info-empty {
        .large-icon {
          background: $accent-light;
          color: $accent;
        }

        h2 {
          color: $accent;
        }

        h4 {
          color: $text-side;
        }
      }
    }
  }
}
