@import '../../../../assets/styles/img-management';


app-help-stepper {
  mat-horizontal-stepper.help-stepper.mat-stepper-horizontal {
    width: 100%;
    max-height: 289px;
    overflow: hidden;
    margin: 0;

    &.new {
      max-height: unset;

      &.image-opened {

        .help-header,
        .mat-card-footer,
        .aditional-info {
          display: none !important;
        }

        .help-content {
          width: unset !important;
          padding: 16px !important;

          .description {
            text-align: center;
          }

          img {
            height: 70vh !important;
            cursor: zoom-out !important;
            object-fit: contain !important;
            object-position: center !important;
          }
        }
      }
    }

    .mat-horizontal-content-container {
      padding: 0;

      mat-card.mat-card.help-card {
        box-shadow: none;
        padding: 0;
        border-radius: 0;
        height: 100%;
        width: 100%;
        display: block !important;

        .mat-card-header {
          display: none
        }

        .mat-card-content {
          margin-bottom: 0;
          display: block !important;
          flex: 1 1 100%;
          max-height: 100%;

          .help-header {
            height: 64px;
            padding: 0 16px;

            h3 {
              font-size: 20px;
            }

            h3,
            h4 {
              margin: 0;
            }

          }

          .help-content {
            height: calc(100% - 56px);
            padding: 0 72px 16px;
            justify-content: center;
            width: calc(100vw / 3);
            min-width: 720px;

            img {
              height: 200px;
              object-fit: cover;
              cursor: zoom-in;
              object-position: top;
            }

            h4 {
              margin-top: 0 !important;
            }

            ul {
              margin: 0;
              display: flex;
              flex-direction: column;
              padding-inline-start: 13px;

              li {
                font-weight: 400;

                &+li {
                  margin-top: 3%;
                }

                b.tip-text {
                  margin-left: -12px;
                  margin-top: 16px;
                  display: block;
                  font-weight: 400;
                }
              }
            }
          }
        }
      }

      .mat-card-footer {
        min-height: 56px;
        padding: 0 16px 16px;
        margin: 0;
      }
    }
  }
}


@mixin helpStepper($bg-card, $divider, $text-side) {
  app-help-stepper {
    mat-horizontal-stepper.help-stepper.mat-stepper-horizontal {
      &.new {
        &.image-opened {
          .help-content {
            img {
              border: 1px solid $divider;
            }
          }
        }
      }
      

      .mat-horizontal-content-container {
        mat-card.mat-card.help-card {
          background: $bg-card;
        }

        .aditional-info {
          color: $text-side;
        }

        .mat-card-footer {
          background: $bg-card;
        }
      }
    }
  }
}