@import '../../../../assets/styles/img-management';

.chip-line.mat-line {
  display: flex !important;
  align-items: center;
  height: auto;
  width: 100%;
}

.mat-basic-chip {
  padding: 2px 12px 2px 8px;
  border-radius: 16px;
  min-width: fit-content;
  margin: 4px !important;

  .mat-icon {
    font-size: 16px !important;
  }

  span {
    font-size: 12px !important;
  }

  .mat-icon::before {
    line-height: 19px !important;
  }
}



app-file-document-state {
  display: flex;
  height: auto;
  width: 100%;

  mat-chip-list {
    height: auto;
    width: 100%;

    .mat-chip-list-wrapper {
      flex-direction: row-reverse;
      margin: 0 0 0 -4px;
      justify-content: flex-end;
      width: 100%;

      .mat-basic-chip {
        padding: 2px 12px 2px 8px;
        border-radius: 16px;
        min-width: fit-content;
        margin: 4px !important;

        .mat-icon {
          font-size: 16px !important;
        }

        span {
          font-size: 12px !important;
        }

        .mat-icon::before {
          line-height: 19px !important;
        }
      }
    }
  }
}

app-file-document-view-dialog {
  .mat-chip-list-wrapper {
    flex-wrap: nowrap !important;
  }
}

.inbox-container.employee.my-documents {
  .chip-line.mat-line {
    height: 40px;
    flex-shrink: 0;
    width: unset;

    .mat-chip-list-wrapper {
      padding: 0;
      margin: 0 !important;
      height: 30px;
      flex-wrap: nowrap !important;
      justify-content: flex-start;

      .mat-basic-chip {
        padding: 0;
        margin: 4px;
        height: 40px;
        width: 40px;
        box-sizing: border-box !important;
        overflow: visible;
        border: none;
        background: none !important;

        &.unseen {
          display: none !important
        }

        &+mat-basic-chip.seen {
          display: none !important;
        }

        .mat-icon {
          margin: 0 !important;
          font-size: 32px !important;
        }

        span {
          display: none;
        }
      }
    }
  }
}

.bottom-container {
  .mat-chip {
    transform: scale(1.3);
    transform-origin: center center;
  }
}

app-file-document-state-chips {
  width: 100%;
  @include display(f, u, u, u, u, u);
}

.chip-group {
  @include display(f, u, u, u, u, u);
}

.resume-status {
  .mat-basic-chip {
    padding: 4px 16px 4px 12px;
    border-radius: 20px;
    margin-top: 0;

    .mat-icon {
      font-size: 20px !important;
    }

    span {
      font-size: 16px !important;
    }
  }
}


@mixin fileDocumentState($bg-toolbar-light, $divider, $text-main-inverse, $seen, $unseen, $signed-ok, $signed-not-ok, $unsigned, $cancelled) {


  .mat-basic-chip {
    background: $bg-toolbar-light !important;
    border: 1px solid $divider;

    &.seen {
      border-color: $seen !important;
      color: $seen !important;
    }

    &.unseen {
      border-color: $unseen !important;
      color: $unseen !important;
    }

    &.signed-ok {
      border-color: $signed-ok !important;
      color: $signed-ok !important;

      &.cancelled {
        border-color: $cancelled !important;
        color: $cancelled !important;
      }
    }

    &.signed-not-ok {
      border-color: $signed-not-ok !important;
      color: $signed-not-ok !important;
    }

    &.not-signed {
      border-color: $unsigned !important;
      color: $unsigned !important;
    }

    &.not-signed-employer {
      border-color: $unsigned !important;
      color: $unsigned !important;
    }
  }

  app-file-document-state {
    mat-chip-list {
      .mat-chip-list-wrapper {
        .mat-basic-chip {
          background: $bg-toolbar-light !important;
          border: 1px solid $divider;

          &.seen {
            border-color: $seen !important;
            color: $seen !important;
          }

          &.unseen {
            border-color: $unseen !important;
            color: $unseen !important;
          }

          &.signed-ok {
            border-color: $signed-ok !important;
            color: $signed-ok !important;

            &.cancelled {
              border-color: $cancelled !important;
              color: $cancelled !important;
            }
          }

          &.signed-not-ok {
            border-color: $signed-not-ok !important;
            color: $signed-not-ok !important;
          }

          &.not-signed {
            border-color: $unsigned !important;
            color: $unsigned !important;
          }

          &.not-signed-employer {
            border-color: $unsigned !important;
            color: $unsigned !important;
          }
        }
      }
    }
  }

  .inbox-container.employee.my-documents {
    .chip-line.mat-line {
      .mat-chip-list-wrapper {
        .mat-basic-chip {
          &.seen {
            color: $unseen !important;
          }
        }
      }
    }
  }

  .resume-status {
    .mat-basic-chip {
      background: $bg-toolbar-light !important;
      border: 1px solid $divider;

      &.signed-ok {
        border-color: $signed-ok !important;
        background-color: $signed-ok !important;
        color: $text-main-inverse !important;

        &.cancelled {
          border-color: $cancelled !important;
          background-color: $cancelled !important;
          color: $text-main-inverse !important;
        }
      }

      &.signed-not-ok {
        border-color: $signed-not-ok !important;
        background-color: $signed-not-ok !important;
        color: $text-main-inverse !important;
      }
    }
  }
}
