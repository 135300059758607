@import '../../../../assets/styles/img-management';


.UI-V2 {
  .main-overlay {
    position: absolute;
    bottom: 0;
    height: 100%;
    opacity: 0;
    z-index: 2;

    &.enabled {
      width: 100% !important;
      opacity: 1
    }
  }

  .icon-complex {
    position: relative;
    height: 40px;
    width: 40px;
    padding: 0 !important;

    mat-icon {
      padding: 0 !important;

      &.complex {
        position: absolute;
        bottom: 0;
        right: 0;
        font-size: 16px !important;
        height: 20px !important;
        width: 20px !important;
        padding: 0 !important;
      }
    }
  }

  .navigation {
    position: relative;

    &.detail-open {
      overflow: hidden;
      width: 0 !important;
      flex: 0 !important;
    }

    .load-window {
      left: 0;
      top: 112px;
      height: calc(100% - 112px) !important;
    }

    h3 {
      font-weight: 500;
    }

    h5 {
      font-weight: 300;
    }

    .toolbar {
      height: 80px;
      margin: 16px;
      border-radius: 8px;
      padding: 0 16px;

      h3,
      h5 {
        margin: 0
      }
    }

    .container {
      padding: 0 8px 16px 16px;
      overflow-y: scroll;
      max-height: calc(100% - 112px);
      box-sizing: border-box;

      .helper {
        padding: 0 16px;
        height: 56px;
        margin-bottom: 16px;
      }

      .item {
        padding: 16px;
        border-radius: 8px;
        cursor: pointer;


        h3 {
          margin: 0;
        }

        &+.item {
          margin-top: 16px;
        }

        &.active {
          cursor: default;
        }
      }
    }
  }

  .work-space {
    mat-tab-group.tabs-settings {
      height: calc(100% - 112px) !important;

      mat-tab-header {
        padding: 0 !important;
        margin: 0 16px;
        width: calc(100% - 32px);
      }
    }


    mat-tab-group {
      width: 100%;
      height: 100%;

      mat-tab-header.mat-tab-header {
        padding: 0 16px;
        box-sizing: border-box;
      }

      .mat-tab-body-wrapper {
        height: 100%;

        mat-tab-body {
          height: 100%;

          .content-scroll {
            height: 100%;

            app-permissions,
            app-appearance {
              height: 100%;
            }

            app-permissions,
            app-appearance,
            app-help-link-config {
              padding: 16px;
            }

            app-email-config,
            app-ou-description-config {
              padding: 16px 16px 0;

              .confirm {
                margin-bottom: 16px;
              }
            }

            app-permissions,
            app-appearance,
            app-email-config,
            app-help-link-config,
            app-ou-description-config {
              display: block;
              box-sizing: border-box;

              .load-window.new {
                display: flex !important;
                align-items: center;
                justify-content: center;
                height: 100% !important;
              }

              .help-hoverable {
                mat-hint {
                  bottom: calc(100% + 8px);
                  left: calc(50% - 320px);
                }
              }

              .form-steped-body {
                padding: 0 16px;
              }

              .separator {
                padding: 0 16px;
                box-sizing: border-box;
                margin-bottom: 16px;

                .subtitle {
                  height: 56px;
                }
              }

              .img-editior-container {
                height: 100%;

                .img-editor {
                  width: 100%;

                  .separator {
                    padding: 0 16px;
                    box-sizing: border-box;
                    margin-bottom: 16px;

                    .subtitle {
                      height: 56px;
                    }
                  }

                  img {
                    width: 100%;
                    margin-bottom: 16px;
                  }

                  &+.cropper-area {
                    height: calc(100% - 72px);

                    image-cropper {
                      padding: 0 !important;
                    }
                  }

                  .upload-warning {
                    .info-empty {
                      min-height: unset !important;
                    }
                  }
                }
              }

              .edit-theme {
                margin-bottom: 16px;
                padding-bottom: 16px;

                mat-card.example {
                  padding: 0;
                  overflow: hidden;
                  background: #eeeeee !important;

                  &.dark {
                    background: #303030 !important;

                    .main-color-container {
                      .example-toolbar {
                        * {
                          color: #000 !important;
                        }

                        &.indigo {
                          background: #9fa8da !important;
                        }

                        &.pink {
                          background: #f06292 !important;
                        }
                      }

                      .main-color {
                        background: #424242 !important;

                        h6 {
                          color: #fff
                        }

                        &:before {
                          border-bottom: 16px solid #424242 !important;
                        }

                        mat-button-toggle-group {
                          mat-button-toggle {
                            &[value='indigo'] {
                              background: #9fa8da !important;
                            }

                            &[value='pink'] {
                              background: #f06292 !important;
                            }
                          }
                        }
                      }
                    }

                    .side-color-container {
                      .side-color {
                        background: #424242 !important;

                        h6 {
                          color: #fff
                        }

                        &:before {
                          border-left: 16px solid #424242 !important;
                        }

                        mat-button-toggle-group {
                          mat-button-toggle {
                            &[value='pink'] {
                              background: #f48fb1 !important;
                            }


                            &[value='amber'] {
                              background: #ffe082 !important;
                            }

                            &[value='purple'] {
                              background: #ce93d8 !important;
                            }

                            &[value='blueGrey'] {
                              background: #b0bec5 !important;
                            }
                          }
                        }
                      }

                      .example-button {
                        color: #000 !important;

                        &.pink {
                          background: #f48fb1 !important;
                        }

                        &.amber {
                          background: #ffe082 !important;
                        }

                        &.purple {
                          background: #ce93d8 !important;
                        }

                        &.blueGrey {
                          background: #b0bec5 !important;
                        }
                      }
                    }

                  }

                  .main-color-container {
                    position: relative;
                    padding-bottom: 16px;

                    .example-toolbar {
                      .theme-title {
                        text-transform: capitalize;
                      }

                      * {
                        color: #fff;
                      }

                      .mat-slide-toggle-content {
                        font-size: 12px;
                      }

                      &.indigo {
                        background: #3f51b5;
                      }

                      &.pink {
                        background: #d81b60;
                      }
                    }

                    .main-color {
                      background: #fff !important;
                      border-radius: 4px;
                      padding: 4px 16px 16px;
                      margin: 8px 16px;
                      position: relative;
                      width: fit-content;
                      opacity: 1;
                      transform: scale(1);
                      transition: all .2s ease;

                      mat-button-toggle-group {
                        mat-button-toggle {
                          &[value='pink'] {
                            background: #d81b60 !important;
                          }
                        }
                      }

                      &.hidden {
                        pointer-events: none;
                        opacity: 0;
                        transform: scale(0);
                        transition: all .2s ease;
                      }

                      &:before {
                        content: "";
                        display: block;
                        width: 0;
                        height: 0;
                        bottom: 100%;
                        right: 32px;
                        position: absolute;
                        z-index: 1;
                        border-bottom: 16px solid #fff !important;
                      }

                      h6 {
                        color: #000
                      }
                    }
                  }

                  .side-color-container {
                    align-items: center;
                    display: flex;
                    flex-direction: row-reverse;
                    padding: 16px 16px 8px;

                    .side-color {
                      background: #fff !important;
                      border-radius: 4px;
                      padding: 8px 16px 16px;
                      margin: 0 8px;
                      position: relative;
                      width: fit-content;
                      opacity: 1;
                      transform: scale(1);
                      transition: all .2s ease;

                      mat-button-toggle-group {
                        mat-button-toggle {
                          &[value='pink'] {
                            background: #e91e63 !important;
                          }
                        }
                      }

                      &.hidden {
                        pointer-events: none;
                        opacity: 0;
                        transform: scale(0);
                        transition: all .2s ease;
                      }

                      &:before {
                        content: "";
                        display: block;
                        width: 0;
                        height: 0;
                        left: 100%;
                        bottom: 36px;
                        position: absolute;
                        z-index: 1;
                        border-left: 16px solid #fff !important;
                      }

                      h6 {
                        color: #000;
                        margin: 8px 4px !important;

                        &.required {
                          &:after {
                            content: ' (elige un color)';
                          }
                        }
                      }
                    }

                    .example-button {
                      color: #fff;

                      &.pink {
                        background: #e91e63;
                      }

                      &.amber {
                        background: #ffc107;
                      }

                      &.purple {
                        background: #9c27b0;
                      }

                      &.blueGrey {
                        background: #607d8b;
                      }
                    }
                  }

                  h6 {
                    margin: 8px 4px !important;
                    color: #000;

                    &.required {
                      &:after {
                        content: ' (elige un color)';
                      }
                    }
                  }

                  mat-button-toggle-group {
                    border: none !important;

                    mat-button-toggle {
                      width: 32px;
                      height: 32px;
                      border-radius: 16px;
                      margin: 4px;
                      overflow: hidden;
                      box-sizing: border-box;
                      transform: scale(.8);
                      transition: all .2s ease;
                      opacity: .9;

                      &.mat-button-toggle-checked {
                        border: 2px solid rgba(255, 255, 255, .5) !important;
                        transform: scale(1.25);
                        box-sizing: border-box;
                        transition: all .2s ease;
                        opacity: 1;

                        &:hover {
                          transform: scale(1.25) !important;
                          transition: all .2s ease;
                        }

                        button {
                          cursor: default !important;
                        }
                      }

                      &:hover {
                        transform: scale(1.1);
                        transition: all .2s ease;
                        opacity: 1;
                      }

                      &[value='indigo'] {
                        background: #3f51b5;
                      }

                      &[value='amber'] {
                        background: #ffc107;
                      }

                      &[value='purple'] {
                        background: #9c27b0;
                      }

                      &[value='blueGrey'] {
                        background: #607d8b;
                      }

                      &.mat-button-toggle-disabled {
                        opacity: .3;

                        &:hover {
                          transform: scale(.8);
                          transition: all .2s ease;
                          opacity: .3 !important;
                        }

                        button {
                          cursor: not-allowed;
                        }
                      }

                      button {
                        width: 100%;
                        height: 100%;
                      }
                    }
                  }
                }
              }

              .permission-settings-cards {
                .card {
                  width: 50%;
                  border-radius: 8px;
                  box-sizing: border-box;


                  .subtitle {
                    padding: 16px;
                    margin-bottom: 0;
                  }

                  mat-slide-toggle {
                    height: 40px !important;
                    padding: 0 16px;
                    width: 100%;
                    box-sizing: border-box;

                    span.mat-slide-toggle-content {
                      margin-left: 12px;
                    }
                  }

                  .slide-sub-group {
                    mat-slide-toggle {
                      padding-left: 36px;
                    }

                    .slide-sub-group {
                      mat-slide-toggle {
                        padding-left: 66px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    h5,
    h6 {
      font-weight: 500;
    }

    .main {
      position: relative;

      .load-window {
        left: 0;
        top: 0;
        height: 100%;
      }

      &.side-open {
        .container {
          &.file-configuration {
            .file-tabs {
              .mat-tab-body-wrapper {
                mat-tab-body {
                  .content-scroll {
                    .item {
                      width: calc((100% / 3) - 16px) !important;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .container {
        &.file-configuration {
          height: calc(100% - 112px) !important;
          position: relative;

          .refresh {
            position: absolute;
            top: 4px;
            left: 32px;
            z-index: 2;
          }

          .refresh-bar {
            top: 48px
          }

          .file-tabs {
            height: 100%;

            mat-tab-header {
              margin: 0 16px;
              padding-left: 72px;
            }

            .mat-tab-body-wrapper {
              height: 100%;

              mat-tab-body {
                height: 100%;

                .mat-tab-body-content {
                  display: flex;
                  align-items: flex-start;
                  max-height: 100%;
                  height: unset;

                  .content-scroll {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    overflow-y: scroll;
                    max-height: 100% !important;
                    box-sizing: border-box;
                    padding: 8px 0 8px 8px;
                    width: 100%;


                    .item {
                      margin-top: 16px;
                      padding: 16px 16px 8px;
                      border-radius: 8px;
                      width: calc((100% / 3) - 16px);
                      margin: 8px;
                      cursor: pointer;
                      justify-content: space-between;


                      mat-icon {
                        &.complex {
                          bottom: 0;
                          right: 0;
                        }
                      }

                      h5 {
                        margin: 0;
                      }

                      h3 {
                        margin: 0;
                        margin-right: 16px !important;
                      }

                      .actions {
                        pointer-events: none;
                        margin-top: 16px;

                        button {
                          pointer-events: all;
                        }
                      }

                      &.full-page {
                        width: calc((100% / 4) - 16px);
                      }

                      &.disabled {
                        .actions {
                          mat-icon {
                            color: unset !important;
                          }
                        }

                        &.selected {
                          opacity: 1;
                          cursor: default;
                        }
                      }

                      &.selected {
                        cursor: default;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

    }

    .side {
      height: 100%;

      .edition-line {
        border-radius: 8px;
        padding: 16px;
        margin: 16px 0;
        position: relative;
        overflow: hidden;

        mat-progress-bar {
          bottom: 0;
          left: 0;
        }
      }

      mat-form-field {
        width: 100%;

        &.mat-form-field-disabled {
          * {
            cursor: not-allowed;
          }

          .mat-form-field-underline {
            height: 2px !important;
          }
        }

        .mat-form-field-flex {
          border-radius: 4px 4px 0 0 !important;
          padding: 8px 8px 0 !important;
          min-height: 56px !important;
          align-items: unset !important;

          .mat-form-field-prefix {
            padding: 0 !important;
            display: flex;
            align-items: center;
          }
        }
      }

      mat-checkbox {
        align-items: center;
        height: 56px;
        display: flex;
        margin-bottom: 16px;
        border-radius: 4px;
        padding: 0 8px;

        .mat-checkbox-layout {
          width: 100%;
          justify-content: flex-start;
          height: 56px;
          align-items: center;

          .mat-checkbox-inner-container {
            margin: 12px;
          }

          .mat-checkbox-label {
            width: 100%;
            padding-left: 16px;
          }
        }
      }

      .hint {
        margin: 0 0 16px;
        font-weight: 700;
        width: 100%;
        text-align: center;
      }

      .toolbar {
        height: 112px;
        padding: 16px 32px;

        .header {
          width: 100%;

          h3 {
            margin: 0;
          }

          h5 {
            margin: 0;
          }
        }
      }

      .container {
        padding: 0 0 16px 8px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        overflow-y: scroll;
        max-height: calc(100% - 112px);
        box-sizing: border-box;

        .role-resume {
          width: calc(100% - 16px);
          padding: 16px;
          border-radius: 8px;
          margin: 0 8px;
          position: relative;

          .line {
            padding: 8px 16px;
            position: relative;
            border-radius: 4px;

            &.editable {
              &:hover {
                button {
                  opacity: 1
                }
              }

              button {
                opacity: 0;
                position: absolute;
                right: 16px;
                top: 4px;
              }
            }

            .header {
              width: 100%;

              .info {
                max-width: calc(100% - 40px - 16px - 16px);

                h4,
                h6 {
                  margin: 0;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
            }
          }
        }

        .section-title {
          width: 100%;
          height: 56px;
          margin: 8px;
          padding: 16px;



          h3 {
            margin: 0;
          }

          h5 {
            margin: 0;
            font-size: 18px;
            font-weight: 700;
          }
        }

        .section-resume {
          width: 100%;
          padding: 16px;
          border-radius: 8px;
          margin: 0 8px;
          max-width: calc(100% - 50px);

          .line {
            padding: 8px 16px;
            border-radius: 4px;
            position: relative;

            &.editable {
              &:hover {
                button {
                  opacity: 1
                }
              }

              button {
                opacity: 0;
                position: absolute;
                right: 16px;
                top: 4px;
              }
            }

            .header {
              width: 100%;

              .info {
                max-width: calc(100% - 40px - 16px - 16px);

                h4 {
                  margin: 0;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }

                h6 {
                  font-weight: 700;
                  margin: 0;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
            }

            ul {
              margin-top: 0;
              margin-bottom: 0;
              padding-inline-start: 16px;
              font-weight: 400;

              &+ul {
                margin-top: 16px;
              }
            }

            &.head {
              padding: 8px 16px;
            }
          }
        }

        app-certificates {
          width: 100%;

          .employee_detail-sign {
            .employee-title {
              height: 56px;
              margin: 8px;
              padding: 16px;
              box-sizing: border-box;

              .title-text {
                display: flex;
                height: 100%;
                align-items: center;

                .title_text-main {
                  font-size: 18px;
                  font-weight: 700;
                }
              }
            }

            .employee_detail-side-body {
              padding: 16px;
              border-radius: 8px;
              box-sizing: border-box;
              margin: 0 8px;

              .data {
                .data-line {
                  display: flex;
                  flex-wrap: nowrap;
                  align-items: center;

                  .data_line-text {
                    padding: 8px 16px;
                    border-radius: 4px;
                    position: relative;
                    flex-direction: row;
                    box-sizing: border-box;
                    display: flex;
                    place-content: center flex-start;
                    align-items: center;

                    mat-icon {
                      margin-right: 16px;
                    }

                    .line_text-container {

                      .line_text-side {
                        font-weight: 700;
                        margin: 0;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        font-size: 0.67em;
                        u{
                          cursor: help;
                        }
                      }

                      .line_text-main {
                        margin: 0;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        font-weight: bold;
                      }
                    }
                  }

                  mat-basic-chip {
                    padding: 4px 12px 4px 8px;

                    mat-icon {
                      width: 20px !important;
                      min-width: 20px !important;
                    }
                  }
                }
              }
            }
          }
        }
      }

      &.edition {
        z-index: 2;

        .toolbar {
          .header {
            width: 100%;
          }
        }

        .container {
          padding: 0;
          overflow-y: unset !important;

          app-role-user-add {
            display: block !important;
            width: 100%;
            box-sizing: border-box !important;

            mat-horizontal-stepper {
              width: 100% !important;
              min-width: unset;
              background-color: unset !important;

              .mat-horizontal-content-container {
                padding: 0;

                form {
                  max-height: calc(100vh - 32px - 112px);
                  overflow-y: scroll;
                  padding: 0 8px 0 16px;
                  width: 100%;
                  position: relative;
                  box-sizing: border-box;
                }

                .form-body {
                  padding: 0 16px !important;
                  border-radius: 8px !important;
                  margin: 0 auto;
                  box-sizing: border-box;

                  &+.form-body {
                    margin-top: 16px;
                  }

                  .line {
                    padding: 16px 8px;

                    .header {
                      width: 100%;

                      h4 {
                        margin: 0;
                      }

                      h6 {
                        margin: 0;
                      }
                    }
                  }

                  mat-radio-group {
                    padding-bottom: 24px;

                    mat-radio-button {
                      height: 40px;
                      display: flex !important;
                      align-items: center;

                      .mat-radio-container {
                        margin-left: 18px;
                      }

                      .mat-radio-label-content {
                        padding-left: 26px;
                      }
                    }
                  }
                }

                .form-actions {
                  border-top: unset !important;
                  padding: 16px 0 !important;
                  display: flex;
                  flex-direction: row-reverse;
                  position: sticky;
                  bottom: 0;

                  button+button {
                    margin-right: 12px;
                  }
                }

                .resume {
                  max-height: calc(100vh - 32px - 112px);
                  overflow-y: scroll;
                  padding: 0 8px 0 16px;
                  width: 100%;
                  position: relative;

                  .form-body {
                    padding: 0 !important;
                    overflow: hidden;

                    .line {
                      padding: 16px 40px;

                      .header {
                        h6 {
                          font-weight: 700;
                        }

                        &+.header {
                          margin-top: 16px;
                        }
                      }

                      ul {
                        margin-top: 0;
                        margin-bottom: 0;
                        padding-inline-start: 16px;
                        font-weight: 400;

                        &+ul {
                          margin-top: 16px;
                        }
                      }

                      &.head {
                        padding: 8px 16px;
                      }
                    }
                  }

                  .section-title {
                    margin: 8px 0 !important;

                    h5 {
                      font-weight: 500 !important;
                    }
                  }

                  h6.hint {
                    margin: 16px 0 !important;
                    padding: 16px;
                    width: 100%;
                    box-sizing: border-box;
                    text-align: start !important;
                    font-size: 14px;
                    border-radius: 8px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}


@mixin documentConfiguration($bg-card, $bg-card-side, $bg-background, $bg-overlay, $bg-dark-blue, $bg-ripple-light, $bg-glass, $scroll-body, $scroll-thumb, $primary, $accent, $accent-light, $warn, $divider, $text-main-solid, $text-main, $text-side, $text-sub, $primary-text, $hover, $row-odd, $row-even) {

  .UI-V2 {
    .main-overlay {
      background: $bg-overlay;
    }

    .icon-complex {
      mat-icon {
        color: $text-side !important;

        &.complex {
          color: $primary-text !important;
        }
      }
    }

    .navigation {
      background: $row-odd;
      border-right: 1px solid $divider;

      *::-webkit-scrollbar-track {
        background: $scroll-body;
      }

      *::-webkit-scrollbar-thumb {
        background: $scroll-thumb;
      }

      .load-window {
        background: $bg-card-side;
      }

      .toolbar {
        background: $primary;
        color: $text-main;

        &.reverse {
          background: $bg-card;
          color: $text-side;
          border: 1px solid $divider;
        }
      }

      .container {
        .helper {
          color: $text-side;
        }

        .item {
          background: $bg-card;
          color: $text-side;
          border: 1px solid $divider;

          &:hover {
            background: $hover !important;
          }

          &.active {
            background: $primary !important;
            color: $text-main;
            border: 1px solid $primary;
          }
        }
      }
    }

    .work-space {
      mat-tab-group {
        .mat-tab-body-wrapper {
          mat-tab-body {
            .content-scroll {

              app-permissions,
              app-appearance,
              app-email-config,
              app-help-link-config,
              app-ou-description-config {
                .separator {
                  .subtitle {
                    color: $text-side;
                  }
                }

                .img-editior-container {
                  .img-editor {
                    .separator {
                      .subtitle {
                        color: $text-side;
                      }
                    }
                  }
                }

                .edit-theme {
                  border-bottom: 1px solid $divider;

                  mat-card.example {
                    .main-color-container {
                      .main-color {
                        border: 1px solid $divider;

                        &:before {
                          border-left: 10px solid $bg-glass;
                          border-right: 10px solid $bg-glass;
                        }
                      }
                    }

                    h6 {
                      &.required {
                        color: $warn !important;
                      }
                    }

                    mat-button-toggle-group {
                      mat-button-toggle {
                        border: 2px solid $bg-glass;
                      }
                    }

                    .side-color-container {
                      .side-color {
                        border: 1px solid $divider;

                        &:before {
                          border-top: 10px solid $bg-glass;
                          border-bottom: 10px solid $bg-glass;
                        }

                        h6 {
                          &.required {
                            color: $warn !important;
                          }
                        }
                      }
                    }
                  }
                }

                .permission-settings-cards {
                  .card {
                    background: $bg-background;

                    .slide-sub-group {
                      background: $divider;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .main {
        *::-webkit-scrollbar-track {
          background: $scroll-body !important;
        }

        .load-window {
          background: $bg-card !important;
        }

        &.side-open {
          border-right: 1px solid $divider;
        }

        .container {
          &.file-configuration {
            .file-tabs {
              .mat-tab-body-wrapper {
                mat-tab-body {
                  .mat-tab-body-content {
                    .content-scroll {
                      .item {
                        background: $row-odd;
                        border: 1px solid $divider;

                        mat-icon {
                          &.complex {
                            text-shadow: -2px 0 0px $bg-card-side, 0 -2px 0px $bg-card-side, 2px 0 0px $bg-card-side, 0 2px 0px $bg-card-side, -2px -2px 0px $bg-card-side, 2px 2px 0px $bg-card-side, 2px -2px 0px $bg-card-side, -2px 2px 0px $bg-card-side !important;
                          }
                        }

                        h5 {
                          color: $text-sub;
                        }

                        h3 {
                          color: $text-main-solid;
                        }

                        .actions {
                          pointer-events: none;

                          button {
                            pointer-events: all;
                          }
                        }

                        &.disabled {
                          background: $bg-card-side;


                          h3 {
                            color: $bg-dark-blue !important;
                          }

                          mat-icon {
                            color: $bg-dark-blue !important;
                          }

                          &.selected {
                            background: $bg-dark-blue;
                            color: $text-main !important;

                            h3 {
                              color: $text-main !important;
                            }

                            mat-icon {
                              color: $text-main !important;

                              &.complex {
                                color: $text-main !important;
                                text-shadow: -2px 0 0px $bg-dark-blue, 0 -2px 0px $bg-dark-blue, 2px 0 0px $bg-dark-blue, 0 2px 0px $bg-dark-blue, -2px -2px 0px $bg-dark-blue, 2px 2px 0px $bg-dark-blue, 2px -2px 0px $bg-dark-blue, -2px 2px 0px $bg-dark-blue !important;
                              }
                            }
                          }
                        }

                        &:hover {
                          background: $hover;
                        }

                        &.selected {
                          background: $primary;
                          color: $text-main;

                          mat-icon {
                            color: $text-main;

                            &.complex {
                              color: $text-main !important;
                              text-shadow: -2px 0 0px $primary, 0 -2px 0px $primary, 2px 0 0px $primary, 0 2px 0px $primary, -2px -2px 0px $primary, 2px 2px 0px $primary, 2px -2px 0px $primary, -2px 2px 0px $primary !important;
                            }
                          }

                          h5,
                          h3 {
                            color: $text-main;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .main-action {
          border-top: 1px solid $divider;
        }
      }

      .side {
        background: $bg-background;

        .icon-complex {
          mat-icon {
            &.complex {
              text-shadow: -2px 0 0px $bg-background, 0 -2px 0px $bg-background, 2px 0 0px $bg-background, 0 2px 0px $bg-background, -2px -2px 0px $bg-background, 2px 2px 0px $bg-background, 2px -2px 0px $bg-background, -2px 2px 0px $bg-background;
            }
          }
        }

        *::-webkit-scrollbar-track {
          background: $scroll-body !important;
        }

        *::-webkit-scrollbar-thumb {
          background: $scroll-thumb;
        }

        mat-form-field {
          &.mat-form-field-disabled {
            .mat-form-field-flex {
              background: $bg-background !important;

              .mat-form-field-prefix {
                color: $text-sub;
              }
            }
          }

          .mat-form-field-flex {
            background: $row-odd;
          }
        }

        mat-checkbox {
          &:hover {
            background: $hover !important;
          }
        }

        .hint {
          &.all {
            color: $accent;
          }
        }

        .toolbar {
          .header {
            color: $primary-text;

            h3 {
              color: $primary-text;
            }
          }
        }

        .container {
          .role-resume {
            background: $primary;

            .line {
              color: $text-main;

              &.editable {
                &:hover {
                  background: $bg-ripple-light;
                }

                button {
                  background: $primary;
                }
              }
            }
          }

          .section-title {
            color: $primary-text;
          }

          .section-resume {
            background: $row-even;
            border: 1px solid $divider;

            .line {
              &.editable {
                &:hover {
                  background: $hover !important;

                  .icon-complex {
                    mat-icon {
                      &.complex {
                        text-shadow: -2px 0 0px $row-even, 0 -2px 0px $row-even, 2px 0 0px $row-even, 0 2px 0px $row-even, -2px -2px 0px $row-even, 2px 2px 0px $row-even, 2px -2px 0px $row-even, -2px 2px 0px $row-even !important;
                      }
                    }
                  }
                }

                button {
                  background: $bg-background;
                }
              }

              .header {
                .icon-complex {
                  mat-icon {
                    color: $text-side !important;

                    &.complex {
                      color: $primary-text !important;
                    }
                  }
                }

                mat-icon:not(.complex) {
                  color: $text-side;
                }

                .info {
                  h4 {
                    color: $primary-text;
                  }
                }
              }

              ul {
                color: $primary-text;
              }

              &.head {
                background: $bg-card-side;

                mat-icon {
                  color: $accent;
                }

                h4 {
                  color: $text-main;
                }
              }
            }
          }

          app-certificates {
            .employee_detail-sign {

              .employee_detail-side-body {
                background: $row-even;

                .data {
                  .data-line {
                    .data_line-text {
                      mat-icon {
                        color: $text-side;
                      }

                      .line_text-container {
                        .line_text-main {
                          color: $primary-text;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        &.edition {
          .toolbar {
            .header {
              color: $text-main;

              mat-icon {
                color: $accent;
              }

              h3 {
                color: $accent;
              }
            }
          }

          .container {
            app-role-user-add {
              mat-horizontal-stepper {
                .mat-horizontal-content-container {
                  .form-body {
                    background: $row-even !important;

                    .line {
                      .header {
                        mat-icon:not(.complex) {
                          color: $primary-text;
                        }

                        h4 {
                          color: $primary-text;
                        }
                      }
                    }
                  }

                  .form-actions {
                    background: $bg-background;
                  }

                  .resume {
                    .form-body {
                      .line {
                        .header {
                          mat-icon {
                            color: $text-side !important;
                          }

                          .icon-complex {
                            mat-icon {
                              &.complex {
                                color: $accent !important;
                              }
                            }
                          }

                          h4 {
                            color: $accent;
                          }
                        }

                        ul {
                          color: $accent;
                          font-weight: 400;
                        }

                        &.head {
                          border-bottom: 1px solid $divider;

                          mat-icon {
                            color: $accent !important;
                          }

                          h4 {
                            color: $text-main-solid;
                          }
                        }
                      }
                    }

                    .section-title {
                      color: $text-main-solid !important;
                    }

                    h6.hint {
                      background: $accent-light;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}