@keyframes fade-in {
    0% {
        opacity:0;
    }

    100% {
        opacity:1;
    }
}

@keyframes pulse {
  0% {
      transform: scale(0.95);
      opacity: .7;
  }

  70% {
      transform: scale(1);
      opacity: 1;
  }

  100% {
      transform: scale(0.95);
      opacity: .7;
  }
}

@keyframes query {
  0% {
    opacity: 1;
    transform: translateX(35%) scale(0.3, 1);
  }

  100% {
    opacity: 0;
    transform: translateX(-50%) scale(0, 1);
  }
}

