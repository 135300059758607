html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
}

*[hidden] {
  display: none !important;
}

*::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

*::-webkit-scrollbar-track {
  overflow: hidden;
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  overflow: hidden;
  border-radius: 5px;
}

*:focus {
  outline: none;
}

a:hover {
  text-decoration: underline;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: #1a0dab;
}

a:visited {
  color: #609;
}

button {
  font-size: 16px !important;
}

.maximize {
  max-height: 100% !important;
}

.mat-form-field-wrapper {
  width: 100%;
}

.mat-form-field-prefix {
  padding: 8px 10px;
  margin-right: 16px;
}

.mat-horizontal-stepper-header-container {
  display: none !important;
}

.toolbar-line.container-ou {
  width: unset !important;
  padding: 0 24px 0 8px;
  border-radius: 28px;
  height: 50px;
}
.toolbar-line.container-ou button.notification-alert {
  background: red;
  max-height: 32px;
  border-radius: 20px;
  max-width: unset;
  flex-shrink: 0;
  margin-right: -15px;
  padding: 0 8px 0 4px !important;
}
.toolbar-line.container-ou button.notification-alert .mat-button-wrapper {
  max-height: 32px;
  display: flex;
  align-items: center;
  max-width: unset !important;
}
.toolbar-line.container-ou .invisible-container {
  position: relative;
}
.toolbar-line.container-ou .invisible-container.ou-selector {
  height: 50px;
  border-radius: 25px;
  overflow: hidden;
  padding: 0 8px;
  background: #536dfe !important;
  cursor: pointer;
  margin-left: -8px;
  z-index: 2;
}
.toolbar-line.container-ou .invisible-container .title {
  width: unset !important;
}
.toolbar-line.container-ou .invisible-container .invisible-item {
  width: 0 !important;
  overflow: hidden !important;
  position: absolute;
  left: 64px;
  top: 9px;
}
.toolbar-line.inbox-selector {
  width: unset;
  padding: 0 8px;
  border-radius: 28px;
  height: 50px;
  cursor: pointer;
  transition: background 0.2s ease;
  position: relative;
}
.toolbar-line.inbox-selector:hover {
  background: rgba(0, 0, 0, 0.04);
  transition: background 0.2s ease;
}
.toolbar-line.inbox-selector .invisible-container {
  margin-right: 0 !important;
}
.toolbar-line.inbox-selector .invisible-container .invisible-item {
  width: 0 !important;
  overflow: hidden !important;
  position: absolute;
  left: 64px;
  top: 9px;
}
.toolbar-line.container-persona {
  padding-right: 0 !important;
}
.toolbar-line.container-persona .persona-title {
  height: 100%;
  border-radius: 58px;
  overflow: hidden;
  padding-left: 58px;
  padding-right: 24px;
  margin-left: -50px;
  z-index: 1;
}
.toolbar-line.container-persona .invisible-container.persona-selector {
  height: 50px;
  border-radius: 58px;
  overflow: hidden;
  cursor: pointer;
  padding-left: 58px;
  padding-right: 8px;
  margin-left: -50px;
}
.toolbar-line.container-persona .invisible-container.persona-selector .invisible-item {
  width: 0 !important;
  overflow: hidden !important;
  position: absolute;
  left: 114px;
  top: 9px;
}

button.mat-icon-button:not([disabled]):hover {
  transition: background 0.2s ease;
}

.text-center {
  text-align: center;
}

.floating-container .mat-toolbar-row {
  padding: 0 16px 0 8px !important;
}

.icon-stacked {
  position: relative;
  margin: 0 8px;
}
.icon-stacked mat-icon + mat-icon {
  position: absolute;
  left: 0;
  top: 0;
  margin: 0 !important;
}

.divider-vert {
  height: 40px;
  width: 1px;
}

mat-divider {
  z-index: 1;
}
mat-divider.mat-divider-inset {
  margin-left: 0 !important;
}

.scroll-infinite {
  height: calc(100% - 65px) !important;
  overflow: auto !important;
  overflow: overlay !important;
  position: relative;
}

.scroll-infinite mat-expansion-panel.mat-expansion-panel.mat-expanded {
  height: auto !important;
  max-height: unset !important;
  overflow: unset !important;
}

.icon-aligned11 {
  padding-left: 11px !important;
  box-sizing: border-box;
}

.icon-aligned48 {
  padding-left: 48px !important;
  box-sizing: border-box;
}

.icon-aligned56 {
  padding-left: 56px !important;
  box-sizing: border-box;
}

.main-line {
  font-size: 14px;
}

.side-line {
  font-weight: bold;
  font-size: 18px;
}

.mat-menu-content:not(:empty) {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.wizard-add .title {
  width: 100% !important;
}

.mat-select-panel .mat-option .option-text {
  display: block;
  width: calc(100% - 56px);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.mat-form-field-suffix {
  align-items: center;
  display: flex;
}
.mat-form-field-suffix button {
  align-items: center;
  display: flex;
  justify-content: center;
}
.mat-form-field-suffix button mat-icon {
  min-width: unset;
}

app-edit-metadata-value-dialog .title h3 {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

ngx-avatar .avatar-content {
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  overflow: hidden;
}

@supports (-ms-ime-align: auto) {
  .mat-list .mat-list-item .floating-actions .mat-icon {
    background: transparent !important;
  }
  .document-search-sub-toolbar > div {
    width: 300px;
  }
  mat-toolbar.inbox-toolbar .mat-avatar {
    display: block !important;
  }
  mat-toolbar .document-search-sub-toolbar mat-form-field {
    width: 180px;
  }
  app-file-document-sign-massive-dialog .cycle-content-action mat-form-field {
    display: block !important;
    width: 250px !important;
    flex-grow: unset !important;
    flex-shrink: unset !important;
    flex-basis: unset !important;
  }
  app-file-document-sign-massive-dialog .img-container {
    max-width: 200px;
  }
  app-file-document-sign-massive-dialog .img-container img {
    width: 100%;
  }
  app-employee-detail .employee_detail-container .employee_detail-scrollable,
app-employee-rrhh-detail .employee_detail-container .employee_detail-scrollable {
    padding-right: 0 !important;
    padding-top: 1px !important;
    margin-top: -2px !important;
  }
  mat-horizontal-stepper {
    width: 100%;
  }
  .mat-basic-chip {
    min-width: auto;
  }
  mat-chip-list .mat-chip-list-wrapper .mat-basic-chip {
    min-width: auto;
  }
  app-employee-detail .employee_detail-container.is-editing .edition-line,
app-employee-rrhh-detail .employee_detail-container.is-editing .edition-line {
    width: 100%;
  }
  app-employee-detail .employee_detail-container .detail_head-body .head_body-title .body_title-name,
app-employee-rrhh-detail .employee_detail-container .detail_head-body .head_body-title .body_title-name {
    width: 100%;
  }
  app-employee-detail .employee_detail-container .detail_head-body .head_body-title .body_title-user,
app-employee-rrhh-detail .employee_detail-container .detail_head-body .head_body-title .body_title-user {
    width: 100%;
  }
  .side-nav .side-nav-container .actions button {
    width: 100%;
  }
}
.intro-spinner {
  background: "#ededee" url("../img/spinner.gif") no-repeat center center !important;
  width: 100vw;
  height: 100vh;
  z-index: 199;
}

.mat-loading-window {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 199;
}

.window-spinner {
  z-index: 199;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mat-progress-bar {
  position: absolute !important;
  z-index: 199;
}

.load-window {
  position: absolute;
  z-index: 199;
  top: 65px;
  width: 100%;
  height: calc(100% - 65px);
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes pulse {
  0% {
    transform: scale(0.95);
    opacity: 0.7;
  }
  70% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.95);
    opacity: 0.7;
  }
}
@keyframes query {
  0% {
    opacity: 1;
    transform: translateX(35%) scale(0.3, 1);
  }
  100% {
    opacity: 0;
    transform: translateX(-50%) scale(0, 1);
  }
}
@media (min-width: 600px) and (max-width: 1300px) {
  mat-drawer-container mat-drawer.profile-menu {
    min-width: 384px;
  }
  mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer.side-nav {
    position: absolute !important;
    z-index: 10 !important;
  }
  mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer.side-nav .mat-drawer-inner-container {
    overflow: hidden;
  }
  mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer.side-nav .mat-drawer-inner-container .content {
    overflow-y: overlay;
    position: relative;
  }
  mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer.side-nav .mat-drawer-inner-container .content .tools {
    height: auto;
    max-height: unset !important;
    position: unset !important;
  }
  mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer.side-nav .mat-drawer-inner-container .content .tools .mat-nav-list {
    overflow-x: hidden;
  }
  mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer.side-nav .mat-drawer-inner-container .content .navigation .mat-nav-list {
    overflow-x: hidden;
  }
  mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container {
    margin-left: 72px !important;
  }
  mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container *.show-md {
    display: flex !important;
  }
  mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container *.hide-md {
    display: none !important;
  }
  mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 > mat-toolbar {
    display: none !important;
  }
  mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card {
    height: 100% !important;
    width: 100% !important;
    border-radius: 0;
  }
  mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .inbox-container {
    width: 100%;
    max-width: unset !important;
  }
  mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .inbox-container mat-toolbar .toolbar-line.container-ou {
    background: unset !important;
    color: unset !important;
  }
  mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .inbox-container mat-list-item.mat-list-item {
    margin: 8px;
    border-radius: 8px;
    width: calc(100% - 16px) !important;
  }
  mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .mat-divider.mat-divider-vertical {
    display: none;
  }
  mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .detail {
    position: absolute;
    left: 100%;
    transition: left 0.2s ease;
    z-index: 1;
  }
  mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .detail button[title="Ocultar Detalle"] {
    position: absolute;
    right: 16px;
    top: 12px;
  }
  mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .detail.finished-process-inbox, mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .detail.error-process-inbox, mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .detail.in-process-inbox, mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .detail.erased-process-inbox {
    left: 0 !important;
    max-width: unset !important;
  }
  mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .detail.close.finished-process-inbox, mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .detail.close.error-process-inbox, mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .detail.close.in-process-inbox, mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .detail.close.erased-process-inbox {
    left: 100% !important;
  }
  mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .detail.is-open {
    left: 0;
    max-width: unset !important;
    width: 100%;
    z-index: 1;
    transition: left 0.2s ease;
    top: 0;
  }
  mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .detail mat-toolbar.detail-toolbar {
    padding-right: 40px !important;
  }
  mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .card-filter {
    max-width: unset !important;
    width: 100% !important;
  }
  mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .navigation {
    position: absolute !important;
    z-index: 200;
    max-width: 50vw !important;
    transition: left 0.2s ease;
    left: -50vw;
  }
  mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .navigation.open {
    left: 0;
    transition: left 0.2s ease;
  }
  mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .navigation.float-in {
    transition: left 0.2s ease;
    left: 0 !important;
    width: 50vw;
  }
  mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .work-space {
    width: 100%;
    max-width: unset !important;
  }
  mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .main .toolbar {
    display: flex !important;
    flex-direction: column !important;
  }
  mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .main .toolbar .header .ou-select {
    display: flex !important;
  }
  mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .main .toolbar .search-container {
    margin-top: 16px;
  }
  mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .main .helper .actions .mat-stroked-button {
    padding: 0;
    min-width: 40px;
    width: 40px;
    height: 40px;
  }
  mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .main .helper .actions .mat-stroked-button .mat-button-wrapper {
    width: 40px !important;
    overflow: hidden;
    justify-content: start !important;
    padding: 0;
  }
  mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .main .helper .actions .mat-stroked-button .mat-button-wrapper mat-icon {
    width: 40px !important;
    min-width: 40px !important;
    margin: 0 !important;
  }
  mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .main.w-detail {
    width: 100% !important;
  }
  mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .main.w-detail + .new-detail-container {
    left: calc(100% - 50vw) !important;
    transition: left 0.2s ease;
  }
  mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .main.w-detail + .new-detail-container:before {
    content: " ";
    display: block;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
  }
  mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .main.side-open {
    width: 100% !important;
    max-width: unset !important;
  }
  mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .main.side-open + .side {
    left: calc(100% - 50vw);
    transition: left 0.2s ease;
    z-index: 101;
  }
  mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .side {
    position: absolute;
    left: 100%;
    width: 50vw !important;
    max-width: unset !important;
    transition: left 0.2s ease;
  }
  mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .side .side {
    position: relative;
    left: unset !important;
    width: unset !important;
  }
  mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .side .container .mat-list-base {
    max-height: unset;
  }
  mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .side .container .mat-list-base + .mat-list-base {
    margin-top: 16px;
  }
  mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container form {
    max-height: calc(100vh - 112px);
  }
  mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .new-detail-container {
    position: absolute;
    min-width: 50vw !important;
    z-index: 100 !important;
    left: 100%;
    transition: left 0.2s ease;
  }
  mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .new-table.w-main-action,
mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .document-table.w-main-action {
    min-height: calc(100% - 192px - 56px - 97px - 16px - 16px) !important;
  }
  mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .new-table.documents,
mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .document-table.documents {
    min-height: calc(100% - 192px - 56px - 16px - 16px) !important;
  }
  mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .new-table.documents .w-12,
mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .document-table.documents .w-12 {
    width: calc(100% - 72px - 72px - 72px) !important;
    min-width: calc(100% - 72px - 72px - 72px) !important;
  }
  mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .audit-filter {
    width: 100% !important;
    max-width: unset !important;
  }
  mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .audit-filter .filter-container.is-floating {
    position: absolute !important;
    z-index: 1000;
    width: 40vw !important;
    border-radius: 8px;
    left: 16px;
    top: 16px;
  }
  mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .audit-filter .filter-container.is-floating .form-body {
    padding: 0 !important;
  }
  mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .audit-result {
    position: absolute !important;
    left: 100%;
    width: 100% !important;
    max-width: unset !important;
  }
  mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .audit-result.float-in {
    left: 0 !important;
  }
  mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .audit-detail-container {
    z-index: 100;
    width: 50vw !important;
    max-width: unset !important;
    transition: right 0.2s ease;
    right: -50vw;
  }
  mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .audit-detail-container .side {
    position: relative !important;
    left: unset !important;
  }
  mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .detail-open .audit-detail-container {
    right: 0 !important;
    transition: right 0.2s ease;
  }
  mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 mat-sidenav-container mat-sidenav-content .new-detail-container {
    position: absolute;
    min-width: 50vw !important;
    z-index: 100 !important;
    left: 100%;
    transition: left 0.2s ease;
    top: 0;
  }
  mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 mat-sidenav-container .main.w-detail {
    width: 100% !important;
  }
  mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 mat-sidenav-container .main.w-detail + .new-detail-container {
    left: calc(100% - 50vw) !important;
    transition: left 0.2s ease;
  }
  mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 mat-sidenav-container .main.w-detail + .new-detail-container:before {
    content: " ";
    display: block;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
  }
  mat-dialog-container app-file-document-view-modal mat-toolbar.view-toolbar {
    height: 128px !important;
    position: relative;
  }
  mat-dialog-container app-file-document-view-modal mat-toolbar.view-toolbar .view-actions {
    position: absolute !important;
    bottom: 12px;
    right: 0;
    width: 100%;
    padding: 0 16px;
    box-sizing: border-box;
  }
  mat-dialog-container app-file-document-view-modal mat-toolbar.view-toolbar .view-actions .divider-vert {
    display: none !important;
  }
  mat-dialog-container app-file-document-view-modal mat-toolbar.view-toolbar .view-actions button[title=Cerrar] {
    position: absolute !important;
    top: -64px;
    right: 16px;
  }
  mat-dialog-container app-file-document-view-modal .view-content {
    height: calc(100% - 129px) !important;
  }
}
@media (min-width: 960px) {
  .login-card.tyc {
    max-width: 45% !important;
  }
}
@media (max-width: 599px) {
  .wellcome-bk {
    display: none !important;
  }
  .mat-tooltip-panel {
    display: none !important;
  }
  app-document-detail .mat-toolbar {
    height: 56px !important;
    padding: 0 16px !important;
    padding-right: 56px !important;
    position: relative;
  }
  app-document-detail .mat-toolbar .mat-toolbar-row {
    padding: 0 !important;
  }
  app-document-detail .mat-toolbar .mat-toolbar-row .mat-avatar.four-icons {
    width: calc(100% - 40px);
  }
  app-document-detail .mat-toolbar .mat-toolbar-row .mat-avatar.four-icons .toolbar-line {
    padding-left: 0;
  }
  app-document-detail .mat-toolbar .mat-toolbar-row .toolbar-actions {
    padding: 0 16px !important;
    width: 100% !important;
    position: absolute !important;
    top: 100% !important;
    z-index: 12 !important;
    height: 40px !important;
    left: 0 !important;
    display: flex !important;
    justify-content: center !important;
  }
  app-document-detail .divider-vert {
    display: none;
  }
  app-document-detail app-pdf-wrapper {
    padding-top: 39px;
  }
  app-document-detail .sign-cycle .sign-content {
    width: 100vw;
    padding: 0 16px;
  }
  app-document-detail .sign-cycle .sign-content .indications {
    width: calc(100vw - 80px - 64px);
    padding: 0 !important;
  }
  app-document-detail .sign-cycle .sign-content .indications h2 {
    text-align: center;
  }
  .app-page {
    height: calc(100vh - 56px) !important;
  }
  .login {
    align-items: flex-start !important;
  }
  .login .login-card {
    overflow-y: auto !important;
    justify-content: space-between;
  }
  .login .login-card .brand {
    height: unset !important;
    background-position-x: left !important;
  }
  .login .login-card .mat-progress-bar {
    top: 0 !important;
  }
  .login .login-card .mat-card-header {
    display: flex !important;
    height: 50%;
    align-items: center;
  }
  .login .login-card .mat-card-header .mat-card-header-text {
    margin: 0 !important;
  }
  .login .login-card .mat-card-content .mat-card-footer {
    flex-wrap: wrap;
  }
  .login .login-card .mat-card-content .mat-card-footer a,
.login .login-card .mat-card-content .mat-card-footer b {
    line-height: 40px;
  }
  .login-card {
    box-shadow: none !important;
    border-radius: 0 !important;
    height: 100% !important;
    max-width: 100vw !important;
    padding: 15px !important;
    top: 0 !important;
  }
  .login-card .mat-card-header-text, .login-card .mat-card-title, .login-card .brand {
    width: 100% !important;
  }
  .mat-avatar h3 {
    font-size: 16px !important;
    line-height: 18px !important;
  }
  .mat-avatar h5 {
    font-size: 11px !important;
    line-height: 13px !important;
  }
  .mat-card.card-1-1 {
    height: 100% !important;
    width: 100% !important;
    max-width: 100% !important;
    border-radius: 0 !important;
  }
  .mat-card.card-1-1 > mat-sidenav-container > mat-sidenav {
    width: 100% !important;
  }
  .inbox-toolbar {
    padding-right: 40px !important;
  }
  app-inbox {
    height: calc(100% - 57px) !important;
  }
  app-inbox .mat-list {
    max-height: calc(100% - 57px) !important;
  }
  mat-expansion-panel.virtual-scrolling .mat-expansion-panel-content .paginated-list {
    height: 100% !important;
  }
  mat-expansion-panel.mat-expanded {
    height: calc(100% - 48px - 49px) !important;
  }
  .detail {
    position: absolute !important;
    right: 100% !important;
    transition: right 0.4s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
    z-index: 1;
  }
  .detail.is-open {
    right: 0 !important;
    transition: right 0.4s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
    z-index: 10 !important;
  }
  .detail.is-open .help-hoverable mat-hint {
    position: fixed;
    top: 56px;
    z-index: 1300;
    width: 100%;
    left: 0;
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 0;
    height: calc(100% - 56px);
    max-height: unset;
    box-shadow: none !important;
  }
  .detail.is-open .help-hoverable mat-hint .close-desktop {
    display: none;
  }
  .detail.is-open .help-hoverable mat-hint .footer {
    display: flex;
  }
  .detail.is-open .help-hoverable mat-hint .footer button {
    width: calc(50% - 8px);
    margin-right: 8px;
  }
  .detail.is-open .help-hoverable mat-hint .footer button + button {
    margin-right: 0 !important;
    margin-left: 8px;
  }
  .detail.is-open .help-hoverable mat-hint .close-mobile {
    display: block;
  }
  .detail .welcome-card {
    display: none !important;
  }
  .mobile-height {
    height: 112px !important;
    padding: 0 !important;
  }
  .toolbar-line-container {
    flex-direction: column !important;
  }
  .toolbar-line-container .toolbar-line {
    height: 56px !important;
    padding: 0 16px !important;
    padding-right: 56px !important;
  }
  .toolbar-line .not-mobile {
    display: none !important;
  }
  #closeDetail {
    display: flex !important;
    visibility: visible !important;
    pointer-events: all !important;
  }
  .toolbar-actions {
    padding: 0 16px !important;
    width: 100% !important;
    position: absolute !important;
    top: 100% !important;
    z-index: 1 !important;
    height: 40px !important;
    left: 0 !important;
    display: flex !important;
    justify-content: center !important;
  }
  .toolbar-actions .divider-vert {
    display: none;
  }
  #pdfPrint {
    display: none;
  }
  mat-drawer.side-nav {
    width: 100vw !important;
    max-width: unset !important;
    border: none !important;
    position: absolute !important;
    left: -100% !important;
    z-index: 6 !important;
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
    box-shadow: none !important;
  }
  mat-drawer.side-nav.enter {
    left: 0 !important;
    z-index: 10 !important;
    height: 100%;
  }
  .app-container.mat-drawer-content {
    height: calc(100vh - 56px) !important;
    width: 100vw !important;
    margin: 0 !important;
  }
  .filter {
    height: 100% !important;
  }
  .mat-subheader {
    overflow: unset !important;
    width: 100% !important;
    padding-left: 16px !important;
  }
  .links .mat-divider {
    width: 100% !important;
    max-width: unset !important;
  }
  .actions button .mat-button-wrapper span {
    margin-right: 20px !important;
    width: auto !important;
  }
  .content .mat-list-text {
    padding-left: 16px !important;
  }
  .profile-menu {
    width: 100vw !important;
    min-width: 100vw !important;
  }
  .profile-menu mat-toolbar.mat-toolbar.profile-toolbar {
    position: absolute !important;
    z-index: 1 !important;
    top: 0 !important;
  }
  .profile-menu mat-toolbar.mat-toolbar.profile-toolbar + mat-divider {
    position: absolute !important;
    z-index: 1 !important;
    top: 56px !important;
    width: 100% !important;
  }
  .profile-menu app-profile {
    padding-top: 56px !important;
  }
  button.side-open-mobile {
    display: block !important;
    position: absolute !important;
    z-index: 5 !important;
    top: 8px !important;
    right: 16px !important;
  }
  mat-divider.mat-divider.mat-divider-vertical {
    display: none !important;
  }
  button.side-close-mobile {
    display: block !important;
    position: absolute !important;
    top: 39px !important;
    right: 16px !important;
    z-index: 10;
  }
  .menu-trigger {
    top: 34px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .menu-trigger .mat-button-wrapper {
    padding: 0 !important;
  }
  button.side-trigger {
    display: none;
  }
  .side-nav .side-nav-container .content {
    padding-top: 0 !important;
    height: calc(100% - 49px) !important;
  }
  app-file-document-inbox-block-calendar mat-expansion-panel.mat-expanded.mat-expansion-panel-spacing {
    height: calc(100% - 48px - 48px - 56px) !important;
  }
  app-file-document-inbox-block-calendar mat-expansion-panel.mat-expanded.mat-expansion-panel-spacing app-cs-paginator.get-more {
    padding: 28px 20px 0 !important;
  }
  app-file-document-inbox-block-calendar mat-expansion-panel.mat-expanded.mat-expansion-panel-spacing app-cs-paginator.get-more mat-form-field {
    display: none !important;
  }
  app-file-document-inbox-block-calendar mat-expansion-panel.mat-expanded.mat-expansion-panel-spacing app-cs-paginator.get-more mat-paginator {
    width: 100% !important;
  }
  app-file-document-inbox-block-calendar mat-expansion-panel.mat-expanded.mat-expansion-panel-spacing app-cs-paginator.get-more mat-paginator .mat-paginator-container {
    min-height: 40px !important;
  }
  app-file-document-inbox-block-calendar mat-expansion-panel.mat-expanded.mat-expansion-panel-spacing app-cs-paginator.get-more mat-paginator .mat-paginator-container .mat-paginator-range-actions {
    justify-content: space-around !important;
    width: 100%;
  }
  app-file-document-inbox-block-calendar mat-expansion-panel.mat-expanded.mat-expansion-panel-spacing app-cs-paginator.get-more mat-paginator .mat-paginator-container .mat-paginator-range-actions .mat-paginator-range-label {
    top: -20px !important;
  }
  app-file-document-inbox-block-calendar mat-expansion-panel.mat-expanded.mat-expansion-panel-spacing app-cs-paginator.get-more mat-paginator .mat-paginator-container .mat-paginator-range-actions button {
    margin: 0 20px !important;
  }
  .catcher .catcher-positioner {
    width: 100% !important;
    max-width: unset !important;
    padding: 56px 0 0 !important;
  }
  .catcher .card-filter {
    width: 100% !important;
    max-width: unset !important;
    border-radius: 0 !important;
  }
  .catcher .card-filter mat-form-field {
    padding: 0 16px !important;
  }
  .catcher .card-filter .mat-chip-list-wrapper {
    max-height: 40vh;
    overflow-y: auto;
  }
  .catcher .card-filter .filter-footer {
    padding: 0 16px !important;
  }
  .inbox-container.employee.my-documents .chip-line.mat-line {
    margin: 0 !important;
    width: unset !important;
  }
  app-home-file-documents app-file-document-state.bottom-container {
    display: flex !important;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    flex: 0;
  }
  app-home-file-documents app-file-document-state.bottom-container mat-chip-list {
    display: flex !important;
    width: 100%;
  }
  app-home-file-documents app-file-document-state.bottom-container mat-chip-list .mat-chip-list-wrapper {
    display: flex;
    flex-wrap: unset;
    justify-content: center;
    padding: 32px;
    flex-direction: column-reverse;
    width: 100%;
  }
  app-home-file-documents app-file-document-state.bottom-container mat-chip-list .mat-chip-list-wrapper .mat-basic-chip {
    margin: 12px !important;
  }
  app-home-file-documents app-file-document-state.bottom-container mat-chip-list .mat-chip-list-wrapper .mat-basic-chip + mat-basic-chip.seen {
    display: none !important;
  }
  .side-nav .filter {
    height: 100%;
  }
  .side-nav .side-nav-container .actions button .mat-button-wrapper span {
    margin-right: 20px;
    width: auto;
  }
  .side-nav .side-nav-container .mat-list-base h3.mat-subheader {
    height: 48px !important;
    padding: 16px !important;
    overflow: visible !important;
  }
  .side-nav .side-nav-container .mat-list-base .mat-list-item {
    width: 100% !important;
  }
  .side-nav .side-nav-container .mat-list-base .mat-list-item .mat-list-item-content {
    justify-content: flex-start !important;
    padding: 0 8px !important;
  }
  .side-nav .side-nav-container .mat-list-base .mat-list-item .mat-list-text {
    padding: 8px !important;
    width: 100% !important;
    max-width: unset !important;
  }
  .side-nav .side-nav-container .mat-list-base .mat-list-item.active {
    position: sticky;
    top: 0;
    bottom: 0;
    z-index: 1;
  }
  .side-nav .side-nav-container .actions + .content .tools {
    max-height: calc(100% - 192px);
    padding-top: 44px;
    padding-right: 0 !important;
  }
  .side-nav .side-nav-container .actions + .content .tools mat-nav-list.mat-nav-list.mat-list-base {
    padding: 0 8px;
    height: 100%;
    overflow-y: scroll;
  }
  .side-nav .side-nav-container .tools {
    height: calc(100% - 192px);
    padding-right: 10px;
  }
  .side-nav .side-nav-container .links {
    padding-right: 10px;
  }
  .side-nav .side-nav-container .version {
    height: 48px;
  }
  .side-nav .side-nav-container .actions {
    padding: 0 !important;
    position: absolute;
    top: -28px;
    z-index: 2 !important;
    left: 8px;
  }
  .side-nav .side-nav-container .content {
    padding: 0 !important;
    height: calc(100% - 49px);
  }
  .side-nav .side-nav-container .actions + .content {
    width: 100%;
    padding-top: 36px;
    height: calc(100% - 49px);
  }
  app-file-document-view {
    height: 100% !important;
    display: block !important;
  }
  app-file-document-view .view-container {
    display: block;
    height: 100%;
  }
  app-file-document-view .view-container mat-toolbar.side-bar {
    min-height: 56px !important;
    position: relative !important;
    display: block;
    z-index: 11;
  }
  app-file-document-view .view-container mat-toolbar.side-bar.upload {
    position: fixed !important;
    top: 0 !important;
  }
  app-file-document-view .view-container mat-toolbar.side-bar .mat-toolbar-row {
    padding-right: 58px !important;
  }
  app-file-document-view .view-container mat-toolbar.side-bar .mat-toolbar-row .mat-avatar.four-icons {
    width: calc(100% - 40px) !important;
  }
  app-file-document-view .view-container mat-toolbar.side-bar .mat-toolbar-row .mat-avatar.four-icons .toolbar-line mat-icon {
    display: none !important;
  }
  app-file-document-view .view-container .view-content {
    display: block;
    height: calc(100% - 56px) !important;
    box-sizing: border-box;
  }
  app-file-document-view .view-container .view-content .toggle-buttons-container {
    flex-wrap: wrap !important;
    height: auto !important;
    justify-content: center !important;
  }
  app-file-document-view .view-container .view-content app-pdf-wrapper.floating {
    position: absolute;
    z-index: 10;
    top: 0;
    height: 100%;
    left: 100%;
    padding-bottom: 56px;
    opacity: 0;
    transition-delay: 0.2s;
    transition: all 0.2s ease-in-out;
  }
  app-file-document-view .view-container .view-content app-pdf-wrapper.floating.on {
    left: 0;
    opacity: 1;
    transition-delay: 0.2s;
    transition: all 0.2s ease-in;
  }
  app-file-document-view .view-container .view-content .upload-indications {
    display: flex !important;
    position: absolute;
    z-index: 11;
    width: 100%;
    bottom: 0;
    left: 100%;
    padding: 16px;
    opacity: 0;
    transition-delay: 0.4s;
    transition: all 0.2s ease-in-out;
  }
  app-file-document-view .view-container .view-content .upload-indications.on {
    left: 0;
    opacity: 1;
    transition-delay: 0.4s;
    transition: all 0.2s ease-in;
  }
  app-file-document-view .view-container .view-content .upload-indications h3 {
    margin: 0;
    width: calc(100% - 32px - 40px - 16px);
    line-break: anywhere;
  }
  app-file-document-view .view-container .view-content .preview-content {
    position: absolute;
    z-index: 10;
    top: 0;
    height: 100%;
    left: 100%;
    padding-bottom: 56px;
    opacity: 0;
    box-sizing: border-box;
    transition-delay: 0.2s;
    transition: all 0.2s ease-in-out;
  }
  app-file-document-view .view-container .view-content .preview-content.on {
    left: 0;
    opacity: 1;
    transition-delay: 0.2s;
    transition: all 0.2s ease-in;
  }
  app-file-document-view .view-container .view-content app-pdf-wrapper {
    box-sizing: border-box;
    padding-top: 40px;
  }
  app-file-document-view .view-container .view-content app-pdf-wrapper .pdf-view-container.signed {
    max-height: unset;
    margin: 0;
  }
  app-file-document-view .view-container .view-content app-file-document-upload {
    height: 100%;
  }
  app-file-document-view .view-container .view-content app-file-document-upload .content-action-main {
    width: calc(100% - 32px);
  }
  app-file-document-view .view-container .view-content app-file-document-upload .sliding-actions.view {
    display: flex !important;
  }
  app-file-document-view .view-container .view-content app-file-document-upload app-cs-cropper-control .actions {
    justify-content: center;
  }
  app-file-document-view .view-container .view-content app-file-document-upload app-cs-cropper-control .actions button {
    width: 50%;
  }
  app-file-document-view .view-container .view-content app-formio-cardinal {
    width: 100%;
    height: 100%;
  }
  app-file-document-view .view-container .view-content app-formio-cardinal + .firmador {
    bottom: 20px !important;
  }
  app-file-document-view .view-container .view-content app-formio-cardinal + .firmador button {
    pointer-events: all;
  }
  app-file-document-view .view-container .view-content .wellcome-bk {
    display: none !important;
  }
  app-file-document-view .view-container .view-content app-file-document-sign {
    display: block;
    position: unset;
  }
  app-file-document-view .view-container .view-content app-file-document-sign .sign-cycle {
    padding: 0 !important;
  }
  app-file-document-view .view-container .view-content app-file-document-sign .sign-cycle h2 {
    font-size: 18px !important;
    line-height: 1.25 !important;
  }
  app-file-document-view .view-container .view-content app-file-document-sign .sign-cycle mat-horizontal-stepper.mat-stepper-horizontal {
    width: 100vw !important;
    min-width: unset;
  }
  app-file-document-view .view-container .view-content app-file-document-sign .sign-cycle .sign-cycle-content {
    width: 100% !important;
    margin: 0 !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: flex-end !important;
    padding: 16px;
  }
  app-file-document-view .view-container .view-content app-file-document-sign .sign-cycle .sign-cycle-content .cycle-content-title-container {
    width: 85%;
    max-width: unset !important;
    left: 0;
    right: 0;
    margin: 0 auto 16px !important;
    background: #DCEDC8;
    padding: 16px 32px 16px 16px;
    z-index: 1;
    border-radius: 16px;
  }
  app-file-document-view .view-container .view-content app-file-document-sign .sign-cycle .sign-cycle-content .cycle-content-title-container .cycle-content-title *:not(button) {
    width: 100%;
  }
  app-file-document-view .view-container .view-content app-file-document-sign .sign-cycle .sign-cycle-content .cycle-content-action {
    width: 85%;
  }
  app-file-document-view .view-container .view-content app-file-document-sign .sign-cycle .sign-cycle-content .cycle-content-action mat-form-field {
    padding-bottom: 0 !important;
    flex: unset !important;
    max-height: unset !important;
    width: 100%;
  }
  app-file-document-view .view-container .view-content app-file-document-sign .sign-cycle .sign-cycle-content .cycle-content-action .content-action-main {
    width: 100%;
    z-index: 1;
  }
  app-pending .floating-container .inbox-container .inbox-toolbar {
    padding-right: 56px !important;
    height: 56px;
  }
  app-pending .floating-container .inbox-container .inbox-toolbar .mat-toolbar-row {
    padding: 0 !important;
  }
  app-pending .floating-container .inbox-container .inbox-toolbar .mat-toolbar-row .mat-avatar {
    width: 100%;
  }
  app-pending .floating-container .inbox-container .inbox-toolbar .mat-toolbar-row .mat-avatar h5 {
    display: none;
  }
  app-pending .floating-container .inbox-container .inbox-toolbar .mat-toolbar-row .mat-avatar mat-icon {
    display: none !important;
  }
  app-pending .floating-container .inbox-container .pending-container {
    height: calc(100% - 56px) !important;
  }
  app-pending .floating-container .inbox-container .pending-container .pending-items {
    padding-bottom: 96px;
  }
  app-pending .floating-container .inbox-container .pending-container .main-action.desktop {
    display: none !important;
  }
  app-pending .floating-container .inbox-container .pending-container .main-action.mobile {
    display: flex !important;
  }
  app-pending .floating-container .detail app-file-document-view .mat-avatar .toolbar-line mat-icon {
    display: none !important;
  }
  app-pending .floating-container .detail app-file-document-view .mat-avatar .toolbar-line .title {
    width: 100%;
  }
  app-document-sign-massive .inbox-container .inbox-toolbar {
    height: 56px !important;
  }
  app-document-sign-massive .inbox-container .pending-container {
    height: calc(100% - 56px) !important;
    display: flex;
    flex-direction: column;
  }
  app-document-sign-massive .inbox-container .pending-container .pending-items {
    padding-bottom: 40px !important;
  }
  app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content.selected button {
    pointer-events: all !important;
  }
  app-document-sign-massive .inbox-container .pending-container app-chapa.minimized {
    position: absolute;
    z-index: 1;
    height: 48px;
    top: -24px;
    width: unset;
    margin: 0 auto;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  app-document-sign-massive .inbox-container .pending-container app-chapa.minimized .info-empty {
    flex-direction: row !important;
    min-height: unset !important;
    padding: 0 16px;
    border-radius: 24px;
    transition: all 0.3s ease-in;
  }
  app-document-sign-massive .inbox-container .pending-container app-chapa.minimized .info-empty > span {
    flex-direction: row !important;
  }
  app-document-sign-massive .inbox-container .pending-container app-chapa.minimized .info-empty > span mat-icon {
    display: none !important;
  }
  app-document-sign-massive .inbox-container .pending-container app-chapa.minimized .info-empty > span h2 {
    font-size: 1rem;
  }
  app-document-sign-massive .inbox-container .pending-container app-chapa.minimized .info-empty > span h4 {
    display: none;
  }
  app-document-sign-massive .inbox-container .signer.mobile {
    padding: 40px 32px 16px;
    position: relative;
  }
  app-document-sign-massive .detail app-file-document-detail {
    display: block;
    height: 100% !important;
  }
  app-document-sign-massive .detail app-file-document-detail .view-container .mat-toolbar {
    height: 56px;
    padding-right: 40px;
    position: relative;
  }
  app-document-sign-massive .detail app-file-document-detail .view-container .mat-toolbar .mat-toolbar-row {
    padding: 0;
  }
  app-document-sign-massive .detail app-file-document-detail .view-container .mat-toolbar .mat-toolbar-row .view-actions {
    padding: 0 16px !important;
    width: 100% !important;
    position: absolute !important;
    top: 100% !important;
    z-index: 1 !important;
    height: 40px !important;
    left: 0 !important;
    display: flex !important;
    justify-content: center !important;
    color: white !important;
  }
  app-document-sign-massive .detail app-file-document-detail .view-container .view-content {
    height: calc(100% - 56px) !important;
    padding-top: 40px;
  }
  app-document-sign-massive .detail app-chapa {
    display: none !important;
  }
  app-document-sign-massive .detail .signer.desktop {
    display: none !important;
  }
  app-add-documentation .floating-container .employee-add-documentation .inbox-container .inbox-toolbar {
    padding-right: 56px !important;
  }
  app-add-documentation .floating-container .employee-add-documentation .inbox-container .inbox-toolbar .mat-toolbar-row {
    padding: 0 !important;
  }
  app-add-documentation .floating-container .employee-add-documentation .inbox-container .inbox-toolbar .mat-toolbar-row .mat-avatar {
    width: 100%;
  }
  app-add-documentation .floating-container .employee-add-documentation .inbox-container .inbox-toolbar .mat-toolbar-row .mat-avatar h5 {
    display: none;
  }
  app-add-documentation .floating-container .employee-add-documentation .inbox-container .inbox-toolbar .mat-toolbar-row .mat-avatar mat-icon {
    display: none !important;
  }
  app-add-documentation .floating-container .employee-add-documentation .inbox-container mat-tab-group {
    height: calc(100% - 56px) !important;
  }
  app-add-documentation .floating-container .employee-add-documentation .inbox-container mat-tab-group .pending-container {
    height: calc(100% - 56px) !important;
  }
  app-add-documentation .floating-container .employee-add-documentation .detail app-file-document-view .view-container .mat-avatar .toolbar-line mat-icon {
    display: none !important;
  }
  app-add-documentation .floating-container .employee-add-documentation .detail app-file-document-view .view-container .mat-avatar .toolbar-line .title {
    width: 100%;
  }
  app-add-documentation .floating-container .employee-add-documentation .detail app-file-document-view .view-container .view-content .new-container app-file-document-upload {
    z-index: 20;
    overflow: auto;
    position: relative;
  }
  app-add-documentation .floating-container .employee-add-documentation .detail app-file-document-view .view-container .view-content .new-container app-file-document-upload.on {
    z-index: 1 !important;
  }
  app-add-documentation .floating-container .employee-add-documentation .detail app-file-document-view .view-container .view-content .new-container app-file-document-upload app-upload-form {
    height: unset !important;
  }
  app-add-documentation .floating-container .employee-add-documentation .detail app-file-document-view .view-container .view-content .new-container app-file-document-upload .metadata-list {
    max-height: unset !important;
    overflow: unset !important;
  }
  app-add-documentation .floating-container .employee-add-documentation .detail app-file-document-view .view-container .view-content .new-container app-file-document-upload .metadata-list .mat-subheader {
    position: relative !important;
  }
  app-add-documentation .floating-container .employee-add-documentation .detail app-file-document-view .view-container .view-content .new-container .firmador {
    position: sticky;
    border-top: none;
    bottom: 0;
    margin: 16px 0;
    bottom: 16px;
  }
  app-add-documentation .floating-container .employee-add-documentation .detail app-file-document-view .view-container .view-content .new-container .firmador button {
    pointer-events: all;
  }
  app-leave-detail {
    position: fixed;
    left: 0;
    top: 4rem;
    z-index: 2;
    background: #fff;
    min-width: 100%;
  }
  app-chapa {
    height: 85% !important;
  }
  .leave_detail-container app-chapa .actions {
    display: flex;
    flex-direction: column !important;
    gap: 12px;
  }
  .leave_detail-container app-chapa [name=btn_RechazarSolicitud] {
    min-width: 242px !important;
    min-height: 49px !important;
  }
  .leave_detail-container app-chapa [name=btn_AprobarSolicitud] {
    min-width: 242px !important;
    min-height: 49px !important;
  }
  .leave_detail-container app-chapa [name=btn_ViewDocument] {
    min-width: 242px !important;
    min-height: 49px !important;
  }
  .mat-list-actionApprovers .signed-ok {
    max-width: 315px;
    min-height: 64px;
  }
  .mat-list-actionApprovers .signed-not-ok {
    max-width: 315px;
    min-height: 64px;
  }
  .mat-list-actionApprovers .signed-not-ok span {
    max-width: 200px;
  }
  mat-basic-chip {
    max-width: 315px;
    min-height: 64px;
  }
  mat-basic-chip span {
    max-width: 200px;
  }
  .daysInfo {
    max-width: 17rem;
  }
}
.app-page {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: relative;
}

.floating-container {
  position: absolute;
  top: 0;
  left: 0;
}

.mat-card.card-1-1 {
  height: calc(100% - 32px);
  width: calc(100% - 32px);
  padding: 0;
  overflow: hidden;
}

.card-1-1.mat-card > mat-sidenav-container > mat-sidenav {
  width: 30%;
}

.mat-avatar {
  font-size: 16px;
  width: calc(100% - 48px);
  min-width: unset !important;
}
.mat-avatar.no-icons {
  width: 100%;
}
.mat-avatar.two-icons {
  width: calc(100% - 96px);
}
.mat-avatar.three-icons {
  width: calc(100% - 144px);
}
.mat-avatar.four-icons {
  width: calc(100% - 192px);
}

.title {
  width: calc(100% - 40px - 16px);
  height: 100%;
}

.mat-avatar h3 {
  font-size: 20px !important;
  line-height: 1.25 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.mat-avatar h4 {
  font-size: 20px !important;
  line-height: 1.25 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.mat-avatar h5 {
  font-size: 12px !important;
  line-height: 1.25 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.app-container {
  height: 100vh !important;
}

.app-toolbar {
  height: 128px !important;
}

app-inbox {
  position: relative;
  display: block;
  height: calc(100% - 65px);
}

.mat-expansion-panel-content {
  flex-direction: unset !important;
  overflow: auto !important;
  overflow-y: overlay !important;
  max-height: calc(100% - 64px) !important;
}
.mat-expansion-panel-content .mat-list-icon {
  font-size: 24px !important;
}

.firmado-conforme .mat-icon:after {
  content: "\f058";
  position: absolute;
  transform: scale(0.7);
  right: 24px;
}

.firmado-no-conforme .mat-icon:after {
  content: "\f057";
  position: absolute;
  transform: scale(0.7);
  right: 24px;
}

.mat-list {
  padding: 0;
  max-height: 100%;
}
.mat-list .mat-list-item {
  height: auto !important;
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.mat-list .mat-list-item .mat-list-item-content {
  padding: 16px !important;
  box-sizing: content-box;
}
.mat-list .mat-list-item .mat-list-item-content .mat-list-avatar {
  height: 40px;
}
.mat-list .mat-list-item .floating-actions {
  position: absolute;
  right: 16px;
  visibility: hidden;
  transform: visivility 0.2s ease;
}
.mat-list .mat-list-item .floating-actions .mat-icon {
  background: unset !important;
  color: unset !important;
  border: unset !important;
}
.mat-list .mat-list-item.finished-process-inbox .mat-line {
  white-space: normal;
}
.mat-list .mat-list-item.finished-process-inbox.active .mat-line {
  white-space: normal;
}
.mat-list .mat-list-item.finished-process-inbox.active .mat-line b {
  color: unset !important;
}
.mat-list .mat-list-item.finished-process-inbox.active .mat-icon {
  background: none;
  border: none;
  color: unset !important;
}
.mat-list .mat-list-item.finished-process-inbox .mat-icon {
  border: none;
}
.mat-list .mat-list-item.erased-process-inbox .mat-line {
  white-space: normal;
}
.mat-list .mat-list-item.erased-process-inbox.active .mat-line {
  white-space: normal;
}
.mat-list .mat-list-item.erased-process-inbox.active .mat-line b {
  color: unset !important;
}
.mat-list .mat-list-item.erased-process-inbox.active .mat-icon {
  background: none;
  border: none;
  color: unset !important;
}
.mat-list .mat-list-item.erased-process-inbox .mat-icon {
  border: none;
}
.mat-list .mat-list-item.in-process-inbox .mat-line {
  white-space: normal;
}
.mat-list .mat-list-item.in-process-inbox.active .mat-line {
  white-space: normal;
}
.mat-list .mat-list-item.in-process-inbox.active .mat-line b {
  color: unset !important;
}
.mat-list .mat-list-item.in-process-inbox.active .mat-icon {
  background: none;
  border: none;
  color: unset !important;
}
.mat-list .mat-list-item.in-process-inbox .mat-icon {
  border: none;
}
.mat-list .mat-list-item.limbo-process-inbox .mat-line {
  white-space: normal;
}
.mat-list .mat-list-item.limbo-process-inbox.active .mat-line {
  white-space: normal;
}
.mat-list .mat-list-item.limbo-process-inbox.active .mat-line b {
  color: unset !important;
}
.mat-list .mat-list-item.limbo-process-inbox.active .mat-icon {
  background: none;
  border: none;
  color: unset !important;
}
.mat-list .mat-list-item.limbo-process-inbox .mat-icon {
  border: none;
}
.mat-list .mat-list-item.error-process-inbox .mat-line {
  white-space: normal;
}
.mat-list .mat-list-item.error-process-inbox.active .mat-line {
  white-space: normal;
}
.mat-list .mat-list-item.error-process-inbox.active .mat-line b {
  color: unset !important;
}
.mat-list .mat-list-item.error-process-inbox.active .mat-icon {
  background: none;
  border: none;
  color: unset !important;
}
.mat-list .mat-list-item.error-process-inbox .mat-icon {
  border: none;
}
.mat-list .mat-list-item.active {
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.mat-list .mat-list-item:hover .floating-actions {
  visibility: visible;
  transform: visivility 0.2s ease;
}
.mat-list .mat-list-item:hover:not(.active) {
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}

span.mat-badge-content {
  height: 15px !important;
  width: 15px !important;
}

.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -4px !important;
}

.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -4px !important;
}

.mat-drawer-content {
  overflow: hidden !important;
}

mat-expansion-panel {
  box-shadow: none !important;
  margin: 0 !important;
  border-radius: 0 !important;
  position: relative;
}
mat-expansion-panel.virtual-scrolling .mat-expansion-panel-content {
  flex-direction: unset !important;
  overflow: unset !important;
  overflow-y: unset !important;
  height: 100%;
}
mat-expansion-panel.virtual-scrolling .mat-expansion-panel-content .get-more {
  width: calc(100% - 64px);
  position: absolute;
  bottom: 16px;
  left: 32px;
  z-index: 1;
}
mat-expansion-panel.virtual-scrolling .mat-expansion-panel-content app-cs-paginator.get-more {
  padding: 0 20px 16px;
  box-sizing: border-box;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  width: 100% !important;
  left: 0 !important;
  bottom: 0 !important;
  min-height: 80px;
}
mat-expansion-panel.virtual-scrolling .mat-expansion-panel-content app-cs-paginator.get-more .mat-form-field {
  padding: 0 !important;
}
mat-expansion-panel.virtual-scrolling .mat-expansion-panel-content app-cs-paginator.get-more .mat-paginator-range-actions {
  position: relative;
}
mat-expansion-panel.virtual-scrolling .mat-expansion-panel-content app-cs-paginator.get-more .mat-paginator-range-actions .mat-paginator-range-label {
  width: 100%;
  position: absolute;
  text-align: center;
  right: 0;
  margin: 0 !important;
  top: 100%;
}
mat-expansion-panel.virtual-scrolling .mat-expansion-panel-content mat-action-list.paginated-list {
  height: 100%;
  overflow: auto !important;
  overflow-y: overlay !important;
  box-sizing: border-box;
}
mat-expansion-panel.mat-expanded {
  height: calc(100% - 48px - 48px - 64px);
}

mat-expansion-panel-header {
  position: relative;
  padding: 0 16px !important;
}
mat-expansion-panel-header mat-divider {
  bottom: 0;
}
mat-expansion-panel-header span.mat-expansion-indicator {
  width: 40px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  padding-bottom: 6px;
}

.mat-expansion-panel-body {
  padding: 0 !important;
  width: 100%;
  height: 100%;
}

.inbox-container {
  position: relative;
}

.inbox-container.employee mat-expansion-panel.mat-expanded {
  height: calc(100% - 48px - 48px);
}

app-file-document-inbox-block-calendar mat-expansion-panel.mat-expanded {
  height: calc(100% - 48px - 48px - 65px) !important;
  box-sizing: border-box;
}
app-file-document-inbox-block-calendar mat-expansion-panel.mat-expanded.w-paginator {
  padding-bottom: 80px !important;
}

.view-employee-files-container {
  height: calc(100% - 65px) !important;
}

mat-action-list {
  padding: 0 !important;
  height: 100%;
}

.title h3,
.title h5 {
  margin: 0;
}

.two-options mat-expansion-panel.mat-expanded {
  height: calc(100% - 48px) !important;
}

.inbox-container.employee.my-documents .mat-list-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100% !important;
}
.inbox-container.employee.my-documents .mat-line.item-metadata {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  display: flex;
  flex: 1;
}

.bottom-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.load-more:hover .mat-line {
  font-weight: bold !important;
  transition: all 0.2s ease;
}
.load-more .mat-line {
  transition: all 0.2s ease;
}
.load-more mat-icon {
  background: none !important;
  border: none !important;
}

.mat-list-text {
  flex: 1;
}
.mat-list-text .mat-line b {
  white-space: break-spaces;
  line-break: strict;
}

.welcome-card .fit {
  width: 100%;
  height: 100%;
}
.welcome-card .img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 3;
}
.welcome-card .main-img {
  height: 35vh;
  width: 35vh;
  min-height: 150px;
  min-width: 150px;
  max-width: 328px;
  max-height: 328px;
  margin: 5vh;
  border-radius: 50%;
}
.welcome-card .text-container {
  padding: 0 5vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
}
.welcome-card .main-tittle {
  font-size: 2rem;
  text-align: center;
  font-weight: 700;
  margin-bottom: 2.5vh;
}
.welcome-card .main-text {
  font-size: 0.75rem;
  text-align: center;
  font-family: "roboto-mono", monospace;
  font-weight: 300;
  width: 70%;
}
.welcome-card .img-divider {
  height: 0.2rem;
  width: 70%;
  margin: 0 auto;
}
.welcome-card .main-link {
  font-size: 1rem;
  margin: 10px;
}

.new-table {
  min-height: calc(100% - 65px - 92px);
}

.document-table {
  height: calc(100% - 92px - 64px);
  overflow: auto;
}

.mat-table {
  height: 100%;
  width: 100%;
  overflow: auto;
}

.mat-header-row {
  min-height: 56px;
  width: 100%;
  z-index: 100 !important;
}

.mat-row {
  min-height: 40px;
  width: 100%;
  position: relative;
  cursor: default;
  transition: background 0.2s ease;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-drag: none;
}
.mat-row.active, .mat-row.active:hover {
  transition: background 0.2s ease;
}
.mat-row:nth-child(odd) {
  transition: background 0.2s ease;
}
.mat-row.selected {
  transition: background 0.2s ease;
}
.mat-row.selected.active, .mat-row.selected.active:hover {
  transition: background 0.2s ease;
}
.mat-row:hover {
  transition: background 0.2s ease;
}
.mat-row:focus, .mat-row:focus:hover, .mat-row:focus:hover .sticky-actions {
  outline: none;
}

.mat-header-cell {
  padding: 0;
  box-sizing: border-box;
}

.mat-header-cell {
  text-align: left;
}

.mat-table .sticky {
  top: 0px;
  position: sticky;
  z-index: 10;
}

.mat-table .sticky-actions {
  right: 0px;
  position: absolute;
  z-index: 99;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  padding: 0 16px;
}
.mat-table .sticky-actions button.mat-icon-button {
  height: 32px !important;
  width: 32px !important;
  line-height: 32px !important;
}

.mat-row:hover > .sticky-actions {
  visibility: visible;
  opacity: 1;
}

.w-12 {
  height: 100%;
  width: 100%;
  min-width: 100%;
}

.mat-cell {
  padding-top: 6px;
  padding-bottom: 6px;
}

span.center {
  text-align: center;
}

.mat-cell.w-1 + .w-12,
.mat-header-cell.w-1 + .w-12 {
  height: 100%;
  width: calc(100% - 72px);
  min-width: calc(100% - 72px);
}

.mat-cell.w-1 + .mat-cell.w-1.icon + .w-12,
.mat-header-cell.w-1 + .mat-header-cell.w-1.icon + .w-12 {
  height: 100%;
  width: calc(100% - 72px - 40px);
  min-width: calc(100% - 72px - 40px);
}

.mat-cell.w-1 + .mat-cell.w-1.icon + .mat-cell.w-1.icon + .w-12,
.mat-header-cell.w-1 + .mat-header-cell.w-1.icon + .mat-header-cell.w-1.icon + .w-12 {
  width: calc(100% - 72px - 40px - 40px);
  min-width: calc(100% - 72px - 40px - 40px);
}

.mat-cell.w-1 + .mat-cell.w-1.icon + .mat-cell.w-1.icon.double + .w-12,
.mat-header-cell.w-1 + .mat-header-cell.w-1.icon + .mat-header-cell.w-1.icon.double + .w-12 {
  width: calc(100% - 72px - 40px - 80px);
  min-width: calc(100% - 72px - 40px - 80px);
}

.mat-cell.w-1.icon.double,
.mat-header-cell.w-1.icon.double {
  width: 80px;
  min-width: 80px;
}

.mat-cell.w-1-5,
.mat-header-cell.w-1-5 {
  height: 100%;
  width: 12.5%;
  min-width: 12.5%;
  padding-left: 24px;
  padding-right: 12px;
  position: relative;
}

.mat-cell.w-1-5.icon,
.mat-header-cell.w-1-5.icon {
  height: 100%;
  width: 12.5%;
  min-width: 12.5%;
}

.w-1 {
  height: 100%;
  width: 72px;
  min-width: 72px;
}

.w-0-8 {
  height: 100%;
  width: 35px;
  min-width: 35px;
}

.w-1.icon {
  height: 100%;
  width: 40px;
  min-width: 40px;
}

.w-1.icon .mat-icon {
  padding: 8px !important;
}

.w-2 {
  height: 100%;
  width: 16.6666666667%;
  min-width: 16.6666666667%;
  padding-left: 24px;
  padding-right: 12px;
  position: relative;
}

.w-24 {
  height: 100%;
  width: 20%;
  min-width: 20%;
  padding-left: 24px;
  padding-right: 12px;
  position: relative;
}

.w-3 {
  height: 100%;
  width: 25%;
  min-width: 25%;
  padding-left: 24px;
  padding-right: 12px;
  position: relative;
}

.w-4 {
  height: 100%;
  width: 33.3333333333%;
  min-width: 33.3333333333%;
  padding-left: 24px !important;
  padding-right: 12px !important;
  position: relative;
}

.w-5 {
  height: 100%;
  width: 41.6666666667%;
  min-width: 41.6666666667%;
  padding-left: 24px !important;
  padding-right: 12px !important;
  position: relative;
}

.w-6 {
  height: 100%;
  width: 50%;
  min-width: 50%;
  padding-left: 24px !important;
  padding-right: 12px !important;
  position: relative;
}

.w-7 {
  height: 100%;
  width: 58.3333333333%;
  min-width: 58.3333333333%;
  padding-left: 24px !important;
  padding-right: 12px !important;
  position: relative;
}

.w-8 {
  height: 100%;
  width: 66.6666666667%;
  min-width: 66.6666666667%;
  padding-left: 24px !important;
  padding-right: 12px !important;
  position: relative;
}

.mat-table span {
  text-overflow: ellipsis;
}
.mat-table span:not(.mat-checkbox-inner-container) {
  overflow: hidden;
}

.mat-table .sort-icon {
  position: absolute;
  left: 0;
}

.mat-table .sort-icon .mat-icon {
  font-size: 15px !important;
  min-width: unset;
}

.mat-table .sorteable {
  cursor: pointer;
}

.mat-row:focus {
  outline: none;
}

.mat-row:focus:hover {
  outline: none;
}

.mat-row:focus:hover .sticky-actions {
  outline: none;
}

app-process-state-history-dialog .status-table {
  min-width: 35vw;
  max-height: 50vw;
  overflow: auto;
}

app-process-state-history-dialog .status-table .w-12 .mat-header-cell,
app-process-state-history-dialog .status-table .w-12 .mat-cell {
  padding-left: 16px !important;
}

.status-table {
  min-width: 800px;
  height: calc(100% - 56px - 65px);
}

.status-table .mat-table {
  height: 100%;
}

.status-table * {
  font-size: 12px;
}

.status-table span {
  line-height: 22px;
}

.status-table .mat-header-row {
  min-height: 56px;
  width: 100%;
  z-index: 100 !important;
}

.status-table .mat-row {
  height: 56px;
  min-height: 56px;
  width: 100%;
  position: relative;
  cursor: default;
}

.mat-cell.w-1 {
  position: relative;
}

td.mat-cell,
td.mat-footer-cell,
th.mat-header-cell {
  padding: 0 16px !important;
}

.status-table .w-1 {
  height: 100%;
  width: 72px;
  min-width: 72px;
}

.status-table .w-2 {
  height: 100%;
  width: 16.6666666667%;
  min-width: 16.6666666667%;
  padding-left: 24px;
  padding-right: 12px;
  position: relative;
}

.status-table .w-3 {
  height: 100%;
  width: 25%;
  min-width: 25%;
  padding-left: 24px;
  padding-right: 12px;
  position: relative;
}

.status-table .w-4 {
  height: 100%;
  width: 33.3333333333%;
  min-width: 33.3333333333%;
  padding-left: 24px;
  padding-right: 12px;
  position: relative;
}

.status-table .mat-header-cell:not(.w-1) {
  text-align: left;
  box-sizing: border-box;
  padding: 0 16px 0 0 !important;
}

.status-table .mat-cell:not(.w-1) {
  text-align: left;
  box-sizing: border-box;
  padding: 0 16px 0 0 !important;
}

.mat-cell.w-1 ngx-avatar {
  position: absolute;
  top: calc(50% - 15px);
  left: calc(50% - 15px);
  cursor: pointer;
}
.mat-cell.w-1 mat-checkbox {
  position: absolute;
  left: calc(50% - 8px);
}
.mat-cell.off mat-checkbox {
  visibility: hidden;
}
.mat-cell.off ngx-avatar {
  visibility: visible;
  opacity: 1;
  backface-visibility: hidden;
  transform: rotateY(0deg);
  transition: transform 0.2s ease;
}
.mat-cell.turn.off mat-checkbox {
  visibility: visible;
  transition: visibility 0.2s ease;
  transition-delay: 0.2s;
}
.mat-cell.turn.off ngx-avatar {
  visibility: hidden;
  opacity: 0;
  backface-visibility: visible;
  transform: rotateY(180deg);
  transition: all 0.2s ease;
}

.status-table .sticky {
  top: 0px;
  position: sticky;
  z-index: 10;
}

.documents-search-table {
  height: calc(100% - 92px - 64px) !important;
  min-height: calc(100% - 92px - 64px);
  overflow: auto;
}

.documents-search-table .mat-table {
  height: 100%;
  width: 100%;
}

.documents-search-table .mat-header-row {
  min-height: 56px;
  width: 100%;
  z-index: 100 !important;
}

.documents-search-table .mat-header-cell {
  text-align: left;
}

.documents-search-table .sticky {
  top: 0px;
  position: sticky;
  z-index: 10;
}

.documents-search-table .sticky-actions {
  right: 0px;
  position: absolute;
  z-index: 99;
  height: 100%;
  visibility: hidden;
  transition: all 0.2s ease;
  transition-delay: 0.05s;
  opacity: 0;
  padding: 0 16px;
}

.documents-search-table .mat-row:hover > .sticky-actions {
  visibility: visible;
  opacity: 1;
  transition: all 0.2s ease;
  transition-delay: 0.05s;
}

.documents-search-table .w-12 {
  height: 100%;
  width: 100%;
  min-width: 100%;
}

.documents-search-table .w-12.multiple-selection {
  height: 100%;
  width: calc(100% - 72px);
  min-width: calc(100% - 72px);
  padding: 0;
}

.documents-search-table .w-12.multiple-selection.icon {
  height: 100%;
  width: calc(100% - 72px - 40px);
  min-width: calc(100% - 72px - 40px);
  padding: 0;
}

.documents-search-table .w-12.multiple-selection.dynamic-table {
  height: 100%;
  width: calc(100% - 216px);
  min-width: calc(100% - 216px);
  padding: 0;
}

.documents-search-table .w-1 {
  height: 100%;
  width: 72px;
  min-width: 72px;
}

.documents-search-table .w-12 .w-1 {
  height: 100%;
  width: 8.3333333333%;
  min-width: 8.3333333333%;
  padding-left: 24px;
  padding-right: 12px;
  position: relative;
}

.documents-search-table .w-12 .w-24 {
  height: 100%;
  width: 20%;
  min-width: 20%;
  padding-left: 24px;
  padding-right: 12px;
  position: relative;
}

.documents-search-table .w-1.icon {
  height: 100%;
  width: 40px;
  min-width: 40px;
}

.documents-search-table .w-1.icon .mat-icon {
  padding: 8px !important;
}

.documents-search-table .w-2 {
  height: 100%;
  width: 16.6666666667%;
  min-width: 16.6666666667%;
  padding-left: 24px;
  padding-right: 12px;
  position: relative;
}

.documents-search-table .w-3 {
  height: 100%;
  width: 25%;
  min-width: 25%;
  padding-left: 24px;
  padding-right: 12px;
  position: relative;
}

.documents-search-table span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.documents-search-table .sort-icon {
  position: absolute;
  left: 0;
}

.documents-search-table .sort-icon .mat-icon {
  font-size: 15px !important;
}

.documents-search-table .sorteable {
  cursor: pointer;
}

.dynamic_fields-scroller {
  height: 25vh;
  overflow: auto;
  overflow-y: overlay;
}

.employee-search-container {
  transition: min-width 0.2s ease;
  width: 100% !important;
  min-width: 100% !important;
  position: relative;
}

.main.w-detail {
  width: 70% !important;
  min-width: 70% !important;
  transition: min-width 0.2s ease;
}

mat-toolbar.employee-search-sub-toolbar {
  min-height: 92px;
  position: relative;
}

mat-toolbar.employee-search-sub-toolbar .mat-toolbar-row {
  height: 100%;
}

.mat-search-field {
  border-radius: 20px;
  width: 30vw;
  min-width: 384px;
  padding-left: 8px;
  z-index: 1;
}

.mat-search-field.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.mat-search-field.disabled * {
  cursor: not-allowed;
}

.mat-search-field input[type=search] {
  border: none;
  outline: none;
  line-height: 30px;
  font-size: 16px;
  width: calc(100% - 72px);
}

.mat-search-field input[type=search]::placeholder {
  border: none;
  line-height: 30px;
  font-size: 16px;
}

.mat-search-field .search-caret {
  font-size: 15px !important;
}

.mat-paginator {
  background: none !important;
}

mat-toolbar.employee-search-sub-toolbar app-cs-paginator .mat-paginator-container {
  padding: 0 !important;
}

mat-toolbar.employee-search-sub-toolbar app-cs-paginator .mat-paginator-range-label {
  margin: 0 !important;
}

.search-adv-menu {
  border-radius: 20px !important;
  width: 30vw !important;
  min-width: 384px !important;
  margin-top: -1px;
  margin-right: -1px;
  transform-origin: center top !important;
}

.search-adv-menu .mat-menu-content {
  padding-top: 0 !important;
}

.search-adv-menu .mat-search-field {
  height: 42px;
  background: none;
  border-radius: 0;
  border: unset;
  padding-left: 8px;
}

.search-adv-menu .mat-search-field mat-icon {
  padding: 8px !important;
}

.search-adv-menu .mat-search-field input {
  padding-right: 16px;
}

.search-adv-menu h4 {
  margin-top: 8px !important;
}
.search-adv-menu h4 i {
  font-style: normal;
  font-size: 12px;
  font-weight: normal;
}

.search-adv-menu .adv-menu-content {
  padding: 8px 56px 16px;
}

.search-adv-menu .slide-group-container {
  padding-bottom: 16px;
  position: relative;
}

.search-adv-menu .adv-menu-footer {
  padding: 8px 46px 8px;
}

.document-search-container {
  transition: min-width 0.2s ease;
  width: 100% !important;
  min-width: 100% !important;
}

.main.w-detail {
  width: 70% !important;
  min-width: 70% !important;
  transition: min-width 0.2s ease;
}

.document-search-sub-toolbar {
  min-height: 92px;
  position: relative;
  padding: 0 24px;
}

.document-search-sub-toolbar .mat-toolbar-row {
  height: 100%;
}

.document-search-sub-toolbar app-cs-paginator .mat-paginator-container {
  padding: 0 !important;
}

.leave {
  height: 0;
  opacity: 0;
  transition: all 0.2s ease;
}

.enter {
  height: 66px;
  opacity: 1;
  transition: all 0.2s ease;
}

app-advanced-employee-search {
  display: block;
}
app-advanced-employee-search .search-adv-menu .mat-menu-content {
  padding-top: 0 !important;
  height: 70vh;
  padding: 0 !important;
}
app-advanced-employee-search .adv-menu-content.input-content {
  padding: 16px 46px;
  overflow-y: auto;
  height: auto;
  max-height: calc(100% - 52px - 42px);
}
app-advanced-employee-search .filter-line {
  height: 64px;
}

.dynamic_fields-selectable {
  min-height: 56px;
  padding: 8px 0;
}

.dynamic_fields-description {
  font-size: 12px;
}

.side-nav {
  width: 72px;
  border: none;
  z-index: -1 !important;
  overflow-y: unset !important;
  transition: width 0.2s ease;
}
.side-nav .side-trigger {
  position: absolute !important;
  bottom: -20px;
  left: 16px;
  z-index: 6;
}
.side-nav .menu-trigger {
  position: absolute !important;
  top: 22px;
  left: 13px;
  width: 50px !important;
  height: 50px !important;
  z-index: 6;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background: none !important;
}
.side-nav .menu-trigger mat-icon {
  font-size: 54px !important;
  height: 50px;
  width: 50px;
  border-radius: 25px;
}
.side-nav .menu-trigger .mat-button-wrapper {
  padding: 0 !important;
}
.side-nav .side-header {
  height: 128px;
  position: relative;
}
.side-nav .side-header .logo {
  display: none;
}
.side-nav .filter {
  position: absolute;
  width: 100%;
  height: calc(100% + 2px);
}
.side-nav .side-nav-container {
  height: calc(100% - 129px);
  position: relative;
}
.side-nav .side-nav-container .actions {
  padding: 36px 8px 16px;
  padding-top: 36px;
}
.side-nav .side-nav-container .actions button {
  width: min-content !important;
  border-radius: 30px;
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.side-nav .side-nav-container .actions button mat-icon {
  margin: 0 8px;
}
.side-nav .side-nav-container .actions button .mat-button-wrapper {
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
}
.side-nav .side-nav-container .actions button .mat-button-wrapper span {
  width: 0;
  overflow: hidden;
}
.side-nav .side-nav-container .content {
  width: 100%;
  padding: 48px 0 16px;
  height: 100%;
}
.side-nav .side-nav-container .actions + .content {
  padding: 0 0 16px;
  height: calc(100% - 108px);
}
.side-nav .side-nav-container .tools {
  box-sizing: border-box;
  padding-top: 0;
}
.side-nav .side-nav-container .links {
  box-sizing: border-box;
  padding-top: 0;
}
.side-nav .side-nav-container .links mat-icon span.mat-badge-content {
  height: 16px !important;
  width: unset !important;
  min-width: 16px;
  line-height: 16px !important;
  border-radius: 8px;
  right: 0px !important;
  left: unset !important;
  top: 5px !important;
  box-sizing: border-box;
  padding: 0.5px;
}
.side-nav .side-nav-container .actions + .content .tools.links-3 {
  max-height: calc(100% - 144px);
}
.side-nav .side-nav-container .actions + .content .tools.links-4 {
  max-height: calc(100% - 193px);
}
.side-nav .side-nav-container .actions + .content .tools.links-5 {
  max-height: calc(100% - 241px);
}
.side-nav .side-nav-container .actions + .content .tools mat-nav-list.mat-nav-list.mat-list-base {
  padding: 0 0 0 8px;
  height: 100%;
  overflow-y: scroll;
}
.side-nav .side-nav-container .tools.links-3 {
  height: calc(100% - 144px);
}
.side-nav .side-nav-container .tools.links-4 {
  height: calc(100% - 193px);
}
.side-nav .side-nav-container .tools.links-5 {
  height: calc(100% - 241px);
}
.side-nav .side-nav-container .mat-list-base {
  padding: 0 8px;
}
.side-nav .side-nav-container .mat-list-base .mat-subheader {
  height: 0;
  padding: 0;
  overflow: hidden;
}
.side-nav .side-nav-container .mat-list-base .mat-list-item {
  height: 48px;
  width: 48px;
  margin: 0 auto;
  background: none !important;
  border-radius: 24px;
  overflow: hidden;
}
.side-nav .side-nav-container .mat-list-base .mat-list-item .mat-list-item-content {
  padding: 0 !important;
  box-sizing: content-box;
  justify-content: center;
}
.side-nav .side-nav-container .mat-list-base .mat-list-item .mat-list-text {
  padding: 0 !important;
  width: 0;
  max-width: 0;
}
.side-nav .side-nav-container .mat-list-base .mat-list-item:hover {
  transition: background 0.2s ease;
}
.side-nav .side-nav-container .mat-list-base .mat-list-item.active {
  position: sticky;
  top: 0;
  bottom: 0;
  z-index: 1;
  transition: background 0.2s ease;
  margin: 4px auto;
}
.side-nav .side-nav-container .mat-list-base .mat-list-item.active * {
  font-weight: bold !important;
}
.side-nav .side-nav-container .mat-list-base .mat-list-item.active .mat-list-item.active:hover {
  transition: background 0.2s ease;
  margin: 4px auto;
}
.side-nav .side-nav-container .mat-list-base .mat-list-item.active .mat-list-item.active:hover * {
  font-weight: bold !important;
}
.side-nav .side-nav-container .mat-list-base .version {
  height: 0;
}
.side-nav .side-nav-container .mat-list-base .mat-nav-list {
  padding-top: 0;
}

.side-nav.open {
  width: 384px;
  transition: width 0.2s ease;
  z-index: 1;
}
.side-nav.open .side-header img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: relative;
}
.side-nav.open .side-trigger {
  right: 18px;
  left: unset;
}
.side-nav.open .filter {
  height: 100%;
}
.side-nav.open .side-nav-container .actions button .mat-button-wrapper span {
  margin-right: 20px;
  width: auto;
}
.side-nav.open .side-nav-container .mat-list-base h3.mat-subheader {
  height: 48px !important;
  padding: 16px !important;
  overflow: visible !important;
}
.side-nav.open .side-nav-container .mat-list-base .mat-list-item {
  width: 100% !important;
}
.side-nav.open .side-nav-container .mat-list-base .mat-list-item .mat-list-item-content {
  justify-content: flex-start !important;
  padding: 0 8px !important;
}
.side-nav.open .side-nav-container .mat-list-base .mat-list-item .mat-list-text {
  padding: 8px !important;
  width: 100% !important;
  max-width: unset;
}
.side-nav.open .side-nav-container .mat-list-base .mat-list-item.active {
  position: sticky;
  top: 0;
  bottom: 0;
  z-index: 1;
}
.side-nav.open .side-nav-container .actions + .content .tools {
  padding-top: 44px;
  padding-right: 0 !important;
}
.side-nav.open .side-nav-container .actions + .content .tools.links-3 {
  max-height: calc(100% - 192px);
}
.side-nav.open .side-nav-container .actions + .content .tools.links-4 {
  max-height: calc(100% - 241px);
}
.side-nav.open .side-nav-container .actions + .content .tools.links-5 {
  max-height: calc(100% - 289px);
}
.side-nav.open .side-nav-container .actions + .content .tools mat-nav-list.mat-nav-list.mat-list-base {
  padding: 0 8px;
  height: 100%;
  overflow-y: scroll;
}
.side-nav.open .side-nav-container .tools {
  padding-right: 10px;
}
.side-nav.open .side-nav-container .tools.links-3 {
  height: calc(100% - 192px);
}
.side-nav.open .side-nav-container .tools.links-4 {
  height: calc(100% - 241px);
}
.side-nav.open .side-nav-container .tools.links-5 {
  height: calc(100% - 289px);
}
.side-nav.open .side-nav-container .links {
  padding-right: 10px;
}
.side-nav.open .side-nav-container .version {
  height: 48px;
}
.side-nav.open .side-nav-container .actions {
  padding: 0 !important;
  position: absolute;
  top: -28px;
  z-index: 2 !important;
  left: 8px;
}
.side-nav.open .side-nav-container .content {
  padding: 0 !important;
  height: calc(100% - 49px);
}
.side-nav.open .side-nav-container .actions + .content {
  width: 100%;
  padding-top: 36px;
  height: calc(100% - 49px);
}

.side-nav.enter .side-nav-container .actions button .mat-button-wrapper span {
  margin-right: 20px;
  width: auto;
}
.side-nav.enter .side-nav-container .mat-list-base h3.mat-subheader {
  height: 48px !important;
  padding: 16px !important;
  overflow: visible !important;
}
.side-nav.enter .side-nav-container .mat-list-base .mat-list-item {
  width: 100% !important;
}
.side-nav.enter .side-nav-container .mat-list-base .mat-list-item .mat-list-item-content {
  justify-content: flex-start !important;
  padding: 0 8px !important;
}
.side-nav.enter .side-nav-container .mat-list-base .mat-list-item .mat-list-text {
  padding: 8px !important;
  width: 100% !important;
}
.side-nav.enter .side-nav-container .mat-list-base .mat-list-item.active {
  position: sticky;
  top: 0;
  bottom: 0;
}
.side-nav.enter .side-nav-container .actions + .content .tools {
  padding-top: 44px;
  padding-right: 0 !important;
}
.side-nav.enter .side-nav-container .actions + .content .tools.links-3 {
  max-height: calc(100% - 192px);
}
.side-nav.enter .side-nav-container .actions + .content .tools.links-4 {
  max-height: calc(100% - 241px);
}
.side-nav.enter .side-nav-container .actions + .content .tools.links-5 {
  max-height: calc(100% - 289px);
}
.side-nav.enter .side-nav-container .actions + .content .tools mat-nav-list.mat-nav-list.mat-list-base {
  padding: 0 8px;
  height: 100%;
  overflow-y: scroll;
}
.side-nav.enter .side-nav-container .tools {
  padding-right: 10px;
}
.side-nav.enter .side-nav-container .tools.links-3 {
  height: calc(100% - 192px);
}
.side-nav.enter .side-nav-container .tools.links-4 {
  height: calc(100% - 241px);
}
.side-nav.enter .side-nav-container .tools.links-5 {
  height: calc(100% - 289px);
}
.side-nav.enter .side-nav-container .links {
  padding-right: 10px;
}
.side-nav.enter .side-nav-container .version {
  height: 48px;
}
.side-nav.enter .side-nav-container .actions {
  padding: 0 !important;
  position: absolute;
  top: -28px;
  z-index: 2 !important;
  left: 8px;
}
.side-nav.enter .side-nav-container .content {
  padding: 0 !important;
  height: calc(100% - 49px);
}
.side-nav.enter .side-nav-container .actions + .content {
  width: 100%;
  padding-top: 36px;
  height: calc(100% - 49px);
}

.mat-menu-panel:not(.metadata-radio-menu) {
  max-width: unset !important;
  width: 384px;
}
.mat-menu-panel:not(.metadata-radio-menu).menu-no-width {
  width: unset !important;
  max-width: 70vw !important;
}
.mat-menu-panel:not(.metadata-radio-menu) .navigation-list {
  padding: 0 !important;
}
.mat-menu-panel:not(.metadata-radio-menu) .navigation-list .mat-list-item-content {
  font-size: 15px;
}
.mat-menu-panel:not(.metadata-radio-menu) .navigation-list .mat-list-item-content mat-list-item {
  padding-left: 8px !important;
  box-sizing: border-box;
  height: 40px !important;
}

.profile-menu .toolbar-line {
  padding-left: 0;
}

thead.mat-calendar-table-header tr + tr {
  display: none !important;
}

thead.mat-calendar-table-header {
  line-height: 30px !important;
}

.mat-calendar-header {
  padding: 0 8px !important;
}

.mat-calendar-content {
  padding: 0 8px 8px !important;
  height: 277px;
}

.mat-datepicker-content .mat-calendar {
  height: auto !important;
}

mat-bottom-sheet-container {
  padding: 0 !important;
  overflow-y: hidden !important;
  min-width: unset !important;
}
mat-bottom-sheet-container mat-toolbar {
  margin: 0 !important;
  width: 100% !important;
}
mat-bottom-sheet-container mat-toolbar .toolbar-line {
  height: 100% !important;
}
mat-bottom-sheet-container mat-toolbar .toolbar-line .mat-chip-list-wrapper {
  flex-wrap: nowrap !important;
}
mat-bottom-sheet-container .add-menu-form-body {
  max-width: unset !important;
  height: calc(100% - 64px);
}
mat-bottom-sheet-container .add-menu-form-body.w-table {
  overflow-y: auto !important;
}

.mat-horizontal-stepper-content {
  position: relative;
}
.mat-horizontal-stepper-content .mat-search-field {
  width: 50%;
  min-width: unset;
}
.mat-horizontal-stepper-content .employee-search-sub-toolbar {
  min-height: unset !important;
  padding-top: 16px;
}

app-file-document-metadata {
  height: 100%;
  display: block;
}
app-file-document-metadata .mat-list-base .mat-list-item .mat-line {
  font-size: 14px !important;
}
app-file-document-metadata .mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 18px !important;
  font-weight: bold;
}

app-employee-rrhh-find mat-expansion-panel.mat-expanded {
  height: calc(100% - 148px - 64px - 50px) !important;
  max-height: calc(100% - 148px - 64px - 50px) !important;
}

app-user-security-detail {
  display: block;
  max-height: unset !important;
}

.new-detail-container {
  height: 100%;
}

.metadata-radio-menu.mat-menu-panel {
  padding: 8px 16px !important;
}

app-dialog {
  padding: 16px;
  display: block;
}

app-generic-bottom-sheet .bottom_sheet-content {
  padding: 0 16px 16px !important;
}
app-generic-bottom-sheet .bottom_sheet-content .bottom_sheet-title {
  padding: 16px 0 0;
}
app-generic-bottom-sheet .bottom_sheet-content.ok p {
  padding: 0 32px 32px;
}
app-generic-bottom-sheet .bottom_sheet-content.not-ok p {
  padding: 0 32px;
}
app-generic-bottom-sheet .bottom_sheet-content.cancel p {
  padding: 0 32px;
}
app-generic-bottom-sheet .bottom_sheet-content mat-form-field {
  padding: 0 32px;
}
app-generic-bottom-sheet h4.accent {
  margin: 0 !important;
}
app-generic-bottom-sheet h4.accent + p {
  margin-top: 0;
}
app-generic-bottom-sheet p {
  width: 30vw;
  min-width: 200px;
}
app-generic-bottom-sheet .verification-code {
  border-radius: 4px;
}
app-generic-bottom-sheet .verification-code h2 {
  letter-spacing: 16px;
  padding-left: 16px;
  line-height: 50px;
  border-radius: 4px;
  margin-left: 16px;
}
app-generic-bottom-sheet .verification-code span {
  width: 50%;
}
app-generic-bottom-sheet .verification-code button {
  margin-right: 16px !important;
}
app-generic-bottom-sheet input::-webkit-outer-spin-button,
app-generic-bottom-sheet input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
app-generic-bottom-sheet input[type=number] {
  -moz-appearance: textfield;
}
app-generic-bottom-sheet .load-window.message {
  position: relative;
  top: 0;
  padding: 16px;
}
app-generic-bottom-sheet .load-window.message .info-container {
  margin-left: 32px;
}
app-generic-bottom-sheet .load-window.message .info-container h3.primary-text {
  margin-top: 0;
}

.mat-list-base {
  padding-top: 0 !important;
}

.mat-list-base .mat-subheader:first-child {
  margin-top: 0 !important;
}

.mat-list-icon.mat-icon {
  padding: 8px !important;
}

.mat-list .mat-list-item.active {
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.mat-list .mat-list-item {
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.mat-list-item:hover:not(.active) {
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.mat-list .mat-list-item {
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  height: auto !important;
}

.mat-list-item .mat-list-item-content {
  padding: 16px !important;
  box-sizing: content-box;
}

.mat-list app-file-document-inbox-item .mat-list-item .mat-list-text {
  width: 100% !important;
}

.card-filter {
  height: fit-content;
  padding: 16px;
  border-radius: 4px;
  z-index: 20;
  max-width: 480px !important;
  width: 30% !important;
}

.card-filter h4 {
  margin: 0;
  line-height: 40px;
  padding-left: 20px;
}

.card-filter mat-form-field {
  padding: 0 56px;
}

.card-filter .filter-footer {
  padding: 0 56px;
}

app-formio-cardinal {
  box-sizing: border-box;
  min-width: 40vw;
  place-content: stretch center;
  align-items: stretch;
  max-height: 100%;
}

.formio-content {
  height: 100%;
}

.autocomplelogin {
  max-height: 340px !important;
  position: absolute !important;
}

.login .login-bg {
  position: absolute;
  height: 100vh;
  width: 100vw;
  object-fit: cover;
}
.login mat-horizontal-stepper {
  width: 100% !important;
  min-width: 100% !important;
}
.login .mat-horizontal-content-container {
  padding: 0 !important;
}
.login .login-card {
  max-height: calc(100vh - 56px);
  overflow-x: hidden;
  overflow-y: auto;
}
.login .login-card .mat-card-header {
  margin-bottom: 8px !important;
}
.login .login-card .mat-card-header-text {
  width: 100%;
}
.login .login-card .brand img {
  margin: 16px 0;
  width: 80%;
}
.login .login-card .description {
  font-size: 0.7rem;
  text-align: center;
}
.login .login-card .mat-progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
}
.login .login-card .mat-checkbox-label {
  white-space: normal !important;
}
.login .login-card .mat-card-content {
  padding: 0 16px 16px !important;
  margin-bottom: 0 !important;
}
.login .login-card .mat-card-content h3 {
  margin-bottom: 32px;
  margin-top: 0;
}
.login .login-card .mat-card-content h3 + h5 {
  margin-top: -24px;
  margin-bottom: 16px;
  font-weight: normal;
}
.login .login-card .mat-card-content h5 {
  margin-top: 0;
  margin-bottom: 16px;
  font-weight: normal;
}
.login .login-card .mat-card-content h2 {
  margin: 0;
}
.login .login-card .mat-card-content h2 + h5 {
  margin-top: 16px;
}
.login .login-card .mat-card-content .mat-card-footer {
  margin: 16px 0 0 0 !important;
  min-height: 36px;
}
.login .tyc-container {
  max-height: calc(90vh - 394px);
  overflow: auto;
  margin-bottom: 16px;
}
.login .otp-qr img {
  height: 35vh;
  width: 35vh;
  margin: 0 auto;
}
.login .otp-qr + .qr-not-loaded {
  display: none !important;
}
.login .otp-help img {
  height: 25vh;
  width: 25vh;
  margin: 0 auto;
}
.login .otp-help img.x2 {
  height: 35vh;
  width: 35vh;
  margin: 0 auto;
}
.login .help-stepper .align-16 {
  padding: 0 16px;
}
.login .help-stepper h5.align-16 {
  font-size: 15px;
}
.login .help-stepper .help-title {
  margin-bottom: 16px;
}
.login .help-stepper .help-title h3 {
  margin: 0 !important;
}
.login .humanage-icon {
  background: white;
  position: fixed;
  bottom: 5px;
  right: 0;
  max-width: 17vw;
  background-color: transparent;
  filter: drop-shadow(0 0 1.2px rgba(255, 255, 255, 0.8));
}

.ux-modal {
  height: 100vh;
  width: 100vw;
  min-width: 100vw;
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 20;
}

.ux-modal .card {
  width: 100%;
  max-width: 350px;
  border-radius: 0.3rem;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
}

.modal-content {
  border: none !important;
}

.main-title {
  font-size: 1.8rem;
  font-weight: 300;
  text-align: center;
  margin-bottom: 1rem;
  line-height: 2rem;
  padding: 1rem;
}

.sub-title {
  font-size: 1.1rem;
  font-weight: 300;
  text-align: center;
  margin-bottom: 1rem;
  padding: 0 1rem;
}

.cad-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 1rem;
}

.icon {
  height: 4rem;
  width: 4rem;
  border-radius: 4rem;
  opacity: 0.8;
  transition: opacity ease 0.2s;
}

.icon:hover {
  opacity: 1 !important;
}

.icon.big {
  height: 8rem;
  width: 8rem;
  border-radius: 4rem;
}

.icon.chr {
  background: url("../img/chrome.png") no-repeat center center !important;
  background-size: contain !important;
}

.icon.fir {
  background: url("../img/firefox.png") no-repeat center center !important;
  background-size: contain !important;
}

.icon.ope {
  background: url("../img/opera.png") no-repeat center center !important;
  background-size: contain !important;
}

.icon.edg {
  background: url("../img/edge.png") no-repeat center center !important;
  background-size: contain !important;
}

.icon.saf {
  background: url("../img/safari.png") no-repeat center center !important;
  background-size: contain !important;
}

.divider {
  width: 1rem;
}

.card-footer {
  padding: 1rem;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.card-footer a {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 0.9rem;
  opacity: 0.7;
  transition: opacity ease 0.2s;
  text-decoration: underline;
  cursor: pointer;
}

.card-footer a:hover {
  opacity: 1;
}

.card-footer a > .chevron {
  height: 1rem;
  width: 1rem;
  background: url("../img/chevron-down.png") no-repeat center center !important;
  background-size: contain !important;
  margin-left: 0.5rem;
}

.card-footer a.hide {
  display: none;
}

.card-footer a.show {
  display: flex;
}

.toggle {
  display: none !important;
}

.profile-menu {
  width: 25vw;
  max-width: 384px;
  z-index: 10;
}

.profile-toolbar {
  position: absolute;
  z-index: 1;
}

app-profile {
  position: relative;
  display: block;
  padding-top: 65px;
}
app-profile.no-Holograph-signature .signature-profile {
  display: none !important;
}
app-profile .profile-certificates .mat-list-item .mat-line {
  white-space: unset !important;
  overflow: unset !important;
  text-overflow: unset !important;
  margin-bottom: 4px !important;
}
app-profile mat-list {
  padding: 0;
}
app-profile mat-list .mat-list-item {
  background: none !important;
}
app-profile .scroller-user-certificates mat-list-item.mat-list-item,
app-profile .scroller-user-data mat-list-item.mat-list-item {
  min-height: 64px !important;
}
app-profile .logout-btn {
  cursor: pointer;
  transition: background 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}
app-profile .logout-btn:hover {
  transition: background 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}
app-profile .avatar-profile,
app-profile .signature-profile {
  margin: 16px;
  flex-wrap: wrap;
}
app-profile .avatar-profile img,
app-profile .signature-profile img {
  border-radius: 50px;
}
app-profile .avatar-profile img.user-avatar, app-profile .avatar-profile img.user-signature,
app-profile .signature-profile img.user-avatar,
app-profile .signature-profile img.user-signature {
  width: 100px;
}
app-profile .cropper {
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 10;
  height: 100vh;
}
app-profile .cropper .edition {
  height: calc(100% - 65px);
  padding-bottom: 16px;
}
app-profile .cropper .edition app-cs-upload-cropper-control {
  width: 100%;
}

app-add-documentation .employee-add-documentation .inbox-toolbar {
  padding: 0 16px;
}
app-add-documentation .employee-add-documentation .inbox-toolbar .toolbar-line {
  padding-left: 0 !important;
}
app-add-documentation .employee-add-documentation mat-divider.false-divider {
  width: calc(100% - 32px) !important;
  margin: -1px 16px 0 !important;
}
app-add-documentation .employee-add-documentation mat-tab-group {
  height: calc(100% - 64px);
}
app-add-documentation .employee-add-documentation mat-tab-group mat-tab-header {
  margin: 0 16px;
  border-bottom: none !important;
  padding-top: 8px;
}
app-add-documentation .employee-add-documentation mat-tab-group mat-tab-header .mat-tab-label-active {
  opacity: 1;
}
app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper {
  height: 100%;
}
app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body {
  height: 100%;
}
app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content {
  height: 100%;
}
app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container {
  height: 100%;
}
app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items {
  height: 100%;
  padding: 16px 9px 16px 16px;
  overflow: auto;
  overflow-y: overlay;
}
app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item {
  height: auto;
  display: block;
}
app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content {
  padding: 16px;
  height: 100%;
  box-sizing: border-box;
  cursor: pointer;
}
app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content.selected {
  cursor: default !important;
}
app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content .item-data {
  margin-left: 16px;
  width: calc(100% - 40px - 16px);
}
app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content .item-data b {
  font-size: 18px;
}
app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content .item-data span {
  font-size: 14px;
}
app-add-documentation .employee-add-documentation .detail app-file-document-view .mat-avatar .toolbar-line h5 {
  display: none;
}
app-add-documentation .employee-add-documentation .detail app-file-document-view .new-container app-file-document-upload app-upload-form {
  height: 50% !important;
}
app-add-documentation .employee-add-documentation .detail app-file-document-view .new-container app-file-document-upload .metadata-list {
  padding: 0 16px 16px;
  max-height: calc(50% - 97px);
  overflow: auto;
  position: relative;
  margin-top: 16px;
}
app-add-documentation .employee-add-documentation .detail app-file-document-view .new-container app-file-document-upload .metadata-list .mat-subheader {
  height: 48px;
  line-height: 16px;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 16px;
  margin-top: 0 !important;
  display: flex;
  box-sizing: border-box;
  padding: 16px;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1;
}
app-add-documentation .employee-add-documentation .detail app-file-document-view .new-container app-file-document-upload .metadata-list mat-form-field {
  width: 100%;
  padding: 0 16px;
  box-sizing: border-box;
}

app-pending .inbox-toolbar {
  padding: 0 16px;
  height: 64px;
}
app-pending .inbox-toolbar .toolbar-line {
  padding-left: 0 !important;
}
app-pending mat-divider.false-divider {
  width: calc(100% - 32px) !important;
  margin: -1px 16px 0 !important;
}
app-pending .pending-container {
  height: calc(100% - 64px);
}
app-pending .pending-container.visible .pending-items {
  padding-bottom: 112px;
}
app-pending .pending-container.visible .main-action {
  transition: bottom 0.2s ease-in;
}
app-pending .pending-container.visible .main-action.desktop {
  bottom: 0 !important;
}
app-pending .pending-container .pending-items {
  height: 100%;
  padding: 16px 9px 16px 16px;
  overflow: auto;
  overflow-y: overlay;
}
app-pending .pending-container .pending-items .pending-item {
  height: auto;
  display: block;
}
app-pending .pending-container .pending-items .pending-item .content {
  padding: 16px;
  height: 100%;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 0.28s cubic-bezier(0.4, 0, 0.2, 0.2) !important;
}
app-pending .pending-container .pending-items .pending-item .content.selected {
  cursor: default !important;
}
app-pending .pending-container .pending-items .pending-item .content .item-data {
  margin-left: 16px;
  width: calc(100% - 40px - 16px);
}
app-pending .pending-container .pending-items .pending-item .content .item-data b {
  font-size: 18px;
}
app-pending .pending-container .pending-items .pending-item .content .item-data span {
  font-size: 14px;
}
app-pending .pending-container .pending-items .pending-item .content mat-checkbox {
  width: 40px;
  display: flex;
  justify-content: center;
}
app-pending .pending-container .main-action {
  position: absolute;
  padding: 16px;
  width: calc(100% - 32px);
  margin: 0 16px;
  transition: bottom 0.2s ease-out;
}
app-pending .pending-container .main-action.desktop {
  bottom: -96px;
  transition: bottom 0.2s ease-out;
}
app-pending .pending-container .main-action.mobile {
  display: none !important;
  bottom: 0;
}
app-pending .pending-container .main-action .main-btn {
  height: 64px;
  border-radius: 32px;
}
app-pending .pending-container .main-action .main-btn .mat-button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  padding-right: 8px;
}
app-pending .pending-container .main-action .main-btn .mat-button-wrapper mat-icon.mat-icon {
  min-width: 40px;
  margin-right: 8px;
}

app-document-sign-massive .inbox-container .inbox-toolbar {
  padding: 0 16px;
  height: 64px;
}
app-document-sign-massive .inbox-container .inbox-toolbar .toolbar-line {
  padding-left: 0 !important;
}
app-document-sign-massive .inbox-container mat-divider.false-divider {
  width: calc(100% - 32px) !important;
  margin: -1px 16px 0 !important;
}
app-document-sign-massive .inbox-container .pending-container {
  height: calc(100% - 64px);
}
app-document-sign-massive .inbox-container .pending-container.visible .pending-items {
  padding-bottom: 112px;
}
app-document-sign-massive .inbox-container .pending-container .pending-items {
  height: 100%;
  padding: 16px 9px 16px 16px;
  overflow: auto;
  overflow-y: overlay;
}
app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item {
  height: auto;
  display: block;
}
app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content {
  padding: 16px;
  height: 100%;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 0.28s cubic-bezier(0.4, 0, 0.2, 0.2) !important;
}
app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content.selected {
  cursor: default !important;
}
app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content.selected button {
  pointer-events: none;
}
app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content .item-data {
  margin-left: 16px;
  width: calc(100% - 40px - 16px);
}
app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content .item-data b {
  font-size: 18px;
}
app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content .item-data span {
  font-size: 14px;
}
app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content mat-checkbox {
  width: 40px;
  display: flex;
  justify-content: center;
  pointer-events: none;
}
app-document-sign-massive .detail app-file-document-detail {
  display: block;
  height: calc(100% - 96px);
}
app-document-sign-massive .detail app-file-document-detail .view-container {
  display: block;
  height: 100%;
}
app-document-sign-massive .detail app-file-document-detail .view-container .mat-toolbar {
  border-bottom: none;
}
app-document-sign-massive .detail app-file-document-detail .view-container .mat-toolbar .view-description {
  padding-left: 16px;
}
app-document-sign-massive .detail app-file-document-detail .view-container .mat-toolbar .view-description mat-icon {
  display: none !important;
}
app-document-sign-massive .detail app-file-document-detail .view-container .view-content {
  display: block;
  height: calc(100% - 64px);
  box-sizing: border-box;
}
app-document-sign-massive .detail app-file-document-detail .view-container .view-content app-pdf-wrapper {
  width: 100%;
  display: block;
  height: 100%;
}
app-document-sign-massive .detail app-file-document-detail .view-container .view-content app-pdf-wrapper .pdf-view-container {
  padding: 16px 32px 0;
  box-sizing: border-box;
}
app-document-sign-massive .detail app-chapa {
  height: calc(100% - 96px);
}
app-document-sign-massive .detail app-chapa.minimized {
  position: absolute;
  z-index: 1000;
  height: 48px;
  bottom: 72px;
  width: unset;
  margin: 0 auto;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
app-document-sign-massive .detail app-chapa.minimized .info-empty {
  flex-direction: row !important;
  min-height: unset !important;
  padding: 0 24px 0 16px;
  border-radius: 24px;
  transition: all 0.3s ease-in;
}
app-document-sign-massive .detail app-chapa.minimized .info-empty > span {
  flex-direction: row !important;
}
app-document-sign-massive .detail app-chapa.minimized .info-empty > span mat-icon {
  font-size: 24px !important;
  width: 40px !important;
  margin: 0 8px 0 0 !important;
  background: unset !important;
  height: 48px !important;
}
app-document-sign-massive .detail app-chapa.minimized .info-empty > span h2 {
  font-size: 1.2rem;
}
app-document-sign-massive .detail app-chapa.minimized .info-empty > span h4 {
  display: none;
}
app-document-sign-massive .signer.desktop {
  align-items: center;
  padding: 16px 32px;
  height: 96px;
}
app-document-sign-massive .signer.mobile {
  display: none;
}
app-document-sign-massive .signer .signer-btn {
  height: 64px;
  min-height: 64px;
  border-radius: 40px !important;
  padding: 0 16px 0 32px !important;
  min-width: unset !important;
  background-color: #009688;
}
app-document-sign-massive .signer .signer-btn .mat-button-wrapper {
  display: flex;
  align-items: center;
}
app-document-sign-massive .signer .signer-btn .mat-button-wrapper span {
  padding: 0 20px;
  font-size: 20px;
}

app-leave .inbox-toolbar {
  padding: 0 16px;
  height: 64px;
}
app-leave .inbox-toolbar .toolbar-line {
  padding-left: 0 !important;
}
app-leave mat-divider.false-divider {
  width: calc(100% - 32px) !important;
  margin: -1px 16px 0 !important;
}
app-leave .leave_container {
  height: calc(100% - 64px);
}
app-leave .leave_container .leave_items {
  height: 100%;
  padding: 16px 9px 16px 16px;
  overflow: auto;
  overflow-y: overlay;
}
app-leave .leave_container .leave_items .leave_items-item {
  height: auto;
  display: block;
}
app-leave .leave_container .leave_items .leave_items-item .content {
  padding: 16px;
  height: 100%;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 0.28s cubic-bezier(0.4, 0, 0.2, 0.2) !important;
}
app-leave .leave_container .leave_items .leave_items-item .content.selected {
  cursor: default !important;
}
app-leave .leave_container .leave_items .leave_items-item .content .item-data {
  margin-left: 16px;
  width: calc(100% - 40px - 16px);
}
app-leave .leave_container .leave_items .leave_items-item .content .item-data b {
  font-size: 18px;
}
app-leave .leave_container .leave_items .leave_items-item .content .item-data span {
  font-size: 14px;
}
app-leave .leave_container .main-action {
  padding: 0 16px 16px;
}
app-leave .leave_container .main-action .main-btn {
  height: 64px;
  width: 100%;
  border-radius: 32px;
}
app-leave .leave_container .main-action .main-btn .mat-button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  padding-right: 8px;
}
app-leave .leave_container .main-action .main-btn .mat-button-wrapper mat-icon.mat-icon {
  min-width: 40px;
  margin-right: 8px;
}

app-leave-detail {
  height: 100%;
  display: flex;
  flex-direction: column;
}
app-leave-detail .leave_detail {
  height: 100%;
}
app-leave-detail .leave_detail .leave_detail-title {
  height: 64px;
  padding: 0 16px;
}
app-leave-detail .leave_detail .leave_detail-title .info h3,
app-leave-detail .leave_detail .leave_detail-title .info h5 {
  margin: 0;
}
app-leave-detail .leave_detail .leave_detail-title .info h3 {
  font-weight: 500;
}
app-leave-detail .leave_detail .leave_detail-title .info h5 {
  font-weight: 300;
}
app-leave-detail .leave_detail .leave_detail-title .info b {
  font-weight: 700;
}
app-leave-detail .leave_detail .leave_detail-container {
  height: calc(100% - 64px);
}
app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .large-icon {
  height: 120px !important;
  width: 120px !important;
  border-radius: 70px;
  font-size: 70px !important;
  margin-bottom: 16px;
}
app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning h2 {
  margin: 0;
  text-align: center;
}
app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning h4 {
  margin: 8px 0 0;
  text-align: center;
  max-width: 40vw;
  font-weight: 400;
}
app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning h4:before {
  content: '"';
  margin-right: 4px;
}
app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning h4:after {
  content: '"';
  margin-left: 4px;
}
app-leave-detail .leave_detail .leave_detail-loading {
  height: 100%;
}
app-leave-detail .main-action {
  padding: 0 16px 16px;
}
app-leave-detail .main-action .main-btn {
  height: 64px;
  width: 100%;
  border-radius: 32px;
}
app-leave-detail .main-action .main-btn .mat-button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  padding-right: 8px;
}
app-leave-detail .main-action .main-btn .mat-button-wrapper mat-icon.mat-icon {
  min-width: 40px;
  margin-right: 8px;
}

.new_leave_modal-toolbar {
  padding: 16px;
}
.new_leave_modal-toolbar h2,
.new_leave_modal-toolbar h4,
.new_leave_modal-toolbar h5,
.new_leave_modal-toolbar h6 {
  margin: 0;
}
.new_leave_modal-toolbar h4,
.new_leave_modal-toolbar h5,
.new_leave_modal-toolbar h6 {
  font-weight: normal;
}

.new_leave_modal-steps {
  display: unset !important;
}
.new_leave_modal-steps .mat-horizontal-content-container {
  padding: 0;
}
.new_leave_modal-steps .mat-horizontal-content-container form {
  padding-top: 16px;
}
.new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options {
  padding: 0 32px 32px;
}
.new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button {
  border-radius: 12px;
  padding: 16px 32px 16px 16px;
}
.new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button[disabled], .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button[disabled] * {
  cursor: not-allowed !important;
  opacity: 0.75;
}
.new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button .mat-radio-label {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}
.new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button .mat-radio-label .mat-radio-label-content {
  width: 100%;
  margin-right: 24px;
  padding: 0;
}
.new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button .mat-radio-label .mat-radio-label-content .main-text {
  margin: 0;
}
.new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button .mat-radio-label .mat-radio-label-content .side-text {
  margin: 0;
  font-size: 16px;
}
.new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button .mat-radio-label .mat-radio-label-content .side-text.muted {
  margin: 0;
  margin-top: 8px;
  font-size: 12px;
}
.new_leave_modal-steps .mat-horizontal-content-container form mat-form-field.new_leave_modal-field {
  padding: 0 32px;
  box-sizing: border-box;
}
.new_leave_modal-steps .mat-horizontal-content-container form mat-form-field.new_leave_modal-field .mat-form-field-infix {
  display: flex;
}
.new_leave_modal-steps .mat-horizontal-content-container form mat-form-field.new_leave_modal-field .mat-form-field-infix mat-datepicker-toggle {
  position: absolute;
  right: 0;
  bottom: 0.5em;
}
.new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-resume_line {
  padding: 0 32px;
}
.new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-resume_line .main {
  font-size: 20px;
}
.new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-resume_line .side {
  font-size: 12px;
}
.new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-actions {
  padding: 0 16px 16px;
}

.new_leave_modal-toogle {
  padding-left: 28px !important;
}

.new_leave_modal-loading {
  padding: 48px;
}

app-document-detail {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}
app-document-detail .window-spinner {
  position: absolute;
  top: 0;
  height: 100%;
  z-index: 10;
}
app-document-detail app-pdf-wrapper {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  display: flex;
  flex: 1;
  overflow: hidden;
}
app-document-detail app-pdf-wrapper .pdf-view-container {
  padding: 0;
  height: 100%;
  width: 100%;
  overflow: auto !important;
  overflow-y: overlay !important;
  transition: max-height 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}
app-document-detail app-sign-document .firmador button.mat-raised-button.mat-accent {
  height: 64px;
  border-radius: 40px !important;
  padding: 0 16px 0 32px !important;
  min-width: unset !important;
  pointer-events: all;
}
app-document-detail app-sign-document .firmador button.mat-raised-button.mat-accent span.mat-button-wrapper {
  padding: 0 20px 0 0;
  font-size: 20px;
}
app-document-detail app-sign-document .sign-cycle {
  padding: 32px 0 !important;
  box-sizing: border-box !important;
  position: relative;
}
app-document-detail app-sign-document .sign-cycle mat-divider {
  position: absolute !important;
  top: 0;
}
app-document-detail app-sign-document .sign-cycle .sign-content {
  width: 70%;
  margin: 0 auto;
}
app-document-detail app-sign-document .sign-cycle .sign-content .indications {
  width: calc(100% - 80px - 32px);
  padding: 0 32px;
  box-sizing: border-box;
}

.detail {
  height: 100%;
  right: 0;
  overflow: hidden;
  position: relative;
  transition: right 0.4s cubic-bezier(0.25, 0.8, 0.25, 1) -in;
}

.pdf-view-container {
  padding: 0;
  height: 100%;
  width: 100%;
  overflow: auto !important;
  overflow-y: overlay !important;
  transition: max-height 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}

mat-toolbar-row.firmado-conforme,
mat-toolbar-row.firmado-no-conforme {
  padding: 16px 0;
  background: unset;
  box-sizing: content-box;
}

mat-toolbar-row .mat-basic-chip span {
  height: 22px;
  line-height: 22px;
}

mat-toolbar-row .mat-basic-chip .mat-icon {
  padding: 0 !important;
}

.secondary-toolbar {
  height: 56px;
  min-height: 56px !important;
}

.secondary-toolbar h4 {
  font-size: 15px;
}

.toolbar-line {
  width: 100%;
  padding-left: 8px;
}

.toolbar-line.not-full {
  width: unset !important;
}

.toolbar-line:not(.wizard-add) {
  max-width: unset;
}

mat-toolbar-row .mat-icon {
  padding: 8px !important;
}

.new-detail-container {
  min-width: 30% !important;
  width: 30% !important;
}
.new-detail-container.cancelled .detail_head-body ngx-avatar .avatar-content {
  background: #b0bec5 !important;
}
.new-detail-container app-employee-detail {
  height: 100%;
  position: relative;
}
.new-detail-container .employee-detail-subbar {
  min-height: 92px;
}
.new-detail-container .employee-avatar {
  font-size: 43px !important;
}
.new-detail-container .employee-detail-info {
  height: 55px;
  padding: 0 16px;
}
.new-detail-container .employee-detail-info .mat-icon {
  padding: 8px !important;
}
.new-detail-container .employee-detail-info .mat-avatar .title {
  width: calc(100% - 70px);
}
.new-detail-container .mat-avatar h3 {
  font-size: 20px !important;
  line-height: 24px !important;
  width: 100%;
  white-space: normal;
}
.new-detail-container .mat-avatar h5 {
  font-size: 12px !important;
  line-height: 16px !important;
  width: 100%;
  white-space: normal;
}
.new-detail-container mat-action-list.mat-list {
  padding: 0;
}
.new-detail-container mat-action-list.mat-list .mat-list-item .mat-list-item-content {
  padding: 16px !important;
  box-sizing: content-box;
}
.new-detail-container mat-expansion-panel.mat-expanded {
  height: calc(100% - 92px - 56px - 48px - 48px - 48px);
}
.new-detail-container .mat-expansion-panel-content {
  flex-direction: unset !important;
  overflow: auto !important;
  overflow-y: overlay !important;
}
.new-detail-container mat-accordion.mat-accordion {
  height: 100%;
}
.new-detail-container .mat-expansion-panel-content {
  height: 100%;
}
.new-detail-container mat-action-list.security-list .mat-list-item {
  max-height: 48px;
  font-size: 14px;
}
.new-detail-container mat-action-list.security-list .mat-list-item .mat-list-item-content {
  padding: 0 16px !important;
  height: 48px;
}
.new-detail-container mat-action-list.security-list .mat-list-item.title .mat-list-item-content {
  padding: 0 24px !important;
}
.new-detail-container mat-action-list.security-list .mat-list-item.title .mat-list-item-content * {
  font-weight: bold;
  font-size: 12px;
}
.new-detail-container mat-list-item {
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  height: auto !important;
}
.new-detail-container mat-list-item .sliding-actions {
  margin-right: -60px;
  transition: all 0.2s ease;
}
.new-detail-container mat-list-item .floating-actions {
  position: absolute;
  right: 0;
  padding: 0 16px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease;
}
.new-detail-container mat-list-item:hover .sliding-actions {
  margin-right: 0;
  transition: all 0.2s ease;
}
.new-detail-container mat-list-item:hover .floating-actions {
  visibility: visible;
  opacity: 1;
  transition: all 0.2s ease;
}
.new-detail-container .mat-list-item + .container-empty {
  display: none !important;
}
.new-detail-container .mat-list-base {
  padding: 0 !important;
}

.w-detail + .new-detail-container {
  opacity: 1;
}

.new-detail-container {
  opacity: 0;
}

app-employee-detail,
app-employee-rrhh-detail {
  height: 100% !important;
}
app-employee-detail .load-window,
app-employee-rrhh-detail .load-window {
  height: 100%;
  top: 0;
}
app-employee-detail .employee_detail-container,
app-employee-rrhh-detail .employee_detail-container {
  height: 100%;
}
app-employee-detail .employee_detail-container.is-editing .edition-line,
app-employee-rrhh-detail .employee_detail-container.is-editing .edition-line {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  flex-wrap: wrap;
  width: -webkit-fill-available;
  padding: 16px;
  border-radius: 4px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
}
app-employee-detail .employee_detail-container.is-editing .edition-line mat-form-field,
app-employee-rrhh-detail .employee_detail-container.is-editing .edition-line mat-form-field {
  width: 100%;
  box-sizing: border-box;
}
app-employee-detail .employee_detail-container.is-editing .edition-line app-custom-control,
app-employee-rrhh-detail .employee_detail-container.is-editing .edition-line app-custom-control {
  width: 100%;
}
app-employee-detail .employee_detail-container.is-editing .edition-line app-custom-control.first-field .indicator .guide.top,
app-employee-rrhh-detail .employee_detail-container.is-editing .edition-line app-custom-control.first-field .indicator .guide.top {
  display: none;
}
app-employee-detail .employee_detail-container.is-editing .edition-line .actions button + button,
app-employee-rrhh-detail .employee_detail-container.is-editing .edition-line .actions button + button {
  margin-left: 12px;
}
app-employee-detail .employee_detail-container.is-editing .edition-line mat-progress-bar,
app-employee-rrhh-detail .employee_detail-container.is-editing .edition-line mat-progress-bar {
  position: absolute;
  left: 0 !important;
  bottom: 0 !important;
}
app-employee-detail .employee_detail-container .employee_detail-toolbar,
app-employee-rrhh-detail .employee_detail-container .employee_detail-toolbar {
  height: 65px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 16px;
}
app-employee-detail .employee_detail-container .employee_detail-head,
app-employee-rrhh-detail .employee_detail-container .employee_detail-head {
  padding: 0 16px;
}
app-employee-detail .employee_detail-container .employee_detail-head .body_title-name + .edition-line,
app-employee-rrhh-detail .employee_detail-container .employee_detail-head .body_title-name + .edition-line {
  margin: 16px 0 0 !important;
}
app-employee-detail .employee_detail-container .employee_detail-head .edition-line,
app-employee-rrhh-detail .employee_detail-container .employee_detail-head .edition-line {
  margin: 16px 0 !important;
}
app-employee-detail .employee_detail-container .employee_detail-head mat-chip span,
app-employee-rrhh-detail .employee_detail-container .employee_detail-head mat-chip span {
  white-space: nowrap;
  margin-right: 8px;
}
app-employee-detail .employee_detail-container .detail_head-body,
app-employee-rrhh-detail .employee_detail-container .detail_head-body {
  position: relative;
  padding: 16px 8px 16px;
  border-radius: 4px 4px 0 0;
  transition: background 0.2s ease;
}
app-employee-detail .employee_detail-container .detail_head-body .head_body-avatar,
app-employee-rrhh-detail .employee_detail-container .detail_head-body .head_body-avatar {
  position: absolute;
  top: -50px;
  left: 16px;
  width: 100px;
}
app-employee-detail .employee_detail-container .detail_head-body .head_body-avatar button,
app-employee-rrhh-detail .employee_detail-container .detail_head-body .head_body-avatar button {
  position: absolute;
  top: 0;
  left: 100%;
}
app-employee-detail .employee_detail-container .detail_head-body .head_body-avatar img,
app-employee-rrhh-detail .employee_detail-container .detail_head-body .head_body-avatar img {
  width: 100px;
  border-radius: 50px;
}
app-employee-detail .employee_detail-container .detail_head-body .edition-line,
app-employee-rrhh-detail .employee_detail-container .detail_head-body .edition-line {
  margin: 0 8px 16px;
}
app-employee-detail .employee_detail-container .detail_head-body .head_body-status,
app-employee-rrhh-detail .employee_detail-container .detail_head-body .head_body-status {
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 8px;
}
app-employee-detail .employee_detail-container .detail_head-body .head_body-status + .head_body-title,
app-employee-rrhh-detail .employee_detail-container .detail_head-body .head_body-status + .head_body-title {
  margin-top: 0 !important;
}
app-employee-detail .employee_detail-container .detail_head-body .head_body-status .mat-chip .mat-icon,
app-employee-rrhh-detail .employee_detail-container .detail_head-body .head_body-status .mat-chip .mat-icon {
  width: unset !important;
}
app-employee-detail .employee_detail-container .detail_head-body .head_body-status mat-icon + mat-icon,
app-employee-rrhh-detail .employee_detail-container .detail_head-body .head_body-status mat-icon + mat-icon {
  margin-left: 8px;
}
app-employee-detail .employee_detail-container .detail_head-body .head_body-status .mat-icon[matbadgecolor=green] .mat-badge-content,
app-employee-rrhh-detail .employee_detail-container .detail_head-body .head_body-status .mat-icon[matbadgecolor=green] .mat-badge-content {
  background: #4CAF50;
  color: #4CAF50 !important;
}
app-employee-detail .employee_detail-container .detail_head-body .head_body-title,
app-employee-rrhh-detail .employee_detail-container .detail_head-body .head_body-title {
  padding: 0 8px;
  margin-top: 40px;
}
app-employee-detail .employee_detail-container .detail_head-body .head_body-title .body_title-name,
app-employee-rrhh-detail .employee_detail-container .detail_head-body .head_body-title .body_title-name {
  position: relative;
  height: 56px;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  font-size: 22px;
  font-weight: 800;
  text-transform: capitalize;
  cursor: pointer;
  transition: all 0.2s ease-out;
  width: min-content;
  max-width: 100%;
  min-width: calc(50% - 8px);
}
app-employee-detail .employee_detail-container .detail_head-body .head_body-title .body_title-name .input-like,
app-employee-rrhh-detail .employee_detail-container .detail_head-body .head_body-title .body_title-name .input-like {
  display: flex;
  flex-direction: column;
  margin-right: 16px;
  width: 100%;
}
app-employee-detail .employee_detail-container .detail_head-body .head_body-title .body_title-name .input-like span,
app-employee-rrhh-detail .employee_detail-container .detail_head-body .head_body-title .body_title-name .input-like span {
  font-weight: 300;
  font-size: 11px;
}
app-employee-detail .employee_detail-container .detail_head-body .head_body-title .body_title-name .input-like b,
app-employee-rrhh-detail .employee_detail-container .detail_head-body .head_body-title .body_title-name .input-like b {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
app-employee-detail .employee_detail-container .detail_head-body .head_body-title .body_title-name button.mat-icon-button,
app-employee-rrhh-detail .employee_detail-container .detail_head-body .head_body-title .body_title-name button.mat-icon-button {
  opacity: 0;
  pointer-events: none;
  transition: all 0.2s ease-out;
}
app-employee-detail .employee_detail-container .detail_head-body .head_body-title .body_title-name:hover,
app-employee-rrhh-detail .employee_detail-container .detail_head-body .head_body-title .body_title-name:hover {
  background: rgba(255, 255, 255, 0.1) !important;
  transition: all 0.2s ease-out;
}
app-employee-detail .employee_detail-container .detail_head-body .head_body-title .body_title-name:hover button,
app-employee-rrhh-detail .employee_detail-container .detail_head-body .head_body-title .body_title-name:hover button {
  opacity: 1;
  pointer-events: all;
  transition: all 0.2s ease-in;
}
app-employee-detail .employee_detail-container .detail_head-body .head_body-title .body_title-user,
app-employee-rrhh-detail .employee_detail-container .detail_head-body .head_body-title .body_title-user {
  position: relative;
  height: 40px;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 300;
  cursor: pointer;
  width: min-content;
  max-width: 100%;
  min-width: calc(50% - 8px);
}
app-employee-detail .employee_detail-container .detail_head-body .head_body-title .body_title-user .input-like,
app-employee-rrhh-detail .employee_detail-container .detail_head-body .head_body-title .body_title-user .input-like {
  display: flex;
  flex-direction: column;
  margin-right: 16px;
  width: 100%;
}
app-employee-detail .employee_detail-container .detail_head-body .head_body-title .body_title-user .input-like span,
app-employee-rrhh-detail .employee_detail-container .detail_head-body .head_body-title .body_title-user .input-like span {
  font-weight: 300;
  font-size: 11px;
}
app-employee-detail .employee_detail-container .detail_head-body .head_body-title .body_title-user .input-like b,
app-employee-rrhh-detail .employee_detail-container .detail_head-body .head_body-title .body_title-user .input-like b {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
app-employee-detail .employee_detail-container .detail_head-body .head_body-title .body_title-user button.mat-icon-button,
app-employee-rrhh-detail .employee_detail-container .detail_head-body .head_body-title .body_title-user button.mat-icon-button {
  opacity: 0;
  pointer-events: none;
  transition: all 0.2s ease-out;
}
app-employee-detail .employee_detail-container .detail_head-body .head_body-title .body_title-user:hover,
app-employee-rrhh-detail .employee_detail-container .detail_head-body .head_body-title .body_title-user:hover {
  background: rgba(255, 255, 255, 0.1) !important;
  transition: all 0.2s ease-out;
}
app-employee-detail .employee_detail-container .detail_head-body .head_body-title .body_title-user:hover button,
app-employee-rrhh-detail .employee_detail-container .detail_head-body .head_body-title .body_title-user:hover button {
  opacity: 1;
  pointer-events: all;
  transition: all 0.2s ease-in;
}
app-employee-detail .employee_detail-container .employee_detail-scrollable,
app-employee-rrhh-detail .employee_detail-container .employee_detail-scrollable {
  height: calc(100% - 233px);
  overflow-y: scroll;
  padding-left: 16px;
  padding-right: 8px;
  box-sizing: border-box;
}
app-employee-detail .employee_detail-container .employee_detail-scrollable .detail_head-body,
app-employee-rrhh-detail .employee_detail-container .employee_detail-scrollable .detail_head-body {
  padding: 0 8px !important;
  border-radius: 0 0 4px 4px !important;
  margin-bottom: 16px;
}
app-employee-detail .employee_detail-container .data,
app-employee-rrhh-detail .employee_detail-container .data {
  display: flex;
  flex-wrap: wrap;
}
app-employee-detail .employee_detail-container .data .data-line,
app-employee-rrhh-detail .employee_detail-container .data .data-line {
  min-height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  padding-right: 8px;
  margin: 0 8px 16px;
  flex-grow: 1;
  min-width: calc(50% - 16px);
  box-sizing: border-box;
  position: relative;
  padding: 8px 16px 8px 0;
}
app-employee-detail .employee_detail-container .data .data-line.clickable,
app-employee-rrhh-detail .employee_detail-container .data .data-line.clickable {
  cursor: pointer;
}
app-employee-detail .employee_detail-container .data .data-line.show-action button.mat-icon-button,
app-employee-rrhh-detail .employee_detail-container .data .data-line.show-action button.mat-icon-button {
  opacity: 1 !important;
  pointer-events: all !important;
}
app-employee-detail .employee_detail-container .data .data-line button.mat-icon-button,
app-employee-rrhh-detail .employee_detail-container .data .data-line button.mat-icon-button {
  position: absolute;
  right: 8px;
  opacity: 0;
  pointer-events: none;
  transition: all 0.2s ease-out;
}
app-employee-detail .employee_detail-container .data .data-line button.mat-icon-button + button.mat-icon-button,
app-employee-rrhh-detail .employee_detail-container .data .data-line button.mat-icon-button + button.mat-icon-button {
  right: 56px;
}
app-employee-detail .employee_detail-container .data .data-line .data_line-text,
app-employee-rrhh-detail .employee_detail-container .data .data-line .data_line-text {
  display: flex;
  align-items: center;
  width: 100%;
}
app-employee-detail .employee_detail-container .data .data-line .data_line-text mat-icon,
app-employee-rrhh-detail .employee_detail-container .data .data-line .data_line-text mat-icon {
  margin: 0 8px;
}
app-employee-detail .employee_detail-container .data .data-line .data_line-text .line_text-container,
app-employee-rrhh-detail .employee_detail-container .data .data-line .data_line-text .line_text-container {
  width: calc(100% - 56px);
}
app-employee-detail .employee_detail-container .data .data-line .data_line-text .line_text-container .line_text-side,
app-employee-rrhh-detail .employee_detail-container .data .data-line .data_line-text .line_text-container .line_text-side {
  font-size: 12px;
  line-height: 16px;
  overflow: hidden;
  white-space: normal;
  word-break: break-word;
}
app-employee-detail .employee_detail-container .data .data-line .data_line-text .line_text-container .line_text-side u,
app-employee-rrhh-detail .employee_detail-container .data .data-line .data_line-text .line_text-container .line_text-side u {
  cursor: help;
}
app-employee-detail .employee_detail-container .data .data-line .data_line-text .line_text-container .line_text-main,
app-employee-rrhh-detail .employee_detail-container .data .data-line .data_line-text .line_text-container .line_text-main {
  font-size: 14px;
  font-weight: 900;
  overflow: hidden;
  white-space: normal;
  word-break: break-word;
}
app-employee-detail .employee_detail-container .data .data-line .data_line-text .line_text-container .line_text-side + .line_text-main,
app-employee-rrhh-detail .employee_detail-container .data .data-line .data_line-text .line_text-container .line_text-side + .line_text-main {
  line-height: 20px;
}
app-employee-detail .employee_detail-container .data .data-line .data_line-text .line_text-container .line_text-main .line_text-side,
app-employee-rrhh-detail .employee_detail-container .data .data-line .data_line-text .line_text-container .line_text-main .line_text-side {
  line-height: 20px !important;
}
app-employee-detail .employee_detail-container .data .data-line:hover button,
app-employee-rrhh-detail .employee_detail-container .data .data-line:hover button {
  opacity: 1;
  pointer-events: all;
  transition: all 0.2s ease-in;
}
app-employee-detail .employee_detail-container .employee-title,
app-employee-rrhh-detail .employee_detail-container .employee-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  margin-top: -16px;
}
app-employee-detail .employee_detail-container .employee-title .title-text,
app-employee-rrhh-detail .employee_detail-container .employee-title .title-text {
  display: flex;
  height: 100%;
  align-items: center;
}
app-employee-detail .employee_detail-container .employee-title .title-text mat-icon,
app-employee-rrhh-detail .employee_detail-container .employee-title .title-text mat-icon {
  margin-right: 16px;
}
app-employee-detail .employee_detail-container .employee-title .title-text .title_text-main,
app-employee-rrhh-detail .employee_detail-container .employee-title .title-text .title_text-main {
  font-size: 18px;
  font-weight: 700;
}
app-employee-detail .employee_detail-container .employee_detail-side-body,
app-employee-rrhh-detail .employee_detail-container .employee_detail-side-body {
  margin: 0 8px;
}

app-leave-request-detail {
  height: 100%;
}
app-leave-request-detail .load-window {
  top: unset !important;
  height: 100% !important;
}
app-leave-request-detail form {
  width: 100% !important;
  height: 100%;
  position: relative;
}
app-leave-request-detail form .leave_detail-container {
  height: 100%;
}
app-leave-request-detail form .leave_detail-container .toolbar {
  height: 112px;
  padding: 16px 32px;
}
app-leave-request-detail form .leave_detail-container .toolbar .header {
  width: 100%;
}
app-leave-request-detail form .leave_detail-container .toolbar .header h3 {
  margin: 0;
}
app-leave-request-detail form .leave_detail-container .toolbar .header h5 {
  margin: 0;
}
app-leave-request-detail form .leave_detail-container .leave_detail-container-content {
  padding: 0 0 16px 8px;
  overflow-y: scroll;
  height: calc(100% - 96px - 112px);
  max-height: calc(100% - 112px);
  box-sizing: border-box;
}
app-leave-request-detail form .leave_detail-container .leave_detail-container-content .leave_resume {
  width: calc(100% - 16px);
  padding: 16px;
  border-radius: 8px;
  margin: 0 8px;
  position: relative;
}
app-leave-request-detail form .leave_detail-container .leave_detail-container-content .leave_resume .leave_resume-title {
  padding: 0 16px;
  margin: 0 0 16px;
}
app-leave-request-detail form .leave_detail-container .leave_detail-container-content .leave_resume .line {
  padding: 8px 16px;
  position: relative;
  border-radius: 4px;
  width: 100%;
}
app-leave-request-detail form .leave_detail-container .leave_detail-container-content .leave_resume .line h3,
app-leave-request-detail form .leave_detail-container .leave_detail-container-content .leave_resume .line h4,
app-leave-request-detail form .leave_detail-container .leave_detail-container-content .leave_resume .line h6 {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
app-leave-request-detail form .leave_detail-container .leave_detail-container-content .leave_resume .line h3 .line-side {
  font-weight: 300;
}
app-leave-request-detail form .leave_detail-container .leave_detail-container-content .leave_resume .line h6 {
  font-weight: 300;
}
app-leave-request-detail form .leave_detail-container .leave_detail-container-content .leave_resume mat-divider {
  margin: 16px 0;
}
app-leave-request-detail form .leave_detail-container .actions {
  padding: 16px;
  height: 96px;
  column-gap: 16px;
}
app-leave-request-detail form .leave_detail-container .actions button {
  height: 100%;
  border-radius: 40px;
}

.config_leave-container .config_leave-card {
  padding: 16px;
  border-radius: 8px;
}
.config_leave-container .config_leave-card .config_leave-auto_chip mat-form-field {
  width: 100%;
}
.config_leave-container .config_leave-card .config_leave-auto_chip mat-form-field.mat-form-field-disabled * {
  cursor: not-allowed;
}
.config_leave-container .config_leave-card .config_leave-auto_chip mat-form-field.mat-form-field-disabled .mat-form-field-underline {
  height: 2px !important;
}
.config_leave-container .config_leave-card .config_leave-auto_chip mat-form-field .mat-form-field-flex {
  border-radius: 4px 4px 0 0 !important;
  padding: 8px 8px 0 !important;
  min-height: 56px !important;
  align-items: unset !important;
}
.config_leave-container .config_leave-card .config_leave-auto_chip mat-form-field .mat-form-field-flex .mat-form-field-prefix {
  padding: 0 !important;
  display: flex;
  align-items: center;
}
*.show-md {
  display: none !important;
}

mat-form-field.new_leave_modal-field {
  padding: 0 32px;
  box-sizing: border-box;
}
mat-form-field.new_leave_modal-field .mat-form-field-infix {
  display: flex;
}
mat-form-field.new_leave_modal-field .mat-form-field-infix mat-datepicker-toggle {
  position: absolute;
  right: 0;
  bottom: 0.5em;
}

.title .filter_range {
  margin: 0;
  font-size: 12px;
}

.autocomple {
  bottom: 110.242px;
  max-height: 340px !important;
  position: absolute !important;
}

.customAccess {
  display: inline !important;
}

.UI-V2 .frame-card > :nth-child(2) {
  display: flex;
  width: 100%;
}
.UI-V2 .load-window.new {
  position: relative !important;
  min-height: calc(100% - 192px) !important;
  height: unset !important;
}
.UI-V2 h2,
.UI-V2 h3,
.UI-V2 h4,
.UI-V2 h5,
.UI-V2 h6 {
  margin: 0;
}
.UI-V2 button mat-icon.mat-icon {
  width: unset;
  min-width: unset;
  min-height: unset;
}
.UI-V2 .icon-complex {
  position: relative;
}
.UI-V2 .icon-complex mat-icon.complex {
  position: absolute;
  bottom: -8px;
  right: -2px;
  font-size: 16px !important;
  height: 20px;
  width: 20px;
  min-width: unset !important;
}
.UI-V2 .main {
  position: relative;
}
.UI-V2 .main .toolbar {
  padding: 16px;
  height: auto;
  display: flex;
  box-sizing: border-box;
  flex-wrap: wrap;
  row-gap: 16px;
  column-gap: 16px;
  flex-direction: row;
}
.UI-V2 .main .toolbar .header {
  width: auto;
  overflow: hidden;
}
.UI-V2 .main .toolbar .header .content .module-title {
  position: relative;
  padding: 16px;
  height: 80px;
  flex-shrink: 0;
}
.UI-V2 .main .toolbar .header .content .module-title[hidden] {
  display: none !important;
}
.UI-V2 .main .toolbar .header .content .module-title.selectable {
  border-radius: 8px;
  cursor: pointer;
}
.UI-V2 .main .toolbar .header .content .module-title.selectable mat-select {
  position: absolute;
  opacity: 0;
  pointer-events: none;
  height: 100%;
}
.UI-V2 .main .toolbar .header .content .module-title .info {
  margin-right: 16px;
  width: 100%;
}
.UI-V2 .main .toolbar .header .content .module-title .info mat-icon + div {
  width: calc(100% - 56px);
}
.UI-V2 .main .toolbar .header .content .module-title .info mat-icon + div h3 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.UI-V2 .main .toolbar .header .content .module-title .info mat-icon + div h5 {
  font-weight: 400;
}
.UI-V2 .main .toolbar .header .content .ou-select {
  position: relative;
  padding: 16px;
  height: 80px;
  border-radius: 8px;
  cursor: pointer;
  flex-shrink: 0;
  max-width: 40vw;
}
.UI-V2 .main .toolbar .header .content .ou-select .info {
  margin-right: 16px;
}
.UI-V2 .main .toolbar .header .content .ou-select .info h5 {
  font-weight: 400;
}
.UI-V2 .main .toolbar .header .content .ou-select mat-select {
  position: absolute;
  opacity: 0;
  pointer-events: none;
  height: 100%;
}
.UI-V2 .main .toolbar .search-container {
  flex-grow: 1;
}
.UI-V2 .main .toolbar .search-container .search {
  box-sizing: border-box;
  display: inline-block;
  max-width: calc(100% - 74px);
  height: 80px;
  position: relative;
  width: 100%;
}
.UI-V2 .main .toolbar .search-container .search .content {
  height: 100%;
  border-radius: 40px;
  padding: 0 16px;
  border: none;
  transition: color 0.1s ease, background 0.15s ease;
}
.UI-V2 .main .toolbar .search-container .search .content label {
  top: 100%;
  font-size: 12px;
  line-height: 1.25;
  bottom: 0;
  position: absolute;
  opacity: 0;
  transition: opacity 0.1s ease;
}
.UI-V2 .main .toolbar .search-container .search .content:hover, .UI-V2 .main .toolbar .search-container .search .content:active, .UI-V2 .main .toolbar .search-container .search .content:focus, .UI-V2 .main .toolbar .search-container .search .content:focus-within {
  transition: background 0.15s ease;
}
.UI-V2 .main .toolbar .search-container .search .content:hover label, .UI-V2 .main .toolbar .search-container .search .content:active label, .UI-V2 .main .toolbar .search-container .search .content:focus label, .UI-V2 .main .toolbar .search-container .search .content:focus-within label {
  opacity: 1;
  transition: opacity 0.1s ease;
}
.UI-V2 .main .toolbar .search-container .search .content input {
  line-height: 80px;
  border: none;
  background: none;
  font-size: 18px;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}
.UI-V2 .main .toolbar .search-container .search button {
  min-width: unset;
  border-radius: 20px;
}
.UI-V2 .main .toolbar .search-container .search button.mat-button .mat-button-wrapper {
  display: flex;
  align-items: center;
  height: 40px;
}
.UI-V2 .main .toolbar .search-container .search button.mat-button .mat-button-wrapper mat-icon {
  margin-right: 8px;
}
.UI-V2 .main .toolbar .search-container .search button.mat-primary .mat-icon {
  color: unset !important;
}
.UI-V2 .main .toolbar .search-container > button {
  margin-right: 16px;
  margin-left: 16px;
  float: right;
  margin-top: 21px;
}
.UI-V2 .main .helper {
  padding: 16px 0;
  margin: 0 16px;
  height: 56px;
  position: relative;
}
.UI-V2 .main .helper mat-hint {
  height: 40px;
  padding: 16px;
}
.UI-V2 .main .helper .actions {
  margin-right: 16px;
}
.UI-V2 .main .helper .actions .mat-stroked-button {
  border-radius: 20px;
}
.UI-V2 .main .helper .actions .mat-stroked-button .mat-button-wrapper {
  display: flex;
  align-items: center;
  height: 40px;
}
.UI-V2 .main .helper .actions .mat-stroked-button .mat-button-wrapper mat-icon {
  margin-right: 8px;
}
.UI-V2 .main .helper .actions .mat-icon-button .mat-button-wrapper {
  display: flex;
  align-items: center;
  height: 100%;
}
.UI-V2 .main .helper mat-progress-bar {
  bottom: -4px;
}
.UI-V2 .main .load-window.new {
  height: unset !important;
  min-height: unset !important;
  top: 0;
  flex-grow: 1;
  flex-shrink: 1;
}
.UI-V2 .main .new-table {
  min-height: calc(100% - 192px - 56px - 16px);
  margin-top: 16px;
}
.UI-V2 .main .new-table[hidden] {
  display: none !important;
}
.UI-V2 .main .new-table.w-main-action {
  min-height: calc(100% - 192px - 56px - 97px - 16px);
  flex-grow: 1;
  flex-shrink: 1;
}
.UI-V2 .main .new-table.documents {
  margin-top: 16px;
  min-height: calc(100% - 192px - 56px - 16px) !important;
}
.UI-V2 .main .new-table.documents .w-12 {
  width: calc(100% - 72px - 72px - 72px - 56px) !important;
  min-width: calc(100% - 72px - 72px - 72px - 56px) !important;
  position: unset !important;
}
.UI-V2 .main .new-table.documents .mat-header-row.sticky .col-selector {
  right: 32px !important;
}
.UI-V2 .main .new-table app-cs-grid-control datatable-header {
  border-bottom: none !important;
}
.UI-V2 .main .new-table app-cs-grid-control datatable-header .datatable-row-center {
  padding: 0 16px 0 72px;
  box-sizing: border-box;
}
.UI-V2 .main .new-table app-cs-grid-control datatable-header * {
  font-weight: 800;
}
.UI-V2 .main .new-table app-cs-grid-control datatable-body-row .datatable-row-center {
  padding: 0 16px 0 72px;
  box-sizing: border-box;
}
.UI-V2 .main .new-table .mat-header-row.sticky {
  border-bottom: none;
  padding: 0 16px;
}
.UI-V2 .main .new-table .mat-header-row.sticky .w-12 {
  position: relative;
}
.UI-V2 .main .new-table .mat-header-row.sticky .w-12 .col-selector {
  position: absolute;
  right: 16px;
}
.UI-V2 .main .new-table .mat-header-row.sticky * {
  font-weight: 800;
}
.UI-V2 .main .new-table .mat-header-row.sticky .mat-header-cell {
  padding-left: 0 !important;
}
.UI-V2 .main .new-table .mat-header-row.sticky .mat-header-cell > div, .UI-V2 .main .new-table .mat-header-row.sticky .mat-header-cell > span {
  overflow: hidden;
}
.UI-V2 .main .new-table .mat-header-row.sticky .mat-header-cell mat-icon.indication[hidden] {
  display: none !important;
}
.UI-V2 .main .new-table .mat-header-row.sticky .mat-header-cell mat-icon.indication {
  opacity: 0;
  min-width: unset;
}
.UI-V2 .main .new-table .mat-header-row.sticky .mat-header-cell .sorteable:hover .mat-icon.indication {
  opacity: 1;
}
.UI-V2 .main .new-table .mat-header-row.sticky .mat-header-cell .sorteable .sort-icon {
  position: relative !important;
}
.UI-V2 .main .new-table .mat-row {
  padding: 0 16px;
}
.UI-V2 .main .new-table .mat-row .mat-cell {
  padding-left: 0 !important;
}
.UI-V2 .main .new-table .mat-row .sticky-actions {
  right: 16px;
}
.UI-V2 .main .main-action {
  min-height: 97px;
  bottom: 0;
  padding: 16px;
  width: calc(100% - 32px);
  margin: 0 16px;
}
.UI-V2 .main .main-action .main-btn {
  height: 64px;
  border-radius: 32px;
}
.UI-V2 .main .main-action .main-btn .mat-button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  padding-right: 8px;
}
.UI-V2 .main .main-action .main-btn .mat-button-wrapper mat-icon.mat-icon {
  min-width: 40px;
  margin-right: 8px;
}

.menu-options.help {
  width: unset !important;
  max-width: unset !important;
}
.menu-options.help .primary {
  padding: 0 !important;
}
.menu-options.help .accent {
  padding: 0 !important;
}
.menu-options.help .container {
  padding: 0 16px 16px !important;
}
.menu-options.help .container .slide-group mat-expansion-panel-header {
  padding: 0 8px !important;
}
.menu-options.help .container .slide-group ul {
  padding-right: 20px;
  margin: 0 0 16px;
}
.menu-options.help .container .slide-group ul ul {
  margin: 0;
}
.menu-options.help .container .slide-group li {
  line-height: 26px;
}
.menu-options.help .container .slide-group h5 {
  margin-bottom: 8px !important;
  margin-top: 24px;
}
.menu-options button mat-icon.mat-icon {
  width: unset;
  min-width: unset;
  min-height: unset;
}
.menu-options .icon-complex {
  position: relative;
}
.menu-options .icon-complex mat-icon.complex {
  position: absolute;
  bottom: -8px;
  right: -2px;
  font-size: 16px !important;
  height: 20px;
  width: 20px;
  min-width: unset !important;
}
.menu-options .mat-menu-content {
  padding: 0 !important;
}
.menu-options .mat-menu-content .container {
  max-height: calc(100vh - 32px - 112px - 68px);
  overflow-y: auto;
  padding: 16px 16px 0;
}
.menu-options .mat-menu-content .container.col-sel {
  max-height: calc(100vh - 32px - 112px - 56px - 16px - 56px - 68px);
  overflow-y: auto;
  padding: 16px 16px 0;
}
.menu-options .mat-menu-content .container .title {
  margin: 16px 0 !important;
  width: 100%;
  padding: 0 8px;
}
.menu-options .mat-menu-content .container .title h4,
.menu-options .mat-menu-content .container .title h6 {
  margin: 0;
}
.menu-options .mat-menu-content .container .title h6 {
  font-weight: 400;
}
.menu-options .mat-menu-content .container mat-tab-group {
  width: 100%;
}
.menu-options .mat-menu-content .container mat-tab-group mat-tab-body {
  padding-top: 16px !important;
  opacity: 0;
}
.menu-options .mat-menu-content .container mat-tab-group mat-tab-body.mat-tab-body-active {
  opacity: 1 !important;
}
.menu-options .mat-menu-content .container mat-tab-group mat-tab-body .mat-tab-body-content {
  display: flex !important;
  flex-direction: column !important;
  max-height: 100%;
  height: unset;
}
.menu-options .mat-menu-content .container mat-list .mat-subheader {
  padding: 16px 8px !important;
}
.menu-options .mat-menu-content .container mat-list .mat-subheader mat-icon {
  margin-right: 16px;
}
.menu-options .mat-menu-content .container mat-list .mat-list-item .mat-list-item-content {
  padding: 16px 8px !important;
}
.menu-options .mat-menu-content .container mat-list .mat-list-item .mat-list-item-content .mat-list-icon {
  padding: 0 !important;
}
.menu-options .mat-menu-content .container mat-list .mat-list-item .mat-list-item-content .mat-list-icon.icon-complex {
  width: 40px;
  min-width: 40px;
}
.menu-options .mat-menu-content .container .slide-group + .slide-group {
  margin-top: 16px !important;
}
.menu-options .mat-menu-content .container .slide-group mat-radio-group {
  margin-bottom: 16px;
}
.menu-options .mat-menu-content .container .slide-group mat-radio-group mat-radio-button {
  min-height: 40px;
  display: flex;
  align-items: center;
  transition: background 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.menu-options .mat-menu-content .container .slide-group mat-radio-group mat-radio-button .mat-radio-container {
  margin: 0 18px;
}
.menu-options .mat-menu-content .container .slide-group mat-form-field {
  width: 100%;
}
.menu-options .mat-menu-content .container .slide-group mat-form-field .mat-form-field-flex {
  border-radius: 4px 4px 0 0 !important;
  padding: 8px 8px 0 !important;
  min-height: 56px !important;
  align-items: unset !important;
}
.menu-options .mat-menu-content .container .slide-group mat-form-field .mat-form-field-flex .mat-form-field-prefix {
  padding: 0 !important;
  display: flex;
  align-items: center;
}
.menu-options .mat-menu-content .container .slide-group mat-slide-toggle {
  min-height: 40px;
  display: flex !important;
  align-items: center;
  padding: 0 10px;
  transition: background 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.menu-options .mat-menu-content .container .slide-group mat-slide-toggle .mat-slide-toggle-content {
  padding-left: 10px;
}
.menu-options .mat-menu-content .footer {
  padding: 16px;
}

.mat-paginator-container {
  padding: 0 !important;
}
.mat-paginator-container .mat-paginator-range-label {
  margin: 0 16px !important;
  white-space: nowrap;
}
.mat-paginator-container .mat-icon-button {
  display: flex;
  justify-content: center;
}

.main.w-detail .documents-search-sub-toolbar .mat-search-field {
  width: unset;
  min-width: unset;
}

.documents-search-sub-toolbar {
  padding: 0 24px;
}
.documents-search-sub-toolbar .mat-checkbox-label {
  font-size: 12px;
}

.mat-menu-panel {
  min-height: unset !important;
}
.mat-menu-panel.mat-card {
  padding: 0 !important;
}
.mat-menu-panel.mat-card.suffix-options {
  padding: 8px 16px !important;
  width: unset;
}
.mat-menu-panel.mat-card.suffix-options mat-radio-button {
  height: 40px;
  margin: 0 !important;
  display: flex !important;
  align-items: center;
}
.mat-menu-panel.notifications-panel {
  width: 500px !important;
  max-height: 60vh !important;
  overflow-y: hidden;
}
.mat-menu-panel.notifications-panel .window-spinner {
  position: absolute !important;
}
.mat-menu-panel.notifications-panel .mat-menu-content {
  padding: 0 !important;
  position: relative;
  height: 100%;
}
.mat-menu-panel.notifications-panel .mat-menu-content .notifications-body {
  overflow-y: auto;
  height: calc(60vh - 64px - 56px);
  box-sizing: border-box;
  margin-bottom: 56px !important;
}
.mat-menu-panel.notifications-panel .mat-menu-content .notification {
  padding: 0 16px 0 24px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
}
.mat-menu-panel.notifications-panel .mat-menu-content .notification.no-notif {
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
}
.mat-menu-panel.notifications-panel .mat-menu-content .notification .mat-progress-bar {
  bottom: -4px !important;
  left: 0 !important;
}
.mat-menu-panel.notifications-panel .mat-menu-content .notification .primary {
  padding: 16px 0;
  padding-right: 16px !important;
}
.mat-menu-panel.notifications-panel .mat-menu-content .notification .primary h3 {
  margin: 0;
}
.mat-menu-panel.notifications-panel .mat-menu-content .notification.top {
  height: 64px;
}
.mat-menu-panel.notifications-panel .mat-menu-content .notification.top .header h3 {
  font-weight: 500;
}
.mat-menu-panel.notifications-panel .mat-menu-content .notification.bottom {
  height: 56px;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable {
  cursor: pointer;
  padding: 16px !important;
  margin: 8px;
  border-radius: 8px;
  width: calc(100% - 16px) !important;
}
.mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable:hover .notif-hide-button {
  opacity: 1;
  right: 16px;
  pointer-events: all;
  transition: all 0.2s ease-in 0.3s;
}
.mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable .notif-hide-button {
  opacity: 0;
  top: 4px;
  pointer-events: none;
  margin-left: 16px;
  right: -56px;
  transition: all 0.2s ease-out;
  position: absolute;
  transform: scale(0.65);
  transform-origin: top right;
}
.mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable h3 {
  margin: 0;
}
.mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable h5 {
  margin: 0;
  font-weight: 400;
}
.mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable .notification-on-signature {
  width: 100%;
  min-width: 250px;
}
.mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable .notification-on-signature .signature-bar {
  position: relative !important;
  bottom: unset !important;
  border-radius: 4px !important;
  overflow: hidden;
  margin-top: 8px;
  height: 8px;
  border-radius: 4px;
}
.mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable .notification-on-signature .signature-count {
  text-align: right;
  font-size: 12px;
  margin-top: 8px;
}
.mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable .subtitle {
  margin-left: 32px;
}
.mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable .subtitle h5 {
  margin: 0;
  height: 32px;
  border-radius: 18px;
  padding: 0 16px 0 8px;
  line-height: 1;
}
.mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable .subtitle h5 mat-icon {
  font-size: inherit !important;
  color: inherit !important;
  width: 20px !important;
  height: 20px !important;
  min-width: unset !important;
}

.info-empty {
  height: 100%;
  min-height: 25vw !important;
}
.info-empty .large-icon {
  height: 120px !important;
  width: 120px !important;
  border-radius: 70px;
  font-size: 70px !important;
  margin-bottom: 16px;
}
.info-empty h2,
.info-empty h3 {
  text-align: center;
  margin: 0;
}
.info-empty h4,
.info-empty h5 {
  margin: 0;
  text-align: center;
  max-width: 40vw;
  font-weight: 400;
}

.side.edition .info-empty {
  padding: 16px 32px;
  text-align: center;
}
.side.edition .info-empty .large-icon {
  height: 120px !important;
  width: 120px !important;
  border-radius: 70px;
  font-size: 70px !important;
  margin: 32px 16px 32px;
}
.side.edition .info-empty h2 {
  margin: 0 0 16px;
  font-size: 20px;
  text-align: center;
}
.side.edition .info-empty h4 {
  margin: 0 0 32px;
  text-align: center;
  max-width: 40vw;
}

.mat-basic-chip mat-icon.mat-icon {
  min-width: unset;
}

mat-icon.mat-icon {
  min-width: 40px;
  height: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  border-radius: 25px;
  box-sizing: border-box !important;
  padding: 0;
  overflow: visible;
}

.fa-bg-new {
  position: relative;
  overflow: visible !important;
}
.fa-bg-new::after {
  position: absolute;
  background-color: tomato;
  content: "NEW";
  border-radius: 6px;
  top: 0;
  right: 1px;
  padding: 2px 4px;
  font-size: 10px;
  font-weight: 600;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  color: white;
}

.mat-button-ripple.mat-ripple,
.mat-button-focus-overlay,
.mat-radio-ripple,
.mat-slide-toggle-ripple,
.mat-checkbox-ripple {
  position: absolute !important;
}

.mat-chip-list-wrapper mat-chip {
  max-width: calc(100% - 16px);
}
.mat-chip-list-wrapper mat-chip .chip-text {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.mat-chip-list-wrapper mat-chip mat-icon.mat-icon {
  min-width: unset;
}

.menu-is-open .UI-V2 .toolbar .header .content .module-title {
  flex-shrink: 1;
}
.menu-is-open .UI-V2 .toolbar .search-container .search button {
  padding: 0;
}
.menu-is-open .UI-V2 .toolbar .search-container .search button .mat-button-wrapper {
  width: 40px !important;
  overflow: hidden;
  justify-content: start;
  padding: 0;
}
.menu-is-open .UI-V2 .toolbar .search-container .search button .mat-button-wrapper mat-icon {
  width: 40px !important;
  min-width: 40px !important;
  margin: 0 !important;
}
.menu-is-open .UI-V2 .helper .actions .mat-stroked-button {
  padding: 0 !important;
  min-width: 40px;
  width: 40px;
  height: 40px;
}
.menu-is-open .UI-V2 .helper .actions .mat-stroked-button .mat-button-wrapper {
  width: 40px !important;
  overflow: hidden;
  justify-content: start;
  padding: 0;
}
.menu-is-open .UI-V2 .helper .actions .mat-stroked-button .mat-button-wrapper mat-icon {
  width: 40px !important;
  min-width: 40px !important;
  margin: 0 !important;
}
.menu-is-open .UI-V2 .helper .actions .mat-stroked-button .mat-button-wrapper span {
  display: none !important;
}

.mat-menu-panel {
  min-height: unset !important;
}
.mat-menu-panel .mat-card {
  padding: 0 !important;
}

app-documentation-signer .info-empty .large-icon,
app-role-user-find .info-empty .large-icon {
  font-size: 50px !important;
}

.navigation-menu h4 {
  margin: 16px 32px;
}
.navigation-menu app-autocomplete-chip {
  margin: 0 32px;
}

.no-clickable {
  pointer-events: none;
}

.mat-datepicker-actions .custom-action-button {
  margin-top: 8px;
}

.UI-V2 .detail-open .audit-filter {
  width: 0 !important;
  flex: 0 !important;
  transition: width 0.2s ease;
}
.UI-V2 .detail-open .audit-detail-container {
  right: 0;
  transition: right 0.2s ease;
}
.UI-V2 .audit-filter {
  width: 30%;
  transition: width 0.2s ease;
  overflow: hidden;
}
.UI-V2 .audit-filter .toolbar {
  margin: 16px;
  padding: 0;
  max-height: 80px !important;
  border-radius: 8px;
}
.UI-V2 .audit-filter .toolbar .header {
  display: inline-block !important;
  margin-right: 16px;
}
.UI-V2 .audit-filter .toolbar .header .content .module-title {
  position: relative;
  padding: 16px;
  height: 80px;
  flex-shrink: 0;
}
.UI-V2 .audit-filter .toolbar .header .content .module-title[hidden] {
  display: none !important;
}
.UI-V2 .audit-filter .toolbar .header .content .module-title.selectable {
  border-radius: 8px;
  cursor: pointer;
}
.UI-V2 .audit-filter .toolbar .header .content .module-title.selectable mat-select {
  position: absolute;
  opacity: 0;
  pointer-events: none;
  height: 100%;
}
.UI-V2 .audit-filter .toolbar .header .content .module-title .info {
  margin-right: 16px;
}
.UI-V2 .audit-filter .toolbar .header .content .module-title .info h5 {
  font-weight: 400;
}
.UI-V2 .audit-filter .toolbar .header .content .ou-select {
  position: relative;
  padding: 16px;
  height: 80px;
  border-radius: 8px;
  cursor: pointer;
  flex-shrink: 0;
  max-width: 40vw;
}
.UI-V2 .audit-filter .toolbar .header .content .ou-select .info {
  margin-right: 16px;
}
.UI-V2 .audit-filter .toolbar .header .content .ou-select .info h5 {
  font-weight: 400;
}
.UI-V2 .audit-filter .toolbar .header .content .ou-select mat-select {
  position: absolute;
  opacity: 0;
  pointer-events: none;
  height: 100%;
}
.UI-V2 .audit-filter .toolbar .search-container {
  box-sizing: border-box;
  float: right;
  width: auto;
}
.UI-V2 .audit-filter .toolbar .search-container .search {
  margin-right: 16px;
  box-sizing: border-box;
  display: inline-block;
  max-width: calc(100% - 74px);
  height: 80px;
  position: relative;
  width: 100%;
}
.UI-V2 .audit-filter .toolbar .search-container .search .content {
  height: 100%;
  border-radius: 40px;
  padding: 0 16px;
  border: none;
  transition: color 0.1s ease, background 0.15s ease;
}
.UI-V2 .audit-filter .toolbar .search-container .search .content label {
  top: 100%;
  font-size: 14px;
  line-height: 30px;
  bottom: 0;
  position: absolute;
  opacity: 0;
  transition: opacity 0.1s ease;
}
.UI-V2 .audit-filter .toolbar .search-container .search .content:hover, .UI-V2 .audit-filter .toolbar .search-container .search .content:active, .UI-V2 .audit-filter .toolbar .search-container .search .content:focus, .UI-V2 .audit-filter .toolbar .search-container .search .content:focus-within {
  transition: background 0.15s ease;
}
.UI-V2 .audit-filter .toolbar .search-container .search .content:hover label, .UI-V2 .audit-filter .toolbar .search-container .search .content:active label, .UI-V2 .audit-filter .toolbar .search-container .search .content:focus label, .UI-V2 .audit-filter .toolbar .search-container .search .content:focus-within label {
  opacity: 1;
  transition: opacity 0.1s ease;
}
.UI-V2 .audit-filter .toolbar .search-container .search .content input {
  line-height: 80px;
  border: none;
  background: none;
  font-size: 18px;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}
.UI-V2 .audit-filter .toolbar .search-container .search button {
  min-width: unset;
  border-radius: 20px;
}
.UI-V2 .audit-filter .toolbar .search-container .search button.mat-button .mat-button-wrapper {
  display: flex;
  align-items: center;
  height: 40px;
}
.UI-V2 .audit-filter .toolbar .search-container .search button.mat-button .mat-button-wrapper mat-icon {
  margin-right: 8px;
}
.UI-V2 .audit-filter .toolbar .search-container .search button.mat-primary .mat-icon {
  color: unset !important;
}
.UI-V2 .audit-filter .filter-container {
  max-height: calc(100vh - 32px - 112px);
  overflow-y: scroll;
  padding: 0 8px 0 16px;
  width: 100%;
  position: relative;
  box-sizing: border-box;
}
.UI-V2 .audit-filter .filter-container .form-body {
  padding: 0 16px !important;
  border-radius: 8px !important;
  margin: 0 auto 16px;
  box-sizing: border-box;
}
.UI-V2 .audit-filter .filter-container .form-body .line {
  padding: 16px 0;
}
.UI-V2 .audit-filter .filter-container .form-body .clean-autocomple {
  margin-bottom: 12px;
}
.UI-V2 .audit-filter .filter-container .form-body .clean-autocomple .mat-form-field {
  margin-bottom: 0 !important;
}
.UI-V2 .audit-filter .filter-container .form-body .clean-autocomple h3.mat-subheader {
  padding: 0 8px;
}
.UI-V2 .audit-filter .filter-container .form-body .clean-autocomple h3.mat-subheader mat-icon {
  margin-right: 16px;
}
.UI-V2 .audit-filter .filter-container .form-body mat-form-field {
  width: 100%;
  margin-bottom: 12px;
}
.UI-V2 .audit-filter .filter-container .form-body mat-form-field .mat-form-field-prefix {
  padding: 0;
  display: flex;
  align-items: center;
}
.UI-V2 .audit-filter .filter-container .filter-footer {
  margin-bottom: 16px;
}
.UI-V2 .audit-result {
  position: relative;
}
.UI-V2 .audit-result .actions {
  margin-right: 16px;
}
.UI-V2 .audit-result .actions .mat-stroked-button {
  border-radius: 20px;
}
.UI-V2 .audit-result .actions .mat-stroked-button .mat-button-wrapper {
  display: flex;
  align-items: center;
  height: 40px;
}
.UI-V2 .audit-result .actions .mat-stroked-button .mat-button-wrapper mat-icon {
  margin-right: 8px;
}
.UI-V2 .audit-result .load-window.new {
  top: 0 !important;
  height: 100% !important;
}
.UI-V2 .audit-result .result-container {
  height: 100%;
}
.UI-V2 .audit-result .result-container .toolbar {
  margin: 16px;
  padding: 0;
  max-height: 80px !important;
  border-radius: 8px;
}
.UI-V2 .audit-result .result-container .toolbar .header {
  display: inline-block !important;
  margin-right: 16px;
}
.UI-V2 .audit-result .result-container .toolbar .header .content .module-title {
  position: relative;
  padding: 16px;
  height: 80px;
  flex-shrink: 0;
}
.UI-V2 .audit-result .result-container .toolbar .header .content .module-title[hidden] {
  display: none !important;
}
.UI-V2 .audit-result .result-container .toolbar .header .content .module-title.selectable {
  border-radius: 8px;
  cursor: pointer;
}
.UI-V2 .audit-result .result-container .toolbar .header .content .module-title.selectable mat-select {
  position: absolute;
  opacity: 0;
  pointer-events: none;
  height: 100%;
}
.UI-V2 .audit-result .result-container .toolbar .header .content .module-title .info {
  margin-right: 16px;
}
.UI-V2 .audit-result .result-container .toolbar .header .content .module-title .info h5 {
  font-weight: 400;
}
.UI-V2 .audit-result .result-container .toolbar .header .content .ou-select {
  position: relative;
  padding: 16px;
  height: 80px;
  border-radius: 8px;
  cursor: pointer;
  flex-shrink: 0;
  max-width: 40vw;
}
.UI-V2 .audit-result .result-container .toolbar .header .content .ou-select .info {
  margin-right: 16px;
}
.UI-V2 .audit-result .result-container .toolbar .header .content .ou-select .info h5 {
  font-weight: 400;
}
.UI-V2 .audit-result .result-container .toolbar .header .content .ou-select mat-select {
  position: absolute;
  opacity: 0;
  pointer-events: none;
  height: 100%;
}
.UI-V2 .audit-result .result-container .toolbar .search-container {
  box-sizing: border-box;
  float: right;
  width: auto;
}
.UI-V2 .audit-result .result-container .toolbar .search-container .search {
  margin-right: 16px;
  box-sizing: border-box;
  display: inline-block;
  max-width: calc(100% - 74px);
  height: 80px;
  position: relative;
  width: 100%;
}
.UI-V2 .audit-result .result-container .toolbar .search-container .search .content {
  height: 100%;
  border-radius: 40px;
  padding: 0 16px;
  border: none;
  transition: color 0.1s ease, background 0.15s ease;
}
.UI-V2 .audit-result .result-container .toolbar .search-container .search .content label {
  top: 100%;
  font-size: 12px;
  line-height: 1.25;
  bottom: 0;
  position: absolute;
  opacity: 0;
  transition: opacity 0.1s ease;
}
.UI-V2 .audit-result .result-container .toolbar .search-container .search .content:hover, .UI-V2 .audit-result .result-container .toolbar .search-container .search .content:active, .UI-V2 .audit-result .result-container .toolbar .search-container .search .content:focus, .UI-V2 .audit-result .result-container .toolbar .search-container .search .content:focus-within {
  transition: background 0.15s ease;
}
.UI-V2 .audit-result .result-container .toolbar .search-container .search .content:hover label, .UI-V2 .audit-result .result-container .toolbar .search-container .search .content:active label, .UI-V2 .audit-result .result-container .toolbar .search-container .search .content:focus label, .UI-V2 .audit-result .result-container .toolbar .search-container .search .content:focus-within label {
  opacity: 1;
  transition: opacity 0.1s ease;
}
.UI-V2 .audit-result .result-container .toolbar .search-container .search .content input {
  line-height: 80px;
  border: none;
  background: none;
  font-size: 18px;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}
.UI-V2 .audit-result .result-container .toolbar .search-container .search button {
  min-width: unset;
  border-radius: 20px;
}
.UI-V2 .audit-result .result-container .toolbar .search-container .search button.mat-button .mat-button-wrapper {
  display: flex;
  align-items: center;
  height: 40px;
}
.UI-V2 .audit-result .result-container .toolbar .search-container .search button.mat-button .mat-button-wrapper mat-icon {
  margin-right: 8px;
}
.UI-V2 .audit-result .result-container .toolbar .search-container .search button.mat-primary .mat-icon {
  color: unset !important;
}
.UI-V2 .audit-result .result-container .new-table {
  min-height: calc(100% - 192px - 56px - 16px);
  margin-top: 16px;
}
.UI-V2 .audit-result .result-container .new-table[hidden] {
  display: none !important;
}
.UI-V2 .audit-result .result-container .new-table.w-main-action {
  min-height: calc(100% - 192px - 56px - 97px - 16px);
  flex-grow: 1;
  flex-shrink: 1;
}
.UI-V2 .audit-result .result-container .new-table.documents {
  margin-top: 16px;
  min-height: calc(100% - 192px - 56px - 16px) !important;
}
.UI-V2 .audit-result .result-container .new-table.documents .w-12 {
  width: calc(100% - 72px - 72px - 72px - 56px) !important;
  min-width: calc(100% - 72px - 72px - 72px - 56px) !important;
  position: unset !important;
}
.UI-V2 .audit-result .result-container .new-table.documents .mat-header-row.sticky .col-selector {
  right: 32px !important;
}
.UI-V2 .audit-result .result-container .new-table app-cs-grid-control datatable-header {
  border-bottom: none !important;
}
.UI-V2 .audit-result .result-container .new-table app-cs-grid-control datatable-header .datatable-row-center {
  padding: 0 16px 0 72px;
  box-sizing: border-box;
}
.UI-V2 .audit-result .result-container .new-table app-cs-grid-control datatable-header * {
  font-weight: 800;
}
.UI-V2 .audit-result .result-container .new-table app-cs-grid-control datatable-body-row .datatable-row-center {
  padding: 0 16px 0 72px;
  box-sizing: border-box;
}
.UI-V2 .audit-result .result-container .new-table .mat-header-row.sticky {
  border-bottom: none;
  padding: 0 16px;
}
.UI-V2 .audit-result .result-container .new-table .mat-header-row.sticky .w-12 {
  position: relative;
}
.UI-V2 .audit-result .result-container .new-table .mat-header-row.sticky .w-12 .col-selector {
  position: absolute;
  right: 16px;
}
.UI-V2 .audit-result .result-container .new-table .mat-header-row.sticky * {
  font-weight: 800;
}
.UI-V2 .audit-result .result-container .new-table .mat-header-row.sticky .mat-header-cell {
  padding-left: 0 !important;
}
.UI-V2 .audit-result .result-container .new-table .mat-header-row.sticky .mat-header-cell > div, .UI-V2 .audit-result .result-container .new-table .mat-header-row.sticky .mat-header-cell > span {
  overflow: hidden;
}
.UI-V2 .audit-result .result-container .new-table .mat-header-row.sticky .mat-header-cell mat-icon.indication[hidden] {
  display: none !important;
}
.UI-V2 .audit-result .result-container .new-table .mat-header-row.sticky .mat-header-cell mat-icon.indication {
  opacity: 0;
  min-width: unset;
}
.UI-V2 .audit-result .result-container .new-table .mat-header-row.sticky .mat-header-cell .sorteable:hover .mat-icon.indication {
  opacity: 1;
}
.UI-V2 .audit-result .result-container .new-table .mat-header-row.sticky .mat-header-cell .sorteable .sort-icon {
  position: relative !important;
}
.UI-V2 .audit-result .result-container .new-table .mat-row {
  padding: 0 16px;
}
.UI-V2 .audit-result .result-container .new-table .mat-row .mat-cell {
  padding-left: 0 !important;
}
.UI-V2 .audit-result .result-container .new-table .mat-row .sticky-actions {
  right: 16px;
}
.UI-V2 .audit-result .result-container .new-table {
  margin-top: 0 !important;
}
.UI-V2 .audit-result .result-container .new-table .mat-row .mat-cell.w-4,
.UI-V2 .audit-result .result-container .new-table .mat-header-row .mat-header-cell.w-4 {
  padding-left: 16px !important;
}
.UI-V2 .audit-detail-container {
  position: absolute;
  right: -30%;
  transition: right 0.2s ease;
  height: auto !important;
}
.UI-V2 .audit-detail-container .side {
  height: 100%;
}
.UI-V2 .audit-detail-container .side .toolbar {
  padding: 16px;
  height: 80px;
  margin: 16px 0;
}
.UI-V2 .audit-detail-container .side .container {
  max-height: calc(100vh - 32px - 112px);
  height: 100%;
  overflow-y: scroll;
  padding: 0 8px 16px 16px;
  width: 100%;
  position: relative;
  box-sizing: border-box;
}
.UI-V2 .audit-detail-container .side .container .mat-list-base {
  border-radius: 8px;
}
.UI-V2 .audit-detail-container .side .container .mat-list-base mat-list-item {
  background: none !important;
}
.UI-V2 .audit-detail-container .side .container .mat-list-base mat-list-item .mat-line {
  font-size: 0.67em;
}
.UI-V2 .audit-detail-container .side .container .mat-list-base mat-list-item .mat-line b {
  font-size: 16px !important;
}

.UI-V2 .inbox-container {
  position: relative;
}
.UI-V2 .inbox-container .load-window {
  left: 0;
  top: 112px;
  height: calc(100% - 112px) !important;
}
.UI-V2 .inbox-container h3 {
  font-weight: 500;
}
.UI-V2 .inbox-container h5 {
  font-weight: 300;
}
.UI-V2 .inbox-container .toolbar {
  height: 80px;
  margin: 16px;
  border-radius: 8px;
  padding: 0 16px;
}
.UI-V2 .inbox-container .toolbar mat-icon:not(.complex) {
  width: 40px;
  height: 40px;
  font-size: 24px !important;
}
.UI-V2 .inbox-container .toolbar button.mat-icon-button mat-icon {
  font-size: 20px !important;
}
.UI-V2 .inbox-container .toolbar h3,
.UI-V2 .inbox-container .toolbar h5 {
  margin: 0;
}
.UI-V2 .inbox-container .container {
  padding: 0 8px 16px 16px;
  overflow-y: scroll;
  max-height: calc(100% - 112px);
  box-sizing: border-box;
}
.UI-V2 .inbox-container .container mat-accordion {
  background: none !important;
}
.UI-V2 .inbox-container .container mat-accordion mat-expansion-panel {
  border-radius: 8px !important;
  overflow: hidden;
}
.UI-V2 .inbox-container .container mat-accordion mat-expansion-panel:not(mat-expansion-panel:last-child) {
  margin-bottom: 16px !important;
}
.UI-V2 .inbox-container .container mat-accordion mat-expansion-panel.mat-expanded mat-expansion-panel-header {
  height: 128px !important;
  border-radius: 0 !important;
  box-sizing: border-box;
  padding-bottom: 56px !important;
}
.UI-V2 .inbox-container .container mat-accordion mat-expansion-panel mat-expansion-panel-header {
  padding: 16px !important;
  height: 128px !important;
  box-sizing: border-box;
  padding-bottom: 56px !important;
  position: relative;
}
.UI-V2 .inbox-container .container mat-accordion mat-expansion-panel mat-expansion-panel-header .signature-inbox-header .mat-icon,
.UI-V2 .inbox-container .container mat-accordion mat-expansion-panel mat-expansion-panel-header .signature-inbox-header b {
  font-size: 1.17em;
}
.UI-V2 .inbox-container .container mat-accordion mat-expansion-panel mat-expansion-panel-header .actions {
  position: absolute;
  bottom: 16px;
  width: calc(100% - 32px);
  left: 0;
  padding: 0 16px;
}
.UI-V2 .inbox-container .container mat-accordion mat-expansion-panel mat-expansion-panel-header .actions button {
  border-radius: 20px;
  float: right;
}
.UI-V2 .inbox-container .container mat-accordion mat-expansion-panel .mat-expansion-panel-body {
  padding: 0 16px 16px !important;
}
.UI-V2 .inbox-container .container mat-accordion mat-expansion-panel .mat-expansion-panel-body .item {
  padding: 16px;
  border-radius: 8px;
  cursor: pointer;
}
.UI-V2 .inbox-container .container mat-accordion mat-expansion-panel .mat-expansion-panel-body .item button mat-icon {
  font-size: 20px !important;
}
.UI-V2 .inbox-container .container mat-accordion mat-expansion-panel .mat-expansion-panel-body .item h3 {
  margin: 0;
}
.UI-V2 .inbox-container .container mat-accordion mat-expansion-panel .mat-expansion-panel-body .item + .item {
  margin-top: 16px;
}
.UI-V2 .inbox-container .container mat-accordion mat-expansion-panel .mat-expansion-panel-body .item.active {
  cursor: default;
}
.UI-V2 .work-space h5,
.UI-V2 .work-space h6 {
  font-weight: 500;
}
.UI-V2 .work-space .main {
  position: relative;
}
.UI-V2 .work-space .main .load-window {
  left: 0;
  top: 0;
  height: 100%;
}
.UI-V2 .work-space .main .container {
  height: calc(100% - 112px - 96px);
}
.UI-V2 .work-space .main .container .content-scroll {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: scroll;
  max-height: calc(100% - 56px);
  box-sizing: border-box;
  padding: 8px 0 8px 8px;
}
.UI-V2 .work-space .helper mat-hint h4 {
  line-height: 1 !important;
}
.UI-V2 .work-space .helper mat-form-field {
  width: 96px;
}
.UI-V2 .work-space .helper mat-form-field .mat-form-field-wrapper {
  padding-bottom: 0;
}
.UI-V2 .work-space .helper mat-form-field .mat-form-field-underline {
  display: none;
}
.UI-V2 .work-space .helper .actions .mat-button-wrapper {
  justify-content: center;
}
.UI-V2 .work-space .helper .actions .mat-button-wrapper mat-icon {
  margin-right: 0;
}

app-chapa {
  height: 100%;
  width: 100%;
  display: block;
}

.upload-warning {
  height: calc(100% - 64px);
}

.adv-menu-content.input-content {
  padding: 16px 46px;
}

.scroller {
  max-height: calc(100vh - 32px - 64px - 68px);
  overflow: auto !important;
  overflow: overlay !important;
  padding: 16px 24px 16px 16px;
  box-sizing: border-box;
}

mat-radio-button.disabled {
  cursor: pointer;
  pointer-events: none;
}

.detail app-process-detail .detail-toolbar .mat-avatar h3,
.detail app-process-detail .detail-toolbar .mat-avatar h5,
.detail app-process-detail .detail-toolbar .mat-avatar h3 b,
.detail app-automatic-process-detail .detail-toolbar .mat-avatar h3,
.detail app-automatic-process-detail .detail-toolbar .mat-avatar h5,
.detail app-automatic-process-detail .detail-toolbar .mat-avatar h3 b {
  width: 100% !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.detail app-process-detail .detail-toolbar .mat-avatar .toolbar-line.container-ou,
.detail app-automatic-process-detail .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  padding-right: 16px !important;
  display: grid !important;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(2, auto);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}
.detail app-process-detail .detail-toolbar .mat-avatar .toolbar-line.container-ou .invisible-container.ou-selector,
.detail app-automatic-process-detail .detail-toolbar .mat-avatar .toolbar-line.container-ou .invisible-container.ou-selector {
  width: 100% !important;
  overflow: hidden !important;
  padding-right: 16px !important;
}
.detail app-process-detail .detail-toolbar .mat-avatar .toolbar-line.container-ou .invisible-container.ou-selector > div,
.detail app-automatic-process-detail .detail-toolbar .mat-avatar .toolbar-line.container-ou .invisible-container.ou-selector > div {
  width: 100% !important;
}
.detail app-process-detail .detail-toolbar .mat-avatar .toolbar-line.container-ou .invisible-container.ou-selector + div,
.detail app-automatic-process-detail .detail-toolbar .mat-avatar .toolbar-line.container-ou .invisible-container.ou-selector + div {
  overflow: hidden;
}
.detail app-process-detail .detail-toolbar .mat-avatar .toolbar-line.container-ou .invisible-container.ou-selector + div .title,
.detail app-automatic-process-detail .detail-toolbar .mat-avatar .toolbar-line.container-ou .invisible-container.ou-selector + div .title {
  width: calc(100% - 48px) !important;
}
.detail app-process-detail .detail-toolbar .mat-avatar .toolbar-line.container-ou .invisible-container .title,
.detail app-automatic-process-detail .detail-toolbar .mat-avatar .toolbar-line.container-ou .invisible-container .title {
  width: calc(100% - 48px) !important;
}
.detail app-automatic-process-detail app-chapa {
  transition: all 0.2s ease;
}
.detail app-automatic-process-detail app-chapa.minimize {
  height: 40px;
  position: absolute;
  top: 12px;
  right: 72px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  padding: 0 16px;
  border-radius: 20px;
  transition: all 0.2s ease;
}
.detail app-automatic-process-detail app-chapa.minimize::before {
  content: "\f04c";
  margin-right: 8px;
}
.detail app-automatic-process-detail app-chapa.minimize::after {
  font-family: roboto;
  content: "Proceso en Pausa";
  font-weight: bold;
}
.detail app-automatic-process-detail app-chapa.minimize.purged::before {
  content: "\f7e4";
}
.detail app-automatic-process-detail app-chapa.minimize.purged::after {
  content: "Proceso Vencido" !important;
}
.detail app-automatic-process-detail app-chapa.minimize .upload-warning {
  display: none;
}
.detail app-process-detail app-chapa {
  transition: all 0.2s ease;
}
.detail app-process-detail app-chapa.minimize {
  height: 40px;
  position: absolute;
  top: 12px;
  right: 16px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  padding: 0 16px;
  border-radius: 20px;
  transition: all 0.2s ease;
}
.detail app-process-detail app-chapa.minimize::before {
  content: "\f04c";
  margin-right: 8px;
}
.detail app-process-detail app-chapa.minimize::after {
  font-family: roboto;
  content: "Proceso en Pausa";
  font-weight: bold;
}
.detail app-process-detail app-chapa.minimize.purged::before {
  content: "\f7e4";
}
.detail app-process-detail app-chapa.minimize.purged::after {
  content: "Proceso Vencido" !important;
}
.detail app-process-detail app-chapa.minimize .upload-warning {
  display: none;
}
.detail .new-table.automatic-processes {
  height: calc(100% - 65px - 92px - 96px - 56px);
  min-height: calc(100% - 65px - 92px - 96px - 56px);
}
.detail .processes-status {
  height: 152px;
  padding: 16px;
}
.detail .processes-status .status-title {
  height: 30px;
  margin-bottom: 16px;
  font-weight: bold;
}
.detail .processes-status .status-bar {
  height: 64px;
}
.detail .processes-status .status-bar .bar-header h1,
.detail .processes-status .status-bar .bar-header h6 {
  margin: 0;
}
.detail .processes-status .status-bar .bar-graph {
  height: 20px;
  transition: all 0.2s ease 0.8s;
  border-radius: 20px;
  overflow: hidden;
}
.detail .processes-status .status-bar .bar-graph .graph-ok {
  height: 100%;
  transition: all 0.2s ease 0.8s;
}
.detail .processes-status .status-bar .bar-graph .graph-not-ok {
  height: 100%;
  transition: all 0.2s ease 0.8s;
}
.detail .processes-status .status-resume {
  height: 48px;
}
.detail .processes-status .status-resume b {
  height: 30px;
  line-height: 30px;
  border-radius: 15px;
  min-width: 30px;
  text-align: center;
  padding: 0 8px;
  box-sizing: border-box;
}
.detail.finished-process-inbox .fake-intro, .detail.in-process-inbox .fake-intro, .detail.erased-process-inbox .fake-intro, .detail.error-process-inbox .fake-intro {
  display: none;
}

.options-menu .mat-menu-content {
  padding: 0 !important;
}

.dash-container {
  height: calc(100% - 64px) !important;
  justify-content: center;
  align-items: center;
}
.dash-container .content-chart {
  width: 50%;
  min-width: 400px;
}
.dash-container .content-chart app-cs-charts-control {
  width: 100%;
  display: block;
}

app-analytics .toolbar .module-title.selectable {
  width: 100%;
}
app-analytics .toolbar .module-title.selectable .info {
  width: 100%;
  margin-right: 0 !important;
}
app-analytics .toolbar .module-title.selectable .info .info-text {
  width: 100%;
}
app-analytics .toolbar .module-title.selectable .info .info-text + mat-icon {
  margin-right: 0 !important;
}

app-actor-find .main-overlay {
  position: absolute;
  bottom: 0;
  height: 100%;
  opacity: 0;
  z-index: 2;
}
app-actor-find .main-overlay.enabled {
  width: 100% !important;
  opacity: 1;
}
app-actor-find .icon-complex {
  position: relative;
  height: 40px;
  width: 40px;
  padding: 0 !important;
}
app-actor-find .icon-complex mat-icon.complex {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 16px !important;
  height: 20px !important;
  width: 20px !important;
  padding: 0 !important;
}
app-actor-find .navigation {
  position: relative;
}
app-actor-find .navigation.detail-open {
  overflow: hidden;
  width: 0 !important;
  flex: 0 !important;
}
app-actor-find .navigation .load-window {
  left: 0;
  top: 112px;
  height: calc(100% - 112px) !important;
}
app-actor-find .navigation h3 {
  font-weight: 500;
}
app-actor-find .navigation h5 {
  font-weight: 300;
}
app-actor-find .navigation .toolbar {
  height: 80px;
  margin: 16px;
  border-radius: 8px;
  padding: 0 16px;
}
app-actor-find .navigation .toolbar h3,
app-actor-find .navigation .toolbar h5 {
  margin: 0;
}
app-actor-find .navigation .container {
  padding: 0 8px 16px 16px;
  overflow-y: scroll;
  max-height: calc(100% - 112px);
  box-sizing: border-box;
}
app-actor-find .navigation .container .helper {
  padding: 0 16px;
  height: 56px;
  margin-bottom: 16px;
}
app-actor-find .navigation .container .item {
  padding: 16px;
  border-radius: 8px;
  cursor: pointer;
}
app-actor-find .navigation .container .item h3 {
  margin: 0;
}
app-actor-find .navigation .container .item + .item {
  margin-top: 16px;
}
app-actor-find .navigation .container .item.active {
  cursor: default;
}
app-actor-find .work-space h5,
app-actor-find .work-space h6 {
  font-weight: 500;
}
app-actor-find .work-space .main {
  position: relative;
}
app-actor-find .work-space .main .load-window {
  left: 0;
  top: 0;
  height: 100%;
}
app-actor-find .work-space .main.side-open .container .content-scroll .item {
  width: calc(33.3333333333% - 16px);
}
app-actor-find .work-space .main .container {
  min-height: calc(100vh - 32px - 192px - 97px);
  flex-grow: 1;
  flex-shrink: 1;
}
app-actor-find .work-space .main .container .content-scroll {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: scroll;
  max-height: calc(100% - 56px);
  box-sizing: border-box;
  padding: 8px 0 8px 8px;
}
app-actor-find .work-space .main .container .item {
  margin: 8px;
  padding: 16px;
  border-radius: 8px;
  width: calc(33.3333333333% - 16px);
  cursor: pointer;
}
app-actor-find .work-space .main .container .item .subtitle {
  text-transform: lowercase;
}
app-actor-find .work-space .main .container .item.full-page {
  width: calc(25% - 16px);
}
app-actor-find .work-space .main .container .item.disabled {
  opacity: 0.7;
}
app-actor-find .work-space .main .container .item.disabled.selected {
  opacity: 1;
  cursor: default;
}
app-actor-find .work-space .main .container .item mat-icon.complex {
  bottom: 0;
  right: 0;
}
app-actor-find .work-space .main .container .item h5 {
  margin: 0;
}
app-actor-find .work-space .main .container .item h3 {
  margin: 0;
}
app-actor-find .work-space .main .container .item.selected {
  cursor: default;
}
app-actor-find .work-space .side {
  height: 100%;
}
app-actor-find .work-space .side .edition-line {
  border-radius: 8px;
  padding: 16px;
  margin: 16px 0;
  position: relative;
  overflow: hidden;
}
app-actor-find .work-space .side .edition-line mat-progress-bar {
  bottom: 0;
  left: 0;
}
app-actor-find .work-space .side .adj-container {
  padding: 0 24px !important;
}
app-actor-find .work-space .side mat-form-field {
  width: 100%;
}
app-actor-find .work-space .side mat-form-field.mat-form-field-disabled * {
  cursor: not-allowed;
}
app-actor-find .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-underline {
  height: 2px !important;
}
app-actor-find .work-space .side mat-form-field .mat-form-field-flex {
  border-radius: 4px 4px 0 0 !important;
  padding: 8px 8px 0 !important;
  min-height: 56px !important;
  align-items: unset !important;
}
app-actor-find .work-space .side mat-form-field .mat-form-field-flex .mat-form-field-prefix {
  padding: 0 !important;
  display: flex;
  align-items: center;
}
app-actor-find .work-space .side mat-checkbox {
  align-items: center;
  height: 56px;
  display: flex;
  margin-bottom: 16px;
  border-radius: 4px;
  padding: 0 8px;
}
app-actor-find .work-space .side mat-checkbox .mat-checkbox-layout {
  width: 100%;
  justify-content: flex-start;
  height: 56px;
  align-items: center;
}
app-actor-find .work-space .side mat-checkbox .mat-checkbox-layout .mat-checkbox-inner-container {
  margin: 12px;
}
app-actor-find .work-space .side mat-checkbox .mat-checkbox-layout .mat-checkbox-label {
  width: 100%;
  padding-left: 16px;
}
app-actor-find .work-space .side .hint {
  margin: 0 0 16px;
  font-weight: 700;
  width: 100%;
  text-align: center;
}
app-actor-find .work-space .side .toolbar {
  height: 112px;
  padding: 16px 32px;
}
app-actor-find .work-space .side .toolbar .header {
  width: 100%;
}
app-actor-find .work-space .side .toolbar .header h3 {
  margin: 0;
}
app-actor-find .work-space .side .toolbar .header h5 {
  margin: 0;
}
app-actor-find .work-space .side .container {
  padding: 0 0 16px 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: scroll;
  max-height: calc(100% - 112px);
  box-sizing: border-box;
}
app-actor-find .work-space .side .container .role-resume {
  width: calc(100% - 16px);
  padding: 16px;
  border-radius: 8px;
  margin: 0 8px;
  position: relative;
}
app-actor-find .work-space .side .container .role-resume .line {
  padding: 8px 16px;
  position: relative;
  border-radius: 4px;
}
app-actor-find .work-space .side .container .role-resume .line.editable:hover button {
  opacity: 1;
}
app-actor-find .work-space .side .container .role-resume .line.editable button {
  opacity: 0;
  position: absolute;
  right: 16px;
  top: 4px;
}
app-actor-find .work-space .side .container .role-resume .line .header {
  width: 100%;
}
app-actor-find .work-space .side .container .role-resume .line .header .info {
  max-width: calc(100% - 40px - 16px - 16px);
}
app-actor-find .work-space .side .container .role-resume .line .header .info h4,
app-actor-find .work-space .side .container .role-resume .line .header .info h6 {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
app-actor-find .work-space .side .container .section-title {
  width: 100%;
  height: 56px;
  margin: 8px;
  padding: 16px;
}
app-actor-find .work-space .side .container .section-title h3 {
  margin: 0;
}
app-actor-find .work-space .side .container .section-title h5 {
  margin: 0;
  font-size: 18px;
  font-weight: 700;
}
app-actor-find .work-space .side .container .section-resume {
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  margin: 0 8px;
  max-width: calc(100% - 50px);
}
app-actor-find .work-space .side .container .section-resume .line {
  padding: 8px 16px;
  border-radius: 4px;
  position: relative;
}
app-actor-find .work-space .side .container .section-resume .line.editable:hover button {
  opacity: 1;
}
app-actor-find .work-space .side .container .section-resume .line.editable button {
  opacity: 0;
  position: absolute;
  right: 16px;
  top: 4px;
}
app-actor-find .work-space .side .container .section-resume .line .header {
  width: 100%;
}
app-actor-find .work-space .side .container .section-resume .line .header .icon-complex mat-icon {
  width: 40px;
  height: 40px;
}
app-actor-find .work-space .side .container .section-resume .line .header .info {
  max-width: calc(100% - 40px - 16px - 16px);
}
app-actor-find .work-space .side .container .section-resume .line .header .info h4 {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
app-actor-find .work-space .side .container .section-resume .line .header .info h6 {
  font-weight: 700;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
app-actor-find .work-space .side .container .section-resume .line ul {
  margin-top: 0;
  margin-bottom: 0;
  padding-inline-start: 16px;
  font-weight: 400;
}
app-actor-find .work-space .side .container .section-resume .line ul + ul {
  margin-top: 16px;
}
app-actor-find .work-space .side .container .section-resume .line.head {
  padding: 8px 16px;
}
app-actor-find .work-space .side .container app-certificates {
  width: 100%;
}
app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee-title {
  height: 56px;
  margin: 8px;
  padding: 16px;
  box-sizing: border-box;
}
app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee-title .title-text {
  display: flex;
  height: 100%;
  align-items: center;
}
app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee-title .title-text mat-icon {
  height: 40px;
  margin-right: 16px;
  font-size: 20px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee-title .title-text .title_text-main {
  font-size: 18px;
  font-weight: 700;
}
app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body {
  padding: 16px;
  border-radius: 8px;
  box-sizing: border-box;
  margin: 0 8px;
}
app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text {
  padding: 8px 16px;
  border-radius: 4px;
  position: relative;
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: center flex-start;
  align-items: center;
}
app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  margin-right: 16px;
}
app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-side {
  font-weight: 700;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.67em;
}
app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-side u {
  cursor: help;
}
app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
}
app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line mat-basic-chip {
  padding: 4px 12px 4px 8px;
}
app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line mat-basic-chip mat-icon {
  width: 20px !important;
  min-width: 20px !important;
}
app-actor-find .work-space .side.edition {
  z-index: 2;
}
app-actor-find .work-space .side.edition .toolbar .header {
  width: 100%;
}
app-actor-find .work-space .side.edition .container {
  padding: 0;
  overflow-y: unset !important;
}
app-actor-find .work-space .side.edition .container app-role-user-add {
  display: block !important;
  width: 100%;
  box-sizing: border-box !important;
}
app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper {
  width: 100% !important;
  min-width: unset;
  background-color: unset !important;
}
app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container {
  padding: 0;
}
app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container form {
  max-height: calc(100vh - 32px - 112px);
  overflow-y: scroll;
  padding: 0 8px 0 16px;
  width: 100%;
  position: relative;
  box-sizing: border-box;
}
app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body {
  padding: 0 16px !important;
  border-radius: 8px !important;
  margin: 0 auto;
  box-sizing: border-box;
}
app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body + .form-body {
  margin-top: 16px;
}
app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line {
  padding: 16px 8px;
}
app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header {
  width: 100%;
}
app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header h4 {
  margin: 0;
}
app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header h6 {
  margin: 0;
}
app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body mat-radio-group {
  padding-bottom: 24px;
}
app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body mat-radio-group mat-radio-button {
  height: 40px;
  display: flex !important;
  align-items: center;
}
app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body mat-radio-group mat-radio-button .mat-radio-container {
  margin-left: 18px;
}
app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body mat-radio-group mat-radio-button .mat-radio-label-content {
  padding-left: 26px;
}
app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-actions {
  border-top: unset !important;
  padding: 16px 0 !important;
  display: flex;
  flex-direction: row-reverse;
  position: sticky;
  bottom: 0;
}
app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-actions button + button {
  margin-right: 12px;
}
app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume {
  max-height: calc(100vh - 32px - 112px);
  overflow-y: scroll;
  padding: 0 8px 0 16px;
  width: 100%;
  position: relative;
}
app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body {
  padding: 0 !important;
  overflow: hidden;
}
app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line {
  padding: 16px 40px;
}
app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header h6 {
  font-weight: 700;
}
app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header + .header {
  margin-top: 16px;
}
app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line ul {
  margin-top: 0;
  margin-bottom: 0;
  padding-inline-start: 16px;
  font-weight: 400;
}
app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line ul + ul {
  margin-top: 16px;
}
app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head {
  padding: 8px 16px;
}
app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .section-title {
  margin: 8px 0 !important;
}
app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .section-title h5 {
  font-weight: 500 !important;
}
app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume h6.hint {
  margin: 16px 0 !important;
  padding: 16px;
  width: 100%;
  box-sizing: border-box;
  text-align: start !important;
  font-size: 14px;
  border-radius: 8px;
}
app-actor-find .work-space app-role-user-detail.disabled .side .container .role-resume .line.editable {
  pointer-events: none;
}
app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line.editable {
  pointer-events: none;
}
app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .mat-icon-button {
  opacity: 0;
  pointer-events: none;
}
app-actor-find .work-space app-role-user-detail.disabled .side .container app-certificates {
  width: 100%;
}
app-actor-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  margin-right: 16px;
}

app-analytics-document-set .navigation {
  max-width: 30% !important;
  transition: all 0.2s ease-out;
}
app-analytics-document-set .navigation .toolbar {
  padding: 0 !important;
}
app-analytics-document-set .navigation .toolbar .module-title {
  padding: 0 16px;
}
app-analytics-document-set .navigation .toolbar .module-title.selectable {
  cursor: pointer;
  height: 100%;
  width: 100%;
}
app-analytics-document-set .navigation .toolbar .module-title.selectable .info {
  width: 100%;
  margin-right: 0 !important;
}
app-analytics-document-set .navigation .toolbar .module-title.selectable .info .info-text {
  width: 100%;
}
app-analytics-document-set .navigation .toolbar .module-title.selectable .info .info-text + mat-icon {
  margin-right: 0 !important;
}
app-analytics-document-set .navigation .toolbar .module-title.selectable .invisible-item {
  width: 0 !important;
  overflow: hidden !important;
  position: absolute;
  left: 64px;
  top: 9px;
}
app-analytics-document-set .navigation .sets-container {
  height: calc(100% - 112px);
  display: flex;
  flex-direction: column;
}
app-analytics-document-set .navigation .sets-container .filters {
  padding: 0 32px 16px;
}
app-analytics-document-set .navigation .sets-container .filters mat-form-field {
  width: 100%;
  max-height: 64px;
}
app-analytics-document-set .navigation .sets-container .filters mat-form-field .mat-form-field-prefix {
  margin-right: 8px;
}
app-analytics-document-set .navigation .sets-container .filters mat-form-field .mat-form-field-prefix mat-icon,
app-analytics-document-set .navigation .sets-container .filters mat-form-field .mat-form-field-suffix mat-icon {
  font-size: 16px !important;
  min-width: unset !important;
}
app-analytics-document-set .navigation .sets-container .mat-list-base.sets-list {
  padding: 16px;
  overflow-y: auto;
  overflow-y: overlay;
  overflow-x: hidden;
  box-sizing: border-box;
}
app-analytics-document-set .navigation .sets-container .mat-list-base.sets-list mat-list-item {
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.2s ease-in !important;
}
app-analytics-document-set .navigation .sets-container .mat-list-base.sets-list mat-list-item .mat-list-item-content {
  flex-wrap: wrap !important;
}
app-analytics-document-set .navigation .sets-container .mat-list-base.sets-list mat-list-item .mat-list-item-content .mat-list-text {
  width: 100% !important;
}
app-analytics-document-set .navigation .sets-container .mat-list-base.sets-list mat-list-item .mat-list-item-content .mat-list-text .mat-line h5 {
  text-overflow: ellipsis;
  overflow: hidden;
}
app-analytics-document-set .navigation .sets-container .mat-list-base.sets-list mat-list-item .mat-list-item-content .mat-list-text .mat-line b {
  text-overflow: ellipsis;
  overflow: hidden;
}
app-analytics-document-set .navigation .sets-container .mat-list-base.sets-list mat-list-item .mat-list-item-content .set-status {
  margin-top: 12px;
  width: 100% !important;
  cursor: help;
}
app-analytics-document-set .navigation .sets-container .mat-list-base.sets-list mat-list-item .mat-list-item-content .set-status .value-percentile {
  height: 12px;
  width: 100%;
  border-radius: 6px;
  overflow: hidden;
}
app-analytics-document-set .navigation .sets-container .mat-list-base.sets-list mat-list-item .mat-list-item-content .set-status .value-percentile .undone-value {
  height: 100%;
}
app-analytics-document-set .navigation .sets-container .mat-list-base.sets-list mat-list-item .mat-list-item-content .set-status .value-percentile .not-value {
  height: 100%;
}
app-analytics-document-set .navigation .sets-container .mat-list-base.sets-list mat-list-item .mat-list-item-content .set-status .value-percentile .done-value {
  height: 100%;
}
app-analytics-document-set .navigation .sets-container .mat-list-base.sets-list mat-list-item + .mat-list-item {
  margin-top: 16px;
}
app-analytics-document-set .navigation.results-open {
  overflow: hidden;
  width: 0 !important;
  flex: 0 !important;
  transition: all 0.2s ease-in;
}
app-analytics-document-set .navigation.results-open + .work-space {
  overflow: hidden;
  width: 100% !important;
  transition: all 0.2s ease-out;
}
app-analytics-document-set .work-space {
  width: 70%;
  transition: all 0.2s ease-out;
}
app-analytics-document-set .work-space .main .toolbar .header {
  min-width: 30%;
  margin-right: 0;
}
app-analytics-document-set .work-space .main .toolbar .header .content {
  width: 100%;
}
app-analytics-document-set .work-space .main .toolbar .header .content .module-title {
  width: 100%;
}
app-analytics-document-set .work-space .main .toolbar .header .content .module-title .info {
  width: 100%;
}
app-analytics-document-set .work-space .main .toolbar .header .content .module-title .info .info-text {
  width: 100%;
}
app-analytics-document-set .work-space .main .toolbar .header .content .module-title .info .info-text .set-status {
  width: 100% !important;
  cursor: help;
  margin-top: 4px;
}
app-analytics-document-set .work-space .main .toolbar .header .content .module-title .info .info-text .set-status .value-percentile {
  height: 12px;
  width: 100%;
  border-radius: 6px;
  overflow: hidden;
}
app-analytics-document-set .work-space .main .toolbar .header .content .module-title .info .info-text .set-status .value-percentile .undone-value {
  height: 100%;
}
app-analytics-document-set .work-space .main .toolbar .header .content .module-title .info .info-text .set-status .value-percentile .not-value {
  height: 100%;
}
app-analytics-document-set .work-space .main .toolbar .header .content .module-title .info .info-text .set-status .value-percentile .done-value {
  height: 100%;
}
app-analytics-document-set .work-space .main .toolbar .search-container {
  min-width: 50%;
}
app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar {
  position: relative !important;
  margin-right: 8px;
  height: 8px;
  border-radius: 4px;
  max-width: 40%;
  z-index: 98 !important;
}
app-analytics-document-set .work-space .main .new-table .mat-table .status_bar-count {
  font-size: 10px;
}
app-analytics-document-set .work-space .main.side-open {
  width: 70% !important;
  transition: all 0.2s ease-out;
}
app-analytics-document-set .work-space .main.side-open .toolbar .header {
  min-width: 40%;
}
app-analytics-document-set .work-space .main.side-open .toolbar .search-container {
  min-width: 60%;
}
app-analytics-document-set .work-space .main.side-open + .side {
  width: 30%;
  transition: all 0.2s ease-in;
}
app-analytics-document-set .work-space .side {
  overflow: hidden;
  width: 0;
  flex: 0;
  transition: all 0.2s ease-out;
}
app-analytics-document-set .work-space .side .container {
  padding: 16px !important;
  overflow-y: auto !important;
}
app-analytics-document-set .work-space .side .container mat-list {
  width: 100%;
  border-radius: 8px;
}
app-analytics-document-set .work-space .side .container mat-list mat-list-item:hover .floating-action {
  visibility: visible;
  opacity: 1;
}
app-analytics-document-set .work-space .side .container mat-list mat-list-item .floating-action {
  visibility: hidden;
  opacity: 0;
}

.mat-tooltip-panel {
  pointer-events: none !important;
}

.catcher {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex !important;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 200;
  animation: fade-in 0.25s;
  transition-timing-function: ease;
}
.catcher.clear {
  position: absolute;
}
.catcher .mat-horizontal-content-container {
  position: relative;
}
.catcher .wizard-add {
  max-width: calc(100vw - 32px);
}
.catcher .wizard-add h3 {
  font-weight: 700;
}
.catcher .wizard-add .mat-row {
  height: 40px !important;
}

.catcher-positioner {
  max-width: 1632px;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 65px 0 0;
  box-sizing: border-box;
}

.side-nav.open + div + .mat-drawer-content .catcher-positioner {
  width: calc(100vw - 384px) !important;
}

h5.stepper-title {
  font-weight: 500;
  margin: 0 0 16px 0 !important;
  font-size: 18px;
}

.add-menu {
  border-radius: 5px;
}
.add-menu.hide .is-hidden {
  display: none !important;
}
.add-menu .mat-horizontal-content-container {
  padding: 0;
  border-radius: 5px;
}

.add-menu-form-body {
  padding: 16px;
  overflow-y: auto;
  display: block !important;
  margin: 0 auto;
  box-sizing: border-box;
  max-height: calc(95vh - 65px - 80px - 40px - 70px);
}
.add-menu-form-body .mat-radio-group {
  min-height: 40px;
}
.add-menu-form-body .mat-radio-group .mat-radio-button {
  padding: 8px 16px;
  display: flex;
  align-items: center;
}
.add-menu-form-body .mat-radio-group .mat-radio-button .mat-radio-label {
  height: 100%;
  display: flex;
  align-items: center;
}
.add-menu-form-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-container {
  margin: 10px;
}
.add-menu-form-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content {
  padding-left: 16px;
  width: 100%;
}
.add-menu-form-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b {
  max-width: 20vw;
  line-break: normal;
  white-space: break-spaces;
}
.add-menu-form-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content h5 {
  margin: 0;
  font-weight: normal;
  max-width: 20vw;
  line-break: normal;
  white-space: break-spaces;
}
.add-menu-form-body .mat-form-field {
  min-height: 64px;
  width: 100%;
  margin-bottom: 0 !important;
}
.add-menu-form-body .mat-radio-label-content {
  padding-left: 16px !important;
  width: 100%;
}
.add-menu-form-body.w-table {
  max-width: unset !important;
  padding: 0 !important;
  width: unset;
  overflow: hidden !important;
  min-height: 300px;
  max-height: calc(95vh - 65px - 69px) !important;
}
.add-menu-form-body.w-table .helper {
  height: 80px;
  padding: 0 16px;
  position: relative;
}
.add-menu-form-body.w-table .helper mat-progress-bar {
  left: 0;
  top: 0;
}
.add-menu-form-body.w-table .helper .search-bar {
  height: 64px;
  border-radius: 32px;
  padding: 0 16px;
  border: none;
  transition: background 0.15s ease;
  margin-right: 16px;
}
.add-menu-form-body.w-table .helper .search-bar:hover, .add-menu-form-body.w-table .helper .search-bar:active, .add-menu-form-body.w-table .helper .search-bar:focus, .add-menu-form-body.w-table .helper .search-bar:focus-within {
  transition: background 0.15s ease;
}
.add-menu-form-body.w-table .helper .search-bar input {
  line-height: 80px;
  border: none;
  background: none;
  font-size: 18px;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 16px;
}
.add-menu-form-body.w-table .helper .search-bar mat-icon {
  margin-right: 8px;
}
.add-menu-form-body.w-table .helper .search-bar button {
  min-width: unset;
  border-radius: 20px;
}
.add-menu-form-body.w-table .helper .search-bar button.mat-primary .mat-icon {
  color: unset !important;
}
.add-menu-form-body.w-table .load-window.fixed {
  min-height: 50vh !important;
  top: 0;
}

.mat-horizontal-stepper-content {
  position: relative;
}
.mat-horizontal-stepper-content .mat-search-field {
  width: 50%;
  min-width: unset;
}
.mat-horizontal-stepper-content .employee-search-sub-toolbar {
  min-height: unset !important;
  padding-top: 16px;
}
.mat-horizontal-stepper-content:not(.mat-horizontal-stepper-content-inactive) .new-table {
  min-width: 60vw;
}
.mat-horizontal-stepper-content:not(.mat-horizontal-stepper-content-inactive) .new-table .mat-table .mat-table-body {
  max-height: calc(90vh - 67px - 80px - 56px - 67px);
  overflow: auto;
  overflow: overlay;
}

.add-menu-form-actions {
  padding: 16px;
  display: flex;
  flex-direction: row-reverse;
  position: relative;
}
.add-menu-form-actions mat-progress-bar {
  top: 0;
  left: 0;
  margin: 0 !important;
}
.add-menu-form-actions mat-selection-list {
  position: absolute;
  left: 16px;
  top: 10px;
  padding: 0 !important;
}
.add-menu-form-actions button + button {
  margin-right: 16px;
}

button.side-open-mobile,
button.side-close-mobile {
  display: none;
}

app-add-employee .mat-input-element:disabled {
  cursor: not-allowed;
}

app-upload-form {
  display: block;
}

.file-upload-list {
  padding: 0;
  width: 100%;
  max-width: 384px;
  box-sizing: border-box;
  margin-bottom: 16px;
}
.file-upload-list mat-list-item {
  border-radius: 50px;
  margin-bottom: 8px;
  overflow: hidden;
}

.mat-horizontal-stepper-content .mat-expansion-panel-body {
  min-height: 64px;
  max-height: 15vh;
  overflow-y: auto;
}

mat-expansion-panel mat-form-field {
  width: 100%;
  padding: 16px 32px;
  box-sizing: border-box;
  min-height: 64px;
}

.mat-horizontal-stepper-content app-file-document-view > span {
  height: 100%;
}
.mat-horizontal-stepper-content app-file-document-view > span mat-divider {
  display: none;
}
.mat-horizontal-stepper-content app-file-document-view .pdf-view-container {
  box-sizing: border-box;
  width: 40vw;
  padding: 16px;
}
.mat-horizontal-stepper-content app-file-document-view .cardinal-pdf-viewer-container {
  overflow-x: unset;
}
.mat-horizontal-stepper-content .preview-container {
  height: unset;
  box-sizing: border-box;
  width: 40vw;
  margin: 0 !important;
}

mat-horizontal-stepper {
  width: 100%;
  width: min-content;
  min-width: 450px;
}

.add-docs-drop {
  max-height: calc(100vh - 65px - 32px) !important;
  position: relative;
}
.add-docs-drop .docs-drop-preview {
  max-height: calc(100vh - 125px - 32px - 65px) !important;
  align-items: stretch;
}
.add-docs-drop .docs-drop-preview .add-menu-form-body {
  min-width: 30vw;
  max-width: 40vw !important;
  max-height: calc(95vh - 81px - 65px);
}
.add-docs-drop .docs-drop-preview .add-menu-form-body .full-btn {
  width: 100%;
}
.add-docs-drop .docs-drop-preview .file-upload-list {
  max-width: unset !important;
  height: auto !important;
}
.add-docs-drop .docs-drop-preview app-file-document-detail {
  height: unset;
  max-width: calc(100% - 30vw);
}
.add-docs-drop .docs-drop-preview app-file-document-detail .view-toolbar {
  border-bottom: none;
}
.add-docs-drop .docs-drop-preview app-file-document-detail .view-toolbar .mat-toolbar-row {
  justify-content: flex-end !important;
}
.add-docs-drop .load-window.message {
  padding: 16px;
  flex-direction: column;
  text-align: center;
  box-sizing: border-box;
  position: absolute;
  top: 0;
}
.add-docs-drop .load-window.message h3.primary-text {
  margin-top: 40px;
}

mat-tab-group.wizard-tabs-options {
  margin: 16px 0;
}
mat-tab-group.wizard-tabs-options .mat-tab-body-content {
  padding-top: 16px;
  box-sizing: border-box;
}

app-add-documentation mat-list.file-upload-list {
  max-height: 180px;
  overflow: auto;
}
app-add-documentation mat-horizontal-stepper {
  width: 100% !important;
}

mat-dialog-container app-add-menu-dialog {
  display: block;
}
mat-dialog-container app-add-menu-dialog mat-horizontal-stepper {
  width: 100% !important;
}
mat-dialog-container app-add-menu-dialog mat-toolbar-row .mat-avatar {
  width: calc(100% - 56px) !important;
}
mat-dialog-container app-add-menu-dialog mat-toolbar-row .mat-avatar .title {
  width: 100%;
}
mat-dialog-container app-add-menu-dialog .load-window {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 32px 0;
  top: 0;
}
mat-dialog-container app-add-menu-dialog .load-window.message {
  padding: 16px;
  flex-direction: column;
  text-align: center;
  box-sizing: border-box;
}
mat-dialog-container app-add-menu-dialog .load-window.message h3.primary-text {
  margin-top: 40px;
}

app-add-metadata-value-dialog {
  min-width: 30vw;
  display: block;
}

.mat-option button .mat-icon {
  margin: 0 !important;
}

.asisted-input {
  position: relative;
  padding: 16px 0;
}
.asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble {
  transform: scale(1.05) !important;
}
.asisted-input:hover .bubble {
  transform: scale(1.05) !important;
}
.asisted-input.big {
  height: auto !important;
  padding: 0;
}
.asisted-input:not(.subtitle) {
  height: 80px;
}
.asisted-input.aligned-icon {
  padding: 16px 32px 0;
}
.asisted-input.aligned-icon.subtitle {
  height: 40px;
}
.asisted-input.aligned-icon.subtitle h3 {
  font-weight: normal;
  margin: 0;
  width: 100%;
  font-size: 0.8rem;
}
.asisted-input.aligned-icon mat-icon {
  margin-right: 16px;
}
.asisted-input .indicator {
  min-width: 40px;
  margin-right: 16px;
  justify-content: center;
  display: flex;
  align-items: center;
}
.asisted-input .indicator .bubble {
  height: 30px;
  width: 30px;
  align-items: center;
  justify-content: center;
  display: flex;
  transform: scale(1);
  border-radius: 15px;
}
.asisted-input .indicator .guide {
  position: absolute;
  border-left: 1px solid;
  height: calc(50% - 20px);
}
.asisted-input .indicator .guide.top {
  bottom: calc(50% + 20px);
}
.asisted-input .indicator .guide.bottom {
  top: calc(50% + 20px);
}
.asisted-input textarea {
  overflow: hidden !important;
}
.asisted-input.icon-only .bubble {
  background: none !important;
  font-size: 20px;
}

app-custom-control {
  width: 100%;
}
app-custom-control.first-field .indicator .guide.top {
  display: none;
}

.UI-V2 .main-overlay {
  position: absolute;
  bottom: 0;
  height: 100%;
  opacity: 0;
  z-index: 2;
}
.UI-V2 .main-overlay.enabled {
  width: 100% !important;
  opacity: 1;
}
.UI-V2 .icon-complex {
  position: relative;
  height: 40px;
  width: 40px;
  padding: 0 !important;
}
.UI-V2 .icon-complex mat-icon {
  padding: 0 !important;
}
.UI-V2 .icon-complex mat-icon.complex {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 16px !important;
  height: 20px !important;
  width: 20px !important;
  padding: 0 !important;
}
.UI-V2 .navigation {
  position: relative;
}
.UI-V2 .navigation.detail-open {
  overflow: hidden;
  width: 0 !important;
  flex: 0 !important;
}
.UI-V2 .navigation .load-window {
  left: 0;
  top: 112px;
  height: calc(100% - 112px) !important;
}
.UI-V2 .navigation h3 {
  font-weight: 500;
}
.UI-V2 .navigation h5 {
  font-weight: 300;
}
.UI-V2 .navigation .toolbar {
  height: 80px;
  margin: 16px;
  border-radius: 8px;
  padding: 0 16px;
}
.UI-V2 .navigation .toolbar h3,
.UI-V2 .navigation .toolbar h5 {
  margin: 0;
}
.UI-V2 .navigation .container {
  padding: 0 8px 16px 16px;
  overflow-y: scroll;
  max-height: calc(100% - 112px);
  box-sizing: border-box;
}
.UI-V2 .navigation .container .helper {
  padding: 0 16px;
  height: 56px;
  margin-bottom: 16px;
}
.UI-V2 .navigation .container .item {
  padding: 16px;
  border-radius: 8px;
  cursor: pointer;
}
.UI-V2 .navigation .container .item h3 {
  margin: 0;
}
.UI-V2 .navigation .container .item + .item {
  margin-top: 16px;
}
.UI-V2 .navigation .container .item.active {
  cursor: default;
}
.UI-V2 .work-space mat-tab-group.tabs-settings {
  height: calc(100% - 112px) !important;
}
.UI-V2 .work-space mat-tab-group.tabs-settings mat-tab-header {
  padding: 0 !important;
  margin: 0 16px;
  width: calc(100% - 32px);
}
.UI-V2 .work-space mat-tab-group {
  width: 100%;
  height: 100%;
}
.UI-V2 .work-space mat-tab-group mat-tab-header.mat-tab-header {
  padding: 0 16px;
  box-sizing: border-box;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper {
  height: 100%;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body {
  height: 100%;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll {
  height: 100%;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance {
  height: 100%;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config {
  padding: 16px;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config {
  padding: 16px 16px 0;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .confirm,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .confirm {
  margin-bottom: 16px;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config {
  display: block;
  box-sizing: border-box;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .load-window.new,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .load-window.new,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .load-window.new,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .load-window.new,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .load-window.new {
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: 100% !important;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .help-hoverable mat-hint,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .help-hoverable mat-hint,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .help-hoverable mat-hint,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .help-hoverable mat-hint,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .help-hoverable mat-hint {
  bottom: calc(100% + 8px);
  left: calc(50% - 320px);
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .form-steped-body,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .form-steped-body,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .form-steped-body,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .form-steped-body,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .form-steped-body {
  padding: 0 16px;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .separator,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .separator,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .separator,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .separator,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .separator {
  padding: 0 16px;
  box-sizing: border-box;
  margin-bottom: 16px;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .separator .subtitle,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .separator .subtitle,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .separator .subtitle,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .separator .subtitle,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .separator .subtitle {
  height: 56px;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .img-editior-container,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .img-editior-container,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .img-editior-container,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .img-editior-container,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .img-editior-container {
  height: 100%;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .img-editior-container .img-editor,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .img-editior-container .img-editor,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .img-editior-container .img-editor,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .img-editior-container .img-editor,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .img-editior-container .img-editor {
  width: 100%;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .img-editior-container .img-editor .separator,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .img-editior-container .img-editor .separator,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .img-editior-container .img-editor .separator,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .img-editior-container .img-editor .separator,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .img-editior-container .img-editor .separator {
  padding: 0 16px;
  box-sizing: border-box;
  margin-bottom: 16px;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .img-editior-container .img-editor .separator .subtitle,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .img-editior-container .img-editor .separator .subtitle,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .img-editior-container .img-editor .separator .subtitle,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .img-editior-container .img-editor .separator .subtitle,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .img-editior-container .img-editor .separator .subtitle {
  height: 56px;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .img-editior-container .img-editor img,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .img-editior-container .img-editor img,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .img-editior-container .img-editor img,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .img-editior-container .img-editor img,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .img-editior-container .img-editor img {
  width: 100%;
  margin-bottom: 16px;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .img-editior-container .img-editor + .cropper-area,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .img-editior-container .img-editor + .cropper-area,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .img-editior-container .img-editor + .cropper-area,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .img-editior-container .img-editor + .cropper-area,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .img-editior-container .img-editor + .cropper-area {
  height: calc(100% - 72px);
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .img-editior-container .img-editor + .cropper-area image-cropper,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .img-editior-container .img-editor + .cropper-area image-cropper,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .img-editior-container .img-editor + .cropper-area image-cropper,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .img-editior-container .img-editor + .cropper-area image-cropper,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .img-editior-container .img-editor + .cropper-area image-cropper {
  padding: 0 !important;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .img-editior-container .img-editor .upload-warning .info-empty,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .img-editior-container .img-editor .upload-warning .info-empty,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .img-editior-container .img-editor .upload-warning .info-empty,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .img-editior-container .img-editor .upload-warning .info-empty,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .img-editior-container .img-editor .upload-warning .info-empty {
  min-height: unset !important;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme {
  margin-bottom: 16px;
  padding-bottom: 16px;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example {
  padding: 0;
  overflow: hidden;
  background: #eeeeee !important;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example.dark,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example.dark,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example.dark,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example.dark,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example.dark {
  background: #303030 !important;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example.dark .main-color-container .example-toolbar *,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example.dark .main-color-container .example-toolbar *,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example.dark .main-color-container .example-toolbar *,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example.dark .main-color-container .example-toolbar *,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example.dark .main-color-container .example-toolbar * {
  color: #000 !important;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example.dark .main-color-container .example-toolbar.indigo,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example.dark .main-color-container .example-toolbar.indigo,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example.dark .main-color-container .example-toolbar.indigo,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example.dark .main-color-container .example-toolbar.indigo,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example.dark .main-color-container .example-toolbar.indigo {
  background: #9fa8da !important;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example.dark .main-color-container .example-toolbar.pink,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example.dark .main-color-container .example-toolbar.pink,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example.dark .main-color-container .example-toolbar.pink,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example.dark .main-color-container .example-toolbar.pink,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example.dark .main-color-container .example-toolbar.pink {
  background: #f06292 !important;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example.dark .main-color-container .main-color,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example.dark .main-color-container .main-color,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example.dark .main-color-container .main-color,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example.dark .main-color-container .main-color,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example.dark .main-color-container .main-color {
  background: #424242 !important;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example.dark .main-color-container .main-color h6,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example.dark .main-color-container .main-color h6,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example.dark .main-color-container .main-color h6,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example.dark .main-color-container .main-color h6,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example.dark .main-color-container .main-color h6 {
  color: #fff;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example.dark .main-color-container .main-color:before,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example.dark .main-color-container .main-color:before,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example.dark .main-color-container .main-color:before,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example.dark .main-color-container .main-color:before,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example.dark .main-color-container .main-color:before {
  border-bottom: 16px solid #424242 !important;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example.dark .main-color-container .main-color mat-button-toggle-group mat-button-toggle[value=indigo],
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example.dark .main-color-container .main-color mat-button-toggle-group mat-button-toggle[value=indigo],
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example.dark .main-color-container .main-color mat-button-toggle-group mat-button-toggle[value=indigo],
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example.dark .main-color-container .main-color mat-button-toggle-group mat-button-toggle[value=indigo],
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example.dark .main-color-container .main-color mat-button-toggle-group mat-button-toggle[value=indigo] {
  background: #9fa8da !important;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example.dark .main-color-container .main-color mat-button-toggle-group mat-button-toggle[value=pink],
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example.dark .main-color-container .main-color mat-button-toggle-group mat-button-toggle[value=pink],
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example.dark .main-color-container .main-color mat-button-toggle-group mat-button-toggle[value=pink],
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example.dark .main-color-container .main-color mat-button-toggle-group mat-button-toggle[value=pink],
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example.dark .main-color-container .main-color mat-button-toggle-group mat-button-toggle[value=pink] {
  background: #f06292 !important;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example.dark .side-color-container .side-color,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example.dark .side-color-container .side-color,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example.dark .side-color-container .side-color,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example.dark .side-color-container .side-color,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example.dark .side-color-container .side-color {
  background: #424242 !important;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example.dark .side-color-container .side-color h6,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example.dark .side-color-container .side-color h6,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example.dark .side-color-container .side-color h6,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example.dark .side-color-container .side-color h6,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example.dark .side-color-container .side-color h6 {
  color: #fff;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example.dark .side-color-container .side-color:before,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example.dark .side-color-container .side-color:before,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example.dark .side-color-container .side-color:before,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example.dark .side-color-container .side-color:before,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example.dark .side-color-container .side-color:before {
  border-left: 16px solid #424242 !important;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example.dark .side-color-container .side-color mat-button-toggle-group mat-button-toggle[value=pink],
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example.dark .side-color-container .side-color mat-button-toggle-group mat-button-toggle[value=pink],
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example.dark .side-color-container .side-color mat-button-toggle-group mat-button-toggle[value=pink],
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example.dark .side-color-container .side-color mat-button-toggle-group mat-button-toggle[value=pink],
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example.dark .side-color-container .side-color mat-button-toggle-group mat-button-toggle[value=pink] {
  background: #f48fb1 !important;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example.dark .side-color-container .side-color mat-button-toggle-group mat-button-toggle[value=amber],
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example.dark .side-color-container .side-color mat-button-toggle-group mat-button-toggle[value=amber],
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example.dark .side-color-container .side-color mat-button-toggle-group mat-button-toggle[value=amber],
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example.dark .side-color-container .side-color mat-button-toggle-group mat-button-toggle[value=amber],
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example.dark .side-color-container .side-color mat-button-toggle-group mat-button-toggle[value=amber] {
  background: #ffe082 !important;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example.dark .side-color-container .side-color mat-button-toggle-group mat-button-toggle[value=purple],
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example.dark .side-color-container .side-color mat-button-toggle-group mat-button-toggle[value=purple],
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example.dark .side-color-container .side-color mat-button-toggle-group mat-button-toggle[value=purple],
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example.dark .side-color-container .side-color mat-button-toggle-group mat-button-toggle[value=purple],
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example.dark .side-color-container .side-color mat-button-toggle-group mat-button-toggle[value=purple] {
  background: #ce93d8 !important;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example.dark .side-color-container .side-color mat-button-toggle-group mat-button-toggle[value=blueGrey],
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example.dark .side-color-container .side-color mat-button-toggle-group mat-button-toggle[value=blueGrey],
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example.dark .side-color-container .side-color mat-button-toggle-group mat-button-toggle[value=blueGrey],
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example.dark .side-color-container .side-color mat-button-toggle-group mat-button-toggle[value=blueGrey],
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example.dark .side-color-container .side-color mat-button-toggle-group mat-button-toggle[value=blueGrey] {
  background: #b0bec5 !important;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example.dark .side-color-container .example-button,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example.dark .side-color-container .example-button,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example.dark .side-color-container .example-button,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example.dark .side-color-container .example-button,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example.dark .side-color-container .example-button {
  color: #000 !important;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example.dark .side-color-container .example-button.pink,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example.dark .side-color-container .example-button.pink,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example.dark .side-color-container .example-button.pink,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example.dark .side-color-container .example-button.pink,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example.dark .side-color-container .example-button.pink {
  background: #f48fb1 !important;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example.dark .side-color-container .example-button.amber,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example.dark .side-color-container .example-button.amber,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example.dark .side-color-container .example-button.amber,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example.dark .side-color-container .example-button.amber,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example.dark .side-color-container .example-button.amber {
  background: #ffe082 !important;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example.dark .side-color-container .example-button.purple,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example.dark .side-color-container .example-button.purple,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example.dark .side-color-container .example-button.purple,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example.dark .side-color-container .example-button.purple,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example.dark .side-color-container .example-button.purple {
  background: #ce93d8 !important;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example.dark .side-color-container .example-button.blueGrey,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example.dark .side-color-container .example-button.blueGrey,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example.dark .side-color-container .example-button.blueGrey,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example.dark .side-color-container .example-button.blueGrey,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example.dark .side-color-container .example-button.blueGrey {
  background: #b0bec5 !important;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .main-color-container,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .main-color-container,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .main-color-container,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .main-color-container,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .main-color-container {
  position: relative;
  padding-bottom: 16px;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .main-color-container .example-toolbar .theme-title,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .main-color-container .example-toolbar .theme-title,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .main-color-container .example-toolbar .theme-title,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .main-color-container .example-toolbar .theme-title,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .main-color-container .example-toolbar .theme-title {
  text-transform: capitalize;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .main-color-container .example-toolbar *,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .main-color-container .example-toolbar *,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .main-color-container .example-toolbar *,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .main-color-container .example-toolbar *,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .main-color-container .example-toolbar * {
  color: #fff;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .main-color-container .example-toolbar .mat-slide-toggle-content,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .main-color-container .example-toolbar .mat-slide-toggle-content,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .main-color-container .example-toolbar .mat-slide-toggle-content,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .main-color-container .example-toolbar .mat-slide-toggle-content,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .main-color-container .example-toolbar .mat-slide-toggle-content {
  font-size: 12px;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .main-color-container .example-toolbar.indigo,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .main-color-container .example-toolbar.indigo,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .main-color-container .example-toolbar.indigo,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .main-color-container .example-toolbar.indigo,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .main-color-container .example-toolbar.indigo {
  background: #3f51b5;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .main-color-container .example-toolbar.pink,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .main-color-container .example-toolbar.pink,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .main-color-container .example-toolbar.pink,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .main-color-container .example-toolbar.pink,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .main-color-container .example-toolbar.pink {
  background: #d81b60;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .main-color-container .main-color,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .main-color-container .main-color,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .main-color-container .main-color,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .main-color-container .main-color,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .main-color-container .main-color {
  background: #fff !important;
  border-radius: 4px;
  padding: 4px 16px 16px;
  margin: 8px 16px;
  position: relative;
  width: fit-content;
  opacity: 1;
  transform: scale(1);
  transition: all 0.2s ease;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .main-color-container .main-color mat-button-toggle-group mat-button-toggle[value=pink],
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .main-color-container .main-color mat-button-toggle-group mat-button-toggle[value=pink],
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .main-color-container .main-color mat-button-toggle-group mat-button-toggle[value=pink],
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .main-color-container .main-color mat-button-toggle-group mat-button-toggle[value=pink],
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .main-color-container .main-color mat-button-toggle-group mat-button-toggle[value=pink] {
  background: #d81b60 !important;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .main-color-container .main-color.hidden,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .main-color-container .main-color.hidden,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .main-color-container .main-color.hidden,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .main-color-container .main-color.hidden,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .main-color-container .main-color.hidden {
  pointer-events: none;
  opacity: 0;
  transform: scale(0);
  transition: all 0.2s ease;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .main-color-container .main-color:before,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .main-color-container .main-color:before,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .main-color-container .main-color:before,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .main-color-container .main-color:before,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .main-color-container .main-color:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  bottom: 100%;
  right: 32px;
  position: absolute;
  z-index: 1;
  border-bottom: 16px solid #fff !important;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .main-color-container .main-color h6,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .main-color-container .main-color h6,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .main-color-container .main-color h6,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .main-color-container .main-color h6,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .main-color-container .main-color h6 {
  color: #000;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .side-color-container,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .side-color-container,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .side-color-container,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .side-color-container,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .side-color-container {
  align-items: center;
  display: flex;
  flex-direction: row-reverse;
  padding: 16px 16px 8px;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .side-color-container .side-color,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .side-color-container .side-color,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .side-color-container .side-color,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .side-color-container .side-color,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .side-color-container .side-color {
  background: #fff !important;
  border-radius: 4px;
  padding: 8px 16px 16px;
  margin: 0 8px;
  position: relative;
  width: fit-content;
  opacity: 1;
  transform: scale(1);
  transition: all 0.2s ease;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .side-color-container .side-color mat-button-toggle-group mat-button-toggle[value=pink],
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .side-color-container .side-color mat-button-toggle-group mat-button-toggle[value=pink],
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .side-color-container .side-color mat-button-toggle-group mat-button-toggle[value=pink],
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .side-color-container .side-color mat-button-toggle-group mat-button-toggle[value=pink],
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .side-color-container .side-color mat-button-toggle-group mat-button-toggle[value=pink] {
  background: #e91e63 !important;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .side-color-container .side-color.hidden,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .side-color-container .side-color.hidden,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .side-color-container .side-color.hidden,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .side-color-container .side-color.hidden,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .side-color-container .side-color.hidden {
  pointer-events: none;
  opacity: 0;
  transform: scale(0);
  transition: all 0.2s ease;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .side-color-container .side-color:before,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .side-color-container .side-color:before,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .side-color-container .side-color:before,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .side-color-container .side-color:before,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .side-color-container .side-color:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  left: 100%;
  bottom: 36px;
  position: absolute;
  z-index: 1;
  border-left: 16px solid #fff !important;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .side-color-container .side-color h6,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .side-color-container .side-color h6,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .side-color-container .side-color h6,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .side-color-container .side-color h6,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .side-color-container .side-color h6 {
  color: #000;
  margin: 8px 4px !important;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .side-color-container .side-color h6.required:after,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .side-color-container .side-color h6.required:after,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .side-color-container .side-color h6.required:after,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .side-color-container .side-color h6.required:after,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .side-color-container .side-color h6.required:after {
  content: " (elige un color)";
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .side-color-container .example-button,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .side-color-container .example-button,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .side-color-container .example-button,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .side-color-container .example-button,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .side-color-container .example-button {
  color: #fff;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .side-color-container .example-button.pink,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .side-color-container .example-button.pink,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .side-color-container .example-button.pink,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .side-color-container .example-button.pink,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .side-color-container .example-button.pink {
  background: #e91e63;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .side-color-container .example-button.amber,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .side-color-container .example-button.amber,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .side-color-container .example-button.amber,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .side-color-container .example-button.amber,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .side-color-container .example-button.amber {
  background: #ffc107;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .side-color-container .example-button.purple,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .side-color-container .example-button.purple,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .side-color-container .example-button.purple,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .side-color-container .example-button.purple,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .side-color-container .example-button.purple {
  background: #9c27b0;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .side-color-container .example-button.blueGrey,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .side-color-container .example-button.blueGrey,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .side-color-container .example-button.blueGrey,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .side-color-container .example-button.blueGrey,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .side-color-container .example-button.blueGrey {
  background: #607d8b;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example h6,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example h6,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example h6,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example h6,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example h6 {
  margin: 8px 4px !important;
  color: #000;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example h6.required:after,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example h6.required:after,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example h6.required:after,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example h6.required:after,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example h6.required:after {
  content: " (elige un color)";
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example mat-button-toggle-group,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example mat-button-toggle-group,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example mat-button-toggle-group,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example mat-button-toggle-group,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example mat-button-toggle-group {
  border: none !important;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle {
  width: 32px;
  height: 32px;
  border-radius: 16px;
  margin: 4px;
  overflow: hidden;
  box-sizing: border-box;
  transform: scale(0.8);
  transition: all 0.2s ease;
  opacity: 0.9;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle.mat-button-toggle-checked,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle.mat-button-toggle-checked,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle.mat-button-toggle-checked,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle.mat-button-toggle-checked,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle.mat-button-toggle-checked {
  border: 2px solid rgba(255, 255, 255, 0.5) !important;
  transform: scale(1.25);
  box-sizing: border-box;
  transition: all 0.2s ease;
  opacity: 1;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle.mat-button-toggle-checked:hover,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle.mat-button-toggle-checked:hover,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle.mat-button-toggle-checked:hover,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle.mat-button-toggle-checked:hover,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle.mat-button-toggle-checked:hover {
  transform: scale(1.25) !important;
  transition: all 0.2s ease;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle.mat-button-toggle-checked button,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle.mat-button-toggle-checked button,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle.mat-button-toggle-checked button,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle.mat-button-toggle-checked button,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle.mat-button-toggle-checked button {
  cursor: default !important;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle:hover,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle:hover,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle:hover,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle:hover,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle:hover {
  transform: scale(1.1);
  transition: all 0.2s ease;
  opacity: 1;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle[value=indigo],
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle[value=indigo],
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle[value=indigo],
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle[value=indigo],
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle[value=indigo] {
  background: #3f51b5;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle[value=amber],
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle[value=amber],
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle[value=amber],
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle[value=amber],
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle[value=amber] {
  background: #ffc107;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle[value=purple],
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle[value=purple],
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle[value=purple],
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle[value=purple],
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle[value=purple] {
  background: #9c27b0;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle[value=blueGrey],
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle[value=blueGrey],
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle[value=blueGrey],
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle[value=blueGrey],
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle[value=blueGrey] {
  background: #607d8b;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle.mat-button-toggle-disabled,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle.mat-button-toggle-disabled,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle.mat-button-toggle-disabled,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle.mat-button-toggle-disabled,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle.mat-button-toggle-disabled {
  opacity: 0.3;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle.mat-button-toggle-disabled:hover,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle.mat-button-toggle-disabled:hover,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle.mat-button-toggle-disabled:hover,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle.mat-button-toggle-disabled:hover,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle.mat-button-toggle-disabled:hover {
  transform: scale(0.8);
  transition: all 0.2s ease;
  opacity: 0.3 !important;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle.mat-button-toggle-disabled button,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle.mat-button-toggle-disabled button,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle.mat-button-toggle-disabled button,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle.mat-button-toggle-disabled button,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle.mat-button-toggle-disabled button {
  cursor: not-allowed;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle button,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle button,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle button,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle button,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle button {
  width: 100%;
  height: 100%;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .permission-settings-cards .card,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .permission-settings-cards .card,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .permission-settings-cards .card,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .permission-settings-cards .card,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .permission-settings-cards .card {
  width: 50%;
  border-radius: 8px;
  box-sizing: border-box;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .permission-settings-cards .card .subtitle,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .permission-settings-cards .card .subtitle,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .permission-settings-cards .card .subtitle,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .permission-settings-cards .card .subtitle,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .permission-settings-cards .card .subtitle {
  padding: 16px;
  margin-bottom: 0;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .permission-settings-cards .card mat-slide-toggle,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .permission-settings-cards .card mat-slide-toggle,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .permission-settings-cards .card mat-slide-toggle,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .permission-settings-cards .card mat-slide-toggle,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .permission-settings-cards .card mat-slide-toggle {
  height: 40px !important;
  padding: 0 16px;
  width: 100%;
  box-sizing: border-box;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .permission-settings-cards .card mat-slide-toggle span.mat-slide-toggle-content,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .permission-settings-cards .card mat-slide-toggle span.mat-slide-toggle-content,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .permission-settings-cards .card mat-slide-toggle span.mat-slide-toggle-content,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .permission-settings-cards .card mat-slide-toggle span.mat-slide-toggle-content,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .permission-settings-cards .card mat-slide-toggle span.mat-slide-toggle-content {
  margin-left: 12px;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .permission-settings-cards .card .slide-sub-group mat-slide-toggle,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .permission-settings-cards .card .slide-sub-group mat-slide-toggle,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .permission-settings-cards .card .slide-sub-group mat-slide-toggle,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .permission-settings-cards .card .slide-sub-group mat-slide-toggle,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .permission-settings-cards .card .slide-sub-group mat-slide-toggle {
  padding-left: 36px;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .permission-settings-cards .card .slide-sub-group .slide-sub-group mat-slide-toggle,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .permission-settings-cards .card .slide-sub-group .slide-sub-group mat-slide-toggle,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .permission-settings-cards .card .slide-sub-group .slide-sub-group mat-slide-toggle,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .permission-settings-cards .card .slide-sub-group .slide-sub-group mat-slide-toggle,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .permission-settings-cards .card .slide-sub-group .slide-sub-group mat-slide-toggle {
  padding-left: 66px;
}
.UI-V2 .work-space h5,
.UI-V2 .work-space h6 {
  font-weight: 500;
}
.UI-V2 .work-space .main {
  position: relative;
}
.UI-V2 .work-space .main .load-window {
  left: 0;
  top: 0;
  height: 100%;
}
.UI-V2 .work-space .main.side-open .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .content-scroll .item {
  width: calc(33.3333333333% - 16px) !important;
}
.UI-V2 .work-space .main .container.file-configuration {
  height: calc(100% - 112px) !important;
  position: relative;
}
.UI-V2 .work-space .main .container.file-configuration .refresh {
  position: absolute;
  top: 4px;
  left: 32px;
  z-index: 2;
}
.UI-V2 .work-space .main .container.file-configuration .refresh-bar {
  top: 48px;
}
.UI-V2 .work-space .main .container.file-configuration .file-tabs {
  height: 100%;
}
.UI-V2 .work-space .main .container.file-configuration .file-tabs mat-tab-header {
  margin: 0 16px;
  padding-left: 72px;
}
.UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper {
  height: 100%;
}
.UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body {
  height: 100%;
}
.UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content {
  display: flex;
  align-items: flex-start;
  max-height: 100%;
  height: unset;
}
.UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: scroll;
  max-height: 100% !important;
  box-sizing: border-box;
  padding: 8px 0 8px 8px;
  width: 100%;
}
.UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item {
  margin-top: 16px;
  padding: 16px 16px 8px;
  border-radius: 8px;
  width: calc(33.3333333333% - 16px);
  margin: 8px;
  cursor: pointer;
  justify-content: space-between;
}
.UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item mat-icon.complex {
  bottom: 0;
  right: 0;
}
.UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item h5 {
  margin: 0;
}
.UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item h3 {
  margin: 0;
  margin-right: 16px !important;
}
.UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item .actions {
  pointer-events: none;
  margin-top: 16px;
}
.UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item .actions button {
  pointer-events: all;
}
.UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.full-page {
  width: calc(25% - 16px);
}
.UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled .actions mat-icon {
  color: unset !important;
}
.UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled.selected {
  opacity: 1;
  cursor: default;
}
.UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected {
  cursor: default;
}
.UI-V2 .work-space .side {
  height: 100%;
}
.UI-V2 .work-space .side .edition-line {
  border-radius: 8px;
  padding: 16px;
  margin: 16px 0;
  position: relative;
  overflow: hidden;
}
.UI-V2 .work-space .side .edition-line mat-progress-bar {
  bottom: 0;
  left: 0;
}
.UI-V2 .work-space .side mat-form-field {
  width: 100%;
}
.UI-V2 .work-space .side mat-form-field.mat-form-field-disabled * {
  cursor: not-allowed;
}
.UI-V2 .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-underline {
  height: 2px !important;
}
.UI-V2 .work-space .side mat-form-field .mat-form-field-flex {
  border-radius: 4px 4px 0 0 !important;
  padding: 8px 8px 0 !important;
  min-height: 56px !important;
  align-items: unset !important;
}
.UI-V2 .work-space .side mat-form-field .mat-form-field-flex .mat-form-field-prefix {
  padding: 0 !important;
  display: flex;
  align-items: center;
}
.UI-V2 .work-space .side mat-checkbox {
  align-items: center;
  height: 56px;
  display: flex;
  margin-bottom: 16px;
  border-radius: 4px;
  padding: 0 8px;
}
.UI-V2 .work-space .side mat-checkbox .mat-checkbox-layout {
  width: 100%;
  justify-content: flex-start;
  height: 56px;
  align-items: center;
}
.UI-V2 .work-space .side mat-checkbox .mat-checkbox-layout .mat-checkbox-inner-container {
  margin: 12px;
}
.UI-V2 .work-space .side mat-checkbox .mat-checkbox-layout .mat-checkbox-label {
  width: 100%;
  padding-left: 16px;
}
.UI-V2 .work-space .side .hint {
  margin: 0 0 16px;
  font-weight: 700;
  width: 100%;
  text-align: center;
}
.UI-V2 .work-space .side .toolbar {
  height: 112px;
  padding: 16px 32px;
}
.UI-V2 .work-space .side .toolbar .header {
  width: 100%;
}
.UI-V2 .work-space .side .toolbar .header h3 {
  margin: 0;
}
.UI-V2 .work-space .side .toolbar .header h5 {
  margin: 0;
}
.UI-V2 .work-space .side .container {
  padding: 0 0 16px 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: scroll;
  max-height: calc(100% - 112px);
  box-sizing: border-box;
}
.UI-V2 .work-space .side .container .role-resume {
  width: calc(100% - 16px);
  padding: 16px;
  border-radius: 8px;
  margin: 0 8px;
  position: relative;
}
.UI-V2 .work-space .side .container .role-resume .line {
  padding: 8px 16px;
  position: relative;
  border-radius: 4px;
}
.UI-V2 .work-space .side .container .role-resume .line.editable:hover button {
  opacity: 1;
}
.UI-V2 .work-space .side .container .role-resume .line.editable button {
  opacity: 0;
  position: absolute;
  right: 16px;
  top: 4px;
}
.UI-V2 .work-space .side .container .role-resume .line .header {
  width: 100%;
}
.UI-V2 .work-space .side .container .role-resume .line .header .info {
  max-width: calc(100% - 40px - 16px - 16px);
}
.UI-V2 .work-space .side .container .role-resume .line .header .info h4,
.UI-V2 .work-space .side .container .role-resume .line .header .info h6 {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
.UI-V2 .work-space .side .container .section-title {
  width: 100%;
  height: 56px;
  margin: 8px;
  padding: 16px;
}
.UI-V2 .work-space .side .container .section-title h3 {
  margin: 0;
}
.UI-V2 .work-space .side .container .section-title h5 {
  margin: 0;
  font-size: 18px;
  font-weight: 700;
}
.UI-V2 .work-space .side .container .section-resume {
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  margin: 0 8px;
  max-width: calc(100% - 50px);
}
.UI-V2 .work-space .side .container .section-resume .line {
  padding: 8px 16px;
  border-radius: 4px;
  position: relative;
}
.UI-V2 .work-space .side .container .section-resume .line.editable:hover button {
  opacity: 1;
}
.UI-V2 .work-space .side .container .section-resume .line.editable button {
  opacity: 0;
  position: absolute;
  right: 16px;
  top: 4px;
}
.UI-V2 .work-space .side .container .section-resume .line .header {
  width: 100%;
}
.UI-V2 .work-space .side .container .section-resume .line .header .info {
  max-width: calc(100% - 40px - 16px - 16px);
}
.UI-V2 .work-space .side .container .section-resume .line .header .info h4 {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
.UI-V2 .work-space .side .container .section-resume .line .header .info h6 {
  font-weight: 700;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
.UI-V2 .work-space .side .container .section-resume .line ul {
  margin-top: 0;
  margin-bottom: 0;
  padding-inline-start: 16px;
  font-weight: 400;
}
.UI-V2 .work-space .side .container .section-resume .line ul + ul {
  margin-top: 16px;
}
.UI-V2 .work-space .side .container .section-resume .line.head {
  padding: 8px 16px;
}
.UI-V2 .work-space .side .container app-certificates {
  width: 100%;
}
.UI-V2 .work-space .side .container app-certificates .employee_detail-sign .employee-title {
  height: 56px;
  margin: 8px;
  padding: 16px;
  box-sizing: border-box;
}
.UI-V2 .work-space .side .container app-certificates .employee_detail-sign .employee-title .title-text {
  display: flex;
  height: 100%;
  align-items: center;
}
.UI-V2 .work-space .side .container app-certificates .employee_detail-sign .employee-title .title-text .title_text-main {
  font-size: 18px;
  font-weight: 700;
}
.UI-V2 .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body {
  padding: 16px;
  border-radius: 8px;
  box-sizing: border-box;
  margin: 0 8px;
}
.UI-V2 .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.UI-V2 .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text {
  padding: 8px 16px;
  border-radius: 4px;
  position: relative;
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: center flex-start;
  align-items: center;
}
.UI-V2 .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  margin-right: 16px;
}
.UI-V2 .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-side {
  font-weight: 700;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.67em;
}
.UI-V2 .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-side u {
  cursor: help;
}
.UI-V2 .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
}
.UI-V2 .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line mat-basic-chip {
  padding: 4px 12px 4px 8px;
}
.UI-V2 .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line mat-basic-chip mat-icon {
  width: 20px !important;
  min-width: 20px !important;
}
.UI-V2 .work-space .side.edition {
  z-index: 2;
}
.UI-V2 .work-space .side.edition .toolbar .header {
  width: 100%;
}
.UI-V2 .work-space .side.edition .container {
  padding: 0;
  overflow-y: unset !important;
}
.UI-V2 .work-space .side.edition .container app-role-user-add {
  display: block !important;
  width: 100%;
  box-sizing: border-box !important;
}
.UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper {
  width: 100% !important;
  min-width: unset;
  background-color: unset !important;
}
.UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container {
  padding: 0;
}
.UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container form {
  max-height: calc(100vh - 32px - 112px);
  overflow-y: scroll;
  padding: 0 8px 0 16px;
  width: 100%;
  position: relative;
  box-sizing: border-box;
}
.UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body {
  padding: 0 16px !important;
  border-radius: 8px !important;
  margin: 0 auto;
  box-sizing: border-box;
}
.UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body + .form-body {
  margin-top: 16px;
}
.UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line {
  padding: 16px 8px;
}
.UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header {
  width: 100%;
}
.UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header h4 {
  margin: 0;
}
.UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header h6 {
  margin: 0;
}
.UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body mat-radio-group {
  padding-bottom: 24px;
}
.UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body mat-radio-group mat-radio-button {
  height: 40px;
  display: flex !important;
  align-items: center;
}
.UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body mat-radio-group mat-radio-button .mat-radio-container {
  margin-left: 18px;
}
.UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body mat-radio-group mat-radio-button .mat-radio-label-content {
  padding-left: 26px;
}
.UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-actions {
  border-top: unset !important;
  padding: 16px 0 !important;
  display: flex;
  flex-direction: row-reverse;
  position: sticky;
  bottom: 0;
}
.UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-actions button + button {
  margin-right: 12px;
}
.UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume {
  max-height: calc(100vh - 32px - 112px);
  overflow-y: scroll;
  padding: 0 8px 0 16px;
  width: 100%;
  position: relative;
}
.UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body {
  padding: 0 !important;
  overflow: hidden;
}
.UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line {
  padding: 16px 40px;
}
.UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header h6 {
  font-weight: 700;
}
.UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header + .header {
  margin-top: 16px;
}
.UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line ul {
  margin-top: 0;
  margin-bottom: 0;
  padding-inline-start: 16px;
  font-weight: 400;
}
.UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line ul + ul {
  margin-top: 16px;
}
.UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head {
  padding: 8px 16px;
}
.UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .section-title {
  margin: 8px 0 !important;
}
.UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .section-title h5 {
  font-weight: 500 !important;
}
.UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume h6.hint {
  margin: 16px 0 !important;
  padding: 16px;
  width: 100%;
  box-sizing: border-box;
  text-align: start !important;
  font-size: 14px;
  border-radius: 8px;
}

app-role-user-find .main-overlay {
  position: absolute;
  bottom: 0;
  height: 100%;
  opacity: 0;
  z-index: 2;
}
app-role-user-find .main-overlay.enabled {
  width: 100% !important;
  opacity: 1;
}
app-role-user-find .icon-complex {
  position: relative;
  height: 40px;
  width: 40px;
  padding: 0 !important;
}
app-role-user-find .icon-complex mat-icon.complex {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 16px !important;
  height: 20px !important;
  width: 20px !important;
  padding: 0 !important;
}
app-role-user-find .navigation {
  position: relative;
}
app-role-user-find .navigation.detail-open {
  overflow: hidden;
  width: 0 !important;
  flex: 0 !important;
}
app-role-user-find .navigation .load-window {
  left: 0;
  top: 112px;
  height: calc(100% - 112px) !important;
}
app-role-user-find .navigation h3 {
  font-weight: 500;
}
app-role-user-find .navigation h5 {
  font-weight: 300;
}
app-role-user-find .navigation .toolbar {
  height: 80px;
  margin: 16px;
  border-radius: 8px;
  padding: 0 16px;
}
app-role-user-find .navigation .toolbar h3,
app-role-user-find .navigation .toolbar h5 {
  margin: 0;
}
app-role-user-find .navigation .container {
  padding: 0 8px 16px 16px;
  overflow-y: scroll;
  max-height: calc(100% - 112px);
  box-sizing: border-box;
}
app-role-user-find .navigation .container .helper {
  padding: 0 16px;
  height: 56px;
  margin-bottom: 16px;
}
app-role-user-find .navigation .container .item {
  padding: 16px;
  border-radius: 8px;
  cursor: pointer;
}
app-role-user-find .navigation .container .item h3 {
  margin: 0;
}
app-role-user-find .navigation .container .item + .item {
  margin-top: 16px;
}
app-role-user-find .navigation .container .item.active {
  cursor: default;
}
app-role-user-find .work-space h5,
app-role-user-find .work-space h6 {
  font-weight: 500;
}
app-role-user-find .work-space .main {
  position: relative;
}
app-role-user-find .work-space .main .load-window {
  left: 0;
  top: 0;
  height: 100%;
}
app-role-user-find .work-space .main.side-open .container .content-scroll .item {
  width: calc(33.3333333333% - 16px);
}
app-role-user-find .work-space .main .container {
  min-height: calc(100vh - 32px - 192px - 97px);
  flex-grow: 1;
  flex-shrink: 1;
}
app-role-user-find .work-space .main .container .content-scroll {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: scroll;
  max-height: calc(100% - 56px);
  box-sizing: border-box;
  padding: 8px 0 8px 8px;
}
app-role-user-find .work-space .main .container .item {
  margin-top: 16px;
  padding: 16px;
  border-radius: 8px;
  width: calc(33.3333333333% - 16px);
  margin: 8px;
  cursor: pointer;
}
app-role-user-find .work-space .main .container .item .subtitle {
  text-transform: lowercase;
}
app-role-user-find .work-space .main .container .item.full-page {
  width: calc(25% - 16px);
}
app-role-user-find .work-space .main .container .item.disabled {
  opacity: 0.7;
}
app-role-user-find .work-space .main .container .item.disabled.selected {
  opacity: 1;
  cursor: default;
}
app-role-user-find .work-space .main .container .item mat-icon.complex {
  bottom: 0;
  right: 0;
}
app-role-user-find .work-space .main .container .item h5 {
  margin: 0;
}
app-role-user-find .work-space .main .container .item h3 {
  margin: 0;
}
app-role-user-find .work-space .main .container .item.selected {
  cursor: default;
}
app-role-user-find .work-space .side {
  height: 100%;
}
app-role-user-find .work-space .side .edition-line {
  border-radius: 8px;
  padding: 16px;
  margin: 16px 0;
  position: relative;
  overflow: hidden;
}
app-role-user-find .work-space .side .edition-line mat-progress-bar {
  bottom: 0;
  left: 0;
}
app-role-user-find .work-space .side mat-form-field {
  width: 100%;
}
app-role-user-find .work-space .side mat-form-field.mat-form-field-disabled * {
  cursor: not-allowed;
}
app-role-user-find .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-underline {
  height: 2px !important;
}
app-role-user-find .work-space .side mat-form-field .mat-form-field-flex {
  border-radius: 4px 4px 0 0 !important;
  padding: 8px 8px 0 !important;
  min-height: 56px !important;
  align-items: unset !important;
}
app-role-user-find .work-space .side mat-form-field .mat-form-field-flex .mat-form-field-prefix {
  padding: 0 !important;
  display: flex;
  align-items: center;
}
app-role-user-find .work-space .side mat-checkbox {
  align-items: center;
  height: 56px;
  display: flex;
  margin-bottom: 16px;
  border-radius: 4px;
  padding: 0 8px;
}
app-role-user-find .work-space .side mat-checkbox .mat-checkbox-layout {
  width: 100%;
  justify-content: flex-start;
  height: 56px;
  align-items: center;
}
app-role-user-find .work-space .side mat-checkbox .mat-checkbox-layout .mat-checkbox-inner-container {
  margin: 12px;
}
app-role-user-find .work-space .side mat-checkbox .mat-checkbox-layout .mat-checkbox-label {
  width: 100%;
  padding-left: 16px;
}
app-role-user-find .work-space .side .hint {
  margin: 0 0 16px;
  font-weight: 700;
  width: 100%;
  text-align: center;
}
app-role-user-find .work-space .side .toolbar {
  height: 112px;
  padding: 16px 32px;
}
app-role-user-find .work-space .side .toolbar .header {
  width: 100%;
}
app-role-user-find .work-space .side .toolbar .header h3 {
  margin: 0;
}
app-role-user-find .work-space .side .toolbar .header h5 {
  margin: 0;
}
app-role-user-find .work-space .side .container {
  padding: 0 0 16px 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: scroll;
  max-height: calc(100% - 112px);
  box-sizing: border-box;
}
app-role-user-find .work-space .side .container .role-resume {
  width: calc(100% - 16px);
  padding: 16px;
  border-radius: 8px;
  margin: 0 8px;
  position: relative;
}
app-role-user-find .work-space .side .container .role-resume .line {
  padding: 8px 16px;
  position: relative;
  border-radius: 4px;
}
app-role-user-find .work-space .side .container .role-resume .line.editable:hover button {
  opacity: 1;
}
app-role-user-find .work-space .side .container .role-resume .line.editable button {
  opacity: 0;
  position: absolute;
  right: 16px;
  top: 4px;
}
app-role-user-find .work-space .side .container .role-resume .line .header {
  width: 100%;
}
app-role-user-find .work-space .side .container .role-resume .line .header .info {
  max-width: calc(100% - 40px - 16px - 16px);
}
app-role-user-find .work-space .side .container .role-resume .line .header .info h4,
app-role-user-find .work-space .side .container .role-resume .line .header .info h6 {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
app-role-user-find .work-space .side .container .section-title {
  width: 100%;
  height: 56px;
  margin: 8px;
  padding: 16px;
}
app-role-user-find .work-space .side .container .section-title h3 {
  margin: 0;
}
app-role-user-find .work-space .side .container .section-title h5 {
  margin: 0;
  font-size: 18px;
  font-weight: 700;
}
app-role-user-find .work-space .side .container .section-resume {
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  margin: 0 8px;
  max-width: calc(100% - 50px);
}
app-role-user-find .work-space .side .container .section-resume .line {
  padding: 8px 16px;
  border-radius: 4px;
  position: relative;
}
app-role-user-find .work-space .side .container .section-resume .line.editable:hover button {
  opacity: 1;
}
app-role-user-find .work-space .side .container .section-resume .line.editable button {
  opacity: 0;
  position: absolute;
  right: 16px;
  top: 4px;
}
app-role-user-find .work-space .side .container .section-resume .line .header {
  width: 100%;
}
app-role-user-find .work-space .side .container .section-resume .line .header .icon-complex mat-icon {
  width: 40px;
  height: 40px;
}
app-role-user-find .work-space .side .container .section-resume .line .header .info {
  max-width: calc(100% - 40px - 16px - 16px);
}
app-role-user-find .work-space .side .container .section-resume .line .header .info h4 {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
app-role-user-find .work-space .side .container .section-resume .line .header .info h6 {
  font-weight: 700;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
app-role-user-find .work-space .side .container .section-resume .line ul {
  margin-top: 0;
  margin-bottom: 0;
  padding-inline-start: 16px;
  font-weight: 400;
}
app-role-user-find .work-space .side .container .section-resume .line ul + ul {
  margin-top: 16px;
}
app-role-user-find .work-space .side .container .section-resume .line.head {
  padding: 8px 16px;
}
app-role-user-find .work-space .side .container app-certificates {
  width: 100%;
}
app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee-title {
  height: 56px;
  margin: 8px;
  padding: 16px;
  box-sizing: border-box;
}
app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee-title .title-text {
  display: flex;
  height: 100%;
  align-items: center;
}
app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee-title .title-text mat-icon {
  height: 40px;
  margin-right: 16px;
  font-size: 20px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee-title .title-text .title_text-main {
  font-size: 18px;
  font-weight: 700;
}
app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body {
  padding: 16px;
  border-radius: 8px;
  box-sizing: border-box;
  margin: 0 8px;
}
app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text {
  padding: 8px 16px;
  border-radius: 4px;
  position: relative;
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: center flex-start;
  align-items: center;
}
app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  margin-right: 16px;
}
app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-side {
  font-weight: 700;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.67em;
}
app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-side u {
  cursor: help;
}
app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
}
app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line mat-basic-chip {
  padding: 4px 12px 4px 8px;
}
app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line mat-basic-chip mat-icon {
  width: 20px !important;
  min-width: 20px !important;
}
app-role-user-find .work-space .side.edition {
  z-index: 2;
}
app-role-user-find .work-space .side.edition .toolbar .header {
  width: 100%;
}
app-role-user-find .work-space .side.edition .container {
  padding: 0;
  overflow-y: unset !important;
}
app-role-user-find .work-space .side.edition .container app-role-user-add {
  display: block !important;
  width: 100%;
  box-sizing: border-box !important;
}
app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper {
  width: 100% !important;
  min-width: unset;
  background-color: unset !important;
}
app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container {
  padding: 0;
}
app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container form {
  max-height: calc(100vh - 32px - 112px);
  overflow-y: scroll;
  padding: 0 8px 0 16px;
  width: 100%;
  position: relative;
  box-sizing: border-box;
}
app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body {
  padding: 0 16px !important;
  border-radius: 8px !important;
  margin: 0 auto;
  box-sizing: border-box;
}
app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body + .form-body {
  margin-top: 16px;
}
app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line {
  padding: 16px 8px;
}
app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header {
  width: 100%;
}
app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header h4 {
  margin: 0;
}
app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header h6 {
  margin: 0;
}
app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body mat-radio-group {
  padding-bottom: 24px;
}
app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body mat-radio-group mat-radio-button {
  height: 40px;
  display: flex !important;
  align-items: center;
}
app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body mat-radio-group mat-radio-button .mat-radio-container {
  margin-left: 18px;
}
app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body mat-radio-group mat-radio-button .mat-radio-label-content {
  padding-left: 26px;
}
app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-actions {
  border-top: unset !important;
  padding: 16px 0 !important;
  display: flex;
  flex-direction: row-reverse;
  position: sticky;
  bottom: 0;
}
app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-actions button + button {
  margin-right: 12px;
}
app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume {
  max-height: calc(100vh - 32px - 112px);
  overflow-y: scroll;
  padding: 0 8px 0 16px;
  width: 100%;
  position: relative;
}
app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body {
  padding: 0 !important;
  overflow: hidden;
}
app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line {
  padding: 16px 40px;
}
app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header h6 {
  font-weight: 700;
}
app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header + .header {
  margin-top: 16px;
}
app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line ul {
  margin-top: 0;
  margin-bottom: 0;
  padding-inline-start: 16px;
  font-weight: 400;
}
app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line ul + ul {
  margin-top: 16px;
}
app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head {
  padding: 8px 16px;
}
app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .section-title {
  margin: 8px 0 !important;
}
app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .section-title h5 {
  font-weight: 500 !important;
}
app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume h6.hint {
  margin: 16px 0 !important;
  padding: 16px;
  width: 100%;
  box-sizing: border-box;
  text-align: start !important;
  font-size: 14px;
  border-radius: 8px;
}
app-role-user-find .work-space app-role-user-detail.disabled .side .container .role-resume .line.editable {
  pointer-events: none;
}
app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line.editable {
  pointer-events: none;
}
app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .mat-icon-button {
  opacity: 0;
  pointer-events: none;
}
app-role-user-find .work-space app-role-user-detail.disabled .side .container app-certificates {
  width: 100%;
}
app-role-user-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  margin-right: 16px;
}

.dropzone {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  height: unset !important;
  padding: 16px !important;
  margin-bottom: 16px !important;
  margin: 10px 0;
  box-sizing: border-box;
}
.dropzone h3,
.dropzone h6 {
  margin: 0;
}
.dropzone .file {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sliding-actions.view {
  display: none;
}

app-file-document-inbox-block-ou mat-list-item .floating-actions {
  visibility: hidden;
  pointer-events: none;
  transition: visivility 0.2s ease;
}
app-file-document-inbox-block-ou mat-list-item :hover .floating-actions {
  visibility: visible;
  pointer-events: all;
  transition: visivility 0.2s ease;
}
app-file-document-inbox-block-ou mat-expansion-panel-header .floating-actions {
  visibility: hidden;
  pointer-events: none;
  transition: visivility 0.2s ease;
  margin-right: 16px;
}
app-file-document-inbox-block-ou mat-expansion-panel-header.mat-expanded .floating-actions {
  visibility: visible;
  pointer-events: all;
  transition: visivility 0.2s ease;
}

app-file-document-list {
  height: 100%;
  display: block;
}

.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: unset !important;
  right: 1px;
  top: 5px !important;
}

app-release-notes-dialog .dialog-footer {
  padding: 16px;
}

.firmador {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 96px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  pointer-events: none;
}
.firmador button {
  pointer-events: all;
}
.firmador .content-action-main {
  height: 64px;
  border-radius: 40px !important;
  padding: 0 16px 0 32px !important;
  min-width: unset !important;
  pointer-events: all;
}
.firmador .content-action-main.disabled {
  cursor: default !important;
  box-shadow: none !important;
}
.firmador .content-action-main .mat-button-wrapper {
  display: flex;
  align-items: center;
}
.firmador .content-action-main .mat-button-wrapper span {
  padding: 0 20px;
  font-size: 20px;
}

.sign-cycle {
  padding: 0;
  box-sizing: content-box;
  position: relative;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  flex: 1;
}
.sign-cycle .mat-divider-horizontal {
  z-index: 0 !important;
}
.sign-cycle mat-icon {
  padding: 8px;
}
.sign-cycle button mat-icon {
  padding: 0;
}
.sign-cycle mat-progress-bar {
  top: 0;
  left: 0;
}
.sign-cycle h1,
.sign-cycle h2,
.sign-cycle h3,
.sign-cycle h4,
.sign-cycle h5,
.sign-cycle h6,
.sign-cycle span {
  margin: 0;
}
.sign-cycle mat-horizontal-stepper {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}
.sign-cycle mat-horizontal-stepper .mat-horizontal-content-container {
  padding: 0;
  height: 100%;
  overflow: visible !important;
}
.sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content > div {
  height: 100%;
}
.sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content {
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 16px;
}
.sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-title-container {
  margin: 16px 32px;
  justify-content: center !important;
  max-width: fit-content !important;
}
.sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-action mat-form-field {
  padding-bottom: 8px;
}
.sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-action mat-form-field .mat-form-field-wrapper {
  margin-bottom: -1.25rem;
  min-width: 250px;
}
.sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-action .content-action-main {
  height: 64px;
  border-radius: 40px !important;
  padding: 0 16px 0 32px !important;
  min-width: unset !important;
}
.sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-action .content-action-main .mat-button-wrapper {
  display: flex;
  align-items: center;
}
.sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-action .content-action-main .mat-button-wrapper span {
  padding: 0 20px;
  font-size: 20px;
}

.resume-status {
  height: 96px;
  padding: 0;
  box-sizing: content-box;
  position: relative;
}
.resume-status .title {
  width: max-content;
  justify-content: center;
}

mat-toolbar-row mat-icon {
  padding: 8px;
}
mat-toolbar-row button mat-icon {
  padding: 0;
}

#closeDetail {
  display: none;
  visibility: hidden;
  pointer-events: none;
}

app-sign-document .mat-avatar .title {
  width: unset;
}
app-sign-document .mat-avatar .title h3,
app-sign-document .mat-avatar .title h5 {
  width: unset;
  overflow: visible;
}

.sign-cycle.employer {
  height: unset;
  padding: 16px 0;
}
.sign-cycle.employer .sign-cycle-content {
  flex-wrap: wrap;
  padding: 0 32px;
  justify-content: space-around;
  align-items: center;
}
.sign-cycle.employer .sign-cycle-content .cycle-content-title-container {
  padding-bottom: 16px;
  justify-content: center !important;
  text-align: center;
}
.sign-cycle.employer .sign-cycle-content .cycle-content-title-container *:not(button) {
  width: 100%;
  text-align: center !important;
  justify-content: center !important;
}

app-home-file-documents app-file-document-sign,
app-pending app-file-document-sign {
  display: block;
  position: relative;
}

.help-hoverable {
  position: relative;
  width: 30px;
  display: flex;
  justify-content: center;
}
.help-hoverable:hover mat-hint {
  visibility: visible !important;
  display: block !important;
}
.help-hoverable.wide mat-hint {
  left: calc(50% - 230px);
  width: 460px;
}
.help-hoverable mat-hint {
  position: absolute;
  padding: 16px;
  bottom: 100%;
  left: calc(50% - 160px);
  margin: 0 auto;
  width: 320px;
  border-radius: 4px;
  z-index: 1;
  white-space: normal;
  box-sizing: border-box;
  max-height: 400px;
  overflow-y: auto;
}
.help-hoverable mat-hint:hover {
  visibility: visible;
}
.help-hoverable mat-hint button {
  float: right;
}
.help-hoverable mat-hint .close-mobile {
  display: none;
}

app-file-document-sign-massive-dialog .dialog-content,
app-share-person-dialog .dialog-content {
  position: relative;
}
app-file-document-sign-massive-dialog .dialog-content mat-toolbar,
app-share-person-dialog .dialog-content mat-toolbar {
  background: none !important;
}
app-file-document-sign-massive-dialog .dialog-content mat-toolbar mat-toolbar-row .mat-avatar,
app-share-person-dialog .dialog-content mat-toolbar mat-toolbar-row .mat-avatar {
  width: 100% !important;
  margin-right: 16px;
}
app-file-document-sign-massive-dialog .dialog-content .dialog-body,
app-share-person-dialog .dialog-content .dialog-body {
  padding: 0;
  position: relative;
  width: 100%;
  display: block !important;
}
app-file-document-sign-massive-dialog .dialog-content .dialog-body p,
app-share-person-dialog .dialog-content .dialog-body p {
  width: 100% !important;
  margin: 0 !important;
  text-align: center;
  padding: 16px 32px;
  box-sizing: border-box;
}
app-file-document-sign-massive-dialog .dialog-content .dialog-body p b,
app-share-person-dialog .dialog-content .dialog-body p b {
  line-height: 26px;
  font-size: 18px;
}
app-file-document-sign-massive-dialog .dialog-content .dialog-body table.mat-table *,
app-share-person-dialog .dialog-content .dialog-body table.mat-table * {
  border: none !important;
}
app-file-document-sign-massive-dialog .dialog-content .dialog-footer,
app-share-person-dialog .dialog-content .dialog-footer {
  padding: 16px 32px;
}
app-file-document-sign-massive-dialog .dialog-content .dialog-table,
app-share-person-dialog .dialog-content .dialog-table {
  max-height: calc(90vh - 65px - 289px);
  overflow: auto;
}
app-file-document-sign-massive-dialog .dialog-content app-file-document-sign,
app-share-person-dialog .dialog-content app-file-document-sign {
  display: flex;
  justify-content: center;
  width: 100%;
}
app-file-document-sign-massive-dialog .dialog-content app-file-document-sign .firmador,
app-share-person-dialog .dialog-content app-file-document-sign .firmador {
  position: unset;
}
app-file-document-sign-massive-dialog .dialog-content app-file-document-sign .firmador button,
app-share-person-dialog .dialog-content app-file-document-sign .firmador button {
  pointer-events: all;
}
app-file-document-sign-massive-dialog .dialog-content app-file-document-sign mat-divider,
app-share-person-dialog .dialog-content app-file-document-sign mat-divider {
  display: none !important;
}
app-file-document-sign-massive-dialog .dialog-content app-file-document-sign .sign-cycle.employer,
app-share-person-dialog .dialog-content app-file-document-sign .sign-cycle.employer {
  width: 100%;
  padding: 0;
}
app-file-document-sign-massive-dialog .dialog-content app-file-document-sign .sign-cycle.employer mat-horizontal-stepper,
app-share-person-dialog .dialog-content app-file-document-sign .sign-cycle.employer mat-horizontal-stepper {
  width: unset !important;
}
app-file-document-sign-massive-dialog .dialog-content app-file-document-sign .sign-cycle.employer mat-horizontal-stepper .sign-cycle-content,
app-share-person-dialog .dialog-content app-file-document-sign .sign-cycle.employer mat-horizontal-stepper .sign-cycle-content {
  display: block !important;
  width: unset;
  padding: 16px 32px !important;
}
app-file-document-sign-massive-dialog .dialog-content app-file-document-sign .sign-cycle.employer mat-horizontal-stepper .sign-cycle-content .cycle-content-title-container,
app-share-person-dialog .dialog-content app-file-document-sign .sign-cycle.employer mat-horizontal-stepper .sign-cycle-content .cycle-content-title-container {
  max-width: unset !important;
}

.chip-line.mat-line {
  display: flex !important;
  align-items: center;
  height: auto;
  width: 100%;
}

.mat-basic-chip {
  padding: 2px 12px 2px 8px;
  border-radius: 16px;
  min-width: fit-content;
  margin: 4px !important;
}
.mat-basic-chip .mat-icon {
  font-size: 16px !important;
}
.mat-basic-chip span {
  font-size: 12px !important;
}
.mat-basic-chip .mat-icon::before {
  line-height: 19px !important;
}

app-file-document-state {
  display: flex;
  height: auto;
  width: 100%;
}
app-file-document-state mat-chip-list {
  height: auto;
  width: 100%;
}
app-file-document-state mat-chip-list .mat-chip-list-wrapper {
  flex-direction: row-reverse;
  margin: 0 0 0 -4px;
  justify-content: flex-end;
  width: 100%;
}
app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip {
  padding: 2px 12px 2px 8px;
  border-radius: 16px;
  min-width: fit-content;
  margin: 4px !important;
}
app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip .mat-icon {
  font-size: 16px !important;
}
app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip span {
  font-size: 12px !important;
}
app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip .mat-icon::before {
  line-height: 19px !important;
}

app-file-document-view-dialog .mat-chip-list-wrapper {
  flex-wrap: nowrap !important;
}

.inbox-container.employee.my-documents .chip-line.mat-line {
  height: 40px;
  flex-shrink: 0;
  width: unset;
}
.inbox-container.employee.my-documents .chip-line.mat-line .mat-chip-list-wrapper {
  padding: 0;
  margin: 0 !important;
  height: 30px;
  flex-wrap: nowrap !important;
  justify-content: flex-start;
}
.inbox-container.employee.my-documents .chip-line.mat-line .mat-chip-list-wrapper .mat-basic-chip {
  padding: 0;
  margin: 4px;
  height: 40px;
  width: 40px;
  box-sizing: border-box !important;
  overflow: visible;
  border: none;
  background: none !important;
}
.inbox-container.employee.my-documents .chip-line.mat-line .mat-chip-list-wrapper .mat-basic-chip.unseen {
  display: none !important;
}
.inbox-container.employee.my-documents .chip-line.mat-line .mat-chip-list-wrapper .mat-basic-chip + mat-basic-chip.seen {
  display: none !important;
}
.inbox-container.employee.my-documents .chip-line.mat-line .mat-chip-list-wrapper .mat-basic-chip .mat-icon {
  margin: 0 !important;
  font-size: 32px !important;
}
.inbox-container.employee.my-documents .chip-line.mat-line .mat-chip-list-wrapper .mat-basic-chip span {
  display: none;
}

.bottom-container .mat-chip {
  transform: scale(1.3);
  transform-origin: center center;
}

app-file-document-state-chips {
  width: 100%;
  display: flex;
}

.chip-group {
  display: flex;
}

.resume-status .mat-basic-chip {
  padding: 4px 16px 4px 12px;
  border-radius: 20px;
  margin-top: 0;
}
.resume-status .mat-basic-chip .mat-icon {
  font-size: 20px !important;
}
.resume-status .mat-basic-chip span {
  font-size: 16px !important;
}

app-file-document-view {
  position: relative;
}
app-file-document-view .new-container {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  display: flex;
  flex: 1;
  overflow: hidden;
}
app-file-document-view .new-container app-file-document-upload {
  display: flex;
  height: 100%;
  flex-direction: column;
  width: 40%;
  min-width: 40%;
}
app-file-document-view .new-container app-file-document-upload app-cs-cropper-control {
  height: 100%;
  padding: 16px;
}
app-file-document-view .new-container app-file-document-upload app-cs-cropper-control .cropper-area {
  justify-content: space-between;
}
app-file-document-view .new-container app-file-document-upload app-cs-cropper-control .cropper-area image-cropper {
  height: calc(100% - 36px - 16px) !important;
  padding: 0 !important;
  overflow: visible !important;
}
app-file-document-view .new-container app-upload-form {
  height: calc(100% - 97px);
  width: 100%;
  margin: 0 !important;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: auto;
}
app-file-document-view .new-container app-upload-form .dropzone {
  flex-grow: 1;
  flex-shrink: 1;
  margin: 16px;
  width: unset !important;
}
app-file-document-view .new-container app-upload-form .file-upload-list {
  margin-bottom: 0;
  overflow-y: auto;
  position: relative;
  padding: 0 16px;
  width: 100%;
  max-width: unset;
}
app-file-document-view .new-container app-upload-form .file-upload-list .mat-subheader {
  position: sticky !important;
  width: 100%;
  z-index: 1;
  top: -1px !important;
  margin-bottom: 16px;
}
app-file-document-view .new-container .firmador {
  position: relative;
}
app-file-document-view .new-container .firmador button {
  pointer-events: all;
}
app-file-document-view .preview-content {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
app-file-document-view .preview-content h4 {
  text-align: center;
  line-height: 1.5;
}
app-file-document-view .upload-indications {
  display: none !important;
}
app-file-document-view app-pdf-wrapper:not([hidden]) {
  display: grid;
  width: 100%;
  height: 100%;
}
app-file-document-view app-pdf-wrapper:not([hidden]) .cardinal-pdf-viewer-container {
  overflow: unset !important;
}
app-file-document-view .view-container {
  height: 100%;
  display: block;
}
app-file-document-view .view-container mat-toolbar.side-bar.upload {
  position: absolute;
  top: -65px;
  z-index: 12;
}
app-file-document-view .view-container mat-toolbar.side-bar .mat-toolbar-row {
  justify-content: space-between;
}
app-file-document-view .view-container mat-toolbar.side-bar .mat-toolbar-row .toolbar-actions button {
  margin: 0 4px;
}
app-file-document-view .view-container .view-content {
  display: flex;
  flex-direction: column;
  height: calc(100% - 64px);
  position: relative;
}
app-file-document-view .view-container .view-content .toggle-buttons-container {
  height: 64px;
  padding: 0 8px;
}
app-file-document-view .view-container .view-content .zip {
  position: absolute;
  top: 0;
}
app-file-document-view .view-container .view-content .zip + app-pdf-wrapper {
  height: 0 !important;
}

app-home-file-documents app-file-document-state.bottom-container,
app-home-file-documents .view-container app-file-document-state {
  display: none;
}

app-file-document-view-dialog .bottom-container {
  display: none;
}

.formio-hint {
  position: absolute;
  bottom: 100%;
  line-height: 20px;
  max-width: 430px;
  padding: 8px 16px;
  border-radius: 30px;
  z-index: 1;
  margin: 0 auto;
  left: 0;
  right: 0;
  text-align: center;
  animation: pulse 2s infinite;
}

.formio-hint {
  position: absolute;
  bottom: 100%;
  line-height: 20px;
  max-width: 430px;
  background: #3F51B5;
  padding: 8px 16px;
  border-radius: 30px;
  color: #fff;
  z-index: 1;
  margin: 0 auto;
  left: 0;
  right: 0;
  text-align: center;
  animation: pulse 2s infinite;
}

.cdk-overlay-pane {
  max-width: 95vw !important;
}

.employee-deetail-dialog {
  position: relative;
}
.employee-deetail-dialog .mat-loading-window {
  position: relative;
  visibility: hidden;
  height: 0;
  width: 0;
}
.employee-deetail-dialog .mat-loading-window.visible {
  visibility: visible;
  width: 150px;
  height: 150px;
}
.employee-deetail-dialog .mat-loading-window .dialog-content {
  visibility: hidden;
  height: 0;
  width: 0;
}
.employee-deetail-dialog .mat-loading-window .dialog-content.visible {
  visibility: visible;
  width: 100%;
  height: 100%;
}
.employee-deetail-dialog.mat-dialog-content {
  padding: 0 !important;
  margin: 0 !important;
  overflow: hidden !important;
  height: 80vh !important;
  max-height: unset !important;
  max-width: 95vw;
  min-width: 70vw;
  position: relative !important;
}
.employee-deetail-dialog app-file-document-state mat-chip-list .mat-chip-list-wrapper {
  flex-wrap: nowrap !important;
}
.employee-deetail-dialog app-pdf-wrapper {
  height: 100%;
}
.employee-deetail-dialog app-pdf-wrapper .pdf-view-container {
  height: 100%;
  overflow: auto;
}
.employee-deetail-dialog .resume-status {
  position: absolute;
  width: 100%;
  bottom: 0;
}

.list-check {
  padding: 0 56px;
}
.list-check .mat-checkbox-layout {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
}
.list-check .mat-checkbox-inner-container {
  width: 16px;
  height: 16px;
  margin: 12px 4px;
  box-sizing: border-box;
}

.list-align {
  padding: 0 56px;
}

mat-dialog-container {
  padding: 0 !important;
  transition: all 0.2s ease;
  overflow: hidden !important;
}
mat-dialog-container mat-toolbar-row {
  justify-content: space-between !important;
}
mat-dialog-container mat-toolbar-row .mat-avatar {
  width: unset !important;
}
mat-dialog-container mat-toolbar-row app-file-document-state-chips {
  width: 100%;
  display: flex;
}
mat-dialog-container app-file-document-state-chips {
  height: 96px;
  display: flex;
  justify-content: center;
  align-items: center;
}
mat-dialog-container app-pdf-wrapper.w-chips {
  height: calc(100% - 65px - 96px) !important;
  display: block;
}
mat-dialog-container app-pdf-wrapper.no-chips {
  height: calc(100% - 65px) !important;
  display: block;
}
mat-dialog-container .firmador {
  position: relative !important;
}
mat-dialog-container .firmador button {
  pointer-events: all;
}
mat-dialog-container .sign-cycle {
  position: relative;
}
mat-dialog-container .sign-cycle .mat-divider {
  position: absolute;
  top: 0;
  width: 100%;
}

app-metadata-filter-dialog .dialog-footer {
  min-height: 56px;
  padding: 16px;
}

app-candidate-promotion {
  display: block;
}
app-candidate-promotion .load-window.message {
  top: 0;
  position: relative;
  height: 100%;
  flex-direction: column;
  padding: 32px;
  box-sizing: border-box;
  text-align: center;
}
app-candidate-promotion .load-window.message h3.primary-text {
  margin-top: 40px;
}
app-candidate-promotion .promote-container {
  display: flex;
  flex-direction: column;
  min-width: 60vw !important;
  max-width: 600px !important;
}

app-multiple-documentation-add-dialog .icon-complex,
app-documentation-type-set-configuration-dialog .icon-complex,
app-add-candidate-dialog .icon-complex,
app-share-person-dialog .icon-complex,
app-share-person-response-dialog .icon-complex {
  position: relative;
}
app-multiple-documentation-add-dialog .icon-complex mat-icon.complex,
app-documentation-type-set-configuration-dialog .icon-complex mat-icon.complex,
app-add-candidate-dialog .icon-complex mat-icon.complex,
app-share-person-dialog .icon-complex mat-icon.complex,
app-share-person-response-dialog .icon-complex mat-icon.complex {
  position: absolute;
  bottom: -8px;
  right: -2px;
  font-size: 16px !important;
  height: 20px;
  width: 20px !important;
  min-width: unset !important;
}
app-multiple-documentation-add-dialog .dialog-content,
app-documentation-type-set-configuration-dialog .dialog-content,
app-add-candidate-dialog .dialog-content,
app-share-person-dialog .dialog-content,
app-share-person-response-dialog .dialog-content {
  max-height: 95vh !important;
  position: relative;
}
app-multiple-documentation-add-dialog .dialog-content .load-window.fixed,
app-documentation-type-set-configuration-dialog .dialog-content .load-window.fixed,
app-add-candidate-dialog .dialog-content .load-window.fixed,
app-share-person-dialog .dialog-content .load-window.fixed,
app-share-person-response-dialog .dialog-content .load-window.fixed {
  top: 0;
  height: 100%;
  min-height: 25vw;
}
app-multiple-documentation-add-dialog .dialog-content .load-window.message,
app-documentation-type-set-configuration-dialog .dialog-content .load-window.message,
app-add-candidate-dialog .dialog-content .load-window.message,
app-share-person-dialog .dialog-content .load-window.message,
app-share-person-response-dialog .dialog-content .load-window.message {
  height: 100%;
}
app-multiple-documentation-add-dialog .dialog-content .toolbar-line,
app-documentation-type-set-configuration-dialog .dialog-content .toolbar-line,
app-add-candidate-dialog .dialog-content .toolbar-line,
app-share-person-dialog .dialog-content .toolbar-line,
app-share-person-response-dialog .dialog-content .toolbar-line {
  padding: 0 !important;
  margin-right: 16px;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container,
app-add-candidate-dialog .dialog-content .document-template-container,
app-share-person-dialog .dialog-content .document-template-container,
app-share-person-response-dialog .dialog-content .document-template-container {
  min-height: 25vw;
  min-width: 40vw;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container.auto-height,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container.auto-height,
app-add-candidate-dialog .dialog-content .document-template-container.auto-height,
app-share-person-dialog .dialog-content .document-template-container.auto-height,
app-share-person-response-dialog .dialog-content .document-template-container.auto-height {
  min-height: auto !important;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container.auto-height .form-steped,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container.auto-height .form-steped,
app-add-candidate-dialog .dialog-content .document-template-container.auto-height .form-steped,
app-share-person-dialog .dialog-content .document-template-container.auto-height .form-steped,
app-share-person-response-dialog .dialog-content .document-template-container.auto-height .form-steped {
  min-height: unset !important;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container.auto-height .form-steped-body,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container.auto-height .form-steped-body,
app-add-candidate-dialog .dialog-content .document-template-container.auto-height .form-steped-body,
app-share-person-dialog .dialog-content .document-template-container.auto-height .form-steped-body,
app-share-person-response-dialog .dialog-content .document-template-container.auto-height .form-steped-body {
  min-height: unset !important;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .set-create-add,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .set-create-add,
app-add-candidate-dialog .dialog-content .document-template-container .set-create-add,
app-share-person-dialog .dialog-content .document-template-container .set-create-add,
app-share-person-response-dialog .dialog-content .document-template-container .set-create-add {
  margin: calc((25vw - 80px - 64px) / 2 - 18px) auto !important;
  left: 0;
  right: 0;
  display: block;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .set-create-add + div > .mat-list,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .set-create-add + div > .mat-list,
app-add-candidate-dialog .dialog-content .document-template-container .set-create-add + div > .mat-list,
app-share-person-dialog .dialog-content .document-template-container .set-create-add + div > .mat-list,
app-share-person-response-dialog .dialog-content .document-template-container .set-create-add + div > .mat-list {
  height: 0 !important;
  min-height: 0 !important;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container.expanded,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container.expanded,
app-add-candidate-dialog .dialog-content .document-template-container.expanded,
app-share-person-dialog .dialog-content .document-template-container.expanded,
app-share-person-response-dialog .dialog-content .document-template-container.expanded {
  max-width: 95vw;
  height: 95vh;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container.expanded mat-list,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container.expanded mat-list,
app-add-candidate-dialog .dialog-content .document-template-container.expanded mat-list,
app-share-person-dialog .dialog-content .document-template-container.expanded mat-list,
app-share-person-response-dialog .dialog-content .document-template-container.expanded mat-list {
  height: calc(95vh - 80px - 64px);
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container.expanded .form-steped,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container.expanded .form-steped,
app-add-candidate-dialog .dialog-content .document-template-container.expanded .form-steped,
app-share-person-dialog .dialog-content .document-template-container.expanded .form-steped,
app-share-person-response-dialog .dialog-content .document-template-container.expanded .form-steped {
  height: calc(95vh - 80px);
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container.expanded .form-steped .form-steped-body,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container.expanded .form-steped .form-steped-body,
app-add-candidate-dialog .dialog-content .document-template-container.expanded .form-steped .form-steped-body,
app-share-person-dialog .dialog-content .document-template-container.expanded .form-steped .form-steped-body,
app-share-person-response-dialog .dialog-content .document-template-container.expanded .form-steped .form-steped-body {
  height: 100%;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container.expanded .set-create-add,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container.expanded .set-create-add,
app-add-candidate-dialog .dialog-content .document-template-container.expanded .set-create-add,
app-share-person-dialog .dialog-content .document-template-container.expanded .set-create-add,
app-share-person-response-dialog .dialog-content .document-template-container.expanded .set-create-add {
  margin: calc((95vh - 80px - 64px) / 2 - 18px) auto !important;
  left: 0;
  right: 0;
  display: block;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container.expanded .upload-content,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container.expanded .upload-content,
app-add-candidate-dialog .dialog-content .document-template-container.expanded .upload-content,
app-share-person-dialog .dialog-content .document-template-container.expanded .upload-content,
app-share-person-response-dialog .dialog-content .document-template-container.expanded .upload-content {
  height: 100% !important;
  max-height: unset;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container.expanded .upload-content.pdf-visible app-file-document-detail,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container.expanded .upload-content.pdf-visible app-file-document-detail,
app-add-candidate-dialog .dialog-content .document-template-container.expanded .upload-content.pdf-visible app-file-document-detail,
app-share-person-dialog .dialog-content .document-template-container.expanded .upload-content.pdf-visible app-file-document-detail,
app-share-person-response-dialog .dialog-content .document-template-container.expanded .upload-content.pdf-visible app-file-document-detail {
  position: absolute;
  right: 0;
  z-index: 201;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection,
app-share-person-dialog .dialog-content .document-template-container .document-selection,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection {
  width: 100%;
  min-width: 25vw;
  height: 100%;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description,
app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description {
  height: 80px;
  padding: 0 16px;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description .header,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description .header,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description .header,
app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description .header,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description .header {
  margin-right: 16px;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description .header button,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description .header button,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description .header button,
app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description .header button,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description .header button {
  height: 32px;
  padding: 0 0 0 10px;
  margin: 0 -8px;
  border-radius: 20px;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description .header button .mat-button-wrapper,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description .header button .mat-button-wrapper,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description .header button .mat-button-wrapper,
app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description .header button .mat-button-wrapper,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description .header button .mat-button-wrapper {
  height: 32px;
  line-height: 32px;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description .header h3,
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description .header h5,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description .header h3,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description .header h5,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description .header h3,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description .header h5,
app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description .header h3,
app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description .header h5,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description .header h3,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description .header h5 {
  margin: 0;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list,
app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list {
  max-height: calc(95vh - 80px - 64px);
  overflow: auto;
  overflow-y: overlay;
  min-height: calc(25vw - 80px - 64px);
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list.reactive .mat-list-item-content,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list.reactive .mat-list-item-content,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list.reactive .mat-list-item-content,
app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list.reactive .mat-list-item-content,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list.reactive .mat-list-item-content {
  cursor: pointer;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content,
app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content {
  opacity: 0.8;
  transition: opacity 0.2s ease;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover,
app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover {
  transition: opacity 0.1s ease;
  opacity: 1;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .mat-line b,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .mat-line b,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .mat-line b,
app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .mat-line b,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .mat-line b {
  width: 100% !important;
  white-space: nowrap !important;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action,
app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action {
  opacity: 1;
  transition: all 0.1s ease;
  pointer-events: all;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex,
app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex {
  min-width: 40px;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line,
app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line {
  margin-right: 16px;
  white-space: normal;
  max-width: calc(100% - 160px - 32px);
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line h5,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line h5,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line h5,
app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line h5,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line h5 {
  margin: 0;
  font-weight: normal;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b,
app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b {
  max-width: 15vw;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line .mat-chip,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line .mat-chip,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line .mat-chip,
app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line .mat-chip,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line .mat-chip {
  padding: 0 12px;
  font-size: 12px;
  margin-top: 8px;
  box-sizing: border-box;
  max-width: 60%;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line .mat-chip.mat-chip-disabled,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line .mat-chip.mat-chip-disabled,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line .mat-chip.mat-chip-disabled,
app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line .mat-chip.mat-chip-disabled,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line .mat-chip.mat-chip-disabled {
  pointer-events: none;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line .mat-chip .mat-icon,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line .mat-chip .mat-icon,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line .mat-chip .mat-icon,
app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line .mat-chip .mat-icon,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line .mat-chip .mat-icon {
  width: unset !important;
  min-width: unset !important;
  font-size: 14px !important;
  line-height: 14px !important;
  height: 14px !important;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line .mat-chip span,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line .mat-chip span,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line .mat-chip span,
app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line .mat-chip span,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line .mat-chip span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  width: 100%;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action,
app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action {
  position: absolute;
  height: 100%;
  padding: 0 16px;
  right: 0;
  opacity: 0;
  pointer-events: none;
  transition: all 0.2s ease;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex,
app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex {
  width: 40px;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list button mat-icon,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list button mat-icon,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list button mat-icon,
app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list button mat-icon,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list button mat-icon {
  min-width: 40px !important;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped,
app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped {
  min-height: calc(25vw - 80px);
  max-height: calc(95vh - 80px);
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body,
app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body {
  padding: 0 16px;
  min-height: calc(25vw - 80px - 72px);
  max-height: calc(95vh - 80px - 72px);
  overflow-y: auto;
  margin-bottom: 8px;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group,
app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group {
  height: calc(100% - 56px);
  overflow-y: auto;
  min-height: calc(25vw - 80px - 72px - 56px);
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button,
app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button {
  padding: 16px 0;
  display: flex;
  align-items: center;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label,
app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label {
  height: 100%;
  display: flex;
  align-items: center;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-container,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-container,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-container,
app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-container,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-container {
  margin: 10px;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content,
app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content {
  padding-left: 16px;
  width: 100%;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b,
app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b {
  max-width: 15vw;
  line-break: normal;
  white-space: break-spaces;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content h5,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content h5,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content h5,
app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content h5,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content h5 {
  margin: 0;
  font-weight: normal;
  max-width: 15vw;
  line-break: normal;
  white-space: break-spaces;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body mat-checkbox .mat-checkbox-layout,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body mat-checkbox .mat-checkbox-layout,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body mat-checkbox .mat-checkbox-layout,
app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body mat-checkbox .mat-checkbox-layout,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body mat-checkbox .mat-checkbox-layout {
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body mat-checkbox .mat-checkbox-layout .mat-checkbox-inner-container,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body mat-checkbox .mat-checkbox-layout .mat-checkbox-inner-container,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body mat-checkbox .mat-checkbox-layout .mat-checkbox-inner-container,
app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body mat-checkbox .mat-checkbox-layout .mat-checkbox-inner-container,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body mat-checkbox .mat-checkbox-layout .mat-checkbox-inner-container {
  margin: 15px 12px !important;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body mat-checkbox .mat-checkbox-layout .mat-checkbox-label,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body mat-checkbox .mat-checkbox-layout .mat-checkbox-label,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body mat-checkbox .mat-checkbox-layout .mat-checkbox-label,
app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body mat-checkbox .mat-checkbox-layout .mat-checkbox-label,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body mat-checkbox .mat-checkbox-layout .mat-checkbox-label {
  margin-left: 16px;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input,
app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input {
  position: relative;
  padding: 16px 0;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble {
  transform: scale(1.05) !important;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input:hover .bubble,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input:hover .bubble,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input:hover .bubble,
app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input:hover .bubble,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input:hover .bubble {
  transform: scale(1.05) !important;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.big,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.big,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.big,
app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.big,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.big {
  height: auto !important;
  padding: 0;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator,
app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator {
  min-width: 40px;
  margin-right: 16px;
  justify-content: center;
  display: flex;
  align-items: center;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble,
app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble {
  height: 30px;
  width: 30px;
  align-items: center;
  justify-content: center;
  display: flex;
  transform: scale(1);
  border-radius: 15px;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide,
app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide {
  position: absolute;
  border-left: 1px solid;
  height: calc(50% - 20px);
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide.top,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide.top,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide.top,
app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide.top,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide.top {
  bottom: calc(50% + 20px);
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide.bottom,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide.bottom,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide.bottom,
app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide.bottom,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide.bottom {
  top: calc(50% + 20px);
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input textarea,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input textarea,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input textarea,
app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input textarea,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input textarea {
  overflow: hidden !important;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble,
app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble {
  background: none !important;
  font-size: 20px;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .main-action,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .main-action,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection .main-action,
app-share-person-dialog .dialog-content .document-template-container .document-selection .main-action,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection .main-action {
  height: 64px;
  padding: 8px 16px 16px;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .main-action button,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .main-action button,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection .main-action button,
app-share-person-dialog .dialog-content .document-template-container .document-selection .main-action button,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection .main-action button {
  width: 100%;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .list-action,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .list-action,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection .list-action,
app-share-person-dialog .dialog-content .document-template-container .document-selection .list-action,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection .list-action {
  padding: 0 16px;
  height: 64px;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .list-action button,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .list-action button,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection .list-action button,
app-share-person-dialog .dialog-content .document-template-container .document-selection .list-action button,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection .list-action button {
  width: 100%;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .list-action button .mat-button-wrapper,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .list-action button .mat-button-wrapper,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection .list-action button .mat-button-wrapper,
app-share-person-dialog .dialog-content .document-template-container .document-selection .list-action button .mat-button-wrapper,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection .list-action button .mat-button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit,
app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit {
  height: 100%;
  display: block;
  width: 100%;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .add-open > .title-description,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .add-open > .title-description,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .add-open > .title-description,
app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .add-open > .title-description,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .add-open > .title-description {
  display: none !important;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .add-open .sets-content,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .add-open .sets-content,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .add-open .sets-content,
app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .add-open .sets-content,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .add-open .sets-content {
  display: none !important;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content,
app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content {
  width: 100% !important;
  max-height: 95vh;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content.pdf-visible,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content.pdf-visible,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content.pdf-visible,
app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content.pdf-visible,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content.pdf-visible {
  height: 95vh !important;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content.pdf-visible .upload-container,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content.pdf-visible .upload-container,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content.pdf-visible .upload-container,
app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content.pdf-visible .upload-container,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content.pdf-visible .upload-container {
  min-width: 25vw;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content.pdf-visible app-file-document-detail,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content.pdf-visible app-file-document-detail,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content.pdf-visible app-file-document-detail,
app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content.pdf-visible app-file-document-detail,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content.pdf-visible app-file-document-detail {
  display: block;
  width: calc(100% - 25vw);
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content .upload-container,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content .upload-container,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content .upload-container,
app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content .upload-container,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content .upload-container {
  height: 100%;
  min-height: 25vw;
  justify-content: space-between;
  width: 100%;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content .upload-container .title-description,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content .upload-container .title-description,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content .upload-container .title-description,
app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content .upload-container .title-description,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content .upload-container .title-description {
  min-height: 80px;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content .upload-container app-upload-form,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content .upload-container app-upload-form,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content .upload-container app-upload-form,
app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content .upload-container app-upload-form,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content .upload-container app-upload-form {
  width: 100%;
  padding: 8px 16px;
  box-sizing: border-box;
  height: calc(100% - 80px - 64px);
  display: flex;
  flex-direction: column;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content .upload-container app-upload-form .dropzone,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content .upload-container app-upload-form .dropzone,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content .upload-container app-upload-form .dropzone,
app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content .upload-container app-upload-form .dropzone,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content .upload-container app-upload-form .dropzone {
  height: calc(100% - 32px) !important;
  box-sizing: border-box;
  margin: 16px 0;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content .upload-container app-upload-form .file-upload-list,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content .upload-container app-upload-form .file-upload-list,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content .upload-container app-upload-form .file-upload-list,
app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content .upload-container app-upload-form .file-upload-list,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content .upload-container app-upload-form .file-upload-list {
  width: 100% !important;
  max-width: 25vw;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content .upload-container app-upload-form .file-upload-list .mat-line,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content .upload-container app-upload-form .file-upload-list .mat-line,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content .upload-container app-upload-form .file-upload-list .mat-line,
app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content .upload-container app-upload-form .file-upload-list .mat-line,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content .upload-container app-upload-form .file-upload-list .mat-line {
  white-space: nowrap !important;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar,
app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar {
  min-height: 80px;
  border-bottom: none;
  justify-content: center;
  padding: 0;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row,
app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row {
  width: 100%;
  justify-content: flex-end !important;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content,
app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content {
  height: calc(100% - 80px);
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper,
app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper {
  display: block;
  height: 100%;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper .pdf-view-container,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper .pdf-view-container,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper .pdf-view-container,
app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper .pdf-view-container,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper .pdf-view-container {
  overflow-y: auto !important;
  padding: 0 32px 24px !important;
  box-sizing: border-box;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper .pdf-view-container pdf-viewer .cardinal-pdf-viewer-container,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper .pdf-view-container pdf-viewer .cardinal-pdf-viewer-container,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper .pdf-view-container pdf-viewer .cardinal-pdf-viewer-container,
app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper .pdf-view-container pdf-viewer .cardinal-pdf-viewer-container,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper .pdf-view-container pdf-viewer .cardinal-pdf-viewer-container {
  overflow-x: unset !important;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit mat-list,
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit mat-selection-list,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit mat-list,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit mat-selection-list,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit mat-list,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit mat-selection-list,
app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit mat-list,
app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit mat-selection-list,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit mat-list,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit mat-selection-list {
  max-height: calc(95vh - 80px - 64px) !important;
  overflow: auto;
  overflow-y: overlay;
  min-height: calc(25vw - 80px - 64px);
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit mat-list mat-list-option,
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit mat-selection-list mat-list-option,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit mat-list mat-list-option,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit mat-selection-list mat-list-option,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit mat-list mat-list-option,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit mat-selection-list mat-list-option,
app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit mat-list mat-list-option,
app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit mat-selection-list mat-list-option,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit mat-list mat-list-option,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit mat-selection-list mat-list-option {
  height: unset !important;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit mat-list mat-list-option .mat-list-text .mat-line,
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit mat-selection-list mat-list-option .mat-list-text .mat-line,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit mat-list mat-list-option .mat-list-text .mat-line,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit mat-selection-list mat-list-option .mat-list-text .mat-line,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit mat-list mat-list-option .mat-list-text .mat-line,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit mat-selection-list mat-list-option .mat-list-text .mat-line,
app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit mat-list mat-list-option .mat-list-text .mat-line,
app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit mat-selection-list mat-list-option .mat-list-text .mat-line,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit mat-list mat-list-option .mat-list-text .mat-line,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit mat-selection-list mat-list-option .mat-list-text .mat-line {
  white-space: normal !important;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit mat-list mat-pseudo-checkbox,
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit mat-selection-list mat-pseudo-checkbox,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit mat-list mat-pseudo-checkbox,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit mat-selection-list mat-pseudo-checkbox,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit mat-list mat-pseudo-checkbox,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit mat-selection-list mat-pseudo-checkbox,
app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit mat-list mat-pseudo-checkbox,
app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit mat-selection-list mat-pseudo-checkbox,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit mat-list mat-pseudo-checkbox,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit mat-selection-list mat-pseudo-checkbox {
  margin: 0 12px;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .main-action,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .main-action,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .main-action,
app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .main-action,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .main-action {
  height: 64px;
  padding: 0 16px;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop,
app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop,
app-share-person-dialog .dialog-content .document-template-container .add-docs-drop,
app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop {
  min-width: 70vw !important;
  position: relative;
  max-height: unset !important;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop app-file-document-detail,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop app-file-document-detail,
app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop app-file-document-detail,
app-share-person-dialog .dialog-content .document-template-container .add-docs-drop app-file-document-detail,
app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop app-file-document-detail {
  border-left: none !important;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .modal-close,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .modal-close,
app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .modal-close,
app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .modal-close,
app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .modal-close {
  position: absolute;
  right: 16px;
  top: 16px;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .set-create,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .set-create,
app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .set-create,
app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .set-create,
app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .set-create {
  position: absolute;
  bottom: 32px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .catcher,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .catcher,
app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .catcher,
app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .catcher,
app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .catcher {
  position: absolute !important;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview,
app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview,
app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview,
app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview {
  width: 100% !important;
  height: 100% !important;
  flex-grow: 2;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body,
app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body,
app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body,
app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body {
  width: 33.3333333333% !important;
  min-width: 33.3333333333% !important;
  padding: 0 !important;
  overflow: hidden !important;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description,
app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description,
app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description,
app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description {
  height: 64px;
  padding: 0 16px;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header,
app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header,
app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header,
app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header {
  margin-right: 16px;
  width: 100%;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3,
app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5,
app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3,
app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5,
app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3,
app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5,
app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3,
app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5 {
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller,
app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller,
app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller,
app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller {
  height: calc(100% - 64px) !important;
  max-height: unset;
  padding: 0 16px 16px !important;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller .dropzone,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller .dropzone,
app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller .dropzone,
app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller .dropzone,
app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller .dropzone {
  text-align: center;
  margin-bottom: 16px;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5,
app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5,
app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5,
app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5 {
  margin: 0;
  line-height: 40px;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .dropzone,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .dropzone,
app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .dropzone,
app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .dropzone,
app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .dropzone {
  text-align: center;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview app-file-document-detail,
app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .preview-container,
app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview app-formio-cardinal,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview app-file-document-detail,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .preview-container,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview app-formio-cardinal,
app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview app-file-document-detail,
app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .preview-container,
app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview app-formio-cardinal,
app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview app-file-document-detail,
app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .preview-container,
app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview app-formio-cardinal,
app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview app-file-document-detail,
app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .preview-container,
app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview app-formio-cardinal {
  min-width: 66.6666666667% !important;
  height: calc(95vh - 64px - 80px) !important;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .view-container .view-toolbar + .view-content,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .view-container .view-toolbar + .view-content,
app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .view-container .view-toolbar + .view-content,
app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .view-container .view-toolbar + .view-content,
app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .view-container .view-toolbar + .view-content {
  display: block;
  height: calc(100% - 64px);
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .view-container .view-toolbar + .view-content .cardinal-pdf-viewer-container,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .view-container .view-toolbar + .view-content .cardinal-pdf-viewer-container,
app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .view-container .view-toolbar + .view-content .cardinal-pdf-viewer-container,
app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .view-container .view-toolbar + .view-content .cardinal-pdf-viewer-container,
app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .view-container .view-toolbar + .view-content .cardinal-pdf-viewer-container {
  overflow-x: unset;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator,
app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator,
app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator,
app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator {
  min-height: 80px;
  overflow-x: scroll;
  overflow-y: hidden;
  padding: 12px 16px 4px;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list,
app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list,
app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list,
app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list {
  flex: 0 0 auto;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper,
app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper,
app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper,
app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper {
  flex-wrap: nowrap !important;
  margin: 0 !important;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip,
app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip,
app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip,
app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip {
  padding: 0 8px !important;
  margin: 0 !important;
  font-size: 16px !important;
  height: auto !important;
  border-radius: 10px !important;
  flex: 0 0 auto;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header,
app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header,
app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header,
app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header {
  margin-right: 16px !important;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled,
app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled,
app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled,
app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled {
  pointer-events: none;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove,
app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove,
app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove,
app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove {
  pointer-events: all;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled:hover::after,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled:hover::after,
app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled:hover::after,
app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled:hover::after,
app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled:hover::after {
  display: none !important;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip:last-child,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip:last-child,
app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip:last-child,
app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip:last-child,
app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip:last-child {
  margin-right: 16px !important;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip + mat-chip,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip + mat-chip,
app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip + mat-chip,
app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip + mat-chip,
app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip + mat-chip {
  margin-left: 16px !important;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip .header,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip .header,
app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip .header,
app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip .header,
app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip .header {
  height: 56px;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip .header mat-icon,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip .header mat-icon,
app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip .header mat-icon,
app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip .header mat-icon,
app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip .header mat-icon {
  min-width: 40px;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip .header h5,
app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip .header h3,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip .header h5,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip .header h3,
app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip .header h5,
app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip .header h3,
app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip .header h5,
app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip .header h3,
app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip .header h5,
app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip .header h3 {
  margin: 0 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 170px !important;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove,
app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove,
app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove,
app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove {
  min-width: 40px;
  margin-left: 8px !important;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .preview-action,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .preview-action,
app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .preview-action,
app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .preview-action,
app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .preview-action {
  min-height: 64px;
  padding: 0 16px;
  position: relative;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .preview-action mat-progress-bar,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .preview-action mat-progress-bar,
app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .preview-action mat-progress-bar,
app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .preview-action mat-progress-bar,
app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .preview-action mat-progress-bar {
  top: 0;
  left: 0;
  margin: 0 !important;
}

app-multiple-documentation-add-dialog .dialog-content {
  width: 95vw !important;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection {
  max-width: 30vw;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line {
  max-width: unset !important;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b {
  width: 100%;
  max-width: unset;
  white-space: nowrap;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop {
  min-width: unset !important;
  width: 200% !important;
  max-width: 70vw;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body {
  width: 100% !important;
  min-width: 25vw !important;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .preview-container,
app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview app-chapa,
app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview app-file-document-detail {
  width: 200% !important;
  min-width: unset !important;
  max-width: unset !important;
}

.tyc .mat-card-header .mat-card-title {
  flex-direction: row !important;
  align-items: center;
}

.tyc .brand {
  max-height: 6rem;
  width: 80% !important;
  margin-bottom: 0 !important;
  background-position-x: left;
}

.tyc .tyc-container {
  max-height: calc(90vh - 32px - 108px - 24px - 36px - 16px);
  margin-bottom: 0 !important;
}

.tyc .sticky {
  top: 0px;
  position: sticky;
  z-index: 10;
}

.tyc .title {
  white-space: normal;
  max-width: calc(100% - 176px);
  line-height: 32px;
  display: block;
}

.tyc-container.tyc-text {
  margin-bottom: 16px !important;
  max-height: calc(90vh - 32px - 108px - 36px - 72px);
}

.tyc mat-card-header.mat-card-header {
  margin-bottom: 0;
}

app-cs-grid-control {
  height: calc(100% - 64px - 92px);
}

.cs-grid-test {
  height: 100% !important;
}
.cs-grid-test ngx-datatable {
  height: 100% !important;
  width: 100% !important;
  box-shadow: none !important;
}
.cs-grid-test ngx-datatable .visible {
  height: 100% !important;
}
.cs-grid-test ngx-datatable datatable-header {
  width: 100% !important;
  height: 56px !important;
}
.cs-grid-test ngx-datatable datatable-header .datatable-header-inner {
  height: calc(100% - 1px);
}
.cs-grid-test ngx-datatable datatable-header .datatable-row-left,
.cs-grid-test ngx-datatable datatable-header .datatable-row-center,
.cs-grid-test ngx-datatable datatable-header .datatable-row-right {
  height: 100% !important;
}
.cs-grid-test ngx-datatable datatable-header datatable-header-cell {
  display: flex !important;
  align-items: center;
  padding: 0.9rem 16px !important;
}
.cs-grid-test ngx-datatable datatable-header datatable-header-cell.actions-cell {
  justify-content: flex-end;
}
.cs-grid-test ngx-datatable datatable-header datatable-header-cell .datatable-header-cell-template-wrap {
  display: flex !important;
  align-items: center !important;
}
.cs-grid-test ngx-datatable datatable-header datatable-header-cell .datatable-header-cell-template-wrap .sort-btn {
  height: 40px !important;
  width: 40px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.cs-grid-test ngx-datatable datatable-header datatable-header-cell .datatable-header-cell-template-wrap .sort-btn:before {
  width: 100% !important;
  height: 100% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 15px !important;
}
.cs-grid-test ngx-datatable datatable-body {
  height: calc(100% - 108px) !important;
  width: 100% !important;
}
.cs-grid-test ngx-datatable datatable-body datatable-selection {
  display: flex;
  width: 100% !important;
  height: 100% !important;
}
.cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller {
  height: 100% !important;
}
.cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper {
  height: 6.6666666667% !important;
  min-height: 50px !important;
}
.cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row {
  height: 100% !important;
}
.cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active * {
  transition-property: color;
  transition-duration: 0.3s;
  transition-timing-function: linear;
}
.cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active:hover .datatable-body-cell-label {
  transition-property: background;
  transition-duration: 0.3s;
  transition-timing-function: linear;
}
.cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active.row-cancel * {
  transition-property: color;
  transition-duration: 0.3s;
  transition-timing-function: linear;
}
.cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active.row-cancel .datatable-row-group {
  transition-property: background;
  transition-duration: 0.3s;
  transition-timing-function: linear;
}
.cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active.row-cancel:hover .datatable-body-cell-label {
  transition-property: background;
  transition-duration: 0.3s;
  transition-timing-function: linear;
}
.cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.row-cancel * {
  color: #b0bec5 !important;
  transition-property: color;
  transition-duration: 0.3s;
  transition-timing-function: linear;
}
.cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row:hover .datatable-body-cell-label {
  transition-property: background;
  transition-duration: 0.3s;
  transition-timing-function: linear;
}
.cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row:hover .datatable-body-cell-label .cs-floating-action {
  opacity: 1 !important;
  transition: opacity 0.1s ease-in;
}
.cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row datatable-body-cell {
  display: flex !important;
  align-items: center;
  padding: 0.9rem 16px !important;
  min-height: 50px !important;
  height: 100% !important;
}
.cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row datatable-body-cell.actions-cell {
  justify-content: flex-end;
  overflow: visible !important;
  z-index: 200;
}
.cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row datatable-body-cell.candidate-status_bar {
  padding: 0 16px !important;
}
.cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row datatable-body-cell.candidate-status_bar .datatable-body-cell-label {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row datatable-body-cell.candidate-status_bar mat-progress-bar {
  position: relative !important;
  margin-right: 8px;
  height: 8px;
  border-radius: 4px;
}
.cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row datatable-body-cell.candidate-status_bar mat-progress-bar.class1 .mat-progress-bar-fill::after {
  background-color: #F44336;
}
.cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row datatable-body-cell.candidate-status_bar mat-progress-bar.class2 .mat-progress-bar-fill::after {
  background-color: #FF9800;
}
.cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row datatable-body-cell.candidate-status_bar mat-progress-bar.class3 .mat-progress-bar-fill::after {
  background-color: #FFEB3B;
}
.cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row datatable-body-cell.candidate-status_bar mat-progress-bar.class4 .mat-progress-bar-fill::after {
  background-color: #8BC34A;
}
.cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row datatable-body-cell.candidate-status_bar .status_bar-count {
  font-size: 10px;
}
.cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row datatable-body-cell .cs-floating-action {
  opacity: 0;
  transition: opacity 0.2s ease-out;
}
.cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row datatable-body-cell .cs-floating-action + .cs-floating-action {
  margin-left: 8px;
}
.cs-grid-test .ngx-datatable {
  display: block;
  overflow: hidden;
  justify-content: center;
  position: relative;
  transform: translate3d(0, 0, 0);
}
.cs-grid-test .ngx-datatable [hidden] {
  display: none !important;
}
.cs-grid-test .ngx-datatable *,
.cs-grid-test .ngx-datatable *:before,
.cs-grid-test .ngx-datatable *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.cs-grid-test .ngx-datatable.scroll-vertical .datatable-body {
  overflow-y: auto;
}
.cs-grid-test .ngx-datatable.scroll-vertical.virtualized .datatable-body .datatable-row-wrapper {
  position: absolute;
}
.cs-grid-test .ngx-datatable.scroll-horz .datatable-body {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.cs-grid-test .ngx-datatable.fixed-header .datatable-header .datatable-header-inner {
  white-space: nowrap;
}
.cs-grid-test .ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cs-grid-test .ngx-datatable.fixed-row .datatable-scroll {
  white-space: nowrap;
}
.cs-grid-test .ngx-datatable.fixed-row .datatable-scroll .datatable-body-row {
  white-space: nowrap;
}
.cs-grid-test .ngx-datatable.fixed-row .datatable-scroll .datatable-body-row .datatable-body-cell {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.cs-grid-test .ngx-datatable.fixed-row .datatable-scroll .datatable-body-row .datatable-body-group-cell {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.cs-grid-test .ngx-datatable .datatable-body-row,
.cs-grid-test .ngx-datatable .datatable-row-center,
.cs-grid-test .ngx-datatable .datatable-header-inner {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  -webkit-flex-flow: row;
  -moz-flex-flow: row;
  -ms-flex-flow: row;
  -o-flex-flow: row;
  flex-flow: row;
}
.cs-grid-test .ngx-datatable .datatable-body-cell,
.cs-grid-test .ngx-datatable .datatable-header-cell {
  overflow-x: hidden;
  vertical-align: top;
  display: inline-block;
  line-height: 1.625;
}
.cs-grid-test .ngx-datatable .datatable-body-cell:focus,
.cs-grid-test .ngx-datatable .datatable-header-cell:focus {
  outline: none;
}
.cs-grid-test .ngx-datatable .datatable-row-left,
.cs-grid-test .ngx-datatable .datatable-row-right {
  z-index: 9;
}
.cs-grid-test .ngx-datatable .datatable-row-left,
.cs-grid-test .ngx-datatable .datatable-row-center,
.cs-grid-test .ngx-datatable .datatable-row-group,
.cs-grid-test .ngx-datatable .datatable-row-right {
  position: relative;
}
.cs-grid-test .ngx-datatable .datatable-header {
  display: block;
  overflow: hidden;
}
.cs-grid-test .ngx-datatable .datatable-header .datatable-header-inner {
  align-items: stretch;
  -webkit-align-items: stretch;
}
.cs-grid-test .ngx-datatable .datatable-header .datatable-header-cell {
  position: relative;
  display: inline-block;
}
.cs-grid-test .ngx-datatable .datatable-header .datatable-header-cell.sortable .datatable-header-cell-wrapper {
  cursor: pointer;
}
.cs-grid-test .ngx-datatable .datatable-header .datatable-header-cell.longpress .datatable-header-cell-wrapper {
  cursor: move;
}
.cs-grid-test .ngx-datatable .datatable-header .datatable-header-cell .sort-btn {
  line-height: 100%;
  vertical-align: middle;
  display: inline-block;
  cursor: pointer;
}
.cs-grid-test .ngx-datatable .datatable-header .datatable-header-cell .resize-handle,
.cs-grid-test .ngx-datatable .datatable-header .datatable-header-cell .resize-handle--not-resizable {
  display: inline-block;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 5px;
  padding: 0 4px;
  visibility: hidden;
}
.cs-grid-test .ngx-datatable .datatable-header .datatable-header-cell .resize-handle {
  cursor: ew-resize;
}
.cs-grid-test .ngx-datatable .datatable-header .datatable-header-cell.resizeable:hover .resize-handle {
  visibility: visible;
}
.cs-grid-test .ngx-datatable .datatable-header .datatable-header-cell:hover .resize-handle--not-resizable {
  visibility: visible;
}
.cs-grid-test .ngx-datatable .datatable-header .datatable-header-cell .targetMarker {
  position: absolute;
  top: 0;
  bottom: 0;
}
.cs-grid-test .ngx-datatable .datatable-header .datatable-header-cell .targetMarker.dragFromLeft {
  right: 0;
}
.cs-grid-test .ngx-datatable .datatable-header .datatable-header-cell .targetMarker.dragFromRight {
  left: 0;
}
.cs-grid-test .ngx-datatable .datatable-header .datatable-header-cell .datatable-header-cell-template-wrap {
  height: inherit;
}
.cs-grid-test .ngx-datatable .datatable-body {
  position: relative;
  z-index: 10;
  display: block;
}
.cs-grid-test .ngx-datatable .datatable-body .datatable-scroll {
  display: inline-block;
}
.cs-grid-test .ngx-datatable .datatable-body .datatable-row-detail {
  overflow-y: hidden;
}
.cs-grid-test .ngx-datatable .datatable-body .datatable-row-wrapper {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.cs-grid-test .ngx-datatable .datatable-body .datatable-body-row {
  outline: none;
}
.cs-grid-test .ngx-datatable .datatable-body .datatable-body-row > div {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.cs-grid-test .ngx-datatable .datatable-footer {
  display: block;
  width: 100%;
  overflow: auto;
}
.cs-grid-test .ngx-datatable .datatable-footer .datatable-footer-inner {
  display: flex;
  align-items: center;
  width: 100%;
}
.cs-grid-test .ngx-datatable .datatable-footer .selected-count .page-count {
  flex: 1 1 40%;
}
.cs-grid-test .ngx-datatable .datatable-footer .selected-count .datatable-pager {
  flex: 1 1 60%;
}
.cs-grid-test .ngx-datatable .datatable-footer .page-count {
  flex: 1 1 20%;
}
.cs-grid-test .ngx-datatable .datatable-footer .datatable-pager {
  flex: 1 1 80%;
  text-align: right;
}
.cs-grid-test .ngx-datatable .datatable-footer .datatable-pager .pager,
.cs-grid-test .ngx-datatable .datatable-footer .datatable-pager .pager li {
  padding: 0;
  margin: 0;
  display: inline-block;
  list-style: none;
}
.cs-grid-test .ngx-datatable .datatable-footer .datatable-pager .pager li,
.cs-grid-test .ngx-datatable .datatable-footer .datatable-pager .pager li a {
  outline: none;
}
.cs-grid-test .ngx-datatable .datatable-footer .datatable-pager .pager li a {
  cursor: pointer;
  display: inline-block;
}
.cs-grid-test .ngx-datatable .datatable-footer .datatable-pager .pager li.disabled a {
  cursor: not-allowed;
}
.cs-grid-test .ngx-datatable,
.cs-grid-test .info {
  text-align: left;
  width: 75%;
  margin: 0 auto;
}
.cs-grid-test .info {
  box-sizing: border-box;
  padding: 0 1em;
  border: solid 1px #ccc;
  font-size: 0.8em;
  margin-bottom: 1em;
}
.cs-grid-test .ngx-datatable.scroll-vertical {
  height: 70vh;
}
.cs-grid-test .group {
  position: relative;
  margin-bottom: 45px;
}
.cs-grid-test .fullscreen {
  position: absolute !important;
  height: auto !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
}
.cs-grid-test .selected-column {
  float: right;
  width: 20%;
  margin-right: 20px;
  text-align: left;
}
.cs-grid-test .selected-column h4 {
  text-align: center;
  margin: 10px 0 0 0;
  padding: 0;
}
.cs-grid-test ul,
.cs-grid-test li {
  padding: 0;
  margin: 0;
  list-style: none;
}
.cs-grid-test ul {
  margin: 10px;
}
.cs-grid-test li {
  padding: 3px 5px;
}
.cs-grid-test nav {
  z-index: 99;
  top: 0;
  left: 0;
  bottom: 0;
  width: 200px;
  position: fixed;
  overflow-y: auto;
  font-size: 14px;
}
.cs-grid-test nav ul {
  margin: 0 8px !important;
}
.cs-grid-test nav li {
  padding: 0;
}
.cs-grid-test nav h3 {
  font-size: 14px;
  margin: 0 0 10px 0;
}
.cs-grid-test nav h4 {
  margin: 7px 0 0 0;
}
.cs-grid-test nav h4 a {
  color: #000;
}
.cs-grid-test .main-ul {
  margin-left: 15px !important;
  display: block;
}
.cs-grid-test [data-icon]:before {
  font-family: "FontAwesome" !important;
  content: "";
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.cs-grid-test [class^=datatable-icon-]:before,
.cs-grid-test [class*=" datatable-icon-"]:before {
  font-family: "FontAwesome" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.cs-grid-test .datatable-icon-filter:before {
  content: "\f0b0";
}
.cs-grid-test .datatable-icon-collapse:before {
  content: "\f147";
}
.cs-grid-test .datatable-icon-expand:before {
  content: "\f196";
}
.cs-grid-test .datatable-icon-close:before {
  content: "\f2d3";
}
.cs-grid-test .datatable-icon-up:before {
  content: "\f062";
}
.cs-grid-test .datatable-icon-down:before {
  content: "\f063";
}
.cs-grid-test .datatable-icon-sort:before {
  content: "\f0dc";
}
.cs-grid-test .datatable-icon-done:before {
  content: "\f00c";
}
.cs-grid-test .datatable-icon-done-all:before {
  content: "\f05d";
}
.cs-grid-test .datatable-icon-search:before {
  content: "\f002";
}
.cs-grid-test .datatable-icon-pin:before {
  content: "\f276";
}
.cs-grid-test .datatable-icon-add:before {
  content: "\f196";
}
.cs-grid-test .datatable-icon-left:before {
  content: "\f104";
}
.cs-grid-test .datatable-icon-right:before {
  content: "\f105";
}
.cs-grid-test .datatable-icon-skip:before {
  content: "\f101";
}
.cs-grid-test .datatable-icon-prev:before {
  content: "\f100";
}
.cs-grid-test .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover, .cs-grid-test .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  transition-property: background;
  transition-duration: 0.3s;
  transition-timing-function: linear;
}
.cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell:hover, .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell:hover .datatable-row-group {
  transition-property: background;
  transition-duration: 0.3s;
  transition-timing-function: linear;
}
.cs-grid-test .ngx-datatable.material .empty-row {
  height: 50px;
  text-align: left;
  padding: 0.5rem 1.2rem;
  vertical-align: top;
  border-top: 0;
}
.cs-grid-test .ngx-datatable.material .loading-row {
  text-align: left;
  padding: 0.5rem 1.2rem;
  vertical-align: top;
  border-top: 0;
}
.cs-grid-test .ngx-datatable.material .datatable-header .datatable-row-left,
.cs-grid-test .ngx-datatable.material .datatable-body .datatable-row-left {
  background-position: 100% 0;
  background-repeat: repeat-y;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAABCAYAAAD5PA/NAAAAFklEQVQIHWPSkNeSBmJhTQVtbiDNCgASagIIuJX8OgAAAABJRU5ErkJggg==);
}
.cs-grid-test .ngx-datatable.material .datatable-header .datatable-row-right,
.cs-grid-test .ngx-datatable.material .datatable-body .datatable-row-right {
  background-position: 0 0;
  background-repeat: repeat-y;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAABCAYAAAD5PA/NAAAAFklEQVQI12PQkNdi1VTQ5gbSwkAsDQARLAIGtOSFUAAAAABJRU5ErkJggg==);
}
.cs-grid-test .ngx-datatable.material .datatable-header .datatable-header-cell {
  text-align: left;
  padding: 0.9rem 1.2rem;
  font-weight: 400;
  vertical-align: bottom;
  font-size: 12px;
  font-weight: 500;
}
.cs-grid-test .ngx-datatable.material .datatable-header .datatable-header-cell .datatable-header-cell-wrapper {
  position: relative;
}
.cs-grid-test .ngx-datatable.material .datatable-header .datatable-header-cell.longpress .draggable::after {
  transition: transform 400ms ease, opacity 400ms ease;
  opacity: 0.5;
  transform: scale(1);
}
.cs-grid-test .ngx-datatable.material .datatable-header .datatable-header-cell .draggable::after {
  content: " ";
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -30px 0 0 -30px;
  height: 60px;
  width: 60px;
  background: #eee;
  border-radius: 100%;
  opacity: 1;
  filter: none;
  transform: scale(0);
  z-index: 9999;
  pointer-events: none;
}
.cs-grid-test .ngx-datatable.material .datatable-header .datatable-header-cell.dragging .resize-handle {
  border-right: none;
}
.cs-grid-test .ngx-datatable.material .datatable-body .datatable-row-detail {
  padding: 10px;
}
.cs-grid-test .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
  text-align: left;
  padding: 0.9rem 1.2rem;
  vertical-align: top;
  border-top: 0;
  transition: width 0.3s ease;
  font-size: 14px;
  font-weight: 400;
}
.cs-grid-test .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-group-cell {
  text-align: left;
  padding: 0.9rem 1.2rem;
  vertical-align: top;
  border-top: 0;
  transition: width 0.3s ease;
  font-size: 14px;
  font-weight: 400;
}
.cs-grid-test .ngx-datatable.material .datatable-body .progress-linear {
  display: block;
  position: relative;
  width: 100%;
  height: 5px;
  padding: 0;
  margin: 0;
  position: absolute;
}
.cs-grid-test .ngx-datatable.material .datatable-body .progress-linear .container {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 5px;
  -webkit-transform: translate(0, 0) scale(1, 1);
  transform: translate(0, 0) scale(1, 1);
}
.cs-grid-test .ngx-datatable.material .datatable-body .progress-linear .container .bar {
  transition: all 0.2s linear;
  -webkit-animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
  animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
  transition: -webkit-transform 0.2s linear;
  transition: transform 0.2s linear;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 5px;
}
.cs-grid-test .ngx-datatable.material .datatable-footer {
  font-size: 12px;
  font-weight: 400;
  padding: 0 32px;
}
.cs-grid-test .ngx-datatable.material .datatable-footer .page-count {
  line-height: 50px;
  height: 50px;
  padding: 0 1.2rem;
}
.cs-grid-test .ngx-datatable.material .datatable-footer .datatable-pager {
  margin: 0 10px;
}
.cs-grid-test .ngx-datatable.material .datatable-footer .datatable-pager li {
  vertical-align: middle;
}
.cs-grid-test .ngx-datatable.material .datatable-footer .datatable-pager li.active a {
  font-weight: bold;
}
.cs-grid-test .ngx-datatable.material .datatable-footer .datatable-pager a {
  height: 22px;
  min-width: 24px;
  line-height: 22px;
  padding: 0 6px;
  border-radius: 3px;
  margin: 6px 3px;
  text-align: center;
  vertical-align: top;
  text-decoration: none;
  vertical-align: bottom;
}
.cs-grid-test .ngx-datatable.material .datatable-footer .datatable-pager .datatable-icon-left,
.cs-grid-test .ngx-datatable.material .datatable-footer .datatable-pager .datatable-icon-skip,
.cs-grid-test .ngx-datatable.material .datatable-footer .datatable-pager .datatable-icon-right,
.cs-grid-test .ngx-datatable.material .datatable-footer .datatable-pager .datatable-icon-prev {
  font-size: 20px;
  line-height: 20px;
  padding: 0 3px;
}
.cs-grid-test .ngx-datatable.material .datatable-summary-row .datatable-body-row .datatable-body-cell {
  font-weight: bold;
}
.cs-grid-test .datatable-checkbox {
  position: relative;
  margin: 0;
  cursor: pointer;
  vertical-align: middle;
  display: inline-block;
  box-sizing: border-box;
  padding: 0;
}
.cs-grid-test .datatable-checkbox input[type=checkbox] {
  position: relative;
  margin: 0 1rem 0 0;
  cursor: pointer;
  outline: none;
}
.cs-grid-test .datatable-checkbox input[type=checkbox]:before {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  content: "";
  position: absolute;
  left: 0;
  z-index: 1;
  width: 1rem;
  height: 1rem;
  border: 2px solid #f2f2f2;
}
.cs-grid-test .datatable-checkbox input[type=checkbox]:checked:before {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  height: 0.5rem;
  border-color: #009688;
  border-top-style: none;
  border-right-style: none;
}
.cs-grid-test .datatable-checkbox input[type=checkbox]:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 1rem;
  height: 1rem;
  cursor: pointer;
}

app-file-document-detail {
  display: block;
  height: 100%;
}

.upload-warning {
  height: 100% !important;
}

.view-toolbar {
  padding-left: 8px;
}
.view-toolbar + .view-content {
  display: block;
  height: calc(100% - 65px);
}
.view-toolbar mat-toolbar-row {
  display: grid;
  grid-template-columns: minmax(0px, 1fr) auto;
  grid-template-areas: "content sidebar ";
}
.view-toolbar mat-toolbar-row .view-description {
  padding-right: 16px;
  grid-area: content;
  display: flex;
  align-items: center;
}
.view-toolbar mat-toolbar-row .view-description mat-icon.mat-icon {
  margin-right: 16px;
}
.view-toolbar mat-toolbar-row .view-description .view-description-text {
  width: calc(100% - 56px);
}
.view-toolbar mat-toolbar-row .view-description .view-description-text h3 {
  font-size: 20px;
  line-height: 1.25;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.view-toolbar mat-toolbar-row .view-description .view-description-text h5 {
  font-size: 12px;
  line-height: 1.25;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.view-toolbar mat-toolbar-row .view-actions {
  grid-area: sidebar;
}
.view-toolbar mat-toolbar-row .view-actions .divider-vert {
  margin: 0 16px;
}

.view-container {
  display: block;
  height: 100%;
}
.view-container .view-toolbar {
  padding-left: 8px;
}
.view-container .view-toolbar + .view-content {
  display: block;
  height: calc(100% - 65px);
}
.view-container .view-toolbar mat-toolbar-row {
  display: grid;
  grid-template-columns: minmax(0px, 1fr) auto;
  grid-template-areas: "content sidebar ";
}
.view-container .view-toolbar mat-toolbar-row .view-description {
  padding-right: 16px;
  grid-area: content;
  display: flex;
  align-items: center;
}
.view-container .view-toolbar mat-toolbar-row .view-description mat-icon.mat-icon {
  margin-right: 16px;
}
.view-container .view-toolbar mat-toolbar-row .view-description .view-description-text {
  width: calc(100% - 56px);
}
.view-container .view-toolbar mat-toolbar-row .view-description .view-description-text h3 {
  font-size: 20px;
  line-height: 1.25;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.view-container .view-toolbar mat-toolbar-row .view-description .view-description-text h5 {
  font-size: 12px;
  line-height: 1.25;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.view-container .view-toolbar mat-toolbar-row .view-actions {
  grid-area: sidebar;
}
.view-container .view-toolbar mat-toolbar-row .view-actions .divider-vert {
  margin: 0 16px;
}
.view-container .view-content {
  display: block;
  height: 100%;
}
.view-container .view-content .toggle-buttons-container {
  height: 64px;
  padding: 0 8px;
}

app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal {
  width: 100%;
  max-height: 289px;
  overflow: hidden;
  margin: 0;
}
app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal.new {
  max-height: unset;
}
app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal.new.image-opened .help-header,
app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal.new.image-opened .mat-card-footer,
app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal.new.image-opened .aditional-info {
  display: none !important;
}
app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal.new.image-opened .help-content {
  width: unset !important;
  padding: 16px !important;
}
app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal.new.image-opened .help-content .description {
  text-align: center;
}
app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal.new.image-opened .help-content img {
  height: 70vh !important;
  cursor: zoom-out !important;
  object-fit: contain !important;
  object-position: center !important;
}
app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container {
  padding: 0;
}
app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card {
  box-shadow: none;
  padding: 0;
  border-radius: 0;
  height: 100%;
  width: 100%;
  display: block !important;
}
app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card .mat-card-header {
  display: none;
}
app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card .mat-card-content {
  margin-bottom: 0;
  display: block !important;
  flex: 1 1 100%;
  max-height: 100%;
}
app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card .mat-card-content .help-header {
  height: 64px;
  padding: 0 16px;
}
app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card .mat-card-content .help-header h3 {
  font-size: 20px;
}
app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card .mat-card-content .help-header h3,
app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card .mat-card-content .help-header h4 {
  margin: 0;
}
app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card .mat-card-content .help-content {
  height: calc(100% - 56px);
  padding: 0 72px 16px;
  justify-content: center;
  width: 33.3333333333vw;
  min-width: 720px;
}
app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card .mat-card-content .help-content img {
  height: 200px;
  object-fit: cover;
  cursor: zoom-in;
  object-position: top;
}
app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card .mat-card-content .help-content h4 {
  margin-top: 0 !important;
}
app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card .mat-card-content .help-content ul {
  margin: 0;
  display: flex;
  flex-direction: column;
  padding-inline-start: 13px;
}
app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card .mat-card-content .help-content ul li {
  font-weight: 400;
}
app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card .mat-card-content .help-content ul li + li {
  margin-top: 3%;
}
app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card .mat-card-content .help-content ul li b.tip-text {
  margin-left: -12px;
  margin-top: 16px;
  display: block;
  font-weight: 400;
}
app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container .mat-card-footer {
  min-height: 56px;
  padding: 0 16px 16px;
  margin: 0;
}

app-help-index mat-horizontal-stepper.help-stepper.mat-stepper-horizontal {
  width: 100%;
  height: auto;
  overflow: hidden;
}
app-help-index mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container {
  padding: 0;
}
app-help-index mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card {
  box-shadow: none;
  padding: 0;
  border-radius: 0;
  height: 100%;
  width: 100%;
}
app-help-index mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card .mat-card-header {
  display: none;
}
app-help-index mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card .mat-card-content {
  margin-bottom: 0;
  display: block !important;
  flex: 1 1 100%;
  max-height: 100%;
}
app-help-index mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card .mat-card-content .help-header {
  height: 64px;
  padding: 0 16px;
}
app-help-index mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card .mat-card-content .help-header h3 {
  font-size: 20px;
}
app-help-index mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card .mat-card-content .help-header h3,
app-help-index mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card .mat-card-content .help-header h4 {
  margin: 0;
}
app-help-index mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card .mat-card-content .help-content {
  height: calc(100% - 56px);
  padding: 0 72px 16px;
  justify-content: center;
  width: 33.3333333333vw;
  min-width: 720px;
}
app-help-index mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card .mat-card-content .help-content h4 {
  margin-top: 0 !important;
}
app-help-index mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card .mat-card-content .help-content ul {
  margin-top: 0;
  display: flex;
  flex-direction: column;
  padding-inline-start: 13px;
}
app-help-index mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card .mat-card-content .help-content ul li {
  font-weight: 400;
}
app-help-index mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card .mat-card-content .help-content ul li + li {
  margin-top: 3%;
}
app-help-index mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card .mat-card-content .help-content ul li b.tip-text {
  margin-left: -13px;
  margin-top: 3%;
}
app-help-index mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card .mat-card-content .help-content .husigner-icon {
  height: 128px;
}
app-help-index mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card .mat-card-content mat-action-list.mat-list.mat-list-base.mat-action-list {
  margin-bottom: 16px;
}
app-help-index mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card .mat-card-content mat-action-list.mat-list.mat-list-base.mat-action-list button.option-btn.mat-list-item {
  padding: 0px 56px;
}
app-help-index mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card .mat-card-footer {
  min-height: 56px;
  padding: 0 16px 16px;
  margin: 0;
}

.cropper .employee-title {
  margin-top: 0 !important;
  padding: 0 16px;
}
.cropper app-cs-upload-cropper-control {
  height: calc(100% - 65px);
  margin-top: 1px;
}
.cropper app-cs-upload-cropper-control .preview {
  padding: 16px;
}
.cropper app-cs-upload-cropper-control .preview img {
  width: 50%;
  border-radius: 50%;
  box-sizing: border-box;
  margin: 0 auto;
}
.cropper app-cs-upload-cropper-control .preview h5 {
  margin-bottom: 0 !important;
}
.cropper app-cs-upload-cropper-control .preview .actions {
  padding: 16px;
  width: 100%;
}
.cropper app-cs-upload-cropper-control .cropper-area {
  padding: 16px;
}
.cropper app-cs-upload-cropper-control .cropper-area .actions {
  padding: 16px;
}

app-file-document-upload {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  display: flex;
  flex: 1;
  height: 100%;
}
app-file-document-upload .preview-container {
  padding: 32px;
  overflow: auto;
  overflow-y: overlay;
  height: 100%;
  display: flex;
  box-sizing: border-box;
  width: 100%;
}
app-file-document-upload .preview-container h4 {
  height: 100%;
  margin: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20%;
  font-weight: 400;
}
app-file-document-upload .preview-container img {
  width: 100%;
  object-fit: contain;
}
app-file-document-upload app-upload-form {
  height: calc(100% - 32px);
  width: 100%;
  margin: 16px;
}
app-file-document-upload .dropzone {
  margin: 0;
  width: 100% !important;
  box-sizing: border-box;
}
app-file-document-upload.not-visible {
  height: 0 !important;
  padding: 0 !important;
}
app-file-document-upload + app-pdf-wrapper {
  height: 0;
  flex: unset;
}
app-file-document-upload.not-visible + app-pdf-wrapper {
  height: 100%;
  flex: unset;
}

.preview-container {
  padding: 32px;
  overflow: auto;
  overflow-y: overlay;
  height: 100%;
  display: flex;
  box-sizing: border-box;
}
.preview-container h4 {
  height: 100%;
  margin: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20%;
  font-weight: 400;
}
.preview-container img {
  width: 100%;
  object-fit: contain;
}
.preview-container .preview-container {
  padding: 0;
  overflow: unset;
}

.mat-form-field-wrapper .clean-autocomple .mat-form-field {
  width: 80% !important;
}

.clean-autocomple {
  margin-bottom: 24px;
}
.clean-autocomple .mat-form-field-wrapper {
  padding-bottom: 0;
}
.clean-autocomple mat-list {
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
  border-top: unset !important;
  box-sizing: border-box;
}
.clean-autocomple mat-list .mat-list-item {
  height: 48px !important;
  max-height: 48px !important;
}
.clean-autocomple mat-list .mat-list-item:hover .mat-list-item-content button {
  opacity: 1;
  pointer-events: all;
}
.clean-autocomple mat-list .mat-list-item .mat-list-item-content {
  padding: 0 8px !important;
}
.clean-autocomple mat-list .mat-list-item .mat-list-item-content button {
  width: 40px;
  height: 40px;
  opacity: 0;
  pointer-events: none;
}

app-lsd .main-overlay {
  position: absolute;
  bottom: 0;
  height: 100%;
  opacity: 0;
  z-index: 2;
}
app-lsd .main-overlay.enabled {
  width: 100% !important;
  opacity: 1;
}
app-lsd .icon-complex {
  position: relative;
  height: 40px;
  width: 40px;
  padding: 0 !important;
}
app-lsd .icon-complex mat-icon.complex {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 16px !important;
  height: 20px !important;
  width: 20px !important;
  padding: 0 !important;
}
app-lsd .work-space h5,
app-lsd .work-space h6 {
  font-weight: 500;
}
app-lsd .work-space.full-page .main .container .content-scroll .item {
  width: calc(25% - 16px);
}
app-lsd .work-space .main {
  position: relative;
}
app-lsd .work-space .main .toolbar {
  display: flex !important;
  justify-content: space-between;
}
app-lsd .work-space .main .toolbar .search-container {
  flex: auto;
  max-width: 50%;
}
app-lsd .work-space .main .load-window {
  left: 0;
  top: 0;
  height: 100%;
}
app-lsd .work-space .main.side-open .container .content-scroll .item {
  width: calc(33.3333333333% - 16px);
}
app-lsd .work-space .main .container {
  min-height: calc(100vh - 32px - 192px - 97px);
  flex-grow: 1;
  flex-shrink: 1;
}
app-lsd .work-space .main .container .helper {
  width: calc(100% - 32px);
}
app-lsd .work-space .main .container .helper mat-hint {
  width: 100%;
}
app-lsd .work-space .main .container .helper .actions {
  margin: 0 !important;
}
app-lsd .work-space .main .container .helper .actions button.mat-stroked-button {
  padding: 0 8px 0 16px;
}
app-lsd .work-space .main .container .helper .actions button.mat-stroked-button h4 {
  margin: 0 !important;
  line-height: 1.1 !important;
}
app-lsd .work-space .main .container .helper .actions button.mat-stroked-button h6 {
  margin: 0 !important;
  line-height: 1.1 !important;
  color: rgba(0, 0, 0, 0.87) !important;
  font-weight: 400 !important;
}
app-lsd .work-space .main .container .content-scroll {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: scroll;
  max-height: calc(100% - 56px);
  box-sizing: border-box;
  padding: 8px 0 8px 8px;
}
app-lsd .work-space .main .container .item {
  margin-top: 16px;
  padding: 16px;
  border-radius: 8px;
  width: calc(33.3333333333% - 16px);
  margin: 8px;
  cursor: pointer;
  overflow: hidden;
}
app-lsd .work-space .main .container .item .info {
  padding: 0 0 16px 16px;
}
app-lsd .work-space .main .container .item .info h2 {
  font-weight: 400;
  margin: 0;
}
app-lsd .work-space .main .container .item .info h3 {
  margin: 8px 0 0;
}
app-lsd .work-space .main .container .item .info h4 {
  margin: 4px 0 0;
  font-weight: 400;
}
app-lsd .work-space .main .container .item .info mat-icon {
  height: 40px !important;
  position: relative;
}
app-lsd .work-space .main .container .item .info mat-icon span {
  width: 100%;
  height: 100%;
  position: absolute;
}
app-lsd .work-space .main .container .item .status {
  padding: 16px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  margin: 0 -16px -16px;
}
app-lsd .work-space .main .container .item .status h4 {
  margin: 0 !important;
  font-weight: 400;
}
app-lsd .work-space .main .container .item .status .mat-icon {
  min-height: 40px;
}
app-lsd .work-space .main .container .item.pending .status {
  background: #FFECB3;
}
app-lsd .work-space .main .container .item.ok .status {
  background: #C8E6C9;
}
app-lsd .work-space .main .container .item.not-ok .status {
  background: #EF9A9A;
}
app-lsd .work-space .main .container .item.selected {
  cursor: default;
}
app-lsd .work-space .side {
  height: 100%;
}
app-lsd .work-space .side .edition-line {
  border-radius: 8px;
  padding: 16px;
  margin: 16px 0;
  position: relative;
  overflow: hidden;
}
app-lsd .work-space .side .edition-line mat-progress-bar {
  bottom: 0;
  left: 0;
}
app-lsd .work-space .side mat-form-field {
  width: 100%;
}
app-lsd .work-space .side mat-form-field.mat-form-field-disabled * {
  cursor: not-allowed;
}
app-lsd .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-underline {
  height: 2px !important;
}
app-lsd .work-space .side mat-form-field .mat-form-field-flex {
  border-radius: 4px 4px 0 0 !important;
  padding: 8px 8px 0 !important;
  min-height: 56px !important;
  align-items: unset !important;
}
app-lsd .work-space .side mat-form-field .mat-form-field-flex .mat-form-field-prefix {
  padding: 0 !important;
  display: flex;
  align-items: center;
}
app-lsd .work-space .side mat-checkbox {
  align-items: center;
  height: 56px;
  display: flex;
  margin-bottom: 16px;
  border-radius: 4px;
  padding: 0 8px;
}
app-lsd .work-space .side mat-checkbox .mat-checkbox-layout {
  width: 100%;
  justify-content: flex-start;
  height: 56px;
  align-items: center;
}
app-lsd .work-space .side mat-checkbox .mat-checkbox-layout .mat-checkbox-inner-container {
  margin: 12px;
}
app-lsd .work-space .side mat-checkbox .mat-checkbox-layout .mat-checkbox-label {
  width: 100%;
  padding-left: 16px;
}
app-lsd .work-space .side .hint {
  margin: 0 0 16px;
  font-weight: 700;
  width: 100%;
  text-align: center;
}
app-lsd .work-space .side .toolbar {
  height: 112px;
  padding: 16px 32px;
}
app-lsd .work-space .side .toolbar .header {
  width: 100%;
}
app-lsd .work-space .side .toolbar .header h3 {
  margin: 0;
}
app-lsd .work-space .side .toolbar .header h5 {
  margin: 0;
}
app-lsd .work-space .side .container {
  padding: 0 0 16px 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: scroll;
  max-height: calc(100% - 112px);
  box-sizing: border-box;
}
app-lsd .work-space .side .container .doc-resume {
  width: calc(100% - 16px);
  padding: 16px;
  border-radius: 8px;
  margin: 0 8px;
  position: relative;
}
app-lsd .work-space .side .container .doc-resume .line {
  padding: 8px 16px;
  position: relative;
  border-radius: 4px;
  min-height: 56px;
}
app-lsd .work-space .side .container .doc-resume .line.editable {
  pointer-events: none;
}
app-lsd .work-space .side .container .doc-resume .line.editable:hover button {
  opacity: 1;
}
app-lsd .work-space .side .container .doc-resume .line.editable button {
  opacity: 0;
  position: absolute;
  right: 16px;
  top: 4px;
}
app-lsd .work-space .side .container .doc-resume .line .header {
  width: 100%;
}
app-lsd .work-space .side .container .doc-resume .line .header .info {
  max-width: calc(100% - 40px - 16px - 16px);
}
app-lsd .work-space .side .container .doc-resume .line .header .info h4,
app-lsd .work-space .side .container .doc-resume .line .header .info h6 {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
app-lsd .work-space .side .container .section-title {
  width: 100%;
  height: 56px;
  margin: 8px;
  padding: 16px;
}
app-lsd .work-space .side .container .section-title h3 {
  margin: 0;
}
app-lsd .work-space .side .container .section-title h5 {
  margin: 0;
  font-size: 18px;
  font-weight: 700;
}
app-lsd .work-space .side .container .section-resume {
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  margin: 0 8px;
  max-width: calc(100% - 50px);
}
app-lsd .work-space .side .container .section-resume + .section-resume {
  margin-top: 16px !important;
}
app-lsd .work-space .side .container .section-resume .mat-subheader {
  min-height: 72px;
  padding: 16px 16px 0;
}
app-lsd .work-space .side .container .section-resume .mat-subheader + .mat-list-item {
  margin-top: 16px;
}
app-lsd .work-space .side .container .section-resume .mat-stroked-button.sign-docs {
  width: 100%;
  margin-top: 16px !important;
}
app-lsd .work-space .side .container .section-resume .mat-stroked-button.sign-docs .mat-button-wrapper {
  display: flex !important;
  width: 100%;
  align-items: center;
  justify-content: center;
}
app-lsd .work-space .side .container .section-resume .mat-stroked-button.sign-docs .mat-button-wrapper .mat-icon {
  margin-right: 8px;
}

app-create-lsd .load-window {
  position: relative;
  top: 0;
  padding: 20vh;
  box-sizing: border-box;
}

.lsd-adv-search .list-align {
  padding: 0;
}
.lsd-adv-search .list-align > div > div {
  flex-wrap: wrap;
}
.lsd-adv-search .list-align > div > div > mat-form-field {
  margin: 0 !important;
}
.lsd-adv-search .mat-form-field-suffix {
  align-items: center;
  display: flex;
}

.dialog-content {
  max-height: 90vh;
  position: relative;
}
.dialog-content mat-toolbar {
  padding: 0;
}
.dialog-content .dialog-container .document-template-container {
  max-width: 420px;
  width: 100%;
  display: flex;
  justify-content: center;
  max-height: calc(90vh - 64px - 64px);
}
.dialog-content .dialog-container .document-template-container .document-selection {
  width: 100%;
  box-sizing: border-box;
  height: 100%;
}
.dialog-content .dialog-container .document-template-container .document-selection.new {
  padding: 16px;
  overflow-y: auto;
}
.dialog-content .dialog-container .document-template-container .document-selection.new .asisted-input.big {
  margin-bottom: 16px;
}
.dialog-content .dialog-container .document-template-container .document-selection.new .asisted-input.big mat-tab-group {
  width: 100%;
  max-width: calc(100% - 56px);
}
.dialog-content .dialog-container .document-template-container .document-selection.new .asisted-input.big mat-tab-group .dropzone {
  margin: 0 !important;
}
.dialog-content .dialog-container .document-template-container .document-selection.new .asisted-input.big mat-tab-group .dropzone + mat-list {
  padding: 0;
  min-width: unset;
  max-height: unset;
}
.dialog-content .dialog-container .document-template-container .document-selection mat-form-field {
  width: 100%;
}
.dialog-content .dialog-container .document-template-container .document-selection mat-form-field.mat-form-field-disabled * {
  cursor: not-allowed;
}
.dialog-content .dialog-container .document-template-container .document-selection mat-form-field.mat-form-field-disabled .mat-form-field-underline {
  height: 2px !important;
}
.dialog-content .dialog-container .document-template-container .document-selection mat-form-field .mat-form-field-flex {
  border-radius: 4px 4px 0 0 !important;
  padding: 8px 8px 0 !important;
  min-height: 56px !important;
  align-items: unset !important;
}
.dialog-content .dialog-container .document-template-container .document-selection mat-form-field .mat-form-field-flex .mat-form-field-prefix {
  padding: 0 !important;
  display: flex;
  align-items: center;
}
.dialog-content .dialog-container .document-template-container .document-selection app-upload-form {
  height: 100%;
}
.dialog-content .dialog-container .document-template-container .document-selection app-upload-form .dropzone {
  margin: 16px !important;
}
.dialog-content .dialog-container .document-template-container .document-selection app-upload-form .dropzone + mat-list {
  margin: 0;
  max-height: calc(100% - 160px - 32px);
  overflow-y: auto;
  padding: 16px;
  width: 100%;
}
.dialog-content .dialog-container .document-template-container .document-selection app-upload-form .file-upload-list {
  max-width: unset;
}
.dialog-content .dialog-container .document-template-container .document-selection app-upload-form .file-upload-list .mat-subheader {
  margin-bottom: 16px !important;
  position: relative;
}
.dialog-content .dialog-container .document-template-container .document-selection app-upload-form .file-upload-list .mat-subheader::before {
  content: "(click para vista previa)";
  position: absolute;
  bottom: 0;
  font-size: 12px;
  font-weight: 300;
}
.dialog-content .dialog-container .document-template-container .document-selection app-upload-form .file-upload-list mat-list-item {
  cursor: pointer;
}
.dialog-content .dialog-container .view-container {
  height: calc(90vh - 64px - 64px);
  overflow-y: auto;
  max-width: 70vw;
  box-sizing: border-box;
}
.dialog-content .dialog-container .view-container app-pdf-wrapper-controls {
  position: absolute;
  top: 12px;
  right: 72px;
}
.dialog-content .dialog-container .view-container .load-window.new {
  width: 25vw;
  top: 0;
  height: 25vw;
  position: relative;
}
.dialog-content .form-actions {
  padding: 16px;
  max-height: 64px;
}

.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1,
.mat-headline,
.mat-typography .mat-h1,
.mat-typography .mat-headline,
.mat-typography h1 {
  font: 400 24px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2,
.mat-title,
.mat-typography .mat-h2,
.mat-typography .mat-title,
.mat-typography h2 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subheading-2,
.mat-typography .mat-h3,
.mat-typography .mat-subheading-2,
.mat-typography h3 {
  font: 400 16px / 28px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4,
.mat-subheading-1,
.mat-typography .mat-h4,
.mat-typography .mat-subheading-1,
.mat-typography h4 {
  font: 400 15px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-body-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-body-2 {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-body,
.mat-body-1,
.mat-typography .mat-body,
.mat-typography .mat-body-1,
.mat-typography {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-body p,
.mat-body-1 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-1 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font: 400 12px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-display-4,
.mat-typography .mat-display-4 {
  font: 300 112px / 112px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}

.mat-display-3,
.mat-typography .mat-display-3 {
  font: 400 56px / 56px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}

.mat-display-2,
.mat-typography .mat-display-2 {
  font: 400 45px / 48px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}

.mat-display-1,
.mat-typography .mat-display-1 {
  font: 400 34px / 40px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-button-toggle {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 14px;
}

.mat-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-dialog-title {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.mat-expansion-panel-content {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
}

.mat-radio-button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-slide-toggle-content {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tab-label, .mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0;
}

.mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-list-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
}

.mat-optgroup-label {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #3f51b5;
}

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #e91e63;
}

.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f44336;
}

.mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #3f51b5;
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #e91e63;
}

.mat-warn .mat-pseudo-checkbox-checked,
.mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #f44336;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}

.mat-badge {
  position: relative;
}

.mat-badge.mat-badge {
  overflow: visible;
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

.mat-badge-content {
  color: white;
  background: #3f51b5;
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-accent .mat-badge-content {
  background: #e91e63;
  color: white;
}

.mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336;
}

.mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}

.mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-button, .mat-icon-button, .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
  color: #3f51b5;
}
.mat-button.mat-accent, .mat-icon-button.mat-accent, .mat-stroked-button.mat-accent {
  color: #e91e63;
}
.mat-button.mat-warn, .mat-icon-button.mat-warn, .mat-stroked-button.mat-warn {
  color: #f44336;
}
.mat-button.mat-primary.mat-button-disabled, .mat-button.mat-accent.mat-button-disabled, .mat-button.mat-warn.mat-button-disabled, .mat-button.mat-button-disabled.mat-button-disabled, .mat-icon-button.mat-primary.mat-button-disabled, .mat-icon-button.mat-accent.mat-button-disabled, .mat-icon-button.mat-warn.mat-button-disabled, .mat-icon-button.mat-button-disabled.mat-button-disabled, .mat-stroked-button.mat-primary.mat-button-disabled, .mat-stroked-button.mat-accent.mat-button-disabled, .mat-stroked-button.mat-warn.mat-button-disabled, .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-button.mat-primary .mat-button-focus-overlay, .mat-icon-button.mat-primary .mat-button-focus-overlay, .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #3f51b5;
}
.mat-button.mat-accent .mat-button-focus-overlay, .mat-icon-button.mat-accent .mat-button-focus-overlay, .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #e91e63;
}
.mat-button.mat-warn .mat-button-focus-overlay, .mat-icon-button.mat-warn .mat-button-focus-overlay, .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #f44336;
}
.mat-button.mat-button-disabled .mat-button-focus-overlay, .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.mat-button .mat-ripple-element, .mat-icon-button .mat-ripple-element, .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}

.mat-button-focus-overlay {
  background: black;
}

.mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}

.mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  color: white;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  color: white;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  color: white;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  background-color: #3f51b5;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  background-color: #e91e63;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  background-color: #f44336;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-flat-button.mat-primary .mat-ripple-element, .mat-raised-button.mat-primary .mat-ripple-element, .mat-fab.mat-primary .mat-ripple-element, .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-accent .mat-ripple-element, .mat-raised-button.mat-accent .mat-ripple-element, .mat-fab.mat-accent .mat-ripple-element, .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-warn .mat-ripple-element, .mat-raised-button.mat-warn .mat-ripple-element, .mat-fab.mat-warn .mat-ripple-element, .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-stroked-button:not([class*=mat-elevation-z]), .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-fab:not([class*=mat-elevation-z]), .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}

.mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}

[dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}

.mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}

.mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}

.mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-checkmark {
  fill: #fafafa;
}

.mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}

.mat-checkbox-mixedmark {
  background-color: #fafafa;
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #3f51b5;
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #e91e63;
}
.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #f44336;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-checkbox .mat-ripple-element {
  background-color: black;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #3f51b5;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #e91e63;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #f44336;
}

.mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.mat-chip.mat-standard-chip::after {
  background: black;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #3f51b5;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #f44336;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #e91e63;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-table {
  background: white;
}

.mat-table thead, .mat-table tbody, .mat-table tfoot,
mat-header-row, mat-row, mat-footer-row,
[mat-header-row], [mat-row], [mat-footer-row],
.mat-table-sticky {
  background: inherit;
}

mat-row, mat-header-row, mat-footer-row,
th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}

.mat-cell, .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}

.mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}

.mat-calendar-table-header,
.mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}

.mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}

.mat-calendar-body-in-range::before {
  background: rgba(63, 81, 181, 0.2);
}

.mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}

.mat-calendar-body-comparison-bridge-start::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(63, 81, 181, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-comparison-bridge-end::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(63, 81, 181, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}

.mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}

.mat-calendar-body-selected {
  background-color: #3f51b5;
  color: white;
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(63, 81, 181, 0.4);
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}

.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(63, 81, 181, 0.3);
}

@media (hover: hover) {
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(63, 81, 181, 0.3);
  }
}
.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(233, 30, 99, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(233, 30, 99, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(233, 30, 99, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #e91e63;
  color: white;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(233, 30, 99, 0.4);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(233, 30, 99, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(233, 30, 99, 0.3);
  }
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(244, 67, 54, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(244, 67, 54, 0.3);
  }
}

.mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-datepicker-toggle-active {
  color: #3f51b5;
}
.mat-datepicker-toggle-active.mat-accent {
  color: #e91e63;
}
.mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}

.mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}

.mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}

.mat-expansion-panel-header {
  height: 48px;
}
.mat-expansion-panel-header.mat-expanded {
  height: 64px;
}

.mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}

.mat-hint {
  color: rgba(0, 0, 0, 0.6);
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #3f51b5;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #e91e63;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #f44336;
}

.mat-focused .mat-form-field-required-marker {
  color: #e91e63;
}

.mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #3f51b5;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #e91e63;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #f44336;
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #3f51b5;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #e91e63;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #f44336;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #f44336;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #f44336;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #f44336;
}

.mat-error {
  color: #f44336;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #3f51b5;
}
.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #e91e63;
}
.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #f44336;
}
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #f44336;
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}

.mat-icon.mat-primary {
  color: #3f51b5;
}
.mat-icon.mat-accent {
  color: #e91e63;
}
.mat-icon.mat-warn {
  color: #f44336;
}

.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-input-element {
  caret-color: #3f51b5;
}
.mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-form-field.mat-accent .mat-input-element {
  caret-color: #e91e63;
}

.mat-form-field.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: #f44336;
}

.mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #f44336;
}

.mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}

.mat-list-option:hover, .mat-list-option:focus,
.mat-nav-list .mat-list-item:hover,
.mat-nav-list .mat-list-item:focus,
.mat-action-list .mat-list-item:hover,
.mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}

.mat-list-single-selected-option, .mat-list-single-selected-option:hover, .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}

.mat-menu-panel {
  background: white;
}
.mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.mat-menu-item[disabled],
.mat-menu-item[disabled] .mat-menu-submenu-icon,
.mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}

.mat-menu-item .mat-icon-no-color,
.mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}

.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-paginator {
  background: white;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}

.mat-paginator-decrement,
.mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-paginator-first,
.mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-icon-button[disabled] .mat-paginator-decrement,
.mat-icon-button[disabled] .mat-paginator-increment,
.mat-icon-button[disabled] .mat-paginator-first,
.mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-paginator-container {
  min-height: 56px;
}

.mat-progress-bar-background {
  fill: #cbd0e9;
}

.mat-progress-bar-buffer {
  background-color: #cbd0e9;
}

.mat-progress-bar-fill::after {
  background-color: #3f51b5;
}

.mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #f6c3d4;
}
.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #f6c3d4;
}
.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #e91e63;
}

.mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f9ccc9;
}
.mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f9ccc9;
}
.mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #f44336;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #3f51b5;
}
.mat-progress-spinner.mat-accent circle, .mat-spinner.mat-accent circle {
  stroke: #e91e63;
}
.mat-progress-spinner.mat-warn circle, .mat-spinner.mat-warn circle {
  stroke: #f44336;
}

.mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #3f51b5;
}
.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #3f51b5;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e91e63;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #e91e63;
}
.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f44336;
}
.mat-radio-button.mat-warn .mat-radio-inner-circle,
.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #f44336;
}
.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button .mat-ripple-element {
  background-color: black;
}

.mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}

.mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}

.mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}

.mat-select-panel {
  background: white;
}
.mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #3f51b5;
}
.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #e91e63;
}
.mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #f44336;
}
.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #f44336;
}
.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}

.mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-drawer.mat-drawer-push {
  background-color: white;
}
.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}

[dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
[dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #e91e63;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(233, 30, 99, 0.54);
}
.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #e91e63;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #3f51b5;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(63, 81, 181, 0.54);
}
.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #3f51b5;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #f44336;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(244, 67, 54, 0.54);
}
.mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #f44336;
}
.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}

.mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}

.mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider.mat-primary .mat-slider-track-fill,
.mat-slider.mat-primary .mat-slider-thumb,
.mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #3f51b5;
}
.mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(63, 81, 181, 0.2);
}
.mat-slider.mat-accent .mat-slider-track-fill,
.mat-slider.mat-accent .mat-slider-thumb,
.mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #e91e63;
}
.mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(233, 30, 99, 0.2);
}
.mat-slider.mat-warn .mat-slider-track-fill,
.mat-slider.mat-warn .mat-slider-thumb,
.mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #f44336;
}
.mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(244, 67, 54, 0.2);
}

.mat-slider:hover .mat-slider-track-background,
.mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider.mat-slider-disabled .mat-slider-track-background,
.mat-slider.mat-slider-disabled .mat-slider-track-fill,
.mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}

.mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover:not([aria-disabled]), .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .mat-step-header:hover {
    background: none;
  }
}
.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #3f51b5;
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon-selected,
.mat-step-header.mat-accent .mat-step-icon-state-done,
.mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #e91e63;
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon-selected,
.mat-step-header.mat-warn .mat-step-icon-state-done,
.mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #f44336;
  color: white;
}
.mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336;
}
.mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336;
}

.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: white;
}

.mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header {
  height: 72px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 24px 24px;
}

.mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}

.mat-sort-header-arrow {
  color: #757575;
}

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.mat-tab-group-inverted-header .mat-tab-nav-bar,
.mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}

.mat-tab-label, .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.mat-tab-label.mat-tab-disabled, .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-tab-group[class*=mat-background-] > .mat-tab-header,
.mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}

.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(197, 202, 233, 0.3);
}
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #3f51b5;
}
.mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 187, 208, 0.3);
}
.mat-tab-group.mat-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #e91e63;
}
.mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.mat-tab-group.mat-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #f44336;
}
.mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(197, 202, 233, 0.3);
}
.mat-tab-group.mat-background-primary > .mat-tab-header, .mat-tab-group.mat-background-primary > .mat-tab-link-container, .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #3f51b5;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 187, 208, 0.3);
}
.mat-tab-group.mat-background-accent > .mat-tab-header, .mat-tab-group.mat-background-accent > .mat-tab-link-container, .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #e91e63;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.mat-tab-group.mat-background-warn > .mat-tab-header, .mat-tab-group.mat-background-warn > .mat-tab-link-container, .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #f44336;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}

.mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.mat-toolbar.mat-primary {
  background: #3f51b5;
  color: white;
}
.mat-toolbar.mat-accent {
  background: #e91e63;
  color: white;
}
.mat-toolbar.mat-warn {
  background: #f44336;
  color: white;
}
.mat-toolbar .mat-form-field-underline,
.mat-toolbar .mat-form-field-ripple,
.mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.mat-toolbar .mat-form-field-label,
.mat-toolbar .mat-focused .mat-form-field-label,
.mat-toolbar .mat-select-value,
.mat-toolbar .mat-select-arrow,
.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.mat-toolbar .mat-input-element {
  caret-color: currentColor;
}

.mat-toolbar-multiple-rows {
  min-height: 64px;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 64px;
}

@media (max-width: 599px) {
  .mat-toolbar-multiple-rows {
    min-height: 56px;
  }
  .mat-toolbar-row, .mat-toolbar-single-row {
    height: 56px;
  }
}
.mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}

.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}

.mat-tree-node {
  min-height: 48px;
}

.mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-simple-snackbar-action {
  color: #e91e63;
}

*::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}

*::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}

*::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}

.toolbar-line.container-ou {
  background: #3f51b5;
  color: white;
}
.toolbar-line.container-persona {
  background: #3f51b5 !important;
}
.toolbar-line.container-persona .persona-title {
  background: #3f51b5;
}
.toolbar-line.container-persona .invisible-container.persona-selector {
  background: #3f51b5 !important;
}

button.mat-icon-button:not([disabled]):hover {
  background: rgba(0, 0, 0, 0.04);
}

.side-bar {
  background: #eeeeee;
}

.icon-stacked {
  color: white !important;
}
.icon-stacked mat-icon + mat-icon {
  text-shadow: -1px 1px 0px #3f51b5, 1px -1px 0px #3f51b5;
}

.divider-vert {
  background: rgba(0, 0, 0, 0.12);
}

.avatar {
  background: #303f9f !important;
}

ngx-avatar .avatar-content {
  background: #303f9f !important;
}

.mat-loading-window {
  background: white;
}

.window-spinner {
  background: white;
}

.load-window {
  background: white;
}

@media (min-width: 600px) and (max-width: 1300px) {
  mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .load-window,
mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .load-window.new {
    background: white !important;
  }
  mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card {
    background: white !important;
  }
  mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .inbox-container mat-toolbar {
    background: #3f51b5;
    color: white;
  }
  mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .inbox-container mat-list-item.mat-list-item {
    border: 1px solid rgba(0, 0, 0, 0.12);
  }
  mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .detail {
    background: white !important;
  }
  mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .detail mat-toolbar {
    background: white !important;
  }
  mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .main.w-detail + .new-detail-container .load-window {
    background: #fafafa !important;
  }
  mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .main.w-detail + .new-detail-container:before {
    background: rgba(0, 0, 0, 0.6);
  }
  mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .main.side-open + .side {
    border-left: 1px solid rgba(0, 0, 0, 0.12);
  }
  mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .side .container .mat-list-base {
    background: white !important;
    border: 1px solid rgba(0, 0, 0, 0.12);
  }
  mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .new-table .mat-header-row,
mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .new-table .mat-table,
mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .document-table .mat-header-row,
mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .document-table .mat-table,
mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .status-table .mat-header-row,
mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .status-table .mat-table {
    background: white !important;
  }
  mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .audit-filter .filter-container.is-floating {
    background: #fafafa !important;
    border: 1px solid rgba(0, 0, 0, 0.12);
  }
  mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .audit-filter .filter-container.is-floating .form-body {
    background: #fafafa !important;
  }
  mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .audit-result {
    background: white;
  }
  mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .audit-detail-container {
    background: #fafafa !important;
  }
}
@media (max-width: 599px) {
  app-document-detail .mat-toolbar {
    background: #303f9f !important;
    color: white !important;
  }
  app-document-detail .mat-toolbar .mat-toolbar-row .toolbar-actions {
    background: #303f9f !important;
    color: white !important;
  }
  .login-card {
    background: white !important;
  }
  .inbox-toolbar {
    background: #303f9f !important;
    color: white !important;
  }
  .detail {
    background: white url("../img/spinner_mobile.gif") no-repeat center center !important;
  }
  .toolbar-line-container .toolbar-line:first-child {
    background: #303f9f !important;
    color: white !important;
  }
  .toolbar-actions {
    background: #303f9f !important;
    color: white !important;
  }
  mat-drawer.side-nav.enter {
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12) !important;
  }
  .side-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0) !important;
  }
  .filter {
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.55) 16%, rgba(0, 0, 0, 0) 100%) !important;
  }
  button.side-open-mobile {
    color: white !important;
  }
  .side-nav .side-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0);
  }
  .side-nav .filter {
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.55) 16%, rgba(0, 0, 0, 0) 100%);
  }
  .side-nav .side-nav-container .links {
    background: white;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
  }
  .side-nav .side-nav-container .version {
    background: white !important;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
  }
  app-file-document-view .view-container {
    background: white !important;
  }
  app-file-document-view .view-container .side-bar {
    background: #303f9f !important;
    color: white !important;
  }
  app-file-document-view .view-container .view-content app-pdf-wrapper.floating {
    background: white;
  }
  app-file-document-view .view-container .view-content .upload-indications {
    background: #eeeeee;
  }
  app-file-document-view .view-container .view-content app-file-document-sign .sign-cycle mat-horizontal-stepper.mat-stepper-horizontal {
    background-color: rgba(0, 0, 0, 0) !important;
  }
  app-file-document-view .view-container .view-content app-file-document-sign .sign-cycle .sign-cycle-content .cycle-content-action .mat-ripple-element {
    background-color: rgba(255, 255, 255, 0.1) !important;
  }
  app-pending .floating-container .inbox-container .inbox-toolbar .mat-toolbar-row .mat-avatar h3 {
    color: white !important;
  }
  app-pending .floating-container .detail {
    background: white url("../img/spinner_mobile.gif") no-repeat center center !important;
  }
  app-document-sign-massive .inbox-container .pending-container app-chapa.minimized .info-empty {
    background: #e91e63;
  }
  app-document-sign-massive .inbox-container .pending-container app-chapa.minimized .info-empty > span h2 {
    color: white !important;
  }
  app-document-sign-massive .inbox-container .signer.mobile {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    background: white;
  }
  app-document-sign-massive .detail app-file-document-detail .view-container .mat-toolbar .mat-toolbar-row .view-actions {
    color: white !important;
  }
  app-add-documentation .floating-container .employee-add-documentation .inbox-container .inbox-toolbar .mat-toolbar-row .mat-avatar h3 {
    color: white !important;
  }
  app-add-documentation .floating-container .employee-add-documentation .detail {
    background: white url("../img/spinner_mobile.gif") no-repeat center center !important;
  }
  app-add-documentation .floating-container .employee-add-documentation .detail app-file-document-view .view-container .view-content .new-container app-file-document-upload {
    background: white;
  }
}
.mat-card .mat-drawer-container {
  background: white !important;
}

.mat-drawer-container {
  background: #e0e0e0 !important;
}

.mat-list .mat-list-item.finished-process-inbox.active {
  background: #4DB6AC !important;
}
.mat-list .mat-list-item.finished-process-inbox .mat-icon {
  background: #4DB6AC;
}
.mat-list .mat-list-item.erased-process-inbox.active {
  background: #837C80 !important;
}
.mat-list .mat-list-item.limbo-process-inbox.active {
  background: #FFD54F !important;
}
.mat-list .mat-list-item.limbo-process-inbox .mat-icon {
  background: #FFD54F;
}
.mat-list .mat-list-item.in-process-inbox.active {
  background: #FFD54F !important;
}
.mat-list .mat-list-item.in-process-inbox .mat-icon {
  background: #FFD54F;
}
.mat-list .mat-list-item.error-process-inbox.active {
  background: #F48FB1 !important;
}
.mat-list .mat-list-item.error-process-inbox .mat-icon {
  background: #F48FB1;
}
.mat-list .mat-list-item.active .mat-line b {
  color: #3f51b5;
}
.mat-list .mat-list-item.active .mat-list-avatar {
  color: #3f51b5;
}
.mat-list .mat-list-item:hover:not(.active) {
  background: rgba(0, 0, 0, 0.04);
}

span.mat-badge-content {
  color: #f44336 !important;
}

mat-expansion-panel.mat-expanded {
  background: #fafafa !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
mat-expansion-panel.virtual-scrolling .mat-expansion-panel-content .get-more {
  color: #3f51b5;
}
mat-expansion-panel.virtual-scrolling .mat-expansion-panel-content app-cs-paginator.get-more {
  background: white;
}

mat-expansion-panel-header.mat-expanded {
  background: white !important;
}

.bottom-container {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.load-more * {
  color: #3f51b5;
}

.welcome-card {
  background: white !important;
}
.welcome-card .img-divider {
  background: #303f9f;
}

.mat-header-row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background: white;
}

.mat-row {
  background: white;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.mat-row .mat-cell.status-cell mat-icon.pending {
  color: #FF9800 !important;
}
.mat-row .mat-cell.status-cell mat-icon.ok {
  color: #009688 !important;
}
.mat-row .mat-cell.status-cell mat-icon.not-ok {
  color: #9C27B0 !important;
}
.mat-row .mat-cell.status-cell mat-icon.cancelled {
  color: #795548 !important;
}
.mat-row .mat-cell.status-cell mat-icon.rejected {
  color: #df4d4c !important;
}
.mat-row .mat-cell.status-cell mat-icon.draft {
  color: #dedede !important;
}
.mat-row.active, .mat-row.active:hover {
  background: #3f51b5 !important;
}
.mat-row.active div, .mat-row.active:hover div {
  color: white;
}
.mat-row.active .mat-cell.status-cell mat-icon.pending, .mat-row.active .mat-cell.status-cell mat-icon.ok, .mat-row.active .mat-cell.status-cell mat-icon.not-ok, .mat-row.active:hover .mat-cell.status-cell mat-icon.pending, .mat-row.active:hover .mat-cell.status-cell mat-icon.ok, .mat-row.active:hover .mat-cell.status-cell mat-icon.not-ok {
  text-shadow: -1px 1px 0 white, 1px 1px 0 white, 1px -1px 0 white, -1px -1px 0 white;
}
.mat-row.active .sticky-actions button, .mat-row.active:hover .sticky-actions button {
  background: #3f51b5 !important;
}
.mat-row.active .sticky-actions button.ok mat-icon, .mat-row.active .sticky-actions button.not-ok mat-icon, .mat-row.active:hover .sticky-actions button.ok mat-icon, .mat-row.active:hover .sticky-actions button.not-ok mat-icon {
  text-shadow: -1px 1px 0 white, 1px 1px 0 white, 1px -1px 0 white, -1px -1px 0 white;
}
.mat-row:nth-child(odd) {
  background: #fafafa;
}
.mat-row.selected div {
  color: white;
}
.mat-row.selected:nth-child(even) {
  background: #9fa8da !important;
}
.mat-row.selected:nth-child(even) .sticky-actions button {
  background: #9fa8da !important;
}
.mat-row.selected:nth-child(odd) {
  background: #c5cae9 !important;
}
.mat-row.selected:nth-child(odd) .sticky-actions button {
  background: #c5cae9 !important;
}
.mat-row.selected.active, .mat-row.selected.active:hover {
  background: #3f51b5 !important;
}
.mat-row.selected.active .sticky-actions button, .mat-row.selected.active:hover .sticky-actions button {
  background: #3f51b5 !important;
}
.mat-row:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.mat-row:hover .sticky-actions button {
  background: white !important;
}
.mat-row.canceled * {
  color: #795548;
}
.mat-row.disabled div {
  color: #607D8B;
}
.mat-row.disabled.active, .mat-row.disabled.active:hover {
  background: #607D8B !important;
}
.mat-row.disabled.active div, .mat-row.disabled.active:hover div {
  color: white;
}
.mat-row.disabled.active .sticky-actions button, .mat-row.disabled.active:hover .sticky-actions button {
  background: #607D8B !important;
}
.mat-row.disabled.selected.active:hover .sticky-actions button {
  background: #607D8B !important;
}
.mat-row .sticky-actions button.ok mat-icon {
  color: #009688 !important;
}
.mat-row .sticky-actions button.not-ok mat-icon {
  color: #9C27B0 !important;
}

.status-table .mat-header-row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background: white;
}

.icon-info-off,
.certificate-off {
  color: #607D8B;
}

.documents-search-table .mat-header-row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background: white;
}

mat-toolbar.employee-search-sub-toolbar {
  background: white;
}

.mat-search-field {
  border: 1px solid rgba(0, 0, 0, 0.12);
  background: #eeeeee;
}

.mat-search-field input[type=search] {
  background: rgba(0, 0, 0, 0);
}

.mat-search-field input[type=search]::placeholder {
  color: rgba(0, 0, 0, 0.38);
}

.search-adv-menu .mat-search-field {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

mat-toolbar.document-search-sub-toolbar {
  background: white;
}

app-advanced-employee-search .mat-chip {
  background: rgba(0, 0, 0, 0.04) !important;
}

.side-nav .side-trigger {
  background: white;
}
.side-nav .menu-trigger mat-icon {
  color: rgba(0, 0, 0, 0.54);
  background: white;
}
.side-nav .side-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0);
}
.side-nav .filter {
  background: white;
}
.side-nav .side-nav-container .links mat-icon span.mat-badge-content {
  color: white !important;
}
.side-nav .side-nav-container .mat-list-base .mat-list-item:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.side-nav .side-nav-container .mat-list-base .mat-list-item.active {
  background: #3f51b5 !important;
}
.side-nav .side-nav-container .mat-list-base .mat-list-item.active * {
  color: white;
}
.side-nav .side-nav-container .mat-list-base .mat-list-item.active .mat-list-item.active:hover {
  background: #3f51b5 !important;
}
.side-nav .side-nav-container .mat-list-base .mat-list-item.active .mat-list-item.active:hover * {
  color: white;
}

.side-nav.open .side-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.side-nav.open .filter {
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.55) 16%, rgba(0, 0, 0, 0) 100%);
}
.side-nav.open .side-nav-container .links {
  background: white;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.side-nav.open .side-nav-container .version {
  background: white !important;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.side-nav.enter .side-nav-container .links {
  background: white;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.side-nav.enter .side-nav-container .version {
  background: white !important;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.mat-menu-panel:not(.metadata-radio-menu) .navigation-list .mat-list-item-content {
  color: rgba(0, 0, 0, 0.54);
}

table.mat-calendar-table {
  background: white !important;
}

.mat-calendar-body-label {
  color: white !important;
}

.mat-calendar-arrow {
  border-top-color: white !important;
}

.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: white !important;
}

.mat-calendar-header {
  background: #303f9f !important;
}

mat-datepicker-content {
  background-color: #303f9f !important;
  color: white !important;
}

.mat-calendar-content {
  background: white !important;
}

.mat-simple-snackbar-action button.mat-button {
  color: #FFE082;
}

.mat-simple-snackbar-action .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

mat-bottom-sheet-container mat-toolbar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}

app-generic-bottom-sheet h4.accent {
  color: #e91e63;
}
app-generic-bottom-sheet .verification-code {
  background: #3f51b5;
  color: white;
}
app-generic-bottom-sheet .verification-code h2 {
  background: #3f51b5;
}
app-generic-bottom-sheet .load-window.message .info-container h3.primary-text {
  color: #3f51b5;
}
app-generic-bottom-sheet .bottom_sheet-content.ok .bottom_sheet-title .bottom_sheet-icon,
app-generic-bottom-sheet .bottom_sheet-content.ok .bottom_sheet-title h3 {
  color: #009688;
}
app-generic-bottom-sheet .bottom_sheet-content.ok .bottom_sheet-main_btn:not(:disabled) {
  background: #009688;
  color: #fff;
}
app-generic-bottom-sheet .bottom_sheet-content.not-ok .bottom_sheet-title .bottom_sheet-icon,
app-generic-bottom-sheet .bottom_sheet-content.not-ok .bottom_sheet-title h3 {
  color: #9C27B0;
}
app-generic-bottom-sheet .bottom_sheet-content.not-ok .bottom_sheet-main_btn:not(:disabled) {
  background: #9C27B0;
  color: #fff;
}
app-generic-bottom-sheet .bottom_sheet-content.cancel .bottom_sheet-title .bottom_sheet-icon,
app-generic-bottom-sheet .bottom_sheet-content.cancel .bottom_sheet-title h3 {
  color: #795548;
}
app-generic-bottom-sheet .bottom_sheet-content.cancel .bottom_sheet-main_btn:not(:disabled) {
  background: #795548;
  color: #fff;
}
app-generic-bottom-sheet .bottom_sheet-content .bottom_sheet_exp_btn {
  background-color: #3f51b5;
  color: #fff;
}
app-generic-bottom-sheet .bottom_sheet-content .bottom_sheet_canc_btn {
  background-color: lightgrey;
  color: black;
}
app-generic-bottom-sheet .bottom_sheet-content .bottom_sheet-exp-h3 {
  color: #3f51b5;
  margin-bottom: -10px !important;
}
app-generic-bottom-sheet .bottom_sheet-content .mat-input-element-export {
  display: flex !important;
  padding-left: 15px;
}
app-generic-bottom-sheet .bottom_sheet-content .mat-form-field-infix-export-right {
  width: 100% !important;
  padding: 0px !important;
  display: flex !important;
}
app-generic-bottom-sheet .bottom_sheet-content .mat-form-field-infix-export-left {
  width: 100% !important;
  padding: 0px;
  display: flex !important;
}
app-generic-bottom-sheet .bottom_sheet-content .mat-form-field-infix {
  display: flex;
}
app-generic-bottom-sheet .bottom_sheet-content .mat-checkbox-layout .mat-checkbox-label {
  color: #e91e63;
}
app-generic-bottom-sheet .bottom_sheet-content .choose-forms {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  margin-left: 10px !important;
}
app-generic-bottom-sheet .bottom_sheet-content .card-container {
  max-height: 150px;
  overflow-y: auto;
}

.mat-list .mat-list-item.active .mat-line {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list .mat-list-item.active .mat-line b {
  color: #3f51b5;
}
.mat-list .mat-list-item.active .mat-list-avatar {
  color: #3f51b5;
}

.mat-list .mat-list-item .mat-line {
  color: rgba(0, 0, 0, 0.54);
}
.mat-list .mat-list-item .mat-list-avatar {
  color: rgba(0, 0, 0, 0.54);
}

.mat-list-item:hover:not(.active) {
  background: rgba(0, 0, 0, 0.04) !important;
}

mat-list-item:nth-child(even),
app-file-document-inbox-item:nth-child(even) mat-list-item,
app-file-document-inbox-item-documentation:nth-child(even) mat-list-item {
  background: #fafafa !important;
}

mat-list-item:nth-child(odd),
app-file-document-inbox-item:nth-child(odd) mat-list-item,
app-file-document-inbox-item-documentation:nth-child(odd) mat-list-item {
  background: white !important;
}

.mat-list-avatar.mat-icon {
  background: #eeeeee;
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.card-filter {
  background: white;
}

.login mat-horizontal-stepper {
  background: white !important;
}
.login .login-card {
  background: white !important;
}
.login .login-card .mat-card-content .mat-card-footer a[color=accent] {
  color: #e91e63;
}
.login .help-stepper h3.align-16 {
  color: #e91e63;
}
.login .help-stepper .help-options mat-icon {
  color: #e91e63;
}

.ux-modal {
  background: rgba(0, 0, 0, 0.32);
}

.ux-modal .card {
  background: white;
}

.main-title {
  background: #607D8B;
  color: white;
}

.sub-title {
  color: rgba(0, 0, 0, 0.54);
}

.card-footer a {
  color: white;
}

.profile-toolbar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

app-profile .logout-btn:hover {
  background: #eeeeee;
}
app-profile .cropper {
  background: white;
}
app-profile .cropper .cropper {
  background: unset;
}

app-add-documentation .employee-add-documentation .inbox-toolbar {
  background: #fafafa;
}
app-add-documentation .employee-add-documentation .inbox-toolbar .toolbar-line mat-icon {
  color: #3f51b5;
}
app-add-documentation .employee-add-documentation .inbox-toolbar .toolbar-line h3 {
  color: #3f51b5;
}
app-add-documentation .employee-add-documentation mat-tab-group {
  background: #fafafa;
}
app-add-documentation .employee-add-documentation mat-tab-group mat-tab-header .mat-tab-label-active {
  color: #3f51b5;
}
app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content:hover {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12) !important;
}
app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content.selected {
  border-left: 6px solid #3f51b5 !important;
}
app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content.selected mat-icon {
  color: #3f51b5 !important;
}
app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content.selected .item-data {
  color: rgba(0, 0, 0, 0.87) !important;
}
app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content mat-icon {
  color: rgba(0, 0, 0, 0.54);
}
app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content .item-data {
  color: rgba(0, 0, 0, 0.54);
}
app-add-documentation .employee-add-documentation .detail app-file-document-view .new-container app-file-document-upload .metadata-list .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
  background: white !important;
}

app-pending .inbox-toolbar {
  background: #fafafa;
}
app-pending .pending-container {
  background: #fafafa;
}
app-pending .pending-container .pending-items .pending-item .content:hover {
  background: rgba(0, 0, 0, 0.04) !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12) !important;
}
app-pending .pending-container .pending-items .pending-item .content.selected {
  border-left: 6px solid #e91e63 !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12) !important;
}
app-pending .pending-container .pending-items .pending-item .content.selected mat-icon {
  color: #e91e63 !important;
}
app-pending .pending-container .pending-items .pending-item .content.selected .item-data {
  color: rgba(0, 0, 0, 0.87) !important;
}
app-pending .pending-container .pending-items .pending-item .content mat-icon {
  color: rgba(0, 0, 0, 0.54);
}
app-pending .pending-container .pending-items .pending-item .content .item-data {
  color: rgba(0, 0, 0, 0.54);
}

app-document-sign-massive .inbox-container .inbox-toolbar {
  background: #fafafa;
}
app-document-sign-massive .inbox-container .pending-container {
  background: #fafafa;
}
app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content:hover {
  background: rgba(0, 0, 0, 0.04) !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12) !important;
}
app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content.selected {
  border-left: 6px solid #e91e63 !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12) !important;
}
app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content.selected mat-icon {
  color: #e91e63 !important;
}
app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content.selected .item-data {
  color: rgba(0, 0, 0, 0.87) !important;
}
app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content mat-icon {
  color: rgba(0, 0, 0, 0.54);
}
app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content .item-data {
  color: rgba(0, 0, 0, 0.54);
}
app-document-sign-massive .detail app-file-document-detail .view-container {
  background: #607D8B;
}
app-document-sign-massive .detail app-file-document-detail .view-container .mat-toolbar {
  background: #607D8B;
  color: white;
  background: #607D8B;
}
app-document-sign-massive .detail app-chapa.minimized .info-empty {
  background: #e91e63;
}
app-document-sign-massive .detail app-chapa.minimized .info-empty > span mat-icon {
  color: white !important;
}
app-document-sign-massive .detail app-chapa.minimized .info-empty > span h2 {
  color: white !important;
}
app-document-sign-massive .signer.desktop {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
app-document-sign-massive .signer .signer-btn {
  color: white !important;
}
app-document-sign-massive .signer .signer-btn[disabled] {
  background-color: rgba(0, 0, 0, 0.12);
}

app-document-detail app-sign-document .firmador button.mat-raised-button.mat-accent {
  color: white !important;
}

mat-toolbar-row.firmado-conforme .mat-icon {
  background: #8BC34A;
  color: white;
}

mat-toolbar-row.firmado-no-conforme .mat-icon {
  background: #f44336;
  color: white;
}

.secondary-toolbar {
  background: white;
}

.new-detail-container {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}
.new-detail-container.cancelled .detail_head-body {
  background: #607D8B !important;
}
.new-detail-container .employee-detail-toolbar {
  background: #eeeeee;
}
.new-detail-container .employee-detail-subbar {
  background: white;
}

app-employee-detail .load-window,
app-employee-rrhh-detail .load-window {
  background: #fafafa;
}
app-employee-detail .employee_detail-container,
app-employee-rrhh-detail .employee_detail-container {
  background: #fafafa;
}
app-employee-detail .employee_detail-container.is-editing .edition-line,
app-employee-rrhh-detail .employee_detail-container.is-editing .edition-line {
  background: #eeeeee;
}
app-employee-detail .employee_detail-container .detail_head-body,
app-employee-rrhh-detail .employee_detail-container .detail_head-body {
  background: #3f51b5;
}
app-employee-detail .employee_detail-container .detail_head-body .head_body-status .icon-stacked + mat-icon,
app-employee-rrhh-detail .employee_detail-container .detail_head-body .head_body-status .icon-stacked + mat-icon {
  color: white !important;
}
app-employee-detail .employee_detail-container .detail_head-body .head_body-title .body_title-name,
app-employee-rrhh-detail .employee_detail-container .detail_head-body .head_body-title .body_title-name {
  color: white;
}
app-employee-detail .employee_detail-container .detail_head-body .head_body-title .body_title-user,
app-employee-rrhh-detail .employee_detail-container .detail_head-body .head_body-title .body_title-user {
  color: white;
}
app-employee-detail .employee_detail-container .data .data-line,
app-employee-rrhh-detail .employee_detail-container .data .data-line {
  background: white;
}
app-employee-detail .employee_detail-container .data .data-line .icon-complex mat-icon.complex,
app-employee-rrhh-detail .employee_detail-container .data .data-line .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px white, 0 -2px 0px white, 2px 0 0px white, 0 2px 0px white, -2px -2px 0px white, 2px 2px 0px white, 2px -2px 0px white, -2px 2px 0px white;
}
app-employee-detail .employee_detail-container .data .data-line .line_text-main,
app-employee-rrhh-detail .employee_detail-container .data .data-line .line_text-main {
  color: #3f51b5;
}
app-employee-detail .employee_detail-container .data .data-line.clickable:hover,
app-employee-rrhh-detail .employee_detail-container .data .data-line.clickable:hover {
  background: #fafafa !important;
}
app-employee-detail .employee_detail-container .data .data-line button.mat-icon-button,
app-employee-rrhh-detail .employee_detail-container .data .data-line button.mat-icon-button {
  background: #eeeeee;
}
app-employee-detail .employee_detail-container .employee-title .title-text,
app-employee-rrhh-detail .employee_detail-container .employee-title .title-text {
  color: #3f51b5;
}
app-employee-detail .employee_detail-container .employee-title .title-text mat-icon,
app-employee-rrhh-detail .employee_detail-container .employee-title .title-text mat-icon {
  color: #3f51b5;
}
app-employee-detail .employee_detail-container .employee-title .title-text .title_text-main,
app-employee-rrhh-detail .employee_detail-container .employee-title .title-text .title_text-main {
  color: #3f51b5;
}

.UI-V2 .icon-complex mat-icon.complex {
  color: #3f51b5;
  text-shadow: -2px 0 0px white, 0 -2px 0px white, 2px 0 0px white, 0 2px 0px white, -2px -2px 0px white, 2px 2px 0px white, 2px -2px 0px white, -2px 2px 0px white;
}
.UI-V2 .icon-complex mat-icon.complex.mat-accent {
  color: #e91e63;
}
.UI-V2 .main .toolbar .header .content .module-title {
  color: #3f51b5;
}
.UI-V2 .main .toolbar .header .content .module-title.selectable:hover {
  background: rgba(0, 0, 0, 0.04);
}
.UI-V2 .main .toolbar .header .content .module-title .info h5 {
  color: rgba(0, 0, 0, 0.87) !important;
}
.UI-V2 .main .toolbar .header .content .ou-select {
  color: white;
  background: #3f51b5;
}
.UI-V2 .main .toolbar .search-container .search .content {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.UI-V2 .main .toolbar .search-container .search .content:hover, .UI-V2 .main .toolbar .search-container .search .content:active, .UI-V2 .main .toolbar .search-container .search .content:focus, .UI-V2 .main .toolbar .search-container .search .content:focus-within {
  background: rgba(0, 0, 0, 0.04) !important;
}
.UI-V2 .main .toolbar .search-container .search .content input {
  color: rgba(0, 0, 0, 0.87);
}
.UI-V2 .main .helper {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.UI-V2 .main .helper mat-hint h4 {
  color: #3f51b5;
}
.UI-V2 .main .new-table app-cs-grid-control datatable-header * {
  color: #3f51b5;
}
.UI-V2 .main .new-table .mat-header-row.sticky * {
  color: #3f51b5 !important;
}
.UI-V2 .main .main-action {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.menu-options.help .primary {
  color: #3f51b5;
}
.menu-options.help .accent {
  color: #e91e63;
}
.menu-options .icon-complex mat-icon.complex {
  color: #3f51b5;
  text-shadow: -2px 0 0px white, 0 -2px 0px white, 2px 0 0px white, 0 2px 0px white, -2px -2px 0px white, 2px 2px 0px white, 2px -2px 0px white, -2px 2px 0px white;
}
.menu-options .mat-menu-content .container mat-list .mat-list-item:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.menu-options .mat-menu-content .container mat-list .mat-list-item .disabled {
  color: rgba(0, 0, 0, 0.54) !important;
}
.menu-options .mat-menu-content .container .slide-group mat-radio-group mat-radio-button:hover {
  background: rgba(0, 0, 0, 0.04);
}
.menu-options .mat-menu-content .container .slide-group mat-form-field .mat-form-field-flex {
  background: #fafafa;
}
.menu-options .mat-menu-content .container .slide-group mat-slide-toggle:hover {
  background: rgba(0, 0, 0, 0.04);
}

.mat-menu-panel.notifications-panel .mat-menu-content .notifications-body {
  background: #fafafa;
}
.mat-menu-panel.notifications-panel .mat-menu-content .notification .primary mat-icon {
  color: #3f51b5;
}
.mat-menu-panel.notifications-panel .mat-menu-content .notification .primary h3 {
  color: #3f51b5;
}
.mat-menu-panel.notifications-panel .mat-menu-content .notification.top {
  background: #fafafa;
}
.mat-menu-panel.notifications-panel .mat-menu-content .notification.top .header h3 {
  color: rgba(0, 0, 0, 0.87);
}
.mat-menu-panel.notifications-panel .mat-menu-content .notification.bottom {
  background: #fafafa;
}
.mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable:nth-child(even) {
  background: #fafafa;
}
.mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable:nth-child(odd) {
  background: white;
}
.mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable:hover {
  background: rgba(0, 0, 0, 0.04);
}
.mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable mat-icon {
  color: #3f51b5;
}
.mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable h3 {
  color: rgba(0, 0, 0, 0.87);
}
.mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable h5 {
  color: rgba(0, 0, 0, 0.38);
}
.mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable .subtitle h5 {
  color: #3f51b5;
  background: #eeeeee;
}

.info-empty .large-icon {
  background: #c5cae9;
  color: #3f51b5;
}
.info-empty h2,
.info-empty h3 {
  color: #3f51b5;
}
.info-empty h4,
.info-empty h5 {
  color: rgba(0, 0, 0, 0.54);
}
.info-empty.accent mat-icon {
  background: #f8bbd0;
  color: #e91e63;
}
.info-empty.accent h2 {
  color: #e91e63;
}
.info-empty .actions {
  padding: 16px;
  height: 96px;
  column-gap: 16px;
}
.info-empty .actions button {
  height: 100%;
  border-radius: 40px;
}
.info-empty .actions button.ok {
  background: #009688 !important;
  color: white;
}
.info-empty .actions button.not-ok {
  color: #9C27B0 !important;
}

.side.edition .info-empty .large-icon {
  background: #f8bbd0;
  color: #e91e63;
}
.side.edition .info-empty h2 {
  color: #e91e63;
}
.side.edition .info-empty h4 {
  color: rgba(0, 0, 0, 0.54);
}

.UI-V2 .deferred-process-list .header h3 {
  color: #3f51b5;
}
.UI-V2 .deferred-process-list .search-container .search .content {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.UI-V2 .deferred-process-list .search-container .search .content:hover, .UI-V2 .deferred-process-list .search-container .search .content:active, .UI-V2 .deferred-process-list .search-container .search .content:focus, .UI-V2 .deferred-process-list .search-container .search .content:focus-within {
  background: rgba(0, 0, 0, 0.04) !important;
}
.UI-V2 .deferred-process-list .search-container .search .content input {
  color: rgba(0, 0, 0, 0.87);
}
.UI-V2 .deferred-process-list .text-success,
.UI-V2 .deferred-process-details .text-success {
  color: #8BC34A;
}
.UI-V2 .deferred-process-list .text-warn,
.UI-V2 .deferred-process-details .text-warn {
  color: #f44336;
}
.UI-V2 .deferred-process-list .text-primary,
.UI-V2 .deferred-process-details .text-primary {
  color: #3f51b5;
}
.UI-V2 .deferred-process-list .text-atention,
.UI-V2 .deferred-process-details .text-atention {
  color: #DB7703;
}
.UI-V2 .deferred-process-list .table-paginator-container,
.UI-V2 .deferred-process-details .table-paginator-container {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
}
.UI-V2 .deferred-process-list .table-paginator-container .mat-table,
.UI-V2 .deferred-process-details .table-paginator-container .mat-table {
  border-bottom: none;
  border-radius: 10px 10px 0 0;
}
.UI-V2 .deferred-process-list .table-paginator-container .paginator-container,
.UI-V2 .deferred-process-details .table-paginator-container .paginator-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: #f2f2f2;
  padding: 0 1.5rem;
  height: 3rem;
}
.UI-V2 .deferred-process-list .mat-table,
.UI-V2 .deferred-process-details .mat-table {
  border: 1px solid #f2f2f2;
  border-radius: 10px;
  overflow: hidden;
}
.UI-V2 .deferred-process-list .mat-table .mat-header-row .mat-header-cell,
.UI-V2 .deferred-process-details .mat-table .mat-header-row .mat-header-cell {
  font-size: 14px;
  font-weight: 700;
}
.UI-V2 .deferred-process-list .mat-table .mat-header-row,
.UI-V2 .deferred-process-details .mat-table .mat-header-row {
  background: #f2f2f2;
}
.UI-V2 .deferred-process-list .mat-table .mat-header-row .mat-header-cell,
.UI-V2 .deferred-process-details .mat-table .mat-header-row .mat-header-cell {
  color: #3f51b5;
}
.UI-V2 .deferred-process-list .mat-table .mat-row,
.UI-V2 .deferred-process-details .mat-table .mat-row {
  background: white;
}
.UI-V2 .progress-bar-container {
  position: relative;
}
.UI-V2 .deferred-process-details .header h3 {
  color: #3f51b5;
}
.UI-V2 .deferred-process-details .header .back-link:visited {
  color: #3f51b5;
}

.process-card,
.process-card-detail,
.process-card-stages {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.process-card mat-card-subtitle,
.process-card-detail mat-card-subtitle,
.process-card-stages mat-card-subtitle {
  color: rgba(0, 0, 0, 0.87);
}
.process-card .stages-container .stage.completed mat-icon,
.process-card-detail .stages-container .stage.completed mat-icon,
.process-card-stages .stages-container .stage.completed mat-icon {
  color: #499719;
}
.process-card .stages-container .stage.completed .icon-container,
.process-card-detail .stages-container .stage.completed .icon-container,
.process-card-stages .stages-container .stage.completed .icon-container {
  background-color: rgba(78, 160, 28, 0.1490196078);
}
.process-card .stages-container .stage.completed .line,
.process-card-detail .stages-container .stage.completed .line,
.process-card-stages .stages-container .stage.completed .line {
  background-color: #499719;
}
.process-card .stages-container .stage.on-going mat-icon,
.process-card-detail .stages-container .stage.on-going mat-icon,
.process-card-stages .stages-container .stage.on-going mat-icon {
  color: #3f51b5;
}
.process-card .stages-container .stage.on-going .icon-container,
.process-card-detail .stages-container .stage.on-going .icon-container,
.process-card-stages .stages-container .stage.on-going .icon-container {
  background-color: rgba(66, 48, 159, 0.2);
}
.process-card .stages-container .stage.on-going .line,
.process-card-detail .stages-container .stage.on-going .line,
.process-card-stages .stages-container .stage.on-going .line {
  background-color: #949494;
}
.process-card .stages-container .stage.pending,
.process-card-detail .stages-container .stage.pending,
.process-card-stages .stages-container .stage.pending {
  color: #949494;
}
.process-card .stages-container .stage.pending mat-icon,
.process-card-detail .stages-container .stage.pending mat-icon,
.process-card-stages .stages-container .stage.pending mat-icon {
  color: #949494;
}
.process-card .stages-container .stage.pending .icon-container,
.process-card-detail .stages-container .stage.pending .icon-container,
.process-card-stages .stages-container .stage.pending .icon-container {
  background-color: rgba(174, 174, 174, 0.2);
}
.process-card .stages-container .stage.pending .line,
.process-card-detail .stages-container .stage.pending .line,
.process-card-stages .stages-container .stage.pending .line {
  background-color: #949494;
}
.process-card .stages-container .stage.has-error mat-icon,
.process-card-detail .stages-container .stage.has-error mat-icon,
.process-card-stages .stages-container .stage.has-error mat-icon {
  color: #f44336;
}
.process-card .stages-container .stage.has-error .icon-container,
.process-card-detail .stages-container .stage.has-error .icon-container,
.process-card-stages .stages-container .stage.has-error .icon-container {
  background-color: rgba(188, 4, 4, 0.2);
}
.process-card .stages-container .stage-description .button-link,
.process-card-detail .stages-container .stage-description .button-link,
.process-card-stages .stages-container .stage-description .button-link {
  color: #3f51b5;
}

app-custom-search-input .custom-search-container {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
  transition: color 0.1s ease, background 0.15s ease;
}
app-custom-search-input .custom-search-container mat-icon.mat-icon {
  color: rgba(0, 0, 0, 0.38);
}
app-custom-search-input .custom-search-container input.custom-search-input {
  color: rgba(0, 0, 0, 0.87);
  background: unset;
}
app-custom-search-input .custom-search-container:hover, app-custom-search-input .custom-search-container:active, app-custom-search-input .custom-search-container:focus, app-custom-search-input .custom-search-container:focus-within {
  background: rgba(0, 0, 0, 0.04) !important;
  transition: background 0.15s ease;
}

.UI-V2 .audit-filter {
  background: white;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.UI-V2 .audit-filter .toolbar {
  background: #3f51b5;
  color: white;
}
.UI-V2 .audit-filter .toolbar .header .content .module-title {
  color: #3f51b5;
}
.UI-V2 .audit-filter .toolbar .header .content .module-title.selectable:hover {
  background: rgba(0, 0, 0, 0.04);
}
.UI-V2 .audit-filter .toolbar .header .content .module-title .info * {
  color: white !important;
}
.UI-V2 .audit-filter .toolbar .header .content .ou-select {
  color: white;
  background: #3f51b5;
}
.UI-V2 .audit-filter .toolbar .search-container .search .content {
  background: #fafafa;
}
.UI-V2 .audit-filter .toolbar .search-container .search .content:hover, .UI-V2 .audit-filter .toolbar .search-container .search .content:active, .UI-V2 .audit-filter .toolbar .search-container .search .content:focus, .UI-V2 .audit-filter .toolbar .search-container .search .content:focus-within {
  background: rgba(0, 0, 0, 0.04) !important;
}
.UI-V2 .audit-filter .toolbar .search-container .search .content input {
  color: rgba(0, 0, 0, 0.87);
}
.UI-V2 .audit-filter .filter-container {
  background: white !important;
}
.UI-V2 .audit-filter .filter-container .form-body {
  background: #fafafa !important;
}
.UI-V2 .audit-filter .filter-container .form-body .line h4 {
  color: #3f51b5;
}
.UI-V2 .audit-filter .filter-container .form-body .line mat-icon {
  color: #3f51b5;
}
.UI-V2 .audit-result .result-container .toolbar {
  color: white;
}
.UI-V2 .audit-result .result-container .toolbar .header .content .module-title {
  color: #3f51b5;
}
.UI-V2 .audit-result .result-container .toolbar .header .content .module-title.selectable:hover {
  background: rgba(0, 0, 0, 0.04);
}
.UI-V2 .audit-result .result-container .toolbar .header .content .module-title .info h5 {
  color: rgba(0, 0, 0, 0.87) !important;
}
.UI-V2 .audit-result .result-container .toolbar .header .content .ou-select {
  color: white;
  background: #3f51b5;
}
.UI-V2 .audit-result .result-container .toolbar .search-container .search .content {
  background: #fafafa;
}
.UI-V2 .audit-result .result-container .toolbar .search-container .search .content:hover, .UI-V2 .audit-result .result-container .toolbar .search-container .search .content:active, .UI-V2 .audit-result .result-container .toolbar .search-container .search .content:focus, .UI-V2 .audit-result .result-container .toolbar .search-container .search .content:focus-within {
  background: rgba(0, 0, 0, 0.04) !important;
}
.UI-V2 .audit-result .result-container .toolbar .search-container .search .content input {
  color: rgba(0, 0, 0, 0.87);
}
.UI-V2 .audit-result .result-container .new-table app-cs-grid-control datatable-header * {
  color: #3f51b5;
}
.UI-V2 .audit-result .result-container .new-table .mat-header-row.sticky * {
  color: #3f51b5 !important;
}
.UI-V2 .audit-detail-container {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  background: #eeeeee !important;
}
.UI-V2 .audit-detail-container .side .toolbar .header {
  color: #3f51b5;
}
.UI-V2 .audit-detail-container .side .container .mat-list-base {
  background: #fafafa !important;
}
.UI-V2 .audit-detail-container .side .container .mat-list-base mat-list-item .mat-line b {
  color: #3f51b5;
}

.UI-V2 .inbox-container {
  background: white;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.UI-V2 .inbox-container *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.UI-V2 .inbox-container *::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}
.UI-V2 .inbox-container *::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}
.UI-V2 .inbox-container .load-window {
  background: white;
}
.UI-V2 .inbox-container .toolbar {
  background: #3f51b5;
  color: white;
}
.UI-V2 .inbox-container .toolbar.reverse {
  background: white;
  color: rgba(0, 0, 0, 0.54);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.UI-V2 .inbox-container .container mat-accordion mat-expansion-panel {
  background: #eeeeee !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.UI-V2 .inbox-container .container mat-accordion mat-expansion-panel.mat-expanded {
  background: #fafafa !important;
}
.UI-V2 .inbox-container .container mat-accordion mat-expansion-panel.mat-expanded mat-expansion-panel-header {
  background: #fafafa !important;
}
.UI-V2 .inbox-container .container mat-accordion mat-expansion-panel.mat-expanded mat-expansion-panel-header:hover {
  background: none !important;
}
.UI-V2 .inbox-container .container mat-accordion mat-expansion-panel mat-expansion-panel-header {
  background: #fafafa !important;
}
.UI-V2 .inbox-container .container mat-accordion mat-expansion-panel mat-expansion-panel-header:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.UI-V2 .inbox-container .container mat-accordion mat-expansion-panel mat-expansion-panel-header .signature-inbox-header .mat-icon,
.UI-V2 .inbox-container .container mat-accordion mat-expansion-panel mat-expansion-panel-header .signature-inbox-header b {
  color: #e91e63;
}
.UI-V2 .inbox-container .container mat-accordion mat-expansion-panel .mat-expansion-panel-body .item {
  background: white;
  color: rgba(0, 0, 0, 0.54);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.UI-V2 .inbox-container .container mat-accordion mat-expansion-panel .mat-expansion-panel-body .item:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.UI-V2 .inbox-container .container mat-accordion mat-expansion-panel .mat-expansion-panel-body .item.active {
  border-left: 6px solid #e91e63;
}
.UI-V2 .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.UI-V2 .work-space .main .load-window {
  background: white !important;
}
.UI-V2 .work-space .main-action {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

mat-radio-button.disabled .mat-radio-label-content {
  color: #e91e63;
}

.detail app-automatic-process-detail app-chapa.minimize,
.detail app-process-detail app-chapa.minimize {
  background: #FFD54F !important;
}
.detail app-automatic-process-detail app-chapa.minimize.purged,
.detail app-process-detail app-chapa.minimize.purged {
  background: #b2adb0 !important;
}
.detail .detail-toolbar {
  background: #eeeeee;
}
.detail .processes-status .status-bar .bar-graph {
  background-color: #FFD54F;
}
.detail .processes-status .status-bar .bar-graph .graph-ok {
  background-color: #4DB6AC;
}
.detail .processes-status .status-bar .bar-graph .graph-not-ok {
  background-color: #F48FB1;
}
.detail .processes-status .status-resume b {
  color: rgba(0, 0, 0, 0.87);
}
.detail .processes-status .status-resume .resume-total b {
  background-color: #FFD54F;
}
.detail .processes-status .status-resume .resume-ok b {
  background-color: #4DB6AC;
}
.detail .processes-status .status-resume .resume-not-ok b {
  background-color: #F48FB1;
}
.detail .detail-toolbar .mat-avatar h3,
.detail .detail-toolbar .mat-avatar h5 {
  color: rgba(0, 0, 0, 0.87);
}
.detail .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  color: rgba(0, 0, 0, 0.87);
}
.detail .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  color: rgba(0, 0, 0, 0.87);
}
.detail .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  color: rgba(0, 0, 0, 0.87);
}
.detail .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  color: rgba(0, 0, 0, 0.87);
}
.detail .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  color: white;
}
.detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  background: #4DB6AC !important;
}
.detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  background: #b2dfdb !important;
}
.detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  background: #b2dfdb !important;
}
.detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  background: #4DB6AC !important;
}
.detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  background: #b2dfdb !important;
}
.detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  background: #837C80 !important;
}
.detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  background: #b2adb0 !important;
}
.detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  background: #b2adb0 !important;
}
.detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  background: #837C80 !important;
}
.detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  background: #b2dfdb !important;
}
.detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  background: #FFD54F !important;
}
.detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  background: #ffecb3 !important;
}
.detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  background: #ffecb3 !important;
}
.detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  background: #FFD54F !important;
}
.detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  background: #ffecb3 !important;
}
.detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  background: #F48FB1 !important;
}
.detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  background: #f8bbd0 !important;
}
.detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  background: #f8bbd0 !important;
}
.detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  background: #F48FB1 !important;
}
.detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  background: #f8bbd0 !important;
}

app-analytics-document-set .sets-container .mat-list-base.sets-list mat-list-item {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
app-analytics-document-set .sets-container .mat-list-base.sets-list mat-list-item .mat-list-item-content .set-status .value-percentile .undone-value {
  background: #673AB7;
}
app-analytics-document-set .sets-container .mat-list-base.sets-list mat-list-item .mat-list-item-content .set-status .value-percentile .not-value {
  background: #eeeeee;
}
app-analytics-document-set .sets-container .mat-list-base.sets-list mat-list-item .mat-list-item-content .set-status .value-percentile .done-value {
  background: #009688;
}
app-analytics-document-set .work-space .main .toolbar .header .content .module-title .info .info-text .set-status {
  cursor: help;
}
app-analytics-document-set .work-space .main .toolbar .header .content .module-title .info .info-text .set-status .value-percentile .undone-value {
  background: #673AB7;
}
app-analytics-document-set .work-space .main .toolbar .header .content .module-title .info .info-text .set-status .value-percentile .not-value {
  background: #eeeeee;
}
app-analytics-document-set .work-space .main .toolbar .header .content .module-title .info .info-text .set-status .value-percentile .done-value {
  background: #009688;
}
app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar .mat-progress-bar-buffer {
  background-color: #eeeeee;
}
app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar.class1 .mat-progress-bar-fill::after {
  background-color: #f44336;
}
app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar.class2 .mat-progress-bar-fill::after {
  background-color: #ff9800;
}
app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar.class3 .mat-progress-bar-fill::after {
  background-color: #ffeb3b;
}
app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar.class4 .mat-progress-bar-fill::after {
  background-color: #8bc34a;
}
app-analytics-document-set .work-space .side .container mat-list {
  background: white !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
app-analytics-document-set .work-space .side .container mat-list mat-list-item .mat-line b {
  color: #3f51b5;
}

.catcher {
  background: rgba(0, 0, 0, 0.6);
}
.catcher.clear {
  background: rgba(0, 0, 0, 0);
}

.add-menu-form-body .mat-radio-group .mat-radio-button:hover {
  background: rgba(0, 0, 0, 0.04);
}
.add-menu-form-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b {
  color: rgba(0, 0, 0, 0.87);
}
.add-menu-form-body.w-table .helper .search-bar {
  background: #fafafa;
}
.add-menu-form-body.w-table .helper .search-bar:hover, .add-menu-form-body.w-table .helper .search-bar:active, .add-menu-form-body.w-table .helper .search-bar:focus, .add-menu-form-body.w-table .helper .search-bar:focus-within {
  background: #fafafa !important;
}
.add-menu-form-body.w-table .helper .search-bar input {
  color: rgba(0, 0, 0, 0.87);
}

.add-menu-form-actions {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.file-upload-list mat-list-item {
  background: white;
}

.mat-horizontal-stepper-content[aria-expanded=true] app-file-document-view {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}
.mat-horizontal-stepper-content[aria-expanded=true] app-file-document-view .pdf-view-container {
  background: #eeeeee;
}

.add-docs-drop .docs-drop-preview app-file-document-detail {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}
.add-docs-drop .docs-drop-preview app-file-document-detail .view-toolbar {
  background: white;
}
.add-docs-drop .load-window.message h3.primary-text {
  color: #3f51b5;
}

mat-dialog-container app-add-menu-dialog .load-window.message h3.primary-text {
  color: #3f51b5;
}

.asisted-input.aligned-icon.subtitle * {
  color: rgba(0, 0, 0, 0.38) !important;
}
.asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble {
  background: #3f51b5;
}
.asisted-input .mat-form-field-invalid + .indicator > .bubble {
  background: #f44336;
}
.asisted-input .mat-form-field-disabled + .indicator > .bubble {
  color: rgba(0, 0, 0, 0.38);
  background: rgba(0, 0, 0, 0.12);
}
.asisted-input .indicator .bubble {
  background: rgba(0, 0, 0, 0.38);
  color: white;
}
.asisted-input .indicator .guide {
  border-color: rgba(0, 0, 0, 0.12);
}
.asisted-input.icon-only .bubble {
  color: rgba(0, 0, 0, 0.54);
}
.asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble {
  color: #3f51b5;
}
.asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble {
  color: #f44336;
}

.UI-V2 .main-overlay {
  background: rgba(0, 0, 0, 0.32);
}
.UI-V2 .icon-complex mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.UI-V2 .icon-complex mat-icon.complex {
  color: #3f51b5 !important;
}
.UI-V2 .navigation {
  background: #fafafa;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.UI-V2 .navigation *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.UI-V2 .navigation *::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}
.UI-V2 .navigation .load-window {
  background: #eeeeee;
}
.UI-V2 .navigation .toolbar {
  background: #3f51b5;
  color: white;
}
.UI-V2 .navigation .toolbar.reverse {
  background: white;
  color: rgba(0, 0, 0, 0.54);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.UI-V2 .navigation .container .helper {
  color: rgba(0, 0, 0, 0.54);
}
.UI-V2 .navigation .container .item {
  background: white;
  color: rgba(0, 0, 0, 0.54);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.UI-V2 .navigation .container .item:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.UI-V2 .navigation .container .item.active {
  background: #3f51b5 !important;
  color: white;
  border: 1px solid #3f51b5;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .separator .subtitle,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .separator .subtitle,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .separator .subtitle,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .separator .subtitle,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .separator .subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .img-editior-container .img-editor .separator .subtitle,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .img-editior-container .img-editor .separator .subtitle,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .img-editior-container .img-editor .separator .subtitle,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .img-editior-container .img-editor .separator .subtitle,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .img-editior-container .img-editor .separator .subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .main-color-container .main-color,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .main-color-container .main-color,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .main-color-container .main-color,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .main-color-container .main-color,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .main-color-container .main-color {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .main-color-container .main-color:before,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .main-color-container .main-color:before,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .main-color-container .main-color:before,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .main-color-container .main-color:before,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .main-color-container .main-color:before {
  border-left: 10px solid rgba(0, 0, 0, 0);
  border-right: 10px solid rgba(0, 0, 0, 0);
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example h6.required,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example h6.required,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example h6.required,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example h6.required,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example h6.required {
  color: #f44336 !important;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle {
  border: 2px solid rgba(0, 0, 0, 0);
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .side-color-container .side-color,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .side-color-container .side-color,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .side-color-container .side-color,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .side-color-container .side-color,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .side-color-container .side-color {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .side-color-container .side-color:before,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .side-color-container .side-color:before,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .side-color-container .side-color:before,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .side-color-container .side-color:before,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .side-color-container .side-color:before {
  border-top: 10px solid rgba(0, 0, 0, 0);
  border-bottom: 10px solid rgba(0, 0, 0, 0);
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .side-color-container .side-color h6.required,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .side-color-container .side-color h6.required,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .side-color-container .side-color h6.required,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .side-color-container .side-color h6.required,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .side-color-container .side-color h6.required {
  color: #f44336 !important;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .permission-settings-cards .card,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .permission-settings-cards .card,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .permission-settings-cards .card,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .permission-settings-cards .card,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .permission-settings-cards .card {
  background: #fafafa;
}
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .permission-settings-cards .card .slide-sub-group,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .permission-settings-cards .card .slide-sub-group,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .permission-settings-cards .card .slide-sub-group,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .permission-settings-cards .card .slide-sub-group,
.UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .permission-settings-cards .card .slide-sub-group {
  background: rgba(0, 0, 0, 0.12);
}
.UI-V2 .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.UI-V2 .work-space .main .load-window {
  background: white !important;
}
.UI-V2 .work-space .main.side-open {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item mat-icon.complex {
  text-shadow: -2px 0 0px #eeeeee, 0 -2px 0px #eeeeee, 2px 0 0px #eeeeee, 0 2px 0px #eeeeee, -2px -2px 0px #eeeeee, 2px 2px 0px #eeeeee, 2px -2px 0px #eeeeee, -2px 2px 0px #eeeeee !important;
}
.UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item h5 {
  color: rgba(0, 0, 0, 0.38);
}
.UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item h3 {
  color: rgba(0, 0, 0, 0.87);
}
.UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item .actions {
  pointer-events: none;
}
.UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item .actions button {
  pointer-events: all;
}
.UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled {
  background: #eeeeee;
}
.UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled h3 {
  color: #607D8B !important;
}
.UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled mat-icon {
  color: #607D8B !important;
}
.UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled.selected {
  background: #607D8B;
  color: white !important;
}
.UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled.selected h3 {
  color: white !important;
}
.UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled.selected mat-icon {
  color: white !important;
}
.UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled.selected mat-icon.complex {
  color: white !important;
  text-shadow: -2px 0 0px #607D8B, 0 -2px 0px #607D8B, 2px 0 0px #607D8B, 0 2px 0px #607D8B, -2px -2px 0px #607D8B, 2px 2px 0px #607D8B, 2px -2px 0px #607D8B, -2px 2px 0px #607D8B !important;
}
.UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item:hover {
  background: rgba(0, 0, 0, 0.04);
}
.UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected {
  background: #3f51b5;
  color: white;
}
.UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected mat-icon {
  color: white;
}
.UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected mat-icon.complex {
  color: white !important;
  text-shadow: -2px 0 0px #3f51b5, 0 -2px 0px #3f51b5, 2px 0 0px #3f51b5, 0 2px 0px #3f51b5, -2px -2px 0px #3f51b5, 2px 2px 0px #3f51b5, 2px -2px 0px #3f51b5, -2px 2px 0px #3f51b5 !important;
}
.UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected h5,
.UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected h3 {
  color: white;
}
.UI-V2 .work-space .main .main-action {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.UI-V2 .work-space .side {
  background: #fafafa;
}
.UI-V2 .work-space .side .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #fafafa, 0 -2px 0px #fafafa, 2px 0 0px #fafafa, 0 2px 0px #fafafa, -2px -2px 0px #fafafa, 2px 2px 0px #fafafa, 2px -2px 0px #fafafa, -2px 2px 0px #fafafa;
}
.UI-V2 .work-space .side *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.UI-V2 .work-space .side *::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}
.UI-V2 .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #fafafa !important;
}
.UI-V2 .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(0, 0, 0, 0.38);
}
.UI-V2 .work-space .side mat-form-field .mat-form-field-flex {
  background: #fafafa;
}
.UI-V2 .work-space .side mat-checkbox:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.UI-V2 .work-space .side .hint.all {
  color: #e91e63;
}
.UI-V2 .work-space .side .toolbar .header {
  color: #3f51b5;
}
.UI-V2 .work-space .side .toolbar .header h3 {
  color: #3f51b5;
}
.UI-V2 .work-space .side .container .role-resume {
  background: #3f51b5;
}
.UI-V2 .work-space .side .container .role-resume .line {
  color: white;
}
.UI-V2 .work-space .side .container .role-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.1);
}
.UI-V2 .work-space .side .container .role-resume .line.editable button {
  background: #3f51b5;
}
.UI-V2 .work-space .side .container .section-title {
  color: #3f51b5;
}
.UI-V2 .work-space .side .container .section-resume {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.UI-V2 .work-space .side .container .section-resume .line.editable:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.UI-V2 .work-space .side .container .section-resume .line.editable:hover .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px white, 0 -2px 0px white, 2px 0 0px white, 0 2px 0px white, -2px -2px 0px white, 2px 2px 0px white, 2px -2px 0px white, -2px 2px 0px white !important;
}
.UI-V2 .work-space .side .container .section-resume .line.editable button {
  background: #fafafa;
}
.UI-V2 .work-space .side .container .section-resume .line .header .icon-complex mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.UI-V2 .work-space .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #3f51b5 !important;
}
.UI-V2 .work-space .side .container .section-resume .line .header mat-icon:not(.complex) {
  color: rgba(0, 0, 0, 0.54);
}
.UI-V2 .work-space .side .container .section-resume .line .header .info h4 {
  color: #3f51b5;
}
.UI-V2 .work-space .side .container .section-resume .line ul {
  color: #3f51b5;
}
.UI-V2 .work-space .side .container .section-resume .line.head {
  background: #eeeeee;
}
.UI-V2 .work-space .side .container .section-resume .line.head mat-icon {
  color: #e91e63;
}
.UI-V2 .work-space .side .container .section-resume .line.head h4 {
  color: white;
}
.UI-V2 .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body {
  background: white;
}
.UI-V2 .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: rgba(0, 0, 0, 0.54);
}
.UI-V2 .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #3f51b5;
}
.UI-V2 .work-space .side.edition .toolbar .header {
  color: white;
}
.UI-V2 .work-space .side.edition .toolbar .header mat-icon {
  color: #e91e63;
}
.UI-V2 .work-space .side.edition .toolbar .header h3 {
  color: #e91e63;
}
.UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body {
  background: white !important;
}
.UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header mat-icon:not(.complex) {
  color: #3f51b5;
}
.UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header h4 {
  color: #3f51b5;
}
.UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-actions {
  background: #fafafa;
}
.UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header .icon-complex mat-icon.complex {
  color: #e91e63 !important;
}
.UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header h4 {
  color: #e91e63;
}
.UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line ul {
  color: #e91e63;
  font-weight: 400;
}
.UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head mat-icon {
  color: #e91e63 !important;
}
.UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head h4 {
  color: rgba(0, 0, 0, 0.87);
}
.UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .section-title {
  color: rgba(0, 0, 0, 0.87) !important;
}
.UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume h6.hint {
  background: #f8bbd0;
}

app-role-user-find .main-overlay {
  background: rgba(0, 0, 0, 0.32);
}
app-role-user-find .icon-complex mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
app-role-user-find .icon-complex mat-icon.complex {
  color: #3f51b5 !important;
}
app-role-user-find .navigation {
  background: #fafafa;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
app-role-user-find .navigation *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
app-role-user-find .navigation *::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}
app-role-user-find .navigation .load-window {
  background: #eeeeee;
}
app-role-user-find .navigation .toolbar {
  background: #3f51b5;
  color: white;
}
app-role-user-find .navigation .toolbar.reverse {
  background: white;
  color: rgba(0, 0, 0, 0.54);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
app-role-user-find .navigation .container .helper {
  color: rgba(0, 0, 0, 0.54);
}
app-role-user-find .navigation .container .item {
  background: white;
  color: rgba(0, 0, 0, 0.54);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
app-role-user-find .navigation .container .item:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
app-role-user-find .navigation .container .item.active {
  background: #3f51b5 !important;
  color: white;
  border: 1px solid #3f51b5;
}
app-role-user-find .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
app-role-user-find .work-space .main .load-window {
  background: white !important;
}
app-role-user-find .work-space .main.side-open {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
app-role-user-find .work-space .main .container .item {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
app-role-user-find .work-space .main .container .item .subtitle {
  color: rgba(0, 0, 0, 0.54);
}
app-role-user-find .work-space .main .container .item.disabled {
  background: #eeeeee;
  color: #607D8B !important;
}
app-role-user-find .work-space .main .container .item.disabled h3 {
  color: #607D8B !important;
}
app-role-user-find .work-space .main .container .item.disabled mat-icon {
  color: #607D8B !important;
}
app-role-user-find .work-space .main .container .item.disabled.selected {
  background: #607D8B;
  color: white !important;
}
app-role-user-find .work-space .main .container .item.disabled.selected h3 {
  color: white !important;
}
app-role-user-find .work-space .main .container .item.disabled.selected mat-icon {
  color: white !important;
}
app-role-user-find .work-space .main .container .item.disabled.selected mat-icon.complex {
  color: white !important;
  text-shadow: -2px 0 0px #607D8B, 0 -2px 0px #607D8B, 2px 0 0px #607D8B, 0 2px 0px #607D8B, -2px -2px 0px #607D8B, 2px 2px 0px #607D8B, 2px -2px 0px #607D8B, -2px 2px 0px #607D8B !important;
}
app-role-user-find .work-space .main .container .item mat-icon.complex {
  text-shadow: -2px 0 0px #eeeeee, 0 -2px 0px #eeeeee, 2px 0 0px #eeeeee, 0 2px 0px #eeeeee, -2px -2px 0px #eeeeee, 2px 2px 0px #eeeeee, 2px -2px 0px #eeeeee, -2px 2px 0px #eeeeee !important;
}
app-role-user-find .work-space .main .container .item h5 {
  color: rgba(0, 0, 0, 0.38);
}
app-role-user-find .work-space .main .container .item h3 {
  color: rgba(0, 0, 0, 0.87);
}
app-role-user-find .work-space .main .container .item:hover {
  background: rgba(0, 0, 0, 0.04);
}
app-role-user-find .work-space .main .container .item.selected {
  background: #3f51b5;
  color: white;
}
app-role-user-find .work-space .main .container .item.selected mat-icon {
  color: white;
}
app-role-user-find .work-space .main .container .item.selected mat-icon.complex {
  color: white !important;
  text-shadow: -2px 0 0px #3f51b5, 0 -2px 0px #3f51b5, 2px 0 0px #3f51b5, 0 2px 0px #3f51b5, -2px -2px 0px #3f51b5, 2px 2px 0px #3f51b5, 2px -2px 0px #3f51b5, -2px 2px 0px #3f51b5 !important;
}
app-role-user-find .work-space .main .container .item.selected h5,
app-role-user-find .work-space .main .container .item.selected h3 {
  color: white;
}
app-role-user-find .work-space .main .main-action {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
app-role-user-find .work-space .side {
  background: #fafafa;
}
app-role-user-find .work-space .side .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #fafafa, 0 -2px 0px #fafafa, 2px 0 0px #fafafa, 0 2px 0px #fafafa, -2px -2px 0px #fafafa, 2px 2px 0px #fafafa, 2px -2px 0px #fafafa, -2px 2px 0px #fafafa;
}
app-role-user-find .work-space .side *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
app-role-user-find .work-space .side *::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}
app-role-user-find .work-space .side .edition-line {
  background: #eeeeee;
}
app-role-user-find .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #fafafa !important;
}
app-role-user-find .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(0, 0, 0, 0.38);
}
app-role-user-find .work-space .side mat-form-field .mat-form-field-flex {
  background: #fafafa;
}
app-role-user-find .work-space .side mat-checkbox:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
app-role-user-find .work-space .side .hint.all {
  color: #e91e63;
}
app-role-user-find .work-space .side .toolbar .header {
  color: #3f51b5;
}
app-role-user-find .work-space .side .toolbar .header h3 {
  color: #3f51b5;
}
app-role-user-find .work-space .side .container .role-resume {
  background: #3f51b5;
}
app-role-user-find .work-space .side .container .role-resume .line {
  color: white;
}
app-role-user-find .work-space .side .container .role-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.1);
}
app-role-user-find .work-space .side .container .role-resume .line.editable button {
  background: #3f51b5;
}
app-role-user-find .work-space .side .container .section-title {
  color: #3f51b5;
}
app-role-user-find .work-space .side .container .section-resume {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
app-role-user-find .work-space .side .container .section-resume .line.editable:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
app-role-user-find .work-space .side .container .section-resume .line.editable:hover .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px white, 0 -2px 0px white, 2px 0 0px white, 0 2px 0px white, -2px -2px 0px white, 2px 2px 0px white, 2px -2px 0px white, -2px 2px 0px white !important;
}
app-role-user-find .work-space .side .container .section-resume .line.editable button {
  background: #fafafa;
}
app-role-user-find .work-space .side .container .section-resume .line .header .icon-complex mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
app-role-user-find .work-space .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #3f51b5 !important;
}
app-role-user-find .work-space .side .container .section-resume .line .header mat-icon:not(.complex) {
  color: rgba(0, 0, 0, 0.54);
}
app-role-user-find .work-space .side .container .section-resume .line .header .info h4 {
  color: #3f51b5;
}
app-role-user-find .work-space .side .container .section-resume .line ul {
  color: #3f51b5;
}
app-role-user-find .work-space .side .container .section-resume .line.head {
  background: #eeeeee;
}
app-role-user-find .work-space .side .container .section-resume .line.head mat-icon {
  color: #e91e63;
}
app-role-user-find .work-space .side .container .section-resume .line.head h4 {
  color: white;
}
app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee-title {
  color: #3f51b5;
}
app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body {
  background: white;
}
app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: rgba(0, 0, 0, 0.54);
}
app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #3f51b5;
}
app-role-user-find .work-space .side.edition .toolbar .header {
  color: white;
}
app-role-user-find .work-space .side.edition .toolbar .header mat-icon {
  color: #e91e63;
}
app-role-user-find .work-space .side.edition .toolbar .header h3 {
  color: #e91e63;
}
app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body {
  background: white !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header mat-icon:not(.complex) {
  color: #3f51b5;
}
app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header h4 {
  color: #3f51b5;
}
app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-actions {
  background: #fafafa;
}
app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header .icon-complex mat-icon.complex {
  color: #e91e63 !important;
}
app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header h4 {
  color: #e91e63;
}
app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line ul {
  color: #e91e63;
}
app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head mat-icon {
  color: #e91e63 !important;
}
app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head h4 {
  color: rgba(0, 0, 0, 0.87);
}
app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .section-title {
  color: rgba(0, 0, 0, 0.87) !important;
}
app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume h6.hint {
  background: #f8bbd0;
}
app-role-user-find .work-space app-role-user-detail.disabled .side .toolbar .header {
  color: #607D8B !important;
}
app-role-user-find .work-space app-role-user-detail.disabled .side .container .role-resume {
  background: #607D8B !important;
}
app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-title {
  color: #607D8B !important;
}
app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line ul {
  color: #607D8B !important;
}
app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header mat-icon {
  color: #607D8B;
}
app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .icon-complex mat-icon {
  color: #607D8B !important;
}
app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #607D8B !important;
}
app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .info h4 {
  color: #607D8B !important;
}
app-role-user-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee-title {
  color: #607D8B;
}
app-role-user-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: #607D8B;
}
app-role-user-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #607D8B;
}

app-actor-find .main-overlay {
  background: rgba(0, 0, 0, 0.32);
}
app-actor-find .icon-complex mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
app-actor-find .icon-complex mat-icon.complex {
  color: #3f51b5 !important;
}
app-actor-find .navigation {
  background: #fafafa;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
app-actor-find .navigation *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
app-actor-find .navigation *::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}
app-actor-find .navigation .load-window {
  background: #eeeeee;
}
app-actor-find .navigation .toolbar {
  background: #3f51b5;
  color: white;
}
app-actor-find .navigation .toolbar.reverse {
  background: white;
  color: rgba(0, 0, 0, 0.54);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
app-actor-find .navigation .container .helper {
  color: rgba(0, 0, 0, 0.54);
}
app-actor-find .navigation .container .item {
  background: white;
  color: rgba(0, 0, 0, 0.54);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
app-actor-find .navigation .container .item:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
app-actor-find .navigation .container .item.active {
  background: #3f51b5 !important;
  color: white;
  border: 1px solid #3f51b5;
}
app-actor-find .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
app-actor-find .work-space .main .load-window {
  background: white !important;
}
app-actor-find .work-space .main.side-open {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
app-actor-find .work-space .main .container .item {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
app-actor-find .work-space .main .container .item .subtitle {
  color: rgba(0, 0, 0, 0.54);
}
app-actor-find .work-space .main .container .item.disabled {
  background: #eeeeee;
  color: #607D8B !important;
}
app-actor-find .work-space .main .container .item.disabled h3 {
  color: #607D8B !important;
}
app-actor-find .work-space .main .container .item.disabled mat-icon {
  color: #607D8B !important;
}
app-actor-find .work-space .main .container .item.disabled.selected {
  background: #607D8B;
  color: white !important;
}
app-actor-find .work-space .main .container .item.disabled.selected h3 {
  color: white !important;
}
app-actor-find .work-space .main .container .item.disabled.selected mat-icon {
  color: white !important;
}
app-actor-find .work-space .main .container .item.disabled.selected mat-icon.complex {
  color: white !important;
  text-shadow: -2px 0 0px #607D8B, 0 -2px 0px #607D8B, 2px 0 0px #607D8B, 0 2px 0px #607D8B, -2px -2px 0px #607D8B, 2px 2px 0px #607D8B, 2px -2px 0px #607D8B, -2px 2px 0px #607D8B !important;
}
app-actor-find .work-space .main .container .item mat-icon.complex {
  text-shadow: -2px 0 0px #eeeeee, 0 -2px 0px #eeeeee, 2px 0 0px #eeeeee, 0 2px 0px #eeeeee, -2px -2px 0px #eeeeee, 2px 2px 0px #eeeeee, 2px -2px 0px #eeeeee, -2px 2px 0px #eeeeee !important;
}
app-actor-find .work-space .main .container .item h5 {
  color: rgba(0, 0, 0, 0.38);
}
app-actor-find .work-space .main .container .item h3 {
  color: rgba(0, 0, 0, 0.87);
}
app-actor-find .work-space .main .container .item:hover {
  background: rgba(0, 0, 0, 0.04);
}
app-actor-find .work-space .main .container .item.selected {
  background: #3f51b5;
  color: white;
}
app-actor-find .work-space .main .container .item.selected mat-icon {
  color: white;
}
app-actor-find .work-space .main .container .item.selected mat-icon.complex {
  color: white !important;
  text-shadow: -2px 0 0px #3f51b5, 0 -2px 0px #3f51b5, 2px 0 0px #3f51b5, 0 2px 0px #3f51b5, -2px -2px 0px #3f51b5, 2px 2px 0px #3f51b5, 2px -2px 0px #3f51b5, -2px 2px 0px #3f51b5 !important;
}
app-actor-find .work-space .main .container .item.selected h5,
app-actor-find .work-space .main .container .item.selected h3 {
  color: white;
}
app-actor-find .work-space .main .main-action {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
app-actor-find .work-space .side {
  background: #fafafa;
}
app-actor-find .work-space .side .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #fafafa, 0 -2px 0px #fafafa, 2px 0 0px #fafafa, 0 2px 0px #fafafa, -2px -2px 0px #fafafa, 2px 2px 0px #fafafa, 2px -2px 0px #fafafa, -2px 2px 0px #fafafa;
}
app-actor-find .work-space .side *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
app-actor-find .work-space .side *::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}
app-actor-find .work-space .side .edition-line {
  background: #eeeeee;
}
app-actor-find .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #fafafa !important;
}
app-actor-find .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(0, 0, 0, 0.38);
}
app-actor-find .work-space .side mat-form-field .mat-form-field-flex {
  background: #fafafa;
}
app-actor-find .work-space .side mat-checkbox:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
app-actor-find .work-space .side .hint.all {
  color: #e91e63;
}
app-actor-find .work-space .side .toolbar .header {
  color: #3f51b5;
}
app-actor-find .work-space .side .toolbar .header h3 {
  color: #3f51b5;
}
app-actor-find .work-space .side .container .role-resume {
  background: #3f51b5;
}
app-actor-find .work-space .side .container .role-resume .line {
  color: white;
}
app-actor-find .work-space .side .container .role-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.1);
}
app-actor-find .work-space .side .container .role-resume .line.editable button {
  background: #3f51b5;
}
app-actor-find .work-space .side .container .section-title {
  color: #3f51b5;
}
app-actor-find .work-space .side .container .section-resume {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
app-actor-find .work-space .side .container .section-resume .line.editable:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
app-actor-find .work-space .side .container .section-resume .line.editable:hover .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px white, 0 -2px 0px white, 2px 0 0px white, 0 2px 0px white, -2px -2px 0px white, 2px 2px 0px white, 2px -2px 0px white, -2px 2px 0px white !important;
}
app-actor-find .work-space .side .container .section-resume .line.editable button {
  background: #fafafa;
}
app-actor-find .work-space .side .container .section-resume .line .header .icon-complex mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
app-actor-find .work-space .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #3f51b5 !important;
}
app-actor-find .work-space .side .container .section-resume .line .header mat-icon:not(.complex) {
  color: rgba(0, 0, 0, 0.54);
}
app-actor-find .work-space .side .container .section-resume .line .header .info h4 {
  color: #3f51b5;
}
app-actor-find .work-space .side .container .section-resume .line ul {
  color: #3f51b5;
}
app-actor-find .work-space .side .container .section-resume .line.head {
  background: #eeeeee;
}
app-actor-find .work-space .side .container .section-resume .line.head mat-icon {
  color: #e91e63;
}
app-actor-find .work-space .side .container .section-resume .line.head h4 {
  color: white;
}
app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee-title {
  color: #3f51b5;
}
app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body {
  background: white;
}
app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: rgba(0, 0, 0, 0.54);
}
app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #3f51b5;
}
app-actor-find .work-space .side.edition .toolbar .header {
  color: white;
}
app-actor-find .work-space .side.edition .toolbar .header mat-icon {
  color: #e91e63;
}
app-actor-find .work-space .side.edition .toolbar .header h3 {
  color: #e91e63;
}
app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body {
  background: white !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header mat-icon:not(.complex) {
  color: #3f51b5;
}
app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header h4 {
  color: #3f51b5;
}
app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-actions {
  background: #fafafa;
}
app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header .icon-complex mat-icon.complex {
  color: #e91e63 !important;
}
app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header h4 {
  color: #e91e63;
}
app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line ul {
  color: #e91e63;
}
app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head mat-icon {
  color: #e91e63 !important;
}
app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head h4 {
  color: rgba(0, 0, 0, 0.87);
}
app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .section-title {
  color: rgba(0, 0, 0, 0.87) !important;
}
app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume h6.hint {
  background: #f8bbd0;
}
app-actor-find .work-space app-role-user-detail.disabled .side .toolbar .header {
  color: #607D8B !important;
}
app-actor-find .work-space app-role-user-detail.disabled .side .container .role-resume {
  background: #607D8B !important;
}
app-actor-find .work-space app-role-user-detail.disabled .side .container .section-title {
  color: #607D8B !important;
}
app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line ul {
  color: #607D8B !important;
}
app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header mat-icon {
  color: #607D8B;
}
app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .icon-complex mat-icon {
  color: #607D8B !important;
}
app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #607D8B !important;
}
app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .info h4 {
  color: #607D8B !important;
}
app-actor-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee-title {
  color: #607D8B;
}
app-actor-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: #607D8B;
}
app-actor-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #607D8B;
}

.dropzone {
  border: 2px dashed #607D8B;
  background: #eeeeee !important;
}
.dropzone.hovering {
  border: 2px solid #607D8B;
  color: rgba(0, 0, 0, 0.38) !important;
}
.dropzone button.mat-stroked-button {
  background: white;
}

.firmador .content-action-main {
  color: white !important;
}
.firmador .content-action-main.disabled {
  color: rgba(0, 0, 0, 0.38) !important;
  background: rgba(0, 0, 0, 0.12);
}

.sign-cycle {
  background: white;
}
.sign-cycle button.success {
  background-color: #009688;
}
.sign-cycle button.warning {
  background-color: #673AB7;
}
.sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-title-container .cycle-content-title h4 {
  color: #607D8B;
}
.sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-action .content-action-main {
  color: white !important;
}
.sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-action .content-action-main.mat-stroked-button:disabled {
  color: #9e9e9e !important;
}
.sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-action .content-action-main.mat-stroked-button:not(:disabled) {
  color: #e91e63 !important;
}
.sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-action .content-action-side u {
  color: #9e9e9e;
}

.resume-status {
  background: white;
}

.help-hoverable mat-icon {
  color: rgba(0, 0, 0, 0.54);
}
.help-hoverable mat-hint {
  background: white;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.help-hoverable mat-hint b {
  color: #3f51b5 !important;
}

app-file-document-sign-massive-dialog .dialog-content,
app-share-person-dialog .dialog-content {
  background: #fafafa;
}
app-file-document-sign-massive-dialog .dialog-content mat-toolbar mat-toolbar-row .mat-avatar h3,
app-share-person-dialog .dialog-content mat-toolbar mat-toolbar-row .mat-avatar h3 {
  color: #3f51b5;
}
app-file-document-sign-massive-dialog .dialog-content .dialog-body p b,
app-share-person-dialog .dialog-content .dialog-body p b {
  color: #e91e63;
}
app-file-document-sign-massive-dialog .dialog-content app-file-document-sign,
app-share-person-dialog .dialog-content app-file-document-sign {
  background: white;
}

.mat-basic-chip {
  background: #eeeeee !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.mat-basic-chip.seen {
  border-color: #2196F3 !important;
  color: #2196F3 !important;
}
.mat-basic-chip.unseen {
  border-color: #607D8B !important;
  color: #607D8B !important;
}
.mat-basic-chip.signed-ok {
  border-color: #009688 !important;
  color: #009688 !important;
}
.mat-basic-chip.signed-ok.cancelled {
  border-color: #795548 !important;
  color: #795548 !important;
}
.mat-basic-chip.signed-not-ok {
  border-color: #673AB7 !important;
  color: #673AB7 !important;
}
.mat-basic-chip.not-signed {
  border-color: #ff4081 !important;
  color: #ff4081 !important;
}
.mat-basic-chip.not-signed-employer {
  border-color: #ff4081 !important;
  color: #ff4081 !important;
}

app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip {
  background: #eeeeee !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.seen {
  border-color: #2196F3 !important;
  color: #2196F3 !important;
}
app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.unseen {
  border-color: #607D8B !important;
  color: #607D8B !important;
}
app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.signed-ok {
  border-color: #009688 !important;
  color: #009688 !important;
}
app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.signed-ok.cancelled {
  border-color: #795548 !important;
  color: #795548 !important;
}
app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.signed-not-ok {
  border-color: #673AB7 !important;
  color: #673AB7 !important;
}
app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.not-signed {
  border-color: #ff4081 !important;
  color: #ff4081 !important;
}
app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.not-signed-employer {
  border-color: #ff4081 !important;
  color: #ff4081 !important;
}

.inbox-container.employee.my-documents .chip-line.mat-line .mat-chip-list-wrapper .mat-basic-chip.seen {
  color: #607D8B !important;
}

.resume-status .mat-basic-chip {
  background: #eeeeee !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.resume-status .mat-basic-chip.signed-ok {
  border-color: #009688 !important;
  background-color: #009688 !important;
  color: white !important;
}
.resume-status .mat-basic-chip.signed-ok.cancelled {
  border-color: #795548 !important;
  background-color: #795548 !important;
  color: white !important;
}
.resume-status .mat-basic-chip.signed-not-ok {
  border-color: #673AB7 !important;
  background-color: #673AB7 !important;
  color: white !important;
}

app-file-document-view .new-container app-file-document-upload {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
app-file-document-view .new-container app-upload-form .file-upload-list .mat-subheader {
  background: white;
}
app-file-document-view .new-container app-upload-form .file-upload-list mat-list-item {
  background: #fafafa !important;
}
app-file-document-view .preview-content {
  background: #607D8B;
}
app-file-document-view .preview-content h4 {
  color: white;
}
app-file-document-view .view-container {
  background: white;
}
app-file-document-view .view-container mat-toolbar.side-bar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.formio-hint {
  background: #3f51b5;
  color: white;
}

mat-dialog-container app-file-document-state-chips {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

app-candidate-promotion .load-window.message h3.primary-text {
  color: #3f51b5;
}

app-multiple-documentation-add-dialog .icon-complex mat-icon,
app-documentation-type-set-configuration-dialog .icon-complex mat-icon,
app-add-candidate-dialog .icon-complex mat-icon,
app-share-person-dialog .icon-complex mat-icon,
app-share-person-response-dialog .icon-complex mat-icon {
  color: rgba(0, 0, 0, 0.87) !important;
}
app-multiple-documentation-add-dialog .icon-complex mat-icon.complex,
app-documentation-type-set-configuration-dialog .icon-complex mat-icon.complex,
app-add-candidate-dialog .icon-complex mat-icon.complex,
app-share-person-dialog .icon-complex mat-icon.complex,
app-share-person-response-dialog .icon-complex mat-icon.complex {
  color: #3f51b5 !important;
}
app-multiple-documentation-add-dialog .icon-complex mat-icon.complex.mat-accent,
app-documentation-type-set-configuration-dialog .icon-complex mat-icon.complex.mat-accent,
app-add-candidate-dialog .icon-complex mat-icon.complex.mat-accent,
app-share-person-dialog .icon-complex mat-icon.complex.mat-accent,
app-share-person-response-dialog .icon-complex mat-icon.complex.mat-accent {
  color: #e91e63;
}
app-multiple-documentation-add-dialog .icon-complex mat-icon.complex.side,
app-documentation-type-set-configuration-dialog .icon-complex mat-icon.complex.side,
app-add-candidate-dialog .icon-complex mat-icon.complex.side,
app-share-person-dialog .icon-complex mat-icon.complex.side,
app-share-person-response-dialog .icon-complex mat-icon.complex.side {
  color: rgba(0, 0, 0, 0.54);
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection,
app-share-person-dialog .dialog-content .document-template-container .document-selection,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection {
  background: #fafafa;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3,
app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3 {
  color: #e91e63 !important;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon,
app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon {
  color: #3f51b5;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3,
app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3 {
  color: #3f51b5;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex,
app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex {
  color: #e91e63 !important;
  text-shadow: -2px 0 0px #eeeeee, 0 -2px 0px #eeeeee, 2px 0 0px #eeeeee, 0 2px 0px #eeeeee, -2px -2px 0px #eeeeee, 2px 2px 0px #eeeeee, 2px -2px 0px #eeeeee, -2px 2px 0px white;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex,
app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex {
  color: rgba(0, 0, 0, 0.38) !important;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b,
app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b {
  color: rgba(0, 0, 0, 0.87);
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex,
app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex {
  color: rgba(0, 0, 0, 0.38) !important;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex,
app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex {
  color: rgba(0, 0, 0, 0.38) !important;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover,
app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover {
  background: rgba(0, 0, 0, 0.04);
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b,
app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b {
  color: rgba(0, 0, 0, 0.87);
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble {
  background: #3f51b5;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble,
app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble {
  background: #f44336;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble,
app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble {
  color: rgba(0, 0, 0, 0.38);
  background: rgba(0, 0, 0, 0.12);
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble,
app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble {
  background: rgba(0, 0, 0, 0.38);
  color: white;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide,
app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide {
  border-color: rgba(0, 0, 0, 0.12);
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble,
app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble {
  color: rgba(0, 0, 0, 0.54);
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble {
  color: #3f51b5;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble,
app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble {
  color: #f44336;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar,
app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar {
  background: #607D8B;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row,
app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row {
  color: white;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper,
app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper,
app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper,
app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper {
  background: #607D8B;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop,
app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop,
app-share-person-dialog .dialog-content .document-template-container .add-docs-drop,
app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body,
app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body,
app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body,
app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3,
app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3,
app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3,
app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3 {
  color: #e91e63;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5,
app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5,
app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5,
app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5 {
  color: rgba(0, 0, 0, 0.54);
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5,
app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5,
app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5,
app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5 {
  color: rgba(0, 0, 0, 0.54);
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator,
app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator,
app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator,
app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background: #fafafa;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip,
app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip,
app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip,
app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip {
  background: white !important;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon,
app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon,
app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon,
app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon {
  color: #e91e63 !important;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3,
app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3,
app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3,
app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3 {
  color: #e91e63 !important;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5,
app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5,
app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5,
app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5 {
  color: rgba(0, 0, 0, 0.54) !important;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon,
app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon,
app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon,
app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon {
  color: #3f51b5 !important;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3,
app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3,
app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3,
app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3 {
  color: #3f51b5 !important;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5,
app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5,
app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5,
app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5 {
  color: #3f51b5 !important;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled,
app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled,
app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled,
app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled {
  background: #fafafa !important;
  color: rgba(0, 0, 0, 0.38);
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove,
app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove,
app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove,
app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove {
  color: rgba(0, 0, 0, 0.54);
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove,
app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove,
app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove,
app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove {
  color: rgba(0, 0, 0, 0.54) !important;
}
app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .preview-action,
app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .preview-action,
app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .preview-action,
app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .preview-action,
app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .preview-action {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.tyc .sticky {
  background: white;
}

.cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper:nth-child(odd) datatable-body-row {
  background: #fafafa;
}
.cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active * {
  color: white !important;
}
.cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active:hover .datatable-body-cell-label {
  background: #3f51b5;
}
.cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active:hover .cs-floating-action {
  background: #3f51b5 !important;
}
.cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active.row-cancel * {
  color: rgba(0, 0, 0, 0.54) !important;
}
.cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active.row-cancel .datatable-row-group {
  background: #607D8B !important;
}
.cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active.row-cancel:hover .datatable-body-cell-label {
  background: #607D8B;
}
.cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.row-cancel * {
  color: #b0bec5 !important;
  transition-property: color;
  transition-duration: 0.3s;
  transition-timing-function: linear;
}
.cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row:hover .datatable-body-cell-label button {
  background: #eeeeee;
}
.cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row datatable-body-cell.candidate-status_bar mat-progress-bar .mat-progress-bar-buffer {
  background-color: #eeeeee;
}
.cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row datatable-body-cell.candidate-status_bar .status_bar-count {
  color: rgba(0, 0, 0, 0.54);
}
.cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row datatable-body-cell.candidate-status_bar .inactive {
  color: rgba(0, 0, 0, 0.54);
}
.cs-grid-test .info {
  background: white;
}
.cs-grid-test .selected-column {
  background: white;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
}
.cs-grid-test .selection-cell .datatable-body-cell.active {
  background: #3f51b5 !important;
  color: white !important;
}
.cs-grid-test nav {
  background: white;
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 13px 19px 2px rgba(0, 0, 0, 0.14), 0 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.cs-grid-test .ngx-datatable.material {
  background: white;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.cs-grid-test .ngx-datatable.material.striped .datatable-row-odd {
  background: #fafafa;
}
.cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active, .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active .datatable-row-group, .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active, .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active .datatable-row-group, .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active, .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active .datatable-row-group {
  background: #3f51b5;
  color: white;
}
.cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active:hover, .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active:hover .datatable-row-group, .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active:hover, .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active:hover .datatable-row-group, .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active:hover, .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active:hover .datatable-row-group {
  background: #3f51b5;
  color: white;
}
.cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active:focus, .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active:focus .datatable-row-group, .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active:focus, .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active:focus .datatable-row-group, .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active:focus, .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active:focus .datatable-row-group {
  background: #3f51b5;
  color: white;
}
.cs-grid-test .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover, .cs-grid-test .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: rgba(0, 0, 0, 0.04);
}
.cs-grid-test .ngx-datatable.material:not(.cell-selection) .datatable-body-row:focus, .cs-grid-test .ngx-datatable.material:not(.cell-selection) .datatable-body-row:focus .datatable-row-group {
  background: rgba(0, 0, 0, 0.04);
}
.cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell:hover, .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell:hover .datatable-row-group {
  background: rgba(0, 0, 0, 0.04);
}
.cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell:focus, .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell:focus .datatable-row-group {
  background: rgba(0, 0, 0, 0.04);
}
.cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active, .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active .datatable-row-group {
  background: #3f51b5;
  color: white;
}
.cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active:hover, .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active:hover .datatable-row-group {
  background: #3f51b5;
  color: white;
}
.cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active:focus, .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active:focus .datatable-row-group {
  background: #3f51b5;
  color: white;
}
.cs-grid-test .ngx-datatable.material .datatable-header .datatable-row-left,
.cs-grid-test .ngx-datatable.material .datatable-body .datatable-row-left {
  background-color: white;
}
.cs-grid-test .ngx-datatable.material .datatable-header .datatable-row-right,
.cs-grid-test .ngx-datatable.material .datatable-body .datatable-row-right {
  background-color: white;
}
.cs-grid-test .ngx-datatable.material .datatable-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.38);
}
.cs-grid-test .ngx-datatable.material .datatable-header .datatable-header-cell {
  background-color: white;
  color: rgba(0, 0, 0, 0.54);
}
.cs-grid-test .ngx-datatable.material .datatable-header .resize-handle {
  border-right: solid 1px rgba(0, 0, 0, 0.04);
}
.cs-grid-test .ngx-datatable.material .datatable-body .datatable-row-detail {
  background: #eeeeee;
}
.cs-grid-test .ngx-datatable.material .datatable-body .datatable-group-header {
  background: #eeeeee;
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
  border-top: solid 1px rgba(0, 0, 0, 0.12);
}
.cs-grid-test .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
  color: rgba(0, 0, 0, 0.87);
}
.cs-grid-test .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-group-cell {
  color: rgba(0, 0, 0, 0.87);
}
.cs-grid-test .ngx-datatable.material .datatable-body .progress-linear .container {
  background-color: white;
}
.cs-grid-test .ngx-datatable.material .datatable-body .progress-linear .container .bar {
  background-color: #3f51b5;
}
.cs-grid-test .ngx-datatable.material .datatable-footer {
  color: rgba(0, 0, 0, 0.54);
}
.cs-grid-test .ngx-datatable.material .datatable-footer .datatable-pager li.disabled a {
  color: rgba(0, 0, 0, 0.38) !important;
  background-color: rgba(0, 0, 0, 0) !important;
}
.cs-grid-test .ngx-datatable.material .datatable-footer .datatable-pager li.active a {
  background-color: #3f51b5;
}
.cs-grid-test .ngx-datatable.material .datatable-footer .datatable-pager a {
  color: rgba(0, 0, 0, 0.54);
}
.cs-grid-test .ngx-datatable.material .datatable-footer .datatable-pager a:hover {
  color: rgba(0, 0, 0, 0.04);
  background-color: rgba(0, 0, 0, 0.04);
}
.cs-grid-test .ngx-datatable.material .datatable-summary-row .datatable-body-row {
  background-color: #fafafa;
}
.cs-grid-test .ngx-datatable.material .datatable-summary-row .datatable-body-row:hover {
  background-color: #fafafa;
}
.cs-grid-test .datatable-checkbox input[type=checkbox]:after {
  background: white;
}

.view-container .view-toolbar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal.new.image-opened .help-content img {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card {
  background: white;
}
app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container .aditional-info {
  color: rgba(0, 0, 0, 0.54);
}
app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container .mat-card-footer {
  background: white;
}

app-help-index mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card {
  background: white;
}
app-help-index mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card .mat-card-footer {
  background: white;
}

.cropper .employee-title .title-text {
  color: white !important;
}
.cropper app-cs-upload-cropper-control .preview h5 {
  color: rgba(0, 0, 0, 0.54);
}

app-file-document-upload .preview-container {
  background: #607D8B !important;
}
app-file-document-upload .preview-container h4 {
  color: #eeeeee;
}

.preview-container {
  background: #607D8B !important;
}
.preview-container h4 {
  color: #eeeeee;
}

.clean-autocomple mat-list {
  background: white;
}
.clean-autocomple mat-list .mat-list-item:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.clean-autocomple mat-list .mat-list-item .mat-list-item-content .mat-list-text {
  color: #3f51b5;
}

app-leave .inbox-toolbar {
  background: #fafafa;
}
app-leave .mat-tab-body-wrapper {
  position: relative;
  overflow: hidden;
  display: flex;
  transition: height 500ms cubic-bezier(0.35, 0, 0.25, 1);
  height: 95% !important;
}
app-leave .leave_container {
  background: #fafafa;
}
app-leave .leave_container .leave_items .leave_items-item.ok .content .leave_item-status_icon {
  color: #009688;
}
app-leave .leave_container .leave_items .leave_items-item.not-ok .content .leave_item-status_icon {
  color: #9C27B0;
}
app-leave .leave_container .leave_items .leave_items-item.pending .content .leave_item-status_icon {
  color: #FF9800;
}
app-leave .leave_container .leave_items .leave_items-item.draft .content .leave_item-status_icon {
  color: rgba(0, 0, 0, 0.54);
}
app-leave .leave_container .leave_items .leave_items-item.cancel .content .leave_item-status_icon {
  color: #795548;
}
app-leave .leave_container .leave_items .leave_items-item .content:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
app-leave .leave_container .leave_items .leave_items-item .content.selected {
  border-left: 6px solid #e91e63 !important;
}
app-leave .leave_container .leave_items .leave_items-item .content.selected mat-icon {
  color: #e91e63 !important;
}
app-leave .leave_container .leave_items .leave_items-item .content.selected .item-data {
  color: rgba(0, 0, 0, 0.87) !important;
}
app-leave .leave_container .leave_items .leave_items-item .content .leave_item-icon {
  color: rgba(0, 0, 0, 0.54);
}
app-leave .leave_container .leave_items .leave_items-item .content .item-data {
  color: rgba(0, 0, 0, 0.54);
}
app-leave .leave_container .window-spinner {
  background: #fafafa !important;
}

app-leave-detail .leave_detail .leave_detail-title .info h3,
app-leave-detail .leave_detail .leave_detail-title .info mat-icon {
  color: #3f51b5;
}
app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .ok .large-icon {
  background: #b2dfdb;
  color: #009688;
}
app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .ok h2 {
  color: #009688;
}
app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .not-ok .large-icon {
  background: #e1bee7;
  color: #9C27B0;
}
app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .not-ok h2 {
  color: #9C27B0;
}
app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .pending .large-icon {
  background: #FFE0B2;
  color: #FF9800;
}
app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .pending h2 {
  color: #FF9800;
}
app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .draft .large-icon {
  background: #f2f2f2;
  color: #dedede;
}
app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .draft h2 {
  color: #dedede;
}
app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .cancelled .large-icon {
  background: #BCAAA4;
  color: #795548;
}
app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .cancelled h2 {
  color: #795548;
}
app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .actions {
  padding: 16px;
  height: 96px;
  column-gap: 16px;
}
app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .actions button {
  height: 100%;
  border-radius: 40px;
}
app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .actions button.ok {
  background: #009688 !important;
  color: white;
}
app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .actions button.not-ok {
  color: #9C27B0 !important;
}
app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning mat-basic-chip {
  background: #eeeeee !important;
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
  border-radius: 40px !important;
  height: 35px !important;
}
app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning mat-basic-chip.signed-ok {
  border-color: #009688 !important;
  color: #009688 !important;
}
app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning mat-basic-chip.signed-not-ok {
  border-color: #9C27B0 !important;
  color: #9C27B0 !important;
}
app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning mat-basic-chip.draft {
  border-color: #dedede !important;
  color: #dedede !important;
}

app-leave-request-detail .load-window {
  background: #fafafa;
}
app-leave-request-detail form .leave_detail-container {
  background: #fafafa;
}
app-leave-request-detail form .leave_detail-container .toolbar .header {
  color: #3f51b5;
}
app-leave-request-detail form .leave_detail-container .toolbar .header .text h3 {
  color: #3f51b5;
}
app-leave-request-detail form .leave_detail-container .toolbar .header .text h5 {
  color: rgba(0, 0, 0, 0.54);
}
app-leave-request-detail form .leave_detail-container .leave_detail-container-content .leave_resume {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
app-leave-request-detail form .leave_detail-container .leave_detail-container-content .line h6 {
  color: rgba(0, 0, 0, 0.54);
}
app-leave-request-detail form .leave_detail-container .actions button.ok {
  background: #009688 !important;
  color: white;
}
app-leave-request-detail form .leave_detail-container .actions button.not-ok {
  color: #9C27B0 !important;
}

.new_leave_modal-steps {
  display: unset !important;
}
.new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button .mat-radio-label .mat-radio-label-content .side-text {
  color: rgba(0, 0, 0, 0.54);
}
.new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button .mat-radio-label .mat-radio-label-content .side-text.muted {
  color: rgba(0, 0, 0, 0.38);
}
.new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button .mat-radio-label .mat-radio-label-content .accent mat-icon,
.new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button .mat-radio-label .mat-radio-label-content .accent .main-text {
  color: #e91e63;
}

.config_leave-container .config_leave-card {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.config_leave-container .config_leave-card .config_leave-auto_chip mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #fafafa !important;
}
.config_leave-container .config_leave-card .config_leave-auto_chip mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(0, 0, 0, 0.38);
}
.config_leave-container .config_leave-card .config_leave-auto_chip mat-form-field .mat-form-field-flex {
  background: #fafafa;
}

*[color=success] {
  color: #4DB6AC;
}

.primary {
  color: #3f51b5 !important;
}

.accent {
  color: #e91e63 !important;
}

app-lsd .icon-complex mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
app-lsd .icon-complex mat-icon.complex {
  color: #3f51b5 !important;
}
app-lsd .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
app-lsd .work-space .main .load-window {
  background: white !important;
}
app-lsd .work-space .main.side-open {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
app-lsd .work-space .main .container .item {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
app-lsd .work-space .main .container .item h5 {
  color: rgba(0, 0, 0, 0.38);
}
app-lsd .work-space .main .container .item h3 {
  color: rgba(0, 0, 0, 0.87);
}
app-lsd .work-space .main .container .item:hover {
  background: rgba(0, 0, 0, 0.04);
}
app-lsd .work-space .main .container .item.selected {
  background: #3f51b5;
  color: white !important;
}
app-lsd .work-space .main .container .item.selected h3 {
  color: white !important;
}
app-lsd .work-space .main .container .item.selected.pending .status {
  background: rgba(0, 0, 0, 0.12) !important;
  color: #ffe082;
}
app-lsd .work-space .main .container .item.selected.ok .status {
  background: rgba(0, 0, 0, 0.12) !important;
  color: #a5d6a7;
}
app-lsd .work-space .main .container .item.selected.not-ok .status {
  background: rgba(0, 0, 0, 0.12) !important;
  color: #e57373;
}
app-lsd .work-space .main .main-action {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
app-lsd .work-space .side {
  background: #fafafa;
}
app-lsd .work-space .side .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #fafafa, 0 -2px 0px #fafafa, 2px 0 0px #fafafa, 0 2px 0px #fafafa, -2px -2px 0px #fafafa, 2px 2px 0px #fafafa, 2px -2px 0px #fafafa, -2px 2px 0px #fafafa;
}
app-lsd .work-space .side *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
app-lsd .work-space .side *::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}
app-lsd .work-space .side .edition-line {
  background: #eeeeee;
}
app-lsd .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #fafafa !important;
}
app-lsd .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(0, 0, 0, 0.38);
}
app-lsd .work-space .side mat-form-field .mat-form-field-flex {
  background: #fafafa;
}
app-lsd .work-space .side mat-checkbox:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
app-lsd .work-space .side .hint.all {
  color: #e91e63;
}
app-lsd .work-space .side .toolbar .header {
  color: #3f51b5;
}
app-lsd .work-space .side .toolbar .header h3 {
  color: #3f51b5;
}
app-lsd .work-space .side .container .doc-resume {
  background: #3f51b5;
}
app-lsd .work-space .side .container .doc-resume .line {
  color: white;
}
app-lsd .work-space .side .container .doc-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.1);
}
app-lsd .work-space .side .container .doc-resume .line.editable button {
  background: #3f51b5;
}
app-lsd .work-space .side .container .section-title {
  color: #3f51b5;
}
app-lsd .work-space .side .container .section-resume {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
app-lsd .work-space .side .container .section-resume .line.editable:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
app-lsd .work-space .side .container .section-resume .line.editable:hover .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px white, 0 -2px 0px white, 2px 0 0px white, 0 2px 0px white, -2px -2px 0px white, 2px 2px 0px white, 2px -2px 0px white, -2px 2px 0px white !important;
}
app-lsd .work-space .side .container .section-resume .line.editable button {
  background: #fafafa;
}
app-lsd .work-space .side .container .section-resume .line .header .icon-complex mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
app-lsd .work-space .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #3f51b5 !important;
}
app-lsd .work-space .side .container .section-resume .line .header mat-icon:not(.complex) {
  color: rgba(0, 0, 0, 0.54);
}
app-lsd .work-space .side .container .section-resume .line .header .info h4 {
  color: #3f51b5;
}
app-lsd .work-space .side .container .section-resume .line ul {
  color: #3f51b5;
}
app-lsd .work-space .side .container .section-resume .line.head {
  background: #eeeeee;
}
app-lsd .work-space .side .container .section-resume .line.head mat-icon {
  color: #e91e63;
}
app-lsd .work-space .side .container .section-resume .line.head h4 {
  color: white;
}
app-lsd .work-space .side.edition .toolbar .header {
  color: white;
}
app-lsd .work-space .side.edition .toolbar .header mat-icon {
  color: #e91e63;
}
app-lsd .work-space .side.edition .toolbar .header h3 {
  color: #e91e63;
}

.dialog-content {
  max-height: 90vh;
}
.dialog-content mat-toolbar {
  background: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.dialog-content .dialog-container .document-template-container .document-selection mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #fafafa !important;
}
.dialog-content .dialog-container .document-template-container .document-selection mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(0, 0, 0, 0.38);
}
.dialog-content .dialog-container .document-template-container .document-selection mat-form-field .mat-form-field-flex {
  background: #fafafa;
}
.dialog-content .dialog-container .document-template-container .document-selection app-upload-form .file-upload-list .mat-subheader::before {
  color: #3f51b5;
}
.dialog-content .dialog-container .view-container {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}
.dialog-content .form-actions {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.indigo-pink.dark .mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-pink.dark .mat-badge-small .mat-badge-content {
  font-size: 9px;
}
.indigo-pink.dark .mat-badge-large .mat-badge-content {
  font-size: 24px;
}
.indigo-pink.dark .mat-h1,
.indigo-pink.dark .mat-headline,
.indigo-pink.dark .mat-typography .mat-h1,
.indigo-pink.dark .mat-typography .mat-headline,
.indigo-pink.dark .mat-typography h1 {
  font: 400 24px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.indigo-pink.dark .mat-h2,
.indigo-pink.dark .mat-title,
.indigo-pink.dark .mat-typography .mat-h2,
.indigo-pink.dark .mat-typography .mat-title,
.indigo-pink.dark .mat-typography h2 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.indigo-pink.dark .mat-h3,
.indigo-pink.dark .mat-subheading-2,
.indigo-pink.dark .mat-typography .mat-h3,
.indigo-pink.dark .mat-typography .mat-subheading-2,
.indigo-pink.dark .mat-typography h3 {
  font: 400 16px / 28px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.indigo-pink.dark .mat-h4,
.indigo-pink.dark .mat-subheading-1,
.indigo-pink.dark .mat-typography .mat-h4,
.indigo-pink.dark .mat-typography .mat-subheading-1,
.indigo-pink.dark .mat-typography h4 {
  font: 400 15px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.indigo-pink.dark .mat-h5,
.indigo-pink.dark .mat-typography .mat-h5,
.indigo-pink.dark .mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}
.indigo-pink.dark .mat-h6,
.indigo-pink.dark .mat-typography .mat-h6,
.indigo-pink.dark .mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}
.indigo-pink.dark .mat-body-strong,
.indigo-pink.dark .mat-body-2,
.indigo-pink.dark .mat-typography .mat-body-strong,
.indigo-pink.dark .mat-typography .mat-body-2 {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.indigo-pink.dark .mat-body,
.indigo-pink.dark .mat-body-1,
.indigo-pink.dark .mat-typography .mat-body,
.indigo-pink.dark .mat-typography .mat-body-1,
.indigo-pink.dark .mat-typography {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.indigo-pink.dark .mat-body p,
.indigo-pink.dark .mat-body-1 p,
.indigo-pink.dark .mat-typography .mat-body p,
.indigo-pink.dark .mat-typography .mat-body-1 p,
.indigo-pink.dark .mat-typography p {
  margin: 0 0 12px;
}
.indigo-pink.dark .mat-small,
.indigo-pink.dark .mat-caption,
.indigo-pink.dark .mat-typography .mat-small,
.indigo-pink.dark .mat-typography .mat-caption {
  font: 400 12px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.indigo-pink.dark .mat-display-4,
.indigo-pink.dark .mat-typography .mat-display-4 {
  font: 300 112px / 112px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}
.indigo-pink.dark .mat-display-3,
.indigo-pink.dark .mat-typography .mat-display-3 {
  font: 400 56px / 56px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}
.indigo-pink.dark .mat-display-2,
.indigo-pink.dark .mat-typography .mat-display-2 {
  font: 400 45px / 48px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}
.indigo-pink.dark .mat-display-1,
.indigo-pink.dark .mat-typography .mat-display-1 {
  font: 400 34px / 40px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}
.indigo-pink.dark .mat-bottom-sheet-container {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.indigo-pink.dark .mat-button, .indigo-pink.dark .mat-raised-button, .indigo-pink.dark .mat-icon-button, .indigo-pink.dark .mat-stroked-button,
.indigo-pink.dark .mat-flat-button, .indigo-pink.dark .mat-fab, .indigo-pink.dark .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.indigo-pink.dark .mat-button-toggle {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-pink.dark .mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-pink.dark .mat-card-title {
  font-size: 24px;
  font-weight: 500;
}
.indigo-pink.dark .mat-card-header .mat-card-title {
  font-size: 20px;
}
.indigo-pink.dark .mat-card-subtitle,
.indigo-pink.dark .mat-card-content {
  font-size: 14px;
}
.indigo-pink.dark .mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-pink.dark .mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}
.indigo-pink.dark .mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.indigo-pink.dark .mat-chip .mat-chip-trailing-icon.mat-icon,
.indigo-pink.dark .mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}
.indigo-pink.dark .mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-pink.dark .mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}
.indigo-pink.dark .mat-cell, .indigo-pink.dark .mat-footer-cell {
  font-size: 14px;
}
.indigo-pink.dark .mat-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-pink.dark .mat-calendar-body {
  font-size: 13px;
}
.indigo-pink.dark .mat-calendar-body-label,
.indigo-pink.dark .mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}
.indigo-pink.dark .mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}
.indigo-pink.dark .mat-dialog-title {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.indigo-pink.dark .mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400;
}
.indigo-pink.dark .mat-expansion-panel-content {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.indigo-pink.dark .mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.indigo-pink.dark .mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}
.indigo-pink.dark .mat-form-field-prefix .mat-icon,
.indigo-pink.dark .mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.indigo-pink.dark .mat-form-field-prefix .mat-icon-button,
.indigo-pink.dark .mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.indigo-pink.dark .mat-form-field-prefix .mat-icon-button .mat-icon,
.indigo-pink.dark .mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}
.indigo-pink.dark .mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}
.indigo-pink.dark .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.indigo-pink.dark .mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34373em) scale(0.75);
  width: 133.3333533333%;
}
.indigo-pink.dark .mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34372em) scale(0.75);
  width: 133.3333633333%;
}
.indigo-pink.dark .mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}
.indigo-pink.dark .mat-form-field-label {
  top: 1.34375em;
}
.indigo-pink.dark .mat-form-field-underline {
  bottom: 1.34375em;
}
.indigo-pink.dark .mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}
.indigo-pink.dark .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.indigo-pink.dark .mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.indigo-pink.dark .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.indigo-pink.dark .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00106px);
  width: 133.3333933333%;
}
.indigo-pink.dark .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00107px);
  width: 133.3334033333%;
}
.indigo-pink.dark .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00108px);
  width: 133.3334133333%;
}
.indigo-pink.dark .mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.indigo-pink.dark .mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.indigo-pink.dark .mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}
@media print {
  .indigo-pink.dark .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.indigo-pink.dark .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28116em) scale(0.75);
  }
  .indigo-pink.dark .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28115em) scale(0.75);
  }
  .indigo-pink.dark .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28114em) scale(0.75);
  }
}
.indigo-pink.dark .mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.indigo-pink.dark .mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.indigo-pink.dark .mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.indigo-pink.dark .mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59373em) scale(0.75);
  width: 133.3333533333%;
}
.indigo-pink.dark .mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59372em) scale(0.75);
  width: 133.3333633333%;
}
.indigo-pink.dark .mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.indigo-pink.dark .mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.indigo-pink.dark .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.indigo-pink.dark .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59373em) scale(0.75);
  width: 133.3333533333%;
}
.indigo-pink.dark .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59372em) scale(0.75);
  width: 133.3333633333%;
}
.indigo-pink.dark .mat-grid-tile-header,
.indigo-pink.dark .mat-grid-tile-footer {
  font-size: 14px;
}
.indigo-pink.dark .mat-grid-tile-header .mat-line,
.indigo-pink.dark .mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.indigo-pink.dark .mat-grid-tile-header .mat-line:nth-child(n+2),
.indigo-pink.dark .mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.indigo-pink.dark input.mat-input-element {
  margin-top: -0.0625em;
}
.indigo-pink.dark .mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
}
.indigo-pink.dark .mat-paginator,
.indigo-pink.dark .mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
}
.indigo-pink.dark .mat-radio-button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-pink.dark .mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-pink.dark .mat-select-trigger {
  height: 1.125em;
}
.indigo-pink.dark .mat-slide-toggle-content {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-pink.dark .mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}
.indigo-pink.dark .mat-stepper-vertical, .indigo-pink.dark .mat-stepper-horizontal {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-pink.dark .mat-step-label {
  font-size: 14px;
  font-weight: 400;
}
.indigo-pink.dark .mat-step-sub-label-error {
  font-weight: normal;
}
.indigo-pink.dark .mat-step-label-error {
  font-size: 14px;
}
.indigo-pink.dark .mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}
.indigo-pink.dark .mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-pink.dark .mat-tab-label, .indigo-pink.dark .mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.indigo-pink.dark .mat-toolbar,
.indigo-pink.dark .mat-toolbar h1,
.indigo-pink.dark .mat-toolbar h2,
.indigo-pink.dark .mat-toolbar h3,
.indigo-pink.dark .mat-toolbar h4,
.indigo-pink.dark .mat-toolbar h5,
.indigo-pink.dark .mat-toolbar h6 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0;
}
.indigo-pink.dark .mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.indigo-pink.dark .mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.indigo-pink.dark .mat-list-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-pink.dark .mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-pink.dark .mat-list-base .mat-list-item {
  font-size: 16px;
}
.indigo-pink.dark .mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.indigo-pink.dark .mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.indigo-pink.dark .mat-list-base .mat-list-option {
  font-size: 16px;
}
.indigo-pink.dark .mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.indigo-pink.dark .mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.indigo-pink.dark .mat-list-base .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.indigo-pink.dark .mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.indigo-pink.dark .mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.indigo-pink.dark .mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.indigo-pink.dark .mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.indigo-pink.dark .mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.indigo-pink.dark .mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.indigo-pink.dark .mat-list-base[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}
.indigo-pink.dark .mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
}
.indigo-pink.dark .mat-optgroup-label {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.indigo-pink.dark .mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
}
.indigo-pink.dark .mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}
.indigo-pink.dark .mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-pink.dark .mat-tree-node,
.indigo-pink.dark .mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}
.indigo-pink.dark .mat-ripple {
  overflow: hidden;
  position: relative;
}
.indigo-pink.dark .mat-ripple:not(:empty) {
  transform: translateZ(0);
}
.indigo-pink.dark .mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}
.indigo-pink.dark .mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .indigo-pink.dark .mat-ripple-element {
  display: none;
}

.indigo-pink.dark .cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .indigo-pink.dark .cdk-visually-hidden {
  left: auto;
  right: 0;
}
.indigo-pink.dark .cdk-overlay-container, .indigo-pink.dark .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.indigo-pink.dark .cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.indigo-pink.dark .cdk-overlay-container:empty {
  display: none;
}
.indigo-pink.dark .cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}
.indigo-pink.dark .cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}
.indigo-pink.dark .cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.indigo-pink.dark .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .indigo-pink.dark .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}
.indigo-pink.dark .cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}
.indigo-pink.dark .cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.indigo-pink.dark .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}
.indigo-pink.dark .cdk-overlay-backdrop-noop-animation {
  transition: none;
}
.indigo-pink.dark .cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}
.indigo-pink.dark .cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}
.indigo-pink.dark textarea.cdk-textarea-autosize {
  resize: none;
}
.indigo-pink.dark textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}
.indigo-pink.dark textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}
@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.indigo-pink.dark .cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}
.indigo-pink.dark .cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}
.indigo-pink.dark .mat-focus-indicator {
  position: relative;
}
.indigo-pink.dark .mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.indigo-pink.dark .mat-focus-indicator:focus::before {
  content: "";
}
.cdk-high-contrast-active .indigo-pink.dark {
  --mat-focus-indicator-display: block;
}

.indigo-pink.dark .mat-mdc-focus-indicator {
  position: relative;
}
.indigo-pink.dark .mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.indigo-pink.dark .mat-mdc-focus-indicator:focus::before {
  content: "";
}
.cdk-high-contrast-active .indigo-pink.dark {
  --mat-mdc-focus-indicator-display: block;
}

.indigo-pink.dark .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.indigo-pink.dark .mat-option {
  color: white;
}
.indigo-pink.dark .mat-option:hover:not(.mat-option-disabled), .indigo-pink.dark .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-pink.dark .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-pink.dark .mat-option.mat-active {
  background: rgba(255, 255, 255, 0.04);
  color: white;
}
.indigo-pink.dark .mat-option.mat-option-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-pink.dark .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #9fa8da;
}
.indigo-pink.dark .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f48fb1;
}
.indigo-pink.dark .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #e57373;
}
.indigo-pink.dark .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-pink.dark .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-pink.dark .mat-pseudo-checkbox {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-pink.dark .mat-pseudo-checkbox::after {
  color: #303030;
}
.indigo-pink.dark .mat-pseudo-checkbox-disabled {
  color: #686868;
}
.indigo-pink.dark .mat-primary .mat-pseudo-checkbox-checked,
.indigo-pink.dark .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #9fa8da;
}
.indigo-pink.dark .mat-pseudo-checkbox-checked,
.indigo-pink.dark .mat-pseudo-checkbox-indeterminate,
.indigo-pink.dark .mat-accent .mat-pseudo-checkbox-checked,
.indigo-pink.dark .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #f48fb1;
}
.indigo-pink.dark .mat-warn .mat-pseudo-checkbox-checked,
.indigo-pink.dark .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #e57373;
}
.indigo-pink.dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.indigo-pink.dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #686868;
}
.indigo-pink.dark .mat-app-background, .indigo-pink.dark.mat-app-background {
  background-color: #303030;
  color: white;
}
.indigo-pink.dark .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.indigo-pink.dark .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.indigo-pink.dark .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.indigo-pink.dark .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.indigo-pink.dark .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.indigo-pink.dark .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.indigo-pink.dark .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.indigo-pink.dark .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.indigo-pink.dark .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.indigo-pink.dark .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.indigo-pink.dark .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.indigo-pink.dark .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.indigo-pink.dark .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.indigo-pink.dark .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.indigo-pink.dark .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.indigo-pink.dark .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.indigo-pink.dark .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.indigo-pink.dark .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.indigo-pink.dark .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.indigo-pink.dark .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.indigo-pink.dark .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.indigo-pink.dark .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.indigo-pink.dark .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.indigo-pink.dark .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.indigo-pink.dark .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.indigo-pink.dark .mat-autocomplete-panel {
  background: #424242;
  color: white;
}
.indigo-pink.dark .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.indigo-pink.dark .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: #424242;
}
.indigo-pink.dark .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: white;
}
.indigo-pink.dark .mat-badge-content {
  color: rgba(0, 0, 0, 0.87);
  background: #9fa8da;
}
.cdk-high-contrast-active .indigo-pink.dark .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.indigo-pink.dark .mat-badge-accent .mat-badge-content {
  background: #f48fb1;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark .mat-badge-warn .mat-badge-content {
  color: rgba(0, 0, 0, 0.87);
  background: #e57373;
}
.indigo-pink.dark .mat-badge-disabled .mat-badge-content {
  background: #6e6e6e;
  color: rgba(255, 255, 255, 0.5);
}
.indigo-pink.dark .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.indigo-pink.dark .mat-button, .indigo-pink.dark .mat-icon-button, .indigo-pink.dark .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.indigo-pink.dark .mat-button.mat-primary, .indigo-pink.dark .mat-icon-button.mat-primary, .indigo-pink.dark .mat-stroked-button.mat-primary {
  color: #9fa8da;
}
.indigo-pink.dark .mat-button.mat-accent, .indigo-pink.dark .mat-icon-button.mat-accent, .indigo-pink.dark .mat-stroked-button.mat-accent {
  color: #f48fb1;
}
.indigo-pink.dark .mat-button.mat-warn, .indigo-pink.dark .mat-icon-button.mat-warn, .indigo-pink.dark .mat-stroked-button.mat-warn {
  color: #e57373;
}
.indigo-pink.dark .mat-button.mat-primary.mat-button-disabled, .indigo-pink.dark .mat-button.mat-accent.mat-button-disabled, .indigo-pink.dark .mat-button.mat-warn.mat-button-disabled, .indigo-pink.dark .mat-button.mat-button-disabled.mat-button-disabled, .indigo-pink.dark .mat-icon-button.mat-primary.mat-button-disabled, .indigo-pink.dark .mat-icon-button.mat-accent.mat-button-disabled, .indigo-pink.dark .mat-icon-button.mat-warn.mat-button-disabled, .indigo-pink.dark .mat-icon-button.mat-button-disabled.mat-button-disabled, .indigo-pink.dark .mat-stroked-button.mat-primary.mat-button-disabled, .indigo-pink.dark .mat-stroked-button.mat-accent.mat-button-disabled, .indigo-pink.dark .mat-stroked-button.mat-warn.mat-button-disabled, .indigo-pink.dark .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.indigo-pink.dark .mat-button.mat-primary .mat-button-focus-overlay, .indigo-pink.dark .mat-icon-button.mat-primary .mat-button-focus-overlay, .indigo-pink.dark .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #9fa8da;
}
.indigo-pink.dark .mat-button.mat-accent .mat-button-focus-overlay, .indigo-pink.dark .mat-icon-button.mat-accent .mat-button-focus-overlay, .indigo-pink.dark .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #f48fb1;
}
.indigo-pink.dark .mat-button.mat-warn .mat-button-focus-overlay, .indigo-pink.dark .mat-icon-button.mat-warn .mat-button-focus-overlay, .indigo-pink.dark .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #e57373;
}
.indigo-pink.dark .mat-button.mat-button-disabled .mat-button-focus-overlay, .indigo-pink.dark .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .indigo-pink.dark .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.indigo-pink.dark .mat-button .mat-ripple-element, .indigo-pink.dark .mat-icon-button .mat-ripple-element, .indigo-pink.dark .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.indigo-pink.dark .mat-button-focus-overlay {
  background: white;
}
.indigo-pink.dark .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark .mat-flat-button, .indigo-pink.dark .mat-raised-button, .indigo-pink.dark .mat-fab, .indigo-pink.dark .mat-mini-fab {
  color: white;
  background-color: #424242;
}
.indigo-pink.dark .mat-flat-button.mat-primary, .indigo-pink.dark .mat-raised-button.mat-primary, .indigo-pink.dark .mat-fab.mat-primary, .indigo-pink.dark .mat-mini-fab.mat-primary {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark .mat-flat-button.mat-accent, .indigo-pink.dark .mat-raised-button.mat-accent, .indigo-pink.dark .mat-fab.mat-accent, .indigo-pink.dark .mat-mini-fab.mat-accent {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark .mat-flat-button.mat-warn, .indigo-pink.dark .mat-raised-button.mat-warn, .indigo-pink.dark .mat-fab.mat-warn, .indigo-pink.dark .mat-mini-fab.mat-warn {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark .mat-flat-button.mat-primary.mat-button-disabled, .indigo-pink.dark .mat-flat-button.mat-accent.mat-button-disabled, .indigo-pink.dark .mat-flat-button.mat-warn.mat-button-disabled, .indigo-pink.dark .mat-flat-button.mat-button-disabled.mat-button-disabled, .indigo-pink.dark .mat-raised-button.mat-primary.mat-button-disabled, .indigo-pink.dark .mat-raised-button.mat-accent.mat-button-disabled, .indigo-pink.dark .mat-raised-button.mat-warn.mat-button-disabled, .indigo-pink.dark .mat-raised-button.mat-button-disabled.mat-button-disabled, .indigo-pink.dark .mat-fab.mat-primary.mat-button-disabled, .indigo-pink.dark .mat-fab.mat-accent.mat-button-disabled, .indigo-pink.dark .mat-fab.mat-warn.mat-button-disabled, .indigo-pink.dark .mat-fab.mat-button-disabled.mat-button-disabled, .indigo-pink.dark .mat-mini-fab.mat-primary.mat-button-disabled, .indigo-pink.dark .mat-mini-fab.mat-accent.mat-button-disabled, .indigo-pink.dark .mat-mini-fab.mat-warn.mat-button-disabled, .indigo-pink.dark .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.indigo-pink.dark .mat-flat-button.mat-primary, .indigo-pink.dark .mat-raised-button.mat-primary, .indigo-pink.dark .mat-fab.mat-primary, .indigo-pink.dark .mat-mini-fab.mat-primary {
  background-color: #9fa8da;
}
.indigo-pink.dark .mat-flat-button.mat-accent, .indigo-pink.dark .mat-raised-button.mat-accent, .indigo-pink.dark .mat-fab.mat-accent, .indigo-pink.dark .mat-mini-fab.mat-accent {
  background-color: #f48fb1;
}
.indigo-pink.dark .mat-flat-button.mat-warn, .indigo-pink.dark .mat-raised-button.mat-warn, .indigo-pink.dark .mat-fab.mat-warn, .indigo-pink.dark .mat-mini-fab.mat-warn {
  background-color: #e57373;
}
.indigo-pink.dark .mat-flat-button.mat-primary.mat-button-disabled, .indigo-pink.dark .mat-flat-button.mat-accent.mat-button-disabled, .indigo-pink.dark .mat-flat-button.mat-warn.mat-button-disabled, .indigo-pink.dark .mat-flat-button.mat-button-disabled.mat-button-disabled, .indigo-pink.dark .mat-raised-button.mat-primary.mat-button-disabled, .indigo-pink.dark .mat-raised-button.mat-accent.mat-button-disabled, .indigo-pink.dark .mat-raised-button.mat-warn.mat-button-disabled, .indigo-pink.dark .mat-raised-button.mat-button-disabled.mat-button-disabled, .indigo-pink.dark .mat-fab.mat-primary.mat-button-disabled, .indigo-pink.dark .mat-fab.mat-accent.mat-button-disabled, .indigo-pink.dark .mat-fab.mat-warn.mat-button-disabled, .indigo-pink.dark .mat-fab.mat-button-disabled.mat-button-disabled, .indigo-pink.dark .mat-mini-fab.mat-primary.mat-button-disabled, .indigo-pink.dark .mat-mini-fab.mat-accent.mat-button-disabled, .indigo-pink.dark .mat-mini-fab.mat-warn.mat-button-disabled, .indigo-pink.dark .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark .mat-flat-button.mat-primary .mat-ripple-element, .indigo-pink.dark .mat-raised-button.mat-primary .mat-ripple-element, .indigo-pink.dark .mat-fab.mat-primary .mat-ripple-element, .indigo-pink.dark .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.indigo-pink.dark .mat-flat-button.mat-accent .mat-ripple-element, .indigo-pink.dark .mat-raised-button.mat-accent .mat-ripple-element, .indigo-pink.dark .mat-fab.mat-accent .mat-ripple-element, .indigo-pink.dark .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.indigo-pink.dark .mat-flat-button.mat-warn .mat-ripple-element, .indigo-pink.dark .mat-raised-button.mat-warn .mat-ripple-element, .indigo-pink.dark .mat-fab.mat-warn .mat-ripple-element, .indigo-pink.dark .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.indigo-pink.dark .mat-stroked-button:not([class*=mat-elevation-z]), .indigo-pink.dark .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.indigo-pink.dark .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.indigo-pink.dark .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.indigo-pink.dark .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.indigo-pink.dark .mat-fab:not([class*=mat-elevation-z]), .indigo-pink.dark .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.indigo-pink.dark .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .indigo-pink.dark .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.indigo-pink.dark .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .indigo-pink.dark .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.indigo-pink.dark .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.indigo-pink.dark .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.indigo-pink.dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.indigo-pink.dark .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.indigo-pink.dark .mat-button-toggle {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-pink.dark .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark .mat-button-toggle-appearance-standard {
  color: white;
  background: #424242;
}
.indigo-pink.dark .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: white;
}
.indigo-pink.dark .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #595959;
}
.indigo-pink.dark [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #595959;
}
.indigo-pink.dark .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #595959;
}
.indigo-pink.dark .mat-button-toggle-checked {
  background-color: #212121;
  color: rgba(255, 255, 255, 0.7);
}
.indigo-pink.dark .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: white;
}
.indigo-pink.dark .mat-button-toggle-disabled {
  color: rgba(255, 255, 255, 0.3);
  background-color: black;
}
.indigo-pink.dark .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: #424242;
}
.indigo-pink.dark .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #424242;
}
.indigo-pink.dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.indigo-pink.dark .mat-button-toggle-group-appearance-standard {
  border: solid 1px #595959;
}
.indigo-pink.dark .mat-card {
  background: #424242;
  color: white;
}
.indigo-pink.dark .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.indigo-pink.dark .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.indigo-pink.dark .mat-card-subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-pink.dark .mat-checkbox-frame {
  border-color: rgba(255, 255, 255, 0.7);
}
.indigo-pink.dark .mat-checkbox-checkmark {
  fill: #303030;
}
.indigo-pink.dark .mat-checkbox-checkmark-path {
  stroke: #303030 !important;
}
.indigo-pink.dark .mat-checkbox-mixedmark {
  background-color: #303030;
}
.indigo-pink.dark .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .indigo-pink.dark .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #9fa8da;
}
.indigo-pink.dark .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .indigo-pink.dark .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #f48fb1;
}
.indigo-pink.dark .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .indigo-pink.dark .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #e57373;
}
.indigo-pink.dark .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .indigo-pink.dark .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #686868;
}
.indigo-pink.dark .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #686868;
}
.indigo-pink.dark .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-pink.dark .mat-checkbox .mat-ripple-element {
  background-color: white;
}
.indigo-pink.dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.indigo-pink.dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #9fa8da;
}
.indigo-pink.dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.indigo-pink.dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #f48fb1;
}
.indigo-pink.dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.indigo-pink.dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #e57373;
}
.indigo-pink.dark .mat-chip.mat-standard-chip {
  background-color: #616161;
  color: white;
}
.indigo-pink.dark .mat-chip.mat-standard-chip .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.indigo-pink.dark .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.indigo-pink.dark .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.indigo-pink.dark .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.indigo-pink.dark .mat-chip.mat-standard-chip::after {
  background: white;
}
.indigo-pink.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.indigo-pink.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.indigo-pink.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #e57373;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.indigo-pink.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.indigo-pink.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #f48fb1;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.indigo-pink.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.indigo-pink.dark .mat-table {
  background: #424242;
}
.indigo-pink.dark .mat-table thead, .indigo-pink.dark .mat-table tbody, .indigo-pink.dark .mat-table tfoot,
.indigo-pink.dark mat-header-row, .indigo-pink.dark mat-row, .indigo-pink.dark mat-footer-row,
.indigo-pink.dark [mat-header-row], .indigo-pink.dark [mat-row], .indigo-pink.dark [mat-footer-row],
.indigo-pink.dark .mat-table-sticky {
  background: inherit;
}
.indigo-pink.dark mat-row, .indigo-pink.dark mat-header-row, .indigo-pink.dark mat-footer-row,
.indigo-pink.dark th.mat-header-cell, .indigo-pink.dark td.mat-cell, .indigo-pink.dark td.mat-footer-cell {
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark .mat-header-cell {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-pink.dark .mat-cell, .indigo-pink.dark .mat-footer-cell {
  color: white;
}
.indigo-pink.dark .mat-calendar-arrow {
  fill: white;
}
.indigo-pink.dark .mat-datepicker-toggle,
.indigo-pink.dark .mat-datepicker-content .mat-calendar-next-button,
.indigo-pink.dark .mat-datepicker-content .mat-calendar-previous-button {
  color: white;
}
.indigo-pink.dark .mat-calendar-table-header-divider::after {
  background: rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark .mat-calendar-table-header,
.indigo-pink.dark .mat-calendar-body-label {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-pink.dark .mat-calendar-body-cell-content,
.indigo-pink.dark .mat-date-range-input-separator {
  color: white;
  border-color: transparent;
}
.indigo-pink.dark .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-pink.dark .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-pink.dark .mat-calendar-body-in-preview {
  color: rgba(255, 255, 255, 0.24);
}
.indigo-pink.dark .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.5);
}
.indigo-pink.dark .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.3);
}
.indigo-pink.dark .mat-calendar-body-in-range::before {
  background: rgba(159, 168, 218, 0.2);
}
.indigo-pink.dark .mat-calendar-body-comparison-identical,
.indigo-pink.dark .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.indigo-pink.dark .mat-calendar-body-comparison-bridge-start::before,
.indigo-pink.dark [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(159, 168, 218, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.indigo-pink.dark .mat-calendar-body-comparison-bridge-end::before,
.indigo-pink.dark [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(159, 168, 218, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.indigo-pink.dark .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.indigo-pink.dark .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.indigo-pink.dark .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.indigo-pink.dark .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.indigo-pink.dark .mat-calendar-body-selected {
  background-color: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(159, 168, 218, 0.4);
}
.indigo-pink.dark .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.indigo-pink.dark .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(159, 168, 218, 0.3);
}
@media (hover: hover) {
  .indigo-pink.dark .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(159, 168, 218, 0.3);
  }
}
.indigo-pink.dark .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #424242;
  color: white;
}
.indigo-pink.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(244, 143, 177, 0.2);
}
.indigo-pink.dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.indigo-pink.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.indigo-pink.dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.indigo-pink.dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 143, 177, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.indigo-pink.dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.indigo-pink.dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 143, 177, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.indigo-pink.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.indigo-pink.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.indigo-pink.dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.indigo-pink.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.indigo-pink.dark .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #f48fb1;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 143, 177, 0.4);
}
.indigo-pink.dark .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.indigo-pink.dark .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(244, 143, 177, 0.3);
}
@media (hover: hover) {
  .indigo-pink.dark .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(244, 143, 177, 0.3);
  }
}
.indigo-pink.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(229, 115, 115, 0.2);
}
.indigo-pink.dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.indigo-pink.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.indigo-pink.dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.indigo-pink.dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(229, 115, 115, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.indigo-pink.dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.indigo-pink.dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(229, 115, 115, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.indigo-pink.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.indigo-pink.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.indigo-pink.dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.indigo-pink.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.indigo-pink.dark .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #e57373;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(229, 115, 115, 0.4);
}
.indigo-pink.dark .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.indigo-pink.dark .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(229, 115, 115, 0.3);
}
@media (hover: hover) {
  .indigo-pink.dark .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(229, 115, 115, 0.3);
  }
}
.indigo-pink.dark .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.indigo-pink.dark .mat-datepicker-toggle-active {
  color: #9fa8da;
}
.indigo-pink.dark .mat-datepicker-toggle-active.mat-accent {
  color: #f48fb1;
}
.indigo-pink.dark .mat-datepicker-toggle-active.mat-warn {
  color: #e57373;
}
.indigo-pink.dark .mat-date-range-input-inner[disabled] {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-pink.dark .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.indigo-pink.dark .mat-divider {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark .mat-divider-vertical {
  border-right-color: rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark .mat-expansion-panel {
  background: #424242;
  color: white;
}
.indigo-pink.dark .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.indigo-pink.dark .mat-action-row {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .indigo-pink.dark .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .indigo-pink.dark .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(255, 255, 255, 0.04);
}
@media (hover: none) {
  .indigo-pink.dark .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: #424242;
  }
}
.indigo-pink.dark .mat-expansion-panel-header-title {
  color: white;
}
.indigo-pink.dark .mat-expansion-panel-header-description,
.indigo-pink.dark .mat-expansion-indicator::after {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-pink.dark .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(255, 255, 255, 0.3);
}
.indigo-pink.dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.indigo-pink.dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.indigo-pink.dark .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-pink.dark .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-pink.dark .mat-form-field.mat-focused .mat-form-field-label {
  color: #9fa8da;
}
.indigo-pink.dark .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #f48fb1;
}
.indigo-pink.dark .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #e57373;
}
.indigo-pink.dark .mat-focused .mat-form-field-required-marker {
  color: #f48fb1;
}
.indigo-pink.dark .mat-form-field-ripple {
  background-color: white;
}
.indigo-pink.dark .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #9fa8da;
}
.indigo-pink.dark .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #f48fb1;
}
.indigo-pink.dark .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #e57373;
}
.indigo-pink.dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #9fa8da;
}
.indigo-pink.dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #f48fb1;
}
.indigo-pink.dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #e57373;
}
.indigo-pink.dark .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #e57373;
}
.indigo-pink.dark .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.indigo-pink.dark .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #e57373;
}
.indigo-pink.dark .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.indigo-pink.dark .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #e57373;
}
.indigo-pink.dark .mat-error {
  color: #e57373;
}
.indigo-pink.dark .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-pink.dark .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-pink.dark .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.indigo-pink.dark .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.indigo-pink.dark .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.indigo-pink.dark .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.indigo-pink.dark .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.1);
}
.indigo-pink.dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.05);
}
.indigo-pink.dark .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(255, 255, 255, 0.5);
}
.indigo-pink.dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-pink.dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.indigo-pink.dark .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.3);
}
.indigo-pink.dark .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: white;
}
.indigo-pink.dark .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #9fa8da;
}
.indigo-pink.dark .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #f48fb1;
}
.indigo-pink.dark .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #e57373;
}
.indigo-pink.dark .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #e57373;
}
.indigo-pink.dark .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-pink.dark .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.15);
}
.indigo-pink.dark .mat-icon.mat-primary {
  color: #9fa8da;
}
.indigo-pink.dark .mat-icon.mat-accent {
  color: #f48fb1;
}
.indigo-pink.dark .mat-icon.mat-warn {
  color: #e57373;
}
.indigo-pink.dark .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-pink.dark .mat-input-element:disabled,
.indigo-pink.dark .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-pink.dark .mat-input-element {
  caret-color: #9fa8da;
}
.indigo-pink.dark .mat-input-element::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-pink.dark .mat-input-element::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-pink.dark .mat-input-element::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-pink.dark .mat-input-element:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-pink.dark .mat-input-element:not(.mat-native-select-inline) option {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark .mat-input-element:not(.mat-native-select-inline) option:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-pink.dark .mat-form-field.mat-accent .mat-input-element {
  caret-color: #f48fb1;
}
.indigo-pink.dark .mat-form-field.mat-warn .mat-input-element,
.indigo-pink.dark .mat-form-field-invalid .mat-input-element {
  caret-color: #e57373;
}
.indigo-pink.dark .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #e57373;
}
.indigo-pink.dark .mat-list-base .mat-list-item {
  color: white;
}
.indigo-pink.dark .mat-list-base .mat-list-option {
  color: white;
}
.indigo-pink.dark .mat-list-base .mat-subheader {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-pink.dark .mat-list-base .mat-list-item-disabled {
  background-color: rgba(255, 255, 255, 0.12);
  color: rgba(255, 255, 255, 0.5);
}
.indigo-pink.dark .mat-list-option:hover, .indigo-pink.dark .mat-list-option:focus,
.indigo-pink.dark .mat-nav-list .mat-list-item:hover,
.indigo-pink.dark .mat-nav-list .mat-list-item:focus,
.indigo-pink.dark .mat-action-list .mat-list-item:hover,
.indigo-pink.dark .mat-action-list .mat-list-item:focus {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-pink.dark .mat-list-single-selected-option, .indigo-pink.dark .mat-list-single-selected-option:hover, .indigo-pink.dark .mat-list-single-selected-option:focus {
  background: rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark .mat-menu-panel {
  background: #424242;
}
.indigo-pink.dark .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.indigo-pink.dark .mat-menu-item {
  background: transparent;
  color: white;
}
.indigo-pink.dark .mat-menu-item[disabled],
.indigo-pink.dark .mat-menu-item[disabled] .mat-menu-submenu-icon,
.indigo-pink.dark .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-pink.dark .mat-menu-item .mat-icon-no-color,
.indigo-pink.dark .mat-menu-submenu-icon {
  color: white;
}
.indigo-pink.dark .mat-menu-item:hover:not([disabled]),
.indigo-pink.dark .mat-menu-item.cdk-program-focused:not([disabled]),
.indigo-pink.dark .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.indigo-pink.dark .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-pink.dark .mat-paginator {
  background: #424242;
}
.indigo-pink.dark .mat-paginator,
.indigo-pink.dark .mat-paginator-page-size .mat-select-trigger {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-pink.dark .mat-paginator-decrement,
.indigo-pink.dark .mat-paginator-increment {
  border-top: 2px solid white;
  border-right: 2px solid white;
}
.indigo-pink.dark .mat-paginator-first,
.indigo-pink.dark .mat-paginator-last {
  border-top: 2px solid white;
}
.indigo-pink.dark .mat-icon-button[disabled] .mat-paginator-decrement,
.indigo-pink.dark .mat-icon-button[disabled] .mat-paginator-increment,
.indigo-pink.dark .mat-icon-button[disabled] .mat-paginator-first,
.indigo-pink.dark .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(255, 255, 255, 0.5);
}
.indigo-pink.dark .mat-progress-bar-background {
  fill: #4c4e5b;
}
.indigo-pink.dark .mat-progress-bar-buffer {
  background-color: #4c4e5b;
}
.indigo-pink.dark .mat-progress-bar-fill::after {
  background-color: #9fa8da;
}
.indigo-pink.dark .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #614850;
}
.indigo-pink.dark .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #614850;
}
.indigo-pink.dark .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #f48fb1;
}
.indigo-pink.dark .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #5d4141;
}
.indigo-pink.dark .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #5d4141;
}
.indigo-pink.dark .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #e57373;
}
.indigo-pink.dark .mat-progress-spinner circle, .indigo-pink.dark .mat-spinner circle {
  stroke: #9fa8da;
}
.indigo-pink.dark .mat-progress-spinner.mat-accent circle, .indigo-pink.dark .mat-spinner.mat-accent circle {
  stroke: #f48fb1;
}
.indigo-pink.dark .mat-progress-spinner.mat-warn circle, .indigo-pink.dark .mat-spinner.mat-warn circle {
  stroke: #e57373;
}
.indigo-pink.dark .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.7);
}
.indigo-pink.dark .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #9fa8da;
}
.indigo-pink.dark .mat-radio-button.mat-primary .mat-radio-inner-circle,
.indigo-pink.dark .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .indigo-pink.dark .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .indigo-pink.dark .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #9fa8da;
}
.indigo-pink.dark .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f48fb1;
}
.indigo-pink.dark .mat-radio-button.mat-accent .mat-radio-inner-circle,
.indigo-pink.dark .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .indigo-pink.dark .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .indigo-pink.dark .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #f48fb1;
}
.indigo-pink.dark .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e57373;
}
.indigo-pink.dark .mat-radio-button.mat-warn .mat-radio-inner-circle,
.indigo-pink.dark .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .indigo-pink.dark .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .indigo-pink.dark .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #e57373;
}
.indigo-pink.dark .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.indigo-pink.dark .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.5);
}
.indigo-pink.dark .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.indigo-pink.dark .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(255, 255, 255, 0.5);
}
.indigo-pink.dark .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-pink.dark .mat-radio-button .mat-ripple-element {
  background-color: white;
}
.indigo-pink.dark .mat-select-value {
  color: white;
}
.indigo-pink.dark .mat-select-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-pink.dark .mat-select-disabled .mat-select-value {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-pink.dark .mat-select-arrow {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-pink.dark .mat-select-panel {
  background: #424242;
}
.indigo-pink.dark .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.indigo-pink.dark .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #9fa8da;
}
.indigo-pink.dark .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #f48fb1;
}
.indigo-pink.dark .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #e57373;
}
.indigo-pink.dark .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #e57373;
}
.indigo-pink.dark .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-pink.dark .mat-drawer-container {
  background-color: #303030;
  color: white;
}
.indigo-pink.dark .mat-drawer {
  background-color: #424242;
  color: white;
}
.indigo-pink.dark .mat-drawer.mat-drawer-push {
  background-color: #424242;
}
.indigo-pink.dark .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.indigo-pink.dark .mat-drawer-side {
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.indigo-pink.dark [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.indigo-pink.dark [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(189, 189, 189, 0.6);
}
.indigo-pink.dark .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #f48fb1;
}
.indigo-pink.dark .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(244, 143, 177, 0.54);
}
.indigo-pink.dark .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #f48fb1;
}
.indigo-pink.dark .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #9fa8da;
}
.indigo-pink.dark .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(159, 168, 218, 0.54);
}
.indigo-pink.dark .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #9fa8da;
}
.indigo-pink.dark .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #e57373;
}
.indigo-pink.dark .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(229, 115, 115, 0.54);
}
.indigo-pink.dark .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #e57373;
}
.indigo-pink.dark .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: white;
}
.indigo-pink.dark .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #bdbdbd;
}
.indigo-pink.dark .mat-slide-toggle-bar {
  background-color: rgba(255, 255, 255, 0.5);
}
.indigo-pink.dark .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.indigo-pink.dark .mat-slider.mat-primary .mat-slider-track-fill,
.indigo-pink.dark .mat-slider.mat-primary .mat-slider-thumb,
.indigo-pink.dark .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #9fa8da;
}
.indigo-pink.dark .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(159, 168, 218, 0.2);
}
.indigo-pink.dark .mat-slider.mat-accent .mat-slider-track-fill,
.indigo-pink.dark .mat-slider.mat-accent .mat-slider-thumb,
.indigo-pink.dark .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #f48fb1;
}
.indigo-pink.dark .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(244, 143, 177, 0.2);
}
.indigo-pink.dark .mat-slider.mat-warn .mat-slider-track-fill,
.indigo-pink.dark .mat-slider.mat-warn .mat-slider-thumb,
.indigo-pink.dark .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #e57373;
}
.indigo-pink.dark .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(229, 115, 115, 0.2);
}
.indigo-pink.dark .mat-slider:hover .mat-slider-track-background,
.indigo-pink.dark .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.indigo-pink.dark .mat-slider.mat-slider-disabled .mat-slider-track-background,
.indigo-pink.dark .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.indigo-pink.dark .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(255, 255, 255, 0.3);
}
.indigo-pink.dark .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.indigo-pink.dark .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.indigo-pink.dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: white;
}
.indigo-pink.dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.indigo-pink.dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(255, 255, 255, 0.3);
}
.indigo-pink.dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
  background-color: transparent;
}
.indigo-pink.dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .indigo-pink.dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.indigo-pink.dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .indigo-pink.dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.indigo-pink.dark .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(255, 255, 255, 0.7);
}
.indigo-pink.dark .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.indigo-pink.dark .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.indigo-pink.dark .mat-step-header.cdk-keyboard-focused, .indigo-pink.dark .mat-step-header.cdk-program-focused, .indigo-pink.dark .mat-step-header:hover:not([aria-disabled]), .indigo-pink.dark .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(255, 255, 255, 0.04);
}
.indigo-pink.dark .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .indigo-pink.dark .mat-step-header:hover {
    background: none;
  }
}
.indigo-pink.dark .mat-step-header .mat-step-label,
.indigo-pink.dark .mat-step-header .mat-step-optional {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-pink.dark .mat-step-header .mat-step-icon {
  background-color: rgba(255, 255, 255, 0.7);
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark .mat-step-header .mat-step-icon-selected,
.indigo-pink.dark .mat-step-header .mat-step-icon-state-done,
.indigo-pink.dark .mat-step-header .mat-step-icon-state-edit {
  background-color: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark .mat-step-header.mat-accent .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark .mat-step-header.mat-accent .mat-step-icon-selected,
.indigo-pink.dark .mat-step-header.mat-accent .mat-step-icon-state-done,
.indigo-pink.dark .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #f48fb1;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark .mat-step-header.mat-warn .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark .mat-step-header.mat-warn .mat-step-icon-selected,
.indigo-pink.dark .mat-step-header.mat-warn .mat-step-icon-state-done,
.indigo-pink.dark .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #e57373;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #e57373;
}
.indigo-pink.dark .mat-step-header .mat-step-label.mat-step-label-active {
  color: white;
}
.indigo-pink.dark .mat-step-header .mat-step-label.mat-step-label-error {
  color: #e57373;
}
.indigo-pink.dark .mat-stepper-horizontal, .indigo-pink.dark .mat-stepper-vertical {
  background-color: #424242;
}
.indigo-pink.dark .mat-stepper-vertical-line::before {
  border-left-color: rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark .mat-horizontal-stepper-header::before,
.indigo-pink.dark .mat-horizontal-stepper-header::after,
.indigo-pink.dark .mat-stepper-horizontal-line {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark .mat-sort-header-arrow {
  color: #c6c6c6;
}
.indigo-pink.dark .mat-tab-nav-bar,
.indigo-pink.dark .mat-tab-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark .mat-tab-group-inverted-header .mat-tab-nav-bar,
.indigo-pink.dark .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  border-bottom: none;
}
.indigo-pink.dark .mat-tab-label, .indigo-pink.dark .mat-tab-link {
  color: white;
}
.indigo-pink.dark .mat-tab-label.mat-tab-disabled, .indigo-pink.dark .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-pink.dark .mat-tab-header-pagination-chevron {
  border-color: white;
}
.indigo-pink.dark .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.5);
}
.indigo-pink.dark .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.indigo-pink.dark .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.indigo-pink.dark .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-pink.dark .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-pink.dark .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-pink.dark .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .indigo-pink.dark .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-pink.dark .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-pink.dark .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-pink.dark .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(197, 202, 233, 0.3);
}
.indigo-pink.dark .mat-tab-group.mat-primary .mat-ink-bar, .indigo-pink.dark .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #9fa8da;
}
.indigo-pink.dark .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .indigo-pink.dark .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .indigo-pink.dark .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .indigo-pink.dark .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-pink.dark .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-pink.dark .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-pink.dark .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .indigo-pink.dark .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-pink.dark .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-pink.dark .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-pink.dark .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 187, 208, 0.3);
}
.indigo-pink.dark .mat-tab-group.mat-accent .mat-ink-bar, .indigo-pink.dark .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #f48fb1;
}
.indigo-pink.dark .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .indigo-pink.dark .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .indigo-pink.dark .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .indigo-pink.dark .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-pink.dark .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-pink.dark .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-pink.dark .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .indigo-pink.dark .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-pink.dark .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-pink.dark .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-pink.dark .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.indigo-pink.dark .mat-tab-group.mat-warn .mat-ink-bar, .indigo-pink.dark .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #e57373;
}
.indigo-pink.dark .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .indigo-pink.dark .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .indigo-pink.dark .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .indigo-pink.dark .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-pink.dark .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-pink.dark .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-pink.dark .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .indigo-pink.dark .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-pink.dark .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-pink.dark .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-pink.dark .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(197, 202, 233, 0.3);
}
.indigo-pink.dark .mat-tab-group.mat-background-primary > .mat-tab-header, .indigo-pink.dark .mat-tab-group.mat-background-primary > .mat-tab-link-container, .indigo-pink.dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .indigo-pink.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .indigo-pink.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .indigo-pink.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #9fa8da;
}
.indigo-pink.dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .indigo-pink.dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .indigo-pink.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .indigo-pink.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .indigo-pink.dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .indigo-pink.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .indigo-pink.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.indigo-pink.dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.indigo-pink.dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.indigo-pink.dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.indigo-pink.dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .indigo-pink.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.indigo-pink.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.indigo-pink.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.indigo-pink.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.indigo-pink.dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .indigo-pink.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.indigo-pink.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.indigo-pink.dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.indigo-pink.dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.indigo-pink.dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .indigo-pink.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.indigo-pink.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.indigo-pink.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.indigo-pink.dark .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-pink.dark .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-pink.dark .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-pink.dark .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .indigo-pink.dark .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-pink.dark .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-pink.dark .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-pink.dark .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 187, 208, 0.3);
}
.indigo-pink.dark .mat-tab-group.mat-background-accent > .mat-tab-header, .indigo-pink.dark .mat-tab-group.mat-background-accent > .mat-tab-link-container, .indigo-pink.dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .indigo-pink.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .indigo-pink.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .indigo-pink.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #f48fb1;
}
.indigo-pink.dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .indigo-pink.dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .indigo-pink.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .indigo-pink.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .indigo-pink.dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .indigo-pink.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .indigo-pink.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.indigo-pink.dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.indigo-pink.dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.indigo-pink.dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.indigo-pink.dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .indigo-pink.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.indigo-pink.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.indigo-pink.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.indigo-pink.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.indigo-pink.dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .indigo-pink.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.indigo-pink.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.indigo-pink.dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.indigo-pink.dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.indigo-pink.dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .indigo-pink.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.indigo-pink.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.indigo-pink.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.indigo-pink.dark .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-pink.dark .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-pink.dark .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-pink.dark .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .indigo-pink.dark .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-pink.dark .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-pink.dark .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-pink.dark .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.indigo-pink.dark .mat-tab-group.mat-background-warn > .mat-tab-header, .indigo-pink.dark .mat-tab-group.mat-background-warn > .mat-tab-link-container, .indigo-pink.dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .indigo-pink.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .indigo-pink.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .indigo-pink.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #e57373;
}
.indigo-pink.dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .indigo-pink.dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .indigo-pink.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .indigo-pink.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .indigo-pink.dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .indigo-pink.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .indigo-pink.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.indigo-pink.dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.indigo-pink.dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.indigo-pink.dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.indigo-pink.dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .indigo-pink.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.indigo-pink.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.indigo-pink.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.indigo-pink.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.indigo-pink.dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .indigo-pink.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.indigo-pink.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.indigo-pink.dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.indigo-pink.dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.indigo-pink.dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .indigo-pink.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.indigo-pink.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.indigo-pink.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.indigo-pink.dark .mat-toolbar {
  background: #212121;
  color: white;
}
.indigo-pink.dark .mat-toolbar.mat-primary {
  background: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark .mat-toolbar.mat-accent {
  background: #f48fb1;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark .mat-toolbar.mat-warn {
  background: #e57373;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark .mat-toolbar .mat-form-field-underline,
.indigo-pink.dark .mat-toolbar .mat-form-field-ripple,
.indigo-pink.dark .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.indigo-pink.dark .mat-toolbar .mat-form-field-label,
.indigo-pink.dark .mat-toolbar .mat-focused .mat-form-field-label,
.indigo-pink.dark .mat-toolbar .mat-select-value,
.indigo-pink.dark .mat-toolbar .mat-select-arrow,
.indigo-pink.dark .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.indigo-pink.dark .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.indigo-pink.dark .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.indigo-pink.dark .mat-tree {
  background: #424242;
}
.indigo-pink.dark .mat-tree-node,
.indigo-pink.dark .mat-nested-tree-node {
  color: white;
}
.indigo-pink.dark .mat-snack-bar-container {
  color: rgba(0, 0, 0, 0.87);
  background: #fafafa;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.indigo-pink.dark .mat-simple-snackbar-action {
  color: inherit;
}
.indigo-pink.dark *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.indigo-pink.dark *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
.indigo-pink.dark *::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}
.indigo-pink.dark .toolbar-line.container-ou {
  background: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark .toolbar-line.container-persona {
  background: #9fa8da !important;
}
.indigo-pink.dark .toolbar-line.container-persona .persona-title {
  background: #9fa8da;
}
.indigo-pink.dark .toolbar-line.container-persona .invisible-container.persona-selector {
  background: #9fa8da !important;
}
.indigo-pink.dark button.mat-icon-button:not([disabled]):hover {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-pink.dark .side-bar {
  background: black;
}
.indigo-pink.dark .icon-stacked {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-pink.dark .icon-stacked mat-icon + mat-icon {
  text-shadow: -1px 1px 0px #9fa8da, 1px -1px 0px #9fa8da;
}
.indigo-pink.dark .divider-vert {
  background: rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark .avatar {
  background: #304ffe !important;
}
.indigo-pink.dark ngx-avatar .avatar-content {
  background: #304ffe !important;
}
.indigo-pink.dark .mat-loading-window {
  background: #424242;
}
.indigo-pink.dark .window-spinner {
  background: #424242;
}
.indigo-pink.dark .load-window {
  background: #424242;
}
@media (min-width: 600px) and (max-width: 1300px) {
  .indigo-pink.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .load-window,
.indigo-pink.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .load-window.new {
    background: #424242 !important;
  }
  .indigo-pink.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card {
    background: #424242 !important;
  }
  .indigo-pink.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .inbox-container mat-toolbar {
    background: #9fa8da;
    color: rgba(0, 0, 0, 0.87);
  }
  .indigo-pink.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .inbox-container mat-list-item.mat-list-item {
    border: 1px solid rgba(255, 255, 255, 0.12);
  }
  .indigo-pink.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .detail {
    background: #424242 !important;
  }
  .indigo-pink.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .detail mat-toolbar {
    background: #424242 !important;
  }
  .indigo-pink.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .main.w-detail + .new-detail-container .load-window {
    background: #303030 !important;
  }
  .indigo-pink.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .main.w-detail + .new-detail-container:before {
    background: rgba(0, 0, 0, 0.6);
  }
  .indigo-pink.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .main.side-open + .side {
    border-left: 1px solid rgba(255, 255, 255, 0.12);
  }
  .indigo-pink.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .side .container .mat-list-base {
    background: #424242 !important;
    border: 1px solid rgba(255, 255, 255, 0.12);
  }
  .indigo-pink.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .new-table .mat-header-row,
.indigo-pink.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .new-table .mat-table,
.indigo-pink.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .document-table .mat-header-row,
.indigo-pink.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .document-table .mat-table,
.indigo-pink.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .status-table .mat-header-row,
.indigo-pink.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .status-table .mat-table {
    background: #424242 !important;
  }
  .indigo-pink.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .audit-filter .filter-container.is-floating {
    background: #303030 !important;
    border: 1px solid rgba(255, 255, 255, 0.12);
  }
  .indigo-pink.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .audit-filter .filter-container.is-floating .form-body {
    background: #303030 !important;
  }
  .indigo-pink.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .audit-result {
    background: #424242;
  }
  .indigo-pink.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .audit-detail-container {
    background: #303030 !important;
  }
}
@media (max-width: 599px) {
  .indigo-pink.dark app-document-detail .mat-toolbar {
    background: #304ffe !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .indigo-pink.dark app-document-detail .mat-toolbar .mat-toolbar-row .toolbar-actions {
    background: #304ffe !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .indigo-pink.dark .login-card {
    background: #424242 !important;
  }
  .indigo-pink.dark .inbox-toolbar {
    background: #304ffe !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .indigo-pink.dark .detail {
    background: #424242 url("../img/spinner_mobile.gif") no-repeat center center !important;
  }
  .indigo-pink.dark .toolbar-line-container .toolbar-line:first-child {
    background: #304ffe !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .indigo-pink.dark .toolbar-actions {
    background: #304ffe !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .indigo-pink.dark mat-drawer.side-nav.enter {
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(255, 255, 255, 0.12) !important;
  }
  .indigo-pink.dark .side-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0) !important;
  }
  .indigo-pink.dark .filter {
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.55) 16%, rgba(0, 0, 0, 0) 100%) !important;
  }
  .indigo-pink.dark button.side-open-mobile {
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .indigo-pink.dark .side-nav .side-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0);
  }
  .indigo-pink.dark .side-nav .filter {
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.55) 16%, rgba(0, 0, 0, 0) 100%);
  }
  .indigo-pink.dark .side-nav .side-nav-container .links {
    background: #424242;
    border-top: 1px solid rgba(255, 255, 255, 0.12);
  }
  .indigo-pink.dark .side-nav .side-nav-container .version {
    background: #424242 !important;
    border-top: 1px solid rgba(255, 255, 255, 0.12);
  }
  .indigo-pink.dark app-file-document-view .view-container {
    background: #424242 !important;
  }
  .indigo-pink.dark app-file-document-view .view-container .side-bar {
    background: #304ffe !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .indigo-pink.dark app-file-document-view .view-container .view-content app-pdf-wrapper.floating {
    background: #424242;
  }
  .indigo-pink.dark app-file-document-view .view-container .view-content .upload-indications {
    background: black;
  }
  .indigo-pink.dark app-file-document-view .view-container .view-content app-file-document-sign .sign-cycle mat-horizontal-stepper.mat-stepper-horizontal {
    background-color: rgba(0, 0, 0, 0) !important;
  }
  .indigo-pink.dark app-file-document-view .view-container .view-content app-file-document-sign .sign-cycle .sign-cycle-content .cycle-content-action .mat-ripple-element {
    background-color: rgba(255, 255, 255, 0.1) !important;
  }
  .indigo-pink.dark app-pending .floating-container .inbox-container .inbox-toolbar .mat-toolbar-row .mat-avatar h3 {
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .indigo-pink.dark app-pending .floating-container .detail {
    background: #424242 url("../img/spinner_mobile.gif") no-repeat center center !important;
  }
  .indigo-pink.dark app-document-sign-massive .inbox-container .pending-container app-chapa.minimized .info-empty {
    background: #f48fb1;
  }
  .indigo-pink.dark app-document-sign-massive .inbox-container .pending-container app-chapa.minimized .info-empty > span h2 {
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .indigo-pink.dark app-document-sign-massive .inbox-container .signer.mobile {
    border-top: 1px solid rgba(255, 255, 255, 0.12);
    background: #424242;
  }
  .indigo-pink.dark app-document-sign-massive .detail app-file-document-detail .view-container .mat-toolbar .mat-toolbar-row .view-actions {
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .indigo-pink.dark app-add-documentation .floating-container .employee-add-documentation .inbox-container .inbox-toolbar .mat-toolbar-row .mat-avatar h3 {
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .indigo-pink.dark app-add-documentation .floating-container .employee-add-documentation .detail {
    background: #424242 url("../img/spinner_mobile.gif") no-repeat center center !important;
  }
  .indigo-pink.dark app-add-documentation .floating-container .employee-add-documentation .detail app-file-document-view .view-container .view-content .new-container app-file-document-upload {
    background: #424242;
  }
}
.indigo-pink.dark .mat-card .mat-drawer-container {
  background: #424242 !important;
}
.indigo-pink.dark .mat-drawer-container {
  background: #212121 !important;
}
.indigo-pink.dark .mat-list .mat-list-item.finished-process-inbox.active {
  background: #009688 !important;
}
.indigo-pink.dark .mat-list .mat-list-item.finished-process-inbox .mat-icon {
  background: #009688;
}
.indigo-pink.dark .mat-list .mat-list-item.erased-process-inbox.active {
  background: #837C80 !important;
}
.indigo-pink.dark .mat-list .mat-list-item.limbo-process-inbox.active {
  background: #ffc107 !important;
}
.indigo-pink.dark .mat-list .mat-list-item.limbo-process-inbox .mat-icon {
  background: #ffc107;
}
.indigo-pink.dark .mat-list .mat-list-item.in-process-inbox.active {
  background: #ffc107 !important;
}
.indigo-pink.dark .mat-list .mat-list-item.in-process-inbox .mat-icon {
  background: #ffc107;
}
.indigo-pink.dark .mat-list .mat-list-item.error-process-inbox.active {
  background: #e91e63 !important;
}
.indigo-pink.dark .mat-list .mat-list-item.error-process-inbox .mat-icon {
  background: #e91e63;
}
.indigo-pink.dark .mat-list .mat-list-item.active .mat-line b {
  color: #9fa8da;
}
.indigo-pink.dark .mat-list .mat-list-item.active .mat-list-avatar {
  color: #9fa8da;
}
.indigo-pink.dark .mat-list .mat-list-item:hover:not(.active) {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-pink.dark span.mat-badge-content {
  color: #e57373 !important;
}
.indigo-pink.dark mat-expansion-panel.mat-expanded {
  background: #303030 !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark mat-expansion-panel.virtual-scrolling .mat-expansion-panel-content .get-more {
  color: #9fa8da;
}
.indigo-pink.dark mat-expansion-panel.virtual-scrolling .mat-expansion-panel-content app-cs-paginator.get-more {
  background: #424242;
}
.indigo-pink.dark mat-expansion-panel-header.mat-expanded {
  background: #424242 !important;
}
.indigo-pink.dark .bottom-container {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark .load-more * {
  color: #9fa8da;
}
.indigo-pink.dark .welcome-card {
  background: #424242 !important;
}
.indigo-pink.dark .welcome-card .img-divider {
  background: #304ffe;
}
.indigo-pink.dark .mat-header-row {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  background: #424242;
}
.indigo-pink.dark .mat-row {
  background: #424242;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.indigo-pink.dark .mat-row .mat-cell.status-cell mat-icon.pending {
  color: #ffb74d !important;
}
.indigo-pink.dark .mat-row .mat-cell.status-cell mat-icon.ok {
  color: #4db6ac !important;
}
.indigo-pink.dark .mat-row .mat-cell.status-cell mat-icon.not-ok {
  color: #ba68c8 !important;
}
.indigo-pink.dark .mat-row .mat-cell.status-cell mat-icon.cancelled {
  color: #795548 !important;
}
.indigo-pink.dark .mat-row .mat-cell.status-cell mat-icon.rejected {
  color: #df4d4c !important;
}
.indigo-pink.dark .mat-row .mat-cell.status-cell mat-icon.draft {
  color: #dedede !important;
}
.indigo-pink.dark .mat-row.active, .indigo-pink.dark .mat-row.active:hover {
  background: #9fa8da !important;
}
.indigo-pink.dark .mat-row.active div, .indigo-pink.dark .mat-row.active:hover div {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark .mat-row.active .mat-cell.status-cell mat-icon.pending, .indigo-pink.dark .mat-row.active .mat-cell.status-cell mat-icon.ok, .indigo-pink.dark .mat-row.active .mat-cell.status-cell mat-icon.not-ok, .indigo-pink.dark .mat-row.active:hover .mat-cell.status-cell mat-icon.pending, .indigo-pink.dark .mat-row.active:hover .mat-cell.status-cell mat-icon.ok, .indigo-pink.dark .mat-row.active:hover .mat-cell.status-cell mat-icon.not-ok {
  text-shadow: -1px 1px 0 rgba(0, 0, 0, 0.87), 1px 1px 0 rgba(0, 0, 0, 0.87), 1px -1px 0 rgba(0, 0, 0, 0.87), -1px -1px 0 rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark .mat-row.active .sticky-actions button, .indigo-pink.dark .mat-row.active:hover .sticky-actions button {
  background: #9fa8da !important;
}
.indigo-pink.dark .mat-row.active .sticky-actions button.ok mat-icon, .indigo-pink.dark .mat-row.active .sticky-actions button.not-ok mat-icon, .indigo-pink.dark .mat-row.active:hover .sticky-actions button.ok mat-icon, .indigo-pink.dark .mat-row.active:hover .sticky-actions button.not-ok mat-icon {
  text-shadow: -1px 1px 0 rgba(0, 0, 0, 0.87), 1px 1px 0 rgba(0, 0, 0, 0.87), 1px -1px 0 rgba(0, 0, 0, 0.87), -1px -1px 0 rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark .mat-row:nth-child(odd) {
  background: #303030;
}
.indigo-pink.dark .mat-row.selected div {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark .mat-row.selected:nth-child(even) {
  background: #9fa8da !important;
}
.indigo-pink.dark .mat-row.selected:nth-child(even) .sticky-actions button {
  background: #9fa8da !important;
}
.indigo-pink.dark .mat-row.selected:nth-child(odd) {
  background: #c5cae9 !important;
}
.indigo-pink.dark .mat-row.selected:nth-child(odd) .sticky-actions button {
  background: #c5cae9 !important;
}
.indigo-pink.dark .mat-row.selected.active, .indigo-pink.dark .mat-row.selected.active:hover {
  background: #9fa8da !important;
}
.indigo-pink.dark .mat-row.selected.active .sticky-actions button, .indigo-pink.dark .mat-row.selected.active:hover .sticky-actions button {
  background: #9fa8da !important;
}
.indigo-pink.dark .mat-row:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-pink.dark .mat-row:hover .sticky-actions button {
  background: #424242 !important;
}
.indigo-pink.dark .mat-row.canceled * {
  color: #795548;
}
.indigo-pink.dark .mat-row.disabled div {
  color: #607D8B;
}
.indigo-pink.dark .mat-row.disabled.active, .indigo-pink.dark .mat-row.disabled.active:hover {
  background: #607D8B !important;
}
.indigo-pink.dark .mat-row.disabled.active div, .indigo-pink.dark .mat-row.disabled.active:hover div {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark .mat-row.disabled.active .sticky-actions button, .indigo-pink.dark .mat-row.disabled.active:hover .sticky-actions button {
  background: #607D8B !important;
}
.indigo-pink.dark .mat-row.disabled.selected.active:hover .sticky-actions button {
  background: #607D8B !important;
}
.indigo-pink.dark .mat-row .sticky-actions button.ok mat-icon {
  color: #4db6ac !important;
}
.indigo-pink.dark .mat-row .sticky-actions button.not-ok mat-icon {
  color: #ba68c8 !important;
}
.indigo-pink.dark .status-table .mat-header-row {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  background: #424242;
}
.indigo-pink.dark .icon-info-off,
.indigo-pink.dark .certificate-off {
  color: #607D8B;
}
.indigo-pink.dark .documents-search-table .mat-header-row {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  background: #424242;
}
.indigo-pink.dark mat-toolbar.employee-search-sub-toolbar {
  background: #424242;
}
.indigo-pink.dark .mat-search-field {
  border: 1px solid rgba(255, 255, 255, 0.12);
  background: black;
}
.indigo-pink.dark .mat-search-field input[type=search] {
  background: rgba(0, 0, 0, 0);
}
.indigo-pink.dark .mat-search-field input[type=search]::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-pink.dark .search-adv-menu .mat-search-field {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark mat-toolbar.document-search-sub-toolbar {
  background: #424242;
}
.indigo-pink.dark app-advanced-employee-search .mat-chip {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-pink.dark .side-nav .side-trigger {
  background: #424242;
}
.indigo-pink.dark .side-nav .menu-trigger mat-icon {
  color: rgba(255, 255, 255, 0.7);
  background: #424242;
}
.indigo-pink.dark .side-nav .side-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0);
}
.indigo-pink.dark .side-nav .filter {
  background: #424242;
}
.indigo-pink.dark .side-nav .side-nav-container .links mat-icon span.mat-badge-content {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-pink.dark .side-nav .side-nav-container .mat-list-base .mat-list-item:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-pink.dark .side-nav .side-nav-container .mat-list-base .mat-list-item.active {
  background: #9fa8da !important;
}
.indigo-pink.dark .side-nav .side-nav-container .mat-list-base .mat-list-item.active * {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark .side-nav .side-nav-container .mat-list-base .mat-list-item.active .mat-list-item.active:hover {
  background: #9fa8da !important;
}
.indigo-pink.dark .side-nav .side-nav-container .mat-list-base .mat-list-item.active .mat-list-item.active:hover * {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark .side-nav.open .side-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark .side-nav.open .filter {
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.55) 16%, rgba(0, 0, 0, 0) 100%);
}
.indigo-pink.dark .side-nav.open .side-nav-container .links {
  background: #424242;
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark .side-nav.open .side-nav-container .version {
  background: #424242 !important;
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark .side-nav.enter .side-nav-container .links {
  background: #424242;
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark .side-nav.enter .side-nav-container .version {
  background: #424242 !important;
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark .mat-menu-panel:not(.metadata-radio-menu) .navigation-list .mat-list-item-content {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-pink.dark table.mat-calendar-table {
  background: #424242 !important;
}
.indigo-pink.dark .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-pink.dark .mat-calendar-arrow {
  border-top-color: #424242 !important;
}
.indigo-pink.dark .mat-datepicker-content .mat-calendar-next-button,
.indigo-pink.dark .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-pink.dark .mat-calendar-header {
  background: #304ffe !important;
}
.indigo-pink.dark mat-datepicker-content {
  background-color: #304ffe !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-pink.dark .mat-calendar-content {
  background: #424242 !important;
}
.indigo-pink.dark .mat-simple-snackbar-action button.mat-button {
  color: #ff9800;
}
.indigo-pink.dark .mat-simple-snackbar-action .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.indigo-pink.dark mat-bottom-sheet-container mat-toolbar {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12) !important;
}
.indigo-pink.dark app-generic-bottom-sheet h4.accent {
  color: #f48fb1;
}
.indigo-pink.dark app-generic-bottom-sheet .verification-code {
  background: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark app-generic-bottom-sheet .verification-code h2 {
  background: #9fa8da;
}
.indigo-pink.dark app-generic-bottom-sheet .load-window.message .info-container h3.primary-text {
  color: #9fa8da;
}
.indigo-pink.dark app-generic-bottom-sheet .bottom_sheet-content.ok .bottom_sheet-title .bottom_sheet-icon,
.indigo-pink.dark app-generic-bottom-sheet .bottom_sheet-content.ok .bottom_sheet-title h3 {
  color: #009688;
}
.indigo-pink.dark app-generic-bottom-sheet .bottom_sheet-content.ok .bottom_sheet-main_btn:not(:disabled) {
  background: #009688;
  color: #fff;
}
.indigo-pink.dark app-generic-bottom-sheet .bottom_sheet-content.not-ok .bottom_sheet-title .bottom_sheet-icon,
.indigo-pink.dark app-generic-bottom-sheet .bottom_sheet-content.not-ok .bottom_sheet-title h3 {
  color: #9C27B0;
}
.indigo-pink.dark app-generic-bottom-sheet .bottom_sheet-content.not-ok .bottom_sheet-main_btn:not(:disabled) {
  background: #9C27B0;
  color: #fff;
}
.indigo-pink.dark app-generic-bottom-sheet .bottom_sheet-content.cancel .bottom_sheet-title .bottom_sheet-icon,
.indigo-pink.dark app-generic-bottom-sheet .bottom_sheet-content.cancel .bottom_sheet-title h3 {
  color: #795548;
}
.indigo-pink.dark app-generic-bottom-sheet .bottom_sheet-content.cancel .bottom_sheet-main_btn:not(:disabled) {
  background: #795548;
  color: #fff;
}
.indigo-pink.dark app-generic-bottom-sheet .bottom_sheet-content .bottom_sheet_exp_btn {
  background-color: #9fa8da;
  color: #fff;
}
.indigo-pink.dark app-generic-bottom-sheet .bottom_sheet-content .bottom_sheet_canc_btn {
  background-color: lightgrey;
  color: black;
}
.indigo-pink.dark app-generic-bottom-sheet .bottom_sheet-content .bottom_sheet-exp-h3 {
  color: #9fa8da;
  margin-bottom: -10px !important;
}
.indigo-pink.dark app-generic-bottom-sheet .bottom_sheet-content .mat-input-element-export {
  display: flex !important;
  padding-left: 15px;
}
.indigo-pink.dark app-generic-bottom-sheet .bottom_sheet-content .mat-form-field-infix-export-right {
  width: 100% !important;
  padding: 0px !important;
  display: flex !important;
}
.indigo-pink.dark app-generic-bottom-sheet .bottom_sheet-content .mat-form-field-infix-export-left {
  width: 100% !important;
  padding: 0px;
  display: flex !important;
}
.indigo-pink.dark app-generic-bottom-sheet .bottom_sheet-content .mat-form-field-infix {
  display: flex;
}
.indigo-pink.dark app-generic-bottom-sheet .bottom_sheet-content .mat-checkbox-layout .mat-checkbox-label {
  color: #f48fb1;
}
.indigo-pink.dark app-generic-bottom-sheet .bottom_sheet-content .choose-forms {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  margin-left: 10px !important;
}
.indigo-pink.dark app-generic-bottom-sheet .bottom_sheet-content .card-container {
  max-height: 150px;
  overflow-y: auto;
}
.indigo-pink.dark .mat-list .mat-list-item.active .mat-line {
  color: white;
}
.indigo-pink.dark .mat-list .mat-list-item.active .mat-line b {
  color: #9fa8da;
}
.indigo-pink.dark .mat-list .mat-list-item.active .mat-list-avatar {
  color: #9fa8da;
}
.indigo-pink.dark .mat-list .mat-list-item .mat-line {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-pink.dark .mat-list .mat-list-item .mat-list-avatar {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-pink.dark .mat-list-item:hover:not(.active) {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-pink.dark mat-list-item:nth-child(even),
.indigo-pink.dark app-file-document-inbox-item:nth-child(even) mat-list-item,
.indigo-pink.dark app-file-document-inbox-item-documentation:nth-child(even) mat-list-item {
  background: #303030 !important;
}
.indigo-pink.dark mat-list-item:nth-child(odd),
.indigo-pink.dark app-file-document-inbox-item:nth-child(odd) mat-list-item,
.indigo-pink.dark app-file-document-inbox-item-documentation:nth-child(odd) mat-list-item {
  background: #424242 !important;
}
.indigo-pink.dark .mat-list-avatar.mat-icon {
  background: black;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark .card-filter {
  background: #424242;
}
.indigo-pink.dark .login mat-horizontal-stepper {
  background: #424242 !important;
}
.indigo-pink.dark .login .login-card {
  background: #424242 !important;
}
.indigo-pink.dark .login .login-card .mat-card-content .mat-card-footer a[color=accent] {
  color: #f48fb1;
}
.indigo-pink.dark .login .help-stepper h3.align-16 {
  color: #f48fb1;
}
.indigo-pink.dark .login .help-stepper .help-options mat-icon {
  color: #f48fb1;
}
.indigo-pink.dark .ux-modal {
  background: rgba(0, 0, 0, 0.32);
}
.indigo-pink.dark .ux-modal .card {
  background: #424242;
}
.indigo-pink.dark .main-title {
  background: #607D8B;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark .sub-title {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-pink.dark .card-footer a {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark .profile-toolbar {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark app-profile .logout-btn:hover {
  background: black;
}
.indigo-pink.dark app-profile .cropper {
  background: #424242;
}
.indigo-pink.dark app-profile .cropper .cropper {
  background: unset;
}
.indigo-pink.dark app-add-documentation .employee-add-documentation .inbox-toolbar {
  background: #303030;
}
.indigo-pink.dark app-add-documentation .employee-add-documentation .inbox-toolbar .toolbar-line mat-icon {
  color: #9fa8da;
}
.indigo-pink.dark app-add-documentation .employee-add-documentation .inbox-toolbar .toolbar-line h3 {
  color: #9fa8da;
}
.indigo-pink.dark app-add-documentation .employee-add-documentation mat-tab-group {
  background: #303030;
}
.indigo-pink.dark app-add-documentation .employee-add-documentation mat-tab-group mat-tab-header .mat-tab-label-active {
  color: #9fa8da;
}
.indigo-pink.dark app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content:hover {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(255, 255, 255, 0.12) !important;
}
.indigo-pink.dark app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content.selected {
  border-left: 6px solid #9fa8da !important;
}
.indigo-pink.dark app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content.selected mat-icon {
  color: #9fa8da !important;
}
.indigo-pink.dark app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content.selected .item-data {
  color: white !important;
}
.indigo-pink.dark app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content mat-icon {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-pink.dark app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content .item-data {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-pink.dark app-add-documentation .employee-add-documentation .detail app-file-document-view .new-container app-file-document-upload .metadata-list .mat-subheader {
  color: rgba(255, 255, 255, 0.7);
  background: #424242 !important;
}
.indigo-pink.dark app-pending .inbox-toolbar {
  background: #303030;
}
.indigo-pink.dark app-pending .pending-container {
  background: #303030;
}
.indigo-pink.dark app-pending .pending-container .pending-items .pending-item .content:hover {
  background: rgba(255, 255, 255, 0.04) !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(255, 255, 255, 0.12) !important;
}
.indigo-pink.dark app-pending .pending-container .pending-items .pending-item .content.selected {
  border-left: 6px solid #f48fb1 !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(255, 255, 255, 0.12) !important;
}
.indigo-pink.dark app-pending .pending-container .pending-items .pending-item .content.selected mat-icon {
  color: #f48fb1 !important;
}
.indigo-pink.dark app-pending .pending-container .pending-items .pending-item .content.selected .item-data {
  color: white !important;
}
.indigo-pink.dark app-pending .pending-container .pending-items .pending-item .content mat-icon {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-pink.dark app-pending .pending-container .pending-items .pending-item .content .item-data {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-pink.dark app-document-sign-massive .inbox-container .inbox-toolbar {
  background: #303030;
}
.indigo-pink.dark app-document-sign-massive .inbox-container .pending-container {
  background: #303030;
}
.indigo-pink.dark app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content:hover {
  background: rgba(255, 255, 255, 0.04) !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(255, 255, 255, 0.12) !important;
}
.indigo-pink.dark app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content.selected {
  border-left: 6px solid #f48fb1 !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(255, 255, 255, 0.12) !important;
}
.indigo-pink.dark app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content.selected mat-icon {
  color: #f48fb1 !important;
}
.indigo-pink.dark app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content.selected .item-data {
  color: white !important;
}
.indigo-pink.dark app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content mat-icon {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-pink.dark app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content .item-data {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-pink.dark app-document-sign-massive .detail app-file-document-detail .view-container {
  background: #607D8B;
}
.indigo-pink.dark app-document-sign-massive .detail app-file-document-detail .view-container .mat-toolbar {
  background: #607D8B;
  color: rgba(0, 0, 0, 0.87);
  background: #607D8B;
}
.indigo-pink.dark app-document-sign-massive .detail app-chapa.minimized .info-empty {
  background: #f48fb1;
}
.indigo-pink.dark app-document-sign-massive .detail app-chapa.minimized .info-empty > span mat-icon {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-pink.dark app-document-sign-massive .detail app-chapa.minimized .info-empty > span h2 {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-pink.dark app-document-sign-massive .signer.desktop {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark app-document-sign-massive .signer .signer-btn {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-pink.dark app-document-sign-massive .signer .signer-btn[disabled] {
  background-color: rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark app-document-detail app-sign-document .firmador button.mat-raised-button.mat-accent {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-pink.dark mat-toolbar-row.firmado-conforme .mat-icon {
  background: #4caf50;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark mat-toolbar-row.firmado-no-conforme .mat-icon {
  background: #e57373;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark .secondary-toolbar {
  background: #424242;
}
.indigo-pink.dark .new-detail-container {
  border-left: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark .new-detail-container.cancelled .detail_head-body {
  background: #607D8B !important;
}
.indigo-pink.dark .new-detail-container .employee-detail-toolbar {
  background: black;
}
.indigo-pink.dark .new-detail-container .employee-detail-subbar {
  background: #424242;
}
.indigo-pink.dark app-employee-detail .load-window,
.indigo-pink.dark app-employee-rrhh-detail .load-window {
  background: #303030;
}
.indigo-pink.dark app-employee-detail .employee_detail-container,
.indigo-pink.dark app-employee-rrhh-detail .employee_detail-container {
  background: #303030;
}
.indigo-pink.dark app-employee-detail .employee_detail-container.is-editing .edition-line,
.indigo-pink.dark app-employee-rrhh-detail .employee_detail-container.is-editing .edition-line {
  background: black;
}
.indigo-pink.dark app-employee-detail .employee_detail-container .detail_head-body,
.indigo-pink.dark app-employee-rrhh-detail .employee_detail-container .detail_head-body {
  background: #9fa8da;
}
.indigo-pink.dark app-employee-detail .employee_detail-container .detail_head-body .head_body-status .icon-stacked + mat-icon,
.indigo-pink.dark app-employee-rrhh-detail .employee_detail-container .detail_head-body .head_body-status .icon-stacked + mat-icon {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-pink.dark app-employee-detail .employee_detail-container .detail_head-body .head_body-title .body_title-name,
.indigo-pink.dark app-employee-rrhh-detail .employee_detail-container .detail_head-body .head_body-title .body_title-name {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark app-employee-detail .employee_detail-container .detail_head-body .head_body-title .body_title-user,
.indigo-pink.dark app-employee-rrhh-detail .employee_detail-container .detail_head-body .head_body-title .body_title-user {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark app-employee-detail .employee_detail-container .data .data-line,
.indigo-pink.dark app-employee-rrhh-detail .employee_detail-container .data .data-line {
  background: #424242;
}
.indigo-pink.dark app-employee-detail .employee_detail-container .data .data-line .icon-complex mat-icon.complex,
.indigo-pink.dark app-employee-rrhh-detail .employee_detail-container .data .data-line .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #424242, 0 -2px 0px #424242, 2px 0 0px #424242, 0 2px 0px #424242, -2px -2px 0px #424242, 2px 2px 0px #424242, 2px -2px 0px #424242, -2px 2px 0px #424242;
}
.indigo-pink.dark app-employee-detail .employee_detail-container .data .data-line .line_text-main,
.indigo-pink.dark app-employee-rrhh-detail .employee_detail-container .data .data-line .line_text-main {
  color: #9fa8da;
}
.indigo-pink.dark app-employee-detail .employee_detail-container .data .data-line.clickable:hover,
.indigo-pink.dark app-employee-rrhh-detail .employee_detail-container .data .data-line.clickable:hover {
  background: #303030 !important;
}
.indigo-pink.dark app-employee-detail .employee_detail-container .data .data-line button.mat-icon-button,
.indigo-pink.dark app-employee-rrhh-detail .employee_detail-container .data .data-line button.mat-icon-button {
  background: black;
}
.indigo-pink.dark app-employee-detail .employee_detail-container .employee-title .title-text,
.indigo-pink.dark app-employee-rrhh-detail .employee_detail-container .employee-title .title-text {
  color: #9fa8da;
}
.indigo-pink.dark app-employee-detail .employee_detail-container .employee-title .title-text mat-icon,
.indigo-pink.dark app-employee-rrhh-detail .employee_detail-container .employee-title .title-text mat-icon {
  color: #9fa8da;
}
.indigo-pink.dark app-employee-detail .employee_detail-container .employee-title .title-text .title_text-main,
.indigo-pink.dark app-employee-rrhh-detail .employee_detail-container .employee-title .title-text .title_text-main {
  color: #9fa8da;
}
.indigo-pink.dark .UI-V2 .icon-complex mat-icon.complex {
  color: #9fa8da;
  text-shadow: -2px 0 0px #424242, 0 -2px 0px #424242, 2px 0 0px #424242, 0 2px 0px #424242, -2px -2px 0px #424242, 2px 2px 0px #424242, 2px -2px 0px #424242, -2px 2px 0px #424242;
}
.indigo-pink.dark .UI-V2 .icon-complex mat-icon.complex.mat-accent {
  color: #f48fb1;
}
.indigo-pink.dark .UI-V2 .main .toolbar .header .content .module-title {
  color: #9fa8da;
}
.indigo-pink.dark .UI-V2 .main .toolbar .header .content .module-title.selectable:hover {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-pink.dark .UI-V2 .main .toolbar .header .content .module-title .info h5 {
  color: white !important;
}
.indigo-pink.dark .UI-V2 .main .toolbar .header .content .ou-select {
  color: rgba(0, 0, 0, 0.87);
  background: #9fa8da;
}
.indigo-pink.dark .UI-V2 .main .toolbar .search-container .search .content {
  background: #303030;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark .UI-V2 .main .toolbar .search-container .search .content:hover, .indigo-pink.dark .UI-V2 .main .toolbar .search-container .search .content:active, .indigo-pink.dark .UI-V2 .main .toolbar .search-container .search .content:focus, .indigo-pink.dark .UI-V2 .main .toolbar .search-container .search .content:focus-within {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-pink.dark .UI-V2 .main .toolbar .search-container .search .content input {
  color: white;
}
.indigo-pink.dark .UI-V2 .main .helper {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark .UI-V2 .main .helper mat-hint h4 {
  color: #9fa8da;
}
.indigo-pink.dark .UI-V2 .main .new-table app-cs-grid-control datatable-header * {
  color: #9fa8da;
}
.indigo-pink.dark .UI-V2 .main .new-table .mat-header-row.sticky * {
  color: #9fa8da !important;
}
.indigo-pink.dark .UI-V2 .main .main-action {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark .menu-options.help .primary {
  color: #9fa8da;
}
.indigo-pink.dark .menu-options.help .accent {
  color: #f48fb1;
}
.indigo-pink.dark .menu-options .icon-complex mat-icon.complex {
  color: #9fa8da;
  text-shadow: -2px 0 0px #424242, 0 -2px 0px #424242, 2px 0 0px #424242, 0 2px 0px #424242, -2px -2px 0px #424242, 2px 2px 0px #424242, 2px -2px 0px #424242, -2px 2px 0px #424242;
}
.indigo-pink.dark .menu-options .mat-menu-content .container mat-list .mat-list-item:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-pink.dark .menu-options .mat-menu-content .container mat-list .mat-list-item .disabled {
  color: rgba(255, 255, 255, 0.7) !important;
}
.indigo-pink.dark .menu-options .mat-menu-content .container .slide-group mat-radio-group mat-radio-button:hover {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-pink.dark .menu-options .mat-menu-content .container .slide-group mat-form-field .mat-form-field-flex {
  background: #303030;
}
.indigo-pink.dark .menu-options .mat-menu-content .container .slide-group mat-slide-toggle:hover {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-pink.dark .mat-menu-panel.notifications-panel .mat-menu-content .notifications-body {
  background: #303030;
}
.indigo-pink.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification .primary mat-icon {
  color: #9fa8da;
}
.indigo-pink.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification .primary h3 {
  color: #9fa8da;
}
.indigo-pink.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.top {
  background: #303030;
}
.indigo-pink.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.top .header h3 {
  color: white;
}
.indigo-pink.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.bottom {
  background: #303030;
}
.indigo-pink.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable {
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable:nth-child(even) {
  background: #303030;
}
.indigo-pink.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable:nth-child(odd) {
  background: #424242;
}
.indigo-pink.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable:hover {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-pink.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable mat-icon {
  color: #9fa8da;
}
.indigo-pink.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable h3 {
  color: white;
}
.indigo-pink.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable h5 {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-pink.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable .subtitle h5 {
  color: #9fa8da;
  background: black;
}
.indigo-pink.dark .info-empty .large-icon {
  background: #7986cb;
  color: #9fa8da;
}
.indigo-pink.dark .info-empty h2,
.indigo-pink.dark .info-empty h3 {
  color: #9fa8da;
}
.indigo-pink.dark .info-empty h4,
.indigo-pink.dark .info-empty h5 {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-pink.dark .info-empty.accent mat-icon {
  background: #f48fb1;
  color: #f48fb1;
}
.indigo-pink.dark .info-empty.accent h2 {
  color: #f48fb1;
}
.indigo-pink.dark .info-empty .actions {
  padding: 16px;
  height: 96px;
  column-gap: 16px;
}
.indigo-pink.dark .info-empty .actions button {
  height: 100%;
  border-radius: 40px;
}
.indigo-pink.dark .info-empty .actions button.ok {
  background: #4db6ac !important;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark .info-empty .actions button.not-ok {
  color: #ba68c8 !important;
}
.indigo-pink.dark .side.edition .info-empty .large-icon {
  background: #f48fb1;
  color: #f48fb1;
}
.indigo-pink.dark .side.edition .info-empty h2 {
  color: #f48fb1;
}
.indigo-pink.dark .side.edition .info-empty h4 {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-pink.dark .UI-V2 .deferred-process-list .header h3 {
  color: #9fa8da;
}
.indigo-pink.dark .UI-V2 .deferred-process-list .search-container .search .content {
  background: #303030;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark .UI-V2 .deferred-process-list .search-container .search .content:hover, .indigo-pink.dark .UI-V2 .deferred-process-list .search-container .search .content:active, .indigo-pink.dark .UI-V2 .deferred-process-list .search-container .search .content:focus, .indigo-pink.dark .UI-V2 .deferred-process-list .search-container .search .content:focus-within {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-pink.dark .UI-V2 .deferred-process-list .search-container .search .content input {
  color: white;
}
.indigo-pink.dark .UI-V2 .deferred-process-list .text-success,
.indigo-pink.dark .UI-V2 .deferred-process-details .text-success {
  color: #4caf50;
}
.indigo-pink.dark .UI-V2 .deferred-process-list .text-warn,
.indigo-pink.dark .UI-V2 .deferred-process-details .text-warn {
  color: #e57373;
}
.indigo-pink.dark .UI-V2 .deferred-process-list .text-primary,
.indigo-pink.dark .UI-V2 .deferred-process-details .text-primary {
  color: #9fa8da;
}
.indigo-pink.dark .UI-V2 .deferred-process-list .text-atention,
.indigo-pink.dark .UI-V2 .deferred-process-details .text-atention {
  color: #DB7703;
}
.indigo-pink.dark .UI-V2 .deferred-process-list .table-paginator-container,
.indigo-pink.dark .UI-V2 .deferred-process-details .table-paginator-container {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
}
.indigo-pink.dark .UI-V2 .deferred-process-list .table-paginator-container .mat-table,
.indigo-pink.dark .UI-V2 .deferred-process-details .table-paginator-container .mat-table {
  border-bottom: none;
  border-radius: 10px 10px 0 0;
}
.indigo-pink.dark .UI-V2 .deferred-process-list .table-paginator-container .paginator-container,
.indigo-pink.dark .UI-V2 .deferred-process-details .table-paginator-container .paginator-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: #f2f2f2;
  padding: 0 1.5rem;
  height: 3rem;
}
.indigo-pink.dark .UI-V2 .deferred-process-list .mat-table,
.indigo-pink.dark .UI-V2 .deferred-process-details .mat-table {
  border: 1px solid #f2f2f2;
  border-radius: 10px;
  overflow: hidden;
}
.indigo-pink.dark .UI-V2 .deferred-process-list .mat-table .mat-header-row .mat-header-cell,
.indigo-pink.dark .UI-V2 .deferred-process-details .mat-table .mat-header-row .mat-header-cell {
  font-size: 14px;
  font-weight: 700;
}
.indigo-pink.dark .UI-V2 .deferred-process-list .mat-table .mat-header-row,
.indigo-pink.dark .UI-V2 .deferred-process-details .mat-table .mat-header-row {
  background: #f2f2f2;
}
.indigo-pink.dark .UI-V2 .deferred-process-list .mat-table .mat-header-row .mat-header-cell,
.indigo-pink.dark .UI-V2 .deferred-process-details .mat-table .mat-header-row .mat-header-cell {
  color: #9fa8da;
}
.indigo-pink.dark .UI-V2 .deferred-process-list .mat-table .mat-row,
.indigo-pink.dark .UI-V2 .deferred-process-details .mat-table .mat-row {
  background: #424242;
}
.indigo-pink.dark .UI-V2 .progress-bar-container {
  position: relative;
}
.indigo-pink.dark .UI-V2 .deferred-process-details .header h3 {
  color: #9fa8da;
}
.indigo-pink.dark .UI-V2 .deferred-process-details .header .back-link:visited {
  color: #9fa8da;
}
.indigo-pink.dark .process-card,
.indigo-pink.dark .process-card-detail,
.indigo-pink.dark .process-card-stages {
  background: #303030;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark .process-card mat-card-subtitle,
.indigo-pink.dark .process-card-detail mat-card-subtitle,
.indigo-pink.dark .process-card-stages mat-card-subtitle {
  color: white;
}
.indigo-pink.dark .process-card .stages-container .stage.completed mat-icon,
.indigo-pink.dark .process-card-detail .stages-container .stage.completed mat-icon,
.indigo-pink.dark .process-card-stages .stages-container .stage.completed mat-icon {
  color: #499719;
}
.indigo-pink.dark .process-card .stages-container .stage.completed .icon-container,
.indigo-pink.dark .process-card-detail .stages-container .stage.completed .icon-container,
.indigo-pink.dark .process-card-stages .stages-container .stage.completed .icon-container {
  background-color: rgba(78, 160, 28, 0.1490196078);
}
.indigo-pink.dark .process-card .stages-container .stage.completed .line,
.indigo-pink.dark .process-card-detail .stages-container .stage.completed .line,
.indigo-pink.dark .process-card-stages .stages-container .stage.completed .line {
  background-color: #499719;
}
.indigo-pink.dark .process-card .stages-container .stage.on-going mat-icon,
.indigo-pink.dark .process-card-detail .stages-container .stage.on-going mat-icon,
.indigo-pink.dark .process-card-stages .stages-container .stage.on-going mat-icon {
  color: #9fa8da;
}
.indigo-pink.dark .process-card .stages-container .stage.on-going .icon-container,
.indigo-pink.dark .process-card-detail .stages-container .stage.on-going .icon-container,
.indigo-pink.dark .process-card-stages .stages-container .stage.on-going .icon-container {
  background-color: rgba(66, 48, 159, 0.2);
}
.indigo-pink.dark .process-card .stages-container .stage.on-going .line,
.indigo-pink.dark .process-card-detail .stages-container .stage.on-going .line,
.indigo-pink.dark .process-card-stages .stages-container .stage.on-going .line {
  background-color: #949494;
}
.indigo-pink.dark .process-card .stages-container .stage.pending,
.indigo-pink.dark .process-card-detail .stages-container .stage.pending,
.indigo-pink.dark .process-card-stages .stages-container .stage.pending {
  color: #949494;
}
.indigo-pink.dark .process-card .stages-container .stage.pending mat-icon,
.indigo-pink.dark .process-card-detail .stages-container .stage.pending mat-icon,
.indigo-pink.dark .process-card-stages .stages-container .stage.pending mat-icon {
  color: #949494;
}
.indigo-pink.dark .process-card .stages-container .stage.pending .icon-container,
.indigo-pink.dark .process-card-detail .stages-container .stage.pending .icon-container,
.indigo-pink.dark .process-card-stages .stages-container .stage.pending .icon-container {
  background-color: rgba(174, 174, 174, 0.2);
}
.indigo-pink.dark .process-card .stages-container .stage.pending .line,
.indigo-pink.dark .process-card-detail .stages-container .stage.pending .line,
.indigo-pink.dark .process-card-stages .stages-container .stage.pending .line {
  background-color: #949494;
}
.indigo-pink.dark .process-card .stages-container .stage.has-error mat-icon,
.indigo-pink.dark .process-card-detail .stages-container .stage.has-error mat-icon,
.indigo-pink.dark .process-card-stages .stages-container .stage.has-error mat-icon {
  color: #e57373;
}
.indigo-pink.dark .process-card .stages-container .stage.has-error .icon-container,
.indigo-pink.dark .process-card-detail .stages-container .stage.has-error .icon-container,
.indigo-pink.dark .process-card-stages .stages-container .stage.has-error .icon-container {
  background-color: rgba(188, 4, 4, 0.2);
}
.indigo-pink.dark .process-card .stages-container .stage-description .button-link,
.indigo-pink.dark .process-card-detail .stages-container .stage-description .button-link,
.indigo-pink.dark .process-card-stages .stages-container .stage-description .button-link {
  color: #9fa8da;
}
.indigo-pink.dark app-custom-search-input .custom-search-container {
  background: #303030;
  border: 1px solid rgba(255, 255, 255, 0.12);
  transition: color 0.1s ease, background 0.15s ease;
}
.indigo-pink.dark app-custom-search-input .custom-search-container mat-icon.mat-icon {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-pink.dark app-custom-search-input .custom-search-container input.custom-search-input {
  color: white;
  background: unset;
}
.indigo-pink.dark app-custom-search-input .custom-search-container:hover, .indigo-pink.dark app-custom-search-input .custom-search-container:active, .indigo-pink.dark app-custom-search-input .custom-search-container:focus, .indigo-pink.dark app-custom-search-input .custom-search-container:focus-within {
  background: rgba(255, 255, 255, 0.04) !important;
  transition: background 0.15s ease;
}
.indigo-pink.dark .UI-V2 .audit-filter {
  background: #424242;
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark .UI-V2 .audit-filter .toolbar {
  background: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark .UI-V2 .audit-filter .toolbar .header .content .module-title {
  color: #9fa8da;
}
.indigo-pink.dark .UI-V2 .audit-filter .toolbar .header .content .module-title.selectable:hover {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-pink.dark .UI-V2 .audit-filter .toolbar .header .content .module-title .info * {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-pink.dark .UI-V2 .audit-filter .toolbar .header .content .ou-select {
  color: rgba(0, 0, 0, 0.87);
  background: #9fa8da;
}
.indigo-pink.dark .UI-V2 .audit-filter .toolbar .search-container .search .content {
  background: #303030;
}
.indigo-pink.dark .UI-V2 .audit-filter .toolbar .search-container .search .content:hover, .indigo-pink.dark .UI-V2 .audit-filter .toolbar .search-container .search .content:active, .indigo-pink.dark .UI-V2 .audit-filter .toolbar .search-container .search .content:focus, .indigo-pink.dark .UI-V2 .audit-filter .toolbar .search-container .search .content:focus-within {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-pink.dark .UI-V2 .audit-filter .toolbar .search-container .search .content input {
  color: white;
}
.indigo-pink.dark .UI-V2 .audit-filter .filter-container {
  background: #424242 !important;
}
.indigo-pink.dark .UI-V2 .audit-filter .filter-container .form-body {
  background: #303030 !important;
}
.indigo-pink.dark .UI-V2 .audit-filter .filter-container .form-body .line h4 {
  color: #9fa8da;
}
.indigo-pink.dark .UI-V2 .audit-filter .filter-container .form-body .line mat-icon {
  color: #9fa8da;
}
.indigo-pink.dark .UI-V2 .audit-result .result-container .toolbar {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark .UI-V2 .audit-result .result-container .toolbar .header .content .module-title {
  color: #9fa8da;
}
.indigo-pink.dark .UI-V2 .audit-result .result-container .toolbar .header .content .module-title.selectable:hover {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-pink.dark .UI-V2 .audit-result .result-container .toolbar .header .content .module-title .info h5 {
  color: white !important;
}
.indigo-pink.dark .UI-V2 .audit-result .result-container .toolbar .header .content .ou-select {
  color: rgba(0, 0, 0, 0.87);
  background: #9fa8da;
}
.indigo-pink.dark .UI-V2 .audit-result .result-container .toolbar .search-container .search .content {
  background: #303030;
}
.indigo-pink.dark .UI-V2 .audit-result .result-container .toolbar .search-container .search .content:hover, .indigo-pink.dark .UI-V2 .audit-result .result-container .toolbar .search-container .search .content:active, .indigo-pink.dark .UI-V2 .audit-result .result-container .toolbar .search-container .search .content:focus, .indigo-pink.dark .UI-V2 .audit-result .result-container .toolbar .search-container .search .content:focus-within {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-pink.dark .UI-V2 .audit-result .result-container .toolbar .search-container .search .content input {
  color: white;
}
.indigo-pink.dark .UI-V2 .audit-result .result-container .new-table app-cs-grid-control datatable-header * {
  color: #9fa8da;
}
.indigo-pink.dark .UI-V2 .audit-result .result-container .new-table .mat-header-row.sticky * {
  color: #9fa8da !important;
}
.indigo-pink.dark .UI-V2 .audit-detail-container {
  border-left: 1px solid rgba(255, 255, 255, 0.12);
  background: black !important;
}
.indigo-pink.dark .UI-V2 .audit-detail-container .side .toolbar .header {
  color: #9fa8da;
}
.indigo-pink.dark .UI-V2 .audit-detail-container .side .container .mat-list-base {
  background: #303030 !important;
}
.indigo-pink.dark .UI-V2 .audit-detail-container .side .container .mat-list-base mat-list-item .mat-line b {
  color: #9fa8da;
}
.indigo-pink.dark .UI-V2 .inbox-container {
  background: #424242;
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark .UI-V2 .inbox-container *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.indigo-pink.dark .UI-V2 .inbox-container *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
.indigo-pink.dark .UI-V2 .inbox-container *::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}
.indigo-pink.dark .UI-V2 .inbox-container .load-window {
  background: #424242;
}
.indigo-pink.dark .UI-V2 .inbox-container .toolbar {
  background: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark .UI-V2 .inbox-container .toolbar.reverse {
  background: #424242;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel {
  background: black !important;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel.mat-expanded {
  background: #303030 !important;
}
.indigo-pink.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel.mat-expanded mat-expansion-panel-header {
  background: #303030 !important;
}
.indigo-pink.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel.mat-expanded mat-expansion-panel-header:hover {
  background: none !important;
}
.indigo-pink.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel mat-expansion-panel-header {
  background: #303030 !important;
}
.indigo-pink.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel mat-expansion-panel-header:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-pink.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel mat-expansion-panel-header .signature-inbox-header .mat-icon,
.indigo-pink.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel mat-expansion-panel-header .signature-inbox-header b {
  color: #f48fb1;
}
.indigo-pink.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel .mat-expansion-panel-body .item {
  background: #424242;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel .mat-expansion-panel-body .item:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-pink.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel .mat-expansion-panel-body .item.active {
  border-left: 6px solid #f48fb1;
}
.indigo-pink.dark .UI-V2 .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.indigo-pink.dark .UI-V2 .work-space .main .load-window {
  background: #424242 !important;
}
.indigo-pink.dark .UI-V2 .work-space .main-action {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark mat-radio-button.disabled .mat-radio-label-content {
  color: #f48fb1;
}
.indigo-pink.dark .detail app-automatic-process-detail app-chapa.minimize,
.indigo-pink.dark .detail app-process-detail app-chapa.minimize {
  background: #ffc107 !important;
}
.indigo-pink.dark .detail app-automatic-process-detail app-chapa.minimize.purged,
.indigo-pink.dark .detail app-process-detail app-chapa.minimize.purged {
  background: #b2adb0 !important;
}
.indigo-pink.dark .detail .detail-toolbar {
  background: black;
}
.indigo-pink.dark .detail .processes-status .status-bar .bar-graph {
  background-color: #ffc107;
}
.indigo-pink.dark .detail .processes-status .status-bar .bar-graph .graph-ok {
  background-color: #009688;
}
.indigo-pink.dark .detail .processes-status .status-bar .bar-graph .graph-not-ok {
  background-color: #e91e63;
}
.indigo-pink.dark .detail .processes-status .status-resume b {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark .detail .processes-status .status-resume .resume-total b {
  background-color: #ffc107;
}
.indigo-pink.dark .detail .processes-status .status-resume .resume-ok b {
  background-color: #009688;
}
.indigo-pink.dark .detail .processes-status .status-resume .resume-not-ok b {
  background-color: #e91e63;
}
.indigo-pink.dark .detail .detail-toolbar .mat-avatar h3,
.indigo-pink.dark .detail .detail-toolbar .mat-avatar h5 {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark .detail .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark .detail .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark .detail .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark .detail .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark .detail .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  background: #009688 !important;
}
.indigo-pink.dark .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  background: #b2dfdb !important;
}
.indigo-pink.dark .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  background: #b2dfdb !important;
}
.indigo-pink.dark .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  background: #009688 !important;
}
.indigo-pink.dark .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  background: #b2dfdb !important;
}
.indigo-pink.dark .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  background: #837C80 !important;
}
.indigo-pink.dark .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  background: #b2adb0 !important;
}
.indigo-pink.dark .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  background: #b2adb0 !important;
}
.indigo-pink.dark .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  background: #837C80 !important;
}
.indigo-pink.dark .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  background: #b2dfdb !important;
}
.indigo-pink.dark .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  background: #ffc107 !important;
}
.indigo-pink.dark .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  background: #ffecb3 !important;
}
.indigo-pink.dark .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  background: #ffecb3 !important;
}
.indigo-pink.dark .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  background: #ffc107 !important;
}
.indigo-pink.dark .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  background: #ffecb3 !important;
}
.indigo-pink.dark .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  background: #e91e63 !important;
}
.indigo-pink.dark .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  background: #f8bbd0 !important;
}
.indigo-pink.dark .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  background: #f8bbd0 !important;
}
.indigo-pink.dark .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  background: #e91e63 !important;
}
.indigo-pink.dark .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  background: #f8bbd0 !important;
}
.indigo-pink.dark app-analytics-document-set .sets-container .mat-list-base.sets-list mat-list-item {
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark app-analytics-document-set .sets-container .mat-list-base.sets-list mat-list-item .mat-list-item-content .set-status .value-percentile .undone-value {
  background: #673AB7;
}
.indigo-pink.dark app-analytics-document-set .sets-container .mat-list-base.sets-list mat-list-item .mat-list-item-content .set-status .value-percentile .not-value {
  background: black;
}
.indigo-pink.dark app-analytics-document-set .sets-container .mat-list-base.sets-list mat-list-item .mat-list-item-content .set-status .value-percentile .done-value {
  background: #009688;
}
.indigo-pink.dark app-analytics-document-set .work-space .main .toolbar .header .content .module-title .info .info-text .set-status {
  cursor: help;
}
.indigo-pink.dark app-analytics-document-set .work-space .main .toolbar .header .content .module-title .info .info-text .set-status .value-percentile .undone-value {
  background: #673AB7;
}
.indigo-pink.dark app-analytics-document-set .work-space .main .toolbar .header .content .module-title .info .info-text .set-status .value-percentile .not-value {
  background: black;
}
.indigo-pink.dark app-analytics-document-set .work-space .main .toolbar .header .content .module-title .info .info-text .set-status .value-percentile .done-value {
  background: #009688;
}
.indigo-pink.dark app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar .mat-progress-bar-buffer {
  background-color: black;
}
.indigo-pink.dark app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar.class1 .mat-progress-bar-fill::after {
  background-color: #f44336;
}
.indigo-pink.dark app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar.class2 .mat-progress-bar-fill::after {
  background-color: #ff9800;
}
.indigo-pink.dark app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar.class3 .mat-progress-bar-fill::after {
  background-color: #ffeb3b;
}
.indigo-pink.dark app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar.class4 .mat-progress-bar-fill::after {
  background-color: #8bc34a;
}
.indigo-pink.dark app-analytics-document-set .work-space .side .container mat-list {
  background: #424242 !important;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark app-analytics-document-set .work-space .side .container mat-list mat-list-item .mat-line b {
  color: #9fa8da;
}
.indigo-pink.dark .catcher {
  background: rgba(0, 0, 0, 0.6);
}
.indigo-pink.dark .catcher.clear {
  background: rgba(0, 0, 0, 0);
}
.indigo-pink.dark .add-menu-form-body .mat-radio-group .mat-radio-button:hover {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-pink.dark .add-menu-form-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b {
  color: white;
}
.indigo-pink.dark .add-menu-form-body.w-table .helper .search-bar {
  background: #303030;
}
.indigo-pink.dark .add-menu-form-body.w-table .helper .search-bar:hover, .indigo-pink.dark .add-menu-form-body.w-table .helper .search-bar:active, .indigo-pink.dark .add-menu-form-body.w-table .helper .search-bar:focus, .indigo-pink.dark .add-menu-form-body.w-table .helper .search-bar:focus-within {
  background: #303030 !important;
}
.indigo-pink.dark .add-menu-form-body.w-table .helper .search-bar input {
  color: white;
}
.indigo-pink.dark .add-menu-form-actions {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark .file-upload-list mat-list-item {
  background: #424242;
}
.indigo-pink.dark .mat-horizontal-stepper-content[aria-expanded=true] app-file-document-view {
  border-left: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark .mat-horizontal-stepper-content[aria-expanded=true] app-file-document-view .pdf-view-container {
  background: black;
}
.indigo-pink.dark .add-docs-drop .docs-drop-preview app-file-document-detail {
  border-left: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark .add-docs-drop .docs-drop-preview app-file-document-detail .view-toolbar {
  background: #424242;
}
.indigo-pink.dark .add-docs-drop .load-window.message h3.primary-text {
  color: #9fa8da;
}
.indigo-pink.dark mat-dialog-container app-add-menu-dialog .load-window.message h3.primary-text {
  color: #9fa8da;
}
.indigo-pink.dark .asisted-input.aligned-icon.subtitle * {
  color: rgba(255, 255, 255, 0.5) !important;
}
.indigo-pink.dark .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble {
  background: #9fa8da;
}
.indigo-pink.dark .asisted-input .mat-form-field-invalid + .indicator > .bubble {
  background: #e57373;
}
.indigo-pink.dark .asisted-input .mat-form-field-disabled + .indicator > .bubble {
  color: rgba(255, 255, 255, 0.5);
  background: rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark .asisted-input .indicator .bubble {
  background: rgba(255, 255, 255, 0.5);
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark .asisted-input .indicator .guide {
  border-color: rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark .asisted-input.icon-only .bubble {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-pink.dark .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble {
  color: #9fa8da;
}
.indigo-pink.dark .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble {
  color: #e57373;
}
.indigo-pink.dark .UI-V2 .main-overlay {
  background: rgba(0, 0, 0, 0.32);
}
.indigo-pink.dark .UI-V2 .icon-complex mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.indigo-pink.dark .UI-V2 .icon-complex mat-icon.complex {
  color: #9fa8da !important;
}
.indigo-pink.dark .UI-V2 .navigation {
  background: #303030;
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark .UI-V2 .navigation *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.indigo-pink.dark .UI-V2 .navigation *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
.indigo-pink.dark .UI-V2 .navigation .load-window {
  background: black;
}
.indigo-pink.dark .UI-V2 .navigation .toolbar {
  background: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark .UI-V2 .navigation .toolbar.reverse {
  background: #424242;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark .UI-V2 .navigation .container .helper {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-pink.dark .UI-V2 .navigation .container .item {
  background: #424242;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark .UI-V2 .navigation .container .item:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-pink.dark .UI-V2 .navigation .container .item.active {
  background: #9fa8da !important;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #9fa8da;
}
.indigo-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .separator .subtitle,
.indigo-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .separator .subtitle,
.indigo-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .separator .subtitle,
.indigo-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .separator .subtitle,
.indigo-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .separator .subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .img-editior-container .img-editor .separator .subtitle,
.indigo-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .img-editior-container .img-editor .separator .subtitle,
.indigo-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .img-editior-container .img-editor .separator .subtitle,
.indigo-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .img-editior-container .img-editor .separator .subtitle,
.indigo-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .img-editior-container .img-editor .separator .subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme,
.indigo-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme,
.indigo-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme,
.indigo-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme,
.indigo-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .main-color-container .main-color,
.indigo-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .main-color-container .main-color,
.indigo-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .main-color-container .main-color,
.indigo-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .main-color-container .main-color,
.indigo-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .main-color-container .main-color {
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .main-color-container .main-color:before,
.indigo-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .main-color-container .main-color:before,
.indigo-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .main-color-container .main-color:before,
.indigo-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .main-color-container .main-color:before,
.indigo-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .main-color-container .main-color:before {
  border-left: 10px solid rgba(0, 0, 0, 0);
  border-right: 10px solid rgba(0, 0, 0, 0);
}
.indigo-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example h6.required,
.indigo-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example h6.required,
.indigo-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example h6.required,
.indigo-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example h6.required,
.indigo-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example h6.required {
  color: #e57373 !important;
}
.indigo-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle,
.indigo-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle,
.indigo-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle,
.indigo-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle,
.indigo-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle {
  border: 2px solid rgba(0, 0, 0, 0);
}
.indigo-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .side-color-container .side-color,
.indigo-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .side-color-container .side-color,
.indigo-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .side-color-container .side-color,
.indigo-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .side-color-container .side-color,
.indigo-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .side-color-container .side-color {
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .side-color-container .side-color:before,
.indigo-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .side-color-container .side-color:before,
.indigo-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .side-color-container .side-color:before,
.indigo-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .side-color-container .side-color:before,
.indigo-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .side-color-container .side-color:before {
  border-top: 10px solid rgba(0, 0, 0, 0);
  border-bottom: 10px solid rgba(0, 0, 0, 0);
}
.indigo-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .side-color-container .side-color h6.required,
.indigo-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .side-color-container .side-color h6.required,
.indigo-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .side-color-container .side-color h6.required,
.indigo-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .side-color-container .side-color h6.required,
.indigo-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .side-color-container .side-color h6.required {
  color: #e57373 !important;
}
.indigo-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .permission-settings-cards .card,
.indigo-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .permission-settings-cards .card,
.indigo-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .permission-settings-cards .card,
.indigo-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .permission-settings-cards .card,
.indigo-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .permission-settings-cards .card {
  background: #303030;
}
.indigo-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .permission-settings-cards .card .slide-sub-group,
.indigo-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .permission-settings-cards .card .slide-sub-group,
.indigo-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .permission-settings-cards .card .slide-sub-group,
.indigo-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .permission-settings-cards .card .slide-sub-group,
.indigo-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .permission-settings-cards .card .slide-sub-group {
  background: rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark .UI-V2 .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.indigo-pink.dark .UI-V2 .work-space .main .load-window {
  background: #424242 !important;
}
.indigo-pink.dark .UI-V2 .work-space .main.side-open {
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item {
  background: #303030;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item mat-icon.complex {
  text-shadow: -2px 0 0px black, 0 -2px 0px black, 2px 0 0px black, 0 2px 0px black, -2px -2px 0px black, 2px 2px 0px black, 2px -2px 0px black, -2px 2px 0px black !important;
}
.indigo-pink.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item h5 {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-pink.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item h3 {
  color: white;
}
.indigo-pink.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item .actions {
  pointer-events: none;
}
.indigo-pink.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item .actions button {
  pointer-events: all;
}
.indigo-pink.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled {
  background: black;
}
.indigo-pink.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled h3 {
  color: #607D8B !important;
}
.indigo-pink.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled mat-icon {
  color: #607D8B !important;
}
.indigo-pink.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled.selected {
  background: #607D8B;
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-pink.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled.selected h3 {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-pink.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled.selected mat-icon {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-pink.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled.selected mat-icon.complex {
  color: rgba(0, 0, 0, 0.87) !important;
  text-shadow: -2px 0 0px #607D8B, 0 -2px 0px #607D8B, 2px 0 0px #607D8B, 0 2px 0px #607D8B, -2px -2px 0px #607D8B, 2px 2px 0px #607D8B, 2px -2px 0px #607D8B, -2px 2px 0px #607D8B !important;
}
.indigo-pink.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item:hover {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-pink.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected {
  background: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected mat-icon {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected mat-icon.complex {
  color: rgba(0, 0, 0, 0.87) !important;
  text-shadow: -2px 0 0px #9fa8da, 0 -2px 0px #9fa8da, 2px 0 0px #9fa8da, 0 2px 0px #9fa8da, -2px -2px 0px #9fa8da, 2px 2px 0px #9fa8da, 2px -2px 0px #9fa8da, -2px 2px 0px #9fa8da !important;
}
.indigo-pink.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected h5,
.indigo-pink.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected h3 {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark .UI-V2 .work-space .main .main-action {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark .UI-V2 .work-space .side {
  background: #303030;
}
.indigo-pink.dark .UI-V2 .work-space .side .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #303030, 0 -2px 0px #303030, 2px 0 0px #303030, 0 2px 0px #303030, -2px -2px 0px #303030, 2px 2px 0px #303030, 2px -2px 0px #303030, -2px 2px 0px #303030;
}
.indigo-pink.dark .UI-V2 .work-space .side *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.indigo-pink.dark .UI-V2 .work-space .side *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
.indigo-pink.dark .UI-V2 .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #303030 !important;
}
.indigo-pink.dark .UI-V2 .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-pink.dark .UI-V2 .work-space .side mat-form-field .mat-form-field-flex {
  background: #303030;
}
.indigo-pink.dark .UI-V2 .work-space .side mat-checkbox:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-pink.dark .UI-V2 .work-space .side .hint.all {
  color: #f48fb1;
}
.indigo-pink.dark .UI-V2 .work-space .side .toolbar .header {
  color: #9fa8da;
}
.indigo-pink.dark .UI-V2 .work-space .side .toolbar .header h3 {
  color: #9fa8da;
}
.indigo-pink.dark .UI-V2 .work-space .side .container .role-resume {
  background: #9fa8da;
}
.indigo-pink.dark .UI-V2 .work-space .side .container .role-resume .line {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark .UI-V2 .work-space .side .container .role-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.1);
}
.indigo-pink.dark .UI-V2 .work-space .side .container .role-resume .line.editable button {
  background: #9fa8da;
}
.indigo-pink.dark .UI-V2 .work-space .side .container .section-title {
  color: #9fa8da;
}
.indigo-pink.dark .UI-V2 .work-space .side .container .section-resume {
  background: #424242;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark .UI-V2 .work-space .side .container .section-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-pink.dark .UI-V2 .work-space .side .container .section-resume .line.editable:hover .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #424242, 0 -2px 0px #424242, 2px 0 0px #424242, 0 2px 0px #424242, -2px -2px 0px #424242, 2px 2px 0px #424242, 2px -2px 0px #424242, -2px 2px 0px #424242 !important;
}
.indigo-pink.dark .UI-V2 .work-space .side .container .section-resume .line.editable button {
  background: #303030;
}
.indigo-pink.dark .UI-V2 .work-space .side .container .section-resume .line .header .icon-complex mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.indigo-pink.dark .UI-V2 .work-space .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #9fa8da !important;
}
.indigo-pink.dark .UI-V2 .work-space .side .container .section-resume .line .header mat-icon:not(.complex) {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-pink.dark .UI-V2 .work-space .side .container .section-resume .line .header .info h4 {
  color: #9fa8da;
}
.indigo-pink.dark .UI-V2 .work-space .side .container .section-resume .line ul {
  color: #9fa8da;
}
.indigo-pink.dark .UI-V2 .work-space .side .container .section-resume .line.head {
  background: black;
}
.indigo-pink.dark .UI-V2 .work-space .side .container .section-resume .line.head mat-icon {
  color: #f48fb1;
}
.indigo-pink.dark .UI-V2 .work-space .side .container .section-resume .line.head h4 {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark .UI-V2 .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body {
  background: #424242;
}
.indigo-pink.dark .UI-V2 .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-pink.dark .UI-V2 .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #9fa8da;
}
.indigo-pink.dark .UI-V2 .work-space .side.edition .toolbar .header {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark .UI-V2 .work-space .side.edition .toolbar .header mat-icon {
  color: #f48fb1;
}
.indigo-pink.dark .UI-V2 .work-space .side.edition .toolbar .header h3 {
  color: #f48fb1;
}
.indigo-pink.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body {
  background: #424242 !important;
}
.indigo-pink.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header mat-icon:not(.complex) {
  color: #9fa8da;
}
.indigo-pink.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header h4 {
  color: #9fa8da;
}
.indigo-pink.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-actions {
  background: #303030;
}
.indigo-pink.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.indigo-pink.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header .icon-complex mat-icon.complex {
  color: #f48fb1 !important;
}
.indigo-pink.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header h4 {
  color: #f48fb1;
}
.indigo-pink.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line ul {
  color: #f48fb1;
  font-weight: 400;
}
.indigo-pink.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head mat-icon {
  color: #f48fb1 !important;
}
.indigo-pink.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head h4 {
  color: white;
}
.indigo-pink.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .section-title {
  color: white !important;
}
.indigo-pink.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume h6.hint {
  background: #f48fb1;
}
.indigo-pink.dark app-role-user-find .main-overlay {
  background: rgba(0, 0, 0, 0.32);
}
.indigo-pink.dark app-role-user-find .icon-complex mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.indigo-pink.dark app-role-user-find .icon-complex mat-icon.complex {
  color: #9fa8da !important;
}
.indigo-pink.dark app-role-user-find .navigation {
  background: #303030;
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark app-role-user-find .navigation *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.indigo-pink.dark app-role-user-find .navigation *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
.indigo-pink.dark app-role-user-find .navigation .load-window {
  background: black;
}
.indigo-pink.dark app-role-user-find .navigation .toolbar {
  background: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark app-role-user-find .navigation .toolbar.reverse {
  background: #424242;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark app-role-user-find .navigation .container .helper {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-pink.dark app-role-user-find .navigation .container .item {
  background: #424242;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark app-role-user-find .navigation .container .item:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-pink.dark app-role-user-find .navigation .container .item.active {
  background: #9fa8da !important;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #9fa8da;
}
.indigo-pink.dark app-role-user-find .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.indigo-pink.dark app-role-user-find .work-space .main .load-window {
  background: #424242 !important;
}
.indigo-pink.dark app-role-user-find .work-space .main.side-open {
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark app-role-user-find .work-space .main .container .item {
  background: #303030;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark app-role-user-find .work-space .main .container .item .subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-pink.dark app-role-user-find .work-space .main .container .item.disabled {
  background: black;
  color: #607D8B !important;
}
.indigo-pink.dark app-role-user-find .work-space .main .container .item.disabled h3 {
  color: #607D8B !important;
}
.indigo-pink.dark app-role-user-find .work-space .main .container .item.disabled mat-icon {
  color: #607D8B !important;
}
.indigo-pink.dark app-role-user-find .work-space .main .container .item.disabled.selected {
  background: #607D8B;
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-pink.dark app-role-user-find .work-space .main .container .item.disabled.selected h3 {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-pink.dark app-role-user-find .work-space .main .container .item.disabled.selected mat-icon {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-pink.dark app-role-user-find .work-space .main .container .item.disabled.selected mat-icon.complex {
  color: rgba(0, 0, 0, 0.87) !important;
  text-shadow: -2px 0 0px #607D8B, 0 -2px 0px #607D8B, 2px 0 0px #607D8B, 0 2px 0px #607D8B, -2px -2px 0px #607D8B, 2px 2px 0px #607D8B, 2px -2px 0px #607D8B, -2px 2px 0px #607D8B !important;
}
.indigo-pink.dark app-role-user-find .work-space .main .container .item mat-icon.complex {
  text-shadow: -2px 0 0px black, 0 -2px 0px black, 2px 0 0px black, 0 2px 0px black, -2px -2px 0px black, 2px 2px 0px black, 2px -2px 0px black, -2px 2px 0px black !important;
}
.indigo-pink.dark app-role-user-find .work-space .main .container .item h5 {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-pink.dark app-role-user-find .work-space .main .container .item h3 {
  color: white;
}
.indigo-pink.dark app-role-user-find .work-space .main .container .item:hover {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-pink.dark app-role-user-find .work-space .main .container .item.selected {
  background: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark app-role-user-find .work-space .main .container .item.selected mat-icon {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark app-role-user-find .work-space .main .container .item.selected mat-icon.complex {
  color: rgba(0, 0, 0, 0.87) !important;
  text-shadow: -2px 0 0px #9fa8da, 0 -2px 0px #9fa8da, 2px 0 0px #9fa8da, 0 2px 0px #9fa8da, -2px -2px 0px #9fa8da, 2px 2px 0px #9fa8da, 2px -2px 0px #9fa8da, -2px 2px 0px #9fa8da !important;
}
.indigo-pink.dark app-role-user-find .work-space .main .container .item.selected h5,
.indigo-pink.dark app-role-user-find .work-space .main .container .item.selected h3 {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark app-role-user-find .work-space .main .main-action {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark app-role-user-find .work-space .side {
  background: #303030;
}
.indigo-pink.dark app-role-user-find .work-space .side .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #303030, 0 -2px 0px #303030, 2px 0 0px #303030, 0 2px 0px #303030, -2px -2px 0px #303030, 2px 2px 0px #303030, 2px -2px 0px #303030, -2px 2px 0px #303030;
}
.indigo-pink.dark app-role-user-find .work-space .side *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.indigo-pink.dark app-role-user-find .work-space .side *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
.indigo-pink.dark app-role-user-find .work-space .side .edition-line {
  background: black;
}
.indigo-pink.dark app-role-user-find .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #303030 !important;
}
.indigo-pink.dark app-role-user-find .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-pink.dark app-role-user-find .work-space .side mat-form-field .mat-form-field-flex {
  background: #303030;
}
.indigo-pink.dark app-role-user-find .work-space .side mat-checkbox:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-pink.dark app-role-user-find .work-space .side .hint.all {
  color: #f48fb1;
}
.indigo-pink.dark app-role-user-find .work-space .side .toolbar .header {
  color: #9fa8da;
}
.indigo-pink.dark app-role-user-find .work-space .side .toolbar .header h3 {
  color: #9fa8da;
}
.indigo-pink.dark app-role-user-find .work-space .side .container .role-resume {
  background: #9fa8da;
}
.indigo-pink.dark app-role-user-find .work-space .side .container .role-resume .line {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark app-role-user-find .work-space .side .container .role-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.1);
}
.indigo-pink.dark app-role-user-find .work-space .side .container .role-resume .line.editable button {
  background: #9fa8da;
}
.indigo-pink.dark app-role-user-find .work-space .side .container .section-title {
  color: #9fa8da;
}
.indigo-pink.dark app-role-user-find .work-space .side .container .section-resume {
  background: #424242;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark app-role-user-find .work-space .side .container .section-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-pink.dark app-role-user-find .work-space .side .container .section-resume .line.editable:hover .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #424242, 0 -2px 0px #424242, 2px 0 0px #424242, 0 2px 0px #424242, -2px -2px 0px #424242, 2px 2px 0px #424242, 2px -2px 0px #424242, -2px 2px 0px #424242 !important;
}
.indigo-pink.dark app-role-user-find .work-space .side .container .section-resume .line.editable button {
  background: #303030;
}
.indigo-pink.dark app-role-user-find .work-space .side .container .section-resume .line .header .icon-complex mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.indigo-pink.dark app-role-user-find .work-space .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #9fa8da !important;
}
.indigo-pink.dark app-role-user-find .work-space .side .container .section-resume .line .header mat-icon:not(.complex) {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-pink.dark app-role-user-find .work-space .side .container .section-resume .line .header .info h4 {
  color: #9fa8da;
}
.indigo-pink.dark app-role-user-find .work-space .side .container .section-resume .line ul {
  color: #9fa8da;
}
.indigo-pink.dark app-role-user-find .work-space .side .container .section-resume .line.head {
  background: black;
}
.indigo-pink.dark app-role-user-find .work-space .side .container .section-resume .line.head mat-icon {
  color: #f48fb1;
}
.indigo-pink.dark app-role-user-find .work-space .side .container .section-resume .line.head h4 {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee-title {
  color: #9fa8da;
}
.indigo-pink.dark app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body {
  background: #424242;
}
.indigo-pink.dark app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-pink.dark app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #9fa8da;
}
.indigo-pink.dark app-role-user-find .work-space .side.edition .toolbar .header {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark app-role-user-find .work-space .side.edition .toolbar .header mat-icon {
  color: #f48fb1;
}
.indigo-pink.dark app-role-user-find .work-space .side.edition .toolbar .header h3 {
  color: #f48fb1;
}
.indigo-pink.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body {
  background: #424242 !important;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header mat-icon:not(.complex) {
  color: #9fa8da;
}
.indigo-pink.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header h4 {
  color: #9fa8da;
}
.indigo-pink.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-actions {
  background: #303030;
}
.indigo-pink.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.indigo-pink.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header .icon-complex mat-icon.complex {
  color: #f48fb1 !important;
}
.indigo-pink.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header h4 {
  color: #f48fb1;
}
.indigo-pink.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line ul {
  color: #f48fb1;
}
.indigo-pink.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head mat-icon {
  color: #f48fb1 !important;
}
.indigo-pink.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head h4 {
  color: white;
}
.indigo-pink.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .section-title {
  color: white !important;
}
.indigo-pink.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume h6.hint {
  background: #f48fb1;
}
.indigo-pink.dark app-role-user-find .work-space app-role-user-detail.disabled .side .toolbar .header {
  color: #607D8B !important;
}
.indigo-pink.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container .role-resume {
  background: #607D8B !important;
}
.indigo-pink.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-title {
  color: #607D8B !important;
}
.indigo-pink.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line ul {
  color: #607D8B !important;
}
.indigo-pink.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header mat-icon {
  color: #607D8B;
}
.indigo-pink.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .icon-complex mat-icon {
  color: #607D8B !important;
}
.indigo-pink.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #607D8B !important;
}
.indigo-pink.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .info h4 {
  color: #607D8B !important;
}
.indigo-pink.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee-title {
  color: #607D8B;
}
.indigo-pink.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: #607D8B;
}
.indigo-pink.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #607D8B;
}
.indigo-pink.dark app-actor-find .main-overlay {
  background: rgba(0, 0, 0, 0.32);
}
.indigo-pink.dark app-actor-find .icon-complex mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.indigo-pink.dark app-actor-find .icon-complex mat-icon.complex {
  color: #9fa8da !important;
}
.indigo-pink.dark app-actor-find .navigation {
  background: #303030;
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark app-actor-find .navigation *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.indigo-pink.dark app-actor-find .navigation *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
.indigo-pink.dark app-actor-find .navigation .load-window {
  background: black;
}
.indigo-pink.dark app-actor-find .navigation .toolbar {
  background: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark app-actor-find .navigation .toolbar.reverse {
  background: #424242;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark app-actor-find .navigation .container .helper {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-pink.dark app-actor-find .navigation .container .item {
  background: #424242;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark app-actor-find .navigation .container .item:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-pink.dark app-actor-find .navigation .container .item.active {
  background: #9fa8da !important;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #9fa8da;
}
.indigo-pink.dark app-actor-find .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.indigo-pink.dark app-actor-find .work-space .main .load-window {
  background: #424242 !important;
}
.indigo-pink.dark app-actor-find .work-space .main.side-open {
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark app-actor-find .work-space .main .container .item {
  background: #303030;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark app-actor-find .work-space .main .container .item .subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-pink.dark app-actor-find .work-space .main .container .item.disabled {
  background: black;
  color: #607D8B !important;
}
.indigo-pink.dark app-actor-find .work-space .main .container .item.disabled h3 {
  color: #607D8B !important;
}
.indigo-pink.dark app-actor-find .work-space .main .container .item.disabled mat-icon {
  color: #607D8B !important;
}
.indigo-pink.dark app-actor-find .work-space .main .container .item.disabled.selected {
  background: #607D8B;
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-pink.dark app-actor-find .work-space .main .container .item.disabled.selected h3 {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-pink.dark app-actor-find .work-space .main .container .item.disabled.selected mat-icon {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-pink.dark app-actor-find .work-space .main .container .item.disabled.selected mat-icon.complex {
  color: rgba(0, 0, 0, 0.87) !important;
  text-shadow: -2px 0 0px #607D8B, 0 -2px 0px #607D8B, 2px 0 0px #607D8B, 0 2px 0px #607D8B, -2px -2px 0px #607D8B, 2px 2px 0px #607D8B, 2px -2px 0px #607D8B, -2px 2px 0px #607D8B !important;
}
.indigo-pink.dark app-actor-find .work-space .main .container .item mat-icon.complex {
  text-shadow: -2px 0 0px black, 0 -2px 0px black, 2px 0 0px black, 0 2px 0px black, -2px -2px 0px black, 2px 2px 0px black, 2px -2px 0px black, -2px 2px 0px black !important;
}
.indigo-pink.dark app-actor-find .work-space .main .container .item h5 {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-pink.dark app-actor-find .work-space .main .container .item h3 {
  color: white;
}
.indigo-pink.dark app-actor-find .work-space .main .container .item:hover {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-pink.dark app-actor-find .work-space .main .container .item.selected {
  background: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark app-actor-find .work-space .main .container .item.selected mat-icon {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark app-actor-find .work-space .main .container .item.selected mat-icon.complex {
  color: rgba(0, 0, 0, 0.87) !important;
  text-shadow: -2px 0 0px #9fa8da, 0 -2px 0px #9fa8da, 2px 0 0px #9fa8da, 0 2px 0px #9fa8da, -2px -2px 0px #9fa8da, 2px 2px 0px #9fa8da, 2px -2px 0px #9fa8da, -2px 2px 0px #9fa8da !important;
}
.indigo-pink.dark app-actor-find .work-space .main .container .item.selected h5,
.indigo-pink.dark app-actor-find .work-space .main .container .item.selected h3 {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark app-actor-find .work-space .main .main-action {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark app-actor-find .work-space .side {
  background: #303030;
}
.indigo-pink.dark app-actor-find .work-space .side .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #303030, 0 -2px 0px #303030, 2px 0 0px #303030, 0 2px 0px #303030, -2px -2px 0px #303030, 2px 2px 0px #303030, 2px -2px 0px #303030, -2px 2px 0px #303030;
}
.indigo-pink.dark app-actor-find .work-space .side *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.indigo-pink.dark app-actor-find .work-space .side *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
.indigo-pink.dark app-actor-find .work-space .side .edition-line {
  background: black;
}
.indigo-pink.dark app-actor-find .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #303030 !important;
}
.indigo-pink.dark app-actor-find .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-pink.dark app-actor-find .work-space .side mat-form-field .mat-form-field-flex {
  background: #303030;
}
.indigo-pink.dark app-actor-find .work-space .side mat-checkbox:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-pink.dark app-actor-find .work-space .side .hint.all {
  color: #f48fb1;
}
.indigo-pink.dark app-actor-find .work-space .side .toolbar .header {
  color: #9fa8da;
}
.indigo-pink.dark app-actor-find .work-space .side .toolbar .header h3 {
  color: #9fa8da;
}
.indigo-pink.dark app-actor-find .work-space .side .container .role-resume {
  background: #9fa8da;
}
.indigo-pink.dark app-actor-find .work-space .side .container .role-resume .line {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark app-actor-find .work-space .side .container .role-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.1);
}
.indigo-pink.dark app-actor-find .work-space .side .container .role-resume .line.editable button {
  background: #9fa8da;
}
.indigo-pink.dark app-actor-find .work-space .side .container .section-title {
  color: #9fa8da;
}
.indigo-pink.dark app-actor-find .work-space .side .container .section-resume {
  background: #424242;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark app-actor-find .work-space .side .container .section-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-pink.dark app-actor-find .work-space .side .container .section-resume .line.editable:hover .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #424242, 0 -2px 0px #424242, 2px 0 0px #424242, 0 2px 0px #424242, -2px -2px 0px #424242, 2px 2px 0px #424242, 2px -2px 0px #424242, -2px 2px 0px #424242 !important;
}
.indigo-pink.dark app-actor-find .work-space .side .container .section-resume .line.editable button {
  background: #303030;
}
.indigo-pink.dark app-actor-find .work-space .side .container .section-resume .line .header .icon-complex mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.indigo-pink.dark app-actor-find .work-space .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #9fa8da !important;
}
.indigo-pink.dark app-actor-find .work-space .side .container .section-resume .line .header mat-icon:not(.complex) {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-pink.dark app-actor-find .work-space .side .container .section-resume .line .header .info h4 {
  color: #9fa8da;
}
.indigo-pink.dark app-actor-find .work-space .side .container .section-resume .line ul {
  color: #9fa8da;
}
.indigo-pink.dark app-actor-find .work-space .side .container .section-resume .line.head {
  background: black;
}
.indigo-pink.dark app-actor-find .work-space .side .container .section-resume .line.head mat-icon {
  color: #f48fb1;
}
.indigo-pink.dark app-actor-find .work-space .side .container .section-resume .line.head h4 {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee-title {
  color: #9fa8da;
}
.indigo-pink.dark app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body {
  background: #424242;
}
.indigo-pink.dark app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-pink.dark app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #9fa8da;
}
.indigo-pink.dark app-actor-find .work-space .side.edition .toolbar .header {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark app-actor-find .work-space .side.edition .toolbar .header mat-icon {
  color: #f48fb1;
}
.indigo-pink.dark app-actor-find .work-space .side.edition .toolbar .header h3 {
  color: #f48fb1;
}
.indigo-pink.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body {
  background: #424242 !important;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header mat-icon:not(.complex) {
  color: #9fa8da;
}
.indigo-pink.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header h4 {
  color: #9fa8da;
}
.indigo-pink.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-actions {
  background: #303030;
}
.indigo-pink.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.indigo-pink.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header .icon-complex mat-icon.complex {
  color: #f48fb1 !important;
}
.indigo-pink.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header h4 {
  color: #f48fb1;
}
.indigo-pink.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line ul {
  color: #f48fb1;
}
.indigo-pink.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head mat-icon {
  color: #f48fb1 !important;
}
.indigo-pink.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head h4 {
  color: white;
}
.indigo-pink.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .section-title {
  color: white !important;
}
.indigo-pink.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume h6.hint {
  background: #f48fb1;
}
.indigo-pink.dark app-actor-find .work-space app-role-user-detail.disabled .side .toolbar .header {
  color: #607D8B !important;
}
.indigo-pink.dark app-actor-find .work-space app-role-user-detail.disabled .side .container .role-resume {
  background: #607D8B !important;
}
.indigo-pink.dark app-actor-find .work-space app-role-user-detail.disabled .side .container .section-title {
  color: #607D8B !important;
}
.indigo-pink.dark app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line ul {
  color: #607D8B !important;
}
.indigo-pink.dark app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header mat-icon {
  color: #607D8B;
}
.indigo-pink.dark app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .icon-complex mat-icon {
  color: #607D8B !important;
}
.indigo-pink.dark app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #607D8B !important;
}
.indigo-pink.dark app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .info h4 {
  color: #607D8B !important;
}
.indigo-pink.dark app-actor-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee-title {
  color: #607D8B;
}
.indigo-pink.dark app-actor-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: #607D8B;
}
.indigo-pink.dark app-actor-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #607D8B;
}
.indigo-pink.dark .dropzone {
  border: 2px dashed #607D8B;
  background: black !important;
}
.indigo-pink.dark .dropzone.hovering {
  border: 2px solid #607D8B;
  color: rgba(255, 255, 255, 0.5) !important;
}
.indigo-pink.dark .dropzone button.mat-stroked-button {
  background: #424242;
}
.indigo-pink.dark .firmador .content-action-main {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-pink.dark .firmador .content-action-main.disabled {
  color: rgba(255, 255, 255, 0.5) !important;
  background: rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark .sign-cycle {
  background: #424242;
}
.indigo-pink.dark .sign-cycle button.success {
  background-color: #009688;
}
.indigo-pink.dark .sign-cycle button.warning {
  background-color: #673AB7;
}
.indigo-pink.dark .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-title-container .cycle-content-title h4 {
  color: #607D8B;
}
.indigo-pink.dark .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-action .content-action-main {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-pink.dark .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-action .content-action-main.mat-stroked-button:disabled {
  color: rgba(255, 255, 255, 0.5) !important;
}
.indigo-pink.dark .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-action .content-action-main.mat-stroked-button:not(:disabled) {
  color: #f48fb1 !important;
}
.indigo-pink.dark .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-action .content-action-side u {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-pink.dark .resume-status {
  background: #424242;
}
.indigo-pink.dark .help-hoverable mat-icon {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-pink.dark .help-hoverable mat-hint {
  background: #424242;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark .help-hoverable mat-hint b {
  color: #9fa8da !important;
}
.indigo-pink.dark app-file-document-sign-massive-dialog .dialog-content,
.indigo-pink.dark app-share-person-dialog .dialog-content {
  background: #303030;
}
.indigo-pink.dark app-file-document-sign-massive-dialog .dialog-content mat-toolbar mat-toolbar-row .mat-avatar h3,
.indigo-pink.dark app-share-person-dialog .dialog-content mat-toolbar mat-toolbar-row .mat-avatar h3 {
  color: #9fa8da;
}
.indigo-pink.dark app-file-document-sign-massive-dialog .dialog-content .dialog-body p b,
.indigo-pink.dark app-share-person-dialog .dialog-content .dialog-body p b {
  color: #f48fb1;
}
.indigo-pink.dark app-file-document-sign-massive-dialog .dialog-content app-file-document-sign,
.indigo-pink.dark app-share-person-dialog .dialog-content app-file-document-sign {
  background: #424242;
}
.indigo-pink.dark .mat-basic-chip {
  background: black !important;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark .mat-basic-chip.seen {
  border-color: #2196F3 !important;
  color: #2196F3 !important;
}
.indigo-pink.dark .mat-basic-chip.unseen {
  border-color: #607D8B !important;
  color: #607D8B !important;
}
.indigo-pink.dark .mat-basic-chip.signed-ok {
  border-color: #009688 !important;
  color: #009688 !important;
}
.indigo-pink.dark .mat-basic-chip.signed-ok.cancelled {
  border-color: #795548 !important;
  color: #795548 !important;
}
.indigo-pink.dark .mat-basic-chip.signed-not-ok {
  border-color: #673AB7 !important;
  color: #673AB7 !important;
}
.indigo-pink.dark .mat-basic-chip.not-signed {
  border-color: #ff4081 !important;
  color: #ff4081 !important;
}
.indigo-pink.dark .mat-basic-chip.not-signed-employer {
  border-color: #ff4081 !important;
  color: #ff4081 !important;
}
.indigo-pink.dark app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip {
  background: black !important;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.seen {
  border-color: #2196F3 !important;
  color: #2196F3 !important;
}
.indigo-pink.dark app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.unseen {
  border-color: #607D8B !important;
  color: #607D8B !important;
}
.indigo-pink.dark app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.signed-ok {
  border-color: #009688 !important;
  color: #009688 !important;
}
.indigo-pink.dark app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.signed-ok.cancelled {
  border-color: #795548 !important;
  color: #795548 !important;
}
.indigo-pink.dark app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.signed-not-ok {
  border-color: #673AB7 !important;
  color: #673AB7 !important;
}
.indigo-pink.dark app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.not-signed {
  border-color: #ff4081 !important;
  color: #ff4081 !important;
}
.indigo-pink.dark app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.not-signed-employer {
  border-color: #ff4081 !important;
  color: #ff4081 !important;
}
.indigo-pink.dark .inbox-container.employee.my-documents .chip-line.mat-line .mat-chip-list-wrapper .mat-basic-chip.seen {
  color: #607D8B !important;
}
.indigo-pink.dark .resume-status .mat-basic-chip {
  background: black !important;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark .resume-status .mat-basic-chip.signed-ok {
  border-color: #009688 !important;
  background-color: #009688 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-pink.dark .resume-status .mat-basic-chip.signed-ok.cancelled {
  border-color: #795548 !important;
  background-color: #795548 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-pink.dark .resume-status .mat-basic-chip.signed-not-ok {
  border-color: #673AB7 !important;
  background-color: #673AB7 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-pink.dark app-file-document-view .new-container app-file-document-upload {
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark app-file-document-view .new-container app-upload-form .file-upload-list .mat-subheader {
  background: #424242;
}
.indigo-pink.dark app-file-document-view .new-container app-upload-form .file-upload-list mat-list-item {
  background: #303030 !important;
}
.indigo-pink.dark app-file-document-view .preview-content {
  background: #607D8B;
}
.indigo-pink.dark app-file-document-view .preview-content h4 {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark app-file-document-view .view-container {
  background: #424242;
}
.indigo-pink.dark app-file-document-view .view-container mat-toolbar.side-bar {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark .formio-hint {
  background: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark mat-dialog-container app-file-document-state-chips {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark app-candidate-promotion .load-window.message h3.primary-text {
  color: #9fa8da;
}
.indigo-pink.dark app-multiple-documentation-add-dialog .icon-complex mat-icon,
.indigo-pink.dark app-documentation-type-set-configuration-dialog .icon-complex mat-icon,
.indigo-pink.dark app-add-candidate-dialog .icon-complex mat-icon,
.indigo-pink.dark app-share-person-dialog .icon-complex mat-icon,
.indigo-pink.dark app-share-person-response-dialog .icon-complex mat-icon {
  color: white !important;
}
.indigo-pink.dark app-multiple-documentation-add-dialog .icon-complex mat-icon.complex,
.indigo-pink.dark app-documentation-type-set-configuration-dialog .icon-complex mat-icon.complex,
.indigo-pink.dark app-add-candidate-dialog .icon-complex mat-icon.complex,
.indigo-pink.dark app-share-person-dialog .icon-complex mat-icon.complex,
.indigo-pink.dark app-share-person-response-dialog .icon-complex mat-icon.complex {
  color: #9fa8da !important;
}
.indigo-pink.dark app-multiple-documentation-add-dialog .icon-complex mat-icon.complex.mat-accent,
.indigo-pink.dark app-documentation-type-set-configuration-dialog .icon-complex mat-icon.complex.mat-accent,
.indigo-pink.dark app-add-candidate-dialog .icon-complex mat-icon.complex.mat-accent,
.indigo-pink.dark app-share-person-dialog .icon-complex mat-icon.complex.mat-accent,
.indigo-pink.dark app-share-person-response-dialog .icon-complex mat-icon.complex.mat-accent {
  color: #f48fb1;
}
.indigo-pink.dark app-multiple-documentation-add-dialog .icon-complex mat-icon.complex.side,
.indigo-pink.dark app-documentation-type-set-configuration-dialog .icon-complex mat-icon.complex.side,
.indigo-pink.dark app-add-candidate-dialog .icon-complex mat-icon.complex.side,
.indigo-pink.dark app-share-person-dialog .icon-complex mat-icon.complex.side,
.indigo-pink.dark app-share-person-response-dialog .icon-complex mat-icon.complex.side {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection,
.indigo-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection,
.indigo-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection,
.indigo-pink.dark app-share-person-dialog .dialog-content .document-template-container .document-selection,
.indigo-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection {
  background: #303030;
}
.indigo-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.indigo-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.indigo-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3,
.indigo-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.indigo-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.indigo-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3,
.indigo-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.indigo-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.indigo-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3,
.indigo-pink.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.indigo-pink.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.indigo-pink.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3,
.indigo-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.indigo-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.indigo-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3 {
  color: #f48fb1 !important;
}
.indigo-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon,
.indigo-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon,
.indigo-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon,
.indigo-pink.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon,
.indigo-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon {
  color: #9fa8da;
}
.indigo-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3,
.indigo-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3,
.indigo-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3,
.indigo-pink.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3,
.indigo-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3 {
  color: #9fa8da;
}
.indigo-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex,
.indigo-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex,
.indigo-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex,
.indigo-pink.dark app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex,
.indigo-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex {
  color: #f48fb1 !important;
  text-shadow: -2px 0 0px black, 0 -2px 0px black, 2px 0 0px black, 0 2px 0px black, -2px -2px 0px black, 2px 2px 0px black, 2px -2px 0px black, -2px 2px 0px #424242;
}
.indigo-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex,
.indigo-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex,
.indigo-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex,
.indigo-pink.dark app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex,
.indigo-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex {
  color: rgba(255, 255, 255, 0.5) !important;
}
.indigo-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b,
.indigo-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b,
.indigo-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b,
.indigo-pink.dark app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b,
.indigo-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b {
  color: white;
}
.indigo-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex,
.indigo-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex,
.indigo-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex,
.indigo-pink.dark app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex,
.indigo-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex {
  color: rgba(255, 255, 255, 0.5) !important;
}
.indigo-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex,
.indigo-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex,
.indigo-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex,
.indigo-pink.dark app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex,
.indigo-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex {
  color: rgba(255, 255, 255, 0.5) !important;
}
.indigo-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover,
.indigo-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover,
.indigo-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover,
.indigo-pink.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover,
.indigo-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b,
.indigo-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b,
.indigo-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b,
.indigo-pink.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b,
.indigo-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b {
  color: white;
}
.indigo-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.indigo-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.indigo-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.indigo-pink.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.indigo-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble {
  background: #9fa8da;
}
.indigo-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble,
.indigo-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble,
.indigo-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble,
.indigo-pink.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble,
.indigo-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble {
  background: #e57373;
}
.indigo-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble,
.indigo-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble,
.indigo-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble,
.indigo-pink.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble,
.indigo-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble {
  color: rgba(255, 255, 255, 0.5);
  background: rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble,
.indigo-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble,
.indigo-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble,
.indigo-pink.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble,
.indigo-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble {
  background: rgba(255, 255, 255, 0.5);
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide,
.indigo-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide,
.indigo-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide,
.indigo-pink.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide,
.indigo-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide {
  border-color: rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble,
.indigo-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble,
.indigo-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble,
.indigo-pink.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble,
.indigo-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.indigo-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.indigo-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.indigo-pink.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.indigo-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble {
  color: #9fa8da;
}
.indigo-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble,
.indigo-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble,
.indigo-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble,
.indigo-pink.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble,
.indigo-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble {
  color: #e57373;
}
.indigo-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar,
.indigo-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar,
.indigo-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar,
.indigo-pink.dark app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar,
.indigo-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar {
  background: #607D8B;
}
.indigo-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row,
.indigo-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row,
.indigo-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row,
.indigo-pink.dark app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row,
.indigo-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper,
.indigo-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper,
.indigo-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper,
.indigo-pink.dark app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper,
.indigo-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper {
  background: #607D8B;
}
.indigo-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop,
.indigo-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop,
.indigo-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop,
.indigo-pink.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop,
.indigo-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop {
  border-left: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body,
.indigo-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body,
.indigo-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body,
.indigo-pink.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body,
.indigo-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body {
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3,
.indigo-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3,
.indigo-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3,
.indigo-pink.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3,
.indigo-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3 {
  color: #f48fb1;
}
.indigo-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5,
.indigo-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5,
.indigo-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5,
.indigo-pink.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5,
.indigo-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5 {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5,
.indigo-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5,
.indigo-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5,
.indigo-pink.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5,
.indigo-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5 {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator,
.indigo-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator,
.indigo-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator,
.indigo-pink.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator,
.indigo-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  background: #303030;
}
.indigo-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip,
.indigo-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip,
.indigo-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip,
.indigo-pink.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip,
.indigo-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip {
  background: #424242 !important;
}
.indigo-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon,
.indigo-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon,
.indigo-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon,
.indigo-pink.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon,
.indigo-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon {
  color: #f48fb1 !important;
}
.indigo-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3,
.indigo-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3,
.indigo-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3,
.indigo-pink.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3,
.indigo-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3 {
  color: #f48fb1 !important;
}
.indigo-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5,
.indigo-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5,
.indigo-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5,
.indigo-pink.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5,
.indigo-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5 {
  color: rgba(255, 255, 255, 0.7) !important;
}
.indigo-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon,
.indigo-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon,
.indigo-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon,
.indigo-pink.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon,
.indigo-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon {
  color: #9fa8da !important;
}
.indigo-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3,
.indigo-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3,
.indigo-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3,
.indigo-pink.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3,
.indigo-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3 {
  color: #9fa8da !important;
}
.indigo-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5,
.indigo-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5,
.indigo-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5,
.indigo-pink.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5,
.indigo-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5 {
  color: #9fa8da !important;
}
.indigo-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled,
.indigo-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled,
.indigo-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled,
.indigo-pink.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled,
.indigo-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled {
  background: #303030 !important;
  color: rgba(255, 255, 255, 0.5);
}
.indigo-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove,
.indigo-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove,
.indigo-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove,
.indigo-pink.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove,
.indigo-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove,
.indigo-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove,
.indigo-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove,
.indigo-pink.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove,
.indigo-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove {
  color: rgba(255, 255, 255, 0.7) !important;
}
.indigo-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .preview-action,
.indigo-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .preview-action,
.indigo-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .preview-action,
.indigo-pink.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .preview-action,
.indigo-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .preview-action {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark .tyc .sticky {
  background: #424242;
}
.indigo-pink.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper:nth-child(odd) datatable-body-row {
  background: #303030;
}
.indigo-pink.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active * {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-pink.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active:hover .datatable-body-cell-label {
  background: #9fa8da;
}
.indigo-pink.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active:hover .cs-floating-action {
  background: #9fa8da !important;
}
.indigo-pink.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active.row-cancel * {
  color: rgba(255, 255, 255, 0.7) !important;
}
.indigo-pink.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active.row-cancel .datatable-row-group {
  background: #607D8B !important;
}
.indigo-pink.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active.row-cancel:hover .datatable-body-cell-label {
  background: #607D8B;
}
.indigo-pink.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.row-cancel * {
  color: #b0bec5 !important;
  transition-property: color;
  transition-duration: 0.3s;
  transition-timing-function: linear;
}
.indigo-pink.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row:hover .datatable-body-cell-label button {
  background: black;
}
.indigo-pink.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row datatable-body-cell.candidate-status_bar mat-progress-bar .mat-progress-bar-buffer {
  background-color: black;
}
.indigo-pink.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row datatable-body-cell.candidate-status_bar .status_bar-count {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-pink.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row datatable-body-cell.candidate-status_bar .inactive {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-pink.dark .cs-grid-test .info {
  background: #424242;
}
.indigo-pink.dark .cs-grid-test .selected-column {
  background: #424242;
  -webkit-box-shadow: 0 1px 3px 0 rgba(255, 255, 255, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0 1px 3px 0 rgba(255, 255, 255, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 3px 0 rgba(255, 255, 255, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
}
.indigo-pink.dark .cs-grid-test .selection-cell .datatable-body-cell.active {
  background: #9fa8da !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-pink.dark .cs-grid-test nav {
  background: #424242;
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 13px 19px 2px rgba(0, 0, 0, 0.14), 0 5px 24px 4px rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark .cs-grid-test .ngx-datatable.material {
  background: #424242;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark .cs-grid-test .ngx-datatable.material.striped .datatable-row-odd {
  background: #303030;
}
.indigo-pink.dark .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active, .indigo-pink.dark .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active .datatable-row-group, .indigo-pink.dark .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active, .indigo-pink.dark .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active .datatable-row-group, .indigo-pink.dark .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active, .indigo-pink.dark .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active .datatable-row-group {
  background: #9fa8da;
  color: #424242;
}
.indigo-pink.dark .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active:hover, .indigo-pink.dark .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active:hover .datatable-row-group, .indigo-pink.dark .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active:hover, .indigo-pink.dark .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active:hover .datatable-row-group, .indigo-pink.dark .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active:hover, .indigo-pink.dark .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active:hover .datatable-row-group {
  background: #9fa8da;
  color: #424242;
}
.indigo-pink.dark .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active:focus, .indigo-pink.dark .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active:focus .datatable-row-group, .indigo-pink.dark .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active:focus, .indigo-pink.dark .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active:focus .datatable-row-group, .indigo-pink.dark .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active:focus, .indigo-pink.dark .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active:focus .datatable-row-group {
  background: #9fa8da;
  color: #424242;
}
.indigo-pink.dark .cs-grid-test .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover, .indigo-pink.dark .cs-grid-test .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-pink.dark .cs-grid-test .ngx-datatable.material:not(.cell-selection) .datatable-body-row:focus, .indigo-pink.dark .cs-grid-test .ngx-datatable.material:not(.cell-selection) .datatable-body-row:focus .datatable-row-group {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-pink.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell:hover, .indigo-pink.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell:hover .datatable-row-group {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-pink.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell:focus, .indigo-pink.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell:focus .datatable-row-group {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-pink.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active, .indigo-pink.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active .datatable-row-group {
  background: #9fa8da;
  color: #424242;
}
.indigo-pink.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active:hover, .indigo-pink.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active:hover .datatable-row-group {
  background: #9fa8da;
  color: #424242;
}
.indigo-pink.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active:focus, .indigo-pink.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active:focus .datatable-row-group {
  background: #9fa8da;
  color: #424242;
}
.indigo-pink.dark .cs-grid-test .ngx-datatable.material .datatable-header .datatable-row-left,
.indigo-pink.dark .cs-grid-test .ngx-datatable.material .datatable-body .datatable-row-left {
  background-color: #424242;
}
.indigo-pink.dark .cs-grid-test .ngx-datatable.material .datatable-header .datatable-row-right,
.indigo-pink.dark .cs-grid-test .ngx-datatable.material .datatable-body .datatable-row-right {
  background-color: #424242;
}
.indigo-pink.dark .cs-grid-test .ngx-datatable.material .datatable-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.indigo-pink.dark .cs-grid-test .ngx-datatable.material .datatable-header .datatable-header-cell {
  background-color: #424242;
  color: rgba(255, 255, 255, 0.7);
}
.indigo-pink.dark .cs-grid-test .ngx-datatable.material .datatable-header .resize-handle {
  border-right: solid 1px rgba(255, 255, 255, 0.04);
}
.indigo-pink.dark .cs-grid-test .ngx-datatable.material .datatable-body .datatable-row-detail {
  background: black;
}
.indigo-pink.dark .cs-grid-test .ngx-datatable.material .datatable-body .datatable-group-header {
  background: black;
  border-bottom: solid 1px rgba(255, 255, 255, 0.12);
  border-top: solid 1px rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark .cs-grid-test .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
  color: white;
}
.indigo-pink.dark .cs-grid-test .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-group-cell {
  color: white;
}
.indigo-pink.dark .cs-grid-test .ngx-datatable.material .datatable-body .progress-linear .container {
  background-color: #424242;
}
.indigo-pink.dark .cs-grid-test .ngx-datatable.material .datatable-body .progress-linear .container .bar {
  background-color: #9fa8da;
}
.indigo-pink.dark .cs-grid-test .ngx-datatable.material .datatable-footer {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-pink.dark .cs-grid-test .ngx-datatable.material .datatable-footer .datatable-pager li.disabled a {
  color: rgba(255, 255, 255, 0.5) !important;
  background-color: rgba(0, 0, 0, 0) !important;
}
.indigo-pink.dark .cs-grid-test .ngx-datatable.material .datatable-footer .datatable-pager li.active a {
  background-color: #9fa8da;
}
.indigo-pink.dark .cs-grid-test .ngx-datatable.material .datatable-footer .datatable-pager a {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-pink.dark .cs-grid-test .ngx-datatable.material .datatable-footer .datatable-pager a:hover {
  color: rgba(255, 255, 255, 0.04);
  background-color: rgba(255, 255, 255, 0.04);
}
.indigo-pink.dark .cs-grid-test .ngx-datatable.material .datatable-summary-row .datatable-body-row {
  background-color: #303030;
}
.indigo-pink.dark .cs-grid-test .ngx-datatable.material .datatable-summary-row .datatable-body-row:hover {
  background-color: #303030;
}
.indigo-pink.dark .cs-grid-test .datatable-checkbox input[type=checkbox]:after {
  background: #424242;
}
.indigo-pink.dark .view-container .view-toolbar {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal.new.image-opened .help-content img {
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card {
  background: #424242;
}
.indigo-pink.dark app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container .aditional-info {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-pink.dark app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container .mat-card-footer {
  background: #424242;
}
.indigo-pink.dark app-help-index mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card {
  background: #424242;
}
.indigo-pink.dark app-help-index mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card .mat-card-footer {
  background: #424242;
}
.indigo-pink.dark .cropper .employee-title .title-text {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-pink.dark .cropper app-cs-upload-cropper-control .preview h5 {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-pink.dark app-file-document-upload .preview-container {
  background: #607D8B !important;
}
.indigo-pink.dark app-file-document-upload .preview-container h4 {
  color: black;
}
.indigo-pink.dark .preview-container {
  background: #607D8B !important;
}
.indigo-pink.dark .preview-container h4 {
  color: black;
}
.indigo-pink.dark .clean-autocomple mat-list {
  background: #424242;
}
.indigo-pink.dark .clean-autocomple mat-list .mat-list-item:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-pink.dark .clean-autocomple mat-list .mat-list-item .mat-list-item-content .mat-list-text {
  color: #9fa8da;
}
.indigo-pink.dark app-leave .inbox-toolbar {
  background: #303030;
}
.indigo-pink.dark app-leave .mat-tab-body-wrapper {
  position: relative;
  overflow: hidden;
  display: flex;
  transition: height 500ms cubic-bezier(0.35, 0, 0.25, 1);
  height: 95% !important;
}
.indigo-pink.dark app-leave .leave_container {
  background: #303030;
}
.indigo-pink.dark app-leave .leave_container .leave_items .leave_items-item.ok .content .leave_item-status_icon {
  color: #4db6ac;
}
.indigo-pink.dark app-leave .leave_container .leave_items .leave_items-item.not-ok .content .leave_item-status_icon {
  color: #ba68c8;
}
.indigo-pink.dark app-leave .leave_container .leave_items .leave_items-item.pending .content .leave_item-status_icon {
  color: #ffb74d;
}
.indigo-pink.dark app-leave .leave_container .leave_items .leave_items-item.draft .content .leave_item-status_icon {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-pink.dark app-leave .leave_container .leave_items .leave_items-item.cancel .content .leave_item-status_icon {
  color: #795548;
}
.indigo-pink.dark app-leave .leave_container .leave_items .leave_items-item .content:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-pink.dark app-leave .leave_container .leave_items .leave_items-item .content.selected {
  border-left: 6px solid #f48fb1 !important;
}
.indigo-pink.dark app-leave .leave_container .leave_items .leave_items-item .content.selected mat-icon {
  color: #f48fb1 !important;
}
.indigo-pink.dark app-leave .leave_container .leave_items .leave_items-item .content.selected .item-data {
  color: white !important;
}
.indigo-pink.dark app-leave .leave_container .leave_items .leave_items-item .content .leave_item-icon {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-pink.dark app-leave .leave_container .leave_items .leave_items-item .content .item-data {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-pink.dark app-leave .leave_container .window-spinner {
  background: #303030 !important;
}
.indigo-pink.dark app-leave-detail .leave_detail .leave_detail-title .info h3,
.indigo-pink.dark app-leave-detail .leave_detail .leave_detail-title .info mat-icon {
  color: #9fa8da;
}
.indigo-pink.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .ok .large-icon {
  background: #e0f2f1;
  color: #4db6ac;
}
.indigo-pink.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .ok h2 {
  color: #4db6ac;
}
.indigo-pink.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .not-ok .large-icon {
  background: #f3e5f5;
  color: #ba68c8;
}
.indigo-pink.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .not-ok h2 {
  color: #ba68c8;
}
.indigo-pink.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .pending .large-icon {
  background: #fff3e0;
  color: #ffb74d;
}
.indigo-pink.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .pending h2 {
  color: #ffb74d;
}
.indigo-pink.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .draft .large-icon {
  background: #f2f2f2;
  color: #dedede;
}
.indigo-pink.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .draft h2 {
  color: #dedede;
}
.indigo-pink.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .cancelled .large-icon {
  background: #BCAAA4;
  color: #795548;
}
.indigo-pink.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .cancelled h2 {
  color: #795548;
}
.indigo-pink.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .actions {
  padding: 16px;
  height: 96px;
  column-gap: 16px;
}
.indigo-pink.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .actions button {
  height: 100%;
  border-radius: 40px;
}
.indigo-pink.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .actions button.ok {
  background: #4db6ac !important;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .actions button.not-ok {
  color: #ba68c8 !important;
}
.indigo-pink.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning mat-basic-chip {
  background: black !important;
  border: 1px solid rgba(255, 255, 255, 0.12) !important;
  border-radius: 40px !important;
  height: 35px !important;
}
.indigo-pink.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning mat-basic-chip.signed-ok {
  border-color: #4db6ac !important;
  color: #4db6ac !important;
}
.indigo-pink.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning mat-basic-chip.signed-not-ok {
  border-color: #ba68c8 !important;
  color: #ba68c8 !important;
}
.indigo-pink.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning mat-basic-chip.draft {
  border-color: #dedede !important;
  color: #dedede !important;
}
.indigo-pink.dark app-leave-request-detail .load-window {
  background: #303030;
}
.indigo-pink.dark app-leave-request-detail form .leave_detail-container {
  background: #303030;
}
.indigo-pink.dark app-leave-request-detail form .leave_detail-container .toolbar .header {
  color: #9fa8da;
}
.indigo-pink.dark app-leave-request-detail form .leave_detail-container .toolbar .header .text h3 {
  color: #9fa8da;
}
.indigo-pink.dark app-leave-request-detail form .leave_detail-container .toolbar .header .text h5 {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-pink.dark app-leave-request-detail form .leave_detail-container .leave_detail-container-content .leave_resume {
  background: #424242;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark app-leave-request-detail form .leave_detail-container .leave_detail-container-content .line h6 {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-pink.dark app-leave-request-detail form .leave_detail-container .actions button.ok {
  background: #4db6ac !important;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark app-leave-request-detail form .leave_detail-container .actions button.not-ok {
  color: #ba68c8 !important;
}
.indigo-pink.dark .new_leave_modal-steps {
  display: unset !important;
}
.indigo-pink.dark .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button {
  background: #303030;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button .mat-radio-label .mat-radio-label-content .side-text {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-pink.dark .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button .mat-radio-label .mat-radio-label-content .side-text.muted {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-pink.dark .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button .mat-radio-label .mat-radio-label-content .accent mat-icon,
.indigo-pink.dark .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button .mat-radio-label .mat-radio-label-content .accent .main-text {
  color: #f48fb1;
}
.indigo-pink.dark .config_leave-container .config_leave-card {
  background: #424242;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark .config_leave-container .config_leave-card .config_leave-auto_chip mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #303030 !important;
}
.indigo-pink.dark .config_leave-container .config_leave-card .config_leave-auto_chip mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-pink.dark .config_leave-container .config_leave-card .config_leave-auto_chip mat-form-field .mat-form-field-flex {
  background: #303030;
}
.indigo-pink.dark *[color=success] {
  color: #009688;
}
.indigo-pink.dark .primary {
  color: #9fa8da !important;
}
.indigo-pink.dark .accent {
  color: #f48fb1 !important;
}
.indigo-pink.dark app-lsd .icon-complex mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.indigo-pink.dark app-lsd .icon-complex mat-icon.complex {
  color: #9fa8da !important;
}
.indigo-pink.dark app-lsd .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.indigo-pink.dark app-lsd .work-space .main .load-window {
  background: #424242 !important;
}
.indigo-pink.dark app-lsd .work-space .main.side-open {
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark app-lsd .work-space .main .container .item {
  background: #303030;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark app-lsd .work-space .main .container .item h5 {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-pink.dark app-lsd .work-space .main .container .item h3 {
  color: white;
}
.indigo-pink.dark app-lsd .work-space .main .container .item:hover {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-pink.dark app-lsd .work-space .main .container .item.selected {
  background: #9fa8da;
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-pink.dark app-lsd .work-space .main .container .item.selected h3 {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-pink.dark app-lsd .work-space .main .container .item.selected.pending .status {
  background: rgba(255, 255, 255, 0.12) !important;
  color: #ffe082;
}
.indigo-pink.dark app-lsd .work-space .main .container .item.selected.ok .status {
  background: rgba(255, 255, 255, 0.12) !important;
  color: #a5d6a7;
}
.indigo-pink.dark app-lsd .work-space .main .container .item.selected.not-ok .status {
  background: rgba(255, 255, 255, 0.12) !important;
  color: #e57373;
}
.indigo-pink.dark app-lsd .work-space .main .main-action {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark app-lsd .work-space .side {
  background: #303030;
}
.indigo-pink.dark app-lsd .work-space .side .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #303030, 0 -2px 0px #303030, 2px 0 0px #303030, 0 2px 0px #303030, -2px -2px 0px #303030, 2px 2px 0px #303030, 2px -2px 0px #303030, -2px 2px 0px #303030;
}
.indigo-pink.dark app-lsd .work-space .side *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.indigo-pink.dark app-lsd .work-space .side *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
.indigo-pink.dark app-lsd .work-space .side .edition-line {
  background: black;
}
.indigo-pink.dark app-lsd .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #303030 !important;
}
.indigo-pink.dark app-lsd .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-pink.dark app-lsd .work-space .side mat-form-field .mat-form-field-flex {
  background: #303030;
}
.indigo-pink.dark app-lsd .work-space .side mat-checkbox:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-pink.dark app-lsd .work-space .side .hint.all {
  color: #f48fb1;
}
.indigo-pink.dark app-lsd .work-space .side .toolbar .header {
  color: #9fa8da;
}
.indigo-pink.dark app-lsd .work-space .side .toolbar .header h3 {
  color: #9fa8da;
}
.indigo-pink.dark app-lsd .work-space .side .container .doc-resume {
  background: #9fa8da;
}
.indigo-pink.dark app-lsd .work-space .side .container .doc-resume .line {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark app-lsd .work-space .side .container .doc-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.1);
}
.indigo-pink.dark app-lsd .work-space .side .container .doc-resume .line.editable button {
  background: #9fa8da;
}
.indigo-pink.dark app-lsd .work-space .side .container .section-title {
  color: #9fa8da;
}
.indigo-pink.dark app-lsd .work-space .side .container .section-resume {
  background: #424242;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark app-lsd .work-space .side .container .section-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-pink.dark app-lsd .work-space .side .container .section-resume .line.editable:hover .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #424242, 0 -2px 0px #424242, 2px 0 0px #424242, 0 2px 0px #424242, -2px -2px 0px #424242, 2px 2px 0px #424242, 2px -2px 0px #424242, -2px 2px 0px #424242 !important;
}
.indigo-pink.dark app-lsd .work-space .side .container .section-resume .line.editable button {
  background: #303030;
}
.indigo-pink.dark app-lsd .work-space .side .container .section-resume .line .header .icon-complex mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.indigo-pink.dark app-lsd .work-space .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #9fa8da !important;
}
.indigo-pink.dark app-lsd .work-space .side .container .section-resume .line .header mat-icon:not(.complex) {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-pink.dark app-lsd .work-space .side .container .section-resume .line .header .info h4 {
  color: #9fa8da;
}
.indigo-pink.dark app-lsd .work-space .side .container .section-resume .line ul {
  color: #9fa8da;
}
.indigo-pink.dark app-lsd .work-space .side .container .section-resume .line.head {
  background: black;
}
.indigo-pink.dark app-lsd .work-space .side .container .section-resume .line.head mat-icon {
  color: #f48fb1;
}
.indigo-pink.dark app-lsd .work-space .side .container .section-resume .line.head h4 {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark app-lsd .work-space .side.edition .toolbar .header {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-pink.dark app-lsd .work-space .side.edition .toolbar .header mat-icon {
  color: #f48fb1;
}
.indigo-pink.dark app-lsd .work-space .side.edition .toolbar .header h3 {
  color: #f48fb1;
}
.indigo-pink.dark .dialog-content {
  max-height: 90vh;
}
.indigo-pink.dark .dialog-content mat-toolbar {
  background: #424242;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark .dialog-content .dialog-container .document-template-container .document-selection mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #303030 !important;
}
.indigo-pink.dark .dialog-content .dialog-container .document-template-container .document-selection mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-pink.dark .dialog-content .dialog-container .document-template-container .document-selection mat-form-field .mat-form-field-flex {
  background: #303030;
}
.indigo-pink.dark .dialog-content .dialog-container .document-template-container .document-selection app-upload-form .file-upload-list .mat-subheader::before {
  color: #9fa8da;
}
.indigo-pink.dark .dialog-content .dialog-container .view-container {
  border-left: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-pink.dark .dialog-content .form-actions {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}

.indigo-purple .mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-purple .mat-badge-small .mat-badge-content {
  font-size: 9px;
}
.indigo-purple .mat-badge-large .mat-badge-content {
  font-size: 24px;
}
.indigo-purple .mat-h1,
.indigo-purple .mat-headline,
.indigo-purple .mat-typography .mat-h1,
.indigo-purple .mat-typography .mat-headline,
.indigo-purple .mat-typography h1 {
  font: 400 24px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.indigo-purple .mat-h2,
.indigo-purple .mat-title,
.indigo-purple .mat-typography .mat-h2,
.indigo-purple .mat-typography .mat-title,
.indigo-purple .mat-typography h2 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.indigo-purple .mat-h3,
.indigo-purple .mat-subheading-2,
.indigo-purple .mat-typography .mat-h3,
.indigo-purple .mat-typography .mat-subheading-2,
.indigo-purple .mat-typography h3 {
  font: 400 16px / 28px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.indigo-purple .mat-h4,
.indigo-purple .mat-subheading-1,
.indigo-purple .mat-typography .mat-h4,
.indigo-purple .mat-typography .mat-subheading-1,
.indigo-purple .mat-typography h4 {
  font: 400 15px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.indigo-purple .mat-h5,
.indigo-purple .mat-typography .mat-h5,
.indigo-purple .mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}
.indigo-purple .mat-h6,
.indigo-purple .mat-typography .mat-h6,
.indigo-purple .mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}
.indigo-purple .mat-body-strong,
.indigo-purple .mat-body-2,
.indigo-purple .mat-typography .mat-body-strong,
.indigo-purple .mat-typography .mat-body-2 {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.indigo-purple .mat-body,
.indigo-purple .mat-body-1,
.indigo-purple .mat-typography .mat-body,
.indigo-purple .mat-typography .mat-body-1,
.indigo-purple .mat-typography {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.indigo-purple .mat-body p,
.indigo-purple .mat-body-1 p,
.indigo-purple .mat-typography .mat-body p,
.indigo-purple .mat-typography .mat-body-1 p,
.indigo-purple .mat-typography p {
  margin: 0 0 12px;
}
.indigo-purple .mat-small,
.indigo-purple .mat-caption,
.indigo-purple .mat-typography .mat-small,
.indigo-purple .mat-typography .mat-caption {
  font: 400 12px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.indigo-purple .mat-display-4,
.indigo-purple .mat-typography .mat-display-4 {
  font: 300 112px / 112px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}
.indigo-purple .mat-display-3,
.indigo-purple .mat-typography .mat-display-3 {
  font: 400 56px / 56px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}
.indigo-purple .mat-display-2,
.indigo-purple .mat-typography .mat-display-2 {
  font: 400 45px / 48px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}
.indigo-purple .mat-display-1,
.indigo-purple .mat-typography .mat-display-1 {
  font: 400 34px / 40px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}
.indigo-purple .mat-bottom-sheet-container {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.indigo-purple .mat-button, .indigo-purple .mat-raised-button, .indigo-purple .mat-icon-button, .indigo-purple .mat-stroked-button,
.indigo-purple .mat-flat-button, .indigo-purple .mat-fab, .indigo-purple .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.indigo-purple .mat-button-toggle {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-purple .mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-purple .mat-card-title {
  font-size: 24px;
  font-weight: 500;
}
.indigo-purple .mat-card-header .mat-card-title {
  font-size: 20px;
}
.indigo-purple .mat-card-subtitle,
.indigo-purple .mat-card-content {
  font-size: 14px;
}
.indigo-purple .mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-purple .mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}
.indigo-purple .mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.indigo-purple .mat-chip .mat-chip-trailing-icon.mat-icon,
.indigo-purple .mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}
.indigo-purple .mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-purple .mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}
.indigo-purple .mat-cell, .indigo-purple .mat-footer-cell {
  font-size: 14px;
}
.indigo-purple .mat-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-purple .mat-calendar-body {
  font-size: 13px;
}
.indigo-purple .mat-calendar-body-label,
.indigo-purple .mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}
.indigo-purple .mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}
.indigo-purple .mat-dialog-title {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.indigo-purple .mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400;
}
.indigo-purple .mat-expansion-panel-content {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.indigo-purple .mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.indigo-purple .mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}
.indigo-purple .mat-form-field-prefix .mat-icon,
.indigo-purple .mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.indigo-purple .mat-form-field-prefix .mat-icon-button,
.indigo-purple .mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.indigo-purple .mat-form-field-prefix .mat-icon-button .mat-icon,
.indigo-purple .mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}
.indigo-purple .mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}
.indigo-purple .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.indigo-purple .mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34371em) scale(0.75);
  width: 133.3333733333%;
}
.indigo-purple .mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.3437em) scale(0.75);
  width: 133.3333833333%;
}
.indigo-purple .mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}
.indigo-purple .mat-form-field-label {
  top: 1.34375em;
}
.indigo-purple .mat-form-field-underline {
  bottom: 1.34375em;
}
.indigo-purple .mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}
.indigo-purple .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.indigo-purple .mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.indigo-purple .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.indigo-purple .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00112px);
  width: 133.3334533333%;
}
.indigo-purple .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00113px);
  width: 133.3334633333%;
}
.indigo-purple .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00114px);
  width: 133.3334733333%;
}
.indigo-purple .mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.indigo-purple .mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.indigo-purple .mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}
@media print {
  .indigo-purple .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.indigo-purple .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2811em) scale(0.75);
  }
  .indigo-purple .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28109em) scale(0.75);
  }
  .indigo-purple .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28108em) scale(0.75);
  }
}
.indigo-purple .mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.indigo-purple .mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.indigo-purple .mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.indigo-purple .mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59371em) scale(0.75);
  width: 133.3333733333%;
}
.indigo-purple .mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.5937em) scale(0.75);
  width: 133.3333833333%;
}
.indigo-purple .mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.indigo-purple .mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.indigo-purple .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.indigo-purple .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59371em) scale(0.75);
  width: 133.3333733333%;
}
.indigo-purple .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.5937em) scale(0.75);
  width: 133.3333833333%;
}
.indigo-purple .mat-grid-tile-header,
.indigo-purple .mat-grid-tile-footer {
  font-size: 14px;
}
.indigo-purple .mat-grid-tile-header .mat-line,
.indigo-purple .mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.indigo-purple .mat-grid-tile-header .mat-line:nth-child(n+2),
.indigo-purple .mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.indigo-purple input.mat-input-element {
  margin-top: -0.0625em;
}
.indigo-purple .mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
}
.indigo-purple .mat-paginator,
.indigo-purple .mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
}
.indigo-purple .mat-radio-button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-purple .mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-purple .mat-select-trigger {
  height: 1.125em;
}
.indigo-purple .mat-slide-toggle-content {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-purple .mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}
.indigo-purple .mat-stepper-vertical, .indigo-purple .mat-stepper-horizontal {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-purple .mat-step-label {
  font-size: 14px;
  font-weight: 400;
}
.indigo-purple .mat-step-sub-label-error {
  font-weight: normal;
}
.indigo-purple .mat-step-label-error {
  font-size: 14px;
}
.indigo-purple .mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}
.indigo-purple .mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-purple .mat-tab-label, .indigo-purple .mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.indigo-purple .mat-toolbar,
.indigo-purple .mat-toolbar h1,
.indigo-purple .mat-toolbar h2,
.indigo-purple .mat-toolbar h3,
.indigo-purple .mat-toolbar h4,
.indigo-purple .mat-toolbar h5,
.indigo-purple .mat-toolbar h6 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0;
}
.indigo-purple .mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.indigo-purple .mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.indigo-purple .mat-list-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-purple .mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-purple .mat-list-base .mat-list-item {
  font-size: 16px;
}
.indigo-purple .mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.indigo-purple .mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.indigo-purple .mat-list-base .mat-list-option {
  font-size: 16px;
}
.indigo-purple .mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.indigo-purple .mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.indigo-purple .mat-list-base .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.indigo-purple .mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.indigo-purple .mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.indigo-purple .mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.indigo-purple .mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.indigo-purple .mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.indigo-purple .mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.indigo-purple .mat-list-base[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}
.indigo-purple .mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
}
.indigo-purple .mat-optgroup-label {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.indigo-purple .mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
}
.indigo-purple .mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}
.indigo-purple .mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-purple .mat-tree-node,
.indigo-purple .mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}
.indigo-purple .mat-ripple {
  overflow: hidden;
  position: relative;
}
.indigo-purple .mat-ripple:not(:empty) {
  transform: translateZ(0);
}
.indigo-purple .mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}
.indigo-purple .mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .indigo-purple .mat-ripple-element {
  display: none;
}

.indigo-purple .cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .indigo-purple .cdk-visually-hidden {
  left: auto;
  right: 0;
}
.indigo-purple .cdk-overlay-container, .indigo-purple .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.indigo-purple .cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.indigo-purple .cdk-overlay-container:empty {
  display: none;
}
.indigo-purple .cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}
.indigo-purple .cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}
.indigo-purple .cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.indigo-purple .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .indigo-purple .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}
.indigo-purple .cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}
.indigo-purple .cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.indigo-purple .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}
.indigo-purple .cdk-overlay-backdrop-noop-animation {
  transition: none;
}
.indigo-purple .cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}
.indigo-purple .cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}
.indigo-purple textarea.cdk-textarea-autosize {
  resize: none;
}
.indigo-purple textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}
.indigo-purple textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}
@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.indigo-purple .cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}
.indigo-purple .cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}
.indigo-purple .mat-focus-indicator {
  position: relative;
}
.indigo-purple .mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.indigo-purple .mat-focus-indicator:focus::before {
  content: "";
}
.cdk-high-contrast-active .indigo-purple {
  --mat-focus-indicator-display: block;
}

.indigo-purple .mat-mdc-focus-indicator {
  position: relative;
}
.indigo-purple .mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.indigo-purple .mat-mdc-focus-indicator:focus::before {
  content: "";
}
.cdk-high-contrast-active .indigo-purple {
  --mat-mdc-focus-indicator-display: block;
}

.indigo-purple .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.indigo-purple .mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple .mat-option:hover:not(.mat-option-disabled), .indigo-purple .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.indigo-purple .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.indigo-purple .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-purple .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #3f51b5;
}
.indigo-purple .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #9c27b0;
}
.indigo-purple .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f44336;
}
.indigo-purple .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-purple .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-purple .mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-purple .mat-pseudo-checkbox::after {
  color: #fafafa;
}
.indigo-purple .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.indigo-purple .mat-primary .mat-pseudo-checkbox-checked,
.indigo-purple .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #3f51b5;
}
.indigo-purple .mat-pseudo-checkbox-checked,
.indigo-purple .mat-pseudo-checkbox-indeterminate,
.indigo-purple .mat-accent .mat-pseudo-checkbox-checked,
.indigo-purple .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #9c27b0;
}
.indigo-purple .mat-warn .mat-pseudo-checkbox-checked,
.indigo-purple .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #f44336;
}
.indigo-purple .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.indigo-purple .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.indigo-purple .mat-app-background, .indigo-purple.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.indigo-purple .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.indigo-purple .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.indigo-purple .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.indigo-purple .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.indigo-purple .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.indigo-purple .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.indigo-purple .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.indigo-purple .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.indigo-purple .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.indigo-purple .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.indigo-purple .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.indigo-purple .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.indigo-purple .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.indigo-purple .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.indigo-purple .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.indigo-purple .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.indigo-purple .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.indigo-purple .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.indigo-purple .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.indigo-purple .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.indigo-purple .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.indigo-purple .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.indigo-purple .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.indigo-purple .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.indigo-purple .mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.indigo-purple .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.indigo-purple .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple .mat-badge-content {
  color: white;
  background: #3f51b5;
}
.cdk-high-contrast-active .indigo-purple .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.indigo-purple .mat-badge-accent .mat-badge-content {
  background: #9c27b0;
  color: white;
}
.indigo-purple .mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336;
}
.indigo-purple .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.indigo-purple .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple .mat-button, .indigo-purple .mat-icon-button, .indigo-purple .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.indigo-purple .mat-button.mat-primary, .indigo-purple .mat-icon-button.mat-primary, .indigo-purple .mat-stroked-button.mat-primary {
  color: #3f51b5;
}
.indigo-purple .mat-button.mat-accent, .indigo-purple .mat-icon-button.mat-accent, .indigo-purple .mat-stroked-button.mat-accent {
  color: #9c27b0;
}
.indigo-purple .mat-button.mat-warn, .indigo-purple .mat-icon-button.mat-warn, .indigo-purple .mat-stroked-button.mat-warn {
  color: #f44336;
}
.indigo-purple .mat-button.mat-primary.mat-button-disabled, .indigo-purple .mat-button.mat-accent.mat-button-disabled, .indigo-purple .mat-button.mat-warn.mat-button-disabled, .indigo-purple .mat-button.mat-button-disabled.mat-button-disabled, .indigo-purple .mat-icon-button.mat-primary.mat-button-disabled, .indigo-purple .mat-icon-button.mat-accent.mat-button-disabled, .indigo-purple .mat-icon-button.mat-warn.mat-button-disabled, .indigo-purple .mat-icon-button.mat-button-disabled.mat-button-disabled, .indigo-purple .mat-stroked-button.mat-primary.mat-button-disabled, .indigo-purple .mat-stroked-button.mat-accent.mat-button-disabled, .indigo-purple .mat-stroked-button.mat-warn.mat-button-disabled, .indigo-purple .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.indigo-purple .mat-button.mat-primary .mat-button-focus-overlay, .indigo-purple .mat-icon-button.mat-primary .mat-button-focus-overlay, .indigo-purple .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #3f51b5;
}
.indigo-purple .mat-button.mat-accent .mat-button-focus-overlay, .indigo-purple .mat-icon-button.mat-accent .mat-button-focus-overlay, .indigo-purple .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #9c27b0;
}
.indigo-purple .mat-button.mat-warn .mat-button-focus-overlay, .indigo-purple .mat-icon-button.mat-warn .mat-button-focus-overlay, .indigo-purple .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #f44336;
}
.indigo-purple .mat-button.mat-button-disabled .mat-button-focus-overlay, .indigo-purple .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .indigo-purple .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.indigo-purple .mat-button .mat-ripple-element, .indigo-purple .mat-icon-button .mat-ripple-element, .indigo-purple .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.indigo-purple .mat-button-focus-overlay {
  background: black;
}
.indigo-purple .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.indigo-purple .mat-flat-button, .indigo-purple .mat-raised-button, .indigo-purple .mat-fab, .indigo-purple .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.indigo-purple .mat-flat-button.mat-primary, .indigo-purple .mat-raised-button.mat-primary, .indigo-purple .mat-fab.mat-primary, .indigo-purple .mat-mini-fab.mat-primary {
  color: white;
}
.indigo-purple .mat-flat-button.mat-accent, .indigo-purple .mat-raised-button.mat-accent, .indigo-purple .mat-fab.mat-accent, .indigo-purple .mat-mini-fab.mat-accent {
  color: white;
}
.indigo-purple .mat-flat-button.mat-warn, .indigo-purple .mat-raised-button.mat-warn, .indigo-purple .mat-fab.mat-warn, .indigo-purple .mat-mini-fab.mat-warn {
  color: white;
}
.indigo-purple .mat-flat-button.mat-primary.mat-button-disabled, .indigo-purple .mat-flat-button.mat-accent.mat-button-disabled, .indigo-purple .mat-flat-button.mat-warn.mat-button-disabled, .indigo-purple .mat-flat-button.mat-button-disabled.mat-button-disabled, .indigo-purple .mat-raised-button.mat-primary.mat-button-disabled, .indigo-purple .mat-raised-button.mat-accent.mat-button-disabled, .indigo-purple .mat-raised-button.mat-warn.mat-button-disabled, .indigo-purple .mat-raised-button.mat-button-disabled.mat-button-disabled, .indigo-purple .mat-fab.mat-primary.mat-button-disabled, .indigo-purple .mat-fab.mat-accent.mat-button-disabled, .indigo-purple .mat-fab.mat-warn.mat-button-disabled, .indigo-purple .mat-fab.mat-button-disabled.mat-button-disabled, .indigo-purple .mat-mini-fab.mat-primary.mat-button-disabled, .indigo-purple .mat-mini-fab.mat-accent.mat-button-disabled, .indigo-purple .mat-mini-fab.mat-warn.mat-button-disabled, .indigo-purple .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.indigo-purple .mat-flat-button.mat-primary, .indigo-purple .mat-raised-button.mat-primary, .indigo-purple .mat-fab.mat-primary, .indigo-purple .mat-mini-fab.mat-primary {
  background-color: #3f51b5;
}
.indigo-purple .mat-flat-button.mat-accent, .indigo-purple .mat-raised-button.mat-accent, .indigo-purple .mat-fab.mat-accent, .indigo-purple .mat-mini-fab.mat-accent {
  background-color: #9c27b0;
}
.indigo-purple .mat-flat-button.mat-warn, .indigo-purple .mat-raised-button.mat-warn, .indigo-purple .mat-fab.mat-warn, .indigo-purple .mat-mini-fab.mat-warn {
  background-color: #f44336;
}
.indigo-purple .mat-flat-button.mat-primary.mat-button-disabled, .indigo-purple .mat-flat-button.mat-accent.mat-button-disabled, .indigo-purple .mat-flat-button.mat-warn.mat-button-disabled, .indigo-purple .mat-flat-button.mat-button-disabled.mat-button-disabled, .indigo-purple .mat-raised-button.mat-primary.mat-button-disabled, .indigo-purple .mat-raised-button.mat-accent.mat-button-disabled, .indigo-purple .mat-raised-button.mat-warn.mat-button-disabled, .indigo-purple .mat-raised-button.mat-button-disabled.mat-button-disabled, .indigo-purple .mat-fab.mat-primary.mat-button-disabled, .indigo-purple .mat-fab.mat-accent.mat-button-disabled, .indigo-purple .mat-fab.mat-warn.mat-button-disabled, .indigo-purple .mat-fab.mat-button-disabled.mat-button-disabled, .indigo-purple .mat-mini-fab.mat-primary.mat-button-disabled, .indigo-purple .mat-mini-fab.mat-accent.mat-button-disabled, .indigo-purple .mat-mini-fab.mat-warn.mat-button-disabled, .indigo-purple .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.indigo-purple .mat-flat-button.mat-primary .mat-ripple-element, .indigo-purple .mat-raised-button.mat-primary .mat-ripple-element, .indigo-purple .mat-fab.mat-primary .mat-ripple-element, .indigo-purple .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.indigo-purple .mat-flat-button.mat-accent .mat-ripple-element, .indigo-purple .mat-raised-button.mat-accent .mat-ripple-element, .indigo-purple .mat-fab.mat-accent .mat-ripple-element, .indigo-purple .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.indigo-purple .mat-flat-button.mat-warn .mat-ripple-element, .indigo-purple .mat-raised-button.mat-warn .mat-ripple-element, .indigo-purple .mat-fab.mat-warn .mat-ripple-element, .indigo-purple .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.indigo-purple .mat-stroked-button:not([class*=mat-elevation-z]), .indigo-purple .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.indigo-purple .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.indigo-purple .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.indigo-purple .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.indigo-purple .mat-fab:not([class*=mat-elevation-z]), .indigo-purple .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.indigo-purple .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .indigo-purple .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.indigo-purple .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .indigo-purple .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.indigo-purple .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.indigo-purple .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.indigo-purple .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.indigo-purple .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.indigo-purple .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-purple .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.indigo-purple .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.indigo-purple .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.indigo-purple .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
.indigo-purple [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.indigo-purple .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
.indigo-purple .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.indigo-purple .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.indigo-purple .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.indigo-purple .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.indigo-purple .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.indigo-purple .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
.indigo-purple .mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.indigo-purple .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.indigo-purple .mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-purple .mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}
.indigo-purple .mat-checkbox-checkmark {
  fill: #fafafa;
}
.indigo-purple .mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
.indigo-purple .mat-checkbox-mixedmark {
  background-color: #fafafa;
}
.indigo-purple .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .indigo-purple .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #3f51b5;
}
.indigo-purple .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .indigo-purple .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #9c27b0;
}
.indigo-purple .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .indigo-purple .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #f44336;
}
.indigo-purple .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .indigo-purple .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.indigo-purple .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.indigo-purple .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-purple .mat-checkbox .mat-ripple-element {
  background-color: black;
}
.indigo-purple .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.indigo-purple .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #3f51b5;
}
.indigo-purple .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.indigo-purple .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #9c27b0;
}
.indigo-purple .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.indigo-purple .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #f44336;
}
.indigo-purple .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple .mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.indigo-purple .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.indigo-purple .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.indigo-purple .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.indigo-purple .mat-chip.mat-standard-chip::after {
  background: black;
}
.indigo-purple .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #3f51b5;
  color: white;
}
.indigo-purple .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.indigo-purple .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.indigo-purple .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #f44336;
  color: white;
}
.indigo-purple .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.indigo-purple .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.indigo-purple .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #9c27b0;
  color: white;
}
.indigo-purple .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.indigo-purple .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.indigo-purple .mat-table {
  background: white;
}
.indigo-purple .mat-table thead, .indigo-purple .mat-table tbody, .indigo-purple .mat-table tfoot,
.indigo-purple mat-header-row, .indigo-purple mat-row, .indigo-purple mat-footer-row,
.indigo-purple [mat-header-row], .indigo-purple [mat-row], .indigo-purple [mat-footer-row],
.indigo-purple .mat-table-sticky {
  background: inherit;
}
.indigo-purple mat-row, .indigo-purple mat-header-row, .indigo-purple mat-footer-row,
.indigo-purple th.mat-header-cell, .indigo-purple td.mat-cell, .indigo-purple td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.indigo-purple .mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-purple .mat-cell, .indigo-purple .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple .mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}
.indigo-purple .mat-datepicker-toggle,
.indigo-purple .mat-datepicker-content .mat-calendar-next-button,
.indigo-purple .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-purple .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.indigo-purple .mat-calendar-table-header,
.indigo-purple .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-purple .mat-calendar-body-cell-content,
.indigo-purple .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.indigo-purple .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-purple .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-purple .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.indigo-purple .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.indigo-purple .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.indigo-purple .mat-calendar-body-in-range::before {
  background: rgba(63, 81, 181, 0.2);
}
.indigo-purple .mat-calendar-body-comparison-identical,
.indigo-purple .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.indigo-purple .mat-calendar-body-comparison-bridge-start::before,
.indigo-purple [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(63, 81, 181, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.indigo-purple .mat-calendar-body-comparison-bridge-end::before,
.indigo-purple [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(63, 81, 181, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.indigo-purple .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.indigo-purple .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.indigo-purple .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.indigo-purple .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.indigo-purple .mat-calendar-body-selected {
  background-color: #3f51b5;
  color: white;
}
.indigo-purple .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(63, 81, 181, 0.4);
}
.indigo-purple .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.indigo-purple .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.indigo-purple .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(63, 81, 181, 0.3);
}
@media (hover: hover) {
  .indigo-purple .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(63, 81, 181, 0.3);
  }
}
.indigo-purple .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(156, 39, 176, 0.2);
}
.indigo-purple .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.indigo-purple .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.indigo-purple .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.indigo-purple .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(156, 39, 176, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.indigo-purple .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.indigo-purple .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(156, 39, 176, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.indigo-purple .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.indigo-purple .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.indigo-purple .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.indigo-purple .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.indigo-purple .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #9c27b0;
  color: white;
}
.indigo-purple .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(156, 39, 176, 0.4);
}
.indigo-purple .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.indigo-purple .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.indigo-purple .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(156, 39, 176, 0.3);
}
@media (hover: hover) {
  .indigo-purple .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(156, 39, 176, 0.3);
  }
}
.indigo-purple .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}
.indigo-purple .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.indigo-purple .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.indigo-purple .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.indigo-purple .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.indigo-purple .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.indigo-purple .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.indigo-purple .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.indigo-purple .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.indigo-purple .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.indigo-purple .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.indigo-purple .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: white;
}
.indigo-purple .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}
.indigo-purple .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.indigo-purple .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.indigo-purple .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(244, 67, 54, 0.3);
}
@media (hover: hover) {
  .indigo-purple .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(244, 67, 54, 0.3);
  }
}
.indigo-purple .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.indigo-purple .mat-datepicker-toggle-active {
  color: #3f51b5;
}
.indigo-purple .mat-datepicker-toggle-active.mat-accent {
  color: #9c27b0;
}
.indigo-purple .mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}
.indigo-purple .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-purple .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.indigo-purple .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.indigo-purple .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.indigo-purple .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.indigo-purple .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .indigo-purple .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .indigo-purple .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .indigo-purple .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.indigo-purple .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple .mat-expansion-panel-header-description,
.indigo-purple .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-purple .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.indigo-purple .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.indigo-purple .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.indigo-purple .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.indigo-purple .mat-hint {
  color: rgba(0, 0, 0, 0.6);
}
.indigo-purple .mat-form-field.mat-focused .mat-form-field-label {
  color: #3f51b5;
}
.indigo-purple .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #9c27b0;
}
.indigo-purple .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #f44336;
}
.indigo-purple .mat-focused .mat-form-field-required-marker {
  color: #9c27b0;
}
.indigo-purple .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.indigo-purple .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #3f51b5;
}
.indigo-purple .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #9c27b0;
}
.indigo-purple .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #f44336;
}
.indigo-purple .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #3f51b5;
}
.indigo-purple .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #9c27b0;
}
.indigo-purple .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #f44336;
}
.indigo-purple .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #f44336;
}
.indigo-purple .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.indigo-purple .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #f44336;
}
.indigo-purple .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.indigo-purple .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #f44336;
}
.indigo-purple .mat-error {
  color: #f44336;
}
.indigo-purple .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-purple .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-purple .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.indigo-purple .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.indigo-purple .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.indigo-purple .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.indigo-purple .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.indigo-purple .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.indigo-purple .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.indigo-purple .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-purple .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.indigo-purple .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.indigo-purple .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #3f51b5;
}
.indigo-purple .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #9c27b0;
}
.indigo-purple .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #f44336;
}
.indigo-purple .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #f44336;
}
.indigo-purple .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-purple .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.indigo-purple .mat-icon.mat-primary {
  color: #3f51b5;
}
.indigo-purple .mat-icon.mat-accent {
  color: #9c27b0;
}
.indigo-purple .mat-icon.mat-warn {
  color: #f44336;
}
.indigo-purple .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-purple .mat-input-element:disabled,
.indigo-purple .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-purple .mat-input-element {
  caret-color: #3f51b5;
}
.indigo-purple .mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.indigo-purple .mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.indigo-purple .mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.indigo-purple .mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.indigo-purple .mat-form-field.mat-accent .mat-input-element {
  caret-color: #9c27b0;
}
.indigo-purple .mat-form-field.mat-warn .mat-input-element,
.indigo-purple .mat-form-field-invalid .mat-input-element {
  caret-color: #f44336;
}
.indigo-purple .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #f44336;
}
.indigo-purple .mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple .mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple .mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-purple .mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}
.indigo-purple .mat-list-option:hover, .indigo-purple .mat-list-option:focus,
.indigo-purple .mat-nav-list .mat-list-item:hover,
.indigo-purple .mat-nav-list .mat-list-item:focus,
.indigo-purple .mat-action-list .mat-list-item:hover,
.indigo-purple .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.indigo-purple .mat-list-single-selected-option, .indigo-purple .mat-list-single-selected-option:hover, .indigo-purple .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.indigo-purple .mat-menu-panel {
  background: white;
}
.indigo-purple .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.indigo-purple .mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple .mat-menu-item[disabled],
.indigo-purple .mat-menu-item[disabled] .mat-menu-submenu-icon,
.indigo-purple .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-purple .mat-menu-item .mat-icon-no-color,
.indigo-purple .mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-purple .mat-menu-item:hover:not([disabled]),
.indigo-purple .mat-menu-item.cdk-program-focused:not([disabled]),
.indigo-purple .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.indigo-purple .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.indigo-purple .mat-paginator {
  background: white;
}
.indigo-purple .mat-paginator,
.indigo-purple .mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-purple .mat-paginator-decrement,
.indigo-purple .mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.indigo-purple .mat-paginator-first,
.indigo-purple .mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.indigo-purple .mat-icon-button[disabled] .mat-paginator-decrement,
.indigo-purple .mat-icon-button[disabled] .mat-paginator-increment,
.indigo-purple .mat-icon-button[disabled] .mat-paginator-first,
.indigo-purple .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.indigo-purple .mat-progress-bar-background {
  fill: #cbd0e9;
}
.indigo-purple .mat-progress-bar-buffer {
  background-color: #cbd0e9;
}
.indigo-purple .mat-progress-bar-fill::after {
  background-color: #3f51b5;
}
.indigo-purple .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #e3c5e8;
}
.indigo-purple .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #e3c5e8;
}
.indigo-purple .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #9c27b0;
}
.indigo-purple .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f9ccc9;
}
.indigo-purple .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f9ccc9;
}
.indigo-purple .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #f44336;
}
.indigo-purple .mat-progress-spinner circle, .indigo-purple .mat-spinner circle {
  stroke: #3f51b5;
}
.indigo-purple .mat-progress-spinner.mat-accent circle, .indigo-purple .mat-spinner.mat-accent circle {
  stroke: #9c27b0;
}
.indigo-purple .mat-progress-spinner.mat-warn circle, .indigo-purple .mat-spinner.mat-warn circle {
  stroke: #f44336;
}
.indigo-purple .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.indigo-purple .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #3f51b5;
}
.indigo-purple .mat-radio-button.mat-primary .mat-radio-inner-circle,
.indigo-purple .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .indigo-purple .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .indigo-purple .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #3f51b5;
}
.indigo-purple .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #9c27b0;
}
.indigo-purple .mat-radio-button.mat-accent .mat-radio-inner-circle,
.indigo-purple .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .indigo-purple .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .indigo-purple .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #9c27b0;
}
.indigo-purple .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f44336;
}
.indigo-purple .mat-radio-button.mat-warn .mat-radio-inner-circle,
.indigo-purple .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .indigo-purple .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .indigo-purple .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #f44336;
}
.indigo-purple .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.indigo-purple .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.indigo-purple .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.indigo-purple .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.indigo-purple .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-purple .mat-radio-button .mat-ripple-element {
  background-color: black;
}
.indigo-purple .mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple .mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.indigo-purple .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-purple .mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-purple .mat-select-panel {
  background: white;
}
.indigo-purple .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.indigo-purple .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.indigo-purple .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #3f51b5;
}
.indigo-purple .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #9c27b0;
}
.indigo-purple .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #f44336;
}
.indigo-purple .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #f44336;
}
.indigo-purple .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-purple .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple .mat-drawer.mat-drawer-push {
  background-color: white;
}
.indigo-purple .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.indigo-purple .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.indigo-purple .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.indigo-purple [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.indigo-purple [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.indigo-purple .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.indigo-purple .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #9c27b0;
}
.indigo-purple .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(156, 39, 176, 0.54);
}
.indigo-purple .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #9c27b0;
}
.indigo-purple .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #3f51b5;
}
.indigo-purple .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(63, 81, 181, 0.54);
}
.indigo-purple .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #3f51b5;
}
.indigo-purple .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #f44336;
}
.indigo-purple .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(244, 67, 54, 0.54);
}
.indigo-purple .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #f44336;
}
.indigo-purple .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
.indigo-purple .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
.indigo-purple .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.indigo-purple .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.indigo-purple .mat-slider.mat-primary .mat-slider-track-fill,
.indigo-purple .mat-slider.mat-primary .mat-slider-thumb,
.indigo-purple .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #3f51b5;
}
.indigo-purple .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.indigo-purple .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(63, 81, 181, 0.2);
}
.indigo-purple .mat-slider.mat-accent .mat-slider-track-fill,
.indigo-purple .mat-slider.mat-accent .mat-slider-thumb,
.indigo-purple .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #9c27b0;
}
.indigo-purple .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.indigo-purple .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(156, 39, 176, 0.2);
}
.indigo-purple .mat-slider.mat-warn .mat-slider-track-fill,
.indigo-purple .mat-slider.mat-warn .mat-slider-thumb,
.indigo-purple .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #f44336;
}
.indigo-purple .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.indigo-purple .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(244, 67, 54, 0.2);
}
.indigo-purple .mat-slider:hover .mat-slider-track-background,
.indigo-purple .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}
.indigo-purple .mat-slider.mat-slider-disabled .mat-slider-track-background,
.indigo-purple .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.indigo-purple .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.indigo-purple .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.indigo-purple .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.indigo-purple .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.indigo-purple .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.indigo-purple .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.indigo-purple .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.indigo-purple .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.indigo-purple .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .indigo-purple .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.indigo-purple .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .indigo-purple .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}
.indigo-purple .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.indigo-purple .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.indigo-purple .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.indigo-purple .mat-step-header.cdk-keyboard-focused, .indigo-purple .mat-step-header.cdk-program-focused, .indigo-purple .mat-step-header:hover:not([aria-disabled]), .indigo-purple .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.indigo-purple .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .indigo-purple .mat-step-header:hover {
    background: none;
  }
}
.indigo-purple .mat-step-header .mat-step-label,
.indigo-purple .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-purple .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.indigo-purple .mat-step-header .mat-step-icon-selected,
.indigo-purple .mat-step-header .mat-step-icon-state-done,
.indigo-purple .mat-step-header .mat-step-icon-state-edit {
  background-color: #3f51b5;
  color: white;
}
.indigo-purple .mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.indigo-purple .mat-step-header.mat-accent .mat-step-icon-selected,
.indigo-purple .mat-step-header.mat-accent .mat-step-icon-state-done,
.indigo-purple .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #9c27b0;
  color: white;
}
.indigo-purple .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.indigo-purple .mat-step-header.mat-warn .mat-step-icon-selected,
.indigo-purple .mat-step-header.mat-warn .mat-step-icon-state-done,
.indigo-purple .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #f44336;
  color: white;
}
.indigo-purple .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336;
}
.indigo-purple .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple .mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336;
}
.indigo-purple .mat-stepper-horizontal, .indigo-purple .mat-stepper-vertical {
  background-color: white;
}
.indigo-purple .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.indigo-purple .mat-horizontal-stepper-header::before,
.indigo-purple .mat-horizontal-stepper-header::after,
.indigo-purple .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.indigo-purple .mat-sort-header-arrow {
  color: #757575;
}
.indigo-purple .mat-tab-nav-bar,
.indigo-purple .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple .mat-tab-group-inverted-header .mat-tab-nav-bar,
.indigo-purple .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.indigo-purple .mat-tab-label, .indigo-purple .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple .mat-tab-label.mat-tab-disabled, .indigo-purple .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-purple .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.indigo-purple .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.indigo-purple .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.indigo-purple .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.indigo-purple .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-purple .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-purple .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-purple .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .indigo-purple .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-purple .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-purple .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-purple .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(197, 202, 233, 0.3);
}
.indigo-purple .mat-tab-group.mat-primary .mat-ink-bar, .indigo-purple .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #3f51b5;
}
.indigo-purple .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .indigo-purple .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .indigo-purple .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .indigo-purple .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.indigo-purple .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-purple .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-purple .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-purple .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .indigo-purple .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-purple .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-purple .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-purple .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(225, 190, 231, 0.3);
}
.indigo-purple .mat-tab-group.mat-accent .mat-ink-bar, .indigo-purple .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #9c27b0;
}
.indigo-purple .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .indigo-purple .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .indigo-purple .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .indigo-purple .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.indigo-purple .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-purple .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-purple .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-purple .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .indigo-purple .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-purple .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-purple .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-purple .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.indigo-purple .mat-tab-group.mat-warn .mat-ink-bar, .indigo-purple .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #f44336;
}
.indigo-purple .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .indigo-purple .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .indigo-purple .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .indigo-purple .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.indigo-purple .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-purple .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-purple .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-purple .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .indigo-purple .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-purple .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-purple .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-purple .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(197, 202, 233, 0.3);
}
.indigo-purple .mat-tab-group.mat-background-primary > .mat-tab-header, .indigo-purple .mat-tab-group.mat-background-primary > .mat-tab-link-container, .indigo-purple .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .indigo-purple .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .indigo-purple .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .indigo-purple .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #3f51b5;
}
.indigo-purple .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .indigo-purple .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .indigo-purple .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .indigo-purple .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.indigo-purple .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .indigo-purple .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .indigo-purple .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .indigo-purple .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.indigo-purple .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.indigo-purple .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.indigo-purple .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.indigo-purple .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .indigo-purple .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.indigo-purple .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.indigo-purple .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.indigo-purple .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.indigo-purple .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.indigo-purple .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .indigo-purple .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.indigo-purple .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.indigo-purple .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.indigo-purple .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.indigo-purple .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .indigo-purple .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.indigo-purple .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.indigo-purple .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.indigo-purple .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-purple .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-purple .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-purple .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .indigo-purple .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-purple .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-purple .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-purple .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(225, 190, 231, 0.3);
}
.indigo-purple .mat-tab-group.mat-background-accent > .mat-tab-header, .indigo-purple .mat-tab-group.mat-background-accent > .mat-tab-link-container, .indigo-purple .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .indigo-purple .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .indigo-purple .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .indigo-purple .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #9c27b0;
}
.indigo-purple .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .indigo-purple .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .indigo-purple .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .indigo-purple .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.indigo-purple .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .indigo-purple .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .indigo-purple .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .indigo-purple .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.indigo-purple .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.indigo-purple .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.indigo-purple .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.indigo-purple .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .indigo-purple .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.indigo-purple .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.indigo-purple .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.indigo-purple .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.indigo-purple .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.indigo-purple .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .indigo-purple .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.indigo-purple .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.indigo-purple .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.indigo-purple .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.indigo-purple .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .indigo-purple .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.indigo-purple .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.indigo-purple .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.indigo-purple .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-purple .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-purple .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-purple .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .indigo-purple .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-purple .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-purple .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-purple .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.indigo-purple .mat-tab-group.mat-background-warn > .mat-tab-header, .indigo-purple .mat-tab-group.mat-background-warn > .mat-tab-link-container, .indigo-purple .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .indigo-purple .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .indigo-purple .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .indigo-purple .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #f44336;
}
.indigo-purple .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .indigo-purple .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .indigo-purple .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .indigo-purple .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.indigo-purple .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .indigo-purple .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .indigo-purple .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .indigo-purple .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.indigo-purple .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.indigo-purple .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.indigo-purple .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.indigo-purple .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .indigo-purple .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.indigo-purple .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.indigo-purple .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.indigo-purple .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.indigo-purple .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.indigo-purple .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .indigo-purple .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.indigo-purple .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.indigo-purple .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.indigo-purple .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.indigo-purple .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .indigo-purple .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.indigo-purple .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.indigo-purple .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.indigo-purple .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple .mat-toolbar.mat-primary {
  background: #3f51b5;
  color: white;
}
.indigo-purple .mat-toolbar.mat-accent {
  background: #9c27b0;
  color: white;
}
.indigo-purple .mat-toolbar.mat-warn {
  background: #f44336;
  color: white;
}
.indigo-purple .mat-toolbar .mat-form-field-underline,
.indigo-purple .mat-toolbar .mat-form-field-ripple,
.indigo-purple .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.indigo-purple .mat-toolbar .mat-form-field-label,
.indigo-purple .mat-toolbar .mat-focused .mat-form-field-label,
.indigo-purple .mat-toolbar .mat-select-value,
.indigo-purple .mat-toolbar .mat-select-arrow,
.indigo-purple .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.indigo-purple .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.indigo-purple .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.indigo-purple .mat-tree {
  background: white;
}
.indigo-purple .mat-tree-node,
.indigo-purple .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.indigo-purple .mat-simple-snackbar-action {
  color: #9c27b0;
}
.indigo-purple *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.indigo-purple *::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}
.indigo-purple *::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}
.indigo-purple .toolbar-line.container-ou {
  background: #3f51b5;
  color: white;
}
.indigo-purple .toolbar-line.container-persona {
  background: #3f51b5 !important;
}
.indigo-purple .toolbar-line.container-persona .persona-title {
  background: #3f51b5;
}
.indigo-purple .toolbar-line.container-persona .invisible-container.persona-selector {
  background: #3f51b5 !important;
}
.indigo-purple button.mat-icon-button:not([disabled]):hover {
  background: rgba(0, 0, 0, 0.04);
}
.indigo-purple .side-bar {
  background: #eeeeee;
}
.indigo-purple .icon-stacked {
  color: white !important;
}
.indigo-purple .icon-stacked mat-icon + mat-icon {
  text-shadow: -1px 1px 0px #3f51b5, 1px -1px 0px #3f51b5;
}
.indigo-purple .divider-vert {
  background: rgba(0, 0, 0, 0.12);
}
.indigo-purple .avatar {
  background: #303f9f !important;
}
.indigo-purple ngx-avatar .avatar-content {
  background: #303f9f !important;
}
.indigo-purple .mat-loading-window {
  background: white;
}
.indigo-purple .window-spinner {
  background: white;
}
.indigo-purple .load-window {
  background: white;
}
@media (min-width: 600px) and (max-width: 1300px) {
  .indigo-purple mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .load-window,
.indigo-purple mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .load-window.new {
    background: white !important;
  }
  .indigo-purple mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card {
    background: white !important;
  }
  .indigo-purple mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .inbox-container mat-toolbar {
    background: #3f51b5;
    color: white;
  }
  .indigo-purple mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .inbox-container mat-list-item.mat-list-item {
    border: 1px solid rgba(0, 0, 0, 0.12);
  }
  .indigo-purple mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .detail {
    background: white !important;
  }
  .indigo-purple mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .detail mat-toolbar {
    background: white !important;
  }
  .indigo-purple mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .main.w-detail + .new-detail-container .load-window {
    background: #fafafa !important;
  }
  .indigo-purple mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .main.w-detail + .new-detail-container:before {
    background: rgba(0, 0, 0, 0.6);
  }
  .indigo-purple mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .main.side-open + .side {
    border-left: 1px solid rgba(0, 0, 0, 0.12);
  }
  .indigo-purple mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .side .container .mat-list-base {
    background: white !important;
    border: 1px solid rgba(0, 0, 0, 0.12);
  }
  .indigo-purple mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .new-table .mat-header-row,
.indigo-purple mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .new-table .mat-table,
.indigo-purple mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .document-table .mat-header-row,
.indigo-purple mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .document-table .mat-table,
.indigo-purple mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .status-table .mat-header-row,
.indigo-purple mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .status-table .mat-table {
    background: white !important;
  }
  .indigo-purple mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .audit-filter .filter-container.is-floating {
    background: #fafafa !important;
    border: 1px solid rgba(0, 0, 0, 0.12);
  }
  .indigo-purple mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .audit-filter .filter-container.is-floating .form-body {
    background: #fafafa !important;
  }
  .indigo-purple mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .audit-result {
    background: white;
  }
  .indigo-purple mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .audit-detail-container {
    background: #fafafa !important;
  }
}
@media (max-width: 599px) {
  .indigo-purple app-document-detail .mat-toolbar {
    background: #303f9f !important;
    color: white !important;
  }
  .indigo-purple app-document-detail .mat-toolbar .mat-toolbar-row .toolbar-actions {
    background: #303f9f !important;
    color: white !important;
  }
  .indigo-purple .login-card {
    background: white !important;
  }
  .indigo-purple .inbox-toolbar {
    background: #303f9f !important;
    color: white !important;
  }
  .indigo-purple .detail {
    background: white url("../img/spinner_mobile.gif") no-repeat center center !important;
  }
  .indigo-purple .toolbar-line-container .toolbar-line:first-child {
    background: #303f9f !important;
    color: white !important;
  }
  .indigo-purple .toolbar-actions {
    background: #303f9f !important;
    color: white !important;
  }
  .indigo-purple mat-drawer.side-nav.enter {
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12) !important;
  }
  .indigo-purple .side-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0) !important;
  }
  .indigo-purple .filter {
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.55) 16%, rgba(0, 0, 0, 0) 100%) !important;
  }
  .indigo-purple button.side-open-mobile {
    color: white !important;
  }
  .indigo-purple .side-nav .side-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0);
  }
  .indigo-purple .side-nav .filter {
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.55) 16%, rgba(0, 0, 0, 0) 100%);
  }
  .indigo-purple .side-nav .side-nav-container .links {
    background: white;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
  }
  .indigo-purple .side-nav .side-nav-container .version {
    background: white !important;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
  }
  .indigo-purple app-file-document-view .view-container {
    background: white !important;
  }
  .indigo-purple app-file-document-view .view-container .side-bar {
    background: #303f9f !important;
    color: white !important;
  }
  .indigo-purple app-file-document-view .view-container .view-content app-pdf-wrapper.floating {
    background: white;
  }
  .indigo-purple app-file-document-view .view-container .view-content .upload-indications {
    background: #eeeeee;
  }
  .indigo-purple app-file-document-view .view-container .view-content app-file-document-sign .sign-cycle mat-horizontal-stepper.mat-stepper-horizontal {
    background-color: rgba(0, 0, 0, 0) !important;
  }
  .indigo-purple app-file-document-view .view-container .view-content app-file-document-sign .sign-cycle .sign-cycle-content .cycle-content-action .mat-ripple-element {
    background-color: rgba(255, 255, 255, 0.1) !important;
  }
  .indigo-purple app-pending .floating-container .inbox-container .inbox-toolbar .mat-toolbar-row .mat-avatar h3 {
    color: white !important;
  }
  .indigo-purple app-pending .floating-container .detail {
    background: white url("../img/spinner_mobile.gif") no-repeat center center !important;
  }
  .indigo-purple app-document-sign-massive .inbox-container .pending-container app-chapa.minimized .info-empty {
    background: #9c27b0;
  }
  .indigo-purple app-document-sign-massive .inbox-container .pending-container app-chapa.minimized .info-empty > span h2 {
    color: white !important;
  }
  .indigo-purple app-document-sign-massive .inbox-container .signer.mobile {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    background: white;
  }
  .indigo-purple app-document-sign-massive .detail app-file-document-detail .view-container .mat-toolbar .mat-toolbar-row .view-actions {
    color: white !important;
  }
  .indigo-purple app-add-documentation .floating-container .employee-add-documentation .inbox-container .inbox-toolbar .mat-toolbar-row .mat-avatar h3 {
    color: white !important;
  }
  .indigo-purple app-add-documentation .floating-container .employee-add-documentation .detail {
    background: white url("../img/spinner_mobile.gif") no-repeat center center !important;
  }
  .indigo-purple app-add-documentation .floating-container .employee-add-documentation .detail app-file-document-view .view-container .view-content .new-container app-file-document-upload {
    background: white;
  }
}
.indigo-purple .mat-card .mat-drawer-container {
  background: white !important;
}
.indigo-purple .mat-drawer-container {
  background: #e0e0e0 !important;
}
.indigo-purple .mat-list .mat-list-item.finished-process-inbox.active {
  background: #4DB6AC !important;
}
.indigo-purple .mat-list .mat-list-item.finished-process-inbox .mat-icon {
  background: #4DB6AC;
}
.indigo-purple .mat-list .mat-list-item.erased-process-inbox.active {
  background: #837C80 !important;
}
.indigo-purple .mat-list .mat-list-item.limbo-process-inbox.active {
  background: #FFD54F !important;
}
.indigo-purple .mat-list .mat-list-item.limbo-process-inbox .mat-icon {
  background: #FFD54F;
}
.indigo-purple .mat-list .mat-list-item.in-process-inbox.active {
  background: #FFD54F !important;
}
.indigo-purple .mat-list .mat-list-item.in-process-inbox .mat-icon {
  background: #FFD54F;
}
.indigo-purple .mat-list .mat-list-item.error-process-inbox.active {
  background: #F48FB1 !important;
}
.indigo-purple .mat-list .mat-list-item.error-process-inbox .mat-icon {
  background: #F48FB1;
}
.indigo-purple .mat-list .mat-list-item.active .mat-line b {
  color: #3f51b5;
}
.indigo-purple .mat-list .mat-list-item.active .mat-list-avatar {
  color: #3f51b5;
}
.indigo-purple .mat-list .mat-list-item:hover:not(.active) {
  background: rgba(0, 0, 0, 0.04);
}
.indigo-purple span.mat-badge-content {
  color: #f44336 !important;
}
.indigo-purple mat-expansion-panel.mat-expanded {
  background: #fafafa !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple mat-expansion-panel.virtual-scrolling .mat-expansion-panel-content .get-more {
  color: #3f51b5;
}
.indigo-purple mat-expansion-panel.virtual-scrolling .mat-expansion-panel-content app-cs-paginator.get-more {
  background: white;
}
.indigo-purple mat-expansion-panel-header.mat-expanded {
  background: white !important;
}
.indigo-purple .bottom-container {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple .load-more * {
  color: #3f51b5;
}
.indigo-purple .welcome-card {
  background: white !important;
}
.indigo-purple .welcome-card .img-divider {
  background: #303f9f;
}
.indigo-purple .mat-header-row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background: white;
}
.indigo-purple .mat-row {
  background: white;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.indigo-purple .mat-row .mat-cell.status-cell mat-icon.pending {
  color: #FF9800 !important;
}
.indigo-purple .mat-row .mat-cell.status-cell mat-icon.ok {
  color: #009688 !important;
}
.indigo-purple .mat-row .mat-cell.status-cell mat-icon.not-ok {
  color: #9C27B0 !important;
}
.indigo-purple .mat-row .mat-cell.status-cell mat-icon.cancelled {
  color: #795548 !important;
}
.indigo-purple .mat-row .mat-cell.status-cell mat-icon.rejected {
  color: #df4d4c !important;
}
.indigo-purple .mat-row .mat-cell.status-cell mat-icon.draft {
  color: #dedede !important;
}
.indigo-purple .mat-row.active, .indigo-purple .mat-row.active:hover {
  background: #3f51b5 !important;
}
.indigo-purple .mat-row.active div, .indigo-purple .mat-row.active:hover div {
  color: white;
}
.indigo-purple .mat-row.active .mat-cell.status-cell mat-icon.pending, .indigo-purple .mat-row.active .mat-cell.status-cell mat-icon.ok, .indigo-purple .mat-row.active .mat-cell.status-cell mat-icon.not-ok, .indigo-purple .mat-row.active:hover .mat-cell.status-cell mat-icon.pending, .indigo-purple .mat-row.active:hover .mat-cell.status-cell mat-icon.ok, .indigo-purple .mat-row.active:hover .mat-cell.status-cell mat-icon.not-ok {
  text-shadow: -1px 1px 0 white, 1px 1px 0 white, 1px -1px 0 white, -1px -1px 0 white;
}
.indigo-purple .mat-row.active .sticky-actions button, .indigo-purple .mat-row.active:hover .sticky-actions button {
  background: #3f51b5 !important;
}
.indigo-purple .mat-row.active .sticky-actions button.ok mat-icon, .indigo-purple .mat-row.active .sticky-actions button.not-ok mat-icon, .indigo-purple .mat-row.active:hover .sticky-actions button.ok mat-icon, .indigo-purple .mat-row.active:hover .sticky-actions button.not-ok mat-icon {
  text-shadow: -1px 1px 0 white, 1px 1px 0 white, 1px -1px 0 white, -1px -1px 0 white;
}
.indigo-purple .mat-row:nth-child(odd) {
  background: #fafafa;
}
.indigo-purple .mat-row.selected div {
  color: white;
}
.indigo-purple .mat-row.selected:nth-child(even) {
  background: #9fa8da !important;
}
.indigo-purple .mat-row.selected:nth-child(even) .sticky-actions button {
  background: #9fa8da !important;
}
.indigo-purple .mat-row.selected:nth-child(odd) {
  background: #c5cae9 !important;
}
.indigo-purple .mat-row.selected:nth-child(odd) .sticky-actions button {
  background: #c5cae9 !important;
}
.indigo-purple .mat-row.selected.active, .indigo-purple .mat-row.selected.active:hover {
  background: #3f51b5 !important;
}
.indigo-purple .mat-row.selected.active .sticky-actions button, .indigo-purple .mat-row.selected.active:hover .sticky-actions button {
  background: #3f51b5 !important;
}
.indigo-purple .mat-row:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.indigo-purple .mat-row:hover .sticky-actions button {
  background: white !important;
}
.indigo-purple .mat-row.canceled * {
  color: #795548;
}
.indigo-purple .mat-row.disabled div {
  color: #607D8B;
}
.indigo-purple .mat-row.disabled.active, .indigo-purple .mat-row.disabled.active:hover {
  background: #607D8B !important;
}
.indigo-purple .mat-row.disabled.active div, .indigo-purple .mat-row.disabled.active:hover div {
  color: white;
}
.indigo-purple .mat-row.disabled.active .sticky-actions button, .indigo-purple .mat-row.disabled.active:hover .sticky-actions button {
  background: #607D8B !important;
}
.indigo-purple .mat-row.disabled.selected.active:hover .sticky-actions button {
  background: #607D8B !important;
}
.indigo-purple .mat-row .sticky-actions button.ok mat-icon {
  color: #009688 !important;
}
.indigo-purple .mat-row .sticky-actions button.not-ok mat-icon {
  color: #9C27B0 !important;
}
.indigo-purple .status-table .mat-header-row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background: white;
}
.indigo-purple .icon-info-off,
.indigo-purple .certificate-off {
  color: #607D8B;
}
.indigo-purple .documents-search-table .mat-header-row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background: white;
}
.indigo-purple mat-toolbar.employee-search-sub-toolbar {
  background: white;
}
.indigo-purple .mat-search-field {
  border: 1px solid rgba(0, 0, 0, 0.12);
  background: #eeeeee;
}
.indigo-purple .mat-search-field input[type=search] {
  background: rgba(0, 0, 0, 0);
}
.indigo-purple .mat-search-field input[type=search]::placeholder {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-purple .search-adv-menu .mat-search-field {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple mat-toolbar.document-search-sub-toolbar {
  background: white;
}
.indigo-purple app-advanced-employee-search .mat-chip {
  background: rgba(0, 0, 0, 0.04) !important;
}
.indigo-purple .side-nav .side-trigger {
  background: white;
}
.indigo-purple .side-nav .menu-trigger mat-icon {
  color: rgba(0, 0, 0, 0.54);
  background: white;
}
.indigo-purple .side-nav .side-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0);
}
.indigo-purple .side-nav .filter {
  background: white;
}
.indigo-purple .side-nav .side-nav-container .links mat-icon span.mat-badge-content {
  color: white !important;
}
.indigo-purple .side-nav .side-nav-container .mat-list-base .mat-list-item:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.indigo-purple .side-nav .side-nav-container .mat-list-base .mat-list-item.active {
  background: #3f51b5 !important;
}
.indigo-purple .side-nav .side-nav-container .mat-list-base .mat-list-item.active * {
  color: white;
}
.indigo-purple .side-nav .side-nav-container .mat-list-base .mat-list-item.active .mat-list-item.active:hover {
  background: #3f51b5 !important;
}
.indigo-purple .side-nav .side-nav-container .mat-list-base .mat-list-item.active .mat-list-item.active:hover * {
  color: white;
}
.indigo-purple .side-nav.open .side-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple .side-nav.open .filter {
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.55) 16%, rgba(0, 0, 0, 0) 100%);
}
.indigo-purple .side-nav.open .side-nav-container .links {
  background: white;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple .side-nav.open .side-nav-container .version {
  background: white !important;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple .side-nav.enter .side-nav-container .links {
  background: white;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple .side-nav.enter .side-nav-container .version {
  background: white !important;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple .mat-menu-panel:not(.metadata-radio-menu) .navigation-list .mat-list-item-content {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-purple table.mat-calendar-table {
  background: white !important;
}
.indigo-purple .mat-calendar-body-label {
  color: white !important;
}
.indigo-purple .mat-calendar-arrow {
  border-top-color: white !important;
}
.indigo-purple .mat-datepicker-content .mat-calendar-next-button,
.indigo-purple .mat-datepicker-content .mat-calendar-previous-button {
  color: white !important;
}
.indigo-purple .mat-calendar-header {
  background: #303f9f !important;
}
.indigo-purple mat-datepicker-content {
  background-color: #303f9f !important;
  color: white !important;
}
.indigo-purple .mat-calendar-content {
  background: white !important;
}
.indigo-purple .mat-simple-snackbar-action button.mat-button {
  color: #FFE082;
}
.indigo-purple .mat-simple-snackbar-action .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.indigo-purple mat-bottom-sheet-container mat-toolbar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}
.indigo-purple app-generic-bottom-sheet h4.accent {
  color: #9c27b0;
}
.indigo-purple app-generic-bottom-sheet .verification-code {
  background: #3f51b5;
  color: white;
}
.indigo-purple app-generic-bottom-sheet .verification-code h2 {
  background: #3f51b5;
}
.indigo-purple app-generic-bottom-sheet .load-window.message .info-container h3.primary-text {
  color: #3f51b5;
}
.indigo-purple app-generic-bottom-sheet .bottom_sheet-content.ok .bottom_sheet-title .bottom_sheet-icon,
.indigo-purple app-generic-bottom-sheet .bottom_sheet-content.ok .bottom_sheet-title h3 {
  color: #009688;
}
.indigo-purple app-generic-bottom-sheet .bottom_sheet-content.ok .bottom_sheet-main_btn:not(:disabled) {
  background: #009688;
  color: #fff;
}
.indigo-purple app-generic-bottom-sheet .bottom_sheet-content.not-ok .bottom_sheet-title .bottom_sheet-icon,
.indigo-purple app-generic-bottom-sheet .bottom_sheet-content.not-ok .bottom_sheet-title h3 {
  color: #9C27B0;
}
.indigo-purple app-generic-bottom-sheet .bottom_sheet-content.not-ok .bottom_sheet-main_btn:not(:disabled) {
  background: #9C27B0;
  color: #fff;
}
.indigo-purple app-generic-bottom-sheet .bottom_sheet-content.cancel .bottom_sheet-title .bottom_sheet-icon,
.indigo-purple app-generic-bottom-sheet .bottom_sheet-content.cancel .bottom_sheet-title h3 {
  color: #795548;
}
.indigo-purple app-generic-bottom-sheet .bottom_sheet-content.cancel .bottom_sheet-main_btn:not(:disabled) {
  background: #795548;
  color: #fff;
}
.indigo-purple app-generic-bottom-sheet .bottom_sheet-content .bottom_sheet_exp_btn {
  background-color: #3f51b5;
  color: #fff;
}
.indigo-purple app-generic-bottom-sheet .bottom_sheet-content .bottom_sheet_canc_btn {
  background-color: lightgrey;
  color: black;
}
.indigo-purple app-generic-bottom-sheet .bottom_sheet-content .bottom_sheet-exp-h3 {
  color: #3f51b5;
  margin-bottom: -10px !important;
}
.indigo-purple app-generic-bottom-sheet .bottom_sheet-content .mat-input-element-export {
  display: flex !important;
  padding-left: 15px;
}
.indigo-purple app-generic-bottom-sheet .bottom_sheet-content .mat-form-field-infix-export-right {
  width: 100% !important;
  padding: 0px !important;
  display: flex !important;
}
.indigo-purple app-generic-bottom-sheet .bottom_sheet-content .mat-form-field-infix-export-left {
  width: 100% !important;
  padding: 0px;
  display: flex !important;
}
.indigo-purple app-generic-bottom-sheet .bottom_sheet-content .mat-form-field-infix {
  display: flex;
}
.indigo-purple app-generic-bottom-sheet .bottom_sheet-content .mat-checkbox-layout .mat-checkbox-label {
  color: #9c27b0;
}
.indigo-purple app-generic-bottom-sheet .bottom_sheet-content .choose-forms {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  margin-left: 10px !important;
}
.indigo-purple app-generic-bottom-sheet .bottom_sheet-content .card-container {
  max-height: 150px;
  overflow-y: auto;
}
.indigo-purple .mat-list .mat-list-item.active .mat-line {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple .mat-list .mat-list-item.active .mat-line b {
  color: #3f51b5;
}
.indigo-purple .mat-list .mat-list-item.active .mat-list-avatar {
  color: #3f51b5;
}
.indigo-purple .mat-list .mat-list-item .mat-line {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-purple .mat-list .mat-list-item .mat-list-avatar {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-purple .mat-list-item:hover:not(.active) {
  background: rgba(0, 0, 0, 0.04) !important;
}
.indigo-purple mat-list-item:nth-child(even),
.indigo-purple app-file-document-inbox-item:nth-child(even) mat-list-item,
.indigo-purple app-file-document-inbox-item-documentation:nth-child(even) mat-list-item {
  background: #fafafa !important;
}
.indigo-purple mat-list-item:nth-child(odd),
.indigo-purple app-file-document-inbox-item:nth-child(odd) mat-list-item,
.indigo-purple app-file-document-inbox-item-documentation:nth-child(odd) mat-list-item {
  background: white !important;
}
.indigo-purple .mat-list-avatar.mat-icon {
  background: #eeeeee;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple .card-filter {
  background: white;
}
.indigo-purple .login mat-horizontal-stepper {
  background: white !important;
}
.indigo-purple .login .login-card {
  background: white !important;
}
.indigo-purple .login .login-card .mat-card-content .mat-card-footer a[color=accent] {
  color: #9c27b0;
}
.indigo-purple .login .help-stepper h3.align-16 {
  color: #9c27b0;
}
.indigo-purple .login .help-stepper .help-options mat-icon {
  color: #9c27b0;
}
.indigo-purple .ux-modal {
  background: rgba(0, 0, 0, 0.32);
}
.indigo-purple .ux-modal .card {
  background: white;
}
.indigo-purple .main-title {
  background: #607D8B;
  color: white;
}
.indigo-purple .sub-title {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-purple .card-footer a {
  color: white;
}
.indigo-purple .profile-toolbar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple app-profile .logout-btn:hover {
  background: #eeeeee;
}
.indigo-purple app-profile .cropper {
  background: white;
}
.indigo-purple app-profile .cropper .cropper {
  background: unset;
}
.indigo-purple app-add-documentation .employee-add-documentation .inbox-toolbar {
  background: #fafafa;
}
.indigo-purple app-add-documentation .employee-add-documentation .inbox-toolbar .toolbar-line mat-icon {
  color: #3f51b5;
}
.indigo-purple app-add-documentation .employee-add-documentation .inbox-toolbar .toolbar-line h3 {
  color: #3f51b5;
}
.indigo-purple app-add-documentation .employee-add-documentation mat-tab-group {
  background: #fafafa;
}
.indigo-purple app-add-documentation .employee-add-documentation mat-tab-group mat-tab-header .mat-tab-label-active {
  color: #3f51b5;
}
.indigo-purple app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content:hover {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12) !important;
}
.indigo-purple app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content.selected {
  border-left: 6px solid #3f51b5 !important;
}
.indigo-purple app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content.selected mat-icon {
  color: #3f51b5 !important;
}
.indigo-purple app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content.selected .item-data {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-purple app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content mat-icon {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-purple app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content .item-data {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-purple app-add-documentation .employee-add-documentation .detail app-file-document-view .new-container app-file-document-upload .metadata-list .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
  background: white !important;
}
.indigo-purple app-pending .inbox-toolbar {
  background: #fafafa;
}
.indigo-purple app-pending .pending-container {
  background: #fafafa;
}
.indigo-purple app-pending .pending-container .pending-items .pending-item .content:hover {
  background: rgba(0, 0, 0, 0.04) !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12) !important;
}
.indigo-purple app-pending .pending-container .pending-items .pending-item .content.selected {
  border-left: 6px solid #9c27b0 !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12) !important;
}
.indigo-purple app-pending .pending-container .pending-items .pending-item .content.selected mat-icon {
  color: #9c27b0 !important;
}
.indigo-purple app-pending .pending-container .pending-items .pending-item .content.selected .item-data {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-purple app-pending .pending-container .pending-items .pending-item .content mat-icon {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-purple app-pending .pending-container .pending-items .pending-item .content .item-data {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-purple app-document-sign-massive .inbox-container .inbox-toolbar {
  background: #fafafa;
}
.indigo-purple app-document-sign-massive .inbox-container .pending-container {
  background: #fafafa;
}
.indigo-purple app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content:hover {
  background: rgba(0, 0, 0, 0.04) !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12) !important;
}
.indigo-purple app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content.selected {
  border-left: 6px solid #9c27b0 !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12) !important;
}
.indigo-purple app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content.selected mat-icon {
  color: #9c27b0 !important;
}
.indigo-purple app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content.selected .item-data {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-purple app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content mat-icon {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-purple app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content .item-data {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-purple app-document-sign-massive .detail app-file-document-detail .view-container {
  background: #607D8B;
}
.indigo-purple app-document-sign-massive .detail app-file-document-detail .view-container .mat-toolbar {
  background: #607D8B;
  color: white;
  background: #607D8B;
}
.indigo-purple app-document-sign-massive .detail app-chapa.minimized .info-empty {
  background: #9c27b0;
}
.indigo-purple app-document-sign-massive .detail app-chapa.minimized .info-empty > span mat-icon {
  color: white !important;
}
.indigo-purple app-document-sign-massive .detail app-chapa.minimized .info-empty > span h2 {
  color: white !important;
}
.indigo-purple app-document-sign-massive .signer.desktop {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple app-document-sign-massive .signer .signer-btn {
  color: white !important;
}
.indigo-purple app-document-sign-massive .signer .signer-btn[disabled] {
  background-color: rgba(0, 0, 0, 0.12);
}
.indigo-purple app-document-detail app-sign-document .firmador button.mat-raised-button.mat-accent {
  color: white !important;
}
.indigo-purple mat-toolbar-row.firmado-conforme .mat-icon {
  background: #8BC34A;
  color: white;
}
.indigo-purple mat-toolbar-row.firmado-no-conforme .mat-icon {
  background: #f44336;
  color: white;
}
.indigo-purple .secondary-toolbar {
  background: white;
}
.indigo-purple .new-detail-container {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple .new-detail-container.cancelled .detail_head-body {
  background: #607D8B !important;
}
.indigo-purple .new-detail-container .employee-detail-toolbar {
  background: #eeeeee;
}
.indigo-purple .new-detail-container .employee-detail-subbar {
  background: white;
}
.indigo-purple app-employee-detail .load-window,
.indigo-purple app-employee-rrhh-detail .load-window {
  background: #fafafa;
}
.indigo-purple app-employee-detail .employee_detail-container,
.indigo-purple app-employee-rrhh-detail .employee_detail-container {
  background: #fafafa;
}
.indigo-purple app-employee-detail .employee_detail-container.is-editing .edition-line,
.indigo-purple app-employee-rrhh-detail .employee_detail-container.is-editing .edition-line {
  background: #eeeeee;
}
.indigo-purple app-employee-detail .employee_detail-container .detail_head-body,
.indigo-purple app-employee-rrhh-detail .employee_detail-container .detail_head-body {
  background: #3f51b5;
}
.indigo-purple app-employee-detail .employee_detail-container .detail_head-body .head_body-status .icon-stacked + mat-icon,
.indigo-purple app-employee-rrhh-detail .employee_detail-container .detail_head-body .head_body-status .icon-stacked + mat-icon {
  color: white !important;
}
.indigo-purple app-employee-detail .employee_detail-container .detail_head-body .head_body-title .body_title-name,
.indigo-purple app-employee-rrhh-detail .employee_detail-container .detail_head-body .head_body-title .body_title-name {
  color: white;
}
.indigo-purple app-employee-detail .employee_detail-container .detail_head-body .head_body-title .body_title-user,
.indigo-purple app-employee-rrhh-detail .employee_detail-container .detail_head-body .head_body-title .body_title-user {
  color: white;
}
.indigo-purple app-employee-detail .employee_detail-container .data .data-line,
.indigo-purple app-employee-rrhh-detail .employee_detail-container .data .data-line {
  background: white;
}
.indigo-purple app-employee-detail .employee_detail-container .data .data-line .icon-complex mat-icon.complex,
.indigo-purple app-employee-rrhh-detail .employee_detail-container .data .data-line .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px white, 0 -2px 0px white, 2px 0 0px white, 0 2px 0px white, -2px -2px 0px white, 2px 2px 0px white, 2px -2px 0px white, -2px 2px 0px white;
}
.indigo-purple app-employee-detail .employee_detail-container .data .data-line .line_text-main,
.indigo-purple app-employee-rrhh-detail .employee_detail-container .data .data-line .line_text-main {
  color: #3f51b5;
}
.indigo-purple app-employee-detail .employee_detail-container .data .data-line.clickable:hover,
.indigo-purple app-employee-rrhh-detail .employee_detail-container .data .data-line.clickable:hover {
  background: #fafafa !important;
}
.indigo-purple app-employee-detail .employee_detail-container .data .data-line button.mat-icon-button,
.indigo-purple app-employee-rrhh-detail .employee_detail-container .data .data-line button.mat-icon-button {
  background: #eeeeee;
}
.indigo-purple app-employee-detail .employee_detail-container .employee-title .title-text,
.indigo-purple app-employee-rrhh-detail .employee_detail-container .employee-title .title-text {
  color: #3f51b5;
}
.indigo-purple app-employee-detail .employee_detail-container .employee-title .title-text mat-icon,
.indigo-purple app-employee-rrhh-detail .employee_detail-container .employee-title .title-text mat-icon {
  color: #3f51b5;
}
.indigo-purple app-employee-detail .employee_detail-container .employee-title .title-text .title_text-main,
.indigo-purple app-employee-rrhh-detail .employee_detail-container .employee-title .title-text .title_text-main {
  color: #3f51b5;
}
.indigo-purple .UI-V2 .icon-complex mat-icon.complex {
  color: #3f51b5;
  text-shadow: -2px 0 0px white, 0 -2px 0px white, 2px 0 0px white, 0 2px 0px white, -2px -2px 0px white, 2px 2px 0px white, 2px -2px 0px white, -2px 2px 0px white;
}
.indigo-purple .UI-V2 .icon-complex mat-icon.complex.mat-accent {
  color: #9c27b0;
}
.indigo-purple .UI-V2 .main .toolbar .header .content .module-title {
  color: #3f51b5;
}
.indigo-purple .UI-V2 .main .toolbar .header .content .module-title.selectable:hover {
  background: rgba(0, 0, 0, 0.04);
}
.indigo-purple .UI-V2 .main .toolbar .header .content .module-title .info h5 {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-purple .UI-V2 .main .toolbar .header .content .ou-select {
  color: white;
  background: #3f51b5;
}
.indigo-purple .UI-V2 .main .toolbar .search-container .search .content {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple .UI-V2 .main .toolbar .search-container .search .content:hover, .indigo-purple .UI-V2 .main .toolbar .search-container .search .content:active, .indigo-purple .UI-V2 .main .toolbar .search-container .search .content:focus, .indigo-purple .UI-V2 .main .toolbar .search-container .search .content:focus-within {
  background: rgba(0, 0, 0, 0.04) !important;
}
.indigo-purple .UI-V2 .main .toolbar .search-container .search .content input {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple .UI-V2 .main .helper {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple .UI-V2 .main .helper mat-hint h4 {
  color: #3f51b5;
}
.indigo-purple .UI-V2 .main .new-table app-cs-grid-control datatable-header * {
  color: #3f51b5;
}
.indigo-purple .UI-V2 .main .new-table .mat-header-row.sticky * {
  color: #3f51b5 !important;
}
.indigo-purple .UI-V2 .main .main-action {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple .menu-options.help .primary {
  color: #3f51b5;
}
.indigo-purple .menu-options.help .accent {
  color: #9c27b0;
}
.indigo-purple .menu-options .icon-complex mat-icon.complex {
  color: #3f51b5;
  text-shadow: -2px 0 0px white, 0 -2px 0px white, 2px 0 0px white, 0 2px 0px white, -2px -2px 0px white, 2px 2px 0px white, 2px -2px 0px white, -2px 2px 0px white;
}
.indigo-purple .menu-options .mat-menu-content .container mat-list .mat-list-item:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.indigo-purple .menu-options .mat-menu-content .container mat-list .mat-list-item .disabled {
  color: rgba(0, 0, 0, 0.54) !important;
}
.indigo-purple .menu-options .mat-menu-content .container .slide-group mat-radio-group mat-radio-button:hover {
  background: rgba(0, 0, 0, 0.04);
}
.indigo-purple .menu-options .mat-menu-content .container .slide-group mat-form-field .mat-form-field-flex {
  background: #fafafa;
}
.indigo-purple .menu-options .mat-menu-content .container .slide-group mat-slide-toggle:hover {
  background: rgba(0, 0, 0, 0.04);
}
.indigo-purple .mat-menu-panel.notifications-panel .mat-menu-content .notifications-body {
  background: #fafafa;
}
.indigo-purple .mat-menu-panel.notifications-panel .mat-menu-content .notification .primary mat-icon {
  color: #3f51b5;
}
.indigo-purple .mat-menu-panel.notifications-panel .mat-menu-content .notification .primary h3 {
  color: #3f51b5;
}
.indigo-purple .mat-menu-panel.notifications-panel .mat-menu-content .notification.top {
  background: #fafafa;
}
.indigo-purple .mat-menu-panel.notifications-panel .mat-menu-content .notification.top .header h3 {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple .mat-menu-panel.notifications-panel .mat-menu-content .notification.bottom {
  background: #fafafa;
}
.indigo-purple .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable:nth-child(even) {
  background: #fafafa;
}
.indigo-purple .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable:nth-child(odd) {
  background: white;
}
.indigo-purple .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable:hover {
  background: rgba(0, 0, 0, 0.04);
}
.indigo-purple .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable mat-icon {
  color: #3f51b5;
}
.indigo-purple .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable h3 {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable h5 {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-purple .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable .subtitle h5 {
  color: #3f51b5;
  background: #eeeeee;
}
.indigo-purple .info-empty .large-icon {
  background: #c5cae9;
  color: #3f51b5;
}
.indigo-purple .info-empty h2,
.indigo-purple .info-empty h3 {
  color: #3f51b5;
}
.indigo-purple .info-empty h4,
.indigo-purple .info-empty h5 {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-purple .info-empty.accent mat-icon {
  background: #e1bee7;
  color: #9c27b0;
}
.indigo-purple .info-empty.accent h2 {
  color: #9c27b0;
}
.indigo-purple .info-empty .actions {
  padding: 16px;
  height: 96px;
  column-gap: 16px;
}
.indigo-purple .info-empty .actions button {
  height: 100%;
  border-radius: 40px;
}
.indigo-purple .info-empty .actions button.ok {
  background: #009688 !important;
  color: white;
}
.indigo-purple .info-empty .actions button.not-ok {
  color: #9C27B0 !important;
}
.indigo-purple .side.edition .info-empty .large-icon {
  background: #e1bee7;
  color: #9c27b0;
}
.indigo-purple .side.edition .info-empty h2 {
  color: #9c27b0;
}
.indigo-purple .side.edition .info-empty h4 {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-purple .UI-V2 .deferred-process-list .header h3 {
  color: #3f51b5;
}
.indigo-purple .UI-V2 .deferred-process-list .search-container .search .content {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple .UI-V2 .deferred-process-list .search-container .search .content:hover, .indigo-purple .UI-V2 .deferred-process-list .search-container .search .content:active, .indigo-purple .UI-V2 .deferred-process-list .search-container .search .content:focus, .indigo-purple .UI-V2 .deferred-process-list .search-container .search .content:focus-within {
  background: rgba(0, 0, 0, 0.04) !important;
}
.indigo-purple .UI-V2 .deferred-process-list .search-container .search .content input {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple .UI-V2 .deferred-process-list .text-success,
.indigo-purple .UI-V2 .deferred-process-details .text-success {
  color: #8BC34A;
}
.indigo-purple .UI-V2 .deferred-process-list .text-warn,
.indigo-purple .UI-V2 .deferred-process-details .text-warn {
  color: #f44336;
}
.indigo-purple .UI-V2 .deferred-process-list .text-primary,
.indigo-purple .UI-V2 .deferred-process-details .text-primary {
  color: #3f51b5;
}
.indigo-purple .UI-V2 .deferred-process-list .text-atention,
.indigo-purple .UI-V2 .deferred-process-details .text-atention {
  color: #DB7703;
}
.indigo-purple .UI-V2 .deferred-process-list .table-paginator-container,
.indigo-purple .UI-V2 .deferred-process-details .table-paginator-container {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
}
.indigo-purple .UI-V2 .deferred-process-list .table-paginator-container .mat-table,
.indigo-purple .UI-V2 .deferred-process-details .table-paginator-container .mat-table {
  border-bottom: none;
  border-radius: 10px 10px 0 0;
}
.indigo-purple .UI-V2 .deferred-process-list .table-paginator-container .paginator-container,
.indigo-purple .UI-V2 .deferred-process-details .table-paginator-container .paginator-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: #f2f2f2;
  padding: 0 1.5rem;
  height: 3rem;
}
.indigo-purple .UI-V2 .deferred-process-list .mat-table,
.indigo-purple .UI-V2 .deferred-process-details .mat-table {
  border: 1px solid #f2f2f2;
  border-radius: 10px;
  overflow: hidden;
}
.indigo-purple .UI-V2 .deferred-process-list .mat-table .mat-header-row .mat-header-cell,
.indigo-purple .UI-V2 .deferred-process-details .mat-table .mat-header-row .mat-header-cell {
  font-size: 14px;
  font-weight: 700;
}
.indigo-purple .UI-V2 .deferred-process-list .mat-table .mat-header-row,
.indigo-purple .UI-V2 .deferred-process-details .mat-table .mat-header-row {
  background: #f2f2f2;
}
.indigo-purple .UI-V2 .deferred-process-list .mat-table .mat-header-row .mat-header-cell,
.indigo-purple .UI-V2 .deferred-process-details .mat-table .mat-header-row .mat-header-cell {
  color: #3f51b5;
}
.indigo-purple .UI-V2 .deferred-process-list .mat-table .mat-row,
.indigo-purple .UI-V2 .deferred-process-details .mat-table .mat-row {
  background: white;
}
.indigo-purple .UI-V2 .progress-bar-container {
  position: relative;
}
.indigo-purple .UI-V2 .deferred-process-details .header h3 {
  color: #3f51b5;
}
.indigo-purple .UI-V2 .deferred-process-details .header .back-link:visited {
  color: #3f51b5;
}
.indigo-purple .process-card,
.indigo-purple .process-card-detail,
.indigo-purple .process-card-stages {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple .process-card mat-card-subtitle,
.indigo-purple .process-card-detail mat-card-subtitle,
.indigo-purple .process-card-stages mat-card-subtitle {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple .process-card .stages-container .stage.completed mat-icon,
.indigo-purple .process-card-detail .stages-container .stage.completed mat-icon,
.indigo-purple .process-card-stages .stages-container .stage.completed mat-icon {
  color: #499719;
}
.indigo-purple .process-card .stages-container .stage.completed .icon-container,
.indigo-purple .process-card-detail .stages-container .stage.completed .icon-container,
.indigo-purple .process-card-stages .stages-container .stage.completed .icon-container {
  background-color: rgba(78, 160, 28, 0.1490196078);
}
.indigo-purple .process-card .stages-container .stage.completed .line,
.indigo-purple .process-card-detail .stages-container .stage.completed .line,
.indigo-purple .process-card-stages .stages-container .stage.completed .line {
  background-color: #499719;
}
.indigo-purple .process-card .stages-container .stage.on-going mat-icon,
.indigo-purple .process-card-detail .stages-container .stage.on-going mat-icon,
.indigo-purple .process-card-stages .stages-container .stage.on-going mat-icon {
  color: #3f51b5;
}
.indigo-purple .process-card .stages-container .stage.on-going .icon-container,
.indigo-purple .process-card-detail .stages-container .stage.on-going .icon-container,
.indigo-purple .process-card-stages .stages-container .stage.on-going .icon-container {
  background-color: rgba(66, 48, 159, 0.2);
}
.indigo-purple .process-card .stages-container .stage.on-going .line,
.indigo-purple .process-card-detail .stages-container .stage.on-going .line,
.indigo-purple .process-card-stages .stages-container .stage.on-going .line {
  background-color: #949494;
}
.indigo-purple .process-card .stages-container .stage.pending,
.indigo-purple .process-card-detail .stages-container .stage.pending,
.indigo-purple .process-card-stages .stages-container .stage.pending {
  color: #949494;
}
.indigo-purple .process-card .stages-container .stage.pending mat-icon,
.indigo-purple .process-card-detail .stages-container .stage.pending mat-icon,
.indigo-purple .process-card-stages .stages-container .stage.pending mat-icon {
  color: #949494;
}
.indigo-purple .process-card .stages-container .stage.pending .icon-container,
.indigo-purple .process-card-detail .stages-container .stage.pending .icon-container,
.indigo-purple .process-card-stages .stages-container .stage.pending .icon-container {
  background-color: rgba(174, 174, 174, 0.2);
}
.indigo-purple .process-card .stages-container .stage.pending .line,
.indigo-purple .process-card-detail .stages-container .stage.pending .line,
.indigo-purple .process-card-stages .stages-container .stage.pending .line {
  background-color: #949494;
}
.indigo-purple .process-card .stages-container .stage.has-error mat-icon,
.indigo-purple .process-card-detail .stages-container .stage.has-error mat-icon,
.indigo-purple .process-card-stages .stages-container .stage.has-error mat-icon {
  color: #f44336;
}
.indigo-purple .process-card .stages-container .stage.has-error .icon-container,
.indigo-purple .process-card-detail .stages-container .stage.has-error .icon-container,
.indigo-purple .process-card-stages .stages-container .stage.has-error .icon-container {
  background-color: rgba(188, 4, 4, 0.2);
}
.indigo-purple .process-card .stages-container .stage-description .button-link,
.indigo-purple .process-card-detail .stages-container .stage-description .button-link,
.indigo-purple .process-card-stages .stages-container .stage-description .button-link {
  color: #3f51b5;
}
.indigo-purple app-custom-search-input .custom-search-container {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
  transition: color 0.1s ease, background 0.15s ease;
}
.indigo-purple app-custom-search-input .custom-search-container mat-icon.mat-icon {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-purple app-custom-search-input .custom-search-container input.custom-search-input {
  color: rgba(0, 0, 0, 0.87);
  background: unset;
}
.indigo-purple app-custom-search-input .custom-search-container:hover, .indigo-purple app-custom-search-input .custom-search-container:active, .indigo-purple app-custom-search-input .custom-search-container:focus, .indigo-purple app-custom-search-input .custom-search-container:focus-within {
  background: rgba(0, 0, 0, 0.04) !important;
  transition: background 0.15s ease;
}
.indigo-purple .UI-V2 .audit-filter {
  background: white;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple .UI-V2 .audit-filter .toolbar {
  background: #3f51b5;
  color: white;
}
.indigo-purple .UI-V2 .audit-filter .toolbar .header .content .module-title {
  color: #3f51b5;
}
.indigo-purple .UI-V2 .audit-filter .toolbar .header .content .module-title.selectable:hover {
  background: rgba(0, 0, 0, 0.04);
}
.indigo-purple .UI-V2 .audit-filter .toolbar .header .content .module-title .info * {
  color: white !important;
}
.indigo-purple .UI-V2 .audit-filter .toolbar .header .content .ou-select {
  color: white;
  background: #3f51b5;
}
.indigo-purple .UI-V2 .audit-filter .toolbar .search-container .search .content {
  background: #fafafa;
}
.indigo-purple .UI-V2 .audit-filter .toolbar .search-container .search .content:hover, .indigo-purple .UI-V2 .audit-filter .toolbar .search-container .search .content:active, .indigo-purple .UI-V2 .audit-filter .toolbar .search-container .search .content:focus, .indigo-purple .UI-V2 .audit-filter .toolbar .search-container .search .content:focus-within {
  background: rgba(0, 0, 0, 0.04) !important;
}
.indigo-purple .UI-V2 .audit-filter .toolbar .search-container .search .content input {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple .UI-V2 .audit-filter .filter-container {
  background: white !important;
}
.indigo-purple .UI-V2 .audit-filter .filter-container .form-body {
  background: #fafafa !important;
}
.indigo-purple .UI-V2 .audit-filter .filter-container .form-body .line h4 {
  color: #3f51b5;
}
.indigo-purple .UI-V2 .audit-filter .filter-container .form-body .line mat-icon {
  color: #3f51b5;
}
.indigo-purple .UI-V2 .audit-result .result-container .toolbar {
  color: white;
}
.indigo-purple .UI-V2 .audit-result .result-container .toolbar .header .content .module-title {
  color: #3f51b5;
}
.indigo-purple .UI-V2 .audit-result .result-container .toolbar .header .content .module-title.selectable:hover {
  background: rgba(0, 0, 0, 0.04);
}
.indigo-purple .UI-V2 .audit-result .result-container .toolbar .header .content .module-title .info h5 {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-purple .UI-V2 .audit-result .result-container .toolbar .header .content .ou-select {
  color: white;
  background: #3f51b5;
}
.indigo-purple .UI-V2 .audit-result .result-container .toolbar .search-container .search .content {
  background: #fafafa;
}
.indigo-purple .UI-V2 .audit-result .result-container .toolbar .search-container .search .content:hover, .indigo-purple .UI-V2 .audit-result .result-container .toolbar .search-container .search .content:active, .indigo-purple .UI-V2 .audit-result .result-container .toolbar .search-container .search .content:focus, .indigo-purple .UI-V2 .audit-result .result-container .toolbar .search-container .search .content:focus-within {
  background: rgba(0, 0, 0, 0.04) !important;
}
.indigo-purple .UI-V2 .audit-result .result-container .toolbar .search-container .search .content input {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple .UI-V2 .audit-result .result-container .new-table app-cs-grid-control datatable-header * {
  color: #3f51b5;
}
.indigo-purple .UI-V2 .audit-result .result-container .new-table .mat-header-row.sticky * {
  color: #3f51b5 !important;
}
.indigo-purple .UI-V2 .audit-detail-container {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  background: #eeeeee !important;
}
.indigo-purple .UI-V2 .audit-detail-container .side .toolbar .header {
  color: #3f51b5;
}
.indigo-purple .UI-V2 .audit-detail-container .side .container .mat-list-base {
  background: #fafafa !important;
}
.indigo-purple .UI-V2 .audit-detail-container .side .container .mat-list-base mat-list-item .mat-line b {
  color: #3f51b5;
}
.indigo-purple .UI-V2 .inbox-container {
  background: white;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple .UI-V2 .inbox-container *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.indigo-purple .UI-V2 .inbox-container *::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}
.indigo-purple .UI-V2 .inbox-container *::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}
.indigo-purple .UI-V2 .inbox-container .load-window {
  background: white;
}
.indigo-purple .UI-V2 .inbox-container .toolbar {
  background: #3f51b5;
  color: white;
}
.indigo-purple .UI-V2 .inbox-container .toolbar.reverse {
  background: white;
  color: rgba(0, 0, 0, 0.54);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel {
  background: #eeeeee !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel.mat-expanded {
  background: #fafafa !important;
}
.indigo-purple .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel.mat-expanded mat-expansion-panel-header {
  background: #fafafa !important;
}
.indigo-purple .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel.mat-expanded mat-expansion-panel-header:hover {
  background: none !important;
}
.indigo-purple .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel mat-expansion-panel-header {
  background: #fafafa !important;
}
.indigo-purple .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel mat-expansion-panel-header:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.indigo-purple .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel mat-expansion-panel-header .signature-inbox-header .mat-icon,
.indigo-purple .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel mat-expansion-panel-header .signature-inbox-header b {
  color: #9c27b0;
}
.indigo-purple .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel .mat-expansion-panel-body .item {
  background: white;
  color: rgba(0, 0, 0, 0.54);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel .mat-expansion-panel-body .item:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.indigo-purple .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel .mat-expansion-panel-body .item.active {
  border-left: 6px solid #9c27b0;
}
.indigo-purple .UI-V2 .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.indigo-purple .UI-V2 .work-space .main .load-window {
  background: white !important;
}
.indigo-purple .UI-V2 .work-space .main-action {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple mat-radio-button.disabled .mat-radio-label-content {
  color: #9c27b0;
}
.indigo-purple .detail app-automatic-process-detail app-chapa.minimize,
.indigo-purple .detail app-process-detail app-chapa.minimize {
  background: #FFD54F !important;
}
.indigo-purple .detail app-automatic-process-detail app-chapa.minimize.purged,
.indigo-purple .detail app-process-detail app-chapa.minimize.purged {
  background: #b2adb0 !important;
}
.indigo-purple .detail .detail-toolbar {
  background: #eeeeee;
}
.indigo-purple .detail .processes-status .status-bar .bar-graph {
  background-color: #FFD54F;
}
.indigo-purple .detail .processes-status .status-bar .bar-graph .graph-ok {
  background-color: #4DB6AC;
}
.indigo-purple .detail .processes-status .status-bar .bar-graph .graph-not-ok {
  background-color: #F48FB1;
}
.indigo-purple .detail .processes-status .status-resume b {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple .detail .processes-status .status-resume .resume-total b {
  background-color: #FFD54F;
}
.indigo-purple .detail .processes-status .status-resume .resume-ok b {
  background-color: #4DB6AC;
}
.indigo-purple .detail .processes-status .status-resume .resume-not-ok b {
  background-color: #F48FB1;
}
.indigo-purple .detail .detail-toolbar .mat-avatar h3,
.indigo-purple .detail .detail-toolbar .mat-avatar h5 {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple .detail .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple .detail .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple .detail .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple .detail .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple .detail .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  color: white;
}
.indigo-purple .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  background: #4DB6AC !important;
}
.indigo-purple .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  background: #b2dfdb !important;
}
.indigo-purple .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  background: #b2dfdb !important;
}
.indigo-purple .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  background: #4DB6AC !important;
}
.indigo-purple .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  background: #b2dfdb !important;
}
.indigo-purple .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  background: #837C80 !important;
}
.indigo-purple .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  background: #b2adb0 !important;
}
.indigo-purple .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  background: #b2adb0 !important;
}
.indigo-purple .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  background: #837C80 !important;
}
.indigo-purple .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  background: #b2dfdb !important;
}
.indigo-purple .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  background: #FFD54F !important;
}
.indigo-purple .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  background: #ffecb3 !important;
}
.indigo-purple .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  background: #ffecb3 !important;
}
.indigo-purple .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  background: #FFD54F !important;
}
.indigo-purple .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  background: #ffecb3 !important;
}
.indigo-purple .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  background: #F48FB1 !important;
}
.indigo-purple .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  background: #f8bbd0 !important;
}
.indigo-purple .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  background: #f8bbd0 !important;
}
.indigo-purple .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  background: #F48FB1 !important;
}
.indigo-purple .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  background: #f8bbd0 !important;
}
.indigo-purple app-analytics-document-set .sets-container .mat-list-base.sets-list mat-list-item {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple app-analytics-document-set .sets-container .mat-list-base.sets-list mat-list-item .mat-list-item-content .set-status .value-percentile .undone-value {
  background: #673AB7;
}
.indigo-purple app-analytics-document-set .sets-container .mat-list-base.sets-list mat-list-item .mat-list-item-content .set-status .value-percentile .not-value {
  background: #eeeeee;
}
.indigo-purple app-analytics-document-set .sets-container .mat-list-base.sets-list mat-list-item .mat-list-item-content .set-status .value-percentile .done-value {
  background: #009688;
}
.indigo-purple app-analytics-document-set .work-space .main .toolbar .header .content .module-title .info .info-text .set-status {
  cursor: help;
}
.indigo-purple app-analytics-document-set .work-space .main .toolbar .header .content .module-title .info .info-text .set-status .value-percentile .undone-value {
  background: #673AB7;
}
.indigo-purple app-analytics-document-set .work-space .main .toolbar .header .content .module-title .info .info-text .set-status .value-percentile .not-value {
  background: #eeeeee;
}
.indigo-purple app-analytics-document-set .work-space .main .toolbar .header .content .module-title .info .info-text .set-status .value-percentile .done-value {
  background: #009688;
}
.indigo-purple app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar .mat-progress-bar-buffer {
  background-color: #eeeeee;
}
.indigo-purple app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar.class1 .mat-progress-bar-fill::after {
  background-color: #f44336;
}
.indigo-purple app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar.class2 .mat-progress-bar-fill::after {
  background-color: #ff9800;
}
.indigo-purple app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar.class3 .mat-progress-bar-fill::after {
  background-color: #ffeb3b;
}
.indigo-purple app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar.class4 .mat-progress-bar-fill::after {
  background-color: #8bc34a;
}
.indigo-purple app-analytics-document-set .work-space .side .container mat-list {
  background: white !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple app-analytics-document-set .work-space .side .container mat-list mat-list-item .mat-line b {
  color: #3f51b5;
}
.indigo-purple .catcher {
  background: rgba(0, 0, 0, 0.6);
}
.indigo-purple .catcher.clear {
  background: rgba(0, 0, 0, 0);
}
.indigo-purple .add-menu-form-body .mat-radio-group .mat-radio-button:hover {
  background: rgba(0, 0, 0, 0.04);
}
.indigo-purple .add-menu-form-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple .add-menu-form-body.w-table .helper .search-bar {
  background: #fafafa;
}
.indigo-purple .add-menu-form-body.w-table .helper .search-bar:hover, .indigo-purple .add-menu-form-body.w-table .helper .search-bar:active, .indigo-purple .add-menu-form-body.w-table .helper .search-bar:focus, .indigo-purple .add-menu-form-body.w-table .helper .search-bar:focus-within {
  background: #fafafa !important;
}
.indigo-purple .add-menu-form-body.w-table .helper .search-bar input {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple .add-menu-form-actions {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple .file-upload-list mat-list-item {
  background: white;
}
.indigo-purple .mat-horizontal-stepper-content[aria-expanded=true] app-file-document-view {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple .mat-horizontal-stepper-content[aria-expanded=true] app-file-document-view .pdf-view-container {
  background: #eeeeee;
}
.indigo-purple .add-docs-drop .docs-drop-preview app-file-document-detail {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple .add-docs-drop .docs-drop-preview app-file-document-detail .view-toolbar {
  background: white;
}
.indigo-purple .add-docs-drop .load-window.message h3.primary-text {
  color: #3f51b5;
}
.indigo-purple mat-dialog-container app-add-menu-dialog .load-window.message h3.primary-text {
  color: #3f51b5;
}
.indigo-purple .asisted-input.aligned-icon.subtitle * {
  color: rgba(0, 0, 0, 0.38) !important;
}
.indigo-purple .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble {
  background: #3f51b5;
}
.indigo-purple .asisted-input .mat-form-field-invalid + .indicator > .bubble {
  background: #f44336;
}
.indigo-purple .asisted-input .mat-form-field-disabled + .indicator > .bubble {
  color: rgba(0, 0, 0, 0.38);
  background: rgba(0, 0, 0, 0.12);
}
.indigo-purple .asisted-input .indicator .bubble {
  background: rgba(0, 0, 0, 0.38);
  color: white;
}
.indigo-purple .asisted-input .indicator .guide {
  border-color: rgba(0, 0, 0, 0.12);
}
.indigo-purple .asisted-input.icon-only .bubble {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-purple .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble {
  color: #3f51b5;
}
.indigo-purple .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble {
  color: #f44336;
}
.indigo-purple .UI-V2 .main-overlay {
  background: rgba(0, 0, 0, 0.32);
}
.indigo-purple .UI-V2 .icon-complex mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.indigo-purple .UI-V2 .icon-complex mat-icon.complex {
  color: #3f51b5 !important;
}
.indigo-purple .UI-V2 .navigation {
  background: #fafafa;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple .UI-V2 .navigation *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.indigo-purple .UI-V2 .navigation *::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}
.indigo-purple .UI-V2 .navigation .load-window {
  background: #eeeeee;
}
.indigo-purple .UI-V2 .navigation .toolbar {
  background: #3f51b5;
  color: white;
}
.indigo-purple .UI-V2 .navigation .toolbar.reverse {
  background: white;
  color: rgba(0, 0, 0, 0.54);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple .UI-V2 .navigation .container .helper {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-purple .UI-V2 .navigation .container .item {
  background: white;
  color: rgba(0, 0, 0, 0.54);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple .UI-V2 .navigation .container .item:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.indigo-purple .UI-V2 .navigation .container .item.active {
  background: #3f51b5 !important;
  color: white;
  border: 1px solid #3f51b5;
}
.indigo-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .separator .subtitle,
.indigo-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .separator .subtitle,
.indigo-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .separator .subtitle,
.indigo-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .separator .subtitle,
.indigo-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .separator .subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .img-editior-container .img-editor .separator .subtitle,
.indigo-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .img-editior-container .img-editor .separator .subtitle,
.indigo-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .img-editior-container .img-editor .separator .subtitle,
.indigo-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .img-editior-container .img-editor .separator .subtitle,
.indigo-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .img-editior-container .img-editor .separator .subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme,
.indigo-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme,
.indigo-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme,
.indigo-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme,
.indigo-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .main-color-container .main-color,
.indigo-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .main-color-container .main-color,
.indigo-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .main-color-container .main-color,
.indigo-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .main-color-container .main-color,
.indigo-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .main-color-container .main-color {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .main-color-container .main-color:before,
.indigo-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .main-color-container .main-color:before,
.indigo-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .main-color-container .main-color:before,
.indigo-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .main-color-container .main-color:before,
.indigo-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .main-color-container .main-color:before {
  border-left: 10px solid rgba(0, 0, 0, 0);
  border-right: 10px solid rgba(0, 0, 0, 0);
}
.indigo-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example h6.required,
.indigo-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example h6.required,
.indigo-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example h6.required,
.indigo-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example h6.required,
.indigo-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example h6.required {
  color: #f44336 !important;
}
.indigo-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle,
.indigo-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle,
.indigo-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle,
.indigo-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle,
.indigo-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle {
  border: 2px solid rgba(0, 0, 0, 0);
}
.indigo-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .side-color-container .side-color,
.indigo-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .side-color-container .side-color,
.indigo-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .side-color-container .side-color,
.indigo-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .side-color-container .side-color,
.indigo-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .side-color-container .side-color {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .side-color-container .side-color:before,
.indigo-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .side-color-container .side-color:before,
.indigo-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .side-color-container .side-color:before,
.indigo-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .side-color-container .side-color:before,
.indigo-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .side-color-container .side-color:before {
  border-top: 10px solid rgba(0, 0, 0, 0);
  border-bottom: 10px solid rgba(0, 0, 0, 0);
}
.indigo-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .side-color-container .side-color h6.required,
.indigo-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .side-color-container .side-color h6.required,
.indigo-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .side-color-container .side-color h6.required,
.indigo-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .side-color-container .side-color h6.required,
.indigo-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .side-color-container .side-color h6.required {
  color: #f44336 !important;
}
.indigo-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .permission-settings-cards .card,
.indigo-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .permission-settings-cards .card,
.indigo-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .permission-settings-cards .card,
.indigo-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .permission-settings-cards .card,
.indigo-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .permission-settings-cards .card {
  background: #fafafa;
}
.indigo-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .permission-settings-cards .card .slide-sub-group,
.indigo-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .permission-settings-cards .card .slide-sub-group,
.indigo-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .permission-settings-cards .card .slide-sub-group,
.indigo-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .permission-settings-cards .card .slide-sub-group,
.indigo-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .permission-settings-cards .card .slide-sub-group {
  background: rgba(0, 0, 0, 0.12);
}
.indigo-purple .UI-V2 .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.indigo-purple .UI-V2 .work-space .main .load-window {
  background: white !important;
}
.indigo-purple .UI-V2 .work-space .main.side-open {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item mat-icon.complex {
  text-shadow: -2px 0 0px #eeeeee, 0 -2px 0px #eeeeee, 2px 0 0px #eeeeee, 0 2px 0px #eeeeee, -2px -2px 0px #eeeeee, 2px 2px 0px #eeeeee, 2px -2px 0px #eeeeee, -2px 2px 0px #eeeeee !important;
}
.indigo-purple .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item h5 {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-purple .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item h3 {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item .actions {
  pointer-events: none;
}
.indigo-purple .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item .actions button {
  pointer-events: all;
}
.indigo-purple .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled {
  background: #eeeeee;
}
.indigo-purple .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled h3 {
  color: #607D8B !important;
}
.indigo-purple .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled mat-icon {
  color: #607D8B !important;
}
.indigo-purple .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled.selected {
  background: #607D8B;
  color: white !important;
}
.indigo-purple .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled.selected h3 {
  color: white !important;
}
.indigo-purple .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled.selected mat-icon {
  color: white !important;
}
.indigo-purple .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled.selected mat-icon.complex {
  color: white !important;
  text-shadow: -2px 0 0px #607D8B, 0 -2px 0px #607D8B, 2px 0 0px #607D8B, 0 2px 0px #607D8B, -2px -2px 0px #607D8B, 2px 2px 0px #607D8B, 2px -2px 0px #607D8B, -2px 2px 0px #607D8B !important;
}
.indigo-purple .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item:hover {
  background: rgba(0, 0, 0, 0.04);
}
.indigo-purple .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected {
  background: #3f51b5;
  color: white;
}
.indigo-purple .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected mat-icon {
  color: white;
}
.indigo-purple .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected mat-icon.complex {
  color: white !important;
  text-shadow: -2px 0 0px #3f51b5, 0 -2px 0px #3f51b5, 2px 0 0px #3f51b5, 0 2px 0px #3f51b5, -2px -2px 0px #3f51b5, 2px 2px 0px #3f51b5, 2px -2px 0px #3f51b5, -2px 2px 0px #3f51b5 !important;
}
.indigo-purple .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected h5,
.indigo-purple .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected h3 {
  color: white;
}
.indigo-purple .UI-V2 .work-space .main .main-action {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple .UI-V2 .work-space .side {
  background: #fafafa;
}
.indigo-purple .UI-V2 .work-space .side .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #fafafa, 0 -2px 0px #fafafa, 2px 0 0px #fafafa, 0 2px 0px #fafafa, -2px -2px 0px #fafafa, 2px 2px 0px #fafafa, 2px -2px 0px #fafafa, -2px 2px 0px #fafafa;
}
.indigo-purple .UI-V2 .work-space .side *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.indigo-purple .UI-V2 .work-space .side *::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}
.indigo-purple .UI-V2 .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #fafafa !important;
}
.indigo-purple .UI-V2 .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-purple .UI-V2 .work-space .side mat-form-field .mat-form-field-flex {
  background: #fafafa;
}
.indigo-purple .UI-V2 .work-space .side mat-checkbox:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.indigo-purple .UI-V2 .work-space .side .hint.all {
  color: #9c27b0;
}
.indigo-purple .UI-V2 .work-space .side .toolbar .header {
  color: #3f51b5;
}
.indigo-purple .UI-V2 .work-space .side .toolbar .header h3 {
  color: #3f51b5;
}
.indigo-purple .UI-V2 .work-space .side .container .role-resume {
  background: #3f51b5;
}
.indigo-purple .UI-V2 .work-space .side .container .role-resume .line {
  color: white;
}
.indigo-purple .UI-V2 .work-space .side .container .role-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.1);
}
.indigo-purple .UI-V2 .work-space .side .container .role-resume .line.editable button {
  background: #3f51b5;
}
.indigo-purple .UI-V2 .work-space .side .container .section-title {
  color: #3f51b5;
}
.indigo-purple .UI-V2 .work-space .side .container .section-resume {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple .UI-V2 .work-space .side .container .section-resume .line.editable:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.indigo-purple .UI-V2 .work-space .side .container .section-resume .line.editable:hover .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px white, 0 -2px 0px white, 2px 0 0px white, 0 2px 0px white, -2px -2px 0px white, 2px 2px 0px white, 2px -2px 0px white, -2px 2px 0px white !important;
}
.indigo-purple .UI-V2 .work-space .side .container .section-resume .line.editable button {
  background: #fafafa;
}
.indigo-purple .UI-V2 .work-space .side .container .section-resume .line .header .icon-complex mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.indigo-purple .UI-V2 .work-space .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #3f51b5 !important;
}
.indigo-purple .UI-V2 .work-space .side .container .section-resume .line .header mat-icon:not(.complex) {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-purple .UI-V2 .work-space .side .container .section-resume .line .header .info h4 {
  color: #3f51b5;
}
.indigo-purple .UI-V2 .work-space .side .container .section-resume .line ul {
  color: #3f51b5;
}
.indigo-purple .UI-V2 .work-space .side .container .section-resume .line.head {
  background: #eeeeee;
}
.indigo-purple .UI-V2 .work-space .side .container .section-resume .line.head mat-icon {
  color: #9c27b0;
}
.indigo-purple .UI-V2 .work-space .side .container .section-resume .line.head h4 {
  color: white;
}
.indigo-purple .UI-V2 .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body {
  background: white;
}
.indigo-purple .UI-V2 .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-purple .UI-V2 .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #3f51b5;
}
.indigo-purple .UI-V2 .work-space .side.edition .toolbar .header {
  color: white;
}
.indigo-purple .UI-V2 .work-space .side.edition .toolbar .header mat-icon {
  color: #9c27b0;
}
.indigo-purple .UI-V2 .work-space .side.edition .toolbar .header h3 {
  color: #9c27b0;
}
.indigo-purple .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body {
  background: white !important;
}
.indigo-purple .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header mat-icon:not(.complex) {
  color: #3f51b5;
}
.indigo-purple .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header h4 {
  color: #3f51b5;
}
.indigo-purple .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-actions {
  background: #fafafa;
}
.indigo-purple .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.indigo-purple .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header .icon-complex mat-icon.complex {
  color: #9c27b0 !important;
}
.indigo-purple .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header h4 {
  color: #9c27b0;
}
.indigo-purple .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line ul {
  color: #9c27b0;
  font-weight: 400;
}
.indigo-purple .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head mat-icon {
  color: #9c27b0 !important;
}
.indigo-purple .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head h4 {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .section-title {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-purple .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume h6.hint {
  background: #e1bee7;
}
.indigo-purple app-role-user-find .main-overlay {
  background: rgba(0, 0, 0, 0.32);
}
.indigo-purple app-role-user-find .icon-complex mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.indigo-purple app-role-user-find .icon-complex mat-icon.complex {
  color: #3f51b5 !important;
}
.indigo-purple app-role-user-find .navigation {
  background: #fafafa;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple app-role-user-find .navigation *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.indigo-purple app-role-user-find .navigation *::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}
.indigo-purple app-role-user-find .navigation .load-window {
  background: #eeeeee;
}
.indigo-purple app-role-user-find .navigation .toolbar {
  background: #3f51b5;
  color: white;
}
.indigo-purple app-role-user-find .navigation .toolbar.reverse {
  background: white;
  color: rgba(0, 0, 0, 0.54);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple app-role-user-find .navigation .container .helper {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-purple app-role-user-find .navigation .container .item {
  background: white;
  color: rgba(0, 0, 0, 0.54);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple app-role-user-find .navigation .container .item:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.indigo-purple app-role-user-find .navigation .container .item.active {
  background: #3f51b5 !important;
  color: white;
  border: 1px solid #3f51b5;
}
.indigo-purple app-role-user-find .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.indigo-purple app-role-user-find .work-space .main .load-window {
  background: white !important;
}
.indigo-purple app-role-user-find .work-space .main.side-open {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple app-role-user-find .work-space .main .container .item {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple app-role-user-find .work-space .main .container .item .subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-purple app-role-user-find .work-space .main .container .item.disabled {
  background: #eeeeee;
  color: #607D8B !important;
}
.indigo-purple app-role-user-find .work-space .main .container .item.disabled h3 {
  color: #607D8B !important;
}
.indigo-purple app-role-user-find .work-space .main .container .item.disabled mat-icon {
  color: #607D8B !important;
}
.indigo-purple app-role-user-find .work-space .main .container .item.disabled.selected {
  background: #607D8B;
  color: white !important;
}
.indigo-purple app-role-user-find .work-space .main .container .item.disabled.selected h3 {
  color: white !important;
}
.indigo-purple app-role-user-find .work-space .main .container .item.disabled.selected mat-icon {
  color: white !important;
}
.indigo-purple app-role-user-find .work-space .main .container .item.disabled.selected mat-icon.complex {
  color: white !important;
  text-shadow: -2px 0 0px #607D8B, 0 -2px 0px #607D8B, 2px 0 0px #607D8B, 0 2px 0px #607D8B, -2px -2px 0px #607D8B, 2px 2px 0px #607D8B, 2px -2px 0px #607D8B, -2px 2px 0px #607D8B !important;
}
.indigo-purple app-role-user-find .work-space .main .container .item mat-icon.complex {
  text-shadow: -2px 0 0px #eeeeee, 0 -2px 0px #eeeeee, 2px 0 0px #eeeeee, 0 2px 0px #eeeeee, -2px -2px 0px #eeeeee, 2px 2px 0px #eeeeee, 2px -2px 0px #eeeeee, -2px 2px 0px #eeeeee !important;
}
.indigo-purple app-role-user-find .work-space .main .container .item h5 {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-purple app-role-user-find .work-space .main .container .item h3 {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple app-role-user-find .work-space .main .container .item:hover {
  background: rgba(0, 0, 0, 0.04);
}
.indigo-purple app-role-user-find .work-space .main .container .item.selected {
  background: #3f51b5;
  color: white;
}
.indigo-purple app-role-user-find .work-space .main .container .item.selected mat-icon {
  color: white;
}
.indigo-purple app-role-user-find .work-space .main .container .item.selected mat-icon.complex {
  color: white !important;
  text-shadow: -2px 0 0px #3f51b5, 0 -2px 0px #3f51b5, 2px 0 0px #3f51b5, 0 2px 0px #3f51b5, -2px -2px 0px #3f51b5, 2px 2px 0px #3f51b5, 2px -2px 0px #3f51b5, -2px 2px 0px #3f51b5 !important;
}
.indigo-purple app-role-user-find .work-space .main .container .item.selected h5,
.indigo-purple app-role-user-find .work-space .main .container .item.selected h3 {
  color: white;
}
.indigo-purple app-role-user-find .work-space .main .main-action {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple app-role-user-find .work-space .side {
  background: #fafafa;
}
.indigo-purple app-role-user-find .work-space .side .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #fafafa, 0 -2px 0px #fafafa, 2px 0 0px #fafafa, 0 2px 0px #fafafa, -2px -2px 0px #fafafa, 2px 2px 0px #fafafa, 2px -2px 0px #fafafa, -2px 2px 0px #fafafa;
}
.indigo-purple app-role-user-find .work-space .side *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.indigo-purple app-role-user-find .work-space .side *::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}
.indigo-purple app-role-user-find .work-space .side .edition-line {
  background: #eeeeee;
}
.indigo-purple app-role-user-find .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #fafafa !important;
}
.indigo-purple app-role-user-find .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-purple app-role-user-find .work-space .side mat-form-field .mat-form-field-flex {
  background: #fafafa;
}
.indigo-purple app-role-user-find .work-space .side mat-checkbox:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.indigo-purple app-role-user-find .work-space .side .hint.all {
  color: #9c27b0;
}
.indigo-purple app-role-user-find .work-space .side .toolbar .header {
  color: #3f51b5;
}
.indigo-purple app-role-user-find .work-space .side .toolbar .header h3 {
  color: #3f51b5;
}
.indigo-purple app-role-user-find .work-space .side .container .role-resume {
  background: #3f51b5;
}
.indigo-purple app-role-user-find .work-space .side .container .role-resume .line {
  color: white;
}
.indigo-purple app-role-user-find .work-space .side .container .role-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.1);
}
.indigo-purple app-role-user-find .work-space .side .container .role-resume .line.editable button {
  background: #3f51b5;
}
.indigo-purple app-role-user-find .work-space .side .container .section-title {
  color: #3f51b5;
}
.indigo-purple app-role-user-find .work-space .side .container .section-resume {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple app-role-user-find .work-space .side .container .section-resume .line.editable:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.indigo-purple app-role-user-find .work-space .side .container .section-resume .line.editable:hover .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px white, 0 -2px 0px white, 2px 0 0px white, 0 2px 0px white, -2px -2px 0px white, 2px 2px 0px white, 2px -2px 0px white, -2px 2px 0px white !important;
}
.indigo-purple app-role-user-find .work-space .side .container .section-resume .line.editable button {
  background: #fafafa;
}
.indigo-purple app-role-user-find .work-space .side .container .section-resume .line .header .icon-complex mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.indigo-purple app-role-user-find .work-space .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #3f51b5 !important;
}
.indigo-purple app-role-user-find .work-space .side .container .section-resume .line .header mat-icon:not(.complex) {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-purple app-role-user-find .work-space .side .container .section-resume .line .header .info h4 {
  color: #3f51b5;
}
.indigo-purple app-role-user-find .work-space .side .container .section-resume .line ul {
  color: #3f51b5;
}
.indigo-purple app-role-user-find .work-space .side .container .section-resume .line.head {
  background: #eeeeee;
}
.indigo-purple app-role-user-find .work-space .side .container .section-resume .line.head mat-icon {
  color: #9c27b0;
}
.indigo-purple app-role-user-find .work-space .side .container .section-resume .line.head h4 {
  color: white;
}
.indigo-purple app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee-title {
  color: #3f51b5;
}
.indigo-purple app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body {
  background: white;
}
.indigo-purple app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-purple app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #3f51b5;
}
.indigo-purple app-role-user-find .work-space .side.edition .toolbar .header {
  color: white;
}
.indigo-purple app-role-user-find .work-space .side.edition .toolbar .header mat-icon {
  color: #9c27b0;
}
.indigo-purple app-role-user-find .work-space .side.edition .toolbar .header h3 {
  color: #9c27b0;
}
.indigo-purple app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body {
  background: white !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header mat-icon:not(.complex) {
  color: #3f51b5;
}
.indigo-purple app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header h4 {
  color: #3f51b5;
}
.indigo-purple app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-actions {
  background: #fafafa;
}
.indigo-purple app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.indigo-purple app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header .icon-complex mat-icon.complex {
  color: #9c27b0 !important;
}
.indigo-purple app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header h4 {
  color: #9c27b0;
}
.indigo-purple app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line ul {
  color: #9c27b0;
}
.indigo-purple app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head mat-icon {
  color: #9c27b0 !important;
}
.indigo-purple app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head h4 {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .section-title {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-purple app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume h6.hint {
  background: #e1bee7;
}
.indigo-purple app-role-user-find .work-space app-role-user-detail.disabled .side .toolbar .header {
  color: #607D8B !important;
}
.indigo-purple app-role-user-find .work-space app-role-user-detail.disabled .side .container .role-resume {
  background: #607D8B !important;
}
.indigo-purple app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-title {
  color: #607D8B !important;
}
.indigo-purple app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line ul {
  color: #607D8B !important;
}
.indigo-purple app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header mat-icon {
  color: #607D8B;
}
.indigo-purple app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .icon-complex mat-icon {
  color: #607D8B !important;
}
.indigo-purple app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #607D8B !important;
}
.indigo-purple app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .info h4 {
  color: #607D8B !important;
}
.indigo-purple app-role-user-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee-title {
  color: #607D8B;
}
.indigo-purple app-role-user-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: #607D8B;
}
.indigo-purple app-role-user-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #607D8B;
}
.indigo-purple app-actor-find .main-overlay {
  background: rgba(0, 0, 0, 0.32);
}
.indigo-purple app-actor-find .icon-complex mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.indigo-purple app-actor-find .icon-complex mat-icon.complex {
  color: #3f51b5 !important;
}
.indigo-purple app-actor-find .navigation {
  background: #fafafa;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple app-actor-find .navigation *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.indigo-purple app-actor-find .navigation *::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}
.indigo-purple app-actor-find .navigation .load-window {
  background: #eeeeee;
}
.indigo-purple app-actor-find .navigation .toolbar {
  background: #3f51b5;
  color: white;
}
.indigo-purple app-actor-find .navigation .toolbar.reverse {
  background: white;
  color: rgba(0, 0, 0, 0.54);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple app-actor-find .navigation .container .helper {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-purple app-actor-find .navigation .container .item {
  background: white;
  color: rgba(0, 0, 0, 0.54);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple app-actor-find .navigation .container .item:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.indigo-purple app-actor-find .navigation .container .item.active {
  background: #3f51b5 !important;
  color: white;
  border: 1px solid #3f51b5;
}
.indigo-purple app-actor-find .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.indigo-purple app-actor-find .work-space .main .load-window {
  background: white !important;
}
.indigo-purple app-actor-find .work-space .main.side-open {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple app-actor-find .work-space .main .container .item {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple app-actor-find .work-space .main .container .item .subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-purple app-actor-find .work-space .main .container .item.disabled {
  background: #eeeeee;
  color: #607D8B !important;
}
.indigo-purple app-actor-find .work-space .main .container .item.disabled h3 {
  color: #607D8B !important;
}
.indigo-purple app-actor-find .work-space .main .container .item.disabled mat-icon {
  color: #607D8B !important;
}
.indigo-purple app-actor-find .work-space .main .container .item.disabled.selected {
  background: #607D8B;
  color: white !important;
}
.indigo-purple app-actor-find .work-space .main .container .item.disabled.selected h3 {
  color: white !important;
}
.indigo-purple app-actor-find .work-space .main .container .item.disabled.selected mat-icon {
  color: white !important;
}
.indigo-purple app-actor-find .work-space .main .container .item.disabled.selected mat-icon.complex {
  color: white !important;
  text-shadow: -2px 0 0px #607D8B, 0 -2px 0px #607D8B, 2px 0 0px #607D8B, 0 2px 0px #607D8B, -2px -2px 0px #607D8B, 2px 2px 0px #607D8B, 2px -2px 0px #607D8B, -2px 2px 0px #607D8B !important;
}
.indigo-purple app-actor-find .work-space .main .container .item mat-icon.complex {
  text-shadow: -2px 0 0px #eeeeee, 0 -2px 0px #eeeeee, 2px 0 0px #eeeeee, 0 2px 0px #eeeeee, -2px -2px 0px #eeeeee, 2px 2px 0px #eeeeee, 2px -2px 0px #eeeeee, -2px 2px 0px #eeeeee !important;
}
.indigo-purple app-actor-find .work-space .main .container .item h5 {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-purple app-actor-find .work-space .main .container .item h3 {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple app-actor-find .work-space .main .container .item:hover {
  background: rgba(0, 0, 0, 0.04);
}
.indigo-purple app-actor-find .work-space .main .container .item.selected {
  background: #3f51b5;
  color: white;
}
.indigo-purple app-actor-find .work-space .main .container .item.selected mat-icon {
  color: white;
}
.indigo-purple app-actor-find .work-space .main .container .item.selected mat-icon.complex {
  color: white !important;
  text-shadow: -2px 0 0px #3f51b5, 0 -2px 0px #3f51b5, 2px 0 0px #3f51b5, 0 2px 0px #3f51b5, -2px -2px 0px #3f51b5, 2px 2px 0px #3f51b5, 2px -2px 0px #3f51b5, -2px 2px 0px #3f51b5 !important;
}
.indigo-purple app-actor-find .work-space .main .container .item.selected h5,
.indigo-purple app-actor-find .work-space .main .container .item.selected h3 {
  color: white;
}
.indigo-purple app-actor-find .work-space .main .main-action {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple app-actor-find .work-space .side {
  background: #fafafa;
}
.indigo-purple app-actor-find .work-space .side .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #fafafa, 0 -2px 0px #fafafa, 2px 0 0px #fafafa, 0 2px 0px #fafafa, -2px -2px 0px #fafafa, 2px 2px 0px #fafafa, 2px -2px 0px #fafafa, -2px 2px 0px #fafafa;
}
.indigo-purple app-actor-find .work-space .side *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.indigo-purple app-actor-find .work-space .side *::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}
.indigo-purple app-actor-find .work-space .side .edition-line {
  background: #eeeeee;
}
.indigo-purple app-actor-find .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #fafafa !important;
}
.indigo-purple app-actor-find .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-purple app-actor-find .work-space .side mat-form-field .mat-form-field-flex {
  background: #fafafa;
}
.indigo-purple app-actor-find .work-space .side mat-checkbox:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.indigo-purple app-actor-find .work-space .side .hint.all {
  color: #9c27b0;
}
.indigo-purple app-actor-find .work-space .side .toolbar .header {
  color: #3f51b5;
}
.indigo-purple app-actor-find .work-space .side .toolbar .header h3 {
  color: #3f51b5;
}
.indigo-purple app-actor-find .work-space .side .container .role-resume {
  background: #3f51b5;
}
.indigo-purple app-actor-find .work-space .side .container .role-resume .line {
  color: white;
}
.indigo-purple app-actor-find .work-space .side .container .role-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.1);
}
.indigo-purple app-actor-find .work-space .side .container .role-resume .line.editable button {
  background: #3f51b5;
}
.indigo-purple app-actor-find .work-space .side .container .section-title {
  color: #3f51b5;
}
.indigo-purple app-actor-find .work-space .side .container .section-resume {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple app-actor-find .work-space .side .container .section-resume .line.editable:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.indigo-purple app-actor-find .work-space .side .container .section-resume .line.editable:hover .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px white, 0 -2px 0px white, 2px 0 0px white, 0 2px 0px white, -2px -2px 0px white, 2px 2px 0px white, 2px -2px 0px white, -2px 2px 0px white !important;
}
.indigo-purple app-actor-find .work-space .side .container .section-resume .line.editable button {
  background: #fafafa;
}
.indigo-purple app-actor-find .work-space .side .container .section-resume .line .header .icon-complex mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.indigo-purple app-actor-find .work-space .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #3f51b5 !important;
}
.indigo-purple app-actor-find .work-space .side .container .section-resume .line .header mat-icon:not(.complex) {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-purple app-actor-find .work-space .side .container .section-resume .line .header .info h4 {
  color: #3f51b5;
}
.indigo-purple app-actor-find .work-space .side .container .section-resume .line ul {
  color: #3f51b5;
}
.indigo-purple app-actor-find .work-space .side .container .section-resume .line.head {
  background: #eeeeee;
}
.indigo-purple app-actor-find .work-space .side .container .section-resume .line.head mat-icon {
  color: #9c27b0;
}
.indigo-purple app-actor-find .work-space .side .container .section-resume .line.head h4 {
  color: white;
}
.indigo-purple app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee-title {
  color: #3f51b5;
}
.indigo-purple app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body {
  background: white;
}
.indigo-purple app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-purple app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #3f51b5;
}
.indigo-purple app-actor-find .work-space .side.edition .toolbar .header {
  color: white;
}
.indigo-purple app-actor-find .work-space .side.edition .toolbar .header mat-icon {
  color: #9c27b0;
}
.indigo-purple app-actor-find .work-space .side.edition .toolbar .header h3 {
  color: #9c27b0;
}
.indigo-purple app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body {
  background: white !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header mat-icon:not(.complex) {
  color: #3f51b5;
}
.indigo-purple app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header h4 {
  color: #3f51b5;
}
.indigo-purple app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-actions {
  background: #fafafa;
}
.indigo-purple app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.indigo-purple app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header .icon-complex mat-icon.complex {
  color: #9c27b0 !important;
}
.indigo-purple app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header h4 {
  color: #9c27b0;
}
.indigo-purple app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line ul {
  color: #9c27b0;
}
.indigo-purple app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head mat-icon {
  color: #9c27b0 !important;
}
.indigo-purple app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head h4 {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .section-title {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-purple app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume h6.hint {
  background: #e1bee7;
}
.indigo-purple app-actor-find .work-space app-role-user-detail.disabled .side .toolbar .header {
  color: #607D8B !important;
}
.indigo-purple app-actor-find .work-space app-role-user-detail.disabled .side .container .role-resume {
  background: #607D8B !important;
}
.indigo-purple app-actor-find .work-space app-role-user-detail.disabled .side .container .section-title {
  color: #607D8B !important;
}
.indigo-purple app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line ul {
  color: #607D8B !important;
}
.indigo-purple app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header mat-icon {
  color: #607D8B;
}
.indigo-purple app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .icon-complex mat-icon {
  color: #607D8B !important;
}
.indigo-purple app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #607D8B !important;
}
.indigo-purple app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .info h4 {
  color: #607D8B !important;
}
.indigo-purple app-actor-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee-title {
  color: #607D8B;
}
.indigo-purple app-actor-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: #607D8B;
}
.indigo-purple app-actor-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #607D8B;
}
.indigo-purple .dropzone {
  border: 2px dashed #607D8B;
  background: #eeeeee !important;
}
.indigo-purple .dropzone.hovering {
  border: 2px solid #607D8B;
  color: rgba(0, 0, 0, 0.38) !important;
}
.indigo-purple .dropzone button.mat-stroked-button {
  background: white;
}
.indigo-purple .firmador .content-action-main {
  color: white !important;
}
.indigo-purple .firmador .content-action-main.disabled {
  color: rgba(0, 0, 0, 0.38) !important;
  background: rgba(0, 0, 0, 0.12);
}
.indigo-purple .sign-cycle {
  background: white;
}
.indigo-purple .sign-cycle button.success {
  background-color: #009688;
}
.indigo-purple .sign-cycle button.warning {
  background-color: #673AB7;
}
.indigo-purple .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-title-container .cycle-content-title h4 {
  color: #607D8B;
}
.indigo-purple .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-action .content-action-main {
  color: white !important;
}
.indigo-purple .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-action .content-action-main.mat-stroked-button:disabled {
  color: #9e9e9e !important;
}
.indigo-purple .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-action .content-action-main.mat-stroked-button:not(:disabled) {
  color: #9c27b0 !important;
}
.indigo-purple .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-action .content-action-side u {
  color: #9e9e9e;
}
.indigo-purple .resume-status {
  background: white;
}
.indigo-purple .help-hoverable mat-icon {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-purple .help-hoverable mat-hint {
  background: white;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.indigo-purple .help-hoverable mat-hint b {
  color: #3f51b5 !important;
}
.indigo-purple app-file-document-sign-massive-dialog .dialog-content,
.indigo-purple app-share-person-dialog .dialog-content {
  background: #fafafa;
}
.indigo-purple app-file-document-sign-massive-dialog .dialog-content mat-toolbar mat-toolbar-row .mat-avatar h3,
.indigo-purple app-share-person-dialog .dialog-content mat-toolbar mat-toolbar-row .mat-avatar h3 {
  color: #3f51b5;
}
.indigo-purple app-file-document-sign-massive-dialog .dialog-content .dialog-body p b,
.indigo-purple app-share-person-dialog .dialog-content .dialog-body p b {
  color: #9c27b0;
}
.indigo-purple app-file-document-sign-massive-dialog .dialog-content app-file-document-sign,
.indigo-purple app-share-person-dialog .dialog-content app-file-document-sign {
  background: white;
}
.indigo-purple .mat-basic-chip {
  background: #eeeeee !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple .mat-basic-chip.seen {
  border-color: #2196F3 !important;
  color: #2196F3 !important;
}
.indigo-purple .mat-basic-chip.unseen {
  border-color: #607D8B !important;
  color: #607D8B !important;
}
.indigo-purple .mat-basic-chip.signed-ok {
  border-color: #009688 !important;
  color: #009688 !important;
}
.indigo-purple .mat-basic-chip.signed-ok.cancelled {
  border-color: #795548 !important;
  color: #795548 !important;
}
.indigo-purple .mat-basic-chip.signed-not-ok {
  border-color: #673AB7 !important;
  color: #673AB7 !important;
}
.indigo-purple .mat-basic-chip.not-signed {
  border-color: #ff4081 !important;
  color: #ff4081 !important;
}
.indigo-purple .mat-basic-chip.not-signed-employer {
  border-color: #ff4081 !important;
  color: #ff4081 !important;
}
.indigo-purple app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip {
  background: #eeeeee !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.seen {
  border-color: #2196F3 !important;
  color: #2196F3 !important;
}
.indigo-purple app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.unseen {
  border-color: #607D8B !important;
  color: #607D8B !important;
}
.indigo-purple app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.signed-ok {
  border-color: #009688 !important;
  color: #009688 !important;
}
.indigo-purple app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.signed-ok.cancelled {
  border-color: #795548 !important;
  color: #795548 !important;
}
.indigo-purple app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.signed-not-ok {
  border-color: #673AB7 !important;
  color: #673AB7 !important;
}
.indigo-purple app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.not-signed {
  border-color: #ff4081 !important;
  color: #ff4081 !important;
}
.indigo-purple app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.not-signed-employer {
  border-color: #ff4081 !important;
  color: #ff4081 !important;
}
.indigo-purple .inbox-container.employee.my-documents .chip-line.mat-line .mat-chip-list-wrapper .mat-basic-chip.seen {
  color: #607D8B !important;
}
.indigo-purple .resume-status .mat-basic-chip {
  background: #eeeeee !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple .resume-status .mat-basic-chip.signed-ok {
  border-color: #009688 !important;
  background-color: #009688 !important;
  color: white !important;
}
.indigo-purple .resume-status .mat-basic-chip.signed-ok.cancelled {
  border-color: #795548 !important;
  background-color: #795548 !important;
  color: white !important;
}
.indigo-purple .resume-status .mat-basic-chip.signed-not-ok {
  border-color: #673AB7 !important;
  background-color: #673AB7 !important;
  color: white !important;
}
.indigo-purple app-file-document-view .new-container app-file-document-upload {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple app-file-document-view .new-container app-upload-form .file-upload-list .mat-subheader {
  background: white;
}
.indigo-purple app-file-document-view .new-container app-upload-form .file-upload-list mat-list-item {
  background: #fafafa !important;
}
.indigo-purple app-file-document-view .preview-content {
  background: #607D8B;
}
.indigo-purple app-file-document-view .preview-content h4 {
  color: white;
}
.indigo-purple app-file-document-view .view-container {
  background: white;
}
.indigo-purple app-file-document-view .view-container mat-toolbar.side-bar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple .formio-hint {
  background: #3f51b5;
  color: white;
}
.indigo-purple mat-dialog-container app-file-document-state-chips {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple app-candidate-promotion .load-window.message h3.primary-text {
  color: #3f51b5;
}
.indigo-purple app-multiple-documentation-add-dialog .icon-complex mat-icon,
.indigo-purple app-documentation-type-set-configuration-dialog .icon-complex mat-icon,
.indigo-purple app-add-candidate-dialog .icon-complex mat-icon,
.indigo-purple app-share-person-dialog .icon-complex mat-icon,
.indigo-purple app-share-person-response-dialog .icon-complex mat-icon {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-purple app-multiple-documentation-add-dialog .icon-complex mat-icon.complex,
.indigo-purple app-documentation-type-set-configuration-dialog .icon-complex mat-icon.complex,
.indigo-purple app-add-candidate-dialog .icon-complex mat-icon.complex,
.indigo-purple app-share-person-dialog .icon-complex mat-icon.complex,
.indigo-purple app-share-person-response-dialog .icon-complex mat-icon.complex {
  color: #3f51b5 !important;
}
.indigo-purple app-multiple-documentation-add-dialog .icon-complex mat-icon.complex.mat-accent,
.indigo-purple app-documentation-type-set-configuration-dialog .icon-complex mat-icon.complex.mat-accent,
.indigo-purple app-add-candidate-dialog .icon-complex mat-icon.complex.mat-accent,
.indigo-purple app-share-person-dialog .icon-complex mat-icon.complex.mat-accent,
.indigo-purple app-share-person-response-dialog .icon-complex mat-icon.complex.mat-accent {
  color: #9c27b0;
}
.indigo-purple app-multiple-documentation-add-dialog .icon-complex mat-icon.complex.side,
.indigo-purple app-documentation-type-set-configuration-dialog .icon-complex mat-icon.complex.side,
.indigo-purple app-add-candidate-dialog .icon-complex mat-icon.complex.side,
.indigo-purple app-share-person-dialog .icon-complex mat-icon.complex.side,
.indigo-purple app-share-person-response-dialog .icon-complex mat-icon.complex.side {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection,
.indigo-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection,
.indigo-purple app-add-candidate-dialog .dialog-content .document-template-container .document-selection,
.indigo-purple app-share-person-dialog .dialog-content .document-template-container .document-selection,
.indigo-purple app-share-person-response-dialog .dialog-content .document-template-container .document-selection {
  background: #fafafa;
}
.indigo-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.indigo-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.indigo-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3,
.indigo-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.indigo-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.indigo-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3,
.indigo-purple app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.indigo-purple app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.indigo-purple app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3,
.indigo-purple app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.indigo-purple app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.indigo-purple app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3,
.indigo-purple app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.indigo-purple app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.indigo-purple app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3 {
  color: #9c27b0 !important;
}
.indigo-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon,
.indigo-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon,
.indigo-purple app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon,
.indigo-purple app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon,
.indigo-purple app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon {
  color: #3f51b5;
}
.indigo-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3,
.indigo-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3,
.indigo-purple app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3,
.indigo-purple app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3,
.indigo-purple app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3 {
  color: #3f51b5;
}
.indigo-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex,
.indigo-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex,
.indigo-purple app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex,
.indigo-purple app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex,
.indigo-purple app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex {
  color: #9c27b0 !important;
  text-shadow: -2px 0 0px #eeeeee, 0 -2px 0px #eeeeee, 2px 0 0px #eeeeee, 0 2px 0px #eeeeee, -2px -2px 0px #eeeeee, 2px 2px 0px #eeeeee, 2px -2px 0px #eeeeee, -2px 2px 0px white;
}
.indigo-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex,
.indigo-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex,
.indigo-purple app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex,
.indigo-purple app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex,
.indigo-purple app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex {
  color: rgba(0, 0, 0, 0.38) !important;
}
.indigo-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b,
.indigo-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b,
.indigo-purple app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b,
.indigo-purple app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b,
.indigo-purple app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex,
.indigo-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex,
.indigo-purple app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex,
.indigo-purple app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex,
.indigo-purple app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex {
  color: rgba(0, 0, 0, 0.38) !important;
}
.indigo-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex,
.indigo-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex,
.indigo-purple app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex,
.indigo-purple app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex,
.indigo-purple app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex {
  color: rgba(0, 0, 0, 0.38) !important;
}
.indigo-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover,
.indigo-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover,
.indigo-purple app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover,
.indigo-purple app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover,
.indigo-purple app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover {
  background: rgba(0, 0, 0, 0.04);
}
.indigo-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b,
.indigo-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b,
.indigo-purple app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b,
.indigo-purple app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b,
.indigo-purple app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.indigo-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.indigo-purple app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.indigo-purple app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.indigo-purple app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble {
  background: #3f51b5;
}
.indigo-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble,
.indigo-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble,
.indigo-purple app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble,
.indigo-purple app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble,
.indigo-purple app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble {
  background: #f44336;
}
.indigo-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble,
.indigo-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble,
.indigo-purple app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble,
.indigo-purple app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble,
.indigo-purple app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble {
  color: rgba(0, 0, 0, 0.38);
  background: rgba(0, 0, 0, 0.12);
}
.indigo-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble,
.indigo-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble,
.indigo-purple app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble,
.indigo-purple app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble,
.indigo-purple app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble {
  background: rgba(0, 0, 0, 0.38);
  color: white;
}
.indigo-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide,
.indigo-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide,
.indigo-purple app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide,
.indigo-purple app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide,
.indigo-purple app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide {
  border-color: rgba(0, 0, 0, 0.12);
}
.indigo-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble,
.indigo-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble,
.indigo-purple app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble,
.indigo-purple app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble,
.indigo-purple app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.indigo-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.indigo-purple app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.indigo-purple app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.indigo-purple app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble {
  color: #3f51b5;
}
.indigo-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble,
.indigo-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble,
.indigo-purple app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble,
.indigo-purple app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble,
.indigo-purple app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble {
  color: #f44336;
}
.indigo-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar,
.indigo-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar,
.indigo-purple app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar,
.indigo-purple app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar,
.indigo-purple app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar {
  background: #607D8B;
}
.indigo-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row,
.indigo-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row,
.indigo-purple app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row,
.indigo-purple app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row,
.indigo-purple app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row {
  color: white;
}
.indigo-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper,
.indigo-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper,
.indigo-purple app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper,
.indigo-purple app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper,
.indigo-purple app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper {
  background: #607D8B;
}
.indigo-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop,
.indigo-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop,
.indigo-purple app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop,
.indigo-purple app-share-person-dialog .dialog-content .document-template-container .add-docs-drop,
.indigo-purple app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body,
.indigo-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body,
.indigo-purple app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body,
.indigo-purple app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body,
.indigo-purple app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3,
.indigo-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3,
.indigo-purple app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3,
.indigo-purple app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3,
.indigo-purple app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3 {
  color: #9c27b0;
}
.indigo-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5,
.indigo-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5,
.indigo-purple app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5,
.indigo-purple app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5,
.indigo-purple app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5 {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5,
.indigo-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5,
.indigo-purple app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5,
.indigo-purple app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5,
.indigo-purple app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5 {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator,
.indigo-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator,
.indigo-purple app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator,
.indigo-purple app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator,
.indigo-purple app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background: #fafafa;
}
.indigo-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip,
.indigo-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip,
.indigo-purple app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip,
.indigo-purple app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip,
.indigo-purple app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip {
  background: white !important;
}
.indigo-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon,
.indigo-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon,
.indigo-purple app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon,
.indigo-purple app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon,
.indigo-purple app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon {
  color: #9c27b0 !important;
}
.indigo-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3,
.indigo-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3,
.indigo-purple app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3,
.indigo-purple app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3,
.indigo-purple app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3 {
  color: #9c27b0 !important;
}
.indigo-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5,
.indigo-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5,
.indigo-purple app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5,
.indigo-purple app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5,
.indigo-purple app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5 {
  color: rgba(0, 0, 0, 0.54) !important;
}
.indigo-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon,
.indigo-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon,
.indigo-purple app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon,
.indigo-purple app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon,
.indigo-purple app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon {
  color: #3f51b5 !important;
}
.indigo-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3,
.indigo-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3,
.indigo-purple app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3,
.indigo-purple app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3,
.indigo-purple app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3 {
  color: #3f51b5 !important;
}
.indigo-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5,
.indigo-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5,
.indigo-purple app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5,
.indigo-purple app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5,
.indigo-purple app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5 {
  color: #3f51b5 !important;
}
.indigo-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled,
.indigo-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled,
.indigo-purple app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled,
.indigo-purple app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled,
.indigo-purple app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled {
  background: #fafafa !important;
  color: rgba(0, 0, 0, 0.38);
}
.indigo-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove,
.indigo-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove,
.indigo-purple app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove,
.indigo-purple app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove,
.indigo-purple app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove,
.indigo-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove,
.indigo-purple app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove,
.indigo-purple app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove,
.indigo-purple app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove {
  color: rgba(0, 0, 0, 0.54) !important;
}
.indigo-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .preview-action,
.indigo-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .preview-action,
.indigo-purple app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .preview-action,
.indigo-purple app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .preview-action,
.indigo-purple app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .preview-action {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple .tyc .sticky {
  background: white;
}
.indigo-purple .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper:nth-child(odd) datatable-body-row {
  background: #fafafa;
}
.indigo-purple .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active * {
  color: white !important;
}
.indigo-purple .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active:hover .datatable-body-cell-label {
  background: #3f51b5;
}
.indigo-purple .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active:hover .cs-floating-action {
  background: #3f51b5 !important;
}
.indigo-purple .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active.row-cancel * {
  color: rgba(0, 0, 0, 0.54) !important;
}
.indigo-purple .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active.row-cancel .datatable-row-group {
  background: #607D8B !important;
}
.indigo-purple .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active.row-cancel:hover .datatable-body-cell-label {
  background: #607D8B;
}
.indigo-purple .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.row-cancel * {
  color: #b0bec5 !important;
  transition-property: color;
  transition-duration: 0.3s;
  transition-timing-function: linear;
}
.indigo-purple .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row:hover .datatable-body-cell-label button {
  background: #eeeeee;
}
.indigo-purple .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row datatable-body-cell.candidate-status_bar mat-progress-bar .mat-progress-bar-buffer {
  background-color: #eeeeee;
}
.indigo-purple .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row datatable-body-cell.candidate-status_bar .status_bar-count {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-purple .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row datatable-body-cell.candidate-status_bar .inactive {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-purple .cs-grid-test .info {
  background: white;
}
.indigo-purple .cs-grid-test .selected-column {
  background: white;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
}
.indigo-purple .cs-grid-test .selection-cell .datatable-body-cell.active {
  background: #3f51b5 !important;
  color: white !important;
}
.indigo-purple .cs-grid-test nav {
  background: white;
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 13px 19px 2px rgba(0, 0, 0, 0.14), 0 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.indigo-purple .cs-grid-test .ngx-datatable.material {
  background: white;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.indigo-purple .cs-grid-test .ngx-datatable.material.striped .datatable-row-odd {
  background: #fafafa;
}
.indigo-purple .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active, .indigo-purple .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active .datatable-row-group, .indigo-purple .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active, .indigo-purple .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active .datatable-row-group, .indigo-purple .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active, .indigo-purple .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active .datatable-row-group {
  background: #3f51b5;
  color: white;
}
.indigo-purple .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active:hover, .indigo-purple .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active:hover .datatable-row-group, .indigo-purple .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active:hover, .indigo-purple .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active:hover .datatable-row-group, .indigo-purple .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active:hover, .indigo-purple .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active:hover .datatable-row-group {
  background: #3f51b5;
  color: white;
}
.indigo-purple .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active:focus, .indigo-purple .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active:focus .datatable-row-group, .indigo-purple .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active:focus, .indigo-purple .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active:focus .datatable-row-group, .indigo-purple .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active:focus, .indigo-purple .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active:focus .datatable-row-group {
  background: #3f51b5;
  color: white;
}
.indigo-purple .cs-grid-test .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover, .indigo-purple .cs-grid-test .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: rgba(0, 0, 0, 0.04);
}
.indigo-purple .cs-grid-test .ngx-datatable.material:not(.cell-selection) .datatable-body-row:focus, .indigo-purple .cs-grid-test .ngx-datatable.material:not(.cell-selection) .datatable-body-row:focus .datatable-row-group {
  background: rgba(0, 0, 0, 0.04);
}
.indigo-purple .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell:hover, .indigo-purple .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell:hover .datatable-row-group {
  background: rgba(0, 0, 0, 0.04);
}
.indigo-purple .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell:focus, .indigo-purple .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell:focus .datatable-row-group {
  background: rgba(0, 0, 0, 0.04);
}
.indigo-purple .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active, .indigo-purple .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active .datatable-row-group {
  background: #3f51b5;
  color: white;
}
.indigo-purple .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active:hover, .indigo-purple .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active:hover .datatable-row-group {
  background: #3f51b5;
  color: white;
}
.indigo-purple .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active:focus, .indigo-purple .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active:focus .datatable-row-group {
  background: #3f51b5;
  color: white;
}
.indigo-purple .cs-grid-test .ngx-datatable.material .datatable-header .datatable-row-left,
.indigo-purple .cs-grid-test .ngx-datatable.material .datatable-body .datatable-row-left {
  background-color: white;
}
.indigo-purple .cs-grid-test .ngx-datatable.material .datatable-header .datatable-row-right,
.indigo-purple .cs-grid-test .ngx-datatable.material .datatable-body .datatable-row-right {
  background-color: white;
}
.indigo-purple .cs-grid-test .ngx-datatable.material .datatable-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.38);
}
.indigo-purple .cs-grid-test .ngx-datatable.material .datatable-header .datatable-header-cell {
  background-color: white;
  color: rgba(0, 0, 0, 0.54);
}
.indigo-purple .cs-grid-test .ngx-datatable.material .datatable-header .resize-handle {
  border-right: solid 1px rgba(0, 0, 0, 0.04);
}
.indigo-purple .cs-grid-test .ngx-datatable.material .datatable-body .datatable-row-detail {
  background: #eeeeee;
}
.indigo-purple .cs-grid-test .ngx-datatable.material .datatable-body .datatable-group-header {
  background: #eeeeee;
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
  border-top: solid 1px rgba(0, 0, 0, 0.12);
}
.indigo-purple .cs-grid-test .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple .cs-grid-test .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-group-cell {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple .cs-grid-test .ngx-datatable.material .datatable-body .progress-linear .container {
  background-color: white;
}
.indigo-purple .cs-grid-test .ngx-datatable.material .datatable-body .progress-linear .container .bar {
  background-color: #3f51b5;
}
.indigo-purple .cs-grid-test .ngx-datatable.material .datatable-footer {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-purple .cs-grid-test .ngx-datatable.material .datatable-footer .datatable-pager li.disabled a {
  color: rgba(0, 0, 0, 0.38) !important;
  background-color: rgba(0, 0, 0, 0) !important;
}
.indigo-purple .cs-grid-test .ngx-datatable.material .datatable-footer .datatable-pager li.active a {
  background-color: #3f51b5;
}
.indigo-purple .cs-grid-test .ngx-datatable.material .datatable-footer .datatable-pager a {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-purple .cs-grid-test .ngx-datatable.material .datatable-footer .datatable-pager a:hover {
  color: rgba(0, 0, 0, 0.04);
  background-color: rgba(0, 0, 0, 0.04);
}
.indigo-purple .cs-grid-test .ngx-datatable.material .datatable-summary-row .datatable-body-row {
  background-color: #fafafa;
}
.indigo-purple .cs-grid-test .ngx-datatable.material .datatable-summary-row .datatable-body-row:hover {
  background-color: #fafafa;
}
.indigo-purple .cs-grid-test .datatable-checkbox input[type=checkbox]:after {
  background: white;
}
.indigo-purple .view-container .view-toolbar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal.new.image-opened .help-content img {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card {
  background: white;
}
.indigo-purple app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container .aditional-info {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-purple app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container .mat-card-footer {
  background: white;
}
.indigo-purple app-help-index mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card {
  background: white;
}
.indigo-purple app-help-index mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card .mat-card-footer {
  background: white;
}
.indigo-purple .cropper .employee-title .title-text {
  color: white !important;
}
.indigo-purple .cropper app-cs-upload-cropper-control .preview h5 {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-purple app-file-document-upload .preview-container {
  background: #607D8B !important;
}
.indigo-purple app-file-document-upload .preview-container h4 {
  color: #eeeeee;
}
.indigo-purple .preview-container {
  background: #607D8B !important;
}
.indigo-purple .preview-container h4 {
  color: #eeeeee;
}
.indigo-purple .clean-autocomple mat-list {
  background: white;
}
.indigo-purple .clean-autocomple mat-list .mat-list-item:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.indigo-purple .clean-autocomple mat-list .mat-list-item .mat-list-item-content .mat-list-text {
  color: #3f51b5;
}
.indigo-purple app-leave .inbox-toolbar {
  background: #fafafa;
}
.indigo-purple app-leave .mat-tab-body-wrapper {
  position: relative;
  overflow: hidden;
  display: flex;
  transition: height 500ms cubic-bezier(0.35, 0, 0.25, 1);
  height: 95% !important;
}
.indigo-purple app-leave .leave_container {
  background: #fafafa;
}
.indigo-purple app-leave .leave_container .leave_items .leave_items-item.ok .content .leave_item-status_icon {
  color: #009688;
}
.indigo-purple app-leave .leave_container .leave_items .leave_items-item.not-ok .content .leave_item-status_icon {
  color: #9C27B0;
}
.indigo-purple app-leave .leave_container .leave_items .leave_items-item.pending .content .leave_item-status_icon {
  color: #FF9800;
}
.indigo-purple app-leave .leave_container .leave_items .leave_items-item.draft .content .leave_item-status_icon {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-purple app-leave .leave_container .leave_items .leave_items-item.cancel .content .leave_item-status_icon {
  color: #795548;
}
.indigo-purple app-leave .leave_container .leave_items .leave_items-item .content:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.indigo-purple app-leave .leave_container .leave_items .leave_items-item .content.selected {
  border-left: 6px solid #9c27b0 !important;
}
.indigo-purple app-leave .leave_container .leave_items .leave_items-item .content.selected mat-icon {
  color: #9c27b0 !important;
}
.indigo-purple app-leave .leave_container .leave_items .leave_items-item .content.selected .item-data {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-purple app-leave .leave_container .leave_items .leave_items-item .content .leave_item-icon {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-purple app-leave .leave_container .leave_items .leave_items-item .content .item-data {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-purple app-leave .leave_container .window-spinner {
  background: #fafafa !important;
}
.indigo-purple app-leave-detail .leave_detail .leave_detail-title .info h3,
.indigo-purple app-leave-detail .leave_detail .leave_detail-title .info mat-icon {
  color: #3f51b5;
}
.indigo-purple app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .ok .large-icon {
  background: #b2dfdb;
  color: #009688;
}
.indigo-purple app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .ok h2 {
  color: #009688;
}
.indigo-purple app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .not-ok .large-icon {
  background: #e1bee7;
  color: #9C27B0;
}
.indigo-purple app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .not-ok h2 {
  color: #9C27B0;
}
.indigo-purple app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .pending .large-icon {
  background: #FFE0B2;
  color: #FF9800;
}
.indigo-purple app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .pending h2 {
  color: #FF9800;
}
.indigo-purple app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .draft .large-icon {
  background: #f2f2f2;
  color: #dedede;
}
.indigo-purple app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .draft h2 {
  color: #dedede;
}
.indigo-purple app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .cancelled .large-icon {
  background: #BCAAA4;
  color: #795548;
}
.indigo-purple app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .cancelled h2 {
  color: #795548;
}
.indigo-purple app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .actions {
  padding: 16px;
  height: 96px;
  column-gap: 16px;
}
.indigo-purple app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .actions button {
  height: 100%;
  border-radius: 40px;
}
.indigo-purple app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .actions button.ok {
  background: #009688 !important;
  color: white;
}
.indigo-purple app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .actions button.not-ok {
  color: #9C27B0 !important;
}
.indigo-purple app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning mat-basic-chip {
  background: #eeeeee !important;
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
  border-radius: 40px !important;
  height: 35px !important;
}
.indigo-purple app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning mat-basic-chip.signed-ok {
  border-color: #009688 !important;
  color: #009688 !important;
}
.indigo-purple app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning mat-basic-chip.signed-not-ok {
  border-color: #9C27B0 !important;
  color: #9C27B0 !important;
}
.indigo-purple app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning mat-basic-chip.draft {
  border-color: #dedede !important;
  color: #dedede !important;
}
.indigo-purple app-leave-request-detail .load-window {
  background: #fafafa;
}
.indigo-purple app-leave-request-detail form .leave_detail-container {
  background: #fafafa;
}
.indigo-purple app-leave-request-detail form .leave_detail-container .toolbar .header {
  color: #3f51b5;
}
.indigo-purple app-leave-request-detail form .leave_detail-container .toolbar .header .text h3 {
  color: #3f51b5;
}
.indigo-purple app-leave-request-detail form .leave_detail-container .toolbar .header .text h5 {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-purple app-leave-request-detail form .leave_detail-container .leave_detail-container-content .leave_resume {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple app-leave-request-detail form .leave_detail-container .leave_detail-container-content .line h6 {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-purple app-leave-request-detail form .leave_detail-container .actions button.ok {
  background: #009688 !important;
  color: white;
}
.indigo-purple app-leave-request-detail form .leave_detail-container .actions button.not-ok {
  color: #9C27B0 !important;
}
.indigo-purple .new_leave_modal-steps {
  display: unset !important;
}
.indigo-purple .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button .mat-radio-label .mat-radio-label-content .side-text {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-purple .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button .mat-radio-label .mat-radio-label-content .side-text.muted {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-purple .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button .mat-radio-label .mat-radio-label-content .accent mat-icon,
.indigo-purple .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button .mat-radio-label .mat-radio-label-content .accent .main-text {
  color: #9c27b0;
}
.indigo-purple .config_leave-container .config_leave-card {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple .config_leave-container .config_leave-card .config_leave-auto_chip mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #fafafa !important;
}
.indigo-purple .config_leave-container .config_leave-card .config_leave-auto_chip mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-purple .config_leave-container .config_leave-card .config_leave-auto_chip mat-form-field .mat-form-field-flex {
  background: #fafafa;
}
.indigo-purple *[color=success] {
  color: #4DB6AC;
}
.indigo-purple .primary {
  color: #3f51b5 !important;
}
.indigo-purple .accent {
  color: #9c27b0 !important;
}
.indigo-purple app-lsd .icon-complex mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.indigo-purple app-lsd .icon-complex mat-icon.complex {
  color: #3f51b5 !important;
}
.indigo-purple app-lsd .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.indigo-purple app-lsd .work-space .main .load-window {
  background: white !important;
}
.indigo-purple app-lsd .work-space .main.side-open {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple app-lsd .work-space .main .container .item {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple app-lsd .work-space .main .container .item h5 {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-purple app-lsd .work-space .main .container .item h3 {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple app-lsd .work-space .main .container .item:hover {
  background: rgba(0, 0, 0, 0.04);
}
.indigo-purple app-lsd .work-space .main .container .item.selected {
  background: #3f51b5;
  color: white !important;
}
.indigo-purple app-lsd .work-space .main .container .item.selected h3 {
  color: white !important;
}
.indigo-purple app-lsd .work-space .main .container .item.selected.pending .status {
  background: rgba(0, 0, 0, 0.12) !important;
  color: #ffe082;
}
.indigo-purple app-lsd .work-space .main .container .item.selected.ok .status {
  background: rgba(0, 0, 0, 0.12) !important;
  color: #a5d6a7;
}
.indigo-purple app-lsd .work-space .main .container .item.selected.not-ok .status {
  background: rgba(0, 0, 0, 0.12) !important;
  color: #e57373;
}
.indigo-purple app-lsd .work-space .main .main-action {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple app-lsd .work-space .side {
  background: #fafafa;
}
.indigo-purple app-lsd .work-space .side .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #fafafa, 0 -2px 0px #fafafa, 2px 0 0px #fafafa, 0 2px 0px #fafafa, -2px -2px 0px #fafafa, 2px 2px 0px #fafafa, 2px -2px 0px #fafafa, -2px 2px 0px #fafafa;
}
.indigo-purple app-lsd .work-space .side *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.indigo-purple app-lsd .work-space .side *::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}
.indigo-purple app-lsd .work-space .side .edition-line {
  background: #eeeeee;
}
.indigo-purple app-lsd .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #fafafa !important;
}
.indigo-purple app-lsd .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-purple app-lsd .work-space .side mat-form-field .mat-form-field-flex {
  background: #fafafa;
}
.indigo-purple app-lsd .work-space .side mat-checkbox:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.indigo-purple app-lsd .work-space .side .hint.all {
  color: #9c27b0;
}
.indigo-purple app-lsd .work-space .side .toolbar .header {
  color: #3f51b5;
}
.indigo-purple app-lsd .work-space .side .toolbar .header h3 {
  color: #3f51b5;
}
.indigo-purple app-lsd .work-space .side .container .doc-resume {
  background: #3f51b5;
}
.indigo-purple app-lsd .work-space .side .container .doc-resume .line {
  color: white;
}
.indigo-purple app-lsd .work-space .side .container .doc-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.1);
}
.indigo-purple app-lsd .work-space .side .container .doc-resume .line.editable button {
  background: #3f51b5;
}
.indigo-purple app-lsd .work-space .side .container .section-title {
  color: #3f51b5;
}
.indigo-purple app-lsd .work-space .side .container .section-resume {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple app-lsd .work-space .side .container .section-resume .line.editable:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.indigo-purple app-lsd .work-space .side .container .section-resume .line.editable:hover .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px white, 0 -2px 0px white, 2px 0 0px white, 0 2px 0px white, -2px -2px 0px white, 2px 2px 0px white, 2px -2px 0px white, -2px 2px 0px white !important;
}
.indigo-purple app-lsd .work-space .side .container .section-resume .line.editable button {
  background: #fafafa;
}
.indigo-purple app-lsd .work-space .side .container .section-resume .line .header .icon-complex mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.indigo-purple app-lsd .work-space .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #3f51b5 !important;
}
.indigo-purple app-lsd .work-space .side .container .section-resume .line .header mat-icon:not(.complex) {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-purple app-lsd .work-space .side .container .section-resume .line .header .info h4 {
  color: #3f51b5;
}
.indigo-purple app-lsd .work-space .side .container .section-resume .line ul {
  color: #3f51b5;
}
.indigo-purple app-lsd .work-space .side .container .section-resume .line.head {
  background: #eeeeee;
}
.indigo-purple app-lsd .work-space .side .container .section-resume .line.head mat-icon {
  color: #9c27b0;
}
.indigo-purple app-lsd .work-space .side .container .section-resume .line.head h4 {
  color: white;
}
.indigo-purple app-lsd .work-space .side.edition .toolbar .header {
  color: white;
}
.indigo-purple app-lsd .work-space .side.edition .toolbar .header mat-icon {
  color: #9c27b0;
}
.indigo-purple app-lsd .work-space .side.edition .toolbar .header h3 {
  color: #9c27b0;
}
.indigo-purple .dialog-content {
  max-height: 90vh;
}
.indigo-purple .dialog-content mat-toolbar {
  background: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple .dialog-content .dialog-container .document-template-container .document-selection mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #fafafa !important;
}
.indigo-purple .dialog-content .dialog-container .document-template-container .document-selection mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-purple .dialog-content .dialog-container .document-template-container .document-selection mat-form-field .mat-form-field-flex {
  background: #fafafa;
}
.indigo-purple .dialog-content .dialog-container .document-template-container .document-selection app-upload-form .file-upload-list .mat-subheader::before {
  color: #3f51b5;
}
.indigo-purple .dialog-content .dialog-container .view-container {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-purple .dialog-content .form-actions {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.indigo-purple.dark .mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-purple.dark .mat-badge-small .mat-badge-content {
  font-size: 9px;
}
.indigo-purple.dark .mat-badge-large .mat-badge-content {
  font-size: 24px;
}
.indigo-purple.dark .mat-h1,
.indigo-purple.dark .mat-headline,
.indigo-purple.dark .mat-typography .mat-h1,
.indigo-purple.dark .mat-typography .mat-headline,
.indigo-purple.dark .mat-typography h1 {
  font: 400 24px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.indigo-purple.dark .mat-h2,
.indigo-purple.dark .mat-title,
.indigo-purple.dark .mat-typography .mat-h2,
.indigo-purple.dark .mat-typography .mat-title,
.indigo-purple.dark .mat-typography h2 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.indigo-purple.dark .mat-h3,
.indigo-purple.dark .mat-subheading-2,
.indigo-purple.dark .mat-typography .mat-h3,
.indigo-purple.dark .mat-typography .mat-subheading-2,
.indigo-purple.dark .mat-typography h3 {
  font: 400 16px / 28px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.indigo-purple.dark .mat-h4,
.indigo-purple.dark .mat-subheading-1,
.indigo-purple.dark .mat-typography .mat-h4,
.indigo-purple.dark .mat-typography .mat-subheading-1,
.indigo-purple.dark .mat-typography h4 {
  font: 400 15px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.indigo-purple.dark .mat-h5,
.indigo-purple.dark .mat-typography .mat-h5,
.indigo-purple.dark .mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}
.indigo-purple.dark .mat-h6,
.indigo-purple.dark .mat-typography .mat-h6,
.indigo-purple.dark .mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}
.indigo-purple.dark .mat-body-strong,
.indigo-purple.dark .mat-body-2,
.indigo-purple.dark .mat-typography .mat-body-strong,
.indigo-purple.dark .mat-typography .mat-body-2 {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.indigo-purple.dark .mat-body,
.indigo-purple.dark .mat-body-1,
.indigo-purple.dark .mat-typography .mat-body,
.indigo-purple.dark .mat-typography .mat-body-1,
.indigo-purple.dark .mat-typography {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.indigo-purple.dark .mat-body p,
.indigo-purple.dark .mat-body-1 p,
.indigo-purple.dark .mat-typography .mat-body p,
.indigo-purple.dark .mat-typography .mat-body-1 p,
.indigo-purple.dark .mat-typography p {
  margin: 0 0 12px;
}
.indigo-purple.dark .mat-small,
.indigo-purple.dark .mat-caption,
.indigo-purple.dark .mat-typography .mat-small,
.indigo-purple.dark .mat-typography .mat-caption {
  font: 400 12px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.indigo-purple.dark .mat-display-4,
.indigo-purple.dark .mat-typography .mat-display-4 {
  font: 300 112px / 112px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}
.indigo-purple.dark .mat-display-3,
.indigo-purple.dark .mat-typography .mat-display-3 {
  font: 400 56px / 56px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}
.indigo-purple.dark .mat-display-2,
.indigo-purple.dark .mat-typography .mat-display-2 {
  font: 400 45px / 48px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}
.indigo-purple.dark .mat-display-1,
.indigo-purple.dark .mat-typography .mat-display-1 {
  font: 400 34px / 40px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}
.indigo-purple.dark .mat-bottom-sheet-container {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.indigo-purple.dark .mat-button, .indigo-purple.dark .mat-raised-button, .indigo-purple.dark .mat-icon-button, .indigo-purple.dark .mat-stroked-button,
.indigo-purple.dark .mat-flat-button, .indigo-purple.dark .mat-fab, .indigo-purple.dark .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.indigo-purple.dark .mat-button-toggle {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-purple.dark .mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-purple.dark .mat-card-title {
  font-size: 24px;
  font-weight: 500;
}
.indigo-purple.dark .mat-card-header .mat-card-title {
  font-size: 20px;
}
.indigo-purple.dark .mat-card-subtitle,
.indigo-purple.dark .mat-card-content {
  font-size: 14px;
}
.indigo-purple.dark .mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-purple.dark .mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}
.indigo-purple.dark .mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.indigo-purple.dark .mat-chip .mat-chip-trailing-icon.mat-icon,
.indigo-purple.dark .mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}
.indigo-purple.dark .mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-purple.dark .mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}
.indigo-purple.dark .mat-cell, .indigo-purple.dark .mat-footer-cell {
  font-size: 14px;
}
.indigo-purple.dark .mat-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-purple.dark .mat-calendar-body {
  font-size: 13px;
}
.indigo-purple.dark .mat-calendar-body-label,
.indigo-purple.dark .mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}
.indigo-purple.dark .mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}
.indigo-purple.dark .mat-dialog-title {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.indigo-purple.dark .mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400;
}
.indigo-purple.dark .mat-expansion-panel-content {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.indigo-purple.dark .mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.indigo-purple.dark .mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}
.indigo-purple.dark .mat-form-field-prefix .mat-icon,
.indigo-purple.dark .mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.indigo-purple.dark .mat-form-field-prefix .mat-icon-button,
.indigo-purple.dark .mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.indigo-purple.dark .mat-form-field-prefix .mat-icon-button .mat-icon,
.indigo-purple.dark .mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}
.indigo-purple.dark .mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}
.indigo-purple.dark .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.indigo-purple.dark .mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34369em) scale(0.75);
  width: 133.3333933333%;
}
.indigo-purple.dark .mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34368em) scale(0.75);
  width: 133.3334033333%;
}
.indigo-purple.dark .mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}
.indigo-purple.dark .mat-form-field-label {
  top: 1.34375em;
}
.indigo-purple.dark .mat-form-field-underline {
  bottom: 1.34375em;
}
.indigo-purple.dark .mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}
.indigo-purple.dark .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.indigo-purple.dark .mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.indigo-purple.dark .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.indigo-purple.dark .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00118px);
  width: 133.3335133333%;
}
.indigo-purple.dark .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00119px);
  width: 133.3335233333%;
}
.indigo-purple.dark .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.0012px);
  width: 133.3335333333%;
}
.indigo-purple.dark .mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.indigo-purple.dark .mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.indigo-purple.dark .mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}
@media print {
  .indigo-purple.dark .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.indigo-purple.dark .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28104em) scale(0.75);
  }
  .indigo-purple.dark .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28103em) scale(0.75);
  }
  .indigo-purple.dark .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28102em) scale(0.75);
  }
}
.indigo-purple.dark .mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.indigo-purple.dark .mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.indigo-purple.dark .mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.indigo-purple.dark .mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59369em) scale(0.75);
  width: 133.3333933333%;
}
.indigo-purple.dark .mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59368em) scale(0.75);
  width: 133.3334033333%;
}
.indigo-purple.dark .mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.indigo-purple.dark .mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.indigo-purple.dark .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.indigo-purple.dark .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59369em) scale(0.75);
  width: 133.3333933333%;
}
.indigo-purple.dark .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59368em) scale(0.75);
  width: 133.3334033333%;
}
.indigo-purple.dark .mat-grid-tile-header,
.indigo-purple.dark .mat-grid-tile-footer {
  font-size: 14px;
}
.indigo-purple.dark .mat-grid-tile-header .mat-line,
.indigo-purple.dark .mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.indigo-purple.dark .mat-grid-tile-header .mat-line:nth-child(n+2),
.indigo-purple.dark .mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.indigo-purple.dark input.mat-input-element {
  margin-top: -0.0625em;
}
.indigo-purple.dark .mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
}
.indigo-purple.dark .mat-paginator,
.indigo-purple.dark .mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
}
.indigo-purple.dark .mat-radio-button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-purple.dark .mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-purple.dark .mat-select-trigger {
  height: 1.125em;
}
.indigo-purple.dark .mat-slide-toggle-content {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-purple.dark .mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}
.indigo-purple.dark .mat-stepper-vertical, .indigo-purple.dark .mat-stepper-horizontal {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-purple.dark .mat-step-label {
  font-size: 14px;
  font-weight: 400;
}
.indigo-purple.dark .mat-step-sub-label-error {
  font-weight: normal;
}
.indigo-purple.dark .mat-step-label-error {
  font-size: 14px;
}
.indigo-purple.dark .mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}
.indigo-purple.dark .mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-purple.dark .mat-tab-label, .indigo-purple.dark .mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.indigo-purple.dark .mat-toolbar,
.indigo-purple.dark .mat-toolbar h1,
.indigo-purple.dark .mat-toolbar h2,
.indigo-purple.dark .mat-toolbar h3,
.indigo-purple.dark .mat-toolbar h4,
.indigo-purple.dark .mat-toolbar h5,
.indigo-purple.dark .mat-toolbar h6 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0;
}
.indigo-purple.dark .mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.indigo-purple.dark .mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.indigo-purple.dark .mat-list-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-purple.dark .mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-purple.dark .mat-list-base .mat-list-item {
  font-size: 16px;
}
.indigo-purple.dark .mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.indigo-purple.dark .mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.indigo-purple.dark .mat-list-base .mat-list-option {
  font-size: 16px;
}
.indigo-purple.dark .mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.indigo-purple.dark .mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.indigo-purple.dark .mat-list-base .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.indigo-purple.dark .mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.indigo-purple.dark .mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.indigo-purple.dark .mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.indigo-purple.dark .mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.indigo-purple.dark .mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.indigo-purple.dark .mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.indigo-purple.dark .mat-list-base[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}
.indigo-purple.dark .mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
}
.indigo-purple.dark .mat-optgroup-label {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.indigo-purple.dark .mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
}
.indigo-purple.dark .mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}
.indigo-purple.dark .mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-purple.dark .mat-tree-node,
.indigo-purple.dark .mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}
.indigo-purple.dark .mat-ripple {
  overflow: hidden;
  position: relative;
}
.indigo-purple.dark .mat-ripple:not(:empty) {
  transform: translateZ(0);
}
.indigo-purple.dark .mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}
.indigo-purple.dark .mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .indigo-purple.dark .mat-ripple-element {
  display: none;
}

.indigo-purple.dark .cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .indigo-purple.dark .cdk-visually-hidden {
  left: auto;
  right: 0;
}
.indigo-purple.dark .cdk-overlay-container, .indigo-purple.dark .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.indigo-purple.dark .cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.indigo-purple.dark .cdk-overlay-container:empty {
  display: none;
}
.indigo-purple.dark .cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}
.indigo-purple.dark .cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}
.indigo-purple.dark .cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.indigo-purple.dark .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .indigo-purple.dark .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}
.indigo-purple.dark .cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}
.indigo-purple.dark .cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.indigo-purple.dark .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}
.indigo-purple.dark .cdk-overlay-backdrop-noop-animation {
  transition: none;
}
.indigo-purple.dark .cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}
.indigo-purple.dark .cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}
.indigo-purple.dark textarea.cdk-textarea-autosize {
  resize: none;
}
.indigo-purple.dark textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}
.indigo-purple.dark textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}
@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.indigo-purple.dark .cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}
.indigo-purple.dark .cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}
.indigo-purple.dark .mat-focus-indicator {
  position: relative;
}
.indigo-purple.dark .mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.indigo-purple.dark .mat-focus-indicator:focus::before {
  content: "";
}
.cdk-high-contrast-active .indigo-purple.dark {
  --mat-focus-indicator-display: block;
}

.indigo-purple.dark .mat-mdc-focus-indicator {
  position: relative;
}
.indigo-purple.dark .mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.indigo-purple.dark .mat-mdc-focus-indicator:focus::before {
  content: "";
}
.cdk-high-contrast-active .indigo-purple.dark {
  --mat-mdc-focus-indicator-display: block;
}

.indigo-purple.dark .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.indigo-purple.dark .mat-option {
  color: white;
}
.indigo-purple.dark .mat-option:hover:not(.mat-option-disabled), .indigo-purple.dark .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-purple.dark .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-purple.dark .mat-option.mat-active {
  background: rgba(255, 255, 255, 0.04);
  color: white;
}
.indigo-purple.dark .mat-option.mat-option-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-purple.dark .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #9fa8da;
}
.indigo-purple.dark .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ce93d8;
}
.indigo-purple.dark .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #e57373;
}
.indigo-purple.dark .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-purple.dark .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-purple.dark .mat-pseudo-checkbox {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-purple.dark .mat-pseudo-checkbox::after {
  color: #303030;
}
.indigo-purple.dark .mat-pseudo-checkbox-disabled {
  color: #686868;
}
.indigo-purple.dark .mat-primary .mat-pseudo-checkbox-checked,
.indigo-purple.dark .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #9fa8da;
}
.indigo-purple.dark .mat-pseudo-checkbox-checked,
.indigo-purple.dark .mat-pseudo-checkbox-indeterminate,
.indigo-purple.dark .mat-accent .mat-pseudo-checkbox-checked,
.indigo-purple.dark .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #ce93d8;
}
.indigo-purple.dark .mat-warn .mat-pseudo-checkbox-checked,
.indigo-purple.dark .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #e57373;
}
.indigo-purple.dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.indigo-purple.dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #686868;
}
.indigo-purple.dark .mat-app-background, .indigo-purple.dark.mat-app-background {
  background-color: #303030;
  color: white;
}
.indigo-purple.dark .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.indigo-purple.dark .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.indigo-purple.dark .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.indigo-purple.dark .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.indigo-purple.dark .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.indigo-purple.dark .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.indigo-purple.dark .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.indigo-purple.dark .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.indigo-purple.dark .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.indigo-purple.dark .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.indigo-purple.dark .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.indigo-purple.dark .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.indigo-purple.dark .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.indigo-purple.dark .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.indigo-purple.dark .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.indigo-purple.dark .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.indigo-purple.dark .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.indigo-purple.dark .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.indigo-purple.dark .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.indigo-purple.dark .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.indigo-purple.dark .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.indigo-purple.dark .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.indigo-purple.dark .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.indigo-purple.dark .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.indigo-purple.dark .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.indigo-purple.dark .mat-autocomplete-panel {
  background: #424242;
  color: white;
}
.indigo-purple.dark .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.indigo-purple.dark .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: #424242;
}
.indigo-purple.dark .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: white;
}
.indigo-purple.dark .mat-badge-content {
  color: rgba(0, 0, 0, 0.87);
  background: #9fa8da;
}
.cdk-high-contrast-active .indigo-purple.dark .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.indigo-purple.dark .mat-badge-accent .mat-badge-content {
  background: #ce93d8;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark .mat-badge-warn .mat-badge-content {
  color: rgba(0, 0, 0, 0.87);
  background: #e57373;
}
.indigo-purple.dark .mat-badge-disabled .mat-badge-content {
  background: #6e6e6e;
  color: rgba(255, 255, 255, 0.5);
}
.indigo-purple.dark .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.indigo-purple.dark .mat-button, .indigo-purple.dark .mat-icon-button, .indigo-purple.dark .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.indigo-purple.dark .mat-button.mat-primary, .indigo-purple.dark .mat-icon-button.mat-primary, .indigo-purple.dark .mat-stroked-button.mat-primary {
  color: #9fa8da;
}
.indigo-purple.dark .mat-button.mat-accent, .indigo-purple.dark .mat-icon-button.mat-accent, .indigo-purple.dark .mat-stroked-button.mat-accent {
  color: #ce93d8;
}
.indigo-purple.dark .mat-button.mat-warn, .indigo-purple.dark .mat-icon-button.mat-warn, .indigo-purple.dark .mat-stroked-button.mat-warn {
  color: #e57373;
}
.indigo-purple.dark .mat-button.mat-primary.mat-button-disabled, .indigo-purple.dark .mat-button.mat-accent.mat-button-disabled, .indigo-purple.dark .mat-button.mat-warn.mat-button-disabled, .indigo-purple.dark .mat-button.mat-button-disabled.mat-button-disabled, .indigo-purple.dark .mat-icon-button.mat-primary.mat-button-disabled, .indigo-purple.dark .mat-icon-button.mat-accent.mat-button-disabled, .indigo-purple.dark .mat-icon-button.mat-warn.mat-button-disabled, .indigo-purple.dark .mat-icon-button.mat-button-disabled.mat-button-disabled, .indigo-purple.dark .mat-stroked-button.mat-primary.mat-button-disabled, .indigo-purple.dark .mat-stroked-button.mat-accent.mat-button-disabled, .indigo-purple.dark .mat-stroked-button.mat-warn.mat-button-disabled, .indigo-purple.dark .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.indigo-purple.dark .mat-button.mat-primary .mat-button-focus-overlay, .indigo-purple.dark .mat-icon-button.mat-primary .mat-button-focus-overlay, .indigo-purple.dark .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #9fa8da;
}
.indigo-purple.dark .mat-button.mat-accent .mat-button-focus-overlay, .indigo-purple.dark .mat-icon-button.mat-accent .mat-button-focus-overlay, .indigo-purple.dark .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #ce93d8;
}
.indigo-purple.dark .mat-button.mat-warn .mat-button-focus-overlay, .indigo-purple.dark .mat-icon-button.mat-warn .mat-button-focus-overlay, .indigo-purple.dark .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #e57373;
}
.indigo-purple.dark .mat-button.mat-button-disabled .mat-button-focus-overlay, .indigo-purple.dark .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .indigo-purple.dark .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.indigo-purple.dark .mat-button .mat-ripple-element, .indigo-purple.dark .mat-icon-button .mat-ripple-element, .indigo-purple.dark .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.indigo-purple.dark .mat-button-focus-overlay {
  background: white;
}
.indigo-purple.dark .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark .mat-flat-button, .indigo-purple.dark .mat-raised-button, .indigo-purple.dark .mat-fab, .indigo-purple.dark .mat-mini-fab {
  color: white;
  background-color: #424242;
}
.indigo-purple.dark .mat-flat-button.mat-primary, .indigo-purple.dark .mat-raised-button.mat-primary, .indigo-purple.dark .mat-fab.mat-primary, .indigo-purple.dark .mat-mini-fab.mat-primary {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark .mat-flat-button.mat-accent, .indigo-purple.dark .mat-raised-button.mat-accent, .indigo-purple.dark .mat-fab.mat-accent, .indigo-purple.dark .mat-mini-fab.mat-accent {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark .mat-flat-button.mat-warn, .indigo-purple.dark .mat-raised-button.mat-warn, .indigo-purple.dark .mat-fab.mat-warn, .indigo-purple.dark .mat-mini-fab.mat-warn {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark .mat-flat-button.mat-primary.mat-button-disabled, .indigo-purple.dark .mat-flat-button.mat-accent.mat-button-disabled, .indigo-purple.dark .mat-flat-button.mat-warn.mat-button-disabled, .indigo-purple.dark .mat-flat-button.mat-button-disabled.mat-button-disabled, .indigo-purple.dark .mat-raised-button.mat-primary.mat-button-disabled, .indigo-purple.dark .mat-raised-button.mat-accent.mat-button-disabled, .indigo-purple.dark .mat-raised-button.mat-warn.mat-button-disabled, .indigo-purple.dark .mat-raised-button.mat-button-disabled.mat-button-disabled, .indigo-purple.dark .mat-fab.mat-primary.mat-button-disabled, .indigo-purple.dark .mat-fab.mat-accent.mat-button-disabled, .indigo-purple.dark .mat-fab.mat-warn.mat-button-disabled, .indigo-purple.dark .mat-fab.mat-button-disabled.mat-button-disabled, .indigo-purple.dark .mat-mini-fab.mat-primary.mat-button-disabled, .indigo-purple.dark .mat-mini-fab.mat-accent.mat-button-disabled, .indigo-purple.dark .mat-mini-fab.mat-warn.mat-button-disabled, .indigo-purple.dark .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.indigo-purple.dark .mat-flat-button.mat-primary, .indigo-purple.dark .mat-raised-button.mat-primary, .indigo-purple.dark .mat-fab.mat-primary, .indigo-purple.dark .mat-mini-fab.mat-primary {
  background-color: #9fa8da;
}
.indigo-purple.dark .mat-flat-button.mat-accent, .indigo-purple.dark .mat-raised-button.mat-accent, .indigo-purple.dark .mat-fab.mat-accent, .indigo-purple.dark .mat-mini-fab.mat-accent {
  background-color: #ce93d8;
}
.indigo-purple.dark .mat-flat-button.mat-warn, .indigo-purple.dark .mat-raised-button.mat-warn, .indigo-purple.dark .mat-fab.mat-warn, .indigo-purple.dark .mat-mini-fab.mat-warn {
  background-color: #e57373;
}
.indigo-purple.dark .mat-flat-button.mat-primary.mat-button-disabled, .indigo-purple.dark .mat-flat-button.mat-accent.mat-button-disabled, .indigo-purple.dark .mat-flat-button.mat-warn.mat-button-disabled, .indigo-purple.dark .mat-flat-button.mat-button-disabled.mat-button-disabled, .indigo-purple.dark .mat-raised-button.mat-primary.mat-button-disabled, .indigo-purple.dark .mat-raised-button.mat-accent.mat-button-disabled, .indigo-purple.dark .mat-raised-button.mat-warn.mat-button-disabled, .indigo-purple.dark .mat-raised-button.mat-button-disabled.mat-button-disabled, .indigo-purple.dark .mat-fab.mat-primary.mat-button-disabled, .indigo-purple.dark .mat-fab.mat-accent.mat-button-disabled, .indigo-purple.dark .mat-fab.mat-warn.mat-button-disabled, .indigo-purple.dark .mat-fab.mat-button-disabled.mat-button-disabled, .indigo-purple.dark .mat-mini-fab.mat-primary.mat-button-disabled, .indigo-purple.dark .mat-mini-fab.mat-accent.mat-button-disabled, .indigo-purple.dark .mat-mini-fab.mat-warn.mat-button-disabled, .indigo-purple.dark .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark .mat-flat-button.mat-primary .mat-ripple-element, .indigo-purple.dark .mat-raised-button.mat-primary .mat-ripple-element, .indigo-purple.dark .mat-fab.mat-primary .mat-ripple-element, .indigo-purple.dark .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.indigo-purple.dark .mat-flat-button.mat-accent .mat-ripple-element, .indigo-purple.dark .mat-raised-button.mat-accent .mat-ripple-element, .indigo-purple.dark .mat-fab.mat-accent .mat-ripple-element, .indigo-purple.dark .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.indigo-purple.dark .mat-flat-button.mat-warn .mat-ripple-element, .indigo-purple.dark .mat-raised-button.mat-warn .mat-ripple-element, .indigo-purple.dark .mat-fab.mat-warn .mat-ripple-element, .indigo-purple.dark .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.indigo-purple.dark .mat-stroked-button:not([class*=mat-elevation-z]), .indigo-purple.dark .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.indigo-purple.dark .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.indigo-purple.dark .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.indigo-purple.dark .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.indigo-purple.dark .mat-fab:not([class*=mat-elevation-z]), .indigo-purple.dark .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.indigo-purple.dark .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .indigo-purple.dark .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.indigo-purple.dark .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .indigo-purple.dark .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.indigo-purple.dark .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.indigo-purple.dark .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.indigo-purple.dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.indigo-purple.dark .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.indigo-purple.dark .mat-button-toggle {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-purple.dark .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark .mat-button-toggle-appearance-standard {
  color: white;
  background: #424242;
}
.indigo-purple.dark .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: white;
}
.indigo-purple.dark .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #595959;
}
.indigo-purple.dark [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #595959;
}
.indigo-purple.dark .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #595959;
}
.indigo-purple.dark .mat-button-toggle-checked {
  background-color: #212121;
  color: rgba(255, 255, 255, 0.7);
}
.indigo-purple.dark .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: white;
}
.indigo-purple.dark .mat-button-toggle-disabled {
  color: rgba(255, 255, 255, 0.3);
  background-color: black;
}
.indigo-purple.dark .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: #424242;
}
.indigo-purple.dark .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #424242;
}
.indigo-purple.dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.indigo-purple.dark .mat-button-toggle-group-appearance-standard {
  border: solid 1px #595959;
}
.indigo-purple.dark .mat-card {
  background: #424242;
  color: white;
}
.indigo-purple.dark .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.indigo-purple.dark .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.indigo-purple.dark .mat-card-subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-purple.dark .mat-checkbox-frame {
  border-color: rgba(255, 255, 255, 0.7);
}
.indigo-purple.dark .mat-checkbox-checkmark {
  fill: #303030;
}
.indigo-purple.dark .mat-checkbox-checkmark-path {
  stroke: #303030 !important;
}
.indigo-purple.dark .mat-checkbox-mixedmark {
  background-color: #303030;
}
.indigo-purple.dark .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .indigo-purple.dark .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #9fa8da;
}
.indigo-purple.dark .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .indigo-purple.dark .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #ce93d8;
}
.indigo-purple.dark .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .indigo-purple.dark .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #e57373;
}
.indigo-purple.dark .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .indigo-purple.dark .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #686868;
}
.indigo-purple.dark .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #686868;
}
.indigo-purple.dark .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-purple.dark .mat-checkbox .mat-ripple-element {
  background-color: white;
}
.indigo-purple.dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.indigo-purple.dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #9fa8da;
}
.indigo-purple.dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.indigo-purple.dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #ce93d8;
}
.indigo-purple.dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.indigo-purple.dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #e57373;
}
.indigo-purple.dark .mat-chip.mat-standard-chip {
  background-color: #616161;
  color: white;
}
.indigo-purple.dark .mat-chip.mat-standard-chip .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.indigo-purple.dark .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.indigo-purple.dark .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.indigo-purple.dark .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.indigo-purple.dark .mat-chip.mat-standard-chip::after {
  background: white;
}
.indigo-purple.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.indigo-purple.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.indigo-purple.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #e57373;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.indigo-purple.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.indigo-purple.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #ce93d8;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.indigo-purple.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.indigo-purple.dark .mat-table {
  background: #424242;
}
.indigo-purple.dark .mat-table thead, .indigo-purple.dark .mat-table tbody, .indigo-purple.dark .mat-table tfoot,
.indigo-purple.dark mat-header-row, .indigo-purple.dark mat-row, .indigo-purple.dark mat-footer-row,
.indigo-purple.dark [mat-header-row], .indigo-purple.dark [mat-row], .indigo-purple.dark [mat-footer-row],
.indigo-purple.dark .mat-table-sticky {
  background: inherit;
}
.indigo-purple.dark mat-row, .indigo-purple.dark mat-header-row, .indigo-purple.dark mat-footer-row,
.indigo-purple.dark th.mat-header-cell, .indigo-purple.dark td.mat-cell, .indigo-purple.dark td.mat-footer-cell {
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark .mat-header-cell {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-purple.dark .mat-cell, .indigo-purple.dark .mat-footer-cell {
  color: white;
}
.indigo-purple.dark .mat-calendar-arrow {
  fill: white;
}
.indigo-purple.dark .mat-datepicker-toggle,
.indigo-purple.dark .mat-datepicker-content .mat-calendar-next-button,
.indigo-purple.dark .mat-datepicker-content .mat-calendar-previous-button {
  color: white;
}
.indigo-purple.dark .mat-calendar-table-header-divider::after {
  background: rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark .mat-calendar-table-header,
.indigo-purple.dark .mat-calendar-body-label {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-purple.dark .mat-calendar-body-cell-content,
.indigo-purple.dark .mat-date-range-input-separator {
  color: white;
  border-color: transparent;
}
.indigo-purple.dark .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-purple.dark .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-purple.dark .mat-calendar-body-in-preview {
  color: rgba(255, 255, 255, 0.24);
}
.indigo-purple.dark .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.5);
}
.indigo-purple.dark .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.3);
}
.indigo-purple.dark .mat-calendar-body-in-range::before {
  background: rgba(159, 168, 218, 0.2);
}
.indigo-purple.dark .mat-calendar-body-comparison-identical,
.indigo-purple.dark .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.indigo-purple.dark .mat-calendar-body-comparison-bridge-start::before,
.indigo-purple.dark [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(159, 168, 218, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.indigo-purple.dark .mat-calendar-body-comparison-bridge-end::before,
.indigo-purple.dark [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(159, 168, 218, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.indigo-purple.dark .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.indigo-purple.dark .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.indigo-purple.dark .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.indigo-purple.dark .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.indigo-purple.dark .mat-calendar-body-selected {
  background-color: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(159, 168, 218, 0.4);
}
.indigo-purple.dark .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.indigo-purple.dark .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(159, 168, 218, 0.3);
}
@media (hover: hover) {
  .indigo-purple.dark .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(159, 168, 218, 0.3);
  }
}
.indigo-purple.dark .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #424242;
  color: white;
}
.indigo-purple.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(206, 147, 216, 0.2);
}
.indigo-purple.dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.indigo-purple.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.indigo-purple.dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.indigo-purple.dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(206, 147, 216, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.indigo-purple.dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.indigo-purple.dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(206, 147, 216, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.indigo-purple.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.indigo-purple.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.indigo-purple.dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.indigo-purple.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.indigo-purple.dark .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #ce93d8;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(206, 147, 216, 0.4);
}
.indigo-purple.dark .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.indigo-purple.dark .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(206, 147, 216, 0.3);
}
@media (hover: hover) {
  .indigo-purple.dark .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(206, 147, 216, 0.3);
  }
}
.indigo-purple.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(229, 115, 115, 0.2);
}
.indigo-purple.dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.indigo-purple.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.indigo-purple.dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.indigo-purple.dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(229, 115, 115, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.indigo-purple.dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.indigo-purple.dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(229, 115, 115, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.indigo-purple.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.indigo-purple.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.indigo-purple.dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.indigo-purple.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.indigo-purple.dark .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #e57373;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(229, 115, 115, 0.4);
}
.indigo-purple.dark .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.indigo-purple.dark .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(229, 115, 115, 0.3);
}
@media (hover: hover) {
  .indigo-purple.dark .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(229, 115, 115, 0.3);
  }
}
.indigo-purple.dark .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.indigo-purple.dark .mat-datepicker-toggle-active {
  color: #9fa8da;
}
.indigo-purple.dark .mat-datepicker-toggle-active.mat-accent {
  color: #ce93d8;
}
.indigo-purple.dark .mat-datepicker-toggle-active.mat-warn {
  color: #e57373;
}
.indigo-purple.dark .mat-date-range-input-inner[disabled] {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-purple.dark .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.indigo-purple.dark .mat-divider {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark .mat-divider-vertical {
  border-right-color: rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark .mat-expansion-panel {
  background: #424242;
  color: white;
}
.indigo-purple.dark .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.indigo-purple.dark .mat-action-row {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .indigo-purple.dark .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .indigo-purple.dark .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(255, 255, 255, 0.04);
}
@media (hover: none) {
  .indigo-purple.dark .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: #424242;
  }
}
.indigo-purple.dark .mat-expansion-panel-header-title {
  color: white;
}
.indigo-purple.dark .mat-expansion-panel-header-description,
.indigo-purple.dark .mat-expansion-indicator::after {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-purple.dark .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(255, 255, 255, 0.3);
}
.indigo-purple.dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.indigo-purple.dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.indigo-purple.dark .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-purple.dark .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-purple.dark .mat-form-field.mat-focused .mat-form-field-label {
  color: #9fa8da;
}
.indigo-purple.dark .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #ce93d8;
}
.indigo-purple.dark .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #e57373;
}
.indigo-purple.dark .mat-focused .mat-form-field-required-marker {
  color: #ce93d8;
}
.indigo-purple.dark .mat-form-field-ripple {
  background-color: white;
}
.indigo-purple.dark .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #9fa8da;
}
.indigo-purple.dark .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #ce93d8;
}
.indigo-purple.dark .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #e57373;
}
.indigo-purple.dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #9fa8da;
}
.indigo-purple.dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #ce93d8;
}
.indigo-purple.dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #e57373;
}
.indigo-purple.dark .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #e57373;
}
.indigo-purple.dark .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.indigo-purple.dark .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #e57373;
}
.indigo-purple.dark .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.indigo-purple.dark .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #e57373;
}
.indigo-purple.dark .mat-error {
  color: #e57373;
}
.indigo-purple.dark .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-purple.dark .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-purple.dark .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.indigo-purple.dark .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.indigo-purple.dark .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.indigo-purple.dark .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.indigo-purple.dark .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.1);
}
.indigo-purple.dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.05);
}
.indigo-purple.dark .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(255, 255, 255, 0.5);
}
.indigo-purple.dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-purple.dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.indigo-purple.dark .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.3);
}
.indigo-purple.dark .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: white;
}
.indigo-purple.dark .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #9fa8da;
}
.indigo-purple.dark .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #ce93d8;
}
.indigo-purple.dark .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #e57373;
}
.indigo-purple.dark .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #e57373;
}
.indigo-purple.dark .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-purple.dark .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.15);
}
.indigo-purple.dark .mat-icon.mat-primary {
  color: #9fa8da;
}
.indigo-purple.dark .mat-icon.mat-accent {
  color: #ce93d8;
}
.indigo-purple.dark .mat-icon.mat-warn {
  color: #e57373;
}
.indigo-purple.dark .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-purple.dark .mat-input-element:disabled,
.indigo-purple.dark .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-purple.dark .mat-input-element {
  caret-color: #9fa8da;
}
.indigo-purple.dark .mat-input-element::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-purple.dark .mat-input-element::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-purple.dark .mat-input-element::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-purple.dark .mat-input-element:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-purple.dark .mat-input-element:not(.mat-native-select-inline) option {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark .mat-input-element:not(.mat-native-select-inline) option:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-purple.dark .mat-form-field.mat-accent .mat-input-element {
  caret-color: #ce93d8;
}
.indigo-purple.dark .mat-form-field.mat-warn .mat-input-element,
.indigo-purple.dark .mat-form-field-invalid .mat-input-element {
  caret-color: #e57373;
}
.indigo-purple.dark .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #e57373;
}
.indigo-purple.dark .mat-list-base .mat-list-item {
  color: white;
}
.indigo-purple.dark .mat-list-base .mat-list-option {
  color: white;
}
.indigo-purple.dark .mat-list-base .mat-subheader {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-purple.dark .mat-list-base .mat-list-item-disabled {
  background-color: rgba(255, 255, 255, 0.12);
  color: rgba(255, 255, 255, 0.5);
}
.indigo-purple.dark .mat-list-option:hover, .indigo-purple.dark .mat-list-option:focus,
.indigo-purple.dark .mat-nav-list .mat-list-item:hover,
.indigo-purple.dark .mat-nav-list .mat-list-item:focus,
.indigo-purple.dark .mat-action-list .mat-list-item:hover,
.indigo-purple.dark .mat-action-list .mat-list-item:focus {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-purple.dark .mat-list-single-selected-option, .indigo-purple.dark .mat-list-single-selected-option:hover, .indigo-purple.dark .mat-list-single-selected-option:focus {
  background: rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark .mat-menu-panel {
  background: #424242;
}
.indigo-purple.dark .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.indigo-purple.dark .mat-menu-item {
  background: transparent;
  color: white;
}
.indigo-purple.dark .mat-menu-item[disabled],
.indigo-purple.dark .mat-menu-item[disabled] .mat-menu-submenu-icon,
.indigo-purple.dark .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-purple.dark .mat-menu-item .mat-icon-no-color,
.indigo-purple.dark .mat-menu-submenu-icon {
  color: white;
}
.indigo-purple.dark .mat-menu-item:hover:not([disabled]),
.indigo-purple.dark .mat-menu-item.cdk-program-focused:not([disabled]),
.indigo-purple.dark .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.indigo-purple.dark .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-purple.dark .mat-paginator {
  background: #424242;
}
.indigo-purple.dark .mat-paginator,
.indigo-purple.dark .mat-paginator-page-size .mat-select-trigger {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-purple.dark .mat-paginator-decrement,
.indigo-purple.dark .mat-paginator-increment {
  border-top: 2px solid white;
  border-right: 2px solid white;
}
.indigo-purple.dark .mat-paginator-first,
.indigo-purple.dark .mat-paginator-last {
  border-top: 2px solid white;
}
.indigo-purple.dark .mat-icon-button[disabled] .mat-paginator-decrement,
.indigo-purple.dark .mat-icon-button[disabled] .mat-paginator-increment,
.indigo-purple.dark .mat-icon-button[disabled] .mat-paginator-first,
.indigo-purple.dark .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(255, 255, 255, 0.5);
}
.indigo-purple.dark .mat-progress-bar-background {
  fill: #4c4e5b;
}
.indigo-purple.dark .mat-progress-bar-buffer {
  background-color: #4c4e5b;
}
.indigo-purple.dark .mat-progress-bar-fill::after {
  background-color: #9fa8da;
}
.indigo-purple.dark .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #58495a;
}
.indigo-purple.dark .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #58495a;
}
.indigo-purple.dark .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #ce93d8;
}
.indigo-purple.dark .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #5d4141;
}
.indigo-purple.dark .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #5d4141;
}
.indigo-purple.dark .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #e57373;
}
.indigo-purple.dark .mat-progress-spinner circle, .indigo-purple.dark .mat-spinner circle {
  stroke: #9fa8da;
}
.indigo-purple.dark .mat-progress-spinner.mat-accent circle, .indigo-purple.dark .mat-spinner.mat-accent circle {
  stroke: #ce93d8;
}
.indigo-purple.dark .mat-progress-spinner.mat-warn circle, .indigo-purple.dark .mat-spinner.mat-warn circle {
  stroke: #e57373;
}
.indigo-purple.dark .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.7);
}
.indigo-purple.dark .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #9fa8da;
}
.indigo-purple.dark .mat-radio-button.mat-primary .mat-radio-inner-circle,
.indigo-purple.dark .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .indigo-purple.dark .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .indigo-purple.dark .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #9fa8da;
}
.indigo-purple.dark .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ce93d8;
}
.indigo-purple.dark .mat-radio-button.mat-accent .mat-radio-inner-circle,
.indigo-purple.dark .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .indigo-purple.dark .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .indigo-purple.dark .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #ce93d8;
}
.indigo-purple.dark .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e57373;
}
.indigo-purple.dark .mat-radio-button.mat-warn .mat-radio-inner-circle,
.indigo-purple.dark .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .indigo-purple.dark .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .indigo-purple.dark .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #e57373;
}
.indigo-purple.dark .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.indigo-purple.dark .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.5);
}
.indigo-purple.dark .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.indigo-purple.dark .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(255, 255, 255, 0.5);
}
.indigo-purple.dark .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-purple.dark .mat-radio-button .mat-ripple-element {
  background-color: white;
}
.indigo-purple.dark .mat-select-value {
  color: white;
}
.indigo-purple.dark .mat-select-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-purple.dark .mat-select-disabled .mat-select-value {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-purple.dark .mat-select-arrow {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-purple.dark .mat-select-panel {
  background: #424242;
}
.indigo-purple.dark .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.indigo-purple.dark .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #9fa8da;
}
.indigo-purple.dark .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #ce93d8;
}
.indigo-purple.dark .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #e57373;
}
.indigo-purple.dark .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #e57373;
}
.indigo-purple.dark .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-purple.dark .mat-drawer-container {
  background-color: #303030;
  color: white;
}
.indigo-purple.dark .mat-drawer {
  background-color: #424242;
  color: white;
}
.indigo-purple.dark .mat-drawer.mat-drawer-push {
  background-color: #424242;
}
.indigo-purple.dark .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.indigo-purple.dark .mat-drawer-side {
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.indigo-purple.dark [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.indigo-purple.dark [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(189, 189, 189, 0.6);
}
.indigo-purple.dark .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #ce93d8;
}
.indigo-purple.dark .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(206, 147, 216, 0.54);
}
.indigo-purple.dark .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #ce93d8;
}
.indigo-purple.dark .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #9fa8da;
}
.indigo-purple.dark .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(159, 168, 218, 0.54);
}
.indigo-purple.dark .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #9fa8da;
}
.indigo-purple.dark .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #e57373;
}
.indigo-purple.dark .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(229, 115, 115, 0.54);
}
.indigo-purple.dark .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #e57373;
}
.indigo-purple.dark .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: white;
}
.indigo-purple.dark .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #bdbdbd;
}
.indigo-purple.dark .mat-slide-toggle-bar {
  background-color: rgba(255, 255, 255, 0.5);
}
.indigo-purple.dark .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.indigo-purple.dark .mat-slider.mat-primary .mat-slider-track-fill,
.indigo-purple.dark .mat-slider.mat-primary .mat-slider-thumb,
.indigo-purple.dark .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #9fa8da;
}
.indigo-purple.dark .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(159, 168, 218, 0.2);
}
.indigo-purple.dark .mat-slider.mat-accent .mat-slider-track-fill,
.indigo-purple.dark .mat-slider.mat-accent .mat-slider-thumb,
.indigo-purple.dark .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #ce93d8;
}
.indigo-purple.dark .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(206, 147, 216, 0.2);
}
.indigo-purple.dark .mat-slider.mat-warn .mat-slider-track-fill,
.indigo-purple.dark .mat-slider.mat-warn .mat-slider-thumb,
.indigo-purple.dark .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #e57373;
}
.indigo-purple.dark .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(229, 115, 115, 0.2);
}
.indigo-purple.dark .mat-slider:hover .mat-slider-track-background,
.indigo-purple.dark .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.indigo-purple.dark .mat-slider.mat-slider-disabled .mat-slider-track-background,
.indigo-purple.dark .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.indigo-purple.dark .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(255, 255, 255, 0.3);
}
.indigo-purple.dark .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.indigo-purple.dark .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.indigo-purple.dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: white;
}
.indigo-purple.dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.indigo-purple.dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(255, 255, 255, 0.3);
}
.indigo-purple.dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
  background-color: transparent;
}
.indigo-purple.dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .indigo-purple.dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.indigo-purple.dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .indigo-purple.dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.indigo-purple.dark .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(255, 255, 255, 0.7);
}
.indigo-purple.dark .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.indigo-purple.dark .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.indigo-purple.dark .mat-step-header.cdk-keyboard-focused, .indigo-purple.dark .mat-step-header.cdk-program-focused, .indigo-purple.dark .mat-step-header:hover:not([aria-disabled]), .indigo-purple.dark .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(255, 255, 255, 0.04);
}
.indigo-purple.dark .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .indigo-purple.dark .mat-step-header:hover {
    background: none;
  }
}
.indigo-purple.dark .mat-step-header .mat-step-label,
.indigo-purple.dark .mat-step-header .mat-step-optional {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-purple.dark .mat-step-header .mat-step-icon {
  background-color: rgba(255, 255, 255, 0.7);
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark .mat-step-header .mat-step-icon-selected,
.indigo-purple.dark .mat-step-header .mat-step-icon-state-done,
.indigo-purple.dark .mat-step-header .mat-step-icon-state-edit {
  background-color: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark .mat-step-header.mat-accent .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark .mat-step-header.mat-accent .mat-step-icon-selected,
.indigo-purple.dark .mat-step-header.mat-accent .mat-step-icon-state-done,
.indigo-purple.dark .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #ce93d8;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark .mat-step-header.mat-warn .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark .mat-step-header.mat-warn .mat-step-icon-selected,
.indigo-purple.dark .mat-step-header.mat-warn .mat-step-icon-state-done,
.indigo-purple.dark .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #e57373;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #e57373;
}
.indigo-purple.dark .mat-step-header .mat-step-label.mat-step-label-active {
  color: white;
}
.indigo-purple.dark .mat-step-header .mat-step-label.mat-step-label-error {
  color: #e57373;
}
.indigo-purple.dark .mat-stepper-horizontal, .indigo-purple.dark .mat-stepper-vertical {
  background-color: #424242;
}
.indigo-purple.dark .mat-stepper-vertical-line::before {
  border-left-color: rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark .mat-horizontal-stepper-header::before,
.indigo-purple.dark .mat-horizontal-stepper-header::after,
.indigo-purple.dark .mat-stepper-horizontal-line {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark .mat-sort-header-arrow {
  color: #c6c6c6;
}
.indigo-purple.dark .mat-tab-nav-bar,
.indigo-purple.dark .mat-tab-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark .mat-tab-group-inverted-header .mat-tab-nav-bar,
.indigo-purple.dark .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  border-bottom: none;
}
.indigo-purple.dark .mat-tab-label, .indigo-purple.dark .mat-tab-link {
  color: white;
}
.indigo-purple.dark .mat-tab-label.mat-tab-disabled, .indigo-purple.dark .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-purple.dark .mat-tab-header-pagination-chevron {
  border-color: white;
}
.indigo-purple.dark .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.5);
}
.indigo-purple.dark .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.indigo-purple.dark .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.indigo-purple.dark .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-purple.dark .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-purple.dark .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-purple.dark .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .indigo-purple.dark .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-purple.dark .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-purple.dark .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-purple.dark .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(197, 202, 233, 0.3);
}
.indigo-purple.dark .mat-tab-group.mat-primary .mat-ink-bar, .indigo-purple.dark .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #9fa8da;
}
.indigo-purple.dark .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .indigo-purple.dark .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .indigo-purple.dark .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .indigo-purple.dark .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-purple.dark .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-purple.dark .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-purple.dark .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .indigo-purple.dark .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-purple.dark .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-purple.dark .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-purple.dark .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(225, 190, 231, 0.3);
}
.indigo-purple.dark .mat-tab-group.mat-accent .mat-ink-bar, .indigo-purple.dark .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #ce93d8;
}
.indigo-purple.dark .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .indigo-purple.dark .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .indigo-purple.dark .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .indigo-purple.dark .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-purple.dark .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-purple.dark .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-purple.dark .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .indigo-purple.dark .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-purple.dark .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-purple.dark .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-purple.dark .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.indigo-purple.dark .mat-tab-group.mat-warn .mat-ink-bar, .indigo-purple.dark .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #e57373;
}
.indigo-purple.dark .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .indigo-purple.dark .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .indigo-purple.dark .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .indigo-purple.dark .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-purple.dark .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-purple.dark .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-purple.dark .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .indigo-purple.dark .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-purple.dark .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-purple.dark .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-purple.dark .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(197, 202, 233, 0.3);
}
.indigo-purple.dark .mat-tab-group.mat-background-primary > .mat-tab-header, .indigo-purple.dark .mat-tab-group.mat-background-primary > .mat-tab-link-container, .indigo-purple.dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .indigo-purple.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .indigo-purple.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .indigo-purple.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #9fa8da;
}
.indigo-purple.dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .indigo-purple.dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .indigo-purple.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .indigo-purple.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .indigo-purple.dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .indigo-purple.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .indigo-purple.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.indigo-purple.dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.indigo-purple.dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.indigo-purple.dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.indigo-purple.dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .indigo-purple.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.indigo-purple.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.indigo-purple.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.indigo-purple.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.indigo-purple.dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .indigo-purple.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.indigo-purple.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.indigo-purple.dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.indigo-purple.dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.indigo-purple.dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .indigo-purple.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.indigo-purple.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.indigo-purple.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.indigo-purple.dark .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-purple.dark .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-purple.dark .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-purple.dark .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .indigo-purple.dark .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-purple.dark .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-purple.dark .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-purple.dark .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(225, 190, 231, 0.3);
}
.indigo-purple.dark .mat-tab-group.mat-background-accent > .mat-tab-header, .indigo-purple.dark .mat-tab-group.mat-background-accent > .mat-tab-link-container, .indigo-purple.dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .indigo-purple.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .indigo-purple.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .indigo-purple.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #ce93d8;
}
.indigo-purple.dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .indigo-purple.dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .indigo-purple.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .indigo-purple.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .indigo-purple.dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .indigo-purple.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .indigo-purple.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.indigo-purple.dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.indigo-purple.dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.indigo-purple.dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.indigo-purple.dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .indigo-purple.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.indigo-purple.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.indigo-purple.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.indigo-purple.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.indigo-purple.dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .indigo-purple.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.indigo-purple.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.indigo-purple.dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.indigo-purple.dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.indigo-purple.dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .indigo-purple.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.indigo-purple.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.indigo-purple.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.indigo-purple.dark .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-purple.dark .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-purple.dark .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-purple.dark .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .indigo-purple.dark .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-purple.dark .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-purple.dark .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-purple.dark .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.indigo-purple.dark .mat-tab-group.mat-background-warn > .mat-tab-header, .indigo-purple.dark .mat-tab-group.mat-background-warn > .mat-tab-link-container, .indigo-purple.dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .indigo-purple.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .indigo-purple.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .indigo-purple.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #e57373;
}
.indigo-purple.dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .indigo-purple.dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .indigo-purple.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .indigo-purple.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .indigo-purple.dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .indigo-purple.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .indigo-purple.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.indigo-purple.dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.indigo-purple.dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.indigo-purple.dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.indigo-purple.dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .indigo-purple.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.indigo-purple.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.indigo-purple.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.indigo-purple.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.indigo-purple.dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .indigo-purple.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.indigo-purple.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.indigo-purple.dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.indigo-purple.dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.indigo-purple.dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .indigo-purple.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.indigo-purple.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.indigo-purple.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.indigo-purple.dark .mat-toolbar {
  background: #212121;
  color: white;
}
.indigo-purple.dark .mat-toolbar.mat-primary {
  background: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark .mat-toolbar.mat-accent {
  background: #ce93d8;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark .mat-toolbar.mat-warn {
  background: #e57373;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark .mat-toolbar .mat-form-field-underline,
.indigo-purple.dark .mat-toolbar .mat-form-field-ripple,
.indigo-purple.dark .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.indigo-purple.dark .mat-toolbar .mat-form-field-label,
.indigo-purple.dark .mat-toolbar .mat-focused .mat-form-field-label,
.indigo-purple.dark .mat-toolbar .mat-select-value,
.indigo-purple.dark .mat-toolbar .mat-select-arrow,
.indigo-purple.dark .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.indigo-purple.dark .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.indigo-purple.dark .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.indigo-purple.dark .mat-tree {
  background: #424242;
}
.indigo-purple.dark .mat-tree-node,
.indigo-purple.dark .mat-nested-tree-node {
  color: white;
}
.indigo-purple.dark .mat-snack-bar-container {
  color: rgba(0, 0, 0, 0.87);
  background: #fafafa;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.indigo-purple.dark .mat-simple-snackbar-action {
  color: inherit;
}
.indigo-purple.dark *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.indigo-purple.dark *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
.indigo-purple.dark *::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}
.indigo-purple.dark .toolbar-line.container-ou {
  background: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark .toolbar-line.container-persona {
  background: #9fa8da !important;
}
.indigo-purple.dark .toolbar-line.container-persona .persona-title {
  background: #9fa8da;
}
.indigo-purple.dark .toolbar-line.container-persona .invisible-container.persona-selector {
  background: #9fa8da !important;
}
.indigo-purple.dark button.mat-icon-button:not([disabled]):hover {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-purple.dark .side-bar {
  background: black;
}
.indigo-purple.dark .icon-stacked {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-purple.dark .icon-stacked mat-icon + mat-icon {
  text-shadow: -1px 1px 0px #9fa8da, 1px -1px 0px #9fa8da;
}
.indigo-purple.dark .divider-vert {
  background: rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark .avatar {
  background: #304ffe !important;
}
.indigo-purple.dark ngx-avatar .avatar-content {
  background: #304ffe !important;
}
.indigo-purple.dark .mat-loading-window {
  background: #424242;
}
.indigo-purple.dark .window-spinner {
  background: #424242;
}
.indigo-purple.dark .load-window {
  background: #424242;
}
@media (min-width: 600px) and (max-width: 1300px) {
  .indigo-purple.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .load-window,
.indigo-purple.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .load-window.new {
    background: #424242 !important;
  }
  .indigo-purple.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card {
    background: #424242 !important;
  }
  .indigo-purple.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .inbox-container mat-toolbar {
    background: #9fa8da;
    color: rgba(0, 0, 0, 0.87);
  }
  .indigo-purple.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .inbox-container mat-list-item.mat-list-item {
    border: 1px solid rgba(255, 255, 255, 0.12);
  }
  .indigo-purple.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .detail {
    background: #424242 !important;
  }
  .indigo-purple.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .detail mat-toolbar {
    background: #424242 !important;
  }
  .indigo-purple.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .main.w-detail + .new-detail-container .load-window {
    background: #303030 !important;
  }
  .indigo-purple.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .main.w-detail + .new-detail-container:before {
    background: rgba(0, 0, 0, 0.6);
  }
  .indigo-purple.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .main.side-open + .side {
    border-left: 1px solid rgba(255, 255, 255, 0.12);
  }
  .indigo-purple.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .side .container .mat-list-base {
    background: #424242 !important;
    border: 1px solid rgba(255, 255, 255, 0.12);
  }
  .indigo-purple.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .new-table .mat-header-row,
.indigo-purple.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .new-table .mat-table,
.indigo-purple.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .document-table .mat-header-row,
.indigo-purple.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .document-table .mat-table,
.indigo-purple.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .status-table .mat-header-row,
.indigo-purple.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .status-table .mat-table {
    background: #424242 !important;
  }
  .indigo-purple.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .audit-filter .filter-container.is-floating {
    background: #303030 !important;
    border: 1px solid rgba(255, 255, 255, 0.12);
  }
  .indigo-purple.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .audit-filter .filter-container.is-floating .form-body {
    background: #303030 !important;
  }
  .indigo-purple.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .audit-result {
    background: #424242;
  }
  .indigo-purple.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .audit-detail-container {
    background: #303030 !important;
  }
}
@media (max-width: 599px) {
  .indigo-purple.dark app-document-detail .mat-toolbar {
    background: #304ffe !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .indigo-purple.dark app-document-detail .mat-toolbar .mat-toolbar-row .toolbar-actions {
    background: #304ffe !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .indigo-purple.dark .login-card {
    background: #424242 !important;
  }
  .indigo-purple.dark .inbox-toolbar {
    background: #304ffe !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .indigo-purple.dark .detail {
    background: #424242 url("../img/spinner_mobile.gif") no-repeat center center !important;
  }
  .indigo-purple.dark .toolbar-line-container .toolbar-line:first-child {
    background: #304ffe !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .indigo-purple.dark .toolbar-actions {
    background: #304ffe !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .indigo-purple.dark mat-drawer.side-nav.enter {
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(255, 255, 255, 0.12) !important;
  }
  .indigo-purple.dark .side-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0) !important;
  }
  .indigo-purple.dark .filter {
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.55) 16%, rgba(0, 0, 0, 0) 100%) !important;
  }
  .indigo-purple.dark button.side-open-mobile {
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .indigo-purple.dark .side-nav .side-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0);
  }
  .indigo-purple.dark .side-nav .filter {
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.55) 16%, rgba(0, 0, 0, 0) 100%);
  }
  .indigo-purple.dark .side-nav .side-nav-container .links {
    background: #424242;
    border-top: 1px solid rgba(255, 255, 255, 0.12);
  }
  .indigo-purple.dark .side-nav .side-nav-container .version {
    background: #424242 !important;
    border-top: 1px solid rgba(255, 255, 255, 0.12);
  }
  .indigo-purple.dark app-file-document-view .view-container {
    background: #424242 !important;
  }
  .indigo-purple.dark app-file-document-view .view-container .side-bar {
    background: #304ffe !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .indigo-purple.dark app-file-document-view .view-container .view-content app-pdf-wrapper.floating {
    background: #424242;
  }
  .indigo-purple.dark app-file-document-view .view-container .view-content .upload-indications {
    background: black;
  }
  .indigo-purple.dark app-file-document-view .view-container .view-content app-file-document-sign .sign-cycle mat-horizontal-stepper.mat-stepper-horizontal {
    background-color: rgba(0, 0, 0, 0) !important;
  }
  .indigo-purple.dark app-file-document-view .view-container .view-content app-file-document-sign .sign-cycle .sign-cycle-content .cycle-content-action .mat-ripple-element {
    background-color: rgba(255, 255, 255, 0.1) !important;
  }
  .indigo-purple.dark app-pending .floating-container .inbox-container .inbox-toolbar .mat-toolbar-row .mat-avatar h3 {
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .indigo-purple.dark app-pending .floating-container .detail {
    background: #424242 url("../img/spinner_mobile.gif") no-repeat center center !important;
  }
  .indigo-purple.dark app-document-sign-massive .inbox-container .pending-container app-chapa.minimized .info-empty {
    background: #ce93d8;
  }
  .indigo-purple.dark app-document-sign-massive .inbox-container .pending-container app-chapa.minimized .info-empty > span h2 {
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .indigo-purple.dark app-document-sign-massive .inbox-container .signer.mobile {
    border-top: 1px solid rgba(255, 255, 255, 0.12);
    background: #424242;
  }
  .indigo-purple.dark app-document-sign-massive .detail app-file-document-detail .view-container .mat-toolbar .mat-toolbar-row .view-actions {
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .indigo-purple.dark app-add-documentation .floating-container .employee-add-documentation .inbox-container .inbox-toolbar .mat-toolbar-row .mat-avatar h3 {
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .indigo-purple.dark app-add-documentation .floating-container .employee-add-documentation .detail {
    background: #424242 url("../img/spinner_mobile.gif") no-repeat center center !important;
  }
  .indigo-purple.dark app-add-documentation .floating-container .employee-add-documentation .detail app-file-document-view .view-container .view-content .new-container app-file-document-upload {
    background: #424242;
  }
}
.indigo-purple.dark .mat-card .mat-drawer-container {
  background: #424242 !important;
}
.indigo-purple.dark .mat-drawer-container {
  background: #212121 !important;
}
.indigo-purple.dark .mat-list .mat-list-item.finished-process-inbox.active {
  background: #009688 !important;
}
.indigo-purple.dark .mat-list .mat-list-item.finished-process-inbox .mat-icon {
  background: #009688;
}
.indigo-purple.dark .mat-list .mat-list-item.erased-process-inbox.active {
  background: #837C80 !important;
}
.indigo-purple.dark .mat-list .mat-list-item.limbo-process-inbox.active {
  background: #ffc107 !important;
}
.indigo-purple.dark .mat-list .mat-list-item.limbo-process-inbox .mat-icon {
  background: #ffc107;
}
.indigo-purple.dark .mat-list .mat-list-item.in-process-inbox.active {
  background: #ffc107 !important;
}
.indigo-purple.dark .mat-list .mat-list-item.in-process-inbox .mat-icon {
  background: #ffc107;
}
.indigo-purple.dark .mat-list .mat-list-item.error-process-inbox.active {
  background: #e91e63 !important;
}
.indigo-purple.dark .mat-list .mat-list-item.error-process-inbox .mat-icon {
  background: #e91e63;
}
.indigo-purple.dark .mat-list .mat-list-item.active .mat-line b {
  color: #9fa8da;
}
.indigo-purple.dark .mat-list .mat-list-item.active .mat-list-avatar {
  color: #9fa8da;
}
.indigo-purple.dark .mat-list .mat-list-item:hover:not(.active) {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-purple.dark span.mat-badge-content {
  color: #e57373 !important;
}
.indigo-purple.dark mat-expansion-panel.mat-expanded {
  background: #303030 !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark mat-expansion-panel.virtual-scrolling .mat-expansion-panel-content .get-more {
  color: #9fa8da;
}
.indigo-purple.dark mat-expansion-panel.virtual-scrolling .mat-expansion-panel-content app-cs-paginator.get-more {
  background: #424242;
}
.indigo-purple.dark mat-expansion-panel-header.mat-expanded {
  background: #424242 !important;
}
.indigo-purple.dark .bottom-container {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark .load-more * {
  color: #9fa8da;
}
.indigo-purple.dark .welcome-card {
  background: #424242 !important;
}
.indigo-purple.dark .welcome-card .img-divider {
  background: #304ffe;
}
.indigo-purple.dark .mat-header-row {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  background: #424242;
}
.indigo-purple.dark .mat-row {
  background: #424242;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.indigo-purple.dark .mat-row .mat-cell.status-cell mat-icon.pending {
  color: #ffb74d !important;
}
.indigo-purple.dark .mat-row .mat-cell.status-cell mat-icon.ok {
  color: #4db6ac !important;
}
.indigo-purple.dark .mat-row .mat-cell.status-cell mat-icon.not-ok {
  color: #ba68c8 !important;
}
.indigo-purple.dark .mat-row .mat-cell.status-cell mat-icon.cancelled {
  color: #795548 !important;
}
.indigo-purple.dark .mat-row .mat-cell.status-cell mat-icon.rejected {
  color: #df4d4c !important;
}
.indigo-purple.dark .mat-row .mat-cell.status-cell mat-icon.draft {
  color: #dedede !important;
}
.indigo-purple.dark .mat-row.active, .indigo-purple.dark .mat-row.active:hover {
  background: #9fa8da !important;
}
.indigo-purple.dark .mat-row.active div, .indigo-purple.dark .mat-row.active:hover div {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark .mat-row.active .mat-cell.status-cell mat-icon.pending, .indigo-purple.dark .mat-row.active .mat-cell.status-cell mat-icon.ok, .indigo-purple.dark .mat-row.active .mat-cell.status-cell mat-icon.not-ok, .indigo-purple.dark .mat-row.active:hover .mat-cell.status-cell mat-icon.pending, .indigo-purple.dark .mat-row.active:hover .mat-cell.status-cell mat-icon.ok, .indigo-purple.dark .mat-row.active:hover .mat-cell.status-cell mat-icon.not-ok {
  text-shadow: -1px 1px 0 rgba(0, 0, 0, 0.87), 1px 1px 0 rgba(0, 0, 0, 0.87), 1px -1px 0 rgba(0, 0, 0, 0.87), -1px -1px 0 rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark .mat-row.active .sticky-actions button, .indigo-purple.dark .mat-row.active:hover .sticky-actions button {
  background: #9fa8da !important;
}
.indigo-purple.dark .mat-row.active .sticky-actions button.ok mat-icon, .indigo-purple.dark .mat-row.active .sticky-actions button.not-ok mat-icon, .indigo-purple.dark .mat-row.active:hover .sticky-actions button.ok mat-icon, .indigo-purple.dark .mat-row.active:hover .sticky-actions button.not-ok mat-icon {
  text-shadow: -1px 1px 0 rgba(0, 0, 0, 0.87), 1px 1px 0 rgba(0, 0, 0, 0.87), 1px -1px 0 rgba(0, 0, 0, 0.87), -1px -1px 0 rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark .mat-row:nth-child(odd) {
  background: #303030;
}
.indigo-purple.dark .mat-row.selected div {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark .mat-row.selected:nth-child(even) {
  background: #9fa8da !important;
}
.indigo-purple.dark .mat-row.selected:nth-child(even) .sticky-actions button {
  background: #9fa8da !important;
}
.indigo-purple.dark .mat-row.selected:nth-child(odd) {
  background: #c5cae9 !important;
}
.indigo-purple.dark .mat-row.selected:nth-child(odd) .sticky-actions button {
  background: #c5cae9 !important;
}
.indigo-purple.dark .mat-row.selected.active, .indigo-purple.dark .mat-row.selected.active:hover {
  background: #9fa8da !important;
}
.indigo-purple.dark .mat-row.selected.active .sticky-actions button, .indigo-purple.dark .mat-row.selected.active:hover .sticky-actions button {
  background: #9fa8da !important;
}
.indigo-purple.dark .mat-row:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-purple.dark .mat-row:hover .sticky-actions button {
  background: #424242 !important;
}
.indigo-purple.dark .mat-row.canceled * {
  color: #795548;
}
.indigo-purple.dark .mat-row.disabled div {
  color: #607D8B;
}
.indigo-purple.dark .mat-row.disabled.active, .indigo-purple.dark .mat-row.disabled.active:hover {
  background: #607D8B !important;
}
.indigo-purple.dark .mat-row.disabled.active div, .indigo-purple.dark .mat-row.disabled.active:hover div {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark .mat-row.disabled.active .sticky-actions button, .indigo-purple.dark .mat-row.disabled.active:hover .sticky-actions button {
  background: #607D8B !important;
}
.indigo-purple.dark .mat-row.disabled.selected.active:hover .sticky-actions button {
  background: #607D8B !important;
}
.indigo-purple.dark .mat-row .sticky-actions button.ok mat-icon {
  color: #4db6ac !important;
}
.indigo-purple.dark .mat-row .sticky-actions button.not-ok mat-icon {
  color: #ba68c8 !important;
}
.indigo-purple.dark .status-table .mat-header-row {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  background: #424242;
}
.indigo-purple.dark .icon-info-off,
.indigo-purple.dark .certificate-off {
  color: #607D8B;
}
.indigo-purple.dark .documents-search-table .mat-header-row {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  background: #424242;
}
.indigo-purple.dark mat-toolbar.employee-search-sub-toolbar {
  background: #424242;
}
.indigo-purple.dark .mat-search-field {
  border: 1px solid rgba(255, 255, 255, 0.12);
  background: black;
}
.indigo-purple.dark .mat-search-field input[type=search] {
  background: rgba(0, 0, 0, 0);
}
.indigo-purple.dark .mat-search-field input[type=search]::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-purple.dark .search-adv-menu .mat-search-field {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark mat-toolbar.document-search-sub-toolbar {
  background: #424242;
}
.indigo-purple.dark app-advanced-employee-search .mat-chip {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-purple.dark .side-nav .side-trigger {
  background: #424242;
}
.indigo-purple.dark .side-nav .menu-trigger mat-icon {
  color: rgba(255, 255, 255, 0.7);
  background: #424242;
}
.indigo-purple.dark .side-nav .side-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0);
}
.indigo-purple.dark .side-nav .filter {
  background: #424242;
}
.indigo-purple.dark .side-nav .side-nav-container .links mat-icon span.mat-badge-content {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-purple.dark .side-nav .side-nav-container .mat-list-base .mat-list-item:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-purple.dark .side-nav .side-nav-container .mat-list-base .mat-list-item.active {
  background: #9fa8da !important;
}
.indigo-purple.dark .side-nav .side-nav-container .mat-list-base .mat-list-item.active * {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark .side-nav .side-nav-container .mat-list-base .mat-list-item.active .mat-list-item.active:hover {
  background: #9fa8da !important;
}
.indigo-purple.dark .side-nav .side-nav-container .mat-list-base .mat-list-item.active .mat-list-item.active:hover * {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark .side-nav.open .side-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark .side-nav.open .filter {
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.55) 16%, rgba(0, 0, 0, 0) 100%);
}
.indigo-purple.dark .side-nav.open .side-nav-container .links {
  background: #424242;
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark .side-nav.open .side-nav-container .version {
  background: #424242 !important;
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark .side-nav.enter .side-nav-container .links {
  background: #424242;
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark .side-nav.enter .side-nav-container .version {
  background: #424242 !important;
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark .mat-menu-panel:not(.metadata-radio-menu) .navigation-list .mat-list-item-content {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-purple.dark table.mat-calendar-table {
  background: #424242 !important;
}
.indigo-purple.dark .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-purple.dark .mat-calendar-arrow {
  border-top-color: #424242 !important;
}
.indigo-purple.dark .mat-datepicker-content .mat-calendar-next-button,
.indigo-purple.dark .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-purple.dark .mat-calendar-header {
  background: #304ffe !important;
}
.indigo-purple.dark mat-datepicker-content {
  background-color: #304ffe !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-purple.dark .mat-calendar-content {
  background: #424242 !important;
}
.indigo-purple.dark .mat-simple-snackbar-action button.mat-button {
  color: #ff9800;
}
.indigo-purple.dark .mat-simple-snackbar-action .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.indigo-purple.dark mat-bottom-sheet-container mat-toolbar {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12) !important;
}
.indigo-purple.dark app-generic-bottom-sheet h4.accent {
  color: #ce93d8;
}
.indigo-purple.dark app-generic-bottom-sheet .verification-code {
  background: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark app-generic-bottom-sheet .verification-code h2 {
  background: #9fa8da;
}
.indigo-purple.dark app-generic-bottom-sheet .load-window.message .info-container h3.primary-text {
  color: #9fa8da;
}
.indigo-purple.dark app-generic-bottom-sheet .bottom_sheet-content.ok .bottom_sheet-title .bottom_sheet-icon,
.indigo-purple.dark app-generic-bottom-sheet .bottom_sheet-content.ok .bottom_sheet-title h3 {
  color: #009688;
}
.indigo-purple.dark app-generic-bottom-sheet .bottom_sheet-content.ok .bottom_sheet-main_btn:not(:disabled) {
  background: #009688;
  color: #fff;
}
.indigo-purple.dark app-generic-bottom-sheet .bottom_sheet-content.not-ok .bottom_sheet-title .bottom_sheet-icon,
.indigo-purple.dark app-generic-bottom-sheet .bottom_sheet-content.not-ok .bottom_sheet-title h3 {
  color: #9C27B0;
}
.indigo-purple.dark app-generic-bottom-sheet .bottom_sheet-content.not-ok .bottom_sheet-main_btn:not(:disabled) {
  background: #9C27B0;
  color: #fff;
}
.indigo-purple.dark app-generic-bottom-sheet .bottom_sheet-content.cancel .bottom_sheet-title .bottom_sheet-icon,
.indigo-purple.dark app-generic-bottom-sheet .bottom_sheet-content.cancel .bottom_sheet-title h3 {
  color: #795548;
}
.indigo-purple.dark app-generic-bottom-sheet .bottom_sheet-content.cancel .bottom_sheet-main_btn:not(:disabled) {
  background: #795548;
  color: #fff;
}
.indigo-purple.dark app-generic-bottom-sheet .bottom_sheet-content .bottom_sheet_exp_btn {
  background-color: #9fa8da;
  color: #fff;
}
.indigo-purple.dark app-generic-bottom-sheet .bottom_sheet-content .bottom_sheet_canc_btn {
  background-color: lightgrey;
  color: black;
}
.indigo-purple.dark app-generic-bottom-sheet .bottom_sheet-content .bottom_sheet-exp-h3 {
  color: #9fa8da;
  margin-bottom: -10px !important;
}
.indigo-purple.dark app-generic-bottom-sheet .bottom_sheet-content .mat-input-element-export {
  display: flex !important;
  padding-left: 15px;
}
.indigo-purple.dark app-generic-bottom-sheet .bottom_sheet-content .mat-form-field-infix-export-right {
  width: 100% !important;
  padding: 0px !important;
  display: flex !important;
}
.indigo-purple.dark app-generic-bottom-sheet .bottom_sheet-content .mat-form-field-infix-export-left {
  width: 100% !important;
  padding: 0px;
  display: flex !important;
}
.indigo-purple.dark app-generic-bottom-sheet .bottom_sheet-content .mat-form-field-infix {
  display: flex;
}
.indigo-purple.dark app-generic-bottom-sheet .bottom_sheet-content .mat-checkbox-layout .mat-checkbox-label {
  color: #ce93d8;
}
.indigo-purple.dark app-generic-bottom-sheet .bottom_sheet-content .choose-forms {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  margin-left: 10px !important;
}
.indigo-purple.dark app-generic-bottom-sheet .bottom_sheet-content .card-container {
  max-height: 150px;
  overflow-y: auto;
}
.indigo-purple.dark .mat-list .mat-list-item.active .mat-line {
  color: white;
}
.indigo-purple.dark .mat-list .mat-list-item.active .mat-line b {
  color: #9fa8da;
}
.indigo-purple.dark .mat-list .mat-list-item.active .mat-list-avatar {
  color: #9fa8da;
}
.indigo-purple.dark .mat-list .mat-list-item .mat-line {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-purple.dark .mat-list .mat-list-item .mat-list-avatar {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-purple.dark .mat-list-item:hover:not(.active) {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-purple.dark mat-list-item:nth-child(even),
.indigo-purple.dark app-file-document-inbox-item:nth-child(even) mat-list-item,
.indigo-purple.dark app-file-document-inbox-item-documentation:nth-child(even) mat-list-item {
  background: #303030 !important;
}
.indigo-purple.dark mat-list-item:nth-child(odd),
.indigo-purple.dark app-file-document-inbox-item:nth-child(odd) mat-list-item,
.indigo-purple.dark app-file-document-inbox-item-documentation:nth-child(odd) mat-list-item {
  background: #424242 !important;
}
.indigo-purple.dark .mat-list-avatar.mat-icon {
  background: black;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark .card-filter {
  background: #424242;
}
.indigo-purple.dark .login mat-horizontal-stepper {
  background: #424242 !important;
}
.indigo-purple.dark .login .login-card {
  background: #424242 !important;
}
.indigo-purple.dark .login .login-card .mat-card-content .mat-card-footer a[color=accent] {
  color: #ce93d8;
}
.indigo-purple.dark .login .help-stepper h3.align-16 {
  color: #ce93d8;
}
.indigo-purple.dark .login .help-stepper .help-options mat-icon {
  color: #ce93d8;
}
.indigo-purple.dark .ux-modal {
  background: rgba(0, 0, 0, 0.32);
}
.indigo-purple.dark .ux-modal .card {
  background: #424242;
}
.indigo-purple.dark .main-title {
  background: #607D8B;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark .sub-title {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-purple.dark .card-footer a {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark .profile-toolbar {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark app-profile .logout-btn:hover {
  background: black;
}
.indigo-purple.dark app-profile .cropper {
  background: #424242;
}
.indigo-purple.dark app-profile .cropper .cropper {
  background: unset;
}
.indigo-purple.dark app-add-documentation .employee-add-documentation .inbox-toolbar {
  background: #303030;
}
.indigo-purple.dark app-add-documentation .employee-add-documentation .inbox-toolbar .toolbar-line mat-icon {
  color: #9fa8da;
}
.indigo-purple.dark app-add-documentation .employee-add-documentation .inbox-toolbar .toolbar-line h3 {
  color: #9fa8da;
}
.indigo-purple.dark app-add-documentation .employee-add-documentation mat-tab-group {
  background: #303030;
}
.indigo-purple.dark app-add-documentation .employee-add-documentation mat-tab-group mat-tab-header .mat-tab-label-active {
  color: #9fa8da;
}
.indigo-purple.dark app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content:hover {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(255, 255, 255, 0.12) !important;
}
.indigo-purple.dark app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content.selected {
  border-left: 6px solid #9fa8da !important;
}
.indigo-purple.dark app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content.selected mat-icon {
  color: #9fa8da !important;
}
.indigo-purple.dark app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content.selected .item-data {
  color: white !important;
}
.indigo-purple.dark app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content mat-icon {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-purple.dark app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content .item-data {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-purple.dark app-add-documentation .employee-add-documentation .detail app-file-document-view .new-container app-file-document-upload .metadata-list .mat-subheader {
  color: rgba(255, 255, 255, 0.7);
  background: #424242 !important;
}
.indigo-purple.dark app-pending .inbox-toolbar {
  background: #303030;
}
.indigo-purple.dark app-pending .pending-container {
  background: #303030;
}
.indigo-purple.dark app-pending .pending-container .pending-items .pending-item .content:hover {
  background: rgba(255, 255, 255, 0.04) !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(255, 255, 255, 0.12) !important;
}
.indigo-purple.dark app-pending .pending-container .pending-items .pending-item .content.selected {
  border-left: 6px solid #ce93d8 !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(255, 255, 255, 0.12) !important;
}
.indigo-purple.dark app-pending .pending-container .pending-items .pending-item .content.selected mat-icon {
  color: #ce93d8 !important;
}
.indigo-purple.dark app-pending .pending-container .pending-items .pending-item .content.selected .item-data {
  color: white !important;
}
.indigo-purple.dark app-pending .pending-container .pending-items .pending-item .content mat-icon {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-purple.dark app-pending .pending-container .pending-items .pending-item .content .item-data {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-purple.dark app-document-sign-massive .inbox-container .inbox-toolbar {
  background: #303030;
}
.indigo-purple.dark app-document-sign-massive .inbox-container .pending-container {
  background: #303030;
}
.indigo-purple.dark app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content:hover {
  background: rgba(255, 255, 255, 0.04) !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(255, 255, 255, 0.12) !important;
}
.indigo-purple.dark app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content.selected {
  border-left: 6px solid #ce93d8 !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(255, 255, 255, 0.12) !important;
}
.indigo-purple.dark app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content.selected mat-icon {
  color: #ce93d8 !important;
}
.indigo-purple.dark app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content.selected .item-data {
  color: white !important;
}
.indigo-purple.dark app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content mat-icon {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-purple.dark app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content .item-data {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-purple.dark app-document-sign-massive .detail app-file-document-detail .view-container {
  background: #607D8B;
}
.indigo-purple.dark app-document-sign-massive .detail app-file-document-detail .view-container .mat-toolbar {
  background: #607D8B;
  color: rgba(0, 0, 0, 0.87);
  background: #607D8B;
}
.indigo-purple.dark app-document-sign-massive .detail app-chapa.minimized .info-empty {
  background: #ce93d8;
}
.indigo-purple.dark app-document-sign-massive .detail app-chapa.minimized .info-empty > span mat-icon {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-purple.dark app-document-sign-massive .detail app-chapa.minimized .info-empty > span h2 {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-purple.dark app-document-sign-massive .signer.desktop {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark app-document-sign-massive .signer .signer-btn {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-purple.dark app-document-sign-massive .signer .signer-btn[disabled] {
  background-color: rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark app-document-detail app-sign-document .firmador button.mat-raised-button.mat-accent {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-purple.dark mat-toolbar-row.firmado-conforme .mat-icon {
  background: #4caf50;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark mat-toolbar-row.firmado-no-conforme .mat-icon {
  background: #e57373;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark .secondary-toolbar {
  background: #424242;
}
.indigo-purple.dark .new-detail-container {
  border-left: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark .new-detail-container.cancelled .detail_head-body {
  background: #607D8B !important;
}
.indigo-purple.dark .new-detail-container .employee-detail-toolbar {
  background: black;
}
.indigo-purple.dark .new-detail-container .employee-detail-subbar {
  background: #424242;
}
.indigo-purple.dark app-employee-detail .load-window,
.indigo-purple.dark app-employee-rrhh-detail .load-window {
  background: #303030;
}
.indigo-purple.dark app-employee-detail .employee_detail-container,
.indigo-purple.dark app-employee-rrhh-detail .employee_detail-container {
  background: #303030;
}
.indigo-purple.dark app-employee-detail .employee_detail-container.is-editing .edition-line,
.indigo-purple.dark app-employee-rrhh-detail .employee_detail-container.is-editing .edition-line {
  background: black;
}
.indigo-purple.dark app-employee-detail .employee_detail-container .detail_head-body,
.indigo-purple.dark app-employee-rrhh-detail .employee_detail-container .detail_head-body {
  background: #9fa8da;
}
.indigo-purple.dark app-employee-detail .employee_detail-container .detail_head-body .head_body-status .icon-stacked + mat-icon,
.indigo-purple.dark app-employee-rrhh-detail .employee_detail-container .detail_head-body .head_body-status .icon-stacked + mat-icon {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-purple.dark app-employee-detail .employee_detail-container .detail_head-body .head_body-title .body_title-name,
.indigo-purple.dark app-employee-rrhh-detail .employee_detail-container .detail_head-body .head_body-title .body_title-name {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark app-employee-detail .employee_detail-container .detail_head-body .head_body-title .body_title-user,
.indigo-purple.dark app-employee-rrhh-detail .employee_detail-container .detail_head-body .head_body-title .body_title-user {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark app-employee-detail .employee_detail-container .data .data-line,
.indigo-purple.dark app-employee-rrhh-detail .employee_detail-container .data .data-line {
  background: #424242;
}
.indigo-purple.dark app-employee-detail .employee_detail-container .data .data-line .icon-complex mat-icon.complex,
.indigo-purple.dark app-employee-rrhh-detail .employee_detail-container .data .data-line .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #424242, 0 -2px 0px #424242, 2px 0 0px #424242, 0 2px 0px #424242, -2px -2px 0px #424242, 2px 2px 0px #424242, 2px -2px 0px #424242, -2px 2px 0px #424242;
}
.indigo-purple.dark app-employee-detail .employee_detail-container .data .data-line .line_text-main,
.indigo-purple.dark app-employee-rrhh-detail .employee_detail-container .data .data-line .line_text-main {
  color: #9fa8da;
}
.indigo-purple.dark app-employee-detail .employee_detail-container .data .data-line.clickable:hover,
.indigo-purple.dark app-employee-rrhh-detail .employee_detail-container .data .data-line.clickable:hover {
  background: #303030 !important;
}
.indigo-purple.dark app-employee-detail .employee_detail-container .data .data-line button.mat-icon-button,
.indigo-purple.dark app-employee-rrhh-detail .employee_detail-container .data .data-line button.mat-icon-button {
  background: black;
}
.indigo-purple.dark app-employee-detail .employee_detail-container .employee-title .title-text,
.indigo-purple.dark app-employee-rrhh-detail .employee_detail-container .employee-title .title-text {
  color: #9fa8da;
}
.indigo-purple.dark app-employee-detail .employee_detail-container .employee-title .title-text mat-icon,
.indigo-purple.dark app-employee-rrhh-detail .employee_detail-container .employee-title .title-text mat-icon {
  color: #9fa8da;
}
.indigo-purple.dark app-employee-detail .employee_detail-container .employee-title .title-text .title_text-main,
.indigo-purple.dark app-employee-rrhh-detail .employee_detail-container .employee-title .title-text .title_text-main {
  color: #9fa8da;
}
.indigo-purple.dark .UI-V2 .icon-complex mat-icon.complex {
  color: #9fa8da;
  text-shadow: -2px 0 0px #424242, 0 -2px 0px #424242, 2px 0 0px #424242, 0 2px 0px #424242, -2px -2px 0px #424242, 2px 2px 0px #424242, 2px -2px 0px #424242, -2px 2px 0px #424242;
}
.indigo-purple.dark .UI-V2 .icon-complex mat-icon.complex.mat-accent {
  color: #ce93d8;
}
.indigo-purple.dark .UI-V2 .main .toolbar .header .content .module-title {
  color: #9fa8da;
}
.indigo-purple.dark .UI-V2 .main .toolbar .header .content .module-title.selectable:hover {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-purple.dark .UI-V2 .main .toolbar .header .content .module-title .info h5 {
  color: white !important;
}
.indigo-purple.dark .UI-V2 .main .toolbar .header .content .ou-select {
  color: rgba(0, 0, 0, 0.87);
  background: #9fa8da;
}
.indigo-purple.dark .UI-V2 .main .toolbar .search-container .search .content {
  background: #303030;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark .UI-V2 .main .toolbar .search-container .search .content:hover, .indigo-purple.dark .UI-V2 .main .toolbar .search-container .search .content:active, .indigo-purple.dark .UI-V2 .main .toolbar .search-container .search .content:focus, .indigo-purple.dark .UI-V2 .main .toolbar .search-container .search .content:focus-within {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-purple.dark .UI-V2 .main .toolbar .search-container .search .content input {
  color: white;
}
.indigo-purple.dark .UI-V2 .main .helper {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark .UI-V2 .main .helper mat-hint h4 {
  color: #9fa8da;
}
.indigo-purple.dark .UI-V2 .main .new-table app-cs-grid-control datatable-header * {
  color: #9fa8da;
}
.indigo-purple.dark .UI-V2 .main .new-table .mat-header-row.sticky * {
  color: #9fa8da !important;
}
.indigo-purple.dark .UI-V2 .main .main-action {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark .menu-options.help .primary {
  color: #9fa8da;
}
.indigo-purple.dark .menu-options.help .accent {
  color: #ce93d8;
}
.indigo-purple.dark .menu-options .icon-complex mat-icon.complex {
  color: #9fa8da;
  text-shadow: -2px 0 0px #424242, 0 -2px 0px #424242, 2px 0 0px #424242, 0 2px 0px #424242, -2px -2px 0px #424242, 2px 2px 0px #424242, 2px -2px 0px #424242, -2px 2px 0px #424242;
}
.indigo-purple.dark .menu-options .mat-menu-content .container mat-list .mat-list-item:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-purple.dark .menu-options .mat-menu-content .container mat-list .mat-list-item .disabled {
  color: rgba(255, 255, 255, 0.7) !important;
}
.indigo-purple.dark .menu-options .mat-menu-content .container .slide-group mat-radio-group mat-radio-button:hover {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-purple.dark .menu-options .mat-menu-content .container .slide-group mat-form-field .mat-form-field-flex {
  background: #303030;
}
.indigo-purple.dark .menu-options .mat-menu-content .container .slide-group mat-slide-toggle:hover {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-purple.dark .mat-menu-panel.notifications-panel .mat-menu-content .notifications-body {
  background: #303030;
}
.indigo-purple.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification .primary mat-icon {
  color: #9fa8da;
}
.indigo-purple.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification .primary h3 {
  color: #9fa8da;
}
.indigo-purple.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.top {
  background: #303030;
}
.indigo-purple.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.top .header h3 {
  color: white;
}
.indigo-purple.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.bottom {
  background: #303030;
}
.indigo-purple.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable {
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable:nth-child(even) {
  background: #303030;
}
.indigo-purple.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable:nth-child(odd) {
  background: #424242;
}
.indigo-purple.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable:hover {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-purple.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable mat-icon {
  color: #9fa8da;
}
.indigo-purple.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable h3 {
  color: white;
}
.indigo-purple.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable h5 {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-purple.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable .subtitle h5 {
  color: #9fa8da;
  background: black;
}
.indigo-purple.dark .info-empty .large-icon {
  background: #7986cb;
  color: #9fa8da;
}
.indigo-purple.dark .info-empty h2,
.indigo-purple.dark .info-empty h3 {
  color: #9fa8da;
}
.indigo-purple.dark .info-empty h4,
.indigo-purple.dark .info-empty h5 {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-purple.dark .info-empty.accent mat-icon {
  background: #ce93d8;
  color: #ce93d8;
}
.indigo-purple.dark .info-empty.accent h2 {
  color: #ce93d8;
}
.indigo-purple.dark .info-empty .actions {
  padding: 16px;
  height: 96px;
  column-gap: 16px;
}
.indigo-purple.dark .info-empty .actions button {
  height: 100%;
  border-radius: 40px;
}
.indigo-purple.dark .info-empty .actions button.ok {
  background: #4db6ac !important;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark .info-empty .actions button.not-ok {
  color: #ba68c8 !important;
}
.indigo-purple.dark .side.edition .info-empty .large-icon {
  background: #ce93d8;
  color: #ce93d8;
}
.indigo-purple.dark .side.edition .info-empty h2 {
  color: #ce93d8;
}
.indigo-purple.dark .side.edition .info-empty h4 {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-purple.dark .UI-V2 .deferred-process-list .header h3 {
  color: #9fa8da;
}
.indigo-purple.dark .UI-V2 .deferred-process-list .search-container .search .content {
  background: #303030;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark .UI-V2 .deferred-process-list .search-container .search .content:hover, .indigo-purple.dark .UI-V2 .deferred-process-list .search-container .search .content:active, .indigo-purple.dark .UI-V2 .deferred-process-list .search-container .search .content:focus, .indigo-purple.dark .UI-V2 .deferred-process-list .search-container .search .content:focus-within {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-purple.dark .UI-V2 .deferred-process-list .search-container .search .content input {
  color: white;
}
.indigo-purple.dark .UI-V2 .deferred-process-list .text-success,
.indigo-purple.dark .UI-V2 .deferred-process-details .text-success {
  color: #4caf50;
}
.indigo-purple.dark .UI-V2 .deferred-process-list .text-warn,
.indigo-purple.dark .UI-V2 .deferred-process-details .text-warn {
  color: #e57373;
}
.indigo-purple.dark .UI-V2 .deferred-process-list .text-primary,
.indigo-purple.dark .UI-V2 .deferred-process-details .text-primary {
  color: #9fa8da;
}
.indigo-purple.dark .UI-V2 .deferred-process-list .text-atention,
.indigo-purple.dark .UI-V2 .deferred-process-details .text-atention {
  color: #DB7703;
}
.indigo-purple.dark .UI-V2 .deferred-process-list .table-paginator-container,
.indigo-purple.dark .UI-V2 .deferred-process-details .table-paginator-container {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
}
.indigo-purple.dark .UI-V2 .deferred-process-list .table-paginator-container .mat-table,
.indigo-purple.dark .UI-V2 .deferred-process-details .table-paginator-container .mat-table {
  border-bottom: none;
  border-radius: 10px 10px 0 0;
}
.indigo-purple.dark .UI-V2 .deferred-process-list .table-paginator-container .paginator-container,
.indigo-purple.dark .UI-V2 .deferred-process-details .table-paginator-container .paginator-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: #f2f2f2;
  padding: 0 1.5rem;
  height: 3rem;
}
.indigo-purple.dark .UI-V2 .deferred-process-list .mat-table,
.indigo-purple.dark .UI-V2 .deferred-process-details .mat-table {
  border: 1px solid #f2f2f2;
  border-radius: 10px;
  overflow: hidden;
}
.indigo-purple.dark .UI-V2 .deferred-process-list .mat-table .mat-header-row .mat-header-cell,
.indigo-purple.dark .UI-V2 .deferred-process-details .mat-table .mat-header-row .mat-header-cell {
  font-size: 14px;
  font-weight: 700;
}
.indigo-purple.dark .UI-V2 .deferred-process-list .mat-table .mat-header-row,
.indigo-purple.dark .UI-V2 .deferred-process-details .mat-table .mat-header-row {
  background: #f2f2f2;
}
.indigo-purple.dark .UI-V2 .deferred-process-list .mat-table .mat-header-row .mat-header-cell,
.indigo-purple.dark .UI-V2 .deferred-process-details .mat-table .mat-header-row .mat-header-cell {
  color: #9fa8da;
}
.indigo-purple.dark .UI-V2 .deferred-process-list .mat-table .mat-row,
.indigo-purple.dark .UI-V2 .deferred-process-details .mat-table .mat-row {
  background: #424242;
}
.indigo-purple.dark .UI-V2 .progress-bar-container {
  position: relative;
}
.indigo-purple.dark .UI-V2 .deferred-process-details .header h3 {
  color: #9fa8da;
}
.indigo-purple.dark .UI-V2 .deferred-process-details .header .back-link:visited {
  color: #9fa8da;
}
.indigo-purple.dark .process-card,
.indigo-purple.dark .process-card-detail,
.indigo-purple.dark .process-card-stages {
  background: #303030;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark .process-card mat-card-subtitle,
.indigo-purple.dark .process-card-detail mat-card-subtitle,
.indigo-purple.dark .process-card-stages mat-card-subtitle {
  color: white;
}
.indigo-purple.dark .process-card .stages-container .stage.completed mat-icon,
.indigo-purple.dark .process-card-detail .stages-container .stage.completed mat-icon,
.indigo-purple.dark .process-card-stages .stages-container .stage.completed mat-icon {
  color: #499719;
}
.indigo-purple.dark .process-card .stages-container .stage.completed .icon-container,
.indigo-purple.dark .process-card-detail .stages-container .stage.completed .icon-container,
.indigo-purple.dark .process-card-stages .stages-container .stage.completed .icon-container {
  background-color: rgba(78, 160, 28, 0.1490196078);
}
.indigo-purple.dark .process-card .stages-container .stage.completed .line,
.indigo-purple.dark .process-card-detail .stages-container .stage.completed .line,
.indigo-purple.dark .process-card-stages .stages-container .stage.completed .line {
  background-color: #499719;
}
.indigo-purple.dark .process-card .stages-container .stage.on-going mat-icon,
.indigo-purple.dark .process-card-detail .stages-container .stage.on-going mat-icon,
.indigo-purple.dark .process-card-stages .stages-container .stage.on-going mat-icon {
  color: #9fa8da;
}
.indigo-purple.dark .process-card .stages-container .stage.on-going .icon-container,
.indigo-purple.dark .process-card-detail .stages-container .stage.on-going .icon-container,
.indigo-purple.dark .process-card-stages .stages-container .stage.on-going .icon-container {
  background-color: rgba(66, 48, 159, 0.2);
}
.indigo-purple.dark .process-card .stages-container .stage.on-going .line,
.indigo-purple.dark .process-card-detail .stages-container .stage.on-going .line,
.indigo-purple.dark .process-card-stages .stages-container .stage.on-going .line {
  background-color: #949494;
}
.indigo-purple.dark .process-card .stages-container .stage.pending,
.indigo-purple.dark .process-card-detail .stages-container .stage.pending,
.indigo-purple.dark .process-card-stages .stages-container .stage.pending {
  color: #949494;
}
.indigo-purple.dark .process-card .stages-container .stage.pending mat-icon,
.indigo-purple.dark .process-card-detail .stages-container .stage.pending mat-icon,
.indigo-purple.dark .process-card-stages .stages-container .stage.pending mat-icon {
  color: #949494;
}
.indigo-purple.dark .process-card .stages-container .stage.pending .icon-container,
.indigo-purple.dark .process-card-detail .stages-container .stage.pending .icon-container,
.indigo-purple.dark .process-card-stages .stages-container .stage.pending .icon-container {
  background-color: rgba(174, 174, 174, 0.2);
}
.indigo-purple.dark .process-card .stages-container .stage.pending .line,
.indigo-purple.dark .process-card-detail .stages-container .stage.pending .line,
.indigo-purple.dark .process-card-stages .stages-container .stage.pending .line {
  background-color: #949494;
}
.indigo-purple.dark .process-card .stages-container .stage.has-error mat-icon,
.indigo-purple.dark .process-card-detail .stages-container .stage.has-error mat-icon,
.indigo-purple.dark .process-card-stages .stages-container .stage.has-error mat-icon {
  color: #e57373;
}
.indigo-purple.dark .process-card .stages-container .stage.has-error .icon-container,
.indigo-purple.dark .process-card-detail .stages-container .stage.has-error .icon-container,
.indigo-purple.dark .process-card-stages .stages-container .stage.has-error .icon-container {
  background-color: rgba(188, 4, 4, 0.2);
}
.indigo-purple.dark .process-card .stages-container .stage-description .button-link,
.indigo-purple.dark .process-card-detail .stages-container .stage-description .button-link,
.indigo-purple.dark .process-card-stages .stages-container .stage-description .button-link {
  color: #9fa8da;
}
.indigo-purple.dark app-custom-search-input .custom-search-container {
  background: #303030;
  border: 1px solid rgba(255, 255, 255, 0.12);
  transition: color 0.1s ease, background 0.15s ease;
}
.indigo-purple.dark app-custom-search-input .custom-search-container mat-icon.mat-icon {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-purple.dark app-custom-search-input .custom-search-container input.custom-search-input {
  color: white;
  background: unset;
}
.indigo-purple.dark app-custom-search-input .custom-search-container:hover, .indigo-purple.dark app-custom-search-input .custom-search-container:active, .indigo-purple.dark app-custom-search-input .custom-search-container:focus, .indigo-purple.dark app-custom-search-input .custom-search-container:focus-within {
  background: rgba(255, 255, 255, 0.04) !important;
  transition: background 0.15s ease;
}
.indigo-purple.dark .UI-V2 .audit-filter {
  background: #424242;
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark .UI-V2 .audit-filter .toolbar {
  background: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark .UI-V2 .audit-filter .toolbar .header .content .module-title {
  color: #9fa8da;
}
.indigo-purple.dark .UI-V2 .audit-filter .toolbar .header .content .module-title.selectable:hover {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-purple.dark .UI-V2 .audit-filter .toolbar .header .content .module-title .info * {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-purple.dark .UI-V2 .audit-filter .toolbar .header .content .ou-select {
  color: rgba(0, 0, 0, 0.87);
  background: #9fa8da;
}
.indigo-purple.dark .UI-V2 .audit-filter .toolbar .search-container .search .content {
  background: #303030;
}
.indigo-purple.dark .UI-V2 .audit-filter .toolbar .search-container .search .content:hover, .indigo-purple.dark .UI-V2 .audit-filter .toolbar .search-container .search .content:active, .indigo-purple.dark .UI-V2 .audit-filter .toolbar .search-container .search .content:focus, .indigo-purple.dark .UI-V2 .audit-filter .toolbar .search-container .search .content:focus-within {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-purple.dark .UI-V2 .audit-filter .toolbar .search-container .search .content input {
  color: white;
}
.indigo-purple.dark .UI-V2 .audit-filter .filter-container {
  background: #424242 !important;
}
.indigo-purple.dark .UI-V2 .audit-filter .filter-container .form-body {
  background: #303030 !important;
}
.indigo-purple.dark .UI-V2 .audit-filter .filter-container .form-body .line h4 {
  color: #9fa8da;
}
.indigo-purple.dark .UI-V2 .audit-filter .filter-container .form-body .line mat-icon {
  color: #9fa8da;
}
.indigo-purple.dark .UI-V2 .audit-result .result-container .toolbar {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark .UI-V2 .audit-result .result-container .toolbar .header .content .module-title {
  color: #9fa8da;
}
.indigo-purple.dark .UI-V2 .audit-result .result-container .toolbar .header .content .module-title.selectable:hover {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-purple.dark .UI-V2 .audit-result .result-container .toolbar .header .content .module-title .info h5 {
  color: white !important;
}
.indigo-purple.dark .UI-V2 .audit-result .result-container .toolbar .header .content .ou-select {
  color: rgba(0, 0, 0, 0.87);
  background: #9fa8da;
}
.indigo-purple.dark .UI-V2 .audit-result .result-container .toolbar .search-container .search .content {
  background: #303030;
}
.indigo-purple.dark .UI-V2 .audit-result .result-container .toolbar .search-container .search .content:hover, .indigo-purple.dark .UI-V2 .audit-result .result-container .toolbar .search-container .search .content:active, .indigo-purple.dark .UI-V2 .audit-result .result-container .toolbar .search-container .search .content:focus, .indigo-purple.dark .UI-V2 .audit-result .result-container .toolbar .search-container .search .content:focus-within {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-purple.dark .UI-V2 .audit-result .result-container .toolbar .search-container .search .content input {
  color: white;
}
.indigo-purple.dark .UI-V2 .audit-result .result-container .new-table app-cs-grid-control datatable-header * {
  color: #9fa8da;
}
.indigo-purple.dark .UI-V2 .audit-result .result-container .new-table .mat-header-row.sticky * {
  color: #9fa8da !important;
}
.indigo-purple.dark .UI-V2 .audit-detail-container {
  border-left: 1px solid rgba(255, 255, 255, 0.12);
  background: black !important;
}
.indigo-purple.dark .UI-V2 .audit-detail-container .side .toolbar .header {
  color: #9fa8da;
}
.indigo-purple.dark .UI-V2 .audit-detail-container .side .container .mat-list-base {
  background: #303030 !important;
}
.indigo-purple.dark .UI-V2 .audit-detail-container .side .container .mat-list-base mat-list-item .mat-line b {
  color: #9fa8da;
}
.indigo-purple.dark .UI-V2 .inbox-container {
  background: #424242;
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark .UI-V2 .inbox-container *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.indigo-purple.dark .UI-V2 .inbox-container *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
.indigo-purple.dark .UI-V2 .inbox-container *::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}
.indigo-purple.dark .UI-V2 .inbox-container .load-window {
  background: #424242;
}
.indigo-purple.dark .UI-V2 .inbox-container .toolbar {
  background: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark .UI-V2 .inbox-container .toolbar.reverse {
  background: #424242;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel {
  background: black !important;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel.mat-expanded {
  background: #303030 !important;
}
.indigo-purple.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel.mat-expanded mat-expansion-panel-header {
  background: #303030 !important;
}
.indigo-purple.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel.mat-expanded mat-expansion-panel-header:hover {
  background: none !important;
}
.indigo-purple.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel mat-expansion-panel-header {
  background: #303030 !important;
}
.indigo-purple.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel mat-expansion-panel-header:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-purple.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel mat-expansion-panel-header .signature-inbox-header .mat-icon,
.indigo-purple.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel mat-expansion-panel-header .signature-inbox-header b {
  color: #ce93d8;
}
.indigo-purple.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel .mat-expansion-panel-body .item {
  background: #424242;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel .mat-expansion-panel-body .item:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-purple.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel .mat-expansion-panel-body .item.active {
  border-left: 6px solid #ce93d8;
}
.indigo-purple.dark .UI-V2 .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.indigo-purple.dark .UI-V2 .work-space .main .load-window {
  background: #424242 !important;
}
.indigo-purple.dark .UI-V2 .work-space .main-action {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark mat-radio-button.disabled .mat-radio-label-content {
  color: #ce93d8;
}
.indigo-purple.dark .detail app-automatic-process-detail app-chapa.minimize,
.indigo-purple.dark .detail app-process-detail app-chapa.minimize {
  background: #ffc107 !important;
}
.indigo-purple.dark .detail app-automatic-process-detail app-chapa.minimize.purged,
.indigo-purple.dark .detail app-process-detail app-chapa.minimize.purged {
  background: #b2adb0 !important;
}
.indigo-purple.dark .detail .detail-toolbar {
  background: black;
}
.indigo-purple.dark .detail .processes-status .status-bar .bar-graph {
  background-color: #ffc107;
}
.indigo-purple.dark .detail .processes-status .status-bar .bar-graph .graph-ok {
  background-color: #009688;
}
.indigo-purple.dark .detail .processes-status .status-bar .bar-graph .graph-not-ok {
  background-color: #e91e63;
}
.indigo-purple.dark .detail .processes-status .status-resume b {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark .detail .processes-status .status-resume .resume-total b {
  background-color: #ffc107;
}
.indigo-purple.dark .detail .processes-status .status-resume .resume-ok b {
  background-color: #009688;
}
.indigo-purple.dark .detail .processes-status .status-resume .resume-not-ok b {
  background-color: #e91e63;
}
.indigo-purple.dark .detail .detail-toolbar .mat-avatar h3,
.indigo-purple.dark .detail .detail-toolbar .mat-avatar h5 {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark .detail .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark .detail .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark .detail .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark .detail .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark .detail .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  background: #009688 !important;
}
.indigo-purple.dark .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  background: #b2dfdb !important;
}
.indigo-purple.dark .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  background: #b2dfdb !important;
}
.indigo-purple.dark .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  background: #009688 !important;
}
.indigo-purple.dark .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  background: #b2dfdb !important;
}
.indigo-purple.dark .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  background: #837C80 !important;
}
.indigo-purple.dark .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  background: #b2adb0 !important;
}
.indigo-purple.dark .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  background: #b2adb0 !important;
}
.indigo-purple.dark .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  background: #837C80 !important;
}
.indigo-purple.dark .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  background: #b2dfdb !important;
}
.indigo-purple.dark .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  background: #ffc107 !important;
}
.indigo-purple.dark .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  background: #ffecb3 !important;
}
.indigo-purple.dark .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  background: #ffecb3 !important;
}
.indigo-purple.dark .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  background: #ffc107 !important;
}
.indigo-purple.dark .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  background: #ffecb3 !important;
}
.indigo-purple.dark .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  background: #e91e63 !important;
}
.indigo-purple.dark .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  background: #f8bbd0 !important;
}
.indigo-purple.dark .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  background: #f8bbd0 !important;
}
.indigo-purple.dark .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  background: #e91e63 !important;
}
.indigo-purple.dark .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  background: #f8bbd0 !important;
}
.indigo-purple.dark app-analytics-document-set .sets-container .mat-list-base.sets-list mat-list-item {
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark app-analytics-document-set .sets-container .mat-list-base.sets-list mat-list-item .mat-list-item-content .set-status .value-percentile .undone-value {
  background: #673AB7;
}
.indigo-purple.dark app-analytics-document-set .sets-container .mat-list-base.sets-list mat-list-item .mat-list-item-content .set-status .value-percentile .not-value {
  background: black;
}
.indigo-purple.dark app-analytics-document-set .sets-container .mat-list-base.sets-list mat-list-item .mat-list-item-content .set-status .value-percentile .done-value {
  background: #009688;
}
.indigo-purple.dark app-analytics-document-set .work-space .main .toolbar .header .content .module-title .info .info-text .set-status {
  cursor: help;
}
.indigo-purple.dark app-analytics-document-set .work-space .main .toolbar .header .content .module-title .info .info-text .set-status .value-percentile .undone-value {
  background: #673AB7;
}
.indigo-purple.dark app-analytics-document-set .work-space .main .toolbar .header .content .module-title .info .info-text .set-status .value-percentile .not-value {
  background: black;
}
.indigo-purple.dark app-analytics-document-set .work-space .main .toolbar .header .content .module-title .info .info-text .set-status .value-percentile .done-value {
  background: #009688;
}
.indigo-purple.dark app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar .mat-progress-bar-buffer {
  background-color: black;
}
.indigo-purple.dark app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar.class1 .mat-progress-bar-fill::after {
  background-color: #f44336;
}
.indigo-purple.dark app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar.class2 .mat-progress-bar-fill::after {
  background-color: #ff9800;
}
.indigo-purple.dark app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar.class3 .mat-progress-bar-fill::after {
  background-color: #ffeb3b;
}
.indigo-purple.dark app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar.class4 .mat-progress-bar-fill::after {
  background-color: #8bc34a;
}
.indigo-purple.dark app-analytics-document-set .work-space .side .container mat-list {
  background: #424242 !important;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark app-analytics-document-set .work-space .side .container mat-list mat-list-item .mat-line b {
  color: #9fa8da;
}
.indigo-purple.dark .catcher {
  background: rgba(0, 0, 0, 0.6);
}
.indigo-purple.dark .catcher.clear {
  background: rgba(0, 0, 0, 0);
}
.indigo-purple.dark .add-menu-form-body .mat-radio-group .mat-radio-button:hover {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-purple.dark .add-menu-form-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b {
  color: white;
}
.indigo-purple.dark .add-menu-form-body.w-table .helper .search-bar {
  background: #303030;
}
.indigo-purple.dark .add-menu-form-body.w-table .helper .search-bar:hover, .indigo-purple.dark .add-menu-form-body.w-table .helper .search-bar:active, .indigo-purple.dark .add-menu-form-body.w-table .helper .search-bar:focus, .indigo-purple.dark .add-menu-form-body.w-table .helper .search-bar:focus-within {
  background: #303030 !important;
}
.indigo-purple.dark .add-menu-form-body.w-table .helper .search-bar input {
  color: white;
}
.indigo-purple.dark .add-menu-form-actions {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark .file-upload-list mat-list-item {
  background: #424242;
}
.indigo-purple.dark .mat-horizontal-stepper-content[aria-expanded=true] app-file-document-view {
  border-left: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark .mat-horizontal-stepper-content[aria-expanded=true] app-file-document-view .pdf-view-container {
  background: black;
}
.indigo-purple.dark .add-docs-drop .docs-drop-preview app-file-document-detail {
  border-left: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark .add-docs-drop .docs-drop-preview app-file-document-detail .view-toolbar {
  background: #424242;
}
.indigo-purple.dark .add-docs-drop .load-window.message h3.primary-text {
  color: #9fa8da;
}
.indigo-purple.dark mat-dialog-container app-add-menu-dialog .load-window.message h3.primary-text {
  color: #9fa8da;
}
.indigo-purple.dark .asisted-input.aligned-icon.subtitle * {
  color: rgba(255, 255, 255, 0.5) !important;
}
.indigo-purple.dark .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble {
  background: #9fa8da;
}
.indigo-purple.dark .asisted-input .mat-form-field-invalid + .indicator > .bubble {
  background: #e57373;
}
.indigo-purple.dark .asisted-input .mat-form-field-disabled + .indicator > .bubble {
  color: rgba(255, 255, 255, 0.5);
  background: rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark .asisted-input .indicator .bubble {
  background: rgba(255, 255, 255, 0.5);
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark .asisted-input .indicator .guide {
  border-color: rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark .asisted-input.icon-only .bubble {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-purple.dark .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble {
  color: #9fa8da;
}
.indigo-purple.dark .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble {
  color: #e57373;
}
.indigo-purple.dark .UI-V2 .main-overlay {
  background: rgba(0, 0, 0, 0.32);
}
.indigo-purple.dark .UI-V2 .icon-complex mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.indigo-purple.dark .UI-V2 .icon-complex mat-icon.complex {
  color: #9fa8da !important;
}
.indigo-purple.dark .UI-V2 .navigation {
  background: #303030;
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark .UI-V2 .navigation *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.indigo-purple.dark .UI-V2 .navigation *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
.indigo-purple.dark .UI-V2 .navigation .load-window {
  background: black;
}
.indigo-purple.dark .UI-V2 .navigation .toolbar {
  background: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark .UI-V2 .navigation .toolbar.reverse {
  background: #424242;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark .UI-V2 .navigation .container .helper {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-purple.dark .UI-V2 .navigation .container .item {
  background: #424242;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark .UI-V2 .navigation .container .item:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-purple.dark .UI-V2 .navigation .container .item.active {
  background: #9fa8da !important;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #9fa8da;
}
.indigo-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .separator .subtitle,
.indigo-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .separator .subtitle,
.indigo-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .separator .subtitle,
.indigo-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .separator .subtitle,
.indigo-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .separator .subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .img-editior-container .img-editor .separator .subtitle,
.indigo-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .img-editior-container .img-editor .separator .subtitle,
.indigo-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .img-editior-container .img-editor .separator .subtitle,
.indigo-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .img-editior-container .img-editor .separator .subtitle,
.indigo-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .img-editior-container .img-editor .separator .subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme,
.indigo-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme,
.indigo-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme,
.indigo-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme,
.indigo-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .main-color-container .main-color,
.indigo-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .main-color-container .main-color,
.indigo-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .main-color-container .main-color,
.indigo-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .main-color-container .main-color,
.indigo-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .main-color-container .main-color {
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .main-color-container .main-color:before,
.indigo-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .main-color-container .main-color:before,
.indigo-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .main-color-container .main-color:before,
.indigo-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .main-color-container .main-color:before,
.indigo-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .main-color-container .main-color:before {
  border-left: 10px solid rgba(0, 0, 0, 0);
  border-right: 10px solid rgba(0, 0, 0, 0);
}
.indigo-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example h6.required,
.indigo-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example h6.required,
.indigo-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example h6.required,
.indigo-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example h6.required,
.indigo-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example h6.required {
  color: #e57373 !important;
}
.indigo-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle,
.indigo-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle,
.indigo-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle,
.indigo-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle,
.indigo-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle {
  border: 2px solid rgba(0, 0, 0, 0);
}
.indigo-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .side-color-container .side-color,
.indigo-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .side-color-container .side-color,
.indigo-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .side-color-container .side-color,
.indigo-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .side-color-container .side-color,
.indigo-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .side-color-container .side-color {
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .side-color-container .side-color:before,
.indigo-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .side-color-container .side-color:before,
.indigo-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .side-color-container .side-color:before,
.indigo-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .side-color-container .side-color:before,
.indigo-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .side-color-container .side-color:before {
  border-top: 10px solid rgba(0, 0, 0, 0);
  border-bottom: 10px solid rgba(0, 0, 0, 0);
}
.indigo-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .side-color-container .side-color h6.required,
.indigo-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .side-color-container .side-color h6.required,
.indigo-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .side-color-container .side-color h6.required,
.indigo-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .side-color-container .side-color h6.required,
.indigo-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .side-color-container .side-color h6.required {
  color: #e57373 !important;
}
.indigo-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .permission-settings-cards .card,
.indigo-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .permission-settings-cards .card,
.indigo-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .permission-settings-cards .card,
.indigo-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .permission-settings-cards .card,
.indigo-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .permission-settings-cards .card {
  background: #303030;
}
.indigo-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .permission-settings-cards .card .slide-sub-group,
.indigo-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .permission-settings-cards .card .slide-sub-group,
.indigo-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .permission-settings-cards .card .slide-sub-group,
.indigo-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .permission-settings-cards .card .slide-sub-group,
.indigo-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .permission-settings-cards .card .slide-sub-group {
  background: rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark .UI-V2 .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.indigo-purple.dark .UI-V2 .work-space .main .load-window {
  background: #424242 !important;
}
.indigo-purple.dark .UI-V2 .work-space .main.side-open {
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item {
  background: #303030;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item mat-icon.complex {
  text-shadow: -2px 0 0px black, 0 -2px 0px black, 2px 0 0px black, 0 2px 0px black, -2px -2px 0px black, 2px 2px 0px black, 2px -2px 0px black, -2px 2px 0px black !important;
}
.indigo-purple.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item h5 {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-purple.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item h3 {
  color: white;
}
.indigo-purple.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item .actions {
  pointer-events: none;
}
.indigo-purple.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item .actions button {
  pointer-events: all;
}
.indigo-purple.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled {
  background: black;
}
.indigo-purple.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled h3 {
  color: #607D8B !important;
}
.indigo-purple.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled mat-icon {
  color: #607D8B !important;
}
.indigo-purple.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled.selected {
  background: #607D8B;
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-purple.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled.selected h3 {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-purple.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled.selected mat-icon {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-purple.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled.selected mat-icon.complex {
  color: rgba(0, 0, 0, 0.87) !important;
  text-shadow: -2px 0 0px #607D8B, 0 -2px 0px #607D8B, 2px 0 0px #607D8B, 0 2px 0px #607D8B, -2px -2px 0px #607D8B, 2px 2px 0px #607D8B, 2px -2px 0px #607D8B, -2px 2px 0px #607D8B !important;
}
.indigo-purple.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item:hover {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-purple.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected {
  background: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected mat-icon {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected mat-icon.complex {
  color: rgba(0, 0, 0, 0.87) !important;
  text-shadow: -2px 0 0px #9fa8da, 0 -2px 0px #9fa8da, 2px 0 0px #9fa8da, 0 2px 0px #9fa8da, -2px -2px 0px #9fa8da, 2px 2px 0px #9fa8da, 2px -2px 0px #9fa8da, -2px 2px 0px #9fa8da !important;
}
.indigo-purple.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected h5,
.indigo-purple.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected h3 {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark .UI-V2 .work-space .main .main-action {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark .UI-V2 .work-space .side {
  background: #303030;
}
.indigo-purple.dark .UI-V2 .work-space .side .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #303030, 0 -2px 0px #303030, 2px 0 0px #303030, 0 2px 0px #303030, -2px -2px 0px #303030, 2px 2px 0px #303030, 2px -2px 0px #303030, -2px 2px 0px #303030;
}
.indigo-purple.dark .UI-V2 .work-space .side *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.indigo-purple.dark .UI-V2 .work-space .side *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
.indigo-purple.dark .UI-V2 .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #303030 !important;
}
.indigo-purple.dark .UI-V2 .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-purple.dark .UI-V2 .work-space .side mat-form-field .mat-form-field-flex {
  background: #303030;
}
.indigo-purple.dark .UI-V2 .work-space .side mat-checkbox:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-purple.dark .UI-V2 .work-space .side .hint.all {
  color: #ce93d8;
}
.indigo-purple.dark .UI-V2 .work-space .side .toolbar .header {
  color: #9fa8da;
}
.indigo-purple.dark .UI-V2 .work-space .side .toolbar .header h3 {
  color: #9fa8da;
}
.indigo-purple.dark .UI-V2 .work-space .side .container .role-resume {
  background: #9fa8da;
}
.indigo-purple.dark .UI-V2 .work-space .side .container .role-resume .line {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark .UI-V2 .work-space .side .container .role-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.1);
}
.indigo-purple.dark .UI-V2 .work-space .side .container .role-resume .line.editable button {
  background: #9fa8da;
}
.indigo-purple.dark .UI-V2 .work-space .side .container .section-title {
  color: #9fa8da;
}
.indigo-purple.dark .UI-V2 .work-space .side .container .section-resume {
  background: #424242;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark .UI-V2 .work-space .side .container .section-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-purple.dark .UI-V2 .work-space .side .container .section-resume .line.editable:hover .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #424242, 0 -2px 0px #424242, 2px 0 0px #424242, 0 2px 0px #424242, -2px -2px 0px #424242, 2px 2px 0px #424242, 2px -2px 0px #424242, -2px 2px 0px #424242 !important;
}
.indigo-purple.dark .UI-V2 .work-space .side .container .section-resume .line.editable button {
  background: #303030;
}
.indigo-purple.dark .UI-V2 .work-space .side .container .section-resume .line .header .icon-complex mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.indigo-purple.dark .UI-V2 .work-space .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #9fa8da !important;
}
.indigo-purple.dark .UI-V2 .work-space .side .container .section-resume .line .header mat-icon:not(.complex) {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-purple.dark .UI-V2 .work-space .side .container .section-resume .line .header .info h4 {
  color: #9fa8da;
}
.indigo-purple.dark .UI-V2 .work-space .side .container .section-resume .line ul {
  color: #9fa8da;
}
.indigo-purple.dark .UI-V2 .work-space .side .container .section-resume .line.head {
  background: black;
}
.indigo-purple.dark .UI-V2 .work-space .side .container .section-resume .line.head mat-icon {
  color: #ce93d8;
}
.indigo-purple.dark .UI-V2 .work-space .side .container .section-resume .line.head h4 {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark .UI-V2 .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body {
  background: #424242;
}
.indigo-purple.dark .UI-V2 .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-purple.dark .UI-V2 .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #9fa8da;
}
.indigo-purple.dark .UI-V2 .work-space .side.edition .toolbar .header {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark .UI-V2 .work-space .side.edition .toolbar .header mat-icon {
  color: #ce93d8;
}
.indigo-purple.dark .UI-V2 .work-space .side.edition .toolbar .header h3 {
  color: #ce93d8;
}
.indigo-purple.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body {
  background: #424242 !important;
}
.indigo-purple.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header mat-icon:not(.complex) {
  color: #9fa8da;
}
.indigo-purple.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header h4 {
  color: #9fa8da;
}
.indigo-purple.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-actions {
  background: #303030;
}
.indigo-purple.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.indigo-purple.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header .icon-complex mat-icon.complex {
  color: #ce93d8 !important;
}
.indigo-purple.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header h4 {
  color: #ce93d8;
}
.indigo-purple.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line ul {
  color: #ce93d8;
  font-weight: 400;
}
.indigo-purple.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head mat-icon {
  color: #ce93d8 !important;
}
.indigo-purple.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head h4 {
  color: white;
}
.indigo-purple.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .section-title {
  color: white !important;
}
.indigo-purple.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume h6.hint {
  background: #ce93d8;
}
.indigo-purple.dark app-role-user-find .main-overlay {
  background: rgba(0, 0, 0, 0.32);
}
.indigo-purple.dark app-role-user-find .icon-complex mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.indigo-purple.dark app-role-user-find .icon-complex mat-icon.complex {
  color: #9fa8da !important;
}
.indigo-purple.dark app-role-user-find .navigation {
  background: #303030;
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark app-role-user-find .navigation *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.indigo-purple.dark app-role-user-find .navigation *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
.indigo-purple.dark app-role-user-find .navigation .load-window {
  background: black;
}
.indigo-purple.dark app-role-user-find .navigation .toolbar {
  background: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark app-role-user-find .navigation .toolbar.reverse {
  background: #424242;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark app-role-user-find .navigation .container .helper {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-purple.dark app-role-user-find .navigation .container .item {
  background: #424242;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark app-role-user-find .navigation .container .item:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-purple.dark app-role-user-find .navigation .container .item.active {
  background: #9fa8da !important;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #9fa8da;
}
.indigo-purple.dark app-role-user-find .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.indigo-purple.dark app-role-user-find .work-space .main .load-window {
  background: #424242 !important;
}
.indigo-purple.dark app-role-user-find .work-space .main.side-open {
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark app-role-user-find .work-space .main .container .item {
  background: #303030;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark app-role-user-find .work-space .main .container .item .subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-purple.dark app-role-user-find .work-space .main .container .item.disabled {
  background: black;
  color: #607D8B !important;
}
.indigo-purple.dark app-role-user-find .work-space .main .container .item.disabled h3 {
  color: #607D8B !important;
}
.indigo-purple.dark app-role-user-find .work-space .main .container .item.disabled mat-icon {
  color: #607D8B !important;
}
.indigo-purple.dark app-role-user-find .work-space .main .container .item.disabled.selected {
  background: #607D8B;
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-purple.dark app-role-user-find .work-space .main .container .item.disabled.selected h3 {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-purple.dark app-role-user-find .work-space .main .container .item.disabled.selected mat-icon {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-purple.dark app-role-user-find .work-space .main .container .item.disabled.selected mat-icon.complex {
  color: rgba(0, 0, 0, 0.87) !important;
  text-shadow: -2px 0 0px #607D8B, 0 -2px 0px #607D8B, 2px 0 0px #607D8B, 0 2px 0px #607D8B, -2px -2px 0px #607D8B, 2px 2px 0px #607D8B, 2px -2px 0px #607D8B, -2px 2px 0px #607D8B !important;
}
.indigo-purple.dark app-role-user-find .work-space .main .container .item mat-icon.complex {
  text-shadow: -2px 0 0px black, 0 -2px 0px black, 2px 0 0px black, 0 2px 0px black, -2px -2px 0px black, 2px 2px 0px black, 2px -2px 0px black, -2px 2px 0px black !important;
}
.indigo-purple.dark app-role-user-find .work-space .main .container .item h5 {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-purple.dark app-role-user-find .work-space .main .container .item h3 {
  color: white;
}
.indigo-purple.dark app-role-user-find .work-space .main .container .item:hover {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-purple.dark app-role-user-find .work-space .main .container .item.selected {
  background: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark app-role-user-find .work-space .main .container .item.selected mat-icon {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark app-role-user-find .work-space .main .container .item.selected mat-icon.complex {
  color: rgba(0, 0, 0, 0.87) !important;
  text-shadow: -2px 0 0px #9fa8da, 0 -2px 0px #9fa8da, 2px 0 0px #9fa8da, 0 2px 0px #9fa8da, -2px -2px 0px #9fa8da, 2px 2px 0px #9fa8da, 2px -2px 0px #9fa8da, -2px 2px 0px #9fa8da !important;
}
.indigo-purple.dark app-role-user-find .work-space .main .container .item.selected h5,
.indigo-purple.dark app-role-user-find .work-space .main .container .item.selected h3 {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark app-role-user-find .work-space .main .main-action {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark app-role-user-find .work-space .side {
  background: #303030;
}
.indigo-purple.dark app-role-user-find .work-space .side .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #303030, 0 -2px 0px #303030, 2px 0 0px #303030, 0 2px 0px #303030, -2px -2px 0px #303030, 2px 2px 0px #303030, 2px -2px 0px #303030, -2px 2px 0px #303030;
}
.indigo-purple.dark app-role-user-find .work-space .side *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.indigo-purple.dark app-role-user-find .work-space .side *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
.indigo-purple.dark app-role-user-find .work-space .side .edition-line {
  background: black;
}
.indigo-purple.dark app-role-user-find .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #303030 !important;
}
.indigo-purple.dark app-role-user-find .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-purple.dark app-role-user-find .work-space .side mat-form-field .mat-form-field-flex {
  background: #303030;
}
.indigo-purple.dark app-role-user-find .work-space .side mat-checkbox:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-purple.dark app-role-user-find .work-space .side .hint.all {
  color: #ce93d8;
}
.indigo-purple.dark app-role-user-find .work-space .side .toolbar .header {
  color: #9fa8da;
}
.indigo-purple.dark app-role-user-find .work-space .side .toolbar .header h3 {
  color: #9fa8da;
}
.indigo-purple.dark app-role-user-find .work-space .side .container .role-resume {
  background: #9fa8da;
}
.indigo-purple.dark app-role-user-find .work-space .side .container .role-resume .line {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark app-role-user-find .work-space .side .container .role-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.1);
}
.indigo-purple.dark app-role-user-find .work-space .side .container .role-resume .line.editable button {
  background: #9fa8da;
}
.indigo-purple.dark app-role-user-find .work-space .side .container .section-title {
  color: #9fa8da;
}
.indigo-purple.dark app-role-user-find .work-space .side .container .section-resume {
  background: #424242;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark app-role-user-find .work-space .side .container .section-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-purple.dark app-role-user-find .work-space .side .container .section-resume .line.editable:hover .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #424242, 0 -2px 0px #424242, 2px 0 0px #424242, 0 2px 0px #424242, -2px -2px 0px #424242, 2px 2px 0px #424242, 2px -2px 0px #424242, -2px 2px 0px #424242 !important;
}
.indigo-purple.dark app-role-user-find .work-space .side .container .section-resume .line.editable button {
  background: #303030;
}
.indigo-purple.dark app-role-user-find .work-space .side .container .section-resume .line .header .icon-complex mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.indigo-purple.dark app-role-user-find .work-space .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #9fa8da !important;
}
.indigo-purple.dark app-role-user-find .work-space .side .container .section-resume .line .header mat-icon:not(.complex) {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-purple.dark app-role-user-find .work-space .side .container .section-resume .line .header .info h4 {
  color: #9fa8da;
}
.indigo-purple.dark app-role-user-find .work-space .side .container .section-resume .line ul {
  color: #9fa8da;
}
.indigo-purple.dark app-role-user-find .work-space .side .container .section-resume .line.head {
  background: black;
}
.indigo-purple.dark app-role-user-find .work-space .side .container .section-resume .line.head mat-icon {
  color: #ce93d8;
}
.indigo-purple.dark app-role-user-find .work-space .side .container .section-resume .line.head h4 {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee-title {
  color: #9fa8da;
}
.indigo-purple.dark app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body {
  background: #424242;
}
.indigo-purple.dark app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-purple.dark app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #9fa8da;
}
.indigo-purple.dark app-role-user-find .work-space .side.edition .toolbar .header {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark app-role-user-find .work-space .side.edition .toolbar .header mat-icon {
  color: #ce93d8;
}
.indigo-purple.dark app-role-user-find .work-space .side.edition .toolbar .header h3 {
  color: #ce93d8;
}
.indigo-purple.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body {
  background: #424242 !important;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header mat-icon:not(.complex) {
  color: #9fa8da;
}
.indigo-purple.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header h4 {
  color: #9fa8da;
}
.indigo-purple.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-actions {
  background: #303030;
}
.indigo-purple.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.indigo-purple.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header .icon-complex mat-icon.complex {
  color: #ce93d8 !important;
}
.indigo-purple.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header h4 {
  color: #ce93d8;
}
.indigo-purple.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line ul {
  color: #ce93d8;
}
.indigo-purple.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head mat-icon {
  color: #ce93d8 !important;
}
.indigo-purple.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head h4 {
  color: white;
}
.indigo-purple.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .section-title {
  color: white !important;
}
.indigo-purple.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume h6.hint {
  background: #ce93d8;
}
.indigo-purple.dark app-role-user-find .work-space app-role-user-detail.disabled .side .toolbar .header {
  color: #607D8B !important;
}
.indigo-purple.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container .role-resume {
  background: #607D8B !important;
}
.indigo-purple.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-title {
  color: #607D8B !important;
}
.indigo-purple.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line ul {
  color: #607D8B !important;
}
.indigo-purple.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header mat-icon {
  color: #607D8B;
}
.indigo-purple.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .icon-complex mat-icon {
  color: #607D8B !important;
}
.indigo-purple.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #607D8B !important;
}
.indigo-purple.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .info h4 {
  color: #607D8B !important;
}
.indigo-purple.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee-title {
  color: #607D8B;
}
.indigo-purple.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: #607D8B;
}
.indigo-purple.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #607D8B;
}
.indigo-purple.dark app-actor-find .main-overlay {
  background: rgba(0, 0, 0, 0.32);
}
.indigo-purple.dark app-actor-find .icon-complex mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.indigo-purple.dark app-actor-find .icon-complex mat-icon.complex {
  color: #9fa8da !important;
}
.indigo-purple.dark app-actor-find .navigation {
  background: #303030;
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark app-actor-find .navigation *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.indigo-purple.dark app-actor-find .navigation *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
.indigo-purple.dark app-actor-find .navigation .load-window {
  background: black;
}
.indigo-purple.dark app-actor-find .navigation .toolbar {
  background: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark app-actor-find .navigation .toolbar.reverse {
  background: #424242;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark app-actor-find .navigation .container .helper {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-purple.dark app-actor-find .navigation .container .item {
  background: #424242;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark app-actor-find .navigation .container .item:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-purple.dark app-actor-find .navigation .container .item.active {
  background: #9fa8da !important;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #9fa8da;
}
.indigo-purple.dark app-actor-find .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.indigo-purple.dark app-actor-find .work-space .main .load-window {
  background: #424242 !important;
}
.indigo-purple.dark app-actor-find .work-space .main.side-open {
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark app-actor-find .work-space .main .container .item {
  background: #303030;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark app-actor-find .work-space .main .container .item .subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-purple.dark app-actor-find .work-space .main .container .item.disabled {
  background: black;
  color: #607D8B !important;
}
.indigo-purple.dark app-actor-find .work-space .main .container .item.disabled h3 {
  color: #607D8B !important;
}
.indigo-purple.dark app-actor-find .work-space .main .container .item.disabled mat-icon {
  color: #607D8B !important;
}
.indigo-purple.dark app-actor-find .work-space .main .container .item.disabled.selected {
  background: #607D8B;
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-purple.dark app-actor-find .work-space .main .container .item.disabled.selected h3 {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-purple.dark app-actor-find .work-space .main .container .item.disabled.selected mat-icon {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-purple.dark app-actor-find .work-space .main .container .item.disabled.selected mat-icon.complex {
  color: rgba(0, 0, 0, 0.87) !important;
  text-shadow: -2px 0 0px #607D8B, 0 -2px 0px #607D8B, 2px 0 0px #607D8B, 0 2px 0px #607D8B, -2px -2px 0px #607D8B, 2px 2px 0px #607D8B, 2px -2px 0px #607D8B, -2px 2px 0px #607D8B !important;
}
.indigo-purple.dark app-actor-find .work-space .main .container .item mat-icon.complex {
  text-shadow: -2px 0 0px black, 0 -2px 0px black, 2px 0 0px black, 0 2px 0px black, -2px -2px 0px black, 2px 2px 0px black, 2px -2px 0px black, -2px 2px 0px black !important;
}
.indigo-purple.dark app-actor-find .work-space .main .container .item h5 {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-purple.dark app-actor-find .work-space .main .container .item h3 {
  color: white;
}
.indigo-purple.dark app-actor-find .work-space .main .container .item:hover {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-purple.dark app-actor-find .work-space .main .container .item.selected {
  background: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark app-actor-find .work-space .main .container .item.selected mat-icon {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark app-actor-find .work-space .main .container .item.selected mat-icon.complex {
  color: rgba(0, 0, 0, 0.87) !important;
  text-shadow: -2px 0 0px #9fa8da, 0 -2px 0px #9fa8da, 2px 0 0px #9fa8da, 0 2px 0px #9fa8da, -2px -2px 0px #9fa8da, 2px 2px 0px #9fa8da, 2px -2px 0px #9fa8da, -2px 2px 0px #9fa8da !important;
}
.indigo-purple.dark app-actor-find .work-space .main .container .item.selected h5,
.indigo-purple.dark app-actor-find .work-space .main .container .item.selected h3 {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark app-actor-find .work-space .main .main-action {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark app-actor-find .work-space .side {
  background: #303030;
}
.indigo-purple.dark app-actor-find .work-space .side .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #303030, 0 -2px 0px #303030, 2px 0 0px #303030, 0 2px 0px #303030, -2px -2px 0px #303030, 2px 2px 0px #303030, 2px -2px 0px #303030, -2px 2px 0px #303030;
}
.indigo-purple.dark app-actor-find .work-space .side *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.indigo-purple.dark app-actor-find .work-space .side *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
.indigo-purple.dark app-actor-find .work-space .side .edition-line {
  background: black;
}
.indigo-purple.dark app-actor-find .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #303030 !important;
}
.indigo-purple.dark app-actor-find .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-purple.dark app-actor-find .work-space .side mat-form-field .mat-form-field-flex {
  background: #303030;
}
.indigo-purple.dark app-actor-find .work-space .side mat-checkbox:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-purple.dark app-actor-find .work-space .side .hint.all {
  color: #ce93d8;
}
.indigo-purple.dark app-actor-find .work-space .side .toolbar .header {
  color: #9fa8da;
}
.indigo-purple.dark app-actor-find .work-space .side .toolbar .header h3 {
  color: #9fa8da;
}
.indigo-purple.dark app-actor-find .work-space .side .container .role-resume {
  background: #9fa8da;
}
.indigo-purple.dark app-actor-find .work-space .side .container .role-resume .line {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark app-actor-find .work-space .side .container .role-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.1);
}
.indigo-purple.dark app-actor-find .work-space .side .container .role-resume .line.editable button {
  background: #9fa8da;
}
.indigo-purple.dark app-actor-find .work-space .side .container .section-title {
  color: #9fa8da;
}
.indigo-purple.dark app-actor-find .work-space .side .container .section-resume {
  background: #424242;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark app-actor-find .work-space .side .container .section-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-purple.dark app-actor-find .work-space .side .container .section-resume .line.editable:hover .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #424242, 0 -2px 0px #424242, 2px 0 0px #424242, 0 2px 0px #424242, -2px -2px 0px #424242, 2px 2px 0px #424242, 2px -2px 0px #424242, -2px 2px 0px #424242 !important;
}
.indigo-purple.dark app-actor-find .work-space .side .container .section-resume .line.editable button {
  background: #303030;
}
.indigo-purple.dark app-actor-find .work-space .side .container .section-resume .line .header .icon-complex mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.indigo-purple.dark app-actor-find .work-space .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #9fa8da !important;
}
.indigo-purple.dark app-actor-find .work-space .side .container .section-resume .line .header mat-icon:not(.complex) {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-purple.dark app-actor-find .work-space .side .container .section-resume .line .header .info h4 {
  color: #9fa8da;
}
.indigo-purple.dark app-actor-find .work-space .side .container .section-resume .line ul {
  color: #9fa8da;
}
.indigo-purple.dark app-actor-find .work-space .side .container .section-resume .line.head {
  background: black;
}
.indigo-purple.dark app-actor-find .work-space .side .container .section-resume .line.head mat-icon {
  color: #ce93d8;
}
.indigo-purple.dark app-actor-find .work-space .side .container .section-resume .line.head h4 {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee-title {
  color: #9fa8da;
}
.indigo-purple.dark app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body {
  background: #424242;
}
.indigo-purple.dark app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-purple.dark app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #9fa8da;
}
.indigo-purple.dark app-actor-find .work-space .side.edition .toolbar .header {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark app-actor-find .work-space .side.edition .toolbar .header mat-icon {
  color: #ce93d8;
}
.indigo-purple.dark app-actor-find .work-space .side.edition .toolbar .header h3 {
  color: #ce93d8;
}
.indigo-purple.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body {
  background: #424242 !important;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header mat-icon:not(.complex) {
  color: #9fa8da;
}
.indigo-purple.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header h4 {
  color: #9fa8da;
}
.indigo-purple.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-actions {
  background: #303030;
}
.indigo-purple.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.indigo-purple.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header .icon-complex mat-icon.complex {
  color: #ce93d8 !important;
}
.indigo-purple.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header h4 {
  color: #ce93d8;
}
.indigo-purple.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line ul {
  color: #ce93d8;
}
.indigo-purple.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head mat-icon {
  color: #ce93d8 !important;
}
.indigo-purple.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head h4 {
  color: white;
}
.indigo-purple.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .section-title {
  color: white !important;
}
.indigo-purple.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume h6.hint {
  background: #ce93d8;
}
.indigo-purple.dark app-actor-find .work-space app-role-user-detail.disabled .side .toolbar .header {
  color: #607D8B !important;
}
.indigo-purple.dark app-actor-find .work-space app-role-user-detail.disabled .side .container .role-resume {
  background: #607D8B !important;
}
.indigo-purple.dark app-actor-find .work-space app-role-user-detail.disabled .side .container .section-title {
  color: #607D8B !important;
}
.indigo-purple.dark app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line ul {
  color: #607D8B !important;
}
.indigo-purple.dark app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header mat-icon {
  color: #607D8B;
}
.indigo-purple.dark app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .icon-complex mat-icon {
  color: #607D8B !important;
}
.indigo-purple.dark app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #607D8B !important;
}
.indigo-purple.dark app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .info h4 {
  color: #607D8B !important;
}
.indigo-purple.dark app-actor-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee-title {
  color: #607D8B;
}
.indigo-purple.dark app-actor-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: #607D8B;
}
.indigo-purple.dark app-actor-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #607D8B;
}
.indigo-purple.dark .dropzone {
  border: 2px dashed #607D8B;
  background: black !important;
}
.indigo-purple.dark .dropzone.hovering {
  border: 2px solid #607D8B;
  color: rgba(255, 255, 255, 0.5) !important;
}
.indigo-purple.dark .dropzone button.mat-stroked-button {
  background: #424242;
}
.indigo-purple.dark .firmador .content-action-main {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-purple.dark .firmador .content-action-main.disabled {
  color: rgba(255, 255, 255, 0.5) !important;
  background: rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark .sign-cycle {
  background: #424242;
}
.indigo-purple.dark .sign-cycle button.success {
  background-color: #009688;
}
.indigo-purple.dark .sign-cycle button.warning {
  background-color: #673AB7;
}
.indigo-purple.dark .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-title-container .cycle-content-title h4 {
  color: #607D8B;
}
.indigo-purple.dark .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-action .content-action-main {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-purple.dark .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-action .content-action-main.mat-stroked-button:disabled {
  color: rgba(255, 255, 255, 0.5) !important;
}
.indigo-purple.dark .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-action .content-action-main.mat-stroked-button:not(:disabled) {
  color: #ce93d8 !important;
}
.indigo-purple.dark .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-action .content-action-side u {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-purple.dark .resume-status {
  background: #424242;
}
.indigo-purple.dark .help-hoverable mat-icon {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-purple.dark .help-hoverable mat-hint {
  background: #424242;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark .help-hoverable mat-hint b {
  color: #9fa8da !important;
}
.indigo-purple.dark app-file-document-sign-massive-dialog .dialog-content,
.indigo-purple.dark app-share-person-dialog .dialog-content {
  background: #303030;
}
.indigo-purple.dark app-file-document-sign-massive-dialog .dialog-content mat-toolbar mat-toolbar-row .mat-avatar h3,
.indigo-purple.dark app-share-person-dialog .dialog-content mat-toolbar mat-toolbar-row .mat-avatar h3 {
  color: #9fa8da;
}
.indigo-purple.dark app-file-document-sign-massive-dialog .dialog-content .dialog-body p b,
.indigo-purple.dark app-share-person-dialog .dialog-content .dialog-body p b {
  color: #ce93d8;
}
.indigo-purple.dark app-file-document-sign-massive-dialog .dialog-content app-file-document-sign,
.indigo-purple.dark app-share-person-dialog .dialog-content app-file-document-sign {
  background: #424242;
}
.indigo-purple.dark .mat-basic-chip {
  background: black !important;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark .mat-basic-chip.seen {
  border-color: #2196F3 !important;
  color: #2196F3 !important;
}
.indigo-purple.dark .mat-basic-chip.unseen {
  border-color: #607D8B !important;
  color: #607D8B !important;
}
.indigo-purple.dark .mat-basic-chip.signed-ok {
  border-color: #009688 !important;
  color: #009688 !important;
}
.indigo-purple.dark .mat-basic-chip.signed-ok.cancelled {
  border-color: #795548 !important;
  color: #795548 !important;
}
.indigo-purple.dark .mat-basic-chip.signed-not-ok {
  border-color: #673AB7 !important;
  color: #673AB7 !important;
}
.indigo-purple.dark .mat-basic-chip.not-signed {
  border-color: #ff4081 !important;
  color: #ff4081 !important;
}
.indigo-purple.dark .mat-basic-chip.not-signed-employer {
  border-color: #ff4081 !important;
  color: #ff4081 !important;
}
.indigo-purple.dark app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip {
  background: black !important;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.seen {
  border-color: #2196F3 !important;
  color: #2196F3 !important;
}
.indigo-purple.dark app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.unseen {
  border-color: #607D8B !important;
  color: #607D8B !important;
}
.indigo-purple.dark app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.signed-ok {
  border-color: #009688 !important;
  color: #009688 !important;
}
.indigo-purple.dark app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.signed-ok.cancelled {
  border-color: #795548 !important;
  color: #795548 !important;
}
.indigo-purple.dark app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.signed-not-ok {
  border-color: #673AB7 !important;
  color: #673AB7 !important;
}
.indigo-purple.dark app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.not-signed {
  border-color: #ff4081 !important;
  color: #ff4081 !important;
}
.indigo-purple.dark app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.not-signed-employer {
  border-color: #ff4081 !important;
  color: #ff4081 !important;
}
.indigo-purple.dark .inbox-container.employee.my-documents .chip-line.mat-line .mat-chip-list-wrapper .mat-basic-chip.seen {
  color: #607D8B !important;
}
.indigo-purple.dark .resume-status .mat-basic-chip {
  background: black !important;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark .resume-status .mat-basic-chip.signed-ok {
  border-color: #009688 !important;
  background-color: #009688 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-purple.dark .resume-status .mat-basic-chip.signed-ok.cancelled {
  border-color: #795548 !important;
  background-color: #795548 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-purple.dark .resume-status .mat-basic-chip.signed-not-ok {
  border-color: #673AB7 !important;
  background-color: #673AB7 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-purple.dark app-file-document-view .new-container app-file-document-upload {
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark app-file-document-view .new-container app-upload-form .file-upload-list .mat-subheader {
  background: #424242;
}
.indigo-purple.dark app-file-document-view .new-container app-upload-form .file-upload-list mat-list-item {
  background: #303030 !important;
}
.indigo-purple.dark app-file-document-view .preview-content {
  background: #607D8B;
}
.indigo-purple.dark app-file-document-view .preview-content h4 {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark app-file-document-view .view-container {
  background: #424242;
}
.indigo-purple.dark app-file-document-view .view-container mat-toolbar.side-bar {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark .formio-hint {
  background: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark mat-dialog-container app-file-document-state-chips {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark app-candidate-promotion .load-window.message h3.primary-text {
  color: #9fa8da;
}
.indigo-purple.dark app-multiple-documentation-add-dialog .icon-complex mat-icon,
.indigo-purple.dark app-documentation-type-set-configuration-dialog .icon-complex mat-icon,
.indigo-purple.dark app-add-candidate-dialog .icon-complex mat-icon,
.indigo-purple.dark app-share-person-dialog .icon-complex mat-icon,
.indigo-purple.dark app-share-person-response-dialog .icon-complex mat-icon {
  color: white !important;
}
.indigo-purple.dark app-multiple-documentation-add-dialog .icon-complex mat-icon.complex,
.indigo-purple.dark app-documentation-type-set-configuration-dialog .icon-complex mat-icon.complex,
.indigo-purple.dark app-add-candidate-dialog .icon-complex mat-icon.complex,
.indigo-purple.dark app-share-person-dialog .icon-complex mat-icon.complex,
.indigo-purple.dark app-share-person-response-dialog .icon-complex mat-icon.complex {
  color: #9fa8da !important;
}
.indigo-purple.dark app-multiple-documentation-add-dialog .icon-complex mat-icon.complex.mat-accent,
.indigo-purple.dark app-documentation-type-set-configuration-dialog .icon-complex mat-icon.complex.mat-accent,
.indigo-purple.dark app-add-candidate-dialog .icon-complex mat-icon.complex.mat-accent,
.indigo-purple.dark app-share-person-dialog .icon-complex mat-icon.complex.mat-accent,
.indigo-purple.dark app-share-person-response-dialog .icon-complex mat-icon.complex.mat-accent {
  color: #ce93d8;
}
.indigo-purple.dark app-multiple-documentation-add-dialog .icon-complex mat-icon.complex.side,
.indigo-purple.dark app-documentation-type-set-configuration-dialog .icon-complex mat-icon.complex.side,
.indigo-purple.dark app-add-candidate-dialog .icon-complex mat-icon.complex.side,
.indigo-purple.dark app-share-person-dialog .icon-complex mat-icon.complex.side,
.indigo-purple.dark app-share-person-response-dialog .icon-complex mat-icon.complex.side {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection,
.indigo-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection,
.indigo-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection,
.indigo-purple.dark app-share-person-dialog .dialog-content .document-template-container .document-selection,
.indigo-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection {
  background: #303030;
}
.indigo-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.indigo-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.indigo-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3,
.indigo-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.indigo-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.indigo-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3,
.indigo-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.indigo-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.indigo-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3,
.indigo-purple.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.indigo-purple.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.indigo-purple.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3,
.indigo-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.indigo-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.indigo-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3 {
  color: #ce93d8 !important;
}
.indigo-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon,
.indigo-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon,
.indigo-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon,
.indigo-purple.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon,
.indigo-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon {
  color: #9fa8da;
}
.indigo-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3,
.indigo-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3,
.indigo-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3,
.indigo-purple.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3,
.indigo-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3 {
  color: #9fa8da;
}
.indigo-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex,
.indigo-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex,
.indigo-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex,
.indigo-purple.dark app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex,
.indigo-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex {
  color: #ce93d8 !important;
  text-shadow: -2px 0 0px black, 0 -2px 0px black, 2px 0 0px black, 0 2px 0px black, -2px -2px 0px black, 2px 2px 0px black, 2px -2px 0px black, -2px 2px 0px #424242;
}
.indigo-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex,
.indigo-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex,
.indigo-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex,
.indigo-purple.dark app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex,
.indigo-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex {
  color: rgba(255, 255, 255, 0.5) !important;
}
.indigo-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b,
.indigo-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b,
.indigo-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b,
.indigo-purple.dark app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b,
.indigo-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b {
  color: white;
}
.indigo-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex,
.indigo-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex,
.indigo-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex,
.indigo-purple.dark app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex,
.indigo-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex {
  color: rgba(255, 255, 255, 0.5) !important;
}
.indigo-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex,
.indigo-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex,
.indigo-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex,
.indigo-purple.dark app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex,
.indigo-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex {
  color: rgba(255, 255, 255, 0.5) !important;
}
.indigo-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover,
.indigo-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover,
.indigo-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover,
.indigo-purple.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover,
.indigo-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b,
.indigo-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b,
.indigo-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b,
.indigo-purple.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b,
.indigo-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b {
  color: white;
}
.indigo-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.indigo-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.indigo-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.indigo-purple.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.indigo-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble {
  background: #9fa8da;
}
.indigo-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble,
.indigo-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble,
.indigo-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble,
.indigo-purple.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble,
.indigo-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble {
  background: #e57373;
}
.indigo-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble,
.indigo-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble,
.indigo-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble,
.indigo-purple.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble,
.indigo-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble {
  color: rgba(255, 255, 255, 0.5);
  background: rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble,
.indigo-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble,
.indigo-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble,
.indigo-purple.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble,
.indigo-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble {
  background: rgba(255, 255, 255, 0.5);
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide,
.indigo-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide,
.indigo-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide,
.indigo-purple.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide,
.indigo-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide {
  border-color: rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble,
.indigo-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble,
.indigo-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble,
.indigo-purple.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble,
.indigo-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.indigo-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.indigo-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.indigo-purple.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.indigo-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble {
  color: #9fa8da;
}
.indigo-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble,
.indigo-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble,
.indigo-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble,
.indigo-purple.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble,
.indigo-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble {
  color: #e57373;
}
.indigo-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar,
.indigo-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar,
.indigo-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar,
.indigo-purple.dark app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar,
.indigo-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar {
  background: #607D8B;
}
.indigo-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row,
.indigo-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row,
.indigo-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row,
.indigo-purple.dark app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row,
.indigo-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper,
.indigo-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper,
.indigo-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper,
.indigo-purple.dark app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper,
.indigo-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper {
  background: #607D8B;
}
.indigo-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop,
.indigo-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop,
.indigo-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop,
.indigo-purple.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop,
.indigo-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop {
  border-left: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body,
.indigo-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body,
.indigo-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body,
.indigo-purple.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body,
.indigo-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body {
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3,
.indigo-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3,
.indigo-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3,
.indigo-purple.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3,
.indigo-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3 {
  color: #ce93d8;
}
.indigo-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5,
.indigo-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5,
.indigo-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5,
.indigo-purple.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5,
.indigo-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5 {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5,
.indigo-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5,
.indigo-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5,
.indigo-purple.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5,
.indigo-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5 {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator,
.indigo-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator,
.indigo-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator,
.indigo-purple.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator,
.indigo-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  background: #303030;
}
.indigo-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip,
.indigo-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip,
.indigo-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip,
.indigo-purple.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip,
.indigo-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip {
  background: #424242 !important;
}
.indigo-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon,
.indigo-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon,
.indigo-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon,
.indigo-purple.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon,
.indigo-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon {
  color: #ce93d8 !important;
}
.indigo-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3,
.indigo-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3,
.indigo-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3,
.indigo-purple.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3,
.indigo-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3 {
  color: #ce93d8 !important;
}
.indigo-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5,
.indigo-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5,
.indigo-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5,
.indigo-purple.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5,
.indigo-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5 {
  color: rgba(255, 255, 255, 0.7) !important;
}
.indigo-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon,
.indigo-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon,
.indigo-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon,
.indigo-purple.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon,
.indigo-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon {
  color: #9fa8da !important;
}
.indigo-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3,
.indigo-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3,
.indigo-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3,
.indigo-purple.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3,
.indigo-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3 {
  color: #9fa8da !important;
}
.indigo-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5,
.indigo-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5,
.indigo-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5,
.indigo-purple.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5,
.indigo-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5 {
  color: #9fa8da !important;
}
.indigo-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled,
.indigo-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled,
.indigo-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled,
.indigo-purple.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled,
.indigo-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled {
  background: #303030 !important;
  color: rgba(255, 255, 255, 0.5);
}
.indigo-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove,
.indigo-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove,
.indigo-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove,
.indigo-purple.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove,
.indigo-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove,
.indigo-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove,
.indigo-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove,
.indigo-purple.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove,
.indigo-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove {
  color: rgba(255, 255, 255, 0.7) !important;
}
.indigo-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .preview-action,
.indigo-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .preview-action,
.indigo-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .preview-action,
.indigo-purple.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .preview-action,
.indigo-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .preview-action {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark .tyc .sticky {
  background: #424242;
}
.indigo-purple.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper:nth-child(odd) datatable-body-row {
  background: #303030;
}
.indigo-purple.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active * {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-purple.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active:hover .datatable-body-cell-label {
  background: #9fa8da;
}
.indigo-purple.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active:hover .cs-floating-action {
  background: #9fa8da !important;
}
.indigo-purple.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active.row-cancel * {
  color: rgba(255, 255, 255, 0.7) !important;
}
.indigo-purple.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active.row-cancel .datatable-row-group {
  background: #607D8B !important;
}
.indigo-purple.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active.row-cancel:hover .datatable-body-cell-label {
  background: #607D8B;
}
.indigo-purple.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.row-cancel * {
  color: #b0bec5 !important;
  transition-property: color;
  transition-duration: 0.3s;
  transition-timing-function: linear;
}
.indigo-purple.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row:hover .datatable-body-cell-label button {
  background: black;
}
.indigo-purple.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row datatable-body-cell.candidate-status_bar mat-progress-bar .mat-progress-bar-buffer {
  background-color: black;
}
.indigo-purple.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row datatable-body-cell.candidate-status_bar .status_bar-count {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-purple.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row datatable-body-cell.candidate-status_bar .inactive {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-purple.dark .cs-grid-test .info {
  background: #424242;
}
.indigo-purple.dark .cs-grid-test .selected-column {
  background: #424242;
  -webkit-box-shadow: 0 1px 3px 0 rgba(255, 255, 255, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0 1px 3px 0 rgba(255, 255, 255, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 3px 0 rgba(255, 255, 255, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
}
.indigo-purple.dark .cs-grid-test .selection-cell .datatable-body-cell.active {
  background: #9fa8da !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-purple.dark .cs-grid-test nav {
  background: #424242;
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 13px 19px 2px rgba(0, 0, 0, 0.14), 0 5px 24px 4px rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark .cs-grid-test .ngx-datatable.material {
  background: #424242;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark .cs-grid-test .ngx-datatable.material.striped .datatable-row-odd {
  background: #303030;
}
.indigo-purple.dark .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active, .indigo-purple.dark .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active .datatable-row-group, .indigo-purple.dark .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active, .indigo-purple.dark .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active .datatable-row-group, .indigo-purple.dark .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active, .indigo-purple.dark .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active .datatable-row-group {
  background: #9fa8da;
  color: #424242;
}
.indigo-purple.dark .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active:hover, .indigo-purple.dark .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active:hover .datatable-row-group, .indigo-purple.dark .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active:hover, .indigo-purple.dark .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active:hover .datatable-row-group, .indigo-purple.dark .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active:hover, .indigo-purple.dark .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active:hover .datatable-row-group {
  background: #9fa8da;
  color: #424242;
}
.indigo-purple.dark .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active:focus, .indigo-purple.dark .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active:focus .datatable-row-group, .indigo-purple.dark .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active:focus, .indigo-purple.dark .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active:focus .datatable-row-group, .indigo-purple.dark .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active:focus, .indigo-purple.dark .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active:focus .datatable-row-group {
  background: #9fa8da;
  color: #424242;
}
.indigo-purple.dark .cs-grid-test .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover, .indigo-purple.dark .cs-grid-test .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-purple.dark .cs-grid-test .ngx-datatable.material:not(.cell-selection) .datatable-body-row:focus, .indigo-purple.dark .cs-grid-test .ngx-datatable.material:not(.cell-selection) .datatable-body-row:focus .datatable-row-group {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-purple.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell:hover, .indigo-purple.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell:hover .datatable-row-group {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-purple.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell:focus, .indigo-purple.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell:focus .datatable-row-group {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-purple.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active, .indigo-purple.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active .datatable-row-group {
  background: #9fa8da;
  color: #424242;
}
.indigo-purple.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active:hover, .indigo-purple.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active:hover .datatable-row-group {
  background: #9fa8da;
  color: #424242;
}
.indigo-purple.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active:focus, .indigo-purple.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active:focus .datatable-row-group {
  background: #9fa8da;
  color: #424242;
}
.indigo-purple.dark .cs-grid-test .ngx-datatable.material .datatable-header .datatable-row-left,
.indigo-purple.dark .cs-grid-test .ngx-datatable.material .datatable-body .datatable-row-left {
  background-color: #424242;
}
.indigo-purple.dark .cs-grid-test .ngx-datatable.material .datatable-header .datatable-row-right,
.indigo-purple.dark .cs-grid-test .ngx-datatable.material .datatable-body .datatable-row-right {
  background-color: #424242;
}
.indigo-purple.dark .cs-grid-test .ngx-datatable.material .datatable-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.indigo-purple.dark .cs-grid-test .ngx-datatable.material .datatable-header .datatable-header-cell {
  background-color: #424242;
  color: rgba(255, 255, 255, 0.7);
}
.indigo-purple.dark .cs-grid-test .ngx-datatable.material .datatable-header .resize-handle {
  border-right: solid 1px rgba(255, 255, 255, 0.04);
}
.indigo-purple.dark .cs-grid-test .ngx-datatable.material .datatable-body .datatable-row-detail {
  background: black;
}
.indigo-purple.dark .cs-grid-test .ngx-datatable.material .datatable-body .datatable-group-header {
  background: black;
  border-bottom: solid 1px rgba(255, 255, 255, 0.12);
  border-top: solid 1px rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark .cs-grid-test .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
  color: white;
}
.indigo-purple.dark .cs-grid-test .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-group-cell {
  color: white;
}
.indigo-purple.dark .cs-grid-test .ngx-datatable.material .datatable-body .progress-linear .container {
  background-color: #424242;
}
.indigo-purple.dark .cs-grid-test .ngx-datatable.material .datatable-body .progress-linear .container .bar {
  background-color: #9fa8da;
}
.indigo-purple.dark .cs-grid-test .ngx-datatable.material .datatable-footer {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-purple.dark .cs-grid-test .ngx-datatable.material .datatable-footer .datatable-pager li.disabled a {
  color: rgba(255, 255, 255, 0.5) !important;
  background-color: rgba(0, 0, 0, 0) !important;
}
.indigo-purple.dark .cs-grid-test .ngx-datatable.material .datatable-footer .datatable-pager li.active a {
  background-color: #9fa8da;
}
.indigo-purple.dark .cs-grid-test .ngx-datatable.material .datatable-footer .datatable-pager a {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-purple.dark .cs-grid-test .ngx-datatable.material .datatable-footer .datatable-pager a:hover {
  color: rgba(255, 255, 255, 0.04);
  background-color: rgba(255, 255, 255, 0.04);
}
.indigo-purple.dark .cs-grid-test .ngx-datatable.material .datatable-summary-row .datatable-body-row {
  background-color: #303030;
}
.indigo-purple.dark .cs-grid-test .ngx-datatable.material .datatable-summary-row .datatable-body-row:hover {
  background-color: #303030;
}
.indigo-purple.dark .cs-grid-test .datatable-checkbox input[type=checkbox]:after {
  background: #424242;
}
.indigo-purple.dark .view-container .view-toolbar {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal.new.image-opened .help-content img {
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card {
  background: #424242;
}
.indigo-purple.dark app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container .aditional-info {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-purple.dark app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container .mat-card-footer {
  background: #424242;
}
.indigo-purple.dark app-help-index mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card {
  background: #424242;
}
.indigo-purple.dark app-help-index mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card .mat-card-footer {
  background: #424242;
}
.indigo-purple.dark .cropper .employee-title .title-text {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-purple.dark .cropper app-cs-upload-cropper-control .preview h5 {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-purple.dark app-file-document-upload .preview-container {
  background: #607D8B !important;
}
.indigo-purple.dark app-file-document-upload .preview-container h4 {
  color: black;
}
.indigo-purple.dark .preview-container {
  background: #607D8B !important;
}
.indigo-purple.dark .preview-container h4 {
  color: black;
}
.indigo-purple.dark .clean-autocomple mat-list {
  background: #424242;
}
.indigo-purple.dark .clean-autocomple mat-list .mat-list-item:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-purple.dark .clean-autocomple mat-list .mat-list-item .mat-list-item-content .mat-list-text {
  color: #9fa8da;
}
.indigo-purple.dark app-leave .inbox-toolbar {
  background: #303030;
}
.indigo-purple.dark app-leave .mat-tab-body-wrapper {
  position: relative;
  overflow: hidden;
  display: flex;
  transition: height 500ms cubic-bezier(0.35, 0, 0.25, 1);
  height: 95% !important;
}
.indigo-purple.dark app-leave .leave_container {
  background: #303030;
}
.indigo-purple.dark app-leave .leave_container .leave_items .leave_items-item.ok .content .leave_item-status_icon {
  color: #4db6ac;
}
.indigo-purple.dark app-leave .leave_container .leave_items .leave_items-item.not-ok .content .leave_item-status_icon {
  color: #ba68c8;
}
.indigo-purple.dark app-leave .leave_container .leave_items .leave_items-item.pending .content .leave_item-status_icon {
  color: #ffb74d;
}
.indigo-purple.dark app-leave .leave_container .leave_items .leave_items-item.draft .content .leave_item-status_icon {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-purple.dark app-leave .leave_container .leave_items .leave_items-item.cancel .content .leave_item-status_icon {
  color: #795548;
}
.indigo-purple.dark app-leave .leave_container .leave_items .leave_items-item .content:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-purple.dark app-leave .leave_container .leave_items .leave_items-item .content.selected {
  border-left: 6px solid #ce93d8 !important;
}
.indigo-purple.dark app-leave .leave_container .leave_items .leave_items-item .content.selected mat-icon {
  color: #ce93d8 !important;
}
.indigo-purple.dark app-leave .leave_container .leave_items .leave_items-item .content.selected .item-data {
  color: white !important;
}
.indigo-purple.dark app-leave .leave_container .leave_items .leave_items-item .content .leave_item-icon {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-purple.dark app-leave .leave_container .leave_items .leave_items-item .content .item-data {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-purple.dark app-leave .leave_container .window-spinner {
  background: #303030 !important;
}
.indigo-purple.dark app-leave-detail .leave_detail .leave_detail-title .info h3,
.indigo-purple.dark app-leave-detail .leave_detail .leave_detail-title .info mat-icon {
  color: #9fa8da;
}
.indigo-purple.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .ok .large-icon {
  background: #e0f2f1;
  color: #4db6ac;
}
.indigo-purple.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .ok h2 {
  color: #4db6ac;
}
.indigo-purple.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .not-ok .large-icon {
  background: #f3e5f5;
  color: #ba68c8;
}
.indigo-purple.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .not-ok h2 {
  color: #ba68c8;
}
.indigo-purple.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .pending .large-icon {
  background: #fff3e0;
  color: #ffb74d;
}
.indigo-purple.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .pending h2 {
  color: #ffb74d;
}
.indigo-purple.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .draft .large-icon {
  background: #f2f2f2;
  color: #dedede;
}
.indigo-purple.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .draft h2 {
  color: #dedede;
}
.indigo-purple.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .cancelled .large-icon {
  background: #BCAAA4;
  color: #795548;
}
.indigo-purple.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .cancelled h2 {
  color: #795548;
}
.indigo-purple.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .actions {
  padding: 16px;
  height: 96px;
  column-gap: 16px;
}
.indigo-purple.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .actions button {
  height: 100%;
  border-radius: 40px;
}
.indigo-purple.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .actions button.ok {
  background: #4db6ac !important;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .actions button.not-ok {
  color: #ba68c8 !important;
}
.indigo-purple.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning mat-basic-chip {
  background: black !important;
  border: 1px solid rgba(255, 255, 255, 0.12) !important;
  border-radius: 40px !important;
  height: 35px !important;
}
.indigo-purple.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning mat-basic-chip.signed-ok {
  border-color: #4db6ac !important;
  color: #4db6ac !important;
}
.indigo-purple.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning mat-basic-chip.signed-not-ok {
  border-color: #ba68c8 !important;
  color: #ba68c8 !important;
}
.indigo-purple.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning mat-basic-chip.draft {
  border-color: #dedede !important;
  color: #dedede !important;
}
.indigo-purple.dark app-leave-request-detail .load-window {
  background: #303030;
}
.indigo-purple.dark app-leave-request-detail form .leave_detail-container {
  background: #303030;
}
.indigo-purple.dark app-leave-request-detail form .leave_detail-container .toolbar .header {
  color: #9fa8da;
}
.indigo-purple.dark app-leave-request-detail form .leave_detail-container .toolbar .header .text h3 {
  color: #9fa8da;
}
.indigo-purple.dark app-leave-request-detail form .leave_detail-container .toolbar .header .text h5 {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-purple.dark app-leave-request-detail form .leave_detail-container .leave_detail-container-content .leave_resume {
  background: #424242;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark app-leave-request-detail form .leave_detail-container .leave_detail-container-content .line h6 {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-purple.dark app-leave-request-detail form .leave_detail-container .actions button.ok {
  background: #4db6ac !important;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark app-leave-request-detail form .leave_detail-container .actions button.not-ok {
  color: #ba68c8 !important;
}
.indigo-purple.dark .new_leave_modal-steps {
  display: unset !important;
}
.indigo-purple.dark .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button {
  background: #303030;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button .mat-radio-label .mat-radio-label-content .side-text {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-purple.dark .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button .mat-radio-label .mat-radio-label-content .side-text.muted {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-purple.dark .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button .mat-radio-label .mat-radio-label-content .accent mat-icon,
.indigo-purple.dark .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button .mat-radio-label .mat-radio-label-content .accent .main-text {
  color: #ce93d8;
}
.indigo-purple.dark .config_leave-container .config_leave-card {
  background: #424242;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark .config_leave-container .config_leave-card .config_leave-auto_chip mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #303030 !important;
}
.indigo-purple.dark .config_leave-container .config_leave-card .config_leave-auto_chip mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-purple.dark .config_leave-container .config_leave-card .config_leave-auto_chip mat-form-field .mat-form-field-flex {
  background: #303030;
}
.indigo-purple.dark *[color=success] {
  color: #009688;
}
.indigo-purple.dark .primary {
  color: #9fa8da !important;
}
.indigo-purple.dark .accent {
  color: #ce93d8 !important;
}
.indigo-purple.dark app-lsd .icon-complex mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.indigo-purple.dark app-lsd .icon-complex mat-icon.complex {
  color: #9fa8da !important;
}
.indigo-purple.dark app-lsd .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.indigo-purple.dark app-lsd .work-space .main .load-window {
  background: #424242 !important;
}
.indigo-purple.dark app-lsd .work-space .main.side-open {
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark app-lsd .work-space .main .container .item {
  background: #303030;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark app-lsd .work-space .main .container .item h5 {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-purple.dark app-lsd .work-space .main .container .item h3 {
  color: white;
}
.indigo-purple.dark app-lsd .work-space .main .container .item:hover {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-purple.dark app-lsd .work-space .main .container .item.selected {
  background: #9fa8da;
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-purple.dark app-lsd .work-space .main .container .item.selected h3 {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-purple.dark app-lsd .work-space .main .container .item.selected.pending .status {
  background: rgba(255, 255, 255, 0.12) !important;
  color: #ffe082;
}
.indigo-purple.dark app-lsd .work-space .main .container .item.selected.ok .status {
  background: rgba(255, 255, 255, 0.12) !important;
  color: #a5d6a7;
}
.indigo-purple.dark app-lsd .work-space .main .container .item.selected.not-ok .status {
  background: rgba(255, 255, 255, 0.12) !important;
  color: #e57373;
}
.indigo-purple.dark app-lsd .work-space .main .main-action {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark app-lsd .work-space .side {
  background: #303030;
}
.indigo-purple.dark app-lsd .work-space .side .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #303030, 0 -2px 0px #303030, 2px 0 0px #303030, 0 2px 0px #303030, -2px -2px 0px #303030, 2px 2px 0px #303030, 2px -2px 0px #303030, -2px 2px 0px #303030;
}
.indigo-purple.dark app-lsd .work-space .side *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.indigo-purple.dark app-lsd .work-space .side *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
.indigo-purple.dark app-lsd .work-space .side .edition-line {
  background: black;
}
.indigo-purple.dark app-lsd .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #303030 !important;
}
.indigo-purple.dark app-lsd .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-purple.dark app-lsd .work-space .side mat-form-field .mat-form-field-flex {
  background: #303030;
}
.indigo-purple.dark app-lsd .work-space .side mat-checkbox:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-purple.dark app-lsd .work-space .side .hint.all {
  color: #ce93d8;
}
.indigo-purple.dark app-lsd .work-space .side .toolbar .header {
  color: #9fa8da;
}
.indigo-purple.dark app-lsd .work-space .side .toolbar .header h3 {
  color: #9fa8da;
}
.indigo-purple.dark app-lsd .work-space .side .container .doc-resume {
  background: #9fa8da;
}
.indigo-purple.dark app-lsd .work-space .side .container .doc-resume .line {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark app-lsd .work-space .side .container .doc-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.1);
}
.indigo-purple.dark app-lsd .work-space .side .container .doc-resume .line.editable button {
  background: #9fa8da;
}
.indigo-purple.dark app-lsd .work-space .side .container .section-title {
  color: #9fa8da;
}
.indigo-purple.dark app-lsd .work-space .side .container .section-resume {
  background: #424242;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark app-lsd .work-space .side .container .section-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-purple.dark app-lsd .work-space .side .container .section-resume .line.editable:hover .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #424242, 0 -2px 0px #424242, 2px 0 0px #424242, 0 2px 0px #424242, -2px -2px 0px #424242, 2px 2px 0px #424242, 2px -2px 0px #424242, -2px 2px 0px #424242 !important;
}
.indigo-purple.dark app-lsd .work-space .side .container .section-resume .line.editable button {
  background: #303030;
}
.indigo-purple.dark app-lsd .work-space .side .container .section-resume .line .header .icon-complex mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.indigo-purple.dark app-lsd .work-space .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #9fa8da !important;
}
.indigo-purple.dark app-lsd .work-space .side .container .section-resume .line .header mat-icon:not(.complex) {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-purple.dark app-lsd .work-space .side .container .section-resume .line .header .info h4 {
  color: #9fa8da;
}
.indigo-purple.dark app-lsd .work-space .side .container .section-resume .line ul {
  color: #9fa8da;
}
.indigo-purple.dark app-lsd .work-space .side .container .section-resume .line.head {
  background: black;
}
.indigo-purple.dark app-lsd .work-space .side .container .section-resume .line.head mat-icon {
  color: #ce93d8;
}
.indigo-purple.dark app-lsd .work-space .side .container .section-resume .line.head h4 {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark app-lsd .work-space .side.edition .toolbar .header {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-purple.dark app-lsd .work-space .side.edition .toolbar .header mat-icon {
  color: #ce93d8;
}
.indigo-purple.dark app-lsd .work-space .side.edition .toolbar .header h3 {
  color: #ce93d8;
}
.indigo-purple.dark .dialog-content {
  max-height: 90vh;
}
.indigo-purple.dark .dialog-content mat-toolbar {
  background: #424242;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark .dialog-content .dialog-container .document-template-container .document-selection mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #303030 !important;
}
.indigo-purple.dark .dialog-content .dialog-container .document-template-container .document-selection mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-purple.dark .dialog-content .dialog-container .document-template-container .document-selection mat-form-field .mat-form-field-flex {
  background: #303030;
}
.indigo-purple.dark .dialog-content .dialog-container .document-template-container .document-selection app-upload-form .file-upload-list .mat-subheader::before {
  color: #9fa8da;
}
.indigo-purple.dark .dialog-content .dialog-container .view-container {
  border-left: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-purple.dark .dialog-content .form-actions {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}

.indigo-amber .mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-amber .mat-badge-small .mat-badge-content {
  font-size: 9px;
}
.indigo-amber .mat-badge-large .mat-badge-content {
  font-size: 24px;
}
.indigo-amber .mat-h1,
.indigo-amber .mat-headline,
.indigo-amber .mat-typography .mat-h1,
.indigo-amber .mat-typography .mat-headline,
.indigo-amber .mat-typography h1 {
  font: 400 24px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.indigo-amber .mat-h2,
.indigo-amber .mat-title,
.indigo-amber .mat-typography .mat-h2,
.indigo-amber .mat-typography .mat-title,
.indigo-amber .mat-typography h2 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.indigo-amber .mat-h3,
.indigo-amber .mat-subheading-2,
.indigo-amber .mat-typography .mat-h3,
.indigo-amber .mat-typography .mat-subheading-2,
.indigo-amber .mat-typography h3 {
  font: 400 16px / 28px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.indigo-amber .mat-h4,
.indigo-amber .mat-subheading-1,
.indigo-amber .mat-typography .mat-h4,
.indigo-amber .mat-typography .mat-subheading-1,
.indigo-amber .mat-typography h4 {
  font: 400 15px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.indigo-amber .mat-h5,
.indigo-amber .mat-typography .mat-h5,
.indigo-amber .mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}
.indigo-amber .mat-h6,
.indigo-amber .mat-typography .mat-h6,
.indigo-amber .mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}
.indigo-amber .mat-body-strong,
.indigo-amber .mat-body-2,
.indigo-amber .mat-typography .mat-body-strong,
.indigo-amber .mat-typography .mat-body-2 {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.indigo-amber .mat-body,
.indigo-amber .mat-body-1,
.indigo-amber .mat-typography .mat-body,
.indigo-amber .mat-typography .mat-body-1,
.indigo-amber .mat-typography {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.indigo-amber .mat-body p,
.indigo-amber .mat-body-1 p,
.indigo-amber .mat-typography .mat-body p,
.indigo-amber .mat-typography .mat-body-1 p,
.indigo-amber .mat-typography p {
  margin: 0 0 12px;
}
.indigo-amber .mat-small,
.indigo-amber .mat-caption,
.indigo-amber .mat-typography .mat-small,
.indigo-amber .mat-typography .mat-caption {
  font: 400 12px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.indigo-amber .mat-display-4,
.indigo-amber .mat-typography .mat-display-4 {
  font: 300 112px / 112px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}
.indigo-amber .mat-display-3,
.indigo-amber .mat-typography .mat-display-3 {
  font: 400 56px / 56px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}
.indigo-amber .mat-display-2,
.indigo-amber .mat-typography .mat-display-2 {
  font: 400 45px / 48px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}
.indigo-amber .mat-display-1,
.indigo-amber .mat-typography .mat-display-1 {
  font: 400 34px / 40px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}
.indigo-amber .mat-bottom-sheet-container {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.indigo-amber .mat-button, .indigo-amber .mat-raised-button, .indigo-amber .mat-icon-button, .indigo-amber .mat-stroked-button,
.indigo-amber .mat-flat-button, .indigo-amber .mat-fab, .indigo-amber .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.indigo-amber .mat-button-toggle {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-amber .mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-amber .mat-card-title {
  font-size: 24px;
  font-weight: 500;
}
.indigo-amber .mat-card-header .mat-card-title {
  font-size: 20px;
}
.indigo-amber .mat-card-subtitle,
.indigo-amber .mat-card-content {
  font-size: 14px;
}
.indigo-amber .mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-amber .mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}
.indigo-amber .mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.indigo-amber .mat-chip .mat-chip-trailing-icon.mat-icon,
.indigo-amber .mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}
.indigo-amber .mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-amber .mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}
.indigo-amber .mat-cell, .indigo-amber .mat-footer-cell {
  font-size: 14px;
}
.indigo-amber .mat-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-amber .mat-calendar-body {
  font-size: 13px;
}
.indigo-amber .mat-calendar-body-label,
.indigo-amber .mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}
.indigo-amber .mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}
.indigo-amber .mat-dialog-title {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.indigo-amber .mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400;
}
.indigo-amber .mat-expansion-panel-content {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.indigo-amber .mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.indigo-amber .mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}
.indigo-amber .mat-form-field-prefix .mat-icon,
.indigo-amber .mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.indigo-amber .mat-form-field-prefix .mat-icon-button,
.indigo-amber .mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.indigo-amber .mat-form-field-prefix .mat-icon-button .mat-icon,
.indigo-amber .mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}
.indigo-amber .mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}
.indigo-amber .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.indigo-amber .mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34367em) scale(0.75);
  width: 133.3334133333%;
}
.indigo-amber .mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34366em) scale(0.75);
  width: 133.3334233333%;
}
.indigo-amber .mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}
.indigo-amber .mat-form-field-label {
  top: 1.34375em;
}
.indigo-amber .mat-form-field-underline {
  bottom: 1.34375em;
}
.indigo-amber .mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}
.indigo-amber .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.indigo-amber .mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.indigo-amber .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.indigo-amber .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00124px);
  width: 133.3335733333%;
}
.indigo-amber .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00125px);
  width: 133.3335833333%;
}
.indigo-amber .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00126px);
  width: 133.3335933333%;
}
.indigo-amber .mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.indigo-amber .mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.indigo-amber .mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}
@media print {
  .indigo-amber .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.indigo-amber .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28098em) scale(0.75);
  }
  .indigo-amber .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28097em) scale(0.75);
  }
  .indigo-amber .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28096em) scale(0.75);
  }
}
.indigo-amber .mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.indigo-amber .mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.indigo-amber .mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.indigo-amber .mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59367em) scale(0.75);
  width: 133.3334133333%;
}
.indigo-amber .mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59366em) scale(0.75);
  width: 133.3334233333%;
}
.indigo-amber .mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.indigo-amber .mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.indigo-amber .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.indigo-amber .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59367em) scale(0.75);
  width: 133.3334133333%;
}
.indigo-amber .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59366em) scale(0.75);
  width: 133.3334233333%;
}
.indigo-amber .mat-grid-tile-header,
.indigo-amber .mat-grid-tile-footer {
  font-size: 14px;
}
.indigo-amber .mat-grid-tile-header .mat-line,
.indigo-amber .mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.indigo-amber .mat-grid-tile-header .mat-line:nth-child(n+2),
.indigo-amber .mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.indigo-amber input.mat-input-element {
  margin-top: -0.0625em;
}
.indigo-amber .mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
}
.indigo-amber .mat-paginator,
.indigo-amber .mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
}
.indigo-amber .mat-radio-button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-amber .mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-amber .mat-select-trigger {
  height: 1.125em;
}
.indigo-amber .mat-slide-toggle-content {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-amber .mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}
.indigo-amber .mat-stepper-vertical, .indigo-amber .mat-stepper-horizontal {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-amber .mat-step-label {
  font-size: 14px;
  font-weight: 400;
}
.indigo-amber .mat-step-sub-label-error {
  font-weight: normal;
}
.indigo-amber .mat-step-label-error {
  font-size: 14px;
}
.indigo-amber .mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}
.indigo-amber .mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-amber .mat-tab-label, .indigo-amber .mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.indigo-amber .mat-toolbar,
.indigo-amber .mat-toolbar h1,
.indigo-amber .mat-toolbar h2,
.indigo-amber .mat-toolbar h3,
.indigo-amber .mat-toolbar h4,
.indigo-amber .mat-toolbar h5,
.indigo-amber .mat-toolbar h6 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0;
}
.indigo-amber .mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.indigo-amber .mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.indigo-amber .mat-list-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-amber .mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-amber .mat-list-base .mat-list-item {
  font-size: 16px;
}
.indigo-amber .mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.indigo-amber .mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.indigo-amber .mat-list-base .mat-list-option {
  font-size: 16px;
}
.indigo-amber .mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.indigo-amber .mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.indigo-amber .mat-list-base .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.indigo-amber .mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.indigo-amber .mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.indigo-amber .mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.indigo-amber .mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.indigo-amber .mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.indigo-amber .mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.indigo-amber .mat-list-base[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}
.indigo-amber .mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
}
.indigo-amber .mat-optgroup-label {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.indigo-amber .mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
}
.indigo-amber .mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}
.indigo-amber .mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-amber .mat-tree-node,
.indigo-amber .mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}
.indigo-amber .mat-ripple {
  overflow: hidden;
  position: relative;
}
.indigo-amber .mat-ripple:not(:empty) {
  transform: translateZ(0);
}
.indigo-amber .mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}
.indigo-amber .mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .indigo-amber .mat-ripple-element {
  display: none;
}

.indigo-amber .cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .indigo-amber .cdk-visually-hidden {
  left: auto;
  right: 0;
}
.indigo-amber .cdk-overlay-container, .indigo-amber .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.indigo-amber .cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.indigo-amber .cdk-overlay-container:empty {
  display: none;
}
.indigo-amber .cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}
.indigo-amber .cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}
.indigo-amber .cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.indigo-amber .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .indigo-amber .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}
.indigo-amber .cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}
.indigo-amber .cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.indigo-amber .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}
.indigo-amber .cdk-overlay-backdrop-noop-animation {
  transition: none;
}
.indigo-amber .cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}
.indigo-amber .cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}
.indigo-amber textarea.cdk-textarea-autosize {
  resize: none;
}
.indigo-amber textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}
.indigo-amber textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}
@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.indigo-amber .cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}
.indigo-amber .cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}
.indigo-amber .mat-focus-indicator {
  position: relative;
}
.indigo-amber .mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.indigo-amber .mat-focus-indicator:focus::before {
  content: "";
}
.cdk-high-contrast-active .indigo-amber {
  --mat-focus-indicator-display: block;
}

.indigo-amber .mat-mdc-focus-indicator {
  position: relative;
}
.indigo-amber .mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.indigo-amber .mat-mdc-focus-indicator:focus::before {
  content: "";
}
.cdk-high-contrast-active .indigo-amber {
  --mat-mdc-focus-indicator-display: block;
}

.indigo-amber .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.indigo-amber .mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber .mat-option:hover:not(.mat-option-disabled), .indigo-amber .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.indigo-amber .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.indigo-amber .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-amber .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #3f51b5;
}
.indigo-amber .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ffc107;
}
.indigo-amber .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f44336;
}
.indigo-amber .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-amber .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-amber .mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-amber .mat-pseudo-checkbox::after {
  color: #fafafa;
}
.indigo-amber .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.indigo-amber .mat-primary .mat-pseudo-checkbox-checked,
.indigo-amber .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #3f51b5;
}
.indigo-amber .mat-pseudo-checkbox-checked,
.indigo-amber .mat-pseudo-checkbox-indeterminate,
.indigo-amber .mat-accent .mat-pseudo-checkbox-checked,
.indigo-amber .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #ffc107;
}
.indigo-amber .mat-warn .mat-pseudo-checkbox-checked,
.indigo-amber .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #f44336;
}
.indigo-amber .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.indigo-amber .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.indigo-amber .mat-app-background, .indigo-amber.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.indigo-amber .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.indigo-amber .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.indigo-amber .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.indigo-amber .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.indigo-amber .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.indigo-amber .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.indigo-amber .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.indigo-amber .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.indigo-amber .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.indigo-amber .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.indigo-amber .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.indigo-amber .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.indigo-amber .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.indigo-amber .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.indigo-amber .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.indigo-amber .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.indigo-amber .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.indigo-amber .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.indigo-amber .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.indigo-amber .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.indigo-amber .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.indigo-amber .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.indigo-amber .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.indigo-amber .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.indigo-amber .mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.indigo-amber .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.indigo-amber .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber .mat-badge-content {
  color: white;
  background: #3f51b5;
}
.cdk-high-contrast-active .indigo-amber .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.indigo-amber .mat-badge-accent .mat-badge-content {
  background: #ffc107;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber .mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336;
}
.indigo-amber .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.indigo-amber .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber .mat-button, .indigo-amber .mat-icon-button, .indigo-amber .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.indigo-amber .mat-button.mat-primary, .indigo-amber .mat-icon-button.mat-primary, .indigo-amber .mat-stroked-button.mat-primary {
  color: #3f51b5;
}
.indigo-amber .mat-button.mat-accent, .indigo-amber .mat-icon-button.mat-accent, .indigo-amber .mat-stroked-button.mat-accent {
  color: #ffc107;
}
.indigo-amber .mat-button.mat-warn, .indigo-amber .mat-icon-button.mat-warn, .indigo-amber .mat-stroked-button.mat-warn {
  color: #f44336;
}
.indigo-amber .mat-button.mat-primary.mat-button-disabled, .indigo-amber .mat-button.mat-accent.mat-button-disabled, .indigo-amber .mat-button.mat-warn.mat-button-disabled, .indigo-amber .mat-button.mat-button-disabled.mat-button-disabled, .indigo-amber .mat-icon-button.mat-primary.mat-button-disabled, .indigo-amber .mat-icon-button.mat-accent.mat-button-disabled, .indigo-amber .mat-icon-button.mat-warn.mat-button-disabled, .indigo-amber .mat-icon-button.mat-button-disabled.mat-button-disabled, .indigo-amber .mat-stroked-button.mat-primary.mat-button-disabled, .indigo-amber .mat-stroked-button.mat-accent.mat-button-disabled, .indigo-amber .mat-stroked-button.mat-warn.mat-button-disabled, .indigo-amber .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.indigo-amber .mat-button.mat-primary .mat-button-focus-overlay, .indigo-amber .mat-icon-button.mat-primary .mat-button-focus-overlay, .indigo-amber .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #3f51b5;
}
.indigo-amber .mat-button.mat-accent .mat-button-focus-overlay, .indigo-amber .mat-icon-button.mat-accent .mat-button-focus-overlay, .indigo-amber .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #ffc107;
}
.indigo-amber .mat-button.mat-warn .mat-button-focus-overlay, .indigo-amber .mat-icon-button.mat-warn .mat-button-focus-overlay, .indigo-amber .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #f44336;
}
.indigo-amber .mat-button.mat-button-disabled .mat-button-focus-overlay, .indigo-amber .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .indigo-amber .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.indigo-amber .mat-button .mat-ripple-element, .indigo-amber .mat-icon-button .mat-ripple-element, .indigo-amber .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.indigo-amber .mat-button-focus-overlay {
  background: black;
}
.indigo-amber .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.indigo-amber .mat-flat-button, .indigo-amber .mat-raised-button, .indigo-amber .mat-fab, .indigo-amber .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.indigo-amber .mat-flat-button.mat-primary, .indigo-amber .mat-raised-button.mat-primary, .indigo-amber .mat-fab.mat-primary, .indigo-amber .mat-mini-fab.mat-primary {
  color: white;
}
.indigo-amber .mat-flat-button.mat-accent, .indigo-amber .mat-raised-button.mat-accent, .indigo-amber .mat-fab.mat-accent, .indigo-amber .mat-mini-fab.mat-accent {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber .mat-flat-button.mat-warn, .indigo-amber .mat-raised-button.mat-warn, .indigo-amber .mat-fab.mat-warn, .indigo-amber .mat-mini-fab.mat-warn {
  color: white;
}
.indigo-amber .mat-flat-button.mat-primary.mat-button-disabled, .indigo-amber .mat-flat-button.mat-accent.mat-button-disabled, .indigo-amber .mat-flat-button.mat-warn.mat-button-disabled, .indigo-amber .mat-flat-button.mat-button-disabled.mat-button-disabled, .indigo-amber .mat-raised-button.mat-primary.mat-button-disabled, .indigo-amber .mat-raised-button.mat-accent.mat-button-disabled, .indigo-amber .mat-raised-button.mat-warn.mat-button-disabled, .indigo-amber .mat-raised-button.mat-button-disabled.mat-button-disabled, .indigo-amber .mat-fab.mat-primary.mat-button-disabled, .indigo-amber .mat-fab.mat-accent.mat-button-disabled, .indigo-amber .mat-fab.mat-warn.mat-button-disabled, .indigo-amber .mat-fab.mat-button-disabled.mat-button-disabled, .indigo-amber .mat-mini-fab.mat-primary.mat-button-disabled, .indigo-amber .mat-mini-fab.mat-accent.mat-button-disabled, .indigo-amber .mat-mini-fab.mat-warn.mat-button-disabled, .indigo-amber .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.indigo-amber .mat-flat-button.mat-primary, .indigo-amber .mat-raised-button.mat-primary, .indigo-amber .mat-fab.mat-primary, .indigo-amber .mat-mini-fab.mat-primary {
  background-color: #3f51b5;
}
.indigo-amber .mat-flat-button.mat-accent, .indigo-amber .mat-raised-button.mat-accent, .indigo-amber .mat-fab.mat-accent, .indigo-amber .mat-mini-fab.mat-accent {
  background-color: #ffc107;
}
.indigo-amber .mat-flat-button.mat-warn, .indigo-amber .mat-raised-button.mat-warn, .indigo-amber .mat-fab.mat-warn, .indigo-amber .mat-mini-fab.mat-warn {
  background-color: #f44336;
}
.indigo-amber .mat-flat-button.mat-primary.mat-button-disabled, .indigo-amber .mat-flat-button.mat-accent.mat-button-disabled, .indigo-amber .mat-flat-button.mat-warn.mat-button-disabled, .indigo-amber .mat-flat-button.mat-button-disabled.mat-button-disabled, .indigo-amber .mat-raised-button.mat-primary.mat-button-disabled, .indigo-amber .mat-raised-button.mat-accent.mat-button-disabled, .indigo-amber .mat-raised-button.mat-warn.mat-button-disabled, .indigo-amber .mat-raised-button.mat-button-disabled.mat-button-disabled, .indigo-amber .mat-fab.mat-primary.mat-button-disabled, .indigo-amber .mat-fab.mat-accent.mat-button-disabled, .indigo-amber .mat-fab.mat-warn.mat-button-disabled, .indigo-amber .mat-fab.mat-button-disabled.mat-button-disabled, .indigo-amber .mat-mini-fab.mat-primary.mat-button-disabled, .indigo-amber .mat-mini-fab.mat-accent.mat-button-disabled, .indigo-amber .mat-mini-fab.mat-warn.mat-button-disabled, .indigo-amber .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.indigo-amber .mat-flat-button.mat-primary .mat-ripple-element, .indigo-amber .mat-raised-button.mat-primary .mat-ripple-element, .indigo-amber .mat-fab.mat-primary .mat-ripple-element, .indigo-amber .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.indigo-amber .mat-flat-button.mat-accent .mat-ripple-element, .indigo-amber .mat-raised-button.mat-accent .mat-ripple-element, .indigo-amber .mat-fab.mat-accent .mat-ripple-element, .indigo-amber .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.indigo-amber .mat-flat-button.mat-warn .mat-ripple-element, .indigo-amber .mat-raised-button.mat-warn .mat-ripple-element, .indigo-amber .mat-fab.mat-warn .mat-ripple-element, .indigo-amber .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.indigo-amber .mat-stroked-button:not([class*=mat-elevation-z]), .indigo-amber .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.indigo-amber .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.indigo-amber .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.indigo-amber .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.indigo-amber .mat-fab:not([class*=mat-elevation-z]), .indigo-amber .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.indigo-amber .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .indigo-amber .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.indigo-amber .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .indigo-amber .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.indigo-amber .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.indigo-amber .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.indigo-amber .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.indigo-amber .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.indigo-amber .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-amber .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.indigo-amber .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.indigo-amber .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.indigo-amber .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
.indigo-amber [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.indigo-amber .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
.indigo-amber .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.indigo-amber .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.indigo-amber .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.indigo-amber .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.indigo-amber .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.indigo-amber .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
.indigo-amber .mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.indigo-amber .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.indigo-amber .mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-amber .mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}
.indigo-amber .mat-checkbox-checkmark {
  fill: #fafafa;
}
.indigo-amber .mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
.indigo-amber .mat-checkbox-mixedmark {
  background-color: #fafafa;
}
.indigo-amber .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .indigo-amber .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #3f51b5;
}
.indigo-amber .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .indigo-amber .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #ffc107;
}
.indigo-amber .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .indigo-amber .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #f44336;
}
.indigo-amber .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .indigo-amber .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.indigo-amber .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.indigo-amber .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-amber .mat-checkbox .mat-ripple-element {
  background-color: black;
}
.indigo-amber .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.indigo-amber .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #3f51b5;
}
.indigo-amber .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.indigo-amber .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #ffc107;
}
.indigo-amber .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.indigo-amber .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #f44336;
}
.indigo-amber .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber .mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.indigo-amber .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.indigo-amber .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.indigo-amber .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.indigo-amber .mat-chip.mat-standard-chip::after {
  background: black;
}
.indigo-amber .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #3f51b5;
  color: white;
}
.indigo-amber .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.indigo-amber .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.indigo-amber .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #f44336;
  color: white;
}
.indigo-amber .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.indigo-amber .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.indigo-amber .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #ffc107;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.indigo-amber .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.indigo-amber .mat-table {
  background: white;
}
.indigo-amber .mat-table thead, .indigo-amber .mat-table tbody, .indigo-amber .mat-table tfoot,
.indigo-amber mat-header-row, .indigo-amber mat-row, .indigo-amber mat-footer-row,
.indigo-amber [mat-header-row], .indigo-amber [mat-row], .indigo-amber [mat-footer-row],
.indigo-amber .mat-table-sticky {
  background: inherit;
}
.indigo-amber mat-row, .indigo-amber mat-header-row, .indigo-amber mat-footer-row,
.indigo-amber th.mat-header-cell, .indigo-amber td.mat-cell, .indigo-amber td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.indigo-amber .mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-amber .mat-cell, .indigo-amber .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber .mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}
.indigo-amber .mat-datepicker-toggle,
.indigo-amber .mat-datepicker-content .mat-calendar-next-button,
.indigo-amber .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-amber .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.indigo-amber .mat-calendar-table-header,
.indigo-amber .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-amber .mat-calendar-body-cell-content,
.indigo-amber .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.indigo-amber .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-amber .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-amber .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.indigo-amber .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.indigo-amber .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.indigo-amber .mat-calendar-body-in-range::before {
  background: rgba(63, 81, 181, 0.2);
}
.indigo-amber .mat-calendar-body-comparison-identical,
.indigo-amber .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.indigo-amber .mat-calendar-body-comparison-bridge-start::before,
.indigo-amber [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(63, 81, 181, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.indigo-amber .mat-calendar-body-comparison-bridge-end::before,
.indigo-amber [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(63, 81, 181, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.indigo-amber .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.indigo-amber .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.indigo-amber .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.indigo-amber .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.indigo-amber .mat-calendar-body-selected {
  background-color: #3f51b5;
  color: white;
}
.indigo-amber .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(63, 81, 181, 0.4);
}
.indigo-amber .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.indigo-amber .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.indigo-amber .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(63, 81, 181, 0.3);
}
@media (hover: hover) {
  .indigo-amber .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(63, 81, 181, 0.3);
  }
}
.indigo-amber .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(255, 193, 7, 0.2);
}
.indigo-amber .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.indigo-amber .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.indigo-amber .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.indigo-amber .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 193, 7, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.indigo-amber .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.indigo-amber .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 193, 7, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.indigo-amber .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.indigo-amber .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.indigo-amber .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.indigo-amber .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.indigo-amber .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #ffc107;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 193, 7, 0.4);
}
.indigo-amber .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.indigo-amber .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.indigo-amber .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 193, 7, 0.3);
}
@media (hover: hover) {
  .indigo-amber .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(255, 193, 7, 0.3);
  }
}
.indigo-amber .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}
.indigo-amber .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.indigo-amber .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.indigo-amber .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.indigo-amber .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.indigo-amber .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.indigo-amber .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.indigo-amber .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.indigo-amber .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.indigo-amber .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.indigo-amber .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.indigo-amber .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: white;
}
.indigo-amber .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}
.indigo-amber .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.indigo-amber .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.indigo-amber .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(244, 67, 54, 0.3);
}
@media (hover: hover) {
  .indigo-amber .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(244, 67, 54, 0.3);
  }
}
.indigo-amber .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.indigo-amber .mat-datepicker-toggle-active {
  color: #3f51b5;
}
.indigo-amber .mat-datepicker-toggle-active.mat-accent {
  color: #ffc107;
}
.indigo-amber .mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}
.indigo-amber .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-amber .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.indigo-amber .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.indigo-amber .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.indigo-amber .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.indigo-amber .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .indigo-amber .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .indigo-amber .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .indigo-amber .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.indigo-amber .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber .mat-expansion-panel-header-description,
.indigo-amber .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-amber .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.indigo-amber .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.indigo-amber .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.indigo-amber .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.indigo-amber .mat-hint {
  color: rgba(0, 0, 0, 0.6);
}
.indigo-amber .mat-form-field.mat-focused .mat-form-field-label {
  color: #3f51b5;
}
.indigo-amber .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #ffc107;
}
.indigo-amber .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #f44336;
}
.indigo-amber .mat-focused .mat-form-field-required-marker {
  color: #ffc107;
}
.indigo-amber .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.indigo-amber .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #3f51b5;
}
.indigo-amber .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #ffc107;
}
.indigo-amber .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #f44336;
}
.indigo-amber .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #3f51b5;
}
.indigo-amber .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #ffc107;
}
.indigo-amber .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #f44336;
}
.indigo-amber .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #f44336;
}
.indigo-amber .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.indigo-amber .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #f44336;
}
.indigo-amber .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.indigo-amber .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #f44336;
}
.indigo-amber .mat-error {
  color: #f44336;
}
.indigo-amber .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-amber .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-amber .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.indigo-amber .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.indigo-amber .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.indigo-amber .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.indigo-amber .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.indigo-amber .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.indigo-amber .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.indigo-amber .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-amber .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.indigo-amber .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.indigo-amber .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #3f51b5;
}
.indigo-amber .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #ffc107;
}
.indigo-amber .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #f44336;
}
.indigo-amber .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #f44336;
}
.indigo-amber .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-amber .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.indigo-amber .mat-icon.mat-primary {
  color: #3f51b5;
}
.indigo-amber .mat-icon.mat-accent {
  color: #ffc107;
}
.indigo-amber .mat-icon.mat-warn {
  color: #f44336;
}
.indigo-amber .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-amber .mat-input-element:disabled,
.indigo-amber .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-amber .mat-input-element {
  caret-color: #3f51b5;
}
.indigo-amber .mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.indigo-amber .mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.indigo-amber .mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.indigo-amber .mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.indigo-amber .mat-form-field.mat-accent .mat-input-element {
  caret-color: #ffc107;
}
.indigo-amber .mat-form-field.mat-warn .mat-input-element,
.indigo-amber .mat-form-field-invalid .mat-input-element {
  caret-color: #f44336;
}
.indigo-amber .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #f44336;
}
.indigo-amber .mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber .mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber .mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-amber .mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}
.indigo-amber .mat-list-option:hover, .indigo-amber .mat-list-option:focus,
.indigo-amber .mat-nav-list .mat-list-item:hover,
.indigo-amber .mat-nav-list .mat-list-item:focus,
.indigo-amber .mat-action-list .mat-list-item:hover,
.indigo-amber .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.indigo-amber .mat-list-single-selected-option, .indigo-amber .mat-list-single-selected-option:hover, .indigo-amber .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.indigo-amber .mat-menu-panel {
  background: white;
}
.indigo-amber .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.indigo-amber .mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber .mat-menu-item[disabled],
.indigo-amber .mat-menu-item[disabled] .mat-menu-submenu-icon,
.indigo-amber .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-amber .mat-menu-item .mat-icon-no-color,
.indigo-amber .mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-amber .mat-menu-item:hover:not([disabled]),
.indigo-amber .mat-menu-item.cdk-program-focused:not([disabled]),
.indigo-amber .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.indigo-amber .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.indigo-amber .mat-paginator {
  background: white;
}
.indigo-amber .mat-paginator,
.indigo-amber .mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-amber .mat-paginator-decrement,
.indigo-amber .mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.indigo-amber .mat-paginator-first,
.indigo-amber .mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.indigo-amber .mat-icon-button[disabled] .mat-paginator-decrement,
.indigo-amber .mat-icon-button[disabled] .mat-paginator-increment,
.indigo-amber .mat-icon-button[disabled] .mat-paginator-first,
.indigo-amber .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.indigo-amber .mat-progress-bar-background {
  fill: #cbd0e9;
}
.indigo-amber .mat-progress-bar-buffer {
  background-color: #cbd0e9;
}
.indigo-amber .mat-progress-bar-fill::after {
  background-color: #3f51b5;
}
.indigo-amber .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #fbecbd;
}
.indigo-amber .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #fbecbd;
}
.indigo-amber .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #ffc107;
}
.indigo-amber .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f9ccc9;
}
.indigo-amber .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f9ccc9;
}
.indigo-amber .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #f44336;
}
.indigo-amber .mat-progress-spinner circle, .indigo-amber .mat-spinner circle {
  stroke: #3f51b5;
}
.indigo-amber .mat-progress-spinner.mat-accent circle, .indigo-amber .mat-spinner.mat-accent circle {
  stroke: #ffc107;
}
.indigo-amber .mat-progress-spinner.mat-warn circle, .indigo-amber .mat-spinner.mat-warn circle {
  stroke: #f44336;
}
.indigo-amber .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.indigo-amber .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #3f51b5;
}
.indigo-amber .mat-radio-button.mat-primary .mat-radio-inner-circle,
.indigo-amber .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .indigo-amber .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .indigo-amber .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #3f51b5;
}
.indigo-amber .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ffc107;
}
.indigo-amber .mat-radio-button.mat-accent .mat-radio-inner-circle,
.indigo-amber .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .indigo-amber .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .indigo-amber .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #ffc107;
}
.indigo-amber .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f44336;
}
.indigo-amber .mat-radio-button.mat-warn .mat-radio-inner-circle,
.indigo-amber .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .indigo-amber .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .indigo-amber .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #f44336;
}
.indigo-amber .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.indigo-amber .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.indigo-amber .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.indigo-amber .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.indigo-amber .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-amber .mat-radio-button .mat-ripple-element {
  background-color: black;
}
.indigo-amber .mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber .mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.indigo-amber .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-amber .mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-amber .mat-select-panel {
  background: white;
}
.indigo-amber .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.indigo-amber .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.indigo-amber .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #3f51b5;
}
.indigo-amber .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #ffc107;
}
.indigo-amber .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #f44336;
}
.indigo-amber .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #f44336;
}
.indigo-amber .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-amber .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber .mat-drawer.mat-drawer-push {
  background-color: white;
}
.indigo-amber .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.indigo-amber .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.indigo-amber .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.indigo-amber [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.indigo-amber [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.indigo-amber .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.indigo-amber .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #ffc107;
}
.indigo-amber .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 193, 7, 0.54);
}
.indigo-amber .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #ffc107;
}
.indigo-amber .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #3f51b5;
}
.indigo-amber .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(63, 81, 181, 0.54);
}
.indigo-amber .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #3f51b5;
}
.indigo-amber .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #f44336;
}
.indigo-amber .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(244, 67, 54, 0.54);
}
.indigo-amber .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #f44336;
}
.indigo-amber .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
.indigo-amber .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
.indigo-amber .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.indigo-amber .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.indigo-amber .mat-slider.mat-primary .mat-slider-track-fill,
.indigo-amber .mat-slider.mat-primary .mat-slider-thumb,
.indigo-amber .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #3f51b5;
}
.indigo-amber .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.indigo-amber .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(63, 81, 181, 0.2);
}
.indigo-amber .mat-slider.mat-accent .mat-slider-track-fill,
.indigo-amber .mat-slider.mat-accent .mat-slider-thumb,
.indigo-amber .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #ffc107;
}
.indigo-amber .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(255, 193, 7, 0.2);
}
.indigo-amber .mat-slider.mat-warn .mat-slider-track-fill,
.indigo-amber .mat-slider.mat-warn .mat-slider-thumb,
.indigo-amber .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #f44336;
}
.indigo-amber .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.indigo-amber .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(244, 67, 54, 0.2);
}
.indigo-amber .mat-slider:hover .mat-slider-track-background,
.indigo-amber .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}
.indigo-amber .mat-slider.mat-slider-disabled .mat-slider-track-background,
.indigo-amber .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.indigo-amber .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.indigo-amber .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.indigo-amber .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.indigo-amber .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.indigo-amber .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.indigo-amber .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.indigo-amber .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.indigo-amber .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.indigo-amber .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .indigo-amber .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.indigo-amber .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .indigo-amber .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}
.indigo-amber .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.indigo-amber .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.indigo-amber .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.indigo-amber .mat-step-header.cdk-keyboard-focused, .indigo-amber .mat-step-header.cdk-program-focused, .indigo-amber .mat-step-header:hover:not([aria-disabled]), .indigo-amber .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.indigo-amber .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .indigo-amber .mat-step-header:hover {
    background: none;
  }
}
.indigo-amber .mat-step-header .mat-step-label,
.indigo-amber .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-amber .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.indigo-amber .mat-step-header .mat-step-icon-selected,
.indigo-amber .mat-step-header .mat-step-icon-state-done,
.indigo-amber .mat-step-header .mat-step-icon-state-edit {
  background-color: #3f51b5;
  color: white;
}
.indigo-amber .mat-step-header.mat-accent .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber .mat-step-header.mat-accent .mat-step-icon-selected,
.indigo-amber .mat-step-header.mat-accent .mat-step-icon-state-done,
.indigo-amber .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #ffc107;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.indigo-amber .mat-step-header.mat-warn .mat-step-icon-selected,
.indigo-amber .mat-step-header.mat-warn .mat-step-icon-state-done,
.indigo-amber .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #f44336;
  color: white;
}
.indigo-amber .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336;
}
.indigo-amber .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber .mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336;
}
.indigo-amber .mat-stepper-horizontal, .indigo-amber .mat-stepper-vertical {
  background-color: white;
}
.indigo-amber .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.indigo-amber .mat-horizontal-stepper-header::before,
.indigo-amber .mat-horizontal-stepper-header::after,
.indigo-amber .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.indigo-amber .mat-sort-header-arrow {
  color: #757575;
}
.indigo-amber .mat-tab-nav-bar,
.indigo-amber .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber .mat-tab-group-inverted-header .mat-tab-nav-bar,
.indigo-amber .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.indigo-amber .mat-tab-label, .indigo-amber .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber .mat-tab-label.mat-tab-disabled, .indigo-amber .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-amber .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.indigo-amber .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.indigo-amber .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.indigo-amber .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.indigo-amber .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-amber .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-amber .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-amber .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .indigo-amber .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-amber .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-amber .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-amber .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(197, 202, 233, 0.3);
}
.indigo-amber .mat-tab-group.mat-primary .mat-ink-bar, .indigo-amber .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #3f51b5;
}
.indigo-amber .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .indigo-amber .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .indigo-amber .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .indigo-amber .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.indigo-amber .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-amber .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-amber .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-amber .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .indigo-amber .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-amber .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-amber .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-amber .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 236, 179, 0.3);
}
.indigo-amber .mat-tab-group.mat-accent .mat-ink-bar, .indigo-amber .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #ffc107;
}
.indigo-amber .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .indigo-amber .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .indigo-amber .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .indigo-amber .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.indigo-amber .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-amber .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-amber .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-amber .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .indigo-amber .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-amber .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-amber .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-amber .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.indigo-amber .mat-tab-group.mat-warn .mat-ink-bar, .indigo-amber .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #f44336;
}
.indigo-amber .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .indigo-amber .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .indigo-amber .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .indigo-amber .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.indigo-amber .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-amber .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-amber .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-amber .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .indigo-amber .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-amber .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-amber .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-amber .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(197, 202, 233, 0.3);
}
.indigo-amber .mat-tab-group.mat-background-primary > .mat-tab-header, .indigo-amber .mat-tab-group.mat-background-primary > .mat-tab-link-container, .indigo-amber .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .indigo-amber .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .indigo-amber .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .indigo-amber .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #3f51b5;
}
.indigo-amber .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .indigo-amber .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .indigo-amber .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .indigo-amber .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.indigo-amber .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .indigo-amber .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .indigo-amber .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .indigo-amber .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.indigo-amber .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.indigo-amber .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.indigo-amber .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.indigo-amber .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .indigo-amber .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.indigo-amber .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.indigo-amber .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.indigo-amber .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.indigo-amber .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.indigo-amber .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .indigo-amber .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.indigo-amber .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.indigo-amber .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.indigo-amber .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.indigo-amber .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .indigo-amber .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.indigo-amber .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.indigo-amber .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.indigo-amber .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-amber .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-amber .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-amber .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .indigo-amber .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-amber .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-amber .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-amber .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 236, 179, 0.3);
}
.indigo-amber .mat-tab-group.mat-background-accent > .mat-tab-header, .indigo-amber .mat-tab-group.mat-background-accent > .mat-tab-link-container, .indigo-amber .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .indigo-amber .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .indigo-amber .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .indigo-amber .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #ffc107;
}
.indigo-amber .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .indigo-amber .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .indigo-amber .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .indigo-amber .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .indigo-amber .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .indigo-amber .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .indigo-amber .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.indigo-amber .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.indigo-amber .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.indigo-amber .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.indigo-amber .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .indigo-amber .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.indigo-amber .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.indigo-amber .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.indigo-amber .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.indigo-amber .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.indigo-amber .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .indigo-amber .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.indigo-amber .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.indigo-amber .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.indigo-amber .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.indigo-amber .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .indigo-amber .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.indigo-amber .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.indigo-amber .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.indigo-amber .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-amber .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-amber .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-amber .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .indigo-amber .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-amber .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-amber .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-amber .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.indigo-amber .mat-tab-group.mat-background-warn > .mat-tab-header, .indigo-amber .mat-tab-group.mat-background-warn > .mat-tab-link-container, .indigo-amber .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .indigo-amber .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .indigo-amber .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .indigo-amber .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #f44336;
}
.indigo-amber .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .indigo-amber .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .indigo-amber .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .indigo-amber .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.indigo-amber .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .indigo-amber .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .indigo-amber .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .indigo-amber .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.indigo-amber .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.indigo-amber .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.indigo-amber .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.indigo-amber .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .indigo-amber .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.indigo-amber .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.indigo-amber .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.indigo-amber .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.indigo-amber .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.indigo-amber .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .indigo-amber .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.indigo-amber .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.indigo-amber .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.indigo-amber .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.indigo-amber .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .indigo-amber .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.indigo-amber .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.indigo-amber .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.indigo-amber .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber .mat-toolbar.mat-primary {
  background: #3f51b5;
  color: white;
}
.indigo-amber .mat-toolbar.mat-accent {
  background: #ffc107;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber .mat-toolbar.mat-warn {
  background: #f44336;
  color: white;
}
.indigo-amber .mat-toolbar .mat-form-field-underline,
.indigo-amber .mat-toolbar .mat-form-field-ripple,
.indigo-amber .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.indigo-amber .mat-toolbar .mat-form-field-label,
.indigo-amber .mat-toolbar .mat-focused .mat-form-field-label,
.indigo-amber .mat-toolbar .mat-select-value,
.indigo-amber .mat-toolbar .mat-select-arrow,
.indigo-amber .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.indigo-amber .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.indigo-amber .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.indigo-amber .mat-tree {
  background: white;
}
.indigo-amber .mat-tree-node,
.indigo-amber .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.indigo-amber .mat-simple-snackbar-action {
  color: #ffc107;
}
.indigo-amber *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.indigo-amber *::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}
.indigo-amber *::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}
.indigo-amber .toolbar-line.container-ou {
  background: #3f51b5;
  color: white;
}
.indigo-amber .toolbar-line.container-persona {
  background: #3f51b5 !important;
}
.indigo-amber .toolbar-line.container-persona .persona-title {
  background: #3f51b5;
}
.indigo-amber .toolbar-line.container-persona .invisible-container.persona-selector {
  background: #3f51b5 !important;
}
.indigo-amber button.mat-icon-button:not([disabled]):hover {
  background: rgba(0, 0, 0, 0.04);
}
.indigo-amber .side-bar {
  background: #eeeeee;
}
.indigo-amber .icon-stacked {
  color: white !important;
}
.indigo-amber .icon-stacked mat-icon + mat-icon {
  text-shadow: -1px 1px 0px #3f51b5, 1px -1px 0px #3f51b5;
}
.indigo-amber .divider-vert {
  background: rgba(0, 0, 0, 0.12);
}
.indigo-amber .avatar {
  background: #303f9f !important;
}
.indigo-amber ngx-avatar .avatar-content {
  background: #303f9f !important;
}
.indigo-amber .mat-loading-window {
  background: white;
}
.indigo-amber .window-spinner {
  background: white;
}
.indigo-amber .load-window {
  background: white;
}
@media (min-width: 600px) and (max-width: 1300px) {
  .indigo-amber mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .load-window,
.indigo-amber mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .load-window.new {
    background: white !important;
  }
  .indigo-amber mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card {
    background: white !important;
  }
  .indigo-amber mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .inbox-container mat-toolbar {
    background: #3f51b5;
    color: white;
  }
  .indigo-amber mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .inbox-container mat-list-item.mat-list-item {
    border: 1px solid rgba(0, 0, 0, 0.12);
  }
  .indigo-amber mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .detail {
    background: white !important;
  }
  .indigo-amber mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .detail mat-toolbar {
    background: white !important;
  }
  .indigo-amber mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .main.w-detail + .new-detail-container .load-window {
    background: #fafafa !important;
  }
  .indigo-amber mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .main.w-detail + .new-detail-container:before {
    background: rgba(0, 0, 0, 0.6);
  }
  .indigo-amber mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .main.side-open + .side {
    border-left: 1px solid rgba(0, 0, 0, 0.12);
  }
  .indigo-amber mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .side .container .mat-list-base {
    background: white !important;
    border: 1px solid rgba(0, 0, 0, 0.12);
  }
  .indigo-amber mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .new-table .mat-header-row,
.indigo-amber mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .new-table .mat-table,
.indigo-amber mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .document-table .mat-header-row,
.indigo-amber mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .document-table .mat-table,
.indigo-amber mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .status-table .mat-header-row,
.indigo-amber mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .status-table .mat-table {
    background: white !important;
  }
  .indigo-amber mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .audit-filter .filter-container.is-floating {
    background: #fafafa !important;
    border: 1px solid rgba(0, 0, 0, 0.12);
  }
  .indigo-amber mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .audit-filter .filter-container.is-floating .form-body {
    background: #fafafa !important;
  }
  .indigo-amber mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .audit-result {
    background: white;
  }
  .indigo-amber mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .audit-detail-container {
    background: #fafafa !important;
  }
}
@media (max-width: 599px) {
  .indigo-amber app-document-detail .mat-toolbar {
    background: #303f9f !important;
    color: white !important;
  }
  .indigo-amber app-document-detail .mat-toolbar .mat-toolbar-row .toolbar-actions {
    background: #303f9f !important;
    color: white !important;
  }
  .indigo-amber .login-card {
    background: white !important;
  }
  .indigo-amber .inbox-toolbar {
    background: #303f9f !important;
    color: white !important;
  }
  .indigo-amber .detail {
    background: white url("../img/spinner_mobile.gif") no-repeat center center !important;
  }
  .indigo-amber .toolbar-line-container .toolbar-line:first-child {
    background: #303f9f !important;
    color: white !important;
  }
  .indigo-amber .toolbar-actions {
    background: #303f9f !important;
    color: white !important;
  }
  .indigo-amber mat-drawer.side-nav.enter {
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12) !important;
  }
  .indigo-amber .side-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0) !important;
  }
  .indigo-amber .filter {
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.55) 16%, rgba(0, 0, 0, 0) 100%) !important;
  }
  .indigo-amber button.side-open-mobile {
    color: white !important;
  }
  .indigo-amber .side-nav .side-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0);
  }
  .indigo-amber .side-nav .filter {
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.55) 16%, rgba(0, 0, 0, 0) 100%);
  }
  .indigo-amber .side-nav .side-nav-container .links {
    background: white;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
  }
  .indigo-amber .side-nav .side-nav-container .version {
    background: white !important;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
  }
  .indigo-amber app-file-document-view .view-container {
    background: white !important;
  }
  .indigo-amber app-file-document-view .view-container .side-bar {
    background: #303f9f !important;
    color: white !important;
  }
  .indigo-amber app-file-document-view .view-container .view-content app-pdf-wrapper.floating {
    background: white;
  }
  .indigo-amber app-file-document-view .view-container .view-content .upload-indications {
    background: #eeeeee;
  }
  .indigo-amber app-file-document-view .view-container .view-content app-file-document-sign .sign-cycle mat-horizontal-stepper.mat-stepper-horizontal {
    background-color: rgba(0, 0, 0, 0) !important;
  }
  .indigo-amber app-file-document-view .view-container .view-content app-file-document-sign .sign-cycle .sign-cycle-content .cycle-content-action .mat-ripple-element {
    background-color: rgba(255, 255, 255, 0.1) !important;
  }
  .indigo-amber app-pending .floating-container .inbox-container .inbox-toolbar .mat-toolbar-row .mat-avatar h3 {
    color: white !important;
  }
  .indigo-amber app-pending .floating-container .detail {
    background: white url("../img/spinner_mobile.gif") no-repeat center center !important;
  }
  .indigo-amber app-document-sign-massive .inbox-container .pending-container app-chapa.minimized .info-empty {
    background: #ffc107;
  }
  .indigo-amber app-document-sign-massive .inbox-container .pending-container app-chapa.minimized .info-empty > span h2 {
    color: white !important;
  }
  .indigo-amber app-document-sign-massive .inbox-container .signer.mobile {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    background: white;
  }
  .indigo-amber app-document-sign-massive .detail app-file-document-detail .view-container .mat-toolbar .mat-toolbar-row .view-actions {
    color: white !important;
  }
  .indigo-amber app-add-documentation .floating-container .employee-add-documentation .inbox-container .inbox-toolbar .mat-toolbar-row .mat-avatar h3 {
    color: white !important;
  }
  .indigo-amber app-add-documentation .floating-container .employee-add-documentation .detail {
    background: white url("../img/spinner_mobile.gif") no-repeat center center !important;
  }
  .indigo-amber app-add-documentation .floating-container .employee-add-documentation .detail app-file-document-view .view-container .view-content .new-container app-file-document-upload {
    background: white;
  }
}
.indigo-amber .mat-card .mat-drawer-container {
  background: white !important;
}
.indigo-amber .mat-drawer-container {
  background: #e0e0e0 !important;
}
.indigo-amber .mat-list .mat-list-item.finished-process-inbox.active {
  background: #4DB6AC !important;
}
.indigo-amber .mat-list .mat-list-item.finished-process-inbox .mat-icon {
  background: #4DB6AC;
}
.indigo-amber .mat-list .mat-list-item.erased-process-inbox.active {
  background: #837C80 !important;
}
.indigo-amber .mat-list .mat-list-item.limbo-process-inbox.active {
  background: #FFD54F !important;
}
.indigo-amber .mat-list .mat-list-item.limbo-process-inbox .mat-icon {
  background: #FFD54F;
}
.indigo-amber .mat-list .mat-list-item.in-process-inbox.active {
  background: #FFD54F !important;
}
.indigo-amber .mat-list .mat-list-item.in-process-inbox .mat-icon {
  background: #FFD54F;
}
.indigo-amber .mat-list .mat-list-item.error-process-inbox.active {
  background: #F48FB1 !important;
}
.indigo-amber .mat-list .mat-list-item.error-process-inbox .mat-icon {
  background: #F48FB1;
}
.indigo-amber .mat-list .mat-list-item.active .mat-line b {
  color: #3f51b5;
}
.indigo-amber .mat-list .mat-list-item.active .mat-list-avatar {
  color: #3f51b5;
}
.indigo-amber .mat-list .mat-list-item:hover:not(.active) {
  background: rgba(0, 0, 0, 0.04);
}
.indigo-amber span.mat-badge-content {
  color: #f44336 !important;
}
.indigo-amber mat-expansion-panel.mat-expanded {
  background: #fafafa !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber mat-expansion-panel.virtual-scrolling .mat-expansion-panel-content .get-more {
  color: #3f51b5;
}
.indigo-amber mat-expansion-panel.virtual-scrolling .mat-expansion-panel-content app-cs-paginator.get-more {
  background: white;
}
.indigo-amber mat-expansion-panel-header.mat-expanded {
  background: white !important;
}
.indigo-amber .bottom-container {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber .load-more * {
  color: #3f51b5;
}
.indigo-amber .welcome-card {
  background: white !important;
}
.indigo-amber .welcome-card .img-divider {
  background: #303f9f;
}
.indigo-amber .mat-header-row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background: white;
}
.indigo-amber .mat-row {
  background: white;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.indigo-amber .mat-row .mat-cell.status-cell mat-icon.pending {
  color: #FF9800 !important;
}
.indigo-amber .mat-row .mat-cell.status-cell mat-icon.ok {
  color: #009688 !important;
}
.indigo-amber .mat-row .mat-cell.status-cell mat-icon.not-ok {
  color: #9C27B0 !important;
}
.indigo-amber .mat-row .mat-cell.status-cell mat-icon.cancelled {
  color: #795548 !important;
}
.indigo-amber .mat-row .mat-cell.status-cell mat-icon.rejected {
  color: #df4d4c !important;
}
.indigo-amber .mat-row .mat-cell.status-cell mat-icon.draft {
  color: #dedede !important;
}
.indigo-amber .mat-row.active, .indigo-amber .mat-row.active:hover {
  background: #3f51b5 !important;
}
.indigo-amber .mat-row.active div, .indigo-amber .mat-row.active:hover div {
  color: white;
}
.indigo-amber .mat-row.active .mat-cell.status-cell mat-icon.pending, .indigo-amber .mat-row.active .mat-cell.status-cell mat-icon.ok, .indigo-amber .mat-row.active .mat-cell.status-cell mat-icon.not-ok, .indigo-amber .mat-row.active:hover .mat-cell.status-cell mat-icon.pending, .indigo-amber .mat-row.active:hover .mat-cell.status-cell mat-icon.ok, .indigo-amber .mat-row.active:hover .mat-cell.status-cell mat-icon.not-ok {
  text-shadow: -1px 1px 0 white, 1px 1px 0 white, 1px -1px 0 white, -1px -1px 0 white;
}
.indigo-amber .mat-row.active .sticky-actions button, .indigo-amber .mat-row.active:hover .sticky-actions button {
  background: #3f51b5 !important;
}
.indigo-amber .mat-row.active .sticky-actions button.ok mat-icon, .indigo-amber .mat-row.active .sticky-actions button.not-ok mat-icon, .indigo-amber .mat-row.active:hover .sticky-actions button.ok mat-icon, .indigo-amber .mat-row.active:hover .sticky-actions button.not-ok mat-icon {
  text-shadow: -1px 1px 0 white, 1px 1px 0 white, 1px -1px 0 white, -1px -1px 0 white;
}
.indigo-amber .mat-row:nth-child(odd) {
  background: #fafafa;
}
.indigo-amber .mat-row.selected div {
  color: white;
}
.indigo-amber .mat-row.selected:nth-child(even) {
  background: #9fa8da !important;
}
.indigo-amber .mat-row.selected:nth-child(even) .sticky-actions button {
  background: #9fa8da !important;
}
.indigo-amber .mat-row.selected:nth-child(odd) {
  background: #c5cae9 !important;
}
.indigo-amber .mat-row.selected:nth-child(odd) .sticky-actions button {
  background: #c5cae9 !important;
}
.indigo-amber .mat-row.selected.active, .indigo-amber .mat-row.selected.active:hover {
  background: #3f51b5 !important;
}
.indigo-amber .mat-row.selected.active .sticky-actions button, .indigo-amber .mat-row.selected.active:hover .sticky-actions button {
  background: #3f51b5 !important;
}
.indigo-amber .mat-row:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.indigo-amber .mat-row:hover .sticky-actions button {
  background: white !important;
}
.indigo-amber .mat-row.canceled * {
  color: #795548;
}
.indigo-amber .mat-row.disabled div {
  color: #607D8B;
}
.indigo-amber .mat-row.disabled.active, .indigo-amber .mat-row.disabled.active:hover {
  background: #607D8B !important;
}
.indigo-amber .mat-row.disabled.active div, .indigo-amber .mat-row.disabled.active:hover div {
  color: white;
}
.indigo-amber .mat-row.disabled.active .sticky-actions button, .indigo-amber .mat-row.disabled.active:hover .sticky-actions button {
  background: #607D8B !important;
}
.indigo-amber .mat-row.disabled.selected.active:hover .sticky-actions button {
  background: #607D8B !important;
}
.indigo-amber .mat-row .sticky-actions button.ok mat-icon {
  color: #009688 !important;
}
.indigo-amber .mat-row .sticky-actions button.not-ok mat-icon {
  color: #9C27B0 !important;
}
.indigo-amber .status-table .mat-header-row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background: white;
}
.indigo-amber .icon-info-off,
.indigo-amber .certificate-off {
  color: #607D8B;
}
.indigo-amber .documents-search-table .mat-header-row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background: white;
}
.indigo-amber mat-toolbar.employee-search-sub-toolbar {
  background: white;
}
.indigo-amber .mat-search-field {
  border: 1px solid rgba(0, 0, 0, 0.12);
  background: #eeeeee;
}
.indigo-amber .mat-search-field input[type=search] {
  background: rgba(0, 0, 0, 0);
}
.indigo-amber .mat-search-field input[type=search]::placeholder {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-amber .search-adv-menu .mat-search-field {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber mat-toolbar.document-search-sub-toolbar {
  background: white;
}
.indigo-amber app-advanced-employee-search .mat-chip {
  background: rgba(0, 0, 0, 0.04) !important;
}
.indigo-amber .side-nav .side-trigger {
  background: white;
}
.indigo-amber .side-nav .menu-trigger mat-icon {
  color: rgba(0, 0, 0, 0.54);
  background: white;
}
.indigo-amber .side-nav .side-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0);
}
.indigo-amber .side-nav .filter {
  background: white;
}
.indigo-amber .side-nav .side-nav-container .links mat-icon span.mat-badge-content {
  color: white !important;
}
.indigo-amber .side-nav .side-nav-container .mat-list-base .mat-list-item:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.indigo-amber .side-nav .side-nav-container .mat-list-base .mat-list-item.active {
  background: #3f51b5 !important;
}
.indigo-amber .side-nav .side-nav-container .mat-list-base .mat-list-item.active * {
  color: white;
}
.indigo-amber .side-nav .side-nav-container .mat-list-base .mat-list-item.active .mat-list-item.active:hover {
  background: #3f51b5 !important;
}
.indigo-amber .side-nav .side-nav-container .mat-list-base .mat-list-item.active .mat-list-item.active:hover * {
  color: white;
}
.indigo-amber .side-nav.open .side-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber .side-nav.open .filter {
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.55) 16%, rgba(0, 0, 0, 0) 100%);
}
.indigo-amber .side-nav.open .side-nav-container .links {
  background: white;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber .side-nav.open .side-nav-container .version {
  background: white !important;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber .side-nav.enter .side-nav-container .links {
  background: white;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber .side-nav.enter .side-nav-container .version {
  background: white !important;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber .mat-menu-panel:not(.metadata-radio-menu) .navigation-list .mat-list-item-content {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-amber table.mat-calendar-table {
  background: white !important;
}
.indigo-amber .mat-calendar-body-label {
  color: white !important;
}
.indigo-amber .mat-calendar-arrow {
  border-top-color: white !important;
}
.indigo-amber .mat-datepicker-content .mat-calendar-next-button,
.indigo-amber .mat-datepicker-content .mat-calendar-previous-button {
  color: white !important;
}
.indigo-amber .mat-calendar-header {
  background: #303f9f !important;
}
.indigo-amber mat-datepicker-content {
  background-color: #303f9f !important;
  color: white !important;
}
.indigo-amber .mat-calendar-content {
  background: white !important;
}
.indigo-amber .mat-simple-snackbar-action button.mat-button {
  color: #FFE082;
}
.indigo-amber .mat-simple-snackbar-action .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.indigo-amber mat-bottom-sheet-container mat-toolbar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}
.indigo-amber app-generic-bottom-sheet h4.accent {
  color: #ffc107;
}
.indigo-amber app-generic-bottom-sheet .verification-code {
  background: #3f51b5;
  color: white;
}
.indigo-amber app-generic-bottom-sheet .verification-code h2 {
  background: #3f51b5;
}
.indigo-amber app-generic-bottom-sheet .load-window.message .info-container h3.primary-text {
  color: #3f51b5;
}
.indigo-amber app-generic-bottom-sheet .bottom_sheet-content.ok .bottom_sheet-title .bottom_sheet-icon,
.indigo-amber app-generic-bottom-sheet .bottom_sheet-content.ok .bottom_sheet-title h3 {
  color: #009688;
}
.indigo-amber app-generic-bottom-sheet .bottom_sheet-content.ok .bottom_sheet-main_btn:not(:disabled) {
  background: #009688;
  color: #fff;
}
.indigo-amber app-generic-bottom-sheet .bottom_sheet-content.not-ok .bottom_sheet-title .bottom_sheet-icon,
.indigo-amber app-generic-bottom-sheet .bottom_sheet-content.not-ok .bottom_sheet-title h3 {
  color: #9C27B0;
}
.indigo-amber app-generic-bottom-sheet .bottom_sheet-content.not-ok .bottom_sheet-main_btn:not(:disabled) {
  background: #9C27B0;
  color: #fff;
}
.indigo-amber app-generic-bottom-sheet .bottom_sheet-content.cancel .bottom_sheet-title .bottom_sheet-icon,
.indigo-amber app-generic-bottom-sheet .bottom_sheet-content.cancel .bottom_sheet-title h3 {
  color: #795548;
}
.indigo-amber app-generic-bottom-sheet .bottom_sheet-content.cancel .bottom_sheet-main_btn:not(:disabled) {
  background: #795548;
  color: #fff;
}
.indigo-amber app-generic-bottom-sheet .bottom_sheet-content .bottom_sheet_exp_btn {
  background-color: #3f51b5;
  color: #fff;
}
.indigo-amber app-generic-bottom-sheet .bottom_sheet-content .bottom_sheet_canc_btn {
  background-color: lightgrey;
  color: black;
}
.indigo-amber app-generic-bottom-sheet .bottom_sheet-content .bottom_sheet-exp-h3 {
  color: #3f51b5;
  margin-bottom: -10px !important;
}
.indigo-amber app-generic-bottom-sheet .bottom_sheet-content .mat-input-element-export {
  display: flex !important;
  padding-left: 15px;
}
.indigo-amber app-generic-bottom-sheet .bottom_sheet-content .mat-form-field-infix-export-right {
  width: 100% !important;
  padding: 0px !important;
  display: flex !important;
}
.indigo-amber app-generic-bottom-sheet .bottom_sheet-content .mat-form-field-infix-export-left {
  width: 100% !important;
  padding: 0px;
  display: flex !important;
}
.indigo-amber app-generic-bottom-sheet .bottom_sheet-content .mat-form-field-infix {
  display: flex;
}
.indigo-amber app-generic-bottom-sheet .bottom_sheet-content .mat-checkbox-layout .mat-checkbox-label {
  color: #ffc107;
}
.indigo-amber app-generic-bottom-sheet .bottom_sheet-content .choose-forms {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  margin-left: 10px !important;
}
.indigo-amber app-generic-bottom-sheet .bottom_sheet-content .card-container {
  max-height: 150px;
  overflow-y: auto;
}
.indigo-amber .mat-list .mat-list-item.active .mat-line {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber .mat-list .mat-list-item.active .mat-line b {
  color: #3f51b5;
}
.indigo-amber .mat-list .mat-list-item.active .mat-list-avatar {
  color: #3f51b5;
}
.indigo-amber .mat-list .mat-list-item .mat-line {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-amber .mat-list .mat-list-item .mat-list-avatar {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-amber .mat-list-item:hover:not(.active) {
  background: rgba(0, 0, 0, 0.04) !important;
}
.indigo-amber mat-list-item:nth-child(even),
.indigo-amber app-file-document-inbox-item:nth-child(even) mat-list-item,
.indigo-amber app-file-document-inbox-item-documentation:nth-child(even) mat-list-item {
  background: #fafafa !important;
}
.indigo-amber mat-list-item:nth-child(odd),
.indigo-amber app-file-document-inbox-item:nth-child(odd) mat-list-item,
.indigo-amber app-file-document-inbox-item-documentation:nth-child(odd) mat-list-item {
  background: white !important;
}
.indigo-amber .mat-list-avatar.mat-icon {
  background: #eeeeee;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber .card-filter {
  background: white;
}
.indigo-amber .login mat-horizontal-stepper {
  background: white !important;
}
.indigo-amber .login .login-card {
  background: white !important;
}
.indigo-amber .login .login-card .mat-card-content .mat-card-footer a[color=accent] {
  color: #ffc107;
}
.indigo-amber .login .help-stepper h3.align-16 {
  color: #ffc107;
}
.indigo-amber .login .help-stepper .help-options mat-icon {
  color: #ffc107;
}
.indigo-amber .ux-modal {
  background: rgba(0, 0, 0, 0.32);
}
.indigo-amber .ux-modal .card {
  background: white;
}
.indigo-amber .main-title {
  background: #607D8B;
  color: white;
}
.indigo-amber .sub-title {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-amber .card-footer a {
  color: white;
}
.indigo-amber .profile-toolbar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber app-profile .logout-btn:hover {
  background: #eeeeee;
}
.indigo-amber app-profile .cropper {
  background: white;
}
.indigo-amber app-profile .cropper .cropper {
  background: unset;
}
.indigo-amber app-add-documentation .employee-add-documentation .inbox-toolbar {
  background: #fafafa;
}
.indigo-amber app-add-documentation .employee-add-documentation .inbox-toolbar .toolbar-line mat-icon {
  color: #3f51b5;
}
.indigo-amber app-add-documentation .employee-add-documentation .inbox-toolbar .toolbar-line h3 {
  color: #3f51b5;
}
.indigo-amber app-add-documentation .employee-add-documentation mat-tab-group {
  background: #fafafa;
}
.indigo-amber app-add-documentation .employee-add-documentation mat-tab-group mat-tab-header .mat-tab-label-active {
  color: #3f51b5;
}
.indigo-amber app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content:hover {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12) !important;
}
.indigo-amber app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content.selected {
  border-left: 6px solid #3f51b5 !important;
}
.indigo-amber app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content.selected mat-icon {
  color: #3f51b5 !important;
}
.indigo-amber app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content.selected .item-data {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-amber app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content mat-icon {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-amber app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content .item-data {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-amber app-add-documentation .employee-add-documentation .detail app-file-document-view .new-container app-file-document-upload .metadata-list .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
  background: white !important;
}
.indigo-amber app-pending .inbox-toolbar {
  background: #fafafa;
}
.indigo-amber app-pending .pending-container {
  background: #fafafa;
}
.indigo-amber app-pending .pending-container .pending-items .pending-item .content:hover {
  background: rgba(0, 0, 0, 0.04) !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12) !important;
}
.indigo-amber app-pending .pending-container .pending-items .pending-item .content.selected {
  border-left: 6px solid #ffc107 !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12) !important;
}
.indigo-amber app-pending .pending-container .pending-items .pending-item .content.selected mat-icon {
  color: #ffc107 !important;
}
.indigo-amber app-pending .pending-container .pending-items .pending-item .content.selected .item-data {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-amber app-pending .pending-container .pending-items .pending-item .content mat-icon {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-amber app-pending .pending-container .pending-items .pending-item .content .item-data {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-amber app-document-sign-massive .inbox-container .inbox-toolbar {
  background: #fafafa;
}
.indigo-amber app-document-sign-massive .inbox-container .pending-container {
  background: #fafafa;
}
.indigo-amber app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content:hover {
  background: rgba(0, 0, 0, 0.04) !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12) !important;
}
.indigo-amber app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content.selected {
  border-left: 6px solid #ffc107 !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12) !important;
}
.indigo-amber app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content.selected mat-icon {
  color: #ffc107 !important;
}
.indigo-amber app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content.selected .item-data {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-amber app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content mat-icon {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-amber app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content .item-data {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-amber app-document-sign-massive .detail app-file-document-detail .view-container {
  background: #607D8B;
}
.indigo-amber app-document-sign-massive .detail app-file-document-detail .view-container .mat-toolbar {
  background: #607D8B;
  color: white;
  background: #607D8B;
}
.indigo-amber app-document-sign-massive .detail app-chapa.minimized .info-empty {
  background: #ffc107;
}
.indigo-amber app-document-sign-massive .detail app-chapa.minimized .info-empty > span mat-icon {
  color: white !important;
}
.indigo-amber app-document-sign-massive .detail app-chapa.minimized .info-empty > span h2 {
  color: white !important;
}
.indigo-amber app-document-sign-massive .signer.desktop {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber app-document-sign-massive .signer .signer-btn {
  color: white !important;
}
.indigo-amber app-document-sign-massive .signer .signer-btn[disabled] {
  background-color: rgba(0, 0, 0, 0.12);
}
.indigo-amber app-document-detail app-sign-document .firmador button.mat-raised-button.mat-accent {
  color: white !important;
}
.indigo-amber mat-toolbar-row.firmado-conforme .mat-icon {
  background: #8BC34A;
  color: white;
}
.indigo-amber mat-toolbar-row.firmado-no-conforme .mat-icon {
  background: #f44336;
  color: white;
}
.indigo-amber .secondary-toolbar {
  background: white;
}
.indigo-amber .new-detail-container {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber .new-detail-container.cancelled .detail_head-body {
  background: #607D8B !important;
}
.indigo-amber .new-detail-container .employee-detail-toolbar {
  background: #eeeeee;
}
.indigo-amber .new-detail-container .employee-detail-subbar {
  background: white;
}
.indigo-amber app-employee-detail .load-window,
.indigo-amber app-employee-rrhh-detail .load-window {
  background: #fafafa;
}
.indigo-amber app-employee-detail .employee_detail-container,
.indigo-amber app-employee-rrhh-detail .employee_detail-container {
  background: #fafafa;
}
.indigo-amber app-employee-detail .employee_detail-container.is-editing .edition-line,
.indigo-amber app-employee-rrhh-detail .employee_detail-container.is-editing .edition-line {
  background: #eeeeee;
}
.indigo-amber app-employee-detail .employee_detail-container .detail_head-body,
.indigo-amber app-employee-rrhh-detail .employee_detail-container .detail_head-body {
  background: #3f51b5;
}
.indigo-amber app-employee-detail .employee_detail-container .detail_head-body .head_body-status .icon-stacked + mat-icon,
.indigo-amber app-employee-rrhh-detail .employee_detail-container .detail_head-body .head_body-status .icon-stacked + mat-icon {
  color: white !important;
}
.indigo-amber app-employee-detail .employee_detail-container .detail_head-body .head_body-title .body_title-name,
.indigo-amber app-employee-rrhh-detail .employee_detail-container .detail_head-body .head_body-title .body_title-name {
  color: white;
}
.indigo-amber app-employee-detail .employee_detail-container .detail_head-body .head_body-title .body_title-user,
.indigo-amber app-employee-rrhh-detail .employee_detail-container .detail_head-body .head_body-title .body_title-user {
  color: white;
}
.indigo-amber app-employee-detail .employee_detail-container .data .data-line,
.indigo-amber app-employee-rrhh-detail .employee_detail-container .data .data-line {
  background: white;
}
.indigo-amber app-employee-detail .employee_detail-container .data .data-line .icon-complex mat-icon.complex,
.indigo-amber app-employee-rrhh-detail .employee_detail-container .data .data-line .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px white, 0 -2px 0px white, 2px 0 0px white, 0 2px 0px white, -2px -2px 0px white, 2px 2px 0px white, 2px -2px 0px white, -2px 2px 0px white;
}
.indigo-amber app-employee-detail .employee_detail-container .data .data-line .line_text-main,
.indigo-amber app-employee-rrhh-detail .employee_detail-container .data .data-line .line_text-main {
  color: #3f51b5;
}
.indigo-amber app-employee-detail .employee_detail-container .data .data-line.clickable:hover,
.indigo-amber app-employee-rrhh-detail .employee_detail-container .data .data-line.clickable:hover {
  background: #fafafa !important;
}
.indigo-amber app-employee-detail .employee_detail-container .data .data-line button.mat-icon-button,
.indigo-amber app-employee-rrhh-detail .employee_detail-container .data .data-line button.mat-icon-button {
  background: #eeeeee;
}
.indigo-amber app-employee-detail .employee_detail-container .employee-title .title-text,
.indigo-amber app-employee-rrhh-detail .employee_detail-container .employee-title .title-text {
  color: #3f51b5;
}
.indigo-amber app-employee-detail .employee_detail-container .employee-title .title-text mat-icon,
.indigo-amber app-employee-rrhh-detail .employee_detail-container .employee-title .title-text mat-icon {
  color: #3f51b5;
}
.indigo-amber app-employee-detail .employee_detail-container .employee-title .title-text .title_text-main,
.indigo-amber app-employee-rrhh-detail .employee_detail-container .employee-title .title-text .title_text-main {
  color: #3f51b5;
}
.indigo-amber .UI-V2 .icon-complex mat-icon.complex {
  color: #3f51b5;
  text-shadow: -2px 0 0px white, 0 -2px 0px white, 2px 0 0px white, 0 2px 0px white, -2px -2px 0px white, 2px 2px 0px white, 2px -2px 0px white, -2px 2px 0px white;
}
.indigo-amber .UI-V2 .icon-complex mat-icon.complex.mat-accent {
  color: #ffc107;
}
.indigo-amber .UI-V2 .main .toolbar .header .content .module-title {
  color: #3f51b5;
}
.indigo-amber .UI-V2 .main .toolbar .header .content .module-title.selectable:hover {
  background: rgba(0, 0, 0, 0.04);
}
.indigo-amber .UI-V2 .main .toolbar .header .content .module-title .info h5 {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-amber .UI-V2 .main .toolbar .header .content .ou-select {
  color: white;
  background: #3f51b5;
}
.indigo-amber .UI-V2 .main .toolbar .search-container .search .content {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber .UI-V2 .main .toolbar .search-container .search .content:hover, .indigo-amber .UI-V2 .main .toolbar .search-container .search .content:active, .indigo-amber .UI-V2 .main .toolbar .search-container .search .content:focus, .indigo-amber .UI-V2 .main .toolbar .search-container .search .content:focus-within {
  background: rgba(0, 0, 0, 0.04) !important;
}
.indigo-amber .UI-V2 .main .toolbar .search-container .search .content input {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber .UI-V2 .main .helper {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber .UI-V2 .main .helper mat-hint h4 {
  color: #3f51b5;
}
.indigo-amber .UI-V2 .main .new-table app-cs-grid-control datatable-header * {
  color: #3f51b5;
}
.indigo-amber .UI-V2 .main .new-table .mat-header-row.sticky * {
  color: #3f51b5 !important;
}
.indigo-amber .UI-V2 .main .main-action {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber .menu-options.help .primary {
  color: #3f51b5;
}
.indigo-amber .menu-options.help .accent {
  color: #ffc107;
}
.indigo-amber .menu-options .icon-complex mat-icon.complex {
  color: #3f51b5;
  text-shadow: -2px 0 0px white, 0 -2px 0px white, 2px 0 0px white, 0 2px 0px white, -2px -2px 0px white, 2px 2px 0px white, 2px -2px 0px white, -2px 2px 0px white;
}
.indigo-amber .menu-options .mat-menu-content .container mat-list .mat-list-item:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.indigo-amber .menu-options .mat-menu-content .container mat-list .mat-list-item .disabled {
  color: rgba(0, 0, 0, 0.54) !important;
}
.indigo-amber .menu-options .mat-menu-content .container .slide-group mat-radio-group mat-radio-button:hover {
  background: rgba(0, 0, 0, 0.04);
}
.indigo-amber .menu-options .mat-menu-content .container .slide-group mat-form-field .mat-form-field-flex {
  background: #fafafa;
}
.indigo-amber .menu-options .mat-menu-content .container .slide-group mat-slide-toggle:hover {
  background: rgba(0, 0, 0, 0.04);
}
.indigo-amber .mat-menu-panel.notifications-panel .mat-menu-content .notifications-body {
  background: #fafafa;
}
.indigo-amber .mat-menu-panel.notifications-panel .mat-menu-content .notification .primary mat-icon {
  color: #3f51b5;
}
.indigo-amber .mat-menu-panel.notifications-panel .mat-menu-content .notification .primary h3 {
  color: #3f51b5;
}
.indigo-amber .mat-menu-panel.notifications-panel .mat-menu-content .notification.top {
  background: #fafafa;
}
.indigo-amber .mat-menu-panel.notifications-panel .mat-menu-content .notification.top .header h3 {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber .mat-menu-panel.notifications-panel .mat-menu-content .notification.bottom {
  background: #fafafa;
}
.indigo-amber .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable:nth-child(even) {
  background: #fafafa;
}
.indigo-amber .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable:nth-child(odd) {
  background: white;
}
.indigo-amber .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable:hover {
  background: rgba(0, 0, 0, 0.04);
}
.indigo-amber .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable mat-icon {
  color: #3f51b5;
}
.indigo-amber .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable h3 {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable h5 {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-amber .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable .subtitle h5 {
  color: #3f51b5;
  background: #eeeeee;
}
.indigo-amber .info-empty .large-icon {
  background: #c5cae9;
  color: #3f51b5;
}
.indigo-amber .info-empty h2,
.indigo-amber .info-empty h3 {
  color: #3f51b5;
}
.indigo-amber .info-empty h4,
.indigo-amber .info-empty h5 {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-amber .info-empty.accent mat-icon {
  background: #ffecb3;
  color: #ffc107;
}
.indigo-amber .info-empty.accent h2 {
  color: #ffc107;
}
.indigo-amber .info-empty .actions {
  padding: 16px;
  height: 96px;
  column-gap: 16px;
}
.indigo-amber .info-empty .actions button {
  height: 100%;
  border-radius: 40px;
}
.indigo-amber .info-empty .actions button.ok {
  background: #009688 !important;
  color: white;
}
.indigo-amber .info-empty .actions button.not-ok {
  color: #9C27B0 !important;
}
.indigo-amber .side.edition .info-empty .large-icon {
  background: #ffecb3;
  color: #ffc107;
}
.indigo-amber .side.edition .info-empty h2 {
  color: #ffc107;
}
.indigo-amber .side.edition .info-empty h4 {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-amber .UI-V2 .deferred-process-list .header h3 {
  color: #3f51b5;
}
.indigo-amber .UI-V2 .deferred-process-list .search-container .search .content {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber .UI-V2 .deferred-process-list .search-container .search .content:hover, .indigo-amber .UI-V2 .deferred-process-list .search-container .search .content:active, .indigo-amber .UI-V2 .deferred-process-list .search-container .search .content:focus, .indigo-amber .UI-V2 .deferred-process-list .search-container .search .content:focus-within {
  background: rgba(0, 0, 0, 0.04) !important;
}
.indigo-amber .UI-V2 .deferred-process-list .search-container .search .content input {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber .UI-V2 .deferred-process-list .text-success,
.indigo-amber .UI-V2 .deferred-process-details .text-success {
  color: #8BC34A;
}
.indigo-amber .UI-V2 .deferred-process-list .text-warn,
.indigo-amber .UI-V2 .deferred-process-details .text-warn {
  color: #f44336;
}
.indigo-amber .UI-V2 .deferred-process-list .text-primary,
.indigo-amber .UI-V2 .deferred-process-details .text-primary {
  color: #3f51b5;
}
.indigo-amber .UI-V2 .deferred-process-list .text-atention,
.indigo-amber .UI-V2 .deferred-process-details .text-atention {
  color: #DB7703;
}
.indigo-amber .UI-V2 .deferred-process-list .table-paginator-container,
.indigo-amber .UI-V2 .deferred-process-details .table-paginator-container {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
}
.indigo-amber .UI-V2 .deferred-process-list .table-paginator-container .mat-table,
.indigo-amber .UI-V2 .deferred-process-details .table-paginator-container .mat-table {
  border-bottom: none;
  border-radius: 10px 10px 0 0;
}
.indigo-amber .UI-V2 .deferred-process-list .table-paginator-container .paginator-container,
.indigo-amber .UI-V2 .deferred-process-details .table-paginator-container .paginator-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: #f2f2f2;
  padding: 0 1.5rem;
  height: 3rem;
}
.indigo-amber .UI-V2 .deferred-process-list .mat-table,
.indigo-amber .UI-V2 .deferred-process-details .mat-table {
  border: 1px solid #f2f2f2;
  border-radius: 10px;
  overflow: hidden;
}
.indigo-amber .UI-V2 .deferred-process-list .mat-table .mat-header-row .mat-header-cell,
.indigo-amber .UI-V2 .deferred-process-details .mat-table .mat-header-row .mat-header-cell {
  font-size: 14px;
  font-weight: 700;
}
.indigo-amber .UI-V2 .deferred-process-list .mat-table .mat-header-row,
.indigo-amber .UI-V2 .deferred-process-details .mat-table .mat-header-row {
  background: #f2f2f2;
}
.indigo-amber .UI-V2 .deferred-process-list .mat-table .mat-header-row .mat-header-cell,
.indigo-amber .UI-V2 .deferred-process-details .mat-table .mat-header-row .mat-header-cell {
  color: #3f51b5;
}
.indigo-amber .UI-V2 .deferred-process-list .mat-table .mat-row,
.indigo-amber .UI-V2 .deferred-process-details .mat-table .mat-row {
  background: white;
}
.indigo-amber .UI-V2 .progress-bar-container {
  position: relative;
}
.indigo-amber .UI-V2 .deferred-process-details .header h3 {
  color: #3f51b5;
}
.indigo-amber .UI-V2 .deferred-process-details .header .back-link:visited {
  color: #3f51b5;
}
.indigo-amber .process-card,
.indigo-amber .process-card-detail,
.indigo-amber .process-card-stages {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber .process-card mat-card-subtitle,
.indigo-amber .process-card-detail mat-card-subtitle,
.indigo-amber .process-card-stages mat-card-subtitle {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber .process-card .stages-container .stage.completed mat-icon,
.indigo-amber .process-card-detail .stages-container .stage.completed mat-icon,
.indigo-amber .process-card-stages .stages-container .stage.completed mat-icon {
  color: #499719;
}
.indigo-amber .process-card .stages-container .stage.completed .icon-container,
.indigo-amber .process-card-detail .stages-container .stage.completed .icon-container,
.indigo-amber .process-card-stages .stages-container .stage.completed .icon-container {
  background-color: rgba(78, 160, 28, 0.1490196078);
}
.indigo-amber .process-card .stages-container .stage.completed .line,
.indigo-amber .process-card-detail .stages-container .stage.completed .line,
.indigo-amber .process-card-stages .stages-container .stage.completed .line {
  background-color: #499719;
}
.indigo-amber .process-card .stages-container .stage.on-going mat-icon,
.indigo-amber .process-card-detail .stages-container .stage.on-going mat-icon,
.indigo-amber .process-card-stages .stages-container .stage.on-going mat-icon {
  color: #3f51b5;
}
.indigo-amber .process-card .stages-container .stage.on-going .icon-container,
.indigo-amber .process-card-detail .stages-container .stage.on-going .icon-container,
.indigo-amber .process-card-stages .stages-container .stage.on-going .icon-container {
  background-color: rgba(66, 48, 159, 0.2);
}
.indigo-amber .process-card .stages-container .stage.on-going .line,
.indigo-amber .process-card-detail .stages-container .stage.on-going .line,
.indigo-amber .process-card-stages .stages-container .stage.on-going .line {
  background-color: #949494;
}
.indigo-amber .process-card .stages-container .stage.pending,
.indigo-amber .process-card-detail .stages-container .stage.pending,
.indigo-amber .process-card-stages .stages-container .stage.pending {
  color: #949494;
}
.indigo-amber .process-card .stages-container .stage.pending mat-icon,
.indigo-amber .process-card-detail .stages-container .stage.pending mat-icon,
.indigo-amber .process-card-stages .stages-container .stage.pending mat-icon {
  color: #949494;
}
.indigo-amber .process-card .stages-container .stage.pending .icon-container,
.indigo-amber .process-card-detail .stages-container .stage.pending .icon-container,
.indigo-amber .process-card-stages .stages-container .stage.pending .icon-container {
  background-color: rgba(174, 174, 174, 0.2);
}
.indigo-amber .process-card .stages-container .stage.pending .line,
.indigo-amber .process-card-detail .stages-container .stage.pending .line,
.indigo-amber .process-card-stages .stages-container .stage.pending .line {
  background-color: #949494;
}
.indigo-amber .process-card .stages-container .stage.has-error mat-icon,
.indigo-amber .process-card-detail .stages-container .stage.has-error mat-icon,
.indigo-amber .process-card-stages .stages-container .stage.has-error mat-icon {
  color: #f44336;
}
.indigo-amber .process-card .stages-container .stage.has-error .icon-container,
.indigo-amber .process-card-detail .stages-container .stage.has-error .icon-container,
.indigo-amber .process-card-stages .stages-container .stage.has-error .icon-container {
  background-color: rgba(188, 4, 4, 0.2);
}
.indigo-amber .process-card .stages-container .stage-description .button-link,
.indigo-amber .process-card-detail .stages-container .stage-description .button-link,
.indigo-amber .process-card-stages .stages-container .stage-description .button-link {
  color: #3f51b5;
}
.indigo-amber app-custom-search-input .custom-search-container {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
  transition: color 0.1s ease, background 0.15s ease;
}
.indigo-amber app-custom-search-input .custom-search-container mat-icon.mat-icon {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-amber app-custom-search-input .custom-search-container input.custom-search-input {
  color: rgba(0, 0, 0, 0.87);
  background: unset;
}
.indigo-amber app-custom-search-input .custom-search-container:hover, .indigo-amber app-custom-search-input .custom-search-container:active, .indigo-amber app-custom-search-input .custom-search-container:focus, .indigo-amber app-custom-search-input .custom-search-container:focus-within {
  background: rgba(0, 0, 0, 0.04) !important;
  transition: background 0.15s ease;
}
.indigo-amber .UI-V2 .audit-filter {
  background: white;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber .UI-V2 .audit-filter .toolbar {
  background: #3f51b5;
  color: white;
}
.indigo-amber .UI-V2 .audit-filter .toolbar .header .content .module-title {
  color: #3f51b5;
}
.indigo-amber .UI-V2 .audit-filter .toolbar .header .content .module-title.selectable:hover {
  background: rgba(0, 0, 0, 0.04);
}
.indigo-amber .UI-V2 .audit-filter .toolbar .header .content .module-title .info * {
  color: white !important;
}
.indigo-amber .UI-V2 .audit-filter .toolbar .header .content .ou-select {
  color: white;
  background: #3f51b5;
}
.indigo-amber .UI-V2 .audit-filter .toolbar .search-container .search .content {
  background: #fafafa;
}
.indigo-amber .UI-V2 .audit-filter .toolbar .search-container .search .content:hover, .indigo-amber .UI-V2 .audit-filter .toolbar .search-container .search .content:active, .indigo-amber .UI-V2 .audit-filter .toolbar .search-container .search .content:focus, .indigo-amber .UI-V2 .audit-filter .toolbar .search-container .search .content:focus-within {
  background: rgba(0, 0, 0, 0.04) !important;
}
.indigo-amber .UI-V2 .audit-filter .toolbar .search-container .search .content input {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber .UI-V2 .audit-filter .filter-container {
  background: white !important;
}
.indigo-amber .UI-V2 .audit-filter .filter-container .form-body {
  background: #fafafa !important;
}
.indigo-amber .UI-V2 .audit-filter .filter-container .form-body .line h4 {
  color: #3f51b5;
}
.indigo-amber .UI-V2 .audit-filter .filter-container .form-body .line mat-icon {
  color: #3f51b5;
}
.indigo-amber .UI-V2 .audit-result .result-container .toolbar {
  color: white;
}
.indigo-amber .UI-V2 .audit-result .result-container .toolbar .header .content .module-title {
  color: #3f51b5;
}
.indigo-amber .UI-V2 .audit-result .result-container .toolbar .header .content .module-title.selectable:hover {
  background: rgba(0, 0, 0, 0.04);
}
.indigo-amber .UI-V2 .audit-result .result-container .toolbar .header .content .module-title .info h5 {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-amber .UI-V2 .audit-result .result-container .toolbar .header .content .ou-select {
  color: white;
  background: #3f51b5;
}
.indigo-amber .UI-V2 .audit-result .result-container .toolbar .search-container .search .content {
  background: #fafafa;
}
.indigo-amber .UI-V2 .audit-result .result-container .toolbar .search-container .search .content:hover, .indigo-amber .UI-V2 .audit-result .result-container .toolbar .search-container .search .content:active, .indigo-amber .UI-V2 .audit-result .result-container .toolbar .search-container .search .content:focus, .indigo-amber .UI-V2 .audit-result .result-container .toolbar .search-container .search .content:focus-within {
  background: rgba(0, 0, 0, 0.04) !important;
}
.indigo-amber .UI-V2 .audit-result .result-container .toolbar .search-container .search .content input {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber .UI-V2 .audit-result .result-container .new-table app-cs-grid-control datatable-header * {
  color: #3f51b5;
}
.indigo-amber .UI-V2 .audit-result .result-container .new-table .mat-header-row.sticky * {
  color: #3f51b5 !important;
}
.indigo-amber .UI-V2 .audit-detail-container {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  background: #eeeeee !important;
}
.indigo-amber .UI-V2 .audit-detail-container .side .toolbar .header {
  color: #3f51b5;
}
.indigo-amber .UI-V2 .audit-detail-container .side .container .mat-list-base {
  background: #fafafa !important;
}
.indigo-amber .UI-V2 .audit-detail-container .side .container .mat-list-base mat-list-item .mat-line b {
  color: #3f51b5;
}
.indigo-amber .UI-V2 .inbox-container {
  background: white;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber .UI-V2 .inbox-container *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.indigo-amber .UI-V2 .inbox-container *::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}
.indigo-amber .UI-V2 .inbox-container *::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}
.indigo-amber .UI-V2 .inbox-container .load-window {
  background: white;
}
.indigo-amber .UI-V2 .inbox-container .toolbar {
  background: #3f51b5;
  color: white;
}
.indigo-amber .UI-V2 .inbox-container .toolbar.reverse {
  background: white;
  color: rgba(0, 0, 0, 0.54);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel {
  background: #eeeeee !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel.mat-expanded {
  background: #fafafa !important;
}
.indigo-amber .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel.mat-expanded mat-expansion-panel-header {
  background: #fafafa !important;
}
.indigo-amber .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel.mat-expanded mat-expansion-panel-header:hover {
  background: none !important;
}
.indigo-amber .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel mat-expansion-panel-header {
  background: #fafafa !important;
}
.indigo-amber .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel mat-expansion-panel-header:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.indigo-amber .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel mat-expansion-panel-header .signature-inbox-header .mat-icon,
.indigo-amber .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel mat-expansion-panel-header .signature-inbox-header b {
  color: #ffc107;
}
.indigo-amber .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel .mat-expansion-panel-body .item {
  background: white;
  color: rgba(0, 0, 0, 0.54);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel .mat-expansion-panel-body .item:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.indigo-amber .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel .mat-expansion-panel-body .item.active {
  border-left: 6px solid #ffc107;
}
.indigo-amber .UI-V2 .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.indigo-amber .UI-V2 .work-space .main .load-window {
  background: white !important;
}
.indigo-amber .UI-V2 .work-space .main-action {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber mat-radio-button.disabled .mat-radio-label-content {
  color: #ffc107;
}
.indigo-amber .detail app-automatic-process-detail app-chapa.minimize,
.indigo-amber .detail app-process-detail app-chapa.minimize {
  background: #FFD54F !important;
}
.indigo-amber .detail app-automatic-process-detail app-chapa.minimize.purged,
.indigo-amber .detail app-process-detail app-chapa.minimize.purged {
  background: #b2adb0 !important;
}
.indigo-amber .detail .detail-toolbar {
  background: #eeeeee;
}
.indigo-amber .detail .processes-status .status-bar .bar-graph {
  background-color: #FFD54F;
}
.indigo-amber .detail .processes-status .status-bar .bar-graph .graph-ok {
  background-color: #4DB6AC;
}
.indigo-amber .detail .processes-status .status-bar .bar-graph .graph-not-ok {
  background-color: #F48FB1;
}
.indigo-amber .detail .processes-status .status-resume b {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber .detail .processes-status .status-resume .resume-total b {
  background-color: #FFD54F;
}
.indigo-amber .detail .processes-status .status-resume .resume-ok b {
  background-color: #4DB6AC;
}
.indigo-amber .detail .processes-status .status-resume .resume-not-ok b {
  background-color: #F48FB1;
}
.indigo-amber .detail .detail-toolbar .mat-avatar h3,
.indigo-amber .detail .detail-toolbar .mat-avatar h5 {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber .detail .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber .detail .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber .detail .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber .detail .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber .detail .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  color: white;
}
.indigo-amber .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  background: #4DB6AC !important;
}
.indigo-amber .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  background: #b2dfdb !important;
}
.indigo-amber .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  background: #b2dfdb !important;
}
.indigo-amber .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  background: #4DB6AC !important;
}
.indigo-amber .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  background: #b2dfdb !important;
}
.indigo-amber .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  background: #837C80 !important;
}
.indigo-amber .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  background: #b2adb0 !important;
}
.indigo-amber .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  background: #b2adb0 !important;
}
.indigo-amber .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  background: #837C80 !important;
}
.indigo-amber .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  background: #b2dfdb !important;
}
.indigo-amber .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  background: #FFD54F !important;
}
.indigo-amber .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  background: #ffecb3 !important;
}
.indigo-amber .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  background: #ffecb3 !important;
}
.indigo-amber .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  background: #FFD54F !important;
}
.indigo-amber .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  background: #ffecb3 !important;
}
.indigo-amber .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  background: #F48FB1 !important;
}
.indigo-amber .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  background: #f8bbd0 !important;
}
.indigo-amber .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  background: #f8bbd0 !important;
}
.indigo-amber .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  background: #F48FB1 !important;
}
.indigo-amber .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  background: #f8bbd0 !important;
}
.indigo-amber app-analytics-document-set .sets-container .mat-list-base.sets-list mat-list-item {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber app-analytics-document-set .sets-container .mat-list-base.sets-list mat-list-item .mat-list-item-content .set-status .value-percentile .undone-value {
  background: #673AB7;
}
.indigo-amber app-analytics-document-set .sets-container .mat-list-base.sets-list mat-list-item .mat-list-item-content .set-status .value-percentile .not-value {
  background: #eeeeee;
}
.indigo-amber app-analytics-document-set .sets-container .mat-list-base.sets-list mat-list-item .mat-list-item-content .set-status .value-percentile .done-value {
  background: #009688;
}
.indigo-amber app-analytics-document-set .work-space .main .toolbar .header .content .module-title .info .info-text .set-status {
  cursor: help;
}
.indigo-amber app-analytics-document-set .work-space .main .toolbar .header .content .module-title .info .info-text .set-status .value-percentile .undone-value {
  background: #673AB7;
}
.indigo-amber app-analytics-document-set .work-space .main .toolbar .header .content .module-title .info .info-text .set-status .value-percentile .not-value {
  background: #eeeeee;
}
.indigo-amber app-analytics-document-set .work-space .main .toolbar .header .content .module-title .info .info-text .set-status .value-percentile .done-value {
  background: #009688;
}
.indigo-amber app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar .mat-progress-bar-buffer {
  background-color: #eeeeee;
}
.indigo-amber app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar.class1 .mat-progress-bar-fill::after {
  background-color: #f44336;
}
.indigo-amber app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar.class2 .mat-progress-bar-fill::after {
  background-color: #ff9800;
}
.indigo-amber app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar.class3 .mat-progress-bar-fill::after {
  background-color: #ffeb3b;
}
.indigo-amber app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar.class4 .mat-progress-bar-fill::after {
  background-color: #8bc34a;
}
.indigo-amber app-analytics-document-set .work-space .side .container mat-list {
  background: white !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber app-analytics-document-set .work-space .side .container mat-list mat-list-item .mat-line b {
  color: #3f51b5;
}
.indigo-amber .catcher {
  background: rgba(0, 0, 0, 0.6);
}
.indigo-amber .catcher.clear {
  background: rgba(0, 0, 0, 0);
}
.indigo-amber .add-menu-form-body .mat-radio-group .mat-radio-button:hover {
  background: rgba(0, 0, 0, 0.04);
}
.indigo-amber .add-menu-form-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber .add-menu-form-body.w-table .helper .search-bar {
  background: #fafafa;
}
.indigo-amber .add-menu-form-body.w-table .helper .search-bar:hover, .indigo-amber .add-menu-form-body.w-table .helper .search-bar:active, .indigo-amber .add-menu-form-body.w-table .helper .search-bar:focus, .indigo-amber .add-menu-form-body.w-table .helper .search-bar:focus-within {
  background: #fafafa !important;
}
.indigo-amber .add-menu-form-body.w-table .helper .search-bar input {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber .add-menu-form-actions {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber .file-upload-list mat-list-item {
  background: white;
}
.indigo-amber .mat-horizontal-stepper-content[aria-expanded=true] app-file-document-view {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber .mat-horizontal-stepper-content[aria-expanded=true] app-file-document-view .pdf-view-container {
  background: #eeeeee;
}
.indigo-amber .add-docs-drop .docs-drop-preview app-file-document-detail {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber .add-docs-drop .docs-drop-preview app-file-document-detail .view-toolbar {
  background: white;
}
.indigo-amber .add-docs-drop .load-window.message h3.primary-text {
  color: #3f51b5;
}
.indigo-amber mat-dialog-container app-add-menu-dialog .load-window.message h3.primary-text {
  color: #3f51b5;
}
.indigo-amber .asisted-input.aligned-icon.subtitle * {
  color: rgba(0, 0, 0, 0.38) !important;
}
.indigo-amber .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble {
  background: #3f51b5;
}
.indigo-amber .asisted-input .mat-form-field-invalid + .indicator > .bubble {
  background: #f44336;
}
.indigo-amber .asisted-input .mat-form-field-disabled + .indicator > .bubble {
  color: rgba(0, 0, 0, 0.38);
  background: rgba(0, 0, 0, 0.12);
}
.indigo-amber .asisted-input .indicator .bubble {
  background: rgba(0, 0, 0, 0.38);
  color: white;
}
.indigo-amber .asisted-input .indicator .guide {
  border-color: rgba(0, 0, 0, 0.12);
}
.indigo-amber .asisted-input.icon-only .bubble {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-amber .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble {
  color: #3f51b5;
}
.indigo-amber .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble {
  color: #f44336;
}
.indigo-amber .UI-V2 .main-overlay {
  background: rgba(0, 0, 0, 0.32);
}
.indigo-amber .UI-V2 .icon-complex mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.indigo-amber .UI-V2 .icon-complex mat-icon.complex {
  color: #3f51b5 !important;
}
.indigo-amber .UI-V2 .navigation {
  background: #fafafa;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber .UI-V2 .navigation *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.indigo-amber .UI-V2 .navigation *::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}
.indigo-amber .UI-V2 .navigation .load-window {
  background: #eeeeee;
}
.indigo-amber .UI-V2 .navigation .toolbar {
  background: #3f51b5;
  color: white;
}
.indigo-amber .UI-V2 .navigation .toolbar.reverse {
  background: white;
  color: rgba(0, 0, 0, 0.54);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber .UI-V2 .navigation .container .helper {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-amber .UI-V2 .navigation .container .item {
  background: white;
  color: rgba(0, 0, 0, 0.54);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber .UI-V2 .navigation .container .item:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.indigo-amber .UI-V2 .navigation .container .item.active {
  background: #3f51b5 !important;
  color: white;
  border: 1px solid #3f51b5;
}
.indigo-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .separator .subtitle,
.indigo-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .separator .subtitle,
.indigo-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .separator .subtitle,
.indigo-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .separator .subtitle,
.indigo-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .separator .subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .img-editior-container .img-editor .separator .subtitle,
.indigo-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .img-editior-container .img-editor .separator .subtitle,
.indigo-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .img-editior-container .img-editor .separator .subtitle,
.indigo-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .img-editior-container .img-editor .separator .subtitle,
.indigo-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .img-editior-container .img-editor .separator .subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme,
.indigo-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme,
.indigo-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme,
.indigo-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme,
.indigo-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .main-color-container .main-color,
.indigo-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .main-color-container .main-color,
.indigo-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .main-color-container .main-color,
.indigo-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .main-color-container .main-color,
.indigo-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .main-color-container .main-color {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .main-color-container .main-color:before,
.indigo-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .main-color-container .main-color:before,
.indigo-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .main-color-container .main-color:before,
.indigo-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .main-color-container .main-color:before,
.indigo-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .main-color-container .main-color:before {
  border-left: 10px solid rgba(0, 0, 0, 0);
  border-right: 10px solid rgba(0, 0, 0, 0);
}
.indigo-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example h6.required,
.indigo-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example h6.required,
.indigo-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example h6.required,
.indigo-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example h6.required,
.indigo-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example h6.required {
  color: #f44336 !important;
}
.indigo-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle,
.indigo-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle,
.indigo-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle,
.indigo-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle,
.indigo-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle {
  border: 2px solid rgba(0, 0, 0, 0);
}
.indigo-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .side-color-container .side-color,
.indigo-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .side-color-container .side-color,
.indigo-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .side-color-container .side-color,
.indigo-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .side-color-container .side-color,
.indigo-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .side-color-container .side-color {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .side-color-container .side-color:before,
.indigo-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .side-color-container .side-color:before,
.indigo-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .side-color-container .side-color:before,
.indigo-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .side-color-container .side-color:before,
.indigo-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .side-color-container .side-color:before {
  border-top: 10px solid rgba(0, 0, 0, 0);
  border-bottom: 10px solid rgba(0, 0, 0, 0);
}
.indigo-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .side-color-container .side-color h6.required,
.indigo-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .side-color-container .side-color h6.required,
.indigo-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .side-color-container .side-color h6.required,
.indigo-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .side-color-container .side-color h6.required,
.indigo-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .side-color-container .side-color h6.required {
  color: #f44336 !important;
}
.indigo-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .permission-settings-cards .card,
.indigo-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .permission-settings-cards .card,
.indigo-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .permission-settings-cards .card,
.indigo-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .permission-settings-cards .card,
.indigo-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .permission-settings-cards .card {
  background: #fafafa;
}
.indigo-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .permission-settings-cards .card .slide-sub-group,
.indigo-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .permission-settings-cards .card .slide-sub-group,
.indigo-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .permission-settings-cards .card .slide-sub-group,
.indigo-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .permission-settings-cards .card .slide-sub-group,
.indigo-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .permission-settings-cards .card .slide-sub-group {
  background: rgba(0, 0, 0, 0.12);
}
.indigo-amber .UI-V2 .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.indigo-amber .UI-V2 .work-space .main .load-window {
  background: white !important;
}
.indigo-amber .UI-V2 .work-space .main.side-open {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item mat-icon.complex {
  text-shadow: -2px 0 0px #eeeeee, 0 -2px 0px #eeeeee, 2px 0 0px #eeeeee, 0 2px 0px #eeeeee, -2px -2px 0px #eeeeee, 2px 2px 0px #eeeeee, 2px -2px 0px #eeeeee, -2px 2px 0px #eeeeee !important;
}
.indigo-amber .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item h5 {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-amber .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item h3 {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item .actions {
  pointer-events: none;
}
.indigo-amber .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item .actions button {
  pointer-events: all;
}
.indigo-amber .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled {
  background: #eeeeee;
}
.indigo-amber .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled h3 {
  color: #607D8B !important;
}
.indigo-amber .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled mat-icon {
  color: #607D8B !important;
}
.indigo-amber .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled.selected {
  background: #607D8B;
  color: white !important;
}
.indigo-amber .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled.selected h3 {
  color: white !important;
}
.indigo-amber .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled.selected mat-icon {
  color: white !important;
}
.indigo-amber .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled.selected mat-icon.complex {
  color: white !important;
  text-shadow: -2px 0 0px #607D8B, 0 -2px 0px #607D8B, 2px 0 0px #607D8B, 0 2px 0px #607D8B, -2px -2px 0px #607D8B, 2px 2px 0px #607D8B, 2px -2px 0px #607D8B, -2px 2px 0px #607D8B !important;
}
.indigo-amber .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item:hover {
  background: rgba(0, 0, 0, 0.04);
}
.indigo-amber .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected {
  background: #3f51b5;
  color: white;
}
.indigo-amber .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected mat-icon {
  color: white;
}
.indigo-amber .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected mat-icon.complex {
  color: white !important;
  text-shadow: -2px 0 0px #3f51b5, 0 -2px 0px #3f51b5, 2px 0 0px #3f51b5, 0 2px 0px #3f51b5, -2px -2px 0px #3f51b5, 2px 2px 0px #3f51b5, 2px -2px 0px #3f51b5, -2px 2px 0px #3f51b5 !important;
}
.indigo-amber .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected h5,
.indigo-amber .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected h3 {
  color: white;
}
.indigo-amber .UI-V2 .work-space .main .main-action {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber .UI-V2 .work-space .side {
  background: #fafafa;
}
.indigo-amber .UI-V2 .work-space .side .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #fafafa, 0 -2px 0px #fafafa, 2px 0 0px #fafafa, 0 2px 0px #fafafa, -2px -2px 0px #fafafa, 2px 2px 0px #fafafa, 2px -2px 0px #fafafa, -2px 2px 0px #fafafa;
}
.indigo-amber .UI-V2 .work-space .side *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.indigo-amber .UI-V2 .work-space .side *::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}
.indigo-amber .UI-V2 .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #fafafa !important;
}
.indigo-amber .UI-V2 .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-amber .UI-V2 .work-space .side mat-form-field .mat-form-field-flex {
  background: #fafafa;
}
.indigo-amber .UI-V2 .work-space .side mat-checkbox:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.indigo-amber .UI-V2 .work-space .side .hint.all {
  color: #ffc107;
}
.indigo-amber .UI-V2 .work-space .side .toolbar .header {
  color: #3f51b5;
}
.indigo-amber .UI-V2 .work-space .side .toolbar .header h3 {
  color: #3f51b5;
}
.indigo-amber .UI-V2 .work-space .side .container .role-resume {
  background: #3f51b5;
}
.indigo-amber .UI-V2 .work-space .side .container .role-resume .line {
  color: white;
}
.indigo-amber .UI-V2 .work-space .side .container .role-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.1);
}
.indigo-amber .UI-V2 .work-space .side .container .role-resume .line.editable button {
  background: #3f51b5;
}
.indigo-amber .UI-V2 .work-space .side .container .section-title {
  color: #3f51b5;
}
.indigo-amber .UI-V2 .work-space .side .container .section-resume {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber .UI-V2 .work-space .side .container .section-resume .line.editable:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.indigo-amber .UI-V2 .work-space .side .container .section-resume .line.editable:hover .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px white, 0 -2px 0px white, 2px 0 0px white, 0 2px 0px white, -2px -2px 0px white, 2px 2px 0px white, 2px -2px 0px white, -2px 2px 0px white !important;
}
.indigo-amber .UI-V2 .work-space .side .container .section-resume .line.editable button {
  background: #fafafa;
}
.indigo-amber .UI-V2 .work-space .side .container .section-resume .line .header .icon-complex mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.indigo-amber .UI-V2 .work-space .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #3f51b5 !important;
}
.indigo-amber .UI-V2 .work-space .side .container .section-resume .line .header mat-icon:not(.complex) {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-amber .UI-V2 .work-space .side .container .section-resume .line .header .info h4 {
  color: #3f51b5;
}
.indigo-amber .UI-V2 .work-space .side .container .section-resume .line ul {
  color: #3f51b5;
}
.indigo-amber .UI-V2 .work-space .side .container .section-resume .line.head {
  background: #eeeeee;
}
.indigo-amber .UI-V2 .work-space .side .container .section-resume .line.head mat-icon {
  color: #ffc107;
}
.indigo-amber .UI-V2 .work-space .side .container .section-resume .line.head h4 {
  color: white;
}
.indigo-amber .UI-V2 .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body {
  background: white;
}
.indigo-amber .UI-V2 .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-amber .UI-V2 .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #3f51b5;
}
.indigo-amber .UI-V2 .work-space .side.edition .toolbar .header {
  color: white;
}
.indigo-amber .UI-V2 .work-space .side.edition .toolbar .header mat-icon {
  color: #ffc107;
}
.indigo-amber .UI-V2 .work-space .side.edition .toolbar .header h3 {
  color: #ffc107;
}
.indigo-amber .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body {
  background: white !important;
}
.indigo-amber .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header mat-icon:not(.complex) {
  color: #3f51b5;
}
.indigo-amber .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header h4 {
  color: #3f51b5;
}
.indigo-amber .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-actions {
  background: #fafafa;
}
.indigo-amber .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.indigo-amber .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header .icon-complex mat-icon.complex {
  color: #ffc107 !important;
}
.indigo-amber .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header h4 {
  color: #ffc107;
}
.indigo-amber .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line ul {
  color: #ffc107;
  font-weight: 400;
}
.indigo-amber .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head mat-icon {
  color: #ffc107 !important;
}
.indigo-amber .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head h4 {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .section-title {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-amber .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume h6.hint {
  background: #ffecb3;
}
.indigo-amber app-role-user-find .main-overlay {
  background: rgba(0, 0, 0, 0.32);
}
.indigo-amber app-role-user-find .icon-complex mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.indigo-amber app-role-user-find .icon-complex mat-icon.complex {
  color: #3f51b5 !important;
}
.indigo-amber app-role-user-find .navigation {
  background: #fafafa;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber app-role-user-find .navigation *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.indigo-amber app-role-user-find .navigation *::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}
.indigo-amber app-role-user-find .navigation .load-window {
  background: #eeeeee;
}
.indigo-amber app-role-user-find .navigation .toolbar {
  background: #3f51b5;
  color: white;
}
.indigo-amber app-role-user-find .navigation .toolbar.reverse {
  background: white;
  color: rgba(0, 0, 0, 0.54);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber app-role-user-find .navigation .container .helper {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-amber app-role-user-find .navigation .container .item {
  background: white;
  color: rgba(0, 0, 0, 0.54);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber app-role-user-find .navigation .container .item:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.indigo-amber app-role-user-find .navigation .container .item.active {
  background: #3f51b5 !important;
  color: white;
  border: 1px solid #3f51b5;
}
.indigo-amber app-role-user-find .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.indigo-amber app-role-user-find .work-space .main .load-window {
  background: white !important;
}
.indigo-amber app-role-user-find .work-space .main.side-open {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber app-role-user-find .work-space .main .container .item {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber app-role-user-find .work-space .main .container .item .subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-amber app-role-user-find .work-space .main .container .item.disabled {
  background: #eeeeee;
  color: #607D8B !important;
}
.indigo-amber app-role-user-find .work-space .main .container .item.disabled h3 {
  color: #607D8B !important;
}
.indigo-amber app-role-user-find .work-space .main .container .item.disabled mat-icon {
  color: #607D8B !important;
}
.indigo-amber app-role-user-find .work-space .main .container .item.disabled.selected {
  background: #607D8B;
  color: white !important;
}
.indigo-amber app-role-user-find .work-space .main .container .item.disabled.selected h3 {
  color: white !important;
}
.indigo-amber app-role-user-find .work-space .main .container .item.disabled.selected mat-icon {
  color: white !important;
}
.indigo-amber app-role-user-find .work-space .main .container .item.disabled.selected mat-icon.complex {
  color: white !important;
  text-shadow: -2px 0 0px #607D8B, 0 -2px 0px #607D8B, 2px 0 0px #607D8B, 0 2px 0px #607D8B, -2px -2px 0px #607D8B, 2px 2px 0px #607D8B, 2px -2px 0px #607D8B, -2px 2px 0px #607D8B !important;
}
.indigo-amber app-role-user-find .work-space .main .container .item mat-icon.complex {
  text-shadow: -2px 0 0px #eeeeee, 0 -2px 0px #eeeeee, 2px 0 0px #eeeeee, 0 2px 0px #eeeeee, -2px -2px 0px #eeeeee, 2px 2px 0px #eeeeee, 2px -2px 0px #eeeeee, -2px 2px 0px #eeeeee !important;
}
.indigo-amber app-role-user-find .work-space .main .container .item h5 {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-amber app-role-user-find .work-space .main .container .item h3 {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber app-role-user-find .work-space .main .container .item:hover {
  background: rgba(0, 0, 0, 0.04);
}
.indigo-amber app-role-user-find .work-space .main .container .item.selected {
  background: #3f51b5;
  color: white;
}
.indigo-amber app-role-user-find .work-space .main .container .item.selected mat-icon {
  color: white;
}
.indigo-amber app-role-user-find .work-space .main .container .item.selected mat-icon.complex {
  color: white !important;
  text-shadow: -2px 0 0px #3f51b5, 0 -2px 0px #3f51b5, 2px 0 0px #3f51b5, 0 2px 0px #3f51b5, -2px -2px 0px #3f51b5, 2px 2px 0px #3f51b5, 2px -2px 0px #3f51b5, -2px 2px 0px #3f51b5 !important;
}
.indigo-amber app-role-user-find .work-space .main .container .item.selected h5,
.indigo-amber app-role-user-find .work-space .main .container .item.selected h3 {
  color: white;
}
.indigo-amber app-role-user-find .work-space .main .main-action {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber app-role-user-find .work-space .side {
  background: #fafafa;
}
.indigo-amber app-role-user-find .work-space .side .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #fafafa, 0 -2px 0px #fafafa, 2px 0 0px #fafafa, 0 2px 0px #fafafa, -2px -2px 0px #fafafa, 2px 2px 0px #fafafa, 2px -2px 0px #fafafa, -2px 2px 0px #fafafa;
}
.indigo-amber app-role-user-find .work-space .side *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.indigo-amber app-role-user-find .work-space .side *::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}
.indigo-amber app-role-user-find .work-space .side .edition-line {
  background: #eeeeee;
}
.indigo-amber app-role-user-find .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #fafafa !important;
}
.indigo-amber app-role-user-find .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-amber app-role-user-find .work-space .side mat-form-field .mat-form-field-flex {
  background: #fafafa;
}
.indigo-amber app-role-user-find .work-space .side mat-checkbox:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.indigo-amber app-role-user-find .work-space .side .hint.all {
  color: #ffc107;
}
.indigo-amber app-role-user-find .work-space .side .toolbar .header {
  color: #3f51b5;
}
.indigo-amber app-role-user-find .work-space .side .toolbar .header h3 {
  color: #3f51b5;
}
.indigo-amber app-role-user-find .work-space .side .container .role-resume {
  background: #3f51b5;
}
.indigo-amber app-role-user-find .work-space .side .container .role-resume .line {
  color: white;
}
.indigo-amber app-role-user-find .work-space .side .container .role-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.1);
}
.indigo-amber app-role-user-find .work-space .side .container .role-resume .line.editable button {
  background: #3f51b5;
}
.indigo-amber app-role-user-find .work-space .side .container .section-title {
  color: #3f51b5;
}
.indigo-amber app-role-user-find .work-space .side .container .section-resume {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber app-role-user-find .work-space .side .container .section-resume .line.editable:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.indigo-amber app-role-user-find .work-space .side .container .section-resume .line.editable:hover .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px white, 0 -2px 0px white, 2px 0 0px white, 0 2px 0px white, -2px -2px 0px white, 2px 2px 0px white, 2px -2px 0px white, -2px 2px 0px white !important;
}
.indigo-amber app-role-user-find .work-space .side .container .section-resume .line.editable button {
  background: #fafafa;
}
.indigo-amber app-role-user-find .work-space .side .container .section-resume .line .header .icon-complex mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.indigo-amber app-role-user-find .work-space .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #3f51b5 !important;
}
.indigo-amber app-role-user-find .work-space .side .container .section-resume .line .header mat-icon:not(.complex) {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-amber app-role-user-find .work-space .side .container .section-resume .line .header .info h4 {
  color: #3f51b5;
}
.indigo-amber app-role-user-find .work-space .side .container .section-resume .line ul {
  color: #3f51b5;
}
.indigo-amber app-role-user-find .work-space .side .container .section-resume .line.head {
  background: #eeeeee;
}
.indigo-amber app-role-user-find .work-space .side .container .section-resume .line.head mat-icon {
  color: #ffc107;
}
.indigo-amber app-role-user-find .work-space .side .container .section-resume .line.head h4 {
  color: white;
}
.indigo-amber app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee-title {
  color: #3f51b5;
}
.indigo-amber app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body {
  background: white;
}
.indigo-amber app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-amber app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #3f51b5;
}
.indigo-amber app-role-user-find .work-space .side.edition .toolbar .header {
  color: white;
}
.indigo-amber app-role-user-find .work-space .side.edition .toolbar .header mat-icon {
  color: #ffc107;
}
.indigo-amber app-role-user-find .work-space .side.edition .toolbar .header h3 {
  color: #ffc107;
}
.indigo-amber app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body {
  background: white !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header mat-icon:not(.complex) {
  color: #3f51b5;
}
.indigo-amber app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header h4 {
  color: #3f51b5;
}
.indigo-amber app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-actions {
  background: #fafafa;
}
.indigo-amber app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.indigo-amber app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header .icon-complex mat-icon.complex {
  color: #ffc107 !important;
}
.indigo-amber app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header h4 {
  color: #ffc107;
}
.indigo-amber app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line ul {
  color: #ffc107;
}
.indigo-amber app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head mat-icon {
  color: #ffc107 !important;
}
.indigo-amber app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head h4 {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .section-title {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-amber app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume h6.hint {
  background: #ffecb3;
}
.indigo-amber app-role-user-find .work-space app-role-user-detail.disabled .side .toolbar .header {
  color: #607D8B !important;
}
.indigo-amber app-role-user-find .work-space app-role-user-detail.disabled .side .container .role-resume {
  background: #607D8B !important;
}
.indigo-amber app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-title {
  color: #607D8B !important;
}
.indigo-amber app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line ul {
  color: #607D8B !important;
}
.indigo-amber app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header mat-icon {
  color: #607D8B;
}
.indigo-amber app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .icon-complex mat-icon {
  color: #607D8B !important;
}
.indigo-amber app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #607D8B !important;
}
.indigo-amber app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .info h4 {
  color: #607D8B !important;
}
.indigo-amber app-role-user-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee-title {
  color: #607D8B;
}
.indigo-amber app-role-user-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: #607D8B;
}
.indigo-amber app-role-user-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #607D8B;
}
.indigo-amber app-actor-find .main-overlay {
  background: rgba(0, 0, 0, 0.32);
}
.indigo-amber app-actor-find .icon-complex mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.indigo-amber app-actor-find .icon-complex mat-icon.complex {
  color: #3f51b5 !important;
}
.indigo-amber app-actor-find .navigation {
  background: #fafafa;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber app-actor-find .navigation *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.indigo-amber app-actor-find .navigation *::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}
.indigo-amber app-actor-find .navigation .load-window {
  background: #eeeeee;
}
.indigo-amber app-actor-find .navigation .toolbar {
  background: #3f51b5;
  color: white;
}
.indigo-amber app-actor-find .navigation .toolbar.reverse {
  background: white;
  color: rgba(0, 0, 0, 0.54);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber app-actor-find .navigation .container .helper {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-amber app-actor-find .navigation .container .item {
  background: white;
  color: rgba(0, 0, 0, 0.54);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber app-actor-find .navigation .container .item:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.indigo-amber app-actor-find .navigation .container .item.active {
  background: #3f51b5 !important;
  color: white;
  border: 1px solid #3f51b5;
}
.indigo-amber app-actor-find .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.indigo-amber app-actor-find .work-space .main .load-window {
  background: white !important;
}
.indigo-amber app-actor-find .work-space .main.side-open {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber app-actor-find .work-space .main .container .item {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber app-actor-find .work-space .main .container .item .subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-amber app-actor-find .work-space .main .container .item.disabled {
  background: #eeeeee;
  color: #607D8B !important;
}
.indigo-amber app-actor-find .work-space .main .container .item.disabled h3 {
  color: #607D8B !important;
}
.indigo-amber app-actor-find .work-space .main .container .item.disabled mat-icon {
  color: #607D8B !important;
}
.indigo-amber app-actor-find .work-space .main .container .item.disabled.selected {
  background: #607D8B;
  color: white !important;
}
.indigo-amber app-actor-find .work-space .main .container .item.disabled.selected h3 {
  color: white !important;
}
.indigo-amber app-actor-find .work-space .main .container .item.disabled.selected mat-icon {
  color: white !important;
}
.indigo-amber app-actor-find .work-space .main .container .item.disabled.selected mat-icon.complex {
  color: white !important;
  text-shadow: -2px 0 0px #607D8B, 0 -2px 0px #607D8B, 2px 0 0px #607D8B, 0 2px 0px #607D8B, -2px -2px 0px #607D8B, 2px 2px 0px #607D8B, 2px -2px 0px #607D8B, -2px 2px 0px #607D8B !important;
}
.indigo-amber app-actor-find .work-space .main .container .item mat-icon.complex {
  text-shadow: -2px 0 0px #eeeeee, 0 -2px 0px #eeeeee, 2px 0 0px #eeeeee, 0 2px 0px #eeeeee, -2px -2px 0px #eeeeee, 2px 2px 0px #eeeeee, 2px -2px 0px #eeeeee, -2px 2px 0px #eeeeee !important;
}
.indigo-amber app-actor-find .work-space .main .container .item h5 {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-amber app-actor-find .work-space .main .container .item h3 {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber app-actor-find .work-space .main .container .item:hover {
  background: rgba(0, 0, 0, 0.04);
}
.indigo-amber app-actor-find .work-space .main .container .item.selected {
  background: #3f51b5;
  color: white;
}
.indigo-amber app-actor-find .work-space .main .container .item.selected mat-icon {
  color: white;
}
.indigo-amber app-actor-find .work-space .main .container .item.selected mat-icon.complex {
  color: white !important;
  text-shadow: -2px 0 0px #3f51b5, 0 -2px 0px #3f51b5, 2px 0 0px #3f51b5, 0 2px 0px #3f51b5, -2px -2px 0px #3f51b5, 2px 2px 0px #3f51b5, 2px -2px 0px #3f51b5, -2px 2px 0px #3f51b5 !important;
}
.indigo-amber app-actor-find .work-space .main .container .item.selected h5,
.indigo-amber app-actor-find .work-space .main .container .item.selected h3 {
  color: white;
}
.indigo-amber app-actor-find .work-space .main .main-action {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber app-actor-find .work-space .side {
  background: #fafafa;
}
.indigo-amber app-actor-find .work-space .side .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #fafafa, 0 -2px 0px #fafafa, 2px 0 0px #fafafa, 0 2px 0px #fafafa, -2px -2px 0px #fafafa, 2px 2px 0px #fafafa, 2px -2px 0px #fafafa, -2px 2px 0px #fafafa;
}
.indigo-amber app-actor-find .work-space .side *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.indigo-amber app-actor-find .work-space .side *::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}
.indigo-amber app-actor-find .work-space .side .edition-line {
  background: #eeeeee;
}
.indigo-amber app-actor-find .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #fafafa !important;
}
.indigo-amber app-actor-find .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-amber app-actor-find .work-space .side mat-form-field .mat-form-field-flex {
  background: #fafafa;
}
.indigo-amber app-actor-find .work-space .side mat-checkbox:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.indigo-amber app-actor-find .work-space .side .hint.all {
  color: #ffc107;
}
.indigo-amber app-actor-find .work-space .side .toolbar .header {
  color: #3f51b5;
}
.indigo-amber app-actor-find .work-space .side .toolbar .header h3 {
  color: #3f51b5;
}
.indigo-amber app-actor-find .work-space .side .container .role-resume {
  background: #3f51b5;
}
.indigo-amber app-actor-find .work-space .side .container .role-resume .line {
  color: white;
}
.indigo-amber app-actor-find .work-space .side .container .role-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.1);
}
.indigo-amber app-actor-find .work-space .side .container .role-resume .line.editable button {
  background: #3f51b5;
}
.indigo-amber app-actor-find .work-space .side .container .section-title {
  color: #3f51b5;
}
.indigo-amber app-actor-find .work-space .side .container .section-resume {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber app-actor-find .work-space .side .container .section-resume .line.editable:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.indigo-amber app-actor-find .work-space .side .container .section-resume .line.editable:hover .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px white, 0 -2px 0px white, 2px 0 0px white, 0 2px 0px white, -2px -2px 0px white, 2px 2px 0px white, 2px -2px 0px white, -2px 2px 0px white !important;
}
.indigo-amber app-actor-find .work-space .side .container .section-resume .line.editable button {
  background: #fafafa;
}
.indigo-amber app-actor-find .work-space .side .container .section-resume .line .header .icon-complex mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.indigo-amber app-actor-find .work-space .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #3f51b5 !important;
}
.indigo-amber app-actor-find .work-space .side .container .section-resume .line .header mat-icon:not(.complex) {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-amber app-actor-find .work-space .side .container .section-resume .line .header .info h4 {
  color: #3f51b5;
}
.indigo-amber app-actor-find .work-space .side .container .section-resume .line ul {
  color: #3f51b5;
}
.indigo-amber app-actor-find .work-space .side .container .section-resume .line.head {
  background: #eeeeee;
}
.indigo-amber app-actor-find .work-space .side .container .section-resume .line.head mat-icon {
  color: #ffc107;
}
.indigo-amber app-actor-find .work-space .side .container .section-resume .line.head h4 {
  color: white;
}
.indigo-amber app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee-title {
  color: #3f51b5;
}
.indigo-amber app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body {
  background: white;
}
.indigo-amber app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-amber app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #3f51b5;
}
.indigo-amber app-actor-find .work-space .side.edition .toolbar .header {
  color: white;
}
.indigo-amber app-actor-find .work-space .side.edition .toolbar .header mat-icon {
  color: #ffc107;
}
.indigo-amber app-actor-find .work-space .side.edition .toolbar .header h3 {
  color: #ffc107;
}
.indigo-amber app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body {
  background: white !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header mat-icon:not(.complex) {
  color: #3f51b5;
}
.indigo-amber app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header h4 {
  color: #3f51b5;
}
.indigo-amber app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-actions {
  background: #fafafa;
}
.indigo-amber app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.indigo-amber app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header .icon-complex mat-icon.complex {
  color: #ffc107 !important;
}
.indigo-amber app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header h4 {
  color: #ffc107;
}
.indigo-amber app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line ul {
  color: #ffc107;
}
.indigo-amber app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head mat-icon {
  color: #ffc107 !important;
}
.indigo-amber app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head h4 {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .section-title {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-amber app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume h6.hint {
  background: #ffecb3;
}
.indigo-amber app-actor-find .work-space app-role-user-detail.disabled .side .toolbar .header {
  color: #607D8B !important;
}
.indigo-amber app-actor-find .work-space app-role-user-detail.disabled .side .container .role-resume {
  background: #607D8B !important;
}
.indigo-amber app-actor-find .work-space app-role-user-detail.disabled .side .container .section-title {
  color: #607D8B !important;
}
.indigo-amber app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line ul {
  color: #607D8B !important;
}
.indigo-amber app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header mat-icon {
  color: #607D8B;
}
.indigo-amber app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .icon-complex mat-icon {
  color: #607D8B !important;
}
.indigo-amber app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #607D8B !important;
}
.indigo-amber app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .info h4 {
  color: #607D8B !important;
}
.indigo-amber app-actor-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee-title {
  color: #607D8B;
}
.indigo-amber app-actor-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: #607D8B;
}
.indigo-amber app-actor-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #607D8B;
}
.indigo-amber .dropzone {
  border: 2px dashed #607D8B;
  background: #eeeeee !important;
}
.indigo-amber .dropzone.hovering {
  border: 2px solid #607D8B;
  color: rgba(0, 0, 0, 0.38) !important;
}
.indigo-amber .dropzone button.mat-stroked-button {
  background: white;
}
.indigo-amber .firmador .content-action-main {
  color: white !important;
}
.indigo-amber .firmador .content-action-main.disabled {
  color: rgba(0, 0, 0, 0.38) !important;
  background: rgba(0, 0, 0, 0.12);
}
.indigo-amber .sign-cycle {
  background: white;
}
.indigo-amber .sign-cycle button.success {
  background-color: #009688;
}
.indigo-amber .sign-cycle button.warning {
  background-color: #673AB7;
}
.indigo-amber .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-title-container .cycle-content-title h4 {
  color: #607D8B;
}
.indigo-amber .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-action .content-action-main {
  color: white !important;
}
.indigo-amber .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-action .content-action-main.mat-stroked-button:disabled {
  color: #9e9e9e !important;
}
.indigo-amber .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-action .content-action-main.mat-stroked-button:not(:disabled) {
  color: #ffc107 !important;
}
.indigo-amber .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-action .content-action-side u {
  color: #9e9e9e;
}
.indigo-amber .resume-status {
  background: white;
}
.indigo-amber .help-hoverable mat-icon {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-amber .help-hoverable mat-hint {
  background: white;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.indigo-amber .help-hoverable mat-hint b {
  color: #3f51b5 !important;
}
.indigo-amber app-file-document-sign-massive-dialog .dialog-content,
.indigo-amber app-share-person-dialog .dialog-content {
  background: #fafafa;
}
.indigo-amber app-file-document-sign-massive-dialog .dialog-content mat-toolbar mat-toolbar-row .mat-avatar h3,
.indigo-amber app-share-person-dialog .dialog-content mat-toolbar mat-toolbar-row .mat-avatar h3 {
  color: #3f51b5;
}
.indigo-amber app-file-document-sign-massive-dialog .dialog-content .dialog-body p b,
.indigo-amber app-share-person-dialog .dialog-content .dialog-body p b {
  color: #ffc107;
}
.indigo-amber app-file-document-sign-massive-dialog .dialog-content app-file-document-sign,
.indigo-amber app-share-person-dialog .dialog-content app-file-document-sign {
  background: white;
}
.indigo-amber .mat-basic-chip {
  background: #eeeeee !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber .mat-basic-chip.seen {
  border-color: #2196F3 !important;
  color: #2196F3 !important;
}
.indigo-amber .mat-basic-chip.unseen {
  border-color: #607D8B !important;
  color: #607D8B !important;
}
.indigo-amber .mat-basic-chip.signed-ok {
  border-color: #009688 !important;
  color: #009688 !important;
}
.indigo-amber .mat-basic-chip.signed-ok.cancelled {
  border-color: #795548 !important;
  color: #795548 !important;
}
.indigo-amber .mat-basic-chip.signed-not-ok {
  border-color: #673AB7 !important;
  color: #673AB7 !important;
}
.indigo-amber .mat-basic-chip.not-signed {
  border-color: #ff4081 !important;
  color: #ff4081 !important;
}
.indigo-amber .mat-basic-chip.not-signed-employer {
  border-color: #ff4081 !important;
  color: #ff4081 !important;
}
.indigo-amber app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip {
  background: #eeeeee !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.seen {
  border-color: #2196F3 !important;
  color: #2196F3 !important;
}
.indigo-amber app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.unseen {
  border-color: #607D8B !important;
  color: #607D8B !important;
}
.indigo-amber app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.signed-ok {
  border-color: #009688 !important;
  color: #009688 !important;
}
.indigo-amber app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.signed-ok.cancelled {
  border-color: #795548 !important;
  color: #795548 !important;
}
.indigo-amber app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.signed-not-ok {
  border-color: #673AB7 !important;
  color: #673AB7 !important;
}
.indigo-amber app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.not-signed {
  border-color: #ff4081 !important;
  color: #ff4081 !important;
}
.indigo-amber app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.not-signed-employer {
  border-color: #ff4081 !important;
  color: #ff4081 !important;
}
.indigo-amber .inbox-container.employee.my-documents .chip-line.mat-line .mat-chip-list-wrapper .mat-basic-chip.seen {
  color: #607D8B !important;
}
.indigo-amber .resume-status .mat-basic-chip {
  background: #eeeeee !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber .resume-status .mat-basic-chip.signed-ok {
  border-color: #009688 !important;
  background-color: #009688 !important;
  color: white !important;
}
.indigo-amber .resume-status .mat-basic-chip.signed-ok.cancelled {
  border-color: #795548 !important;
  background-color: #795548 !important;
  color: white !important;
}
.indigo-amber .resume-status .mat-basic-chip.signed-not-ok {
  border-color: #673AB7 !important;
  background-color: #673AB7 !important;
  color: white !important;
}
.indigo-amber app-file-document-view .new-container app-file-document-upload {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber app-file-document-view .new-container app-upload-form .file-upload-list .mat-subheader {
  background: white;
}
.indigo-amber app-file-document-view .new-container app-upload-form .file-upload-list mat-list-item {
  background: #fafafa !important;
}
.indigo-amber app-file-document-view .preview-content {
  background: #607D8B;
}
.indigo-amber app-file-document-view .preview-content h4 {
  color: white;
}
.indigo-amber app-file-document-view .view-container {
  background: white;
}
.indigo-amber app-file-document-view .view-container mat-toolbar.side-bar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber .formio-hint {
  background: #3f51b5;
  color: white;
}
.indigo-amber mat-dialog-container app-file-document-state-chips {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber app-candidate-promotion .load-window.message h3.primary-text {
  color: #3f51b5;
}
.indigo-amber app-multiple-documentation-add-dialog .icon-complex mat-icon,
.indigo-amber app-documentation-type-set-configuration-dialog .icon-complex mat-icon,
.indigo-amber app-add-candidate-dialog .icon-complex mat-icon,
.indigo-amber app-share-person-dialog .icon-complex mat-icon,
.indigo-amber app-share-person-response-dialog .icon-complex mat-icon {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-amber app-multiple-documentation-add-dialog .icon-complex mat-icon.complex,
.indigo-amber app-documentation-type-set-configuration-dialog .icon-complex mat-icon.complex,
.indigo-amber app-add-candidate-dialog .icon-complex mat-icon.complex,
.indigo-amber app-share-person-dialog .icon-complex mat-icon.complex,
.indigo-amber app-share-person-response-dialog .icon-complex mat-icon.complex {
  color: #3f51b5 !important;
}
.indigo-amber app-multiple-documentation-add-dialog .icon-complex mat-icon.complex.mat-accent,
.indigo-amber app-documentation-type-set-configuration-dialog .icon-complex mat-icon.complex.mat-accent,
.indigo-amber app-add-candidate-dialog .icon-complex mat-icon.complex.mat-accent,
.indigo-amber app-share-person-dialog .icon-complex mat-icon.complex.mat-accent,
.indigo-amber app-share-person-response-dialog .icon-complex mat-icon.complex.mat-accent {
  color: #ffc107;
}
.indigo-amber app-multiple-documentation-add-dialog .icon-complex mat-icon.complex.side,
.indigo-amber app-documentation-type-set-configuration-dialog .icon-complex mat-icon.complex.side,
.indigo-amber app-add-candidate-dialog .icon-complex mat-icon.complex.side,
.indigo-amber app-share-person-dialog .icon-complex mat-icon.complex.side,
.indigo-amber app-share-person-response-dialog .icon-complex mat-icon.complex.side {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection,
.indigo-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection,
.indigo-amber app-add-candidate-dialog .dialog-content .document-template-container .document-selection,
.indigo-amber app-share-person-dialog .dialog-content .document-template-container .document-selection,
.indigo-amber app-share-person-response-dialog .dialog-content .document-template-container .document-selection {
  background: #fafafa;
}
.indigo-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.indigo-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.indigo-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3,
.indigo-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.indigo-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.indigo-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3,
.indigo-amber app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.indigo-amber app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.indigo-amber app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3,
.indigo-amber app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.indigo-amber app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.indigo-amber app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3,
.indigo-amber app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.indigo-amber app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.indigo-amber app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3 {
  color: #ffc107 !important;
}
.indigo-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon,
.indigo-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon,
.indigo-amber app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon,
.indigo-amber app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon,
.indigo-amber app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon {
  color: #3f51b5;
}
.indigo-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3,
.indigo-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3,
.indigo-amber app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3,
.indigo-amber app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3,
.indigo-amber app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3 {
  color: #3f51b5;
}
.indigo-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex,
.indigo-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex,
.indigo-amber app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex,
.indigo-amber app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex,
.indigo-amber app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex {
  color: #ffc107 !important;
  text-shadow: -2px 0 0px #eeeeee, 0 -2px 0px #eeeeee, 2px 0 0px #eeeeee, 0 2px 0px #eeeeee, -2px -2px 0px #eeeeee, 2px 2px 0px #eeeeee, 2px -2px 0px #eeeeee, -2px 2px 0px white;
}
.indigo-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex,
.indigo-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex,
.indigo-amber app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex,
.indigo-amber app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex,
.indigo-amber app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex {
  color: rgba(0, 0, 0, 0.38) !important;
}
.indigo-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b,
.indigo-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b,
.indigo-amber app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b,
.indigo-amber app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b,
.indigo-amber app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex,
.indigo-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex,
.indigo-amber app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex,
.indigo-amber app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex,
.indigo-amber app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex {
  color: rgba(0, 0, 0, 0.38) !important;
}
.indigo-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex,
.indigo-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex,
.indigo-amber app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex,
.indigo-amber app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex,
.indigo-amber app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex {
  color: rgba(0, 0, 0, 0.38) !important;
}
.indigo-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover,
.indigo-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover,
.indigo-amber app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover,
.indigo-amber app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover,
.indigo-amber app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover {
  background: rgba(0, 0, 0, 0.04);
}
.indigo-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b,
.indigo-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b,
.indigo-amber app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b,
.indigo-amber app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b,
.indigo-amber app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.indigo-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.indigo-amber app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.indigo-amber app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.indigo-amber app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble {
  background: #3f51b5;
}
.indigo-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble,
.indigo-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble,
.indigo-amber app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble,
.indigo-amber app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble,
.indigo-amber app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble {
  background: #f44336;
}
.indigo-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble,
.indigo-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble,
.indigo-amber app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble,
.indigo-amber app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble,
.indigo-amber app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble {
  color: rgba(0, 0, 0, 0.38);
  background: rgba(0, 0, 0, 0.12);
}
.indigo-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble,
.indigo-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble,
.indigo-amber app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble,
.indigo-amber app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble,
.indigo-amber app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble {
  background: rgba(0, 0, 0, 0.38);
  color: white;
}
.indigo-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide,
.indigo-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide,
.indigo-amber app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide,
.indigo-amber app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide,
.indigo-amber app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide {
  border-color: rgba(0, 0, 0, 0.12);
}
.indigo-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble,
.indigo-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble,
.indigo-amber app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble,
.indigo-amber app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble,
.indigo-amber app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.indigo-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.indigo-amber app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.indigo-amber app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.indigo-amber app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble {
  color: #3f51b5;
}
.indigo-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble,
.indigo-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble,
.indigo-amber app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble,
.indigo-amber app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble,
.indigo-amber app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble {
  color: #f44336;
}
.indigo-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar,
.indigo-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar,
.indigo-amber app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar,
.indigo-amber app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar,
.indigo-amber app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar {
  background: #607D8B;
}
.indigo-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row,
.indigo-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row,
.indigo-amber app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row,
.indigo-amber app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row,
.indigo-amber app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row {
  color: white;
}
.indigo-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper,
.indigo-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper,
.indigo-amber app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper,
.indigo-amber app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper,
.indigo-amber app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper {
  background: #607D8B;
}
.indigo-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop,
.indigo-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop,
.indigo-amber app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop,
.indigo-amber app-share-person-dialog .dialog-content .document-template-container .add-docs-drop,
.indigo-amber app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body,
.indigo-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body,
.indigo-amber app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body,
.indigo-amber app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body,
.indigo-amber app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3,
.indigo-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3,
.indigo-amber app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3,
.indigo-amber app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3,
.indigo-amber app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3 {
  color: #ffc107;
}
.indigo-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5,
.indigo-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5,
.indigo-amber app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5,
.indigo-amber app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5,
.indigo-amber app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5 {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5,
.indigo-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5,
.indigo-amber app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5,
.indigo-amber app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5,
.indigo-amber app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5 {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator,
.indigo-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator,
.indigo-amber app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator,
.indigo-amber app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator,
.indigo-amber app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background: #fafafa;
}
.indigo-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip,
.indigo-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip,
.indigo-amber app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip,
.indigo-amber app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip,
.indigo-amber app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip {
  background: white !important;
}
.indigo-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon,
.indigo-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon,
.indigo-amber app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon,
.indigo-amber app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon,
.indigo-amber app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon {
  color: #ffc107 !important;
}
.indigo-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3,
.indigo-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3,
.indigo-amber app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3,
.indigo-amber app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3,
.indigo-amber app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3 {
  color: #ffc107 !important;
}
.indigo-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5,
.indigo-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5,
.indigo-amber app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5,
.indigo-amber app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5,
.indigo-amber app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5 {
  color: rgba(0, 0, 0, 0.54) !important;
}
.indigo-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon,
.indigo-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon,
.indigo-amber app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon,
.indigo-amber app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon,
.indigo-amber app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon {
  color: #3f51b5 !important;
}
.indigo-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3,
.indigo-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3,
.indigo-amber app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3,
.indigo-amber app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3,
.indigo-amber app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3 {
  color: #3f51b5 !important;
}
.indigo-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5,
.indigo-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5,
.indigo-amber app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5,
.indigo-amber app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5,
.indigo-amber app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5 {
  color: #3f51b5 !important;
}
.indigo-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled,
.indigo-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled,
.indigo-amber app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled,
.indigo-amber app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled,
.indigo-amber app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled {
  background: #fafafa !important;
  color: rgba(0, 0, 0, 0.38);
}
.indigo-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove,
.indigo-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove,
.indigo-amber app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove,
.indigo-amber app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove,
.indigo-amber app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove,
.indigo-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove,
.indigo-amber app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove,
.indigo-amber app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove,
.indigo-amber app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove {
  color: rgba(0, 0, 0, 0.54) !important;
}
.indigo-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .preview-action,
.indigo-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .preview-action,
.indigo-amber app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .preview-action,
.indigo-amber app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .preview-action,
.indigo-amber app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .preview-action {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber .tyc .sticky {
  background: white;
}
.indigo-amber .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper:nth-child(odd) datatable-body-row {
  background: #fafafa;
}
.indigo-amber .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active * {
  color: white !important;
}
.indigo-amber .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active:hover .datatable-body-cell-label {
  background: #3f51b5;
}
.indigo-amber .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active:hover .cs-floating-action {
  background: #3f51b5 !important;
}
.indigo-amber .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active.row-cancel * {
  color: rgba(0, 0, 0, 0.54) !important;
}
.indigo-amber .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active.row-cancel .datatable-row-group {
  background: #607D8B !important;
}
.indigo-amber .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active.row-cancel:hover .datatable-body-cell-label {
  background: #607D8B;
}
.indigo-amber .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.row-cancel * {
  color: #b0bec5 !important;
  transition-property: color;
  transition-duration: 0.3s;
  transition-timing-function: linear;
}
.indigo-amber .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row:hover .datatable-body-cell-label button {
  background: #eeeeee;
}
.indigo-amber .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row datatable-body-cell.candidate-status_bar mat-progress-bar .mat-progress-bar-buffer {
  background-color: #eeeeee;
}
.indigo-amber .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row datatable-body-cell.candidate-status_bar .status_bar-count {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-amber .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row datatable-body-cell.candidate-status_bar .inactive {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-amber .cs-grid-test .info {
  background: white;
}
.indigo-amber .cs-grid-test .selected-column {
  background: white;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
}
.indigo-amber .cs-grid-test .selection-cell .datatable-body-cell.active {
  background: #3f51b5 !important;
  color: white !important;
}
.indigo-amber .cs-grid-test nav {
  background: white;
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 13px 19px 2px rgba(0, 0, 0, 0.14), 0 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.indigo-amber .cs-grid-test .ngx-datatable.material {
  background: white;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.indigo-amber .cs-grid-test .ngx-datatable.material.striped .datatable-row-odd {
  background: #fafafa;
}
.indigo-amber .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active, .indigo-amber .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active .datatable-row-group, .indigo-amber .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active, .indigo-amber .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active .datatable-row-group, .indigo-amber .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active, .indigo-amber .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active .datatable-row-group {
  background: #3f51b5;
  color: white;
}
.indigo-amber .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active:hover, .indigo-amber .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active:hover .datatable-row-group, .indigo-amber .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active:hover, .indigo-amber .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active:hover .datatable-row-group, .indigo-amber .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active:hover, .indigo-amber .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active:hover .datatable-row-group {
  background: #3f51b5;
  color: white;
}
.indigo-amber .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active:focus, .indigo-amber .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active:focus .datatable-row-group, .indigo-amber .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active:focus, .indigo-amber .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active:focus .datatable-row-group, .indigo-amber .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active:focus, .indigo-amber .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active:focus .datatable-row-group {
  background: #3f51b5;
  color: white;
}
.indigo-amber .cs-grid-test .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover, .indigo-amber .cs-grid-test .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: rgba(0, 0, 0, 0.04);
}
.indigo-amber .cs-grid-test .ngx-datatable.material:not(.cell-selection) .datatable-body-row:focus, .indigo-amber .cs-grid-test .ngx-datatable.material:not(.cell-selection) .datatable-body-row:focus .datatable-row-group {
  background: rgba(0, 0, 0, 0.04);
}
.indigo-amber .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell:hover, .indigo-amber .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell:hover .datatable-row-group {
  background: rgba(0, 0, 0, 0.04);
}
.indigo-amber .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell:focus, .indigo-amber .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell:focus .datatable-row-group {
  background: rgba(0, 0, 0, 0.04);
}
.indigo-amber .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active, .indigo-amber .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active .datatable-row-group {
  background: #3f51b5;
  color: white;
}
.indigo-amber .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active:hover, .indigo-amber .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active:hover .datatable-row-group {
  background: #3f51b5;
  color: white;
}
.indigo-amber .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active:focus, .indigo-amber .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active:focus .datatable-row-group {
  background: #3f51b5;
  color: white;
}
.indigo-amber .cs-grid-test .ngx-datatable.material .datatable-header .datatable-row-left,
.indigo-amber .cs-grid-test .ngx-datatable.material .datatable-body .datatable-row-left {
  background-color: white;
}
.indigo-amber .cs-grid-test .ngx-datatable.material .datatable-header .datatable-row-right,
.indigo-amber .cs-grid-test .ngx-datatable.material .datatable-body .datatable-row-right {
  background-color: white;
}
.indigo-amber .cs-grid-test .ngx-datatable.material .datatable-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.38);
}
.indigo-amber .cs-grid-test .ngx-datatable.material .datatable-header .datatable-header-cell {
  background-color: white;
  color: rgba(0, 0, 0, 0.54);
}
.indigo-amber .cs-grid-test .ngx-datatable.material .datatable-header .resize-handle {
  border-right: solid 1px rgba(0, 0, 0, 0.04);
}
.indigo-amber .cs-grid-test .ngx-datatable.material .datatable-body .datatable-row-detail {
  background: #eeeeee;
}
.indigo-amber .cs-grid-test .ngx-datatable.material .datatable-body .datatable-group-header {
  background: #eeeeee;
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
  border-top: solid 1px rgba(0, 0, 0, 0.12);
}
.indigo-amber .cs-grid-test .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber .cs-grid-test .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-group-cell {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber .cs-grid-test .ngx-datatable.material .datatable-body .progress-linear .container {
  background-color: white;
}
.indigo-amber .cs-grid-test .ngx-datatable.material .datatable-body .progress-linear .container .bar {
  background-color: #3f51b5;
}
.indigo-amber .cs-grid-test .ngx-datatable.material .datatable-footer {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-amber .cs-grid-test .ngx-datatable.material .datatable-footer .datatable-pager li.disabled a {
  color: rgba(0, 0, 0, 0.38) !important;
  background-color: rgba(0, 0, 0, 0) !important;
}
.indigo-amber .cs-grid-test .ngx-datatable.material .datatable-footer .datatable-pager li.active a {
  background-color: #3f51b5;
}
.indigo-amber .cs-grid-test .ngx-datatable.material .datatable-footer .datatable-pager a {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-amber .cs-grid-test .ngx-datatable.material .datatable-footer .datatable-pager a:hover {
  color: rgba(0, 0, 0, 0.04);
  background-color: rgba(0, 0, 0, 0.04);
}
.indigo-amber .cs-grid-test .ngx-datatable.material .datatable-summary-row .datatable-body-row {
  background-color: #fafafa;
}
.indigo-amber .cs-grid-test .ngx-datatable.material .datatable-summary-row .datatable-body-row:hover {
  background-color: #fafafa;
}
.indigo-amber .cs-grid-test .datatable-checkbox input[type=checkbox]:after {
  background: white;
}
.indigo-amber .view-container .view-toolbar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal.new.image-opened .help-content img {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card {
  background: white;
}
.indigo-amber app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container .aditional-info {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-amber app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container .mat-card-footer {
  background: white;
}
.indigo-amber app-help-index mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card {
  background: white;
}
.indigo-amber app-help-index mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card .mat-card-footer {
  background: white;
}
.indigo-amber .cropper .employee-title .title-text {
  color: white !important;
}
.indigo-amber .cropper app-cs-upload-cropper-control .preview h5 {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-amber app-file-document-upload .preview-container {
  background: #607D8B !important;
}
.indigo-amber app-file-document-upload .preview-container h4 {
  color: #eeeeee;
}
.indigo-amber .preview-container {
  background: #607D8B !important;
}
.indigo-amber .preview-container h4 {
  color: #eeeeee;
}
.indigo-amber .clean-autocomple mat-list {
  background: white;
}
.indigo-amber .clean-autocomple mat-list .mat-list-item:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.indigo-amber .clean-autocomple mat-list .mat-list-item .mat-list-item-content .mat-list-text {
  color: #3f51b5;
}
.indigo-amber app-leave .inbox-toolbar {
  background: #fafafa;
}
.indigo-amber app-leave .mat-tab-body-wrapper {
  position: relative;
  overflow: hidden;
  display: flex;
  transition: height 500ms cubic-bezier(0.35, 0, 0.25, 1);
  height: 95% !important;
}
.indigo-amber app-leave .leave_container {
  background: #fafafa;
}
.indigo-amber app-leave .leave_container .leave_items .leave_items-item.ok .content .leave_item-status_icon {
  color: #009688;
}
.indigo-amber app-leave .leave_container .leave_items .leave_items-item.not-ok .content .leave_item-status_icon {
  color: #9C27B0;
}
.indigo-amber app-leave .leave_container .leave_items .leave_items-item.pending .content .leave_item-status_icon {
  color: #FF9800;
}
.indigo-amber app-leave .leave_container .leave_items .leave_items-item.draft .content .leave_item-status_icon {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-amber app-leave .leave_container .leave_items .leave_items-item.cancel .content .leave_item-status_icon {
  color: #795548;
}
.indigo-amber app-leave .leave_container .leave_items .leave_items-item .content:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.indigo-amber app-leave .leave_container .leave_items .leave_items-item .content.selected {
  border-left: 6px solid #ffc107 !important;
}
.indigo-amber app-leave .leave_container .leave_items .leave_items-item .content.selected mat-icon {
  color: #ffc107 !important;
}
.indigo-amber app-leave .leave_container .leave_items .leave_items-item .content.selected .item-data {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-amber app-leave .leave_container .leave_items .leave_items-item .content .leave_item-icon {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-amber app-leave .leave_container .leave_items .leave_items-item .content .item-data {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-amber app-leave .leave_container .window-spinner {
  background: #fafafa !important;
}
.indigo-amber app-leave-detail .leave_detail .leave_detail-title .info h3,
.indigo-amber app-leave-detail .leave_detail .leave_detail-title .info mat-icon {
  color: #3f51b5;
}
.indigo-amber app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .ok .large-icon {
  background: #b2dfdb;
  color: #009688;
}
.indigo-amber app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .ok h2 {
  color: #009688;
}
.indigo-amber app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .not-ok .large-icon {
  background: #e1bee7;
  color: #9C27B0;
}
.indigo-amber app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .not-ok h2 {
  color: #9C27B0;
}
.indigo-amber app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .pending .large-icon {
  background: #FFE0B2;
  color: #FF9800;
}
.indigo-amber app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .pending h2 {
  color: #FF9800;
}
.indigo-amber app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .draft .large-icon {
  background: #f2f2f2;
  color: #dedede;
}
.indigo-amber app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .draft h2 {
  color: #dedede;
}
.indigo-amber app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .cancelled .large-icon {
  background: #BCAAA4;
  color: #795548;
}
.indigo-amber app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .cancelled h2 {
  color: #795548;
}
.indigo-amber app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .actions {
  padding: 16px;
  height: 96px;
  column-gap: 16px;
}
.indigo-amber app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .actions button {
  height: 100%;
  border-radius: 40px;
}
.indigo-amber app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .actions button.ok {
  background: #009688 !important;
  color: white;
}
.indigo-amber app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .actions button.not-ok {
  color: #9C27B0 !important;
}
.indigo-amber app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning mat-basic-chip {
  background: #eeeeee !important;
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
  border-radius: 40px !important;
  height: 35px !important;
}
.indigo-amber app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning mat-basic-chip.signed-ok {
  border-color: #009688 !important;
  color: #009688 !important;
}
.indigo-amber app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning mat-basic-chip.signed-not-ok {
  border-color: #9C27B0 !important;
  color: #9C27B0 !important;
}
.indigo-amber app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning mat-basic-chip.draft {
  border-color: #dedede !important;
  color: #dedede !important;
}
.indigo-amber app-leave-request-detail .load-window {
  background: #fafafa;
}
.indigo-amber app-leave-request-detail form .leave_detail-container {
  background: #fafafa;
}
.indigo-amber app-leave-request-detail form .leave_detail-container .toolbar .header {
  color: #3f51b5;
}
.indigo-amber app-leave-request-detail form .leave_detail-container .toolbar .header .text h3 {
  color: #3f51b5;
}
.indigo-amber app-leave-request-detail form .leave_detail-container .toolbar .header .text h5 {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-amber app-leave-request-detail form .leave_detail-container .leave_detail-container-content .leave_resume {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber app-leave-request-detail form .leave_detail-container .leave_detail-container-content .line h6 {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-amber app-leave-request-detail form .leave_detail-container .actions button.ok {
  background: #009688 !important;
  color: white;
}
.indigo-amber app-leave-request-detail form .leave_detail-container .actions button.not-ok {
  color: #9C27B0 !important;
}
.indigo-amber .new_leave_modal-steps {
  display: unset !important;
}
.indigo-amber .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button .mat-radio-label .mat-radio-label-content .side-text {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-amber .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button .mat-radio-label .mat-radio-label-content .side-text.muted {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-amber .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button .mat-radio-label .mat-radio-label-content .accent mat-icon,
.indigo-amber .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button .mat-radio-label .mat-radio-label-content .accent .main-text {
  color: #ffc107;
}
.indigo-amber .config_leave-container .config_leave-card {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber .config_leave-container .config_leave-card .config_leave-auto_chip mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #fafafa !important;
}
.indigo-amber .config_leave-container .config_leave-card .config_leave-auto_chip mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-amber .config_leave-container .config_leave-card .config_leave-auto_chip mat-form-field .mat-form-field-flex {
  background: #fafafa;
}
.indigo-amber *[color=success] {
  color: #4DB6AC;
}
.indigo-amber .primary {
  color: #3f51b5 !important;
}
.indigo-amber .accent {
  color: #ffc107 !important;
}
.indigo-amber app-lsd .icon-complex mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.indigo-amber app-lsd .icon-complex mat-icon.complex {
  color: #3f51b5 !important;
}
.indigo-amber app-lsd .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.indigo-amber app-lsd .work-space .main .load-window {
  background: white !important;
}
.indigo-amber app-lsd .work-space .main.side-open {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber app-lsd .work-space .main .container .item {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber app-lsd .work-space .main .container .item h5 {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-amber app-lsd .work-space .main .container .item h3 {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber app-lsd .work-space .main .container .item:hover {
  background: rgba(0, 0, 0, 0.04);
}
.indigo-amber app-lsd .work-space .main .container .item.selected {
  background: #3f51b5;
  color: white !important;
}
.indigo-amber app-lsd .work-space .main .container .item.selected h3 {
  color: white !important;
}
.indigo-amber app-lsd .work-space .main .container .item.selected.pending .status {
  background: rgba(0, 0, 0, 0.12) !important;
  color: #ffe082;
}
.indigo-amber app-lsd .work-space .main .container .item.selected.ok .status {
  background: rgba(0, 0, 0, 0.12) !important;
  color: #a5d6a7;
}
.indigo-amber app-lsd .work-space .main .container .item.selected.not-ok .status {
  background: rgba(0, 0, 0, 0.12) !important;
  color: #e57373;
}
.indigo-amber app-lsd .work-space .main .main-action {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber app-lsd .work-space .side {
  background: #fafafa;
}
.indigo-amber app-lsd .work-space .side .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #fafafa, 0 -2px 0px #fafafa, 2px 0 0px #fafafa, 0 2px 0px #fafafa, -2px -2px 0px #fafafa, 2px 2px 0px #fafafa, 2px -2px 0px #fafafa, -2px 2px 0px #fafafa;
}
.indigo-amber app-lsd .work-space .side *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.indigo-amber app-lsd .work-space .side *::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}
.indigo-amber app-lsd .work-space .side .edition-line {
  background: #eeeeee;
}
.indigo-amber app-lsd .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #fafafa !important;
}
.indigo-amber app-lsd .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-amber app-lsd .work-space .side mat-form-field .mat-form-field-flex {
  background: #fafafa;
}
.indigo-amber app-lsd .work-space .side mat-checkbox:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.indigo-amber app-lsd .work-space .side .hint.all {
  color: #ffc107;
}
.indigo-amber app-lsd .work-space .side .toolbar .header {
  color: #3f51b5;
}
.indigo-amber app-lsd .work-space .side .toolbar .header h3 {
  color: #3f51b5;
}
.indigo-amber app-lsd .work-space .side .container .doc-resume {
  background: #3f51b5;
}
.indigo-amber app-lsd .work-space .side .container .doc-resume .line {
  color: white;
}
.indigo-amber app-lsd .work-space .side .container .doc-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.1);
}
.indigo-amber app-lsd .work-space .side .container .doc-resume .line.editable button {
  background: #3f51b5;
}
.indigo-amber app-lsd .work-space .side .container .section-title {
  color: #3f51b5;
}
.indigo-amber app-lsd .work-space .side .container .section-resume {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber app-lsd .work-space .side .container .section-resume .line.editable:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.indigo-amber app-lsd .work-space .side .container .section-resume .line.editable:hover .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px white, 0 -2px 0px white, 2px 0 0px white, 0 2px 0px white, -2px -2px 0px white, 2px 2px 0px white, 2px -2px 0px white, -2px 2px 0px white !important;
}
.indigo-amber app-lsd .work-space .side .container .section-resume .line.editable button {
  background: #fafafa;
}
.indigo-amber app-lsd .work-space .side .container .section-resume .line .header .icon-complex mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.indigo-amber app-lsd .work-space .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #3f51b5 !important;
}
.indigo-amber app-lsd .work-space .side .container .section-resume .line .header mat-icon:not(.complex) {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-amber app-lsd .work-space .side .container .section-resume .line .header .info h4 {
  color: #3f51b5;
}
.indigo-amber app-lsd .work-space .side .container .section-resume .line ul {
  color: #3f51b5;
}
.indigo-amber app-lsd .work-space .side .container .section-resume .line.head {
  background: #eeeeee;
}
.indigo-amber app-lsd .work-space .side .container .section-resume .line.head mat-icon {
  color: #ffc107;
}
.indigo-amber app-lsd .work-space .side .container .section-resume .line.head h4 {
  color: white;
}
.indigo-amber app-lsd .work-space .side.edition .toolbar .header {
  color: white;
}
.indigo-amber app-lsd .work-space .side.edition .toolbar .header mat-icon {
  color: #ffc107;
}
.indigo-amber app-lsd .work-space .side.edition .toolbar .header h3 {
  color: #ffc107;
}
.indigo-amber .dialog-content {
  max-height: 90vh;
}
.indigo-amber .dialog-content mat-toolbar {
  background: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber .dialog-content .dialog-container .document-template-container .document-selection mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #fafafa !important;
}
.indigo-amber .dialog-content .dialog-container .document-template-container .document-selection mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-amber .dialog-content .dialog-container .document-template-container .document-selection mat-form-field .mat-form-field-flex {
  background: #fafafa;
}
.indigo-amber .dialog-content .dialog-container .document-template-container .document-selection app-upload-form .file-upload-list .mat-subheader::before {
  color: #3f51b5;
}
.indigo-amber .dialog-content .dialog-container .view-container {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-amber .dialog-content .form-actions {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.indigo-amber.dark .mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-amber.dark .mat-badge-small .mat-badge-content {
  font-size: 9px;
}
.indigo-amber.dark .mat-badge-large .mat-badge-content {
  font-size: 24px;
}
.indigo-amber.dark .mat-h1,
.indigo-amber.dark .mat-headline,
.indigo-amber.dark .mat-typography .mat-h1,
.indigo-amber.dark .mat-typography .mat-headline,
.indigo-amber.dark .mat-typography h1 {
  font: 400 24px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.indigo-amber.dark .mat-h2,
.indigo-amber.dark .mat-title,
.indigo-amber.dark .mat-typography .mat-h2,
.indigo-amber.dark .mat-typography .mat-title,
.indigo-amber.dark .mat-typography h2 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.indigo-amber.dark .mat-h3,
.indigo-amber.dark .mat-subheading-2,
.indigo-amber.dark .mat-typography .mat-h3,
.indigo-amber.dark .mat-typography .mat-subheading-2,
.indigo-amber.dark .mat-typography h3 {
  font: 400 16px / 28px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.indigo-amber.dark .mat-h4,
.indigo-amber.dark .mat-subheading-1,
.indigo-amber.dark .mat-typography .mat-h4,
.indigo-amber.dark .mat-typography .mat-subheading-1,
.indigo-amber.dark .mat-typography h4 {
  font: 400 15px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.indigo-amber.dark .mat-h5,
.indigo-amber.dark .mat-typography .mat-h5,
.indigo-amber.dark .mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}
.indigo-amber.dark .mat-h6,
.indigo-amber.dark .mat-typography .mat-h6,
.indigo-amber.dark .mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}
.indigo-amber.dark .mat-body-strong,
.indigo-amber.dark .mat-body-2,
.indigo-amber.dark .mat-typography .mat-body-strong,
.indigo-amber.dark .mat-typography .mat-body-2 {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.indigo-amber.dark .mat-body,
.indigo-amber.dark .mat-body-1,
.indigo-amber.dark .mat-typography .mat-body,
.indigo-amber.dark .mat-typography .mat-body-1,
.indigo-amber.dark .mat-typography {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.indigo-amber.dark .mat-body p,
.indigo-amber.dark .mat-body-1 p,
.indigo-amber.dark .mat-typography .mat-body p,
.indigo-amber.dark .mat-typography .mat-body-1 p,
.indigo-amber.dark .mat-typography p {
  margin: 0 0 12px;
}
.indigo-amber.dark .mat-small,
.indigo-amber.dark .mat-caption,
.indigo-amber.dark .mat-typography .mat-small,
.indigo-amber.dark .mat-typography .mat-caption {
  font: 400 12px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.indigo-amber.dark .mat-display-4,
.indigo-amber.dark .mat-typography .mat-display-4 {
  font: 300 112px / 112px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}
.indigo-amber.dark .mat-display-3,
.indigo-amber.dark .mat-typography .mat-display-3 {
  font: 400 56px / 56px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}
.indigo-amber.dark .mat-display-2,
.indigo-amber.dark .mat-typography .mat-display-2 {
  font: 400 45px / 48px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}
.indigo-amber.dark .mat-display-1,
.indigo-amber.dark .mat-typography .mat-display-1 {
  font: 400 34px / 40px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}
.indigo-amber.dark .mat-bottom-sheet-container {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.indigo-amber.dark .mat-button, .indigo-amber.dark .mat-raised-button, .indigo-amber.dark .mat-icon-button, .indigo-amber.dark .mat-stroked-button,
.indigo-amber.dark .mat-flat-button, .indigo-amber.dark .mat-fab, .indigo-amber.dark .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.indigo-amber.dark .mat-button-toggle {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-amber.dark .mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-amber.dark .mat-card-title {
  font-size: 24px;
  font-weight: 500;
}
.indigo-amber.dark .mat-card-header .mat-card-title {
  font-size: 20px;
}
.indigo-amber.dark .mat-card-subtitle,
.indigo-amber.dark .mat-card-content {
  font-size: 14px;
}
.indigo-amber.dark .mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-amber.dark .mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}
.indigo-amber.dark .mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.indigo-amber.dark .mat-chip .mat-chip-trailing-icon.mat-icon,
.indigo-amber.dark .mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}
.indigo-amber.dark .mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-amber.dark .mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}
.indigo-amber.dark .mat-cell, .indigo-amber.dark .mat-footer-cell {
  font-size: 14px;
}
.indigo-amber.dark .mat-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-amber.dark .mat-calendar-body {
  font-size: 13px;
}
.indigo-amber.dark .mat-calendar-body-label,
.indigo-amber.dark .mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}
.indigo-amber.dark .mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}
.indigo-amber.dark .mat-dialog-title {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.indigo-amber.dark .mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400;
}
.indigo-amber.dark .mat-expansion-panel-content {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.indigo-amber.dark .mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.indigo-amber.dark .mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}
.indigo-amber.dark .mat-form-field-prefix .mat-icon,
.indigo-amber.dark .mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.indigo-amber.dark .mat-form-field-prefix .mat-icon-button,
.indigo-amber.dark .mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.indigo-amber.dark .mat-form-field-prefix .mat-icon-button .mat-icon,
.indigo-amber.dark .mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}
.indigo-amber.dark .mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}
.indigo-amber.dark .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.indigo-amber.dark .mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34365em) scale(0.75);
  width: 133.3334333333%;
}
.indigo-amber.dark .mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34364em) scale(0.75);
  width: 133.3334433333%;
}
.indigo-amber.dark .mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}
.indigo-amber.dark .mat-form-field-label {
  top: 1.34375em;
}
.indigo-amber.dark .mat-form-field-underline {
  bottom: 1.34375em;
}
.indigo-amber.dark .mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}
.indigo-amber.dark .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.indigo-amber.dark .mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.indigo-amber.dark .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.indigo-amber.dark .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.0013px);
  width: 133.3336333333%;
}
.indigo-amber.dark .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00131px);
  width: 133.3336433333%;
}
.indigo-amber.dark .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00132px);
  width: 133.3336533333%;
}
.indigo-amber.dark .mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.indigo-amber.dark .mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.indigo-amber.dark .mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}
@media print {
  .indigo-amber.dark .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.indigo-amber.dark .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28092em) scale(0.75);
  }
  .indigo-amber.dark .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28091em) scale(0.75);
  }
  .indigo-amber.dark .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2809em) scale(0.75);
  }
}
.indigo-amber.dark .mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.indigo-amber.dark .mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.indigo-amber.dark .mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.indigo-amber.dark .mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59365em) scale(0.75);
  width: 133.3334333333%;
}
.indigo-amber.dark .mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59364em) scale(0.75);
  width: 133.3334433333%;
}
.indigo-amber.dark .mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.indigo-amber.dark .mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.indigo-amber.dark .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.indigo-amber.dark .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59365em) scale(0.75);
  width: 133.3334333333%;
}
.indigo-amber.dark .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59364em) scale(0.75);
  width: 133.3334433333%;
}
.indigo-amber.dark .mat-grid-tile-header,
.indigo-amber.dark .mat-grid-tile-footer {
  font-size: 14px;
}
.indigo-amber.dark .mat-grid-tile-header .mat-line,
.indigo-amber.dark .mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.indigo-amber.dark .mat-grid-tile-header .mat-line:nth-child(n+2),
.indigo-amber.dark .mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.indigo-amber.dark input.mat-input-element {
  margin-top: -0.0625em;
}
.indigo-amber.dark .mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
}
.indigo-amber.dark .mat-paginator,
.indigo-amber.dark .mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
}
.indigo-amber.dark .mat-radio-button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-amber.dark .mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-amber.dark .mat-select-trigger {
  height: 1.125em;
}
.indigo-amber.dark .mat-slide-toggle-content {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-amber.dark .mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}
.indigo-amber.dark .mat-stepper-vertical, .indigo-amber.dark .mat-stepper-horizontal {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-amber.dark .mat-step-label {
  font-size: 14px;
  font-weight: 400;
}
.indigo-amber.dark .mat-step-sub-label-error {
  font-weight: normal;
}
.indigo-amber.dark .mat-step-label-error {
  font-size: 14px;
}
.indigo-amber.dark .mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}
.indigo-amber.dark .mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-amber.dark .mat-tab-label, .indigo-amber.dark .mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.indigo-amber.dark .mat-toolbar,
.indigo-amber.dark .mat-toolbar h1,
.indigo-amber.dark .mat-toolbar h2,
.indigo-amber.dark .mat-toolbar h3,
.indigo-amber.dark .mat-toolbar h4,
.indigo-amber.dark .mat-toolbar h5,
.indigo-amber.dark .mat-toolbar h6 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0;
}
.indigo-amber.dark .mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.indigo-amber.dark .mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.indigo-amber.dark .mat-list-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-amber.dark .mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-amber.dark .mat-list-base .mat-list-item {
  font-size: 16px;
}
.indigo-amber.dark .mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.indigo-amber.dark .mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.indigo-amber.dark .mat-list-base .mat-list-option {
  font-size: 16px;
}
.indigo-amber.dark .mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.indigo-amber.dark .mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.indigo-amber.dark .mat-list-base .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.indigo-amber.dark .mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.indigo-amber.dark .mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.indigo-amber.dark .mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.indigo-amber.dark .mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.indigo-amber.dark .mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.indigo-amber.dark .mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.indigo-amber.dark .mat-list-base[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}
.indigo-amber.dark .mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
}
.indigo-amber.dark .mat-optgroup-label {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.indigo-amber.dark .mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
}
.indigo-amber.dark .mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}
.indigo-amber.dark .mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-amber.dark .mat-tree-node,
.indigo-amber.dark .mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}
.indigo-amber.dark .mat-ripple {
  overflow: hidden;
  position: relative;
}
.indigo-amber.dark .mat-ripple:not(:empty) {
  transform: translateZ(0);
}
.indigo-amber.dark .mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}
.indigo-amber.dark .mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .indigo-amber.dark .mat-ripple-element {
  display: none;
}

.indigo-amber.dark .cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .indigo-amber.dark .cdk-visually-hidden {
  left: auto;
  right: 0;
}
.indigo-amber.dark .cdk-overlay-container, .indigo-amber.dark .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.indigo-amber.dark .cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.indigo-amber.dark .cdk-overlay-container:empty {
  display: none;
}
.indigo-amber.dark .cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}
.indigo-amber.dark .cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}
.indigo-amber.dark .cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.indigo-amber.dark .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .indigo-amber.dark .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}
.indigo-amber.dark .cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}
.indigo-amber.dark .cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.indigo-amber.dark .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}
.indigo-amber.dark .cdk-overlay-backdrop-noop-animation {
  transition: none;
}
.indigo-amber.dark .cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}
.indigo-amber.dark .cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}
.indigo-amber.dark textarea.cdk-textarea-autosize {
  resize: none;
}
.indigo-amber.dark textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}
.indigo-amber.dark textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}
@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.indigo-amber.dark .cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}
.indigo-amber.dark .cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}
.indigo-amber.dark .mat-focus-indicator {
  position: relative;
}
.indigo-amber.dark .mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.indigo-amber.dark .mat-focus-indicator:focus::before {
  content: "";
}
.cdk-high-contrast-active .indigo-amber.dark {
  --mat-focus-indicator-display: block;
}

.indigo-amber.dark .mat-mdc-focus-indicator {
  position: relative;
}
.indigo-amber.dark .mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.indigo-amber.dark .mat-mdc-focus-indicator:focus::before {
  content: "";
}
.cdk-high-contrast-active .indigo-amber.dark {
  --mat-mdc-focus-indicator-display: block;
}

.indigo-amber.dark .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.indigo-amber.dark .mat-option {
  color: white;
}
.indigo-amber.dark .mat-option:hover:not(.mat-option-disabled), .indigo-amber.dark .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-amber.dark .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-amber.dark .mat-option.mat-active {
  background: rgba(255, 255, 255, 0.04);
  color: white;
}
.indigo-amber.dark .mat-option.mat-option-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-amber.dark .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #9fa8da;
}
.indigo-amber.dark .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ffe082;
}
.indigo-amber.dark .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #e57373;
}
.indigo-amber.dark .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-amber.dark .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-amber.dark .mat-pseudo-checkbox {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-amber.dark .mat-pseudo-checkbox::after {
  color: #303030;
}
.indigo-amber.dark .mat-pseudo-checkbox-disabled {
  color: #686868;
}
.indigo-amber.dark .mat-primary .mat-pseudo-checkbox-checked,
.indigo-amber.dark .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #9fa8da;
}
.indigo-amber.dark .mat-pseudo-checkbox-checked,
.indigo-amber.dark .mat-pseudo-checkbox-indeterminate,
.indigo-amber.dark .mat-accent .mat-pseudo-checkbox-checked,
.indigo-amber.dark .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #ffe082;
}
.indigo-amber.dark .mat-warn .mat-pseudo-checkbox-checked,
.indigo-amber.dark .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #e57373;
}
.indigo-amber.dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.indigo-amber.dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #686868;
}
.indigo-amber.dark .mat-app-background, .indigo-amber.dark.mat-app-background {
  background-color: #303030;
  color: white;
}
.indigo-amber.dark .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.indigo-amber.dark .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.indigo-amber.dark .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.indigo-amber.dark .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.indigo-amber.dark .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.indigo-amber.dark .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.indigo-amber.dark .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.indigo-amber.dark .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.indigo-amber.dark .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.indigo-amber.dark .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.indigo-amber.dark .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.indigo-amber.dark .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.indigo-amber.dark .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.indigo-amber.dark .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.indigo-amber.dark .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.indigo-amber.dark .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.indigo-amber.dark .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.indigo-amber.dark .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.indigo-amber.dark .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.indigo-amber.dark .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.indigo-amber.dark .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.indigo-amber.dark .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.indigo-amber.dark .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.indigo-amber.dark .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.indigo-amber.dark .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.indigo-amber.dark .mat-autocomplete-panel {
  background: #424242;
  color: white;
}
.indigo-amber.dark .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.indigo-amber.dark .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: #424242;
}
.indigo-amber.dark .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: white;
}
.indigo-amber.dark .mat-badge-content {
  color: rgba(0, 0, 0, 0.87);
  background: #9fa8da;
}
.cdk-high-contrast-active .indigo-amber.dark .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.indigo-amber.dark .mat-badge-accent .mat-badge-content {
  background: #ffe082;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark .mat-badge-warn .mat-badge-content {
  color: rgba(0, 0, 0, 0.87);
  background: #e57373;
}
.indigo-amber.dark .mat-badge-disabled .mat-badge-content {
  background: #6e6e6e;
  color: rgba(255, 255, 255, 0.5);
}
.indigo-amber.dark .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.indigo-amber.dark .mat-button, .indigo-amber.dark .mat-icon-button, .indigo-amber.dark .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.indigo-amber.dark .mat-button.mat-primary, .indigo-amber.dark .mat-icon-button.mat-primary, .indigo-amber.dark .mat-stroked-button.mat-primary {
  color: #9fa8da;
}
.indigo-amber.dark .mat-button.mat-accent, .indigo-amber.dark .mat-icon-button.mat-accent, .indigo-amber.dark .mat-stroked-button.mat-accent {
  color: #ffe082;
}
.indigo-amber.dark .mat-button.mat-warn, .indigo-amber.dark .mat-icon-button.mat-warn, .indigo-amber.dark .mat-stroked-button.mat-warn {
  color: #e57373;
}
.indigo-amber.dark .mat-button.mat-primary.mat-button-disabled, .indigo-amber.dark .mat-button.mat-accent.mat-button-disabled, .indigo-amber.dark .mat-button.mat-warn.mat-button-disabled, .indigo-amber.dark .mat-button.mat-button-disabled.mat-button-disabled, .indigo-amber.dark .mat-icon-button.mat-primary.mat-button-disabled, .indigo-amber.dark .mat-icon-button.mat-accent.mat-button-disabled, .indigo-amber.dark .mat-icon-button.mat-warn.mat-button-disabled, .indigo-amber.dark .mat-icon-button.mat-button-disabled.mat-button-disabled, .indigo-amber.dark .mat-stroked-button.mat-primary.mat-button-disabled, .indigo-amber.dark .mat-stroked-button.mat-accent.mat-button-disabled, .indigo-amber.dark .mat-stroked-button.mat-warn.mat-button-disabled, .indigo-amber.dark .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.indigo-amber.dark .mat-button.mat-primary .mat-button-focus-overlay, .indigo-amber.dark .mat-icon-button.mat-primary .mat-button-focus-overlay, .indigo-amber.dark .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #9fa8da;
}
.indigo-amber.dark .mat-button.mat-accent .mat-button-focus-overlay, .indigo-amber.dark .mat-icon-button.mat-accent .mat-button-focus-overlay, .indigo-amber.dark .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #ffe082;
}
.indigo-amber.dark .mat-button.mat-warn .mat-button-focus-overlay, .indigo-amber.dark .mat-icon-button.mat-warn .mat-button-focus-overlay, .indigo-amber.dark .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #e57373;
}
.indigo-amber.dark .mat-button.mat-button-disabled .mat-button-focus-overlay, .indigo-amber.dark .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .indigo-amber.dark .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.indigo-amber.dark .mat-button .mat-ripple-element, .indigo-amber.dark .mat-icon-button .mat-ripple-element, .indigo-amber.dark .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.indigo-amber.dark .mat-button-focus-overlay {
  background: white;
}
.indigo-amber.dark .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark .mat-flat-button, .indigo-amber.dark .mat-raised-button, .indigo-amber.dark .mat-fab, .indigo-amber.dark .mat-mini-fab {
  color: white;
  background-color: #424242;
}
.indigo-amber.dark .mat-flat-button.mat-primary, .indigo-amber.dark .mat-raised-button.mat-primary, .indigo-amber.dark .mat-fab.mat-primary, .indigo-amber.dark .mat-mini-fab.mat-primary {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark .mat-flat-button.mat-accent, .indigo-amber.dark .mat-raised-button.mat-accent, .indigo-amber.dark .mat-fab.mat-accent, .indigo-amber.dark .mat-mini-fab.mat-accent {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark .mat-flat-button.mat-warn, .indigo-amber.dark .mat-raised-button.mat-warn, .indigo-amber.dark .mat-fab.mat-warn, .indigo-amber.dark .mat-mini-fab.mat-warn {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark .mat-flat-button.mat-primary.mat-button-disabled, .indigo-amber.dark .mat-flat-button.mat-accent.mat-button-disabled, .indigo-amber.dark .mat-flat-button.mat-warn.mat-button-disabled, .indigo-amber.dark .mat-flat-button.mat-button-disabled.mat-button-disabled, .indigo-amber.dark .mat-raised-button.mat-primary.mat-button-disabled, .indigo-amber.dark .mat-raised-button.mat-accent.mat-button-disabled, .indigo-amber.dark .mat-raised-button.mat-warn.mat-button-disabled, .indigo-amber.dark .mat-raised-button.mat-button-disabled.mat-button-disabled, .indigo-amber.dark .mat-fab.mat-primary.mat-button-disabled, .indigo-amber.dark .mat-fab.mat-accent.mat-button-disabled, .indigo-amber.dark .mat-fab.mat-warn.mat-button-disabled, .indigo-amber.dark .mat-fab.mat-button-disabled.mat-button-disabled, .indigo-amber.dark .mat-mini-fab.mat-primary.mat-button-disabled, .indigo-amber.dark .mat-mini-fab.mat-accent.mat-button-disabled, .indigo-amber.dark .mat-mini-fab.mat-warn.mat-button-disabled, .indigo-amber.dark .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.indigo-amber.dark .mat-flat-button.mat-primary, .indigo-amber.dark .mat-raised-button.mat-primary, .indigo-amber.dark .mat-fab.mat-primary, .indigo-amber.dark .mat-mini-fab.mat-primary {
  background-color: #9fa8da;
}
.indigo-amber.dark .mat-flat-button.mat-accent, .indigo-amber.dark .mat-raised-button.mat-accent, .indigo-amber.dark .mat-fab.mat-accent, .indigo-amber.dark .mat-mini-fab.mat-accent {
  background-color: #ffe082;
}
.indigo-amber.dark .mat-flat-button.mat-warn, .indigo-amber.dark .mat-raised-button.mat-warn, .indigo-amber.dark .mat-fab.mat-warn, .indigo-amber.dark .mat-mini-fab.mat-warn {
  background-color: #e57373;
}
.indigo-amber.dark .mat-flat-button.mat-primary.mat-button-disabled, .indigo-amber.dark .mat-flat-button.mat-accent.mat-button-disabled, .indigo-amber.dark .mat-flat-button.mat-warn.mat-button-disabled, .indigo-amber.dark .mat-flat-button.mat-button-disabled.mat-button-disabled, .indigo-amber.dark .mat-raised-button.mat-primary.mat-button-disabled, .indigo-amber.dark .mat-raised-button.mat-accent.mat-button-disabled, .indigo-amber.dark .mat-raised-button.mat-warn.mat-button-disabled, .indigo-amber.dark .mat-raised-button.mat-button-disabled.mat-button-disabled, .indigo-amber.dark .mat-fab.mat-primary.mat-button-disabled, .indigo-amber.dark .mat-fab.mat-accent.mat-button-disabled, .indigo-amber.dark .mat-fab.mat-warn.mat-button-disabled, .indigo-amber.dark .mat-fab.mat-button-disabled.mat-button-disabled, .indigo-amber.dark .mat-mini-fab.mat-primary.mat-button-disabled, .indigo-amber.dark .mat-mini-fab.mat-accent.mat-button-disabled, .indigo-amber.dark .mat-mini-fab.mat-warn.mat-button-disabled, .indigo-amber.dark .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark .mat-flat-button.mat-primary .mat-ripple-element, .indigo-amber.dark .mat-raised-button.mat-primary .mat-ripple-element, .indigo-amber.dark .mat-fab.mat-primary .mat-ripple-element, .indigo-amber.dark .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.indigo-amber.dark .mat-flat-button.mat-accent .mat-ripple-element, .indigo-amber.dark .mat-raised-button.mat-accent .mat-ripple-element, .indigo-amber.dark .mat-fab.mat-accent .mat-ripple-element, .indigo-amber.dark .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.indigo-amber.dark .mat-flat-button.mat-warn .mat-ripple-element, .indigo-amber.dark .mat-raised-button.mat-warn .mat-ripple-element, .indigo-amber.dark .mat-fab.mat-warn .mat-ripple-element, .indigo-amber.dark .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.indigo-amber.dark .mat-stroked-button:not([class*=mat-elevation-z]), .indigo-amber.dark .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.indigo-amber.dark .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.indigo-amber.dark .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.indigo-amber.dark .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.indigo-amber.dark .mat-fab:not([class*=mat-elevation-z]), .indigo-amber.dark .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.indigo-amber.dark .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .indigo-amber.dark .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.indigo-amber.dark .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .indigo-amber.dark .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.indigo-amber.dark .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.indigo-amber.dark .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.indigo-amber.dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.indigo-amber.dark .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.indigo-amber.dark .mat-button-toggle {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-amber.dark .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark .mat-button-toggle-appearance-standard {
  color: white;
  background: #424242;
}
.indigo-amber.dark .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: white;
}
.indigo-amber.dark .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #595959;
}
.indigo-amber.dark [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #595959;
}
.indigo-amber.dark .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #595959;
}
.indigo-amber.dark .mat-button-toggle-checked {
  background-color: #212121;
  color: rgba(255, 255, 255, 0.7);
}
.indigo-amber.dark .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: white;
}
.indigo-amber.dark .mat-button-toggle-disabled {
  color: rgba(255, 255, 255, 0.3);
  background-color: black;
}
.indigo-amber.dark .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: #424242;
}
.indigo-amber.dark .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #424242;
}
.indigo-amber.dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.indigo-amber.dark .mat-button-toggle-group-appearance-standard {
  border: solid 1px #595959;
}
.indigo-amber.dark .mat-card {
  background: #424242;
  color: white;
}
.indigo-amber.dark .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.indigo-amber.dark .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.indigo-amber.dark .mat-card-subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-amber.dark .mat-checkbox-frame {
  border-color: rgba(255, 255, 255, 0.7);
}
.indigo-amber.dark .mat-checkbox-checkmark {
  fill: #303030;
}
.indigo-amber.dark .mat-checkbox-checkmark-path {
  stroke: #303030 !important;
}
.indigo-amber.dark .mat-checkbox-mixedmark {
  background-color: #303030;
}
.indigo-amber.dark .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .indigo-amber.dark .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #9fa8da;
}
.indigo-amber.dark .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .indigo-amber.dark .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #ffe082;
}
.indigo-amber.dark .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .indigo-amber.dark .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #e57373;
}
.indigo-amber.dark .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .indigo-amber.dark .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #686868;
}
.indigo-amber.dark .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #686868;
}
.indigo-amber.dark .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-amber.dark .mat-checkbox .mat-ripple-element {
  background-color: white;
}
.indigo-amber.dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.indigo-amber.dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #9fa8da;
}
.indigo-amber.dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.indigo-amber.dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #ffe082;
}
.indigo-amber.dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.indigo-amber.dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #e57373;
}
.indigo-amber.dark .mat-chip.mat-standard-chip {
  background-color: #616161;
  color: white;
}
.indigo-amber.dark .mat-chip.mat-standard-chip .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.indigo-amber.dark .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.indigo-amber.dark .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.indigo-amber.dark .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.indigo-amber.dark .mat-chip.mat-standard-chip::after {
  background: white;
}
.indigo-amber.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.indigo-amber.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.indigo-amber.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #e57373;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.indigo-amber.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.indigo-amber.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #ffe082;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.indigo-amber.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.indigo-amber.dark .mat-table {
  background: #424242;
}
.indigo-amber.dark .mat-table thead, .indigo-amber.dark .mat-table tbody, .indigo-amber.dark .mat-table tfoot,
.indigo-amber.dark mat-header-row, .indigo-amber.dark mat-row, .indigo-amber.dark mat-footer-row,
.indigo-amber.dark [mat-header-row], .indigo-amber.dark [mat-row], .indigo-amber.dark [mat-footer-row],
.indigo-amber.dark .mat-table-sticky {
  background: inherit;
}
.indigo-amber.dark mat-row, .indigo-amber.dark mat-header-row, .indigo-amber.dark mat-footer-row,
.indigo-amber.dark th.mat-header-cell, .indigo-amber.dark td.mat-cell, .indigo-amber.dark td.mat-footer-cell {
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark .mat-header-cell {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-amber.dark .mat-cell, .indigo-amber.dark .mat-footer-cell {
  color: white;
}
.indigo-amber.dark .mat-calendar-arrow {
  fill: white;
}
.indigo-amber.dark .mat-datepicker-toggle,
.indigo-amber.dark .mat-datepicker-content .mat-calendar-next-button,
.indigo-amber.dark .mat-datepicker-content .mat-calendar-previous-button {
  color: white;
}
.indigo-amber.dark .mat-calendar-table-header-divider::after {
  background: rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark .mat-calendar-table-header,
.indigo-amber.dark .mat-calendar-body-label {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-amber.dark .mat-calendar-body-cell-content,
.indigo-amber.dark .mat-date-range-input-separator {
  color: white;
  border-color: transparent;
}
.indigo-amber.dark .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-amber.dark .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-amber.dark .mat-calendar-body-in-preview {
  color: rgba(255, 255, 255, 0.24);
}
.indigo-amber.dark .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.5);
}
.indigo-amber.dark .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.3);
}
.indigo-amber.dark .mat-calendar-body-in-range::before {
  background: rgba(159, 168, 218, 0.2);
}
.indigo-amber.dark .mat-calendar-body-comparison-identical,
.indigo-amber.dark .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.indigo-amber.dark .mat-calendar-body-comparison-bridge-start::before,
.indigo-amber.dark [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(159, 168, 218, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.indigo-amber.dark .mat-calendar-body-comparison-bridge-end::before,
.indigo-amber.dark [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(159, 168, 218, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.indigo-amber.dark .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.indigo-amber.dark .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.indigo-amber.dark .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.indigo-amber.dark .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.indigo-amber.dark .mat-calendar-body-selected {
  background-color: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(159, 168, 218, 0.4);
}
.indigo-amber.dark .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.indigo-amber.dark .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(159, 168, 218, 0.3);
}
@media (hover: hover) {
  .indigo-amber.dark .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(159, 168, 218, 0.3);
  }
}
.indigo-amber.dark .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #424242;
  color: white;
}
.indigo-amber.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(255, 224, 130, 0.2);
}
.indigo-amber.dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.indigo-amber.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.indigo-amber.dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.indigo-amber.dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 224, 130, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.indigo-amber.dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.indigo-amber.dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 224, 130, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.indigo-amber.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.indigo-amber.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.indigo-amber.dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.indigo-amber.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.indigo-amber.dark .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #ffe082;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 224, 130, 0.4);
}
.indigo-amber.dark .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.indigo-amber.dark .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 224, 130, 0.3);
}
@media (hover: hover) {
  .indigo-amber.dark .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(255, 224, 130, 0.3);
  }
}
.indigo-amber.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(229, 115, 115, 0.2);
}
.indigo-amber.dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.indigo-amber.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.indigo-amber.dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.indigo-amber.dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(229, 115, 115, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.indigo-amber.dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.indigo-amber.dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(229, 115, 115, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.indigo-amber.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.indigo-amber.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.indigo-amber.dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.indigo-amber.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.indigo-amber.dark .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #e57373;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(229, 115, 115, 0.4);
}
.indigo-amber.dark .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.indigo-amber.dark .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(229, 115, 115, 0.3);
}
@media (hover: hover) {
  .indigo-amber.dark .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(229, 115, 115, 0.3);
  }
}
.indigo-amber.dark .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.indigo-amber.dark .mat-datepicker-toggle-active {
  color: #9fa8da;
}
.indigo-amber.dark .mat-datepicker-toggle-active.mat-accent {
  color: #ffe082;
}
.indigo-amber.dark .mat-datepicker-toggle-active.mat-warn {
  color: #e57373;
}
.indigo-amber.dark .mat-date-range-input-inner[disabled] {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-amber.dark .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.indigo-amber.dark .mat-divider {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark .mat-divider-vertical {
  border-right-color: rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark .mat-expansion-panel {
  background: #424242;
  color: white;
}
.indigo-amber.dark .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.indigo-amber.dark .mat-action-row {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .indigo-amber.dark .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .indigo-amber.dark .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(255, 255, 255, 0.04);
}
@media (hover: none) {
  .indigo-amber.dark .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: #424242;
  }
}
.indigo-amber.dark .mat-expansion-panel-header-title {
  color: white;
}
.indigo-amber.dark .mat-expansion-panel-header-description,
.indigo-amber.dark .mat-expansion-indicator::after {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-amber.dark .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(255, 255, 255, 0.3);
}
.indigo-amber.dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.indigo-amber.dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.indigo-amber.dark .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-amber.dark .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-amber.dark .mat-form-field.mat-focused .mat-form-field-label {
  color: #9fa8da;
}
.indigo-amber.dark .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #ffe082;
}
.indigo-amber.dark .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #e57373;
}
.indigo-amber.dark .mat-focused .mat-form-field-required-marker {
  color: #ffe082;
}
.indigo-amber.dark .mat-form-field-ripple {
  background-color: white;
}
.indigo-amber.dark .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #9fa8da;
}
.indigo-amber.dark .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #ffe082;
}
.indigo-amber.dark .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #e57373;
}
.indigo-amber.dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #9fa8da;
}
.indigo-amber.dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #ffe082;
}
.indigo-amber.dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #e57373;
}
.indigo-amber.dark .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #e57373;
}
.indigo-amber.dark .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.indigo-amber.dark .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #e57373;
}
.indigo-amber.dark .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.indigo-amber.dark .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #e57373;
}
.indigo-amber.dark .mat-error {
  color: #e57373;
}
.indigo-amber.dark .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-amber.dark .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-amber.dark .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.indigo-amber.dark .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.indigo-amber.dark .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.indigo-amber.dark .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.indigo-amber.dark .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.1);
}
.indigo-amber.dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.05);
}
.indigo-amber.dark .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(255, 255, 255, 0.5);
}
.indigo-amber.dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-amber.dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.indigo-amber.dark .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.3);
}
.indigo-amber.dark .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: white;
}
.indigo-amber.dark .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #9fa8da;
}
.indigo-amber.dark .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #ffe082;
}
.indigo-amber.dark .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #e57373;
}
.indigo-amber.dark .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #e57373;
}
.indigo-amber.dark .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-amber.dark .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.15);
}
.indigo-amber.dark .mat-icon.mat-primary {
  color: #9fa8da;
}
.indigo-amber.dark .mat-icon.mat-accent {
  color: #ffe082;
}
.indigo-amber.dark .mat-icon.mat-warn {
  color: #e57373;
}
.indigo-amber.dark .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-amber.dark .mat-input-element:disabled,
.indigo-amber.dark .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-amber.dark .mat-input-element {
  caret-color: #9fa8da;
}
.indigo-amber.dark .mat-input-element::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-amber.dark .mat-input-element::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-amber.dark .mat-input-element::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-amber.dark .mat-input-element:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-amber.dark .mat-input-element:not(.mat-native-select-inline) option {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark .mat-input-element:not(.mat-native-select-inline) option:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-amber.dark .mat-form-field.mat-accent .mat-input-element {
  caret-color: #ffe082;
}
.indigo-amber.dark .mat-form-field.mat-warn .mat-input-element,
.indigo-amber.dark .mat-form-field-invalid .mat-input-element {
  caret-color: #e57373;
}
.indigo-amber.dark .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #e57373;
}
.indigo-amber.dark .mat-list-base .mat-list-item {
  color: white;
}
.indigo-amber.dark .mat-list-base .mat-list-option {
  color: white;
}
.indigo-amber.dark .mat-list-base .mat-subheader {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-amber.dark .mat-list-base .mat-list-item-disabled {
  background-color: rgba(255, 255, 255, 0.12);
  color: rgba(255, 255, 255, 0.5);
}
.indigo-amber.dark .mat-list-option:hover, .indigo-amber.dark .mat-list-option:focus,
.indigo-amber.dark .mat-nav-list .mat-list-item:hover,
.indigo-amber.dark .mat-nav-list .mat-list-item:focus,
.indigo-amber.dark .mat-action-list .mat-list-item:hover,
.indigo-amber.dark .mat-action-list .mat-list-item:focus {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-amber.dark .mat-list-single-selected-option, .indigo-amber.dark .mat-list-single-selected-option:hover, .indigo-amber.dark .mat-list-single-selected-option:focus {
  background: rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark .mat-menu-panel {
  background: #424242;
}
.indigo-amber.dark .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.indigo-amber.dark .mat-menu-item {
  background: transparent;
  color: white;
}
.indigo-amber.dark .mat-menu-item[disabled],
.indigo-amber.dark .mat-menu-item[disabled] .mat-menu-submenu-icon,
.indigo-amber.dark .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-amber.dark .mat-menu-item .mat-icon-no-color,
.indigo-amber.dark .mat-menu-submenu-icon {
  color: white;
}
.indigo-amber.dark .mat-menu-item:hover:not([disabled]),
.indigo-amber.dark .mat-menu-item.cdk-program-focused:not([disabled]),
.indigo-amber.dark .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.indigo-amber.dark .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-amber.dark .mat-paginator {
  background: #424242;
}
.indigo-amber.dark .mat-paginator,
.indigo-amber.dark .mat-paginator-page-size .mat-select-trigger {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-amber.dark .mat-paginator-decrement,
.indigo-amber.dark .mat-paginator-increment {
  border-top: 2px solid white;
  border-right: 2px solid white;
}
.indigo-amber.dark .mat-paginator-first,
.indigo-amber.dark .mat-paginator-last {
  border-top: 2px solid white;
}
.indigo-amber.dark .mat-icon-button[disabled] .mat-paginator-decrement,
.indigo-amber.dark .mat-icon-button[disabled] .mat-paginator-increment,
.indigo-amber.dark .mat-icon-button[disabled] .mat-paginator-first,
.indigo-amber.dark .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(255, 255, 255, 0.5);
}
.indigo-amber.dark .mat-progress-bar-background {
  fill: #4c4e5b;
}
.indigo-amber.dark .mat-progress-bar-buffer {
  background-color: #4c4e5b;
}
.indigo-amber.dark .mat-progress-bar-fill::after {
  background-color: #9fa8da;
}
.indigo-amber.dark .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #645c45;
}
.indigo-amber.dark .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #645c45;
}
.indigo-amber.dark .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #ffe082;
}
.indigo-amber.dark .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #5d4141;
}
.indigo-amber.dark .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #5d4141;
}
.indigo-amber.dark .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #e57373;
}
.indigo-amber.dark .mat-progress-spinner circle, .indigo-amber.dark .mat-spinner circle {
  stroke: #9fa8da;
}
.indigo-amber.dark .mat-progress-spinner.mat-accent circle, .indigo-amber.dark .mat-spinner.mat-accent circle {
  stroke: #ffe082;
}
.indigo-amber.dark .mat-progress-spinner.mat-warn circle, .indigo-amber.dark .mat-spinner.mat-warn circle {
  stroke: #e57373;
}
.indigo-amber.dark .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.7);
}
.indigo-amber.dark .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #9fa8da;
}
.indigo-amber.dark .mat-radio-button.mat-primary .mat-radio-inner-circle,
.indigo-amber.dark .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .indigo-amber.dark .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .indigo-amber.dark .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #9fa8da;
}
.indigo-amber.dark .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ffe082;
}
.indigo-amber.dark .mat-radio-button.mat-accent .mat-radio-inner-circle,
.indigo-amber.dark .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .indigo-amber.dark .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .indigo-amber.dark .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #ffe082;
}
.indigo-amber.dark .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e57373;
}
.indigo-amber.dark .mat-radio-button.mat-warn .mat-radio-inner-circle,
.indigo-amber.dark .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .indigo-amber.dark .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .indigo-amber.dark .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #e57373;
}
.indigo-amber.dark .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.indigo-amber.dark .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.5);
}
.indigo-amber.dark .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.indigo-amber.dark .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(255, 255, 255, 0.5);
}
.indigo-amber.dark .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-amber.dark .mat-radio-button .mat-ripple-element {
  background-color: white;
}
.indigo-amber.dark .mat-select-value {
  color: white;
}
.indigo-amber.dark .mat-select-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-amber.dark .mat-select-disabled .mat-select-value {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-amber.dark .mat-select-arrow {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-amber.dark .mat-select-panel {
  background: #424242;
}
.indigo-amber.dark .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.indigo-amber.dark .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #9fa8da;
}
.indigo-amber.dark .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #ffe082;
}
.indigo-amber.dark .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #e57373;
}
.indigo-amber.dark .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #e57373;
}
.indigo-amber.dark .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-amber.dark .mat-drawer-container {
  background-color: #303030;
  color: white;
}
.indigo-amber.dark .mat-drawer {
  background-color: #424242;
  color: white;
}
.indigo-amber.dark .mat-drawer.mat-drawer-push {
  background-color: #424242;
}
.indigo-amber.dark .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.indigo-amber.dark .mat-drawer-side {
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.indigo-amber.dark [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.indigo-amber.dark [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(189, 189, 189, 0.6);
}
.indigo-amber.dark .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #ffe082;
}
.indigo-amber.dark .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 224, 130, 0.54);
}
.indigo-amber.dark .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #ffe082;
}
.indigo-amber.dark .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #9fa8da;
}
.indigo-amber.dark .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(159, 168, 218, 0.54);
}
.indigo-amber.dark .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #9fa8da;
}
.indigo-amber.dark .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #e57373;
}
.indigo-amber.dark .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(229, 115, 115, 0.54);
}
.indigo-amber.dark .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #e57373;
}
.indigo-amber.dark .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: white;
}
.indigo-amber.dark .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #bdbdbd;
}
.indigo-amber.dark .mat-slide-toggle-bar {
  background-color: rgba(255, 255, 255, 0.5);
}
.indigo-amber.dark .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.indigo-amber.dark .mat-slider.mat-primary .mat-slider-track-fill,
.indigo-amber.dark .mat-slider.mat-primary .mat-slider-thumb,
.indigo-amber.dark .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #9fa8da;
}
.indigo-amber.dark .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(159, 168, 218, 0.2);
}
.indigo-amber.dark .mat-slider.mat-accent .mat-slider-track-fill,
.indigo-amber.dark .mat-slider.mat-accent .mat-slider-thumb,
.indigo-amber.dark .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #ffe082;
}
.indigo-amber.dark .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(255, 224, 130, 0.2);
}
.indigo-amber.dark .mat-slider.mat-warn .mat-slider-track-fill,
.indigo-amber.dark .mat-slider.mat-warn .mat-slider-thumb,
.indigo-amber.dark .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #e57373;
}
.indigo-amber.dark .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(229, 115, 115, 0.2);
}
.indigo-amber.dark .mat-slider:hover .mat-slider-track-background,
.indigo-amber.dark .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.indigo-amber.dark .mat-slider.mat-slider-disabled .mat-slider-track-background,
.indigo-amber.dark .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.indigo-amber.dark .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(255, 255, 255, 0.3);
}
.indigo-amber.dark .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.indigo-amber.dark .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.indigo-amber.dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: white;
}
.indigo-amber.dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.indigo-amber.dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(255, 255, 255, 0.3);
}
.indigo-amber.dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
  background-color: transparent;
}
.indigo-amber.dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .indigo-amber.dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.indigo-amber.dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .indigo-amber.dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.indigo-amber.dark .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(255, 255, 255, 0.7);
}
.indigo-amber.dark .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.indigo-amber.dark .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.indigo-amber.dark .mat-step-header.cdk-keyboard-focused, .indigo-amber.dark .mat-step-header.cdk-program-focused, .indigo-amber.dark .mat-step-header:hover:not([aria-disabled]), .indigo-amber.dark .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(255, 255, 255, 0.04);
}
.indigo-amber.dark .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .indigo-amber.dark .mat-step-header:hover {
    background: none;
  }
}
.indigo-amber.dark .mat-step-header .mat-step-label,
.indigo-amber.dark .mat-step-header .mat-step-optional {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-amber.dark .mat-step-header .mat-step-icon {
  background-color: rgba(255, 255, 255, 0.7);
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark .mat-step-header .mat-step-icon-selected,
.indigo-amber.dark .mat-step-header .mat-step-icon-state-done,
.indigo-amber.dark .mat-step-header .mat-step-icon-state-edit {
  background-color: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark .mat-step-header.mat-accent .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark .mat-step-header.mat-accent .mat-step-icon-selected,
.indigo-amber.dark .mat-step-header.mat-accent .mat-step-icon-state-done,
.indigo-amber.dark .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #ffe082;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark .mat-step-header.mat-warn .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark .mat-step-header.mat-warn .mat-step-icon-selected,
.indigo-amber.dark .mat-step-header.mat-warn .mat-step-icon-state-done,
.indigo-amber.dark .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #e57373;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #e57373;
}
.indigo-amber.dark .mat-step-header .mat-step-label.mat-step-label-active {
  color: white;
}
.indigo-amber.dark .mat-step-header .mat-step-label.mat-step-label-error {
  color: #e57373;
}
.indigo-amber.dark .mat-stepper-horizontal, .indigo-amber.dark .mat-stepper-vertical {
  background-color: #424242;
}
.indigo-amber.dark .mat-stepper-vertical-line::before {
  border-left-color: rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark .mat-horizontal-stepper-header::before,
.indigo-amber.dark .mat-horizontal-stepper-header::after,
.indigo-amber.dark .mat-stepper-horizontal-line {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark .mat-sort-header-arrow {
  color: #c6c6c6;
}
.indigo-amber.dark .mat-tab-nav-bar,
.indigo-amber.dark .mat-tab-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark .mat-tab-group-inverted-header .mat-tab-nav-bar,
.indigo-amber.dark .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  border-bottom: none;
}
.indigo-amber.dark .mat-tab-label, .indigo-amber.dark .mat-tab-link {
  color: white;
}
.indigo-amber.dark .mat-tab-label.mat-tab-disabled, .indigo-amber.dark .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-amber.dark .mat-tab-header-pagination-chevron {
  border-color: white;
}
.indigo-amber.dark .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.5);
}
.indigo-amber.dark .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.indigo-amber.dark .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.indigo-amber.dark .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-amber.dark .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-amber.dark .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-amber.dark .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .indigo-amber.dark .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-amber.dark .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-amber.dark .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-amber.dark .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(197, 202, 233, 0.3);
}
.indigo-amber.dark .mat-tab-group.mat-primary .mat-ink-bar, .indigo-amber.dark .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #9fa8da;
}
.indigo-amber.dark .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .indigo-amber.dark .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .indigo-amber.dark .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .indigo-amber.dark .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-amber.dark .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-amber.dark .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-amber.dark .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .indigo-amber.dark .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-amber.dark .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-amber.dark .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-amber.dark .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 236, 179, 0.3);
}
.indigo-amber.dark .mat-tab-group.mat-accent .mat-ink-bar, .indigo-amber.dark .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #ffe082;
}
.indigo-amber.dark .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .indigo-amber.dark .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .indigo-amber.dark .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .indigo-amber.dark .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-amber.dark .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-amber.dark .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-amber.dark .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .indigo-amber.dark .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-amber.dark .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-amber.dark .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-amber.dark .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.indigo-amber.dark .mat-tab-group.mat-warn .mat-ink-bar, .indigo-amber.dark .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #e57373;
}
.indigo-amber.dark .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .indigo-amber.dark .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .indigo-amber.dark .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .indigo-amber.dark .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-amber.dark .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-amber.dark .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-amber.dark .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .indigo-amber.dark .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-amber.dark .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-amber.dark .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-amber.dark .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(197, 202, 233, 0.3);
}
.indigo-amber.dark .mat-tab-group.mat-background-primary > .mat-tab-header, .indigo-amber.dark .mat-tab-group.mat-background-primary > .mat-tab-link-container, .indigo-amber.dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .indigo-amber.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .indigo-amber.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .indigo-amber.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #9fa8da;
}
.indigo-amber.dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .indigo-amber.dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .indigo-amber.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .indigo-amber.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .indigo-amber.dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .indigo-amber.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .indigo-amber.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.indigo-amber.dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.indigo-amber.dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.indigo-amber.dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.indigo-amber.dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .indigo-amber.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.indigo-amber.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.indigo-amber.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.indigo-amber.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.indigo-amber.dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .indigo-amber.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.indigo-amber.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.indigo-amber.dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.indigo-amber.dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.indigo-amber.dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .indigo-amber.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.indigo-amber.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.indigo-amber.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.indigo-amber.dark .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-amber.dark .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-amber.dark .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-amber.dark .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .indigo-amber.dark .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-amber.dark .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-amber.dark .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-amber.dark .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 236, 179, 0.3);
}
.indigo-amber.dark .mat-tab-group.mat-background-accent > .mat-tab-header, .indigo-amber.dark .mat-tab-group.mat-background-accent > .mat-tab-link-container, .indigo-amber.dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .indigo-amber.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .indigo-amber.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .indigo-amber.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #ffe082;
}
.indigo-amber.dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .indigo-amber.dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .indigo-amber.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .indigo-amber.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .indigo-amber.dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .indigo-amber.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .indigo-amber.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.indigo-amber.dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.indigo-amber.dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.indigo-amber.dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.indigo-amber.dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .indigo-amber.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.indigo-amber.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.indigo-amber.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.indigo-amber.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.indigo-amber.dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .indigo-amber.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.indigo-amber.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.indigo-amber.dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.indigo-amber.dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.indigo-amber.dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .indigo-amber.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.indigo-amber.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.indigo-amber.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.indigo-amber.dark .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-amber.dark .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-amber.dark .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-amber.dark .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .indigo-amber.dark .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-amber.dark .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-amber.dark .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-amber.dark .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.indigo-amber.dark .mat-tab-group.mat-background-warn > .mat-tab-header, .indigo-amber.dark .mat-tab-group.mat-background-warn > .mat-tab-link-container, .indigo-amber.dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .indigo-amber.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .indigo-amber.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .indigo-amber.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #e57373;
}
.indigo-amber.dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .indigo-amber.dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .indigo-amber.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .indigo-amber.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .indigo-amber.dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .indigo-amber.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .indigo-amber.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.indigo-amber.dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.indigo-amber.dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.indigo-amber.dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.indigo-amber.dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .indigo-amber.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.indigo-amber.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.indigo-amber.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.indigo-amber.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.indigo-amber.dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .indigo-amber.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.indigo-amber.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.indigo-amber.dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.indigo-amber.dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.indigo-amber.dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .indigo-amber.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.indigo-amber.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.indigo-amber.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.indigo-amber.dark .mat-toolbar {
  background: #212121;
  color: white;
}
.indigo-amber.dark .mat-toolbar.mat-primary {
  background: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark .mat-toolbar.mat-accent {
  background: #ffe082;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark .mat-toolbar.mat-warn {
  background: #e57373;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark .mat-toolbar .mat-form-field-underline,
.indigo-amber.dark .mat-toolbar .mat-form-field-ripple,
.indigo-amber.dark .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.indigo-amber.dark .mat-toolbar .mat-form-field-label,
.indigo-amber.dark .mat-toolbar .mat-focused .mat-form-field-label,
.indigo-amber.dark .mat-toolbar .mat-select-value,
.indigo-amber.dark .mat-toolbar .mat-select-arrow,
.indigo-amber.dark .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.indigo-amber.dark .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.indigo-amber.dark .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.indigo-amber.dark .mat-tree {
  background: #424242;
}
.indigo-amber.dark .mat-tree-node,
.indigo-amber.dark .mat-nested-tree-node {
  color: white;
}
.indigo-amber.dark .mat-snack-bar-container {
  color: rgba(0, 0, 0, 0.87);
  background: #fafafa;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.indigo-amber.dark .mat-simple-snackbar-action {
  color: inherit;
}
.indigo-amber.dark *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.indigo-amber.dark *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
.indigo-amber.dark *::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}
.indigo-amber.dark .toolbar-line.container-ou {
  background: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark .toolbar-line.container-persona {
  background: #9fa8da !important;
}
.indigo-amber.dark .toolbar-line.container-persona .persona-title {
  background: #9fa8da;
}
.indigo-amber.dark .toolbar-line.container-persona .invisible-container.persona-selector {
  background: #9fa8da !important;
}
.indigo-amber.dark button.mat-icon-button:not([disabled]):hover {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-amber.dark .side-bar {
  background: black;
}
.indigo-amber.dark .icon-stacked {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-amber.dark .icon-stacked mat-icon + mat-icon {
  text-shadow: -1px 1px 0px #9fa8da, 1px -1px 0px #9fa8da;
}
.indigo-amber.dark .divider-vert {
  background: rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark .avatar {
  background: #304ffe !important;
}
.indigo-amber.dark ngx-avatar .avatar-content {
  background: #304ffe !important;
}
.indigo-amber.dark .mat-loading-window {
  background: #424242;
}
.indigo-amber.dark .window-spinner {
  background: #424242;
}
.indigo-amber.dark .load-window {
  background: #424242;
}
@media (min-width: 600px) and (max-width: 1300px) {
  .indigo-amber.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .load-window,
.indigo-amber.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .load-window.new {
    background: #424242 !important;
  }
  .indigo-amber.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card {
    background: #424242 !important;
  }
  .indigo-amber.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .inbox-container mat-toolbar {
    background: #9fa8da;
    color: rgba(0, 0, 0, 0.87);
  }
  .indigo-amber.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .inbox-container mat-list-item.mat-list-item {
    border: 1px solid rgba(255, 255, 255, 0.12);
  }
  .indigo-amber.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .detail {
    background: #424242 !important;
  }
  .indigo-amber.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .detail mat-toolbar {
    background: #424242 !important;
  }
  .indigo-amber.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .main.w-detail + .new-detail-container .load-window {
    background: #303030 !important;
  }
  .indigo-amber.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .main.w-detail + .new-detail-container:before {
    background: rgba(0, 0, 0, 0.6);
  }
  .indigo-amber.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .main.side-open + .side {
    border-left: 1px solid rgba(255, 255, 255, 0.12);
  }
  .indigo-amber.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .side .container .mat-list-base {
    background: #424242 !important;
    border: 1px solid rgba(255, 255, 255, 0.12);
  }
  .indigo-amber.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .new-table .mat-header-row,
.indigo-amber.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .new-table .mat-table,
.indigo-amber.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .document-table .mat-header-row,
.indigo-amber.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .document-table .mat-table,
.indigo-amber.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .status-table .mat-header-row,
.indigo-amber.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .status-table .mat-table {
    background: #424242 !important;
  }
  .indigo-amber.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .audit-filter .filter-container.is-floating {
    background: #303030 !important;
    border: 1px solid rgba(255, 255, 255, 0.12);
  }
  .indigo-amber.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .audit-filter .filter-container.is-floating .form-body {
    background: #303030 !important;
  }
  .indigo-amber.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .audit-result {
    background: #424242;
  }
  .indigo-amber.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .audit-detail-container {
    background: #303030 !important;
  }
}
@media (max-width: 599px) {
  .indigo-amber.dark app-document-detail .mat-toolbar {
    background: #304ffe !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .indigo-amber.dark app-document-detail .mat-toolbar .mat-toolbar-row .toolbar-actions {
    background: #304ffe !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .indigo-amber.dark .login-card {
    background: #424242 !important;
  }
  .indigo-amber.dark .inbox-toolbar {
    background: #304ffe !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .indigo-amber.dark .detail {
    background: #424242 url("../img/spinner_mobile.gif") no-repeat center center !important;
  }
  .indigo-amber.dark .toolbar-line-container .toolbar-line:first-child {
    background: #304ffe !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .indigo-amber.dark .toolbar-actions {
    background: #304ffe !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .indigo-amber.dark mat-drawer.side-nav.enter {
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(255, 255, 255, 0.12) !important;
  }
  .indigo-amber.dark .side-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0) !important;
  }
  .indigo-amber.dark .filter {
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.55) 16%, rgba(0, 0, 0, 0) 100%) !important;
  }
  .indigo-amber.dark button.side-open-mobile {
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .indigo-amber.dark .side-nav .side-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0);
  }
  .indigo-amber.dark .side-nav .filter {
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.55) 16%, rgba(0, 0, 0, 0) 100%);
  }
  .indigo-amber.dark .side-nav .side-nav-container .links {
    background: #424242;
    border-top: 1px solid rgba(255, 255, 255, 0.12);
  }
  .indigo-amber.dark .side-nav .side-nav-container .version {
    background: #424242 !important;
    border-top: 1px solid rgba(255, 255, 255, 0.12);
  }
  .indigo-amber.dark app-file-document-view .view-container {
    background: #424242 !important;
  }
  .indigo-amber.dark app-file-document-view .view-container .side-bar {
    background: #304ffe !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .indigo-amber.dark app-file-document-view .view-container .view-content app-pdf-wrapper.floating {
    background: #424242;
  }
  .indigo-amber.dark app-file-document-view .view-container .view-content .upload-indications {
    background: black;
  }
  .indigo-amber.dark app-file-document-view .view-container .view-content app-file-document-sign .sign-cycle mat-horizontal-stepper.mat-stepper-horizontal {
    background-color: rgba(0, 0, 0, 0) !important;
  }
  .indigo-amber.dark app-file-document-view .view-container .view-content app-file-document-sign .sign-cycle .sign-cycle-content .cycle-content-action .mat-ripple-element {
    background-color: rgba(255, 255, 255, 0.1) !important;
  }
  .indigo-amber.dark app-pending .floating-container .inbox-container .inbox-toolbar .mat-toolbar-row .mat-avatar h3 {
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .indigo-amber.dark app-pending .floating-container .detail {
    background: #424242 url("../img/spinner_mobile.gif") no-repeat center center !important;
  }
  .indigo-amber.dark app-document-sign-massive .inbox-container .pending-container app-chapa.minimized .info-empty {
    background: #ffe082;
  }
  .indigo-amber.dark app-document-sign-massive .inbox-container .pending-container app-chapa.minimized .info-empty > span h2 {
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .indigo-amber.dark app-document-sign-massive .inbox-container .signer.mobile {
    border-top: 1px solid rgba(255, 255, 255, 0.12);
    background: #424242;
  }
  .indigo-amber.dark app-document-sign-massive .detail app-file-document-detail .view-container .mat-toolbar .mat-toolbar-row .view-actions {
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .indigo-amber.dark app-add-documentation .floating-container .employee-add-documentation .inbox-container .inbox-toolbar .mat-toolbar-row .mat-avatar h3 {
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .indigo-amber.dark app-add-documentation .floating-container .employee-add-documentation .detail {
    background: #424242 url("../img/spinner_mobile.gif") no-repeat center center !important;
  }
  .indigo-amber.dark app-add-documentation .floating-container .employee-add-documentation .detail app-file-document-view .view-container .view-content .new-container app-file-document-upload {
    background: #424242;
  }
}
.indigo-amber.dark .mat-card .mat-drawer-container {
  background: #424242 !important;
}
.indigo-amber.dark .mat-drawer-container {
  background: #212121 !important;
}
.indigo-amber.dark .mat-list .mat-list-item.finished-process-inbox.active {
  background: #009688 !important;
}
.indigo-amber.dark .mat-list .mat-list-item.finished-process-inbox .mat-icon {
  background: #009688;
}
.indigo-amber.dark .mat-list .mat-list-item.erased-process-inbox.active {
  background: #837C80 !important;
}
.indigo-amber.dark .mat-list .mat-list-item.limbo-process-inbox.active {
  background: #ffc107 !important;
}
.indigo-amber.dark .mat-list .mat-list-item.limbo-process-inbox .mat-icon {
  background: #ffc107;
}
.indigo-amber.dark .mat-list .mat-list-item.in-process-inbox.active {
  background: #ffc107 !important;
}
.indigo-amber.dark .mat-list .mat-list-item.in-process-inbox .mat-icon {
  background: #ffc107;
}
.indigo-amber.dark .mat-list .mat-list-item.error-process-inbox.active {
  background: #e91e63 !important;
}
.indigo-amber.dark .mat-list .mat-list-item.error-process-inbox .mat-icon {
  background: #e91e63;
}
.indigo-amber.dark .mat-list .mat-list-item.active .mat-line b {
  color: #9fa8da;
}
.indigo-amber.dark .mat-list .mat-list-item.active .mat-list-avatar {
  color: #9fa8da;
}
.indigo-amber.dark .mat-list .mat-list-item:hover:not(.active) {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-amber.dark span.mat-badge-content {
  color: #e57373 !important;
}
.indigo-amber.dark mat-expansion-panel.mat-expanded {
  background: #303030 !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark mat-expansion-panel.virtual-scrolling .mat-expansion-panel-content .get-more {
  color: #9fa8da;
}
.indigo-amber.dark mat-expansion-panel.virtual-scrolling .mat-expansion-panel-content app-cs-paginator.get-more {
  background: #424242;
}
.indigo-amber.dark mat-expansion-panel-header.mat-expanded {
  background: #424242 !important;
}
.indigo-amber.dark .bottom-container {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark .load-more * {
  color: #9fa8da;
}
.indigo-amber.dark .welcome-card {
  background: #424242 !important;
}
.indigo-amber.dark .welcome-card .img-divider {
  background: #304ffe;
}
.indigo-amber.dark .mat-header-row {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  background: #424242;
}
.indigo-amber.dark .mat-row {
  background: #424242;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.indigo-amber.dark .mat-row .mat-cell.status-cell mat-icon.pending {
  color: #ffb74d !important;
}
.indigo-amber.dark .mat-row .mat-cell.status-cell mat-icon.ok {
  color: #4db6ac !important;
}
.indigo-amber.dark .mat-row .mat-cell.status-cell mat-icon.not-ok {
  color: #ba68c8 !important;
}
.indigo-amber.dark .mat-row .mat-cell.status-cell mat-icon.cancelled {
  color: #795548 !important;
}
.indigo-amber.dark .mat-row .mat-cell.status-cell mat-icon.rejected {
  color: #df4d4c !important;
}
.indigo-amber.dark .mat-row .mat-cell.status-cell mat-icon.draft {
  color: #dedede !important;
}
.indigo-amber.dark .mat-row.active, .indigo-amber.dark .mat-row.active:hover {
  background: #9fa8da !important;
}
.indigo-amber.dark .mat-row.active div, .indigo-amber.dark .mat-row.active:hover div {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark .mat-row.active .mat-cell.status-cell mat-icon.pending, .indigo-amber.dark .mat-row.active .mat-cell.status-cell mat-icon.ok, .indigo-amber.dark .mat-row.active .mat-cell.status-cell mat-icon.not-ok, .indigo-amber.dark .mat-row.active:hover .mat-cell.status-cell mat-icon.pending, .indigo-amber.dark .mat-row.active:hover .mat-cell.status-cell mat-icon.ok, .indigo-amber.dark .mat-row.active:hover .mat-cell.status-cell mat-icon.not-ok {
  text-shadow: -1px 1px 0 rgba(0, 0, 0, 0.87), 1px 1px 0 rgba(0, 0, 0, 0.87), 1px -1px 0 rgba(0, 0, 0, 0.87), -1px -1px 0 rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark .mat-row.active .sticky-actions button, .indigo-amber.dark .mat-row.active:hover .sticky-actions button {
  background: #9fa8da !important;
}
.indigo-amber.dark .mat-row.active .sticky-actions button.ok mat-icon, .indigo-amber.dark .mat-row.active .sticky-actions button.not-ok mat-icon, .indigo-amber.dark .mat-row.active:hover .sticky-actions button.ok mat-icon, .indigo-amber.dark .mat-row.active:hover .sticky-actions button.not-ok mat-icon {
  text-shadow: -1px 1px 0 rgba(0, 0, 0, 0.87), 1px 1px 0 rgba(0, 0, 0, 0.87), 1px -1px 0 rgba(0, 0, 0, 0.87), -1px -1px 0 rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark .mat-row:nth-child(odd) {
  background: #303030;
}
.indigo-amber.dark .mat-row.selected div {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark .mat-row.selected:nth-child(even) {
  background: #9fa8da !important;
}
.indigo-amber.dark .mat-row.selected:nth-child(even) .sticky-actions button {
  background: #9fa8da !important;
}
.indigo-amber.dark .mat-row.selected:nth-child(odd) {
  background: #c5cae9 !important;
}
.indigo-amber.dark .mat-row.selected:nth-child(odd) .sticky-actions button {
  background: #c5cae9 !important;
}
.indigo-amber.dark .mat-row.selected.active, .indigo-amber.dark .mat-row.selected.active:hover {
  background: #9fa8da !important;
}
.indigo-amber.dark .mat-row.selected.active .sticky-actions button, .indigo-amber.dark .mat-row.selected.active:hover .sticky-actions button {
  background: #9fa8da !important;
}
.indigo-amber.dark .mat-row:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-amber.dark .mat-row:hover .sticky-actions button {
  background: #424242 !important;
}
.indigo-amber.dark .mat-row.canceled * {
  color: #795548;
}
.indigo-amber.dark .mat-row.disabled div {
  color: #607D8B;
}
.indigo-amber.dark .mat-row.disabled.active, .indigo-amber.dark .mat-row.disabled.active:hover {
  background: #607D8B !important;
}
.indigo-amber.dark .mat-row.disabled.active div, .indigo-amber.dark .mat-row.disabled.active:hover div {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark .mat-row.disabled.active .sticky-actions button, .indigo-amber.dark .mat-row.disabled.active:hover .sticky-actions button {
  background: #607D8B !important;
}
.indigo-amber.dark .mat-row.disabled.selected.active:hover .sticky-actions button {
  background: #607D8B !important;
}
.indigo-amber.dark .mat-row .sticky-actions button.ok mat-icon {
  color: #4db6ac !important;
}
.indigo-amber.dark .mat-row .sticky-actions button.not-ok mat-icon {
  color: #ba68c8 !important;
}
.indigo-amber.dark .status-table .mat-header-row {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  background: #424242;
}
.indigo-amber.dark .icon-info-off,
.indigo-amber.dark .certificate-off {
  color: #607D8B;
}
.indigo-amber.dark .documents-search-table .mat-header-row {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  background: #424242;
}
.indigo-amber.dark mat-toolbar.employee-search-sub-toolbar {
  background: #424242;
}
.indigo-amber.dark .mat-search-field {
  border: 1px solid rgba(255, 255, 255, 0.12);
  background: black;
}
.indigo-amber.dark .mat-search-field input[type=search] {
  background: rgba(0, 0, 0, 0);
}
.indigo-amber.dark .mat-search-field input[type=search]::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-amber.dark .search-adv-menu .mat-search-field {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark mat-toolbar.document-search-sub-toolbar {
  background: #424242;
}
.indigo-amber.dark app-advanced-employee-search .mat-chip {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-amber.dark .side-nav .side-trigger {
  background: #424242;
}
.indigo-amber.dark .side-nav .menu-trigger mat-icon {
  color: rgba(255, 255, 255, 0.7);
  background: #424242;
}
.indigo-amber.dark .side-nav .side-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0);
}
.indigo-amber.dark .side-nav .filter {
  background: #424242;
}
.indigo-amber.dark .side-nav .side-nav-container .links mat-icon span.mat-badge-content {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-amber.dark .side-nav .side-nav-container .mat-list-base .mat-list-item:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-amber.dark .side-nav .side-nav-container .mat-list-base .mat-list-item.active {
  background: #9fa8da !important;
}
.indigo-amber.dark .side-nav .side-nav-container .mat-list-base .mat-list-item.active * {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark .side-nav .side-nav-container .mat-list-base .mat-list-item.active .mat-list-item.active:hover {
  background: #9fa8da !important;
}
.indigo-amber.dark .side-nav .side-nav-container .mat-list-base .mat-list-item.active .mat-list-item.active:hover * {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark .side-nav.open .side-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark .side-nav.open .filter {
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.55) 16%, rgba(0, 0, 0, 0) 100%);
}
.indigo-amber.dark .side-nav.open .side-nav-container .links {
  background: #424242;
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark .side-nav.open .side-nav-container .version {
  background: #424242 !important;
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark .side-nav.enter .side-nav-container .links {
  background: #424242;
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark .side-nav.enter .side-nav-container .version {
  background: #424242 !important;
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark .mat-menu-panel:not(.metadata-radio-menu) .navigation-list .mat-list-item-content {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-amber.dark table.mat-calendar-table {
  background: #424242 !important;
}
.indigo-amber.dark .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-amber.dark .mat-calendar-arrow {
  border-top-color: #424242 !important;
}
.indigo-amber.dark .mat-datepicker-content .mat-calendar-next-button,
.indigo-amber.dark .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-amber.dark .mat-calendar-header {
  background: #304ffe !important;
}
.indigo-amber.dark mat-datepicker-content {
  background-color: #304ffe !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-amber.dark .mat-calendar-content {
  background: #424242 !important;
}
.indigo-amber.dark .mat-simple-snackbar-action button.mat-button {
  color: #ff9800;
}
.indigo-amber.dark .mat-simple-snackbar-action .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.indigo-amber.dark mat-bottom-sheet-container mat-toolbar {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12) !important;
}
.indigo-amber.dark app-generic-bottom-sheet h4.accent {
  color: #ffe082;
}
.indigo-amber.dark app-generic-bottom-sheet .verification-code {
  background: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark app-generic-bottom-sheet .verification-code h2 {
  background: #9fa8da;
}
.indigo-amber.dark app-generic-bottom-sheet .load-window.message .info-container h3.primary-text {
  color: #9fa8da;
}
.indigo-amber.dark app-generic-bottom-sheet .bottom_sheet-content.ok .bottom_sheet-title .bottom_sheet-icon,
.indigo-amber.dark app-generic-bottom-sheet .bottom_sheet-content.ok .bottom_sheet-title h3 {
  color: #009688;
}
.indigo-amber.dark app-generic-bottom-sheet .bottom_sheet-content.ok .bottom_sheet-main_btn:not(:disabled) {
  background: #009688;
  color: #fff;
}
.indigo-amber.dark app-generic-bottom-sheet .bottom_sheet-content.not-ok .bottom_sheet-title .bottom_sheet-icon,
.indigo-amber.dark app-generic-bottom-sheet .bottom_sheet-content.not-ok .bottom_sheet-title h3 {
  color: #9C27B0;
}
.indigo-amber.dark app-generic-bottom-sheet .bottom_sheet-content.not-ok .bottom_sheet-main_btn:not(:disabled) {
  background: #9C27B0;
  color: #fff;
}
.indigo-amber.dark app-generic-bottom-sheet .bottom_sheet-content.cancel .bottom_sheet-title .bottom_sheet-icon,
.indigo-amber.dark app-generic-bottom-sheet .bottom_sheet-content.cancel .bottom_sheet-title h3 {
  color: #795548;
}
.indigo-amber.dark app-generic-bottom-sheet .bottom_sheet-content.cancel .bottom_sheet-main_btn:not(:disabled) {
  background: #795548;
  color: #fff;
}
.indigo-amber.dark app-generic-bottom-sheet .bottom_sheet-content .bottom_sheet_exp_btn {
  background-color: #9fa8da;
  color: #fff;
}
.indigo-amber.dark app-generic-bottom-sheet .bottom_sheet-content .bottom_sheet_canc_btn {
  background-color: lightgrey;
  color: black;
}
.indigo-amber.dark app-generic-bottom-sheet .bottom_sheet-content .bottom_sheet-exp-h3 {
  color: #9fa8da;
  margin-bottom: -10px !important;
}
.indigo-amber.dark app-generic-bottom-sheet .bottom_sheet-content .mat-input-element-export {
  display: flex !important;
  padding-left: 15px;
}
.indigo-amber.dark app-generic-bottom-sheet .bottom_sheet-content .mat-form-field-infix-export-right {
  width: 100% !important;
  padding: 0px !important;
  display: flex !important;
}
.indigo-amber.dark app-generic-bottom-sheet .bottom_sheet-content .mat-form-field-infix-export-left {
  width: 100% !important;
  padding: 0px;
  display: flex !important;
}
.indigo-amber.dark app-generic-bottom-sheet .bottom_sheet-content .mat-form-field-infix {
  display: flex;
}
.indigo-amber.dark app-generic-bottom-sheet .bottom_sheet-content .mat-checkbox-layout .mat-checkbox-label {
  color: #ffe082;
}
.indigo-amber.dark app-generic-bottom-sheet .bottom_sheet-content .choose-forms {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  margin-left: 10px !important;
}
.indigo-amber.dark app-generic-bottom-sheet .bottom_sheet-content .card-container {
  max-height: 150px;
  overflow-y: auto;
}
.indigo-amber.dark .mat-list .mat-list-item.active .mat-line {
  color: white;
}
.indigo-amber.dark .mat-list .mat-list-item.active .mat-line b {
  color: #9fa8da;
}
.indigo-amber.dark .mat-list .mat-list-item.active .mat-list-avatar {
  color: #9fa8da;
}
.indigo-amber.dark .mat-list .mat-list-item .mat-line {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-amber.dark .mat-list .mat-list-item .mat-list-avatar {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-amber.dark .mat-list-item:hover:not(.active) {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-amber.dark mat-list-item:nth-child(even),
.indigo-amber.dark app-file-document-inbox-item:nth-child(even) mat-list-item,
.indigo-amber.dark app-file-document-inbox-item-documentation:nth-child(even) mat-list-item {
  background: #303030 !important;
}
.indigo-amber.dark mat-list-item:nth-child(odd),
.indigo-amber.dark app-file-document-inbox-item:nth-child(odd) mat-list-item,
.indigo-amber.dark app-file-document-inbox-item-documentation:nth-child(odd) mat-list-item {
  background: #424242 !important;
}
.indigo-amber.dark .mat-list-avatar.mat-icon {
  background: black;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark .card-filter {
  background: #424242;
}
.indigo-amber.dark .login mat-horizontal-stepper {
  background: #424242 !important;
}
.indigo-amber.dark .login .login-card {
  background: #424242 !important;
}
.indigo-amber.dark .login .login-card .mat-card-content .mat-card-footer a[color=accent] {
  color: #ffe082;
}
.indigo-amber.dark .login .help-stepper h3.align-16 {
  color: #ffe082;
}
.indigo-amber.dark .login .help-stepper .help-options mat-icon {
  color: #ffe082;
}
.indigo-amber.dark .ux-modal {
  background: rgba(0, 0, 0, 0.32);
}
.indigo-amber.dark .ux-modal .card {
  background: #424242;
}
.indigo-amber.dark .main-title {
  background: #607D8B;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark .sub-title {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-amber.dark .card-footer a {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark .profile-toolbar {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark app-profile .logout-btn:hover {
  background: black;
}
.indigo-amber.dark app-profile .cropper {
  background: #424242;
}
.indigo-amber.dark app-profile .cropper .cropper {
  background: unset;
}
.indigo-amber.dark app-add-documentation .employee-add-documentation .inbox-toolbar {
  background: #303030;
}
.indigo-amber.dark app-add-documentation .employee-add-documentation .inbox-toolbar .toolbar-line mat-icon {
  color: #9fa8da;
}
.indigo-amber.dark app-add-documentation .employee-add-documentation .inbox-toolbar .toolbar-line h3 {
  color: #9fa8da;
}
.indigo-amber.dark app-add-documentation .employee-add-documentation mat-tab-group {
  background: #303030;
}
.indigo-amber.dark app-add-documentation .employee-add-documentation mat-tab-group mat-tab-header .mat-tab-label-active {
  color: #9fa8da;
}
.indigo-amber.dark app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content:hover {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(255, 255, 255, 0.12) !important;
}
.indigo-amber.dark app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content.selected {
  border-left: 6px solid #9fa8da !important;
}
.indigo-amber.dark app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content.selected mat-icon {
  color: #9fa8da !important;
}
.indigo-amber.dark app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content.selected .item-data {
  color: white !important;
}
.indigo-amber.dark app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content mat-icon {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-amber.dark app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content .item-data {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-amber.dark app-add-documentation .employee-add-documentation .detail app-file-document-view .new-container app-file-document-upload .metadata-list .mat-subheader {
  color: rgba(255, 255, 255, 0.7);
  background: #424242 !important;
}
.indigo-amber.dark app-pending .inbox-toolbar {
  background: #303030;
}
.indigo-amber.dark app-pending .pending-container {
  background: #303030;
}
.indigo-amber.dark app-pending .pending-container .pending-items .pending-item .content:hover {
  background: rgba(255, 255, 255, 0.04) !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(255, 255, 255, 0.12) !important;
}
.indigo-amber.dark app-pending .pending-container .pending-items .pending-item .content.selected {
  border-left: 6px solid #ffe082 !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(255, 255, 255, 0.12) !important;
}
.indigo-amber.dark app-pending .pending-container .pending-items .pending-item .content.selected mat-icon {
  color: #ffe082 !important;
}
.indigo-amber.dark app-pending .pending-container .pending-items .pending-item .content.selected .item-data {
  color: white !important;
}
.indigo-amber.dark app-pending .pending-container .pending-items .pending-item .content mat-icon {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-amber.dark app-pending .pending-container .pending-items .pending-item .content .item-data {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-amber.dark app-document-sign-massive .inbox-container .inbox-toolbar {
  background: #303030;
}
.indigo-amber.dark app-document-sign-massive .inbox-container .pending-container {
  background: #303030;
}
.indigo-amber.dark app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content:hover {
  background: rgba(255, 255, 255, 0.04) !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(255, 255, 255, 0.12) !important;
}
.indigo-amber.dark app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content.selected {
  border-left: 6px solid #ffe082 !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(255, 255, 255, 0.12) !important;
}
.indigo-amber.dark app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content.selected mat-icon {
  color: #ffe082 !important;
}
.indigo-amber.dark app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content.selected .item-data {
  color: white !important;
}
.indigo-amber.dark app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content mat-icon {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-amber.dark app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content .item-data {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-amber.dark app-document-sign-massive .detail app-file-document-detail .view-container {
  background: #607D8B;
}
.indigo-amber.dark app-document-sign-massive .detail app-file-document-detail .view-container .mat-toolbar {
  background: #607D8B;
  color: rgba(0, 0, 0, 0.87);
  background: #607D8B;
}
.indigo-amber.dark app-document-sign-massive .detail app-chapa.minimized .info-empty {
  background: #ffe082;
}
.indigo-amber.dark app-document-sign-massive .detail app-chapa.minimized .info-empty > span mat-icon {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-amber.dark app-document-sign-massive .detail app-chapa.minimized .info-empty > span h2 {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-amber.dark app-document-sign-massive .signer.desktop {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark app-document-sign-massive .signer .signer-btn {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-amber.dark app-document-sign-massive .signer .signer-btn[disabled] {
  background-color: rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark app-document-detail app-sign-document .firmador button.mat-raised-button.mat-accent {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-amber.dark mat-toolbar-row.firmado-conforme .mat-icon {
  background: #4caf50;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark mat-toolbar-row.firmado-no-conforme .mat-icon {
  background: #e57373;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark .secondary-toolbar {
  background: #424242;
}
.indigo-amber.dark .new-detail-container {
  border-left: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark .new-detail-container.cancelled .detail_head-body {
  background: #607D8B !important;
}
.indigo-amber.dark .new-detail-container .employee-detail-toolbar {
  background: black;
}
.indigo-amber.dark .new-detail-container .employee-detail-subbar {
  background: #424242;
}
.indigo-amber.dark app-employee-detail .load-window,
.indigo-amber.dark app-employee-rrhh-detail .load-window {
  background: #303030;
}
.indigo-amber.dark app-employee-detail .employee_detail-container,
.indigo-amber.dark app-employee-rrhh-detail .employee_detail-container {
  background: #303030;
}
.indigo-amber.dark app-employee-detail .employee_detail-container.is-editing .edition-line,
.indigo-amber.dark app-employee-rrhh-detail .employee_detail-container.is-editing .edition-line {
  background: black;
}
.indigo-amber.dark app-employee-detail .employee_detail-container .detail_head-body,
.indigo-amber.dark app-employee-rrhh-detail .employee_detail-container .detail_head-body {
  background: #9fa8da;
}
.indigo-amber.dark app-employee-detail .employee_detail-container .detail_head-body .head_body-status .icon-stacked + mat-icon,
.indigo-amber.dark app-employee-rrhh-detail .employee_detail-container .detail_head-body .head_body-status .icon-stacked + mat-icon {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-amber.dark app-employee-detail .employee_detail-container .detail_head-body .head_body-title .body_title-name,
.indigo-amber.dark app-employee-rrhh-detail .employee_detail-container .detail_head-body .head_body-title .body_title-name {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark app-employee-detail .employee_detail-container .detail_head-body .head_body-title .body_title-user,
.indigo-amber.dark app-employee-rrhh-detail .employee_detail-container .detail_head-body .head_body-title .body_title-user {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark app-employee-detail .employee_detail-container .data .data-line,
.indigo-amber.dark app-employee-rrhh-detail .employee_detail-container .data .data-line {
  background: #424242;
}
.indigo-amber.dark app-employee-detail .employee_detail-container .data .data-line .icon-complex mat-icon.complex,
.indigo-amber.dark app-employee-rrhh-detail .employee_detail-container .data .data-line .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #424242, 0 -2px 0px #424242, 2px 0 0px #424242, 0 2px 0px #424242, -2px -2px 0px #424242, 2px 2px 0px #424242, 2px -2px 0px #424242, -2px 2px 0px #424242;
}
.indigo-amber.dark app-employee-detail .employee_detail-container .data .data-line .line_text-main,
.indigo-amber.dark app-employee-rrhh-detail .employee_detail-container .data .data-line .line_text-main {
  color: #9fa8da;
}
.indigo-amber.dark app-employee-detail .employee_detail-container .data .data-line.clickable:hover,
.indigo-amber.dark app-employee-rrhh-detail .employee_detail-container .data .data-line.clickable:hover {
  background: #303030 !important;
}
.indigo-amber.dark app-employee-detail .employee_detail-container .data .data-line button.mat-icon-button,
.indigo-amber.dark app-employee-rrhh-detail .employee_detail-container .data .data-line button.mat-icon-button {
  background: black;
}
.indigo-amber.dark app-employee-detail .employee_detail-container .employee-title .title-text,
.indigo-amber.dark app-employee-rrhh-detail .employee_detail-container .employee-title .title-text {
  color: #9fa8da;
}
.indigo-amber.dark app-employee-detail .employee_detail-container .employee-title .title-text mat-icon,
.indigo-amber.dark app-employee-rrhh-detail .employee_detail-container .employee-title .title-text mat-icon {
  color: #9fa8da;
}
.indigo-amber.dark app-employee-detail .employee_detail-container .employee-title .title-text .title_text-main,
.indigo-amber.dark app-employee-rrhh-detail .employee_detail-container .employee-title .title-text .title_text-main {
  color: #9fa8da;
}
.indigo-amber.dark .UI-V2 .icon-complex mat-icon.complex {
  color: #9fa8da;
  text-shadow: -2px 0 0px #424242, 0 -2px 0px #424242, 2px 0 0px #424242, 0 2px 0px #424242, -2px -2px 0px #424242, 2px 2px 0px #424242, 2px -2px 0px #424242, -2px 2px 0px #424242;
}
.indigo-amber.dark .UI-V2 .icon-complex mat-icon.complex.mat-accent {
  color: #ffe082;
}
.indigo-amber.dark .UI-V2 .main .toolbar .header .content .module-title {
  color: #9fa8da;
}
.indigo-amber.dark .UI-V2 .main .toolbar .header .content .module-title.selectable:hover {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-amber.dark .UI-V2 .main .toolbar .header .content .module-title .info h5 {
  color: white !important;
}
.indigo-amber.dark .UI-V2 .main .toolbar .header .content .ou-select {
  color: rgba(0, 0, 0, 0.87);
  background: #9fa8da;
}
.indigo-amber.dark .UI-V2 .main .toolbar .search-container .search .content {
  background: #303030;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark .UI-V2 .main .toolbar .search-container .search .content:hover, .indigo-amber.dark .UI-V2 .main .toolbar .search-container .search .content:active, .indigo-amber.dark .UI-V2 .main .toolbar .search-container .search .content:focus, .indigo-amber.dark .UI-V2 .main .toolbar .search-container .search .content:focus-within {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-amber.dark .UI-V2 .main .toolbar .search-container .search .content input {
  color: white;
}
.indigo-amber.dark .UI-V2 .main .helper {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark .UI-V2 .main .helper mat-hint h4 {
  color: #9fa8da;
}
.indigo-amber.dark .UI-V2 .main .new-table app-cs-grid-control datatable-header * {
  color: #9fa8da;
}
.indigo-amber.dark .UI-V2 .main .new-table .mat-header-row.sticky * {
  color: #9fa8da !important;
}
.indigo-amber.dark .UI-V2 .main .main-action {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark .menu-options.help .primary {
  color: #9fa8da;
}
.indigo-amber.dark .menu-options.help .accent {
  color: #ffe082;
}
.indigo-amber.dark .menu-options .icon-complex mat-icon.complex {
  color: #9fa8da;
  text-shadow: -2px 0 0px #424242, 0 -2px 0px #424242, 2px 0 0px #424242, 0 2px 0px #424242, -2px -2px 0px #424242, 2px 2px 0px #424242, 2px -2px 0px #424242, -2px 2px 0px #424242;
}
.indigo-amber.dark .menu-options .mat-menu-content .container mat-list .mat-list-item:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-amber.dark .menu-options .mat-menu-content .container mat-list .mat-list-item .disabled {
  color: rgba(255, 255, 255, 0.7) !important;
}
.indigo-amber.dark .menu-options .mat-menu-content .container .slide-group mat-radio-group mat-radio-button:hover {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-amber.dark .menu-options .mat-menu-content .container .slide-group mat-form-field .mat-form-field-flex {
  background: #303030;
}
.indigo-amber.dark .menu-options .mat-menu-content .container .slide-group mat-slide-toggle:hover {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-amber.dark .mat-menu-panel.notifications-panel .mat-menu-content .notifications-body {
  background: #303030;
}
.indigo-amber.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification .primary mat-icon {
  color: #9fa8da;
}
.indigo-amber.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification .primary h3 {
  color: #9fa8da;
}
.indigo-amber.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.top {
  background: #303030;
}
.indigo-amber.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.top .header h3 {
  color: white;
}
.indigo-amber.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.bottom {
  background: #303030;
}
.indigo-amber.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable {
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable:nth-child(even) {
  background: #303030;
}
.indigo-amber.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable:nth-child(odd) {
  background: #424242;
}
.indigo-amber.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable:hover {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-amber.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable mat-icon {
  color: #9fa8da;
}
.indigo-amber.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable h3 {
  color: white;
}
.indigo-amber.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable h5 {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-amber.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable .subtitle h5 {
  color: #9fa8da;
  background: black;
}
.indigo-amber.dark .info-empty .large-icon {
  background: #7986cb;
  color: #9fa8da;
}
.indigo-amber.dark .info-empty h2,
.indigo-amber.dark .info-empty h3 {
  color: #9fa8da;
}
.indigo-amber.dark .info-empty h4,
.indigo-amber.dark .info-empty h5 {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-amber.dark .info-empty.accent mat-icon {
  background: #ffe082;
  color: #ffe082;
}
.indigo-amber.dark .info-empty.accent h2 {
  color: #ffe082;
}
.indigo-amber.dark .info-empty .actions {
  padding: 16px;
  height: 96px;
  column-gap: 16px;
}
.indigo-amber.dark .info-empty .actions button {
  height: 100%;
  border-radius: 40px;
}
.indigo-amber.dark .info-empty .actions button.ok {
  background: #4db6ac !important;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark .info-empty .actions button.not-ok {
  color: #ba68c8 !important;
}
.indigo-amber.dark .side.edition .info-empty .large-icon {
  background: #ffe082;
  color: #ffe082;
}
.indigo-amber.dark .side.edition .info-empty h2 {
  color: #ffe082;
}
.indigo-amber.dark .side.edition .info-empty h4 {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-amber.dark .UI-V2 .deferred-process-list .header h3 {
  color: #9fa8da;
}
.indigo-amber.dark .UI-V2 .deferred-process-list .search-container .search .content {
  background: #303030;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark .UI-V2 .deferred-process-list .search-container .search .content:hover, .indigo-amber.dark .UI-V2 .deferred-process-list .search-container .search .content:active, .indigo-amber.dark .UI-V2 .deferred-process-list .search-container .search .content:focus, .indigo-amber.dark .UI-V2 .deferred-process-list .search-container .search .content:focus-within {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-amber.dark .UI-V2 .deferred-process-list .search-container .search .content input {
  color: white;
}
.indigo-amber.dark .UI-V2 .deferred-process-list .text-success,
.indigo-amber.dark .UI-V2 .deferred-process-details .text-success {
  color: #4caf50;
}
.indigo-amber.dark .UI-V2 .deferred-process-list .text-warn,
.indigo-amber.dark .UI-V2 .deferred-process-details .text-warn {
  color: #e57373;
}
.indigo-amber.dark .UI-V2 .deferred-process-list .text-primary,
.indigo-amber.dark .UI-V2 .deferred-process-details .text-primary {
  color: #9fa8da;
}
.indigo-amber.dark .UI-V2 .deferred-process-list .text-atention,
.indigo-amber.dark .UI-V2 .deferred-process-details .text-atention {
  color: #DB7703;
}
.indigo-amber.dark .UI-V2 .deferred-process-list .table-paginator-container,
.indigo-amber.dark .UI-V2 .deferred-process-details .table-paginator-container {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
}
.indigo-amber.dark .UI-V2 .deferred-process-list .table-paginator-container .mat-table,
.indigo-amber.dark .UI-V2 .deferred-process-details .table-paginator-container .mat-table {
  border-bottom: none;
  border-radius: 10px 10px 0 0;
}
.indigo-amber.dark .UI-V2 .deferred-process-list .table-paginator-container .paginator-container,
.indigo-amber.dark .UI-V2 .deferred-process-details .table-paginator-container .paginator-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: #f2f2f2;
  padding: 0 1.5rem;
  height: 3rem;
}
.indigo-amber.dark .UI-V2 .deferred-process-list .mat-table,
.indigo-amber.dark .UI-V2 .deferred-process-details .mat-table {
  border: 1px solid #f2f2f2;
  border-radius: 10px;
  overflow: hidden;
}
.indigo-amber.dark .UI-V2 .deferred-process-list .mat-table .mat-header-row .mat-header-cell,
.indigo-amber.dark .UI-V2 .deferred-process-details .mat-table .mat-header-row .mat-header-cell {
  font-size: 14px;
  font-weight: 700;
}
.indigo-amber.dark .UI-V2 .deferred-process-list .mat-table .mat-header-row,
.indigo-amber.dark .UI-V2 .deferred-process-details .mat-table .mat-header-row {
  background: #f2f2f2;
}
.indigo-amber.dark .UI-V2 .deferred-process-list .mat-table .mat-header-row .mat-header-cell,
.indigo-amber.dark .UI-V2 .deferred-process-details .mat-table .mat-header-row .mat-header-cell {
  color: #9fa8da;
}
.indigo-amber.dark .UI-V2 .deferred-process-list .mat-table .mat-row,
.indigo-amber.dark .UI-V2 .deferred-process-details .mat-table .mat-row {
  background: #424242;
}
.indigo-amber.dark .UI-V2 .progress-bar-container {
  position: relative;
}
.indigo-amber.dark .UI-V2 .deferred-process-details .header h3 {
  color: #9fa8da;
}
.indigo-amber.dark .UI-V2 .deferred-process-details .header .back-link:visited {
  color: #9fa8da;
}
.indigo-amber.dark .process-card,
.indigo-amber.dark .process-card-detail,
.indigo-amber.dark .process-card-stages {
  background: #303030;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark .process-card mat-card-subtitle,
.indigo-amber.dark .process-card-detail mat-card-subtitle,
.indigo-amber.dark .process-card-stages mat-card-subtitle {
  color: white;
}
.indigo-amber.dark .process-card .stages-container .stage.completed mat-icon,
.indigo-amber.dark .process-card-detail .stages-container .stage.completed mat-icon,
.indigo-amber.dark .process-card-stages .stages-container .stage.completed mat-icon {
  color: #499719;
}
.indigo-amber.dark .process-card .stages-container .stage.completed .icon-container,
.indigo-amber.dark .process-card-detail .stages-container .stage.completed .icon-container,
.indigo-amber.dark .process-card-stages .stages-container .stage.completed .icon-container {
  background-color: rgba(78, 160, 28, 0.1490196078);
}
.indigo-amber.dark .process-card .stages-container .stage.completed .line,
.indigo-amber.dark .process-card-detail .stages-container .stage.completed .line,
.indigo-amber.dark .process-card-stages .stages-container .stage.completed .line {
  background-color: #499719;
}
.indigo-amber.dark .process-card .stages-container .stage.on-going mat-icon,
.indigo-amber.dark .process-card-detail .stages-container .stage.on-going mat-icon,
.indigo-amber.dark .process-card-stages .stages-container .stage.on-going mat-icon {
  color: #9fa8da;
}
.indigo-amber.dark .process-card .stages-container .stage.on-going .icon-container,
.indigo-amber.dark .process-card-detail .stages-container .stage.on-going .icon-container,
.indigo-amber.dark .process-card-stages .stages-container .stage.on-going .icon-container {
  background-color: rgba(66, 48, 159, 0.2);
}
.indigo-amber.dark .process-card .stages-container .stage.on-going .line,
.indigo-amber.dark .process-card-detail .stages-container .stage.on-going .line,
.indigo-amber.dark .process-card-stages .stages-container .stage.on-going .line {
  background-color: #949494;
}
.indigo-amber.dark .process-card .stages-container .stage.pending,
.indigo-amber.dark .process-card-detail .stages-container .stage.pending,
.indigo-amber.dark .process-card-stages .stages-container .stage.pending {
  color: #949494;
}
.indigo-amber.dark .process-card .stages-container .stage.pending mat-icon,
.indigo-amber.dark .process-card-detail .stages-container .stage.pending mat-icon,
.indigo-amber.dark .process-card-stages .stages-container .stage.pending mat-icon {
  color: #949494;
}
.indigo-amber.dark .process-card .stages-container .stage.pending .icon-container,
.indigo-amber.dark .process-card-detail .stages-container .stage.pending .icon-container,
.indigo-amber.dark .process-card-stages .stages-container .stage.pending .icon-container {
  background-color: rgba(174, 174, 174, 0.2);
}
.indigo-amber.dark .process-card .stages-container .stage.pending .line,
.indigo-amber.dark .process-card-detail .stages-container .stage.pending .line,
.indigo-amber.dark .process-card-stages .stages-container .stage.pending .line {
  background-color: #949494;
}
.indigo-amber.dark .process-card .stages-container .stage.has-error mat-icon,
.indigo-amber.dark .process-card-detail .stages-container .stage.has-error mat-icon,
.indigo-amber.dark .process-card-stages .stages-container .stage.has-error mat-icon {
  color: #e57373;
}
.indigo-amber.dark .process-card .stages-container .stage.has-error .icon-container,
.indigo-amber.dark .process-card-detail .stages-container .stage.has-error .icon-container,
.indigo-amber.dark .process-card-stages .stages-container .stage.has-error .icon-container {
  background-color: rgba(188, 4, 4, 0.2);
}
.indigo-amber.dark .process-card .stages-container .stage-description .button-link,
.indigo-amber.dark .process-card-detail .stages-container .stage-description .button-link,
.indigo-amber.dark .process-card-stages .stages-container .stage-description .button-link {
  color: #9fa8da;
}
.indigo-amber.dark app-custom-search-input .custom-search-container {
  background: #303030;
  border: 1px solid rgba(255, 255, 255, 0.12);
  transition: color 0.1s ease, background 0.15s ease;
}
.indigo-amber.dark app-custom-search-input .custom-search-container mat-icon.mat-icon {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-amber.dark app-custom-search-input .custom-search-container input.custom-search-input {
  color: white;
  background: unset;
}
.indigo-amber.dark app-custom-search-input .custom-search-container:hover, .indigo-amber.dark app-custom-search-input .custom-search-container:active, .indigo-amber.dark app-custom-search-input .custom-search-container:focus, .indigo-amber.dark app-custom-search-input .custom-search-container:focus-within {
  background: rgba(255, 255, 255, 0.04) !important;
  transition: background 0.15s ease;
}
.indigo-amber.dark .UI-V2 .audit-filter {
  background: #424242;
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark .UI-V2 .audit-filter .toolbar {
  background: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark .UI-V2 .audit-filter .toolbar .header .content .module-title {
  color: #9fa8da;
}
.indigo-amber.dark .UI-V2 .audit-filter .toolbar .header .content .module-title.selectable:hover {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-amber.dark .UI-V2 .audit-filter .toolbar .header .content .module-title .info * {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-amber.dark .UI-V2 .audit-filter .toolbar .header .content .ou-select {
  color: rgba(0, 0, 0, 0.87);
  background: #9fa8da;
}
.indigo-amber.dark .UI-V2 .audit-filter .toolbar .search-container .search .content {
  background: #303030;
}
.indigo-amber.dark .UI-V2 .audit-filter .toolbar .search-container .search .content:hover, .indigo-amber.dark .UI-V2 .audit-filter .toolbar .search-container .search .content:active, .indigo-amber.dark .UI-V2 .audit-filter .toolbar .search-container .search .content:focus, .indigo-amber.dark .UI-V2 .audit-filter .toolbar .search-container .search .content:focus-within {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-amber.dark .UI-V2 .audit-filter .toolbar .search-container .search .content input {
  color: white;
}
.indigo-amber.dark .UI-V2 .audit-filter .filter-container {
  background: #424242 !important;
}
.indigo-amber.dark .UI-V2 .audit-filter .filter-container .form-body {
  background: #303030 !important;
}
.indigo-amber.dark .UI-V2 .audit-filter .filter-container .form-body .line h4 {
  color: #9fa8da;
}
.indigo-amber.dark .UI-V2 .audit-filter .filter-container .form-body .line mat-icon {
  color: #9fa8da;
}
.indigo-amber.dark .UI-V2 .audit-result .result-container .toolbar {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark .UI-V2 .audit-result .result-container .toolbar .header .content .module-title {
  color: #9fa8da;
}
.indigo-amber.dark .UI-V2 .audit-result .result-container .toolbar .header .content .module-title.selectable:hover {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-amber.dark .UI-V2 .audit-result .result-container .toolbar .header .content .module-title .info h5 {
  color: white !important;
}
.indigo-amber.dark .UI-V2 .audit-result .result-container .toolbar .header .content .ou-select {
  color: rgba(0, 0, 0, 0.87);
  background: #9fa8da;
}
.indigo-amber.dark .UI-V2 .audit-result .result-container .toolbar .search-container .search .content {
  background: #303030;
}
.indigo-amber.dark .UI-V2 .audit-result .result-container .toolbar .search-container .search .content:hover, .indigo-amber.dark .UI-V2 .audit-result .result-container .toolbar .search-container .search .content:active, .indigo-amber.dark .UI-V2 .audit-result .result-container .toolbar .search-container .search .content:focus, .indigo-amber.dark .UI-V2 .audit-result .result-container .toolbar .search-container .search .content:focus-within {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-amber.dark .UI-V2 .audit-result .result-container .toolbar .search-container .search .content input {
  color: white;
}
.indigo-amber.dark .UI-V2 .audit-result .result-container .new-table app-cs-grid-control datatable-header * {
  color: #9fa8da;
}
.indigo-amber.dark .UI-V2 .audit-result .result-container .new-table .mat-header-row.sticky * {
  color: #9fa8da !important;
}
.indigo-amber.dark .UI-V2 .audit-detail-container {
  border-left: 1px solid rgba(255, 255, 255, 0.12);
  background: black !important;
}
.indigo-amber.dark .UI-V2 .audit-detail-container .side .toolbar .header {
  color: #9fa8da;
}
.indigo-amber.dark .UI-V2 .audit-detail-container .side .container .mat-list-base {
  background: #303030 !important;
}
.indigo-amber.dark .UI-V2 .audit-detail-container .side .container .mat-list-base mat-list-item .mat-line b {
  color: #9fa8da;
}
.indigo-amber.dark .UI-V2 .inbox-container {
  background: #424242;
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark .UI-V2 .inbox-container *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.indigo-amber.dark .UI-V2 .inbox-container *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
.indigo-amber.dark .UI-V2 .inbox-container *::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}
.indigo-amber.dark .UI-V2 .inbox-container .load-window {
  background: #424242;
}
.indigo-amber.dark .UI-V2 .inbox-container .toolbar {
  background: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark .UI-V2 .inbox-container .toolbar.reverse {
  background: #424242;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel {
  background: black !important;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel.mat-expanded {
  background: #303030 !important;
}
.indigo-amber.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel.mat-expanded mat-expansion-panel-header {
  background: #303030 !important;
}
.indigo-amber.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel.mat-expanded mat-expansion-panel-header:hover {
  background: none !important;
}
.indigo-amber.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel mat-expansion-panel-header {
  background: #303030 !important;
}
.indigo-amber.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel mat-expansion-panel-header:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-amber.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel mat-expansion-panel-header .signature-inbox-header .mat-icon,
.indigo-amber.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel mat-expansion-panel-header .signature-inbox-header b {
  color: #ffe082;
}
.indigo-amber.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel .mat-expansion-panel-body .item {
  background: #424242;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel .mat-expansion-panel-body .item:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-amber.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel .mat-expansion-panel-body .item.active {
  border-left: 6px solid #ffe082;
}
.indigo-amber.dark .UI-V2 .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.indigo-amber.dark .UI-V2 .work-space .main .load-window {
  background: #424242 !important;
}
.indigo-amber.dark .UI-V2 .work-space .main-action {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark mat-radio-button.disabled .mat-radio-label-content {
  color: #ffe082;
}
.indigo-amber.dark .detail app-automatic-process-detail app-chapa.minimize,
.indigo-amber.dark .detail app-process-detail app-chapa.minimize {
  background: #ffc107 !important;
}
.indigo-amber.dark .detail app-automatic-process-detail app-chapa.minimize.purged,
.indigo-amber.dark .detail app-process-detail app-chapa.minimize.purged {
  background: #b2adb0 !important;
}
.indigo-amber.dark .detail .detail-toolbar {
  background: black;
}
.indigo-amber.dark .detail .processes-status .status-bar .bar-graph {
  background-color: #ffc107;
}
.indigo-amber.dark .detail .processes-status .status-bar .bar-graph .graph-ok {
  background-color: #009688;
}
.indigo-amber.dark .detail .processes-status .status-bar .bar-graph .graph-not-ok {
  background-color: #e91e63;
}
.indigo-amber.dark .detail .processes-status .status-resume b {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark .detail .processes-status .status-resume .resume-total b {
  background-color: #ffc107;
}
.indigo-amber.dark .detail .processes-status .status-resume .resume-ok b {
  background-color: #009688;
}
.indigo-amber.dark .detail .processes-status .status-resume .resume-not-ok b {
  background-color: #e91e63;
}
.indigo-amber.dark .detail .detail-toolbar .mat-avatar h3,
.indigo-amber.dark .detail .detail-toolbar .mat-avatar h5 {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark .detail .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark .detail .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark .detail .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark .detail .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark .detail .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  background: #009688 !important;
}
.indigo-amber.dark .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  background: #b2dfdb !important;
}
.indigo-amber.dark .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  background: #b2dfdb !important;
}
.indigo-amber.dark .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  background: #009688 !important;
}
.indigo-amber.dark .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  background: #b2dfdb !important;
}
.indigo-amber.dark .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  background: #837C80 !important;
}
.indigo-amber.dark .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  background: #b2adb0 !important;
}
.indigo-amber.dark .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  background: #b2adb0 !important;
}
.indigo-amber.dark .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  background: #837C80 !important;
}
.indigo-amber.dark .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  background: #b2dfdb !important;
}
.indigo-amber.dark .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  background: #ffc107 !important;
}
.indigo-amber.dark .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  background: #ffecb3 !important;
}
.indigo-amber.dark .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  background: #ffecb3 !important;
}
.indigo-amber.dark .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  background: #ffc107 !important;
}
.indigo-amber.dark .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  background: #ffecb3 !important;
}
.indigo-amber.dark .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  background: #e91e63 !important;
}
.indigo-amber.dark .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  background: #f8bbd0 !important;
}
.indigo-amber.dark .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  background: #f8bbd0 !important;
}
.indigo-amber.dark .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  background: #e91e63 !important;
}
.indigo-amber.dark .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  background: #f8bbd0 !important;
}
.indigo-amber.dark app-analytics-document-set .sets-container .mat-list-base.sets-list mat-list-item {
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark app-analytics-document-set .sets-container .mat-list-base.sets-list mat-list-item .mat-list-item-content .set-status .value-percentile .undone-value {
  background: #673AB7;
}
.indigo-amber.dark app-analytics-document-set .sets-container .mat-list-base.sets-list mat-list-item .mat-list-item-content .set-status .value-percentile .not-value {
  background: black;
}
.indigo-amber.dark app-analytics-document-set .sets-container .mat-list-base.sets-list mat-list-item .mat-list-item-content .set-status .value-percentile .done-value {
  background: #009688;
}
.indigo-amber.dark app-analytics-document-set .work-space .main .toolbar .header .content .module-title .info .info-text .set-status {
  cursor: help;
}
.indigo-amber.dark app-analytics-document-set .work-space .main .toolbar .header .content .module-title .info .info-text .set-status .value-percentile .undone-value {
  background: #673AB7;
}
.indigo-amber.dark app-analytics-document-set .work-space .main .toolbar .header .content .module-title .info .info-text .set-status .value-percentile .not-value {
  background: black;
}
.indigo-amber.dark app-analytics-document-set .work-space .main .toolbar .header .content .module-title .info .info-text .set-status .value-percentile .done-value {
  background: #009688;
}
.indigo-amber.dark app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar .mat-progress-bar-buffer {
  background-color: black;
}
.indigo-amber.dark app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar.class1 .mat-progress-bar-fill::after {
  background-color: #f44336;
}
.indigo-amber.dark app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar.class2 .mat-progress-bar-fill::after {
  background-color: #ff9800;
}
.indigo-amber.dark app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar.class3 .mat-progress-bar-fill::after {
  background-color: #ffeb3b;
}
.indigo-amber.dark app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar.class4 .mat-progress-bar-fill::after {
  background-color: #8bc34a;
}
.indigo-amber.dark app-analytics-document-set .work-space .side .container mat-list {
  background: #424242 !important;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark app-analytics-document-set .work-space .side .container mat-list mat-list-item .mat-line b {
  color: #9fa8da;
}
.indigo-amber.dark .catcher {
  background: rgba(0, 0, 0, 0.6);
}
.indigo-amber.dark .catcher.clear {
  background: rgba(0, 0, 0, 0);
}
.indigo-amber.dark .add-menu-form-body .mat-radio-group .mat-radio-button:hover {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-amber.dark .add-menu-form-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b {
  color: white;
}
.indigo-amber.dark .add-menu-form-body.w-table .helper .search-bar {
  background: #303030;
}
.indigo-amber.dark .add-menu-form-body.w-table .helper .search-bar:hover, .indigo-amber.dark .add-menu-form-body.w-table .helper .search-bar:active, .indigo-amber.dark .add-menu-form-body.w-table .helper .search-bar:focus, .indigo-amber.dark .add-menu-form-body.w-table .helper .search-bar:focus-within {
  background: #303030 !important;
}
.indigo-amber.dark .add-menu-form-body.w-table .helper .search-bar input {
  color: white;
}
.indigo-amber.dark .add-menu-form-actions {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark .file-upload-list mat-list-item {
  background: #424242;
}
.indigo-amber.dark .mat-horizontal-stepper-content[aria-expanded=true] app-file-document-view {
  border-left: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark .mat-horizontal-stepper-content[aria-expanded=true] app-file-document-view .pdf-view-container {
  background: black;
}
.indigo-amber.dark .add-docs-drop .docs-drop-preview app-file-document-detail {
  border-left: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark .add-docs-drop .docs-drop-preview app-file-document-detail .view-toolbar {
  background: #424242;
}
.indigo-amber.dark .add-docs-drop .load-window.message h3.primary-text {
  color: #9fa8da;
}
.indigo-amber.dark mat-dialog-container app-add-menu-dialog .load-window.message h3.primary-text {
  color: #9fa8da;
}
.indigo-amber.dark .asisted-input.aligned-icon.subtitle * {
  color: rgba(255, 255, 255, 0.5) !important;
}
.indigo-amber.dark .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble {
  background: #9fa8da;
}
.indigo-amber.dark .asisted-input .mat-form-field-invalid + .indicator > .bubble {
  background: #e57373;
}
.indigo-amber.dark .asisted-input .mat-form-field-disabled + .indicator > .bubble {
  color: rgba(255, 255, 255, 0.5);
  background: rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark .asisted-input .indicator .bubble {
  background: rgba(255, 255, 255, 0.5);
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark .asisted-input .indicator .guide {
  border-color: rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark .asisted-input.icon-only .bubble {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-amber.dark .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble {
  color: #9fa8da;
}
.indigo-amber.dark .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble {
  color: #e57373;
}
.indigo-amber.dark .UI-V2 .main-overlay {
  background: rgba(0, 0, 0, 0.32);
}
.indigo-amber.dark .UI-V2 .icon-complex mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.indigo-amber.dark .UI-V2 .icon-complex mat-icon.complex {
  color: #9fa8da !important;
}
.indigo-amber.dark .UI-V2 .navigation {
  background: #303030;
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark .UI-V2 .navigation *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.indigo-amber.dark .UI-V2 .navigation *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
.indigo-amber.dark .UI-V2 .navigation .load-window {
  background: black;
}
.indigo-amber.dark .UI-V2 .navigation .toolbar {
  background: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark .UI-V2 .navigation .toolbar.reverse {
  background: #424242;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark .UI-V2 .navigation .container .helper {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-amber.dark .UI-V2 .navigation .container .item {
  background: #424242;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark .UI-V2 .navigation .container .item:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-amber.dark .UI-V2 .navigation .container .item.active {
  background: #9fa8da !important;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #9fa8da;
}
.indigo-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .separator .subtitle,
.indigo-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .separator .subtitle,
.indigo-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .separator .subtitle,
.indigo-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .separator .subtitle,
.indigo-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .separator .subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .img-editior-container .img-editor .separator .subtitle,
.indigo-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .img-editior-container .img-editor .separator .subtitle,
.indigo-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .img-editior-container .img-editor .separator .subtitle,
.indigo-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .img-editior-container .img-editor .separator .subtitle,
.indigo-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .img-editior-container .img-editor .separator .subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme,
.indigo-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme,
.indigo-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme,
.indigo-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme,
.indigo-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .main-color-container .main-color,
.indigo-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .main-color-container .main-color,
.indigo-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .main-color-container .main-color,
.indigo-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .main-color-container .main-color,
.indigo-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .main-color-container .main-color {
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .main-color-container .main-color:before,
.indigo-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .main-color-container .main-color:before,
.indigo-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .main-color-container .main-color:before,
.indigo-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .main-color-container .main-color:before,
.indigo-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .main-color-container .main-color:before {
  border-left: 10px solid rgba(0, 0, 0, 0);
  border-right: 10px solid rgba(0, 0, 0, 0);
}
.indigo-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example h6.required,
.indigo-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example h6.required,
.indigo-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example h6.required,
.indigo-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example h6.required,
.indigo-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example h6.required {
  color: #e57373 !important;
}
.indigo-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle,
.indigo-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle,
.indigo-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle,
.indigo-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle,
.indigo-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle {
  border: 2px solid rgba(0, 0, 0, 0);
}
.indigo-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .side-color-container .side-color,
.indigo-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .side-color-container .side-color,
.indigo-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .side-color-container .side-color,
.indigo-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .side-color-container .side-color,
.indigo-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .side-color-container .side-color {
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .side-color-container .side-color:before,
.indigo-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .side-color-container .side-color:before,
.indigo-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .side-color-container .side-color:before,
.indigo-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .side-color-container .side-color:before,
.indigo-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .side-color-container .side-color:before {
  border-top: 10px solid rgba(0, 0, 0, 0);
  border-bottom: 10px solid rgba(0, 0, 0, 0);
}
.indigo-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .side-color-container .side-color h6.required,
.indigo-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .side-color-container .side-color h6.required,
.indigo-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .side-color-container .side-color h6.required,
.indigo-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .side-color-container .side-color h6.required,
.indigo-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .side-color-container .side-color h6.required {
  color: #e57373 !important;
}
.indigo-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .permission-settings-cards .card,
.indigo-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .permission-settings-cards .card,
.indigo-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .permission-settings-cards .card,
.indigo-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .permission-settings-cards .card,
.indigo-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .permission-settings-cards .card {
  background: #303030;
}
.indigo-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .permission-settings-cards .card .slide-sub-group,
.indigo-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .permission-settings-cards .card .slide-sub-group,
.indigo-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .permission-settings-cards .card .slide-sub-group,
.indigo-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .permission-settings-cards .card .slide-sub-group,
.indigo-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .permission-settings-cards .card .slide-sub-group {
  background: rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark .UI-V2 .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.indigo-amber.dark .UI-V2 .work-space .main .load-window {
  background: #424242 !important;
}
.indigo-amber.dark .UI-V2 .work-space .main.side-open {
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item {
  background: #303030;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item mat-icon.complex {
  text-shadow: -2px 0 0px black, 0 -2px 0px black, 2px 0 0px black, 0 2px 0px black, -2px -2px 0px black, 2px 2px 0px black, 2px -2px 0px black, -2px 2px 0px black !important;
}
.indigo-amber.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item h5 {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-amber.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item h3 {
  color: white;
}
.indigo-amber.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item .actions {
  pointer-events: none;
}
.indigo-amber.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item .actions button {
  pointer-events: all;
}
.indigo-amber.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled {
  background: black;
}
.indigo-amber.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled h3 {
  color: #607D8B !important;
}
.indigo-amber.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled mat-icon {
  color: #607D8B !important;
}
.indigo-amber.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled.selected {
  background: #607D8B;
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-amber.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled.selected h3 {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-amber.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled.selected mat-icon {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-amber.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled.selected mat-icon.complex {
  color: rgba(0, 0, 0, 0.87) !important;
  text-shadow: -2px 0 0px #607D8B, 0 -2px 0px #607D8B, 2px 0 0px #607D8B, 0 2px 0px #607D8B, -2px -2px 0px #607D8B, 2px 2px 0px #607D8B, 2px -2px 0px #607D8B, -2px 2px 0px #607D8B !important;
}
.indigo-amber.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item:hover {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-amber.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected {
  background: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected mat-icon {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected mat-icon.complex {
  color: rgba(0, 0, 0, 0.87) !important;
  text-shadow: -2px 0 0px #9fa8da, 0 -2px 0px #9fa8da, 2px 0 0px #9fa8da, 0 2px 0px #9fa8da, -2px -2px 0px #9fa8da, 2px 2px 0px #9fa8da, 2px -2px 0px #9fa8da, -2px 2px 0px #9fa8da !important;
}
.indigo-amber.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected h5,
.indigo-amber.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected h3 {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark .UI-V2 .work-space .main .main-action {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark .UI-V2 .work-space .side {
  background: #303030;
}
.indigo-amber.dark .UI-V2 .work-space .side .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #303030, 0 -2px 0px #303030, 2px 0 0px #303030, 0 2px 0px #303030, -2px -2px 0px #303030, 2px 2px 0px #303030, 2px -2px 0px #303030, -2px 2px 0px #303030;
}
.indigo-amber.dark .UI-V2 .work-space .side *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.indigo-amber.dark .UI-V2 .work-space .side *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
.indigo-amber.dark .UI-V2 .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #303030 !important;
}
.indigo-amber.dark .UI-V2 .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-amber.dark .UI-V2 .work-space .side mat-form-field .mat-form-field-flex {
  background: #303030;
}
.indigo-amber.dark .UI-V2 .work-space .side mat-checkbox:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-amber.dark .UI-V2 .work-space .side .hint.all {
  color: #ffe082;
}
.indigo-amber.dark .UI-V2 .work-space .side .toolbar .header {
  color: #9fa8da;
}
.indigo-amber.dark .UI-V2 .work-space .side .toolbar .header h3 {
  color: #9fa8da;
}
.indigo-amber.dark .UI-V2 .work-space .side .container .role-resume {
  background: #9fa8da;
}
.indigo-amber.dark .UI-V2 .work-space .side .container .role-resume .line {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark .UI-V2 .work-space .side .container .role-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.1);
}
.indigo-amber.dark .UI-V2 .work-space .side .container .role-resume .line.editable button {
  background: #9fa8da;
}
.indigo-amber.dark .UI-V2 .work-space .side .container .section-title {
  color: #9fa8da;
}
.indigo-amber.dark .UI-V2 .work-space .side .container .section-resume {
  background: #424242;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark .UI-V2 .work-space .side .container .section-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-amber.dark .UI-V2 .work-space .side .container .section-resume .line.editable:hover .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #424242, 0 -2px 0px #424242, 2px 0 0px #424242, 0 2px 0px #424242, -2px -2px 0px #424242, 2px 2px 0px #424242, 2px -2px 0px #424242, -2px 2px 0px #424242 !important;
}
.indigo-amber.dark .UI-V2 .work-space .side .container .section-resume .line.editable button {
  background: #303030;
}
.indigo-amber.dark .UI-V2 .work-space .side .container .section-resume .line .header .icon-complex mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.indigo-amber.dark .UI-V2 .work-space .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #9fa8da !important;
}
.indigo-amber.dark .UI-V2 .work-space .side .container .section-resume .line .header mat-icon:not(.complex) {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-amber.dark .UI-V2 .work-space .side .container .section-resume .line .header .info h4 {
  color: #9fa8da;
}
.indigo-amber.dark .UI-V2 .work-space .side .container .section-resume .line ul {
  color: #9fa8da;
}
.indigo-amber.dark .UI-V2 .work-space .side .container .section-resume .line.head {
  background: black;
}
.indigo-amber.dark .UI-V2 .work-space .side .container .section-resume .line.head mat-icon {
  color: #ffe082;
}
.indigo-amber.dark .UI-V2 .work-space .side .container .section-resume .line.head h4 {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark .UI-V2 .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body {
  background: #424242;
}
.indigo-amber.dark .UI-V2 .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-amber.dark .UI-V2 .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #9fa8da;
}
.indigo-amber.dark .UI-V2 .work-space .side.edition .toolbar .header {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark .UI-V2 .work-space .side.edition .toolbar .header mat-icon {
  color: #ffe082;
}
.indigo-amber.dark .UI-V2 .work-space .side.edition .toolbar .header h3 {
  color: #ffe082;
}
.indigo-amber.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body {
  background: #424242 !important;
}
.indigo-amber.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header mat-icon:not(.complex) {
  color: #9fa8da;
}
.indigo-amber.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header h4 {
  color: #9fa8da;
}
.indigo-amber.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-actions {
  background: #303030;
}
.indigo-amber.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.indigo-amber.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header .icon-complex mat-icon.complex {
  color: #ffe082 !important;
}
.indigo-amber.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header h4 {
  color: #ffe082;
}
.indigo-amber.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line ul {
  color: #ffe082;
  font-weight: 400;
}
.indigo-amber.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head mat-icon {
  color: #ffe082 !important;
}
.indigo-amber.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head h4 {
  color: white;
}
.indigo-amber.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .section-title {
  color: white !important;
}
.indigo-amber.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume h6.hint {
  background: #ffe082;
}
.indigo-amber.dark app-role-user-find .main-overlay {
  background: rgba(0, 0, 0, 0.32);
}
.indigo-amber.dark app-role-user-find .icon-complex mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.indigo-amber.dark app-role-user-find .icon-complex mat-icon.complex {
  color: #9fa8da !important;
}
.indigo-amber.dark app-role-user-find .navigation {
  background: #303030;
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark app-role-user-find .navigation *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.indigo-amber.dark app-role-user-find .navigation *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
.indigo-amber.dark app-role-user-find .navigation .load-window {
  background: black;
}
.indigo-amber.dark app-role-user-find .navigation .toolbar {
  background: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark app-role-user-find .navigation .toolbar.reverse {
  background: #424242;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark app-role-user-find .navigation .container .helper {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-amber.dark app-role-user-find .navigation .container .item {
  background: #424242;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark app-role-user-find .navigation .container .item:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-amber.dark app-role-user-find .navigation .container .item.active {
  background: #9fa8da !important;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #9fa8da;
}
.indigo-amber.dark app-role-user-find .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.indigo-amber.dark app-role-user-find .work-space .main .load-window {
  background: #424242 !important;
}
.indigo-amber.dark app-role-user-find .work-space .main.side-open {
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark app-role-user-find .work-space .main .container .item {
  background: #303030;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark app-role-user-find .work-space .main .container .item .subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-amber.dark app-role-user-find .work-space .main .container .item.disabled {
  background: black;
  color: #607D8B !important;
}
.indigo-amber.dark app-role-user-find .work-space .main .container .item.disabled h3 {
  color: #607D8B !important;
}
.indigo-amber.dark app-role-user-find .work-space .main .container .item.disabled mat-icon {
  color: #607D8B !important;
}
.indigo-amber.dark app-role-user-find .work-space .main .container .item.disabled.selected {
  background: #607D8B;
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-amber.dark app-role-user-find .work-space .main .container .item.disabled.selected h3 {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-amber.dark app-role-user-find .work-space .main .container .item.disabled.selected mat-icon {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-amber.dark app-role-user-find .work-space .main .container .item.disabled.selected mat-icon.complex {
  color: rgba(0, 0, 0, 0.87) !important;
  text-shadow: -2px 0 0px #607D8B, 0 -2px 0px #607D8B, 2px 0 0px #607D8B, 0 2px 0px #607D8B, -2px -2px 0px #607D8B, 2px 2px 0px #607D8B, 2px -2px 0px #607D8B, -2px 2px 0px #607D8B !important;
}
.indigo-amber.dark app-role-user-find .work-space .main .container .item mat-icon.complex {
  text-shadow: -2px 0 0px black, 0 -2px 0px black, 2px 0 0px black, 0 2px 0px black, -2px -2px 0px black, 2px 2px 0px black, 2px -2px 0px black, -2px 2px 0px black !important;
}
.indigo-amber.dark app-role-user-find .work-space .main .container .item h5 {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-amber.dark app-role-user-find .work-space .main .container .item h3 {
  color: white;
}
.indigo-amber.dark app-role-user-find .work-space .main .container .item:hover {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-amber.dark app-role-user-find .work-space .main .container .item.selected {
  background: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark app-role-user-find .work-space .main .container .item.selected mat-icon {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark app-role-user-find .work-space .main .container .item.selected mat-icon.complex {
  color: rgba(0, 0, 0, 0.87) !important;
  text-shadow: -2px 0 0px #9fa8da, 0 -2px 0px #9fa8da, 2px 0 0px #9fa8da, 0 2px 0px #9fa8da, -2px -2px 0px #9fa8da, 2px 2px 0px #9fa8da, 2px -2px 0px #9fa8da, -2px 2px 0px #9fa8da !important;
}
.indigo-amber.dark app-role-user-find .work-space .main .container .item.selected h5,
.indigo-amber.dark app-role-user-find .work-space .main .container .item.selected h3 {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark app-role-user-find .work-space .main .main-action {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark app-role-user-find .work-space .side {
  background: #303030;
}
.indigo-amber.dark app-role-user-find .work-space .side .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #303030, 0 -2px 0px #303030, 2px 0 0px #303030, 0 2px 0px #303030, -2px -2px 0px #303030, 2px 2px 0px #303030, 2px -2px 0px #303030, -2px 2px 0px #303030;
}
.indigo-amber.dark app-role-user-find .work-space .side *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.indigo-amber.dark app-role-user-find .work-space .side *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
.indigo-amber.dark app-role-user-find .work-space .side .edition-line {
  background: black;
}
.indigo-amber.dark app-role-user-find .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #303030 !important;
}
.indigo-amber.dark app-role-user-find .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-amber.dark app-role-user-find .work-space .side mat-form-field .mat-form-field-flex {
  background: #303030;
}
.indigo-amber.dark app-role-user-find .work-space .side mat-checkbox:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-amber.dark app-role-user-find .work-space .side .hint.all {
  color: #ffe082;
}
.indigo-amber.dark app-role-user-find .work-space .side .toolbar .header {
  color: #9fa8da;
}
.indigo-amber.dark app-role-user-find .work-space .side .toolbar .header h3 {
  color: #9fa8da;
}
.indigo-amber.dark app-role-user-find .work-space .side .container .role-resume {
  background: #9fa8da;
}
.indigo-amber.dark app-role-user-find .work-space .side .container .role-resume .line {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark app-role-user-find .work-space .side .container .role-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.1);
}
.indigo-amber.dark app-role-user-find .work-space .side .container .role-resume .line.editable button {
  background: #9fa8da;
}
.indigo-amber.dark app-role-user-find .work-space .side .container .section-title {
  color: #9fa8da;
}
.indigo-amber.dark app-role-user-find .work-space .side .container .section-resume {
  background: #424242;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark app-role-user-find .work-space .side .container .section-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-amber.dark app-role-user-find .work-space .side .container .section-resume .line.editable:hover .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #424242, 0 -2px 0px #424242, 2px 0 0px #424242, 0 2px 0px #424242, -2px -2px 0px #424242, 2px 2px 0px #424242, 2px -2px 0px #424242, -2px 2px 0px #424242 !important;
}
.indigo-amber.dark app-role-user-find .work-space .side .container .section-resume .line.editable button {
  background: #303030;
}
.indigo-amber.dark app-role-user-find .work-space .side .container .section-resume .line .header .icon-complex mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.indigo-amber.dark app-role-user-find .work-space .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #9fa8da !important;
}
.indigo-amber.dark app-role-user-find .work-space .side .container .section-resume .line .header mat-icon:not(.complex) {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-amber.dark app-role-user-find .work-space .side .container .section-resume .line .header .info h4 {
  color: #9fa8da;
}
.indigo-amber.dark app-role-user-find .work-space .side .container .section-resume .line ul {
  color: #9fa8da;
}
.indigo-amber.dark app-role-user-find .work-space .side .container .section-resume .line.head {
  background: black;
}
.indigo-amber.dark app-role-user-find .work-space .side .container .section-resume .line.head mat-icon {
  color: #ffe082;
}
.indigo-amber.dark app-role-user-find .work-space .side .container .section-resume .line.head h4 {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee-title {
  color: #9fa8da;
}
.indigo-amber.dark app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body {
  background: #424242;
}
.indigo-amber.dark app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-amber.dark app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #9fa8da;
}
.indigo-amber.dark app-role-user-find .work-space .side.edition .toolbar .header {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark app-role-user-find .work-space .side.edition .toolbar .header mat-icon {
  color: #ffe082;
}
.indigo-amber.dark app-role-user-find .work-space .side.edition .toolbar .header h3 {
  color: #ffe082;
}
.indigo-amber.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body {
  background: #424242 !important;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header mat-icon:not(.complex) {
  color: #9fa8da;
}
.indigo-amber.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header h4 {
  color: #9fa8da;
}
.indigo-amber.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-actions {
  background: #303030;
}
.indigo-amber.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.indigo-amber.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header .icon-complex mat-icon.complex {
  color: #ffe082 !important;
}
.indigo-amber.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header h4 {
  color: #ffe082;
}
.indigo-amber.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line ul {
  color: #ffe082;
}
.indigo-amber.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head mat-icon {
  color: #ffe082 !important;
}
.indigo-amber.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head h4 {
  color: white;
}
.indigo-amber.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .section-title {
  color: white !important;
}
.indigo-amber.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume h6.hint {
  background: #ffe082;
}
.indigo-amber.dark app-role-user-find .work-space app-role-user-detail.disabled .side .toolbar .header {
  color: #607D8B !important;
}
.indigo-amber.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container .role-resume {
  background: #607D8B !important;
}
.indigo-amber.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-title {
  color: #607D8B !important;
}
.indigo-amber.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line ul {
  color: #607D8B !important;
}
.indigo-amber.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header mat-icon {
  color: #607D8B;
}
.indigo-amber.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .icon-complex mat-icon {
  color: #607D8B !important;
}
.indigo-amber.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #607D8B !important;
}
.indigo-amber.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .info h4 {
  color: #607D8B !important;
}
.indigo-amber.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee-title {
  color: #607D8B;
}
.indigo-amber.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: #607D8B;
}
.indigo-amber.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #607D8B;
}
.indigo-amber.dark app-actor-find .main-overlay {
  background: rgba(0, 0, 0, 0.32);
}
.indigo-amber.dark app-actor-find .icon-complex mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.indigo-amber.dark app-actor-find .icon-complex mat-icon.complex {
  color: #9fa8da !important;
}
.indigo-amber.dark app-actor-find .navigation {
  background: #303030;
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark app-actor-find .navigation *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.indigo-amber.dark app-actor-find .navigation *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
.indigo-amber.dark app-actor-find .navigation .load-window {
  background: black;
}
.indigo-amber.dark app-actor-find .navigation .toolbar {
  background: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark app-actor-find .navigation .toolbar.reverse {
  background: #424242;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark app-actor-find .navigation .container .helper {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-amber.dark app-actor-find .navigation .container .item {
  background: #424242;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark app-actor-find .navigation .container .item:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-amber.dark app-actor-find .navigation .container .item.active {
  background: #9fa8da !important;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #9fa8da;
}
.indigo-amber.dark app-actor-find .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.indigo-amber.dark app-actor-find .work-space .main .load-window {
  background: #424242 !important;
}
.indigo-amber.dark app-actor-find .work-space .main.side-open {
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark app-actor-find .work-space .main .container .item {
  background: #303030;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark app-actor-find .work-space .main .container .item .subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-amber.dark app-actor-find .work-space .main .container .item.disabled {
  background: black;
  color: #607D8B !important;
}
.indigo-amber.dark app-actor-find .work-space .main .container .item.disabled h3 {
  color: #607D8B !important;
}
.indigo-amber.dark app-actor-find .work-space .main .container .item.disabled mat-icon {
  color: #607D8B !important;
}
.indigo-amber.dark app-actor-find .work-space .main .container .item.disabled.selected {
  background: #607D8B;
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-amber.dark app-actor-find .work-space .main .container .item.disabled.selected h3 {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-amber.dark app-actor-find .work-space .main .container .item.disabled.selected mat-icon {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-amber.dark app-actor-find .work-space .main .container .item.disabled.selected mat-icon.complex {
  color: rgba(0, 0, 0, 0.87) !important;
  text-shadow: -2px 0 0px #607D8B, 0 -2px 0px #607D8B, 2px 0 0px #607D8B, 0 2px 0px #607D8B, -2px -2px 0px #607D8B, 2px 2px 0px #607D8B, 2px -2px 0px #607D8B, -2px 2px 0px #607D8B !important;
}
.indigo-amber.dark app-actor-find .work-space .main .container .item mat-icon.complex {
  text-shadow: -2px 0 0px black, 0 -2px 0px black, 2px 0 0px black, 0 2px 0px black, -2px -2px 0px black, 2px 2px 0px black, 2px -2px 0px black, -2px 2px 0px black !important;
}
.indigo-amber.dark app-actor-find .work-space .main .container .item h5 {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-amber.dark app-actor-find .work-space .main .container .item h3 {
  color: white;
}
.indigo-amber.dark app-actor-find .work-space .main .container .item:hover {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-amber.dark app-actor-find .work-space .main .container .item.selected {
  background: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark app-actor-find .work-space .main .container .item.selected mat-icon {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark app-actor-find .work-space .main .container .item.selected mat-icon.complex {
  color: rgba(0, 0, 0, 0.87) !important;
  text-shadow: -2px 0 0px #9fa8da, 0 -2px 0px #9fa8da, 2px 0 0px #9fa8da, 0 2px 0px #9fa8da, -2px -2px 0px #9fa8da, 2px 2px 0px #9fa8da, 2px -2px 0px #9fa8da, -2px 2px 0px #9fa8da !important;
}
.indigo-amber.dark app-actor-find .work-space .main .container .item.selected h5,
.indigo-amber.dark app-actor-find .work-space .main .container .item.selected h3 {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark app-actor-find .work-space .main .main-action {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark app-actor-find .work-space .side {
  background: #303030;
}
.indigo-amber.dark app-actor-find .work-space .side .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #303030, 0 -2px 0px #303030, 2px 0 0px #303030, 0 2px 0px #303030, -2px -2px 0px #303030, 2px 2px 0px #303030, 2px -2px 0px #303030, -2px 2px 0px #303030;
}
.indigo-amber.dark app-actor-find .work-space .side *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.indigo-amber.dark app-actor-find .work-space .side *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
.indigo-amber.dark app-actor-find .work-space .side .edition-line {
  background: black;
}
.indigo-amber.dark app-actor-find .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #303030 !important;
}
.indigo-amber.dark app-actor-find .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-amber.dark app-actor-find .work-space .side mat-form-field .mat-form-field-flex {
  background: #303030;
}
.indigo-amber.dark app-actor-find .work-space .side mat-checkbox:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-amber.dark app-actor-find .work-space .side .hint.all {
  color: #ffe082;
}
.indigo-amber.dark app-actor-find .work-space .side .toolbar .header {
  color: #9fa8da;
}
.indigo-amber.dark app-actor-find .work-space .side .toolbar .header h3 {
  color: #9fa8da;
}
.indigo-amber.dark app-actor-find .work-space .side .container .role-resume {
  background: #9fa8da;
}
.indigo-amber.dark app-actor-find .work-space .side .container .role-resume .line {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark app-actor-find .work-space .side .container .role-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.1);
}
.indigo-amber.dark app-actor-find .work-space .side .container .role-resume .line.editable button {
  background: #9fa8da;
}
.indigo-amber.dark app-actor-find .work-space .side .container .section-title {
  color: #9fa8da;
}
.indigo-amber.dark app-actor-find .work-space .side .container .section-resume {
  background: #424242;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark app-actor-find .work-space .side .container .section-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-amber.dark app-actor-find .work-space .side .container .section-resume .line.editable:hover .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #424242, 0 -2px 0px #424242, 2px 0 0px #424242, 0 2px 0px #424242, -2px -2px 0px #424242, 2px 2px 0px #424242, 2px -2px 0px #424242, -2px 2px 0px #424242 !important;
}
.indigo-amber.dark app-actor-find .work-space .side .container .section-resume .line.editable button {
  background: #303030;
}
.indigo-amber.dark app-actor-find .work-space .side .container .section-resume .line .header .icon-complex mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.indigo-amber.dark app-actor-find .work-space .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #9fa8da !important;
}
.indigo-amber.dark app-actor-find .work-space .side .container .section-resume .line .header mat-icon:not(.complex) {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-amber.dark app-actor-find .work-space .side .container .section-resume .line .header .info h4 {
  color: #9fa8da;
}
.indigo-amber.dark app-actor-find .work-space .side .container .section-resume .line ul {
  color: #9fa8da;
}
.indigo-amber.dark app-actor-find .work-space .side .container .section-resume .line.head {
  background: black;
}
.indigo-amber.dark app-actor-find .work-space .side .container .section-resume .line.head mat-icon {
  color: #ffe082;
}
.indigo-amber.dark app-actor-find .work-space .side .container .section-resume .line.head h4 {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee-title {
  color: #9fa8da;
}
.indigo-amber.dark app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body {
  background: #424242;
}
.indigo-amber.dark app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-amber.dark app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #9fa8da;
}
.indigo-amber.dark app-actor-find .work-space .side.edition .toolbar .header {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark app-actor-find .work-space .side.edition .toolbar .header mat-icon {
  color: #ffe082;
}
.indigo-amber.dark app-actor-find .work-space .side.edition .toolbar .header h3 {
  color: #ffe082;
}
.indigo-amber.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body {
  background: #424242 !important;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header mat-icon:not(.complex) {
  color: #9fa8da;
}
.indigo-amber.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header h4 {
  color: #9fa8da;
}
.indigo-amber.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-actions {
  background: #303030;
}
.indigo-amber.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.indigo-amber.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header .icon-complex mat-icon.complex {
  color: #ffe082 !important;
}
.indigo-amber.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header h4 {
  color: #ffe082;
}
.indigo-amber.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line ul {
  color: #ffe082;
}
.indigo-amber.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head mat-icon {
  color: #ffe082 !important;
}
.indigo-amber.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head h4 {
  color: white;
}
.indigo-amber.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .section-title {
  color: white !important;
}
.indigo-amber.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume h6.hint {
  background: #ffe082;
}
.indigo-amber.dark app-actor-find .work-space app-role-user-detail.disabled .side .toolbar .header {
  color: #607D8B !important;
}
.indigo-amber.dark app-actor-find .work-space app-role-user-detail.disabled .side .container .role-resume {
  background: #607D8B !important;
}
.indigo-amber.dark app-actor-find .work-space app-role-user-detail.disabled .side .container .section-title {
  color: #607D8B !important;
}
.indigo-amber.dark app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line ul {
  color: #607D8B !important;
}
.indigo-amber.dark app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header mat-icon {
  color: #607D8B;
}
.indigo-amber.dark app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .icon-complex mat-icon {
  color: #607D8B !important;
}
.indigo-amber.dark app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #607D8B !important;
}
.indigo-amber.dark app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .info h4 {
  color: #607D8B !important;
}
.indigo-amber.dark app-actor-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee-title {
  color: #607D8B;
}
.indigo-amber.dark app-actor-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: #607D8B;
}
.indigo-amber.dark app-actor-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #607D8B;
}
.indigo-amber.dark .dropzone {
  border: 2px dashed #607D8B;
  background: black !important;
}
.indigo-amber.dark .dropzone.hovering {
  border: 2px solid #607D8B;
  color: rgba(255, 255, 255, 0.5) !important;
}
.indigo-amber.dark .dropzone button.mat-stroked-button {
  background: #424242;
}
.indigo-amber.dark .firmador .content-action-main {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-amber.dark .firmador .content-action-main.disabled {
  color: rgba(255, 255, 255, 0.5) !important;
  background: rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark .sign-cycle {
  background: #424242;
}
.indigo-amber.dark .sign-cycle button.success {
  background-color: #009688;
}
.indigo-amber.dark .sign-cycle button.warning {
  background-color: #673AB7;
}
.indigo-amber.dark .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-title-container .cycle-content-title h4 {
  color: #607D8B;
}
.indigo-amber.dark .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-action .content-action-main {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-amber.dark .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-action .content-action-main.mat-stroked-button:disabled {
  color: rgba(255, 255, 255, 0.5) !important;
}
.indigo-amber.dark .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-action .content-action-main.mat-stroked-button:not(:disabled) {
  color: #ffe082 !important;
}
.indigo-amber.dark .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-action .content-action-side u {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-amber.dark .resume-status {
  background: #424242;
}
.indigo-amber.dark .help-hoverable mat-icon {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-amber.dark .help-hoverable mat-hint {
  background: #424242;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark .help-hoverable mat-hint b {
  color: #9fa8da !important;
}
.indigo-amber.dark app-file-document-sign-massive-dialog .dialog-content,
.indigo-amber.dark app-share-person-dialog .dialog-content {
  background: #303030;
}
.indigo-amber.dark app-file-document-sign-massive-dialog .dialog-content mat-toolbar mat-toolbar-row .mat-avatar h3,
.indigo-amber.dark app-share-person-dialog .dialog-content mat-toolbar mat-toolbar-row .mat-avatar h3 {
  color: #9fa8da;
}
.indigo-amber.dark app-file-document-sign-massive-dialog .dialog-content .dialog-body p b,
.indigo-amber.dark app-share-person-dialog .dialog-content .dialog-body p b {
  color: #ffe082;
}
.indigo-amber.dark app-file-document-sign-massive-dialog .dialog-content app-file-document-sign,
.indigo-amber.dark app-share-person-dialog .dialog-content app-file-document-sign {
  background: #424242;
}
.indigo-amber.dark .mat-basic-chip {
  background: black !important;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark .mat-basic-chip.seen {
  border-color: #2196F3 !important;
  color: #2196F3 !important;
}
.indigo-amber.dark .mat-basic-chip.unseen {
  border-color: #607D8B !important;
  color: #607D8B !important;
}
.indigo-amber.dark .mat-basic-chip.signed-ok {
  border-color: #009688 !important;
  color: #009688 !important;
}
.indigo-amber.dark .mat-basic-chip.signed-ok.cancelled {
  border-color: #795548 !important;
  color: #795548 !important;
}
.indigo-amber.dark .mat-basic-chip.signed-not-ok {
  border-color: #673AB7 !important;
  color: #673AB7 !important;
}
.indigo-amber.dark .mat-basic-chip.not-signed {
  border-color: #ff4081 !important;
  color: #ff4081 !important;
}
.indigo-amber.dark .mat-basic-chip.not-signed-employer {
  border-color: #ff4081 !important;
  color: #ff4081 !important;
}
.indigo-amber.dark app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip {
  background: black !important;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.seen {
  border-color: #2196F3 !important;
  color: #2196F3 !important;
}
.indigo-amber.dark app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.unseen {
  border-color: #607D8B !important;
  color: #607D8B !important;
}
.indigo-amber.dark app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.signed-ok {
  border-color: #009688 !important;
  color: #009688 !important;
}
.indigo-amber.dark app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.signed-ok.cancelled {
  border-color: #795548 !important;
  color: #795548 !important;
}
.indigo-amber.dark app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.signed-not-ok {
  border-color: #673AB7 !important;
  color: #673AB7 !important;
}
.indigo-amber.dark app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.not-signed {
  border-color: #ff4081 !important;
  color: #ff4081 !important;
}
.indigo-amber.dark app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.not-signed-employer {
  border-color: #ff4081 !important;
  color: #ff4081 !important;
}
.indigo-amber.dark .inbox-container.employee.my-documents .chip-line.mat-line .mat-chip-list-wrapper .mat-basic-chip.seen {
  color: #607D8B !important;
}
.indigo-amber.dark .resume-status .mat-basic-chip {
  background: black !important;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark .resume-status .mat-basic-chip.signed-ok {
  border-color: #009688 !important;
  background-color: #009688 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-amber.dark .resume-status .mat-basic-chip.signed-ok.cancelled {
  border-color: #795548 !important;
  background-color: #795548 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-amber.dark .resume-status .mat-basic-chip.signed-not-ok {
  border-color: #673AB7 !important;
  background-color: #673AB7 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-amber.dark app-file-document-view .new-container app-file-document-upload {
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark app-file-document-view .new-container app-upload-form .file-upload-list .mat-subheader {
  background: #424242;
}
.indigo-amber.dark app-file-document-view .new-container app-upload-form .file-upload-list mat-list-item {
  background: #303030 !important;
}
.indigo-amber.dark app-file-document-view .preview-content {
  background: #607D8B;
}
.indigo-amber.dark app-file-document-view .preview-content h4 {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark app-file-document-view .view-container {
  background: #424242;
}
.indigo-amber.dark app-file-document-view .view-container mat-toolbar.side-bar {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark .formio-hint {
  background: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark mat-dialog-container app-file-document-state-chips {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark app-candidate-promotion .load-window.message h3.primary-text {
  color: #9fa8da;
}
.indigo-amber.dark app-multiple-documentation-add-dialog .icon-complex mat-icon,
.indigo-amber.dark app-documentation-type-set-configuration-dialog .icon-complex mat-icon,
.indigo-amber.dark app-add-candidate-dialog .icon-complex mat-icon,
.indigo-amber.dark app-share-person-dialog .icon-complex mat-icon,
.indigo-amber.dark app-share-person-response-dialog .icon-complex mat-icon {
  color: white !important;
}
.indigo-amber.dark app-multiple-documentation-add-dialog .icon-complex mat-icon.complex,
.indigo-amber.dark app-documentation-type-set-configuration-dialog .icon-complex mat-icon.complex,
.indigo-amber.dark app-add-candidate-dialog .icon-complex mat-icon.complex,
.indigo-amber.dark app-share-person-dialog .icon-complex mat-icon.complex,
.indigo-amber.dark app-share-person-response-dialog .icon-complex mat-icon.complex {
  color: #9fa8da !important;
}
.indigo-amber.dark app-multiple-documentation-add-dialog .icon-complex mat-icon.complex.mat-accent,
.indigo-amber.dark app-documentation-type-set-configuration-dialog .icon-complex mat-icon.complex.mat-accent,
.indigo-amber.dark app-add-candidate-dialog .icon-complex mat-icon.complex.mat-accent,
.indigo-amber.dark app-share-person-dialog .icon-complex mat-icon.complex.mat-accent,
.indigo-amber.dark app-share-person-response-dialog .icon-complex mat-icon.complex.mat-accent {
  color: #ffe082;
}
.indigo-amber.dark app-multiple-documentation-add-dialog .icon-complex mat-icon.complex.side,
.indigo-amber.dark app-documentation-type-set-configuration-dialog .icon-complex mat-icon.complex.side,
.indigo-amber.dark app-add-candidate-dialog .icon-complex mat-icon.complex.side,
.indigo-amber.dark app-share-person-dialog .icon-complex mat-icon.complex.side,
.indigo-amber.dark app-share-person-response-dialog .icon-complex mat-icon.complex.side {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection,
.indigo-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection,
.indigo-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection,
.indigo-amber.dark app-share-person-dialog .dialog-content .document-template-container .document-selection,
.indigo-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection {
  background: #303030;
}
.indigo-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.indigo-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.indigo-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3,
.indigo-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.indigo-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.indigo-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3,
.indigo-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.indigo-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.indigo-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3,
.indigo-amber.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.indigo-amber.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.indigo-amber.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3,
.indigo-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.indigo-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.indigo-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3 {
  color: #ffe082 !important;
}
.indigo-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon,
.indigo-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon,
.indigo-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon,
.indigo-amber.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon,
.indigo-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon {
  color: #9fa8da;
}
.indigo-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3,
.indigo-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3,
.indigo-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3,
.indigo-amber.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3,
.indigo-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3 {
  color: #9fa8da;
}
.indigo-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex,
.indigo-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex,
.indigo-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex,
.indigo-amber.dark app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex,
.indigo-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex {
  color: #ffe082 !important;
  text-shadow: -2px 0 0px black, 0 -2px 0px black, 2px 0 0px black, 0 2px 0px black, -2px -2px 0px black, 2px 2px 0px black, 2px -2px 0px black, -2px 2px 0px #424242;
}
.indigo-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex,
.indigo-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex,
.indigo-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex,
.indigo-amber.dark app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex,
.indigo-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex {
  color: rgba(255, 255, 255, 0.5) !important;
}
.indigo-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b,
.indigo-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b,
.indigo-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b,
.indigo-amber.dark app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b,
.indigo-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b {
  color: white;
}
.indigo-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex,
.indigo-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex,
.indigo-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex,
.indigo-amber.dark app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex,
.indigo-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex {
  color: rgba(255, 255, 255, 0.5) !important;
}
.indigo-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex,
.indigo-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex,
.indigo-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex,
.indigo-amber.dark app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex,
.indigo-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex {
  color: rgba(255, 255, 255, 0.5) !important;
}
.indigo-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover,
.indigo-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover,
.indigo-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover,
.indigo-amber.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover,
.indigo-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b,
.indigo-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b,
.indigo-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b,
.indigo-amber.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b,
.indigo-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b {
  color: white;
}
.indigo-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.indigo-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.indigo-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.indigo-amber.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.indigo-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble {
  background: #9fa8da;
}
.indigo-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble,
.indigo-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble,
.indigo-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble,
.indigo-amber.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble,
.indigo-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble {
  background: #e57373;
}
.indigo-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble,
.indigo-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble,
.indigo-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble,
.indigo-amber.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble,
.indigo-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble {
  color: rgba(255, 255, 255, 0.5);
  background: rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble,
.indigo-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble,
.indigo-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble,
.indigo-amber.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble,
.indigo-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble {
  background: rgba(255, 255, 255, 0.5);
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide,
.indigo-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide,
.indigo-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide,
.indigo-amber.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide,
.indigo-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide {
  border-color: rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble,
.indigo-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble,
.indigo-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble,
.indigo-amber.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble,
.indigo-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.indigo-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.indigo-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.indigo-amber.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.indigo-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble {
  color: #9fa8da;
}
.indigo-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble,
.indigo-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble,
.indigo-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble,
.indigo-amber.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble,
.indigo-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble {
  color: #e57373;
}
.indigo-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar,
.indigo-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar,
.indigo-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar,
.indigo-amber.dark app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar,
.indigo-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar {
  background: #607D8B;
}
.indigo-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row,
.indigo-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row,
.indigo-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row,
.indigo-amber.dark app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row,
.indigo-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper,
.indigo-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper,
.indigo-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper,
.indigo-amber.dark app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper,
.indigo-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper {
  background: #607D8B;
}
.indigo-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop,
.indigo-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop,
.indigo-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop,
.indigo-amber.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop,
.indigo-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop {
  border-left: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body,
.indigo-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body,
.indigo-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body,
.indigo-amber.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body,
.indigo-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body {
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3,
.indigo-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3,
.indigo-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3,
.indigo-amber.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3,
.indigo-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3 {
  color: #ffe082;
}
.indigo-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5,
.indigo-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5,
.indigo-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5,
.indigo-amber.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5,
.indigo-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5 {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5,
.indigo-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5,
.indigo-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5,
.indigo-amber.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5,
.indigo-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5 {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator,
.indigo-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator,
.indigo-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator,
.indigo-amber.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator,
.indigo-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  background: #303030;
}
.indigo-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip,
.indigo-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip,
.indigo-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip,
.indigo-amber.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip,
.indigo-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip {
  background: #424242 !important;
}
.indigo-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon,
.indigo-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon,
.indigo-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon,
.indigo-amber.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon,
.indigo-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon {
  color: #ffe082 !important;
}
.indigo-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3,
.indigo-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3,
.indigo-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3,
.indigo-amber.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3,
.indigo-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3 {
  color: #ffe082 !important;
}
.indigo-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5,
.indigo-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5,
.indigo-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5,
.indigo-amber.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5,
.indigo-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5 {
  color: rgba(255, 255, 255, 0.7) !important;
}
.indigo-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon,
.indigo-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon,
.indigo-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon,
.indigo-amber.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon,
.indigo-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon {
  color: #9fa8da !important;
}
.indigo-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3,
.indigo-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3,
.indigo-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3,
.indigo-amber.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3,
.indigo-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3 {
  color: #9fa8da !important;
}
.indigo-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5,
.indigo-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5,
.indigo-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5,
.indigo-amber.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5,
.indigo-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5 {
  color: #9fa8da !important;
}
.indigo-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled,
.indigo-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled,
.indigo-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled,
.indigo-amber.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled,
.indigo-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled {
  background: #303030 !important;
  color: rgba(255, 255, 255, 0.5);
}
.indigo-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove,
.indigo-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove,
.indigo-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove,
.indigo-amber.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove,
.indigo-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove,
.indigo-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove,
.indigo-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove,
.indigo-amber.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove,
.indigo-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove {
  color: rgba(255, 255, 255, 0.7) !important;
}
.indigo-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .preview-action,
.indigo-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .preview-action,
.indigo-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .preview-action,
.indigo-amber.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .preview-action,
.indigo-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .preview-action {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark .tyc .sticky {
  background: #424242;
}
.indigo-amber.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper:nth-child(odd) datatable-body-row {
  background: #303030;
}
.indigo-amber.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active * {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-amber.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active:hover .datatable-body-cell-label {
  background: #9fa8da;
}
.indigo-amber.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active:hover .cs-floating-action {
  background: #9fa8da !important;
}
.indigo-amber.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active.row-cancel * {
  color: rgba(255, 255, 255, 0.7) !important;
}
.indigo-amber.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active.row-cancel .datatable-row-group {
  background: #607D8B !important;
}
.indigo-amber.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active.row-cancel:hover .datatable-body-cell-label {
  background: #607D8B;
}
.indigo-amber.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.row-cancel * {
  color: #b0bec5 !important;
  transition-property: color;
  transition-duration: 0.3s;
  transition-timing-function: linear;
}
.indigo-amber.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row:hover .datatable-body-cell-label button {
  background: black;
}
.indigo-amber.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row datatable-body-cell.candidate-status_bar mat-progress-bar .mat-progress-bar-buffer {
  background-color: black;
}
.indigo-amber.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row datatable-body-cell.candidate-status_bar .status_bar-count {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-amber.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row datatable-body-cell.candidate-status_bar .inactive {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-amber.dark .cs-grid-test .info {
  background: #424242;
}
.indigo-amber.dark .cs-grid-test .selected-column {
  background: #424242;
  -webkit-box-shadow: 0 1px 3px 0 rgba(255, 255, 255, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0 1px 3px 0 rgba(255, 255, 255, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 3px 0 rgba(255, 255, 255, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
}
.indigo-amber.dark .cs-grid-test .selection-cell .datatable-body-cell.active {
  background: #9fa8da !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-amber.dark .cs-grid-test nav {
  background: #424242;
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 13px 19px 2px rgba(0, 0, 0, 0.14), 0 5px 24px 4px rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark .cs-grid-test .ngx-datatable.material {
  background: #424242;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark .cs-grid-test .ngx-datatable.material.striped .datatable-row-odd {
  background: #303030;
}
.indigo-amber.dark .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active, .indigo-amber.dark .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active .datatable-row-group, .indigo-amber.dark .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active, .indigo-amber.dark .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active .datatable-row-group, .indigo-amber.dark .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active, .indigo-amber.dark .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active .datatable-row-group {
  background: #9fa8da;
  color: #424242;
}
.indigo-amber.dark .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active:hover, .indigo-amber.dark .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active:hover .datatable-row-group, .indigo-amber.dark .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active:hover, .indigo-amber.dark .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active:hover .datatable-row-group, .indigo-amber.dark .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active:hover, .indigo-amber.dark .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active:hover .datatable-row-group {
  background: #9fa8da;
  color: #424242;
}
.indigo-amber.dark .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active:focus, .indigo-amber.dark .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active:focus .datatable-row-group, .indigo-amber.dark .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active:focus, .indigo-amber.dark .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active:focus .datatable-row-group, .indigo-amber.dark .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active:focus, .indigo-amber.dark .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active:focus .datatable-row-group {
  background: #9fa8da;
  color: #424242;
}
.indigo-amber.dark .cs-grid-test .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover, .indigo-amber.dark .cs-grid-test .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-amber.dark .cs-grid-test .ngx-datatable.material:not(.cell-selection) .datatable-body-row:focus, .indigo-amber.dark .cs-grid-test .ngx-datatable.material:not(.cell-selection) .datatable-body-row:focus .datatable-row-group {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-amber.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell:hover, .indigo-amber.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell:hover .datatable-row-group {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-amber.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell:focus, .indigo-amber.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell:focus .datatable-row-group {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-amber.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active, .indigo-amber.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active .datatable-row-group {
  background: #9fa8da;
  color: #424242;
}
.indigo-amber.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active:hover, .indigo-amber.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active:hover .datatable-row-group {
  background: #9fa8da;
  color: #424242;
}
.indigo-amber.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active:focus, .indigo-amber.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active:focus .datatable-row-group {
  background: #9fa8da;
  color: #424242;
}
.indigo-amber.dark .cs-grid-test .ngx-datatable.material .datatable-header .datatable-row-left,
.indigo-amber.dark .cs-grid-test .ngx-datatable.material .datatable-body .datatable-row-left {
  background-color: #424242;
}
.indigo-amber.dark .cs-grid-test .ngx-datatable.material .datatable-header .datatable-row-right,
.indigo-amber.dark .cs-grid-test .ngx-datatable.material .datatable-body .datatable-row-right {
  background-color: #424242;
}
.indigo-amber.dark .cs-grid-test .ngx-datatable.material .datatable-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.indigo-amber.dark .cs-grid-test .ngx-datatable.material .datatable-header .datatable-header-cell {
  background-color: #424242;
  color: rgba(255, 255, 255, 0.7);
}
.indigo-amber.dark .cs-grid-test .ngx-datatable.material .datatable-header .resize-handle {
  border-right: solid 1px rgba(255, 255, 255, 0.04);
}
.indigo-amber.dark .cs-grid-test .ngx-datatable.material .datatable-body .datatable-row-detail {
  background: black;
}
.indigo-amber.dark .cs-grid-test .ngx-datatable.material .datatable-body .datatable-group-header {
  background: black;
  border-bottom: solid 1px rgba(255, 255, 255, 0.12);
  border-top: solid 1px rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark .cs-grid-test .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
  color: white;
}
.indigo-amber.dark .cs-grid-test .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-group-cell {
  color: white;
}
.indigo-amber.dark .cs-grid-test .ngx-datatable.material .datatable-body .progress-linear .container {
  background-color: #424242;
}
.indigo-amber.dark .cs-grid-test .ngx-datatable.material .datatable-body .progress-linear .container .bar {
  background-color: #9fa8da;
}
.indigo-amber.dark .cs-grid-test .ngx-datatable.material .datatable-footer {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-amber.dark .cs-grid-test .ngx-datatable.material .datatable-footer .datatable-pager li.disabled a {
  color: rgba(255, 255, 255, 0.5) !important;
  background-color: rgba(0, 0, 0, 0) !important;
}
.indigo-amber.dark .cs-grid-test .ngx-datatable.material .datatable-footer .datatable-pager li.active a {
  background-color: #9fa8da;
}
.indigo-amber.dark .cs-grid-test .ngx-datatable.material .datatable-footer .datatable-pager a {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-amber.dark .cs-grid-test .ngx-datatable.material .datatable-footer .datatable-pager a:hover {
  color: rgba(255, 255, 255, 0.04);
  background-color: rgba(255, 255, 255, 0.04);
}
.indigo-amber.dark .cs-grid-test .ngx-datatable.material .datatable-summary-row .datatable-body-row {
  background-color: #303030;
}
.indigo-amber.dark .cs-grid-test .ngx-datatable.material .datatable-summary-row .datatable-body-row:hover {
  background-color: #303030;
}
.indigo-amber.dark .cs-grid-test .datatable-checkbox input[type=checkbox]:after {
  background: #424242;
}
.indigo-amber.dark .view-container .view-toolbar {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal.new.image-opened .help-content img {
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card {
  background: #424242;
}
.indigo-amber.dark app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container .aditional-info {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-amber.dark app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container .mat-card-footer {
  background: #424242;
}
.indigo-amber.dark app-help-index mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card {
  background: #424242;
}
.indigo-amber.dark app-help-index mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card .mat-card-footer {
  background: #424242;
}
.indigo-amber.dark .cropper .employee-title .title-text {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-amber.dark .cropper app-cs-upload-cropper-control .preview h5 {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-amber.dark app-file-document-upload .preview-container {
  background: #607D8B !important;
}
.indigo-amber.dark app-file-document-upload .preview-container h4 {
  color: black;
}
.indigo-amber.dark .preview-container {
  background: #607D8B !important;
}
.indigo-amber.dark .preview-container h4 {
  color: black;
}
.indigo-amber.dark .clean-autocomple mat-list {
  background: #424242;
}
.indigo-amber.dark .clean-autocomple mat-list .mat-list-item:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-amber.dark .clean-autocomple mat-list .mat-list-item .mat-list-item-content .mat-list-text {
  color: #9fa8da;
}
.indigo-amber.dark app-leave .inbox-toolbar {
  background: #303030;
}
.indigo-amber.dark app-leave .mat-tab-body-wrapper {
  position: relative;
  overflow: hidden;
  display: flex;
  transition: height 500ms cubic-bezier(0.35, 0, 0.25, 1);
  height: 95% !important;
}
.indigo-amber.dark app-leave .leave_container {
  background: #303030;
}
.indigo-amber.dark app-leave .leave_container .leave_items .leave_items-item.ok .content .leave_item-status_icon {
  color: #4db6ac;
}
.indigo-amber.dark app-leave .leave_container .leave_items .leave_items-item.not-ok .content .leave_item-status_icon {
  color: #ba68c8;
}
.indigo-amber.dark app-leave .leave_container .leave_items .leave_items-item.pending .content .leave_item-status_icon {
  color: #ffb74d;
}
.indigo-amber.dark app-leave .leave_container .leave_items .leave_items-item.draft .content .leave_item-status_icon {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-amber.dark app-leave .leave_container .leave_items .leave_items-item.cancel .content .leave_item-status_icon {
  color: #795548;
}
.indigo-amber.dark app-leave .leave_container .leave_items .leave_items-item .content:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-amber.dark app-leave .leave_container .leave_items .leave_items-item .content.selected {
  border-left: 6px solid #ffe082 !important;
}
.indigo-amber.dark app-leave .leave_container .leave_items .leave_items-item .content.selected mat-icon {
  color: #ffe082 !important;
}
.indigo-amber.dark app-leave .leave_container .leave_items .leave_items-item .content.selected .item-data {
  color: white !important;
}
.indigo-amber.dark app-leave .leave_container .leave_items .leave_items-item .content .leave_item-icon {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-amber.dark app-leave .leave_container .leave_items .leave_items-item .content .item-data {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-amber.dark app-leave .leave_container .window-spinner {
  background: #303030 !important;
}
.indigo-amber.dark app-leave-detail .leave_detail .leave_detail-title .info h3,
.indigo-amber.dark app-leave-detail .leave_detail .leave_detail-title .info mat-icon {
  color: #9fa8da;
}
.indigo-amber.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .ok .large-icon {
  background: #e0f2f1;
  color: #4db6ac;
}
.indigo-amber.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .ok h2 {
  color: #4db6ac;
}
.indigo-amber.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .not-ok .large-icon {
  background: #f3e5f5;
  color: #ba68c8;
}
.indigo-amber.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .not-ok h2 {
  color: #ba68c8;
}
.indigo-amber.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .pending .large-icon {
  background: #fff3e0;
  color: #ffb74d;
}
.indigo-amber.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .pending h2 {
  color: #ffb74d;
}
.indigo-amber.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .draft .large-icon {
  background: #f2f2f2;
  color: #dedede;
}
.indigo-amber.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .draft h2 {
  color: #dedede;
}
.indigo-amber.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .cancelled .large-icon {
  background: #BCAAA4;
  color: #795548;
}
.indigo-amber.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .cancelled h2 {
  color: #795548;
}
.indigo-amber.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .actions {
  padding: 16px;
  height: 96px;
  column-gap: 16px;
}
.indigo-amber.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .actions button {
  height: 100%;
  border-radius: 40px;
}
.indigo-amber.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .actions button.ok {
  background: #4db6ac !important;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .actions button.not-ok {
  color: #ba68c8 !important;
}
.indigo-amber.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning mat-basic-chip {
  background: black !important;
  border: 1px solid rgba(255, 255, 255, 0.12) !important;
  border-radius: 40px !important;
  height: 35px !important;
}
.indigo-amber.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning mat-basic-chip.signed-ok {
  border-color: #4db6ac !important;
  color: #4db6ac !important;
}
.indigo-amber.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning mat-basic-chip.signed-not-ok {
  border-color: #ba68c8 !important;
  color: #ba68c8 !important;
}
.indigo-amber.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning mat-basic-chip.draft {
  border-color: #dedede !important;
  color: #dedede !important;
}
.indigo-amber.dark app-leave-request-detail .load-window {
  background: #303030;
}
.indigo-amber.dark app-leave-request-detail form .leave_detail-container {
  background: #303030;
}
.indigo-amber.dark app-leave-request-detail form .leave_detail-container .toolbar .header {
  color: #9fa8da;
}
.indigo-amber.dark app-leave-request-detail form .leave_detail-container .toolbar .header .text h3 {
  color: #9fa8da;
}
.indigo-amber.dark app-leave-request-detail form .leave_detail-container .toolbar .header .text h5 {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-amber.dark app-leave-request-detail form .leave_detail-container .leave_detail-container-content .leave_resume {
  background: #424242;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark app-leave-request-detail form .leave_detail-container .leave_detail-container-content .line h6 {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-amber.dark app-leave-request-detail form .leave_detail-container .actions button.ok {
  background: #4db6ac !important;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark app-leave-request-detail form .leave_detail-container .actions button.not-ok {
  color: #ba68c8 !important;
}
.indigo-amber.dark .new_leave_modal-steps {
  display: unset !important;
}
.indigo-amber.dark .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button {
  background: #303030;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button .mat-radio-label .mat-radio-label-content .side-text {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-amber.dark .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button .mat-radio-label .mat-radio-label-content .side-text.muted {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-amber.dark .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button .mat-radio-label .mat-radio-label-content .accent mat-icon,
.indigo-amber.dark .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button .mat-radio-label .mat-radio-label-content .accent .main-text {
  color: #ffe082;
}
.indigo-amber.dark .config_leave-container .config_leave-card {
  background: #424242;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark .config_leave-container .config_leave-card .config_leave-auto_chip mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #303030 !important;
}
.indigo-amber.dark .config_leave-container .config_leave-card .config_leave-auto_chip mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-amber.dark .config_leave-container .config_leave-card .config_leave-auto_chip mat-form-field .mat-form-field-flex {
  background: #303030;
}
.indigo-amber.dark *[color=success] {
  color: #009688;
}
.indigo-amber.dark .primary {
  color: #9fa8da !important;
}
.indigo-amber.dark .accent {
  color: #ffe082 !important;
}
.indigo-amber.dark app-lsd .icon-complex mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.indigo-amber.dark app-lsd .icon-complex mat-icon.complex {
  color: #9fa8da !important;
}
.indigo-amber.dark app-lsd .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.indigo-amber.dark app-lsd .work-space .main .load-window {
  background: #424242 !important;
}
.indigo-amber.dark app-lsd .work-space .main.side-open {
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark app-lsd .work-space .main .container .item {
  background: #303030;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark app-lsd .work-space .main .container .item h5 {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-amber.dark app-lsd .work-space .main .container .item h3 {
  color: white;
}
.indigo-amber.dark app-lsd .work-space .main .container .item:hover {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-amber.dark app-lsd .work-space .main .container .item.selected {
  background: #9fa8da;
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-amber.dark app-lsd .work-space .main .container .item.selected h3 {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-amber.dark app-lsd .work-space .main .container .item.selected.pending .status {
  background: rgba(255, 255, 255, 0.12) !important;
  color: #ffe082;
}
.indigo-amber.dark app-lsd .work-space .main .container .item.selected.ok .status {
  background: rgba(255, 255, 255, 0.12) !important;
  color: #a5d6a7;
}
.indigo-amber.dark app-lsd .work-space .main .container .item.selected.not-ok .status {
  background: rgba(255, 255, 255, 0.12) !important;
  color: #e57373;
}
.indigo-amber.dark app-lsd .work-space .main .main-action {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark app-lsd .work-space .side {
  background: #303030;
}
.indigo-amber.dark app-lsd .work-space .side .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #303030, 0 -2px 0px #303030, 2px 0 0px #303030, 0 2px 0px #303030, -2px -2px 0px #303030, 2px 2px 0px #303030, 2px -2px 0px #303030, -2px 2px 0px #303030;
}
.indigo-amber.dark app-lsd .work-space .side *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.indigo-amber.dark app-lsd .work-space .side *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
.indigo-amber.dark app-lsd .work-space .side .edition-line {
  background: black;
}
.indigo-amber.dark app-lsd .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #303030 !important;
}
.indigo-amber.dark app-lsd .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-amber.dark app-lsd .work-space .side mat-form-field .mat-form-field-flex {
  background: #303030;
}
.indigo-amber.dark app-lsd .work-space .side mat-checkbox:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-amber.dark app-lsd .work-space .side .hint.all {
  color: #ffe082;
}
.indigo-amber.dark app-lsd .work-space .side .toolbar .header {
  color: #9fa8da;
}
.indigo-amber.dark app-lsd .work-space .side .toolbar .header h3 {
  color: #9fa8da;
}
.indigo-amber.dark app-lsd .work-space .side .container .doc-resume {
  background: #9fa8da;
}
.indigo-amber.dark app-lsd .work-space .side .container .doc-resume .line {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark app-lsd .work-space .side .container .doc-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.1);
}
.indigo-amber.dark app-lsd .work-space .side .container .doc-resume .line.editable button {
  background: #9fa8da;
}
.indigo-amber.dark app-lsd .work-space .side .container .section-title {
  color: #9fa8da;
}
.indigo-amber.dark app-lsd .work-space .side .container .section-resume {
  background: #424242;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark app-lsd .work-space .side .container .section-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-amber.dark app-lsd .work-space .side .container .section-resume .line.editable:hover .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #424242, 0 -2px 0px #424242, 2px 0 0px #424242, 0 2px 0px #424242, -2px -2px 0px #424242, 2px 2px 0px #424242, 2px -2px 0px #424242, -2px 2px 0px #424242 !important;
}
.indigo-amber.dark app-lsd .work-space .side .container .section-resume .line.editable button {
  background: #303030;
}
.indigo-amber.dark app-lsd .work-space .side .container .section-resume .line .header .icon-complex mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.indigo-amber.dark app-lsd .work-space .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #9fa8da !important;
}
.indigo-amber.dark app-lsd .work-space .side .container .section-resume .line .header mat-icon:not(.complex) {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-amber.dark app-lsd .work-space .side .container .section-resume .line .header .info h4 {
  color: #9fa8da;
}
.indigo-amber.dark app-lsd .work-space .side .container .section-resume .line ul {
  color: #9fa8da;
}
.indigo-amber.dark app-lsd .work-space .side .container .section-resume .line.head {
  background: black;
}
.indigo-amber.dark app-lsd .work-space .side .container .section-resume .line.head mat-icon {
  color: #ffe082;
}
.indigo-amber.dark app-lsd .work-space .side .container .section-resume .line.head h4 {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark app-lsd .work-space .side.edition .toolbar .header {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-amber.dark app-lsd .work-space .side.edition .toolbar .header mat-icon {
  color: #ffe082;
}
.indigo-amber.dark app-lsd .work-space .side.edition .toolbar .header h3 {
  color: #ffe082;
}
.indigo-amber.dark .dialog-content {
  max-height: 90vh;
}
.indigo-amber.dark .dialog-content mat-toolbar {
  background: #424242;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark .dialog-content .dialog-container .document-template-container .document-selection mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #303030 !important;
}
.indigo-amber.dark .dialog-content .dialog-container .document-template-container .document-selection mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-amber.dark .dialog-content .dialog-container .document-template-container .document-selection mat-form-field .mat-form-field-flex {
  background: #303030;
}
.indigo-amber.dark .dialog-content .dialog-container .document-template-container .document-selection app-upload-form .file-upload-list .mat-subheader::before {
  color: #9fa8da;
}
.indigo-amber.dark .dialog-content .dialog-container .view-container {
  border-left: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-amber.dark .dialog-content .form-actions {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}

.indigo-blueGrey .mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-blueGrey .mat-badge-small .mat-badge-content {
  font-size: 9px;
}
.indigo-blueGrey .mat-badge-large .mat-badge-content {
  font-size: 24px;
}
.indigo-blueGrey .mat-h1,
.indigo-blueGrey .mat-headline,
.indigo-blueGrey .mat-typography .mat-h1,
.indigo-blueGrey .mat-typography .mat-headline,
.indigo-blueGrey .mat-typography h1 {
  font: 400 24px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.indigo-blueGrey .mat-h2,
.indigo-blueGrey .mat-title,
.indigo-blueGrey .mat-typography .mat-h2,
.indigo-blueGrey .mat-typography .mat-title,
.indigo-blueGrey .mat-typography h2 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.indigo-blueGrey .mat-h3,
.indigo-blueGrey .mat-subheading-2,
.indigo-blueGrey .mat-typography .mat-h3,
.indigo-blueGrey .mat-typography .mat-subheading-2,
.indigo-blueGrey .mat-typography h3 {
  font: 400 16px / 28px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.indigo-blueGrey .mat-h4,
.indigo-blueGrey .mat-subheading-1,
.indigo-blueGrey .mat-typography .mat-h4,
.indigo-blueGrey .mat-typography .mat-subheading-1,
.indigo-blueGrey .mat-typography h4 {
  font: 400 15px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.indigo-blueGrey .mat-h5,
.indigo-blueGrey .mat-typography .mat-h5,
.indigo-blueGrey .mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}
.indigo-blueGrey .mat-h6,
.indigo-blueGrey .mat-typography .mat-h6,
.indigo-blueGrey .mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}
.indigo-blueGrey .mat-body-strong,
.indigo-blueGrey .mat-body-2,
.indigo-blueGrey .mat-typography .mat-body-strong,
.indigo-blueGrey .mat-typography .mat-body-2 {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.indigo-blueGrey .mat-body,
.indigo-blueGrey .mat-body-1,
.indigo-blueGrey .mat-typography .mat-body,
.indigo-blueGrey .mat-typography .mat-body-1,
.indigo-blueGrey .mat-typography {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.indigo-blueGrey .mat-body p,
.indigo-blueGrey .mat-body-1 p,
.indigo-blueGrey .mat-typography .mat-body p,
.indigo-blueGrey .mat-typography .mat-body-1 p,
.indigo-blueGrey .mat-typography p {
  margin: 0 0 12px;
}
.indigo-blueGrey .mat-small,
.indigo-blueGrey .mat-caption,
.indigo-blueGrey .mat-typography .mat-small,
.indigo-blueGrey .mat-typography .mat-caption {
  font: 400 12px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.indigo-blueGrey .mat-display-4,
.indigo-blueGrey .mat-typography .mat-display-4 {
  font: 300 112px / 112px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}
.indigo-blueGrey .mat-display-3,
.indigo-blueGrey .mat-typography .mat-display-3 {
  font: 400 56px / 56px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}
.indigo-blueGrey .mat-display-2,
.indigo-blueGrey .mat-typography .mat-display-2 {
  font: 400 45px / 48px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}
.indigo-blueGrey .mat-display-1,
.indigo-blueGrey .mat-typography .mat-display-1 {
  font: 400 34px / 40px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}
.indigo-blueGrey .mat-bottom-sheet-container {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.indigo-blueGrey .mat-button, .indigo-blueGrey .mat-raised-button, .indigo-blueGrey .mat-icon-button, .indigo-blueGrey .mat-stroked-button,
.indigo-blueGrey .mat-flat-button, .indigo-blueGrey .mat-fab, .indigo-blueGrey .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.indigo-blueGrey .mat-button-toggle {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-blueGrey .mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-blueGrey .mat-card-title {
  font-size: 24px;
  font-weight: 500;
}
.indigo-blueGrey .mat-card-header .mat-card-title {
  font-size: 20px;
}
.indigo-blueGrey .mat-card-subtitle,
.indigo-blueGrey .mat-card-content {
  font-size: 14px;
}
.indigo-blueGrey .mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-blueGrey .mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}
.indigo-blueGrey .mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.indigo-blueGrey .mat-chip .mat-chip-trailing-icon.mat-icon,
.indigo-blueGrey .mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}
.indigo-blueGrey .mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-blueGrey .mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}
.indigo-blueGrey .mat-cell, .indigo-blueGrey .mat-footer-cell {
  font-size: 14px;
}
.indigo-blueGrey .mat-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-blueGrey .mat-calendar-body {
  font-size: 13px;
}
.indigo-blueGrey .mat-calendar-body-label,
.indigo-blueGrey .mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}
.indigo-blueGrey .mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}
.indigo-blueGrey .mat-dialog-title {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.indigo-blueGrey .mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400;
}
.indigo-blueGrey .mat-expansion-panel-content {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.indigo-blueGrey .mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.indigo-blueGrey .mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}
.indigo-blueGrey .mat-form-field-prefix .mat-icon,
.indigo-blueGrey .mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.indigo-blueGrey .mat-form-field-prefix .mat-icon-button,
.indigo-blueGrey .mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.indigo-blueGrey .mat-form-field-prefix .mat-icon-button .mat-icon,
.indigo-blueGrey .mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}
.indigo-blueGrey .mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}
.indigo-blueGrey .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.indigo-blueGrey .mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34363em) scale(0.75);
  width: 133.3334533333%;
}
.indigo-blueGrey .mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34362em) scale(0.75);
  width: 133.3334633333%;
}
.indigo-blueGrey .mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}
.indigo-blueGrey .mat-form-field-label {
  top: 1.34375em;
}
.indigo-blueGrey .mat-form-field-underline {
  bottom: 1.34375em;
}
.indigo-blueGrey .mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}
.indigo-blueGrey .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.indigo-blueGrey .mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.indigo-blueGrey .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.indigo-blueGrey .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00136px);
  width: 133.3336933333%;
}
.indigo-blueGrey .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00137px);
  width: 133.3337033333%;
}
.indigo-blueGrey .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00138px);
  width: 133.3337133333%;
}
.indigo-blueGrey .mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.indigo-blueGrey .mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.indigo-blueGrey .mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}
@media print {
  .indigo-blueGrey .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.indigo-blueGrey .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28086em) scale(0.75);
  }
  .indigo-blueGrey .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28085em) scale(0.75);
  }
  .indigo-blueGrey .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28084em) scale(0.75);
  }
}
.indigo-blueGrey .mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.indigo-blueGrey .mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.indigo-blueGrey .mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.indigo-blueGrey .mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59363em) scale(0.75);
  width: 133.3334533333%;
}
.indigo-blueGrey .mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59362em) scale(0.75);
  width: 133.3334633333%;
}
.indigo-blueGrey .mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.indigo-blueGrey .mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.indigo-blueGrey .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.indigo-blueGrey .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59363em) scale(0.75);
  width: 133.3334533333%;
}
.indigo-blueGrey .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59362em) scale(0.75);
  width: 133.3334633333%;
}
.indigo-blueGrey .mat-grid-tile-header,
.indigo-blueGrey .mat-grid-tile-footer {
  font-size: 14px;
}
.indigo-blueGrey .mat-grid-tile-header .mat-line,
.indigo-blueGrey .mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.indigo-blueGrey .mat-grid-tile-header .mat-line:nth-child(n+2),
.indigo-blueGrey .mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.indigo-blueGrey input.mat-input-element {
  margin-top: -0.0625em;
}
.indigo-blueGrey .mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
}
.indigo-blueGrey .mat-paginator,
.indigo-blueGrey .mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
}
.indigo-blueGrey .mat-radio-button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-blueGrey .mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-blueGrey .mat-select-trigger {
  height: 1.125em;
}
.indigo-blueGrey .mat-slide-toggle-content {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-blueGrey .mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}
.indigo-blueGrey .mat-stepper-vertical, .indigo-blueGrey .mat-stepper-horizontal {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-blueGrey .mat-step-label {
  font-size: 14px;
  font-weight: 400;
}
.indigo-blueGrey .mat-step-sub-label-error {
  font-weight: normal;
}
.indigo-blueGrey .mat-step-label-error {
  font-size: 14px;
}
.indigo-blueGrey .mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}
.indigo-blueGrey .mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-blueGrey .mat-tab-label, .indigo-blueGrey .mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.indigo-blueGrey .mat-toolbar,
.indigo-blueGrey .mat-toolbar h1,
.indigo-blueGrey .mat-toolbar h2,
.indigo-blueGrey .mat-toolbar h3,
.indigo-blueGrey .mat-toolbar h4,
.indigo-blueGrey .mat-toolbar h5,
.indigo-blueGrey .mat-toolbar h6 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0;
}
.indigo-blueGrey .mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.indigo-blueGrey .mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.indigo-blueGrey .mat-list-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-blueGrey .mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-blueGrey .mat-list-base .mat-list-item {
  font-size: 16px;
}
.indigo-blueGrey .mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.indigo-blueGrey .mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.indigo-blueGrey .mat-list-base .mat-list-option {
  font-size: 16px;
}
.indigo-blueGrey .mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.indigo-blueGrey .mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.indigo-blueGrey .mat-list-base .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.indigo-blueGrey .mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.indigo-blueGrey .mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.indigo-blueGrey .mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.indigo-blueGrey .mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.indigo-blueGrey .mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.indigo-blueGrey .mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.indigo-blueGrey .mat-list-base[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}
.indigo-blueGrey .mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
}
.indigo-blueGrey .mat-optgroup-label {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.indigo-blueGrey .mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
}
.indigo-blueGrey .mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}
.indigo-blueGrey .mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-blueGrey .mat-tree-node,
.indigo-blueGrey .mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}
.indigo-blueGrey .mat-ripple {
  overflow: hidden;
  position: relative;
}
.indigo-blueGrey .mat-ripple:not(:empty) {
  transform: translateZ(0);
}
.indigo-blueGrey .mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}
.indigo-blueGrey .mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .indigo-blueGrey .mat-ripple-element {
  display: none;
}

.indigo-blueGrey .cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .indigo-blueGrey .cdk-visually-hidden {
  left: auto;
  right: 0;
}
.indigo-blueGrey .cdk-overlay-container, .indigo-blueGrey .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.indigo-blueGrey .cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.indigo-blueGrey .cdk-overlay-container:empty {
  display: none;
}
.indigo-blueGrey .cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}
.indigo-blueGrey .cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}
.indigo-blueGrey .cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.indigo-blueGrey .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .indigo-blueGrey .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}
.indigo-blueGrey .cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}
.indigo-blueGrey .cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.indigo-blueGrey .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}
.indigo-blueGrey .cdk-overlay-backdrop-noop-animation {
  transition: none;
}
.indigo-blueGrey .cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}
.indigo-blueGrey .cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}
.indigo-blueGrey textarea.cdk-textarea-autosize {
  resize: none;
}
.indigo-blueGrey textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}
.indigo-blueGrey textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}
@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.indigo-blueGrey .cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}
.indigo-blueGrey .cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}
.indigo-blueGrey .mat-focus-indicator {
  position: relative;
}
.indigo-blueGrey .mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.indigo-blueGrey .mat-focus-indicator:focus::before {
  content: "";
}
.cdk-high-contrast-active .indigo-blueGrey {
  --mat-focus-indicator-display: block;
}

.indigo-blueGrey .mat-mdc-focus-indicator {
  position: relative;
}
.indigo-blueGrey .mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.indigo-blueGrey .mat-mdc-focus-indicator:focus::before {
  content: "";
}
.cdk-high-contrast-active .indigo-blueGrey {
  --mat-mdc-focus-indicator-display: block;
}

.indigo-blueGrey .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.indigo-blueGrey .mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey .mat-option:hover:not(.mat-option-disabled), .indigo-blueGrey .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.indigo-blueGrey .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.indigo-blueGrey .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-blueGrey .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #3f51b5;
}
.indigo-blueGrey .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #607d8b;
}
.indigo-blueGrey .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f44336;
}
.indigo-blueGrey .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-blueGrey .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-blueGrey .mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-blueGrey .mat-pseudo-checkbox::after {
  color: #fafafa;
}
.indigo-blueGrey .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.indigo-blueGrey .mat-primary .mat-pseudo-checkbox-checked,
.indigo-blueGrey .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #3f51b5;
}
.indigo-blueGrey .mat-pseudo-checkbox-checked,
.indigo-blueGrey .mat-pseudo-checkbox-indeterminate,
.indigo-blueGrey .mat-accent .mat-pseudo-checkbox-checked,
.indigo-blueGrey .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #607d8b;
}
.indigo-blueGrey .mat-warn .mat-pseudo-checkbox-checked,
.indigo-blueGrey .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #f44336;
}
.indigo-blueGrey .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.indigo-blueGrey .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.indigo-blueGrey .mat-app-background, .indigo-blueGrey.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.indigo-blueGrey .mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.indigo-blueGrey .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey .mat-badge-content {
  color: white;
  background: #3f51b5;
}
.cdk-high-contrast-active .indigo-blueGrey .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.indigo-blueGrey .mat-badge-accent .mat-badge-content {
  background: #607d8b;
  color: white;
}
.indigo-blueGrey .mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336;
}
.indigo-blueGrey .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.indigo-blueGrey .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey .mat-button, .indigo-blueGrey .mat-icon-button, .indigo-blueGrey .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.indigo-blueGrey .mat-button.mat-primary, .indigo-blueGrey .mat-icon-button.mat-primary, .indigo-blueGrey .mat-stroked-button.mat-primary {
  color: #3f51b5;
}
.indigo-blueGrey .mat-button.mat-accent, .indigo-blueGrey .mat-icon-button.mat-accent, .indigo-blueGrey .mat-stroked-button.mat-accent {
  color: #607d8b;
}
.indigo-blueGrey .mat-button.mat-warn, .indigo-blueGrey .mat-icon-button.mat-warn, .indigo-blueGrey .mat-stroked-button.mat-warn {
  color: #f44336;
}
.indigo-blueGrey .mat-button.mat-primary.mat-button-disabled, .indigo-blueGrey .mat-button.mat-accent.mat-button-disabled, .indigo-blueGrey .mat-button.mat-warn.mat-button-disabled, .indigo-blueGrey .mat-button.mat-button-disabled.mat-button-disabled, .indigo-blueGrey .mat-icon-button.mat-primary.mat-button-disabled, .indigo-blueGrey .mat-icon-button.mat-accent.mat-button-disabled, .indigo-blueGrey .mat-icon-button.mat-warn.mat-button-disabled, .indigo-blueGrey .mat-icon-button.mat-button-disabled.mat-button-disabled, .indigo-blueGrey .mat-stroked-button.mat-primary.mat-button-disabled, .indigo-blueGrey .mat-stroked-button.mat-accent.mat-button-disabled, .indigo-blueGrey .mat-stroked-button.mat-warn.mat-button-disabled, .indigo-blueGrey .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.indigo-blueGrey .mat-button.mat-primary .mat-button-focus-overlay, .indigo-blueGrey .mat-icon-button.mat-primary .mat-button-focus-overlay, .indigo-blueGrey .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #3f51b5;
}
.indigo-blueGrey .mat-button.mat-accent .mat-button-focus-overlay, .indigo-blueGrey .mat-icon-button.mat-accent .mat-button-focus-overlay, .indigo-blueGrey .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #607d8b;
}
.indigo-blueGrey .mat-button.mat-warn .mat-button-focus-overlay, .indigo-blueGrey .mat-icon-button.mat-warn .mat-button-focus-overlay, .indigo-blueGrey .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #f44336;
}
.indigo-blueGrey .mat-button.mat-button-disabled .mat-button-focus-overlay, .indigo-blueGrey .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .indigo-blueGrey .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.indigo-blueGrey .mat-button .mat-ripple-element, .indigo-blueGrey .mat-icon-button .mat-ripple-element, .indigo-blueGrey .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.indigo-blueGrey .mat-button-focus-overlay {
  background: black;
}
.indigo-blueGrey .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .mat-flat-button, .indigo-blueGrey .mat-raised-button, .indigo-blueGrey .mat-fab, .indigo-blueGrey .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.indigo-blueGrey .mat-flat-button.mat-primary, .indigo-blueGrey .mat-raised-button.mat-primary, .indigo-blueGrey .mat-fab.mat-primary, .indigo-blueGrey .mat-mini-fab.mat-primary {
  color: white;
}
.indigo-blueGrey .mat-flat-button.mat-accent, .indigo-blueGrey .mat-raised-button.mat-accent, .indigo-blueGrey .mat-fab.mat-accent, .indigo-blueGrey .mat-mini-fab.mat-accent {
  color: white;
}
.indigo-blueGrey .mat-flat-button.mat-warn, .indigo-blueGrey .mat-raised-button.mat-warn, .indigo-blueGrey .mat-fab.mat-warn, .indigo-blueGrey .mat-mini-fab.mat-warn {
  color: white;
}
.indigo-blueGrey .mat-flat-button.mat-primary.mat-button-disabled, .indigo-blueGrey .mat-flat-button.mat-accent.mat-button-disabled, .indigo-blueGrey .mat-flat-button.mat-warn.mat-button-disabled, .indigo-blueGrey .mat-flat-button.mat-button-disabled.mat-button-disabled, .indigo-blueGrey .mat-raised-button.mat-primary.mat-button-disabled, .indigo-blueGrey .mat-raised-button.mat-accent.mat-button-disabled, .indigo-blueGrey .mat-raised-button.mat-warn.mat-button-disabled, .indigo-blueGrey .mat-raised-button.mat-button-disabled.mat-button-disabled, .indigo-blueGrey .mat-fab.mat-primary.mat-button-disabled, .indigo-blueGrey .mat-fab.mat-accent.mat-button-disabled, .indigo-blueGrey .mat-fab.mat-warn.mat-button-disabled, .indigo-blueGrey .mat-fab.mat-button-disabled.mat-button-disabled, .indigo-blueGrey .mat-mini-fab.mat-primary.mat-button-disabled, .indigo-blueGrey .mat-mini-fab.mat-accent.mat-button-disabled, .indigo-blueGrey .mat-mini-fab.mat-warn.mat-button-disabled, .indigo-blueGrey .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.indigo-blueGrey .mat-flat-button.mat-primary, .indigo-blueGrey .mat-raised-button.mat-primary, .indigo-blueGrey .mat-fab.mat-primary, .indigo-blueGrey .mat-mini-fab.mat-primary {
  background-color: #3f51b5;
}
.indigo-blueGrey .mat-flat-button.mat-accent, .indigo-blueGrey .mat-raised-button.mat-accent, .indigo-blueGrey .mat-fab.mat-accent, .indigo-blueGrey .mat-mini-fab.mat-accent {
  background-color: #607d8b;
}
.indigo-blueGrey .mat-flat-button.mat-warn, .indigo-blueGrey .mat-raised-button.mat-warn, .indigo-blueGrey .mat-fab.mat-warn, .indigo-blueGrey .mat-mini-fab.mat-warn {
  background-color: #f44336;
}
.indigo-blueGrey .mat-flat-button.mat-primary.mat-button-disabled, .indigo-blueGrey .mat-flat-button.mat-accent.mat-button-disabled, .indigo-blueGrey .mat-flat-button.mat-warn.mat-button-disabled, .indigo-blueGrey .mat-flat-button.mat-button-disabled.mat-button-disabled, .indigo-blueGrey .mat-raised-button.mat-primary.mat-button-disabled, .indigo-blueGrey .mat-raised-button.mat-accent.mat-button-disabled, .indigo-blueGrey .mat-raised-button.mat-warn.mat-button-disabled, .indigo-blueGrey .mat-raised-button.mat-button-disabled.mat-button-disabled, .indigo-blueGrey .mat-fab.mat-primary.mat-button-disabled, .indigo-blueGrey .mat-fab.mat-accent.mat-button-disabled, .indigo-blueGrey .mat-fab.mat-warn.mat-button-disabled, .indigo-blueGrey .mat-fab.mat-button-disabled.mat-button-disabled, .indigo-blueGrey .mat-mini-fab.mat-primary.mat-button-disabled, .indigo-blueGrey .mat-mini-fab.mat-accent.mat-button-disabled, .indigo-blueGrey .mat-mini-fab.mat-warn.mat-button-disabled, .indigo-blueGrey .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .mat-flat-button.mat-primary .mat-ripple-element, .indigo-blueGrey .mat-raised-button.mat-primary .mat-ripple-element, .indigo-blueGrey .mat-fab.mat-primary .mat-ripple-element, .indigo-blueGrey .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.indigo-blueGrey .mat-flat-button.mat-accent .mat-ripple-element, .indigo-blueGrey .mat-raised-button.mat-accent .mat-ripple-element, .indigo-blueGrey .mat-fab.mat-accent .mat-ripple-element, .indigo-blueGrey .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.indigo-blueGrey .mat-flat-button.mat-warn .mat-ripple-element, .indigo-blueGrey .mat-raised-button.mat-warn .mat-ripple-element, .indigo-blueGrey .mat-fab.mat-warn .mat-ripple-element, .indigo-blueGrey .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.indigo-blueGrey .mat-stroked-button:not([class*=mat-elevation-z]), .indigo-blueGrey .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .mat-fab:not([class*=mat-elevation-z]), .indigo-blueGrey .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .indigo-blueGrey .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .indigo-blueGrey .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.indigo-blueGrey .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.indigo-blueGrey .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.indigo-blueGrey .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-blueGrey .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.indigo-blueGrey .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.indigo-blueGrey .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
.indigo-blueGrey [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.indigo-blueGrey .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
.indigo-blueGrey .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.indigo-blueGrey .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.indigo-blueGrey .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.indigo-blueGrey .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.indigo-blueGrey .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.indigo-blueGrey .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
.indigo-blueGrey .mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-blueGrey .mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}
.indigo-blueGrey .mat-checkbox-checkmark {
  fill: #fafafa;
}
.indigo-blueGrey .mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
.indigo-blueGrey .mat-checkbox-mixedmark {
  background-color: #fafafa;
}
.indigo-blueGrey .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .indigo-blueGrey .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #3f51b5;
}
.indigo-blueGrey .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .indigo-blueGrey .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #607d8b;
}
.indigo-blueGrey .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .indigo-blueGrey .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #f44336;
}
.indigo-blueGrey .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .indigo-blueGrey .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.indigo-blueGrey .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.indigo-blueGrey .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-blueGrey .mat-checkbox .mat-ripple-element {
  background-color: black;
}
.indigo-blueGrey .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.indigo-blueGrey .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #3f51b5;
}
.indigo-blueGrey .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.indigo-blueGrey .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #607d8b;
}
.indigo-blueGrey .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.indigo-blueGrey .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #f44336;
}
.indigo-blueGrey .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey .mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.indigo-blueGrey .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.indigo-blueGrey .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.indigo-blueGrey .mat-chip.mat-standard-chip::after {
  background: black;
}
.indigo-blueGrey .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #3f51b5;
  color: white;
}
.indigo-blueGrey .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.indigo-blueGrey .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.indigo-blueGrey .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #f44336;
  color: white;
}
.indigo-blueGrey .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.indigo-blueGrey .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.indigo-blueGrey .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #607d8b;
  color: white;
}
.indigo-blueGrey .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.indigo-blueGrey .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.indigo-blueGrey .mat-table {
  background: white;
}
.indigo-blueGrey .mat-table thead, .indigo-blueGrey .mat-table tbody, .indigo-blueGrey .mat-table tfoot,
.indigo-blueGrey mat-header-row, .indigo-blueGrey mat-row, .indigo-blueGrey mat-footer-row,
.indigo-blueGrey [mat-header-row], .indigo-blueGrey [mat-row], .indigo-blueGrey [mat-footer-row],
.indigo-blueGrey .mat-table-sticky {
  background: inherit;
}
.indigo-blueGrey mat-row, .indigo-blueGrey mat-header-row, .indigo-blueGrey mat-footer-row,
.indigo-blueGrey th.mat-header-cell, .indigo-blueGrey td.mat-cell, .indigo-blueGrey td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-blueGrey .mat-cell, .indigo-blueGrey .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey .mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}
.indigo-blueGrey .mat-datepicker-toggle,
.indigo-blueGrey .mat-datepicker-content .mat-calendar-next-button,
.indigo-blueGrey .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-blueGrey .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .mat-calendar-table-header,
.indigo-blueGrey .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-blueGrey .mat-calendar-body-cell-content,
.indigo-blueGrey .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.indigo-blueGrey .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-blueGrey .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-blueGrey .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.indigo-blueGrey .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.indigo-blueGrey .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.indigo-blueGrey .mat-calendar-body-in-range::before {
  background: rgba(63, 81, 181, 0.2);
}
.indigo-blueGrey .mat-calendar-body-comparison-identical,
.indigo-blueGrey .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.indigo-blueGrey .mat-calendar-body-comparison-bridge-start::before,
.indigo-blueGrey [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(63, 81, 181, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.indigo-blueGrey .mat-calendar-body-comparison-bridge-end::before,
.indigo-blueGrey [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(63, 81, 181, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.indigo-blueGrey .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.indigo-blueGrey .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.indigo-blueGrey .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.indigo-blueGrey .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.indigo-blueGrey .mat-calendar-body-selected {
  background-color: #3f51b5;
  color: white;
}
.indigo-blueGrey .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(63, 81, 181, 0.4);
}
.indigo-blueGrey .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.indigo-blueGrey .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.indigo-blueGrey .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(63, 81, 181, 0.3);
}
@media (hover: hover) {
  .indigo-blueGrey .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(63, 81, 181, 0.3);
  }
}
.indigo-blueGrey .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(96, 125, 139, 0.2);
}
.indigo-blueGrey .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.indigo-blueGrey .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.indigo-blueGrey .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.indigo-blueGrey .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(96, 125, 139, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.indigo-blueGrey .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.indigo-blueGrey .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(96, 125, 139, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.indigo-blueGrey .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.indigo-blueGrey .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.indigo-blueGrey .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.indigo-blueGrey .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.indigo-blueGrey .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #607d8b;
  color: white;
}
.indigo-blueGrey .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(96, 125, 139, 0.4);
}
.indigo-blueGrey .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.indigo-blueGrey .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.indigo-blueGrey .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(96, 125, 139, 0.3);
}
@media (hover: hover) {
  .indigo-blueGrey .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(96, 125, 139, 0.3);
  }
}
.indigo-blueGrey .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}
.indigo-blueGrey .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.indigo-blueGrey .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.indigo-blueGrey .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.indigo-blueGrey .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.indigo-blueGrey .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.indigo-blueGrey .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.indigo-blueGrey .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.indigo-blueGrey .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.indigo-blueGrey .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.indigo-blueGrey .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.indigo-blueGrey .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: white;
}
.indigo-blueGrey .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}
.indigo-blueGrey .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.indigo-blueGrey .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.indigo-blueGrey .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(244, 67, 54, 0.3);
}
@media (hover: hover) {
  .indigo-blueGrey .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(244, 67, 54, 0.3);
  }
}
.indigo-blueGrey .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .mat-datepicker-toggle-active {
  color: #3f51b5;
}
.indigo-blueGrey .mat-datepicker-toggle-active.mat-accent {
  color: #607d8b;
}
.indigo-blueGrey .mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}
.indigo-blueGrey .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-blueGrey .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .indigo-blueGrey .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .indigo-blueGrey .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .indigo-blueGrey .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.indigo-blueGrey .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey .mat-expansion-panel-header-description,
.indigo-blueGrey .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-blueGrey .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.indigo-blueGrey .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.indigo-blueGrey .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.indigo-blueGrey .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.indigo-blueGrey .mat-hint {
  color: rgba(0, 0, 0, 0.6);
}
.indigo-blueGrey .mat-form-field.mat-focused .mat-form-field-label {
  color: #3f51b5;
}
.indigo-blueGrey .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #607d8b;
}
.indigo-blueGrey .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #f44336;
}
.indigo-blueGrey .mat-focused .mat-form-field-required-marker {
  color: #607d8b;
}
.indigo-blueGrey .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #3f51b5;
}
.indigo-blueGrey .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #607d8b;
}
.indigo-blueGrey .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #f44336;
}
.indigo-blueGrey .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #3f51b5;
}
.indigo-blueGrey .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #607d8b;
}
.indigo-blueGrey .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #f44336;
}
.indigo-blueGrey .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #f44336;
}
.indigo-blueGrey .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.indigo-blueGrey .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #f44336;
}
.indigo-blueGrey .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.indigo-blueGrey .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #f44336;
}
.indigo-blueGrey .mat-error {
  color: #f44336;
}
.indigo-blueGrey .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-blueGrey .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-blueGrey .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.indigo-blueGrey .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.indigo-blueGrey .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.indigo-blueGrey .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.indigo-blueGrey .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.indigo-blueGrey .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.indigo-blueGrey .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.indigo-blueGrey .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-blueGrey .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.indigo-blueGrey .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #3f51b5;
}
.indigo-blueGrey .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #607d8b;
}
.indigo-blueGrey .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #f44336;
}
.indigo-blueGrey .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #f44336;
}
.indigo-blueGrey .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-blueGrey .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.indigo-blueGrey .mat-icon.mat-primary {
  color: #3f51b5;
}
.indigo-blueGrey .mat-icon.mat-accent {
  color: #607d8b;
}
.indigo-blueGrey .mat-icon.mat-warn {
  color: #f44336;
}
.indigo-blueGrey .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-blueGrey .mat-input-element:disabled,
.indigo-blueGrey .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-blueGrey .mat-input-element {
  caret-color: #3f51b5;
}
.indigo-blueGrey .mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.indigo-blueGrey .mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.indigo-blueGrey .mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.indigo-blueGrey .mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.indigo-blueGrey .mat-form-field.mat-accent .mat-input-element {
  caret-color: #607d8b;
}
.indigo-blueGrey .mat-form-field.mat-warn .mat-input-element,
.indigo-blueGrey .mat-form-field-invalid .mat-input-element {
  caret-color: #f44336;
}
.indigo-blueGrey .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #f44336;
}
.indigo-blueGrey .mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey .mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey .mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-blueGrey .mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}
.indigo-blueGrey .mat-list-option:hover, .indigo-blueGrey .mat-list-option:focus,
.indigo-blueGrey .mat-nav-list .mat-list-item:hover,
.indigo-blueGrey .mat-nav-list .mat-list-item:focus,
.indigo-blueGrey .mat-action-list .mat-list-item:hover,
.indigo-blueGrey .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.indigo-blueGrey .mat-list-single-selected-option, .indigo-blueGrey .mat-list-single-selected-option:hover, .indigo-blueGrey .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .mat-menu-panel {
  background: white;
}
.indigo-blueGrey .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey .mat-menu-item[disabled],
.indigo-blueGrey .mat-menu-item[disabled] .mat-menu-submenu-icon,
.indigo-blueGrey .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-blueGrey .mat-menu-item .mat-icon-no-color,
.indigo-blueGrey .mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-blueGrey .mat-menu-item:hover:not([disabled]),
.indigo-blueGrey .mat-menu-item.cdk-program-focused:not([disabled]),
.indigo-blueGrey .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.indigo-blueGrey .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.indigo-blueGrey .mat-paginator {
  background: white;
}
.indigo-blueGrey .mat-paginator,
.indigo-blueGrey .mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-blueGrey .mat-paginator-decrement,
.indigo-blueGrey .mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.indigo-blueGrey .mat-paginator-first,
.indigo-blueGrey .mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.indigo-blueGrey .mat-icon-button[disabled] .mat-paginator-decrement,
.indigo-blueGrey .mat-icon-button[disabled] .mat-paginator-increment,
.indigo-blueGrey .mat-icon-button[disabled] .mat-paginator-first,
.indigo-blueGrey .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.indigo-blueGrey .mat-progress-bar-background {
  fill: #cbd0e9;
}
.indigo-blueGrey .mat-progress-bar-buffer {
  background-color: #cbd0e9;
}
.indigo-blueGrey .mat-progress-bar-fill::after {
  background-color: #3f51b5;
}
.indigo-blueGrey .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #d4dbde;
}
.indigo-blueGrey .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #d4dbde;
}
.indigo-blueGrey .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #607d8b;
}
.indigo-blueGrey .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f9ccc9;
}
.indigo-blueGrey .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f9ccc9;
}
.indigo-blueGrey .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #f44336;
}
.indigo-blueGrey .mat-progress-spinner circle, .indigo-blueGrey .mat-spinner circle {
  stroke: #3f51b5;
}
.indigo-blueGrey .mat-progress-spinner.mat-accent circle, .indigo-blueGrey .mat-spinner.mat-accent circle {
  stroke: #607d8b;
}
.indigo-blueGrey .mat-progress-spinner.mat-warn circle, .indigo-blueGrey .mat-spinner.mat-warn circle {
  stroke: #f44336;
}
.indigo-blueGrey .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.indigo-blueGrey .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #3f51b5;
}
.indigo-blueGrey .mat-radio-button.mat-primary .mat-radio-inner-circle,
.indigo-blueGrey .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .indigo-blueGrey .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .indigo-blueGrey .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #3f51b5;
}
.indigo-blueGrey .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #607d8b;
}
.indigo-blueGrey .mat-radio-button.mat-accent .mat-radio-inner-circle,
.indigo-blueGrey .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .indigo-blueGrey .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .indigo-blueGrey .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #607d8b;
}
.indigo-blueGrey .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f44336;
}
.indigo-blueGrey .mat-radio-button.mat-warn .mat-radio-inner-circle,
.indigo-blueGrey .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .indigo-blueGrey .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .indigo-blueGrey .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #f44336;
}
.indigo-blueGrey .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.indigo-blueGrey .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.indigo-blueGrey .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.indigo-blueGrey .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.indigo-blueGrey .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-blueGrey .mat-radio-button .mat-ripple-element {
  background-color: black;
}
.indigo-blueGrey .mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey .mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.indigo-blueGrey .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-blueGrey .mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-blueGrey .mat-select-panel {
  background: white;
}
.indigo-blueGrey .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #3f51b5;
}
.indigo-blueGrey .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #607d8b;
}
.indigo-blueGrey .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #f44336;
}
.indigo-blueGrey .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #f44336;
}
.indigo-blueGrey .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-blueGrey .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey .mat-drawer.mat-drawer-push {
  background-color: white;
}
.indigo-blueGrey .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.indigo-blueGrey [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.indigo-blueGrey [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.indigo-blueGrey .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #607d8b;
}
.indigo-blueGrey .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(96, 125, 139, 0.54);
}
.indigo-blueGrey .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #607d8b;
}
.indigo-blueGrey .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #3f51b5;
}
.indigo-blueGrey .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(63, 81, 181, 0.54);
}
.indigo-blueGrey .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #3f51b5;
}
.indigo-blueGrey .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #f44336;
}
.indigo-blueGrey .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(244, 67, 54, 0.54);
}
.indigo-blueGrey .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #f44336;
}
.indigo-blueGrey .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
.indigo-blueGrey .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
.indigo-blueGrey .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.indigo-blueGrey .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.indigo-blueGrey .mat-slider.mat-primary .mat-slider-track-fill,
.indigo-blueGrey .mat-slider.mat-primary .mat-slider-thumb,
.indigo-blueGrey .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #3f51b5;
}
.indigo-blueGrey .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.indigo-blueGrey .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(63, 81, 181, 0.2);
}
.indigo-blueGrey .mat-slider.mat-accent .mat-slider-track-fill,
.indigo-blueGrey .mat-slider.mat-accent .mat-slider-thumb,
.indigo-blueGrey .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #607d8b;
}
.indigo-blueGrey .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.indigo-blueGrey .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(96, 125, 139, 0.2);
}
.indigo-blueGrey .mat-slider.mat-warn .mat-slider-track-fill,
.indigo-blueGrey .mat-slider.mat-warn .mat-slider-thumb,
.indigo-blueGrey .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #f44336;
}
.indigo-blueGrey .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.indigo-blueGrey .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(244, 67, 54, 0.2);
}
.indigo-blueGrey .mat-slider:hover .mat-slider-track-background,
.indigo-blueGrey .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}
.indigo-blueGrey .mat-slider.mat-slider-disabled .mat-slider-track-background,
.indigo-blueGrey .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.indigo-blueGrey .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.indigo-blueGrey .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.indigo-blueGrey .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.indigo-blueGrey .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.indigo-blueGrey .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.indigo-blueGrey .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.indigo-blueGrey .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .indigo-blueGrey .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.indigo-blueGrey .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .indigo-blueGrey .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}
.indigo-blueGrey .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.indigo-blueGrey .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.indigo-blueGrey .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.indigo-blueGrey .mat-step-header.cdk-keyboard-focused, .indigo-blueGrey .mat-step-header.cdk-program-focused, .indigo-blueGrey .mat-step-header:hover:not([aria-disabled]), .indigo-blueGrey .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.indigo-blueGrey .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .indigo-blueGrey .mat-step-header:hover {
    background: none;
  }
}
.indigo-blueGrey .mat-step-header .mat-step-label,
.indigo-blueGrey .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-blueGrey .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.indigo-blueGrey .mat-step-header .mat-step-icon-selected,
.indigo-blueGrey .mat-step-header .mat-step-icon-state-done,
.indigo-blueGrey .mat-step-header .mat-step-icon-state-edit {
  background-color: #3f51b5;
  color: white;
}
.indigo-blueGrey .mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.indigo-blueGrey .mat-step-header.mat-accent .mat-step-icon-selected,
.indigo-blueGrey .mat-step-header.mat-accent .mat-step-icon-state-done,
.indigo-blueGrey .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #607d8b;
  color: white;
}
.indigo-blueGrey .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.indigo-blueGrey .mat-step-header.mat-warn .mat-step-icon-selected,
.indigo-blueGrey .mat-step-header.mat-warn .mat-step-icon-state-done,
.indigo-blueGrey .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #f44336;
  color: white;
}
.indigo-blueGrey .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336;
}
.indigo-blueGrey .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey .mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336;
}
.indigo-blueGrey .mat-stepper-horizontal, .indigo-blueGrey .mat-stepper-vertical {
  background-color: white;
}
.indigo-blueGrey .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .mat-horizontal-stepper-header::before,
.indigo-blueGrey .mat-horizontal-stepper-header::after,
.indigo-blueGrey .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .mat-sort-header-arrow {
  color: #757575;
}
.indigo-blueGrey .mat-tab-nav-bar,
.indigo-blueGrey .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .mat-tab-group-inverted-header .mat-tab-nav-bar,
.indigo-blueGrey .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.indigo-blueGrey .mat-tab-label, .indigo-blueGrey .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey .mat-tab-label.mat-tab-disabled, .indigo-blueGrey .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-blueGrey .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.indigo-blueGrey .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.indigo-blueGrey .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.indigo-blueGrey .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-blueGrey .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-blueGrey .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-blueGrey .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .indigo-blueGrey .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-blueGrey .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-blueGrey .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-blueGrey .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(197, 202, 233, 0.3);
}
.indigo-blueGrey .mat-tab-group.mat-primary .mat-ink-bar, .indigo-blueGrey .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #3f51b5;
}
.indigo-blueGrey .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .indigo-blueGrey .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .indigo-blueGrey .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .indigo-blueGrey .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.indigo-blueGrey .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-blueGrey .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-blueGrey .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-blueGrey .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .indigo-blueGrey .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-blueGrey .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-blueGrey .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-blueGrey .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(207, 216, 220, 0.3);
}
.indigo-blueGrey .mat-tab-group.mat-accent .mat-ink-bar, .indigo-blueGrey .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #607d8b;
}
.indigo-blueGrey .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .indigo-blueGrey .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .indigo-blueGrey .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .indigo-blueGrey .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.indigo-blueGrey .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-blueGrey .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-blueGrey .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-blueGrey .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .indigo-blueGrey .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-blueGrey .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-blueGrey .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-blueGrey .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.indigo-blueGrey .mat-tab-group.mat-warn .mat-ink-bar, .indigo-blueGrey .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #f44336;
}
.indigo-blueGrey .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .indigo-blueGrey .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .indigo-blueGrey .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .indigo-blueGrey .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.indigo-blueGrey .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-blueGrey .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-blueGrey .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-blueGrey .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .indigo-blueGrey .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-blueGrey .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-blueGrey .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-blueGrey .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(197, 202, 233, 0.3);
}
.indigo-blueGrey .mat-tab-group.mat-background-primary > .mat-tab-header, .indigo-blueGrey .mat-tab-group.mat-background-primary > .mat-tab-link-container, .indigo-blueGrey .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .indigo-blueGrey .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .indigo-blueGrey .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .indigo-blueGrey .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #3f51b5;
}
.indigo-blueGrey .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .indigo-blueGrey .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .indigo-blueGrey .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .indigo-blueGrey .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.indigo-blueGrey .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .indigo-blueGrey .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .indigo-blueGrey .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .indigo-blueGrey .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.indigo-blueGrey .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.indigo-blueGrey .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.indigo-blueGrey .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.indigo-blueGrey .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .indigo-blueGrey .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.indigo-blueGrey .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.indigo-blueGrey .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.indigo-blueGrey .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.indigo-blueGrey .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.indigo-blueGrey .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .indigo-blueGrey .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.indigo-blueGrey .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.indigo-blueGrey .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.indigo-blueGrey .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.indigo-blueGrey .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .indigo-blueGrey .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.indigo-blueGrey .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.indigo-blueGrey .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.indigo-blueGrey .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-blueGrey .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-blueGrey .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-blueGrey .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .indigo-blueGrey .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-blueGrey .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-blueGrey .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-blueGrey .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(207, 216, 220, 0.3);
}
.indigo-blueGrey .mat-tab-group.mat-background-accent > .mat-tab-header, .indigo-blueGrey .mat-tab-group.mat-background-accent > .mat-tab-link-container, .indigo-blueGrey .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .indigo-blueGrey .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .indigo-blueGrey .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .indigo-blueGrey .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #607d8b;
}
.indigo-blueGrey .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .indigo-blueGrey .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .indigo-blueGrey .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .indigo-blueGrey .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.indigo-blueGrey .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .indigo-blueGrey .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .indigo-blueGrey .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .indigo-blueGrey .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.indigo-blueGrey .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.indigo-blueGrey .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.indigo-blueGrey .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.indigo-blueGrey .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .indigo-blueGrey .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.indigo-blueGrey .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.indigo-blueGrey .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.indigo-blueGrey .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.indigo-blueGrey .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.indigo-blueGrey .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .indigo-blueGrey .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.indigo-blueGrey .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.indigo-blueGrey .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.indigo-blueGrey .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.indigo-blueGrey .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .indigo-blueGrey .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.indigo-blueGrey .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.indigo-blueGrey .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.indigo-blueGrey .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-blueGrey .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-blueGrey .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-blueGrey .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .indigo-blueGrey .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-blueGrey .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-blueGrey .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-blueGrey .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.indigo-blueGrey .mat-tab-group.mat-background-warn > .mat-tab-header, .indigo-blueGrey .mat-tab-group.mat-background-warn > .mat-tab-link-container, .indigo-blueGrey .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .indigo-blueGrey .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .indigo-blueGrey .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .indigo-blueGrey .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #f44336;
}
.indigo-blueGrey .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .indigo-blueGrey .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .indigo-blueGrey .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .indigo-blueGrey .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.indigo-blueGrey .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .indigo-blueGrey .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .indigo-blueGrey .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .indigo-blueGrey .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.indigo-blueGrey .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.indigo-blueGrey .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.indigo-blueGrey .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.indigo-blueGrey .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .indigo-blueGrey .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.indigo-blueGrey .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.indigo-blueGrey .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.indigo-blueGrey .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.indigo-blueGrey .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.indigo-blueGrey .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .indigo-blueGrey .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.indigo-blueGrey .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.indigo-blueGrey .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.indigo-blueGrey .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.indigo-blueGrey .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .indigo-blueGrey .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.indigo-blueGrey .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.indigo-blueGrey .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.indigo-blueGrey .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey .mat-toolbar.mat-primary {
  background: #3f51b5;
  color: white;
}
.indigo-blueGrey .mat-toolbar.mat-accent {
  background: #607d8b;
  color: white;
}
.indigo-blueGrey .mat-toolbar.mat-warn {
  background: #f44336;
  color: white;
}
.indigo-blueGrey .mat-toolbar .mat-form-field-underline,
.indigo-blueGrey .mat-toolbar .mat-form-field-ripple,
.indigo-blueGrey .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.indigo-blueGrey .mat-toolbar .mat-form-field-label,
.indigo-blueGrey .mat-toolbar .mat-focused .mat-form-field-label,
.indigo-blueGrey .mat-toolbar .mat-select-value,
.indigo-blueGrey .mat-toolbar .mat-select-arrow,
.indigo-blueGrey .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.indigo-blueGrey .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.indigo-blueGrey .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.indigo-blueGrey .mat-tree {
  background: white;
}
.indigo-blueGrey .mat-tree-node,
.indigo-blueGrey .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .mat-simple-snackbar-action {
  color: #607d8b;
}
.indigo-blueGrey *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.indigo-blueGrey *::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}
.indigo-blueGrey *::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}
.indigo-blueGrey .toolbar-line.container-ou {
  background: #3f51b5;
  color: white;
}
.indigo-blueGrey .toolbar-line.container-persona {
  background: #3f51b5 !important;
}
.indigo-blueGrey .toolbar-line.container-persona .persona-title {
  background: #3f51b5;
}
.indigo-blueGrey .toolbar-line.container-persona .invisible-container.persona-selector {
  background: #3f51b5 !important;
}
.indigo-blueGrey button.mat-icon-button:not([disabled]):hover {
  background: rgba(0, 0, 0, 0.04);
}
.indigo-blueGrey .side-bar {
  background: #eeeeee;
}
.indigo-blueGrey .icon-stacked {
  color: white !important;
}
.indigo-blueGrey .icon-stacked mat-icon + mat-icon {
  text-shadow: -1px 1px 0px #3f51b5, 1px -1px 0px #3f51b5;
}
.indigo-blueGrey .divider-vert {
  background: rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .avatar {
  background: #303f9f !important;
}
.indigo-blueGrey ngx-avatar .avatar-content {
  background: #303f9f !important;
}
.indigo-blueGrey .mat-loading-window {
  background: white;
}
.indigo-blueGrey .window-spinner {
  background: white;
}
.indigo-blueGrey .load-window {
  background: white;
}
@media (min-width: 600px) and (max-width: 1300px) {
  .indigo-blueGrey mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .load-window,
.indigo-blueGrey mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .load-window.new {
    background: white !important;
  }
  .indigo-blueGrey mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card {
    background: white !important;
  }
  .indigo-blueGrey mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .inbox-container mat-toolbar {
    background: #3f51b5;
    color: white;
  }
  .indigo-blueGrey mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .inbox-container mat-list-item.mat-list-item {
    border: 1px solid rgba(0, 0, 0, 0.12);
  }
  .indigo-blueGrey mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .detail {
    background: white !important;
  }
  .indigo-blueGrey mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .detail mat-toolbar {
    background: white !important;
  }
  .indigo-blueGrey mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .main.w-detail + .new-detail-container .load-window {
    background: #fafafa !important;
  }
  .indigo-blueGrey mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .main.w-detail + .new-detail-container:before {
    background: rgba(0, 0, 0, 0.6);
  }
  .indigo-blueGrey mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .main.side-open + .side {
    border-left: 1px solid rgba(0, 0, 0, 0.12);
  }
  .indigo-blueGrey mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .side .container .mat-list-base {
    background: white !important;
    border: 1px solid rgba(0, 0, 0, 0.12);
  }
  .indigo-blueGrey mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .new-table .mat-header-row,
.indigo-blueGrey mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .new-table .mat-table,
.indigo-blueGrey mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .document-table .mat-header-row,
.indigo-blueGrey mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .document-table .mat-table,
.indigo-blueGrey mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .status-table .mat-header-row,
.indigo-blueGrey mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .status-table .mat-table {
    background: white !important;
  }
  .indigo-blueGrey mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .audit-filter .filter-container.is-floating {
    background: #fafafa !important;
    border: 1px solid rgba(0, 0, 0, 0.12);
  }
  .indigo-blueGrey mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .audit-filter .filter-container.is-floating .form-body {
    background: #fafafa !important;
  }
  .indigo-blueGrey mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .audit-result {
    background: white;
  }
  .indigo-blueGrey mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .audit-detail-container {
    background: #fafafa !important;
  }
}
@media (max-width: 599px) {
  .indigo-blueGrey app-document-detail .mat-toolbar {
    background: #303f9f !important;
    color: white !important;
  }
  .indigo-blueGrey app-document-detail .mat-toolbar .mat-toolbar-row .toolbar-actions {
    background: #303f9f !important;
    color: white !important;
  }
  .indigo-blueGrey .login-card {
    background: white !important;
  }
  .indigo-blueGrey .inbox-toolbar {
    background: #303f9f !important;
    color: white !important;
  }
  .indigo-blueGrey .detail {
    background: white url("../img/spinner_mobile.gif") no-repeat center center !important;
  }
  .indigo-blueGrey .toolbar-line-container .toolbar-line:first-child {
    background: #303f9f !important;
    color: white !important;
  }
  .indigo-blueGrey .toolbar-actions {
    background: #303f9f !important;
    color: white !important;
  }
  .indigo-blueGrey mat-drawer.side-nav.enter {
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12) !important;
  }
  .indigo-blueGrey .side-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0) !important;
  }
  .indigo-blueGrey .filter {
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.55) 16%, rgba(0, 0, 0, 0) 100%) !important;
  }
  .indigo-blueGrey button.side-open-mobile {
    color: white !important;
  }
  .indigo-blueGrey .side-nav .side-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0);
  }
  .indigo-blueGrey .side-nav .filter {
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.55) 16%, rgba(0, 0, 0, 0) 100%);
  }
  .indigo-blueGrey .side-nav .side-nav-container .links {
    background: white;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
  }
  .indigo-blueGrey .side-nav .side-nav-container .version {
    background: white !important;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
  }
  .indigo-blueGrey app-file-document-view .view-container {
    background: white !important;
  }
  .indigo-blueGrey app-file-document-view .view-container .side-bar {
    background: #303f9f !important;
    color: white !important;
  }
  .indigo-blueGrey app-file-document-view .view-container .view-content app-pdf-wrapper.floating {
    background: white;
  }
  .indigo-blueGrey app-file-document-view .view-container .view-content .upload-indications {
    background: #eeeeee;
  }
  .indigo-blueGrey app-file-document-view .view-container .view-content app-file-document-sign .sign-cycle mat-horizontal-stepper.mat-stepper-horizontal {
    background-color: rgba(0, 0, 0, 0) !important;
  }
  .indigo-blueGrey app-file-document-view .view-container .view-content app-file-document-sign .sign-cycle .sign-cycle-content .cycle-content-action .mat-ripple-element {
    background-color: rgba(255, 255, 255, 0.1) !important;
  }
  .indigo-blueGrey app-pending .floating-container .inbox-container .inbox-toolbar .mat-toolbar-row .mat-avatar h3 {
    color: white !important;
  }
  .indigo-blueGrey app-pending .floating-container .detail {
    background: white url("../img/spinner_mobile.gif") no-repeat center center !important;
  }
  .indigo-blueGrey app-document-sign-massive .inbox-container .pending-container app-chapa.minimized .info-empty {
    background: #607d8b;
  }
  .indigo-blueGrey app-document-sign-massive .inbox-container .pending-container app-chapa.minimized .info-empty > span h2 {
    color: white !important;
  }
  .indigo-blueGrey app-document-sign-massive .inbox-container .signer.mobile {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    background: white;
  }
  .indigo-blueGrey app-document-sign-massive .detail app-file-document-detail .view-container .mat-toolbar .mat-toolbar-row .view-actions {
    color: white !important;
  }
  .indigo-blueGrey app-add-documentation .floating-container .employee-add-documentation .inbox-container .inbox-toolbar .mat-toolbar-row .mat-avatar h3 {
    color: white !important;
  }
  .indigo-blueGrey app-add-documentation .floating-container .employee-add-documentation .detail {
    background: white url("../img/spinner_mobile.gif") no-repeat center center !important;
  }
  .indigo-blueGrey app-add-documentation .floating-container .employee-add-documentation .detail app-file-document-view .view-container .view-content .new-container app-file-document-upload {
    background: white;
  }
}
.indigo-blueGrey .mat-card .mat-drawer-container {
  background: white !important;
}
.indigo-blueGrey .mat-drawer-container {
  background: #e0e0e0 !important;
}
.indigo-blueGrey .mat-list .mat-list-item.finished-process-inbox.active {
  background: #4DB6AC !important;
}
.indigo-blueGrey .mat-list .mat-list-item.finished-process-inbox .mat-icon {
  background: #4DB6AC;
}
.indigo-blueGrey .mat-list .mat-list-item.erased-process-inbox.active {
  background: #837C80 !important;
}
.indigo-blueGrey .mat-list .mat-list-item.limbo-process-inbox.active {
  background: #FFD54F !important;
}
.indigo-blueGrey .mat-list .mat-list-item.limbo-process-inbox .mat-icon {
  background: #FFD54F;
}
.indigo-blueGrey .mat-list .mat-list-item.in-process-inbox.active {
  background: #FFD54F !important;
}
.indigo-blueGrey .mat-list .mat-list-item.in-process-inbox .mat-icon {
  background: #FFD54F;
}
.indigo-blueGrey .mat-list .mat-list-item.error-process-inbox.active {
  background: #F48FB1 !important;
}
.indigo-blueGrey .mat-list .mat-list-item.error-process-inbox .mat-icon {
  background: #F48FB1;
}
.indigo-blueGrey .mat-list .mat-list-item.active .mat-line b {
  color: #3f51b5;
}
.indigo-blueGrey .mat-list .mat-list-item.active .mat-list-avatar {
  color: #3f51b5;
}
.indigo-blueGrey .mat-list .mat-list-item:hover:not(.active) {
  background: rgba(0, 0, 0, 0.04);
}
.indigo-blueGrey span.mat-badge-content {
  color: #f44336 !important;
}
.indigo-blueGrey mat-expansion-panel.mat-expanded {
  background: #fafafa !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey mat-expansion-panel.virtual-scrolling .mat-expansion-panel-content .get-more {
  color: #3f51b5;
}
.indigo-blueGrey mat-expansion-panel.virtual-scrolling .mat-expansion-panel-content app-cs-paginator.get-more {
  background: white;
}
.indigo-blueGrey mat-expansion-panel-header.mat-expanded {
  background: white !important;
}
.indigo-blueGrey .bottom-container {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .load-more * {
  color: #3f51b5;
}
.indigo-blueGrey .welcome-card {
  background: white !important;
}
.indigo-blueGrey .welcome-card .img-divider {
  background: #303f9f;
}
.indigo-blueGrey .mat-header-row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background: white;
}
.indigo-blueGrey .mat-row {
  background: white;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.indigo-blueGrey .mat-row .mat-cell.status-cell mat-icon.pending {
  color: #FF9800 !important;
}
.indigo-blueGrey .mat-row .mat-cell.status-cell mat-icon.ok {
  color: #009688 !important;
}
.indigo-blueGrey .mat-row .mat-cell.status-cell mat-icon.not-ok {
  color: #9C27B0 !important;
}
.indigo-blueGrey .mat-row .mat-cell.status-cell mat-icon.cancelled {
  color: #795548 !important;
}
.indigo-blueGrey .mat-row .mat-cell.status-cell mat-icon.rejected {
  color: #df4d4c !important;
}
.indigo-blueGrey .mat-row .mat-cell.status-cell mat-icon.draft {
  color: #dedede !important;
}
.indigo-blueGrey .mat-row.active, .indigo-blueGrey .mat-row.active:hover {
  background: #3f51b5 !important;
}
.indigo-blueGrey .mat-row.active div, .indigo-blueGrey .mat-row.active:hover div {
  color: white;
}
.indigo-blueGrey .mat-row.active .mat-cell.status-cell mat-icon.pending, .indigo-blueGrey .mat-row.active .mat-cell.status-cell mat-icon.ok, .indigo-blueGrey .mat-row.active .mat-cell.status-cell mat-icon.not-ok, .indigo-blueGrey .mat-row.active:hover .mat-cell.status-cell mat-icon.pending, .indigo-blueGrey .mat-row.active:hover .mat-cell.status-cell mat-icon.ok, .indigo-blueGrey .mat-row.active:hover .mat-cell.status-cell mat-icon.not-ok {
  text-shadow: -1px 1px 0 white, 1px 1px 0 white, 1px -1px 0 white, -1px -1px 0 white;
}
.indigo-blueGrey .mat-row.active .sticky-actions button, .indigo-blueGrey .mat-row.active:hover .sticky-actions button {
  background: #3f51b5 !important;
}
.indigo-blueGrey .mat-row.active .sticky-actions button.ok mat-icon, .indigo-blueGrey .mat-row.active .sticky-actions button.not-ok mat-icon, .indigo-blueGrey .mat-row.active:hover .sticky-actions button.ok mat-icon, .indigo-blueGrey .mat-row.active:hover .sticky-actions button.not-ok mat-icon {
  text-shadow: -1px 1px 0 white, 1px 1px 0 white, 1px -1px 0 white, -1px -1px 0 white;
}
.indigo-blueGrey .mat-row:nth-child(odd) {
  background: #fafafa;
}
.indigo-blueGrey .mat-row.selected div {
  color: white;
}
.indigo-blueGrey .mat-row.selected:nth-child(even) {
  background: #9fa8da !important;
}
.indigo-blueGrey .mat-row.selected:nth-child(even) .sticky-actions button {
  background: #9fa8da !important;
}
.indigo-blueGrey .mat-row.selected:nth-child(odd) {
  background: #c5cae9 !important;
}
.indigo-blueGrey .mat-row.selected:nth-child(odd) .sticky-actions button {
  background: #c5cae9 !important;
}
.indigo-blueGrey .mat-row.selected.active, .indigo-blueGrey .mat-row.selected.active:hover {
  background: #3f51b5 !important;
}
.indigo-blueGrey .mat-row.selected.active .sticky-actions button, .indigo-blueGrey .mat-row.selected.active:hover .sticky-actions button {
  background: #3f51b5 !important;
}
.indigo-blueGrey .mat-row:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.indigo-blueGrey .mat-row:hover .sticky-actions button {
  background: white !important;
}
.indigo-blueGrey .mat-row.canceled * {
  color: #795548;
}
.indigo-blueGrey .mat-row.disabled div {
  color: #607D8B;
}
.indigo-blueGrey .mat-row.disabled.active, .indigo-blueGrey .mat-row.disabled.active:hover {
  background: #607D8B !important;
}
.indigo-blueGrey .mat-row.disabled.active div, .indigo-blueGrey .mat-row.disabled.active:hover div {
  color: white;
}
.indigo-blueGrey .mat-row.disabled.active .sticky-actions button, .indigo-blueGrey .mat-row.disabled.active:hover .sticky-actions button {
  background: #607D8B !important;
}
.indigo-blueGrey .mat-row.disabled.selected.active:hover .sticky-actions button {
  background: #607D8B !important;
}
.indigo-blueGrey .mat-row .sticky-actions button.ok mat-icon {
  color: #009688 !important;
}
.indigo-blueGrey .mat-row .sticky-actions button.not-ok mat-icon {
  color: #9C27B0 !important;
}
.indigo-blueGrey .status-table .mat-header-row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background: white;
}
.indigo-blueGrey .icon-info-off,
.indigo-blueGrey .certificate-off {
  color: #607D8B;
}
.indigo-blueGrey .documents-search-table .mat-header-row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background: white;
}
.indigo-blueGrey mat-toolbar.employee-search-sub-toolbar {
  background: white;
}
.indigo-blueGrey .mat-search-field {
  border: 1px solid rgba(0, 0, 0, 0.12);
  background: #eeeeee;
}
.indigo-blueGrey .mat-search-field input[type=search] {
  background: rgba(0, 0, 0, 0);
}
.indigo-blueGrey .mat-search-field input[type=search]::placeholder {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-blueGrey .search-adv-menu .mat-search-field {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey mat-toolbar.document-search-sub-toolbar {
  background: white;
}
.indigo-blueGrey app-advanced-employee-search .mat-chip {
  background: rgba(0, 0, 0, 0.04) !important;
}
.indigo-blueGrey .side-nav .side-trigger {
  background: white;
}
.indigo-blueGrey .side-nav .menu-trigger mat-icon {
  color: rgba(0, 0, 0, 0.54);
  background: white;
}
.indigo-blueGrey .side-nav .side-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0);
}
.indigo-blueGrey .side-nav .filter {
  background: white;
}
.indigo-blueGrey .side-nav .side-nav-container .links mat-icon span.mat-badge-content {
  color: white !important;
}
.indigo-blueGrey .side-nav .side-nav-container .mat-list-base .mat-list-item:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.indigo-blueGrey .side-nav .side-nav-container .mat-list-base .mat-list-item.active {
  background: #3f51b5 !important;
}
.indigo-blueGrey .side-nav .side-nav-container .mat-list-base .mat-list-item.active * {
  color: white;
}
.indigo-blueGrey .side-nav .side-nav-container .mat-list-base .mat-list-item.active .mat-list-item.active:hover {
  background: #3f51b5 !important;
}
.indigo-blueGrey .side-nav .side-nav-container .mat-list-base .mat-list-item.active .mat-list-item.active:hover * {
  color: white;
}
.indigo-blueGrey .side-nav.open .side-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .side-nav.open .filter {
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.55) 16%, rgba(0, 0, 0, 0) 100%);
}
.indigo-blueGrey .side-nav.open .side-nav-container .links {
  background: white;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .side-nav.open .side-nav-container .version {
  background: white !important;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .side-nav.enter .side-nav-container .links {
  background: white;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .side-nav.enter .side-nav-container .version {
  background: white !important;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .mat-menu-panel:not(.metadata-radio-menu) .navigation-list .mat-list-item-content {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-blueGrey table.mat-calendar-table {
  background: white !important;
}
.indigo-blueGrey .mat-calendar-body-label {
  color: white !important;
}
.indigo-blueGrey .mat-calendar-arrow {
  border-top-color: white !important;
}
.indigo-blueGrey .mat-datepicker-content .mat-calendar-next-button,
.indigo-blueGrey .mat-datepicker-content .mat-calendar-previous-button {
  color: white !important;
}
.indigo-blueGrey .mat-calendar-header {
  background: #303f9f !important;
}
.indigo-blueGrey mat-datepicker-content {
  background-color: #303f9f !important;
  color: white !important;
}
.indigo-blueGrey .mat-calendar-content {
  background: white !important;
}
.indigo-blueGrey .mat-simple-snackbar-action button.mat-button {
  color: #FFE082;
}
.indigo-blueGrey .mat-simple-snackbar-action .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.indigo-blueGrey mat-bottom-sheet-container mat-toolbar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}
.indigo-blueGrey app-generic-bottom-sheet h4.accent {
  color: #607d8b;
}
.indigo-blueGrey app-generic-bottom-sheet .verification-code {
  background: #3f51b5;
  color: white;
}
.indigo-blueGrey app-generic-bottom-sheet .verification-code h2 {
  background: #3f51b5;
}
.indigo-blueGrey app-generic-bottom-sheet .load-window.message .info-container h3.primary-text {
  color: #3f51b5;
}
.indigo-blueGrey app-generic-bottom-sheet .bottom_sheet-content.ok .bottom_sheet-title .bottom_sheet-icon,
.indigo-blueGrey app-generic-bottom-sheet .bottom_sheet-content.ok .bottom_sheet-title h3 {
  color: #009688;
}
.indigo-blueGrey app-generic-bottom-sheet .bottom_sheet-content.ok .bottom_sheet-main_btn:not(:disabled) {
  background: #009688;
  color: #fff;
}
.indigo-blueGrey app-generic-bottom-sheet .bottom_sheet-content.not-ok .bottom_sheet-title .bottom_sheet-icon,
.indigo-blueGrey app-generic-bottom-sheet .bottom_sheet-content.not-ok .bottom_sheet-title h3 {
  color: #9C27B0;
}
.indigo-blueGrey app-generic-bottom-sheet .bottom_sheet-content.not-ok .bottom_sheet-main_btn:not(:disabled) {
  background: #9C27B0;
  color: #fff;
}
.indigo-blueGrey app-generic-bottom-sheet .bottom_sheet-content.cancel .bottom_sheet-title .bottom_sheet-icon,
.indigo-blueGrey app-generic-bottom-sheet .bottom_sheet-content.cancel .bottom_sheet-title h3 {
  color: #795548;
}
.indigo-blueGrey app-generic-bottom-sheet .bottom_sheet-content.cancel .bottom_sheet-main_btn:not(:disabled) {
  background: #795548;
  color: #fff;
}
.indigo-blueGrey app-generic-bottom-sheet .bottom_sheet-content .bottom_sheet_exp_btn {
  background-color: #3f51b5;
  color: #fff;
}
.indigo-blueGrey app-generic-bottom-sheet .bottom_sheet-content .bottom_sheet_canc_btn {
  background-color: lightgrey;
  color: black;
}
.indigo-blueGrey app-generic-bottom-sheet .bottom_sheet-content .bottom_sheet-exp-h3 {
  color: #3f51b5;
  margin-bottom: -10px !important;
}
.indigo-blueGrey app-generic-bottom-sheet .bottom_sheet-content .mat-input-element-export {
  display: flex !important;
  padding-left: 15px;
}
.indigo-blueGrey app-generic-bottom-sheet .bottom_sheet-content .mat-form-field-infix-export-right {
  width: 100% !important;
  padding: 0px !important;
  display: flex !important;
}
.indigo-blueGrey app-generic-bottom-sheet .bottom_sheet-content .mat-form-field-infix-export-left {
  width: 100% !important;
  padding: 0px;
  display: flex !important;
}
.indigo-blueGrey app-generic-bottom-sheet .bottom_sheet-content .mat-form-field-infix {
  display: flex;
}
.indigo-blueGrey app-generic-bottom-sheet .bottom_sheet-content .mat-checkbox-layout .mat-checkbox-label {
  color: #607d8b;
}
.indigo-blueGrey app-generic-bottom-sheet .bottom_sheet-content .choose-forms {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  margin-left: 10px !important;
}
.indigo-blueGrey app-generic-bottom-sheet .bottom_sheet-content .card-container {
  max-height: 150px;
  overflow-y: auto;
}
.indigo-blueGrey .mat-list .mat-list-item.active .mat-line {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey .mat-list .mat-list-item.active .mat-line b {
  color: #3f51b5;
}
.indigo-blueGrey .mat-list .mat-list-item.active .mat-list-avatar {
  color: #3f51b5;
}
.indigo-blueGrey .mat-list .mat-list-item .mat-line {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-blueGrey .mat-list .mat-list-item .mat-list-avatar {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-blueGrey .mat-list-item:hover:not(.active) {
  background: rgba(0, 0, 0, 0.04) !important;
}
.indigo-blueGrey mat-list-item:nth-child(even),
.indigo-blueGrey app-file-document-inbox-item:nth-child(even) mat-list-item,
.indigo-blueGrey app-file-document-inbox-item-documentation:nth-child(even) mat-list-item {
  background: #fafafa !important;
}
.indigo-blueGrey mat-list-item:nth-child(odd),
.indigo-blueGrey app-file-document-inbox-item:nth-child(odd) mat-list-item,
.indigo-blueGrey app-file-document-inbox-item-documentation:nth-child(odd) mat-list-item {
  background: white !important;
}
.indigo-blueGrey .mat-list-avatar.mat-icon {
  background: #eeeeee;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .card-filter {
  background: white;
}
.indigo-blueGrey .login mat-horizontal-stepper {
  background: white !important;
}
.indigo-blueGrey .login .login-card {
  background: white !important;
}
.indigo-blueGrey .login .login-card .mat-card-content .mat-card-footer a[color=accent] {
  color: #607d8b;
}
.indigo-blueGrey .login .help-stepper h3.align-16 {
  color: #607d8b;
}
.indigo-blueGrey .login .help-stepper .help-options mat-icon {
  color: #607d8b;
}
.indigo-blueGrey .ux-modal {
  background: rgba(0, 0, 0, 0.32);
}
.indigo-blueGrey .ux-modal .card {
  background: white;
}
.indigo-blueGrey .main-title {
  background: #607D8B;
  color: white;
}
.indigo-blueGrey .sub-title {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-blueGrey .card-footer a {
  color: white;
}
.indigo-blueGrey .profile-toolbar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey app-profile .logout-btn:hover {
  background: #eeeeee;
}
.indigo-blueGrey app-profile .cropper {
  background: white;
}
.indigo-blueGrey app-profile .cropper .cropper {
  background: unset;
}
.indigo-blueGrey app-add-documentation .employee-add-documentation .inbox-toolbar {
  background: #fafafa;
}
.indigo-blueGrey app-add-documentation .employee-add-documentation .inbox-toolbar .toolbar-line mat-icon {
  color: #3f51b5;
}
.indigo-blueGrey app-add-documentation .employee-add-documentation .inbox-toolbar .toolbar-line h3 {
  color: #3f51b5;
}
.indigo-blueGrey app-add-documentation .employee-add-documentation mat-tab-group {
  background: #fafafa;
}
.indigo-blueGrey app-add-documentation .employee-add-documentation mat-tab-group mat-tab-header .mat-tab-label-active {
  color: #3f51b5;
}
.indigo-blueGrey app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content:hover {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12) !important;
}
.indigo-blueGrey app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content.selected {
  border-left: 6px solid #3f51b5 !important;
}
.indigo-blueGrey app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content.selected mat-icon {
  color: #3f51b5 !important;
}
.indigo-blueGrey app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content.selected .item-data {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-blueGrey app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content mat-icon {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-blueGrey app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content .item-data {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-blueGrey app-add-documentation .employee-add-documentation .detail app-file-document-view .new-container app-file-document-upload .metadata-list .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
  background: white !important;
}
.indigo-blueGrey app-pending .inbox-toolbar {
  background: #fafafa;
}
.indigo-blueGrey app-pending .pending-container {
  background: #fafafa;
}
.indigo-blueGrey app-pending .pending-container .pending-items .pending-item .content:hover {
  background: rgba(0, 0, 0, 0.04) !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12) !important;
}
.indigo-blueGrey app-pending .pending-container .pending-items .pending-item .content.selected {
  border-left: 6px solid #607d8b !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12) !important;
}
.indigo-blueGrey app-pending .pending-container .pending-items .pending-item .content.selected mat-icon {
  color: #607d8b !important;
}
.indigo-blueGrey app-pending .pending-container .pending-items .pending-item .content.selected .item-data {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-blueGrey app-pending .pending-container .pending-items .pending-item .content mat-icon {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-blueGrey app-pending .pending-container .pending-items .pending-item .content .item-data {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-blueGrey app-document-sign-massive .inbox-container .inbox-toolbar {
  background: #fafafa;
}
.indigo-blueGrey app-document-sign-massive .inbox-container .pending-container {
  background: #fafafa;
}
.indigo-blueGrey app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content:hover {
  background: rgba(0, 0, 0, 0.04) !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12) !important;
}
.indigo-blueGrey app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content.selected {
  border-left: 6px solid #607d8b !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12) !important;
}
.indigo-blueGrey app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content.selected mat-icon {
  color: #607d8b !important;
}
.indigo-blueGrey app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content.selected .item-data {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-blueGrey app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content mat-icon {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-blueGrey app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content .item-data {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-blueGrey app-document-sign-massive .detail app-file-document-detail .view-container {
  background: #607D8B;
}
.indigo-blueGrey app-document-sign-massive .detail app-file-document-detail .view-container .mat-toolbar {
  background: #607D8B;
  color: white;
  background: #607D8B;
}
.indigo-blueGrey app-document-sign-massive .detail app-chapa.minimized .info-empty {
  background: #607d8b;
}
.indigo-blueGrey app-document-sign-massive .detail app-chapa.minimized .info-empty > span mat-icon {
  color: white !important;
}
.indigo-blueGrey app-document-sign-massive .detail app-chapa.minimized .info-empty > span h2 {
  color: white !important;
}
.indigo-blueGrey app-document-sign-massive .signer.desktop {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey app-document-sign-massive .signer .signer-btn {
  color: white !important;
}
.indigo-blueGrey app-document-sign-massive .signer .signer-btn[disabled] {
  background-color: rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey app-document-detail app-sign-document .firmador button.mat-raised-button.mat-accent {
  color: white !important;
}
.indigo-blueGrey mat-toolbar-row.firmado-conforme .mat-icon {
  background: #8BC34A;
  color: white;
}
.indigo-blueGrey mat-toolbar-row.firmado-no-conforme .mat-icon {
  background: #f44336;
  color: white;
}
.indigo-blueGrey .secondary-toolbar {
  background: white;
}
.indigo-blueGrey .new-detail-container {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .new-detail-container.cancelled .detail_head-body {
  background: #607D8B !important;
}
.indigo-blueGrey .new-detail-container .employee-detail-toolbar {
  background: #eeeeee;
}
.indigo-blueGrey .new-detail-container .employee-detail-subbar {
  background: white;
}
.indigo-blueGrey app-employee-detail .load-window,
.indigo-blueGrey app-employee-rrhh-detail .load-window {
  background: #fafafa;
}
.indigo-blueGrey app-employee-detail .employee_detail-container,
.indigo-blueGrey app-employee-rrhh-detail .employee_detail-container {
  background: #fafafa;
}
.indigo-blueGrey app-employee-detail .employee_detail-container.is-editing .edition-line,
.indigo-blueGrey app-employee-rrhh-detail .employee_detail-container.is-editing .edition-line {
  background: #eeeeee;
}
.indigo-blueGrey app-employee-detail .employee_detail-container .detail_head-body,
.indigo-blueGrey app-employee-rrhh-detail .employee_detail-container .detail_head-body {
  background: #3f51b5;
}
.indigo-blueGrey app-employee-detail .employee_detail-container .detail_head-body .head_body-status .icon-stacked + mat-icon,
.indigo-blueGrey app-employee-rrhh-detail .employee_detail-container .detail_head-body .head_body-status .icon-stacked + mat-icon {
  color: white !important;
}
.indigo-blueGrey app-employee-detail .employee_detail-container .detail_head-body .head_body-title .body_title-name,
.indigo-blueGrey app-employee-rrhh-detail .employee_detail-container .detail_head-body .head_body-title .body_title-name {
  color: white;
}
.indigo-blueGrey app-employee-detail .employee_detail-container .detail_head-body .head_body-title .body_title-user,
.indigo-blueGrey app-employee-rrhh-detail .employee_detail-container .detail_head-body .head_body-title .body_title-user {
  color: white;
}
.indigo-blueGrey app-employee-detail .employee_detail-container .data .data-line,
.indigo-blueGrey app-employee-rrhh-detail .employee_detail-container .data .data-line {
  background: white;
}
.indigo-blueGrey app-employee-detail .employee_detail-container .data .data-line .icon-complex mat-icon.complex,
.indigo-blueGrey app-employee-rrhh-detail .employee_detail-container .data .data-line .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px white, 0 -2px 0px white, 2px 0 0px white, 0 2px 0px white, -2px -2px 0px white, 2px 2px 0px white, 2px -2px 0px white, -2px 2px 0px white;
}
.indigo-blueGrey app-employee-detail .employee_detail-container .data .data-line .line_text-main,
.indigo-blueGrey app-employee-rrhh-detail .employee_detail-container .data .data-line .line_text-main {
  color: #3f51b5;
}
.indigo-blueGrey app-employee-detail .employee_detail-container .data .data-line.clickable:hover,
.indigo-blueGrey app-employee-rrhh-detail .employee_detail-container .data .data-line.clickable:hover {
  background: #fafafa !important;
}
.indigo-blueGrey app-employee-detail .employee_detail-container .data .data-line button.mat-icon-button,
.indigo-blueGrey app-employee-rrhh-detail .employee_detail-container .data .data-line button.mat-icon-button {
  background: #eeeeee;
}
.indigo-blueGrey app-employee-detail .employee_detail-container .employee-title .title-text,
.indigo-blueGrey app-employee-rrhh-detail .employee_detail-container .employee-title .title-text {
  color: #3f51b5;
}
.indigo-blueGrey app-employee-detail .employee_detail-container .employee-title .title-text mat-icon,
.indigo-blueGrey app-employee-rrhh-detail .employee_detail-container .employee-title .title-text mat-icon {
  color: #3f51b5;
}
.indigo-blueGrey app-employee-detail .employee_detail-container .employee-title .title-text .title_text-main,
.indigo-blueGrey app-employee-rrhh-detail .employee_detail-container .employee-title .title-text .title_text-main {
  color: #3f51b5;
}
.indigo-blueGrey .UI-V2 .icon-complex mat-icon.complex {
  color: #3f51b5;
  text-shadow: -2px 0 0px white, 0 -2px 0px white, 2px 0 0px white, 0 2px 0px white, -2px -2px 0px white, 2px 2px 0px white, 2px -2px 0px white, -2px 2px 0px white;
}
.indigo-blueGrey .UI-V2 .icon-complex mat-icon.complex.mat-accent {
  color: #607d8b;
}
.indigo-blueGrey .UI-V2 .main .toolbar .header .content .module-title {
  color: #3f51b5;
}
.indigo-blueGrey .UI-V2 .main .toolbar .header .content .module-title.selectable:hover {
  background: rgba(0, 0, 0, 0.04);
}
.indigo-blueGrey .UI-V2 .main .toolbar .header .content .module-title .info h5 {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-blueGrey .UI-V2 .main .toolbar .header .content .ou-select {
  color: white;
  background: #3f51b5;
}
.indigo-blueGrey .UI-V2 .main .toolbar .search-container .search .content {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .UI-V2 .main .toolbar .search-container .search .content:hover, .indigo-blueGrey .UI-V2 .main .toolbar .search-container .search .content:active, .indigo-blueGrey .UI-V2 .main .toolbar .search-container .search .content:focus, .indigo-blueGrey .UI-V2 .main .toolbar .search-container .search .content:focus-within {
  background: rgba(0, 0, 0, 0.04) !important;
}
.indigo-blueGrey .UI-V2 .main .toolbar .search-container .search .content input {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey .UI-V2 .main .helper {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .UI-V2 .main .helper mat-hint h4 {
  color: #3f51b5;
}
.indigo-blueGrey .UI-V2 .main .new-table app-cs-grid-control datatable-header * {
  color: #3f51b5;
}
.indigo-blueGrey .UI-V2 .main .new-table .mat-header-row.sticky * {
  color: #3f51b5 !important;
}
.indigo-blueGrey .UI-V2 .main .main-action {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .menu-options.help .primary {
  color: #3f51b5;
}
.indigo-blueGrey .menu-options.help .accent {
  color: #607d8b;
}
.indigo-blueGrey .menu-options .icon-complex mat-icon.complex {
  color: #3f51b5;
  text-shadow: -2px 0 0px white, 0 -2px 0px white, 2px 0 0px white, 0 2px 0px white, -2px -2px 0px white, 2px 2px 0px white, 2px -2px 0px white, -2px 2px 0px white;
}
.indigo-blueGrey .menu-options .mat-menu-content .container mat-list .mat-list-item:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.indigo-blueGrey .menu-options .mat-menu-content .container mat-list .mat-list-item .disabled {
  color: rgba(0, 0, 0, 0.54) !important;
}
.indigo-blueGrey .menu-options .mat-menu-content .container .slide-group mat-radio-group mat-radio-button:hover {
  background: rgba(0, 0, 0, 0.04);
}
.indigo-blueGrey .menu-options .mat-menu-content .container .slide-group mat-form-field .mat-form-field-flex {
  background: #fafafa;
}
.indigo-blueGrey .menu-options .mat-menu-content .container .slide-group mat-slide-toggle:hover {
  background: rgba(0, 0, 0, 0.04);
}
.indigo-blueGrey .mat-menu-panel.notifications-panel .mat-menu-content .notifications-body {
  background: #fafafa;
}
.indigo-blueGrey .mat-menu-panel.notifications-panel .mat-menu-content .notification .primary mat-icon {
  color: #3f51b5;
}
.indigo-blueGrey .mat-menu-panel.notifications-panel .mat-menu-content .notification .primary h3 {
  color: #3f51b5;
}
.indigo-blueGrey .mat-menu-panel.notifications-panel .mat-menu-content .notification.top {
  background: #fafafa;
}
.indigo-blueGrey .mat-menu-panel.notifications-panel .mat-menu-content .notification.top .header h3 {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey .mat-menu-panel.notifications-panel .mat-menu-content .notification.bottom {
  background: #fafafa;
}
.indigo-blueGrey .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable:nth-child(even) {
  background: #fafafa;
}
.indigo-blueGrey .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable:nth-child(odd) {
  background: white;
}
.indigo-blueGrey .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable:hover {
  background: rgba(0, 0, 0, 0.04);
}
.indigo-blueGrey .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable mat-icon {
  color: #3f51b5;
}
.indigo-blueGrey .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable h3 {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable h5 {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-blueGrey .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable .subtitle h5 {
  color: #3f51b5;
  background: #eeeeee;
}
.indigo-blueGrey .info-empty .large-icon {
  background: #c5cae9;
  color: #3f51b5;
}
.indigo-blueGrey .info-empty h2,
.indigo-blueGrey .info-empty h3 {
  color: #3f51b5;
}
.indigo-blueGrey .info-empty h4,
.indigo-blueGrey .info-empty h5 {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-blueGrey .info-empty.accent mat-icon {
  background: #cfd8dc;
  color: #607d8b;
}
.indigo-blueGrey .info-empty.accent h2 {
  color: #607d8b;
}
.indigo-blueGrey .info-empty .actions {
  padding: 16px;
  height: 96px;
  column-gap: 16px;
}
.indigo-blueGrey .info-empty .actions button {
  height: 100%;
  border-radius: 40px;
}
.indigo-blueGrey .info-empty .actions button.ok {
  background: #009688 !important;
  color: white;
}
.indigo-blueGrey .info-empty .actions button.not-ok {
  color: #9C27B0 !important;
}
.indigo-blueGrey .side.edition .info-empty .large-icon {
  background: #cfd8dc;
  color: #607d8b;
}
.indigo-blueGrey .side.edition .info-empty h2 {
  color: #607d8b;
}
.indigo-blueGrey .side.edition .info-empty h4 {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-blueGrey .UI-V2 .deferred-process-list .header h3 {
  color: #3f51b5;
}
.indigo-blueGrey .UI-V2 .deferred-process-list .search-container .search .content {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .UI-V2 .deferred-process-list .search-container .search .content:hover, .indigo-blueGrey .UI-V2 .deferred-process-list .search-container .search .content:active, .indigo-blueGrey .UI-V2 .deferred-process-list .search-container .search .content:focus, .indigo-blueGrey .UI-V2 .deferred-process-list .search-container .search .content:focus-within {
  background: rgba(0, 0, 0, 0.04) !important;
}
.indigo-blueGrey .UI-V2 .deferred-process-list .search-container .search .content input {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey .UI-V2 .deferred-process-list .text-success,
.indigo-blueGrey .UI-V2 .deferred-process-details .text-success {
  color: #8BC34A;
}
.indigo-blueGrey .UI-V2 .deferred-process-list .text-warn,
.indigo-blueGrey .UI-V2 .deferred-process-details .text-warn {
  color: #f44336;
}
.indigo-blueGrey .UI-V2 .deferred-process-list .text-primary,
.indigo-blueGrey .UI-V2 .deferred-process-details .text-primary {
  color: #3f51b5;
}
.indigo-blueGrey .UI-V2 .deferred-process-list .text-atention,
.indigo-blueGrey .UI-V2 .deferred-process-details .text-atention {
  color: #DB7703;
}
.indigo-blueGrey .UI-V2 .deferred-process-list .table-paginator-container,
.indigo-blueGrey .UI-V2 .deferred-process-details .table-paginator-container {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
}
.indigo-blueGrey .UI-V2 .deferred-process-list .table-paginator-container .mat-table,
.indigo-blueGrey .UI-V2 .deferred-process-details .table-paginator-container .mat-table {
  border-bottom: none;
  border-radius: 10px 10px 0 0;
}
.indigo-blueGrey .UI-V2 .deferred-process-list .table-paginator-container .paginator-container,
.indigo-blueGrey .UI-V2 .deferred-process-details .table-paginator-container .paginator-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: #f2f2f2;
  padding: 0 1.5rem;
  height: 3rem;
}
.indigo-blueGrey .UI-V2 .deferred-process-list .mat-table,
.indigo-blueGrey .UI-V2 .deferred-process-details .mat-table {
  border: 1px solid #f2f2f2;
  border-radius: 10px;
  overflow: hidden;
}
.indigo-blueGrey .UI-V2 .deferred-process-list .mat-table .mat-header-row .mat-header-cell,
.indigo-blueGrey .UI-V2 .deferred-process-details .mat-table .mat-header-row .mat-header-cell {
  font-size: 14px;
  font-weight: 700;
}
.indigo-blueGrey .UI-V2 .deferred-process-list .mat-table .mat-header-row,
.indigo-blueGrey .UI-V2 .deferred-process-details .mat-table .mat-header-row {
  background: #f2f2f2;
}
.indigo-blueGrey .UI-V2 .deferred-process-list .mat-table .mat-header-row .mat-header-cell,
.indigo-blueGrey .UI-V2 .deferred-process-details .mat-table .mat-header-row .mat-header-cell {
  color: #3f51b5;
}
.indigo-blueGrey .UI-V2 .deferred-process-list .mat-table .mat-row,
.indigo-blueGrey .UI-V2 .deferred-process-details .mat-table .mat-row {
  background: white;
}
.indigo-blueGrey .UI-V2 .progress-bar-container {
  position: relative;
}
.indigo-blueGrey .UI-V2 .deferred-process-details .header h3 {
  color: #3f51b5;
}
.indigo-blueGrey .UI-V2 .deferred-process-details .header .back-link:visited {
  color: #3f51b5;
}
.indigo-blueGrey .process-card,
.indigo-blueGrey .process-card-detail,
.indigo-blueGrey .process-card-stages {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .process-card mat-card-subtitle,
.indigo-blueGrey .process-card-detail mat-card-subtitle,
.indigo-blueGrey .process-card-stages mat-card-subtitle {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey .process-card .stages-container .stage.completed mat-icon,
.indigo-blueGrey .process-card-detail .stages-container .stage.completed mat-icon,
.indigo-blueGrey .process-card-stages .stages-container .stage.completed mat-icon {
  color: #499719;
}
.indigo-blueGrey .process-card .stages-container .stage.completed .icon-container,
.indigo-blueGrey .process-card-detail .stages-container .stage.completed .icon-container,
.indigo-blueGrey .process-card-stages .stages-container .stage.completed .icon-container {
  background-color: rgba(78, 160, 28, 0.1490196078);
}
.indigo-blueGrey .process-card .stages-container .stage.completed .line,
.indigo-blueGrey .process-card-detail .stages-container .stage.completed .line,
.indigo-blueGrey .process-card-stages .stages-container .stage.completed .line {
  background-color: #499719;
}
.indigo-blueGrey .process-card .stages-container .stage.on-going mat-icon,
.indigo-blueGrey .process-card-detail .stages-container .stage.on-going mat-icon,
.indigo-blueGrey .process-card-stages .stages-container .stage.on-going mat-icon {
  color: #3f51b5;
}
.indigo-blueGrey .process-card .stages-container .stage.on-going .icon-container,
.indigo-blueGrey .process-card-detail .stages-container .stage.on-going .icon-container,
.indigo-blueGrey .process-card-stages .stages-container .stage.on-going .icon-container {
  background-color: rgba(66, 48, 159, 0.2);
}
.indigo-blueGrey .process-card .stages-container .stage.on-going .line,
.indigo-blueGrey .process-card-detail .stages-container .stage.on-going .line,
.indigo-blueGrey .process-card-stages .stages-container .stage.on-going .line {
  background-color: #949494;
}
.indigo-blueGrey .process-card .stages-container .stage.pending,
.indigo-blueGrey .process-card-detail .stages-container .stage.pending,
.indigo-blueGrey .process-card-stages .stages-container .stage.pending {
  color: #949494;
}
.indigo-blueGrey .process-card .stages-container .stage.pending mat-icon,
.indigo-blueGrey .process-card-detail .stages-container .stage.pending mat-icon,
.indigo-blueGrey .process-card-stages .stages-container .stage.pending mat-icon {
  color: #949494;
}
.indigo-blueGrey .process-card .stages-container .stage.pending .icon-container,
.indigo-blueGrey .process-card-detail .stages-container .stage.pending .icon-container,
.indigo-blueGrey .process-card-stages .stages-container .stage.pending .icon-container {
  background-color: rgba(174, 174, 174, 0.2);
}
.indigo-blueGrey .process-card .stages-container .stage.pending .line,
.indigo-blueGrey .process-card-detail .stages-container .stage.pending .line,
.indigo-blueGrey .process-card-stages .stages-container .stage.pending .line {
  background-color: #949494;
}
.indigo-blueGrey .process-card .stages-container .stage.has-error mat-icon,
.indigo-blueGrey .process-card-detail .stages-container .stage.has-error mat-icon,
.indigo-blueGrey .process-card-stages .stages-container .stage.has-error mat-icon {
  color: #f44336;
}
.indigo-blueGrey .process-card .stages-container .stage.has-error .icon-container,
.indigo-blueGrey .process-card-detail .stages-container .stage.has-error .icon-container,
.indigo-blueGrey .process-card-stages .stages-container .stage.has-error .icon-container {
  background-color: rgba(188, 4, 4, 0.2);
}
.indigo-blueGrey .process-card .stages-container .stage-description .button-link,
.indigo-blueGrey .process-card-detail .stages-container .stage-description .button-link,
.indigo-blueGrey .process-card-stages .stages-container .stage-description .button-link {
  color: #3f51b5;
}
.indigo-blueGrey app-custom-search-input .custom-search-container {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
  transition: color 0.1s ease, background 0.15s ease;
}
.indigo-blueGrey app-custom-search-input .custom-search-container mat-icon.mat-icon {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-blueGrey app-custom-search-input .custom-search-container input.custom-search-input {
  color: rgba(0, 0, 0, 0.87);
  background: unset;
}
.indigo-blueGrey app-custom-search-input .custom-search-container:hover, .indigo-blueGrey app-custom-search-input .custom-search-container:active, .indigo-blueGrey app-custom-search-input .custom-search-container:focus, .indigo-blueGrey app-custom-search-input .custom-search-container:focus-within {
  background: rgba(0, 0, 0, 0.04) !important;
  transition: background 0.15s ease;
}
.indigo-blueGrey .UI-V2 .audit-filter {
  background: white;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .UI-V2 .audit-filter .toolbar {
  background: #3f51b5;
  color: white;
}
.indigo-blueGrey .UI-V2 .audit-filter .toolbar .header .content .module-title {
  color: #3f51b5;
}
.indigo-blueGrey .UI-V2 .audit-filter .toolbar .header .content .module-title.selectable:hover {
  background: rgba(0, 0, 0, 0.04);
}
.indigo-blueGrey .UI-V2 .audit-filter .toolbar .header .content .module-title .info * {
  color: white !important;
}
.indigo-blueGrey .UI-V2 .audit-filter .toolbar .header .content .ou-select {
  color: white;
  background: #3f51b5;
}
.indigo-blueGrey .UI-V2 .audit-filter .toolbar .search-container .search .content {
  background: #fafafa;
}
.indigo-blueGrey .UI-V2 .audit-filter .toolbar .search-container .search .content:hover, .indigo-blueGrey .UI-V2 .audit-filter .toolbar .search-container .search .content:active, .indigo-blueGrey .UI-V2 .audit-filter .toolbar .search-container .search .content:focus, .indigo-blueGrey .UI-V2 .audit-filter .toolbar .search-container .search .content:focus-within {
  background: rgba(0, 0, 0, 0.04) !important;
}
.indigo-blueGrey .UI-V2 .audit-filter .toolbar .search-container .search .content input {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey .UI-V2 .audit-filter .filter-container {
  background: white !important;
}
.indigo-blueGrey .UI-V2 .audit-filter .filter-container .form-body {
  background: #fafafa !important;
}
.indigo-blueGrey .UI-V2 .audit-filter .filter-container .form-body .line h4 {
  color: #3f51b5;
}
.indigo-blueGrey .UI-V2 .audit-filter .filter-container .form-body .line mat-icon {
  color: #3f51b5;
}
.indigo-blueGrey .UI-V2 .audit-result .result-container .toolbar {
  color: white;
}
.indigo-blueGrey .UI-V2 .audit-result .result-container .toolbar .header .content .module-title {
  color: #3f51b5;
}
.indigo-blueGrey .UI-V2 .audit-result .result-container .toolbar .header .content .module-title.selectable:hover {
  background: rgba(0, 0, 0, 0.04);
}
.indigo-blueGrey .UI-V2 .audit-result .result-container .toolbar .header .content .module-title .info h5 {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-blueGrey .UI-V2 .audit-result .result-container .toolbar .header .content .ou-select {
  color: white;
  background: #3f51b5;
}
.indigo-blueGrey .UI-V2 .audit-result .result-container .toolbar .search-container .search .content {
  background: #fafafa;
}
.indigo-blueGrey .UI-V2 .audit-result .result-container .toolbar .search-container .search .content:hover, .indigo-blueGrey .UI-V2 .audit-result .result-container .toolbar .search-container .search .content:active, .indigo-blueGrey .UI-V2 .audit-result .result-container .toolbar .search-container .search .content:focus, .indigo-blueGrey .UI-V2 .audit-result .result-container .toolbar .search-container .search .content:focus-within {
  background: rgba(0, 0, 0, 0.04) !important;
}
.indigo-blueGrey .UI-V2 .audit-result .result-container .toolbar .search-container .search .content input {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey .UI-V2 .audit-result .result-container .new-table app-cs-grid-control datatable-header * {
  color: #3f51b5;
}
.indigo-blueGrey .UI-V2 .audit-result .result-container .new-table .mat-header-row.sticky * {
  color: #3f51b5 !important;
}
.indigo-blueGrey .UI-V2 .audit-detail-container {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  background: #eeeeee !important;
}
.indigo-blueGrey .UI-V2 .audit-detail-container .side .toolbar .header {
  color: #3f51b5;
}
.indigo-blueGrey .UI-V2 .audit-detail-container .side .container .mat-list-base {
  background: #fafafa !important;
}
.indigo-blueGrey .UI-V2 .audit-detail-container .side .container .mat-list-base mat-list-item .mat-line b {
  color: #3f51b5;
}
.indigo-blueGrey .UI-V2 .inbox-container {
  background: white;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .UI-V2 .inbox-container *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.indigo-blueGrey .UI-V2 .inbox-container *::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}
.indigo-blueGrey .UI-V2 .inbox-container *::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}
.indigo-blueGrey .UI-V2 .inbox-container .load-window {
  background: white;
}
.indigo-blueGrey .UI-V2 .inbox-container .toolbar {
  background: #3f51b5;
  color: white;
}
.indigo-blueGrey .UI-V2 .inbox-container .toolbar.reverse {
  background: white;
  color: rgba(0, 0, 0, 0.54);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel {
  background: #eeeeee !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel.mat-expanded {
  background: #fafafa !important;
}
.indigo-blueGrey .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel.mat-expanded mat-expansion-panel-header {
  background: #fafafa !important;
}
.indigo-blueGrey .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel.mat-expanded mat-expansion-panel-header:hover {
  background: none !important;
}
.indigo-blueGrey .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel mat-expansion-panel-header {
  background: #fafafa !important;
}
.indigo-blueGrey .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel mat-expansion-panel-header:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.indigo-blueGrey .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel mat-expansion-panel-header .signature-inbox-header .mat-icon,
.indigo-blueGrey .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel mat-expansion-panel-header .signature-inbox-header b {
  color: #607d8b;
}
.indigo-blueGrey .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel .mat-expansion-panel-body .item {
  background: white;
  color: rgba(0, 0, 0, 0.54);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel .mat-expansion-panel-body .item:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.indigo-blueGrey .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel .mat-expansion-panel-body .item.active {
  border-left: 6px solid #607d8b;
}
.indigo-blueGrey .UI-V2 .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.indigo-blueGrey .UI-V2 .work-space .main .load-window {
  background: white !important;
}
.indigo-blueGrey .UI-V2 .work-space .main-action {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey mat-radio-button.disabled .mat-radio-label-content {
  color: #607d8b;
}
.indigo-blueGrey .detail app-automatic-process-detail app-chapa.minimize,
.indigo-blueGrey .detail app-process-detail app-chapa.minimize {
  background: #FFD54F !important;
}
.indigo-blueGrey .detail app-automatic-process-detail app-chapa.minimize.purged,
.indigo-blueGrey .detail app-process-detail app-chapa.minimize.purged {
  background: #b2adb0 !important;
}
.indigo-blueGrey .detail .detail-toolbar {
  background: #eeeeee;
}
.indigo-blueGrey .detail .processes-status .status-bar .bar-graph {
  background-color: #FFD54F;
}
.indigo-blueGrey .detail .processes-status .status-bar .bar-graph .graph-ok {
  background-color: #4DB6AC;
}
.indigo-blueGrey .detail .processes-status .status-bar .bar-graph .graph-not-ok {
  background-color: #F48FB1;
}
.indigo-blueGrey .detail .processes-status .status-resume b {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey .detail .processes-status .status-resume .resume-total b {
  background-color: #FFD54F;
}
.indigo-blueGrey .detail .processes-status .status-resume .resume-ok b {
  background-color: #4DB6AC;
}
.indigo-blueGrey .detail .processes-status .status-resume .resume-not-ok b {
  background-color: #F48FB1;
}
.indigo-blueGrey .detail .detail-toolbar .mat-avatar h3,
.indigo-blueGrey .detail .detail-toolbar .mat-avatar h5 {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey .detail .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey .detail .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey .detail .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey .detail .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey .detail .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  color: white;
}
.indigo-blueGrey .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  background: #4DB6AC !important;
}
.indigo-blueGrey .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  background: #b2dfdb !important;
}
.indigo-blueGrey .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  background: #b2dfdb !important;
}
.indigo-blueGrey .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  background: #4DB6AC !important;
}
.indigo-blueGrey .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  background: #b2dfdb !important;
}
.indigo-blueGrey .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  background: #837C80 !important;
}
.indigo-blueGrey .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  background: #b2adb0 !important;
}
.indigo-blueGrey .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  background: #b2adb0 !important;
}
.indigo-blueGrey .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  background: #837C80 !important;
}
.indigo-blueGrey .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  background: #b2dfdb !important;
}
.indigo-blueGrey .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  background: #FFD54F !important;
}
.indigo-blueGrey .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  background: #ffecb3 !important;
}
.indigo-blueGrey .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  background: #ffecb3 !important;
}
.indigo-blueGrey .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  background: #FFD54F !important;
}
.indigo-blueGrey .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  background: #ffecb3 !important;
}
.indigo-blueGrey .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  background: #F48FB1 !important;
}
.indigo-blueGrey .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  background: #f8bbd0 !important;
}
.indigo-blueGrey .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  background: #f8bbd0 !important;
}
.indigo-blueGrey .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  background: #F48FB1 !important;
}
.indigo-blueGrey .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  background: #f8bbd0 !important;
}
.indigo-blueGrey app-analytics-document-set .sets-container .mat-list-base.sets-list mat-list-item {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey app-analytics-document-set .sets-container .mat-list-base.sets-list mat-list-item .mat-list-item-content .set-status .value-percentile .undone-value {
  background: #673AB7;
}
.indigo-blueGrey app-analytics-document-set .sets-container .mat-list-base.sets-list mat-list-item .mat-list-item-content .set-status .value-percentile .not-value {
  background: #eeeeee;
}
.indigo-blueGrey app-analytics-document-set .sets-container .mat-list-base.sets-list mat-list-item .mat-list-item-content .set-status .value-percentile .done-value {
  background: #009688;
}
.indigo-blueGrey app-analytics-document-set .work-space .main .toolbar .header .content .module-title .info .info-text .set-status {
  cursor: help;
}
.indigo-blueGrey app-analytics-document-set .work-space .main .toolbar .header .content .module-title .info .info-text .set-status .value-percentile .undone-value {
  background: #673AB7;
}
.indigo-blueGrey app-analytics-document-set .work-space .main .toolbar .header .content .module-title .info .info-text .set-status .value-percentile .not-value {
  background: #eeeeee;
}
.indigo-blueGrey app-analytics-document-set .work-space .main .toolbar .header .content .module-title .info .info-text .set-status .value-percentile .done-value {
  background: #009688;
}
.indigo-blueGrey app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar .mat-progress-bar-buffer {
  background-color: #eeeeee;
}
.indigo-blueGrey app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar.class1 .mat-progress-bar-fill::after {
  background-color: #f44336;
}
.indigo-blueGrey app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar.class2 .mat-progress-bar-fill::after {
  background-color: #ff9800;
}
.indigo-blueGrey app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar.class3 .mat-progress-bar-fill::after {
  background-color: #ffeb3b;
}
.indigo-blueGrey app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar.class4 .mat-progress-bar-fill::after {
  background-color: #8bc34a;
}
.indigo-blueGrey app-analytics-document-set .work-space .side .container mat-list {
  background: white !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey app-analytics-document-set .work-space .side .container mat-list mat-list-item .mat-line b {
  color: #3f51b5;
}
.indigo-blueGrey .catcher {
  background: rgba(0, 0, 0, 0.6);
}
.indigo-blueGrey .catcher.clear {
  background: rgba(0, 0, 0, 0);
}
.indigo-blueGrey .add-menu-form-body .mat-radio-group .mat-radio-button:hover {
  background: rgba(0, 0, 0, 0.04);
}
.indigo-blueGrey .add-menu-form-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey .add-menu-form-body.w-table .helper .search-bar {
  background: #fafafa;
}
.indigo-blueGrey .add-menu-form-body.w-table .helper .search-bar:hover, .indigo-blueGrey .add-menu-form-body.w-table .helper .search-bar:active, .indigo-blueGrey .add-menu-form-body.w-table .helper .search-bar:focus, .indigo-blueGrey .add-menu-form-body.w-table .helper .search-bar:focus-within {
  background: #fafafa !important;
}
.indigo-blueGrey .add-menu-form-body.w-table .helper .search-bar input {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey .add-menu-form-actions {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .file-upload-list mat-list-item {
  background: white;
}
.indigo-blueGrey .mat-horizontal-stepper-content[aria-expanded=true] app-file-document-view {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .mat-horizontal-stepper-content[aria-expanded=true] app-file-document-view .pdf-view-container {
  background: #eeeeee;
}
.indigo-blueGrey .add-docs-drop .docs-drop-preview app-file-document-detail {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .add-docs-drop .docs-drop-preview app-file-document-detail .view-toolbar {
  background: white;
}
.indigo-blueGrey .add-docs-drop .load-window.message h3.primary-text {
  color: #3f51b5;
}
.indigo-blueGrey mat-dialog-container app-add-menu-dialog .load-window.message h3.primary-text {
  color: #3f51b5;
}
.indigo-blueGrey .asisted-input.aligned-icon.subtitle * {
  color: rgba(0, 0, 0, 0.38) !important;
}
.indigo-blueGrey .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble {
  background: #3f51b5;
}
.indigo-blueGrey .asisted-input .mat-form-field-invalid + .indicator > .bubble {
  background: #f44336;
}
.indigo-blueGrey .asisted-input .mat-form-field-disabled + .indicator > .bubble {
  color: rgba(0, 0, 0, 0.38);
  background: rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .asisted-input .indicator .bubble {
  background: rgba(0, 0, 0, 0.38);
  color: white;
}
.indigo-blueGrey .asisted-input .indicator .guide {
  border-color: rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .asisted-input.icon-only .bubble {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-blueGrey .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble {
  color: #3f51b5;
}
.indigo-blueGrey .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble {
  color: #f44336;
}
.indigo-blueGrey .UI-V2 .main-overlay {
  background: rgba(0, 0, 0, 0.32);
}
.indigo-blueGrey .UI-V2 .icon-complex mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.indigo-blueGrey .UI-V2 .icon-complex mat-icon.complex {
  color: #3f51b5 !important;
}
.indigo-blueGrey .UI-V2 .navigation {
  background: #fafafa;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .UI-V2 .navigation *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.indigo-blueGrey .UI-V2 .navigation *::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}
.indigo-blueGrey .UI-V2 .navigation .load-window {
  background: #eeeeee;
}
.indigo-blueGrey .UI-V2 .navigation .toolbar {
  background: #3f51b5;
  color: white;
}
.indigo-blueGrey .UI-V2 .navigation .toolbar.reverse {
  background: white;
  color: rgba(0, 0, 0, 0.54);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .UI-V2 .navigation .container .helper {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-blueGrey .UI-V2 .navigation .container .item {
  background: white;
  color: rgba(0, 0, 0, 0.54);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .UI-V2 .navigation .container .item:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.indigo-blueGrey .UI-V2 .navigation .container .item.active {
  background: #3f51b5 !important;
  color: white;
  border: 1px solid #3f51b5;
}
.indigo-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .separator .subtitle,
.indigo-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .separator .subtitle,
.indigo-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .separator .subtitle,
.indigo-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .separator .subtitle,
.indigo-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .separator .subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .img-editior-container .img-editor .separator .subtitle,
.indigo-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .img-editior-container .img-editor .separator .subtitle,
.indigo-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .img-editior-container .img-editor .separator .subtitle,
.indigo-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .img-editior-container .img-editor .separator .subtitle,
.indigo-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .img-editior-container .img-editor .separator .subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme,
.indigo-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme,
.indigo-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme,
.indigo-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme,
.indigo-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .main-color-container .main-color,
.indigo-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .main-color-container .main-color,
.indigo-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .main-color-container .main-color,
.indigo-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .main-color-container .main-color,
.indigo-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .main-color-container .main-color {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .main-color-container .main-color:before,
.indigo-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .main-color-container .main-color:before,
.indigo-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .main-color-container .main-color:before,
.indigo-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .main-color-container .main-color:before,
.indigo-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .main-color-container .main-color:before {
  border-left: 10px solid rgba(0, 0, 0, 0);
  border-right: 10px solid rgba(0, 0, 0, 0);
}
.indigo-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example h6.required,
.indigo-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example h6.required,
.indigo-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example h6.required,
.indigo-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example h6.required,
.indigo-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example h6.required {
  color: #f44336 !important;
}
.indigo-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle,
.indigo-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle,
.indigo-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle,
.indigo-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle,
.indigo-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle {
  border: 2px solid rgba(0, 0, 0, 0);
}
.indigo-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .side-color-container .side-color,
.indigo-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .side-color-container .side-color,
.indigo-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .side-color-container .side-color,
.indigo-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .side-color-container .side-color,
.indigo-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .side-color-container .side-color {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .side-color-container .side-color:before,
.indigo-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .side-color-container .side-color:before,
.indigo-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .side-color-container .side-color:before,
.indigo-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .side-color-container .side-color:before,
.indigo-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .side-color-container .side-color:before {
  border-top: 10px solid rgba(0, 0, 0, 0);
  border-bottom: 10px solid rgba(0, 0, 0, 0);
}
.indigo-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .side-color-container .side-color h6.required,
.indigo-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .side-color-container .side-color h6.required,
.indigo-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .side-color-container .side-color h6.required,
.indigo-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .side-color-container .side-color h6.required,
.indigo-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .side-color-container .side-color h6.required {
  color: #f44336 !important;
}
.indigo-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .permission-settings-cards .card,
.indigo-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .permission-settings-cards .card,
.indigo-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .permission-settings-cards .card,
.indigo-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .permission-settings-cards .card,
.indigo-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .permission-settings-cards .card {
  background: #fafafa;
}
.indigo-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .permission-settings-cards .card .slide-sub-group,
.indigo-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .permission-settings-cards .card .slide-sub-group,
.indigo-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .permission-settings-cards .card .slide-sub-group,
.indigo-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .permission-settings-cards .card .slide-sub-group,
.indigo-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .permission-settings-cards .card .slide-sub-group {
  background: rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .UI-V2 .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.indigo-blueGrey .UI-V2 .work-space .main .load-window {
  background: white !important;
}
.indigo-blueGrey .UI-V2 .work-space .main.side-open {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item mat-icon.complex {
  text-shadow: -2px 0 0px #eeeeee, 0 -2px 0px #eeeeee, 2px 0 0px #eeeeee, 0 2px 0px #eeeeee, -2px -2px 0px #eeeeee, 2px 2px 0px #eeeeee, 2px -2px 0px #eeeeee, -2px 2px 0px #eeeeee !important;
}
.indigo-blueGrey .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item h5 {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-blueGrey .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item h3 {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item .actions {
  pointer-events: none;
}
.indigo-blueGrey .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item .actions button {
  pointer-events: all;
}
.indigo-blueGrey .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled {
  background: #eeeeee;
}
.indigo-blueGrey .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled h3 {
  color: #607D8B !important;
}
.indigo-blueGrey .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled mat-icon {
  color: #607D8B !important;
}
.indigo-blueGrey .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled.selected {
  background: #607D8B;
  color: white !important;
}
.indigo-blueGrey .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled.selected h3 {
  color: white !important;
}
.indigo-blueGrey .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled.selected mat-icon {
  color: white !important;
}
.indigo-blueGrey .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled.selected mat-icon.complex {
  color: white !important;
  text-shadow: -2px 0 0px #607D8B, 0 -2px 0px #607D8B, 2px 0 0px #607D8B, 0 2px 0px #607D8B, -2px -2px 0px #607D8B, 2px 2px 0px #607D8B, 2px -2px 0px #607D8B, -2px 2px 0px #607D8B !important;
}
.indigo-blueGrey .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item:hover {
  background: rgba(0, 0, 0, 0.04);
}
.indigo-blueGrey .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected {
  background: #3f51b5;
  color: white;
}
.indigo-blueGrey .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected mat-icon {
  color: white;
}
.indigo-blueGrey .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected mat-icon.complex {
  color: white !important;
  text-shadow: -2px 0 0px #3f51b5, 0 -2px 0px #3f51b5, 2px 0 0px #3f51b5, 0 2px 0px #3f51b5, -2px -2px 0px #3f51b5, 2px 2px 0px #3f51b5, 2px -2px 0px #3f51b5, -2px 2px 0px #3f51b5 !important;
}
.indigo-blueGrey .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected h5,
.indigo-blueGrey .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected h3 {
  color: white;
}
.indigo-blueGrey .UI-V2 .work-space .main .main-action {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .UI-V2 .work-space .side {
  background: #fafafa;
}
.indigo-blueGrey .UI-V2 .work-space .side .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #fafafa, 0 -2px 0px #fafafa, 2px 0 0px #fafafa, 0 2px 0px #fafafa, -2px -2px 0px #fafafa, 2px 2px 0px #fafafa, 2px -2px 0px #fafafa, -2px 2px 0px #fafafa;
}
.indigo-blueGrey .UI-V2 .work-space .side *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.indigo-blueGrey .UI-V2 .work-space .side *::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}
.indigo-blueGrey .UI-V2 .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #fafafa !important;
}
.indigo-blueGrey .UI-V2 .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-blueGrey .UI-V2 .work-space .side mat-form-field .mat-form-field-flex {
  background: #fafafa;
}
.indigo-blueGrey .UI-V2 .work-space .side mat-checkbox:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.indigo-blueGrey .UI-V2 .work-space .side .hint.all {
  color: #607d8b;
}
.indigo-blueGrey .UI-V2 .work-space .side .toolbar .header {
  color: #3f51b5;
}
.indigo-blueGrey .UI-V2 .work-space .side .toolbar .header h3 {
  color: #3f51b5;
}
.indigo-blueGrey .UI-V2 .work-space .side .container .role-resume {
  background: #3f51b5;
}
.indigo-blueGrey .UI-V2 .work-space .side .container .role-resume .line {
  color: white;
}
.indigo-blueGrey .UI-V2 .work-space .side .container .role-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.1);
}
.indigo-blueGrey .UI-V2 .work-space .side .container .role-resume .line.editable button {
  background: #3f51b5;
}
.indigo-blueGrey .UI-V2 .work-space .side .container .section-title {
  color: #3f51b5;
}
.indigo-blueGrey .UI-V2 .work-space .side .container .section-resume {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .UI-V2 .work-space .side .container .section-resume .line.editable:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.indigo-blueGrey .UI-V2 .work-space .side .container .section-resume .line.editable:hover .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px white, 0 -2px 0px white, 2px 0 0px white, 0 2px 0px white, -2px -2px 0px white, 2px 2px 0px white, 2px -2px 0px white, -2px 2px 0px white !important;
}
.indigo-blueGrey .UI-V2 .work-space .side .container .section-resume .line.editable button {
  background: #fafafa;
}
.indigo-blueGrey .UI-V2 .work-space .side .container .section-resume .line .header .icon-complex mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.indigo-blueGrey .UI-V2 .work-space .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #3f51b5 !important;
}
.indigo-blueGrey .UI-V2 .work-space .side .container .section-resume .line .header mat-icon:not(.complex) {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-blueGrey .UI-V2 .work-space .side .container .section-resume .line .header .info h4 {
  color: #3f51b5;
}
.indigo-blueGrey .UI-V2 .work-space .side .container .section-resume .line ul {
  color: #3f51b5;
}
.indigo-blueGrey .UI-V2 .work-space .side .container .section-resume .line.head {
  background: #eeeeee;
}
.indigo-blueGrey .UI-V2 .work-space .side .container .section-resume .line.head mat-icon {
  color: #607d8b;
}
.indigo-blueGrey .UI-V2 .work-space .side .container .section-resume .line.head h4 {
  color: white;
}
.indigo-blueGrey .UI-V2 .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body {
  background: white;
}
.indigo-blueGrey .UI-V2 .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-blueGrey .UI-V2 .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #3f51b5;
}
.indigo-blueGrey .UI-V2 .work-space .side.edition .toolbar .header {
  color: white;
}
.indigo-blueGrey .UI-V2 .work-space .side.edition .toolbar .header mat-icon {
  color: #607d8b;
}
.indigo-blueGrey .UI-V2 .work-space .side.edition .toolbar .header h3 {
  color: #607d8b;
}
.indigo-blueGrey .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body {
  background: white !important;
}
.indigo-blueGrey .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header mat-icon:not(.complex) {
  color: #3f51b5;
}
.indigo-blueGrey .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header h4 {
  color: #3f51b5;
}
.indigo-blueGrey .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-actions {
  background: #fafafa;
}
.indigo-blueGrey .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.indigo-blueGrey .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header .icon-complex mat-icon.complex {
  color: #607d8b !important;
}
.indigo-blueGrey .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header h4 {
  color: #607d8b;
}
.indigo-blueGrey .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line ul {
  color: #607d8b;
  font-weight: 400;
}
.indigo-blueGrey .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head mat-icon {
  color: #607d8b !important;
}
.indigo-blueGrey .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head h4 {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .section-title {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-blueGrey .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume h6.hint {
  background: #cfd8dc;
}
.indigo-blueGrey app-role-user-find .main-overlay {
  background: rgba(0, 0, 0, 0.32);
}
.indigo-blueGrey app-role-user-find .icon-complex mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.indigo-blueGrey app-role-user-find .icon-complex mat-icon.complex {
  color: #3f51b5 !important;
}
.indigo-blueGrey app-role-user-find .navigation {
  background: #fafafa;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey app-role-user-find .navigation *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.indigo-blueGrey app-role-user-find .navigation *::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}
.indigo-blueGrey app-role-user-find .navigation .load-window {
  background: #eeeeee;
}
.indigo-blueGrey app-role-user-find .navigation .toolbar {
  background: #3f51b5;
  color: white;
}
.indigo-blueGrey app-role-user-find .navigation .toolbar.reverse {
  background: white;
  color: rgba(0, 0, 0, 0.54);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey app-role-user-find .navigation .container .helper {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-blueGrey app-role-user-find .navigation .container .item {
  background: white;
  color: rgba(0, 0, 0, 0.54);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey app-role-user-find .navigation .container .item:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.indigo-blueGrey app-role-user-find .navigation .container .item.active {
  background: #3f51b5 !important;
  color: white;
  border: 1px solid #3f51b5;
}
.indigo-blueGrey app-role-user-find .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.indigo-blueGrey app-role-user-find .work-space .main .load-window {
  background: white !important;
}
.indigo-blueGrey app-role-user-find .work-space .main.side-open {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey app-role-user-find .work-space .main .container .item {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey app-role-user-find .work-space .main .container .item .subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-blueGrey app-role-user-find .work-space .main .container .item.disabled {
  background: #eeeeee;
  color: #607D8B !important;
}
.indigo-blueGrey app-role-user-find .work-space .main .container .item.disabled h3 {
  color: #607D8B !important;
}
.indigo-blueGrey app-role-user-find .work-space .main .container .item.disabled mat-icon {
  color: #607D8B !important;
}
.indigo-blueGrey app-role-user-find .work-space .main .container .item.disabled.selected {
  background: #607D8B;
  color: white !important;
}
.indigo-blueGrey app-role-user-find .work-space .main .container .item.disabled.selected h3 {
  color: white !important;
}
.indigo-blueGrey app-role-user-find .work-space .main .container .item.disabled.selected mat-icon {
  color: white !important;
}
.indigo-blueGrey app-role-user-find .work-space .main .container .item.disabled.selected mat-icon.complex {
  color: white !important;
  text-shadow: -2px 0 0px #607D8B, 0 -2px 0px #607D8B, 2px 0 0px #607D8B, 0 2px 0px #607D8B, -2px -2px 0px #607D8B, 2px 2px 0px #607D8B, 2px -2px 0px #607D8B, -2px 2px 0px #607D8B !important;
}
.indigo-blueGrey app-role-user-find .work-space .main .container .item mat-icon.complex {
  text-shadow: -2px 0 0px #eeeeee, 0 -2px 0px #eeeeee, 2px 0 0px #eeeeee, 0 2px 0px #eeeeee, -2px -2px 0px #eeeeee, 2px 2px 0px #eeeeee, 2px -2px 0px #eeeeee, -2px 2px 0px #eeeeee !important;
}
.indigo-blueGrey app-role-user-find .work-space .main .container .item h5 {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-blueGrey app-role-user-find .work-space .main .container .item h3 {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey app-role-user-find .work-space .main .container .item:hover {
  background: rgba(0, 0, 0, 0.04);
}
.indigo-blueGrey app-role-user-find .work-space .main .container .item.selected {
  background: #3f51b5;
  color: white;
}
.indigo-blueGrey app-role-user-find .work-space .main .container .item.selected mat-icon {
  color: white;
}
.indigo-blueGrey app-role-user-find .work-space .main .container .item.selected mat-icon.complex {
  color: white !important;
  text-shadow: -2px 0 0px #3f51b5, 0 -2px 0px #3f51b5, 2px 0 0px #3f51b5, 0 2px 0px #3f51b5, -2px -2px 0px #3f51b5, 2px 2px 0px #3f51b5, 2px -2px 0px #3f51b5, -2px 2px 0px #3f51b5 !important;
}
.indigo-blueGrey app-role-user-find .work-space .main .container .item.selected h5,
.indigo-blueGrey app-role-user-find .work-space .main .container .item.selected h3 {
  color: white;
}
.indigo-blueGrey app-role-user-find .work-space .main .main-action {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey app-role-user-find .work-space .side {
  background: #fafafa;
}
.indigo-blueGrey app-role-user-find .work-space .side .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #fafafa, 0 -2px 0px #fafafa, 2px 0 0px #fafafa, 0 2px 0px #fafafa, -2px -2px 0px #fafafa, 2px 2px 0px #fafafa, 2px -2px 0px #fafafa, -2px 2px 0px #fafafa;
}
.indigo-blueGrey app-role-user-find .work-space .side *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.indigo-blueGrey app-role-user-find .work-space .side *::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}
.indigo-blueGrey app-role-user-find .work-space .side .edition-line {
  background: #eeeeee;
}
.indigo-blueGrey app-role-user-find .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #fafafa !important;
}
.indigo-blueGrey app-role-user-find .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-blueGrey app-role-user-find .work-space .side mat-form-field .mat-form-field-flex {
  background: #fafafa;
}
.indigo-blueGrey app-role-user-find .work-space .side mat-checkbox:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.indigo-blueGrey app-role-user-find .work-space .side .hint.all {
  color: #607d8b;
}
.indigo-blueGrey app-role-user-find .work-space .side .toolbar .header {
  color: #3f51b5;
}
.indigo-blueGrey app-role-user-find .work-space .side .toolbar .header h3 {
  color: #3f51b5;
}
.indigo-blueGrey app-role-user-find .work-space .side .container .role-resume {
  background: #3f51b5;
}
.indigo-blueGrey app-role-user-find .work-space .side .container .role-resume .line {
  color: white;
}
.indigo-blueGrey app-role-user-find .work-space .side .container .role-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.1);
}
.indigo-blueGrey app-role-user-find .work-space .side .container .role-resume .line.editable button {
  background: #3f51b5;
}
.indigo-blueGrey app-role-user-find .work-space .side .container .section-title {
  color: #3f51b5;
}
.indigo-blueGrey app-role-user-find .work-space .side .container .section-resume {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey app-role-user-find .work-space .side .container .section-resume .line.editable:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.indigo-blueGrey app-role-user-find .work-space .side .container .section-resume .line.editable:hover .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px white, 0 -2px 0px white, 2px 0 0px white, 0 2px 0px white, -2px -2px 0px white, 2px 2px 0px white, 2px -2px 0px white, -2px 2px 0px white !important;
}
.indigo-blueGrey app-role-user-find .work-space .side .container .section-resume .line.editable button {
  background: #fafafa;
}
.indigo-blueGrey app-role-user-find .work-space .side .container .section-resume .line .header .icon-complex mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.indigo-blueGrey app-role-user-find .work-space .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #3f51b5 !important;
}
.indigo-blueGrey app-role-user-find .work-space .side .container .section-resume .line .header mat-icon:not(.complex) {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-blueGrey app-role-user-find .work-space .side .container .section-resume .line .header .info h4 {
  color: #3f51b5;
}
.indigo-blueGrey app-role-user-find .work-space .side .container .section-resume .line ul {
  color: #3f51b5;
}
.indigo-blueGrey app-role-user-find .work-space .side .container .section-resume .line.head {
  background: #eeeeee;
}
.indigo-blueGrey app-role-user-find .work-space .side .container .section-resume .line.head mat-icon {
  color: #607d8b;
}
.indigo-blueGrey app-role-user-find .work-space .side .container .section-resume .line.head h4 {
  color: white;
}
.indigo-blueGrey app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee-title {
  color: #3f51b5;
}
.indigo-blueGrey app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body {
  background: white;
}
.indigo-blueGrey app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-blueGrey app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #3f51b5;
}
.indigo-blueGrey app-role-user-find .work-space .side.edition .toolbar .header {
  color: white;
}
.indigo-blueGrey app-role-user-find .work-space .side.edition .toolbar .header mat-icon {
  color: #607d8b;
}
.indigo-blueGrey app-role-user-find .work-space .side.edition .toolbar .header h3 {
  color: #607d8b;
}
.indigo-blueGrey app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body {
  background: white !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header mat-icon:not(.complex) {
  color: #3f51b5;
}
.indigo-blueGrey app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header h4 {
  color: #3f51b5;
}
.indigo-blueGrey app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-actions {
  background: #fafafa;
}
.indigo-blueGrey app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.indigo-blueGrey app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header .icon-complex mat-icon.complex {
  color: #607d8b !important;
}
.indigo-blueGrey app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header h4 {
  color: #607d8b;
}
.indigo-blueGrey app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line ul {
  color: #607d8b;
}
.indigo-blueGrey app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head mat-icon {
  color: #607d8b !important;
}
.indigo-blueGrey app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head h4 {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .section-title {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-blueGrey app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume h6.hint {
  background: #cfd8dc;
}
.indigo-blueGrey app-role-user-find .work-space app-role-user-detail.disabled .side .toolbar .header {
  color: #607D8B !important;
}
.indigo-blueGrey app-role-user-find .work-space app-role-user-detail.disabled .side .container .role-resume {
  background: #607D8B !important;
}
.indigo-blueGrey app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-title {
  color: #607D8B !important;
}
.indigo-blueGrey app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line ul {
  color: #607D8B !important;
}
.indigo-blueGrey app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header mat-icon {
  color: #607D8B;
}
.indigo-blueGrey app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .icon-complex mat-icon {
  color: #607D8B !important;
}
.indigo-blueGrey app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #607D8B !important;
}
.indigo-blueGrey app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .info h4 {
  color: #607D8B !important;
}
.indigo-blueGrey app-role-user-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee-title {
  color: #607D8B;
}
.indigo-blueGrey app-role-user-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: #607D8B;
}
.indigo-blueGrey app-role-user-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #607D8B;
}
.indigo-blueGrey app-actor-find .main-overlay {
  background: rgba(0, 0, 0, 0.32);
}
.indigo-blueGrey app-actor-find .icon-complex mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.indigo-blueGrey app-actor-find .icon-complex mat-icon.complex {
  color: #3f51b5 !important;
}
.indigo-blueGrey app-actor-find .navigation {
  background: #fafafa;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey app-actor-find .navigation *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.indigo-blueGrey app-actor-find .navigation *::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}
.indigo-blueGrey app-actor-find .navigation .load-window {
  background: #eeeeee;
}
.indigo-blueGrey app-actor-find .navigation .toolbar {
  background: #3f51b5;
  color: white;
}
.indigo-blueGrey app-actor-find .navigation .toolbar.reverse {
  background: white;
  color: rgba(0, 0, 0, 0.54);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey app-actor-find .navigation .container .helper {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-blueGrey app-actor-find .navigation .container .item {
  background: white;
  color: rgba(0, 0, 0, 0.54);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey app-actor-find .navigation .container .item:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.indigo-blueGrey app-actor-find .navigation .container .item.active {
  background: #3f51b5 !important;
  color: white;
  border: 1px solid #3f51b5;
}
.indigo-blueGrey app-actor-find .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.indigo-blueGrey app-actor-find .work-space .main .load-window {
  background: white !important;
}
.indigo-blueGrey app-actor-find .work-space .main.side-open {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey app-actor-find .work-space .main .container .item {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey app-actor-find .work-space .main .container .item .subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-blueGrey app-actor-find .work-space .main .container .item.disabled {
  background: #eeeeee;
  color: #607D8B !important;
}
.indigo-blueGrey app-actor-find .work-space .main .container .item.disabled h3 {
  color: #607D8B !important;
}
.indigo-blueGrey app-actor-find .work-space .main .container .item.disabled mat-icon {
  color: #607D8B !important;
}
.indigo-blueGrey app-actor-find .work-space .main .container .item.disabled.selected {
  background: #607D8B;
  color: white !important;
}
.indigo-blueGrey app-actor-find .work-space .main .container .item.disabled.selected h3 {
  color: white !important;
}
.indigo-blueGrey app-actor-find .work-space .main .container .item.disabled.selected mat-icon {
  color: white !important;
}
.indigo-blueGrey app-actor-find .work-space .main .container .item.disabled.selected mat-icon.complex {
  color: white !important;
  text-shadow: -2px 0 0px #607D8B, 0 -2px 0px #607D8B, 2px 0 0px #607D8B, 0 2px 0px #607D8B, -2px -2px 0px #607D8B, 2px 2px 0px #607D8B, 2px -2px 0px #607D8B, -2px 2px 0px #607D8B !important;
}
.indigo-blueGrey app-actor-find .work-space .main .container .item mat-icon.complex {
  text-shadow: -2px 0 0px #eeeeee, 0 -2px 0px #eeeeee, 2px 0 0px #eeeeee, 0 2px 0px #eeeeee, -2px -2px 0px #eeeeee, 2px 2px 0px #eeeeee, 2px -2px 0px #eeeeee, -2px 2px 0px #eeeeee !important;
}
.indigo-blueGrey app-actor-find .work-space .main .container .item h5 {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-blueGrey app-actor-find .work-space .main .container .item h3 {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey app-actor-find .work-space .main .container .item:hover {
  background: rgba(0, 0, 0, 0.04);
}
.indigo-blueGrey app-actor-find .work-space .main .container .item.selected {
  background: #3f51b5;
  color: white;
}
.indigo-blueGrey app-actor-find .work-space .main .container .item.selected mat-icon {
  color: white;
}
.indigo-blueGrey app-actor-find .work-space .main .container .item.selected mat-icon.complex {
  color: white !important;
  text-shadow: -2px 0 0px #3f51b5, 0 -2px 0px #3f51b5, 2px 0 0px #3f51b5, 0 2px 0px #3f51b5, -2px -2px 0px #3f51b5, 2px 2px 0px #3f51b5, 2px -2px 0px #3f51b5, -2px 2px 0px #3f51b5 !important;
}
.indigo-blueGrey app-actor-find .work-space .main .container .item.selected h5,
.indigo-blueGrey app-actor-find .work-space .main .container .item.selected h3 {
  color: white;
}
.indigo-blueGrey app-actor-find .work-space .main .main-action {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey app-actor-find .work-space .side {
  background: #fafafa;
}
.indigo-blueGrey app-actor-find .work-space .side .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #fafafa, 0 -2px 0px #fafafa, 2px 0 0px #fafafa, 0 2px 0px #fafafa, -2px -2px 0px #fafafa, 2px 2px 0px #fafafa, 2px -2px 0px #fafafa, -2px 2px 0px #fafafa;
}
.indigo-blueGrey app-actor-find .work-space .side *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.indigo-blueGrey app-actor-find .work-space .side *::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}
.indigo-blueGrey app-actor-find .work-space .side .edition-line {
  background: #eeeeee;
}
.indigo-blueGrey app-actor-find .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #fafafa !important;
}
.indigo-blueGrey app-actor-find .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-blueGrey app-actor-find .work-space .side mat-form-field .mat-form-field-flex {
  background: #fafafa;
}
.indigo-blueGrey app-actor-find .work-space .side mat-checkbox:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.indigo-blueGrey app-actor-find .work-space .side .hint.all {
  color: #607d8b;
}
.indigo-blueGrey app-actor-find .work-space .side .toolbar .header {
  color: #3f51b5;
}
.indigo-blueGrey app-actor-find .work-space .side .toolbar .header h3 {
  color: #3f51b5;
}
.indigo-blueGrey app-actor-find .work-space .side .container .role-resume {
  background: #3f51b5;
}
.indigo-blueGrey app-actor-find .work-space .side .container .role-resume .line {
  color: white;
}
.indigo-blueGrey app-actor-find .work-space .side .container .role-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.1);
}
.indigo-blueGrey app-actor-find .work-space .side .container .role-resume .line.editable button {
  background: #3f51b5;
}
.indigo-blueGrey app-actor-find .work-space .side .container .section-title {
  color: #3f51b5;
}
.indigo-blueGrey app-actor-find .work-space .side .container .section-resume {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey app-actor-find .work-space .side .container .section-resume .line.editable:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.indigo-blueGrey app-actor-find .work-space .side .container .section-resume .line.editable:hover .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px white, 0 -2px 0px white, 2px 0 0px white, 0 2px 0px white, -2px -2px 0px white, 2px 2px 0px white, 2px -2px 0px white, -2px 2px 0px white !important;
}
.indigo-blueGrey app-actor-find .work-space .side .container .section-resume .line.editable button {
  background: #fafafa;
}
.indigo-blueGrey app-actor-find .work-space .side .container .section-resume .line .header .icon-complex mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.indigo-blueGrey app-actor-find .work-space .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #3f51b5 !important;
}
.indigo-blueGrey app-actor-find .work-space .side .container .section-resume .line .header mat-icon:not(.complex) {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-blueGrey app-actor-find .work-space .side .container .section-resume .line .header .info h4 {
  color: #3f51b5;
}
.indigo-blueGrey app-actor-find .work-space .side .container .section-resume .line ul {
  color: #3f51b5;
}
.indigo-blueGrey app-actor-find .work-space .side .container .section-resume .line.head {
  background: #eeeeee;
}
.indigo-blueGrey app-actor-find .work-space .side .container .section-resume .line.head mat-icon {
  color: #607d8b;
}
.indigo-blueGrey app-actor-find .work-space .side .container .section-resume .line.head h4 {
  color: white;
}
.indigo-blueGrey app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee-title {
  color: #3f51b5;
}
.indigo-blueGrey app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body {
  background: white;
}
.indigo-blueGrey app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-blueGrey app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #3f51b5;
}
.indigo-blueGrey app-actor-find .work-space .side.edition .toolbar .header {
  color: white;
}
.indigo-blueGrey app-actor-find .work-space .side.edition .toolbar .header mat-icon {
  color: #607d8b;
}
.indigo-blueGrey app-actor-find .work-space .side.edition .toolbar .header h3 {
  color: #607d8b;
}
.indigo-blueGrey app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body {
  background: white !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header mat-icon:not(.complex) {
  color: #3f51b5;
}
.indigo-blueGrey app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header h4 {
  color: #3f51b5;
}
.indigo-blueGrey app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-actions {
  background: #fafafa;
}
.indigo-blueGrey app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.indigo-blueGrey app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header .icon-complex mat-icon.complex {
  color: #607d8b !important;
}
.indigo-blueGrey app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header h4 {
  color: #607d8b;
}
.indigo-blueGrey app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line ul {
  color: #607d8b;
}
.indigo-blueGrey app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head mat-icon {
  color: #607d8b !important;
}
.indigo-blueGrey app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head h4 {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .section-title {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-blueGrey app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume h6.hint {
  background: #cfd8dc;
}
.indigo-blueGrey app-actor-find .work-space app-role-user-detail.disabled .side .toolbar .header {
  color: #607D8B !important;
}
.indigo-blueGrey app-actor-find .work-space app-role-user-detail.disabled .side .container .role-resume {
  background: #607D8B !important;
}
.indigo-blueGrey app-actor-find .work-space app-role-user-detail.disabled .side .container .section-title {
  color: #607D8B !important;
}
.indigo-blueGrey app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line ul {
  color: #607D8B !important;
}
.indigo-blueGrey app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header mat-icon {
  color: #607D8B;
}
.indigo-blueGrey app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .icon-complex mat-icon {
  color: #607D8B !important;
}
.indigo-blueGrey app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #607D8B !important;
}
.indigo-blueGrey app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .info h4 {
  color: #607D8B !important;
}
.indigo-blueGrey app-actor-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee-title {
  color: #607D8B;
}
.indigo-blueGrey app-actor-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: #607D8B;
}
.indigo-blueGrey app-actor-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #607D8B;
}
.indigo-blueGrey .dropzone {
  border: 2px dashed #607D8B;
  background: #eeeeee !important;
}
.indigo-blueGrey .dropzone.hovering {
  border: 2px solid #607D8B;
  color: rgba(0, 0, 0, 0.38) !important;
}
.indigo-blueGrey .dropzone button.mat-stroked-button {
  background: white;
}
.indigo-blueGrey .firmador .content-action-main {
  color: white !important;
}
.indigo-blueGrey .firmador .content-action-main.disabled {
  color: rgba(0, 0, 0, 0.38) !important;
  background: rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .sign-cycle {
  background: white;
}
.indigo-blueGrey .sign-cycle button.success {
  background-color: #009688;
}
.indigo-blueGrey .sign-cycle button.warning {
  background-color: #673AB7;
}
.indigo-blueGrey .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-title-container .cycle-content-title h4 {
  color: #607D8B;
}
.indigo-blueGrey .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-action .content-action-main {
  color: white !important;
}
.indigo-blueGrey .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-action .content-action-main.mat-stroked-button:disabled {
  color: #9e9e9e !important;
}
.indigo-blueGrey .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-action .content-action-main.mat-stroked-button:not(:disabled) {
  color: #607d8b !important;
}
.indigo-blueGrey .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-action .content-action-side u {
  color: #9e9e9e;
}
.indigo-blueGrey .resume-status {
  background: white;
}
.indigo-blueGrey .help-hoverable mat-icon {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-blueGrey .help-hoverable mat-hint {
  background: white;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .help-hoverable mat-hint b {
  color: #3f51b5 !important;
}
.indigo-blueGrey app-file-document-sign-massive-dialog .dialog-content,
.indigo-blueGrey app-share-person-dialog .dialog-content {
  background: #fafafa;
}
.indigo-blueGrey app-file-document-sign-massive-dialog .dialog-content mat-toolbar mat-toolbar-row .mat-avatar h3,
.indigo-blueGrey app-share-person-dialog .dialog-content mat-toolbar mat-toolbar-row .mat-avatar h3 {
  color: #3f51b5;
}
.indigo-blueGrey app-file-document-sign-massive-dialog .dialog-content .dialog-body p b,
.indigo-blueGrey app-share-person-dialog .dialog-content .dialog-body p b {
  color: #607d8b;
}
.indigo-blueGrey app-file-document-sign-massive-dialog .dialog-content app-file-document-sign,
.indigo-blueGrey app-share-person-dialog .dialog-content app-file-document-sign {
  background: white;
}
.indigo-blueGrey .mat-basic-chip {
  background: #eeeeee !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .mat-basic-chip.seen {
  border-color: #2196F3 !important;
  color: #2196F3 !important;
}
.indigo-blueGrey .mat-basic-chip.unseen {
  border-color: #607D8B !important;
  color: #607D8B !important;
}
.indigo-blueGrey .mat-basic-chip.signed-ok {
  border-color: #009688 !important;
  color: #009688 !important;
}
.indigo-blueGrey .mat-basic-chip.signed-ok.cancelled {
  border-color: #795548 !important;
  color: #795548 !important;
}
.indigo-blueGrey .mat-basic-chip.signed-not-ok {
  border-color: #673AB7 !important;
  color: #673AB7 !important;
}
.indigo-blueGrey .mat-basic-chip.not-signed {
  border-color: #ff4081 !important;
  color: #ff4081 !important;
}
.indigo-blueGrey .mat-basic-chip.not-signed-employer {
  border-color: #ff4081 !important;
  color: #ff4081 !important;
}
.indigo-blueGrey app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip {
  background: #eeeeee !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.seen {
  border-color: #2196F3 !important;
  color: #2196F3 !important;
}
.indigo-blueGrey app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.unseen {
  border-color: #607D8B !important;
  color: #607D8B !important;
}
.indigo-blueGrey app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.signed-ok {
  border-color: #009688 !important;
  color: #009688 !important;
}
.indigo-blueGrey app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.signed-ok.cancelled {
  border-color: #795548 !important;
  color: #795548 !important;
}
.indigo-blueGrey app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.signed-not-ok {
  border-color: #673AB7 !important;
  color: #673AB7 !important;
}
.indigo-blueGrey app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.not-signed {
  border-color: #ff4081 !important;
  color: #ff4081 !important;
}
.indigo-blueGrey app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.not-signed-employer {
  border-color: #ff4081 !important;
  color: #ff4081 !important;
}
.indigo-blueGrey .inbox-container.employee.my-documents .chip-line.mat-line .mat-chip-list-wrapper .mat-basic-chip.seen {
  color: #607D8B !important;
}
.indigo-blueGrey .resume-status .mat-basic-chip {
  background: #eeeeee !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .resume-status .mat-basic-chip.signed-ok {
  border-color: #009688 !important;
  background-color: #009688 !important;
  color: white !important;
}
.indigo-blueGrey .resume-status .mat-basic-chip.signed-ok.cancelled {
  border-color: #795548 !important;
  background-color: #795548 !important;
  color: white !important;
}
.indigo-blueGrey .resume-status .mat-basic-chip.signed-not-ok {
  border-color: #673AB7 !important;
  background-color: #673AB7 !important;
  color: white !important;
}
.indigo-blueGrey app-file-document-view .new-container app-file-document-upload {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey app-file-document-view .new-container app-upload-form .file-upload-list .mat-subheader {
  background: white;
}
.indigo-blueGrey app-file-document-view .new-container app-upload-form .file-upload-list mat-list-item {
  background: #fafafa !important;
}
.indigo-blueGrey app-file-document-view .preview-content {
  background: #607D8B;
}
.indigo-blueGrey app-file-document-view .preview-content h4 {
  color: white;
}
.indigo-blueGrey app-file-document-view .view-container {
  background: white;
}
.indigo-blueGrey app-file-document-view .view-container mat-toolbar.side-bar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .formio-hint {
  background: #3f51b5;
  color: white;
}
.indigo-blueGrey mat-dialog-container app-file-document-state-chips {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey app-candidate-promotion .load-window.message h3.primary-text {
  color: #3f51b5;
}
.indigo-blueGrey app-multiple-documentation-add-dialog .icon-complex mat-icon,
.indigo-blueGrey app-documentation-type-set-configuration-dialog .icon-complex mat-icon,
.indigo-blueGrey app-add-candidate-dialog .icon-complex mat-icon,
.indigo-blueGrey app-share-person-dialog .icon-complex mat-icon,
.indigo-blueGrey app-share-person-response-dialog .icon-complex mat-icon {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-blueGrey app-multiple-documentation-add-dialog .icon-complex mat-icon.complex,
.indigo-blueGrey app-documentation-type-set-configuration-dialog .icon-complex mat-icon.complex,
.indigo-blueGrey app-add-candidate-dialog .icon-complex mat-icon.complex,
.indigo-blueGrey app-share-person-dialog .icon-complex mat-icon.complex,
.indigo-blueGrey app-share-person-response-dialog .icon-complex mat-icon.complex {
  color: #3f51b5 !important;
}
.indigo-blueGrey app-multiple-documentation-add-dialog .icon-complex mat-icon.complex.mat-accent,
.indigo-blueGrey app-documentation-type-set-configuration-dialog .icon-complex mat-icon.complex.mat-accent,
.indigo-blueGrey app-add-candidate-dialog .icon-complex mat-icon.complex.mat-accent,
.indigo-blueGrey app-share-person-dialog .icon-complex mat-icon.complex.mat-accent,
.indigo-blueGrey app-share-person-response-dialog .icon-complex mat-icon.complex.mat-accent {
  color: #607d8b;
}
.indigo-blueGrey app-multiple-documentation-add-dialog .icon-complex mat-icon.complex.side,
.indigo-blueGrey app-documentation-type-set-configuration-dialog .icon-complex mat-icon.complex.side,
.indigo-blueGrey app-add-candidate-dialog .icon-complex mat-icon.complex.side,
.indigo-blueGrey app-share-person-dialog .icon-complex mat-icon.complex.side,
.indigo-blueGrey app-share-person-response-dialog .icon-complex mat-icon.complex.side {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection,
.indigo-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection,
.indigo-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .document-selection,
.indigo-blueGrey app-share-person-dialog .dialog-content .document-template-container .document-selection,
.indigo-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .document-selection {
  background: #fafafa;
}
.indigo-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.indigo-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.indigo-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3,
.indigo-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.indigo-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.indigo-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3,
.indigo-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.indigo-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.indigo-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3,
.indigo-blueGrey app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.indigo-blueGrey app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.indigo-blueGrey app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3,
.indigo-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.indigo-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.indigo-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3 {
  color: #607d8b !important;
}
.indigo-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon,
.indigo-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon,
.indigo-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon,
.indigo-blueGrey app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon,
.indigo-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon {
  color: #3f51b5;
}
.indigo-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3,
.indigo-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3,
.indigo-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3,
.indigo-blueGrey app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3,
.indigo-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3 {
  color: #3f51b5;
}
.indigo-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex,
.indigo-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex,
.indigo-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex,
.indigo-blueGrey app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex,
.indigo-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex {
  color: #607d8b !important;
  text-shadow: -2px 0 0px #eeeeee, 0 -2px 0px #eeeeee, 2px 0 0px #eeeeee, 0 2px 0px #eeeeee, -2px -2px 0px #eeeeee, 2px 2px 0px #eeeeee, 2px -2px 0px #eeeeee, -2px 2px 0px white;
}
.indigo-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex,
.indigo-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex,
.indigo-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex,
.indigo-blueGrey app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex,
.indigo-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex {
  color: rgba(0, 0, 0, 0.38) !important;
}
.indigo-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b,
.indigo-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b,
.indigo-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b,
.indigo-blueGrey app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b,
.indigo-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex,
.indigo-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex,
.indigo-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex,
.indigo-blueGrey app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex,
.indigo-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex {
  color: rgba(0, 0, 0, 0.38) !important;
}
.indigo-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex,
.indigo-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex,
.indigo-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex,
.indigo-blueGrey app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex,
.indigo-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex {
  color: rgba(0, 0, 0, 0.38) !important;
}
.indigo-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover,
.indigo-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover,
.indigo-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover,
.indigo-blueGrey app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover,
.indigo-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover {
  background: rgba(0, 0, 0, 0.04);
}
.indigo-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b,
.indigo-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b,
.indigo-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b,
.indigo-blueGrey app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b,
.indigo-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.indigo-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.indigo-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.indigo-blueGrey app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.indigo-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble {
  background: #3f51b5;
}
.indigo-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble,
.indigo-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble,
.indigo-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble,
.indigo-blueGrey app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble,
.indigo-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble {
  background: #f44336;
}
.indigo-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble,
.indigo-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble,
.indigo-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble,
.indigo-blueGrey app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble,
.indigo-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble {
  color: rgba(0, 0, 0, 0.38);
  background: rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble,
.indigo-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble,
.indigo-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble,
.indigo-blueGrey app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble,
.indigo-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble {
  background: rgba(0, 0, 0, 0.38);
  color: white;
}
.indigo-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide,
.indigo-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide,
.indigo-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide,
.indigo-blueGrey app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide,
.indigo-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide {
  border-color: rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble,
.indigo-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble,
.indigo-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble,
.indigo-blueGrey app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble,
.indigo-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.indigo-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.indigo-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.indigo-blueGrey app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.indigo-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble {
  color: #3f51b5;
}
.indigo-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble,
.indigo-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble,
.indigo-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble,
.indigo-blueGrey app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble,
.indigo-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble {
  color: #f44336;
}
.indigo-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar,
.indigo-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar,
.indigo-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar,
.indigo-blueGrey app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar,
.indigo-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar {
  background: #607D8B;
}
.indigo-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row,
.indigo-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row,
.indigo-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row,
.indigo-blueGrey app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row,
.indigo-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row {
  color: white;
}
.indigo-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper,
.indigo-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper,
.indigo-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper,
.indigo-blueGrey app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper,
.indigo-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper {
  background: #607D8B;
}
.indigo-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop,
.indigo-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop,
.indigo-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop,
.indigo-blueGrey app-share-person-dialog .dialog-content .document-template-container .add-docs-drop,
.indigo-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body,
.indigo-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body,
.indigo-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body,
.indigo-blueGrey app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body,
.indigo-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3,
.indigo-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3,
.indigo-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3,
.indigo-blueGrey app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3,
.indigo-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3 {
  color: #607d8b;
}
.indigo-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5,
.indigo-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5,
.indigo-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5,
.indigo-blueGrey app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5,
.indigo-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5 {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5,
.indigo-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5,
.indigo-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5,
.indigo-blueGrey app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5,
.indigo-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5 {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator,
.indigo-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator,
.indigo-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator,
.indigo-blueGrey app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator,
.indigo-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background: #fafafa;
}
.indigo-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip,
.indigo-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip,
.indigo-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip,
.indigo-blueGrey app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip,
.indigo-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip {
  background: white !important;
}
.indigo-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon,
.indigo-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon,
.indigo-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon,
.indigo-blueGrey app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon,
.indigo-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon {
  color: #607d8b !important;
}
.indigo-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3,
.indigo-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3,
.indigo-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3,
.indigo-blueGrey app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3,
.indigo-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3 {
  color: #607d8b !important;
}
.indigo-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5,
.indigo-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5,
.indigo-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5,
.indigo-blueGrey app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5,
.indigo-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5 {
  color: rgba(0, 0, 0, 0.54) !important;
}
.indigo-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon,
.indigo-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon,
.indigo-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon,
.indigo-blueGrey app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon,
.indigo-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon {
  color: #3f51b5 !important;
}
.indigo-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3,
.indigo-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3,
.indigo-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3,
.indigo-blueGrey app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3,
.indigo-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3 {
  color: #3f51b5 !important;
}
.indigo-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5,
.indigo-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5,
.indigo-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5,
.indigo-blueGrey app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5,
.indigo-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5 {
  color: #3f51b5 !important;
}
.indigo-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled,
.indigo-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled,
.indigo-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled,
.indigo-blueGrey app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled,
.indigo-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled {
  background: #fafafa !important;
  color: rgba(0, 0, 0, 0.38);
}
.indigo-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove,
.indigo-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove,
.indigo-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove,
.indigo-blueGrey app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove,
.indigo-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove,
.indigo-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove,
.indigo-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove,
.indigo-blueGrey app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove,
.indigo-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove {
  color: rgba(0, 0, 0, 0.54) !important;
}
.indigo-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .preview-action,
.indigo-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .preview-action,
.indigo-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .preview-action,
.indigo-blueGrey app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .preview-action,
.indigo-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .preview-action {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .tyc .sticky {
  background: white;
}
.indigo-blueGrey .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper:nth-child(odd) datatable-body-row {
  background: #fafafa;
}
.indigo-blueGrey .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active * {
  color: white !important;
}
.indigo-blueGrey .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active:hover .datatable-body-cell-label {
  background: #3f51b5;
}
.indigo-blueGrey .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active:hover .cs-floating-action {
  background: #3f51b5 !important;
}
.indigo-blueGrey .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active.row-cancel * {
  color: rgba(0, 0, 0, 0.54) !important;
}
.indigo-blueGrey .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active.row-cancel .datatable-row-group {
  background: #607D8B !important;
}
.indigo-blueGrey .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active.row-cancel:hover .datatable-body-cell-label {
  background: #607D8B;
}
.indigo-blueGrey .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.row-cancel * {
  color: #b0bec5 !important;
  transition-property: color;
  transition-duration: 0.3s;
  transition-timing-function: linear;
}
.indigo-blueGrey .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row:hover .datatable-body-cell-label button {
  background: #eeeeee;
}
.indigo-blueGrey .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row datatable-body-cell.candidate-status_bar mat-progress-bar .mat-progress-bar-buffer {
  background-color: #eeeeee;
}
.indigo-blueGrey .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row datatable-body-cell.candidate-status_bar .status_bar-count {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-blueGrey .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row datatable-body-cell.candidate-status_bar .inactive {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-blueGrey .cs-grid-test .info {
  background: white;
}
.indigo-blueGrey .cs-grid-test .selected-column {
  background: white;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
}
.indigo-blueGrey .cs-grid-test .selection-cell .datatable-body-cell.active {
  background: #3f51b5 !important;
  color: white !important;
}
.indigo-blueGrey .cs-grid-test nav {
  background: white;
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 13px 19px 2px rgba(0, 0, 0, 0.14), 0 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .cs-grid-test .ngx-datatable.material {
  background: white;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .cs-grid-test .ngx-datatable.material.striped .datatable-row-odd {
  background: #fafafa;
}
.indigo-blueGrey .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active, .indigo-blueGrey .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active .datatable-row-group, .indigo-blueGrey .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active, .indigo-blueGrey .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active .datatable-row-group, .indigo-blueGrey .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active, .indigo-blueGrey .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active .datatable-row-group {
  background: #3f51b5;
  color: white;
}
.indigo-blueGrey .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active:hover, .indigo-blueGrey .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active:hover .datatable-row-group, .indigo-blueGrey .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active:hover, .indigo-blueGrey .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active:hover .datatable-row-group, .indigo-blueGrey .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active:hover, .indigo-blueGrey .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active:hover .datatable-row-group {
  background: #3f51b5;
  color: white;
}
.indigo-blueGrey .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active:focus, .indigo-blueGrey .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active:focus .datatable-row-group, .indigo-blueGrey .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active:focus, .indigo-blueGrey .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active:focus .datatable-row-group, .indigo-blueGrey .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active:focus, .indigo-blueGrey .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active:focus .datatable-row-group {
  background: #3f51b5;
  color: white;
}
.indigo-blueGrey .cs-grid-test .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover, .indigo-blueGrey .cs-grid-test .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: rgba(0, 0, 0, 0.04);
}
.indigo-blueGrey .cs-grid-test .ngx-datatable.material:not(.cell-selection) .datatable-body-row:focus, .indigo-blueGrey .cs-grid-test .ngx-datatable.material:not(.cell-selection) .datatable-body-row:focus .datatable-row-group {
  background: rgba(0, 0, 0, 0.04);
}
.indigo-blueGrey .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell:hover, .indigo-blueGrey .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell:hover .datatable-row-group {
  background: rgba(0, 0, 0, 0.04);
}
.indigo-blueGrey .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell:focus, .indigo-blueGrey .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell:focus .datatable-row-group {
  background: rgba(0, 0, 0, 0.04);
}
.indigo-blueGrey .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active, .indigo-blueGrey .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active .datatable-row-group {
  background: #3f51b5;
  color: white;
}
.indigo-blueGrey .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active:hover, .indigo-blueGrey .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active:hover .datatable-row-group {
  background: #3f51b5;
  color: white;
}
.indigo-blueGrey .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active:focus, .indigo-blueGrey .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active:focus .datatable-row-group {
  background: #3f51b5;
  color: white;
}
.indigo-blueGrey .cs-grid-test .ngx-datatable.material .datatable-header .datatable-row-left,
.indigo-blueGrey .cs-grid-test .ngx-datatable.material .datatable-body .datatable-row-left {
  background-color: white;
}
.indigo-blueGrey .cs-grid-test .ngx-datatable.material .datatable-header .datatable-row-right,
.indigo-blueGrey .cs-grid-test .ngx-datatable.material .datatable-body .datatable-row-right {
  background-color: white;
}
.indigo-blueGrey .cs-grid-test .ngx-datatable.material .datatable-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.38);
}
.indigo-blueGrey .cs-grid-test .ngx-datatable.material .datatable-header .datatable-header-cell {
  background-color: white;
  color: rgba(0, 0, 0, 0.54);
}
.indigo-blueGrey .cs-grid-test .ngx-datatable.material .datatable-header .resize-handle {
  border-right: solid 1px rgba(0, 0, 0, 0.04);
}
.indigo-blueGrey .cs-grid-test .ngx-datatable.material .datatable-body .datatable-row-detail {
  background: #eeeeee;
}
.indigo-blueGrey .cs-grid-test .ngx-datatable.material .datatable-body .datatable-group-header {
  background: #eeeeee;
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
  border-top: solid 1px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .cs-grid-test .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey .cs-grid-test .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-group-cell {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey .cs-grid-test .ngx-datatable.material .datatable-body .progress-linear .container {
  background-color: white;
}
.indigo-blueGrey .cs-grid-test .ngx-datatable.material .datatable-body .progress-linear .container .bar {
  background-color: #3f51b5;
}
.indigo-blueGrey .cs-grid-test .ngx-datatable.material .datatable-footer {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-blueGrey .cs-grid-test .ngx-datatable.material .datatable-footer .datatable-pager li.disabled a {
  color: rgba(0, 0, 0, 0.38) !important;
  background-color: rgba(0, 0, 0, 0) !important;
}
.indigo-blueGrey .cs-grid-test .ngx-datatable.material .datatable-footer .datatable-pager li.active a {
  background-color: #3f51b5;
}
.indigo-blueGrey .cs-grid-test .ngx-datatable.material .datatable-footer .datatable-pager a {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-blueGrey .cs-grid-test .ngx-datatable.material .datatable-footer .datatable-pager a:hover {
  color: rgba(0, 0, 0, 0.04);
  background-color: rgba(0, 0, 0, 0.04);
}
.indigo-blueGrey .cs-grid-test .ngx-datatable.material .datatable-summary-row .datatable-body-row {
  background-color: #fafafa;
}
.indigo-blueGrey .cs-grid-test .ngx-datatable.material .datatable-summary-row .datatable-body-row:hover {
  background-color: #fafafa;
}
.indigo-blueGrey .cs-grid-test .datatable-checkbox input[type=checkbox]:after {
  background: white;
}
.indigo-blueGrey .view-container .view-toolbar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal.new.image-opened .help-content img {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card {
  background: white;
}
.indigo-blueGrey app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container .aditional-info {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-blueGrey app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container .mat-card-footer {
  background: white;
}
.indigo-blueGrey app-help-index mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card {
  background: white;
}
.indigo-blueGrey app-help-index mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card .mat-card-footer {
  background: white;
}
.indigo-blueGrey .cropper .employee-title .title-text {
  color: white !important;
}
.indigo-blueGrey .cropper app-cs-upload-cropper-control .preview h5 {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-blueGrey app-file-document-upload .preview-container {
  background: #607D8B !important;
}
.indigo-blueGrey app-file-document-upload .preview-container h4 {
  color: #eeeeee;
}
.indigo-blueGrey .preview-container {
  background: #607D8B !important;
}
.indigo-blueGrey .preview-container h4 {
  color: #eeeeee;
}
.indigo-blueGrey .clean-autocomple mat-list {
  background: white;
}
.indigo-blueGrey .clean-autocomple mat-list .mat-list-item:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.indigo-blueGrey .clean-autocomple mat-list .mat-list-item .mat-list-item-content .mat-list-text {
  color: #3f51b5;
}
.indigo-blueGrey app-leave .inbox-toolbar {
  background: #fafafa;
}
.indigo-blueGrey app-leave .mat-tab-body-wrapper {
  position: relative;
  overflow: hidden;
  display: flex;
  transition: height 500ms cubic-bezier(0.35, 0, 0.25, 1);
  height: 95% !important;
}
.indigo-blueGrey app-leave .leave_container {
  background: #fafafa;
}
.indigo-blueGrey app-leave .leave_container .leave_items .leave_items-item.ok .content .leave_item-status_icon {
  color: #009688;
}
.indigo-blueGrey app-leave .leave_container .leave_items .leave_items-item.not-ok .content .leave_item-status_icon {
  color: #9C27B0;
}
.indigo-blueGrey app-leave .leave_container .leave_items .leave_items-item.pending .content .leave_item-status_icon {
  color: #FF9800;
}
.indigo-blueGrey app-leave .leave_container .leave_items .leave_items-item.draft .content .leave_item-status_icon {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-blueGrey app-leave .leave_container .leave_items .leave_items-item.cancel .content .leave_item-status_icon {
  color: #795548;
}
.indigo-blueGrey app-leave .leave_container .leave_items .leave_items-item .content:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.indigo-blueGrey app-leave .leave_container .leave_items .leave_items-item .content.selected {
  border-left: 6px solid #607d8b !important;
}
.indigo-blueGrey app-leave .leave_container .leave_items .leave_items-item .content.selected mat-icon {
  color: #607d8b !important;
}
.indigo-blueGrey app-leave .leave_container .leave_items .leave_items-item .content.selected .item-data {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-blueGrey app-leave .leave_container .leave_items .leave_items-item .content .leave_item-icon {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-blueGrey app-leave .leave_container .leave_items .leave_items-item .content .item-data {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-blueGrey app-leave .leave_container .window-spinner {
  background: #fafafa !important;
}
.indigo-blueGrey app-leave-detail .leave_detail .leave_detail-title .info h3,
.indigo-blueGrey app-leave-detail .leave_detail .leave_detail-title .info mat-icon {
  color: #3f51b5;
}
.indigo-blueGrey app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .ok .large-icon {
  background: #b2dfdb;
  color: #009688;
}
.indigo-blueGrey app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .ok h2 {
  color: #009688;
}
.indigo-blueGrey app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .not-ok .large-icon {
  background: #e1bee7;
  color: #9C27B0;
}
.indigo-blueGrey app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .not-ok h2 {
  color: #9C27B0;
}
.indigo-blueGrey app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .pending .large-icon {
  background: #FFE0B2;
  color: #FF9800;
}
.indigo-blueGrey app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .pending h2 {
  color: #FF9800;
}
.indigo-blueGrey app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .draft .large-icon {
  background: #f2f2f2;
  color: #dedede;
}
.indigo-blueGrey app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .draft h2 {
  color: #dedede;
}
.indigo-blueGrey app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .cancelled .large-icon {
  background: #BCAAA4;
  color: #795548;
}
.indigo-blueGrey app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .cancelled h2 {
  color: #795548;
}
.indigo-blueGrey app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .actions {
  padding: 16px;
  height: 96px;
  column-gap: 16px;
}
.indigo-blueGrey app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .actions button {
  height: 100%;
  border-radius: 40px;
}
.indigo-blueGrey app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .actions button.ok {
  background: #009688 !important;
  color: white;
}
.indigo-blueGrey app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .actions button.not-ok {
  color: #9C27B0 !important;
}
.indigo-blueGrey app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning mat-basic-chip {
  background: #eeeeee !important;
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
  border-radius: 40px !important;
  height: 35px !important;
}
.indigo-blueGrey app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning mat-basic-chip.signed-ok {
  border-color: #009688 !important;
  color: #009688 !important;
}
.indigo-blueGrey app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning mat-basic-chip.signed-not-ok {
  border-color: #9C27B0 !important;
  color: #9C27B0 !important;
}
.indigo-blueGrey app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning mat-basic-chip.draft {
  border-color: #dedede !important;
  color: #dedede !important;
}
.indigo-blueGrey app-leave-request-detail .load-window {
  background: #fafafa;
}
.indigo-blueGrey app-leave-request-detail form .leave_detail-container {
  background: #fafafa;
}
.indigo-blueGrey app-leave-request-detail form .leave_detail-container .toolbar .header {
  color: #3f51b5;
}
.indigo-blueGrey app-leave-request-detail form .leave_detail-container .toolbar .header .text h3 {
  color: #3f51b5;
}
.indigo-blueGrey app-leave-request-detail form .leave_detail-container .toolbar .header .text h5 {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-blueGrey app-leave-request-detail form .leave_detail-container .leave_detail-container-content .leave_resume {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey app-leave-request-detail form .leave_detail-container .leave_detail-container-content .line h6 {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-blueGrey app-leave-request-detail form .leave_detail-container .actions button.ok {
  background: #009688 !important;
  color: white;
}
.indigo-blueGrey app-leave-request-detail form .leave_detail-container .actions button.not-ok {
  color: #9C27B0 !important;
}
.indigo-blueGrey .new_leave_modal-steps {
  display: unset !important;
}
.indigo-blueGrey .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button .mat-radio-label .mat-radio-label-content .side-text {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-blueGrey .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button .mat-radio-label .mat-radio-label-content .side-text.muted {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-blueGrey .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button .mat-radio-label .mat-radio-label-content .accent mat-icon,
.indigo-blueGrey .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button .mat-radio-label .mat-radio-label-content .accent .main-text {
  color: #607d8b;
}
.indigo-blueGrey .config_leave-container .config_leave-card {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .config_leave-container .config_leave-card .config_leave-auto_chip mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #fafafa !important;
}
.indigo-blueGrey .config_leave-container .config_leave-card .config_leave-auto_chip mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-blueGrey .config_leave-container .config_leave-card .config_leave-auto_chip mat-form-field .mat-form-field-flex {
  background: #fafafa;
}
.indigo-blueGrey *[color=success] {
  color: #4DB6AC;
}
.indigo-blueGrey .primary {
  color: #3f51b5 !important;
}
.indigo-blueGrey .accent {
  color: #607d8b !important;
}
.indigo-blueGrey app-lsd .icon-complex mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.indigo-blueGrey app-lsd .icon-complex mat-icon.complex {
  color: #3f51b5 !important;
}
.indigo-blueGrey app-lsd .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.indigo-blueGrey app-lsd .work-space .main .load-window {
  background: white !important;
}
.indigo-blueGrey app-lsd .work-space .main.side-open {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey app-lsd .work-space .main .container .item {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey app-lsd .work-space .main .container .item h5 {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-blueGrey app-lsd .work-space .main .container .item h3 {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey app-lsd .work-space .main .container .item:hover {
  background: rgba(0, 0, 0, 0.04);
}
.indigo-blueGrey app-lsd .work-space .main .container .item.selected {
  background: #3f51b5;
  color: white !important;
}
.indigo-blueGrey app-lsd .work-space .main .container .item.selected h3 {
  color: white !important;
}
.indigo-blueGrey app-lsd .work-space .main .container .item.selected.pending .status {
  background: rgba(0, 0, 0, 0.12) !important;
  color: #ffe082;
}
.indigo-blueGrey app-lsd .work-space .main .container .item.selected.ok .status {
  background: rgba(0, 0, 0, 0.12) !important;
  color: #a5d6a7;
}
.indigo-blueGrey app-lsd .work-space .main .container .item.selected.not-ok .status {
  background: rgba(0, 0, 0, 0.12) !important;
  color: #e57373;
}
.indigo-blueGrey app-lsd .work-space .main .main-action {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey app-lsd .work-space .side {
  background: #fafafa;
}
.indigo-blueGrey app-lsd .work-space .side .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #fafafa, 0 -2px 0px #fafafa, 2px 0 0px #fafafa, 0 2px 0px #fafafa, -2px -2px 0px #fafafa, 2px 2px 0px #fafafa, 2px -2px 0px #fafafa, -2px 2px 0px #fafafa;
}
.indigo-blueGrey app-lsd .work-space .side *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.indigo-blueGrey app-lsd .work-space .side *::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}
.indigo-blueGrey app-lsd .work-space .side .edition-line {
  background: #eeeeee;
}
.indigo-blueGrey app-lsd .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #fafafa !important;
}
.indigo-blueGrey app-lsd .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-blueGrey app-lsd .work-space .side mat-form-field .mat-form-field-flex {
  background: #fafafa;
}
.indigo-blueGrey app-lsd .work-space .side mat-checkbox:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.indigo-blueGrey app-lsd .work-space .side .hint.all {
  color: #607d8b;
}
.indigo-blueGrey app-lsd .work-space .side .toolbar .header {
  color: #3f51b5;
}
.indigo-blueGrey app-lsd .work-space .side .toolbar .header h3 {
  color: #3f51b5;
}
.indigo-blueGrey app-lsd .work-space .side .container .doc-resume {
  background: #3f51b5;
}
.indigo-blueGrey app-lsd .work-space .side .container .doc-resume .line {
  color: white;
}
.indigo-blueGrey app-lsd .work-space .side .container .doc-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.1);
}
.indigo-blueGrey app-lsd .work-space .side .container .doc-resume .line.editable button {
  background: #3f51b5;
}
.indigo-blueGrey app-lsd .work-space .side .container .section-title {
  color: #3f51b5;
}
.indigo-blueGrey app-lsd .work-space .side .container .section-resume {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey app-lsd .work-space .side .container .section-resume .line.editable:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.indigo-blueGrey app-lsd .work-space .side .container .section-resume .line.editable:hover .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px white, 0 -2px 0px white, 2px 0 0px white, 0 2px 0px white, -2px -2px 0px white, 2px 2px 0px white, 2px -2px 0px white, -2px 2px 0px white !important;
}
.indigo-blueGrey app-lsd .work-space .side .container .section-resume .line.editable button {
  background: #fafafa;
}
.indigo-blueGrey app-lsd .work-space .side .container .section-resume .line .header .icon-complex mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.indigo-blueGrey app-lsd .work-space .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #3f51b5 !important;
}
.indigo-blueGrey app-lsd .work-space .side .container .section-resume .line .header mat-icon:not(.complex) {
  color: rgba(0, 0, 0, 0.54);
}
.indigo-blueGrey app-lsd .work-space .side .container .section-resume .line .header .info h4 {
  color: #3f51b5;
}
.indigo-blueGrey app-lsd .work-space .side .container .section-resume .line ul {
  color: #3f51b5;
}
.indigo-blueGrey app-lsd .work-space .side .container .section-resume .line.head {
  background: #eeeeee;
}
.indigo-blueGrey app-lsd .work-space .side .container .section-resume .line.head mat-icon {
  color: #607d8b;
}
.indigo-blueGrey app-lsd .work-space .side .container .section-resume .line.head h4 {
  color: white;
}
.indigo-blueGrey app-lsd .work-space .side.edition .toolbar .header {
  color: white;
}
.indigo-blueGrey app-lsd .work-space .side.edition .toolbar .header mat-icon {
  color: #607d8b;
}
.indigo-blueGrey app-lsd .work-space .side.edition .toolbar .header h3 {
  color: #607d8b;
}
.indigo-blueGrey .dialog-content {
  max-height: 90vh;
}
.indigo-blueGrey .dialog-content mat-toolbar {
  background: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .dialog-content .dialog-container .document-template-container .document-selection mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #fafafa !important;
}
.indigo-blueGrey .dialog-content .dialog-container .document-template-container .document-selection mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-blueGrey .dialog-content .dialog-container .document-template-container .document-selection mat-form-field .mat-form-field-flex {
  background: #fafafa;
}
.indigo-blueGrey .dialog-content .dialog-container .document-template-container .document-selection app-upload-form .file-upload-list .mat-subheader::before {
  color: #3f51b5;
}
.indigo-blueGrey .dialog-content .dialog-container .view-container {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey .dialog-content .form-actions {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.indigo-blueGrey.dark .mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-blueGrey.dark .mat-badge-small .mat-badge-content {
  font-size: 9px;
}
.indigo-blueGrey.dark .mat-badge-large .mat-badge-content {
  font-size: 24px;
}
.indigo-blueGrey.dark .mat-h1,
.indigo-blueGrey.dark .mat-headline,
.indigo-blueGrey.dark .mat-typography .mat-h1,
.indigo-blueGrey.dark .mat-typography .mat-headline,
.indigo-blueGrey.dark .mat-typography h1 {
  font: 400 24px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.indigo-blueGrey.dark .mat-h2,
.indigo-blueGrey.dark .mat-title,
.indigo-blueGrey.dark .mat-typography .mat-h2,
.indigo-blueGrey.dark .mat-typography .mat-title,
.indigo-blueGrey.dark .mat-typography h2 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.indigo-blueGrey.dark .mat-h3,
.indigo-blueGrey.dark .mat-subheading-2,
.indigo-blueGrey.dark .mat-typography .mat-h3,
.indigo-blueGrey.dark .mat-typography .mat-subheading-2,
.indigo-blueGrey.dark .mat-typography h3 {
  font: 400 16px / 28px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.indigo-blueGrey.dark .mat-h4,
.indigo-blueGrey.dark .mat-subheading-1,
.indigo-blueGrey.dark .mat-typography .mat-h4,
.indigo-blueGrey.dark .mat-typography .mat-subheading-1,
.indigo-blueGrey.dark .mat-typography h4 {
  font: 400 15px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.indigo-blueGrey.dark .mat-h5,
.indigo-blueGrey.dark .mat-typography .mat-h5,
.indigo-blueGrey.dark .mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}
.indigo-blueGrey.dark .mat-h6,
.indigo-blueGrey.dark .mat-typography .mat-h6,
.indigo-blueGrey.dark .mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}
.indigo-blueGrey.dark .mat-body-strong,
.indigo-blueGrey.dark .mat-body-2,
.indigo-blueGrey.dark .mat-typography .mat-body-strong,
.indigo-blueGrey.dark .mat-typography .mat-body-2 {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.indigo-blueGrey.dark .mat-body,
.indigo-blueGrey.dark .mat-body-1,
.indigo-blueGrey.dark .mat-typography .mat-body,
.indigo-blueGrey.dark .mat-typography .mat-body-1,
.indigo-blueGrey.dark .mat-typography {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.indigo-blueGrey.dark .mat-body p,
.indigo-blueGrey.dark .mat-body-1 p,
.indigo-blueGrey.dark .mat-typography .mat-body p,
.indigo-blueGrey.dark .mat-typography .mat-body-1 p,
.indigo-blueGrey.dark .mat-typography p {
  margin: 0 0 12px;
}
.indigo-blueGrey.dark .mat-small,
.indigo-blueGrey.dark .mat-caption,
.indigo-blueGrey.dark .mat-typography .mat-small,
.indigo-blueGrey.dark .mat-typography .mat-caption {
  font: 400 12px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.indigo-blueGrey.dark .mat-display-4,
.indigo-blueGrey.dark .mat-typography .mat-display-4 {
  font: 300 112px / 112px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}
.indigo-blueGrey.dark .mat-display-3,
.indigo-blueGrey.dark .mat-typography .mat-display-3 {
  font: 400 56px / 56px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}
.indigo-blueGrey.dark .mat-display-2,
.indigo-blueGrey.dark .mat-typography .mat-display-2 {
  font: 400 45px / 48px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}
.indigo-blueGrey.dark .mat-display-1,
.indigo-blueGrey.dark .mat-typography .mat-display-1 {
  font: 400 34px / 40px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}
.indigo-blueGrey.dark .mat-bottom-sheet-container {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.indigo-blueGrey.dark .mat-button, .indigo-blueGrey.dark .mat-raised-button, .indigo-blueGrey.dark .mat-icon-button, .indigo-blueGrey.dark .mat-stroked-button,
.indigo-blueGrey.dark .mat-flat-button, .indigo-blueGrey.dark .mat-fab, .indigo-blueGrey.dark .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.indigo-blueGrey.dark .mat-button-toggle {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-blueGrey.dark .mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-blueGrey.dark .mat-card-title {
  font-size: 24px;
  font-weight: 500;
}
.indigo-blueGrey.dark .mat-card-header .mat-card-title {
  font-size: 20px;
}
.indigo-blueGrey.dark .mat-card-subtitle,
.indigo-blueGrey.dark .mat-card-content {
  font-size: 14px;
}
.indigo-blueGrey.dark .mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-blueGrey.dark .mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}
.indigo-blueGrey.dark .mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.indigo-blueGrey.dark .mat-chip .mat-chip-trailing-icon.mat-icon,
.indigo-blueGrey.dark .mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}
.indigo-blueGrey.dark .mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-blueGrey.dark .mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}
.indigo-blueGrey.dark .mat-cell, .indigo-blueGrey.dark .mat-footer-cell {
  font-size: 14px;
}
.indigo-blueGrey.dark .mat-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-blueGrey.dark .mat-calendar-body {
  font-size: 13px;
}
.indigo-blueGrey.dark .mat-calendar-body-label,
.indigo-blueGrey.dark .mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}
.indigo-blueGrey.dark .mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}
.indigo-blueGrey.dark .mat-dialog-title {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.indigo-blueGrey.dark .mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400;
}
.indigo-blueGrey.dark .mat-expansion-panel-content {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.indigo-blueGrey.dark .mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.indigo-blueGrey.dark .mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}
.indigo-blueGrey.dark .mat-form-field-prefix .mat-icon,
.indigo-blueGrey.dark .mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.indigo-blueGrey.dark .mat-form-field-prefix .mat-icon-button,
.indigo-blueGrey.dark .mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.indigo-blueGrey.dark .mat-form-field-prefix .mat-icon-button .mat-icon,
.indigo-blueGrey.dark .mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}
.indigo-blueGrey.dark .mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}
.indigo-blueGrey.dark .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.indigo-blueGrey.dark .mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34361em) scale(0.75);
  width: 133.3334733333%;
}
.indigo-blueGrey.dark .mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.3436em) scale(0.75);
  width: 133.3334833333%;
}
.indigo-blueGrey.dark .mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}
.indigo-blueGrey.dark .mat-form-field-label {
  top: 1.34375em;
}
.indigo-blueGrey.dark .mat-form-field-underline {
  bottom: 1.34375em;
}
.indigo-blueGrey.dark .mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}
.indigo-blueGrey.dark .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.indigo-blueGrey.dark .mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.indigo-blueGrey.dark .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.indigo-blueGrey.dark .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00142px);
  width: 133.3337533333%;
}
.indigo-blueGrey.dark .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00143px);
  width: 133.3337633333%;
}
.indigo-blueGrey.dark .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00144px);
  width: 133.3337733333%;
}
.indigo-blueGrey.dark .mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.indigo-blueGrey.dark .mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.indigo-blueGrey.dark .mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}
@media print {
  .indigo-blueGrey.dark .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.indigo-blueGrey.dark .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2808em) scale(0.75);
  }
  .indigo-blueGrey.dark .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28079em) scale(0.75);
  }
  .indigo-blueGrey.dark .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28078em) scale(0.75);
  }
}
.indigo-blueGrey.dark .mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.indigo-blueGrey.dark .mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.indigo-blueGrey.dark .mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.indigo-blueGrey.dark .mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59361em) scale(0.75);
  width: 133.3334733333%;
}
.indigo-blueGrey.dark .mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.5936em) scale(0.75);
  width: 133.3334833333%;
}
.indigo-blueGrey.dark .mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.indigo-blueGrey.dark .mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.indigo-blueGrey.dark .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.indigo-blueGrey.dark .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59361em) scale(0.75);
  width: 133.3334733333%;
}
.indigo-blueGrey.dark .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.5936em) scale(0.75);
  width: 133.3334833333%;
}
.indigo-blueGrey.dark .mat-grid-tile-header,
.indigo-blueGrey.dark .mat-grid-tile-footer {
  font-size: 14px;
}
.indigo-blueGrey.dark .mat-grid-tile-header .mat-line,
.indigo-blueGrey.dark .mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.indigo-blueGrey.dark .mat-grid-tile-header .mat-line:nth-child(n+2),
.indigo-blueGrey.dark .mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.indigo-blueGrey.dark input.mat-input-element {
  margin-top: -0.0625em;
}
.indigo-blueGrey.dark .mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
}
.indigo-blueGrey.dark .mat-paginator,
.indigo-blueGrey.dark .mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
}
.indigo-blueGrey.dark .mat-radio-button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-blueGrey.dark .mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-blueGrey.dark .mat-select-trigger {
  height: 1.125em;
}
.indigo-blueGrey.dark .mat-slide-toggle-content {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-blueGrey.dark .mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}
.indigo-blueGrey.dark .mat-stepper-vertical, .indigo-blueGrey.dark .mat-stepper-horizontal {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-blueGrey.dark .mat-step-label {
  font-size: 14px;
  font-weight: 400;
}
.indigo-blueGrey.dark .mat-step-sub-label-error {
  font-weight: normal;
}
.indigo-blueGrey.dark .mat-step-label-error {
  font-size: 14px;
}
.indigo-blueGrey.dark .mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}
.indigo-blueGrey.dark .mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-blueGrey.dark .mat-tab-label, .indigo-blueGrey.dark .mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.indigo-blueGrey.dark .mat-toolbar,
.indigo-blueGrey.dark .mat-toolbar h1,
.indigo-blueGrey.dark .mat-toolbar h2,
.indigo-blueGrey.dark .mat-toolbar h3,
.indigo-blueGrey.dark .mat-toolbar h4,
.indigo-blueGrey.dark .mat-toolbar h5,
.indigo-blueGrey.dark .mat-toolbar h6 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0;
}
.indigo-blueGrey.dark .mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.indigo-blueGrey.dark .mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.indigo-blueGrey.dark .mat-list-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-blueGrey.dark .mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-blueGrey.dark .mat-list-base .mat-list-item {
  font-size: 16px;
}
.indigo-blueGrey.dark .mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.indigo-blueGrey.dark .mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.indigo-blueGrey.dark .mat-list-base .mat-list-option {
  font-size: 16px;
}
.indigo-blueGrey.dark .mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.indigo-blueGrey.dark .mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.indigo-blueGrey.dark .mat-list-base .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.indigo-blueGrey.dark .mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.indigo-blueGrey.dark .mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.indigo-blueGrey.dark .mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.indigo-blueGrey.dark .mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.indigo-blueGrey.dark .mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.indigo-blueGrey.dark .mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.indigo-blueGrey.dark .mat-list-base[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}
.indigo-blueGrey.dark .mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
}
.indigo-blueGrey.dark .mat-optgroup-label {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.indigo-blueGrey.dark .mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
}
.indigo-blueGrey.dark .mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}
.indigo-blueGrey.dark .mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.indigo-blueGrey.dark .mat-tree-node,
.indigo-blueGrey.dark .mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}
.indigo-blueGrey.dark .mat-ripple {
  overflow: hidden;
  position: relative;
}
.indigo-blueGrey.dark .mat-ripple:not(:empty) {
  transform: translateZ(0);
}
.indigo-blueGrey.dark .mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}
.indigo-blueGrey.dark .mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .indigo-blueGrey.dark .mat-ripple-element {
  display: none;
}

.indigo-blueGrey.dark .cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .indigo-blueGrey.dark .cdk-visually-hidden {
  left: auto;
  right: 0;
}
.indigo-blueGrey.dark .cdk-overlay-container, .indigo-blueGrey.dark .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.indigo-blueGrey.dark .cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.indigo-blueGrey.dark .cdk-overlay-container:empty {
  display: none;
}
.indigo-blueGrey.dark .cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}
.indigo-blueGrey.dark .cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}
.indigo-blueGrey.dark .cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.indigo-blueGrey.dark .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .indigo-blueGrey.dark .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}
.indigo-blueGrey.dark .cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}
.indigo-blueGrey.dark .cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.indigo-blueGrey.dark .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}
.indigo-blueGrey.dark .cdk-overlay-backdrop-noop-animation {
  transition: none;
}
.indigo-blueGrey.dark .cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}
.indigo-blueGrey.dark .cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}
.indigo-blueGrey.dark textarea.cdk-textarea-autosize {
  resize: none;
}
.indigo-blueGrey.dark textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}
.indigo-blueGrey.dark textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}
@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.indigo-blueGrey.dark .cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}
.indigo-blueGrey.dark .cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}
.indigo-blueGrey.dark .mat-focus-indicator {
  position: relative;
}
.indigo-blueGrey.dark .mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.indigo-blueGrey.dark .mat-focus-indicator:focus::before {
  content: "";
}
.cdk-high-contrast-active .indigo-blueGrey.dark {
  --mat-focus-indicator-display: block;
}

.indigo-blueGrey.dark .mat-mdc-focus-indicator {
  position: relative;
}
.indigo-blueGrey.dark .mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.indigo-blueGrey.dark .mat-mdc-focus-indicator:focus::before {
  content: "";
}
.cdk-high-contrast-active .indigo-blueGrey.dark {
  --mat-mdc-focus-indicator-display: block;
}

.indigo-blueGrey.dark .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.indigo-blueGrey.dark .mat-option {
  color: white;
}
.indigo-blueGrey.dark .mat-option:hover:not(.mat-option-disabled), .indigo-blueGrey.dark .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-blueGrey.dark .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-blueGrey.dark .mat-option.mat-active {
  background: rgba(255, 255, 255, 0.04);
  color: white;
}
.indigo-blueGrey.dark .mat-option.mat-option-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-blueGrey.dark .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #9fa8da;
}
.indigo-blueGrey.dark .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #b0bec5;
}
.indigo-blueGrey.dark .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #e57373;
}
.indigo-blueGrey.dark .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-blueGrey.dark .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-blueGrey.dark .mat-pseudo-checkbox {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-blueGrey.dark .mat-pseudo-checkbox::after {
  color: #303030;
}
.indigo-blueGrey.dark .mat-pseudo-checkbox-disabled {
  color: #686868;
}
.indigo-blueGrey.dark .mat-primary .mat-pseudo-checkbox-checked,
.indigo-blueGrey.dark .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #9fa8da;
}
.indigo-blueGrey.dark .mat-pseudo-checkbox-checked,
.indigo-blueGrey.dark .mat-pseudo-checkbox-indeterminate,
.indigo-blueGrey.dark .mat-accent .mat-pseudo-checkbox-checked,
.indigo-blueGrey.dark .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #b0bec5;
}
.indigo-blueGrey.dark .mat-warn .mat-pseudo-checkbox-checked,
.indigo-blueGrey.dark .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #e57373;
}
.indigo-blueGrey.dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.indigo-blueGrey.dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #686868;
}
.indigo-blueGrey.dark .mat-app-background, .indigo-blueGrey.dark.mat-app-background {
  background-color: #303030;
  color: white;
}
.indigo-blueGrey.dark .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey.dark .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey.dark .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey.dark .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey.dark .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey.dark .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey.dark .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey.dark .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey.dark .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey.dark .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey.dark .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey.dark .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey.dark .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey.dark .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey.dark .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey.dark .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey.dark .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey.dark .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey.dark .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey.dark .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey.dark .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey.dark .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey.dark .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey.dark .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey.dark .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.indigo-blueGrey.dark .mat-autocomplete-panel {
  background: #424242;
  color: white;
}
.indigo-blueGrey.dark .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey.dark .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: #424242;
}
.indigo-blueGrey.dark .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: white;
}
.indigo-blueGrey.dark .mat-badge-content {
  color: rgba(0, 0, 0, 0.87);
  background: #9fa8da;
}
.cdk-high-contrast-active .indigo-blueGrey.dark .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.indigo-blueGrey.dark .mat-badge-accent .mat-badge-content {
  background: #b0bec5;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark .mat-badge-warn .mat-badge-content {
  color: rgba(0, 0, 0, 0.87);
  background: #e57373;
}
.indigo-blueGrey.dark .mat-badge-disabled .mat-badge-content {
  background: #6e6e6e;
  color: rgba(255, 255, 255, 0.5);
}
.indigo-blueGrey.dark .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.indigo-blueGrey.dark .mat-button, .indigo-blueGrey.dark .mat-icon-button, .indigo-blueGrey.dark .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.indigo-blueGrey.dark .mat-button.mat-primary, .indigo-blueGrey.dark .mat-icon-button.mat-primary, .indigo-blueGrey.dark .mat-stroked-button.mat-primary {
  color: #9fa8da;
}
.indigo-blueGrey.dark .mat-button.mat-accent, .indigo-blueGrey.dark .mat-icon-button.mat-accent, .indigo-blueGrey.dark .mat-stroked-button.mat-accent {
  color: #b0bec5;
}
.indigo-blueGrey.dark .mat-button.mat-warn, .indigo-blueGrey.dark .mat-icon-button.mat-warn, .indigo-blueGrey.dark .mat-stroked-button.mat-warn {
  color: #e57373;
}
.indigo-blueGrey.dark .mat-button.mat-primary.mat-button-disabled, .indigo-blueGrey.dark .mat-button.mat-accent.mat-button-disabled, .indigo-blueGrey.dark .mat-button.mat-warn.mat-button-disabled, .indigo-blueGrey.dark .mat-button.mat-button-disabled.mat-button-disabled, .indigo-blueGrey.dark .mat-icon-button.mat-primary.mat-button-disabled, .indigo-blueGrey.dark .mat-icon-button.mat-accent.mat-button-disabled, .indigo-blueGrey.dark .mat-icon-button.mat-warn.mat-button-disabled, .indigo-blueGrey.dark .mat-icon-button.mat-button-disabled.mat-button-disabled, .indigo-blueGrey.dark .mat-stroked-button.mat-primary.mat-button-disabled, .indigo-blueGrey.dark .mat-stroked-button.mat-accent.mat-button-disabled, .indigo-blueGrey.dark .mat-stroked-button.mat-warn.mat-button-disabled, .indigo-blueGrey.dark .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.indigo-blueGrey.dark .mat-button.mat-primary .mat-button-focus-overlay, .indigo-blueGrey.dark .mat-icon-button.mat-primary .mat-button-focus-overlay, .indigo-blueGrey.dark .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #9fa8da;
}
.indigo-blueGrey.dark .mat-button.mat-accent .mat-button-focus-overlay, .indigo-blueGrey.dark .mat-icon-button.mat-accent .mat-button-focus-overlay, .indigo-blueGrey.dark .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #b0bec5;
}
.indigo-blueGrey.dark .mat-button.mat-warn .mat-button-focus-overlay, .indigo-blueGrey.dark .mat-icon-button.mat-warn .mat-button-focus-overlay, .indigo-blueGrey.dark .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #e57373;
}
.indigo-blueGrey.dark .mat-button.mat-button-disabled .mat-button-focus-overlay, .indigo-blueGrey.dark .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .indigo-blueGrey.dark .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.indigo-blueGrey.dark .mat-button .mat-ripple-element, .indigo-blueGrey.dark .mat-icon-button .mat-ripple-element, .indigo-blueGrey.dark .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.indigo-blueGrey.dark .mat-button-focus-overlay {
  background: white;
}
.indigo-blueGrey.dark .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark .mat-flat-button, .indigo-blueGrey.dark .mat-raised-button, .indigo-blueGrey.dark .mat-fab, .indigo-blueGrey.dark .mat-mini-fab {
  color: white;
  background-color: #424242;
}
.indigo-blueGrey.dark .mat-flat-button.mat-primary, .indigo-blueGrey.dark .mat-raised-button.mat-primary, .indigo-blueGrey.dark .mat-fab.mat-primary, .indigo-blueGrey.dark .mat-mini-fab.mat-primary {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark .mat-flat-button.mat-accent, .indigo-blueGrey.dark .mat-raised-button.mat-accent, .indigo-blueGrey.dark .mat-fab.mat-accent, .indigo-blueGrey.dark .mat-mini-fab.mat-accent {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark .mat-flat-button.mat-warn, .indigo-blueGrey.dark .mat-raised-button.mat-warn, .indigo-blueGrey.dark .mat-fab.mat-warn, .indigo-blueGrey.dark .mat-mini-fab.mat-warn {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark .mat-flat-button.mat-primary.mat-button-disabled, .indigo-blueGrey.dark .mat-flat-button.mat-accent.mat-button-disabled, .indigo-blueGrey.dark .mat-flat-button.mat-warn.mat-button-disabled, .indigo-blueGrey.dark .mat-flat-button.mat-button-disabled.mat-button-disabled, .indigo-blueGrey.dark .mat-raised-button.mat-primary.mat-button-disabled, .indigo-blueGrey.dark .mat-raised-button.mat-accent.mat-button-disabled, .indigo-blueGrey.dark .mat-raised-button.mat-warn.mat-button-disabled, .indigo-blueGrey.dark .mat-raised-button.mat-button-disabled.mat-button-disabled, .indigo-blueGrey.dark .mat-fab.mat-primary.mat-button-disabled, .indigo-blueGrey.dark .mat-fab.mat-accent.mat-button-disabled, .indigo-blueGrey.dark .mat-fab.mat-warn.mat-button-disabled, .indigo-blueGrey.dark .mat-fab.mat-button-disabled.mat-button-disabled, .indigo-blueGrey.dark .mat-mini-fab.mat-primary.mat-button-disabled, .indigo-blueGrey.dark .mat-mini-fab.mat-accent.mat-button-disabled, .indigo-blueGrey.dark .mat-mini-fab.mat-warn.mat-button-disabled, .indigo-blueGrey.dark .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.indigo-blueGrey.dark .mat-flat-button.mat-primary, .indigo-blueGrey.dark .mat-raised-button.mat-primary, .indigo-blueGrey.dark .mat-fab.mat-primary, .indigo-blueGrey.dark .mat-mini-fab.mat-primary {
  background-color: #9fa8da;
}
.indigo-blueGrey.dark .mat-flat-button.mat-accent, .indigo-blueGrey.dark .mat-raised-button.mat-accent, .indigo-blueGrey.dark .mat-fab.mat-accent, .indigo-blueGrey.dark .mat-mini-fab.mat-accent {
  background-color: #b0bec5;
}
.indigo-blueGrey.dark .mat-flat-button.mat-warn, .indigo-blueGrey.dark .mat-raised-button.mat-warn, .indigo-blueGrey.dark .mat-fab.mat-warn, .indigo-blueGrey.dark .mat-mini-fab.mat-warn {
  background-color: #e57373;
}
.indigo-blueGrey.dark .mat-flat-button.mat-primary.mat-button-disabled, .indigo-blueGrey.dark .mat-flat-button.mat-accent.mat-button-disabled, .indigo-blueGrey.dark .mat-flat-button.mat-warn.mat-button-disabled, .indigo-blueGrey.dark .mat-flat-button.mat-button-disabled.mat-button-disabled, .indigo-blueGrey.dark .mat-raised-button.mat-primary.mat-button-disabled, .indigo-blueGrey.dark .mat-raised-button.mat-accent.mat-button-disabled, .indigo-blueGrey.dark .mat-raised-button.mat-warn.mat-button-disabled, .indigo-blueGrey.dark .mat-raised-button.mat-button-disabled.mat-button-disabled, .indigo-blueGrey.dark .mat-fab.mat-primary.mat-button-disabled, .indigo-blueGrey.dark .mat-fab.mat-accent.mat-button-disabled, .indigo-blueGrey.dark .mat-fab.mat-warn.mat-button-disabled, .indigo-blueGrey.dark .mat-fab.mat-button-disabled.mat-button-disabled, .indigo-blueGrey.dark .mat-mini-fab.mat-primary.mat-button-disabled, .indigo-blueGrey.dark .mat-mini-fab.mat-accent.mat-button-disabled, .indigo-blueGrey.dark .mat-mini-fab.mat-warn.mat-button-disabled, .indigo-blueGrey.dark .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark .mat-flat-button.mat-primary .mat-ripple-element, .indigo-blueGrey.dark .mat-raised-button.mat-primary .mat-ripple-element, .indigo-blueGrey.dark .mat-fab.mat-primary .mat-ripple-element, .indigo-blueGrey.dark .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.indigo-blueGrey.dark .mat-flat-button.mat-accent .mat-ripple-element, .indigo-blueGrey.dark .mat-raised-button.mat-accent .mat-ripple-element, .indigo-blueGrey.dark .mat-fab.mat-accent .mat-ripple-element, .indigo-blueGrey.dark .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.indigo-blueGrey.dark .mat-flat-button.mat-warn .mat-ripple-element, .indigo-blueGrey.dark .mat-raised-button.mat-warn .mat-ripple-element, .indigo-blueGrey.dark .mat-fab.mat-warn .mat-ripple-element, .indigo-blueGrey.dark .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.indigo-blueGrey.dark .mat-stroked-button:not([class*=mat-elevation-z]), .indigo-blueGrey.dark .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey.dark .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey.dark .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey.dark .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey.dark .mat-fab:not([class*=mat-elevation-z]), .indigo-blueGrey.dark .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey.dark .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .indigo-blueGrey.dark .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey.dark .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .indigo-blueGrey.dark .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey.dark .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.indigo-blueGrey.dark .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey.dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.indigo-blueGrey.dark .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.indigo-blueGrey.dark .mat-button-toggle {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-blueGrey.dark .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark .mat-button-toggle-appearance-standard {
  color: white;
  background: #424242;
}
.indigo-blueGrey.dark .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: white;
}
.indigo-blueGrey.dark .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #595959;
}
.indigo-blueGrey.dark [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #595959;
}
.indigo-blueGrey.dark .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #595959;
}
.indigo-blueGrey.dark .mat-button-toggle-checked {
  background-color: #212121;
  color: rgba(255, 255, 255, 0.7);
}
.indigo-blueGrey.dark .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: white;
}
.indigo-blueGrey.dark .mat-button-toggle-disabled {
  color: rgba(255, 255, 255, 0.3);
  background-color: black;
}
.indigo-blueGrey.dark .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: #424242;
}
.indigo-blueGrey.dark .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #424242;
}
.indigo-blueGrey.dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.indigo-blueGrey.dark .mat-button-toggle-group-appearance-standard {
  border: solid 1px #595959;
}
.indigo-blueGrey.dark .mat-card {
  background: #424242;
  color: white;
}
.indigo-blueGrey.dark .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey.dark .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey.dark .mat-card-subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-blueGrey.dark .mat-checkbox-frame {
  border-color: rgba(255, 255, 255, 0.7);
}
.indigo-blueGrey.dark .mat-checkbox-checkmark {
  fill: #303030;
}
.indigo-blueGrey.dark .mat-checkbox-checkmark-path {
  stroke: #303030 !important;
}
.indigo-blueGrey.dark .mat-checkbox-mixedmark {
  background-color: #303030;
}
.indigo-blueGrey.dark .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .indigo-blueGrey.dark .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #9fa8da;
}
.indigo-blueGrey.dark .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .indigo-blueGrey.dark .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #b0bec5;
}
.indigo-blueGrey.dark .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .indigo-blueGrey.dark .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #e57373;
}
.indigo-blueGrey.dark .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .indigo-blueGrey.dark .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #686868;
}
.indigo-blueGrey.dark .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #686868;
}
.indigo-blueGrey.dark .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-blueGrey.dark .mat-checkbox .mat-ripple-element {
  background-color: white;
}
.indigo-blueGrey.dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.indigo-blueGrey.dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #9fa8da;
}
.indigo-blueGrey.dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.indigo-blueGrey.dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #b0bec5;
}
.indigo-blueGrey.dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.indigo-blueGrey.dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #e57373;
}
.indigo-blueGrey.dark .mat-chip.mat-standard-chip {
  background-color: #616161;
  color: white;
}
.indigo-blueGrey.dark .mat-chip.mat-standard-chip .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.indigo-blueGrey.dark .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey.dark .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.indigo-blueGrey.dark .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.indigo-blueGrey.dark .mat-chip.mat-standard-chip::after {
  background: white;
}
.indigo-blueGrey.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.indigo-blueGrey.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.indigo-blueGrey.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #e57373;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.indigo-blueGrey.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.indigo-blueGrey.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #b0bec5;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.indigo-blueGrey.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.indigo-blueGrey.dark .mat-table {
  background: #424242;
}
.indigo-blueGrey.dark .mat-table thead, .indigo-blueGrey.dark .mat-table tbody, .indigo-blueGrey.dark .mat-table tfoot,
.indigo-blueGrey.dark mat-header-row, .indigo-blueGrey.dark mat-row, .indigo-blueGrey.dark mat-footer-row,
.indigo-blueGrey.dark [mat-header-row], .indigo-blueGrey.dark [mat-row], .indigo-blueGrey.dark [mat-footer-row],
.indigo-blueGrey.dark .mat-table-sticky {
  background: inherit;
}
.indigo-blueGrey.dark mat-row, .indigo-blueGrey.dark mat-header-row, .indigo-blueGrey.dark mat-footer-row,
.indigo-blueGrey.dark th.mat-header-cell, .indigo-blueGrey.dark td.mat-cell, .indigo-blueGrey.dark td.mat-footer-cell {
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark .mat-header-cell {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-blueGrey.dark .mat-cell, .indigo-blueGrey.dark .mat-footer-cell {
  color: white;
}
.indigo-blueGrey.dark .mat-calendar-arrow {
  fill: white;
}
.indigo-blueGrey.dark .mat-datepicker-toggle,
.indigo-blueGrey.dark .mat-datepicker-content .mat-calendar-next-button,
.indigo-blueGrey.dark .mat-datepicker-content .mat-calendar-previous-button {
  color: white;
}
.indigo-blueGrey.dark .mat-calendar-table-header-divider::after {
  background: rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark .mat-calendar-table-header,
.indigo-blueGrey.dark .mat-calendar-body-label {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-blueGrey.dark .mat-calendar-body-cell-content,
.indigo-blueGrey.dark .mat-date-range-input-separator {
  color: white;
  border-color: transparent;
}
.indigo-blueGrey.dark .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-blueGrey.dark .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-blueGrey.dark .mat-calendar-body-in-preview {
  color: rgba(255, 255, 255, 0.24);
}
.indigo-blueGrey.dark .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.5);
}
.indigo-blueGrey.dark .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.3);
}
.indigo-blueGrey.dark .mat-calendar-body-in-range::before {
  background: rgba(159, 168, 218, 0.2);
}
.indigo-blueGrey.dark .mat-calendar-body-comparison-identical,
.indigo-blueGrey.dark .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.indigo-blueGrey.dark .mat-calendar-body-comparison-bridge-start::before,
.indigo-blueGrey.dark [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(159, 168, 218, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.indigo-blueGrey.dark .mat-calendar-body-comparison-bridge-end::before,
.indigo-blueGrey.dark [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(159, 168, 218, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.indigo-blueGrey.dark .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.indigo-blueGrey.dark .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.indigo-blueGrey.dark .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.indigo-blueGrey.dark .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.indigo-blueGrey.dark .mat-calendar-body-selected {
  background-color: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(159, 168, 218, 0.4);
}
.indigo-blueGrey.dark .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.indigo-blueGrey.dark .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(159, 168, 218, 0.3);
}
@media (hover: hover) {
  .indigo-blueGrey.dark .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(159, 168, 218, 0.3);
  }
}
.indigo-blueGrey.dark .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #424242;
  color: white;
}
.indigo-blueGrey.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(176, 190, 197, 0.2);
}
.indigo-blueGrey.dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.indigo-blueGrey.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.indigo-blueGrey.dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.indigo-blueGrey.dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(176, 190, 197, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.indigo-blueGrey.dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.indigo-blueGrey.dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(176, 190, 197, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.indigo-blueGrey.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.indigo-blueGrey.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.indigo-blueGrey.dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.indigo-blueGrey.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.indigo-blueGrey.dark .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #b0bec5;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(176, 190, 197, 0.4);
}
.indigo-blueGrey.dark .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.indigo-blueGrey.dark .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(176, 190, 197, 0.3);
}
@media (hover: hover) {
  .indigo-blueGrey.dark .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(176, 190, 197, 0.3);
  }
}
.indigo-blueGrey.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(229, 115, 115, 0.2);
}
.indigo-blueGrey.dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.indigo-blueGrey.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.indigo-blueGrey.dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.indigo-blueGrey.dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(229, 115, 115, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.indigo-blueGrey.dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.indigo-blueGrey.dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(229, 115, 115, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.indigo-blueGrey.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.indigo-blueGrey.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.indigo-blueGrey.dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.indigo-blueGrey.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.indigo-blueGrey.dark .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #e57373;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(229, 115, 115, 0.4);
}
.indigo-blueGrey.dark .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.indigo-blueGrey.dark .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(229, 115, 115, 0.3);
}
@media (hover: hover) {
  .indigo-blueGrey.dark .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(229, 115, 115, 0.3);
  }
}
.indigo-blueGrey.dark .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey.dark .mat-datepicker-toggle-active {
  color: #9fa8da;
}
.indigo-blueGrey.dark .mat-datepicker-toggle-active.mat-accent {
  color: #b0bec5;
}
.indigo-blueGrey.dark .mat-datepicker-toggle-active.mat-warn {
  color: #e57373;
}
.indigo-blueGrey.dark .mat-date-range-input-inner[disabled] {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-blueGrey.dark .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.indigo-blueGrey.dark .mat-divider {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark .mat-divider-vertical {
  border-right-color: rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark .mat-expansion-panel {
  background: #424242;
  color: white;
}
.indigo-blueGrey.dark .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey.dark .mat-action-row {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .indigo-blueGrey.dark .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .indigo-blueGrey.dark .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(255, 255, 255, 0.04);
}
@media (hover: none) {
  .indigo-blueGrey.dark .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: #424242;
  }
}
.indigo-blueGrey.dark .mat-expansion-panel-header-title {
  color: white;
}
.indigo-blueGrey.dark .mat-expansion-panel-header-description,
.indigo-blueGrey.dark .mat-expansion-indicator::after {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-blueGrey.dark .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(255, 255, 255, 0.3);
}
.indigo-blueGrey.dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.indigo-blueGrey.dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.indigo-blueGrey.dark .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-blueGrey.dark .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-blueGrey.dark .mat-form-field.mat-focused .mat-form-field-label {
  color: #9fa8da;
}
.indigo-blueGrey.dark .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #b0bec5;
}
.indigo-blueGrey.dark .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #e57373;
}
.indigo-blueGrey.dark .mat-focused .mat-form-field-required-marker {
  color: #b0bec5;
}
.indigo-blueGrey.dark .mat-form-field-ripple {
  background-color: white;
}
.indigo-blueGrey.dark .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #9fa8da;
}
.indigo-blueGrey.dark .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #b0bec5;
}
.indigo-blueGrey.dark .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #e57373;
}
.indigo-blueGrey.dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #9fa8da;
}
.indigo-blueGrey.dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #b0bec5;
}
.indigo-blueGrey.dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #e57373;
}
.indigo-blueGrey.dark .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #e57373;
}
.indigo-blueGrey.dark .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.indigo-blueGrey.dark .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #e57373;
}
.indigo-blueGrey.dark .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.indigo-blueGrey.dark .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #e57373;
}
.indigo-blueGrey.dark .mat-error {
  color: #e57373;
}
.indigo-blueGrey.dark .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-blueGrey.dark .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-blueGrey.dark .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.indigo-blueGrey.dark .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.indigo-blueGrey.dark .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.indigo-blueGrey.dark .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.indigo-blueGrey.dark .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.1);
}
.indigo-blueGrey.dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.05);
}
.indigo-blueGrey.dark .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(255, 255, 255, 0.5);
}
.indigo-blueGrey.dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-blueGrey.dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.indigo-blueGrey.dark .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.3);
}
.indigo-blueGrey.dark .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: white;
}
.indigo-blueGrey.dark .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #9fa8da;
}
.indigo-blueGrey.dark .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #b0bec5;
}
.indigo-blueGrey.dark .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #e57373;
}
.indigo-blueGrey.dark .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #e57373;
}
.indigo-blueGrey.dark .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-blueGrey.dark .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.15);
}
.indigo-blueGrey.dark .mat-icon.mat-primary {
  color: #9fa8da;
}
.indigo-blueGrey.dark .mat-icon.mat-accent {
  color: #b0bec5;
}
.indigo-blueGrey.dark .mat-icon.mat-warn {
  color: #e57373;
}
.indigo-blueGrey.dark .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-blueGrey.dark .mat-input-element:disabled,
.indigo-blueGrey.dark .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-blueGrey.dark .mat-input-element {
  caret-color: #9fa8da;
}
.indigo-blueGrey.dark .mat-input-element::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-blueGrey.dark .mat-input-element::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-blueGrey.dark .mat-input-element::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-blueGrey.dark .mat-input-element:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-blueGrey.dark .mat-input-element:not(.mat-native-select-inline) option {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark .mat-input-element:not(.mat-native-select-inline) option:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.indigo-blueGrey.dark .mat-form-field.mat-accent .mat-input-element {
  caret-color: #b0bec5;
}
.indigo-blueGrey.dark .mat-form-field.mat-warn .mat-input-element,
.indigo-blueGrey.dark .mat-form-field-invalid .mat-input-element {
  caret-color: #e57373;
}
.indigo-blueGrey.dark .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #e57373;
}
.indigo-blueGrey.dark .mat-list-base .mat-list-item {
  color: white;
}
.indigo-blueGrey.dark .mat-list-base .mat-list-option {
  color: white;
}
.indigo-blueGrey.dark .mat-list-base .mat-subheader {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-blueGrey.dark .mat-list-base .mat-list-item-disabled {
  background-color: rgba(255, 255, 255, 0.12);
  color: rgba(255, 255, 255, 0.5);
}
.indigo-blueGrey.dark .mat-list-option:hover, .indigo-blueGrey.dark .mat-list-option:focus,
.indigo-blueGrey.dark .mat-nav-list .mat-list-item:hover,
.indigo-blueGrey.dark .mat-nav-list .mat-list-item:focus,
.indigo-blueGrey.dark .mat-action-list .mat-list-item:hover,
.indigo-blueGrey.dark .mat-action-list .mat-list-item:focus {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-blueGrey.dark .mat-list-single-selected-option, .indigo-blueGrey.dark .mat-list-single-selected-option:hover, .indigo-blueGrey.dark .mat-list-single-selected-option:focus {
  background: rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark .mat-menu-panel {
  background: #424242;
}
.indigo-blueGrey.dark .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey.dark .mat-menu-item {
  background: transparent;
  color: white;
}
.indigo-blueGrey.dark .mat-menu-item[disabled],
.indigo-blueGrey.dark .mat-menu-item[disabled] .mat-menu-submenu-icon,
.indigo-blueGrey.dark .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-blueGrey.dark .mat-menu-item .mat-icon-no-color,
.indigo-blueGrey.dark .mat-menu-submenu-icon {
  color: white;
}
.indigo-blueGrey.dark .mat-menu-item:hover:not([disabled]),
.indigo-blueGrey.dark .mat-menu-item.cdk-program-focused:not([disabled]),
.indigo-blueGrey.dark .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.indigo-blueGrey.dark .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-blueGrey.dark .mat-paginator {
  background: #424242;
}
.indigo-blueGrey.dark .mat-paginator,
.indigo-blueGrey.dark .mat-paginator-page-size .mat-select-trigger {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-blueGrey.dark .mat-paginator-decrement,
.indigo-blueGrey.dark .mat-paginator-increment {
  border-top: 2px solid white;
  border-right: 2px solid white;
}
.indigo-blueGrey.dark .mat-paginator-first,
.indigo-blueGrey.dark .mat-paginator-last {
  border-top: 2px solid white;
}
.indigo-blueGrey.dark .mat-icon-button[disabled] .mat-paginator-decrement,
.indigo-blueGrey.dark .mat-icon-button[disabled] .mat-paginator-increment,
.indigo-blueGrey.dark .mat-icon-button[disabled] .mat-paginator-first,
.indigo-blueGrey.dark .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(255, 255, 255, 0.5);
}
.indigo-blueGrey.dark .mat-progress-bar-background {
  fill: #4c4e5b;
}
.indigo-blueGrey.dark .mat-progress-bar-buffer {
  background-color: #4c4e5b;
}
.indigo-blueGrey.dark .mat-progress-bar-fill::after {
  background-color: #9fa8da;
}
.indigo-blueGrey.dark .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #505455;
}
.indigo-blueGrey.dark .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #505455;
}
.indigo-blueGrey.dark .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #b0bec5;
}
.indigo-blueGrey.dark .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #5d4141;
}
.indigo-blueGrey.dark .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #5d4141;
}
.indigo-blueGrey.dark .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #e57373;
}
.indigo-blueGrey.dark .mat-progress-spinner circle, .indigo-blueGrey.dark .mat-spinner circle {
  stroke: #9fa8da;
}
.indigo-blueGrey.dark .mat-progress-spinner.mat-accent circle, .indigo-blueGrey.dark .mat-spinner.mat-accent circle {
  stroke: #b0bec5;
}
.indigo-blueGrey.dark .mat-progress-spinner.mat-warn circle, .indigo-blueGrey.dark .mat-spinner.mat-warn circle {
  stroke: #e57373;
}
.indigo-blueGrey.dark .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.7);
}
.indigo-blueGrey.dark .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #9fa8da;
}
.indigo-blueGrey.dark .mat-radio-button.mat-primary .mat-radio-inner-circle,
.indigo-blueGrey.dark .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .indigo-blueGrey.dark .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .indigo-blueGrey.dark .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #9fa8da;
}
.indigo-blueGrey.dark .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #b0bec5;
}
.indigo-blueGrey.dark .mat-radio-button.mat-accent .mat-radio-inner-circle,
.indigo-blueGrey.dark .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .indigo-blueGrey.dark .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .indigo-blueGrey.dark .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #b0bec5;
}
.indigo-blueGrey.dark .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e57373;
}
.indigo-blueGrey.dark .mat-radio-button.mat-warn .mat-radio-inner-circle,
.indigo-blueGrey.dark .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .indigo-blueGrey.dark .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .indigo-blueGrey.dark .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #e57373;
}
.indigo-blueGrey.dark .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.indigo-blueGrey.dark .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.5);
}
.indigo-blueGrey.dark .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.indigo-blueGrey.dark .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(255, 255, 255, 0.5);
}
.indigo-blueGrey.dark .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-blueGrey.dark .mat-radio-button .mat-ripple-element {
  background-color: white;
}
.indigo-blueGrey.dark .mat-select-value {
  color: white;
}
.indigo-blueGrey.dark .mat-select-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-blueGrey.dark .mat-select-disabled .mat-select-value {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-blueGrey.dark .mat-select-arrow {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-blueGrey.dark .mat-select-panel {
  background: #424242;
}
.indigo-blueGrey.dark .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey.dark .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #9fa8da;
}
.indigo-blueGrey.dark .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #b0bec5;
}
.indigo-blueGrey.dark .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #e57373;
}
.indigo-blueGrey.dark .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #e57373;
}
.indigo-blueGrey.dark .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-blueGrey.dark .mat-drawer-container {
  background-color: #303030;
  color: white;
}
.indigo-blueGrey.dark .mat-drawer {
  background-color: #424242;
  color: white;
}
.indigo-blueGrey.dark .mat-drawer.mat-drawer-push {
  background-color: #424242;
}
.indigo-blueGrey.dark .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey.dark .mat-drawer-side {
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.indigo-blueGrey.dark [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.indigo-blueGrey.dark [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(189, 189, 189, 0.6);
}
.indigo-blueGrey.dark .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #b0bec5;
}
.indigo-blueGrey.dark .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(176, 190, 197, 0.54);
}
.indigo-blueGrey.dark .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #b0bec5;
}
.indigo-blueGrey.dark .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #9fa8da;
}
.indigo-blueGrey.dark .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(159, 168, 218, 0.54);
}
.indigo-blueGrey.dark .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #9fa8da;
}
.indigo-blueGrey.dark .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #e57373;
}
.indigo-blueGrey.dark .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(229, 115, 115, 0.54);
}
.indigo-blueGrey.dark .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #e57373;
}
.indigo-blueGrey.dark .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: white;
}
.indigo-blueGrey.dark .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #bdbdbd;
}
.indigo-blueGrey.dark .mat-slide-toggle-bar {
  background-color: rgba(255, 255, 255, 0.5);
}
.indigo-blueGrey.dark .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.indigo-blueGrey.dark .mat-slider.mat-primary .mat-slider-track-fill,
.indigo-blueGrey.dark .mat-slider.mat-primary .mat-slider-thumb,
.indigo-blueGrey.dark .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #9fa8da;
}
.indigo-blueGrey.dark .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(159, 168, 218, 0.2);
}
.indigo-blueGrey.dark .mat-slider.mat-accent .mat-slider-track-fill,
.indigo-blueGrey.dark .mat-slider.mat-accent .mat-slider-thumb,
.indigo-blueGrey.dark .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #b0bec5;
}
.indigo-blueGrey.dark .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(176, 190, 197, 0.2);
}
.indigo-blueGrey.dark .mat-slider.mat-warn .mat-slider-track-fill,
.indigo-blueGrey.dark .mat-slider.mat-warn .mat-slider-thumb,
.indigo-blueGrey.dark .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #e57373;
}
.indigo-blueGrey.dark .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(229, 115, 115, 0.2);
}
.indigo-blueGrey.dark .mat-slider:hover .mat-slider-track-background,
.indigo-blueGrey.dark .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.indigo-blueGrey.dark .mat-slider.mat-slider-disabled .mat-slider-track-background,
.indigo-blueGrey.dark .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.indigo-blueGrey.dark .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(255, 255, 255, 0.3);
}
.indigo-blueGrey.dark .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.indigo-blueGrey.dark .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.indigo-blueGrey.dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: white;
}
.indigo-blueGrey.dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.indigo-blueGrey.dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(255, 255, 255, 0.3);
}
.indigo-blueGrey.dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
  background-color: transparent;
}
.indigo-blueGrey.dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .indigo-blueGrey.dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.indigo-blueGrey.dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .indigo-blueGrey.dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.indigo-blueGrey.dark .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(255, 255, 255, 0.7);
}
.indigo-blueGrey.dark .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.indigo-blueGrey.dark .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.indigo-blueGrey.dark .mat-step-header.cdk-keyboard-focused, .indigo-blueGrey.dark .mat-step-header.cdk-program-focused, .indigo-blueGrey.dark .mat-step-header:hover:not([aria-disabled]), .indigo-blueGrey.dark .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(255, 255, 255, 0.04);
}
.indigo-blueGrey.dark .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .indigo-blueGrey.dark .mat-step-header:hover {
    background: none;
  }
}
.indigo-blueGrey.dark .mat-step-header .mat-step-label,
.indigo-blueGrey.dark .mat-step-header .mat-step-optional {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-blueGrey.dark .mat-step-header .mat-step-icon {
  background-color: rgba(255, 255, 255, 0.7);
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark .mat-step-header .mat-step-icon-selected,
.indigo-blueGrey.dark .mat-step-header .mat-step-icon-state-done,
.indigo-blueGrey.dark .mat-step-header .mat-step-icon-state-edit {
  background-color: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark .mat-step-header.mat-accent .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark .mat-step-header.mat-accent .mat-step-icon-selected,
.indigo-blueGrey.dark .mat-step-header.mat-accent .mat-step-icon-state-done,
.indigo-blueGrey.dark .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #b0bec5;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark .mat-step-header.mat-warn .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark .mat-step-header.mat-warn .mat-step-icon-selected,
.indigo-blueGrey.dark .mat-step-header.mat-warn .mat-step-icon-state-done,
.indigo-blueGrey.dark .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #e57373;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #e57373;
}
.indigo-blueGrey.dark .mat-step-header .mat-step-label.mat-step-label-active {
  color: white;
}
.indigo-blueGrey.dark .mat-step-header .mat-step-label.mat-step-label-error {
  color: #e57373;
}
.indigo-blueGrey.dark .mat-stepper-horizontal, .indigo-blueGrey.dark .mat-stepper-vertical {
  background-color: #424242;
}
.indigo-blueGrey.dark .mat-stepper-vertical-line::before {
  border-left-color: rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark .mat-horizontal-stepper-header::before,
.indigo-blueGrey.dark .mat-horizontal-stepper-header::after,
.indigo-blueGrey.dark .mat-stepper-horizontal-line {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark .mat-sort-header-arrow {
  color: #c6c6c6;
}
.indigo-blueGrey.dark .mat-tab-nav-bar,
.indigo-blueGrey.dark .mat-tab-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark .mat-tab-group-inverted-header .mat-tab-nav-bar,
.indigo-blueGrey.dark .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  border-bottom: none;
}
.indigo-blueGrey.dark .mat-tab-label, .indigo-blueGrey.dark .mat-tab-link {
  color: white;
}
.indigo-blueGrey.dark .mat-tab-label.mat-tab-disabled, .indigo-blueGrey.dark .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-blueGrey.dark .mat-tab-header-pagination-chevron {
  border-color: white;
}
.indigo-blueGrey.dark .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.5);
}
.indigo-blueGrey.dark .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.indigo-blueGrey.dark .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.indigo-blueGrey.dark .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-blueGrey.dark .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-blueGrey.dark .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-blueGrey.dark .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .indigo-blueGrey.dark .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-blueGrey.dark .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-blueGrey.dark .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-blueGrey.dark .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(197, 202, 233, 0.3);
}
.indigo-blueGrey.dark .mat-tab-group.mat-primary .mat-ink-bar, .indigo-blueGrey.dark .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #9fa8da;
}
.indigo-blueGrey.dark .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .indigo-blueGrey.dark .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .indigo-blueGrey.dark .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .indigo-blueGrey.dark .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-blueGrey.dark .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-blueGrey.dark .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-blueGrey.dark .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .indigo-blueGrey.dark .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-blueGrey.dark .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-blueGrey.dark .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-blueGrey.dark .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(207, 216, 220, 0.3);
}
.indigo-blueGrey.dark .mat-tab-group.mat-accent .mat-ink-bar, .indigo-blueGrey.dark .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #b0bec5;
}
.indigo-blueGrey.dark .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .indigo-blueGrey.dark .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .indigo-blueGrey.dark .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .indigo-blueGrey.dark .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-blueGrey.dark .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-blueGrey.dark .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-blueGrey.dark .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .indigo-blueGrey.dark .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-blueGrey.dark .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-blueGrey.dark .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-blueGrey.dark .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.indigo-blueGrey.dark .mat-tab-group.mat-warn .mat-ink-bar, .indigo-blueGrey.dark .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #e57373;
}
.indigo-blueGrey.dark .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .indigo-blueGrey.dark .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .indigo-blueGrey.dark .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .indigo-blueGrey.dark .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-blueGrey.dark .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-blueGrey.dark .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-blueGrey.dark .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .indigo-blueGrey.dark .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-blueGrey.dark .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-blueGrey.dark .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-blueGrey.dark .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(197, 202, 233, 0.3);
}
.indigo-blueGrey.dark .mat-tab-group.mat-background-primary > .mat-tab-header, .indigo-blueGrey.dark .mat-tab-group.mat-background-primary > .mat-tab-link-container, .indigo-blueGrey.dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .indigo-blueGrey.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .indigo-blueGrey.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .indigo-blueGrey.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #9fa8da;
}
.indigo-blueGrey.dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .indigo-blueGrey.dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .indigo-blueGrey.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .indigo-blueGrey.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .indigo-blueGrey.dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .indigo-blueGrey.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .indigo-blueGrey.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.indigo-blueGrey.dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.indigo-blueGrey.dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.indigo-blueGrey.dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.indigo-blueGrey.dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .indigo-blueGrey.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.indigo-blueGrey.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.indigo-blueGrey.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.indigo-blueGrey.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.indigo-blueGrey.dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .indigo-blueGrey.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.indigo-blueGrey.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.indigo-blueGrey.dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.indigo-blueGrey.dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.indigo-blueGrey.dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .indigo-blueGrey.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.indigo-blueGrey.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.indigo-blueGrey.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.indigo-blueGrey.dark .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-blueGrey.dark .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-blueGrey.dark .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-blueGrey.dark .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .indigo-blueGrey.dark .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-blueGrey.dark .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-blueGrey.dark .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-blueGrey.dark .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(207, 216, 220, 0.3);
}
.indigo-blueGrey.dark .mat-tab-group.mat-background-accent > .mat-tab-header, .indigo-blueGrey.dark .mat-tab-group.mat-background-accent > .mat-tab-link-container, .indigo-blueGrey.dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .indigo-blueGrey.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .indigo-blueGrey.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .indigo-blueGrey.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #b0bec5;
}
.indigo-blueGrey.dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .indigo-blueGrey.dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .indigo-blueGrey.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .indigo-blueGrey.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .indigo-blueGrey.dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .indigo-blueGrey.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .indigo-blueGrey.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.indigo-blueGrey.dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.indigo-blueGrey.dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.indigo-blueGrey.dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.indigo-blueGrey.dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .indigo-blueGrey.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.indigo-blueGrey.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.indigo-blueGrey.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.indigo-blueGrey.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.indigo-blueGrey.dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .indigo-blueGrey.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.indigo-blueGrey.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.indigo-blueGrey.dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.indigo-blueGrey.dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.indigo-blueGrey.dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .indigo-blueGrey.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.indigo-blueGrey.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.indigo-blueGrey.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.indigo-blueGrey.dark .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-blueGrey.dark .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-blueGrey.dark .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-blueGrey.dark .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .indigo-blueGrey.dark .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .indigo-blueGrey.dark .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.indigo-blueGrey.dark .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.indigo-blueGrey.dark .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.indigo-blueGrey.dark .mat-tab-group.mat-background-warn > .mat-tab-header, .indigo-blueGrey.dark .mat-tab-group.mat-background-warn > .mat-tab-link-container, .indigo-blueGrey.dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .indigo-blueGrey.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .indigo-blueGrey.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .indigo-blueGrey.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #e57373;
}
.indigo-blueGrey.dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .indigo-blueGrey.dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .indigo-blueGrey.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .indigo-blueGrey.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .indigo-blueGrey.dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .indigo-blueGrey.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .indigo-blueGrey.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.indigo-blueGrey.dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.indigo-blueGrey.dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.indigo-blueGrey.dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.indigo-blueGrey.dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .indigo-blueGrey.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.indigo-blueGrey.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.indigo-blueGrey.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.indigo-blueGrey.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.indigo-blueGrey.dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .indigo-blueGrey.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.indigo-blueGrey.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.indigo-blueGrey.dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.indigo-blueGrey.dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.indigo-blueGrey.dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .indigo-blueGrey.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.indigo-blueGrey.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.indigo-blueGrey.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.indigo-blueGrey.dark .mat-toolbar {
  background: #212121;
  color: white;
}
.indigo-blueGrey.dark .mat-toolbar.mat-primary {
  background: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark .mat-toolbar.mat-accent {
  background: #b0bec5;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark .mat-toolbar.mat-warn {
  background: #e57373;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark .mat-toolbar .mat-form-field-underline,
.indigo-blueGrey.dark .mat-toolbar .mat-form-field-ripple,
.indigo-blueGrey.dark .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.indigo-blueGrey.dark .mat-toolbar .mat-form-field-label,
.indigo-blueGrey.dark .mat-toolbar .mat-focused .mat-form-field-label,
.indigo-blueGrey.dark .mat-toolbar .mat-select-value,
.indigo-blueGrey.dark .mat-toolbar .mat-select-arrow,
.indigo-blueGrey.dark .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.indigo-blueGrey.dark .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.indigo-blueGrey.dark .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.indigo-blueGrey.dark .mat-tree {
  background: #424242;
}
.indigo-blueGrey.dark .mat-tree-node,
.indigo-blueGrey.dark .mat-nested-tree-node {
  color: white;
}
.indigo-blueGrey.dark .mat-snack-bar-container {
  color: rgba(0, 0, 0, 0.87);
  background: #fafafa;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.indigo-blueGrey.dark .mat-simple-snackbar-action {
  color: inherit;
}
.indigo-blueGrey.dark *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.indigo-blueGrey.dark *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
.indigo-blueGrey.dark *::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}
.indigo-blueGrey.dark .toolbar-line.container-ou {
  background: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark .toolbar-line.container-persona {
  background: #9fa8da !important;
}
.indigo-blueGrey.dark .toolbar-line.container-persona .persona-title {
  background: #9fa8da;
}
.indigo-blueGrey.dark .toolbar-line.container-persona .invisible-container.persona-selector {
  background: #9fa8da !important;
}
.indigo-blueGrey.dark button.mat-icon-button:not([disabled]):hover {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-blueGrey.dark .side-bar {
  background: black;
}
.indigo-blueGrey.dark .icon-stacked {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-blueGrey.dark .icon-stacked mat-icon + mat-icon {
  text-shadow: -1px 1px 0px #9fa8da, 1px -1px 0px #9fa8da;
}
.indigo-blueGrey.dark .divider-vert {
  background: rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark .avatar {
  background: #304ffe !important;
}
.indigo-blueGrey.dark ngx-avatar .avatar-content {
  background: #304ffe !important;
}
.indigo-blueGrey.dark .mat-loading-window {
  background: #424242;
}
.indigo-blueGrey.dark .window-spinner {
  background: #424242;
}
.indigo-blueGrey.dark .load-window {
  background: #424242;
}
@media (min-width: 600px) and (max-width: 1300px) {
  .indigo-blueGrey.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .load-window,
.indigo-blueGrey.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .load-window.new {
    background: #424242 !important;
  }
  .indigo-blueGrey.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card {
    background: #424242 !important;
  }
  .indigo-blueGrey.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .inbox-container mat-toolbar {
    background: #9fa8da;
    color: rgba(0, 0, 0, 0.87);
  }
  .indigo-blueGrey.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .inbox-container mat-list-item.mat-list-item {
    border: 1px solid rgba(255, 255, 255, 0.12);
  }
  .indigo-blueGrey.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .detail {
    background: #424242 !important;
  }
  .indigo-blueGrey.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .detail mat-toolbar {
    background: #424242 !important;
  }
  .indigo-blueGrey.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .main.w-detail + .new-detail-container .load-window {
    background: #303030 !important;
  }
  .indigo-blueGrey.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .main.w-detail + .new-detail-container:before {
    background: rgba(0, 0, 0, 0.6);
  }
  .indigo-blueGrey.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .main.side-open + .side {
    border-left: 1px solid rgba(255, 255, 255, 0.12);
  }
  .indigo-blueGrey.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .side .container .mat-list-base {
    background: #424242 !important;
    border: 1px solid rgba(255, 255, 255, 0.12);
  }
  .indigo-blueGrey.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .new-table .mat-header-row,
.indigo-blueGrey.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .new-table .mat-table,
.indigo-blueGrey.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .document-table .mat-header-row,
.indigo-blueGrey.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .document-table .mat-table,
.indigo-blueGrey.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .status-table .mat-header-row,
.indigo-blueGrey.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .status-table .mat-table {
    background: #424242 !important;
  }
  .indigo-blueGrey.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .audit-filter .filter-container.is-floating {
    background: #303030 !important;
    border: 1px solid rgba(255, 255, 255, 0.12);
  }
  .indigo-blueGrey.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .audit-filter .filter-container.is-floating .form-body {
    background: #303030 !important;
  }
  .indigo-blueGrey.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .audit-result {
    background: #424242;
  }
  .indigo-blueGrey.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .audit-detail-container {
    background: #303030 !important;
  }
}
@media (max-width: 599px) {
  .indigo-blueGrey.dark app-document-detail .mat-toolbar {
    background: #304ffe !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .indigo-blueGrey.dark app-document-detail .mat-toolbar .mat-toolbar-row .toolbar-actions {
    background: #304ffe !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .indigo-blueGrey.dark .login-card {
    background: #424242 !important;
  }
  .indigo-blueGrey.dark .inbox-toolbar {
    background: #304ffe !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .indigo-blueGrey.dark .detail {
    background: #424242 url("../img/spinner_mobile.gif") no-repeat center center !important;
  }
  .indigo-blueGrey.dark .toolbar-line-container .toolbar-line:first-child {
    background: #304ffe !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .indigo-blueGrey.dark .toolbar-actions {
    background: #304ffe !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .indigo-blueGrey.dark mat-drawer.side-nav.enter {
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(255, 255, 255, 0.12) !important;
  }
  .indigo-blueGrey.dark .side-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0) !important;
  }
  .indigo-blueGrey.dark .filter {
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.55) 16%, rgba(0, 0, 0, 0) 100%) !important;
  }
  .indigo-blueGrey.dark button.side-open-mobile {
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .indigo-blueGrey.dark .side-nav .side-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0);
  }
  .indigo-blueGrey.dark .side-nav .filter {
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.55) 16%, rgba(0, 0, 0, 0) 100%);
  }
  .indigo-blueGrey.dark .side-nav .side-nav-container .links {
    background: #424242;
    border-top: 1px solid rgba(255, 255, 255, 0.12);
  }
  .indigo-blueGrey.dark .side-nav .side-nav-container .version {
    background: #424242 !important;
    border-top: 1px solid rgba(255, 255, 255, 0.12);
  }
  .indigo-blueGrey.dark app-file-document-view .view-container {
    background: #424242 !important;
  }
  .indigo-blueGrey.dark app-file-document-view .view-container .side-bar {
    background: #304ffe !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .indigo-blueGrey.dark app-file-document-view .view-container .view-content app-pdf-wrapper.floating {
    background: #424242;
  }
  .indigo-blueGrey.dark app-file-document-view .view-container .view-content .upload-indications {
    background: black;
  }
  .indigo-blueGrey.dark app-file-document-view .view-container .view-content app-file-document-sign .sign-cycle mat-horizontal-stepper.mat-stepper-horizontal {
    background-color: rgba(0, 0, 0, 0) !important;
  }
  .indigo-blueGrey.dark app-file-document-view .view-container .view-content app-file-document-sign .sign-cycle .sign-cycle-content .cycle-content-action .mat-ripple-element {
    background-color: rgba(255, 255, 255, 0.1) !important;
  }
  .indigo-blueGrey.dark app-pending .floating-container .inbox-container .inbox-toolbar .mat-toolbar-row .mat-avatar h3 {
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .indigo-blueGrey.dark app-pending .floating-container .detail {
    background: #424242 url("../img/spinner_mobile.gif") no-repeat center center !important;
  }
  .indigo-blueGrey.dark app-document-sign-massive .inbox-container .pending-container app-chapa.minimized .info-empty {
    background: #b0bec5;
  }
  .indigo-blueGrey.dark app-document-sign-massive .inbox-container .pending-container app-chapa.minimized .info-empty > span h2 {
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .indigo-blueGrey.dark app-document-sign-massive .inbox-container .signer.mobile {
    border-top: 1px solid rgba(255, 255, 255, 0.12);
    background: #424242;
  }
  .indigo-blueGrey.dark app-document-sign-massive .detail app-file-document-detail .view-container .mat-toolbar .mat-toolbar-row .view-actions {
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .indigo-blueGrey.dark app-add-documentation .floating-container .employee-add-documentation .inbox-container .inbox-toolbar .mat-toolbar-row .mat-avatar h3 {
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .indigo-blueGrey.dark app-add-documentation .floating-container .employee-add-documentation .detail {
    background: #424242 url("../img/spinner_mobile.gif") no-repeat center center !important;
  }
  .indigo-blueGrey.dark app-add-documentation .floating-container .employee-add-documentation .detail app-file-document-view .view-container .view-content .new-container app-file-document-upload {
    background: #424242;
  }
}
.indigo-blueGrey.dark .mat-card .mat-drawer-container {
  background: #424242 !important;
}
.indigo-blueGrey.dark .mat-drawer-container {
  background: #212121 !important;
}
.indigo-blueGrey.dark .mat-list .mat-list-item.finished-process-inbox.active {
  background: #009688 !important;
}
.indigo-blueGrey.dark .mat-list .mat-list-item.finished-process-inbox .mat-icon {
  background: #009688;
}
.indigo-blueGrey.dark .mat-list .mat-list-item.erased-process-inbox.active {
  background: #837C80 !important;
}
.indigo-blueGrey.dark .mat-list .mat-list-item.limbo-process-inbox.active {
  background: #ffc107 !important;
}
.indigo-blueGrey.dark .mat-list .mat-list-item.limbo-process-inbox .mat-icon {
  background: #ffc107;
}
.indigo-blueGrey.dark .mat-list .mat-list-item.in-process-inbox.active {
  background: #ffc107 !important;
}
.indigo-blueGrey.dark .mat-list .mat-list-item.in-process-inbox .mat-icon {
  background: #ffc107;
}
.indigo-blueGrey.dark .mat-list .mat-list-item.error-process-inbox.active {
  background: #e91e63 !important;
}
.indigo-blueGrey.dark .mat-list .mat-list-item.error-process-inbox .mat-icon {
  background: #e91e63;
}
.indigo-blueGrey.dark .mat-list .mat-list-item.active .mat-line b {
  color: #9fa8da;
}
.indigo-blueGrey.dark .mat-list .mat-list-item.active .mat-list-avatar {
  color: #9fa8da;
}
.indigo-blueGrey.dark .mat-list .mat-list-item:hover:not(.active) {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-blueGrey.dark span.mat-badge-content {
  color: #e57373 !important;
}
.indigo-blueGrey.dark mat-expansion-panel.mat-expanded {
  background: #303030 !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark mat-expansion-panel.virtual-scrolling .mat-expansion-panel-content .get-more {
  color: #9fa8da;
}
.indigo-blueGrey.dark mat-expansion-panel.virtual-scrolling .mat-expansion-panel-content app-cs-paginator.get-more {
  background: #424242;
}
.indigo-blueGrey.dark mat-expansion-panel-header.mat-expanded {
  background: #424242 !important;
}
.indigo-blueGrey.dark .bottom-container {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark .load-more * {
  color: #9fa8da;
}
.indigo-blueGrey.dark .welcome-card {
  background: #424242 !important;
}
.indigo-blueGrey.dark .welcome-card .img-divider {
  background: #304ffe;
}
.indigo-blueGrey.dark .mat-header-row {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  background: #424242;
}
.indigo-blueGrey.dark .mat-row {
  background: #424242;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.indigo-blueGrey.dark .mat-row .mat-cell.status-cell mat-icon.pending {
  color: #ffb74d !important;
}
.indigo-blueGrey.dark .mat-row .mat-cell.status-cell mat-icon.ok {
  color: #4db6ac !important;
}
.indigo-blueGrey.dark .mat-row .mat-cell.status-cell mat-icon.not-ok {
  color: #ba68c8 !important;
}
.indigo-blueGrey.dark .mat-row .mat-cell.status-cell mat-icon.cancelled {
  color: #795548 !important;
}
.indigo-blueGrey.dark .mat-row .mat-cell.status-cell mat-icon.rejected {
  color: #df4d4c !important;
}
.indigo-blueGrey.dark .mat-row .mat-cell.status-cell mat-icon.draft {
  color: #dedede !important;
}
.indigo-blueGrey.dark .mat-row.active, .indigo-blueGrey.dark .mat-row.active:hover {
  background: #9fa8da !important;
}
.indigo-blueGrey.dark .mat-row.active div, .indigo-blueGrey.dark .mat-row.active:hover div {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark .mat-row.active .mat-cell.status-cell mat-icon.pending, .indigo-blueGrey.dark .mat-row.active .mat-cell.status-cell mat-icon.ok, .indigo-blueGrey.dark .mat-row.active .mat-cell.status-cell mat-icon.not-ok, .indigo-blueGrey.dark .mat-row.active:hover .mat-cell.status-cell mat-icon.pending, .indigo-blueGrey.dark .mat-row.active:hover .mat-cell.status-cell mat-icon.ok, .indigo-blueGrey.dark .mat-row.active:hover .mat-cell.status-cell mat-icon.not-ok {
  text-shadow: -1px 1px 0 rgba(0, 0, 0, 0.87), 1px 1px 0 rgba(0, 0, 0, 0.87), 1px -1px 0 rgba(0, 0, 0, 0.87), -1px -1px 0 rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark .mat-row.active .sticky-actions button, .indigo-blueGrey.dark .mat-row.active:hover .sticky-actions button {
  background: #9fa8da !important;
}
.indigo-blueGrey.dark .mat-row.active .sticky-actions button.ok mat-icon, .indigo-blueGrey.dark .mat-row.active .sticky-actions button.not-ok mat-icon, .indigo-blueGrey.dark .mat-row.active:hover .sticky-actions button.ok mat-icon, .indigo-blueGrey.dark .mat-row.active:hover .sticky-actions button.not-ok mat-icon {
  text-shadow: -1px 1px 0 rgba(0, 0, 0, 0.87), 1px 1px 0 rgba(0, 0, 0, 0.87), 1px -1px 0 rgba(0, 0, 0, 0.87), -1px -1px 0 rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark .mat-row:nth-child(odd) {
  background: #303030;
}
.indigo-blueGrey.dark .mat-row.selected div {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark .mat-row.selected:nth-child(even) {
  background: #9fa8da !important;
}
.indigo-blueGrey.dark .mat-row.selected:nth-child(even) .sticky-actions button {
  background: #9fa8da !important;
}
.indigo-blueGrey.dark .mat-row.selected:nth-child(odd) {
  background: #c5cae9 !important;
}
.indigo-blueGrey.dark .mat-row.selected:nth-child(odd) .sticky-actions button {
  background: #c5cae9 !important;
}
.indigo-blueGrey.dark .mat-row.selected.active, .indigo-blueGrey.dark .mat-row.selected.active:hover {
  background: #9fa8da !important;
}
.indigo-blueGrey.dark .mat-row.selected.active .sticky-actions button, .indigo-blueGrey.dark .mat-row.selected.active:hover .sticky-actions button {
  background: #9fa8da !important;
}
.indigo-blueGrey.dark .mat-row:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-blueGrey.dark .mat-row:hover .sticky-actions button {
  background: #424242 !important;
}
.indigo-blueGrey.dark .mat-row.canceled * {
  color: #795548;
}
.indigo-blueGrey.dark .mat-row.disabled div {
  color: #607D8B;
}
.indigo-blueGrey.dark .mat-row.disabled.active, .indigo-blueGrey.dark .mat-row.disabled.active:hover {
  background: #607D8B !important;
}
.indigo-blueGrey.dark .mat-row.disabled.active div, .indigo-blueGrey.dark .mat-row.disabled.active:hover div {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark .mat-row.disabled.active .sticky-actions button, .indigo-blueGrey.dark .mat-row.disabled.active:hover .sticky-actions button {
  background: #607D8B !important;
}
.indigo-blueGrey.dark .mat-row.disabled.selected.active:hover .sticky-actions button {
  background: #607D8B !important;
}
.indigo-blueGrey.dark .mat-row .sticky-actions button.ok mat-icon {
  color: #4db6ac !important;
}
.indigo-blueGrey.dark .mat-row .sticky-actions button.not-ok mat-icon {
  color: #ba68c8 !important;
}
.indigo-blueGrey.dark .status-table .mat-header-row {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  background: #424242;
}
.indigo-blueGrey.dark .icon-info-off,
.indigo-blueGrey.dark .certificate-off {
  color: #607D8B;
}
.indigo-blueGrey.dark .documents-search-table .mat-header-row {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  background: #424242;
}
.indigo-blueGrey.dark mat-toolbar.employee-search-sub-toolbar {
  background: #424242;
}
.indigo-blueGrey.dark .mat-search-field {
  border: 1px solid rgba(255, 255, 255, 0.12);
  background: black;
}
.indigo-blueGrey.dark .mat-search-field input[type=search] {
  background: rgba(0, 0, 0, 0);
}
.indigo-blueGrey.dark .mat-search-field input[type=search]::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-blueGrey.dark .search-adv-menu .mat-search-field {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark mat-toolbar.document-search-sub-toolbar {
  background: #424242;
}
.indigo-blueGrey.dark app-advanced-employee-search .mat-chip {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-blueGrey.dark .side-nav .side-trigger {
  background: #424242;
}
.indigo-blueGrey.dark .side-nav .menu-trigger mat-icon {
  color: rgba(255, 255, 255, 0.7);
  background: #424242;
}
.indigo-blueGrey.dark .side-nav .side-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0);
}
.indigo-blueGrey.dark .side-nav .filter {
  background: #424242;
}
.indigo-blueGrey.dark .side-nav .side-nav-container .links mat-icon span.mat-badge-content {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-blueGrey.dark .side-nav .side-nav-container .mat-list-base .mat-list-item:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-blueGrey.dark .side-nav .side-nav-container .mat-list-base .mat-list-item.active {
  background: #9fa8da !important;
}
.indigo-blueGrey.dark .side-nav .side-nav-container .mat-list-base .mat-list-item.active * {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark .side-nav .side-nav-container .mat-list-base .mat-list-item.active .mat-list-item.active:hover {
  background: #9fa8da !important;
}
.indigo-blueGrey.dark .side-nav .side-nav-container .mat-list-base .mat-list-item.active .mat-list-item.active:hover * {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark .side-nav.open .side-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark .side-nav.open .filter {
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.55) 16%, rgba(0, 0, 0, 0) 100%);
}
.indigo-blueGrey.dark .side-nav.open .side-nav-container .links {
  background: #424242;
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark .side-nav.open .side-nav-container .version {
  background: #424242 !important;
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark .side-nav.enter .side-nav-container .links {
  background: #424242;
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark .side-nav.enter .side-nav-container .version {
  background: #424242 !important;
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark .mat-menu-panel:not(.metadata-radio-menu) .navigation-list .mat-list-item-content {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-blueGrey.dark table.mat-calendar-table {
  background: #424242 !important;
}
.indigo-blueGrey.dark .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-blueGrey.dark .mat-calendar-arrow {
  border-top-color: #424242 !important;
}
.indigo-blueGrey.dark .mat-datepicker-content .mat-calendar-next-button,
.indigo-blueGrey.dark .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-blueGrey.dark .mat-calendar-header {
  background: #304ffe !important;
}
.indigo-blueGrey.dark mat-datepicker-content {
  background-color: #304ffe !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-blueGrey.dark .mat-calendar-content {
  background: #424242 !important;
}
.indigo-blueGrey.dark .mat-simple-snackbar-action button.mat-button {
  color: #ff9800;
}
.indigo-blueGrey.dark .mat-simple-snackbar-action .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.indigo-blueGrey.dark mat-bottom-sheet-container mat-toolbar {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12) !important;
}
.indigo-blueGrey.dark app-generic-bottom-sheet h4.accent {
  color: #b0bec5;
}
.indigo-blueGrey.dark app-generic-bottom-sheet .verification-code {
  background: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark app-generic-bottom-sheet .verification-code h2 {
  background: #9fa8da;
}
.indigo-blueGrey.dark app-generic-bottom-sheet .load-window.message .info-container h3.primary-text {
  color: #9fa8da;
}
.indigo-blueGrey.dark app-generic-bottom-sheet .bottom_sheet-content.ok .bottom_sheet-title .bottom_sheet-icon,
.indigo-blueGrey.dark app-generic-bottom-sheet .bottom_sheet-content.ok .bottom_sheet-title h3 {
  color: #009688;
}
.indigo-blueGrey.dark app-generic-bottom-sheet .bottom_sheet-content.ok .bottom_sheet-main_btn:not(:disabled) {
  background: #009688;
  color: #fff;
}
.indigo-blueGrey.dark app-generic-bottom-sheet .bottom_sheet-content.not-ok .bottom_sheet-title .bottom_sheet-icon,
.indigo-blueGrey.dark app-generic-bottom-sheet .bottom_sheet-content.not-ok .bottom_sheet-title h3 {
  color: #9C27B0;
}
.indigo-blueGrey.dark app-generic-bottom-sheet .bottom_sheet-content.not-ok .bottom_sheet-main_btn:not(:disabled) {
  background: #9C27B0;
  color: #fff;
}
.indigo-blueGrey.dark app-generic-bottom-sheet .bottom_sheet-content.cancel .bottom_sheet-title .bottom_sheet-icon,
.indigo-blueGrey.dark app-generic-bottom-sheet .bottom_sheet-content.cancel .bottom_sheet-title h3 {
  color: #795548;
}
.indigo-blueGrey.dark app-generic-bottom-sheet .bottom_sheet-content.cancel .bottom_sheet-main_btn:not(:disabled) {
  background: #795548;
  color: #fff;
}
.indigo-blueGrey.dark app-generic-bottom-sheet .bottom_sheet-content .bottom_sheet_exp_btn {
  background-color: #9fa8da;
  color: #fff;
}
.indigo-blueGrey.dark app-generic-bottom-sheet .bottom_sheet-content .bottom_sheet_canc_btn {
  background-color: lightgrey;
  color: black;
}
.indigo-blueGrey.dark app-generic-bottom-sheet .bottom_sheet-content .bottom_sheet-exp-h3 {
  color: #9fa8da;
  margin-bottom: -10px !important;
}
.indigo-blueGrey.dark app-generic-bottom-sheet .bottom_sheet-content .mat-input-element-export {
  display: flex !important;
  padding-left: 15px;
}
.indigo-blueGrey.dark app-generic-bottom-sheet .bottom_sheet-content .mat-form-field-infix-export-right {
  width: 100% !important;
  padding: 0px !important;
  display: flex !important;
}
.indigo-blueGrey.dark app-generic-bottom-sheet .bottom_sheet-content .mat-form-field-infix-export-left {
  width: 100% !important;
  padding: 0px;
  display: flex !important;
}
.indigo-blueGrey.dark app-generic-bottom-sheet .bottom_sheet-content .mat-form-field-infix {
  display: flex;
}
.indigo-blueGrey.dark app-generic-bottom-sheet .bottom_sheet-content .mat-checkbox-layout .mat-checkbox-label {
  color: #b0bec5;
}
.indigo-blueGrey.dark app-generic-bottom-sheet .bottom_sheet-content .choose-forms {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  margin-left: 10px !important;
}
.indigo-blueGrey.dark app-generic-bottom-sheet .bottom_sheet-content .card-container {
  max-height: 150px;
  overflow-y: auto;
}
.indigo-blueGrey.dark .mat-list .mat-list-item.active .mat-line {
  color: white;
}
.indigo-blueGrey.dark .mat-list .mat-list-item.active .mat-line b {
  color: #9fa8da;
}
.indigo-blueGrey.dark .mat-list .mat-list-item.active .mat-list-avatar {
  color: #9fa8da;
}
.indigo-blueGrey.dark .mat-list .mat-list-item .mat-line {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-blueGrey.dark .mat-list .mat-list-item .mat-list-avatar {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-blueGrey.dark .mat-list-item:hover:not(.active) {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-blueGrey.dark mat-list-item:nth-child(even),
.indigo-blueGrey.dark app-file-document-inbox-item:nth-child(even) mat-list-item,
.indigo-blueGrey.dark app-file-document-inbox-item-documentation:nth-child(even) mat-list-item {
  background: #303030 !important;
}
.indigo-blueGrey.dark mat-list-item:nth-child(odd),
.indigo-blueGrey.dark app-file-document-inbox-item:nth-child(odd) mat-list-item,
.indigo-blueGrey.dark app-file-document-inbox-item-documentation:nth-child(odd) mat-list-item {
  background: #424242 !important;
}
.indigo-blueGrey.dark .mat-list-avatar.mat-icon {
  background: black;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark .card-filter {
  background: #424242;
}
.indigo-blueGrey.dark .login mat-horizontal-stepper {
  background: #424242 !important;
}
.indigo-blueGrey.dark .login .login-card {
  background: #424242 !important;
}
.indigo-blueGrey.dark .login .login-card .mat-card-content .mat-card-footer a[color=accent] {
  color: #b0bec5;
}
.indigo-blueGrey.dark .login .help-stepper h3.align-16 {
  color: #b0bec5;
}
.indigo-blueGrey.dark .login .help-stepper .help-options mat-icon {
  color: #b0bec5;
}
.indigo-blueGrey.dark .ux-modal {
  background: rgba(0, 0, 0, 0.32);
}
.indigo-blueGrey.dark .ux-modal .card {
  background: #424242;
}
.indigo-blueGrey.dark .main-title {
  background: #607D8B;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark .sub-title {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-blueGrey.dark .card-footer a {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark .profile-toolbar {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark app-profile .logout-btn:hover {
  background: black;
}
.indigo-blueGrey.dark app-profile .cropper {
  background: #424242;
}
.indigo-blueGrey.dark app-profile .cropper .cropper {
  background: unset;
}
.indigo-blueGrey.dark app-add-documentation .employee-add-documentation .inbox-toolbar {
  background: #303030;
}
.indigo-blueGrey.dark app-add-documentation .employee-add-documentation .inbox-toolbar .toolbar-line mat-icon {
  color: #9fa8da;
}
.indigo-blueGrey.dark app-add-documentation .employee-add-documentation .inbox-toolbar .toolbar-line h3 {
  color: #9fa8da;
}
.indigo-blueGrey.dark app-add-documentation .employee-add-documentation mat-tab-group {
  background: #303030;
}
.indigo-blueGrey.dark app-add-documentation .employee-add-documentation mat-tab-group mat-tab-header .mat-tab-label-active {
  color: #9fa8da;
}
.indigo-blueGrey.dark app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content:hover {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(255, 255, 255, 0.12) !important;
}
.indigo-blueGrey.dark app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content.selected {
  border-left: 6px solid #9fa8da !important;
}
.indigo-blueGrey.dark app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content.selected mat-icon {
  color: #9fa8da !important;
}
.indigo-blueGrey.dark app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content.selected .item-data {
  color: white !important;
}
.indigo-blueGrey.dark app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content mat-icon {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-blueGrey.dark app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content .item-data {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-blueGrey.dark app-add-documentation .employee-add-documentation .detail app-file-document-view .new-container app-file-document-upload .metadata-list .mat-subheader {
  color: rgba(255, 255, 255, 0.7);
  background: #424242 !important;
}
.indigo-blueGrey.dark app-pending .inbox-toolbar {
  background: #303030;
}
.indigo-blueGrey.dark app-pending .pending-container {
  background: #303030;
}
.indigo-blueGrey.dark app-pending .pending-container .pending-items .pending-item .content:hover {
  background: rgba(255, 255, 255, 0.04) !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(255, 255, 255, 0.12) !important;
}
.indigo-blueGrey.dark app-pending .pending-container .pending-items .pending-item .content.selected {
  border-left: 6px solid #b0bec5 !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(255, 255, 255, 0.12) !important;
}
.indigo-blueGrey.dark app-pending .pending-container .pending-items .pending-item .content.selected mat-icon {
  color: #b0bec5 !important;
}
.indigo-blueGrey.dark app-pending .pending-container .pending-items .pending-item .content.selected .item-data {
  color: white !important;
}
.indigo-blueGrey.dark app-pending .pending-container .pending-items .pending-item .content mat-icon {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-blueGrey.dark app-pending .pending-container .pending-items .pending-item .content .item-data {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-blueGrey.dark app-document-sign-massive .inbox-container .inbox-toolbar {
  background: #303030;
}
.indigo-blueGrey.dark app-document-sign-massive .inbox-container .pending-container {
  background: #303030;
}
.indigo-blueGrey.dark app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content:hover {
  background: rgba(255, 255, 255, 0.04) !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(255, 255, 255, 0.12) !important;
}
.indigo-blueGrey.dark app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content.selected {
  border-left: 6px solid #b0bec5 !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(255, 255, 255, 0.12) !important;
}
.indigo-blueGrey.dark app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content.selected mat-icon {
  color: #b0bec5 !important;
}
.indigo-blueGrey.dark app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content.selected .item-data {
  color: white !important;
}
.indigo-blueGrey.dark app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content mat-icon {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-blueGrey.dark app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content .item-data {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-blueGrey.dark app-document-sign-massive .detail app-file-document-detail .view-container {
  background: #607D8B;
}
.indigo-blueGrey.dark app-document-sign-massive .detail app-file-document-detail .view-container .mat-toolbar {
  background: #607D8B;
  color: rgba(0, 0, 0, 0.87);
  background: #607D8B;
}
.indigo-blueGrey.dark app-document-sign-massive .detail app-chapa.minimized .info-empty {
  background: #b0bec5;
}
.indigo-blueGrey.dark app-document-sign-massive .detail app-chapa.minimized .info-empty > span mat-icon {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-blueGrey.dark app-document-sign-massive .detail app-chapa.minimized .info-empty > span h2 {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-blueGrey.dark app-document-sign-massive .signer.desktop {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark app-document-sign-massive .signer .signer-btn {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-blueGrey.dark app-document-sign-massive .signer .signer-btn[disabled] {
  background-color: rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark app-document-detail app-sign-document .firmador button.mat-raised-button.mat-accent {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-blueGrey.dark mat-toolbar-row.firmado-conforme .mat-icon {
  background: #4caf50;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark mat-toolbar-row.firmado-no-conforme .mat-icon {
  background: #e57373;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark .secondary-toolbar {
  background: #424242;
}
.indigo-blueGrey.dark .new-detail-container {
  border-left: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark .new-detail-container.cancelled .detail_head-body {
  background: #607D8B !important;
}
.indigo-blueGrey.dark .new-detail-container .employee-detail-toolbar {
  background: black;
}
.indigo-blueGrey.dark .new-detail-container .employee-detail-subbar {
  background: #424242;
}
.indigo-blueGrey.dark app-employee-detail .load-window,
.indigo-blueGrey.dark app-employee-rrhh-detail .load-window {
  background: #303030;
}
.indigo-blueGrey.dark app-employee-detail .employee_detail-container,
.indigo-blueGrey.dark app-employee-rrhh-detail .employee_detail-container {
  background: #303030;
}
.indigo-blueGrey.dark app-employee-detail .employee_detail-container.is-editing .edition-line,
.indigo-blueGrey.dark app-employee-rrhh-detail .employee_detail-container.is-editing .edition-line {
  background: black;
}
.indigo-blueGrey.dark app-employee-detail .employee_detail-container .detail_head-body,
.indigo-blueGrey.dark app-employee-rrhh-detail .employee_detail-container .detail_head-body {
  background: #9fa8da;
}
.indigo-blueGrey.dark app-employee-detail .employee_detail-container .detail_head-body .head_body-status .icon-stacked + mat-icon,
.indigo-blueGrey.dark app-employee-rrhh-detail .employee_detail-container .detail_head-body .head_body-status .icon-stacked + mat-icon {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-blueGrey.dark app-employee-detail .employee_detail-container .detail_head-body .head_body-title .body_title-name,
.indigo-blueGrey.dark app-employee-rrhh-detail .employee_detail-container .detail_head-body .head_body-title .body_title-name {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark app-employee-detail .employee_detail-container .detail_head-body .head_body-title .body_title-user,
.indigo-blueGrey.dark app-employee-rrhh-detail .employee_detail-container .detail_head-body .head_body-title .body_title-user {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark app-employee-detail .employee_detail-container .data .data-line,
.indigo-blueGrey.dark app-employee-rrhh-detail .employee_detail-container .data .data-line {
  background: #424242;
}
.indigo-blueGrey.dark app-employee-detail .employee_detail-container .data .data-line .icon-complex mat-icon.complex,
.indigo-blueGrey.dark app-employee-rrhh-detail .employee_detail-container .data .data-line .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #424242, 0 -2px 0px #424242, 2px 0 0px #424242, 0 2px 0px #424242, -2px -2px 0px #424242, 2px 2px 0px #424242, 2px -2px 0px #424242, -2px 2px 0px #424242;
}
.indigo-blueGrey.dark app-employee-detail .employee_detail-container .data .data-line .line_text-main,
.indigo-blueGrey.dark app-employee-rrhh-detail .employee_detail-container .data .data-line .line_text-main {
  color: #9fa8da;
}
.indigo-blueGrey.dark app-employee-detail .employee_detail-container .data .data-line.clickable:hover,
.indigo-blueGrey.dark app-employee-rrhh-detail .employee_detail-container .data .data-line.clickable:hover {
  background: #303030 !important;
}
.indigo-blueGrey.dark app-employee-detail .employee_detail-container .data .data-line button.mat-icon-button,
.indigo-blueGrey.dark app-employee-rrhh-detail .employee_detail-container .data .data-line button.mat-icon-button {
  background: black;
}
.indigo-blueGrey.dark app-employee-detail .employee_detail-container .employee-title .title-text,
.indigo-blueGrey.dark app-employee-rrhh-detail .employee_detail-container .employee-title .title-text {
  color: #9fa8da;
}
.indigo-blueGrey.dark app-employee-detail .employee_detail-container .employee-title .title-text mat-icon,
.indigo-blueGrey.dark app-employee-rrhh-detail .employee_detail-container .employee-title .title-text mat-icon {
  color: #9fa8da;
}
.indigo-blueGrey.dark app-employee-detail .employee_detail-container .employee-title .title-text .title_text-main,
.indigo-blueGrey.dark app-employee-rrhh-detail .employee_detail-container .employee-title .title-text .title_text-main {
  color: #9fa8da;
}
.indigo-blueGrey.dark .UI-V2 .icon-complex mat-icon.complex {
  color: #9fa8da;
  text-shadow: -2px 0 0px #424242, 0 -2px 0px #424242, 2px 0 0px #424242, 0 2px 0px #424242, -2px -2px 0px #424242, 2px 2px 0px #424242, 2px -2px 0px #424242, -2px 2px 0px #424242;
}
.indigo-blueGrey.dark .UI-V2 .icon-complex mat-icon.complex.mat-accent {
  color: #b0bec5;
}
.indigo-blueGrey.dark .UI-V2 .main .toolbar .header .content .module-title {
  color: #9fa8da;
}
.indigo-blueGrey.dark .UI-V2 .main .toolbar .header .content .module-title.selectable:hover {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-blueGrey.dark .UI-V2 .main .toolbar .header .content .module-title .info h5 {
  color: white !important;
}
.indigo-blueGrey.dark .UI-V2 .main .toolbar .header .content .ou-select {
  color: rgba(0, 0, 0, 0.87);
  background: #9fa8da;
}
.indigo-blueGrey.dark .UI-V2 .main .toolbar .search-container .search .content {
  background: #303030;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark .UI-V2 .main .toolbar .search-container .search .content:hover, .indigo-blueGrey.dark .UI-V2 .main .toolbar .search-container .search .content:active, .indigo-blueGrey.dark .UI-V2 .main .toolbar .search-container .search .content:focus, .indigo-blueGrey.dark .UI-V2 .main .toolbar .search-container .search .content:focus-within {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-blueGrey.dark .UI-V2 .main .toolbar .search-container .search .content input {
  color: white;
}
.indigo-blueGrey.dark .UI-V2 .main .helper {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark .UI-V2 .main .helper mat-hint h4 {
  color: #9fa8da;
}
.indigo-blueGrey.dark .UI-V2 .main .new-table app-cs-grid-control datatable-header * {
  color: #9fa8da;
}
.indigo-blueGrey.dark .UI-V2 .main .new-table .mat-header-row.sticky * {
  color: #9fa8da !important;
}
.indigo-blueGrey.dark .UI-V2 .main .main-action {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark .menu-options.help .primary {
  color: #9fa8da;
}
.indigo-blueGrey.dark .menu-options.help .accent {
  color: #b0bec5;
}
.indigo-blueGrey.dark .menu-options .icon-complex mat-icon.complex {
  color: #9fa8da;
  text-shadow: -2px 0 0px #424242, 0 -2px 0px #424242, 2px 0 0px #424242, 0 2px 0px #424242, -2px -2px 0px #424242, 2px 2px 0px #424242, 2px -2px 0px #424242, -2px 2px 0px #424242;
}
.indigo-blueGrey.dark .menu-options .mat-menu-content .container mat-list .mat-list-item:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-blueGrey.dark .menu-options .mat-menu-content .container mat-list .mat-list-item .disabled {
  color: rgba(255, 255, 255, 0.7) !important;
}
.indigo-blueGrey.dark .menu-options .mat-menu-content .container .slide-group mat-radio-group mat-radio-button:hover {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-blueGrey.dark .menu-options .mat-menu-content .container .slide-group mat-form-field .mat-form-field-flex {
  background: #303030;
}
.indigo-blueGrey.dark .menu-options .mat-menu-content .container .slide-group mat-slide-toggle:hover {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-blueGrey.dark .mat-menu-panel.notifications-panel .mat-menu-content .notifications-body {
  background: #303030;
}
.indigo-blueGrey.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification .primary mat-icon {
  color: #9fa8da;
}
.indigo-blueGrey.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification .primary h3 {
  color: #9fa8da;
}
.indigo-blueGrey.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.top {
  background: #303030;
}
.indigo-blueGrey.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.top .header h3 {
  color: white;
}
.indigo-blueGrey.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.bottom {
  background: #303030;
}
.indigo-blueGrey.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable {
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable:nth-child(even) {
  background: #303030;
}
.indigo-blueGrey.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable:nth-child(odd) {
  background: #424242;
}
.indigo-blueGrey.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable:hover {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-blueGrey.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable mat-icon {
  color: #9fa8da;
}
.indigo-blueGrey.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable h3 {
  color: white;
}
.indigo-blueGrey.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable h5 {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-blueGrey.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable .subtitle h5 {
  color: #9fa8da;
  background: black;
}
.indigo-blueGrey.dark .info-empty .large-icon {
  background: #7986cb;
  color: #9fa8da;
}
.indigo-blueGrey.dark .info-empty h2,
.indigo-blueGrey.dark .info-empty h3 {
  color: #9fa8da;
}
.indigo-blueGrey.dark .info-empty h4,
.indigo-blueGrey.dark .info-empty h5 {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-blueGrey.dark .info-empty.accent mat-icon {
  background: #b0bec5;
  color: #b0bec5;
}
.indigo-blueGrey.dark .info-empty.accent h2 {
  color: #b0bec5;
}
.indigo-blueGrey.dark .info-empty .actions {
  padding: 16px;
  height: 96px;
  column-gap: 16px;
}
.indigo-blueGrey.dark .info-empty .actions button {
  height: 100%;
  border-radius: 40px;
}
.indigo-blueGrey.dark .info-empty .actions button.ok {
  background: #4db6ac !important;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark .info-empty .actions button.not-ok {
  color: #ba68c8 !important;
}
.indigo-blueGrey.dark .side.edition .info-empty .large-icon {
  background: #b0bec5;
  color: #b0bec5;
}
.indigo-blueGrey.dark .side.edition .info-empty h2 {
  color: #b0bec5;
}
.indigo-blueGrey.dark .side.edition .info-empty h4 {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-blueGrey.dark .UI-V2 .deferred-process-list .header h3 {
  color: #9fa8da;
}
.indigo-blueGrey.dark .UI-V2 .deferred-process-list .search-container .search .content {
  background: #303030;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark .UI-V2 .deferred-process-list .search-container .search .content:hover, .indigo-blueGrey.dark .UI-V2 .deferred-process-list .search-container .search .content:active, .indigo-blueGrey.dark .UI-V2 .deferred-process-list .search-container .search .content:focus, .indigo-blueGrey.dark .UI-V2 .deferred-process-list .search-container .search .content:focus-within {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-blueGrey.dark .UI-V2 .deferred-process-list .search-container .search .content input {
  color: white;
}
.indigo-blueGrey.dark .UI-V2 .deferred-process-list .text-success,
.indigo-blueGrey.dark .UI-V2 .deferred-process-details .text-success {
  color: #4caf50;
}
.indigo-blueGrey.dark .UI-V2 .deferred-process-list .text-warn,
.indigo-blueGrey.dark .UI-V2 .deferred-process-details .text-warn {
  color: #e57373;
}
.indigo-blueGrey.dark .UI-V2 .deferred-process-list .text-primary,
.indigo-blueGrey.dark .UI-V2 .deferred-process-details .text-primary {
  color: #9fa8da;
}
.indigo-blueGrey.dark .UI-V2 .deferred-process-list .text-atention,
.indigo-blueGrey.dark .UI-V2 .deferred-process-details .text-atention {
  color: #DB7703;
}
.indigo-blueGrey.dark .UI-V2 .deferred-process-list .table-paginator-container,
.indigo-blueGrey.dark .UI-V2 .deferred-process-details .table-paginator-container {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
}
.indigo-blueGrey.dark .UI-V2 .deferred-process-list .table-paginator-container .mat-table,
.indigo-blueGrey.dark .UI-V2 .deferred-process-details .table-paginator-container .mat-table {
  border-bottom: none;
  border-radius: 10px 10px 0 0;
}
.indigo-blueGrey.dark .UI-V2 .deferred-process-list .table-paginator-container .paginator-container,
.indigo-blueGrey.dark .UI-V2 .deferred-process-details .table-paginator-container .paginator-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: #f2f2f2;
  padding: 0 1.5rem;
  height: 3rem;
}
.indigo-blueGrey.dark .UI-V2 .deferred-process-list .mat-table,
.indigo-blueGrey.dark .UI-V2 .deferred-process-details .mat-table {
  border: 1px solid #f2f2f2;
  border-radius: 10px;
  overflow: hidden;
}
.indigo-blueGrey.dark .UI-V2 .deferred-process-list .mat-table .mat-header-row .mat-header-cell,
.indigo-blueGrey.dark .UI-V2 .deferred-process-details .mat-table .mat-header-row .mat-header-cell {
  font-size: 14px;
  font-weight: 700;
}
.indigo-blueGrey.dark .UI-V2 .deferred-process-list .mat-table .mat-header-row,
.indigo-blueGrey.dark .UI-V2 .deferred-process-details .mat-table .mat-header-row {
  background: #f2f2f2;
}
.indigo-blueGrey.dark .UI-V2 .deferred-process-list .mat-table .mat-header-row .mat-header-cell,
.indigo-blueGrey.dark .UI-V2 .deferred-process-details .mat-table .mat-header-row .mat-header-cell {
  color: #9fa8da;
}
.indigo-blueGrey.dark .UI-V2 .deferred-process-list .mat-table .mat-row,
.indigo-blueGrey.dark .UI-V2 .deferred-process-details .mat-table .mat-row {
  background: #424242;
}
.indigo-blueGrey.dark .UI-V2 .progress-bar-container {
  position: relative;
}
.indigo-blueGrey.dark .UI-V2 .deferred-process-details .header h3 {
  color: #9fa8da;
}
.indigo-blueGrey.dark .UI-V2 .deferred-process-details .header .back-link:visited {
  color: #9fa8da;
}
.indigo-blueGrey.dark .process-card,
.indigo-blueGrey.dark .process-card-detail,
.indigo-blueGrey.dark .process-card-stages {
  background: #303030;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark .process-card mat-card-subtitle,
.indigo-blueGrey.dark .process-card-detail mat-card-subtitle,
.indigo-blueGrey.dark .process-card-stages mat-card-subtitle {
  color: white;
}
.indigo-blueGrey.dark .process-card .stages-container .stage.completed mat-icon,
.indigo-blueGrey.dark .process-card-detail .stages-container .stage.completed mat-icon,
.indigo-blueGrey.dark .process-card-stages .stages-container .stage.completed mat-icon {
  color: #499719;
}
.indigo-blueGrey.dark .process-card .stages-container .stage.completed .icon-container,
.indigo-blueGrey.dark .process-card-detail .stages-container .stage.completed .icon-container,
.indigo-blueGrey.dark .process-card-stages .stages-container .stage.completed .icon-container {
  background-color: rgba(78, 160, 28, 0.1490196078);
}
.indigo-blueGrey.dark .process-card .stages-container .stage.completed .line,
.indigo-blueGrey.dark .process-card-detail .stages-container .stage.completed .line,
.indigo-blueGrey.dark .process-card-stages .stages-container .stage.completed .line {
  background-color: #499719;
}
.indigo-blueGrey.dark .process-card .stages-container .stage.on-going mat-icon,
.indigo-blueGrey.dark .process-card-detail .stages-container .stage.on-going mat-icon,
.indigo-blueGrey.dark .process-card-stages .stages-container .stage.on-going mat-icon {
  color: #9fa8da;
}
.indigo-blueGrey.dark .process-card .stages-container .stage.on-going .icon-container,
.indigo-blueGrey.dark .process-card-detail .stages-container .stage.on-going .icon-container,
.indigo-blueGrey.dark .process-card-stages .stages-container .stage.on-going .icon-container {
  background-color: rgba(66, 48, 159, 0.2);
}
.indigo-blueGrey.dark .process-card .stages-container .stage.on-going .line,
.indigo-blueGrey.dark .process-card-detail .stages-container .stage.on-going .line,
.indigo-blueGrey.dark .process-card-stages .stages-container .stage.on-going .line {
  background-color: #949494;
}
.indigo-blueGrey.dark .process-card .stages-container .stage.pending,
.indigo-blueGrey.dark .process-card-detail .stages-container .stage.pending,
.indigo-blueGrey.dark .process-card-stages .stages-container .stage.pending {
  color: #949494;
}
.indigo-blueGrey.dark .process-card .stages-container .stage.pending mat-icon,
.indigo-blueGrey.dark .process-card-detail .stages-container .stage.pending mat-icon,
.indigo-blueGrey.dark .process-card-stages .stages-container .stage.pending mat-icon {
  color: #949494;
}
.indigo-blueGrey.dark .process-card .stages-container .stage.pending .icon-container,
.indigo-blueGrey.dark .process-card-detail .stages-container .stage.pending .icon-container,
.indigo-blueGrey.dark .process-card-stages .stages-container .stage.pending .icon-container {
  background-color: rgba(174, 174, 174, 0.2);
}
.indigo-blueGrey.dark .process-card .stages-container .stage.pending .line,
.indigo-blueGrey.dark .process-card-detail .stages-container .stage.pending .line,
.indigo-blueGrey.dark .process-card-stages .stages-container .stage.pending .line {
  background-color: #949494;
}
.indigo-blueGrey.dark .process-card .stages-container .stage.has-error mat-icon,
.indigo-blueGrey.dark .process-card-detail .stages-container .stage.has-error mat-icon,
.indigo-blueGrey.dark .process-card-stages .stages-container .stage.has-error mat-icon {
  color: #e57373;
}
.indigo-blueGrey.dark .process-card .stages-container .stage.has-error .icon-container,
.indigo-blueGrey.dark .process-card-detail .stages-container .stage.has-error .icon-container,
.indigo-blueGrey.dark .process-card-stages .stages-container .stage.has-error .icon-container {
  background-color: rgba(188, 4, 4, 0.2);
}
.indigo-blueGrey.dark .process-card .stages-container .stage-description .button-link,
.indigo-blueGrey.dark .process-card-detail .stages-container .stage-description .button-link,
.indigo-blueGrey.dark .process-card-stages .stages-container .stage-description .button-link {
  color: #9fa8da;
}
.indigo-blueGrey.dark app-custom-search-input .custom-search-container {
  background: #303030;
  border: 1px solid rgba(255, 255, 255, 0.12);
  transition: color 0.1s ease, background 0.15s ease;
}
.indigo-blueGrey.dark app-custom-search-input .custom-search-container mat-icon.mat-icon {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-blueGrey.dark app-custom-search-input .custom-search-container input.custom-search-input {
  color: white;
  background: unset;
}
.indigo-blueGrey.dark app-custom-search-input .custom-search-container:hover, .indigo-blueGrey.dark app-custom-search-input .custom-search-container:active, .indigo-blueGrey.dark app-custom-search-input .custom-search-container:focus, .indigo-blueGrey.dark app-custom-search-input .custom-search-container:focus-within {
  background: rgba(255, 255, 255, 0.04) !important;
  transition: background 0.15s ease;
}
.indigo-blueGrey.dark .UI-V2 .audit-filter {
  background: #424242;
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark .UI-V2 .audit-filter .toolbar {
  background: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark .UI-V2 .audit-filter .toolbar .header .content .module-title {
  color: #9fa8da;
}
.indigo-blueGrey.dark .UI-V2 .audit-filter .toolbar .header .content .module-title.selectable:hover {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-blueGrey.dark .UI-V2 .audit-filter .toolbar .header .content .module-title .info * {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-blueGrey.dark .UI-V2 .audit-filter .toolbar .header .content .ou-select {
  color: rgba(0, 0, 0, 0.87);
  background: #9fa8da;
}
.indigo-blueGrey.dark .UI-V2 .audit-filter .toolbar .search-container .search .content {
  background: #303030;
}
.indigo-blueGrey.dark .UI-V2 .audit-filter .toolbar .search-container .search .content:hover, .indigo-blueGrey.dark .UI-V2 .audit-filter .toolbar .search-container .search .content:active, .indigo-blueGrey.dark .UI-V2 .audit-filter .toolbar .search-container .search .content:focus, .indigo-blueGrey.dark .UI-V2 .audit-filter .toolbar .search-container .search .content:focus-within {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-blueGrey.dark .UI-V2 .audit-filter .toolbar .search-container .search .content input {
  color: white;
}
.indigo-blueGrey.dark .UI-V2 .audit-filter .filter-container {
  background: #424242 !important;
}
.indigo-blueGrey.dark .UI-V2 .audit-filter .filter-container .form-body {
  background: #303030 !important;
}
.indigo-blueGrey.dark .UI-V2 .audit-filter .filter-container .form-body .line h4 {
  color: #9fa8da;
}
.indigo-blueGrey.dark .UI-V2 .audit-filter .filter-container .form-body .line mat-icon {
  color: #9fa8da;
}
.indigo-blueGrey.dark .UI-V2 .audit-result .result-container .toolbar {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark .UI-V2 .audit-result .result-container .toolbar .header .content .module-title {
  color: #9fa8da;
}
.indigo-blueGrey.dark .UI-V2 .audit-result .result-container .toolbar .header .content .module-title.selectable:hover {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-blueGrey.dark .UI-V2 .audit-result .result-container .toolbar .header .content .module-title .info h5 {
  color: white !important;
}
.indigo-blueGrey.dark .UI-V2 .audit-result .result-container .toolbar .header .content .ou-select {
  color: rgba(0, 0, 0, 0.87);
  background: #9fa8da;
}
.indigo-blueGrey.dark .UI-V2 .audit-result .result-container .toolbar .search-container .search .content {
  background: #303030;
}
.indigo-blueGrey.dark .UI-V2 .audit-result .result-container .toolbar .search-container .search .content:hover, .indigo-blueGrey.dark .UI-V2 .audit-result .result-container .toolbar .search-container .search .content:active, .indigo-blueGrey.dark .UI-V2 .audit-result .result-container .toolbar .search-container .search .content:focus, .indigo-blueGrey.dark .UI-V2 .audit-result .result-container .toolbar .search-container .search .content:focus-within {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-blueGrey.dark .UI-V2 .audit-result .result-container .toolbar .search-container .search .content input {
  color: white;
}
.indigo-blueGrey.dark .UI-V2 .audit-result .result-container .new-table app-cs-grid-control datatable-header * {
  color: #9fa8da;
}
.indigo-blueGrey.dark .UI-V2 .audit-result .result-container .new-table .mat-header-row.sticky * {
  color: #9fa8da !important;
}
.indigo-blueGrey.dark .UI-V2 .audit-detail-container {
  border-left: 1px solid rgba(255, 255, 255, 0.12);
  background: black !important;
}
.indigo-blueGrey.dark .UI-V2 .audit-detail-container .side .toolbar .header {
  color: #9fa8da;
}
.indigo-blueGrey.dark .UI-V2 .audit-detail-container .side .container .mat-list-base {
  background: #303030 !important;
}
.indigo-blueGrey.dark .UI-V2 .audit-detail-container .side .container .mat-list-base mat-list-item .mat-line b {
  color: #9fa8da;
}
.indigo-blueGrey.dark .UI-V2 .inbox-container {
  background: #424242;
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark .UI-V2 .inbox-container *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.indigo-blueGrey.dark .UI-V2 .inbox-container *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
.indigo-blueGrey.dark .UI-V2 .inbox-container *::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}
.indigo-blueGrey.dark .UI-V2 .inbox-container .load-window {
  background: #424242;
}
.indigo-blueGrey.dark .UI-V2 .inbox-container .toolbar {
  background: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark .UI-V2 .inbox-container .toolbar.reverse {
  background: #424242;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel {
  background: black !important;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel.mat-expanded {
  background: #303030 !important;
}
.indigo-blueGrey.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel.mat-expanded mat-expansion-panel-header {
  background: #303030 !important;
}
.indigo-blueGrey.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel.mat-expanded mat-expansion-panel-header:hover {
  background: none !important;
}
.indigo-blueGrey.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel mat-expansion-panel-header {
  background: #303030 !important;
}
.indigo-blueGrey.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel mat-expansion-panel-header:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-blueGrey.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel mat-expansion-panel-header .signature-inbox-header .mat-icon,
.indigo-blueGrey.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel mat-expansion-panel-header .signature-inbox-header b {
  color: #b0bec5;
}
.indigo-blueGrey.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel .mat-expansion-panel-body .item {
  background: #424242;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel .mat-expansion-panel-body .item:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-blueGrey.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel .mat-expansion-panel-body .item.active {
  border-left: 6px solid #b0bec5;
}
.indigo-blueGrey.dark .UI-V2 .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.indigo-blueGrey.dark .UI-V2 .work-space .main .load-window {
  background: #424242 !important;
}
.indigo-blueGrey.dark .UI-V2 .work-space .main-action {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark mat-radio-button.disabled .mat-radio-label-content {
  color: #b0bec5;
}
.indigo-blueGrey.dark .detail app-automatic-process-detail app-chapa.minimize,
.indigo-blueGrey.dark .detail app-process-detail app-chapa.minimize {
  background: #ffc107 !important;
}
.indigo-blueGrey.dark .detail app-automatic-process-detail app-chapa.minimize.purged,
.indigo-blueGrey.dark .detail app-process-detail app-chapa.minimize.purged {
  background: #b2adb0 !important;
}
.indigo-blueGrey.dark .detail .detail-toolbar {
  background: black;
}
.indigo-blueGrey.dark .detail .processes-status .status-bar .bar-graph {
  background-color: #ffc107;
}
.indigo-blueGrey.dark .detail .processes-status .status-bar .bar-graph .graph-ok {
  background-color: #009688;
}
.indigo-blueGrey.dark .detail .processes-status .status-bar .bar-graph .graph-not-ok {
  background-color: #e91e63;
}
.indigo-blueGrey.dark .detail .processes-status .status-resume b {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark .detail .processes-status .status-resume .resume-total b {
  background-color: #ffc107;
}
.indigo-blueGrey.dark .detail .processes-status .status-resume .resume-ok b {
  background-color: #009688;
}
.indigo-blueGrey.dark .detail .processes-status .status-resume .resume-not-ok b {
  background-color: #e91e63;
}
.indigo-blueGrey.dark .detail .detail-toolbar .mat-avatar h3,
.indigo-blueGrey.dark .detail .detail-toolbar .mat-avatar h5 {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark .detail .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark .detail .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark .detail .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark .detail .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark .detail .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  background: #009688 !important;
}
.indigo-blueGrey.dark .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  background: #b2dfdb !important;
}
.indigo-blueGrey.dark .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  background: #b2dfdb !important;
}
.indigo-blueGrey.dark .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  background: #009688 !important;
}
.indigo-blueGrey.dark .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  background: #b2dfdb !important;
}
.indigo-blueGrey.dark .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  background: #837C80 !important;
}
.indigo-blueGrey.dark .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  background: #b2adb0 !important;
}
.indigo-blueGrey.dark .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  background: #b2adb0 !important;
}
.indigo-blueGrey.dark .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  background: #837C80 !important;
}
.indigo-blueGrey.dark .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  background: #b2dfdb !important;
}
.indigo-blueGrey.dark .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  background: #ffc107 !important;
}
.indigo-blueGrey.dark .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  background: #ffecb3 !important;
}
.indigo-blueGrey.dark .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  background: #ffecb3 !important;
}
.indigo-blueGrey.dark .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  background: #ffc107 !important;
}
.indigo-blueGrey.dark .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  background: #ffecb3 !important;
}
.indigo-blueGrey.dark .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  background: #e91e63 !important;
}
.indigo-blueGrey.dark .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  background: #f8bbd0 !important;
}
.indigo-blueGrey.dark .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  background: #f8bbd0 !important;
}
.indigo-blueGrey.dark .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  background: #e91e63 !important;
}
.indigo-blueGrey.dark .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  background: #f8bbd0 !important;
}
.indigo-blueGrey.dark app-analytics-document-set .sets-container .mat-list-base.sets-list mat-list-item {
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark app-analytics-document-set .sets-container .mat-list-base.sets-list mat-list-item .mat-list-item-content .set-status .value-percentile .undone-value {
  background: #673AB7;
}
.indigo-blueGrey.dark app-analytics-document-set .sets-container .mat-list-base.sets-list mat-list-item .mat-list-item-content .set-status .value-percentile .not-value {
  background: black;
}
.indigo-blueGrey.dark app-analytics-document-set .sets-container .mat-list-base.sets-list mat-list-item .mat-list-item-content .set-status .value-percentile .done-value {
  background: #009688;
}
.indigo-blueGrey.dark app-analytics-document-set .work-space .main .toolbar .header .content .module-title .info .info-text .set-status {
  cursor: help;
}
.indigo-blueGrey.dark app-analytics-document-set .work-space .main .toolbar .header .content .module-title .info .info-text .set-status .value-percentile .undone-value {
  background: #673AB7;
}
.indigo-blueGrey.dark app-analytics-document-set .work-space .main .toolbar .header .content .module-title .info .info-text .set-status .value-percentile .not-value {
  background: black;
}
.indigo-blueGrey.dark app-analytics-document-set .work-space .main .toolbar .header .content .module-title .info .info-text .set-status .value-percentile .done-value {
  background: #009688;
}
.indigo-blueGrey.dark app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar .mat-progress-bar-buffer {
  background-color: black;
}
.indigo-blueGrey.dark app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar.class1 .mat-progress-bar-fill::after {
  background-color: #f44336;
}
.indigo-blueGrey.dark app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar.class2 .mat-progress-bar-fill::after {
  background-color: #ff9800;
}
.indigo-blueGrey.dark app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar.class3 .mat-progress-bar-fill::after {
  background-color: #ffeb3b;
}
.indigo-blueGrey.dark app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar.class4 .mat-progress-bar-fill::after {
  background-color: #8bc34a;
}
.indigo-blueGrey.dark app-analytics-document-set .work-space .side .container mat-list {
  background: #424242 !important;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark app-analytics-document-set .work-space .side .container mat-list mat-list-item .mat-line b {
  color: #9fa8da;
}
.indigo-blueGrey.dark .catcher {
  background: rgba(0, 0, 0, 0.6);
}
.indigo-blueGrey.dark .catcher.clear {
  background: rgba(0, 0, 0, 0);
}
.indigo-blueGrey.dark .add-menu-form-body .mat-radio-group .mat-radio-button:hover {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-blueGrey.dark .add-menu-form-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b {
  color: white;
}
.indigo-blueGrey.dark .add-menu-form-body.w-table .helper .search-bar {
  background: #303030;
}
.indigo-blueGrey.dark .add-menu-form-body.w-table .helper .search-bar:hover, .indigo-blueGrey.dark .add-menu-form-body.w-table .helper .search-bar:active, .indigo-blueGrey.dark .add-menu-form-body.w-table .helper .search-bar:focus, .indigo-blueGrey.dark .add-menu-form-body.w-table .helper .search-bar:focus-within {
  background: #303030 !important;
}
.indigo-blueGrey.dark .add-menu-form-body.w-table .helper .search-bar input {
  color: white;
}
.indigo-blueGrey.dark .add-menu-form-actions {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark .file-upload-list mat-list-item {
  background: #424242;
}
.indigo-blueGrey.dark .mat-horizontal-stepper-content[aria-expanded=true] app-file-document-view {
  border-left: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark .mat-horizontal-stepper-content[aria-expanded=true] app-file-document-view .pdf-view-container {
  background: black;
}
.indigo-blueGrey.dark .add-docs-drop .docs-drop-preview app-file-document-detail {
  border-left: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark .add-docs-drop .docs-drop-preview app-file-document-detail .view-toolbar {
  background: #424242;
}
.indigo-blueGrey.dark .add-docs-drop .load-window.message h3.primary-text {
  color: #9fa8da;
}
.indigo-blueGrey.dark mat-dialog-container app-add-menu-dialog .load-window.message h3.primary-text {
  color: #9fa8da;
}
.indigo-blueGrey.dark .asisted-input.aligned-icon.subtitle * {
  color: rgba(255, 255, 255, 0.5) !important;
}
.indigo-blueGrey.dark .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble {
  background: #9fa8da;
}
.indigo-blueGrey.dark .asisted-input .mat-form-field-invalid + .indicator > .bubble {
  background: #e57373;
}
.indigo-blueGrey.dark .asisted-input .mat-form-field-disabled + .indicator > .bubble {
  color: rgba(255, 255, 255, 0.5);
  background: rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark .asisted-input .indicator .bubble {
  background: rgba(255, 255, 255, 0.5);
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark .asisted-input .indicator .guide {
  border-color: rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark .asisted-input.icon-only .bubble {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-blueGrey.dark .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble {
  color: #9fa8da;
}
.indigo-blueGrey.dark .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble {
  color: #e57373;
}
.indigo-blueGrey.dark .UI-V2 .main-overlay {
  background: rgba(0, 0, 0, 0.32);
}
.indigo-blueGrey.dark .UI-V2 .icon-complex mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.indigo-blueGrey.dark .UI-V2 .icon-complex mat-icon.complex {
  color: #9fa8da !important;
}
.indigo-blueGrey.dark .UI-V2 .navigation {
  background: #303030;
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark .UI-V2 .navigation *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.indigo-blueGrey.dark .UI-V2 .navigation *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
.indigo-blueGrey.dark .UI-V2 .navigation .load-window {
  background: black;
}
.indigo-blueGrey.dark .UI-V2 .navigation .toolbar {
  background: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark .UI-V2 .navigation .toolbar.reverse {
  background: #424242;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark .UI-V2 .navigation .container .helper {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-blueGrey.dark .UI-V2 .navigation .container .item {
  background: #424242;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark .UI-V2 .navigation .container .item:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-blueGrey.dark .UI-V2 .navigation .container .item.active {
  background: #9fa8da !important;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #9fa8da;
}
.indigo-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .separator .subtitle,
.indigo-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .separator .subtitle,
.indigo-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .separator .subtitle,
.indigo-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .separator .subtitle,
.indigo-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .separator .subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .img-editior-container .img-editor .separator .subtitle,
.indigo-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .img-editior-container .img-editor .separator .subtitle,
.indigo-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .img-editior-container .img-editor .separator .subtitle,
.indigo-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .img-editior-container .img-editor .separator .subtitle,
.indigo-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .img-editior-container .img-editor .separator .subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme,
.indigo-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme,
.indigo-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme,
.indigo-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme,
.indigo-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .main-color-container .main-color,
.indigo-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .main-color-container .main-color,
.indigo-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .main-color-container .main-color,
.indigo-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .main-color-container .main-color,
.indigo-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .main-color-container .main-color {
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .main-color-container .main-color:before,
.indigo-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .main-color-container .main-color:before,
.indigo-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .main-color-container .main-color:before,
.indigo-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .main-color-container .main-color:before,
.indigo-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .main-color-container .main-color:before {
  border-left: 10px solid rgba(0, 0, 0, 0);
  border-right: 10px solid rgba(0, 0, 0, 0);
}
.indigo-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example h6.required,
.indigo-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example h6.required,
.indigo-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example h6.required,
.indigo-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example h6.required,
.indigo-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example h6.required {
  color: #e57373 !important;
}
.indigo-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle,
.indigo-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle,
.indigo-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle,
.indigo-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle,
.indigo-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle {
  border: 2px solid rgba(0, 0, 0, 0);
}
.indigo-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .side-color-container .side-color,
.indigo-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .side-color-container .side-color,
.indigo-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .side-color-container .side-color,
.indigo-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .side-color-container .side-color,
.indigo-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .side-color-container .side-color {
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .side-color-container .side-color:before,
.indigo-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .side-color-container .side-color:before,
.indigo-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .side-color-container .side-color:before,
.indigo-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .side-color-container .side-color:before,
.indigo-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .side-color-container .side-color:before {
  border-top: 10px solid rgba(0, 0, 0, 0);
  border-bottom: 10px solid rgba(0, 0, 0, 0);
}
.indigo-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .side-color-container .side-color h6.required,
.indigo-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .side-color-container .side-color h6.required,
.indigo-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .side-color-container .side-color h6.required,
.indigo-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .side-color-container .side-color h6.required,
.indigo-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .side-color-container .side-color h6.required {
  color: #e57373 !important;
}
.indigo-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .permission-settings-cards .card,
.indigo-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .permission-settings-cards .card,
.indigo-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .permission-settings-cards .card,
.indigo-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .permission-settings-cards .card,
.indigo-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .permission-settings-cards .card {
  background: #303030;
}
.indigo-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .permission-settings-cards .card .slide-sub-group,
.indigo-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .permission-settings-cards .card .slide-sub-group,
.indigo-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .permission-settings-cards .card .slide-sub-group,
.indigo-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .permission-settings-cards .card .slide-sub-group,
.indigo-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .permission-settings-cards .card .slide-sub-group {
  background: rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark .UI-V2 .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.indigo-blueGrey.dark .UI-V2 .work-space .main .load-window {
  background: #424242 !important;
}
.indigo-blueGrey.dark .UI-V2 .work-space .main.side-open {
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item {
  background: #303030;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item mat-icon.complex {
  text-shadow: -2px 0 0px black, 0 -2px 0px black, 2px 0 0px black, 0 2px 0px black, -2px -2px 0px black, 2px 2px 0px black, 2px -2px 0px black, -2px 2px 0px black !important;
}
.indigo-blueGrey.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item h5 {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-blueGrey.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item h3 {
  color: white;
}
.indigo-blueGrey.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item .actions {
  pointer-events: none;
}
.indigo-blueGrey.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item .actions button {
  pointer-events: all;
}
.indigo-blueGrey.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled {
  background: black;
}
.indigo-blueGrey.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled h3 {
  color: #607D8B !important;
}
.indigo-blueGrey.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled mat-icon {
  color: #607D8B !important;
}
.indigo-blueGrey.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled.selected {
  background: #607D8B;
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-blueGrey.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled.selected h3 {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-blueGrey.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled.selected mat-icon {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-blueGrey.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled.selected mat-icon.complex {
  color: rgba(0, 0, 0, 0.87) !important;
  text-shadow: -2px 0 0px #607D8B, 0 -2px 0px #607D8B, 2px 0 0px #607D8B, 0 2px 0px #607D8B, -2px -2px 0px #607D8B, 2px 2px 0px #607D8B, 2px -2px 0px #607D8B, -2px 2px 0px #607D8B !important;
}
.indigo-blueGrey.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item:hover {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-blueGrey.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected {
  background: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected mat-icon {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected mat-icon.complex {
  color: rgba(0, 0, 0, 0.87) !important;
  text-shadow: -2px 0 0px #9fa8da, 0 -2px 0px #9fa8da, 2px 0 0px #9fa8da, 0 2px 0px #9fa8da, -2px -2px 0px #9fa8da, 2px 2px 0px #9fa8da, 2px -2px 0px #9fa8da, -2px 2px 0px #9fa8da !important;
}
.indigo-blueGrey.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected h5,
.indigo-blueGrey.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected h3 {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark .UI-V2 .work-space .main .main-action {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark .UI-V2 .work-space .side {
  background: #303030;
}
.indigo-blueGrey.dark .UI-V2 .work-space .side .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #303030, 0 -2px 0px #303030, 2px 0 0px #303030, 0 2px 0px #303030, -2px -2px 0px #303030, 2px 2px 0px #303030, 2px -2px 0px #303030, -2px 2px 0px #303030;
}
.indigo-blueGrey.dark .UI-V2 .work-space .side *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.indigo-blueGrey.dark .UI-V2 .work-space .side *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
.indigo-blueGrey.dark .UI-V2 .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #303030 !important;
}
.indigo-blueGrey.dark .UI-V2 .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-blueGrey.dark .UI-V2 .work-space .side mat-form-field .mat-form-field-flex {
  background: #303030;
}
.indigo-blueGrey.dark .UI-V2 .work-space .side mat-checkbox:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-blueGrey.dark .UI-V2 .work-space .side .hint.all {
  color: #b0bec5;
}
.indigo-blueGrey.dark .UI-V2 .work-space .side .toolbar .header {
  color: #9fa8da;
}
.indigo-blueGrey.dark .UI-V2 .work-space .side .toolbar .header h3 {
  color: #9fa8da;
}
.indigo-blueGrey.dark .UI-V2 .work-space .side .container .role-resume {
  background: #9fa8da;
}
.indigo-blueGrey.dark .UI-V2 .work-space .side .container .role-resume .line {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark .UI-V2 .work-space .side .container .role-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.1);
}
.indigo-blueGrey.dark .UI-V2 .work-space .side .container .role-resume .line.editable button {
  background: #9fa8da;
}
.indigo-blueGrey.dark .UI-V2 .work-space .side .container .section-title {
  color: #9fa8da;
}
.indigo-blueGrey.dark .UI-V2 .work-space .side .container .section-resume {
  background: #424242;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark .UI-V2 .work-space .side .container .section-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-blueGrey.dark .UI-V2 .work-space .side .container .section-resume .line.editable:hover .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #424242, 0 -2px 0px #424242, 2px 0 0px #424242, 0 2px 0px #424242, -2px -2px 0px #424242, 2px 2px 0px #424242, 2px -2px 0px #424242, -2px 2px 0px #424242 !important;
}
.indigo-blueGrey.dark .UI-V2 .work-space .side .container .section-resume .line.editable button {
  background: #303030;
}
.indigo-blueGrey.dark .UI-V2 .work-space .side .container .section-resume .line .header .icon-complex mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.indigo-blueGrey.dark .UI-V2 .work-space .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #9fa8da !important;
}
.indigo-blueGrey.dark .UI-V2 .work-space .side .container .section-resume .line .header mat-icon:not(.complex) {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-blueGrey.dark .UI-V2 .work-space .side .container .section-resume .line .header .info h4 {
  color: #9fa8da;
}
.indigo-blueGrey.dark .UI-V2 .work-space .side .container .section-resume .line ul {
  color: #9fa8da;
}
.indigo-blueGrey.dark .UI-V2 .work-space .side .container .section-resume .line.head {
  background: black;
}
.indigo-blueGrey.dark .UI-V2 .work-space .side .container .section-resume .line.head mat-icon {
  color: #b0bec5;
}
.indigo-blueGrey.dark .UI-V2 .work-space .side .container .section-resume .line.head h4 {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark .UI-V2 .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body {
  background: #424242;
}
.indigo-blueGrey.dark .UI-V2 .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-blueGrey.dark .UI-V2 .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #9fa8da;
}
.indigo-blueGrey.dark .UI-V2 .work-space .side.edition .toolbar .header {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark .UI-V2 .work-space .side.edition .toolbar .header mat-icon {
  color: #b0bec5;
}
.indigo-blueGrey.dark .UI-V2 .work-space .side.edition .toolbar .header h3 {
  color: #b0bec5;
}
.indigo-blueGrey.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body {
  background: #424242 !important;
}
.indigo-blueGrey.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header mat-icon:not(.complex) {
  color: #9fa8da;
}
.indigo-blueGrey.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header h4 {
  color: #9fa8da;
}
.indigo-blueGrey.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-actions {
  background: #303030;
}
.indigo-blueGrey.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.indigo-blueGrey.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header .icon-complex mat-icon.complex {
  color: #b0bec5 !important;
}
.indigo-blueGrey.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header h4 {
  color: #b0bec5;
}
.indigo-blueGrey.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line ul {
  color: #b0bec5;
  font-weight: 400;
}
.indigo-blueGrey.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head mat-icon {
  color: #b0bec5 !important;
}
.indigo-blueGrey.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head h4 {
  color: white;
}
.indigo-blueGrey.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .section-title {
  color: white !important;
}
.indigo-blueGrey.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume h6.hint {
  background: #b0bec5;
}
.indigo-blueGrey.dark app-role-user-find .main-overlay {
  background: rgba(0, 0, 0, 0.32);
}
.indigo-blueGrey.dark app-role-user-find .icon-complex mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.indigo-blueGrey.dark app-role-user-find .icon-complex mat-icon.complex {
  color: #9fa8da !important;
}
.indigo-blueGrey.dark app-role-user-find .navigation {
  background: #303030;
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark app-role-user-find .navigation *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.indigo-blueGrey.dark app-role-user-find .navigation *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
.indigo-blueGrey.dark app-role-user-find .navigation .load-window {
  background: black;
}
.indigo-blueGrey.dark app-role-user-find .navigation .toolbar {
  background: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark app-role-user-find .navigation .toolbar.reverse {
  background: #424242;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark app-role-user-find .navigation .container .helper {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-blueGrey.dark app-role-user-find .navigation .container .item {
  background: #424242;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark app-role-user-find .navigation .container .item:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-blueGrey.dark app-role-user-find .navigation .container .item.active {
  background: #9fa8da !important;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #9fa8da;
}
.indigo-blueGrey.dark app-role-user-find .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.indigo-blueGrey.dark app-role-user-find .work-space .main .load-window {
  background: #424242 !important;
}
.indigo-blueGrey.dark app-role-user-find .work-space .main.side-open {
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark app-role-user-find .work-space .main .container .item {
  background: #303030;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark app-role-user-find .work-space .main .container .item .subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-blueGrey.dark app-role-user-find .work-space .main .container .item.disabled {
  background: black;
  color: #607D8B !important;
}
.indigo-blueGrey.dark app-role-user-find .work-space .main .container .item.disabled h3 {
  color: #607D8B !important;
}
.indigo-blueGrey.dark app-role-user-find .work-space .main .container .item.disabled mat-icon {
  color: #607D8B !important;
}
.indigo-blueGrey.dark app-role-user-find .work-space .main .container .item.disabled.selected {
  background: #607D8B;
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-blueGrey.dark app-role-user-find .work-space .main .container .item.disabled.selected h3 {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-blueGrey.dark app-role-user-find .work-space .main .container .item.disabled.selected mat-icon {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-blueGrey.dark app-role-user-find .work-space .main .container .item.disabled.selected mat-icon.complex {
  color: rgba(0, 0, 0, 0.87) !important;
  text-shadow: -2px 0 0px #607D8B, 0 -2px 0px #607D8B, 2px 0 0px #607D8B, 0 2px 0px #607D8B, -2px -2px 0px #607D8B, 2px 2px 0px #607D8B, 2px -2px 0px #607D8B, -2px 2px 0px #607D8B !important;
}
.indigo-blueGrey.dark app-role-user-find .work-space .main .container .item mat-icon.complex {
  text-shadow: -2px 0 0px black, 0 -2px 0px black, 2px 0 0px black, 0 2px 0px black, -2px -2px 0px black, 2px 2px 0px black, 2px -2px 0px black, -2px 2px 0px black !important;
}
.indigo-blueGrey.dark app-role-user-find .work-space .main .container .item h5 {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-blueGrey.dark app-role-user-find .work-space .main .container .item h3 {
  color: white;
}
.indigo-blueGrey.dark app-role-user-find .work-space .main .container .item:hover {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-blueGrey.dark app-role-user-find .work-space .main .container .item.selected {
  background: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark app-role-user-find .work-space .main .container .item.selected mat-icon {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark app-role-user-find .work-space .main .container .item.selected mat-icon.complex {
  color: rgba(0, 0, 0, 0.87) !important;
  text-shadow: -2px 0 0px #9fa8da, 0 -2px 0px #9fa8da, 2px 0 0px #9fa8da, 0 2px 0px #9fa8da, -2px -2px 0px #9fa8da, 2px 2px 0px #9fa8da, 2px -2px 0px #9fa8da, -2px 2px 0px #9fa8da !important;
}
.indigo-blueGrey.dark app-role-user-find .work-space .main .container .item.selected h5,
.indigo-blueGrey.dark app-role-user-find .work-space .main .container .item.selected h3 {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark app-role-user-find .work-space .main .main-action {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark app-role-user-find .work-space .side {
  background: #303030;
}
.indigo-blueGrey.dark app-role-user-find .work-space .side .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #303030, 0 -2px 0px #303030, 2px 0 0px #303030, 0 2px 0px #303030, -2px -2px 0px #303030, 2px 2px 0px #303030, 2px -2px 0px #303030, -2px 2px 0px #303030;
}
.indigo-blueGrey.dark app-role-user-find .work-space .side *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.indigo-blueGrey.dark app-role-user-find .work-space .side *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
.indigo-blueGrey.dark app-role-user-find .work-space .side .edition-line {
  background: black;
}
.indigo-blueGrey.dark app-role-user-find .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #303030 !important;
}
.indigo-blueGrey.dark app-role-user-find .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-blueGrey.dark app-role-user-find .work-space .side mat-form-field .mat-form-field-flex {
  background: #303030;
}
.indigo-blueGrey.dark app-role-user-find .work-space .side mat-checkbox:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-blueGrey.dark app-role-user-find .work-space .side .hint.all {
  color: #b0bec5;
}
.indigo-blueGrey.dark app-role-user-find .work-space .side .toolbar .header {
  color: #9fa8da;
}
.indigo-blueGrey.dark app-role-user-find .work-space .side .toolbar .header h3 {
  color: #9fa8da;
}
.indigo-blueGrey.dark app-role-user-find .work-space .side .container .role-resume {
  background: #9fa8da;
}
.indigo-blueGrey.dark app-role-user-find .work-space .side .container .role-resume .line {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark app-role-user-find .work-space .side .container .role-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.1);
}
.indigo-blueGrey.dark app-role-user-find .work-space .side .container .role-resume .line.editable button {
  background: #9fa8da;
}
.indigo-blueGrey.dark app-role-user-find .work-space .side .container .section-title {
  color: #9fa8da;
}
.indigo-blueGrey.dark app-role-user-find .work-space .side .container .section-resume {
  background: #424242;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark app-role-user-find .work-space .side .container .section-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-blueGrey.dark app-role-user-find .work-space .side .container .section-resume .line.editable:hover .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #424242, 0 -2px 0px #424242, 2px 0 0px #424242, 0 2px 0px #424242, -2px -2px 0px #424242, 2px 2px 0px #424242, 2px -2px 0px #424242, -2px 2px 0px #424242 !important;
}
.indigo-blueGrey.dark app-role-user-find .work-space .side .container .section-resume .line.editable button {
  background: #303030;
}
.indigo-blueGrey.dark app-role-user-find .work-space .side .container .section-resume .line .header .icon-complex mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.indigo-blueGrey.dark app-role-user-find .work-space .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #9fa8da !important;
}
.indigo-blueGrey.dark app-role-user-find .work-space .side .container .section-resume .line .header mat-icon:not(.complex) {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-blueGrey.dark app-role-user-find .work-space .side .container .section-resume .line .header .info h4 {
  color: #9fa8da;
}
.indigo-blueGrey.dark app-role-user-find .work-space .side .container .section-resume .line ul {
  color: #9fa8da;
}
.indigo-blueGrey.dark app-role-user-find .work-space .side .container .section-resume .line.head {
  background: black;
}
.indigo-blueGrey.dark app-role-user-find .work-space .side .container .section-resume .line.head mat-icon {
  color: #b0bec5;
}
.indigo-blueGrey.dark app-role-user-find .work-space .side .container .section-resume .line.head h4 {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee-title {
  color: #9fa8da;
}
.indigo-blueGrey.dark app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body {
  background: #424242;
}
.indigo-blueGrey.dark app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-blueGrey.dark app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #9fa8da;
}
.indigo-blueGrey.dark app-role-user-find .work-space .side.edition .toolbar .header {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark app-role-user-find .work-space .side.edition .toolbar .header mat-icon {
  color: #b0bec5;
}
.indigo-blueGrey.dark app-role-user-find .work-space .side.edition .toolbar .header h3 {
  color: #b0bec5;
}
.indigo-blueGrey.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body {
  background: #424242 !important;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header mat-icon:not(.complex) {
  color: #9fa8da;
}
.indigo-blueGrey.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header h4 {
  color: #9fa8da;
}
.indigo-blueGrey.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-actions {
  background: #303030;
}
.indigo-blueGrey.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.indigo-blueGrey.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header .icon-complex mat-icon.complex {
  color: #b0bec5 !important;
}
.indigo-blueGrey.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header h4 {
  color: #b0bec5;
}
.indigo-blueGrey.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line ul {
  color: #b0bec5;
}
.indigo-blueGrey.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head mat-icon {
  color: #b0bec5 !important;
}
.indigo-blueGrey.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head h4 {
  color: white;
}
.indigo-blueGrey.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .section-title {
  color: white !important;
}
.indigo-blueGrey.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume h6.hint {
  background: #b0bec5;
}
.indigo-blueGrey.dark app-role-user-find .work-space app-role-user-detail.disabled .side .toolbar .header {
  color: #607D8B !important;
}
.indigo-blueGrey.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container .role-resume {
  background: #607D8B !important;
}
.indigo-blueGrey.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-title {
  color: #607D8B !important;
}
.indigo-blueGrey.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line ul {
  color: #607D8B !important;
}
.indigo-blueGrey.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header mat-icon {
  color: #607D8B;
}
.indigo-blueGrey.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .icon-complex mat-icon {
  color: #607D8B !important;
}
.indigo-blueGrey.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #607D8B !important;
}
.indigo-blueGrey.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .info h4 {
  color: #607D8B !important;
}
.indigo-blueGrey.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee-title {
  color: #607D8B;
}
.indigo-blueGrey.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: #607D8B;
}
.indigo-blueGrey.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #607D8B;
}
.indigo-blueGrey.dark app-actor-find .main-overlay {
  background: rgba(0, 0, 0, 0.32);
}
.indigo-blueGrey.dark app-actor-find .icon-complex mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.indigo-blueGrey.dark app-actor-find .icon-complex mat-icon.complex {
  color: #9fa8da !important;
}
.indigo-blueGrey.dark app-actor-find .navigation {
  background: #303030;
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark app-actor-find .navigation *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.indigo-blueGrey.dark app-actor-find .navigation *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
.indigo-blueGrey.dark app-actor-find .navigation .load-window {
  background: black;
}
.indigo-blueGrey.dark app-actor-find .navigation .toolbar {
  background: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark app-actor-find .navigation .toolbar.reverse {
  background: #424242;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark app-actor-find .navigation .container .helper {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-blueGrey.dark app-actor-find .navigation .container .item {
  background: #424242;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark app-actor-find .navigation .container .item:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-blueGrey.dark app-actor-find .navigation .container .item.active {
  background: #9fa8da !important;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #9fa8da;
}
.indigo-blueGrey.dark app-actor-find .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.indigo-blueGrey.dark app-actor-find .work-space .main .load-window {
  background: #424242 !important;
}
.indigo-blueGrey.dark app-actor-find .work-space .main.side-open {
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark app-actor-find .work-space .main .container .item {
  background: #303030;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark app-actor-find .work-space .main .container .item .subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-blueGrey.dark app-actor-find .work-space .main .container .item.disabled {
  background: black;
  color: #607D8B !important;
}
.indigo-blueGrey.dark app-actor-find .work-space .main .container .item.disabled h3 {
  color: #607D8B !important;
}
.indigo-blueGrey.dark app-actor-find .work-space .main .container .item.disabled mat-icon {
  color: #607D8B !important;
}
.indigo-blueGrey.dark app-actor-find .work-space .main .container .item.disabled.selected {
  background: #607D8B;
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-blueGrey.dark app-actor-find .work-space .main .container .item.disabled.selected h3 {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-blueGrey.dark app-actor-find .work-space .main .container .item.disabled.selected mat-icon {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-blueGrey.dark app-actor-find .work-space .main .container .item.disabled.selected mat-icon.complex {
  color: rgba(0, 0, 0, 0.87) !important;
  text-shadow: -2px 0 0px #607D8B, 0 -2px 0px #607D8B, 2px 0 0px #607D8B, 0 2px 0px #607D8B, -2px -2px 0px #607D8B, 2px 2px 0px #607D8B, 2px -2px 0px #607D8B, -2px 2px 0px #607D8B !important;
}
.indigo-blueGrey.dark app-actor-find .work-space .main .container .item mat-icon.complex {
  text-shadow: -2px 0 0px black, 0 -2px 0px black, 2px 0 0px black, 0 2px 0px black, -2px -2px 0px black, 2px 2px 0px black, 2px -2px 0px black, -2px 2px 0px black !important;
}
.indigo-blueGrey.dark app-actor-find .work-space .main .container .item h5 {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-blueGrey.dark app-actor-find .work-space .main .container .item h3 {
  color: white;
}
.indigo-blueGrey.dark app-actor-find .work-space .main .container .item:hover {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-blueGrey.dark app-actor-find .work-space .main .container .item.selected {
  background: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark app-actor-find .work-space .main .container .item.selected mat-icon {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark app-actor-find .work-space .main .container .item.selected mat-icon.complex {
  color: rgba(0, 0, 0, 0.87) !important;
  text-shadow: -2px 0 0px #9fa8da, 0 -2px 0px #9fa8da, 2px 0 0px #9fa8da, 0 2px 0px #9fa8da, -2px -2px 0px #9fa8da, 2px 2px 0px #9fa8da, 2px -2px 0px #9fa8da, -2px 2px 0px #9fa8da !important;
}
.indigo-blueGrey.dark app-actor-find .work-space .main .container .item.selected h5,
.indigo-blueGrey.dark app-actor-find .work-space .main .container .item.selected h3 {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark app-actor-find .work-space .main .main-action {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark app-actor-find .work-space .side {
  background: #303030;
}
.indigo-blueGrey.dark app-actor-find .work-space .side .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #303030, 0 -2px 0px #303030, 2px 0 0px #303030, 0 2px 0px #303030, -2px -2px 0px #303030, 2px 2px 0px #303030, 2px -2px 0px #303030, -2px 2px 0px #303030;
}
.indigo-blueGrey.dark app-actor-find .work-space .side *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.indigo-blueGrey.dark app-actor-find .work-space .side *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
.indigo-blueGrey.dark app-actor-find .work-space .side .edition-line {
  background: black;
}
.indigo-blueGrey.dark app-actor-find .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #303030 !important;
}
.indigo-blueGrey.dark app-actor-find .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-blueGrey.dark app-actor-find .work-space .side mat-form-field .mat-form-field-flex {
  background: #303030;
}
.indigo-blueGrey.dark app-actor-find .work-space .side mat-checkbox:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-blueGrey.dark app-actor-find .work-space .side .hint.all {
  color: #b0bec5;
}
.indigo-blueGrey.dark app-actor-find .work-space .side .toolbar .header {
  color: #9fa8da;
}
.indigo-blueGrey.dark app-actor-find .work-space .side .toolbar .header h3 {
  color: #9fa8da;
}
.indigo-blueGrey.dark app-actor-find .work-space .side .container .role-resume {
  background: #9fa8da;
}
.indigo-blueGrey.dark app-actor-find .work-space .side .container .role-resume .line {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark app-actor-find .work-space .side .container .role-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.1);
}
.indigo-blueGrey.dark app-actor-find .work-space .side .container .role-resume .line.editable button {
  background: #9fa8da;
}
.indigo-blueGrey.dark app-actor-find .work-space .side .container .section-title {
  color: #9fa8da;
}
.indigo-blueGrey.dark app-actor-find .work-space .side .container .section-resume {
  background: #424242;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark app-actor-find .work-space .side .container .section-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-blueGrey.dark app-actor-find .work-space .side .container .section-resume .line.editable:hover .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #424242, 0 -2px 0px #424242, 2px 0 0px #424242, 0 2px 0px #424242, -2px -2px 0px #424242, 2px 2px 0px #424242, 2px -2px 0px #424242, -2px 2px 0px #424242 !important;
}
.indigo-blueGrey.dark app-actor-find .work-space .side .container .section-resume .line.editable button {
  background: #303030;
}
.indigo-blueGrey.dark app-actor-find .work-space .side .container .section-resume .line .header .icon-complex mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.indigo-blueGrey.dark app-actor-find .work-space .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #9fa8da !important;
}
.indigo-blueGrey.dark app-actor-find .work-space .side .container .section-resume .line .header mat-icon:not(.complex) {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-blueGrey.dark app-actor-find .work-space .side .container .section-resume .line .header .info h4 {
  color: #9fa8da;
}
.indigo-blueGrey.dark app-actor-find .work-space .side .container .section-resume .line ul {
  color: #9fa8da;
}
.indigo-blueGrey.dark app-actor-find .work-space .side .container .section-resume .line.head {
  background: black;
}
.indigo-blueGrey.dark app-actor-find .work-space .side .container .section-resume .line.head mat-icon {
  color: #b0bec5;
}
.indigo-blueGrey.dark app-actor-find .work-space .side .container .section-resume .line.head h4 {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee-title {
  color: #9fa8da;
}
.indigo-blueGrey.dark app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body {
  background: #424242;
}
.indigo-blueGrey.dark app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-blueGrey.dark app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #9fa8da;
}
.indigo-blueGrey.dark app-actor-find .work-space .side.edition .toolbar .header {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark app-actor-find .work-space .side.edition .toolbar .header mat-icon {
  color: #b0bec5;
}
.indigo-blueGrey.dark app-actor-find .work-space .side.edition .toolbar .header h3 {
  color: #b0bec5;
}
.indigo-blueGrey.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body {
  background: #424242 !important;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header mat-icon:not(.complex) {
  color: #9fa8da;
}
.indigo-blueGrey.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header h4 {
  color: #9fa8da;
}
.indigo-blueGrey.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-actions {
  background: #303030;
}
.indigo-blueGrey.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.indigo-blueGrey.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header .icon-complex mat-icon.complex {
  color: #b0bec5 !important;
}
.indigo-blueGrey.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header h4 {
  color: #b0bec5;
}
.indigo-blueGrey.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line ul {
  color: #b0bec5;
}
.indigo-blueGrey.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head mat-icon {
  color: #b0bec5 !important;
}
.indigo-blueGrey.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head h4 {
  color: white;
}
.indigo-blueGrey.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .section-title {
  color: white !important;
}
.indigo-blueGrey.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume h6.hint {
  background: #b0bec5;
}
.indigo-blueGrey.dark app-actor-find .work-space app-role-user-detail.disabled .side .toolbar .header {
  color: #607D8B !important;
}
.indigo-blueGrey.dark app-actor-find .work-space app-role-user-detail.disabled .side .container .role-resume {
  background: #607D8B !important;
}
.indigo-blueGrey.dark app-actor-find .work-space app-role-user-detail.disabled .side .container .section-title {
  color: #607D8B !important;
}
.indigo-blueGrey.dark app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line ul {
  color: #607D8B !important;
}
.indigo-blueGrey.dark app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header mat-icon {
  color: #607D8B;
}
.indigo-blueGrey.dark app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .icon-complex mat-icon {
  color: #607D8B !important;
}
.indigo-blueGrey.dark app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #607D8B !important;
}
.indigo-blueGrey.dark app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .info h4 {
  color: #607D8B !important;
}
.indigo-blueGrey.dark app-actor-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee-title {
  color: #607D8B;
}
.indigo-blueGrey.dark app-actor-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: #607D8B;
}
.indigo-blueGrey.dark app-actor-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #607D8B;
}
.indigo-blueGrey.dark .dropzone {
  border: 2px dashed #607D8B;
  background: black !important;
}
.indigo-blueGrey.dark .dropzone.hovering {
  border: 2px solid #607D8B;
  color: rgba(255, 255, 255, 0.5) !important;
}
.indigo-blueGrey.dark .dropzone button.mat-stroked-button {
  background: #424242;
}
.indigo-blueGrey.dark .firmador .content-action-main {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-blueGrey.dark .firmador .content-action-main.disabled {
  color: rgba(255, 255, 255, 0.5) !important;
  background: rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark .sign-cycle {
  background: #424242;
}
.indigo-blueGrey.dark .sign-cycle button.success {
  background-color: #009688;
}
.indigo-blueGrey.dark .sign-cycle button.warning {
  background-color: #673AB7;
}
.indigo-blueGrey.dark .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-title-container .cycle-content-title h4 {
  color: #607D8B;
}
.indigo-blueGrey.dark .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-action .content-action-main {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-blueGrey.dark .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-action .content-action-main.mat-stroked-button:disabled {
  color: rgba(255, 255, 255, 0.5) !important;
}
.indigo-blueGrey.dark .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-action .content-action-main.mat-stroked-button:not(:disabled) {
  color: #b0bec5 !important;
}
.indigo-blueGrey.dark .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-action .content-action-side u {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-blueGrey.dark .resume-status {
  background: #424242;
}
.indigo-blueGrey.dark .help-hoverable mat-icon {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-blueGrey.dark .help-hoverable mat-hint {
  background: #424242;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark .help-hoverable mat-hint b {
  color: #9fa8da !important;
}
.indigo-blueGrey.dark app-file-document-sign-massive-dialog .dialog-content,
.indigo-blueGrey.dark app-share-person-dialog .dialog-content {
  background: #303030;
}
.indigo-blueGrey.dark app-file-document-sign-massive-dialog .dialog-content mat-toolbar mat-toolbar-row .mat-avatar h3,
.indigo-blueGrey.dark app-share-person-dialog .dialog-content mat-toolbar mat-toolbar-row .mat-avatar h3 {
  color: #9fa8da;
}
.indigo-blueGrey.dark app-file-document-sign-massive-dialog .dialog-content .dialog-body p b,
.indigo-blueGrey.dark app-share-person-dialog .dialog-content .dialog-body p b {
  color: #b0bec5;
}
.indigo-blueGrey.dark app-file-document-sign-massive-dialog .dialog-content app-file-document-sign,
.indigo-blueGrey.dark app-share-person-dialog .dialog-content app-file-document-sign {
  background: #424242;
}
.indigo-blueGrey.dark .mat-basic-chip {
  background: black !important;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark .mat-basic-chip.seen {
  border-color: #2196F3 !important;
  color: #2196F3 !important;
}
.indigo-blueGrey.dark .mat-basic-chip.unseen {
  border-color: #607D8B !important;
  color: #607D8B !important;
}
.indigo-blueGrey.dark .mat-basic-chip.signed-ok {
  border-color: #009688 !important;
  color: #009688 !important;
}
.indigo-blueGrey.dark .mat-basic-chip.signed-ok.cancelled {
  border-color: #795548 !important;
  color: #795548 !important;
}
.indigo-blueGrey.dark .mat-basic-chip.signed-not-ok {
  border-color: #673AB7 !important;
  color: #673AB7 !important;
}
.indigo-blueGrey.dark .mat-basic-chip.not-signed {
  border-color: #ff4081 !important;
  color: #ff4081 !important;
}
.indigo-blueGrey.dark .mat-basic-chip.not-signed-employer {
  border-color: #ff4081 !important;
  color: #ff4081 !important;
}
.indigo-blueGrey.dark app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip {
  background: black !important;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.seen {
  border-color: #2196F3 !important;
  color: #2196F3 !important;
}
.indigo-blueGrey.dark app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.unseen {
  border-color: #607D8B !important;
  color: #607D8B !important;
}
.indigo-blueGrey.dark app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.signed-ok {
  border-color: #009688 !important;
  color: #009688 !important;
}
.indigo-blueGrey.dark app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.signed-ok.cancelled {
  border-color: #795548 !important;
  color: #795548 !important;
}
.indigo-blueGrey.dark app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.signed-not-ok {
  border-color: #673AB7 !important;
  color: #673AB7 !important;
}
.indigo-blueGrey.dark app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.not-signed {
  border-color: #ff4081 !important;
  color: #ff4081 !important;
}
.indigo-blueGrey.dark app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.not-signed-employer {
  border-color: #ff4081 !important;
  color: #ff4081 !important;
}
.indigo-blueGrey.dark .inbox-container.employee.my-documents .chip-line.mat-line .mat-chip-list-wrapper .mat-basic-chip.seen {
  color: #607D8B !important;
}
.indigo-blueGrey.dark .resume-status .mat-basic-chip {
  background: black !important;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark .resume-status .mat-basic-chip.signed-ok {
  border-color: #009688 !important;
  background-color: #009688 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-blueGrey.dark .resume-status .mat-basic-chip.signed-ok.cancelled {
  border-color: #795548 !important;
  background-color: #795548 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-blueGrey.dark .resume-status .mat-basic-chip.signed-not-ok {
  border-color: #673AB7 !important;
  background-color: #673AB7 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-blueGrey.dark app-file-document-view .new-container app-file-document-upload {
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark app-file-document-view .new-container app-upload-form .file-upload-list .mat-subheader {
  background: #424242;
}
.indigo-blueGrey.dark app-file-document-view .new-container app-upload-form .file-upload-list mat-list-item {
  background: #303030 !important;
}
.indigo-blueGrey.dark app-file-document-view .preview-content {
  background: #607D8B;
}
.indigo-blueGrey.dark app-file-document-view .preview-content h4 {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark app-file-document-view .view-container {
  background: #424242;
}
.indigo-blueGrey.dark app-file-document-view .view-container mat-toolbar.side-bar {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark .formio-hint {
  background: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark mat-dialog-container app-file-document-state-chips {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark app-candidate-promotion .load-window.message h3.primary-text {
  color: #9fa8da;
}
.indigo-blueGrey.dark app-multiple-documentation-add-dialog .icon-complex mat-icon,
.indigo-blueGrey.dark app-documentation-type-set-configuration-dialog .icon-complex mat-icon,
.indigo-blueGrey.dark app-add-candidate-dialog .icon-complex mat-icon,
.indigo-blueGrey.dark app-share-person-dialog .icon-complex mat-icon,
.indigo-blueGrey.dark app-share-person-response-dialog .icon-complex mat-icon {
  color: white !important;
}
.indigo-blueGrey.dark app-multiple-documentation-add-dialog .icon-complex mat-icon.complex,
.indigo-blueGrey.dark app-documentation-type-set-configuration-dialog .icon-complex mat-icon.complex,
.indigo-blueGrey.dark app-add-candidate-dialog .icon-complex mat-icon.complex,
.indigo-blueGrey.dark app-share-person-dialog .icon-complex mat-icon.complex,
.indigo-blueGrey.dark app-share-person-response-dialog .icon-complex mat-icon.complex {
  color: #9fa8da !important;
}
.indigo-blueGrey.dark app-multiple-documentation-add-dialog .icon-complex mat-icon.complex.mat-accent,
.indigo-blueGrey.dark app-documentation-type-set-configuration-dialog .icon-complex mat-icon.complex.mat-accent,
.indigo-blueGrey.dark app-add-candidate-dialog .icon-complex mat-icon.complex.mat-accent,
.indigo-blueGrey.dark app-share-person-dialog .icon-complex mat-icon.complex.mat-accent,
.indigo-blueGrey.dark app-share-person-response-dialog .icon-complex mat-icon.complex.mat-accent {
  color: #b0bec5;
}
.indigo-blueGrey.dark app-multiple-documentation-add-dialog .icon-complex mat-icon.complex.side,
.indigo-blueGrey.dark app-documentation-type-set-configuration-dialog .icon-complex mat-icon.complex.side,
.indigo-blueGrey.dark app-add-candidate-dialog .icon-complex mat-icon.complex.side,
.indigo-blueGrey.dark app-share-person-dialog .icon-complex mat-icon.complex.side,
.indigo-blueGrey.dark app-share-person-response-dialog .icon-complex mat-icon.complex.side {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection,
.indigo-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection,
.indigo-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection,
.indigo-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .document-selection,
.indigo-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection {
  background: #303030;
}
.indigo-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.indigo-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.indigo-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3,
.indigo-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.indigo-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.indigo-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3,
.indigo-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.indigo-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.indigo-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3,
.indigo-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.indigo-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.indigo-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3,
.indigo-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.indigo-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.indigo-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3 {
  color: #b0bec5 !important;
}
.indigo-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon,
.indigo-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon,
.indigo-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon,
.indigo-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon,
.indigo-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon {
  color: #9fa8da;
}
.indigo-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3,
.indigo-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3,
.indigo-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3,
.indigo-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3,
.indigo-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3 {
  color: #9fa8da;
}
.indigo-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex,
.indigo-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex,
.indigo-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex,
.indigo-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex,
.indigo-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex {
  color: #b0bec5 !important;
  text-shadow: -2px 0 0px black, 0 -2px 0px black, 2px 0 0px black, 0 2px 0px black, -2px -2px 0px black, 2px 2px 0px black, 2px -2px 0px black, -2px 2px 0px #424242;
}
.indigo-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex,
.indigo-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex,
.indigo-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex,
.indigo-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex,
.indigo-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex {
  color: rgba(255, 255, 255, 0.5) !important;
}
.indigo-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b,
.indigo-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b,
.indigo-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b,
.indigo-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b,
.indigo-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b {
  color: white;
}
.indigo-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex,
.indigo-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex,
.indigo-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex,
.indigo-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex,
.indigo-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex {
  color: rgba(255, 255, 255, 0.5) !important;
}
.indigo-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex,
.indigo-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex,
.indigo-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex,
.indigo-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex,
.indigo-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex {
  color: rgba(255, 255, 255, 0.5) !important;
}
.indigo-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover,
.indigo-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover,
.indigo-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover,
.indigo-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover,
.indigo-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b,
.indigo-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b,
.indigo-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b,
.indigo-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b,
.indigo-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b {
  color: white;
}
.indigo-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.indigo-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.indigo-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.indigo-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.indigo-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble {
  background: #9fa8da;
}
.indigo-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble,
.indigo-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble,
.indigo-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble,
.indigo-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble,
.indigo-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble {
  background: #e57373;
}
.indigo-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble,
.indigo-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble,
.indigo-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble,
.indigo-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble,
.indigo-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble {
  color: rgba(255, 255, 255, 0.5);
  background: rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble,
.indigo-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble,
.indigo-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble,
.indigo-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble,
.indigo-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble {
  background: rgba(255, 255, 255, 0.5);
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide,
.indigo-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide,
.indigo-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide,
.indigo-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide,
.indigo-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide {
  border-color: rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble,
.indigo-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble,
.indigo-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble,
.indigo-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble,
.indigo-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.indigo-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.indigo-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.indigo-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.indigo-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble {
  color: #9fa8da;
}
.indigo-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble,
.indigo-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble,
.indigo-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble,
.indigo-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble,
.indigo-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble {
  color: #e57373;
}
.indigo-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar,
.indigo-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar,
.indigo-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar,
.indigo-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar,
.indigo-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar {
  background: #607D8B;
}
.indigo-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row,
.indigo-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row,
.indigo-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row,
.indigo-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row,
.indigo-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper,
.indigo-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper,
.indigo-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper,
.indigo-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper,
.indigo-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper {
  background: #607D8B;
}
.indigo-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop,
.indigo-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop,
.indigo-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop,
.indigo-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop,
.indigo-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop {
  border-left: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body,
.indigo-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body,
.indigo-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body,
.indigo-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body,
.indigo-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body {
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3,
.indigo-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3,
.indigo-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3,
.indigo-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3,
.indigo-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3 {
  color: #b0bec5;
}
.indigo-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5,
.indigo-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5,
.indigo-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5,
.indigo-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5,
.indigo-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5 {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5,
.indigo-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5,
.indigo-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5,
.indigo-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5,
.indigo-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5 {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator,
.indigo-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator,
.indigo-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator,
.indigo-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator,
.indigo-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  background: #303030;
}
.indigo-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip,
.indigo-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip,
.indigo-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip,
.indigo-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip,
.indigo-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip {
  background: #424242 !important;
}
.indigo-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon,
.indigo-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon,
.indigo-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon,
.indigo-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon,
.indigo-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon {
  color: #b0bec5 !important;
}
.indigo-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3,
.indigo-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3,
.indigo-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3,
.indigo-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3,
.indigo-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3 {
  color: #b0bec5 !important;
}
.indigo-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5,
.indigo-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5,
.indigo-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5,
.indigo-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5,
.indigo-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5 {
  color: rgba(255, 255, 255, 0.7) !important;
}
.indigo-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon,
.indigo-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon,
.indigo-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon,
.indigo-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon,
.indigo-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon {
  color: #9fa8da !important;
}
.indigo-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3,
.indigo-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3,
.indigo-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3,
.indigo-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3,
.indigo-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3 {
  color: #9fa8da !important;
}
.indigo-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5,
.indigo-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5,
.indigo-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5,
.indigo-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5,
.indigo-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5 {
  color: #9fa8da !important;
}
.indigo-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled,
.indigo-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled,
.indigo-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled,
.indigo-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled,
.indigo-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled {
  background: #303030 !important;
  color: rgba(255, 255, 255, 0.5);
}
.indigo-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove,
.indigo-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove,
.indigo-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove,
.indigo-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove,
.indigo-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove,
.indigo-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove,
.indigo-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove,
.indigo-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove,
.indigo-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove {
  color: rgba(255, 255, 255, 0.7) !important;
}
.indigo-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .preview-action,
.indigo-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .preview-action,
.indigo-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .preview-action,
.indigo-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .preview-action,
.indigo-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .preview-action {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark .tyc .sticky {
  background: #424242;
}
.indigo-blueGrey.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper:nth-child(odd) datatable-body-row {
  background: #303030;
}
.indigo-blueGrey.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active * {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-blueGrey.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active:hover .datatable-body-cell-label {
  background: #9fa8da;
}
.indigo-blueGrey.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active:hover .cs-floating-action {
  background: #9fa8da !important;
}
.indigo-blueGrey.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active.row-cancel * {
  color: rgba(255, 255, 255, 0.7) !important;
}
.indigo-blueGrey.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active.row-cancel .datatable-row-group {
  background: #607D8B !important;
}
.indigo-blueGrey.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active.row-cancel:hover .datatable-body-cell-label {
  background: #607D8B;
}
.indigo-blueGrey.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.row-cancel * {
  color: #b0bec5 !important;
  transition-property: color;
  transition-duration: 0.3s;
  transition-timing-function: linear;
}
.indigo-blueGrey.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row:hover .datatable-body-cell-label button {
  background: black;
}
.indigo-blueGrey.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row datatable-body-cell.candidate-status_bar mat-progress-bar .mat-progress-bar-buffer {
  background-color: black;
}
.indigo-blueGrey.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row datatable-body-cell.candidate-status_bar .status_bar-count {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-blueGrey.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row datatable-body-cell.candidate-status_bar .inactive {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-blueGrey.dark .cs-grid-test .info {
  background: #424242;
}
.indigo-blueGrey.dark .cs-grid-test .selected-column {
  background: #424242;
  -webkit-box-shadow: 0 1px 3px 0 rgba(255, 255, 255, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0 1px 3px 0 rgba(255, 255, 255, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 3px 0 rgba(255, 255, 255, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
}
.indigo-blueGrey.dark .cs-grid-test .selection-cell .datatable-body-cell.active {
  background: #9fa8da !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-blueGrey.dark .cs-grid-test nav {
  background: #424242;
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 13px 19px 2px rgba(0, 0, 0, 0.14), 0 5px 24px 4px rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark .cs-grid-test .ngx-datatable.material {
  background: #424242;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark .cs-grid-test .ngx-datatable.material.striped .datatable-row-odd {
  background: #303030;
}
.indigo-blueGrey.dark .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active, .indigo-blueGrey.dark .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active .datatable-row-group, .indigo-blueGrey.dark .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active, .indigo-blueGrey.dark .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active .datatable-row-group, .indigo-blueGrey.dark .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active, .indigo-blueGrey.dark .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active .datatable-row-group {
  background: #9fa8da;
  color: #424242;
}
.indigo-blueGrey.dark .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active:hover, .indigo-blueGrey.dark .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active:hover .datatable-row-group, .indigo-blueGrey.dark .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active:hover, .indigo-blueGrey.dark .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active:hover .datatable-row-group, .indigo-blueGrey.dark .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active:hover, .indigo-blueGrey.dark .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active:hover .datatable-row-group {
  background: #9fa8da;
  color: #424242;
}
.indigo-blueGrey.dark .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active:focus, .indigo-blueGrey.dark .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active:focus .datatable-row-group, .indigo-blueGrey.dark .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active:focus, .indigo-blueGrey.dark .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active:focus .datatable-row-group, .indigo-blueGrey.dark .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active:focus, .indigo-blueGrey.dark .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active:focus .datatable-row-group {
  background: #9fa8da;
  color: #424242;
}
.indigo-blueGrey.dark .cs-grid-test .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover, .indigo-blueGrey.dark .cs-grid-test .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-blueGrey.dark .cs-grid-test .ngx-datatable.material:not(.cell-selection) .datatable-body-row:focus, .indigo-blueGrey.dark .cs-grid-test .ngx-datatable.material:not(.cell-selection) .datatable-body-row:focus .datatable-row-group {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-blueGrey.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell:hover, .indigo-blueGrey.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell:hover .datatable-row-group {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-blueGrey.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell:focus, .indigo-blueGrey.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell:focus .datatable-row-group {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-blueGrey.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active, .indigo-blueGrey.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active .datatable-row-group {
  background: #9fa8da;
  color: #424242;
}
.indigo-blueGrey.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active:hover, .indigo-blueGrey.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active:hover .datatable-row-group {
  background: #9fa8da;
  color: #424242;
}
.indigo-blueGrey.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active:focus, .indigo-blueGrey.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active:focus .datatable-row-group {
  background: #9fa8da;
  color: #424242;
}
.indigo-blueGrey.dark .cs-grid-test .ngx-datatable.material .datatable-header .datatable-row-left,
.indigo-blueGrey.dark .cs-grid-test .ngx-datatable.material .datatable-body .datatable-row-left {
  background-color: #424242;
}
.indigo-blueGrey.dark .cs-grid-test .ngx-datatable.material .datatable-header .datatable-row-right,
.indigo-blueGrey.dark .cs-grid-test .ngx-datatable.material .datatable-body .datatable-row-right {
  background-color: #424242;
}
.indigo-blueGrey.dark .cs-grid-test .ngx-datatable.material .datatable-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.indigo-blueGrey.dark .cs-grid-test .ngx-datatable.material .datatable-header .datatable-header-cell {
  background-color: #424242;
  color: rgba(255, 255, 255, 0.7);
}
.indigo-blueGrey.dark .cs-grid-test .ngx-datatable.material .datatable-header .resize-handle {
  border-right: solid 1px rgba(255, 255, 255, 0.04);
}
.indigo-blueGrey.dark .cs-grid-test .ngx-datatable.material .datatable-body .datatable-row-detail {
  background: black;
}
.indigo-blueGrey.dark .cs-grid-test .ngx-datatable.material .datatable-body .datatable-group-header {
  background: black;
  border-bottom: solid 1px rgba(255, 255, 255, 0.12);
  border-top: solid 1px rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark .cs-grid-test .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
  color: white;
}
.indigo-blueGrey.dark .cs-grid-test .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-group-cell {
  color: white;
}
.indigo-blueGrey.dark .cs-grid-test .ngx-datatable.material .datatable-body .progress-linear .container {
  background-color: #424242;
}
.indigo-blueGrey.dark .cs-grid-test .ngx-datatable.material .datatable-body .progress-linear .container .bar {
  background-color: #9fa8da;
}
.indigo-blueGrey.dark .cs-grid-test .ngx-datatable.material .datatable-footer {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-blueGrey.dark .cs-grid-test .ngx-datatable.material .datatable-footer .datatable-pager li.disabled a {
  color: rgba(255, 255, 255, 0.5) !important;
  background-color: rgba(0, 0, 0, 0) !important;
}
.indigo-blueGrey.dark .cs-grid-test .ngx-datatable.material .datatable-footer .datatable-pager li.active a {
  background-color: #9fa8da;
}
.indigo-blueGrey.dark .cs-grid-test .ngx-datatable.material .datatable-footer .datatable-pager a {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-blueGrey.dark .cs-grid-test .ngx-datatable.material .datatable-footer .datatable-pager a:hover {
  color: rgba(255, 255, 255, 0.04);
  background-color: rgba(255, 255, 255, 0.04);
}
.indigo-blueGrey.dark .cs-grid-test .ngx-datatable.material .datatable-summary-row .datatable-body-row {
  background-color: #303030;
}
.indigo-blueGrey.dark .cs-grid-test .ngx-datatable.material .datatable-summary-row .datatable-body-row:hover {
  background-color: #303030;
}
.indigo-blueGrey.dark .cs-grid-test .datatable-checkbox input[type=checkbox]:after {
  background: #424242;
}
.indigo-blueGrey.dark .view-container .view-toolbar {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal.new.image-opened .help-content img {
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card {
  background: #424242;
}
.indigo-blueGrey.dark app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container .aditional-info {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-blueGrey.dark app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container .mat-card-footer {
  background: #424242;
}
.indigo-blueGrey.dark app-help-index mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card {
  background: #424242;
}
.indigo-blueGrey.dark app-help-index mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card .mat-card-footer {
  background: #424242;
}
.indigo-blueGrey.dark .cropper .employee-title .title-text {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-blueGrey.dark .cropper app-cs-upload-cropper-control .preview h5 {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-blueGrey.dark app-file-document-upload .preview-container {
  background: #607D8B !important;
}
.indigo-blueGrey.dark app-file-document-upload .preview-container h4 {
  color: black;
}
.indigo-blueGrey.dark .preview-container {
  background: #607D8B !important;
}
.indigo-blueGrey.dark .preview-container h4 {
  color: black;
}
.indigo-blueGrey.dark .clean-autocomple mat-list {
  background: #424242;
}
.indigo-blueGrey.dark .clean-autocomple mat-list .mat-list-item:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-blueGrey.dark .clean-autocomple mat-list .mat-list-item .mat-list-item-content .mat-list-text {
  color: #9fa8da;
}
.indigo-blueGrey.dark app-leave .inbox-toolbar {
  background: #303030;
}
.indigo-blueGrey.dark app-leave .mat-tab-body-wrapper {
  position: relative;
  overflow: hidden;
  display: flex;
  transition: height 500ms cubic-bezier(0.35, 0, 0.25, 1);
  height: 95% !important;
}
.indigo-blueGrey.dark app-leave .leave_container {
  background: #303030;
}
.indigo-blueGrey.dark app-leave .leave_container .leave_items .leave_items-item.ok .content .leave_item-status_icon {
  color: #4db6ac;
}
.indigo-blueGrey.dark app-leave .leave_container .leave_items .leave_items-item.not-ok .content .leave_item-status_icon {
  color: #ba68c8;
}
.indigo-blueGrey.dark app-leave .leave_container .leave_items .leave_items-item.pending .content .leave_item-status_icon {
  color: #ffb74d;
}
.indigo-blueGrey.dark app-leave .leave_container .leave_items .leave_items-item.draft .content .leave_item-status_icon {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-blueGrey.dark app-leave .leave_container .leave_items .leave_items-item.cancel .content .leave_item-status_icon {
  color: #795548;
}
.indigo-blueGrey.dark app-leave .leave_container .leave_items .leave_items-item .content:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-blueGrey.dark app-leave .leave_container .leave_items .leave_items-item .content.selected {
  border-left: 6px solid #b0bec5 !important;
}
.indigo-blueGrey.dark app-leave .leave_container .leave_items .leave_items-item .content.selected mat-icon {
  color: #b0bec5 !important;
}
.indigo-blueGrey.dark app-leave .leave_container .leave_items .leave_items-item .content.selected .item-data {
  color: white !important;
}
.indigo-blueGrey.dark app-leave .leave_container .leave_items .leave_items-item .content .leave_item-icon {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-blueGrey.dark app-leave .leave_container .leave_items .leave_items-item .content .item-data {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-blueGrey.dark app-leave .leave_container .window-spinner {
  background: #303030 !important;
}
.indigo-blueGrey.dark app-leave-detail .leave_detail .leave_detail-title .info h3,
.indigo-blueGrey.dark app-leave-detail .leave_detail .leave_detail-title .info mat-icon {
  color: #9fa8da;
}
.indigo-blueGrey.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .ok .large-icon {
  background: #e0f2f1;
  color: #4db6ac;
}
.indigo-blueGrey.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .ok h2 {
  color: #4db6ac;
}
.indigo-blueGrey.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .not-ok .large-icon {
  background: #f3e5f5;
  color: #ba68c8;
}
.indigo-blueGrey.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .not-ok h2 {
  color: #ba68c8;
}
.indigo-blueGrey.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .pending .large-icon {
  background: #fff3e0;
  color: #ffb74d;
}
.indigo-blueGrey.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .pending h2 {
  color: #ffb74d;
}
.indigo-blueGrey.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .draft .large-icon {
  background: #f2f2f2;
  color: #dedede;
}
.indigo-blueGrey.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .draft h2 {
  color: #dedede;
}
.indigo-blueGrey.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .cancelled .large-icon {
  background: #BCAAA4;
  color: #795548;
}
.indigo-blueGrey.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .cancelled h2 {
  color: #795548;
}
.indigo-blueGrey.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .actions {
  padding: 16px;
  height: 96px;
  column-gap: 16px;
}
.indigo-blueGrey.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .actions button {
  height: 100%;
  border-radius: 40px;
}
.indigo-blueGrey.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .actions button.ok {
  background: #4db6ac !important;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .actions button.not-ok {
  color: #ba68c8 !important;
}
.indigo-blueGrey.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning mat-basic-chip {
  background: black !important;
  border: 1px solid rgba(255, 255, 255, 0.12) !important;
  border-radius: 40px !important;
  height: 35px !important;
}
.indigo-blueGrey.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning mat-basic-chip.signed-ok {
  border-color: #4db6ac !important;
  color: #4db6ac !important;
}
.indigo-blueGrey.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning mat-basic-chip.signed-not-ok {
  border-color: #ba68c8 !important;
  color: #ba68c8 !important;
}
.indigo-blueGrey.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning mat-basic-chip.draft {
  border-color: #dedede !important;
  color: #dedede !important;
}
.indigo-blueGrey.dark app-leave-request-detail .load-window {
  background: #303030;
}
.indigo-blueGrey.dark app-leave-request-detail form .leave_detail-container {
  background: #303030;
}
.indigo-blueGrey.dark app-leave-request-detail form .leave_detail-container .toolbar .header {
  color: #9fa8da;
}
.indigo-blueGrey.dark app-leave-request-detail form .leave_detail-container .toolbar .header .text h3 {
  color: #9fa8da;
}
.indigo-blueGrey.dark app-leave-request-detail form .leave_detail-container .toolbar .header .text h5 {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-blueGrey.dark app-leave-request-detail form .leave_detail-container .leave_detail-container-content .leave_resume {
  background: #424242;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark app-leave-request-detail form .leave_detail-container .leave_detail-container-content .line h6 {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-blueGrey.dark app-leave-request-detail form .leave_detail-container .actions button.ok {
  background: #4db6ac !important;
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark app-leave-request-detail form .leave_detail-container .actions button.not-ok {
  color: #ba68c8 !important;
}
.indigo-blueGrey.dark .new_leave_modal-steps {
  display: unset !important;
}
.indigo-blueGrey.dark .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button {
  background: #303030;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button .mat-radio-label .mat-radio-label-content .side-text {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-blueGrey.dark .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button .mat-radio-label .mat-radio-label-content .side-text.muted {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-blueGrey.dark .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button .mat-radio-label .mat-radio-label-content .accent mat-icon,
.indigo-blueGrey.dark .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button .mat-radio-label .mat-radio-label-content .accent .main-text {
  color: #b0bec5;
}
.indigo-blueGrey.dark .config_leave-container .config_leave-card {
  background: #424242;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark .config_leave-container .config_leave-card .config_leave-auto_chip mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #303030 !important;
}
.indigo-blueGrey.dark .config_leave-container .config_leave-card .config_leave-auto_chip mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-blueGrey.dark .config_leave-container .config_leave-card .config_leave-auto_chip mat-form-field .mat-form-field-flex {
  background: #303030;
}
.indigo-blueGrey.dark *[color=success] {
  color: #009688;
}
.indigo-blueGrey.dark .primary {
  color: #9fa8da !important;
}
.indigo-blueGrey.dark .accent {
  color: #b0bec5 !important;
}
.indigo-blueGrey.dark app-lsd .icon-complex mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.indigo-blueGrey.dark app-lsd .icon-complex mat-icon.complex {
  color: #9fa8da !important;
}
.indigo-blueGrey.dark app-lsd .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.indigo-blueGrey.dark app-lsd .work-space .main .load-window {
  background: #424242 !important;
}
.indigo-blueGrey.dark app-lsd .work-space .main.side-open {
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark app-lsd .work-space .main .container .item {
  background: #303030;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark app-lsd .work-space .main .container .item h5 {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-blueGrey.dark app-lsd .work-space .main .container .item h3 {
  color: white;
}
.indigo-blueGrey.dark app-lsd .work-space .main .container .item:hover {
  background: rgba(255, 255, 255, 0.04);
}
.indigo-blueGrey.dark app-lsd .work-space .main .container .item.selected {
  background: #9fa8da;
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-blueGrey.dark app-lsd .work-space .main .container .item.selected h3 {
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-blueGrey.dark app-lsd .work-space .main .container .item.selected.pending .status {
  background: rgba(255, 255, 255, 0.12) !important;
  color: #ffe082;
}
.indigo-blueGrey.dark app-lsd .work-space .main .container .item.selected.ok .status {
  background: rgba(255, 255, 255, 0.12) !important;
  color: #a5d6a7;
}
.indigo-blueGrey.dark app-lsd .work-space .main .container .item.selected.not-ok .status {
  background: rgba(255, 255, 255, 0.12) !important;
  color: #e57373;
}
.indigo-blueGrey.dark app-lsd .work-space .main .main-action {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark app-lsd .work-space .side {
  background: #303030;
}
.indigo-blueGrey.dark app-lsd .work-space .side .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #303030, 0 -2px 0px #303030, 2px 0 0px #303030, 0 2px 0px #303030, -2px -2px 0px #303030, 2px 2px 0px #303030, 2px -2px 0px #303030, -2px 2px 0px #303030;
}
.indigo-blueGrey.dark app-lsd .work-space .side *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.indigo-blueGrey.dark app-lsd .work-space .side *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
.indigo-blueGrey.dark app-lsd .work-space .side .edition-line {
  background: black;
}
.indigo-blueGrey.dark app-lsd .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #303030 !important;
}
.indigo-blueGrey.dark app-lsd .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-blueGrey.dark app-lsd .work-space .side mat-form-field .mat-form-field-flex {
  background: #303030;
}
.indigo-blueGrey.dark app-lsd .work-space .side mat-checkbox:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-blueGrey.dark app-lsd .work-space .side .hint.all {
  color: #b0bec5;
}
.indigo-blueGrey.dark app-lsd .work-space .side .toolbar .header {
  color: #9fa8da;
}
.indigo-blueGrey.dark app-lsd .work-space .side .toolbar .header h3 {
  color: #9fa8da;
}
.indigo-blueGrey.dark app-lsd .work-space .side .container .doc-resume {
  background: #9fa8da;
}
.indigo-blueGrey.dark app-lsd .work-space .side .container .doc-resume .line {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark app-lsd .work-space .side .container .doc-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.1);
}
.indigo-blueGrey.dark app-lsd .work-space .side .container .doc-resume .line.editable button {
  background: #9fa8da;
}
.indigo-blueGrey.dark app-lsd .work-space .side .container .section-title {
  color: #9fa8da;
}
.indigo-blueGrey.dark app-lsd .work-space .side .container .section-resume {
  background: #424242;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark app-lsd .work-space .side .container .section-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.indigo-blueGrey.dark app-lsd .work-space .side .container .section-resume .line.editable:hover .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #424242, 0 -2px 0px #424242, 2px 0 0px #424242, 0 2px 0px #424242, -2px -2px 0px #424242, 2px 2px 0px #424242, 2px -2px 0px #424242, -2px 2px 0px #424242 !important;
}
.indigo-blueGrey.dark app-lsd .work-space .side .container .section-resume .line.editable button {
  background: #303030;
}
.indigo-blueGrey.dark app-lsd .work-space .side .container .section-resume .line .header .icon-complex mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.indigo-blueGrey.dark app-lsd .work-space .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #9fa8da !important;
}
.indigo-blueGrey.dark app-lsd .work-space .side .container .section-resume .line .header mat-icon:not(.complex) {
  color: rgba(255, 255, 255, 0.7);
}
.indigo-blueGrey.dark app-lsd .work-space .side .container .section-resume .line .header .info h4 {
  color: #9fa8da;
}
.indigo-blueGrey.dark app-lsd .work-space .side .container .section-resume .line ul {
  color: #9fa8da;
}
.indigo-blueGrey.dark app-lsd .work-space .side .container .section-resume .line.head {
  background: black;
}
.indigo-blueGrey.dark app-lsd .work-space .side .container .section-resume .line.head mat-icon {
  color: #b0bec5;
}
.indigo-blueGrey.dark app-lsd .work-space .side .container .section-resume .line.head h4 {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark app-lsd .work-space .side.edition .toolbar .header {
  color: rgba(0, 0, 0, 0.87);
}
.indigo-blueGrey.dark app-lsd .work-space .side.edition .toolbar .header mat-icon {
  color: #b0bec5;
}
.indigo-blueGrey.dark app-lsd .work-space .side.edition .toolbar .header h3 {
  color: #b0bec5;
}
.indigo-blueGrey.dark .dialog-content {
  max-height: 90vh;
}
.indigo-blueGrey.dark .dialog-content mat-toolbar {
  background: #424242;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark .dialog-content .dialog-container .document-template-container .document-selection mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #303030 !important;
}
.indigo-blueGrey.dark .dialog-content .dialog-container .document-template-container .document-selection mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(255, 255, 255, 0.5);
}
.indigo-blueGrey.dark .dialog-content .dialog-container .document-template-container .document-selection mat-form-field .mat-form-field-flex {
  background: #303030;
}
.indigo-blueGrey.dark .dialog-content .dialog-container .document-template-container .document-selection app-upload-form .file-upload-list .mat-subheader::before {
  color: #9fa8da;
}
.indigo-blueGrey.dark .dialog-content .dialog-container .view-container {
  border-left: 1px solid rgba(255, 255, 255, 0.12);
}
.indigo-blueGrey.dark .dialog-content .form-actions {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}

.pink-pink .mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-pink .mat-badge-small .mat-badge-content {
  font-size: 9px;
}
.pink-pink .mat-badge-large .mat-badge-content {
  font-size: 24px;
}
.pink-pink .mat-h1,
.pink-pink .mat-headline,
.pink-pink .mat-typography .mat-h1,
.pink-pink .mat-typography .mat-headline,
.pink-pink .mat-typography h1 {
  font: 400 24px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.pink-pink .mat-h2,
.pink-pink .mat-title,
.pink-pink .mat-typography .mat-h2,
.pink-pink .mat-typography .mat-title,
.pink-pink .mat-typography h2 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.pink-pink .mat-h3,
.pink-pink .mat-subheading-2,
.pink-pink .mat-typography .mat-h3,
.pink-pink .mat-typography .mat-subheading-2,
.pink-pink .mat-typography h3 {
  font: 400 16px / 28px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.pink-pink .mat-h4,
.pink-pink .mat-subheading-1,
.pink-pink .mat-typography .mat-h4,
.pink-pink .mat-typography .mat-subheading-1,
.pink-pink .mat-typography h4 {
  font: 400 15px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.pink-pink .mat-h5,
.pink-pink .mat-typography .mat-h5,
.pink-pink .mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}
.pink-pink .mat-h6,
.pink-pink .mat-typography .mat-h6,
.pink-pink .mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}
.pink-pink .mat-body-strong,
.pink-pink .mat-body-2,
.pink-pink .mat-typography .mat-body-strong,
.pink-pink .mat-typography .mat-body-2 {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.pink-pink .mat-body,
.pink-pink .mat-body-1,
.pink-pink .mat-typography .mat-body,
.pink-pink .mat-typography .mat-body-1,
.pink-pink .mat-typography {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.pink-pink .mat-body p,
.pink-pink .mat-body-1 p,
.pink-pink .mat-typography .mat-body p,
.pink-pink .mat-typography .mat-body-1 p,
.pink-pink .mat-typography p {
  margin: 0 0 12px;
}
.pink-pink .mat-small,
.pink-pink .mat-caption,
.pink-pink .mat-typography .mat-small,
.pink-pink .mat-typography .mat-caption {
  font: 400 12px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.pink-pink .mat-display-4,
.pink-pink .mat-typography .mat-display-4 {
  font: 300 112px / 112px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}
.pink-pink .mat-display-3,
.pink-pink .mat-typography .mat-display-3 {
  font: 400 56px / 56px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}
.pink-pink .mat-display-2,
.pink-pink .mat-typography .mat-display-2 {
  font: 400 45px / 48px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}
.pink-pink .mat-display-1,
.pink-pink .mat-typography .mat-display-1 {
  font: 400 34px / 40px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}
.pink-pink .mat-bottom-sheet-container {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.pink-pink .mat-button, .pink-pink .mat-raised-button, .pink-pink .mat-icon-button, .pink-pink .mat-stroked-button,
.pink-pink .mat-flat-button, .pink-pink .mat-fab, .pink-pink .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.pink-pink .mat-button-toggle {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-pink .mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-pink .mat-card-title {
  font-size: 24px;
  font-weight: 500;
}
.pink-pink .mat-card-header .mat-card-title {
  font-size: 20px;
}
.pink-pink .mat-card-subtitle,
.pink-pink .mat-card-content {
  font-size: 14px;
}
.pink-pink .mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-pink .mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}
.pink-pink .mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.pink-pink .mat-chip .mat-chip-trailing-icon.mat-icon,
.pink-pink .mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}
.pink-pink .mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-pink .mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}
.pink-pink .mat-cell, .pink-pink .mat-footer-cell {
  font-size: 14px;
}
.pink-pink .mat-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-pink .mat-calendar-body {
  font-size: 13px;
}
.pink-pink .mat-calendar-body-label,
.pink-pink .mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}
.pink-pink .mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}
.pink-pink .mat-dialog-title {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.pink-pink .mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400;
}
.pink-pink .mat-expansion-panel-content {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.pink-pink .mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.pink-pink .mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}
.pink-pink .mat-form-field-prefix .mat-icon,
.pink-pink .mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.pink-pink .mat-form-field-prefix .mat-icon-button,
.pink-pink .mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.pink-pink .mat-form-field-prefix .mat-icon-button .mat-icon,
.pink-pink .mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}
.pink-pink .mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}
.pink-pink .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.pink-pink .mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34359em) scale(0.75);
  width: 133.3334933333%;
}
.pink-pink .mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34358em) scale(0.75);
  width: 133.3335033333%;
}
.pink-pink .mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}
.pink-pink .mat-form-field-label {
  top: 1.34375em;
}
.pink-pink .mat-form-field-underline {
  bottom: 1.34375em;
}
.pink-pink .mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}
.pink-pink .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.pink-pink .mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.pink-pink .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.pink-pink .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00148px);
  width: 133.3338133333%;
}
.pink-pink .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00149px);
  width: 133.3338233333%;
}
.pink-pink .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.0015px);
  width: 133.3338333333%;
}
.pink-pink .mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.pink-pink .mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.pink-pink .mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}
@media print {
  .pink-pink .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.pink-pink .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28074em) scale(0.75);
  }
  .pink-pink .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28073em) scale(0.75);
  }
  .pink-pink .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28072em) scale(0.75);
  }
}
.pink-pink .mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.pink-pink .mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.pink-pink .mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.pink-pink .mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59359em) scale(0.75);
  width: 133.3334933333%;
}
.pink-pink .mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59358em) scale(0.75);
  width: 133.3335033333%;
}
.pink-pink .mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.pink-pink .mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.pink-pink .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.pink-pink .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59359em) scale(0.75);
  width: 133.3334933333%;
}
.pink-pink .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59358em) scale(0.75);
  width: 133.3335033333%;
}
.pink-pink .mat-grid-tile-header,
.pink-pink .mat-grid-tile-footer {
  font-size: 14px;
}
.pink-pink .mat-grid-tile-header .mat-line,
.pink-pink .mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.pink-pink .mat-grid-tile-header .mat-line:nth-child(n+2),
.pink-pink .mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.pink-pink input.mat-input-element {
  margin-top: -0.0625em;
}
.pink-pink .mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
}
.pink-pink .mat-paginator,
.pink-pink .mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
}
.pink-pink .mat-radio-button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-pink .mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-pink .mat-select-trigger {
  height: 1.125em;
}
.pink-pink .mat-slide-toggle-content {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-pink .mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}
.pink-pink .mat-stepper-vertical, .pink-pink .mat-stepper-horizontal {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-pink .mat-step-label {
  font-size: 14px;
  font-weight: 400;
}
.pink-pink .mat-step-sub-label-error {
  font-weight: normal;
}
.pink-pink .mat-step-label-error {
  font-size: 14px;
}
.pink-pink .mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}
.pink-pink .mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-pink .mat-tab-label, .pink-pink .mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.pink-pink .mat-toolbar,
.pink-pink .mat-toolbar h1,
.pink-pink .mat-toolbar h2,
.pink-pink .mat-toolbar h3,
.pink-pink .mat-toolbar h4,
.pink-pink .mat-toolbar h5,
.pink-pink .mat-toolbar h6 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0;
}
.pink-pink .mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.pink-pink .mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.pink-pink .mat-list-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-pink .mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-pink .mat-list-base .mat-list-item {
  font-size: 16px;
}
.pink-pink .mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.pink-pink .mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.pink-pink .mat-list-base .mat-list-option {
  font-size: 16px;
}
.pink-pink .mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.pink-pink .mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.pink-pink .mat-list-base .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.pink-pink .mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.pink-pink .mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.pink-pink .mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.pink-pink .mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.pink-pink .mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.pink-pink .mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.pink-pink .mat-list-base[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}
.pink-pink .mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
}
.pink-pink .mat-optgroup-label {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.pink-pink .mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
}
.pink-pink .mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}
.pink-pink .mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-pink .mat-tree-node,
.pink-pink .mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}
.pink-pink .mat-ripple {
  overflow: hidden;
  position: relative;
}
.pink-pink .mat-ripple:not(:empty) {
  transform: translateZ(0);
}
.pink-pink .mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}
.pink-pink .mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .pink-pink .mat-ripple-element {
  display: none;
}

.pink-pink .cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .pink-pink .cdk-visually-hidden {
  left: auto;
  right: 0;
}
.pink-pink .cdk-overlay-container, .pink-pink .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.pink-pink .cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.pink-pink .cdk-overlay-container:empty {
  display: none;
}
.pink-pink .cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}
.pink-pink .cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}
.pink-pink .cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.pink-pink .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .pink-pink .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}
.pink-pink .cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}
.pink-pink .cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.pink-pink .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}
.pink-pink .cdk-overlay-backdrop-noop-animation {
  transition: none;
}
.pink-pink .cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}
.pink-pink .cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}
.pink-pink textarea.cdk-textarea-autosize {
  resize: none;
}
.pink-pink textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}
.pink-pink textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}
@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.pink-pink .cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}
.pink-pink .cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}
.pink-pink .mat-focus-indicator {
  position: relative;
}
.pink-pink .mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.pink-pink .mat-focus-indicator:focus::before {
  content: "";
}
.cdk-high-contrast-active .pink-pink {
  --mat-focus-indicator-display: block;
}

.pink-pink .mat-mdc-focus-indicator {
  position: relative;
}
.pink-pink .mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.pink-pink .mat-mdc-focus-indicator:focus::before {
  content: "";
}
.cdk-high-contrast-active .pink-pink {
  --mat-mdc-focus-indicator-display: block;
}

.pink-pink .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.pink-pink .mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink .mat-option:hover:not(.mat-option-disabled), .pink-pink .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.pink-pink .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.pink-pink .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.pink-pink .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #d81b60;
}
.pink-pink .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #e91e63;
}
.pink-pink .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f44336;
}
.pink-pink .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}
.pink-pink .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.pink-pink .mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.pink-pink .mat-pseudo-checkbox::after {
  color: #fafafa;
}
.pink-pink .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.pink-pink .mat-primary .mat-pseudo-checkbox-checked,
.pink-pink .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #d81b60;
}
.pink-pink .mat-pseudo-checkbox-checked,
.pink-pink .mat-pseudo-checkbox-indeterminate,
.pink-pink .mat-accent .mat-pseudo-checkbox-checked,
.pink-pink .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #e91e63;
}
.pink-pink .mat-warn .mat-pseudo-checkbox-checked,
.pink-pink .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #f44336;
}
.pink-pink .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.pink-pink .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.pink-pink .mat-app-background, .pink-pink.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.pink-pink .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.pink-pink .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.pink-pink .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.pink-pink .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.pink-pink .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.pink-pink .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.pink-pink .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.pink-pink .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.pink-pink .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.pink-pink .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.pink-pink .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.pink-pink .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.pink-pink .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.pink-pink .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.pink-pink .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.pink-pink .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.pink-pink .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.pink-pink .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.pink-pink .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.pink-pink .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.pink-pink .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.pink-pink .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.pink-pink .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.pink-pink .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.pink-pink .mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.pink-pink .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.pink-pink .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink .mat-badge-content {
  color: white;
  background: #d81b60;
}
.cdk-high-contrast-active .pink-pink .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.pink-pink .mat-badge-accent .mat-badge-content {
  background: #e91e63;
  color: white;
}
.pink-pink .mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336;
}
.pink-pink .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.pink-pink .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink .mat-button, .pink-pink .mat-icon-button, .pink-pink .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.pink-pink .mat-button.mat-primary, .pink-pink .mat-icon-button.mat-primary, .pink-pink .mat-stroked-button.mat-primary {
  color: #d81b60;
}
.pink-pink .mat-button.mat-accent, .pink-pink .mat-icon-button.mat-accent, .pink-pink .mat-stroked-button.mat-accent {
  color: #e91e63;
}
.pink-pink .mat-button.mat-warn, .pink-pink .mat-icon-button.mat-warn, .pink-pink .mat-stroked-button.mat-warn {
  color: #f44336;
}
.pink-pink .mat-button.mat-primary.mat-button-disabled, .pink-pink .mat-button.mat-accent.mat-button-disabled, .pink-pink .mat-button.mat-warn.mat-button-disabled, .pink-pink .mat-button.mat-button-disabled.mat-button-disabled, .pink-pink .mat-icon-button.mat-primary.mat-button-disabled, .pink-pink .mat-icon-button.mat-accent.mat-button-disabled, .pink-pink .mat-icon-button.mat-warn.mat-button-disabled, .pink-pink .mat-icon-button.mat-button-disabled.mat-button-disabled, .pink-pink .mat-stroked-button.mat-primary.mat-button-disabled, .pink-pink .mat-stroked-button.mat-accent.mat-button-disabled, .pink-pink .mat-stroked-button.mat-warn.mat-button-disabled, .pink-pink .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.pink-pink .mat-button.mat-primary .mat-button-focus-overlay, .pink-pink .mat-icon-button.mat-primary .mat-button-focus-overlay, .pink-pink .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #d81b60;
}
.pink-pink .mat-button.mat-accent .mat-button-focus-overlay, .pink-pink .mat-icon-button.mat-accent .mat-button-focus-overlay, .pink-pink .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #e91e63;
}
.pink-pink .mat-button.mat-warn .mat-button-focus-overlay, .pink-pink .mat-icon-button.mat-warn .mat-button-focus-overlay, .pink-pink .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #f44336;
}
.pink-pink .mat-button.mat-button-disabled .mat-button-focus-overlay, .pink-pink .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .pink-pink .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.pink-pink .mat-button .mat-ripple-element, .pink-pink .mat-icon-button .mat-ripple-element, .pink-pink .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.pink-pink .mat-button-focus-overlay {
  background: black;
}
.pink-pink .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.pink-pink .mat-flat-button, .pink-pink .mat-raised-button, .pink-pink .mat-fab, .pink-pink .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.pink-pink .mat-flat-button.mat-primary, .pink-pink .mat-raised-button.mat-primary, .pink-pink .mat-fab.mat-primary, .pink-pink .mat-mini-fab.mat-primary {
  color: white;
}
.pink-pink .mat-flat-button.mat-accent, .pink-pink .mat-raised-button.mat-accent, .pink-pink .mat-fab.mat-accent, .pink-pink .mat-mini-fab.mat-accent {
  color: white;
}
.pink-pink .mat-flat-button.mat-warn, .pink-pink .mat-raised-button.mat-warn, .pink-pink .mat-fab.mat-warn, .pink-pink .mat-mini-fab.mat-warn {
  color: white;
}
.pink-pink .mat-flat-button.mat-primary.mat-button-disabled, .pink-pink .mat-flat-button.mat-accent.mat-button-disabled, .pink-pink .mat-flat-button.mat-warn.mat-button-disabled, .pink-pink .mat-flat-button.mat-button-disabled.mat-button-disabled, .pink-pink .mat-raised-button.mat-primary.mat-button-disabled, .pink-pink .mat-raised-button.mat-accent.mat-button-disabled, .pink-pink .mat-raised-button.mat-warn.mat-button-disabled, .pink-pink .mat-raised-button.mat-button-disabled.mat-button-disabled, .pink-pink .mat-fab.mat-primary.mat-button-disabled, .pink-pink .mat-fab.mat-accent.mat-button-disabled, .pink-pink .mat-fab.mat-warn.mat-button-disabled, .pink-pink .mat-fab.mat-button-disabled.mat-button-disabled, .pink-pink .mat-mini-fab.mat-primary.mat-button-disabled, .pink-pink .mat-mini-fab.mat-accent.mat-button-disabled, .pink-pink .mat-mini-fab.mat-warn.mat-button-disabled, .pink-pink .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.pink-pink .mat-flat-button.mat-primary, .pink-pink .mat-raised-button.mat-primary, .pink-pink .mat-fab.mat-primary, .pink-pink .mat-mini-fab.mat-primary {
  background-color: #d81b60;
}
.pink-pink .mat-flat-button.mat-accent, .pink-pink .mat-raised-button.mat-accent, .pink-pink .mat-fab.mat-accent, .pink-pink .mat-mini-fab.mat-accent {
  background-color: #e91e63;
}
.pink-pink .mat-flat-button.mat-warn, .pink-pink .mat-raised-button.mat-warn, .pink-pink .mat-fab.mat-warn, .pink-pink .mat-mini-fab.mat-warn {
  background-color: #f44336;
}
.pink-pink .mat-flat-button.mat-primary.mat-button-disabled, .pink-pink .mat-flat-button.mat-accent.mat-button-disabled, .pink-pink .mat-flat-button.mat-warn.mat-button-disabled, .pink-pink .mat-flat-button.mat-button-disabled.mat-button-disabled, .pink-pink .mat-raised-button.mat-primary.mat-button-disabled, .pink-pink .mat-raised-button.mat-accent.mat-button-disabled, .pink-pink .mat-raised-button.mat-warn.mat-button-disabled, .pink-pink .mat-raised-button.mat-button-disabled.mat-button-disabled, .pink-pink .mat-fab.mat-primary.mat-button-disabled, .pink-pink .mat-fab.mat-accent.mat-button-disabled, .pink-pink .mat-fab.mat-warn.mat-button-disabled, .pink-pink .mat-fab.mat-button-disabled.mat-button-disabled, .pink-pink .mat-mini-fab.mat-primary.mat-button-disabled, .pink-pink .mat-mini-fab.mat-accent.mat-button-disabled, .pink-pink .mat-mini-fab.mat-warn.mat-button-disabled, .pink-pink .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.pink-pink .mat-flat-button.mat-primary .mat-ripple-element, .pink-pink .mat-raised-button.mat-primary .mat-ripple-element, .pink-pink .mat-fab.mat-primary .mat-ripple-element, .pink-pink .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.pink-pink .mat-flat-button.mat-accent .mat-ripple-element, .pink-pink .mat-raised-button.mat-accent .mat-ripple-element, .pink-pink .mat-fab.mat-accent .mat-ripple-element, .pink-pink .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.pink-pink .mat-flat-button.mat-warn .mat-ripple-element, .pink-pink .mat-raised-button.mat-warn .mat-ripple-element, .pink-pink .mat-fab.mat-warn .mat-ripple-element, .pink-pink .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.pink-pink .mat-stroked-button:not([class*=mat-elevation-z]), .pink-pink .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.pink-pink .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.pink-pink .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.pink-pink .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.pink-pink .mat-fab:not([class*=mat-elevation-z]), .pink-pink .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.pink-pink .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .pink-pink .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.pink-pink .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .pink-pink .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.pink-pink .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.pink-pink .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.pink-pink .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.pink-pink .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.pink-pink .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.pink-pink .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.pink-pink .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.pink-pink .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.pink-pink .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
.pink-pink [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.pink-pink .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
.pink-pink .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.pink-pink .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.pink-pink .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.pink-pink .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.pink-pink .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.pink-pink .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
.pink-pink .mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.pink-pink .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.pink-pink .mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.pink-pink .mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}
.pink-pink .mat-checkbox-checkmark {
  fill: #fafafa;
}
.pink-pink .mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
.pink-pink .mat-checkbox-mixedmark {
  background-color: #fafafa;
}
.pink-pink .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .pink-pink .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #d81b60;
}
.pink-pink .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .pink-pink .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #e91e63;
}
.pink-pink .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .pink-pink .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #f44336;
}
.pink-pink .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .pink-pink .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.pink-pink .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.pink-pink .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}
.pink-pink .mat-checkbox .mat-ripple-element {
  background-color: black;
}
.pink-pink .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.pink-pink .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #d81b60;
}
.pink-pink .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.pink-pink .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #e91e63;
}
.pink-pink .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.pink-pink .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #f44336;
}
.pink-pink .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink .mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.pink-pink .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.pink-pink .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.pink-pink .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.pink-pink .mat-chip.mat-standard-chip::after {
  background: black;
}
.pink-pink .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #d81b60;
  color: white;
}
.pink-pink .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.pink-pink .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.pink-pink .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #f44336;
  color: white;
}
.pink-pink .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.pink-pink .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.pink-pink .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #e91e63;
  color: white;
}
.pink-pink .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.pink-pink .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.pink-pink .mat-table {
  background: white;
}
.pink-pink .mat-table thead, .pink-pink .mat-table tbody, .pink-pink .mat-table tfoot,
.pink-pink mat-header-row, .pink-pink mat-row, .pink-pink mat-footer-row,
.pink-pink [mat-header-row], .pink-pink [mat-row], .pink-pink [mat-footer-row],
.pink-pink .mat-table-sticky {
  background: inherit;
}
.pink-pink mat-row, .pink-pink mat-header-row, .pink-pink mat-footer-row,
.pink-pink th.mat-header-cell, .pink-pink td.mat-cell, .pink-pink td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.pink-pink .mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}
.pink-pink .mat-cell, .pink-pink .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink .mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}
.pink-pink .mat-datepicker-toggle,
.pink-pink .mat-datepicker-content .mat-calendar-next-button,
.pink-pink .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.pink-pink .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.pink-pink .mat-calendar-table-header,
.pink-pink .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.pink-pink .mat-calendar-body-cell-content,
.pink-pink .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.pink-pink .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.pink-pink .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.pink-pink .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.pink-pink .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.pink-pink .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.pink-pink .mat-calendar-body-in-range::before {
  background: rgba(216, 27, 96, 0.2);
}
.pink-pink .mat-calendar-body-comparison-identical,
.pink-pink .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.pink-pink .mat-calendar-body-comparison-bridge-start::before,
.pink-pink [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(216, 27, 96, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.pink-pink .mat-calendar-body-comparison-bridge-end::before,
.pink-pink [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(216, 27, 96, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.pink-pink .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.pink-pink .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.pink-pink .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.pink-pink .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.pink-pink .mat-calendar-body-selected {
  background-color: #d81b60;
  color: white;
}
.pink-pink .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(216, 27, 96, 0.4);
}
.pink-pink .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.pink-pink .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.pink-pink .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(216, 27, 96, 0.3);
}
@media (hover: hover) {
  .pink-pink .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(216, 27, 96, 0.3);
  }
}
.pink-pink .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(233, 30, 99, 0.2);
}
.pink-pink .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.pink-pink .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.pink-pink .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.pink-pink .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(233, 30, 99, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.pink-pink .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.pink-pink .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(233, 30, 99, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.pink-pink .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.pink-pink .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.pink-pink .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.pink-pink .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.pink-pink .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #e91e63;
  color: white;
}
.pink-pink .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(233, 30, 99, 0.4);
}
.pink-pink .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.pink-pink .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.pink-pink .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(233, 30, 99, 0.3);
}
@media (hover: hover) {
  .pink-pink .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(233, 30, 99, 0.3);
  }
}
.pink-pink .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}
.pink-pink .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.pink-pink .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.pink-pink .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.pink-pink .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.pink-pink .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.pink-pink .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.pink-pink .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.pink-pink .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.pink-pink .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.pink-pink .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.pink-pink .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: white;
}
.pink-pink .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}
.pink-pink .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.pink-pink .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.pink-pink .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(244, 67, 54, 0.3);
}
@media (hover: hover) {
  .pink-pink .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(244, 67, 54, 0.3);
  }
}
.pink-pink .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.pink-pink .mat-datepicker-toggle-active {
  color: #d81b60;
}
.pink-pink .mat-datepicker-toggle-active.mat-accent {
  color: #e91e63;
}
.pink-pink .mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}
.pink-pink .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.pink-pink .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.pink-pink .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.pink-pink .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.pink-pink .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.pink-pink .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .pink-pink .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .pink-pink .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .pink-pink .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.pink-pink .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink .mat-expansion-panel-header-description,
.pink-pink .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.pink-pink .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.pink-pink .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.pink-pink .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.pink-pink .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.pink-pink .mat-hint {
  color: rgba(0, 0, 0, 0.6);
}
.pink-pink .mat-form-field.mat-focused .mat-form-field-label {
  color: #d81b60;
}
.pink-pink .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #e91e63;
}
.pink-pink .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #f44336;
}
.pink-pink .mat-focused .mat-form-field-required-marker {
  color: #e91e63;
}
.pink-pink .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.pink-pink .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #d81b60;
}
.pink-pink .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #e91e63;
}
.pink-pink .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #f44336;
}
.pink-pink .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #d81b60;
}
.pink-pink .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #e91e63;
}
.pink-pink .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #f44336;
}
.pink-pink .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #f44336;
}
.pink-pink .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.pink-pink .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #f44336;
}
.pink-pink .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.pink-pink .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #f44336;
}
.pink-pink .mat-error {
  color: #f44336;
}
.pink-pink .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.pink-pink .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.pink-pink .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.pink-pink .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.pink-pink .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.pink-pink .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.pink-pink .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.pink-pink .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.pink-pink .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.pink-pink .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.pink-pink .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.pink-pink .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.pink-pink .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #d81b60;
}
.pink-pink .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #e91e63;
}
.pink-pink .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #f44336;
}
.pink-pink .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #f44336;
}
.pink-pink .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.pink-pink .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.pink-pink .mat-icon.mat-primary {
  color: #d81b60;
}
.pink-pink .mat-icon.mat-accent {
  color: #e91e63;
}
.pink-pink .mat-icon.mat-warn {
  color: #f44336;
}
.pink-pink .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.pink-pink .mat-input-element:disabled,
.pink-pink .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.pink-pink .mat-input-element {
  caret-color: #d81b60;
}
.pink-pink .mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.pink-pink .mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.pink-pink .mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.pink-pink .mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.pink-pink .mat-form-field.mat-accent .mat-input-element {
  caret-color: #e91e63;
}
.pink-pink .mat-form-field.mat-warn .mat-input-element,
.pink-pink .mat-form-field-invalid .mat-input-element {
  caret-color: #f44336;
}
.pink-pink .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #f44336;
}
.pink-pink .mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink .mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink .mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.pink-pink .mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}
.pink-pink .mat-list-option:hover, .pink-pink .mat-list-option:focus,
.pink-pink .mat-nav-list .mat-list-item:hover,
.pink-pink .mat-nav-list .mat-list-item:focus,
.pink-pink .mat-action-list .mat-list-item:hover,
.pink-pink .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.pink-pink .mat-list-single-selected-option, .pink-pink .mat-list-single-selected-option:hover, .pink-pink .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.pink-pink .mat-menu-panel {
  background: white;
}
.pink-pink .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.pink-pink .mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink .mat-menu-item[disabled],
.pink-pink .mat-menu-item[disabled] .mat-menu-submenu-icon,
.pink-pink .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}
.pink-pink .mat-menu-item .mat-icon-no-color,
.pink-pink .mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}
.pink-pink .mat-menu-item:hover:not([disabled]),
.pink-pink .mat-menu-item.cdk-program-focused:not([disabled]),
.pink-pink .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.pink-pink .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.pink-pink .mat-paginator {
  background: white;
}
.pink-pink .mat-paginator,
.pink-pink .mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}
.pink-pink .mat-paginator-decrement,
.pink-pink .mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.pink-pink .mat-paginator-first,
.pink-pink .mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.pink-pink .mat-icon-button[disabled] .mat-paginator-decrement,
.pink-pink .mat-icon-button[disabled] .mat-paginator-increment,
.pink-pink .mat-icon-button[disabled] .mat-paginator-first,
.pink-pink .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.pink-pink .mat-progress-bar-background {
  fill: #f2c2d4;
}
.pink-pink .mat-progress-bar-buffer {
  background-color: #f2c2d4;
}
.pink-pink .mat-progress-bar-fill::after {
  background-color: #d81b60;
}
.pink-pink .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #f6c3d4;
}
.pink-pink .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #f6c3d4;
}
.pink-pink .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #e91e63;
}
.pink-pink .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f9ccc9;
}
.pink-pink .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f9ccc9;
}
.pink-pink .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #f44336;
}
.pink-pink .mat-progress-spinner circle, .pink-pink .mat-spinner circle {
  stroke: #d81b60;
}
.pink-pink .mat-progress-spinner.mat-accent circle, .pink-pink .mat-spinner.mat-accent circle {
  stroke: #e91e63;
}
.pink-pink .mat-progress-spinner.mat-warn circle, .pink-pink .mat-spinner.mat-warn circle {
  stroke: #f44336;
}
.pink-pink .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.pink-pink .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #d81b60;
}
.pink-pink .mat-radio-button.mat-primary .mat-radio-inner-circle,
.pink-pink .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .pink-pink .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .pink-pink .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #d81b60;
}
.pink-pink .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e91e63;
}
.pink-pink .mat-radio-button.mat-accent .mat-radio-inner-circle,
.pink-pink .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .pink-pink .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .pink-pink .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #e91e63;
}
.pink-pink .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f44336;
}
.pink-pink .mat-radio-button.mat-warn .mat-radio-inner-circle,
.pink-pink .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .pink-pink .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .pink-pink .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #f44336;
}
.pink-pink .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.pink-pink .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.pink-pink .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.pink-pink .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.pink-pink .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.pink-pink .mat-radio-button .mat-ripple-element {
  background-color: black;
}
.pink-pink .mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink .mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.pink-pink .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.pink-pink .mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.pink-pink .mat-select-panel {
  background: white;
}
.pink-pink .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.pink-pink .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.pink-pink .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #d81b60;
}
.pink-pink .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #e91e63;
}
.pink-pink .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #f44336;
}
.pink-pink .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #f44336;
}
.pink-pink .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.pink-pink .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink .mat-drawer.mat-drawer-push {
  background-color: white;
}
.pink-pink .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.pink-pink .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.pink-pink .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.pink-pink [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.pink-pink [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.pink-pink .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.pink-pink .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #e91e63;
}
.pink-pink .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(233, 30, 99, 0.54);
}
.pink-pink .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #e91e63;
}
.pink-pink .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #d81b60;
}
.pink-pink .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(216, 27, 96, 0.54);
}
.pink-pink .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #d81b60;
}
.pink-pink .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #f44336;
}
.pink-pink .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(244, 67, 54, 0.54);
}
.pink-pink .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #f44336;
}
.pink-pink .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
.pink-pink .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
.pink-pink .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.pink-pink .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.pink-pink .mat-slider.mat-primary .mat-slider-track-fill,
.pink-pink .mat-slider.mat-primary .mat-slider-thumb,
.pink-pink .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #d81b60;
}
.pink-pink .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.pink-pink .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(216, 27, 96, 0.2);
}
.pink-pink .mat-slider.mat-accent .mat-slider-track-fill,
.pink-pink .mat-slider.mat-accent .mat-slider-thumb,
.pink-pink .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #e91e63;
}
.pink-pink .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.pink-pink .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(233, 30, 99, 0.2);
}
.pink-pink .mat-slider.mat-warn .mat-slider-track-fill,
.pink-pink .mat-slider.mat-warn .mat-slider-thumb,
.pink-pink .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #f44336;
}
.pink-pink .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.pink-pink .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(244, 67, 54, 0.2);
}
.pink-pink .mat-slider:hover .mat-slider-track-background,
.pink-pink .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}
.pink-pink .mat-slider.mat-slider-disabled .mat-slider-track-background,
.pink-pink .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.pink-pink .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.pink-pink .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.pink-pink .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.pink-pink .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.pink-pink .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.pink-pink .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.pink-pink .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.pink-pink .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.pink-pink .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .pink-pink .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.pink-pink .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .pink-pink .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}
.pink-pink .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.pink-pink .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.pink-pink .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.pink-pink .mat-step-header.cdk-keyboard-focused, .pink-pink .mat-step-header.cdk-program-focused, .pink-pink .mat-step-header:hover:not([aria-disabled]), .pink-pink .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.pink-pink .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .pink-pink .mat-step-header:hover {
    background: none;
  }
}
.pink-pink .mat-step-header .mat-step-label,
.pink-pink .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.pink-pink .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.pink-pink .mat-step-header .mat-step-icon-selected,
.pink-pink .mat-step-header .mat-step-icon-state-done,
.pink-pink .mat-step-header .mat-step-icon-state-edit {
  background-color: #d81b60;
  color: white;
}
.pink-pink .mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.pink-pink .mat-step-header.mat-accent .mat-step-icon-selected,
.pink-pink .mat-step-header.mat-accent .mat-step-icon-state-done,
.pink-pink .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #e91e63;
  color: white;
}
.pink-pink .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.pink-pink .mat-step-header.mat-warn .mat-step-icon-selected,
.pink-pink .mat-step-header.mat-warn .mat-step-icon-state-done,
.pink-pink .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #f44336;
  color: white;
}
.pink-pink .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336;
}
.pink-pink .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink .mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336;
}
.pink-pink .mat-stepper-horizontal, .pink-pink .mat-stepper-vertical {
  background-color: white;
}
.pink-pink .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.pink-pink .mat-horizontal-stepper-header::before,
.pink-pink .mat-horizontal-stepper-header::after,
.pink-pink .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.pink-pink .mat-sort-header-arrow {
  color: #757575;
}
.pink-pink .mat-tab-nav-bar,
.pink-pink .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink .mat-tab-group-inverted-header .mat-tab-nav-bar,
.pink-pink .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.pink-pink .mat-tab-label, .pink-pink .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink .mat-tab-label.mat-tab-disabled, .pink-pink .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.pink-pink .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.pink-pink .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.pink-pink .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.pink-pink .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.pink-pink .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-pink .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-pink .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-pink .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .pink-pink .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-pink .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-pink .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-pink .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 187, 208, 0.3);
}
.pink-pink .mat-tab-group.mat-primary .mat-ink-bar, .pink-pink .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #d81b60;
}
.pink-pink .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .pink-pink .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .pink-pink .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .pink-pink .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.pink-pink .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-pink .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-pink .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-pink .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .pink-pink .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-pink .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-pink .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-pink .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 187, 208, 0.3);
}
.pink-pink .mat-tab-group.mat-accent .mat-ink-bar, .pink-pink .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #e91e63;
}
.pink-pink .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .pink-pink .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .pink-pink .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .pink-pink .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.pink-pink .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-pink .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-pink .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-pink .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .pink-pink .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-pink .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-pink .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-pink .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.pink-pink .mat-tab-group.mat-warn .mat-ink-bar, .pink-pink .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #f44336;
}
.pink-pink .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .pink-pink .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .pink-pink .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .pink-pink .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.pink-pink .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-pink .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-pink .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-pink .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .pink-pink .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-pink .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-pink .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-pink .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 187, 208, 0.3);
}
.pink-pink .mat-tab-group.mat-background-primary > .mat-tab-header, .pink-pink .mat-tab-group.mat-background-primary > .mat-tab-link-container, .pink-pink .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .pink-pink .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .pink-pink .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .pink-pink .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #d81b60;
}
.pink-pink .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .pink-pink .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .pink-pink .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .pink-pink .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.pink-pink .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .pink-pink .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .pink-pink .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .pink-pink .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.pink-pink .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.pink-pink .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.pink-pink .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.pink-pink .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .pink-pink .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.pink-pink .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.pink-pink .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.pink-pink .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.pink-pink .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.pink-pink .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .pink-pink .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.pink-pink .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.pink-pink .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.pink-pink .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.pink-pink .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .pink-pink .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.pink-pink .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.pink-pink .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.pink-pink .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-pink .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-pink .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-pink .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .pink-pink .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-pink .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-pink .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-pink .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 187, 208, 0.3);
}
.pink-pink .mat-tab-group.mat-background-accent > .mat-tab-header, .pink-pink .mat-tab-group.mat-background-accent > .mat-tab-link-container, .pink-pink .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .pink-pink .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .pink-pink .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .pink-pink .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #e91e63;
}
.pink-pink .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .pink-pink .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .pink-pink .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .pink-pink .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.pink-pink .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .pink-pink .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .pink-pink .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .pink-pink .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.pink-pink .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.pink-pink .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.pink-pink .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.pink-pink .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .pink-pink .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.pink-pink .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.pink-pink .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.pink-pink .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.pink-pink .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.pink-pink .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .pink-pink .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.pink-pink .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.pink-pink .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.pink-pink .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.pink-pink .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .pink-pink .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.pink-pink .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.pink-pink .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.pink-pink .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-pink .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-pink .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-pink .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .pink-pink .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-pink .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-pink .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-pink .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.pink-pink .mat-tab-group.mat-background-warn > .mat-tab-header, .pink-pink .mat-tab-group.mat-background-warn > .mat-tab-link-container, .pink-pink .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .pink-pink .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .pink-pink .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .pink-pink .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #f44336;
}
.pink-pink .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .pink-pink .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .pink-pink .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .pink-pink .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.pink-pink .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .pink-pink .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .pink-pink .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .pink-pink .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.pink-pink .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.pink-pink .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.pink-pink .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.pink-pink .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .pink-pink .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.pink-pink .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.pink-pink .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.pink-pink .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.pink-pink .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.pink-pink .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .pink-pink .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.pink-pink .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.pink-pink .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.pink-pink .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.pink-pink .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .pink-pink .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.pink-pink .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.pink-pink .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.pink-pink .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink .mat-toolbar.mat-primary {
  background: #d81b60;
  color: white;
}
.pink-pink .mat-toolbar.mat-accent {
  background: #e91e63;
  color: white;
}
.pink-pink .mat-toolbar.mat-warn {
  background: #f44336;
  color: white;
}
.pink-pink .mat-toolbar .mat-form-field-underline,
.pink-pink .mat-toolbar .mat-form-field-ripple,
.pink-pink .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.pink-pink .mat-toolbar .mat-form-field-label,
.pink-pink .mat-toolbar .mat-focused .mat-form-field-label,
.pink-pink .mat-toolbar .mat-select-value,
.pink-pink .mat-toolbar .mat-select-arrow,
.pink-pink .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.pink-pink .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.pink-pink .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.pink-pink .mat-tree {
  background: white;
}
.pink-pink .mat-tree-node,
.pink-pink .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.pink-pink .mat-simple-snackbar-action {
  color: #e91e63;
}
.pink-pink *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.pink-pink *::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}
.pink-pink *::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}
.pink-pink .toolbar-line.container-ou {
  background: #d81b60;
  color: white;
}
.pink-pink .toolbar-line.container-persona {
  background: #d81b60 !important;
}
.pink-pink .toolbar-line.container-persona .persona-title {
  background: #d81b60;
}
.pink-pink .toolbar-line.container-persona .invisible-container.persona-selector {
  background: #d81b60 !important;
}
.pink-pink button.mat-icon-button:not([disabled]):hover {
  background: rgba(0, 0, 0, 0.04);
}
.pink-pink .side-bar {
  background: #eeeeee;
}
.pink-pink .icon-stacked {
  color: white !important;
}
.pink-pink .icon-stacked mat-icon + mat-icon {
  text-shadow: -1px 1px 0px #d81b60, 1px -1px 0px #d81b60;
}
.pink-pink .divider-vert {
  background: rgba(0, 0, 0, 0.12);
}
.pink-pink .avatar {
  background: #c2185b !important;
}
.pink-pink ngx-avatar .avatar-content {
  background: #c2185b !important;
}
.pink-pink .mat-loading-window {
  background: white;
}
.pink-pink .window-spinner {
  background: white;
}
.pink-pink .load-window {
  background: white;
}
@media (min-width: 600px) and (max-width: 1300px) {
  .pink-pink mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .load-window,
.pink-pink mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .load-window.new {
    background: white !important;
  }
  .pink-pink mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card {
    background: white !important;
  }
  .pink-pink mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .inbox-container mat-toolbar {
    background: #d81b60;
    color: white;
  }
  .pink-pink mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .inbox-container mat-list-item.mat-list-item {
    border: 1px solid rgba(0, 0, 0, 0.12);
  }
  .pink-pink mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .detail {
    background: white !important;
  }
  .pink-pink mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .detail mat-toolbar {
    background: white !important;
  }
  .pink-pink mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .main.w-detail + .new-detail-container .load-window {
    background: #fafafa !important;
  }
  .pink-pink mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .main.w-detail + .new-detail-container:before {
    background: rgba(0, 0, 0, 0.6);
  }
  .pink-pink mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .main.side-open + .side {
    border-left: 1px solid rgba(0, 0, 0, 0.12);
  }
  .pink-pink mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .side .container .mat-list-base {
    background: white !important;
    border: 1px solid rgba(0, 0, 0, 0.12);
  }
  .pink-pink mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .new-table .mat-header-row,
.pink-pink mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .new-table .mat-table,
.pink-pink mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .document-table .mat-header-row,
.pink-pink mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .document-table .mat-table,
.pink-pink mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .status-table .mat-header-row,
.pink-pink mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .status-table .mat-table {
    background: white !important;
  }
  .pink-pink mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .audit-filter .filter-container.is-floating {
    background: #fafafa !important;
    border: 1px solid rgba(0, 0, 0, 0.12);
  }
  .pink-pink mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .audit-filter .filter-container.is-floating .form-body {
    background: #fafafa !important;
  }
  .pink-pink mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .audit-result {
    background: white;
  }
  .pink-pink mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .audit-detail-container {
    background: #fafafa !important;
  }
}
@media (max-width: 599px) {
  .pink-pink app-document-detail .mat-toolbar {
    background: #c2185b !important;
    color: white !important;
  }
  .pink-pink app-document-detail .mat-toolbar .mat-toolbar-row .toolbar-actions {
    background: #c2185b !important;
    color: white !important;
  }
  .pink-pink .login-card {
    background: white !important;
  }
  .pink-pink .inbox-toolbar {
    background: #c2185b !important;
    color: white !important;
  }
  .pink-pink .detail {
    background: white url("../img/spinner_mobile.gif") no-repeat center center !important;
  }
  .pink-pink .toolbar-line-container .toolbar-line:first-child {
    background: #c2185b !important;
    color: white !important;
  }
  .pink-pink .toolbar-actions {
    background: #c2185b !important;
    color: white !important;
  }
  .pink-pink mat-drawer.side-nav.enter {
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12) !important;
  }
  .pink-pink .side-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0) !important;
  }
  .pink-pink .filter {
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.55) 16%, rgba(0, 0, 0, 0) 100%) !important;
  }
  .pink-pink button.side-open-mobile {
    color: white !important;
  }
  .pink-pink .side-nav .side-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0);
  }
  .pink-pink .side-nav .filter {
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.55) 16%, rgba(0, 0, 0, 0) 100%);
  }
  .pink-pink .side-nav .side-nav-container .links {
    background: white;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
  }
  .pink-pink .side-nav .side-nav-container .version {
    background: white !important;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
  }
  .pink-pink app-file-document-view .view-container {
    background: white !important;
  }
  .pink-pink app-file-document-view .view-container .side-bar {
    background: #c2185b !important;
    color: white !important;
  }
  .pink-pink app-file-document-view .view-container .view-content app-pdf-wrapper.floating {
    background: white;
  }
  .pink-pink app-file-document-view .view-container .view-content .upload-indications {
    background: #eeeeee;
  }
  .pink-pink app-file-document-view .view-container .view-content app-file-document-sign .sign-cycle mat-horizontal-stepper.mat-stepper-horizontal {
    background-color: rgba(0, 0, 0, 0) !important;
  }
  .pink-pink app-file-document-view .view-container .view-content app-file-document-sign .sign-cycle .sign-cycle-content .cycle-content-action .mat-ripple-element {
    background-color: rgba(255, 255, 255, 0.1) !important;
  }
  .pink-pink app-pending .floating-container .inbox-container .inbox-toolbar .mat-toolbar-row .mat-avatar h3 {
    color: white !important;
  }
  .pink-pink app-pending .floating-container .detail {
    background: white url("../img/spinner_mobile.gif") no-repeat center center !important;
  }
  .pink-pink app-document-sign-massive .inbox-container .pending-container app-chapa.minimized .info-empty {
    background: #e91e63;
  }
  .pink-pink app-document-sign-massive .inbox-container .pending-container app-chapa.minimized .info-empty > span h2 {
    color: white !important;
  }
  .pink-pink app-document-sign-massive .inbox-container .signer.mobile {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    background: white;
  }
  .pink-pink app-document-sign-massive .detail app-file-document-detail .view-container .mat-toolbar .mat-toolbar-row .view-actions {
    color: white !important;
  }
  .pink-pink app-add-documentation .floating-container .employee-add-documentation .inbox-container .inbox-toolbar .mat-toolbar-row .mat-avatar h3 {
    color: white !important;
  }
  .pink-pink app-add-documentation .floating-container .employee-add-documentation .detail {
    background: white url("../img/spinner_mobile.gif") no-repeat center center !important;
  }
  .pink-pink app-add-documentation .floating-container .employee-add-documentation .detail app-file-document-view .view-container .view-content .new-container app-file-document-upload {
    background: white;
  }
}
.pink-pink .mat-card .mat-drawer-container {
  background: white !important;
}
.pink-pink .mat-drawer-container {
  background: #e0e0e0 !important;
}
.pink-pink .mat-list .mat-list-item.finished-process-inbox.active {
  background: #4DB6AC !important;
}
.pink-pink .mat-list .mat-list-item.finished-process-inbox .mat-icon {
  background: #4DB6AC;
}
.pink-pink .mat-list .mat-list-item.erased-process-inbox.active {
  background: #837C80 !important;
}
.pink-pink .mat-list .mat-list-item.limbo-process-inbox.active {
  background: #FFD54F !important;
}
.pink-pink .mat-list .mat-list-item.limbo-process-inbox .mat-icon {
  background: #FFD54F;
}
.pink-pink .mat-list .mat-list-item.in-process-inbox.active {
  background: #FFD54F !important;
}
.pink-pink .mat-list .mat-list-item.in-process-inbox .mat-icon {
  background: #FFD54F;
}
.pink-pink .mat-list .mat-list-item.error-process-inbox.active {
  background: #F48FB1 !important;
}
.pink-pink .mat-list .mat-list-item.error-process-inbox .mat-icon {
  background: #F48FB1;
}
.pink-pink .mat-list .mat-list-item.active .mat-line b {
  color: #d81b60;
}
.pink-pink .mat-list .mat-list-item.active .mat-list-avatar {
  color: #d81b60;
}
.pink-pink .mat-list .mat-list-item:hover:not(.active) {
  background: rgba(0, 0, 0, 0.04);
}
.pink-pink span.mat-badge-content {
  color: #f44336 !important;
}
.pink-pink mat-expansion-panel.mat-expanded {
  background: #fafafa !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink mat-expansion-panel.virtual-scrolling .mat-expansion-panel-content .get-more {
  color: #d81b60;
}
.pink-pink mat-expansion-panel.virtual-scrolling .mat-expansion-panel-content app-cs-paginator.get-more {
  background: white;
}
.pink-pink mat-expansion-panel-header.mat-expanded {
  background: white !important;
}
.pink-pink .bottom-container {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink .load-more * {
  color: #d81b60;
}
.pink-pink .welcome-card {
  background: white !important;
}
.pink-pink .welcome-card .img-divider {
  background: #c2185b;
}
.pink-pink .mat-header-row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background: white;
}
.pink-pink .mat-row {
  background: white;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.pink-pink .mat-row .mat-cell.status-cell mat-icon.pending {
  color: #FF9800 !important;
}
.pink-pink .mat-row .mat-cell.status-cell mat-icon.ok {
  color: #009688 !important;
}
.pink-pink .mat-row .mat-cell.status-cell mat-icon.not-ok {
  color: #9C27B0 !important;
}
.pink-pink .mat-row .mat-cell.status-cell mat-icon.cancelled {
  color: #795548 !important;
}
.pink-pink .mat-row .mat-cell.status-cell mat-icon.rejected {
  color: #df4d4c !important;
}
.pink-pink .mat-row .mat-cell.status-cell mat-icon.draft {
  color: #dedede !important;
}
.pink-pink .mat-row.active, .pink-pink .mat-row.active:hover {
  background: #d81b60 !important;
}
.pink-pink .mat-row.active div, .pink-pink .mat-row.active:hover div {
  color: white;
}
.pink-pink .mat-row.active .mat-cell.status-cell mat-icon.pending, .pink-pink .mat-row.active .mat-cell.status-cell mat-icon.ok, .pink-pink .mat-row.active .mat-cell.status-cell mat-icon.not-ok, .pink-pink .mat-row.active:hover .mat-cell.status-cell mat-icon.pending, .pink-pink .mat-row.active:hover .mat-cell.status-cell mat-icon.ok, .pink-pink .mat-row.active:hover .mat-cell.status-cell mat-icon.not-ok {
  text-shadow: -1px 1px 0 white, 1px 1px 0 white, 1px -1px 0 white, -1px -1px 0 white;
}
.pink-pink .mat-row.active .sticky-actions button, .pink-pink .mat-row.active:hover .sticky-actions button {
  background: #d81b60 !important;
}
.pink-pink .mat-row.active .sticky-actions button.ok mat-icon, .pink-pink .mat-row.active .sticky-actions button.not-ok mat-icon, .pink-pink .mat-row.active:hover .sticky-actions button.ok mat-icon, .pink-pink .mat-row.active:hover .sticky-actions button.not-ok mat-icon {
  text-shadow: -1px 1px 0 white, 1px 1px 0 white, 1px -1px 0 white, -1px -1px 0 white;
}
.pink-pink .mat-row:nth-child(odd) {
  background: #fafafa;
}
.pink-pink .mat-row.selected div {
  color: white;
}
.pink-pink .mat-row.selected:nth-child(even) {
  background: #f48fb1 !important;
}
.pink-pink .mat-row.selected:nth-child(even) .sticky-actions button {
  background: #f48fb1 !important;
}
.pink-pink .mat-row.selected:nth-child(odd) {
  background: #f8bbd0 !important;
}
.pink-pink .mat-row.selected:nth-child(odd) .sticky-actions button {
  background: #f8bbd0 !important;
}
.pink-pink .mat-row.selected.active, .pink-pink .mat-row.selected.active:hover {
  background: #d81b60 !important;
}
.pink-pink .mat-row.selected.active .sticky-actions button, .pink-pink .mat-row.selected.active:hover .sticky-actions button {
  background: #d81b60 !important;
}
.pink-pink .mat-row:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-pink .mat-row:hover .sticky-actions button {
  background: white !important;
}
.pink-pink .mat-row.canceled * {
  color: #795548;
}
.pink-pink .mat-row.disabled div {
  color: #607D8B;
}
.pink-pink .mat-row.disabled.active, .pink-pink .mat-row.disabled.active:hover {
  background: #607D8B !important;
}
.pink-pink .mat-row.disabled.active div, .pink-pink .mat-row.disabled.active:hover div {
  color: white;
}
.pink-pink .mat-row.disabled.active .sticky-actions button, .pink-pink .mat-row.disabled.active:hover .sticky-actions button {
  background: #607D8B !important;
}
.pink-pink .mat-row.disabled.selected.active:hover .sticky-actions button {
  background: #607D8B !important;
}
.pink-pink .mat-row .sticky-actions button.ok mat-icon {
  color: #009688 !important;
}
.pink-pink .mat-row .sticky-actions button.not-ok mat-icon {
  color: #9C27B0 !important;
}
.pink-pink .status-table .mat-header-row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background: white;
}
.pink-pink .icon-info-off,
.pink-pink .certificate-off {
  color: #607D8B;
}
.pink-pink .documents-search-table .mat-header-row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background: white;
}
.pink-pink mat-toolbar.employee-search-sub-toolbar {
  background: white;
}
.pink-pink .mat-search-field {
  border: 1px solid rgba(0, 0, 0, 0.12);
  background: #eeeeee;
}
.pink-pink .mat-search-field input[type=search] {
  background: rgba(0, 0, 0, 0);
}
.pink-pink .mat-search-field input[type=search]::placeholder {
  color: rgba(0, 0, 0, 0.38);
}
.pink-pink .search-adv-menu .mat-search-field {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink mat-toolbar.document-search-sub-toolbar {
  background: white;
}
.pink-pink app-advanced-employee-search .mat-chip {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-pink .side-nav .side-trigger {
  background: white;
}
.pink-pink .side-nav .menu-trigger mat-icon {
  color: rgba(0, 0, 0, 0.54);
  background: white;
}
.pink-pink .side-nav .side-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0);
}
.pink-pink .side-nav .filter {
  background: white;
}
.pink-pink .side-nav .side-nav-container .links mat-icon span.mat-badge-content {
  color: white !important;
}
.pink-pink .side-nav .side-nav-container .mat-list-base .mat-list-item:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-pink .side-nav .side-nav-container .mat-list-base .mat-list-item.active {
  background: #d81b60 !important;
}
.pink-pink .side-nav .side-nav-container .mat-list-base .mat-list-item.active * {
  color: white;
}
.pink-pink .side-nav .side-nav-container .mat-list-base .mat-list-item.active .mat-list-item.active:hover {
  background: #d81b60 !important;
}
.pink-pink .side-nav .side-nav-container .mat-list-base .mat-list-item.active .mat-list-item.active:hover * {
  color: white;
}
.pink-pink .side-nav.open .side-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink .side-nav.open .filter {
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.55) 16%, rgba(0, 0, 0, 0) 100%);
}
.pink-pink .side-nav.open .side-nav-container .links {
  background: white;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink .side-nav.open .side-nav-container .version {
  background: white !important;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink .side-nav.enter .side-nav-container .links {
  background: white;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink .side-nav.enter .side-nav-container .version {
  background: white !important;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink .mat-menu-panel:not(.metadata-radio-menu) .navigation-list .mat-list-item-content {
  color: rgba(0, 0, 0, 0.54);
}
.pink-pink table.mat-calendar-table {
  background: white !important;
}
.pink-pink .mat-calendar-body-label {
  color: white !important;
}
.pink-pink .mat-calendar-arrow {
  border-top-color: white !important;
}
.pink-pink .mat-datepicker-content .mat-calendar-next-button,
.pink-pink .mat-datepicker-content .mat-calendar-previous-button {
  color: white !important;
}
.pink-pink .mat-calendar-header {
  background: #c2185b !important;
}
.pink-pink mat-datepicker-content {
  background-color: #c2185b !important;
  color: white !important;
}
.pink-pink .mat-calendar-content {
  background: white !important;
}
.pink-pink .mat-simple-snackbar-action button.mat-button {
  color: #FFE082;
}
.pink-pink .mat-simple-snackbar-action .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.pink-pink mat-bottom-sheet-container mat-toolbar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}
.pink-pink app-generic-bottom-sheet h4.accent {
  color: #e91e63;
}
.pink-pink app-generic-bottom-sheet .verification-code {
  background: #d81b60;
  color: white;
}
.pink-pink app-generic-bottom-sheet .verification-code h2 {
  background: #d81b60;
}
.pink-pink app-generic-bottom-sheet .load-window.message .info-container h3.primary-text {
  color: #d81b60;
}
.pink-pink app-generic-bottom-sheet .bottom_sheet-content.ok .bottom_sheet-title .bottom_sheet-icon,
.pink-pink app-generic-bottom-sheet .bottom_sheet-content.ok .bottom_sheet-title h3 {
  color: #009688;
}
.pink-pink app-generic-bottom-sheet .bottom_sheet-content.ok .bottom_sheet-main_btn:not(:disabled) {
  background: #009688;
  color: #fff;
}
.pink-pink app-generic-bottom-sheet .bottom_sheet-content.not-ok .bottom_sheet-title .bottom_sheet-icon,
.pink-pink app-generic-bottom-sheet .bottom_sheet-content.not-ok .bottom_sheet-title h3 {
  color: #9C27B0;
}
.pink-pink app-generic-bottom-sheet .bottom_sheet-content.not-ok .bottom_sheet-main_btn:not(:disabled) {
  background: #9C27B0;
  color: #fff;
}
.pink-pink app-generic-bottom-sheet .bottom_sheet-content.cancel .bottom_sheet-title .bottom_sheet-icon,
.pink-pink app-generic-bottom-sheet .bottom_sheet-content.cancel .bottom_sheet-title h3 {
  color: #795548;
}
.pink-pink app-generic-bottom-sheet .bottom_sheet-content.cancel .bottom_sheet-main_btn:not(:disabled) {
  background: #795548;
  color: #fff;
}
.pink-pink app-generic-bottom-sheet .bottom_sheet-content .bottom_sheet_exp_btn {
  background-color: #d81b60;
  color: #fff;
}
.pink-pink app-generic-bottom-sheet .bottom_sheet-content .bottom_sheet_canc_btn {
  background-color: lightgrey;
  color: black;
}
.pink-pink app-generic-bottom-sheet .bottom_sheet-content .bottom_sheet-exp-h3 {
  color: #d81b60;
  margin-bottom: -10px !important;
}
.pink-pink app-generic-bottom-sheet .bottom_sheet-content .mat-input-element-export {
  display: flex !important;
  padding-left: 15px;
}
.pink-pink app-generic-bottom-sheet .bottom_sheet-content .mat-form-field-infix-export-right {
  width: 100% !important;
  padding: 0px !important;
  display: flex !important;
}
.pink-pink app-generic-bottom-sheet .bottom_sheet-content .mat-form-field-infix-export-left {
  width: 100% !important;
  padding: 0px;
  display: flex !important;
}
.pink-pink app-generic-bottom-sheet .bottom_sheet-content .mat-form-field-infix {
  display: flex;
}
.pink-pink app-generic-bottom-sheet .bottom_sheet-content .mat-checkbox-layout .mat-checkbox-label {
  color: #e91e63;
}
.pink-pink app-generic-bottom-sheet .bottom_sheet-content .choose-forms {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  margin-left: 10px !important;
}
.pink-pink app-generic-bottom-sheet .bottom_sheet-content .card-container {
  max-height: 150px;
  overflow-y: auto;
}
.pink-pink .mat-list .mat-list-item.active .mat-line {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink .mat-list .mat-list-item.active .mat-line b {
  color: #d81b60;
}
.pink-pink .mat-list .mat-list-item.active .mat-list-avatar {
  color: #d81b60;
}
.pink-pink .mat-list .mat-list-item .mat-line {
  color: rgba(0, 0, 0, 0.54);
}
.pink-pink .mat-list .mat-list-item .mat-list-avatar {
  color: rgba(0, 0, 0, 0.54);
}
.pink-pink .mat-list-item:hover:not(.active) {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-pink mat-list-item:nth-child(even),
.pink-pink app-file-document-inbox-item:nth-child(even) mat-list-item,
.pink-pink app-file-document-inbox-item-documentation:nth-child(even) mat-list-item {
  background: #fafafa !important;
}
.pink-pink mat-list-item:nth-child(odd),
.pink-pink app-file-document-inbox-item:nth-child(odd) mat-list-item,
.pink-pink app-file-document-inbox-item-documentation:nth-child(odd) mat-list-item {
  background: white !important;
}
.pink-pink .mat-list-avatar.mat-icon {
  background: #eeeeee;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink .card-filter {
  background: white;
}
.pink-pink .login mat-horizontal-stepper {
  background: white !important;
}
.pink-pink .login .login-card {
  background: white !important;
}
.pink-pink .login .login-card .mat-card-content .mat-card-footer a[color=accent] {
  color: #e91e63;
}
.pink-pink .login .help-stepper h3.align-16 {
  color: #e91e63;
}
.pink-pink .login .help-stepper .help-options mat-icon {
  color: #e91e63;
}
.pink-pink .ux-modal {
  background: rgba(0, 0, 0, 0.32);
}
.pink-pink .ux-modal .card {
  background: white;
}
.pink-pink .main-title {
  background: #607D8B;
  color: white;
}
.pink-pink .sub-title {
  color: rgba(0, 0, 0, 0.54);
}
.pink-pink .card-footer a {
  color: white;
}
.pink-pink .profile-toolbar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink app-profile .logout-btn:hover {
  background: #eeeeee;
}
.pink-pink app-profile .cropper {
  background: white;
}
.pink-pink app-profile .cropper .cropper {
  background: unset;
}
.pink-pink app-add-documentation .employee-add-documentation .inbox-toolbar {
  background: #fafafa;
}
.pink-pink app-add-documentation .employee-add-documentation .inbox-toolbar .toolbar-line mat-icon {
  color: #d81b60;
}
.pink-pink app-add-documentation .employee-add-documentation .inbox-toolbar .toolbar-line h3 {
  color: #d81b60;
}
.pink-pink app-add-documentation .employee-add-documentation mat-tab-group {
  background: #fafafa;
}
.pink-pink app-add-documentation .employee-add-documentation mat-tab-group mat-tab-header .mat-tab-label-active {
  color: #d81b60;
}
.pink-pink app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content:hover {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12) !important;
}
.pink-pink app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content.selected {
  border-left: 6px solid #d81b60 !important;
}
.pink-pink app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content.selected mat-icon {
  color: #d81b60 !important;
}
.pink-pink app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content.selected .item-data {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-pink app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content mat-icon {
  color: rgba(0, 0, 0, 0.54);
}
.pink-pink app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content .item-data {
  color: rgba(0, 0, 0, 0.54);
}
.pink-pink app-add-documentation .employee-add-documentation .detail app-file-document-view .new-container app-file-document-upload .metadata-list .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
  background: white !important;
}
.pink-pink app-pending .inbox-toolbar {
  background: #fafafa;
}
.pink-pink app-pending .pending-container {
  background: #fafafa;
}
.pink-pink app-pending .pending-container .pending-items .pending-item .content:hover {
  background: rgba(0, 0, 0, 0.04) !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12) !important;
}
.pink-pink app-pending .pending-container .pending-items .pending-item .content.selected {
  border-left: 6px solid #e91e63 !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12) !important;
}
.pink-pink app-pending .pending-container .pending-items .pending-item .content.selected mat-icon {
  color: #e91e63 !important;
}
.pink-pink app-pending .pending-container .pending-items .pending-item .content.selected .item-data {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-pink app-pending .pending-container .pending-items .pending-item .content mat-icon {
  color: rgba(0, 0, 0, 0.54);
}
.pink-pink app-pending .pending-container .pending-items .pending-item .content .item-data {
  color: rgba(0, 0, 0, 0.54);
}
.pink-pink app-document-sign-massive .inbox-container .inbox-toolbar {
  background: #fafafa;
}
.pink-pink app-document-sign-massive .inbox-container .pending-container {
  background: #fafafa;
}
.pink-pink app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content:hover {
  background: rgba(0, 0, 0, 0.04) !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12) !important;
}
.pink-pink app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content.selected {
  border-left: 6px solid #e91e63 !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12) !important;
}
.pink-pink app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content.selected mat-icon {
  color: #e91e63 !important;
}
.pink-pink app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content.selected .item-data {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-pink app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content mat-icon {
  color: rgba(0, 0, 0, 0.54);
}
.pink-pink app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content .item-data {
  color: rgba(0, 0, 0, 0.54);
}
.pink-pink app-document-sign-massive .detail app-file-document-detail .view-container {
  background: #607D8B;
}
.pink-pink app-document-sign-massive .detail app-file-document-detail .view-container .mat-toolbar {
  background: #607D8B;
  color: white;
  background: #607D8B;
}
.pink-pink app-document-sign-massive .detail app-chapa.minimized .info-empty {
  background: #e91e63;
}
.pink-pink app-document-sign-massive .detail app-chapa.minimized .info-empty > span mat-icon {
  color: white !important;
}
.pink-pink app-document-sign-massive .detail app-chapa.minimized .info-empty > span h2 {
  color: white !important;
}
.pink-pink app-document-sign-massive .signer.desktop {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink app-document-sign-massive .signer .signer-btn {
  color: white !important;
}
.pink-pink app-document-sign-massive .signer .signer-btn[disabled] {
  background-color: rgba(0, 0, 0, 0.12);
}
.pink-pink app-document-detail app-sign-document .firmador button.mat-raised-button.mat-accent {
  color: white !important;
}
.pink-pink mat-toolbar-row.firmado-conforme .mat-icon {
  background: #8BC34A;
  color: white;
}
.pink-pink mat-toolbar-row.firmado-no-conforme .mat-icon {
  background: #f44336;
  color: white;
}
.pink-pink .secondary-toolbar {
  background: white;
}
.pink-pink .new-detail-container {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink .new-detail-container.cancelled .detail_head-body {
  background: #607D8B !important;
}
.pink-pink .new-detail-container .employee-detail-toolbar {
  background: #eeeeee;
}
.pink-pink .new-detail-container .employee-detail-subbar {
  background: white;
}
.pink-pink app-employee-detail .load-window,
.pink-pink app-employee-rrhh-detail .load-window {
  background: #fafafa;
}
.pink-pink app-employee-detail .employee_detail-container,
.pink-pink app-employee-rrhh-detail .employee_detail-container {
  background: #fafafa;
}
.pink-pink app-employee-detail .employee_detail-container.is-editing .edition-line,
.pink-pink app-employee-rrhh-detail .employee_detail-container.is-editing .edition-line {
  background: #eeeeee;
}
.pink-pink app-employee-detail .employee_detail-container .detail_head-body,
.pink-pink app-employee-rrhh-detail .employee_detail-container .detail_head-body {
  background: #d81b60;
}
.pink-pink app-employee-detail .employee_detail-container .detail_head-body .head_body-status .icon-stacked + mat-icon,
.pink-pink app-employee-rrhh-detail .employee_detail-container .detail_head-body .head_body-status .icon-stacked + mat-icon {
  color: white !important;
}
.pink-pink app-employee-detail .employee_detail-container .detail_head-body .head_body-title .body_title-name,
.pink-pink app-employee-rrhh-detail .employee_detail-container .detail_head-body .head_body-title .body_title-name {
  color: white;
}
.pink-pink app-employee-detail .employee_detail-container .detail_head-body .head_body-title .body_title-user,
.pink-pink app-employee-rrhh-detail .employee_detail-container .detail_head-body .head_body-title .body_title-user {
  color: white;
}
.pink-pink app-employee-detail .employee_detail-container .data .data-line,
.pink-pink app-employee-rrhh-detail .employee_detail-container .data .data-line {
  background: white;
}
.pink-pink app-employee-detail .employee_detail-container .data .data-line .icon-complex mat-icon.complex,
.pink-pink app-employee-rrhh-detail .employee_detail-container .data .data-line .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px white, 0 -2px 0px white, 2px 0 0px white, 0 2px 0px white, -2px -2px 0px white, 2px 2px 0px white, 2px -2px 0px white, -2px 2px 0px white;
}
.pink-pink app-employee-detail .employee_detail-container .data .data-line .line_text-main,
.pink-pink app-employee-rrhh-detail .employee_detail-container .data .data-line .line_text-main {
  color: #d81b60;
}
.pink-pink app-employee-detail .employee_detail-container .data .data-line.clickable:hover,
.pink-pink app-employee-rrhh-detail .employee_detail-container .data .data-line.clickable:hover {
  background: #fafafa !important;
}
.pink-pink app-employee-detail .employee_detail-container .data .data-line button.mat-icon-button,
.pink-pink app-employee-rrhh-detail .employee_detail-container .data .data-line button.mat-icon-button {
  background: #eeeeee;
}
.pink-pink app-employee-detail .employee_detail-container .employee-title .title-text,
.pink-pink app-employee-rrhh-detail .employee_detail-container .employee-title .title-text {
  color: #d81b60;
}
.pink-pink app-employee-detail .employee_detail-container .employee-title .title-text mat-icon,
.pink-pink app-employee-rrhh-detail .employee_detail-container .employee-title .title-text mat-icon {
  color: #d81b60;
}
.pink-pink app-employee-detail .employee_detail-container .employee-title .title-text .title_text-main,
.pink-pink app-employee-rrhh-detail .employee_detail-container .employee-title .title-text .title_text-main {
  color: #d81b60;
}
.pink-pink .UI-V2 .icon-complex mat-icon.complex {
  color: #d81b60;
  text-shadow: -2px 0 0px white, 0 -2px 0px white, 2px 0 0px white, 0 2px 0px white, -2px -2px 0px white, 2px 2px 0px white, 2px -2px 0px white, -2px 2px 0px white;
}
.pink-pink .UI-V2 .icon-complex mat-icon.complex.mat-accent {
  color: #e91e63;
}
.pink-pink .UI-V2 .main .toolbar .header .content .module-title {
  color: #d81b60;
}
.pink-pink .UI-V2 .main .toolbar .header .content .module-title.selectable:hover {
  background: rgba(0, 0, 0, 0.04);
}
.pink-pink .UI-V2 .main .toolbar .header .content .module-title .info h5 {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-pink .UI-V2 .main .toolbar .header .content .ou-select {
  color: white;
  background: #d81b60;
}
.pink-pink .UI-V2 .main .toolbar .search-container .search .content {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink .UI-V2 .main .toolbar .search-container .search .content:hover, .pink-pink .UI-V2 .main .toolbar .search-container .search .content:active, .pink-pink .UI-V2 .main .toolbar .search-container .search .content:focus, .pink-pink .UI-V2 .main .toolbar .search-container .search .content:focus-within {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-pink .UI-V2 .main .toolbar .search-container .search .content input {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink .UI-V2 .main .helper {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink .UI-V2 .main .helper mat-hint h4 {
  color: #d81b60;
}
.pink-pink .UI-V2 .main .new-table app-cs-grid-control datatable-header * {
  color: #d81b60;
}
.pink-pink .UI-V2 .main .new-table .mat-header-row.sticky * {
  color: #d81b60 !important;
}
.pink-pink .UI-V2 .main .main-action {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink .menu-options.help .primary {
  color: #d81b60;
}
.pink-pink .menu-options.help .accent {
  color: #e91e63;
}
.pink-pink .menu-options .icon-complex mat-icon.complex {
  color: #d81b60;
  text-shadow: -2px 0 0px white, 0 -2px 0px white, 2px 0 0px white, 0 2px 0px white, -2px -2px 0px white, 2px 2px 0px white, 2px -2px 0px white, -2px 2px 0px white;
}
.pink-pink .menu-options .mat-menu-content .container mat-list .mat-list-item:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-pink .menu-options .mat-menu-content .container mat-list .mat-list-item .disabled {
  color: rgba(0, 0, 0, 0.54) !important;
}
.pink-pink .menu-options .mat-menu-content .container .slide-group mat-radio-group mat-radio-button:hover {
  background: rgba(0, 0, 0, 0.04);
}
.pink-pink .menu-options .mat-menu-content .container .slide-group mat-form-field .mat-form-field-flex {
  background: #fafafa;
}
.pink-pink .menu-options .mat-menu-content .container .slide-group mat-slide-toggle:hover {
  background: rgba(0, 0, 0, 0.04);
}
.pink-pink .mat-menu-panel.notifications-panel .mat-menu-content .notifications-body {
  background: #fafafa;
}
.pink-pink .mat-menu-panel.notifications-panel .mat-menu-content .notification .primary mat-icon {
  color: #d81b60;
}
.pink-pink .mat-menu-panel.notifications-panel .mat-menu-content .notification .primary h3 {
  color: #d81b60;
}
.pink-pink .mat-menu-panel.notifications-panel .mat-menu-content .notification.top {
  background: #fafafa;
}
.pink-pink .mat-menu-panel.notifications-panel .mat-menu-content .notification.top .header h3 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink .mat-menu-panel.notifications-panel .mat-menu-content .notification.bottom {
  background: #fafafa;
}
.pink-pink .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable:nth-child(even) {
  background: #fafafa;
}
.pink-pink .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable:nth-child(odd) {
  background: white;
}
.pink-pink .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable:hover {
  background: rgba(0, 0, 0, 0.04);
}
.pink-pink .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable mat-icon {
  color: #d81b60;
}
.pink-pink .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable h3 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable h5 {
  color: rgba(0, 0, 0, 0.38);
}
.pink-pink .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable .subtitle h5 {
  color: #d81b60;
  background: #eeeeee;
}
.pink-pink .info-empty .large-icon {
  background: #f8bbd0;
  color: #d81b60;
}
.pink-pink .info-empty h2,
.pink-pink .info-empty h3 {
  color: #d81b60;
}
.pink-pink .info-empty h4,
.pink-pink .info-empty h5 {
  color: rgba(0, 0, 0, 0.54);
}
.pink-pink .info-empty.accent mat-icon {
  background: #f8bbd0;
  color: #e91e63;
}
.pink-pink .info-empty.accent h2 {
  color: #e91e63;
}
.pink-pink .info-empty .actions {
  padding: 16px;
  height: 96px;
  column-gap: 16px;
}
.pink-pink .info-empty .actions button {
  height: 100%;
  border-radius: 40px;
}
.pink-pink .info-empty .actions button.ok {
  background: #009688 !important;
  color: white;
}
.pink-pink .info-empty .actions button.not-ok {
  color: #9C27B0 !important;
}
.pink-pink .side.edition .info-empty .large-icon {
  background: #f8bbd0;
  color: #e91e63;
}
.pink-pink .side.edition .info-empty h2 {
  color: #e91e63;
}
.pink-pink .side.edition .info-empty h4 {
  color: rgba(0, 0, 0, 0.54);
}
.pink-pink .UI-V2 .deferred-process-list .header h3 {
  color: #d81b60;
}
.pink-pink .UI-V2 .deferred-process-list .search-container .search .content {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink .UI-V2 .deferred-process-list .search-container .search .content:hover, .pink-pink .UI-V2 .deferred-process-list .search-container .search .content:active, .pink-pink .UI-V2 .deferred-process-list .search-container .search .content:focus, .pink-pink .UI-V2 .deferred-process-list .search-container .search .content:focus-within {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-pink .UI-V2 .deferred-process-list .search-container .search .content input {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink .UI-V2 .deferred-process-list .text-success,
.pink-pink .UI-V2 .deferred-process-details .text-success {
  color: #8BC34A;
}
.pink-pink .UI-V2 .deferred-process-list .text-warn,
.pink-pink .UI-V2 .deferred-process-details .text-warn {
  color: #f44336;
}
.pink-pink .UI-V2 .deferred-process-list .text-primary,
.pink-pink .UI-V2 .deferred-process-details .text-primary {
  color: #d81b60;
}
.pink-pink .UI-V2 .deferred-process-list .text-atention,
.pink-pink .UI-V2 .deferred-process-details .text-atention {
  color: #DB7703;
}
.pink-pink .UI-V2 .deferred-process-list .table-paginator-container,
.pink-pink .UI-V2 .deferred-process-details .table-paginator-container {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
}
.pink-pink .UI-V2 .deferred-process-list .table-paginator-container .mat-table,
.pink-pink .UI-V2 .deferred-process-details .table-paginator-container .mat-table {
  border-bottom: none;
  border-radius: 10px 10px 0 0;
}
.pink-pink .UI-V2 .deferred-process-list .table-paginator-container .paginator-container,
.pink-pink .UI-V2 .deferred-process-details .table-paginator-container .paginator-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: #f2f2f2;
  padding: 0 1.5rem;
  height: 3rem;
}
.pink-pink .UI-V2 .deferred-process-list .mat-table,
.pink-pink .UI-V2 .deferred-process-details .mat-table {
  border: 1px solid #f2f2f2;
  border-radius: 10px;
  overflow: hidden;
}
.pink-pink .UI-V2 .deferred-process-list .mat-table .mat-header-row .mat-header-cell,
.pink-pink .UI-V2 .deferred-process-details .mat-table .mat-header-row .mat-header-cell {
  font-size: 14px;
  font-weight: 700;
}
.pink-pink .UI-V2 .deferred-process-list .mat-table .mat-header-row,
.pink-pink .UI-V2 .deferred-process-details .mat-table .mat-header-row {
  background: #f2f2f2;
}
.pink-pink .UI-V2 .deferred-process-list .mat-table .mat-header-row .mat-header-cell,
.pink-pink .UI-V2 .deferred-process-details .mat-table .mat-header-row .mat-header-cell {
  color: #d81b60;
}
.pink-pink .UI-V2 .deferred-process-list .mat-table .mat-row,
.pink-pink .UI-V2 .deferred-process-details .mat-table .mat-row {
  background: white;
}
.pink-pink .UI-V2 .progress-bar-container {
  position: relative;
}
.pink-pink .UI-V2 .deferred-process-details .header h3 {
  color: #d81b60;
}
.pink-pink .UI-V2 .deferred-process-details .header .back-link:visited {
  color: #d81b60;
}
.pink-pink .process-card,
.pink-pink .process-card-detail,
.pink-pink .process-card-stages {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink .process-card mat-card-subtitle,
.pink-pink .process-card-detail mat-card-subtitle,
.pink-pink .process-card-stages mat-card-subtitle {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink .process-card .stages-container .stage.completed mat-icon,
.pink-pink .process-card-detail .stages-container .stage.completed mat-icon,
.pink-pink .process-card-stages .stages-container .stage.completed mat-icon {
  color: #499719;
}
.pink-pink .process-card .stages-container .stage.completed .icon-container,
.pink-pink .process-card-detail .stages-container .stage.completed .icon-container,
.pink-pink .process-card-stages .stages-container .stage.completed .icon-container {
  background-color: rgba(78, 160, 28, 0.1490196078);
}
.pink-pink .process-card .stages-container .stage.completed .line,
.pink-pink .process-card-detail .stages-container .stage.completed .line,
.pink-pink .process-card-stages .stages-container .stage.completed .line {
  background-color: #499719;
}
.pink-pink .process-card .stages-container .stage.on-going mat-icon,
.pink-pink .process-card-detail .stages-container .stage.on-going mat-icon,
.pink-pink .process-card-stages .stages-container .stage.on-going mat-icon {
  color: #d81b60;
}
.pink-pink .process-card .stages-container .stage.on-going .icon-container,
.pink-pink .process-card-detail .stages-container .stage.on-going .icon-container,
.pink-pink .process-card-stages .stages-container .stage.on-going .icon-container {
  background-color: rgba(66, 48, 159, 0.2);
}
.pink-pink .process-card .stages-container .stage.on-going .line,
.pink-pink .process-card-detail .stages-container .stage.on-going .line,
.pink-pink .process-card-stages .stages-container .stage.on-going .line {
  background-color: #949494;
}
.pink-pink .process-card .stages-container .stage.pending,
.pink-pink .process-card-detail .stages-container .stage.pending,
.pink-pink .process-card-stages .stages-container .stage.pending {
  color: #949494;
}
.pink-pink .process-card .stages-container .stage.pending mat-icon,
.pink-pink .process-card-detail .stages-container .stage.pending mat-icon,
.pink-pink .process-card-stages .stages-container .stage.pending mat-icon {
  color: #949494;
}
.pink-pink .process-card .stages-container .stage.pending .icon-container,
.pink-pink .process-card-detail .stages-container .stage.pending .icon-container,
.pink-pink .process-card-stages .stages-container .stage.pending .icon-container {
  background-color: rgba(174, 174, 174, 0.2);
}
.pink-pink .process-card .stages-container .stage.pending .line,
.pink-pink .process-card-detail .stages-container .stage.pending .line,
.pink-pink .process-card-stages .stages-container .stage.pending .line {
  background-color: #949494;
}
.pink-pink .process-card .stages-container .stage.has-error mat-icon,
.pink-pink .process-card-detail .stages-container .stage.has-error mat-icon,
.pink-pink .process-card-stages .stages-container .stage.has-error mat-icon {
  color: #f44336;
}
.pink-pink .process-card .stages-container .stage.has-error .icon-container,
.pink-pink .process-card-detail .stages-container .stage.has-error .icon-container,
.pink-pink .process-card-stages .stages-container .stage.has-error .icon-container {
  background-color: rgba(188, 4, 4, 0.2);
}
.pink-pink .process-card .stages-container .stage-description .button-link,
.pink-pink .process-card-detail .stages-container .stage-description .button-link,
.pink-pink .process-card-stages .stages-container .stage-description .button-link {
  color: #d81b60;
}
.pink-pink app-custom-search-input .custom-search-container {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
  transition: color 0.1s ease, background 0.15s ease;
}
.pink-pink app-custom-search-input .custom-search-container mat-icon.mat-icon {
  color: rgba(0, 0, 0, 0.38);
}
.pink-pink app-custom-search-input .custom-search-container input.custom-search-input {
  color: rgba(0, 0, 0, 0.87);
  background: unset;
}
.pink-pink app-custom-search-input .custom-search-container:hover, .pink-pink app-custom-search-input .custom-search-container:active, .pink-pink app-custom-search-input .custom-search-container:focus, .pink-pink app-custom-search-input .custom-search-container:focus-within {
  background: rgba(0, 0, 0, 0.04) !important;
  transition: background 0.15s ease;
}
.pink-pink .UI-V2 .audit-filter {
  background: white;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink .UI-V2 .audit-filter .toolbar {
  background: #d81b60;
  color: white;
}
.pink-pink .UI-V2 .audit-filter .toolbar .header .content .module-title {
  color: #d81b60;
}
.pink-pink .UI-V2 .audit-filter .toolbar .header .content .module-title.selectable:hover {
  background: rgba(0, 0, 0, 0.04);
}
.pink-pink .UI-V2 .audit-filter .toolbar .header .content .module-title .info * {
  color: white !important;
}
.pink-pink .UI-V2 .audit-filter .toolbar .header .content .ou-select {
  color: white;
  background: #d81b60;
}
.pink-pink .UI-V2 .audit-filter .toolbar .search-container .search .content {
  background: #fafafa;
}
.pink-pink .UI-V2 .audit-filter .toolbar .search-container .search .content:hover, .pink-pink .UI-V2 .audit-filter .toolbar .search-container .search .content:active, .pink-pink .UI-V2 .audit-filter .toolbar .search-container .search .content:focus, .pink-pink .UI-V2 .audit-filter .toolbar .search-container .search .content:focus-within {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-pink .UI-V2 .audit-filter .toolbar .search-container .search .content input {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink .UI-V2 .audit-filter .filter-container {
  background: white !important;
}
.pink-pink .UI-V2 .audit-filter .filter-container .form-body {
  background: #fafafa !important;
}
.pink-pink .UI-V2 .audit-filter .filter-container .form-body .line h4 {
  color: #d81b60;
}
.pink-pink .UI-V2 .audit-filter .filter-container .form-body .line mat-icon {
  color: #d81b60;
}
.pink-pink .UI-V2 .audit-result .result-container .toolbar {
  color: white;
}
.pink-pink .UI-V2 .audit-result .result-container .toolbar .header .content .module-title {
  color: #d81b60;
}
.pink-pink .UI-V2 .audit-result .result-container .toolbar .header .content .module-title.selectable:hover {
  background: rgba(0, 0, 0, 0.04);
}
.pink-pink .UI-V2 .audit-result .result-container .toolbar .header .content .module-title .info h5 {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-pink .UI-V2 .audit-result .result-container .toolbar .header .content .ou-select {
  color: white;
  background: #d81b60;
}
.pink-pink .UI-V2 .audit-result .result-container .toolbar .search-container .search .content {
  background: #fafafa;
}
.pink-pink .UI-V2 .audit-result .result-container .toolbar .search-container .search .content:hover, .pink-pink .UI-V2 .audit-result .result-container .toolbar .search-container .search .content:active, .pink-pink .UI-V2 .audit-result .result-container .toolbar .search-container .search .content:focus, .pink-pink .UI-V2 .audit-result .result-container .toolbar .search-container .search .content:focus-within {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-pink .UI-V2 .audit-result .result-container .toolbar .search-container .search .content input {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink .UI-V2 .audit-result .result-container .new-table app-cs-grid-control datatable-header * {
  color: #d81b60;
}
.pink-pink .UI-V2 .audit-result .result-container .new-table .mat-header-row.sticky * {
  color: #d81b60 !important;
}
.pink-pink .UI-V2 .audit-detail-container {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  background: #eeeeee !important;
}
.pink-pink .UI-V2 .audit-detail-container .side .toolbar .header {
  color: #d81b60;
}
.pink-pink .UI-V2 .audit-detail-container .side .container .mat-list-base {
  background: #fafafa !important;
}
.pink-pink .UI-V2 .audit-detail-container .side .container .mat-list-base mat-list-item .mat-line b {
  color: #d81b60;
}
.pink-pink .UI-V2 .inbox-container {
  background: white;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink .UI-V2 .inbox-container *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.pink-pink .UI-V2 .inbox-container *::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}
.pink-pink .UI-V2 .inbox-container *::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}
.pink-pink .UI-V2 .inbox-container .load-window {
  background: white;
}
.pink-pink .UI-V2 .inbox-container .toolbar {
  background: #d81b60;
  color: white;
}
.pink-pink .UI-V2 .inbox-container .toolbar.reverse {
  background: white;
  color: rgba(0, 0, 0, 0.54);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel {
  background: #eeeeee !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel.mat-expanded {
  background: #fafafa !important;
}
.pink-pink .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel.mat-expanded mat-expansion-panel-header {
  background: #fafafa !important;
}
.pink-pink .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel.mat-expanded mat-expansion-panel-header:hover {
  background: none !important;
}
.pink-pink .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel mat-expansion-panel-header {
  background: #fafafa !important;
}
.pink-pink .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel mat-expansion-panel-header:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-pink .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel mat-expansion-panel-header .signature-inbox-header .mat-icon,
.pink-pink .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel mat-expansion-panel-header .signature-inbox-header b {
  color: #e91e63;
}
.pink-pink .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel .mat-expansion-panel-body .item {
  background: white;
  color: rgba(0, 0, 0, 0.54);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel .mat-expansion-panel-body .item:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-pink .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel .mat-expansion-panel-body .item.active {
  border-left: 6px solid #e91e63;
}
.pink-pink .UI-V2 .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.pink-pink .UI-V2 .work-space .main .load-window {
  background: white !important;
}
.pink-pink .UI-V2 .work-space .main-action {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink mat-radio-button.disabled .mat-radio-label-content {
  color: #e91e63;
}
.pink-pink .detail app-automatic-process-detail app-chapa.minimize,
.pink-pink .detail app-process-detail app-chapa.minimize {
  background: #FFD54F !important;
}
.pink-pink .detail app-automatic-process-detail app-chapa.minimize.purged,
.pink-pink .detail app-process-detail app-chapa.minimize.purged {
  background: #b2adb0 !important;
}
.pink-pink .detail .detail-toolbar {
  background: #eeeeee;
}
.pink-pink .detail .processes-status .status-bar .bar-graph {
  background-color: #FFD54F;
}
.pink-pink .detail .processes-status .status-bar .bar-graph .graph-ok {
  background-color: #4DB6AC;
}
.pink-pink .detail .processes-status .status-bar .bar-graph .graph-not-ok {
  background-color: #F48FB1;
}
.pink-pink .detail .processes-status .status-resume b {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink .detail .processes-status .status-resume .resume-total b {
  background-color: #FFD54F;
}
.pink-pink .detail .processes-status .status-resume .resume-ok b {
  background-color: #4DB6AC;
}
.pink-pink .detail .processes-status .status-resume .resume-not-ok b {
  background-color: #F48FB1;
}
.pink-pink .detail .detail-toolbar .mat-avatar h3,
.pink-pink .detail .detail-toolbar .mat-avatar h5 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink .detail .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink .detail .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink .detail .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink .detail .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink .detail .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  color: white;
}
.pink-pink .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  background: #4DB6AC !important;
}
.pink-pink .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  background: #b2dfdb !important;
}
.pink-pink .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  background: #b2dfdb !important;
}
.pink-pink .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  background: #4DB6AC !important;
}
.pink-pink .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  background: #b2dfdb !important;
}
.pink-pink .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  background: #837C80 !important;
}
.pink-pink .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  background: #b2adb0 !important;
}
.pink-pink .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  background: #b2adb0 !important;
}
.pink-pink .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  background: #837C80 !important;
}
.pink-pink .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  background: #b2dfdb !important;
}
.pink-pink .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  background: #FFD54F !important;
}
.pink-pink .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  background: #ffecb3 !important;
}
.pink-pink .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  background: #ffecb3 !important;
}
.pink-pink .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  background: #FFD54F !important;
}
.pink-pink .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  background: #ffecb3 !important;
}
.pink-pink .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  background: #F48FB1 !important;
}
.pink-pink .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  background: #f8bbd0 !important;
}
.pink-pink .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  background: #f8bbd0 !important;
}
.pink-pink .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  background: #F48FB1 !important;
}
.pink-pink .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  background: #f8bbd0 !important;
}
.pink-pink app-analytics-document-set .sets-container .mat-list-base.sets-list mat-list-item {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink app-analytics-document-set .sets-container .mat-list-base.sets-list mat-list-item .mat-list-item-content .set-status .value-percentile .undone-value {
  background: #673AB7;
}
.pink-pink app-analytics-document-set .sets-container .mat-list-base.sets-list mat-list-item .mat-list-item-content .set-status .value-percentile .not-value {
  background: #eeeeee;
}
.pink-pink app-analytics-document-set .sets-container .mat-list-base.sets-list mat-list-item .mat-list-item-content .set-status .value-percentile .done-value {
  background: #009688;
}
.pink-pink app-analytics-document-set .work-space .main .toolbar .header .content .module-title .info .info-text .set-status {
  cursor: help;
}
.pink-pink app-analytics-document-set .work-space .main .toolbar .header .content .module-title .info .info-text .set-status .value-percentile .undone-value {
  background: #673AB7;
}
.pink-pink app-analytics-document-set .work-space .main .toolbar .header .content .module-title .info .info-text .set-status .value-percentile .not-value {
  background: #eeeeee;
}
.pink-pink app-analytics-document-set .work-space .main .toolbar .header .content .module-title .info .info-text .set-status .value-percentile .done-value {
  background: #009688;
}
.pink-pink app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar .mat-progress-bar-buffer {
  background-color: #eeeeee;
}
.pink-pink app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar.class1 .mat-progress-bar-fill::after {
  background-color: #f44336;
}
.pink-pink app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar.class2 .mat-progress-bar-fill::after {
  background-color: #ff9800;
}
.pink-pink app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar.class3 .mat-progress-bar-fill::after {
  background-color: #ffeb3b;
}
.pink-pink app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar.class4 .mat-progress-bar-fill::after {
  background-color: #8bc34a;
}
.pink-pink app-analytics-document-set .work-space .side .container mat-list {
  background: white !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink app-analytics-document-set .work-space .side .container mat-list mat-list-item .mat-line b {
  color: #d81b60;
}
.pink-pink .catcher {
  background: rgba(0, 0, 0, 0.6);
}
.pink-pink .catcher.clear {
  background: rgba(0, 0, 0, 0);
}
.pink-pink .add-menu-form-body .mat-radio-group .mat-radio-button:hover {
  background: rgba(0, 0, 0, 0.04);
}
.pink-pink .add-menu-form-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink .add-menu-form-body.w-table .helper .search-bar {
  background: #fafafa;
}
.pink-pink .add-menu-form-body.w-table .helper .search-bar:hover, .pink-pink .add-menu-form-body.w-table .helper .search-bar:active, .pink-pink .add-menu-form-body.w-table .helper .search-bar:focus, .pink-pink .add-menu-form-body.w-table .helper .search-bar:focus-within {
  background: #fafafa !important;
}
.pink-pink .add-menu-form-body.w-table .helper .search-bar input {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink .add-menu-form-actions {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink .file-upload-list mat-list-item {
  background: white;
}
.pink-pink .mat-horizontal-stepper-content[aria-expanded=true] app-file-document-view {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink .mat-horizontal-stepper-content[aria-expanded=true] app-file-document-view .pdf-view-container {
  background: #eeeeee;
}
.pink-pink .add-docs-drop .docs-drop-preview app-file-document-detail {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink .add-docs-drop .docs-drop-preview app-file-document-detail .view-toolbar {
  background: white;
}
.pink-pink .add-docs-drop .load-window.message h3.primary-text {
  color: #d81b60;
}
.pink-pink mat-dialog-container app-add-menu-dialog .load-window.message h3.primary-text {
  color: #d81b60;
}
.pink-pink .asisted-input.aligned-icon.subtitle * {
  color: rgba(0, 0, 0, 0.38) !important;
}
.pink-pink .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble {
  background: #d81b60;
}
.pink-pink .asisted-input .mat-form-field-invalid + .indicator > .bubble {
  background: #f44336;
}
.pink-pink .asisted-input .mat-form-field-disabled + .indicator > .bubble {
  color: rgba(0, 0, 0, 0.38);
  background: rgba(0, 0, 0, 0.12);
}
.pink-pink .asisted-input .indicator .bubble {
  background: rgba(0, 0, 0, 0.38);
  color: white;
}
.pink-pink .asisted-input .indicator .guide {
  border-color: rgba(0, 0, 0, 0.12);
}
.pink-pink .asisted-input.icon-only .bubble {
  color: rgba(0, 0, 0, 0.54);
}
.pink-pink .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble {
  color: #d81b60;
}
.pink-pink .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble {
  color: #f44336;
}
.pink-pink .UI-V2 .main-overlay {
  background: rgba(0, 0, 0, 0.32);
}
.pink-pink .UI-V2 .icon-complex mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.pink-pink .UI-V2 .icon-complex mat-icon.complex {
  color: #d81b60 !important;
}
.pink-pink .UI-V2 .navigation {
  background: #fafafa;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink .UI-V2 .navigation *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.pink-pink .UI-V2 .navigation *::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}
.pink-pink .UI-V2 .navigation .load-window {
  background: #eeeeee;
}
.pink-pink .UI-V2 .navigation .toolbar {
  background: #d81b60;
  color: white;
}
.pink-pink .UI-V2 .navigation .toolbar.reverse {
  background: white;
  color: rgba(0, 0, 0, 0.54);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink .UI-V2 .navigation .container .helper {
  color: rgba(0, 0, 0, 0.54);
}
.pink-pink .UI-V2 .navigation .container .item {
  background: white;
  color: rgba(0, 0, 0, 0.54);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink .UI-V2 .navigation .container .item:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-pink .UI-V2 .navigation .container .item.active {
  background: #d81b60 !important;
  color: white;
  border: 1px solid #d81b60;
}
.pink-pink .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .separator .subtitle,
.pink-pink .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .separator .subtitle,
.pink-pink .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .separator .subtitle,
.pink-pink .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .separator .subtitle,
.pink-pink .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .separator .subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.pink-pink .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .img-editior-container .img-editor .separator .subtitle,
.pink-pink .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .img-editior-container .img-editor .separator .subtitle,
.pink-pink .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .img-editior-container .img-editor .separator .subtitle,
.pink-pink .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .img-editior-container .img-editor .separator .subtitle,
.pink-pink .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .img-editior-container .img-editor .separator .subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.pink-pink .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme,
.pink-pink .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme,
.pink-pink .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme,
.pink-pink .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme,
.pink-pink .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .main-color-container .main-color,
.pink-pink .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .main-color-container .main-color,
.pink-pink .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .main-color-container .main-color,
.pink-pink .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .main-color-container .main-color,
.pink-pink .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .main-color-container .main-color {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .main-color-container .main-color:before,
.pink-pink .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .main-color-container .main-color:before,
.pink-pink .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .main-color-container .main-color:before,
.pink-pink .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .main-color-container .main-color:before,
.pink-pink .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .main-color-container .main-color:before {
  border-left: 10px solid rgba(0, 0, 0, 0);
  border-right: 10px solid rgba(0, 0, 0, 0);
}
.pink-pink .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example h6.required,
.pink-pink .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example h6.required,
.pink-pink .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example h6.required,
.pink-pink .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example h6.required,
.pink-pink .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example h6.required {
  color: #f44336 !important;
}
.pink-pink .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle,
.pink-pink .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle,
.pink-pink .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle,
.pink-pink .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle,
.pink-pink .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle {
  border: 2px solid rgba(0, 0, 0, 0);
}
.pink-pink .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .side-color-container .side-color,
.pink-pink .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .side-color-container .side-color,
.pink-pink .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .side-color-container .side-color,
.pink-pink .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .side-color-container .side-color,
.pink-pink .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .side-color-container .side-color {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .side-color-container .side-color:before,
.pink-pink .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .side-color-container .side-color:before,
.pink-pink .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .side-color-container .side-color:before,
.pink-pink .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .side-color-container .side-color:before,
.pink-pink .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .side-color-container .side-color:before {
  border-top: 10px solid rgba(0, 0, 0, 0);
  border-bottom: 10px solid rgba(0, 0, 0, 0);
}
.pink-pink .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .side-color-container .side-color h6.required,
.pink-pink .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .side-color-container .side-color h6.required,
.pink-pink .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .side-color-container .side-color h6.required,
.pink-pink .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .side-color-container .side-color h6.required,
.pink-pink .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .side-color-container .side-color h6.required {
  color: #f44336 !important;
}
.pink-pink .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .permission-settings-cards .card,
.pink-pink .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .permission-settings-cards .card,
.pink-pink .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .permission-settings-cards .card,
.pink-pink .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .permission-settings-cards .card,
.pink-pink .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .permission-settings-cards .card {
  background: #fafafa;
}
.pink-pink .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .permission-settings-cards .card .slide-sub-group,
.pink-pink .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .permission-settings-cards .card .slide-sub-group,
.pink-pink .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .permission-settings-cards .card .slide-sub-group,
.pink-pink .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .permission-settings-cards .card .slide-sub-group,
.pink-pink .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .permission-settings-cards .card .slide-sub-group {
  background: rgba(0, 0, 0, 0.12);
}
.pink-pink .UI-V2 .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.pink-pink .UI-V2 .work-space .main .load-window {
  background: white !important;
}
.pink-pink .UI-V2 .work-space .main.side-open {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item mat-icon.complex {
  text-shadow: -2px 0 0px #eeeeee, 0 -2px 0px #eeeeee, 2px 0 0px #eeeeee, 0 2px 0px #eeeeee, -2px -2px 0px #eeeeee, 2px 2px 0px #eeeeee, 2px -2px 0px #eeeeee, -2px 2px 0px #eeeeee !important;
}
.pink-pink .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item h5 {
  color: rgba(0, 0, 0, 0.38);
}
.pink-pink .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item h3 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item .actions {
  pointer-events: none;
}
.pink-pink .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item .actions button {
  pointer-events: all;
}
.pink-pink .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled {
  background: #eeeeee;
}
.pink-pink .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled h3 {
  color: #607D8B !important;
}
.pink-pink .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled mat-icon {
  color: #607D8B !important;
}
.pink-pink .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled.selected {
  background: #607D8B;
  color: white !important;
}
.pink-pink .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled.selected h3 {
  color: white !important;
}
.pink-pink .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled.selected mat-icon {
  color: white !important;
}
.pink-pink .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled.selected mat-icon.complex {
  color: white !important;
  text-shadow: -2px 0 0px #607D8B, 0 -2px 0px #607D8B, 2px 0 0px #607D8B, 0 2px 0px #607D8B, -2px -2px 0px #607D8B, 2px 2px 0px #607D8B, 2px -2px 0px #607D8B, -2px 2px 0px #607D8B !important;
}
.pink-pink .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item:hover {
  background: rgba(0, 0, 0, 0.04);
}
.pink-pink .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected {
  background: #d81b60;
  color: white;
}
.pink-pink .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected mat-icon {
  color: white;
}
.pink-pink .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected mat-icon.complex {
  color: white !important;
  text-shadow: -2px 0 0px #d81b60, 0 -2px 0px #d81b60, 2px 0 0px #d81b60, 0 2px 0px #d81b60, -2px -2px 0px #d81b60, 2px 2px 0px #d81b60, 2px -2px 0px #d81b60, -2px 2px 0px #d81b60 !important;
}
.pink-pink .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected h5,
.pink-pink .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected h3 {
  color: white;
}
.pink-pink .UI-V2 .work-space .main .main-action {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink .UI-V2 .work-space .side {
  background: #fafafa;
}
.pink-pink .UI-V2 .work-space .side .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #fafafa, 0 -2px 0px #fafafa, 2px 0 0px #fafafa, 0 2px 0px #fafafa, -2px -2px 0px #fafafa, 2px 2px 0px #fafafa, 2px -2px 0px #fafafa, -2px 2px 0px #fafafa;
}
.pink-pink .UI-V2 .work-space .side *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.pink-pink .UI-V2 .work-space .side *::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}
.pink-pink .UI-V2 .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #fafafa !important;
}
.pink-pink .UI-V2 .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(0, 0, 0, 0.38);
}
.pink-pink .UI-V2 .work-space .side mat-form-field .mat-form-field-flex {
  background: #fafafa;
}
.pink-pink .UI-V2 .work-space .side mat-checkbox:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-pink .UI-V2 .work-space .side .hint.all {
  color: #e91e63;
}
.pink-pink .UI-V2 .work-space .side .toolbar .header {
  color: #d81b60;
}
.pink-pink .UI-V2 .work-space .side .toolbar .header h3 {
  color: #d81b60;
}
.pink-pink .UI-V2 .work-space .side .container .role-resume {
  background: #d81b60;
}
.pink-pink .UI-V2 .work-space .side .container .role-resume .line {
  color: white;
}
.pink-pink .UI-V2 .work-space .side .container .role-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.1);
}
.pink-pink .UI-V2 .work-space .side .container .role-resume .line.editable button {
  background: #d81b60;
}
.pink-pink .UI-V2 .work-space .side .container .section-title {
  color: #d81b60;
}
.pink-pink .UI-V2 .work-space .side .container .section-resume {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink .UI-V2 .work-space .side .container .section-resume .line.editable:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-pink .UI-V2 .work-space .side .container .section-resume .line.editable:hover .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px white, 0 -2px 0px white, 2px 0 0px white, 0 2px 0px white, -2px -2px 0px white, 2px 2px 0px white, 2px -2px 0px white, -2px 2px 0px white !important;
}
.pink-pink .UI-V2 .work-space .side .container .section-resume .line.editable button {
  background: #fafafa;
}
.pink-pink .UI-V2 .work-space .side .container .section-resume .line .header .icon-complex mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.pink-pink .UI-V2 .work-space .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #d81b60 !important;
}
.pink-pink .UI-V2 .work-space .side .container .section-resume .line .header mat-icon:not(.complex) {
  color: rgba(0, 0, 0, 0.54);
}
.pink-pink .UI-V2 .work-space .side .container .section-resume .line .header .info h4 {
  color: #d81b60;
}
.pink-pink .UI-V2 .work-space .side .container .section-resume .line ul {
  color: #d81b60;
}
.pink-pink .UI-V2 .work-space .side .container .section-resume .line.head {
  background: #eeeeee;
}
.pink-pink .UI-V2 .work-space .side .container .section-resume .line.head mat-icon {
  color: #e91e63;
}
.pink-pink .UI-V2 .work-space .side .container .section-resume .line.head h4 {
  color: white;
}
.pink-pink .UI-V2 .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body {
  background: white;
}
.pink-pink .UI-V2 .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: rgba(0, 0, 0, 0.54);
}
.pink-pink .UI-V2 .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #d81b60;
}
.pink-pink .UI-V2 .work-space .side.edition .toolbar .header {
  color: white;
}
.pink-pink .UI-V2 .work-space .side.edition .toolbar .header mat-icon {
  color: #e91e63;
}
.pink-pink .UI-V2 .work-space .side.edition .toolbar .header h3 {
  color: #e91e63;
}
.pink-pink .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body {
  background: white !important;
}
.pink-pink .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header mat-icon:not(.complex) {
  color: #d81b60;
}
.pink-pink .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header h4 {
  color: #d81b60;
}
.pink-pink .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-actions {
  background: #fafafa;
}
.pink-pink .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.pink-pink .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header .icon-complex mat-icon.complex {
  color: #e91e63 !important;
}
.pink-pink .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header h4 {
  color: #e91e63;
}
.pink-pink .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line ul {
  color: #e91e63;
  font-weight: 400;
}
.pink-pink .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head mat-icon {
  color: #e91e63 !important;
}
.pink-pink .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head h4 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .section-title {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-pink .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume h6.hint {
  background: #f8bbd0;
}
.pink-pink app-role-user-find .main-overlay {
  background: rgba(0, 0, 0, 0.32);
}
.pink-pink app-role-user-find .icon-complex mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.pink-pink app-role-user-find .icon-complex mat-icon.complex {
  color: #d81b60 !important;
}
.pink-pink app-role-user-find .navigation {
  background: #fafafa;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink app-role-user-find .navigation *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.pink-pink app-role-user-find .navigation *::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}
.pink-pink app-role-user-find .navigation .load-window {
  background: #eeeeee;
}
.pink-pink app-role-user-find .navigation .toolbar {
  background: #d81b60;
  color: white;
}
.pink-pink app-role-user-find .navigation .toolbar.reverse {
  background: white;
  color: rgba(0, 0, 0, 0.54);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink app-role-user-find .navigation .container .helper {
  color: rgba(0, 0, 0, 0.54);
}
.pink-pink app-role-user-find .navigation .container .item {
  background: white;
  color: rgba(0, 0, 0, 0.54);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink app-role-user-find .navigation .container .item:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-pink app-role-user-find .navigation .container .item.active {
  background: #d81b60 !important;
  color: white;
  border: 1px solid #d81b60;
}
.pink-pink app-role-user-find .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.pink-pink app-role-user-find .work-space .main .load-window {
  background: white !important;
}
.pink-pink app-role-user-find .work-space .main.side-open {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink app-role-user-find .work-space .main .container .item {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink app-role-user-find .work-space .main .container .item .subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.pink-pink app-role-user-find .work-space .main .container .item.disabled {
  background: #eeeeee;
  color: #607D8B !important;
}
.pink-pink app-role-user-find .work-space .main .container .item.disabled h3 {
  color: #607D8B !important;
}
.pink-pink app-role-user-find .work-space .main .container .item.disabled mat-icon {
  color: #607D8B !important;
}
.pink-pink app-role-user-find .work-space .main .container .item.disabled.selected {
  background: #607D8B;
  color: white !important;
}
.pink-pink app-role-user-find .work-space .main .container .item.disabled.selected h3 {
  color: white !important;
}
.pink-pink app-role-user-find .work-space .main .container .item.disabled.selected mat-icon {
  color: white !important;
}
.pink-pink app-role-user-find .work-space .main .container .item.disabled.selected mat-icon.complex {
  color: white !important;
  text-shadow: -2px 0 0px #607D8B, 0 -2px 0px #607D8B, 2px 0 0px #607D8B, 0 2px 0px #607D8B, -2px -2px 0px #607D8B, 2px 2px 0px #607D8B, 2px -2px 0px #607D8B, -2px 2px 0px #607D8B !important;
}
.pink-pink app-role-user-find .work-space .main .container .item mat-icon.complex {
  text-shadow: -2px 0 0px #eeeeee, 0 -2px 0px #eeeeee, 2px 0 0px #eeeeee, 0 2px 0px #eeeeee, -2px -2px 0px #eeeeee, 2px 2px 0px #eeeeee, 2px -2px 0px #eeeeee, -2px 2px 0px #eeeeee !important;
}
.pink-pink app-role-user-find .work-space .main .container .item h5 {
  color: rgba(0, 0, 0, 0.38);
}
.pink-pink app-role-user-find .work-space .main .container .item h3 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink app-role-user-find .work-space .main .container .item:hover {
  background: rgba(0, 0, 0, 0.04);
}
.pink-pink app-role-user-find .work-space .main .container .item.selected {
  background: #d81b60;
  color: white;
}
.pink-pink app-role-user-find .work-space .main .container .item.selected mat-icon {
  color: white;
}
.pink-pink app-role-user-find .work-space .main .container .item.selected mat-icon.complex {
  color: white !important;
  text-shadow: -2px 0 0px #d81b60, 0 -2px 0px #d81b60, 2px 0 0px #d81b60, 0 2px 0px #d81b60, -2px -2px 0px #d81b60, 2px 2px 0px #d81b60, 2px -2px 0px #d81b60, -2px 2px 0px #d81b60 !important;
}
.pink-pink app-role-user-find .work-space .main .container .item.selected h5,
.pink-pink app-role-user-find .work-space .main .container .item.selected h3 {
  color: white;
}
.pink-pink app-role-user-find .work-space .main .main-action {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink app-role-user-find .work-space .side {
  background: #fafafa;
}
.pink-pink app-role-user-find .work-space .side .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #fafafa, 0 -2px 0px #fafafa, 2px 0 0px #fafafa, 0 2px 0px #fafafa, -2px -2px 0px #fafafa, 2px 2px 0px #fafafa, 2px -2px 0px #fafafa, -2px 2px 0px #fafafa;
}
.pink-pink app-role-user-find .work-space .side *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.pink-pink app-role-user-find .work-space .side *::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}
.pink-pink app-role-user-find .work-space .side .edition-line {
  background: #eeeeee;
}
.pink-pink app-role-user-find .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #fafafa !important;
}
.pink-pink app-role-user-find .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(0, 0, 0, 0.38);
}
.pink-pink app-role-user-find .work-space .side mat-form-field .mat-form-field-flex {
  background: #fafafa;
}
.pink-pink app-role-user-find .work-space .side mat-checkbox:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-pink app-role-user-find .work-space .side .hint.all {
  color: #e91e63;
}
.pink-pink app-role-user-find .work-space .side .toolbar .header {
  color: #d81b60;
}
.pink-pink app-role-user-find .work-space .side .toolbar .header h3 {
  color: #d81b60;
}
.pink-pink app-role-user-find .work-space .side .container .role-resume {
  background: #d81b60;
}
.pink-pink app-role-user-find .work-space .side .container .role-resume .line {
  color: white;
}
.pink-pink app-role-user-find .work-space .side .container .role-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.1);
}
.pink-pink app-role-user-find .work-space .side .container .role-resume .line.editable button {
  background: #d81b60;
}
.pink-pink app-role-user-find .work-space .side .container .section-title {
  color: #d81b60;
}
.pink-pink app-role-user-find .work-space .side .container .section-resume {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink app-role-user-find .work-space .side .container .section-resume .line.editable:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-pink app-role-user-find .work-space .side .container .section-resume .line.editable:hover .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px white, 0 -2px 0px white, 2px 0 0px white, 0 2px 0px white, -2px -2px 0px white, 2px 2px 0px white, 2px -2px 0px white, -2px 2px 0px white !important;
}
.pink-pink app-role-user-find .work-space .side .container .section-resume .line.editable button {
  background: #fafafa;
}
.pink-pink app-role-user-find .work-space .side .container .section-resume .line .header .icon-complex mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.pink-pink app-role-user-find .work-space .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #d81b60 !important;
}
.pink-pink app-role-user-find .work-space .side .container .section-resume .line .header mat-icon:not(.complex) {
  color: rgba(0, 0, 0, 0.54);
}
.pink-pink app-role-user-find .work-space .side .container .section-resume .line .header .info h4 {
  color: #d81b60;
}
.pink-pink app-role-user-find .work-space .side .container .section-resume .line ul {
  color: #d81b60;
}
.pink-pink app-role-user-find .work-space .side .container .section-resume .line.head {
  background: #eeeeee;
}
.pink-pink app-role-user-find .work-space .side .container .section-resume .line.head mat-icon {
  color: #e91e63;
}
.pink-pink app-role-user-find .work-space .side .container .section-resume .line.head h4 {
  color: white;
}
.pink-pink app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee-title {
  color: #d81b60;
}
.pink-pink app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body {
  background: white;
}
.pink-pink app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: rgba(0, 0, 0, 0.54);
}
.pink-pink app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #d81b60;
}
.pink-pink app-role-user-find .work-space .side.edition .toolbar .header {
  color: white;
}
.pink-pink app-role-user-find .work-space .side.edition .toolbar .header mat-icon {
  color: #e91e63;
}
.pink-pink app-role-user-find .work-space .side.edition .toolbar .header h3 {
  color: #e91e63;
}
.pink-pink app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body {
  background: white !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header mat-icon:not(.complex) {
  color: #d81b60;
}
.pink-pink app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header h4 {
  color: #d81b60;
}
.pink-pink app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-actions {
  background: #fafafa;
}
.pink-pink app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.pink-pink app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header .icon-complex mat-icon.complex {
  color: #e91e63 !important;
}
.pink-pink app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header h4 {
  color: #e91e63;
}
.pink-pink app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line ul {
  color: #e91e63;
}
.pink-pink app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head mat-icon {
  color: #e91e63 !important;
}
.pink-pink app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head h4 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .section-title {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-pink app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume h6.hint {
  background: #f8bbd0;
}
.pink-pink app-role-user-find .work-space app-role-user-detail.disabled .side .toolbar .header {
  color: #607D8B !important;
}
.pink-pink app-role-user-find .work-space app-role-user-detail.disabled .side .container .role-resume {
  background: #607D8B !important;
}
.pink-pink app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-title {
  color: #607D8B !important;
}
.pink-pink app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line ul {
  color: #607D8B !important;
}
.pink-pink app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header mat-icon {
  color: #607D8B;
}
.pink-pink app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .icon-complex mat-icon {
  color: #607D8B !important;
}
.pink-pink app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #607D8B !important;
}
.pink-pink app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .info h4 {
  color: #607D8B !important;
}
.pink-pink app-role-user-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee-title {
  color: #607D8B;
}
.pink-pink app-role-user-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: #607D8B;
}
.pink-pink app-role-user-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #607D8B;
}
.pink-pink app-actor-find .main-overlay {
  background: rgba(0, 0, 0, 0.32);
}
.pink-pink app-actor-find .icon-complex mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.pink-pink app-actor-find .icon-complex mat-icon.complex {
  color: #d81b60 !important;
}
.pink-pink app-actor-find .navigation {
  background: #fafafa;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink app-actor-find .navigation *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.pink-pink app-actor-find .navigation *::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}
.pink-pink app-actor-find .navigation .load-window {
  background: #eeeeee;
}
.pink-pink app-actor-find .navigation .toolbar {
  background: #d81b60;
  color: white;
}
.pink-pink app-actor-find .navigation .toolbar.reverse {
  background: white;
  color: rgba(0, 0, 0, 0.54);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink app-actor-find .navigation .container .helper {
  color: rgba(0, 0, 0, 0.54);
}
.pink-pink app-actor-find .navigation .container .item {
  background: white;
  color: rgba(0, 0, 0, 0.54);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink app-actor-find .navigation .container .item:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-pink app-actor-find .navigation .container .item.active {
  background: #d81b60 !important;
  color: white;
  border: 1px solid #d81b60;
}
.pink-pink app-actor-find .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.pink-pink app-actor-find .work-space .main .load-window {
  background: white !important;
}
.pink-pink app-actor-find .work-space .main.side-open {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink app-actor-find .work-space .main .container .item {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink app-actor-find .work-space .main .container .item .subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.pink-pink app-actor-find .work-space .main .container .item.disabled {
  background: #eeeeee;
  color: #607D8B !important;
}
.pink-pink app-actor-find .work-space .main .container .item.disabled h3 {
  color: #607D8B !important;
}
.pink-pink app-actor-find .work-space .main .container .item.disabled mat-icon {
  color: #607D8B !important;
}
.pink-pink app-actor-find .work-space .main .container .item.disabled.selected {
  background: #607D8B;
  color: white !important;
}
.pink-pink app-actor-find .work-space .main .container .item.disabled.selected h3 {
  color: white !important;
}
.pink-pink app-actor-find .work-space .main .container .item.disabled.selected mat-icon {
  color: white !important;
}
.pink-pink app-actor-find .work-space .main .container .item.disabled.selected mat-icon.complex {
  color: white !important;
  text-shadow: -2px 0 0px #607D8B, 0 -2px 0px #607D8B, 2px 0 0px #607D8B, 0 2px 0px #607D8B, -2px -2px 0px #607D8B, 2px 2px 0px #607D8B, 2px -2px 0px #607D8B, -2px 2px 0px #607D8B !important;
}
.pink-pink app-actor-find .work-space .main .container .item mat-icon.complex {
  text-shadow: -2px 0 0px #eeeeee, 0 -2px 0px #eeeeee, 2px 0 0px #eeeeee, 0 2px 0px #eeeeee, -2px -2px 0px #eeeeee, 2px 2px 0px #eeeeee, 2px -2px 0px #eeeeee, -2px 2px 0px #eeeeee !important;
}
.pink-pink app-actor-find .work-space .main .container .item h5 {
  color: rgba(0, 0, 0, 0.38);
}
.pink-pink app-actor-find .work-space .main .container .item h3 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink app-actor-find .work-space .main .container .item:hover {
  background: rgba(0, 0, 0, 0.04);
}
.pink-pink app-actor-find .work-space .main .container .item.selected {
  background: #d81b60;
  color: white;
}
.pink-pink app-actor-find .work-space .main .container .item.selected mat-icon {
  color: white;
}
.pink-pink app-actor-find .work-space .main .container .item.selected mat-icon.complex {
  color: white !important;
  text-shadow: -2px 0 0px #d81b60, 0 -2px 0px #d81b60, 2px 0 0px #d81b60, 0 2px 0px #d81b60, -2px -2px 0px #d81b60, 2px 2px 0px #d81b60, 2px -2px 0px #d81b60, -2px 2px 0px #d81b60 !important;
}
.pink-pink app-actor-find .work-space .main .container .item.selected h5,
.pink-pink app-actor-find .work-space .main .container .item.selected h3 {
  color: white;
}
.pink-pink app-actor-find .work-space .main .main-action {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink app-actor-find .work-space .side {
  background: #fafafa;
}
.pink-pink app-actor-find .work-space .side .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #fafafa, 0 -2px 0px #fafafa, 2px 0 0px #fafafa, 0 2px 0px #fafafa, -2px -2px 0px #fafafa, 2px 2px 0px #fafafa, 2px -2px 0px #fafafa, -2px 2px 0px #fafafa;
}
.pink-pink app-actor-find .work-space .side *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.pink-pink app-actor-find .work-space .side *::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}
.pink-pink app-actor-find .work-space .side .edition-line {
  background: #eeeeee;
}
.pink-pink app-actor-find .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #fafafa !important;
}
.pink-pink app-actor-find .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(0, 0, 0, 0.38);
}
.pink-pink app-actor-find .work-space .side mat-form-field .mat-form-field-flex {
  background: #fafafa;
}
.pink-pink app-actor-find .work-space .side mat-checkbox:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-pink app-actor-find .work-space .side .hint.all {
  color: #e91e63;
}
.pink-pink app-actor-find .work-space .side .toolbar .header {
  color: #d81b60;
}
.pink-pink app-actor-find .work-space .side .toolbar .header h3 {
  color: #d81b60;
}
.pink-pink app-actor-find .work-space .side .container .role-resume {
  background: #d81b60;
}
.pink-pink app-actor-find .work-space .side .container .role-resume .line {
  color: white;
}
.pink-pink app-actor-find .work-space .side .container .role-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.1);
}
.pink-pink app-actor-find .work-space .side .container .role-resume .line.editable button {
  background: #d81b60;
}
.pink-pink app-actor-find .work-space .side .container .section-title {
  color: #d81b60;
}
.pink-pink app-actor-find .work-space .side .container .section-resume {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink app-actor-find .work-space .side .container .section-resume .line.editable:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-pink app-actor-find .work-space .side .container .section-resume .line.editable:hover .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px white, 0 -2px 0px white, 2px 0 0px white, 0 2px 0px white, -2px -2px 0px white, 2px 2px 0px white, 2px -2px 0px white, -2px 2px 0px white !important;
}
.pink-pink app-actor-find .work-space .side .container .section-resume .line.editable button {
  background: #fafafa;
}
.pink-pink app-actor-find .work-space .side .container .section-resume .line .header .icon-complex mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.pink-pink app-actor-find .work-space .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #d81b60 !important;
}
.pink-pink app-actor-find .work-space .side .container .section-resume .line .header mat-icon:not(.complex) {
  color: rgba(0, 0, 0, 0.54);
}
.pink-pink app-actor-find .work-space .side .container .section-resume .line .header .info h4 {
  color: #d81b60;
}
.pink-pink app-actor-find .work-space .side .container .section-resume .line ul {
  color: #d81b60;
}
.pink-pink app-actor-find .work-space .side .container .section-resume .line.head {
  background: #eeeeee;
}
.pink-pink app-actor-find .work-space .side .container .section-resume .line.head mat-icon {
  color: #e91e63;
}
.pink-pink app-actor-find .work-space .side .container .section-resume .line.head h4 {
  color: white;
}
.pink-pink app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee-title {
  color: #d81b60;
}
.pink-pink app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body {
  background: white;
}
.pink-pink app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: rgba(0, 0, 0, 0.54);
}
.pink-pink app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #d81b60;
}
.pink-pink app-actor-find .work-space .side.edition .toolbar .header {
  color: white;
}
.pink-pink app-actor-find .work-space .side.edition .toolbar .header mat-icon {
  color: #e91e63;
}
.pink-pink app-actor-find .work-space .side.edition .toolbar .header h3 {
  color: #e91e63;
}
.pink-pink app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body {
  background: white !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header mat-icon:not(.complex) {
  color: #d81b60;
}
.pink-pink app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header h4 {
  color: #d81b60;
}
.pink-pink app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-actions {
  background: #fafafa;
}
.pink-pink app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.pink-pink app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header .icon-complex mat-icon.complex {
  color: #e91e63 !important;
}
.pink-pink app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header h4 {
  color: #e91e63;
}
.pink-pink app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line ul {
  color: #e91e63;
}
.pink-pink app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head mat-icon {
  color: #e91e63 !important;
}
.pink-pink app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head h4 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .section-title {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-pink app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume h6.hint {
  background: #f8bbd0;
}
.pink-pink app-actor-find .work-space app-role-user-detail.disabled .side .toolbar .header {
  color: #607D8B !important;
}
.pink-pink app-actor-find .work-space app-role-user-detail.disabled .side .container .role-resume {
  background: #607D8B !important;
}
.pink-pink app-actor-find .work-space app-role-user-detail.disabled .side .container .section-title {
  color: #607D8B !important;
}
.pink-pink app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line ul {
  color: #607D8B !important;
}
.pink-pink app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header mat-icon {
  color: #607D8B;
}
.pink-pink app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .icon-complex mat-icon {
  color: #607D8B !important;
}
.pink-pink app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #607D8B !important;
}
.pink-pink app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .info h4 {
  color: #607D8B !important;
}
.pink-pink app-actor-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee-title {
  color: #607D8B;
}
.pink-pink app-actor-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: #607D8B;
}
.pink-pink app-actor-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #607D8B;
}
.pink-pink .dropzone {
  border: 2px dashed #607D8B;
  background: #eeeeee !important;
}
.pink-pink .dropzone.hovering {
  border: 2px solid #607D8B;
  color: rgba(0, 0, 0, 0.38) !important;
}
.pink-pink .dropzone button.mat-stroked-button {
  background: white;
}
.pink-pink .firmador .content-action-main {
  color: white !important;
}
.pink-pink .firmador .content-action-main.disabled {
  color: rgba(0, 0, 0, 0.38) !important;
  background: rgba(0, 0, 0, 0.12);
}
.pink-pink .sign-cycle {
  background: white;
}
.pink-pink .sign-cycle button.success {
  background-color: #009688;
}
.pink-pink .sign-cycle button.warning {
  background-color: #673AB7;
}
.pink-pink .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-title-container .cycle-content-title h4 {
  color: #607D8B;
}
.pink-pink .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-action .content-action-main {
  color: white !important;
}
.pink-pink .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-action .content-action-main.mat-stroked-button:disabled {
  color: #9e9e9e !important;
}
.pink-pink .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-action .content-action-main.mat-stroked-button:not(:disabled) {
  color: #e91e63 !important;
}
.pink-pink .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-action .content-action-side u {
  color: #9e9e9e;
}
.pink-pink .resume-status {
  background: white;
}
.pink-pink .help-hoverable mat-icon {
  color: rgba(0, 0, 0, 0.54);
}
.pink-pink .help-hoverable mat-hint {
  background: white;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.pink-pink .help-hoverable mat-hint b {
  color: #d81b60 !important;
}
.pink-pink app-file-document-sign-massive-dialog .dialog-content,
.pink-pink app-share-person-dialog .dialog-content {
  background: #fafafa;
}
.pink-pink app-file-document-sign-massive-dialog .dialog-content mat-toolbar mat-toolbar-row .mat-avatar h3,
.pink-pink app-share-person-dialog .dialog-content mat-toolbar mat-toolbar-row .mat-avatar h3 {
  color: #d81b60;
}
.pink-pink app-file-document-sign-massive-dialog .dialog-content .dialog-body p b,
.pink-pink app-share-person-dialog .dialog-content .dialog-body p b {
  color: #e91e63;
}
.pink-pink app-file-document-sign-massive-dialog .dialog-content app-file-document-sign,
.pink-pink app-share-person-dialog .dialog-content app-file-document-sign {
  background: white;
}
.pink-pink .mat-basic-chip {
  background: #eeeeee !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink .mat-basic-chip.seen {
  border-color: #2196F3 !important;
  color: #2196F3 !important;
}
.pink-pink .mat-basic-chip.unseen {
  border-color: #607D8B !important;
  color: #607D8B !important;
}
.pink-pink .mat-basic-chip.signed-ok {
  border-color: #009688 !important;
  color: #009688 !important;
}
.pink-pink .mat-basic-chip.signed-ok.cancelled {
  border-color: #795548 !important;
  color: #795548 !important;
}
.pink-pink .mat-basic-chip.signed-not-ok {
  border-color: #673AB7 !important;
  color: #673AB7 !important;
}
.pink-pink .mat-basic-chip.not-signed {
  border-color: #ff4081 !important;
  color: #ff4081 !important;
}
.pink-pink .mat-basic-chip.not-signed-employer {
  border-color: #ff4081 !important;
  color: #ff4081 !important;
}
.pink-pink app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip {
  background: #eeeeee !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.seen {
  border-color: #2196F3 !important;
  color: #2196F3 !important;
}
.pink-pink app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.unseen {
  border-color: #607D8B !important;
  color: #607D8B !important;
}
.pink-pink app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.signed-ok {
  border-color: #009688 !important;
  color: #009688 !important;
}
.pink-pink app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.signed-ok.cancelled {
  border-color: #795548 !important;
  color: #795548 !important;
}
.pink-pink app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.signed-not-ok {
  border-color: #673AB7 !important;
  color: #673AB7 !important;
}
.pink-pink app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.not-signed {
  border-color: #ff4081 !important;
  color: #ff4081 !important;
}
.pink-pink app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.not-signed-employer {
  border-color: #ff4081 !important;
  color: #ff4081 !important;
}
.pink-pink .inbox-container.employee.my-documents .chip-line.mat-line .mat-chip-list-wrapper .mat-basic-chip.seen {
  color: #607D8B !important;
}
.pink-pink .resume-status .mat-basic-chip {
  background: #eeeeee !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink .resume-status .mat-basic-chip.signed-ok {
  border-color: #009688 !important;
  background-color: #009688 !important;
  color: white !important;
}
.pink-pink .resume-status .mat-basic-chip.signed-ok.cancelled {
  border-color: #795548 !important;
  background-color: #795548 !important;
  color: white !important;
}
.pink-pink .resume-status .mat-basic-chip.signed-not-ok {
  border-color: #673AB7 !important;
  background-color: #673AB7 !important;
  color: white !important;
}
.pink-pink app-file-document-view .new-container app-file-document-upload {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink app-file-document-view .new-container app-upload-form .file-upload-list .mat-subheader {
  background: white;
}
.pink-pink app-file-document-view .new-container app-upload-form .file-upload-list mat-list-item {
  background: #fafafa !important;
}
.pink-pink app-file-document-view .preview-content {
  background: #607D8B;
}
.pink-pink app-file-document-view .preview-content h4 {
  color: white;
}
.pink-pink app-file-document-view .view-container {
  background: white;
}
.pink-pink app-file-document-view .view-container mat-toolbar.side-bar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink .formio-hint {
  background: #d81b60;
  color: white;
}
.pink-pink mat-dialog-container app-file-document-state-chips {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink app-candidate-promotion .load-window.message h3.primary-text {
  color: #d81b60;
}
.pink-pink app-multiple-documentation-add-dialog .icon-complex mat-icon,
.pink-pink app-documentation-type-set-configuration-dialog .icon-complex mat-icon,
.pink-pink app-add-candidate-dialog .icon-complex mat-icon,
.pink-pink app-share-person-dialog .icon-complex mat-icon,
.pink-pink app-share-person-response-dialog .icon-complex mat-icon {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-pink app-multiple-documentation-add-dialog .icon-complex mat-icon.complex,
.pink-pink app-documentation-type-set-configuration-dialog .icon-complex mat-icon.complex,
.pink-pink app-add-candidate-dialog .icon-complex mat-icon.complex,
.pink-pink app-share-person-dialog .icon-complex mat-icon.complex,
.pink-pink app-share-person-response-dialog .icon-complex mat-icon.complex {
  color: #d81b60 !important;
}
.pink-pink app-multiple-documentation-add-dialog .icon-complex mat-icon.complex.mat-accent,
.pink-pink app-documentation-type-set-configuration-dialog .icon-complex mat-icon.complex.mat-accent,
.pink-pink app-add-candidate-dialog .icon-complex mat-icon.complex.mat-accent,
.pink-pink app-share-person-dialog .icon-complex mat-icon.complex.mat-accent,
.pink-pink app-share-person-response-dialog .icon-complex mat-icon.complex.mat-accent {
  color: #e91e63;
}
.pink-pink app-multiple-documentation-add-dialog .icon-complex mat-icon.complex.side,
.pink-pink app-documentation-type-set-configuration-dialog .icon-complex mat-icon.complex.side,
.pink-pink app-add-candidate-dialog .icon-complex mat-icon.complex.side,
.pink-pink app-share-person-dialog .icon-complex mat-icon.complex.side,
.pink-pink app-share-person-response-dialog .icon-complex mat-icon.complex.side {
  color: rgba(0, 0, 0, 0.54);
}
.pink-pink app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection,
.pink-pink app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection,
.pink-pink app-add-candidate-dialog .dialog-content .document-template-container .document-selection,
.pink-pink app-share-person-dialog .dialog-content .document-template-container .document-selection,
.pink-pink app-share-person-response-dialog .dialog-content .document-template-container .document-selection {
  background: #fafafa;
}
.pink-pink app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.pink-pink app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.pink-pink app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3,
.pink-pink app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.pink-pink app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.pink-pink app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3,
.pink-pink app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.pink-pink app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.pink-pink app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3,
.pink-pink app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.pink-pink app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.pink-pink app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3,
.pink-pink app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.pink-pink app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.pink-pink app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3 {
  color: #e91e63 !important;
}
.pink-pink app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon,
.pink-pink app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon,
.pink-pink app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon,
.pink-pink app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon,
.pink-pink app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon {
  color: #d81b60;
}
.pink-pink app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3,
.pink-pink app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3,
.pink-pink app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3,
.pink-pink app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3,
.pink-pink app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3 {
  color: #d81b60;
}
.pink-pink app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex,
.pink-pink app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex,
.pink-pink app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex,
.pink-pink app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex,
.pink-pink app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex {
  color: #e91e63 !important;
  text-shadow: -2px 0 0px #eeeeee, 0 -2px 0px #eeeeee, 2px 0 0px #eeeeee, 0 2px 0px #eeeeee, -2px -2px 0px #eeeeee, 2px 2px 0px #eeeeee, 2px -2px 0px #eeeeee, -2px 2px 0px white;
}
.pink-pink app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex,
.pink-pink app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex,
.pink-pink app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex,
.pink-pink app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex,
.pink-pink app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex {
  color: rgba(0, 0, 0, 0.38) !important;
}
.pink-pink app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b,
.pink-pink app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b,
.pink-pink app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b,
.pink-pink app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b,
.pink-pink app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex,
.pink-pink app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex,
.pink-pink app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex,
.pink-pink app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex,
.pink-pink app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex {
  color: rgba(0, 0, 0, 0.38) !important;
}
.pink-pink app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex,
.pink-pink app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex,
.pink-pink app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex,
.pink-pink app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex,
.pink-pink app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex {
  color: rgba(0, 0, 0, 0.38) !important;
}
.pink-pink app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover,
.pink-pink app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover,
.pink-pink app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover,
.pink-pink app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover,
.pink-pink app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover {
  background: rgba(0, 0, 0, 0.04);
}
.pink-pink app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b,
.pink-pink app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b,
.pink-pink app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b,
.pink-pink app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b,
.pink-pink app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.pink-pink app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.pink-pink app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.pink-pink app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.pink-pink app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble {
  background: #d81b60;
}
.pink-pink app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble,
.pink-pink app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble,
.pink-pink app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble,
.pink-pink app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble,
.pink-pink app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble {
  background: #f44336;
}
.pink-pink app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble,
.pink-pink app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble,
.pink-pink app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble,
.pink-pink app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble,
.pink-pink app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble {
  color: rgba(0, 0, 0, 0.38);
  background: rgba(0, 0, 0, 0.12);
}
.pink-pink app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble,
.pink-pink app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble,
.pink-pink app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble,
.pink-pink app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble,
.pink-pink app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble {
  background: rgba(0, 0, 0, 0.38);
  color: white;
}
.pink-pink app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide,
.pink-pink app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide,
.pink-pink app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide,
.pink-pink app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide,
.pink-pink app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide {
  border-color: rgba(0, 0, 0, 0.12);
}
.pink-pink app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble,
.pink-pink app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble,
.pink-pink app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble,
.pink-pink app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble,
.pink-pink app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble {
  color: rgba(0, 0, 0, 0.54);
}
.pink-pink app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.pink-pink app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.pink-pink app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.pink-pink app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.pink-pink app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble {
  color: #d81b60;
}
.pink-pink app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble,
.pink-pink app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble,
.pink-pink app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble,
.pink-pink app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble,
.pink-pink app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble {
  color: #f44336;
}
.pink-pink app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar,
.pink-pink app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar,
.pink-pink app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar,
.pink-pink app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar,
.pink-pink app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar {
  background: #607D8B;
}
.pink-pink app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row,
.pink-pink app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row,
.pink-pink app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row,
.pink-pink app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row,
.pink-pink app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row {
  color: white;
}
.pink-pink app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper,
.pink-pink app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper,
.pink-pink app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper,
.pink-pink app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper,
.pink-pink app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper {
  background: #607D8B;
}
.pink-pink app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop,
.pink-pink app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop,
.pink-pink app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop,
.pink-pink app-share-person-dialog .dialog-content .document-template-container .add-docs-drop,
.pink-pink app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body,
.pink-pink app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body,
.pink-pink app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body,
.pink-pink app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body,
.pink-pink app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3,
.pink-pink app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3,
.pink-pink app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3,
.pink-pink app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3,
.pink-pink app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3 {
  color: #e91e63;
}
.pink-pink app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5,
.pink-pink app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5,
.pink-pink app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5,
.pink-pink app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5,
.pink-pink app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5 {
  color: rgba(0, 0, 0, 0.54);
}
.pink-pink app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5,
.pink-pink app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5,
.pink-pink app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5,
.pink-pink app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5,
.pink-pink app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5 {
  color: rgba(0, 0, 0, 0.54);
}
.pink-pink app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator,
.pink-pink app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator,
.pink-pink app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator,
.pink-pink app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator,
.pink-pink app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background: #fafafa;
}
.pink-pink app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip,
.pink-pink app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip,
.pink-pink app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip,
.pink-pink app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip,
.pink-pink app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip {
  background: white !important;
}
.pink-pink app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon,
.pink-pink app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon,
.pink-pink app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon,
.pink-pink app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon,
.pink-pink app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon {
  color: #e91e63 !important;
}
.pink-pink app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3,
.pink-pink app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3,
.pink-pink app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3,
.pink-pink app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3,
.pink-pink app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3 {
  color: #e91e63 !important;
}
.pink-pink app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5,
.pink-pink app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5,
.pink-pink app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5,
.pink-pink app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5,
.pink-pink app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5 {
  color: rgba(0, 0, 0, 0.54) !important;
}
.pink-pink app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon,
.pink-pink app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon,
.pink-pink app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon,
.pink-pink app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon,
.pink-pink app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon {
  color: #d81b60 !important;
}
.pink-pink app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3,
.pink-pink app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3,
.pink-pink app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3,
.pink-pink app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3,
.pink-pink app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3 {
  color: #d81b60 !important;
}
.pink-pink app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5,
.pink-pink app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5,
.pink-pink app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5,
.pink-pink app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5,
.pink-pink app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5 {
  color: #d81b60 !important;
}
.pink-pink app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled,
.pink-pink app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled,
.pink-pink app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled,
.pink-pink app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled,
.pink-pink app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled {
  background: #fafafa !important;
  color: rgba(0, 0, 0, 0.38);
}
.pink-pink app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove,
.pink-pink app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove,
.pink-pink app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove,
.pink-pink app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove,
.pink-pink app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove {
  color: rgba(0, 0, 0, 0.54);
}
.pink-pink app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove,
.pink-pink app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove,
.pink-pink app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove,
.pink-pink app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove,
.pink-pink app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove {
  color: rgba(0, 0, 0, 0.54) !important;
}
.pink-pink app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .preview-action,
.pink-pink app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .preview-action,
.pink-pink app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .preview-action,
.pink-pink app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .preview-action,
.pink-pink app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .preview-action {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink .tyc .sticky {
  background: white;
}
.pink-pink .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper:nth-child(odd) datatable-body-row {
  background: #fafafa;
}
.pink-pink .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active * {
  color: white !important;
}
.pink-pink .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active:hover .datatable-body-cell-label {
  background: #d81b60;
}
.pink-pink .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active:hover .cs-floating-action {
  background: #d81b60 !important;
}
.pink-pink .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active.row-cancel * {
  color: rgba(0, 0, 0, 0.54) !important;
}
.pink-pink .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active.row-cancel .datatable-row-group {
  background: #607D8B !important;
}
.pink-pink .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active.row-cancel:hover .datatable-body-cell-label {
  background: #607D8B;
}
.pink-pink .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.row-cancel * {
  color: #b0bec5 !important;
  transition-property: color;
  transition-duration: 0.3s;
  transition-timing-function: linear;
}
.pink-pink .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row:hover .datatable-body-cell-label button {
  background: #eeeeee;
}
.pink-pink .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row datatable-body-cell.candidate-status_bar mat-progress-bar .mat-progress-bar-buffer {
  background-color: #eeeeee;
}
.pink-pink .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row datatable-body-cell.candidate-status_bar .status_bar-count {
  color: rgba(0, 0, 0, 0.54);
}
.pink-pink .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row datatable-body-cell.candidate-status_bar .inactive {
  color: rgba(0, 0, 0, 0.54);
}
.pink-pink .cs-grid-test .info {
  background: white;
}
.pink-pink .cs-grid-test .selected-column {
  background: white;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
}
.pink-pink .cs-grid-test .selection-cell .datatable-body-cell.active {
  background: #d81b60 !important;
  color: white !important;
}
.pink-pink .cs-grid-test nav {
  background: white;
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 13px 19px 2px rgba(0, 0, 0, 0.14), 0 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.pink-pink .cs-grid-test .ngx-datatable.material {
  background: white;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.pink-pink .cs-grid-test .ngx-datatable.material.striped .datatable-row-odd {
  background: #fafafa;
}
.pink-pink .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active, .pink-pink .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active .datatable-row-group, .pink-pink .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active, .pink-pink .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active .datatable-row-group, .pink-pink .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active, .pink-pink .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active .datatable-row-group {
  background: #d81b60;
  color: white;
}
.pink-pink .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active:hover, .pink-pink .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active:hover .datatable-row-group, .pink-pink .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active:hover, .pink-pink .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active:hover .datatable-row-group, .pink-pink .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active:hover, .pink-pink .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active:hover .datatable-row-group {
  background: #d81b60;
  color: white;
}
.pink-pink .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active:focus, .pink-pink .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active:focus .datatable-row-group, .pink-pink .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active:focus, .pink-pink .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active:focus .datatable-row-group, .pink-pink .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active:focus, .pink-pink .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active:focus .datatable-row-group {
  background: #d81b60;
  color: white;
}
.pink-pink .cs-grid-test .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover, .pink-pink .cs-grid-test .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: rgba(0, 0, 0, 0.04);
}
.pink-pink .cs-grid-test .ngx-datatable.material:not(.cell-selection) .datatable-body-row:focus, .pink-pink .cs-grid-test .ngx-datatable.material:not(.cell-selection) .datatable-body-row:focus .datatable-row-group {
  background: rgba(0, 0, 0, 0.04);
}
.pink-pink .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell:hover, .pink-pink .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell:hover .datatable-row-group {
  background: rgba(0, 0, 0, 0.04);
}
.pink-pink .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell:focus, .pink-pink .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell:focus .datatable-row-group {
  background: rgba(0, 0, 0, 0.04);
}
.pink-pink .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active, .pink-pink .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active .datatable-row-group {
  background: #d81b60;
  color: white;
}
.pink-pink .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active:hover, .pink-pink .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active:hover .datatable-row-group {
  background: #d81b60;
  color: white;
}
.pink-pink .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active:focus, .pink-pink .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active:focus .datatable-row-group {
  background: #d81b60;
  color: white;
}
.pink-pink .cs-grid-test .ngx-datatable.material .datatable-header .datatable-row-left,
.pink-pink .cs-grid-test .ngx-datatable.material .datatable-body .datatable-row-left {
  background-color: white;
}
.pink-pink .cs-grid-test .ngx-datatable.material .datatable-header .datatable-row-right,
.pink-pink .cs-grid-test .ngx-datatable.material .datatable-body .datatable-row-right {
  background-color: white;
}
.pink-pink .cs-grid-test .ngx-datatable.material .datatable-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.38);
}
.pink-pink .cs-grid-test .ngx-datatable.material .datatable-header .datatable-header-cell {
  background-color: white;
  color: rgba(0, 0, 0, 0.54);
}
.pink-pink .cs-grid-test .ngx-datatable.material .datatable-header .resize-handle {
  border-right: solid 1px rgba(0, 0, 0, 0.04);
}
.pink-pink .cs-grid-test .ngx-datatable.material .datatable-body .datatable-row-detail {
  background: #eeeeee;
}
.pink-pink .cs-grid-test .ngx-datatable.material .datatable-body .datatable-group-header {
  background: #eeeeee;
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
  border-top: solid 1px rgba(0, 0, 0, 0.12);
}
.pink-pink .cs-grid-test .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink .cs-grid-test .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-group-cell {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink .cs-grid-test .ngx-datatable.material .datatable-body .progress-linear .container {
  background-color: white;
}
.pink-pink .cs-grid-test .ngx-datatable.material .datatable-body .progress-linear .container .bar {
  background-color: #d81b60;
}
.pink-pink .cs-grid-test .ngx-datatable.material .datatable-footer {
  color: rgba(0, 0, 0, 0.54);
}
.pink-pink .cs-grid-test .ngx-datatable.material .datatable-footer .datatable-pager li.disabled a {
  color: rgba(0, 0, 0, 0.38) !important;
  background-color: rgba(0, 0, 0, 0) !important;
}
.pink-pink .cs-grid-test .ngx-datatable.material .datatable-footer .datatable-pager li.active a {
  background-color: #d81b60;
}
.pink-pink .cs-grid-test .ngx-datatable.material .datatable-footer .datatable-pager a {
  color: rgba(0, 0, 0, 0.54);
}
.pink-pink .cs-grid-test .ngx-datatable.material .datatable-footer .datatable-pager a:hover {
  color: rgba(0, 0, 0, 0.04);
  background-color: rgba(0, 0, 0, 0.04);
}
.pink-pink .cs-grid-test .ngx-datatable.material .datatable-summary-row .datatable-body-row {
  background-color: #fafafa;
}
.pink-pink .cs-grid-test .ngx-datatable.material .datatable-summary-row .datatable-body-row:hover {
  background-color: #fafafa;
}
.pink-pink .cs-grid-test .datatable-checkbox input[type=checkbox]:after {
  background: white;
}
.pink-pink .view-container .view-toolbar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal.new.image-opened .help-content img {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card {
  background: white;
}
.pink-pink app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container .aditional-info {
  color: rgba(0, 0, 0, 0.54);
}
.pink-pink app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container .mat-card-footer {
  background: white;
}
.pink-pink app-help-index mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card {
  background: white;
}
.pink-pink app-help-index mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card .mat-card-footer {
  background: white;
}
.pink-pink .cropper .employee-title .title-text {
  color: white !important;
}
.pink-pink .cropper app-cs-upload-cropper-control .preview h5 {
  color: rgba(0, 0, 0, 0.54);
}
.pink-pink app-file-document-upload .preview-container {
  background: #607D8B !important;
}
.pink-pink app-file-document-upload .preview-container h4 {
  color: #eeeeee;
}
.pink-pink .preview-container {
  background: #607D8B !important;
}
.pink-pink .preview-container h4 {
  color: #eeeeee;
}
.pink-pink .clean-autocomple mat-list {
  background: white;
}
.pink-pink .clean-autocomple mat-list .mat-list-item:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-pink .clean-autocomple mat-list .mat-list-item .mat-list-item-content .mat-list-text {
  color: #d81b60;
}
.pink-pink app-leave .inbox-toolbar {
  background: #fafafa;
}
.pink-pink app-leave .mat-tab-body-wrapper {
  position: relative;
  overflow: hidden;
  display: flex;
  transition: height 500ms cubic-bezier(0.35, 0, 0.25, 1);
  height: 95% !important;
}
.pink-pink app-leave .leave_container {
  background: #fafafa;
}
.pink-pink app-leave .leave_container .leave_items .leave_items-item.ok .content .leave_item-status_icon {
  color: #009688;
}
.pink-pink app-leave .leave_container .leave_items .leave_items-item.not-ok .content .leave_item-status_icon {
  color: #9C27B0;
}
.pink-pink app-leave .leave_container .leave_items .leave_items-item.pending .content .leave_item-status_icon {
  color: #FF9800;
}
.pink-pink app-leave .leave_container .leave_items .leave_items-item.draft .content .leave_item-status_icon {
  color: rgba(0, 0, 0, 0.54);
}
.pink-pink app-leave .leave_container .leave_items .leave_items-item.cancel .content .leave_item-status_icon {
  color: #795548;
}
.pink-pink app-leave .leave_container .leave_items .leave_items-item .content:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-pink app-leave .leave_container .leave_items .leave_items-item .content.selected {
  border-left: 6px solid #e91e63 !important;
}
.pink-pink app-leave .leave_container .leave_items .leave_items-item .content.selected mat-icon {
  color: #e91e63 !important;
}
.pink-pink app-leave .leave_container .leave_items .leave_items-item .content.selected .item-data {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-pink app-leave .leave_container .leave_items .leave_items-item .content .leave_item-icon {
  color: rgba(0, 0, 0, 0.54);
}
.pink-pink app-leave .leave_container .leave_items .leave_items-item .content .item-data {
  color: rgba(0, 0, 0, 0.54);
}
.pink-pink app-leave .leave_container .window-spinner {
  background: #fafafa !important;
}
.pink-pink app-leave-detail .leave_detail .leave_detail-title .info h3,
.pink-pink app-leave-detail .leave_detail .leave_detail-title .info mat-icon {
  color: #d81b60;
}
.pink-pink app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .ok .large-icon {
  background: #b2dfdb;
  color: #009688;
}
.pink-pink app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .ok h2 {
  color: #009688;
}
.pink-pink app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .not-ok .large-icon {
  background: #e1bee7;
  color: #9C27B0;
}
.pink-pink app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .not-ok h2 {
  color: #9C27B0;
}
.pink-pink app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .pending .large-icon {
  background: #FFE0B2;
  color: #FF9800;
}
.pink-pink app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .pending h2 {
  color: #FF9800;
}
.pink-pink app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .draft .large-icon {
  background: #f2f2f2;
  color: #dedede;
}
.pink-pink app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .draft h2 {
  color: #dedede;
}
.pink-pink app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .cancelled .large-icon {
  background: #BCAAA4;
  color: #795548;
}
.pink-pink app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .cancelled h2 {
  color: #795548;
}
.pink-pink app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .actions {
  padding: 16px;
  height: 96px;
  column-gap: 16px;
}
.pink-pink app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .actions button {
  height: 100%;
  border-radius: 40px;
}
.pink-pink app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .actions button.ok {
  background: #009688 !important;
  color: white;
}
.pink-pink app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .actions button.not-ok {
  color: #9C27B0 !important;
}
.pink-pink app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning mat-basic-chip {
  background: #eeeeee !important;
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
  border-radius: 40px !important;
  height: 35px !important;
}
.pink-pink app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning mat-basic-chip.signed-ok {
  border-color: #009688 !important;
  color: #009688 !important;
}
.pink-pink app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning mat-basic-chip.signed-not-ok {
  border-color: #9C27B0 !important;
  color: #9C27B0 !important;
}
.pink-pink app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning mat-basic-chip.draft {
  border-color: #dedede !important;
  color: #dedede !important;
}
.pink-pink app-leave-request-detail .load-window {
  background: #fafafa;
}
.pink-pink app-leave-request-detail form .leave_detail-container {
  background: #fafafa;
}
.pink-pink app-leave-request-detail form .leave_detail-container .toolbar .header {
  color: #d81b60;
}
.pink-pink app-leave-request-detail form .leave_detail-container .toolbar .header .text h3 {
  color: #d81b60;
}
.pink-pink app-leave-request-detail form .leave_detail-container .toolbar .header .text h5 {
  color: rgba(0, 0, 0, 0.54);
}
.pink-pink app-leave-request-detail form .leave_detail-container .leave_detail-container-content .leave_resume {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink app-leave-request-detail form .leave_detail-container .leave_detail-container-content .line h6 {
  color: rgba(0, 0, 0, 0.54);
}
.pink-pink app-leave-request-detail form .leave_detail-container .actions button.ok {
  background: #009688 !important;
  color: white;
}
.pink-pink app-leave-request-detail form .leave_detail-container .actions button.not-ok {
  color: #9C27B0 !important;
}
.pink-pink .new_leave_modal-steps {
  display: unset !important;
}
.pink-pink .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button .mat-radio-label .mat-radio-label-content .side-text {
  color: rgba(0, 0, 0, 0.54);
}
.pink-pink .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button .mat-radio-label .mat-radio-label-content .side-text.muted {
  color: rgba(0, 0, 0, 0.38);
}
.pink-pink .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button .mat-radio-label .mat-radio-label-content .accent mat-icon,
.pink-pink .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button .mat-radio-label .mat-radio-label-content .accent .main-text {
  color: #e91e63;
}
.pink-pink .config_leave-container .config_leave-card {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink .config_leave-container .config_leave-card .config_leave-auto_chip mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #fafafa !important;
}
.pink-pink .config_leave-container .config_leave-card .config_leave-auto_chip mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(0, 0, 0, 0.38);
}
.pink-pink .config_leave-container .config_leave-card .config_leave-auto_chip mat-form-field .mat-form-field-flex {
  background: #fafafa;
}
.pink-pink *[color=success] {
  color: #4DB6AC;
}
.pink-pink .primary {
  color: #d81b60 !important;
}
.pink-pink .accent {
  color: #e91e63 !important;
}
.pink-pink app-lsd .icon-complex mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.pink-pink app-lsd .icon-complex mat-icon.complex {
  color: #d81b60 !important;
}
.pink-pink app-lsd .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.pink-pink app-lsd .work-space .main .load-window {
  background: white !important;
}
.pink-pink app-lsd .work-space .main.side-open {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink app-lsd .work-space .main .container .item {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink app-lsd .work-space .main .container .item h5 {
  color: rgba(0, 0, 0, 0.38);
}
.pink-pink app-lsd .work-space .main .container .item h3 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink app-lsd .work-space .main .container .item:hover {
  background: rgba(0, 0, 0, 0.04);
}
.pink-pink app-lsd .work-space .main .container .item.selected {
  background: #d81b60;
  color: white !important;
}
.pink-pink app-lsd .work-space .main .container .item.selected h3 {
  color: white !important;
}
.pink-pink app-lsd .work-space .main .container .item.selected.pending .status {
  background: rgba(0, 0, 0, 0.12) !important;
  color: #ffe082;
}
.pink-pink app-lsd .work-space .main .container .item.selected.ok .status {
  background: rgba(0, 0, 0, 0.12) !important;
  color: #a5d6a7;
}
.pink-pink app-lsd .work-space .main .container .item.selected.not-ok .status {
  background: rgba(0, 0, 0, 0.12) !important;
  color: #e57373;
}
.pink-pink app-lsd .work-space .main .main-action {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink app-lsd .work-space .side {
  background: #fafafa;
}
.pink-pink app-lsd .work-space .side .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #fafafa, 0 -2px 0px #fafafa, 2px 0 0px #fafafa, 0 2px 0px #fafafa, -2px -2px 0px #fafafa, 2px 2px 0px #fafafa, 2px -2px 0px #fafafa, -2px 2px 0px #fafafa;
}
.pink-pink app-lsd .work-space .side *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.pink-pink app-lsd .work-space .side *::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}
.pink-pink app-lsd .work-space .side .edition-line {
  background: #eeeeee;
}
.pink-pink app-lsd .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #fafafa !important;
}
.pink-pink app-lsd .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(0, 0, 0, 0.38);
}
.pink-pink app-lsd .work-space .side mat-form-field .mat-form-field-flex {
  background: #fafafa;
}
.pink-pink app-lsd .work-space .side mat-checkbox:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-pink app-lsd .work-space .side .hint.all {
  color: #e91e63;
}
.pink-pink app-lsd .work-space .side .toolbar .header {
  color: #d81b60;
}
.pink-pink app-lsd .work-space .side .toolbar .header h3 {
  color: #d81b60;
}
.pink-pink app-lsd .work-space .side .container .doc-resume {
  background: #d81b60;
}
.pink-pink app-lsd .work-space .side .container .doc-resume .line {
  color: white;
}
.pink-pink app-lsd .work-space .side .container .doc-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.1);
}
.pink-pink app-lsd .work-space .side .container .doc-resume .line.editable button {
  background: #d81b60;
}
.pink-pink app-lsd .work-space .side .container .section-title {
  color: #d81b60;
}
.pink-pink app-lsd .work-space .side .container .section-resume {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink app-lsd .work-space .side .container .section-resume .line.editable:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-pink app-lsd .work-space .side .container .section-resume .line.editable:hover .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px white, 0 -2px 0px white, 2px 0 0px white, 0 2px 0px white, -2px -2px 0px white, 2px 2px 0px white, 2px -2px 0px white, -2px 2px 0px white !important;
}
.pink-pink app-lsd .work-space .side .container .section-resume .line.editable button {
  background: #fafafa;
}
.pink-pink app-lsd .work-space .side .container .section-resume .line .header .icon-complex mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.pink-pink app-lsd .work-space .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #d81b60 !important;
}
.pink-pink app-lsd .work-space .side .container .section-resume .line .header mat-icon:not(.complex) {
  color: rgba(0, 0, 0, 0.54);
}
.pink-pink app-lsd .work-space .side .container .section-resume .line .header .info h4 {
  color: #d81b60;
}
.pink-pink app-lsd .work-space .side .container .section-resume .line ul {
  color: #d81b60;
}
.pink-pink app-lsd .work-space .side .container .section-resume .line.head {
  background: #eeeeee;
}
.pink-pink app-lsd .work-space .side .container .section-resume .line.head mat-icon {
  color: #e91e63;
}
.pink-pink app-lsd .work-space .side .container .section-resume .line.head h4 {
  color: white;
}
.pink-pink app-lsd .work-space .side.edition .toolbar .header {
  color: white;
}
.pink-pink app-lsd .work-space .side.edition .toolbar .header mat-icon {
  color: #e91e63;
}
.pink-pink app-lsd .work-space .side.edition .toolbar .header h3 {
  color: #e91e63;
}
.pink-pink .dialog-content {
  max-height: 90vh;
}
.pink-pink .dialog-content mat-toolbar {
  background: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink .dialog-content .dialog-container .document-template-container .document-selection mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #fafafa !important;
}
.pink-pink .dialog-content .dialog-container .document-template-container .document-selection mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(0, 0, 0, 0.38);
}
.pink-pink .dialog-content .dialog-container .document-template-container .document-selection mat-form-field .mat-form-field-flex {
  background: #fafafa;
}
.pink-pink .dialog-content .dialog-container .document-template-container .document-selection app-upload-form .file-upload-list .mat-subheader::before {
  color: #d81b60;
}
.pink-pink .dialog-content .dialog-container .view-container {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-pink .dialog-content .form-actions {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.pink-pink.dark .mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-pink.dark .mat-badge-small .mat-badge-content {
  font-size: 9px;
}
.pink-pink.dark .mat-badge-large .mat-badge-content {
  font-size: 24px;
}
.pink-pink.dark .mat-h1,
.pink-pink.dark .mat-headline,
.pink-pink.dark .mat-typography .mat-h1,
.pink-pink.dark .mat-typography .mat-headline,
.pink-pink.dark .mat-typography h1 {
  font: 400 24px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.pink-pink.dark .mat-h2,
.pink-pink.dark .mat-title,
.pink-pink.dark .mat-typography .mat-h2,
.pink-pink.dark .mat-typography .mat-title,
.pink-pink.dark .mat-typography h2 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.pink-pink.dark .mat-h3,
.pink-pink.dark .mat-subheading-2,
.pink-pink.dark .mat-typography .mat-h3,
.pink-pink.dark .mat-typography .mat-subheading-2,
.pink-pink.dark .mat-typography h3 {
  font: 400 16px / 28px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.pink-pink.dark .mat-h4,
.pink-pink.dark .mat-subheading-1,
.pink-pink.dark .mat-typography .mat-h4,
.pink-pink.dark .mat-typography .mat-subheading-1,
.pink-pink.dark .mat-typography h4 {
  font: 400 15px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.pink-pink.dark .mat-h5,
.pink-pink.dark .mat-typography .mat-h5,
.pink-pink.dark .mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}
.pink-pink.dark .mat-h6,
.pink-pink.dark .mat-typography .mat-h6,
.pink-pink.dark .mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}
.pink-pink.dark .mat-body-strong,
.pink-pink.dark .mat-body-2,
.pink-pink.dark .mat-typography .mat-body-strong,
.pink-pink.dark .mat-typography .mat-body-2 {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.pink-pink.dark .mat-body,
.pink-pink.dark .mat-body-1,
.pink-pink.dark .mat-typography .mat-body,
.pink-pink.dark .mat-typography .mat-body-1,
.pink-pink.dark .mat-typography {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.pink-pink.dark .mat-body p,
.pink-pink.dark .mat-body-1 p,
.pink-pink.dark .mat-typography .mat-body p,
.pink-pink.dark .mat-typography .mat-body-1 p,
.pink-pink.dark .mat-typography p {
  margin: 0 0 12px;
}
.pink-pink.dark .mat-small,
.pink-pink.dark .mat-caption,
.pink-pink.dark .mat-typography .mat-small,
.pink-pink.dark .mat-typography .mat-caption {
  font: 400 12px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.pink-pink.dark .mat-display-4,
.pink-pink.dark .mat-typography .mat-display-4 {
  font: 300 112px / 112px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}
.pink-pink.dark .mat-display-3,
.pink-pink.dark .mat-typography .mat-display-3 {
  font: 400 56px / 56px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}
.pink-pink.dark .mat-display-2,
.pink-pink.dark .mat-typography .mat-display-2 {
  font: 400 45px / 48px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}
.pink-pink.dark .mat-display-1,
.pink-pink.dark .mat-typography .mat-display-1 {
  font: 400 34px / 40px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}
.pink-pink.dark .mat-bottom-sheet-container {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.pink-pink.dark .mat-button, .pink-pink.dark .mat-raised-button, .pink-pink.dark .mat-icon-button, .pink-pink.dark .mat-stroked-button,
.pink-pink.dark .mat-flat-button, .pink-pink.dark .mat-fab, .pink-pink.dark .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.pink-pink.dark .mat-button-toggle {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-pink.dark .mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-pink.dark .mat-card-title {
  font-size: 24px;
  font-weight: 500;
}
.pink-pink.dark .mat-card-header .mat-card-title {
  font-size: 20px;
}
.pink-pink.dark .mat-card-subtitle,
.pink-pink.dark .mat-card-content {
  font-size: 14px;
}
.pink-pink.dark .mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-pink.dark .mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}
.pink-pink.dark .mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.pink-pink.dark .mat-chip .mat-chip-trailing-icon.mat-icon,
.pink-pink.dark .mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}
.pink-pink.dark .mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-pink.dark .mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}
.pink-pink.dark .mat-cell, .pink-pink.dark .mat-footer-cell {
  font-size: 14px;
}
.pink-pink.dark .mat-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-pink.dark .mat-calendar-body {
  font-size: 13px;
}
.pink-pink.dark .mat-calendar-body-label,
.pink-pink.dark .mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}
.pink-pink.dark .mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}
.pink-pink.dark .mat-dialog-title {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.pink-pink.dark .mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400;
}
.pink-pink.dark .mat-expansion-panel-content {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.pink-pink.dark .mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.pink-pink.dark .mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}
.pink-pink.dark .mat-form-field-prefix .mat-icon,
.pink-pink.dark .mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.pink-pink.dark .mat-form-field-prefix .mat-icon-button,
.pink-pink.dark .mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.pink-pink.dark .mat-form-field-prefix .mat-icon-button .mat-icon,
.pink-pink.dark .mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}
.pink-pink.dark .mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}
.pink-pink.dark .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.pink-pink.dark .mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34357em) scale(0.75);
  width: 133.3335133333%;
}
.pink-pink.dark .mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34356em) scale(0.75);
  width: 133.3335233333%;
}
.pink-pink.dark .mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}
.pink-pink.dark .mat-form-field-label {
  top: 1.34375em;
}
.pink-pink.dark .mat-form-field-underline {
  bottom: 1.34375em;
}
.pink-pink.dark .mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}
.pink-pink.dark .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.pink-pink.dark .mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.pink-pink.dark .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.pink-pink.dark .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00154px);
  width: 133.3338733333%;
}
.pink-pink.dark .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00155px);
  width: 133.3338833333%;
}
.pink-pink.dark .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00156px);
  width: 133.3338933333%;
}
.pink-pink.dark .mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.pink-pink.dark .mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.pink-pink.dark .mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}
@media print {
  .pink-pink.dark .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.pink-pink.dark .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28068em) scale(0.75);
  }
  .pink-pink.dark .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28067em) scale(0.75);
  }
  .pink-pink.dark .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28066em) scale(0.75);
  }
}
.pink-pink.dark .mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.pink-pink.dark .mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.pink-pink.dark .mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.pink-pink.dark .mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59357em) scale(0.75);
  width: 133.3335133333%;
}
.pink-pink.dark .mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59356em) scale(0.75);
  width: 133.3335233333%;
}
.pink-pink.dark .mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.pink-pink.dark .mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.pink-pink.dark .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.pink-pink.dark .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59357em) scale(0.75);
  width: 133.3335133333%;
}
.pink-pink.dark .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59356em) scale(0.75);
  width: 133.3335233333%;
}
.pink-pink.dark .mat-grid-tile-header,
.pink-pink.dark .mat-grid-tile-footer {
  font-size: 14px;
}
.pink-pink.dark .mat-grid-tile-header .mat-line,
.pink-pink.dark .mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.pink-pink.dark .mat-grid-tile-header .mat-line:nth-child(n+2),
.pink-pink.dark .mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.pink-pink.dark input.mat-input-element {
  margin-top: -0.0625em;
}
.pink-pink.dark .mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
}
.pink-pink.dark .mat-paginator,
.pink-pink.dark .mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
}
.pink-pink.dark .mat-radio-button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-pink.dark .mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-pink.dark .mat-select-trigger {
  height: 1.125em;
}
.pink-pink.dark .mat-slide-toggle-content {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-pink.dark .mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}
.pink-pink.dark .mat-stepper-vertical, .pink-pink.dark .mat-stepper-horizontal {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-pink.dark .mat-step-label {
  font-size: 14px;
  font-weight: 400;
}
.pink-pink.dark .mat-step-sub-label-error {
  font-weight: normal;
}
.pink-pink.dark .mat-step-label-error {
  font-size: 14px;
}
.pink-pink.dark .mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}
.pink-pink.dark .mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-pink.dark .mat-tab-label, .pink-pink.dark .mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.pink-pink.dark .mat-toolbar,
.pink-pink.dark .mat-toolbar h1,
.pink-pink.dark .mat-toolbar h2,
.pink-pink.dark .mat-toolbar h3,
.pink-pink.dark .mat-toolbar h4,
.pink-pink.dark .mat-toolbar h5,
.pink-pink.dark .mat-toolbar h6 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0;
}
.pink-pink.dark .mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.pink-pink.dark .mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.pink-pink.dark .mat-list-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-pink.dark .mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-pink.dark .mat-list-base .mat-list-item {
  font-size: 16px;
}
.pink-pink.dark .mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.pink-pink.dark .mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.pink-pink.dark .mat-list-base .mat-list-option {
  font-size: 16px;
}
.pink-pink.dark .mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.pink-pink.dark .mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.pink-pink.dark .mat-list-base .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.pink-pink.dark .mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.pink-pink.dark .mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.pink-pink.dark .mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.pink-pink.dark .mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.pink-pink.dark .mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.pink-pink.dark .mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.pink-pink.dark .mat-list-base[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}
.pink-pink.dark .mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
}
.pink-pink.dark .mat-optgroup-label {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.pink-pink.dark .mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
}
.pink-pink.dark .mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}
.pink-pink.dark .mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-pink.dark .mat-tree-node,
.pink-pink.dark .mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}
.pink-pink.dark .mat-ripple {
  overflow: hidden;
  position: relative;
}
.pink-pink.dark .mat-ripple:not(:empty) {
  transform: translateZ(0);
}
.pink-pink.dark .mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}
.pink-pink.dark .mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .pink-pink.dark .mat-ripple-element {
  display: none;
}

.pink-pink.dark .cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .pink-pink.dark .cdk-visually-hidden {
  left: auto;
  right: 0;
}
.pink-pink.dark .cdk-overlay-container, .pink-pink.dark .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.pink-pink.dark .cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.pink-pink.dark .cdk-overlay-container:empty {
  display: none;
}
.pink-pink.dark .cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}
.pink-pink.dark .cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}
.pink-pink.dark .cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.pink-pink.dark .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .pink-pink.dark .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}
.pink-pink.dark .cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}
.pink-pink.dark .cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.pink-pink.dark .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}
.pink-pink.dark .cdk-overlay-backdrop-noop-animation {
  transition: none;
}
.pink-pink.dark .cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}
.pink-pink.dark .cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}
.pink-pink.dark textarea.cdk-textarea-autosize {
  resize: none;
}
.pink-pink.dark textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}
.pink-pink.dark textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}
@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.pink-pink.dark .cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}
.pink-pink.dark .cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}
.pink-pink.dark .mat-focus-indicator {
  position: relative;
}
.pink-pink.dark .mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.pink-pink.dark .mat-focus-indicator:focus::before {
  content: "";
}
.cdk-high-contrast-active .pink-pink.dark {
  --mat-focus-indicator-display: block;
}

.pink-pink.dark .mat-mdc-focus-indicator {
  position: relative;
}
.pink-pink.dark .mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.pink-pink.dark .mat-mdc-focus-indicator:focus::before {
  content: "";
}
.cdk-high-contrast-active .pink-pink.dark {
  --mat-mdc-focus-indicator-display: block;
}

.pink-pink.dark .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.pink-pink.dark .mat-option {
  color: white;
}
.pink-pink.dark .mat-option:hover:not(.mat-option-disabled), .pink-pink.dark .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.pink-pink.dark .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.pink-pink.dark .mat-option.mat-active {
  background: rgba(255, 255, 255, 0.04);
  color: white;
}
.pink-pink.dark .mat-option.mat-option-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.pink-pink.dark .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f06292;
}
.pink-pink.dark .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f48fb1;
}
.pink-pink.dark .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #e57373;
}
.pink-pink.dark .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.7);
}
.pink-pink.dark .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.5);
}
.pink-pink.dark .mat-pseudo-checkbox {
  color: rgba(255, 255, 255, 0.7);
}
.pink-pink.dark .mat-pseudo-checkbox::after {
  color: #303030;
}
.pink-pink.dark .mat-pseudo-checkbox-disabled {
  color: #686868;
}
.pink-pink.dark .mat-primary .mat-pseudo-checkbox-checked,
.pink-pink.dark .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #f06292;
}
.pink-pink.dark .mat-pseudo-checkbox-checked,
.pink-pink.dark .mat-pseudo-checkbox-indeterminate,
.pink-pink.dark .mat-accent .mat-pseudo-checkbox-checked,
.pink-pink.dark .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #f48fb1;
}
.pink-pink.dark .mat-warn .mat-pseudo-checkbox-checked,
.pink-pink.dark .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #e57373;
}
.pink-pink.dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.pink-pink.dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #686868;
}
.pink-pink.dark .mat-app-background, .pink-pink.dark.mat-app-background {
  background-color: #303030;
  color: white;
}
.pink-pink.dark .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.pink-pink.dark .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.pink-pink.dark .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.pink-pink.dark .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.pink-pink.dark .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.pink-pink.dark .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.pink-pink.dark .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.pink-pink.dark .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.pink-pink.dark .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.pink-pink.dark .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.pink-pink.dark .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.pink-pink.dark .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.pink-pink.dark .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.pink-pink.dark .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.pink-pink.dark .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.pink-pink.dark .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.pink-pink.dark .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.pink-pink.dark .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.pink-pink.dark .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.pink-pink.dark .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.pink-pink.dark .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.pink-pink.dark .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.pink-pink.dark .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.pink-pink.dark .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.pink-pink.dark .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.pink-pink.dark .mat-autocomplete-panel {
  background: #424242;
  color: white;
}
.pink-pink.dark .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.pink-pink.dark .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: #424242;
}
.pink-pink.dark .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: white;
}
.pink-pink.dark .mat-badge-content {
  color: rgba(0, 0, 0, 0.87);
  background: #f06292;
}
.cdk-high-contrast-active .pink-pink.dark .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.pink-pink.dark .mat-badge-accent .mat-badge-content {
  background: #f48fb1;
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark .mat-badge-warn .mat-badge-content {
  color: rgba(0, 0, 0, 0.87);
  background: #e57373;
}
.pink-pink.dark .mat-badge-disabled .mat-badge-content {
  background: #6e6e6e;
  color: rgba(255, 255, 255, 0.5);
}
.pink-pink.dark .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.pink-pink.dark .mat-button, .pink-pink.dark .mat-icon-button, .pink-pink.dark .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.pink-pink.dark .mat-button.mat-primary, .pink-pink.dark .mat-icon-button.mat-primary, .pink-pink.dark .mat-stroked-button.mat-primary {
  color: #f06292;
}
.pink-pink.dark .mat-button.mat-accent, .pink-pink.dark .mat-icon-button.mat-accent, .pink-pink.dark .mat-stroked-button.mat-accent {
  color: #f48fb1;
}
.pink-pink.dark .mat-button.mat-warn, .pink-pink.dark .mat-icon-button.mat-warn, .pink-pink.dark .mat-stroked-button.mat-warn {
  color: #e57373;
}
.pink-pink.dark .mat-button.mat-primary.mat-button-disabled, .pink-pink.dark .mat-button.mat-accent.mat-button-disabled, .pink-pink.dark .mat-button.mat-warn.mat-button-disabled, .pink-pink.dark .mat-button.mat-button-disabled.mat-button-disabled, .pink-pink.dark .mat-icon-button.mat-primary.mat-button-disabled, .pink-pink.dark .mat-icon-button.mat-accent.mat-button-disabled, .pink-pink.dark .mat-icon-button.mat-warn.mat-button-disabled, .pink-pink.dark .mat-icon-button.mat-button-disabled.mat-button-disabled, .pink-pink.dark .mat-stroked-button.mat-primary.mat-button-disabled, .pink-pink.dark .mat-stroked-button.mat-accent.mat-button-disabled, .pink-pink.dark .mat-stroked-button.mat-warn.mat-button-disabled, .pink-pink.dark .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.pink-pink.dark .mat-button.mat-primary .mat-button-focus-overlay, .pink-pink.dark .mat-icon-button.mat-primary .mat-button-focus-overlay, .pink-pink.dark .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #f06292;
}
.pink-pink.dark .mat-button.mat-accent .mat-button-focus-overlay, .pink-pink.dark .mat-icon-button.mat-accent .mat-button-focus-overlay, .pink-pink.dark .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #f48fb1;
}
.pink-pink.dark .mat-button.mat-warn .mat-button-focus-overlay, .pink-pink.dark .mat-icon-button.mat-warn .mat-button-focus-overlay, .pink-pink.dark .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #e57373;
}
.pink-pink.dark .mat-button.mat-button-disabled .mat-button-focus-overlay, .pink-pink.dark .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .pink-pink.dark .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.pink-pink.dark .mat-button .mat-ripple-element, .pink-pink.dark .mat-icon-button .mat-ripple-element, .pink-pink.dark .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.pink-pink.dark .mat-button-focus-overlay {
  background: white;
}
.pink-pink.dark .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(255, 255, 255, 0.12);
}
.pink-pink.dark .mat-flat-button, .pink-pink.dark .mat-raised-button, .pink-pink.dark .mat-fab, .pink-pink.dark .mat-mini-fab {
  color: white;
  background-color: #424242;
}
.pink-pink.dark .mat-flat-button.mat-primary, .pink-pink.dark .mat-raised-button.mat-primary, .pink-pink.dark .mat-fab.mat-primary, .pink-pink.dark .mat-mini-fab.mat-primary {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark .mat-flat-button.mat-accent, .pink-pink.dark .mat-raised-button.mat-accent, .pink-pink.dark .mat-fab.mat-accent, .pink-pink.dark .mat-mini-fab.mat-accent {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark .mat-flat-button.mat-warn, .pink-pink.dark .mat-raised-button.mat-warn, .pink-pink.dark .mat-fab.mat-warn, .pink-pink.dark .mat-mini-fab.mat-warn {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark .mat-flat-button.mat-primary.mat-button-disabled, .pink-pink.dark .mat-flat-button.mat-accent.mat-button-disabled, .pink-pink.dark .mat-flat-button.mat-warn.mat-button-disabled, .pink-pink.dark .mat-flat-button.mat-button-disabled.mat-button-disabled, .pink-pink.dark .mat-raised-button.mat-primary.mat-button-disabled, .pink-pink.dark .mat-raised-button.mat-accent.mat-button-disabled, .pink-pink.dark .mat-raised-button.mat-warn.mat-button-disabled, .pink-pink.dark .mat-raised-button.mat-button-disabled.mat-button-disabled, .pink-pink.dark .mat-fab.mat-primary.mat-button-disabled, .pink-pink.dark .mat-fab.mat-accent.mat-button-disabled, .pink-pink.dark .mat-fab.mat-warn.mat-button-disabled, .pink-pink.dark .mat-fab.mat-button-disabled.mat-button-disabled, .pink-pink.dark .mat-mini-fab.mat-primary.mat-button-disabled, .pink-pink.dark .mat-mini-fab.mat-accent.mat-button-disabled, .pink-pink.dark .mat-mini-fab.mat-warn.mat-button-disabled, .pink-pink.dark .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.pink-pink.dark .mat-flat-button.mat-primary, .pink-pink.dark .mat-raised-button.mat-primary, .pink-pink.dark .mat-fab.mat-primary, .pink-pink.dark .mat-mini-fab.mat-primary {
  background-color: #f06292;
}
.pink-pink.dark .mat-flat-button.mat-accent, .pink-pink.dark .mat-raised-button.mat-accent, .pink-pink.dark .mat-fab.mat-accent, .pink-pink.dark .mat-mini-fab.mat-accent {
  background-color: #f48fb1;
}
.pink-pink.dark .mat-flat-button.mat-warn, .pink-pink.dark .mat-raised-button.mat-warn, .pink-pink.dark .mat-fab.mat-warn, .pink-pink.dark .mat-mini-fab.mat-warn {
  background-color: #e57373;
}
.pink-pink.dark .mat-flat-button.mat-primary.mat-button-disabled, .pink-pink.dark .mat-flat-button.mat-accent.mat-button-disabled, .pink-pink.dark .mat-flat-button.mat-warn.mat-button-disabled, .pink-pink.dark .mat-flat-button.mat-button-disabled.mat-button-disabled, .pink-pink.dark .mat-raised-button.mat-primary.mat-button-disabled, .pink-pink.dark .mat-raised-button.mat-accent.mat-button-disabled, .pink-pink.dark .mat-raised-button.mat-warn.mat-button-disabled, .pink-pink.dark .mat-raised-button.mat-button-disabled.mat-button-disabled, .pink-pink.dark .mat-fab.mat-primary.mat-button-disabled, .pink-pink.dark .mat-fab.mat-accent.mat-button-disabled, .pink-pink.dark .mat-fab.mat-warn.mat-button-disabled, .pink-pink.dark .mat-fab.mat-button-disabled.mat-button-disabled, .pink-pink.dark .mat-mini-fab.mat-primary.mat-button-disabled, .pink-pink.dark .mat-mini-fab.mat-accent.mat-button-disabled, .pink-pink.dark .mat-mini-fab.mat-warn.mat-button-disabled, .pink-pink.dark .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(255, 255, 255, 0.12);
}
.pink-pink.dark .mat-flat-button.mat-primary .mat-ripple-element, .pink-pink.dark .mat-raised-button.mat-primary .mat-ripple-element, .pink-pink.dark .mat-fab.mat-primary .mat-ripple-element, .pink-pink.dark .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.pink-pink.dark .mat-flat-button.mat-accent .mat-ripple-element, .pink-pink.dark .mat-raised-button.mat-accent .mat-ripple-element, .pink-pink.dark .mat-fab.mat-accent .mat-ripple-element, .pink-pink.dark .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.pink-pink.dark .mat-flat-button.mat-warn .mat-ripple-element, .pink-pink.dark .mat-raised-button.mat-warn .mat-ripple-element, .pink-pink.dark .mat-fab.mat-warn .mat-ripple-element, .pink-pink.dark .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.pink-pink.dark .mat-stroked-button:not([class*=mat-elevation-z]), .pink-pink.dark .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.pink-pink.dark .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.pink-pink.dark .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.pink-pink.dark .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.pink-pink.dark .mat-fab:not([class*=mat-elevation-z]), .pink-pink.dark .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.pink-pink.dark .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .pink-pink.dark .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.pink-pink.dark .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .pink-pink.dark .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.pink-pink.dark .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.pink-pink.dark .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.pink-pink.dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.pink-pink.dark .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.pink-pink.dark .mat-button-toggle {
  color: rgba(255, 255, 255, 0.5);
}
.pink-pink.dark .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(255, 255, 255, 0.12);
}
.pink-pink.dark .mat-button-toggle-appearance-standard {
  color: white;
  background: #424242;
}
.pink-pink.dark .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: white;
}
.pink-pink.dark .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #595959;
}
.pink-pink.dark [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #595959;
}
.pink-pink.dark .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #595959;
}
.pink-pink.dark .mat-button-toggle-checked {
  background-color: #212121;
  color: rgba(255, 255, 255, 0.7);
}
.pink-pink.dark .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: white;
}
.pink-pink.dark .mat-button-toggle-disabled {
  color: rgba(255, 255, 255, 0.3);
  background-color: black;
}
.pink-pink.dark .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: #424242;
}
.pink-pink.dark .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #424242;
}
.pink-pink.dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.pink-pink.dark .mat-button-toggle-group-appearance-standard {
  border: solid 1px #595959;
}
.pink-pink.dark .mat-card {
  background: #424242;
  color: white;
}
.pink-pink.dark .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.pink-pink.dark .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.pink-pink.dark .mat-card-subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.pink-pink.dark .mat-checkbox-frame {
  border-color: rgba(255, 255, 255, 0.7);
}
.pink-pink.dark .mat-checkbox-checkmark {
  fill: #303030;
}
.pink-pink.dark .mat-checkbox-checkmark-path {
  stroke: #303030 !important;
}
.pink-pink.dark .mat-checkbox-mixedmark {
  background-color: #303030;
}
.pink-pink.dark .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .pink-pink.dark .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #f06292;
}
.pink-pink.dark .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .pink-pink.dark .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #f48fb1;
}
.pink-pink.dark .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .pink-pink.dark .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #e57373;
}
.pink-pink.dark .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .pink-pink.dark .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #686868;
}
.pink-pink.dark .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #686868;
}
.pink-pink.dark .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(255, 255, 255, 0.5);
}
.pink-pink.dark .mat-checkbox .mat-ripple-element {
  background-color: white;
}
.pink-pink.dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.pink-pink.dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #f06292;
}
.pink-pink.dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.pink-pink.dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #f48fb1;
}
.pink-pink.dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.pink-pink.dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #e57373;
}
.pink-pink.dark .mat-chip.mat-standard-chip {
  background-color: #616161;
  color: white;
}
.pink-pink.dark .mat-chip.mat-standard-chip .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.pink-pink.dark .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.pink-pink.dark .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.pink-pink.dark .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.pink-pink.dark .mat-chip.mat-standard-chip::after {
  background: white;
}
.pink-pink.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #f06292;
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.pink-pink.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.pink-pink.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #e57373;
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.pink-pink.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.pink-pink.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #f48fb1;
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.pink-pink.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.pink-pink.dark .mat-table {
  background: #424242;
}
.pink-pink.dark .mat-table thead, .pink-pink.dark .mat-table tbody, .pink-pink.dark .mat-table tfoot,
.pink-pink.dark mat-header-row, .pink-pink.dark mat-row, .pink-pink.dark mat-footer-row,
.pink-pink.dark [mat-header-row], .pink-pink.dark [mat-row], .pink-pink.dark [mat-footer-row],
.pink-pink.dark .mat-table-sticky {
  background: inherit;
}
.pink-pink.dark mat-row, .pink-pink.dark mat-header-row, .pink-pink.dark mat-footer-row,
.pink-pink.dark th.mat-header-cell, .pink-pink.dark td.mat-cell, .pink-pink.dark td.mat-footer-cell {
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
.pink-pink.dark .mat-header-cell {
  color: rgba(255, 255, 255, 0.7);
}
.pink-pink.dark .mat-cell, .pink-pink.dark .mat-footer-cell {
  color: white;
}
.pink-pink.dark .mat-calendar-arrow {
  fill: white;
}
.pink-pink.dark .mat-datepicker-toggle,
.pink-pink.dark .mat-datepicker-content .mat-calendar-next-button,
.pink-pink.dark .mat-datepicker-content .mat-calendar-previous-button {
  color: white;
}
.pink-pink.dark .mat-calendar-table-header-divider::after {
  background: rgba(255, 255, 255, 0.12);
}
.pink-pink.dark .mat-calendar-table-header,
.pink-pink.dark .mat-calendar-body-label {
  color: rgba(255, 255, 255, 0.7);
}
.pink-pink.dark .mat-calendar-body-cell-content,
.pink-pink.dark .mat-date-range-input-separator {
  color: white;
  border-color: transparent;
}
.pink-pink.dark .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(255, 255, 255, 0.5);
}
.pink-pink.dark .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(255, 255, 255, 0.5);
}
.pink-pink.dark .mat-calendar-body-in-preview {
  color: rgba(255, 255, 255, 0.24);
}
.pink-pink.dark .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.5);
}
.pink-pink.dark .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.3);
}
.pink-pink.dark .mat-calendar-body-in-range::before {
  background: rgba(240, 98, 146, 0.2);
}
.pink-pink.dark .mat-calendar-body-comparison-identical,
.pink-pink.dark .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.pink-pink.dark .mat-calendar-body-comparison-bridge-start::before,
.pink-pink.dark [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(240, 98, 146, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.pink-pink.dark .mat-calendar-body-comparison-bridge-end::before,
.pink-pink.dark [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(240, 98, 146, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.pink-pink.dark .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.pink-pink.dark .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.pink-pink.dark .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.pink-pink.dark .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.pink-pink.dark .mat-calendar-body-selected {
  background-color: #f06292;
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(240, 98, 146, 0.4);
}
.pink-pink.dark .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.pink-pink.dark .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.pink-pink.dark .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(240, 98, 146, 0.3);
}
@media (hover: hover) {
  .pink-pink.dark .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(240, 98, 146, 0.3);
  }
}
.pink-pink.dark .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #424242;
  color: white;
}
.pink-pink.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(244, 143, 177, 0.2);
}
.pink-pink.dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.pink-pink.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.pink-pink.dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.pink-pink.dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 143, 177, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.pink-pink.dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.pink-pink.dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 143, 177, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.pink-pink.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.pink-pink.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.pink-pink.dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.pink-pink.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.pink-pink.dark .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #f48fb1;
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 143, 177, 0.4);
}
.pink-pink.dark .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.pink-pink.dark .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.pink-pink.dark .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(244, 143, 177, 0.3);
}
@media (hover: hover) {
  .pink-pink.dark .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(244, 143, 177, 0.3);
  }
}
.pink-pink.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(229, 115, 115, 0.2);
}
.pink-pink.dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.pink-pink.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.pink-pink.dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.pink-pink.dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(229, 115, 115, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.pink-pink.dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.pink-pink.dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(229, 115, 115, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.pink-pink.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.pink-pink.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.pink-pink.dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.pink-pink.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.pink-pink.dark .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #e57373;
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(229, 115, 115, 0.4);
}
.pink-pink.dark .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.pink-pink.dark .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.pink-pink.dark .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(229, 115, 115, 0.3);
}
@media (hover: hover) {
  .pink-pink.dark .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(229, 115, 115, 0.3);
  }
}
.pink-pink.dark .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.pink-pink.dark .mat-datepicker-toggle-active {
  color: #f06292;
}
.pink-pink.dark .mat-datepicker-toggle-active.mat-accent {
  color: #f48fb1;
}
.pink-pink.dark .mat-datepicker-toggle-active.mat-warn {
  color: #e57373;
}
.pink-pink.dark .mat-date-range-input-inner[disabled] {
  color: rgba(255, 255, 255, 0.5);
}
.pink-pink.dark .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.pink-pink.dark .mat-divider {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.pink-pink.dark .mat-divider-vertical {
  border-right-color: rgba(255, 255, 255, 0.12);
}
.pink-pink.dark .mat-expansion-panel {
  background: #424242;
  color: white;
}
.pink-pink.dark .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.pink-pink.dark .mat-action-row {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.pink-pink.dark .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .pink-pink.dark .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .pink-pink.dark .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(255, 255, 255, 0.04);
}
@media (hover: none) {
  .pink-pink.dark .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: #424242;
  }
}
.pink-pink.dark .mat-expansion-panel-header-title {
  color: white;
}
.pink-pink.dark .mat-expansion-panel-header-description,
.pink-pink.dark .mat-expansion-indicator::after {
  color: rgba(255, 255, 255, 0.7);
}
.pink-pink.dark .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(255, 255, 255, 0.3);
}
.pink-pink.dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.pink-pink.dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.pink-pink.dark .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.pink-pink.dark .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.pink-pink.dark .mat-form-field.mat-focused .mat-form-field-label {
  color: #f06292;
}
.pink-pink.dark .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #f48fb1;
}
.pink-pink.dark .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #e57373;
}
.pink-pink.dark .mat-focused .mat-form-field-required-marker {
  color: #f48fb1;
}
.pink-pink.dark .mat-form-field-ripple {
  background-color: white;
}
.pink-pink.dark .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #f06292;
}
.pink-pink.dark .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #f48fb1;
}
.pink-pink.dark .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #e57373;
}
.pink-pink.dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #f06292;
}
.pink-pink.dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #f48fb1;
}
.pink-pink.dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #e57373;
}
.pink-pink.dark .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #e57373;
}
.pink-pink.dark .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.pink-pink.dark .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #e57373;
}
.pink-pink.dark .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.pink-pink.dark .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #e57373;
}
.pink-pink.dark .mat-error {
  color: #e57373;
}
.pink-pink.dark .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.pink-pink.dark .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.pink-pink.dark .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.pink-pink.dark .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.pink-pink.dark .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.pink-pink.dark .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.pink-pink.dark .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.1);
}
.pink-pink.dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.05);
}
.pink-pink.dark .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(255, 255, 255, 0.5);
}
.pink-pink.dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.pink-pink.dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.pink-pink.dark .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.3);
}
.pink-pink.dark .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: white;
}
.pink-pink.dark .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #f06292;
}
.pink-pink.dark .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #f48fb1;
}
.pink-pink.dark .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #e57373;
}
.pink-pink.dark .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #e57373;
}
.pink-pink.dark .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.pink-pink.dark .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.15);
}
.pink-pink.dark .mat-icon.mat-primary {
  color: #f06292;
}
.pink-pink.dark .mat-icon.mat-accent {
  color: #f48fb1;
}
.pink-pink.dark .mat-icon.mat-warn {
  color: #e57373;
}
.pink-pink.dark .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.7);
}
.pink-pink.dark .mat-input-element:disabled,
.pink-pink.dark .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.5);
}
.pink-pink.dark .mat-input-element {
  caret-color: #f06292;
}
.pink-pink.dark .mat-input-element::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.pink-pink.dark .mat-input-element::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.pink-pink.dark .mat-input-element::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.pink-pink.dark .mat-input-element:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.pink-pink.dark .mat-input-element:not(.mat-native-select-inline) option {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark .mat-input-element:not(.mat-native-select-inline) option:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.pink-pink.dark .mat-form-field.mat-accent .mat-input-element {
  caret-color: #f48fb1;
}
.pink-pink.dark .mat-form-field.mat-warn .mat-input-element,
.pink-pink.dark .mat-form-field-invalid .mat-input-element {
  caret-color: #e57373;
}
.pink-pink.dark .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #e57373;
}
.pink-pink.dark .mat-list-base .mat-list-item {
  color: white;
}
.pink-pink.dark .mat-list-base .mat-list-option {
  color: white;
}
.pink-pink.dark .mat-list-base .mat-subheader {
  color: rgba(255, 255, 255, 0.7);
}
.pink-pink.dark .mat-list-base .mat-list-item-disabled {
  background-color: rgba(255, 255, 255, 0.12);
  color: rgba(255, 255, 255, 0.5);
}
.pink-pink.dark .mat-list-option:hover, .pink-pink.dark .mat-list-option:focus,
.pink-pink.dark .mat-nav-list .mat-list-item:hover,
.pink-pink.dark .mat-nav-list .mat-list-item:focus,
.pink-pink.dark .mat-action-list .mat-list-item:hover,
.pink-pink.dark .mat-action-list .mat-list-item:focus {
  background: rgba(255, 255, 255, 0.04);
}
.pink-pink.dark .mat-list-single-selected-option, .pink-pink.dark .mat-list-single-selected-option:hover, .pink-pink.dark .mat-list-single-selected-option:focus {
  background: rgba(255, 255, 255, 0.12);
}
.pink-pink.dark .mat-menu-panel {
  background: #424242;
}
.pink-pink.dark .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.pink-pink.dark .mat-menu-item {
  background: transparent;
  color: white;
}
.pink-pink.dark .mat-menu-item[disabled],
.pink-pink.dark .mat-menu-item[disabled] .mat-menu-submenu-icon,
.pink-pink.dark .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(255, 255, 255, 0.5);
}
.pink-pink.dark .mat-menu-item .mat-icon-no-color,
.pink-pink.dark .mat-menu-submenu-icon {
  color: white;
}
.pink-pink.dark .mat-menu-item:hover:not([disabled]),
.pink-pink.dark .mat-menu-item.cdk-program-focused:not([disabled]),
.pink-pink.dark .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.pink-pink.dark .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(255, 255, 255, 0.04);
}
.pink-pink.dark .mat-paginator {
  background: #424242;
}
.pink-pink.dark .mat-paginator,
.pink-pink.dark .mat-paginator-page-size .mat-select-trigger {
  color: rgba(255, 255, 255, 0.7);
}
.pink-pink.dark .mat-paginator-decrement,
.pink-pink.dark .mat-paginator-increment {
  border-top: 2px solid white;
  border-right: 2px solid white;
}
.pink-pink.dark .mat-paginator-first,
.pink-pink.dark .mat-paginator-last {
  border-top: 2px solid white;
}
.pink-pink.dark .mat-icon-button[disabled] .mat-paginator-decrement,
.pink-pink.dark .mat-icon-button[disabled] .mat-paginator-increment,
.pink-pink.dark .mat-icon-button[disabled] .mat-paginator-first,
.pink-pink.dark .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(255, 255, 255, 0.5);
}
.pink-pink.dark .mat-progress-bar-background {
  fill: #603d49;
}
.pink-pink.dark .mat-progress-bar-buffer {
  background-color: #603d49;
}
.pink-pink.dark .mat-progress-bar-fill::after {
  background-color: #f06292;
}
.pink-pink.dark .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #614850;
}
.pink-pink.dark .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #614850;
}
.pink-pink.dark .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #f48fb1;
}
.pink-pink.dark .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #5d4141;
}
.pink-pink.dark .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #5d4141;
}
.pink-pink.dark .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #e57373;
}
.pink-pink.dark .mat-progress-spinner circle, .pink-pink.dark .mat-spinner circle {
  stroke: #f06292;
}
.pink-pink.dark .mat-progress-spinner.mat-accent circle, .pink-pink.dark .mat-spinner.mat-accent circle {
  stroke: #f48fb1;
}
.pink-pink.dark .mat-progress-spinner.mat-warn circle, .pink-pink.dark .mat-spinner.mat-warn circle {
  stroke: #e57373;
}
.pink-pink.dark .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.7);
}
.pink-pink.dark .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f06292;
}
.pink-pink.dark .mat-radio-button.mat-primary .mat-radio-inner-circle,
.pink-pink.dark .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .pink-pink.dark .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .pink-pink.dark .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #f06292;
}
.pink-pink.dark .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f48fb1;
}
.pink-pink.dark .mat-radio-button.mat-accent .mat-radio-inner-circle,
.pink-pink.dark .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .pink-pink.dark .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .pink-pink.dark .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #f48fb1;
}
.pink-pink.dark .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e57373;
}
.pink-pink.dark .mat-radio-button.mat-warn .mat-radio-inner-circle,
.pink-pink.dark .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .pink-pink.dark .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .pink-pink.dark .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #e57373;
}
.pink-pink.dark .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.pink-pink.dark .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.5);
}
.pink-pink.dark .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.pink-pink.dark .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(255, 255, 255, 0.5);
}
.pink-pink.dark .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(255, 255, 255, 0.5);
}
.pink-pink.dark .mat-radio-button .mat-ripple-element {
  background-color: white;
}
.pink-pink.dark .mat-select-value {
  color: white;
}
.pink-pink.dark .mat-select-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.pink-pink.dark .mat-select-disabled .mat-select-value {
  color: rgba(255, 255, 255, 0.5);
}
.pink-pink.dark .mat-select-arrow {
  color: rgba(255, 255, 255, 0.7);
}
.pink-pink.dark .mat-select-panel {
  background: #424242;
}
.pink-pink.dark .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.pink-pink.dark .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(255, 255, 255, 0.12);
}
.pink-pink.dark .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #f06292;
}
.pink-pink.dark .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #f48fb1;
}
.pink-pink.dark .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #e57373;
}
.pink-pink.dark .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #e57373;
}
.pink-pink.dark .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(255, 255, 255, 0.5);
}
.pink-pink.dark .mat-drawer-container {
  background-color: #303030;
  color: white;
}
.pink-pink.dark .mat-drawer {
  background-color: #424242;
  color: white;
}
.pink-pink.dark .mat-drawer.mat-drawer-push {
  background-color: #424242;
}
.pink-pink.dark .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.pink-pink.dark .mat-drawer-side {
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.pink-pink.dark .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.pink-pink.dark [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.pink-pink.dark [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.pink-pink.dark .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(189, 189, 189, 0.6);
}
.pink-pink.dark .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #f48fb1;
}
.pink-pink.dark .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(244, 143, 177, 0.54);
}
.pink-pink.dark .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #f48fb1;
}
.pink-pink.dark .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #f06292;
}
.pink-pink.dark .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(240, 98, 146, 0.54);
}
.pink-pink.dark .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #f06292;
}
.pink-pink.dark .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #e57373;
}
.pink-pink.dark .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(229, 115, 115, 0.54);
}
.pink-pink.dark .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #e57373;
}
.pink-pink.dark .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: white;
}
.pink-pink.dark .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #bdbdbd;
}
.pink-pink.dark .mat-slide-toggle-bar {
  background-color: rgba(255, 255, 255, 0.5);
}
.pink-pink.dark .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.pink-pink.dark .mat-slider.mat-primary .mat-slider-track-fill,
.pink-pink.dark .mat-slider.mat-primary .mat-slider-thumb,
.pink-pink.dark .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #f06292;
}
.pink-pink.dark .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(240, 98, 146, 0.2);
}
.pink-pink.dark .mat-slider.mat-accent .mat-slider-track-fill,
.pink-pink.dark .mat-slider.mat-accent .mat-slider-thumb,
.pink-pink.dark .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #f48fb1;
}
.pink-pink.dark .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(244, 143, 177, 0.2);
}
.pink-pink.dark .mat-slider.mat-warn .mat-slider-track-fill,
.pink-pink.dark .mat-slider.mat-warn .mat-slider-thumb,
.pink-pink.dark .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #e57373;
}
.pink-pink.dark .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(229, 115, 115, 0.2);
}
.pink-pink.dark .mat-slider:hover .mat-slider-track-background,
.pink-pink.dark .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.pink-pink.dark .mat-slider.mat-slider-disabled .mat-slider-track-background,
.pink-pink.dark .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.pink-pink.dark .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(255, 255, 255, 0.3);
}
.pink-pink.dark .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.pink-pink.dark .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(255, 255, 255, 0.12);
}
.pink-pink.dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.pink-pink.dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: white;
}
.pink-pink.dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.pink-pink.dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(255, 255, 255, 0.3);
}
.pink-pink.dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
  background-color: transparent;
}
.pink-pink.dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .pink-pink.dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.pink-pink.dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .pink-pink.dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.pink-pink.dark .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(255, 255, 255, 0.7);
}
.pink-pink.dark .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.pink-pink.dark .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.pink-pink.dark .mat-step-header.cdk-keyboard-focused, .pink-pink.dark .mat-step-header.cdk-program-focused, .pink-pink.dark .mat-step-header:hover:not([aria-disabled]), .pink-pink.dark .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(255, 255, 255, 0.04);
}
.pink-pink.dark .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .pink-pink.dark .mat-step-header:hover {
    background: none;
  }
}
.pink-pink.dark .mat-step-header .mat-step-label,
.pink-pink.dark .mat-step-header .mat-step-optional {
  color: rgba(255, 255, 255, 0.7);
}
.pink-pink.dark .mat-step-header .mat-step-icon {
  background-color: rgba(255, 255, 255, 0.7);
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark .mat-step-header .mat-step-icon-selected,
.pink-pink.dark .mat-step-header .mat-step-icon-state-done,
.pink-pink.dark .mat-step-header .mat-step-icon-state-edit {
  background-color: #f06292;
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark .mat-step-header.mat-accent .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark .mat-step-header.mat-accent .mat-step-icon-selected,
.pink-pink.dark .mat-step-header.mat-accent .mat-step-icon-state-done,
.pink-pink.dark .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #f48fb1;
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark .mat-step-header.mat-warn .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark .mat-step-header.mat-warn .mat-step-icon-selected,
.pink-pink.dark .mat-step-header.mat-warn .mat-step-icon-state-done,
.pink-pink.dark .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #e57373;
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #e57373;
}
.pink-pink.dark .mat-step-header .mat-step-label.mat-step-label-active {
  color: white;
}
.pink-pink.dark .mat-step-header .mat-step-label.mat-step-label-error {
  color: #e57373;
}
.pink-pink.dark .mat-stepper-horizontal, .pink-pink.dark .mat-stepper-vertical {
  background-color: #424242;
}
.pink-pink.dark .mat-stepper-vertical-line::before {
  border-left-color: rgba(255, 255, 255, 0.12);
}
.pink-pink.dark .mat-horizontal-stepper-header::before,
.pink-pink.dark .mat-horizontal-stepper-header::after,
.pink-pink.dark .mat-stepper-horizontal-line {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.pink-pink.dark .mat-sort-header-arrow {
  color: #c6c6c6;
}
.pink-pink.dark .mat-tab-nav-bar,
.pink-pink.dark .mat-tab-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark .mat-tab-group-inverted-header .mat-tab-nav-bar,
.pink-pink.dark .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  border-bottom: none;
}
.pink-pink.dark .mat-tab-label, .pink-pink.dark .mat-tab-link {
  color: white;
}
.pink-pink.dark .mat-tab-label.mat-tab-disabled, .pink-pink.dark .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.pink-pink.dark .mat-tab-header-pagination-chevron {
  border-color: white;
}
.pink-pink.dark .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.5);
}
.pink-pink.dark .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.pink-pink.dark .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.pink-pink.dark .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-pink.dark .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-pink.dark .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-pink.dark .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .pink-pink.dark .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-pink.dark .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-pink.dark .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-pink.dark .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 187, 208, 0.3);
}
.pink-pink.dark .mat-tab-group.mat-primary .mat-ink-bar, .pink-pink.dark .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #f06292;
}
.pink-pink.dark .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .pink-pink.dark .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .pink-pink.dark .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .pink-pink.dark .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-pink.dark .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-pink.dark .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-pink.dark .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .pink-pink.dark .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-pink.dark .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-pink.dark .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-pink.dark .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 187, 208, 0.3);
}
.pink-pink.dark .mat-tab-group.mat-accent .mat-ink-bar, .pink-pink.dark .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #f48fb1;
}
.pink-pink.dark .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .pink-pink.dark .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .pink-pink.dark .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .pink-pink.dark .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-pink.dark .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-pink.dark .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-pink.dark .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .pink-pink.dark .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-pink.dark .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-pink.dark .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-pink.dark .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.pink-pink.dark .mat-tab-group.mat-warn .mat-ink-bar, .pink-pink.dark .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #e57373;
}
.pink-pink.dark .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .pink-pink.dark .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .pink-pink.dark .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .pink-pink.dark .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-pink.dark .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-pink.dark .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-pink.dark .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .pink-pink.dark .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-pink.dark .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-pink.dark .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-pink.dark .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 187, 208, 0.3);
}
.pink-pink.dark .mat-tab-group.mat-background-primary > .mat-tab-header, .pink-pink.dark .mat-tab-group.mat-background-primary > .mat-tab-link-container, .pink-pink.dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .pink-pink.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .pink-pink.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .pink-pink.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #f06292;
}
.pink-pink.dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .pink-pink.dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .pink-pink.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .pink-pink.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .pink-pink.dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .pink-pink.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .pink-pink.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.pink-pink.dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.pink-pink.dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.pink-pink.dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.pink-pink.dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .pink-pink.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.pink-pink.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.pink-pink.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.pink-pink.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.pink-pink.dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .pink-pink.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.pink-pink.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.pink-pink.dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.pink-pink.dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.pink-pink.dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .pink-pink.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.pink-pink.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.pink-pink.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.pink-pink.dark .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-pink.dark .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-pink.dark .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-pink.dark .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .pink-pink.dark .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-pink.dark .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-pink.dark .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-pink.dark .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 187, 208, 0.3);
}
.pink-pink.dark .mat-tab-group.mat-background-accent > .mat-tab-header, .pink-pink.dark .mat-tab-group.mat-background-accent > .mat-tab-link-container, .pink-pink.dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .pink-pink.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .pink-pink.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .pink-pink.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #f48fb1;
}
.pink-pink.dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .pink-pink.dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .pink-pink.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .pink-pink.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .pink-pink.dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .pink-pink.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .pink-pink.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.pink-pink.dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.pink-pink.dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.pink-pink.dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.pink-pink.dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .pink-pink.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.pink-pink.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.pink-pink.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.pink-pink.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.pink-pink.dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .pink-pink.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.pink-pink.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.pink-pink.dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.pink-pink.dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.pink-pink.dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .pink-pink.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.pink-pink.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.pink-pink.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.pink-pink.dark .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-pink.dark .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-pink.dark .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-pink.dark .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .pink-pink.dark .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-pink.dark .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-pink.dark .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-pink.dark .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.pink-pink.dark .mat-tab-group.mat-background-warn > .mat-tab-header, .pink-pink.dark .mat-tab-group.mat-background-warn > .mat-tab-link-container, .pink-pink.dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .pink-pink.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .pink-pink.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .pink-pink.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #e57373;
}
.pink-pink.dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .pink-pink.dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .pink-pink.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .pink-pink.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .pink-pink.dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .pink-pink.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .pink-pink.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.pink-pink.dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.pink-pink.dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.pink-pink.dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.pink-pink.dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .pink-pink.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.pink-pink.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.pink-pink.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.pink-pink.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.pink-pink.dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .pink-pink.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.pink-pink.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.pink-pink.dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.pink-pink.dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.pink-pink.dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .pink-pink.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.pink-pink.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.pink-pink.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.pink-pink.dark .mat-toolbar {
  background: #212121;
  color: white;
}
.pink-pink.dark .mat-toolbar.mat-primary {
  background: #f06292;
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark .mat-toolbar.mat-accent {
  background: #f48fb1;
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark .mat-toolbar.mat-warn {
  background: #e57373;
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark .mat-toolbar .mat-form-field-underline,
.pink-pink.dark .mat-toolbar .mat-form-field-ripple,
.pink-pink.dark .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.pink-pink.dark .mat-toolbar .mat-form-field-label,
.pink-pink.dark .mat-toolbar .mat-focused .mat-form-field-label,
.pink-pink.dark .mat-toolbar .mat-select-value,
.pink-pink.dark .mat-toolbar .mat-select-arrow,
.pink-pink.dark .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.pink-pink.dark .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.pink-pink.dark .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.pink-pink.dark .mat-tree {
  background: #424242;
}
.pink-pink.dark .mat-tree-node,
.pink-pink.dark .mat-nested-tree-node {
  color: white;
}
.pink-pink.dark .mat-snack-bar-container {
  color: rgba(0, 0, 0, 0.87);
  background: #fafafa;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.pink-pink.dark .mat-simple-snackbar-action {
  color: inherit;
}
.pink-pink.dark *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.pink-pink.dark *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
.pink-pink.dark *::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}
.pink-pink.dark .toolbar-line.container-ou {
  background: #f06292;
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark .toolbar-line.container-persona {
  background: #f06292 !important;
}
.pink-pink.dark .toolbar-line.container-persona .persona-title {
  background: #f06292;
}
.pink-pink.dark .toolbar-line.container-persona .invisible-container.persona-selector {
  background: #f06292 !important;
}
.pink-pink.dark button.mat-icon-button:not([disabled]):hover {
  background: rgba(255, 255, 255, 0.04);
}
.pink-pink.dark .side-bar {
  background: black;
}
.pink-pink.dark .icon-stacked {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-pink.dark .icon-stacked mat-icon + mat-icon {
  text-shadow: -1px 1px 0px #f06292, 1px -1px 0px #f06292;
}
.pink-pink.dark .divider-vert {
  background: rgba(255, 255, 255, 0.12);
}
.pink-pink.dark .avatar {
  background: #c51162 !important;
}
.pink-pink.dark ngx-avatar .avatar-content {
  background: #c51162 !important;
}
.pink-pink.dark .mat-loading-window {
  background: #424242;
}
.pink-pink.dark .window-spinner {
  background: #424242;
}
.pink-pink.dark .load-window {
  background: #424242;
}
@media (min-width: 600px) and (max-width: 1300px) {
  .pink-pink.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .load-window,
.pink-pink.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .load-window.new {
    background: #424242 !important;
  }
  .pink-pink.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card {
    background: #424242 !important;
  }
  .pink-pink.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .inbox-container mat-toolbar {
    background: #f06292;
    color: rgba(0, 0, 0, 0.87);
  }
  .pink-pink.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .inbox-container mat-list-item.mat-list-item {
    border: 1px solid rgba(255, 255, 255, 0.12);
  }
  .pink-pink.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .detail {
    background: #424242 !important;
  }
  .pink-pink.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .detail mat-toolbar {
    background: #424242 !important;
  }
  .pink-pink.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .main.w-detail + .new-detail-container .load-window {
    background: #303030 !important;
  }
  .pink-pink.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .main.w-detail + .new-detail-container:before {
    background: rgba(0, 0, 0, 0.6);
  }
  .pink-pink.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .main.side-open + .side {
    border-left: 1px solid rgba(255, 255, 255, 0.12);
  }
  .pink-pink.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .side .container .mat-list-base {
    background: #424242 !important;
    border: 1px solid rgba(255, 255, 255, 0.12);
  }
  .pink-pink.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .new-table .mat-header-row,
.pink-pink.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .new-table .mat-table,
.pink-pink.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .document-table .mat-header-row,
.pink-pink.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .document-table .mat-table,
.pink-pink.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .status-table .mat-header-row,
.pink-pink.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .status-table .mat-table {
    background: #424242 !important;
  }
  .pink-pink.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .audit-filter .filter-container.is-floating {
    background: #303030 !important;
    border: 1px solid rgba(255, 255, 255, 0.12);
  }
  .pink-pink.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .audit-filter .filter-container.is-floating .form-body {
    background: #303030 !important;
  }
  .pink-pink.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .audit-result {
    background: #424242;
  }
  .pink-pink.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .audit-detail-container {
    background: #303030 !important;
  }
}
@media (max-width: 599px) {
  .pink-pink.dark app-document-detail .mat-toolbar {
    background: #c51162 !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .pink-pink.dark app-document-detail .mat-toolbar .mat-toolbar-row .toolbar-actions {
    background: #c51162 !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .pink-pink.dark .login-card {
    background: #424242 !important;
  }
  .pink-pink.dark .inbox-toolbar {
    background: #c51162 !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .pink-pink.dark .detail {
    background: #424242 url("../img/spinner_mobile.gif") no-repeat center center !important;
  }
  .pink-pink.dark .toolbar-line-container .toolbar-line:first-child {
    background: #c51162 !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .pink-pink.dark .toolbar-actions {
    background: #c51162 !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .pink-pink.dark mat-drawer.side-nav.enter {
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(255, 255, 255, 0.12) !important;
  }
  .pink-pink.dark .side-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0) !important;
  }
  .pink-pink.dark .filter {
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.55) 16%, rgba(0, 0, 0, 0) 100%) !important;
  }
  .pink-pink.dark button.side-open-mobile {
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .pink-pink.dark .side-nav .side-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0);
  }
  .pink-pink.dark .side-nav .filter {
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.55) 16%, rgba(0, 0, 0, 0) 100%);
  }
  .pink-pink.dark .side-nav .side-nav-container .links {
    background: #424242;
    border-top: 1px solid rgba(255, 255, 255, 0.12);
  }
  .pink-pink.dark .side-nav .side-nav-container .version {
    background: #424242 !important;
    border-top: 1px solid rgba(255, 255, 255, 0.12);
  }
  .pink-pink.dark app-file-document-view .view-container {
    background: #424242 !important;
  }
  .pink-pink.dark app-file-document-view .view-container .side-bar {
    background: #c51162 !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .pink-pink.dark app-file-document-view .view-container .view-content app-pdf-wrapper.floating {
    background: #424242;
  }
  .pink-pink.dark app-file-document-view .view-container .view-content .upload-indications {
    background: black;
  }
  .pink-pink.dark app-file-document-view .view-container .view-content app-file-document-sign .sign-cycle mat-horizontal-stepper.mat-stepper-horizontal {
    background-color: rgba(0, 0, 0, 0) !important;
  }
  .pink-pink.dark app-file-document-view .view-container .view-content app-file-document-sign .sign-cycle .sign-cycle-content .cycle-content-action .mat-ripple-element {
    background-color: rgba(255, 255, 255, 0.1) !important;
  }
  .pink-pink.dark app-pending .floating-container .inbox-container .inbox-toolbar .mat-toolbar-row .mat-avatar h3 {
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .pink-pink.dark app-pending .floating-container .detail {
    background: #424242 url("../img/spinner_mobile.gif") no-repeat center center !important;
  }
  .pink-pink.dark app-document-sign-massive .inbox-container .pending-container app-chapa.minimized .info-empty {
    background: #f48fb1;
  }
  .pink-pink.dark app-document-sign-massive .inbox-container .pending-container app-chapa.minimized .info-empty > span h2 {
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .pink-pink.dark app-document-sign-massive .inbox-container .signer.mobile {
    border-top: 1px solid rgba(255, 255, 255, 0.12);
    background: #424242;
  }
  .pink-pink.dark app-document-sign-massive .detail app-file-document-detail .view-container .mat-toolbar .mat-toolbar-row .view-actions {
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .pink-pink.dark app-add-documentation .floating-container .employee-add-documentation .inbox-container .inbox-toolbar .mat-toolbar-row .mat-avatar h3 {
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .pink-pink.dark app-add-documentation .floating-container .employee-add-documentation .detail {
    background: #424242 url("../img/spinner_mobile.gif") no-repeat center center !important;
  }
  .pink-pink.dark app-add-documentation .floating-container .employee-add-documentation .detail app-file-document-view .view-container .view-content .new-container app-file-document-upload {
    background: #424242;
  }
}
.pink-pink.dark .mat-card .mat-drawer-container {
  background: #424242 !important;
}
.pink-pink.dark .mat-drawer-container {
  background: #212121 !important;
}
.pink-pink.dark .mat-list .mat-list-item.finished-process-inbox.active {
  background: #009688 !important;
}
.pink-pink.dark .mat-list .mat-list-item.finished-process-inbox .mat-icon {
  background: #009688;
}
.pink-pink.dark .mat-list .mat-list-item.erased-process-inbox.active {
  background: #837C80 !important;
}
.pink-pink.dark .mat-list .mat-list-item.limbo-process-inbox.active {
  background: #ffc107 !important;
}
.pink-pink.dark .mat-list .mat-list-item.limbo-process-inbox .mat-icon {
  background: #ffc107;
}
.pink-pink.dark .mat-list .mat-list-item.in-process-inbox.active {
  background: #ffc107 !important;
}
.pink-pink.dark .mat-list .mat-list-item.in-process-inbox .mat-icon {
  background: #ffc107;
}
.pink-pink.dark .mat-list .mat-list-item.error-process-inbox.active {
  background: #e91e63 !important;
}
.pink-pink.dark .mat-list .mat-list-item.error-process-inbox .mat-icon {
  background: #e91e63;
}
.pink-pink.dark .mat-list .mat-list-item.active .mat-line b {
  color: #f06292;
}
.pink-pink.dark .mat-list .mat-list-item.active .mat-list-avatar {
  color: #f06292;
}
.pink-pink.dark .mat-list .mat-list-item:hover:not(.active) {
  background: rgba(255, 255, 255, 0.04);
}
.pink-pink.dark span.mat-badge-content {
  color: #e57373 !important;
}
.pink-pink.dark mat-expansion-panel.mat-expanded {
  background: #303030 !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark mat-expansion-panel.virtual-scrolling .mat-expansion-panel-content .get-more {
  color: #f06292;
}
.pink-pink.dark mat-expansion-panel.virtual-scrolling .mat-expansion-panel-content app-cs-paginator.get-more {
  background: #424242;
}
.pink-pink.dark mat-expansion-panel-header.mat-expanded {
  background: #424242 !important;
}
.pink-pink.dark .bottom-container {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark .load-more * {
  color: #f06292;
}
.pink-pink.dark .welcome-card {
  background: #424242 !important;
}
.pink-pink.dark .welcome-card .img-divider {
  background: #c51162;
}
.pink-pink.dark .mat-header-row {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  background: #424242;
}
.pink-pink.dark .mat-row {
  background: #424242;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.pink-pink.dark .mat-row .mat-cell.status-cell mat-icon.pending {
  color: #ffb74d !important;
}
.pink-pink.dark .mat-row .mat-cell.status-cell mat-icon.ok {
  color: #4db6ac !important;
}
.pink-pink.dark .mat-row .mat-cell.status-cell mat-icon.not-ok {
  color: #ba68c8 !important;
}
.pink-pink.dark .mat-row .mat-cell.status-cell mat-icon.cancelled {
  color: #795548 !important;
}
.pink-pink.dark .mat-row .mat-cell.status-cell mat-icon.rejected {
  color: #df4d4c !important;
}
.pink-pink.dark .mat-row .mat-cell.status-cell mat-icon.draft {
  color: #dedede !important;
}
.pink-pink.dark .mat-row.active, .pink-pink.dark .mat-row.active:hover {
  background: #f06292 !important;
}
.pink-pink.dark .mat-row.active div, .pink-pink.dark .mat-row.active:hover div {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark .mat-row.active .mat-cell.status-cell mat-icon.pending, .pink-pink.dark .mat-row.active .mat-cell.status-cell mat-icon.ok, .pink-pink.dark .mat-row.active .mat-cell.status-cell mat-icon.not-ok, .pink-pink.dark .mat-row.active:hover .mat-cell.status-cell mat-icon.pending, .pink-pink.dark .mat-row.active:hover .mat-cell.status-cell mat-icon.ok, .pink-pink.dark .mat-row.active:hover .mat-cell.status-cell mat-icon.not-ok {
  text-shadow: -1px 1px 0 rgba(0, 0, 0, 0.87), 1px 1px 0 rgba(0, 0, 0, 0.87), 1px -1px 0 rgba(0, 0, 0, 0.87), -1px -1px 0 rgba(0, 0, 0, 0.87);
}
.pink-pink.dark .mat-row.active .sticky-actions button, .pink-pink.dark .mat-row.active:hover .sticky-actions button {
  background: #f06292 !important;
}
.pink-pink.dark .mat-row.active .sticky-actions button.ok mat-icon, .pink-pink.dark .mat-row.active .sticky-actions button.not-ok mat-icon, .pink-pink.dark .mat-row.active:hover .sticky-actions button.ok mat-icon, .pink-pink.dark .mat-row.active:hover .sticky-actions button.not-ok mat-icon {
  text-shadow: -1px 1px 0 rgba(0, 0, 0, 0.87), 1px 1px 0 rgba(0, 0, 0, 0.87), 1px -1px 0 rgba(0, 0, 0, 0.87), -1px -1px 0 rgba(0, 0, 0, 0.87);
}
.pink-pink.dark .mat-row:nth-child(odd) {
  background: #303030;
}
.pink-pink.dark .mat-row.selected div {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark .mat-row.selected:nth-child(even) {
  background: #f48fb1 !important;
}
.pink-pink.dark .mat-row.selected:nth-child(even) .sticky-actions button {
  background: #f48fb1 !important;
}
.pink-pink.dark .mat-row.selected:nth-child(odd) {
  background: #f8bbd0 !important;
}
.pink-pink.dark .mat-row.selected:nth-child(odd) .sticky-actions button {
  background: #f8bbd0 !important;
}
.pink-pink.dark .mat-row.selected.active, .pink-pink.dark .mat-row.selected.active:hover {
  background: #f06292 !important;
}
.pink-pink.dark .mat-row.selected.active .sticky-actions button, .pink-pink.dark .mat-row.selected.active:hover .sticky-actions button {
  background: #f06292 !important;
}
.pink-pink.dark .mat-row:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-pink.dark .mat-row:hover .sticky-actions button {
  background: #424242 !important;
}
.pink-pink.dark .mat-row.canceled * {
  color: #795548;
}
.pink-pink.dark .mat-row.disabled div {
  color: #607D8B;
}
.pink-pink.dark .mat-row.disabled.active, .pink-pink.dark .mat-row.disabled.active:hover {
  background: #607D8B !important;
}
.pink-pink.dark .mat-row.disabled.active div, .pink-pink.dark .mat-row.disabled.active:hover div {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark .mat-row.disabled.active .sticky-actions button, .pink-pink.dark .mat-row.disabled.active:hover .sticky-actions button {
  background: #607D8B !important;
}
.pink-pink.dark .mat-row.disabled.selected.active:hover .sticky-actions button {
  background: #607D8B !important;
}
.pink-pink.dark .mat-row .sticky-actions button.ok mat-icon {
  color: #4db6ac !important;
}
.pink-pink.dark .mat-row .sticky-actions button.not-ok mat-icon {
  color: #ba68c8 !important;
}
.pink-pink.dark .status-table .mat-header-row {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  background: #424242;
}
.pink-pink.dark .icon-info-off,
.pink-pink.dark .certificate-off {
  color: #607D8B;
}
.pink-pink.dark .documents-search-table .mat-header-row {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  background: #424242;
}
.pink-pink.dark mat-toolbar.employee-search-sub-toolbar {
  background: #424242;
}
.pink-pink.dark .mat-search-field {
  border: 1px solid rgba(255, 255, 255, 0.12);
  background: black;
}
.pink-pink.dark .mat-search-field input[type=search] {
  background: rgba(0, 0, 0, 0);
}
.pink-pink.dark .mat-search-field input[type=search]::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.pink-pink.dark .search-adv-menu .mat-search-field {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark mat-toolbar.document-search-sub-toolbar {
  background: #424242;
}
.pink-pink.dark app-advanced-employee-search .mat-chip {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-pink.dark .side-nav .side-trigger {
  background: #424242;
}
.pink-pink.dark .side-nav .menu-trigger mat-icon {
  color: rgba(255, 255, 255, 0.7);
  background: #424242;
}
.pink-pink.dark .side-nav .side-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0);
}
.pink-pink.dark .side-nav .filter {
  background: #424242;
}
.pink-pink.dark .side-nav .side-nav-container .links mat-icon span.mat-badge-content {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-pink.dark .side-nav .side-nav-container .mat-list-base .mat-list-item:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-pink.dark .side-nav .side-nav-container .mat-list-base .mat-list-item.active {
  background: #f06292 !important;
}
.pink-pink.dark .side-nav .side-nav-container .mat-list-base .mat-list-item.active * {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark .side-nav .side-nav-container .mat-list-base .mat-list-item.active .mat-list-item.active:hover {
  background: #f06292 !important;
}
.pink-pink.dark .side-nav .side-nav-container .mat-list-base .mat-list-item.active .mat-list-item.active:hover * {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark .side-nav.open .side-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark .side-nav.open .filter {
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.55) 16%, rgba(0, 0, 0, 0) 100%);
}
.pink-pink.dark .side-nav.open .side-nav-container .links {
  background: #424242;
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark .side-nav.open .side-nav-container .version {
  background: #424242 !important;
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark .side-nav.enter .side-nav-container .links {
  background: #424242;
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark .side-nav.enter .side-nav-container .version {
  background: #424242 !important;
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark .mat-menu-panel:not(.metadata-radio-menu) .navigation-list .mat-list-item-content {
  color: rgba(255, 255, 255, 0.7);
}
.pink-pink.dark table.mat-calendar-table {
  background: #424242 !important;
}
.pink-pink.dark .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-pink.dark .mat-calendar-arrow {
  border-top-color: #424242 !important;
}
.pink-pink.dark .mat-datepicker-content .mat-calendar-next-button,
.pink-pink.dark .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-pink.dark .mat-calendar-header {
  background: #c51162 !important;
}
.pink-pink.dark mat-datepicker-content {
  background-color: #c51162 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-pink.dark .mat-calendar-content {
  background: #424242 !important;
}
.pink-pink.dark .mat-simple-snackbar-action button.mat-button {
  color: #ff9800;
}
.pink-pink.dark .mat-simple-snackbar-action .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.pink-pink.dark mat-bottom-sheet-container mat-toolbar {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12) !important;
}
.pink-pink.dark app-generic-bottom-sheet h4.accent {
  color: #f48fb1;
}
.pink-pink.dark app-generic-bottom-sheet .verification-code {
  background: #f06292;
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark app-generic-bottom-sheet .verification-code h2 {
  background: #f06292;
}
.pink-pink.dark app-generic-bottom-sheet .load-window.message .info-container h3.primary-text {
  color: #f06292;
}
.pink-pink.dark app-generic-bottom-sheet .bottom_sheet-content.ok .bottom_sheet-title .bottom_sheet-icon,
.pink-pink.dark app-generic-bottom-sheet .bottom_sheet-content.ok .bottom_sheet-title h3 {
  color: #009688;
}
.pink-pink.dark app-generic-bottom-sheet .bottom_sheet-content.ok .bottom_sheet-main_btn:not(:disabled) {
  background: #009688;
  color: #fff;
}
.pink-pink.dark app-generic-bottom-sheet .bottom_sheet-content.not-ok .bottom_sheet-title .bottom_sheet-icon,
.pink-pink.dark app-generic-bottom-sheet .bottom_sheet-content.not-ok .bottom_sheet-title h3 {
  color: #9C27B0;
}
.pink-pink.dark app-generic-bottom-sheet .bottom_sheet-content.not-ok .bottom_sheet-main_btn:not(:disabled) {
  background: #9C27B0;
  color: #fff;
}
.pink-pink.dark app-generic-bottom-sheet .bottom_sheet-content.cancel .bottom_sheet-title .bottom_sheet-icon,
.pink-pink.dark app-generic-bottom-sheet .bottom_sheet-content.cancel .bottom_sheet-title h3 {
  color: #795548;
}
.pink-pink.dark app-generic-bottom-sheet .bottom_sheet-content.cancel .bottom_sheet-main_btn:not(:disabled) {
  background: #795548;
  color: #fff;
}
.pink-pink.dark app-generic-bottom-sheet .bottom_sheet-content .bottom_sheet_exp_btn {
  background-color: #f06292;
  color: #fff;
}
.pink-pink.dark app-generic-bottom-sheet .bottom_sheet-content .bottom_sheet_canc_btn {
  background-color: lightgrey;
  color: black;
}
.pink-pink.dark app-generic-bottom-sheet .bottom_sheet-content .bottom_sheet-exp-h3 {
  color: #f06292;
  margin-bottom: -10px !important;
}
.pink-pink.dark app-generic-bottom-sheet .bottom_sheet-content .mat-input-element-export {
  display: flex !important;
  padding-left: 15px;
}
.pink-pink.dark app-generic-bottom-sheet .bottom_sheet-content .mat-form-field-infix-export-right {
  width: 100% !important;
  padding: 0px !important;
  display: flex !important;
}
.pink-pink.dark app-generic-bottom-sheet .bottom_sheet-content .mat-form-field-infix-export-left {
  width: 100% !important;
  padding: 0px;
  display: flex !important;
}
.pink-pink.dark app-generic-bottom-sheet .bottom_sheet-content .mat-form-field-infix {
  display: flex;
}
.pink-pink.dark app-generic-bottom-sheet .bottom_sheet-content .mat-checkbox-layout .mat-checkbox-label {
  color: #f48fb1;
}
.pink-pink.dark app-generic-bottom-sheet .bottom_sheet-content .choose-forms {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  margin-left: 10px !important;
}
.pink-pink.dark app-generic-bottom-sheet .bottom_sheet-content .card-container {
  max-height: 150px;
  overflow-y: auto;
}
.pink-pink.dark .mat-list .mat-list-item.active .mat-line {
  color: white;
}
.pink-pink.dark .mat-list .mat-list-item.active .mat-line b {
  color: #f06292;
}
.pink-pink.dark .mat-list .mat-list-item.active .mat-list-avatar {
  color: #f06292;
}
.pink-pink.dark .mat-list .mat-list-item .mat-line {
  color: rgba(255, 255, 255, 0.7);
}
.pink-pink.dark .mat-list .mat-list-item .mat-list-avatar {
  color: rgba(255, 255, 255, 0.7);
}
.pink-pink.dark .mat-list-item:hover:not(.active) {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-pink.dark mat-list-item:nth-child(even),
.pink-pink.dark app-file-document-inbox-item:nth-child(even) mat-list-item,
.pink-pink.dark app-file-document-inbox-item-documentation:nth-child(even) mat-list-item {
  background: #303030 !important;
}
.pink-pink.dark mat-list-item:nth-child(odd),
.pink-pink.dark app-file-document-inbox-item:nth-child(odd) mat-list-item,
.pink-pink.dark app-file-document-inbox-item-documentation:nth-child(odd) mat-list-item {
  background: #424242 !important;
}
.pink-pink.dark .mat-list-avatar.mat-icon {
  background: black;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark .card-filter {
  background: #424242;
}
.pink-pink.dark .login mat-horizontal-stepper {
  background: #424242 !important;
}
.pink-pink.dark .login .login-card {
  background: #424242 !important;
}
.pink-pink.dark .login .login-card .mat-card-content .mat-card-footer a[color=accent] {
  color: #f48fb1;
}
.pink-pink.dark .login .help-stepper h3.align-16 {
  color: #f48fb1;
}
.pink-pink.dark .login .help-stepper .help-options mat-icon {
  color: #f48fb1;
}
.pink-pink.dark .ux-modal {
  background: rgba(0, 0, 0, 0.32);
}
.pink-pink.dark .ux-modal .card {
  background: #424242;
}
.pink-pink.dark .main-title {
  background: #607D8B;
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark .sub-title {
  color: rgba(255, 255, 255, 0.7);
}
.pink-pink.dark .card-footer a {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark .profile-toolbar {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark app-profile .logout-btn:hover {
  background: black;
}
.pink-pink.dark app-profile .cropper {
  background: #424242;
}
.pink-pink.dark app-profile .cropper .cropper {
  background: unset;
}
.pink-pink.dark app-add-documentation .employee-add-documentation .inbox-toolbar {
  background: #303030;
}
.pink-pink.dark app-add-documentation .employee-add-documentation .inbox-toolbar .toolbar-line mat-icon {
  color: #f06292;
}
.pink-pink.dark app-add-documentation .employee-add-documentation .inbox-toolbar .toolbar-line h3 {
  color: #f06292;
}
.pink-pink.dark app-add-documentation .employee-add-documentation mat-tab-group {
  background: #303030;
}
.pink-pink.dark app-add-documentation .employee-add-documentation mat-tab-group mat-tab-header .mat-tab-label-active {
  color: #f06292;
}
.pink-pink.dark app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content:hover {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(255, 255, 255, 0.12) !important;
}
.pink-pink.dark app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content.selected {
  border-left: 6px solid #f06292 !important;
}
.pink-pink.dark app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content.selected mat-icon {
  color: #f06292 !important;
}
.pink-pink.dark app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content.selected .item-data {
  color: white !important;
}
.pink-pink.dark app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content mat-icon {
  color: rgba(255, 255, 255, 0.7);
}
.pink-pink.dark app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content .item-data {
  color: rgba(255, 255, 255, 0.7);
}
.pink-pink.dark app-add-documentation .employee-add-documentation .detail app-file-document-view .new-container app-file-document-upload .metadata-list .mat-subheader {
  color: rgba(255, 255, 255, 0.7);
  background: #424242 !important;
}
.pink-pink.dark app-pending .inbox-toolbar {
  background: #303030;
}
.pink-pink.dark app-pending .pending-container {
  background: #303030;
}
.pink-pink.dark app-pending .pending-container .pending-items .pending-item .content:hover {
  background: rgba(255, 255, 255, 0.04) !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(255, 255, 255, 0.12) !important;
}
.pink-pink.dark app-pending .pending-container .pending-items .pending-item .content.selected {
  border-left: 6px solid #f48fb1 !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(255, 255, 255, 0.12) !important;
}
.pink-pink.dark app-pending .pending-container .pending-items .pending-item .content.selected mat-icon {
  color: #f48fb1 !important;
}
.pink-pink.dark app-pending .pending-container .pending-items .pending-item .content.selected .item-data {
  color: white !important;
}
.pink-pink.dark app-pending .pending-container .pending-items .pending-item .content mat-icon {
  color: rgba(255, 255, 255, 0.7);
}
.pink-pink.dark app-pending .pending-container .pending-items .pending-item .content .item-data {
  color: rgba(255, 255, 255, 0.7);
}
.pink-pink.dark app-document-sign-massive .inbox-container .inbox-toolbar {
  background: #303030;
}
.pink-pink.dark app-document-sign-massive .inbox-container .pending-container {
  background: #303030;
}
.pink-pink.dark app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content:hover {
  background: rgba(255, 255, 255, 0.04) !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(255, 255, 255, 0.12) !important;
}
.pink-pink.dark app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content.selected {
  border-left: 6px solid #f48fb1 !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(255, 255, 255, 0.12) !important;
}
.pink-pink.dark app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content.selected mat-icon {
  color: #f48fb1 !important;
}
.pink-pink.dark app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content.selected .item-data {
  color: white !important;
}
.pink-pink.dark app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content mat-icon {
  color: rgba(255, 255, 255, 0.7);
}
.pink-pink.dark app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content .item-data {
  color: rgba(255, 255, 255, 0.7);
}
.pink-pink.dark app-document-sign-massive .detail app-file-document-detail .view-container {
  background: #607D8B;
}
.pink-pink.dark app-document-sign-massive .detail app-file-document-detail .view-container .mat-toolbar {
  background: #607D8B;
  color: rgba(0, 0, 0, 0.87);
  background: #607D8B;
}
.pink-pink.dark app-document-sign-massive .detail app-chapa.minimized .info-empty {
  background: #f48fb1;
}
.pink-pink.dark app-document-sign-massive .detail app-chapa.minimized .info-empty > span mat-icon {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-pink.dark app-document-sign-massive .detail app-chapa.minimized .info-empty > span h2 {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-pink.dark app-document-sign-massive .signer.desktop {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark app-document-sign-massive .signer .signer-btn {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-pink.dark app-document-sign-massive .signer .signer-btn[disabled] {
  background-color: rgba(255, 255, 255, 0.12);
}
.pink-pink.dark app-document-detail app-sign-document .firmador button.mat-raised-button.mat-accent {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-pink.dark mat-toolbar-row.firmado-conforme .mat-icon {
  background: #4caf50;
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark mat-toolbar-row.firmado-no-conforme .mat-icon {
  background: #e57373;
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark .secondary-toolbar {
  background: #424242;
}
.pink-pink.dark .new-detail-container {
  border-left: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark .new-detail-container.cancelled .detail_head-body {
  background: #607D8B !important;
}
.pink-pink.dark .new-detail-container .employee-detail-toolbar {
  background: black;
}
.pink-pink.dark .new-detail-container .employee-detail-subbar {
  background: #424242;
}
.pink-pink.dark app-employee-detail .load-window,
.pink-pink.dark app-employee-rrhh-detail .load-window {
  background: #303030;
}
.pink-pink.dark app-employee-detail .employee_detail-container,
.pink-pink.dark app-employee-rrhh-detail .employee_detail-container {
  background: #303030;
}
.pink-pink.dark app-employee-detail .employee_detail-container.is-editing .edition-line,
.pink-pink.dark app-employee-rrhh-detail .employee_detail-container.is-editing .edition-line {
  background: black;
}
.pink-pink.dark app-employee-detail .employee_detail-container .detail_head-body,
.pink-pink.dark app-employee-rrhh-detail .employee_detail-container .detail_head-body {
  background: #f06292;
}
.pink-pink.dark app-employee-detail .employee_detail-container .detail_head-body .head_body-status .icon-stacked + mat-icon,
.pink-pink.dark app-employee-rrhh-detail .employee_detail-container .detail_head-body .head_body-status .icon-stacked + mat-icon {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-pink.dark app-employee-detail .employee_detail-container .detail_head-body .head_body-title .body_title-name,
.pink-pink.dark app-employee-rrhh-detail .employee_detail-container .detail_head-body .head_body-title .body_title-name {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark app-employee-detail .employee_detail-container .detail_head-body .head_body-title .body_title-user,
.pink-pink.dark app-employee-rrhh-detail .employee_detail-container .detail_head-body .head_body-title .body_title-user {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark app-employee-detail .employee_detail-container .data .data-line,
.pink-pink.dark app-employee-rrhh-detail .employee_detail-container .data .data-line {
  background: #424242;
}
.pink-pink.dark app-employee-detail .employee_detail-container .data .data-line .icon-complex mat-icon.complex,
.pink-pink.dark app-employee-rrhh-detail .employee_detail-container .data .data-line .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #424242, 0 -2px 0px #424242, 2px 0 0px #424242, 0 2px 0px #424242, -2px -2px 0px #424242, 2px 2px 0px #424242, 2px -2px 0px #424242, -2px 2px 0px #424242;
}
.pink-pink.dark app-employee-detail .employee_detail-container .data .data-line .line_text-main,
.pink-pink.dark app-employee-rrhh-detail .employee_detail-container .data .data-line .line_text-main {
  color: #f06292;
}
.pink-pink.dark app-employee-detail .employee_detail-container .data .data-line.clickable:hover,
.pink-pink.dark app-employee-rrhh-detail .employee_detail-container .data .data-line.clickable:hover {
  background: #303030 !important;
}
.pink-pink.dark app-employee-detail .employee_detail-container .data .data-line button.mat-icon-button,
.pink-pink.dark app-employee-rrhh-detail .employee_detail-container .data .data-line button.mat-icon-button {
  background: black;
}
.pink-pink.dark app-employee-detail .employee_detail-container .employee-title .title-text,
.pink-pink.dark app-employee-rrhh-detail .employee_detail-container .employee-title .title-text {
  color: #f06292;
}
.pink-pink.dark app-employee-detail .employee_detail-container .employee-title .title-text mat-icon,
.pink-pink.dark app-employee-rrhh-detail .employee_detail-container .employee-title .title-text mat-icon {
  color: #f06292;
}
.pink-pink.dark app-employee-detail .employee_detail-container .employee-title .title-text .title_text-main,
.pink-pink.dark app-employee-rrhh-detail .employee_detail-container .employee-title .title-text .title_text-main {
  color: #f06292;
}
.pink-pink.dark .UI-V2 .icon-complex mat-icon.complex {
  color: #f06292;
  text-shadow: -2px 0 0px #424242, 0 -2px 0px #424242, 2px 0 0px #424242, 0 2px 0px #424242, -2px -2px 0px #424242, 2px 2px 0px #424242, 2px -2px 0px #424242, -2px 2px 0px #424242;
}
.pink-pink.dark .UI-V2 .icon-complex mat-icon.complex.mat-accent {
  color: #f48fb1;
}
.pink-pink.dark .UI-V2 .main .toolbar .header .content .module-title {
  color: #f06292;
}
.pink-pink.dark .UI-V2 .main .toolbar .header .content .module-title.selectable:hover {
  background: rgba(255, 255, 255, 0.04);
}
.pink-pink.dark .UI-V2 .main .toolbar .header .content .module-title .info h5 {
  color: white !important;
}
.pink-pink.dark .UI-V2 .main .toolbar .header .content .ou-select {
  color: rgba(0, 0, 0, 0.87);
  background: #f06292;
}
.pink-pink.dark .UI-V2 .main .toolbar .search-container .search .content {
  background: #303030;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark .UI-V2 .main .toolbar .search-container .search .content:hover, .pink-pink.dark .UI-V2 .main .toolbar .search-container .search .content:active, .pink-pink.dark .UI-V2 .main .toolbar .search-container .search .content:focus, .pink-pink.dark .UI-V2 .main .toolbar .search-container .search .content:focus-within {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-pink.dark .UI-V2 .main .toolbar .search-container .search .content input {
  color: white;
}
.pink-pink.dark .UI-V2 .main .helper {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark .UI-V2 .main .helper mat-hint h4 {
  color: #f06292;
}
.pink-pink.dark .UI-V2 .main .new-table app-cs-grid-control datatable-header * {
  color: #f06292;
}
.pink-pink.dark .UI-V2 .main .new-table .mat-header-row.sticky * {
  color: #f06292 !important;
}
.pink-pink.dark .UI-V2 .main .main-action {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark .menu-options.help .primary {
  color: #f06292;
}
.pink-pink.dark .menu-options.help .accent {
  color: #f48fb1;
}
.pink-pink.dark .menu-options .icon-complex mat-icon.complex {
  color: #f06292;
  text-shadow: -2px 0 0px #424242, 0 -2px 0px #424242, 2px 0 0px #424242, 0 2px 0px #424242, -2px -2px 0px #424242, 2px 2px 0px #424242, 2px -2px 0px #424242, -2px 2px 0px #424242;
}
.pink-pink.dark .menu-options .mat-menu-content .container mat-list .mat-list-item:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-pink.dark .menu-options .mat-menu-content .container mat-list .mat-list-item .disabled {
  color: rgba(255, 255, 255, 0.7) !important;
}
.pink-pink.dark .menu-options .mat-menu-content .container .slide-group mat-radio-group mat-radio-button:hover {
  background: rgba(255, 255, 255, 0.04);
}
.pink-pink.dark .menu-options .mat-menu-content .container .slide-group mat-form-field .mat-form-field-flex {
  background: #303030;
}
.pink-pink.dark .menu-options .mat-menu-content .container .slide-group mat-slide-toggle:hover {
  background: rgba(255, 255, 255, 0.04);
}
.pink-pink.dark .mat-menu-panel.notifications-panel .mat-menu-content .notifications-body {
  background: #303030;
}
.pink-pink.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification .primary mat-icon {
  color: #f06292;
}
.pink-pink.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification .primary h3 {
  color: #f06292;
}
.pink-pink.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.top {
  background: #303030;
}
.pink-pink.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.top .header h3 {
  color: white;
}
.pink-pink.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.bottom {
  background: #303030;
}
.pink-pink.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable {
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable:nth-child(even) {
  background: #303030;
}
.pink-pink.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable:nth-child(odd) {
  background: #424242;
}
.pink-pink.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable:hover {
  background: rgba(255, 255, 255, 0.04);
}
.pink-pink.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable mat-icon {
  color: #f06292;
}
.pink-pink.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable h3 {
  color: white;
}
.pink-pink.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable h5 {
  color: rgba(255, 255, 255, 0.5);
}
.pink-pink.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable .subtitle h5 {
  color: #f06292;
  background: black;
}
.pink-pink.dark .info-empty .large-icon {
  background: #f06292;
  color: #f06292;
}
.pink-pink.dark .info-empty h2,
.pink-pink.dark .info-empty h3 {
  color: #f06292;
}
.pink-pink.dark .info-empty h4,
.pink-pink.dark .info-empty h5 {
  color: rgba(255, 255, 255, 0.7);
}
.pink-pink.dark .info-empty.accent mat-icon {
  background: #f48fb1;
  color: #f48fb1;
}
.pink-pink.dark .info-empty.accent h2 {
  color: #f48fb1;
}
.pink-pink.dark .info-empty .actions {
  padding: 16px;
  height: 96px;
  column-gap: 16px;
}
.pink-pink.dark .info-empty .actions button {
  height: 100%;
  border-radius: 40px;
}
.pink-pink.dark .info-empty .actions button.ok {
  background: #4db6ac !important;
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark .info-empty .actions button.not-ok {
  color: #ba68c8 !important;
}
.pink-pink.dark .side.edition .info-empty .large-icon {
  background: #f48fb1;
  color: #f48fb1;
}
.pink-pink.dark .side.edition .info-empty h2 {
  color: #f48fb1;
}
.pink-pink.dark .side.edition .info-empty h4 {
  color: rgba(255, 255, 255, 0.7);
}
.pink-pink.dark .UI-V2 .deferred-process-list .header h3 {
  color: #f06292;
}
.pink-pink.dark .UI-V2 .deferred-process-list .search-container .search .content {
  background: #303030;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark .UI-V2 .deferred-process-list .search-container .search .content:hover, .pink-pink.dark .UI-V2 .deferred-process-list .search-container .search .content:active, .pink-pink.dark .UI-V2 .deferred-process-list .search-container .search .content:focus, .pink-pink.dark .UI-V2 .deferred-process-list .search-container .search .content:focus-within {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-pink.dark .UI-V2 .deferred-process-list .search-container .search .content input {
  color: white;
}
.pink-pink.dark .UI-V2 .deferred-process-list .text-success,
.pink-pink.dark .UI-V2 .deferred-process-details .text-success {
  color: #4caf50;
}
.pink-pink.dark .UI-V2 .deferred-process-list .text-warn,
.pink-pink.dark .UI-V2 .deferred-process-details .text-warn {
  color: #e57373;
}
.pink-pink.dark .UI-V2 .deferred-process-list .text-primary,
.pink-pink.dark .UI-V2 .deferred-process-details .text-primary {
  color: #f06292;
}
.pink-pink.dark .UI-V2 .deferred-process-list .text-atention,
.pink-pink.dark .UI-V2 .deferred-process-details .text-atention {
  color: #DB7703;
}
.pink-pink.dark .UI-V2 .deferred-process-list .table-paginator-container,
.pink-pink.dark .UI-V2 .deferred-process-details .table-paginator-container {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
}
.pink-pink.dark .UI-V2 .deferred-process-list .table-paginator-container .mat-table,
.pink-pink.dark .UI-V2 .deferred-process-details .table-paginator-container .mat-table {
  border-bottom: none;
  border-radius: 10px 10px 0 0;
}
.pink-pink.dark .UI-V2 .deferred-process-list .table-paginator-container .paginator-container,
.pink-pink.dark .UI-V2 .deferred-process-details .table-paginator-container .paginator-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: #f2f2f2;
  padding: 0 1.5rem;
  height: 3rem;
}
.pink-pink.dark .UI-V2 .deferred-process-list .mat-table,
.pink-pink.dark .UI-V2 .deferred-process-details .mat-table {
  border: 1px solid #f2f2f2;
  border-radius: 10px;
  overflow: hidden;
}
.pink-pink.dark .UI-V2 .deferred-process-list .mat-table .mat-header-row .mat-header-cell,
.pink-pink.dark .UI-V2 .deferred-process-details .mat-table .mat-header-row .mat-header-cell {
  font-size: 14px;
  font-weight: 700;
}
.pink-pink.dark .UI-V2 .deferred-process-list .mat-table .mat-header-row,
.pink-pink.dark .UI-V2 .deferred-process-details .mat-table .mat-header-row {
  background: #f2f2f2;
}
.pink-pink.dark .UI-V2 .deferred-process-list .mat-table .mat-header-row .mat-header-cell,
.pink-pink.dark .UI-V2 .deferred-process-details .mat-table .mat-header-row .mat-header-cell {
  color: #f06292;
}
.pink-pink.dark .UI-V2 .deferred-process-list .mat-table .mat-row,
.pink-pink.dark .UI-V2 .deferred-process-details .mat-table .mat-row {
  background: #424242;
}
.pink-pink.dark .UI-V2 .progress-bar-container {
  position: relative;
}
.pink-pink.dark .UI-V2 .deferred-process-details .header h3 {
  color: #f06292;
}
.pink-pink.dark .UI-V2 .deferred-process-details .header .back-link:visited {
  color: #f06292;
}
.pink-pink.dark .process-card,
.pink-pink.dark .process-card-detail,
.pink-pink.dark .process-card-stages {
  background: #303030;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark .process-card mat-card-subtitle,
.pink-pink.dark .process-card-detail mat-card-subtitle,
.pink-pink.dark .process-card-stages mat-card-subtitle {
  color: white;
}
.pink-pink.dark .process-card .stages-container .stage.completed mat-icon,
.pink-pink.dark .process-card-detail .stages-container .stage.completed mat-icon,
.pink-pink.dark .process-card-stages .stages-container .stage.completed mat-icon {
  color: #499719;
}
.pink-pink.dark .process-card .stages-container .stage.completed .icon-container,
.pink-pink.dark .process-card-detail .stages-container .stage.completed .icon-container,
.pink-pink.dark .process-card-stages .stages-container .stage.completed .icon-container {
  background-color: rgba(78, 160, 28, 0.1490196078);
}
.pink-pink.dark .process-card .stages-container .stage.completed .line,
.pink-pink.dark .process-card-detail .stages-container .stage.completed .line,
.pink-pink.dark .process-card-stages .stages-container .stage.completed .line {
  background-color: #499719;
}
.pink-pink.dark .process-card .stages-container .stage.on-going mat-icon,
.pink-pink.dark .process-card-detail .stages-container .stage.on-going mat-icon,
.pink-pink.dark .process-card-stages .stages-container .stage.on-going mat-icon {
  color: #f06292;
}
.pink-pink.dark .process-card .stages-container .stage.on-going .icon-container,
.pink-pink.dark .process-card-detail .stages-container .stage.on-going .icon-container,
.pink-pink.dark .process-card-stages .stages-container .stage.on-going .icon-container {
  background-color: rgba(66, 48, 159, 0.2);
}
.pink-pink.dark .process-card .stages-container .stage.on-going .line,
.pink-pink.dark .process-card-detail .stages-container .stage.on-going .line,
.pink-pink.dark .process-card-stages .stages-container .stage.on-going .line {
  background-color: #949494;
}
.pink-pink.dark .process-card .stages-container .stage.pending,
.pink-pink.dark .process-card-detail .stages-container .stage.pending,
.pink-pink.dark .process-card-stages .stages-container .stage.pending {
  color: #949494;
}
.pink-pink.dark .process-card .stages-container .stage.pending mat-icon,
.pink-pink.dark .process-card-detail .stages-container .stage.pending mat-icon,
.pink-pink.dark .process-card-stages .stages-container .stage.pending mat-icon {
  color: #949494;
}
.pink-pink.dark .process-card .stages-container .stage.pending .icon-container,
.pink-pink.dark .process-card-detail .stages-container .stage.pending .icon-container,
.pink-pink.dark .process-card-stages .stages-container .stage.pending .icon-container {
  background-color: rgba(174, 174, 174, 0.2);
}
.pink-pink.dark .process-card .stages-container .stage.pending .line,
.pink-pink.dark .process-card-detail .stages-container .stage.pending .line,
.pink-pink.dark .process-card-stages .stages-container .stage.pending .line {
  background-color: #949494;
}
.pink-pink.dark .process-card .stages-container .stage.has-error mat-icon,
.pink-pink.dark .process-card-detail .stages-container .stage.has-error mat-icon,
.pink-pink.dark .process-card-stages .stages-container .stage.has-error mat-icon {
  color: #e57373;
}
.pink-pink.dark .process-card .stages-container .stage.has-error .icon-container,
.pink-pink.dark .process-card-detail .stages-container .stage.has-error .icon-container,
.pink-pink.dark .process-card-stages .stages-container .stage.has-error .icon-container {
  background-color: rgba(188, 4, 4, 0.2);
}
.pink-pink.dark .process-card .stages-container .stage-description .button-link,
.pink-pink.dark .process-card-detail .stages-container .stage-description .button-link,
.pink-pink.dark .process-card-stages .stages-container .stage-description .button-link {
  color: #f06292;
}
.pink-pink.dark app-custom-search-input .custom-search-container {
  background: #303030;
  border: 1px solid rgba(255, 255, 255, 0.12);
  transition: color 0.1s ease, background 0.15s ease;
}
.pink-pink.dark app-custom-search-input .custom-search-container mat-icon.mat-icon {
  color: rgba(255, 255, 255, 0.5);
}
.pink-pink.dark app-custom-search-input .custom-search-container input.custom-search-input {
  color: white;
  background: unset;
}
.pink-pink.dark app-custom-search-input .custom-search-container:hover, .pink-pink.dark app-custom-search-input .custom-search-container:active, .pink-pink.dark app-custom-search-input .custom-search-container:focus, .pink-pink.dark app-custom-search-input .custom-search-container:focus-within {
  background: rgba(255, 255, 255, 0.04) !important;
  transition: background 0.15s ease;
}
.pink-pink.dark .UI-V2 .audit-filter {
  background: #424242;
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark .UI-V2 .audit-filter .toolbar {
  background: #f06292;
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark .UI-V2 .audit-filter .toolbar .header .content .module-title {
  color: #f06292;
}
.pink-pink.dark .UI-V2 .audit-filter .toolbar .header .content .module-title.selectable:hover {
  background: rgba(255, 255, 255, 0.04);
}
.pink-pink.dark .UI-V2 .audit-filter .toolbar .header .content .module-title .info * {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-pink.dark .UI-V2 .audit-filter .toolbar .header .content .ou-select {
  color: rgba(0, 0, 0, 0.87);
  background: #f06292;
}
.pink-pink.dark .UI-V2 .audit-filter .toolbar .search-container .search .content {
  background: #303030;
}
.pink-pink.dark .UI-V2 .audit-filter .toolbar .search-container .search .content:hover, .pink-pink.dark .UI-V2 .audit-filter .toolbar .search-container .search .content:active, .pink-pink.dark .UI-V2 .audit-filter .toolbar .search-container .search .content:focus, .pink-pink.dark .UI-V2 .audit-filter .toolbar .search-container .search .content:focus-within {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-pink.dark .UI-V2 .audit-filter .toolbar .search-container .search .content input {
  color: white;
}
.pink-pink.dark .UI-V2 .audit-filter .filter-container {
  background: #424242 !important;
}
.pink-pink.dark .UI-V2 .audit-filter .filter-container .form-body {
  background: #303030 !important;
}
.pink-pink.dark .UI-V2 .audit-filter .filter-container .form-body .line h4 {
  color: #f06292;
}
.pink-pink.dark .UI-V2 .audit-filter .filter-container .form-body .line mat-icon {
  color: #f06292;
}
.pink-pink.dark .UI-V2 .audit-result .result-container .toolbar {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark .UI-V2 .audit-result .result-container .toolbar .header .content .module-title {
  color: #f06292;
}
.pink-pink.dark .UI-V2 .audit-result .result-container .toolbar .header .content .module-title.selectable:hover {
  background: rgba(255, 255, 255, 0.04);
}
.pink-pink.dark .UI-V2 .audit-result .result-container .toolbar .header .content .module-title .info h5 {
  color: white !important;
}
.pink-pink.dark .UI-V2 .audit-result .result-container .toolbar .header .content .ou-select {
  color: rgba(0, 0, 0, 0.87);
  background: #f06292;
}
.pink-pink.dark .UI-V2 .audit-result .result-container .toolbar .search-container .search .content {
  background: #303030;
}
.pink-pink.dark .UI-V2 .audit-result .result-container .toolbar .search-container .search .content:hover, .pink-pink.dark .UI-V2 .audit-result .result-container .toolbar .search-container .search .content:active, .pink-pink.dark .UI-V2 .audit-result .result-container .toolbar .search-container .search .content:focus, .pink-pink.dark .UI-V2 .audit-result .result-container .toolbar .search-container .search .content:focus-within {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-pink.dark .UI-V2 .audit-result .result-container .toolbar .search-container .search .content input {
  color: white;
}
.pink-pink.dark .UI-V2 .audit-result .result-container .new-table app-cs-grid-control datatable-header * {
  color: #f06292;
}
.pink-pink.dark .UI-V2 .audit-result .result-container .new-table .mat-header-row.sticky * {
  color: #f06292 !important;
}
.pink-pink.dark .UI-V2 .audit-detail-container {
  border-left: 1px solid rgba(255, 255, 255, 0.12);
  background: black !important;
}
.pink-pink.dark .UI-V2 .audit-detail-container .side .toolbar .header {
  color: #f06292;
}
.pink-pink.dark .UI-V2 .audit-detail-container .side .container .mat-list-base {
  background: #303030 !important;
}
.pink-pink.dark .UI-V2 .audit-detail-container .side .container .mat-list-base mat-list-item .mat-line b {
  color: #f06292;
}
.pink-pink.dark .UI-V2 .inbox-container {
  background: #424242;
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark .UI-V2 .inbox-container *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.pink-pink.dark .UI-V2 .inbox-container *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
.pink-pink.dark .UI-V2 .inbox-container *::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}
.pink-pink.dark .UI-V2 .inbox-container .load-window {
  background: #424242;
}
.pink-pink.dark .UI-V2 .inbox-container .toolbar {
  background: #f06292;
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark .UI-V2 .inbox-container .toolbar.reverse {
  background: #424242;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel {
  background: black !important;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel.mat-expanded {
  background: #303030 !important;
}
.pink-pink.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel.mat-expanded mat-expansion-panel-header {
  background: #303030 !important;
}
.pink-pink.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel.mat-expanded mat-expansion-panel-header:hover {
  background: none !important;
}
.pink-pink.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel mat-expansion-panel-header {
  background: #303030 !important;
}
.pink-pink.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel mat-expansion-panel-header:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-pink.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel mat-expansion-panel-header .signature-inbox-header .mat-icon,
.pink-pink.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel mat-expansion-panel-header .signature-inbox-header b {
  color: #f48fb1;
}
.pink-pink.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel .mat-expansion-panel-body .item {
  background: #424242;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel .mat-expansion-panel-body .item:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-pink.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel .mat-expansion-panel-body .item.active {
  border-left: 6px solid #f48fb1;
}
.pink-pink.dark .UI-V2 .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.pink-pink.dark .UI-V2 .work-space .main .load-window {
  background: #424242 !important;
}
.pink-pink.dark .UI-V2 .work-space .main-action {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark mat-radio-button.disabled .mat-radio-label-content {
  color: #f48fb1;
}
.pink-pink.dark .detail app-automatic-process-detail app-chapa.minimize,
.pink-pink.dark .detail app-process-detail app-chapa.minimize {
  background: #ffc107 !important;
}
.pink-pink.dark .detail app-automatic-process-detail app-chapa.minimize.purged,
.pink-pink.dark .detail app-process-detail app-chapa.minimize.purged {
  background: #b2adb0 !important;
}
.pink-pink.dark .detail .detail-toolbar {
  background: black;
}
.pink-pink.dark .detail .processes-status .status-bar .bar-graph {
  background-color: #ffc107;
}
.pink-pink.dark .detail .processes-status .status-bar .bar-graph .graph-ok {
  background-color: #009688;
}
.pink-pink.dark .detail .processes-status .status-bar .bar-graph .graph-not-ok {
  background-color: #e91e63;
}
.pink-pink.dark .detail .processes-status .status-resume b {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark .detail .processes-status .status-resume .resume-total b {
  background-color: #ffc107;
}
.pink-pink.dark .detail .processes-status .status-resume .resume-ok b {
  background-color: #009688;
}
.pink-pink.dark .detail .processes-status .status-resume .resume-not-ok b {
  background-color: #e91e63;
}
.pink-pink.dark .detail .detail-toolbar .mat-avatar h3,
.pink-pink.dark .detail .detail-toolbar .mat-avatar h5 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark .detail .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark .detail .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark .detail .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark .detail .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark .detail .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  background: #009688 !important;
}
.pink-pink.dark .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  background: #b2dfdb !important;
}
.pink-pink.dark .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  background: #b2dfdb !important;
}
.pink-pink.dark .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  background: #009688 !important;
}
.pink-pink.dark .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  background: #b2dfdb !important;
}
.pink-pink.dark .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  background: #837C80 !important;
}
.pink-pink.dark .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  background: #b2adb0 !important;
}
.pink-pink.dark .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  background: #b2adb0 !important;
}
.pink-pink.dark .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  background: #837C80 !important;
}
.pink-pink.dark .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  background: #b2dfdb !important;
}
.pink-pink.dark .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  background: #ffc107 !important;
}
.pink-pink.dark .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  background: #ffecb3 !important;
}
.pink-pink.dark .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  background: #ffecb3 !important;
}
.pink-pink.dark .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  background: #ffc107 !important;
}
.pink-pink.dark .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  background: #ffecb3 !important;
}
.pink-pink.dark .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  background: #e91e63 !important;
}
.pink-pink.dark .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  background: #f8bbd0 !important;
}
.pink-pink.dark .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  background: #f8bbd0 !important;
}
.pink-pink.dark .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  background: #e91e63 !important;
}
.pink-pink.dark .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  background: #f8bbd0 !important;
}
.pink-pink.dark app-analytics-document-set .sets-container .mat-list-base.sets-list mat-list-item {
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark app-analytics-document-set .sets-container .mat-list-base.sets-list mat-list-item .mat-list-item-content .set-status .value-percentile .undone-value {
  background: #673AB7;
}
.pink-pink.dark app-analytics-document-set .sets-container .mat-list-base.sets-list mat-list-item .mat-list-item-content .set-status .value-percentile .not-value {
  background: black;
}
.pink-pink.dark app-analytics-document-set .sets-container .mat-list-base.sets-list mat-list-item .mat-list-item-content .set-status .value-percentile .done-value {
  background: #009688;
}
.pink-pink.dark app-analytics-document-set .work-space .main .toolbar .header .content .module-title .info .info-text .set-status {
  cursor: help;
}
.pink-pink.dark app-analytics-document-set .work-space .main .toolbar .header .content .module-title .info .info-text .set-status .value-percentile .undone-value {
  background: #673AB7;
}
.pink-pink.dark app-analytics-document-set .work-space .main .toolbar .header .content .module-title .info .info-text .set-status .value-percentile .not-value {
  background: black;
}
.pink-pink.dark app-analytics-document-set .work-space .main .toolbar .header .content .module-title .info .info-text .set-status .value-percentile .done-value {
  background: #009688;
}
.pink-pink.dark app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar .mat-progress-bar-buffer {
  background-color: black;
}
.pink-pink.dark app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar.class1 .mat-progress-bar-fill::after {
  background-color: #f44336;
}
.pink-pink.dark app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar.class2 .mat-progress-bar-fill::after {
  background-color: #ff9800;
}
.pink-pink.dark app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar.class3 .mat-progress-bar-fill::after {
  background-color: #ffeb3b;
}
.pink-pink.dark app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar.class4 .mat-progress-bar-fill::after {
  background-color: #8bc34a;
}
.pink-pink.dark app-analytics-document-set .work-space .side .container mat-list {
  background: #424242 !important;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark app-analytics-document-set .work-space .side .container mat-list mat-list-item .mat-line b {
  color: #f06292;
}
.pink-pink.dark .catcher {
  background: rgba(0, 0, 0, 0.6);
}
.pink-pink.dark .catcher.clear {
  background: rgba(0, 0, 0, 0);
}
.pink-pink.dark .add-menu-form-body .mat-radio-group .mat-radio-button:hover {
  background: rgba(255, 255, 255, 0.04);
}
.pink-pink.dark .add-menu-form-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b {
  color: white;
}
.pink-pink.dark .add-menu-form-body.w-table .helper .search-bar {
  background: #303030;
}
.pink-pink.dark .add-menu-form-body.w-table .helper .search-bar:hover, .pink-pink.dark .add-menu-form-body.w-table .helper .search-bar:active, .pink-pink.dark .add-menu-form-body.w-table .helper .search-bar:focus, .pink-pink.dark .add-menu-form-body.w-table .helper .search-bar:focus-within {
  background: #303030 !important;
}
.pink-pink.dark .add-menu-form-body.w-table .helper .search-bar input {
  color: white;
}
.pink-pink.dark .add-menu-form-actions {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark .file-upload-list mat-list-item {
  background: #424242;
}
.pink-pink.dark .mat-horizontal-stepper-content[aria-expanded=true] app-file-document-view {
  border-left: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark .mat-horizontal-stepper-content[aria-expanded=true] app-file-document-view .pdf-view-container {
  background: black;
}
.pink-pink.dark .add-docs-drop .docs-drop-preview app-file-document-detail {
  border-left: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark .add-docs-drop .docs-drop-preview app-file-document-detail .view-toolbar {
  background: #424242;
}
.pink-pink.dark .add-docs-drop .load-window.message h3.primary-text {
  color: #f06292;
}
.pink-pink.dark mat-dialog-container app-add-menu-dialog .load-window.message h3.primary-text {
  color: #f06292;
}
.pink-pink.dark .asisted-input.aligned-icon.subtitle * {
  color: rgba(255, 255, 255, 0.5) !important;
}
.pink-pink.dark .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble {
  background: #f06292;
}
.pink-pink.dark .asisted-input .mat-form-field-invalid + .indicator > .bubble {
  background: #e57373;
}
.pink-pink.dark .asisted-input .mat-form-field-disabled + .indicator > .bubble {
  color: rgba(255, 255, 255, 0.5);
  background: rgba(255, 255, 255, 0.12);
}
.pink-pink.dark .asisted-input .indicator .bubble {
  background: rgba(255, 255, 255, 0.5);
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark .asisted-input .indicator .guide {
  border-color: rgba(255, 255, 255, 0.12);
}
.pink-pink.dark .asisted-input.icon-only .bubble {
  color: rgba(255, 255, 255, 0.7);
}
.pink-pink.dark .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble {
  color: #f06292;
}
.pink-pink.dark .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble {
  color: #e57373;
}
.pink-pink.dark .UI-V2 .main-overlay {
  background: rgba(0, 0, 0, 0.32);
}
.pink-pink.dark .UI-V2 .icon-complex mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.pink-pink.dark .UI-V2 .icon-complex mat-icon.complex {
  color: #f06292 !important;
}
.pink-pink.dark .UI-V2 .navigation {
  background: #303030;
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark .UI-V2 .navigation *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.pink-pink.dark .UI-V2 .navigation *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
.pink-pink.dark .UI-V2 .navigation .load-window {
  background: black;
}
.pink-pink.dark .UI-V2 .navigation .toolbar {
  background: #f06292;
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark .UI-V2 .navigation .toolbar.reverse {
  background: #424242;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark .UI-V2 .navigation .container .helper {
  color: rgba(255, 255, 255, 0.7);
}
.pink-pink.dark .UI-V2 .navigation .container .item {
  background: #424242;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark .UI-V2 .navigation .container .item:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-pink.dark .UI-V2 .navigation .container .item.active {
  background: #f06292 !important;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #f06292;
}
.pink-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .separator .subtitle,
.pink-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .separator .subtitle,
.pink-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .separator .subtitle,
.pink-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .separator .subtitle,
.pink-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .separator .subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.pink-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .img-editior-container .img-editor .separator .subtitle,
.pink-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .img-editior-container .img-editor .separator .subtitle,
.pink-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .img-editior-container .img-editor .separator .subtitle,
.pink-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .img-editior-container .img-editor .separator .subtitle,
.pink-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .img-editior-container .img-editor .separator .subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.pink-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme,
.pink-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme,
.pink-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme,
.pink-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme,
.pink-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .main-color-container .main-color,
.pink-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .main-color-container .main-color,
.pink-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .main-color-container .main-color,
.pink-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .main-color-container .main-color,
.pink-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .main-color-container .main-color {
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .main-color-container .main-color:before,
.pink-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .main-color-container .main-color:before,
.pink-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .main-color-container .main-color:before,
.pink-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .main-color-container .main-color:before,
.pink-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .main-color-container .main-color:before {
  border-left: 10px solid rgba(0, 0, 0, 0);
  border-right: 10px solid rgba(0, 0, 0, 0);
}
.pink-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example h6.required,
.pink-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example h6.required,
.pink-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example h6.required,
.pink-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example h6.required,
.pink-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example h6.required {
  color: #e57373 !important;
}
.pink-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle,
.pink-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle,
.pink-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle,
.pink-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle,
.pink-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle {
  border: 2px solid rgba(0, 0, 0, 0);
}
.pink-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .side-color-container .side-color,
.pink-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .side-color-container .side-color,
.pink-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .side-color-container .side-color,
.pink-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .side-color-container .side-color,
.pink-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .side-color-container .side-color {
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .side-color-container .side-color:before,
.pink-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .side-color-container .side-color:before,
.pink-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .side-color-container .side-color:before,
.pink-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .side-color-container .side-color:before,
.pink-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .side-color-container .side-color:before {
  border-top: 10px solid rgba(0, 0, 0, 0);
  border-bottom: 10px solid rgba(0, 0, 0, 0);
}
.pink-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .side-color-container .side-color h6.required,
.pink-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .side-color-container .side-color h6.required,
.pink-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .side-color-container .side-color h6.required,
.pink-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .side-color-container .side-color h6.required,
.pink-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .side-color-container .side-color h6.required {
  color: #e57373 !important;
}
.pink-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .permission-settings-cards .card,
.pink-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .permission-settings-cards .card,
.pink-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .permission-settings-cards .card,
.pink-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .permission-settings-cards .card,
.pink-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .permission-settings-cards .card {
  background: #303030;
}
.pink-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .permission-settings-cards .card .slide-sub-group,
.pink-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .permission-settings-cards .card .slide-sub-group,
.pink-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .permission-settings-cards .card .slide-sub-group,
.pink-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .permission-settings-cards .card .slide-sub-group,
.pink-pink.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .permission-settings-cards .card .slide-sub-group {
  background: rgba(255, 255, 255, 0.12);
}
.pink-pink.dark .UI-V2 .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.pink-pink.dark .UI-V2 .work-space .main .load-window {
  background: #424242 !important;
}
.pink-pink.dark .UI-V2 .work-space .main.side-open {
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item {
  background: #303030;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item mat-icon.complex {
  text-shadow: -2px 0 0px black, 0 -2px 0px black, 2px 0 0px black, 0 2px 0px black, -2px -2px 0px black, 2px 2px 0px black, 2px -2px 0px black, -2px 2px 0px black !important;
}
.pink-pink.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item h5 {
  color: rgba(255, 255, 255, 0.5);
}
.pink-pink.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item h3 {
  color: white;
}
.pink-pink.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item .actions {
  pointer-events: none;
}
.pink-pink.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item .actions button {
  pointer-events: all;
}
.pink-pink.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled {
  background: black;
}
.pink-pink.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled h3 {
  color: #607D8B !important;
}
.pink-pink.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled mat-icon {
  color: #607D8B !important;
}
.pink-pink.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled.selected {
  background: #607D8B;
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-pink.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled.selected h3 {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-pink.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled.selected mat-icon {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-pink.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled.selected mat-icon.complex {
  color: rgba(0, 0, 0, 0.87) !important;
  text-shadow: -2px 0 0px #607D8B, 0 -2px 0px #607D8B, 2px 0 0px #607D8B, 0 2px 0px #607D8B, -2px -2px 0px #607D8B, 2px 2px 0px #607D8B, 2px -2px 0px #607D8B, -2px 2px 0px #607D8B !important;
}
.pink-pink.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item:hover {
  background: rgba(255, 255, 255, 0.04);
}
.pink-pink.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected {
  background: #f06292;
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected mat-icon {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected mat-icon.complex {
  color: rgba(0, 0, 0, 0.87) !important;
  text-shadow: -2px 0 0px #f06292, 0 -2px 0px #f06292, 2px 0 0px #f06292, 0 2px 0px #f06292, -2px -2px 0px #f06292, 2px 2px 0px #f06292, 2px -2px 0px #f06292, -2px 2px 0px #f06292 !important;
}
.pink-pink.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected h5,
.pink-pink.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected h3 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark .UI-V2 .work-space .main .main-action {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark .UI-V2 .work-space .side {
  background: #303030;
}
.pink-pink.dark .UI-V2 .work-space .side .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #303030, 0 -2px 0px #303030, 2px 0 0px #303030, 0 2px 0px #303030, -2px -2px 0px #303030, 2px 2px 0px #303030, 2px -2px 0px #303030, -2px 2px 0px #303030;
}
.pink-pink.dark .UI-V2 .work-space .side *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.pink-pink.dark .UI-V2 .work-space .side *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
.pink-pink.dark .UI-V2 .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #303030 !important;
}
.pink-pink.dark .UI-V2 .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(255, 255, 255, 0.5);
}
.pink-pink.dark .UI-V2 .work-space .side mat-form-field .mat-form-field-flex {
  background: #303030;
}
.pink-pink.dark .UI-V2 .work-space .side mat-checkbox:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-pink.dark .UI-V2 .work-space .side .hint.all {
  color: #f48fb1;
}
.pink-pink.dark .UI-V2 .work-space .side .toolbar .header {
  color: #f06292;
}
.pink-pink.dark .UI-V2 .work-space .side .toolbar .header h3 {
  color: #f06292;
}
.pink-pink.dark .UI-V2 .work-space .side .container .role-resume {
  background: #f06292;
}
.pink-pink.dark .UI-V2 .work-space .side .container .role-resume .line {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark .UI-V2 .work-space .side .container .role-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.1);
}
.pink-pink.dark .UI-V2 .work-space .side .container .role-resume .line.editable button {
  background: #f06292;
}
.pink-pink.dark .UI-V2 .work-space .side .container .section-title {
  color: #f06292;
}
.pink-pink.dark .UI-V2 .work-space .side .container .section-resume {
  background: #424242;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark .UI-V2 .work-space .side .container .section-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-pink.dark .UI-V2 .work-space .side .container .section-resume .line.editable:hover .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #424242, 0 -2px 0px #424242, 2px 0 0px #424242, 0 2px 0px #424242, -2px -2px 0px #424242, 2px 2px 0px #424242, 2px -2px 0px #424242, -2px 2px 0px #424242 !important;
}
.pink-pink.dark .UI-V2 .work-space .side .container .section-resume .line.editable button {
  background: #303030;
}
.pink-pink.dark .UI-V2 .work-space .side .container .section-resume .line .header .icon-complex mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.pink-pink.dark .UI-V2 .work-space .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #f06292 !important;
}
.pink-pink.dark .UI-V2 .work-space .side .container .section-resume .line .header mat-icon:not(.complex) {
  color: rgba(255, 255, 255, 0.7);
}
.pink-pink.dark .UI-V2 .work-space .side .container .section-resume .line .header .info h4 {
  color: #f06292;
}
.pink-pink.dark .UI-V2 .work-space .side .container .section-resume .line ul {
  color: #f06292;
}
.pink-pink.dark .UI-V2 .work-space .side .container .section-resume .line.head {
  background: black;
}
.pink-pink.dark .UI-V2 .work-space .side .container .section-resume .line.head mat-icon {
  color: #f48fb1;
}
.pink-pink.dark .UI-V2 .work-space .side .container .section-resume .line.head h4 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark .UI-V2 .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body {
  background: #424242;
}
.pink-pink.dark .UI-V2 .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: rgba(255, 255, 255, 0.7);
}
.pink-pink.dark .UI-V2 .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #f06292;
}
.pink-pink.dark .UI-V2 .work-space .side.edition .toolbar .header {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark .UI-V2 .work-space .side.edition .toolbar .header mat-icon {
  color: #f48fb1;
}
.pink-pink.dark .UI-V2 .work-space .side.edition .toolbar .header h3 {
  color: #f48fb1;
}
.pink-pink.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body {
  background: #424242 !important;
}
.pink-pink.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header mat-icon:not(.complex) {
  color: #f06292;
}
.pink-pink.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header h4 {
  color: #f06292;
}
.pink-pink.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-actions {
  background: #303030;
}
.pink-pink.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.pink-pink.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header .icon-complex mat-icon.complex {
  color: #f48fb1 !important;
}
.pink-pink.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header h4 {
  color: #f48fb1;
}
.pink-pink.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line ul {
  color: #f48fb1;
  font-weight: 400;
}
.pink-pink.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head mat-icon {
  color: #f48fb1 !important;
}
.pink-pink.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head h4 {
  color: white;
}
.pink-pink.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .section-title {
  color: white !important;
}
.pink-pink.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume h6.hint {
  background: #f48fb1;
}
.pink-pink.dark app-role-user-find .main-overlay {
  background: rgba(0, 0, 0, 0.32);
}
.pink-pink.dark app-role-user-find .icon-complex mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.pink-pink.dark app-role-user-find .icon-complex mat-icon.complex {
  color: #f06292 !important;
}
.pink-pink.dark app-role-user-find .navigation {
  background: #303030;
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark app-role-user-find .navigation *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.pink-pink.dark app-role-user-find .navigation *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
.pink-pink.dark app-role-user-find .navigation .load-window {
  background: black;
}
.pink-pink.dark app-role-user-find .navigation .toolbar {
  background: #f06292;
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark app-role-user-find .navigation .toolbar.reverse {
  background: #424242;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark app-role-user-find .navigation .container .helper {
  color: rgba(255, 255, 255, 0.7);
}
.pink-pink.dark app-role-user-find .navigation .container .item {
  background: #424242;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark app-role-user-find .navigation .container .item:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-pink.dark app-role-user-find .navigation .container .item.active {
  background: #f06292 !important;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #f06292;
}
.pink-pink.dark app-role-user-find .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.pink-pink.dark app-role-user-find .work-space .main .load-window {
  background: #424242 !important;
}
.pink-pink.dark app-role-user-find .work-space .main.side-open {
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark app-role-user-find .work-space .main .container .item {
  background: #303030;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark app-role-user-find .work-space .main .container .item .subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.pink-pink.dark app-role-user-find .work-space .main .container .item.disabled {
  background: black;
  color: #607D8B !important;
}
.pink-pink.dark app-role-user-find .work-space .main .container .item.disabled h3 {
  color: #607D8B !important;
}
.pink-pink.dark app-role-user-find .work-space .main .container .item.disabled mat-icon {
  color: #607D8B !important;
}
.pink-pink.dark app-role-user-find .work-space .main .container .item.disabled.selected {
  background: #607D8B;
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-pink.dark app-role-user-find .work-space .main .container .item.disabled.selected h3 {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-pink.dark app-role-user-find .work-space .main .container .item.disabled.selected mat-icon {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-pink.dark app-role-user-find .work-space .main .container .item.disabled.selected mat-icon.complex {
  color: rgba(0, 0, 0, 0.87) !important;
  text-shadow: -2px 0 0px #607D8B, 0 -2px 0px #607D8B, 2px 0 0px #607D8B, 0 2px 0px #607D8B, -2px -2px 0px #607D8B, 2px 2px 0px #607D8B, 2px -2px 0px #607D8B, -2px 2px 0px #607D8B !important;
}
.pink-pink.dark app-role-user-find .work-space .main .container .item mat-icon.complex {
  text-shadow: -2px 0 0px black, 0 -2px 0px black, 2px 0 0px black, 0 2px 0px black, -2px -2px 0px black, 2px 2px 0px black, 2px -2px 0px black, -2px 2px 0px black !important;
}
.pink-pink.dark app-role-user-find .work-space .main .container .item h5 {
  color: rgba(255, 255, 255, 0.5);
}
.pink-pink.dark app-role-user-find .work-space .main .container .item h3 {
  color: white;
}
.pink-pink.dark app-role-user-find .work-space .main .container .item:hover {
  background: rgba(255, 255, 255, 0.04);
}
.pink-pink.dark app-role-user-find .work-space .main .container .item.selected {
  background: #f06292;
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark app-role-user-find .work-space .main .container .item.selected mat-icon {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark app-role-user-find .work-space .main .container .item.selected mat-icon.complex {
  color: rgba(0, 0, 0, 0.87) !important;
  text-shadow: -2px 0 0px #f06292, 0 -2px 0px #f06292, 2px 0 0px #f06292, 0 2px 0px #f06292, -2px -2px 0px #f06292, 2px 2px 0px #f06292, 2px -2px 0px #f06292, -2px 2px 0px #f06292 !important;
}
.pink-pink.dark app-role-user-find .work-space .main .container .item.selected h5,
.pink-pink.dark app-role-user-find .work-space .main .container .item.selected h3 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark app-role-user-find .work-space .main .main-action {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark app-role-user-find .work-space .side {
  background: #303030;
}
.pink-pink.dark app-role-user-find .work-space .side .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #303030, 0 -2px 0px #303030, 2px 0 0px #303030, 0 2px 0px #303030, -2px -2px 0px #303030, 2px 2px 0px #303030, 2px -2px 0px #303030, -2px 2px 0px #303030;
}
.pink-pink.dark app-role-user-find .work-space .side *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.pink-pink.dark app-role-user-find .work-space .side *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
.pink-pink.dark app-role-user-find .work-space .side .edition-line {
  background: black;
}
.pink-pink.dark app-role-user-find .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #303030 !important;
}
.pink-pink.dark app-role-user-find .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(255, 255, 255, 0.5);
}
.pink-pink.dark app-role-user-find .work-space .side mat-form-field .mat-form-field-flex {
  background: #303030;
}
.pink-pink.dark app-role-user-find .work-space .side mat-checkbox:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-pink.dark app-role-user-find .work-space .side .hint.all {
  color: #f48fb1;
}
.pink-pink.dark app-role-user-find .work-space .side .toolbar .header {
  color: #f06292;
}
.pink-pink.dark app-role-user-find .work-space .side .toolbar .header h3 {
  color: #f06292;
}
.pink-pink.dark app-role-user-find .work-space .side .container .role-resume {
  background: #f06292;
}
.pink-pink.dark app-role-user-find .work-space .side .container .role-resume .line {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark app-role-user-find .work-space .side .container .role-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.1);
}
.pink-pink.dark app-role-user-find .work-space .side .container .role-resume .line.editable button {
  background: #f06292;
}
.pink-pink.dark app-role-user-find .work-space .side .container .section-title {
  color: #f06292;
}
.pink-pink.dark app-role-user-find .work-space .side .container .section-resume {
  background: #424242;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark app-role-user-find .work-space .side .container .section-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-pink.dark app-role-user-find .work-space .side .container .section-resume .line.editable:hover .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #424242, 0 -2px 0px #424242, 2px 0 0px #424242, 0 2px 0px #424242, -2px -2px 0px #424242, 2px 2px 0px #424242, 2px -2px 0px #424242, -2px 2px 0px #424242 !important;
}
.pink-pink.dark app-role-user-find .work-space .side .container .section-resume .line.editable button {
  background: #303030;
}
.pink-pink.dark app-role-user-find .work-space .side .container .section-resume .line .header .icon-complex mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.pink-pink.dark app-role-user-find .work-space .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #f06292 !important;
}
.pink-pink.dark app-role-user-find .work-space .side .container .section-resume .line .header mat-icon:not(.complex) {
  color: rgba(255, 255, 255, 0.7);
}
.pink-pink.dark app-role-user-find .work-space .side .container .section-resume .line .header .info h4 {
  color: #f06292;
}
.pink-pink.dark app-role-user-find .work-space .side .container .section-resume .line ul {
  color: #f06292;
}
.pink-pink.dark app-role-user-find .work-space .side .container .section-resume .line.head {
  background: black;
}
.pink-pink.dark app-role-user-find .work-space .side .container .section-resume .line.head mat-icon {
  color: #f48fb1;
}
.pink-pink.dark app-role-user-find .work-space .side .container .section-resume .line.head h4 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee-title {
  color: #f06292;
}
.pink-pink.dark app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body {
  background: #424242;
}
.pink-pink.dark app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: rgba(255, 255, 255, 0.7);
}
.pink-pink.dark app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #f06292;
}
.pink-pink.dark app-role-user-find .work-space .side.edition .toolbar .header {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark app-role-user-find .work-space .side.edition .toolbar .header mat-icon {
  color: #f48fb1;
}
.pink-pink.dark app-role-user-find .work-space .side.edition .toolbar .header h3 {
  color: #f48fb1;
}
.pink-pink.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body {
  background: #424242 !important;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header mat-icon:not(.complex) {
  color: #f06292;
}
.pink-pink.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header h4 {
  color: #f06292;
}
.pink-pink.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-actions {
  background: #303030;
}
.pink-pink.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.pink-pink.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header .icon-complex mat-icon.complex {
  color: #f48fb1 !important;
}
.pink-pink.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header h4 {
  color: #f48fb1;
}
.pink-pink.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line ul {
  color: #f48fb1;
}
.pink-pink.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head mat-icon {
  color: #f48fb1 !important;
}
.pink-pink.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head h4 {
  color: white;
}
.pink-pink.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .section-title {
  color: white !important;
}
.pink-pink.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume h6.hint {
  background: #f48fb1;
}
.pink-pink.dark app-role-user-find .work-space app-role-user-detail.disabled .side .toolbar .header {
  color: #607D8B !important;
}
.pink-pink.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container .role-resume {
  background: #607D8B !important;
}
.pink-pink.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-title {
  color: #607D8B !important;
}
.pink-pink.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line ul {
  color: #607D8B !important;
}
.pink-pink.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header mat-icon {
  color: #607D8B;
}
.pink-pink.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .icon-complex mat-icon {
  color: #607D8B !important;
}
.pink-pink.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #607D8B !important;
}
.pink-pink.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .info h4 {
  color: #607D8B !important;
}
.pink-pink.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee-title {
  color: #607D8B;
}
.pink-pink.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: #607D8B;
}
.pink-pink.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #607D8B;
}
.pink-pink.dark app-actor-find .main-overlay {
  background: rgba(0, 0, 0, 0.32);
}
.pink-pink.dark app-actor-find .icon-complex mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.pink-pink.dark app-actor-find .icon-complex mat-icon.complex {
  color: #f06292 !important;
}
.pink-pink.dark app-actor-find .navigation {
  background: #303030;
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark app-actor-find .navigation *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.pink-pink.dark app-actor-find .navigation *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
.pink-pink.dark app-actor-find .navigation .load-window {
  background: black;
}
.pink-pink.dark app-actor-find .navigation .toolbar {
  background: #f06292;
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark app-actor-find .navigation .toolbar.reverse {
  background: #424242;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark app-actor-find .navigation .container .helper {
  color: rgba(255, 255, 255, 0.7);
}
.pink-pink.dark app-actor-find .navigation .container .item {
  background: #424242;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark app-actor-find .navigation .container .item:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-pink.dark app-actor-find .navigation .container .item.active {
  background: #f06292 !important;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #f06292;
}
.pink-pink.dark app-actor-find .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.pink-pink.dark app-actor-find .work-space .main .load-window {
  background: #424242 !important;
}
.pink-pink.dark app-actor-find .work-space .main.side-open {
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark app-actor-find .work-space .main .container .item {
  background: #303030;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark app-actor-find .work-space .main .container .item .subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.pink-pink.dark app-actor-find .work-space .main .container .item.disabled {
  background: black;
  color: #607D8B !important;
}
.pink-pink.dark app-actor-find .work-space .main .container .item.disabled h3 {
  color: #607D8B !important;
}
.pink-pink.dark app-actor-find .work-space .main .container .item.disabled mat-icon {
  color: #607D8B !important;
}
.pink-pink.dark app-actor-find .work-space .main .container .item.disabled.selected {
  background: #607D8B;
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-pink.dark app-actor-find .work-space .main .container .item.disabled.selected h3 {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-pink.dark app-actor-find .work-space .main .container .item.disabled.selected mat-icon {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-pink.dark app-actor-find .work-space .main .container .item.disabled.selected mat-icon.complex {
  color: rgba(0, 0, 0, 0.87) !important;
  text-shadow: -2px 0 0px #607D8B, 0 -2px 0px #607D8B, 2px 0 0px #607D8B, 0 2px 0px #607D8B, -2px -2px 0px #607D8B, 2px 2px 0px #607D8B, 2px -2px 0px #607D8B, -2px 2px 0px #607D8B !important;
}
.pink-pink.dark app-actor-find .work-space .main .container .item mat-icon.complex {
  text-shadow: -2px 0 0px black, 0 -2px 0px black, 2px 0 0px black, 0 2px 0px black, -2px -2px 0px black, 2px 2px 0px black, 2px -2px 0px black, -2px 2px 0px black !important;
}
.pink-pink.dark app-actor-find .work-space .main .container .item h5 {
  color: rgba(255, 255, 255, 0.5);
}
.pink-pink.dark app-actor-find .work-space .main .container .item h3 {
  color: white;
}
.pink-pink.dark app-actor-find .work-space .main .container .item:hover {
  background: rgba(255, 255, 255, 0.04);
}
.pink-pink.dark app-actor-find .work-space .main .container .item.selected {
  background: #f06292;
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark app-actor-find .work-space .main .container .item.selected mat-icon {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark app-actor-find .work-space .main .container .item.selected mat-icon.complex {
  color: rgba(0, 0, 0, 0.87) !important;
  text-shadow: -2px 0 0px #f06292, 0 -2px 0px #f06292, 2px 0 0px #f06292, 0 2px 0px #f06292, -2px -2px 0px #f06292, 2px 2px 0px #f06292, 2px -2px 0px #f06292, -2px 2px 0px #f06292 !important;
}
.pink-pink.dark app-actor-find .work-space .main .container .item.selected h5,
.pink-pink.dark app-actor-find .work-space .main .container .item.selected h3 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark app-actor-find .work-space .main .main-action {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark app-actor-find .work-space .side {
  background: #303030;
}
.pink-pink.dark app-actor-find .work-space .side .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #303030, 0 -2px 0px #303030, 2px 0 0px #303030, 0 2px 0px #303030, -2px -2px 0px #303030, 2px 2px 0px #303030, 2px -2px 0px #303030, -2px 2px 0px #303030;
}
.pink-pink.dark app-actor-find .work-space .side *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.pink-pink.dark app-actor-find .work-space .side *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
.pink-pink.dark app-actor-find .work-space .side .edition-line {
  background: black;
}
.pink-pink.dark app-actor-find .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #303030 !important;
}
.pink-pink.dark app-actor-find .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(255, 255, 255, 0.5);
}
.pink-pink.dark app-actor-find .work-space .side mat-form-field .mat-form-field-flex {
  background: #303030;
}
.pink-pink.dark app-actor-find .work-space .side mat-checkbox:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-pink.dark app-actor-find .work-space .side .hint.all {
  color: #f48fb1;
}
.pink-pink.dark app-actor-find .work-space .side .toolbar .header {
  color: #f06292;
}
.pink-pink.dark app-actor-find .work-space .side .toolbar .header h3 {
  color: #f06292;
}
.pink-pink.dark app-actor-find .work-space .side .container .role-resume {
  background: #f06292;
}
.pink-pink.dark app-actor-find .work-space .side .container .role-resume .line {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark app-actor-find .work-space .side .container .role-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.1);
}
.pink-pink.dark app-actor-find .work-space .side .container .role-resume .line.editable button {
  background: #f06292;
}
.pink-pink.dark app-actor-find .work-space .side .container .section-title {
  color: #f06292;
}
.pink-pink.dark app-actor-find .work-space .side .container .section-resume {
  background: #424242;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark app-actor-find .work-space .side .container .section-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-pink.dark app-actor-find .work-space .side .container .section-resume .line.editable:hover .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #424242, 0 -2px 0px #424242, 2px 0 0px #424242, 0 2px 0px #424242, -2px -2px 0px #424242, 2px 2px 0px #424242, 2px -2px 0px #424242, -2px 2px 0px #424242 !important;
}
.pink-pink.dark app-actor-find .work-space .side .container .section-resume .line.editable button {
  background: #303030;
}
.pink-pink.dark app-actor-find .work-space .side .container .section-resume .line .header .icon-complex mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.pink-pink.dark app-actor-find .work-space .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #f06292 !important;
}
.pink-pink.dark app-actor-find .work-space .side .container .section-resume .line .header mat-icon:not(.complex) {
  color: rgba(255, 255, 255, 0.7);
}
.pink-pink.dark app-actor-find .work-space .side .container .section-resume .line .header .info h4 {
  color: #f06292;
}
.pink-pink.dark app-actor-find .work-space .side .container .section-resume .line ul {
  color: #f06292;
}
.pink-pink.dark app-actor-find .work-space .side .container .section-resume .line.head {
  background: black;
}
.pink-pink.dark app-actor-find .work-space .side .container .section-resume .line.head mat-icon {
  color: #f48fb1;
}
.pink-pink.dark app-actor-find .work-space .side .container .section-resume .line.head h4 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee-title {
  color: #f06292;
}
.pink-pink.dark app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body {
  background: #424242;
}
.pink-pink.dark app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: rgba(255, 255, 255, 0.7);
}
.pink-pink.dark app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #f06292;
}
.pink-pink.dark app-actor-find .work-space .side.edition .toolbar .header {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark app-actor-find .work-space .side.edition .toolbar .header mat-icon {
  color: #f48fb1;
}
.pink-pink.dark app-actor-find .work-space .side.edition .toolbar .header h3 {
  color: #f48fb1;
}
.pink-pink.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body {
  background: #424242 !important;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header mat-icon:not(.complex) {
  color: #f06292;
}
.pink-pink.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header h4 {
  color: #f06292;
}
.pink-pink.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-actions {
  background: #303030;
}
.pink-pink.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.pink-pink.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header .icon-complex mat-icon.complex {
  color: #f48fb1 !important;
}
.pink-pink.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header h4 {
  color: #f48fb1;
}
.pink-pink.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line ul {
  color: #f48fb1;
}
.pink-pink.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head mat-icon {
  color: #f48fb1 !important;
}
.pink-pink.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head h4 {
  color: white;
}
.pink-pink.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .section-title {
  color: white !important;
}
.pink-pink.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume h6.hint {
  background: #f48fb1;
}
.pink-pink.dark app-actor-find .work-space app-role-user-detail.disabled .side .toolbar .header {
  color: #607D8B !important;
}
.pink-pink.dark app-actor-find .work-space app-role-user-detail.disabled .side .container .role-resume {
  background: #607D8B !important;
}
.pink-pink.dark app-actor-find .work-space app-role-user-detail.disabled .side .container .section-title {
  color: #607D8B !important;
}
.pink-pink.dark app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line ul {
  color: #607D8B !important;
}
.pink-pink.dark app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header mat-icon {
  color: #607D8B;
}
.pink-pink.dark app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .icon-complex mat-icon {
  color: #607D8B !important;
}
.pink-pink.dark app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #607D8B !important;
}
.pink-pink.dark app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .info h4 {
  color: #607D8B !important;
}
.pink-pink.dark app-actor-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee-title {
  color: #607D8B;
}
.pink-pink.dark app-actor-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: #607D8B;
}
.pink-pink.dark app-actor-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #607D8B;
}
.pink-pink.dark .dropzone {
  border: 2px dashed #607D8B;
  background: black !important;
}
.pink-pink.dark .dropzone.hovering {
  border: 2px solid #607D8B;
  color: rgba(255, 255, 255, 0.5) !important;
}
.pink-pink.dark .dropzone button.mat-stroked-button {
  background: #424242;
}
.pink-pink.dark .firmador .content-action-main {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-pink.dark .firmador .content-action-main.disabled {
  color: rgba(255, 255, 255, 0.5) !important;
  background: rgba(255, 255, 255, 0.12);
}
.pink-pink.dark .sign-cycle {
  background: #424242;
}
.pink-pink.dark .sign-cycle button.success {
  background-color: #009688;
}
.pink-pink.dark .sign-cycle button.warning {
  background-color: #673AB7;
}
.pink-pink.dark .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-title-container .cycle-content-title h4 {
  color: #607D8B;
}
.pink-pink.dark .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-action .content-action-main {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-pink.dark .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-action .content-action-main.mat-stroked-button:disabled {
  color: rgba(255, 255, 255, 0.5) !important;
}
.pink-pink.dark .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-action .content-action-main.mat-stroked-button:not(:disabled) {
  color: #f48fb1 !important;
}
.pink-pink.dark .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-action .content-action-side u {
  color: rgba(255, 255, 255, 0.5);
}
.pink-pink.dark .resume-status {
  background: #424242;
}
.pink-pink.dark .help-hoverable mat-icon {
  color: rgba(255, 255, 255, 0.7);
}
.pink-pink.dark .help-hoverable mat-hint {
  background: #424242;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(255, 255, 255, 0.12);
}
.pink-pink.dark .help-hoverable mat-hint b {
  color: #f06292 !important;
}
.pink-pink.dark app-file-document-sign-massive-dialog .dialog-content,
.pink-pink.dark app-share-person-dialog .dialog-content {
  background: #303030;
}
.pink-pink.dark app-file-document-sign-massive-dialog .dialog-content mat-toolbar mat-toolbar-row .mat-avatar h3,
.pink-pink.dark app-share-person-dialog .dialog-content mat-toolbar mat-toolbar-row .mat-avatar h3 {
  color: #f06292;
}
.pink-pink.dark app-file-document-sign-massive-dialog .dialog-content .dialog-body p b,
.pink-pink.dark app-share-person-dialog .dialog-content .dialog-body p b {
  color: #f48fb1;
}
.pink-pink.dark app-file-document-sign-massive-dialog .dialog-content app-file-document-sign,
.pink-pink.dark app-share-person-dialog .dialog-content app-file-document-sign {
  background: #424242;
}
.pink-pink.dark .mat-basic-chip {
  background: black !important;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark .mat-basic-chip.seen {
  border-color: #2196F3 !important;
  color: #2196F3 !important;
}
.pink-pink.dark .mat-basic-chip.unseen {
  border-color: #607D8B !important;
  color: #607D8B !important;
}
.pink-pink.dark .mat-basic-chip.signed-ok {
  border-color: #009688 !important;
  color: #009688 !important;
}
.pink-pink.dark .mat-basic-chip.signed-ok.cancelled {
  border-color: #795548 !important;
  color: #795548 !important;
}
.pink-pink.dark .mat-basic-chip.signed-not-ok {
  border-color: #673AB7 !important;
  color: #673AB7 !important;
}
.pink-pink.dark .mat-basic-chip.not-signed {
  border-color: #ff4081 !important;
  color: #ff4081 !important;
}
.pink-pink.dark .mat-basic-chip.not-signed-employer {
  border-color: #ff4081 !important;
  color: #ff4081 !important;
}
.pink-pink.dark app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip {
  background: black !important;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.seen {
  border-color: #2196F3 !important;
  color: #2196F3 !important;
}
.pink-pink.dark app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.unseen {
  border-color: #607D8B !important;
  color: #607D8B !important;
}
.pink-pink.dark app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.signed-ok {
  border-color: #009688 !important;
  color: #009688 !important;
}
.pink-pink.dark app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.signed-ok.cancelled {
  border-color: #795548 !important;
  color: #795548 !important;
}
.pink-pink.dark app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.signed-not-ok {
  border-color: #673AB7 !important;
  color: #673AB7 !important;
}
.pink-pink.dark app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.not-signed {
  border-color: #ff4081 !important;
  color: #ff4081 !important;
}
.pink-pink.dark app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.not-signed-employer {
  border-color: #ff4081 !important;
  color: #ff4081 !important;
}
.pink-pink.dark .inbox-container.employee.my-documents .chip-line.mat-line .mat-chip-list-wrapper .mat-basic-chip.seen {
  color: #607D8B !important;
}
.pink-pink.dark .resume-status .mat-basic-chip {
  background: black !important;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark .resume-status .mat-basic-chip.signed-ok {
  border-color: #009688 !important;
  background-color: #009688 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-pink.dark .resume-status .mat-basic-chip.signed-ok.cancelled {
  border-color: #795548 !important;
  background-color: #795548 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-pink.dark .resume-status .mat-basic-chip.signed-not-ok {
  border-color: #673AB7 !important;
  background-color: #673AB7 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-pink.dark app-file-document-view .new-container app-file-document-upload {
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark app-file-document-view .new-container app-upload-form .file-upload-list .mat-subheader {
  background: #424242;
}
.pink-pink.dark app-file-document-view .new-container app-upload-form .file-upload-list mat-list-item {
  background: #303030 !important;
}
.pink-pink.dark app-file-document-view .preview-content {
  background: #607D8B;
}
.pink-pink.dark app-file-document-view .preview-content h4 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark app-file-document-view .view-container {
  background: #424242;
}
.pink-pink.dark app-file-document-view .view-container mat-toolbar.side-bar {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark .formio-hint {
  background: #f06292;
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark mat-dialog-container app-file-document-state-chips {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark app-candidate-promotion .load-window.message h3.primary-text {
  color: #f06292;
}
.pink-pink.dark app-multiple-documentation-add-dialog .icon-complex mat-icon,
.pink-pink.dark app-documentation-type-set-configuration-dialog .icon-complex mat-icon,
.pink-pink.dark app-add-candidate-dialog .icon-complex mat-icon,
.pink-pink.dark app-share-person-dialog .icon-complex mat-icon,
.pink-pink.dark app-share-person-response-dialog .icon-complex mat-icon {
  color: white !important;
}
.pink-pink.dark app-multiple-documentation-add-dialog .icon-complex mat-icon.complex,
.pink-pink.dark app-documentation-type-set-configuration-dialog .icon-complex mat-icon.complex,
.pink-pink.dark app-add-candidate-dialog .icon-complex mat-icon.complex,
.pink-pink.dark app-share-person-dialog .icon-complex mat-icon.complex,
.pink-pink.dark app-share-person-response-dialog .icon-complex mat-icon.complex {
  color: #f06292 !important;
}
.pink-pink.dark app-multiple-documentation-add-dialog .icon-complex mat-icon.complex.mat-accent,
.pink-pink.dark app-documentation-type-set-configuration-dialog .icon-complex mat-icon.complex.mat-accent,
.pink-pink.dark app-add-candidate-dialog .icon-complex mat-icon.complex.mat-accent,
.pink-pink.dark app-share-person-dialog .icon-complex mat-icon.complex.mat-accent,
.pink-pink.dark app-share-person-response-dialog .icon-complex mat-icon.complex.mat-accent {
  color: #f48fb1;
}
.pink-pink.dark app-multiple-documentation-add-dialog .icon-complex mat-icon.complex.side,
.pink-pink.dark app-documentation-type-set-configuration-dialog .icon-complex mat-icon.complex.side,
.pink-pink.dark app-add-candidate-dialog .icon-complex mat-icon.complex.side,
.pink-pink.dark app-share-person-dialog .icon-complex mat-icon.complex.side,
.pink-pink.dark app-share-person-response-dialog .icon-complex mat-icon.complex.side {
  color: rgba(255, 255, 255, 0.7);
}
.pink-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection,
.pink-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection,
.pink-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection,
.pink-pink.dark app-share-person-dialog .dialog-content .document-template-container .document-selection,
.pink-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection {
  background: #303030;
}
.pink-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.pink-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.pink-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3,
.pink-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.pink-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.pink-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3,
.pink-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.pink-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.pink-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3,
.pink-pink.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.pink-pink.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.pink-pink.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3,
.pink-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.pink-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.pink-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3 {
  color: #f48fb1 !important;
}
.pink-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon,
.pink-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon,
.pink-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon,
.pink-pink.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon,
.pink-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon {
  color: #f06292;
}
.pink-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3,
.pink-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3,
.pink-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3,
.pink-pink.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3,
.pink-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3 {
  color: #f06292;
}
.pink-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex,
.pink-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex,
.pink-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex,
.pink-pink.dark app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex,
.pink-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex {
  color: #f48fb1 !important;
  text-shadow: -2px 0 0px black, 0 -2px 0px black, 2px 0 0px black, 0 2px 0px black, -2px -2px 0px black, 2px 2px 0px black, 2px -2px 0px black, -2px 2px 0px #424242;
}
.pink-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex,
.pink-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex,
.pink-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex,
.pink-pink.dark app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex,
.pink-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex {
  color: rgba(255, 255, 255, 0.5) !important;
}
.pink-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b,
.pink-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b,
.pink-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b,
.pink-pink.dark app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b,
.pink-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b {
  color: white;
}
.pink-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex,
.pink-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex,
.pink-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex,
.pink-pink.dark app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex,
.pink-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex {
  color: rgba(255, 255, 255, 0.5) !important;
}
.pink-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex,
.pink-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex,
.pink-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex,
.pink-pink.dark app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex,
.pink-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex {
  color: rgba(255, 255, 255, 0.5) !important;
}
.pink-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover,
.pink-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover,
.pink-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover,
.pink-pink.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover,
.pink-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover {
  background: rgba(255, 255, 255, 0.04);
}
.pink-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b,
.pink-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b,
.pink-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b,
.pink-pink.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b,
.pink-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b {
  color: white;
}
.pink-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.pink-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.pink-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.pink-pink.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.pink-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble {
  background: #f06292;
}
.pink-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble,
.pink-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble,
.pink-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble,
.pink-pink.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble,
.pink-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble {
  background: #e57373;
}
.pink-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble,
.pink-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble,
.pink-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble,
.pink-pink.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble,
.pink-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble {
  color: rgba(255, 255, 255, 0.5);
  background: rgba(255, 255, 255, 0.12);
}
.pink-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble,
.pink-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble,
.pink-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble,
.pink-pink.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble,
.pink-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble {
  background: rgba(255, 255, 255, 0.5);
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide,
.pink-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide,
.pink-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide,
.pink-pink.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide,
.pink-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide {
  border-color: rgba(255, 255, 255, 0.12);
}
.pink-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble,
.pink-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble,
.pink-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble,
.pink-pink.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble,
.pink-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble {
  color: rgba(255, 255, 255, 0.7);
}
.pink-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.pink-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.pink-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.pink-pink.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.pink-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble {
  color: #f06292;
}
.pink-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble,
.pink-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble,
.pink-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble,
.pink-pink.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble,
.pink-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble {
  color: #e57373;
}
.pink-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar,
.pink-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar,
.pink-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar,
.pink-pink.dark app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar,
.pink-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar {
  background: #607D8B;
}
.pink-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row,
.pink-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row,
.pink-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row,
.pink-pink.dark app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row,
.pink-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper,
.pink-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper,
.pink-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper,
.pink-pink.dark app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper,
.pink-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper {
  background: #607D8B;
}
.pink-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop,
.pink-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop,
.pink-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop,
.pink-pink.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop,
.pink-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop {
  border-left: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body,
.pink-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body,
.pink-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body,
.pink-pink.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body,
.pink-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body {
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3,
.pink-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3,
.pink-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3,
.pink-pink.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3,
.pink-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3 {
  color: #f48fb1;
}
.pink-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5,
.pink-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5,
.pink-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5,
.pink-pink.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5,
.pink-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5 {
  color: rgba(255, 255, 255, 0.7);
}
.pink-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5,
.pink-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5,
.pink-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5,
.pink-pink.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5,
.pink-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5 {
  color: rgba(255, 255, 255, 0.7);
}
.pink-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator,
.pink-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator,
.pink-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator,
.pink-pink.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator,
.pink-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  background: #303030;
}
.pink-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip,
.pink-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip,
.pink-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip,
.pink-pink.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip,
.pink-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip {
  background: #424242 !important;
}
.pink-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon,
.pink-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon,
.pink-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon,
.pink-pink.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon,
.pink-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon {
  color: #f48fb1 !important;
}
.pink-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3,
.pink-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3,
.pink-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3,
.pink-pink.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3,
.pink-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3 {
  color: #f48fb1 !important;
}
.pink-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5,
.pink-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5,
.pink-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5,
.pink-pink.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5,
.pink-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5 {
  color: rgba(255, 255, 255, 0.7) !important;
}
.pink-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon,
.pink-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon,
.pink-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon,
.pink-pink.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon,
.pink-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon {
  color: #f06292 !important;
}
.pink-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3,
.pink-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3,
.pink-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3,
.pink-pink.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3,
.pink-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3 {
  color: #f06292 !important;
}
.pink-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5,
.pink-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5,
.pink-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5,
.pink-pink.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5,
.pink-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5 {
  color: #f06292 !important;
}
.pink-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled,
.pink-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled,
.pink-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled,
.pink-pink.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled,
.pink-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled {
  background: #303030 !important;
  color: rgba(255, 255, 255, 0.5);
}
.pink-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove,
.pink-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove,
.pink-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove,
.pink-pink.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove,
.pink-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove {
  color: rgba(255, 255, 255, 0.7);
}
.pink-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove,
.pink-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove,
.pink-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove,
.pink-pink.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove,
.pink-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove {
  color: rgba(255, 255, 255, 0.7) !important;
}
.pink-pink.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .preview-action,
.pink-pink.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .preview-action,
.pink-pink.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .preview-action,
.pink-pink.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .preview-action,
.pink-pink.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .preview-action {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark .tyc .sticky {
  background: #424242;
}
.pink-pink.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper:nth-child(odd) datatable-body-row {
  background: #303030;
}
.pink-pink.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active * {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-pink.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active:hover .datatable-body-cell-label {
  background: #f06292;
}
.pink-pink.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active:hover .cs-floating-action {
  background: #f06292 !important;
}
.pink-pink.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active.row-cancel * {
  color: rgba(255, 255, 255, 0.7) !important;
}
.pink-pink.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active.row-cancel .datatable-row-group {
  background: #607D8B !important;
}
.pink-pink.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active.row-cancel:hover .datatable-body-cell-label {
  background: #607D8B;
}
.pink-pink.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.row-cancel * {
  color: #b0bec5 !important;
  transition-property: color;
  transition-duration: 0.3s;
  transition-timing-function: linear;
}
.pink-pink.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row:hover .datatable-body-cell-label button {
  background: black;
}
.pink-pink.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row datatable-body-cell.candidate-status_bar mat-progress-bar .mat-progress-bar-buffer {
  background-color: black;
}
.pink-pink.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row datatable-body-cell.candidate-status_bar .status_bar-count {
  color: rgba(255, 255, 255, 0.7);
}
.pink-pink.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row datatable-body-cell.candidate-status_bar .inactive {
  color: rgba(255, 255, 255, 0.7);
}
.pink-pink.dark .cs-grid-test .info {
  background: #424242;
}
.pink-pink.dark .cs-grid-test .selected-column {
  background: #424242;
  -webkit-box-shadow: 0 1px 3px 0 rgba(255, 255, 255, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0 1px 3px 0 rgba(255, 255, 255, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 3px 0 rgba(255, 255, 255, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
}
.pink-pink.dark .cs-grid-test .selection-cell .datatable-body-cell.active {
  background: #f06292 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-pink.dark .cs-grid-test nav {
  background: #424242;
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 13px 19px 2px rgba(0, 0, 0, 0.14), 0 5px 24px 4px rgba(255, 255, 255, 0.12);
}
.pink-pink.dark .cs-grid-test .ngx-datatable.material {
  background: #424242;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(255, 255, 255, 0.12);
}
.pink-pink.dark .cs-grid-test .ngx-datatable.material.striped .datatable-row-odd {
  background: #303030;
}
.pink-pink.dark .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active, .pink-pink.dark .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active .datatable-row-group, .pink-pink.dark .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active, .pink-pink.dark .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active .datatable-row-group, .pink-pink.dark .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active, .pink-pink.dark .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active .datatable-row-group {
  background: #f06292;
  color: #424242;
}
.pink-pink.dark .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active:hover, .pink-pink.dark .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active:hover .datatable-row-group, .pink-pink.dark .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active:hover, .pink-pink.dark .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active:hover .datatable-row-group, .pink-pink.dark .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active:hover, .pink-pink.dark .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active:hover .datatable-row-group {
  background: #f06292;
  color: #424242;
}
.pink-pink.dark .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active:focus, .pink-pink.dark .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active:focus .datatable-row-group, .pink-pink.dark .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active:focus, .pink-pink.dark .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active:focus .datatable-row-group, .pink-pink.dark .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active:focus, .pink-pink.dark .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active:focus .datatable-row-group {
  background: #f06292;
  color: #424242;
}
.pink-pink.dark .cs-grid-test .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover, .pink-pink.dark .cs-grid-test .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: rgba(255, 255, 255, 0.04);
}
.pink-pink.dark .cs-grid-test .ngx-datatable.material:not(.cell-selection) .datatable-body-row:focus, .pink-pink.dark .cs-grid-test .ngx-datatable.material:not(.cell-selection) .datatable-body-row:focus .datatable-row-group {
  background: rgba(255, 255, 255, 0.04);
}
.pink-pink.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell:hover, .pink-pink.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell:hover .datatable-row-group {
  background: rgba(255, 255, 255, 0.04);
}
.pink-pink.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell:focus, .pink-pink.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell:focus .datatable-row-group {
  background: rgba(255, 255, 255, 0.04);
}
.pink-pink.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active, .pink-pink.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active .datatable-row-group {
  background: #f06292;
  color: #424242;
}
.pink-pink.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active:hover, .pink-pink.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active:hover .datatable-row-group {
  background: #f06292;
  color: #424242;
}
.pink-pink.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active:focus, .pink-pink.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active:focus .datatable-row-group {
  background: #f06292;
  color: #424242;
}
.pink-pink.dark .cs-grid-test .ngx-datatable.material .datatable-header .datatable-row-left,
.pink-pink.dark .cs-grid-test .ngx-datatable.material .datatable-body .datatable-row-left {
  background-color: #424242;
}
.pink-pink.dark .cs-grid-test .ngx-datatable.material .datatable-header .datatable-row-right,
.pink-pink.dark .cs-grid-test .ngx-datatable.material .datatable-body .datatable-row-right {
  background-color: #424242;
}
.pink-pink.dark .cs-grid-test .ngx-datatable.material .datatable-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.pink-pink.dark .cs-grid-test .ngx-datatable.material .datatable-header .datatable-header-cell {
  background-color: #424242;
  color: rgba(255, 255, 255, 0.7);
}
.pink-pink.dark .cs-grid-test .ngx-datatable.material .datatable-header .resize-handle {
  border-right: solid 1px rgba(255, 255, 255, 0.04);
}
.pink-pink.dark .cs-grid-test .ngx-datatable.material .datatable-body .datatable-row-detail {
  background: black;
}
.pink-pink.dark .cs-grid-test .ngx-datatable.material .datatable-body .datatable-group-header {
  background: black;
  border-bottom: solid 1px rgba(255, 255, 255, 0.12);
  border-top: solid 1px rgba(255, 255, 255, 0.12);
}
.pink-pink.dark .cs-grid-test .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
  color: white;
}
.pink-pink.dark .cs-grid-test .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-group-cell {
  color: white;
}
.pink-pink.dark .cs-grid-test .ngx-datatable.material .datatable-body .progress-linear .container {
  background-color: #424242;
}
.pink-pink.dark .cs-grid-test .ngx-datatable.material .datatable-body .progress-linear .container .bar {
  background-color: #f06292;
}
.pink-pink.dark .cs-grid-test .ngx-datatable.material .datatable-footer {
  color: rgba(255, 255, 255, 0.7);
}
.pink-pink.dark .cs-grid-test .ngx-datatable.material .datatable-footer .datatable-pager li.disabled a {
  color: rgba(255, 255, 255, 0.5) !important;
  background-color: rgba(0, 0, 0, 0) !important;
}
.pink-pink.dark .cs-grid-test .ngx-datatable.material .datatable-footer .datatable-pager li.active a {
  background-color: #f06292;
}
.pink-pink.dark .cs-grid-test .ngx-datatable.material .datatable-footer .datatable-pager a {
  color: rgba(255, 255, 255, 0.7);
}
.pink-pink.dark .cs-grid-test .ngx-datatable.material .datatable-footer .datatable-pager a:hover {
  color: rgba(255, 255, 255, 0.04);
  background-color: rgba(255, 255, 255, 0.04);
}
.pink-pink.dark .cs-grid-test .ngx-datatable.material .datatable-summary-row .datatable-body-row {
  background-color: #303030;
}
.pink-pink.dark .cs-grid-test .ngx-datatable.material .datatable-summary-row .datatable-body-row:hover {
  background-color: #303030;
}
.pink-pink.dark .cs-grid-test .datatable-checkbox input[type=checkbox]:after {
  background: #424242;
}
.pink-pink.dark .view-container .view-toolbar {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal.new.image-opened .help-content img {
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card {
  background: #424242;
}
.pink-pink.dark app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container .aditional-info {
  color: rgba(255, 255, 255, 0.7);
}
.pink-pink.dark app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container .mat-card-footer {
  background: #424242;
}
.pink-pink.dark app-help-index mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card {
  background: #424242;
}
.pink-pink.dark app-help-index mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card .mat-card-footer {
  background: #424242;
}
.pink-pink.dark .cropper .employee-title .title-text {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-pink.dark .cropper app-cs-upload-cropper-control .preview h5 {
  color: rgba(255, 255, 255, 0.7);
}
.pink-pink.dark app-file-document-upload .preview-container {
  background: #607D8B !important;
}
.pink-pink.dark app-file-document-upload .preview-container h4 {
  color: black;
}
.pink-pink.dark .preview-container {
  background: #607D8B !important;
}
.pink-pink.dark .preview-container h4 {
  color: black;
}
.pink-pink.dark .clean-autocomple mat-list {
  background: #424242;
}
.pink-pink.dark .clean-autocomple mat-list .mat-list-item:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-pink.dark .clean-autocomple mat-list .mat-list-item .mat-list-item-content .mat-list-text {
  color: #f06292;
}
.pink-pink.dark app-leave .inbox-toolbar {
  background: #303030;
}
.pink-pink.dark app-leave .mat-tab-body-wrapper {
  position: relative;
  overflow: hidden;
  display: flex;
  transition: height 500ms cubic-bezier(0.35, 0, 0.25, 1);
  height: 95% !important;
}
.pink-pink.dark app-leave .leave_container {
  background: #303030;
}
.pink-pink.dark app-leave .leave_container .leave_items .leave_items-item.ok .content .leave_item-status_icon {
  color: #4db6ac;
}
.pink-pink.dark app-leave .leave_container .leave_items .leave_items-item.not-ok .content .leave_item-status_icon {
  color: #ba68c8;
}
.pink-pink.dark app-leave .leave_container .leave_items .leave_items-item.pending .content .leave_item-status_icon {
  color: #ffb74d;
}
.pink-pink.dark app-leave .leave_container .leave_items .leave_items-item.draft .content .leave_item-status_icon {
  color: rgba(255, 255, 255, 0.7);
}
.pink-pink.dark app-leave .leave_container .leave_items .leave_items-item.cancel .content .leave_item-status_icon {
  color: #795548;
}
.pink-pink.dark app-leave .leave_container .leave_items .leave_items-item .content:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-pink.dark app-leave .leave_container .leave_items .leave_items-item .content.selected {
  border-left: 6px solid #f48fb1 !important;
}
.pink-pink.dark app-leave .leave_container .leave_items .leave_items-item .content.selected mat-icon {
  color: #f48fb1 !important;
}
.pink-pink.dark app-leave .leave_container .leave_items .leave_items-item .content.selected .item-data {
  color: white !important;
}
.pink-pink.dark app-leave .leave_container .leave_items .leave_items-item .content .leave_item-icon {
  color: rgba(255, 255, 255, 0.7);
}
.pink-pink.dark app-leave .leave_container .leave_items .leave_items-item .content .item-data {
  color: rgba(255, 255, 255, 0.7);
}
.pink-pink.dark app-leave .leave_container .window-spinner {
  background: #303030 !important;
}
.pink-pink.dark app-leave-detail .leave_detail .leave_detail-title .info h3,
.pink-pink.dark app-leave-detail .leave_detail .leave_detail-title .info mat-icon {
  color: #f06292;
}
.pink-pink.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .ok .large-icon {
  background: #e0f2f1;
  color: #4db6ac;
}
.pink-pink.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .ok h2 {
  color: #4db6ac;
}
.pink-pink.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .not-ok .large-icon {
  background: #f3e5f5;
  color: #ba68c8;
}
.pink-pink.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .not-ok h2 {
  color: #ba68c8;
}
.pink-pink.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .pending .large-icon {
  background: #fff3e0;
  color: #ffb74d;
}
.pink-pink.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .pending h2 {
  color: #ffb74d;
}
.pink-pink.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .draft .large-icon {
  background: #f2f2f2;
  color: #dedede;
}
.pink-pink.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .draft h2 {
  color: #dedede;
}
.pink-pink.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .cancelled .large-icon {
  background: #BCAAA4;
  color: #795548;
}
.pink-pink.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .cancelled h2 {
  color: #795548;
}
.pink-pink.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .actions {
  padding: 16px;
  height: 96px;
  column-gap: 16px;
}
.pink-pink.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .actions button {
  height: 100%;
  border-radius: 40px;
}
.pink-pink.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .actions button.ok {
  background: #4db6ac !important;
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .actions button.not-ok {
  color: #ba68c8 !important;
}
.pink-pink.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning mat-basic-chip {
  background: black !important;
  border: 1px solid rgba(255, 255, 255, 0.12) !important;
  border-radius: 40px !important;
  height: 35px !important;
}
.pink-pink.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning mat-basic-chip.signed-ok {
  border-color: #4db6ac !important;
  color: #4db6ac !important;
}
.pink-pink.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning mat-basic-chip.signed-not-ok {
  border-color: #ba68c8 !important;
  color: #ba68c8 !important;
}
.pink-pink.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning mat-basic-chip.draft {
  border-color: #dedede !important;
  color: #dedede !important;
}
.pink-pink.dark app-leave-request-detail .load-window {
  background: #303030;
}
.pink-pink.dark app-leave-request-detail form .leave_detail-container {
  background: #303030;
}
.pink-pink.dark app-leave-request-detail form .leave_detail-container .toolbar .header {
  color: #f06292;
}
.pink-pink.dark app-leave-request-detail form .leave_detail-container .toolbar .header .text h3 {
  color: #f06292;
}
.pink-pink.dark app-leave-request-detail form .leave_detail-container .toolbar .header .text h5 {
  color: rgba(255, 255, 255, 0.7);
}
.pink-pink.dark app-leave-request-detail form .leave_detail-container .leave_detail-container-content .leave_resume {
  background: #424242;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark app-leave-request-detail form .leave_detail-container .leave_detail-container-content .line h6 {
  color: rgba(255, 255, 255, 0.7);
}
.pink-pink.dark app-leave-request-detail form .leave_detail-container .actions button.ok {
  background: #4db6ac !important;
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark app-leave-request-detail form .leave_detail-container .actions button.not-ok {
  color: #ba68c8 !important;
}
.pink-pink.dark .new_leave_modal-steps {
  display: unset !important;
}
.pink-pink.dark .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button {
  background: #303030;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button .mat-radio-label .mat-radio-label-content .side-text {
  color: rgba(255, 255, 255, 0.7);
}
.pink-pink.dark .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button .mat-radio-label .mat-radio-label-content .side-text.muted {
  color: rgba(255, 255, 255, 0.5);
}
.pink-pink.dark .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button .mat-radio-label .mat-radio-label-content .accent mat-icon,
.pink-pink.dark .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button .mat-radio-label .mat-radio-label-content .accent .main-text {
  color: #f48fb1;
}
.pink-pink.dark .config_leave-container .config_leave-card {
  background: #424242;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark .config_leave-container .config_leave-card .config_leave-auto_chip mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #303030 !important;
}
.pink-pink.dark .config_leave-container .config_leave-card .config_leave-auto_chip mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(255, 255, 255, 0.5);
}
.pink-pink.dark .config_leave-container .config_leave-card .config_leave-auto_chip mat-form-field .mat-form-field-flex {
  background: #303030;
}
.pink-pink.dark *[color=success] {
  color: #009688;
}
.pink-pink.dark .primary {
  color: #f06292 !important;
}
.pink-pink.dark .accent {
  color: #f48fb1 !important;
}
.pink-pink.dark app-lsd .icon-complex mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.pink-pink.dark app-lsd .icon-complex mat-icon.complex {
  color: #f06292 !important;
}
.pink-pink.dark app-lsd .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.pink-pink.dark app-lsd .work-space .main .load-window {
  background: #424242 !important;
}
.pink-pink.dark app-lsd .work-space .main.side-open {
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark app-lsd .work-space .main .container .item {
  background: #303030;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark app-lsd .work-space .main .container .item h5 {
  color: rgba(255, 255, 255, 0.5);
}
.pink-pink.dark app-lsd .work-space .main .container .item h3 {
  color: white;
}
.pink-pink.dark app-lsd .work-space .main .container .item:hover {
  background: rgba(255, 255, 255, 0.04);
}
.pink-pink.dark app-lsd .work-space .main .container .item.selected {
  background: #f06292;
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-pink.dark app-lsd .work-space .main .container .item.selected h3 {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-pink.dark app-lsd .work-space .main .container .item.selected.pending .status {
  background: rgba(255, 255, 255, 0.12) !important;
  color: #ffe082;
}
.pink-pink.dark app-lsd .work-space .main .container .item.selected.ok .status {
  background: rgba(255, 255, 255, 0.12) !important;
  color: #a5d6a7;
}
.pink-pink.dark app-lsd .work-space .main .container .item.selected.not-ok .status {
  background: rgba(255, 255, 255, 0.12) !important;
  color: #e57373;
}
.pink-pink.dark app-lsd .work-space .main .main-action {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark app-lsd .work-space .side {
  background: #303030;
}
.pink-pink.dark app-lsd .work-space .side .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #303030, 0 -2px 0px #303030, 2px 0 0px #303030, 0 2px 0px #303030, -2px -2px 0px #303030, 2px 2px 0px #303030, 2px -2px 0px #303030, -2px 2px 0px #303030;
}
.pink-pink.dark app-lsd .work-space .side *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.pink-pink.dark app-lsd .work-space .side *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
.pink-pink.dark app-lsd .work-space .side .edition-line {
  background: black;
}
.pink-pink.dark app-lsd .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #303030 !important;
}
.pink-pink.dark app-lsd .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(255, 255, 255, 0.5);
}
.pink-pink.dark app-lsd .work-space .side mat-form-field .mat-form-field-flex {
  background: #303030;
}
.pink-pink.dark app-lsd .work-space .side mat-checkbox:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-pink.dark app-lsd .work-space .side .hint.all {
  color: #f48fb1;
}
.pink-pink.dark app-lsd .work-space .side .toolbar .header {
  color: #f06292;
}
.pink-pink.dark app-lsd .work-space .side .toolbar .header h3 {
  color: #f06292;
}
.pink-pink.dark app-lsd .work-space .side .container .doc-resume {
  background: #f06292;
}
.pink-pink.dark app-lsd .work-space .side .container .doc-resume .line {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark app-lsd .work-space .side .container .doc-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.1);
}
.pink-pink.dark app-lsd .work-space .side .container .doc-resume .line.editable button {
  background: #f06292;
}
.pink-pink.dark app-lsd .work-space .side .container .section-title {
  color: #f06292;
}
.pink-pink.dark app-lsd .work-space .side .container .section-resume {
  background: #424242;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark app-lsd .work-space .side .container .section-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-pink.dark app-lsd .work-space .side .container .section-resume .line.editable:hover .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #424242, 0 -2px 0px #424242, 2px 0 0px #424242, 0 2px 0px #424242, -2px -2px 0px #424242, 2px 2px 0px #424242, 2px -2px 0px #424242, -2px 2px 0px #424242 !important;
}
.pink-pink.dark app-lsd .work-space .side .container .section-resume .line.editable button {
  background: #303030;
}
.pink-pink.dark app-lsd .work-space .side .container .section-resume .line .header .icon-complex mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.pink-pink.dark app-lsd .work-space .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #f06292 !important;
}
.pink-pink.dark app-lsd .work-space .side .container .section-resume .line .header mat-icon:not(.complex) {
  color: rgba(255, 255, 255, 0.7);
}
.pink-pink.dark app-lsd .work-space .side .container .section-resume .line .header .info h4 {
  color: #f06292;
}
.pink-pink.dark app-lsd .work-space .side .container .section-resume .line ul {
  color: #f06292;
}
.pink-pink.dark app-lsd .work-space .side .container .section-resume .line.head {
  background: black;
}
.pink-pink.dark app-lsd .work-space .side .container .section-resume .line.head mat-icon {
  color: #f48fb1;
}
.pink-pink.dark app-lsd .work-space .side .container .section-resume .line.head h4 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark app-lsd .work-space .side.edition .toolbar .header {
  color: rgba(0, 0, 0, 0.87);
}
.pink-pink.dark app-lsd .work-space .side.edition .toolbar .header mat-icon {
  color: #f48fb1;
}
.pink-pink.dark app-lsd .work-space .side.edition .toolbar .header h3 {
  color: #f48fb1;
}
.pink-pink.dark .dialog-content {
  max-height: 90vh;
}
.pink-pink.dark .dialog-content mat-toolbar {
  background: #424242;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark .dialog-content .dialog-container .document-template-container .document-selection mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #303030 !important;
}
.pink-pink.dark .dialog-content .dialog-container .document-template-container .document-selection mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(255, 255, 255, 0.5);
}
.pink-pink.dark .dialog-content .dialog-container .document-template-container .document-selection mat-form-field .mat-form-field-flex {
  background: #303030;
}
.pink-pink.dark .dialog-content .dialog-container .document-template-container .document-selection app-upload-form .file-upload-list .mat-subheader::before {
  color: #f06292;
}
.pink-pink.dark .dialog-content .dialog-container .view-container {
  border-left: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-pink.dark .dialog-content .form-actions {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}

.pink-purple .mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-purple .mat-badge-small .mat-badge-content {
  font-size: 9px;
}
.pink-purple .mat-badge-large .mat-badge-content {
  font-size: 24px;
}
.pink-purple .mat-h1,
.pink-purple .mat-headline,
.pink-purple .mat-typography .mat-h1,
.pink-purple .mat-typography .mat-headline,
.pink-purple .mat-typography h1 {
  font: 400 24px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.pink-purple .mat-h2,
.pink-purple .mat-title,
.pink-purple .mat-typography .mat-h2,
.pink-purple .mat-typography .mat-title,
.pink-purple .mat-typography h2 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.pink-purple .mat-h3,
.pink-purple .mat-subheading-2,
.pink-purple .mat-typography .mat-h3,
.pink-purple .mat-typography .mat-subheading-2,
.pink-purple .mat-typography h3 {
  font: 400 16px / 28px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.pink-purple .mat-h4,
.pink-purple .mat-subheading-1,
.pink-purple .mat-typography .mat-h4,
.pink-purple .mat-typography .mat-subheading-1,
.pink-purple .mat-typography h4 {
  font: 400 15px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.pink-purple .mat-h5,
.pink-purple .mat-typography .mat-h5,
.pink-purple .mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}
.pink-purple .mat-h6,
.pink-purple .mat-typography .mat-h6,
.pink-purple .mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}
.pink-purple .mat-body-strong,
.pink-purple .mat-body-2,
.pink-purple .mat-typography .mat-body-strong,
.pink-purple .mat-typography .mat-body-2 {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.pink-purple .mat-body,
.pink-purple .mat-body-1,
.pink-purple .mat-typography .mat-body,
.pink-purple .mat-typography .mat-body-1,
.pink-purple .mat-typography {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.pink-purple .mat-body p,
.pink-purple .mat-body-1 p,
.pink-purple .mat-typography .mat-body p,
.pink-purple .mat-typography .mat-body-1 p,
.pink-purple .mat-typography p {
  margin: 0 0 12px;
}
.pink-purple .mat-small,
.pink-purple .mat-caption,
.pink-purple .mat-typography .mat-small,
.pink-purple .mat-typography .mat-caption {
  font: 400 12px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.pink-purple .mat-display-4,
.pink-purple .mat-typography .mat-display-4 {
  font: 300 112px / 112px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}
.pink-purple .mat-display-3,
.pink-purple .mat-typography .mat-display-3 {
  font: 400 56px / 56px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}
.pink-purple .mat-display-2,
.pink-purple .mat-typography .mat-display-2 {
  font: 400 45px / 48px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}
.pink-purple .mat-display-1,
.pink-purple .mat-typography .mat-display-1 {
  font: 400 34px / 40px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}
.pink-purple .mat-bottom-sheet-container {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.pink-purple .mat-button, .pink-purple .mat-raised-button, .pink-purple .mat-icon-button, .pink-purple .mat-stroked-button,
.pink-purple .mat-flat-button, .pink-purple .mat-fab, .pink-purple .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.pink-purple .mat-button-toggle {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-purple .mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-purple .mat-card-title {
  font-size: 24px;
  font-weight: 500;
}
.pink-purple .mat-card-header .mat-card-title {
  font-size: 20px;
}
.pink-purple .mat-card-subtitle,
.pink-purple .mat-card-content {
  font-size: 14px;
}
.pink-purple .mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-purple .mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}
.pink-purple .mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.pink-purple .mat-chip .mat-chip-trailing-icon.mat-icon,
.pink-purple .mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}
.pink-purple .mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-purple .mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}
.pink-purple .mat-cell, .pink-purple .mat-footer-cell {
  font-size: 14px;
}
.pink-purple .mat-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-purple .mat-calendar-body {
  font-size: 13px;
}
.pink-purple .mat-calendar-body-label,
.pink-purple .mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}
.pink-purple .mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}
.pink-purple .mat-dialog-title {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.pink-purple .mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400;
}
.pink-purple .mat-expansion-panel-content {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.pink-purple .mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.pink-purple .mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}
.pink-purple .mat-form-field-prefix .mat-icon,
.pink-purple .mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.pink-purple .mat-form-field-prefix .mat-icon-button,
.pink-purple .mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.pink-purple .mat-form-field-prefix .mat-icon-button .mat-icon,
.pink-purple .mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}
.pink-purple .mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}
.pink-purple .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.pink-purple .mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34355em) scale(0.75);
  width: 133.3335333333%;
}
.pink-purple .mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34354em) scale(0.75);
  width: 133.3335433333%;
}
.pink-purple .mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}
.pink-purple .mat-form-field-label {
  top: 1.34375em;
}
.pink-purple .mat-form-field-underline {
  bottom: 1.34375em;
}
.pink-purple .mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}
.pink-purple .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.pink-purple .mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.pink-purple .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.pink-purple .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.0016px);
  width: 133.3339333333%;
}
.pink-purple .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00161px);
  width: 133.3339433333%;
}
.pink-purple .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00162px);
  width: 133.3339533333%;
}
.pink-purple .mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.pink-purple .mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.pink-purple .mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}
@media print {
  .pink-purple .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.pink-purple .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28062em) scale(0.75);
  }
  .pink-purple .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28061em) scale(0.75);
  }
  .pink-purple .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2806em) scale(0.75);
  }
}
.pink-purple .mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.pink-purple .mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.pink-purple .mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.pink-purple .mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59355em) scale(0.75);
  width: 133.3335333333%;
}
.pink-purple .mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59354em) scale(0.75);
  width: 133.3335433333%;
}
.pink-purple .mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.pink-purple .mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.pink-purple .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.pink-purple .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59355em) scale(0.75);
  width: 133.3335333333%;
}
.pink-purple .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59354em) scale(0.75);
  width: 133.3335433333%;
}
.pink-purple .mat-grid-tile-header,
.pink-purple .mat-grid-tile-footer {
  font-size: 14px;
}
.pink-purple .mat-grid-tile-header .mat-line,
.pink-purple .mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.pink-purple .mat-grid-tile-header .mat-line:nth-child(n+2),
.pink-purple .mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.pink-purple input.mat-input-element {
  margin-top: -0.0625em;
}
.pink-purple .mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
}
.pink-purple .mat-paginator,
.pink-purple .mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
}
.pink-purple .mat-radio-button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-purple .mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-purple .mat-select-trigger {
  height: 1.125em;
}
.pink-purple .mat-slide-toggle-content {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-purple .mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}
.pink-purple .mat-stepper-vertical, .pink-purple .mat-stepper-horizontal {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-purple .mat-step-label {
  font-size: 14px;
  font-weight: 400;
}
.pink-purple .mat-step-sub-label-error {
  font-weight: normal;
}
.pink-purple .mat-step-label-error {
  font-size: 14px;
}
.pink-purple .mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}
.pink-purple .mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-purple .mat-tab-label, .pink-purple .mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.pink-purple .mat-toolbar,
.pink-purple .mat-toolbar h1,
.pink-purple .mat-toolbar h2,
.pink-purple .mat-toolbar h3,
.pink-purple .mat-toolbar h4,
.pink-purple .mat-toolbar h5,
.pink-purple .mat-toolbar h6 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0;
}
.pink-purple .mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.pink-purple .mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.pink-purple .mat-list-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-purple .mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-purple .mat-list-base .mat-list-item {
  font-size: 16px;
}
.pink-purple .mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.pink-purple .mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.pink-purple .mat-list-base .mat-list-option {
  font-size: 16px;
}
.pink-purple .mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.pink-purple .mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.pink-purple .mat-list-base .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.pink-purple .mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.pink-purple .mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.pink-purple .mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.pink-purple .mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.pink-purple .mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.pink-purple .mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.pink-purple .mat-list-base[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}
.pink-purple .mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
}
.pink-purple .mat-optgroup-label {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.pink-purple .mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
}
.pink-purple .mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}
.pink-purple .mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-purple .mat-tree-node,
.pink-purple .mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}
.pink-purple .mat-ripple {
  overflow: hidden;
  position: relative;
}
.pink-purple .mat-ripple:not(:empty) {
  transform: translateZ(0);
}
.pink-purple .mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}
.pink-purple .mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .pink-purple .mat-ripple-element {
  display: none;
}

.pink-purple .cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .pink-purple .cdk-visually-hidden {
  left: auto;
  right: 0;
}
.pink-purple .cdk-overlay-container, .pink-purple .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.pink-purple .cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.pink-purple .cdk-overlay-container:empty {
  display: none;
}
.pink-purple .cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}
.pink-purple .cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}
.pink-purple .cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.pink-purple .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .pink-purple .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}
.pink-purple .cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}
.pink-purple .cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.pink-purple .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}
.pink-purple .cdk-overlay-backdrop-noop-animation {
  transition: none;
}
.pink-purple .cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}
.pink-purple .cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}
.pink-purple textarea.cdk-textarea-autosize {
  resize: none;
}
.pink-purple textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}
.pink-purple textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}
@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.pink-purple .cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}
.pink-purple .cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}
.pink-purple .mat-focus-indicator {
  position: relative;
}
.pink-purple .mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.pink-purple .mat-focus-indicator:focus::before {
  content: "";
}
.cdk-high-contrast-active .pink-purple {
  --mat-focus-indicator-display: block;
}

.pink-purple .mat-mdc-focus-indicator {
  position: relative;
}
.pink-purple .mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.pink-purple .mat-mdc-focus-indicator:focus::before {
  content: "";
}
.cdk-high-contrast-active .pink-purple {
  --mat-mdc-focus-indicator-display: block;
}

.pink-purple .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.pink-purple .mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple .mat-option:hover:not(.mat-option-disabled), .pink-purple .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.pink-purple .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.pink-purple .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.pink-purple .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #e91e63;
}
.pink-purple .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #9c27b0;
}
.pink-purple .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f44336;
}
.pink-purple .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}
.pink-purple .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.pink-purple .mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.pink-purple .mat-pseudo-checkbox::after {
  color: #fafafa;
}
.pink-purple .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.pink-purple .mat-primary .mat-pseudo-checkbox-checked,
.pink-purple .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #e91e63;
}
.pink-purple .mat-pseudo-checkbox-checked,
.pink-purple .mat-pseudo-checkbox-indeterminate,
.pink-purple .mat-accent .mat-pseudo-checkbox-checked,
.pink-purple .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #9c27b0;
}
.pink-purple .mat-warn .mat-pseudo-checkbox-checked,
.pink-purple .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #f44336;
}
.pink-purple .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.pink-purple .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.pink-purple .mat-app-background, .pink-purple.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.pink-purple .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.pink-purple .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.pink-purple .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.pink-purple .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.pink-purple .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.pink-purple .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.pink-purple .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.pink-purple .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.pink-purple .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.pink-purple .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.pink-purple .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.pink-purple .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.pink-purple .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.pink-purple .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.pink-purple .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.pink-purple .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.pink-purple .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.pink-purple .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.pink-purple .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.pink-purple .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.pink-purple .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.pink-purple .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.pink-purple .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.pink-purple .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.pink-purple .mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.pink-purple .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.pink-purple .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple .mat-badge-content {
  color: white;
  background: #e91e63;
}
.cdk-high-contrast-active .pink-purple .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.pink-purple .mat-badge-accent .mat-badge-content {
  background: #9c27b0;
  color: white;
}
.pink-purple .mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336;
}
.pink-purple .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.pink-purple .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple .mat-button, .pink-purple .mat-icon-button, .pink-purple .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.pink-purple .mat-button.mat-primary, .pink-purple .mat-icon-button.mat-primary, .pink-purple .mat-stroked-button.mat-primary {
  color: #e91e63;
}
.pink-purple .mat-button.mat-accent, .pink-purple .mat-icon-button.mat-accent, .pink-purple .mat-stroked-button.mat-accent {
  color: #9c27b0;
}
.pink-purple .mat-button.mat-warn, .pink-purple .mat-icon-button.mat-warn, .pink-purple .mat-stroked-button.mat-warn {
  color: #f44336;
}
.pink-purple .mat-button.mat-primary.mat-button-disabled, .pink-purple .mat-button.mat-accent.mat-button-disabled, .pink-purple .mat-button.mat-warn.mat-button-disabled, .pink-purple .mat-button.mat-button-disabled.mat-button-disabled, .pink-purple .mat-icon-button.mat-primary.mat-button-disabled, .pink-purple .mat-icon-button.mat-accent.mat-button-disabled, .pink-purple .mat-icon-button.mat-warn.mat-button-disabled, .pink-purple .mat-icon-button.mat-button-disabled.mat-button-disabled, .pink-purple .mat-stroked-button.mat-primary.mat-button-disabled, .pink-purple .mat-stroked-button.mat-accent.mat-button-disabled, .pink-purple .mat-stroked-button.mat-warn.mat-button-disabled, .pink-purple .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.pink-purple .mat-button.mat-primary .mat-button-focus-overlay, .pink-purple .mat-icon-button.mat-primary .mat-button-focus-overlay, .pink-purple .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #e91e63;
}
.pink-purple .mat-button.mat-accent .mat-button-focus-overlay, .pink-purple .mat-icon-button.mat-accent .mat-button-focus-overlay, .pink-purple .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #9c27b0;
}
.pink-purple .mat-button.mat-warn .mat-button-focus-overlay, .pink-purple .mat-icon-button.mat-warn .mat-button-focus-overlay, .pink-purple .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #f44336;
}
.pink-purple .mat-button.mat-button-disabled .mat-button-focus-overlay, .pink-purple .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .pink-purple .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.pink-purple .mat-button .mat-ripple-element, .pink-purple .mat-icon-button .mat-ripple-element, .pink-purple .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.pink-purple .mat-button-focus-overlay {
  background: black;
}
.pink-purple .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.pink-purple .mat-flat-button, .pink-purple .mat-raised-button, .pink-purple .mat-fab, .pink-purple .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.pink-purple .mat-flat-button.mat-primary, .pink-purple .mat-raised-button.mat-primary, .pink-purple .mat-fab.mat-primary, .pink-purple .mat-mini-fab.mat-primary {
  color: white;
}
.pink-purple .mat-flat-button.mat-accent, .pink-purple .mat-raised-button.mat-accent, .pink-purple .mat-fab.mat-accent, .pink-purple .mat-mini-fab.mat-accent {
  color: white;
}
.pink-purple .mat-flat-button.mat-warn, .pink-purple .mat-raised-button.mat-warn, .pink-purple .mat-fab.mat-warn, .pink-purple .mat-mini-fab.mat-warn {
  color: white;
}
.pink-purple .mat-flat-button.mat-primary.mat-button-disabled, .pink-purple .mat-flat-button.mat-accent.mat-button-disabled, .pink-purple .mat-flat-button.mat-warn.mat-button-disabled, .pink-purple .mat-flat-button.mat-button-disabled.mat-button-disabled, .pink-purple .mat-raised-button.mat-primary.mat-button-disabled, .pink-purple .mat-raised-button.mat-accent.mat-button-disabled, .pink-purple .mat-raised-button.mat-warn.mat-button-disabled, .pink-purple .mat-raised-button.mat-button-disabled.mat-button-disabled, .pink-purple .mat-fab.mat-primary.mat-button-disabled, .pink-purple .mat-fab.mat-accent.mat-button-disabled, .pink-purple .mat-fab.mat-warn.mat-button-disabled, .pink-purple .mat-fab.mat-button-disabled.mat-button-disabled, .pink-purple .mat-mini-fab.mat-primary.mat-button-disabled, .pink-purple .mat-mini-fab.mat-accent.mat-button-disabled, .pink-purple .mat-mini-fab.mat-warn.mat-button-disabled, .pink-purple .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.pink-purple .mat-flat-button.mat-primary, .pink-purple .mat-raised-button.mat-primary, .pink-purple .mat-fab.mat-primary, .pink-purple .mat-mini-fab.mat-primary {
  background-color: #e91e63;
}
.pink-purple .mat-flat-button.mat-accent, .pink-purple .mat-raised-button.mat-accent, .pink-purple .mat-fab.mat-accent, .pink-purple .mat-mini-fab.mat-accent {
  background-color: #9c27b0;
}
.pink-purple .mat-flat-button.mat-warn, .pink-purple .mat-raised-button.mat-warn, .pink-purple .mat-fab.mat-warn, .pink-purple .mat-mini-fab.mat-warn {
  background-color: #f44336;
}
.pink-purple .mat-flat-button.mat-primary.mat-button-disabled, .pink-purple .mat-flat-button.mat-accent.mat-button-disabled, .pink-purple .mat-flat-button.mat-warn.mat-button-disabled, .pink-purple .mat-flat-button.mat-button-disabled.mat-button-disabled, .pink-purple .mat-raised-button.mat-primary.mat-button-disabled, .pink-purple .mat-raised-button.mat-accent.mat-button-disabled, .pink-purple .mat-raised-button.mat-warn.mat-button-disabled, .pink-purple .mat-raised-button.mat-button-disabled.mat-button-disabled, .pink-purple .mat-fab.mat-primary.mat-button-disabled, .pink-purple .mat-fab.mat-accent.mat-button-disabled, .pink-purple .mat-fab.mat-warn.mat-button-disabled, .pink-purple .mat-fab.mat-button-disabled.mat-button-disabled, .pink-purple .mat-mini-fab.mat-primary.mat-button-disabled, .pink-purple .mat-mini-fab.mat-accent.mat-button-disabled, .pink-purple .mat-mini-fab.mat-warn.mat-button-disabled, .pink-purple .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.pink-purple .mat-flat-button.mat-primary .mat-ripple-element, .pink-purple .mat-raised-button.mat-primary .mat-ripple-element, .pink-purple .mat-fab.mat-primary .mat-ripple-element, .pink-purple .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.pink-purple .mat-flat-button.mat-accent .mat-ripple-element, .pink-purple .mat-raised-button.mat-accent .mat-ripple-element, .pink-purple .mat-fab.mat-accent .mat-ripple-element, .pink-purple .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.pink-purple .mat-flat-button.mat-warn .mat-ripple-element, .pink-purple .mat-raised-button.mat-warn .mat-ripple-element, .pink-purple .mat-fab.mat-warn .mat-ripple-element, .pink-purple .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.pink-purple .mat-stroked-button:not([class*=mat-elevation-z]), .pink-purple .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.pink-purple .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.pink-purple .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.pink-purple .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.pink-purple .mat-fab:not([class*=mat-elevation-z]), .pink-purple .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.pink-purple .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .pink-purple .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.pink-purple .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .pink-purple .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.pink-purple .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.pink-purple .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.pink-purple .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.pink-purple .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.pink-purple .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.pink-purple .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.pink-purple .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.pink-purple .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.pink-purple .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
.pink-purple [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.pink-purple .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
.pink-purple .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.pink-purple .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.pink-purple .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.pink-purple .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.pink-purple .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.pink-purple .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
.pink-purple .mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.pink-purple .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.pink-purple .mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.pink-purple .mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}
.pink-purple .mat-checkbox-checkmark {
  fill: #fafafa;
}
.pink-purple .mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
.pink-purple .mat-checkbox-mixedmark {
  background-color: #fafafa;
}
.pink-purple .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .pink-purple .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #e91e63;
}
.pink-purple .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .pink-purple .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #9c27b0;
}
.pink-purple .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .pink-purple .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #f44336;
}
.pink-purple .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .pink-purple .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.pink-purple .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.pink-purple .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}
.pink-purple .mat-checkbox .mat-ripple-element {
  background-color: black;
}
.pink-purple .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.pink-purple .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #e91e63;
}
.pink-purple .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.pink-purple .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #9c27b0;
}
.pink-purple .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.pink-purple .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #f44336;
}
.pink-purple .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple .mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.pink-purple .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.pink-purple .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.pink-purple .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.pink-purple .mat-chip.mat-standard-chip::after {
  background: black;
}
.pink-purple .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #e91e63;
  color: white;
}
.pink-purple .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.pink-purple .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.pink-purple .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #f44336;
  color: white;
}
.pink-purple .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.pink-purple .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.pink-purple .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #9c27b0;
  color: white;
}
.pink-purple .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.pink-purple .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.pink-purple .mat-table {
  background: white;
}
.pink-purple .mat-table thead, .pink-purple .mat-table tbody, .pink-purple .mat-table tfoot,
.pink-purple mat-header-row, .pink-purple mat-row, .pink-purple mat-footer-row,
.pink-purple [mat-header-row], .pink-purple [mat-row], .pink-purple [mat-footer-row],
.pink-purple .mat-table-sticky {
  background: inherit;
}
.pink-purple mat-row, .pink-purple mat-header-row, .pink-purple mat-footer-row,
.pink-purple th.mat-header-cell, .pink-purple td.mat-cell, .pink-purple td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.pink-purple .mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}
.pink-purple .mat-cell, .pink-purple .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple .mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}
.pink-purple .mat-datepicker-toggle,
.pink-purple .mat-datepicker-content .mat-calendar-next-button,
.pink-purple .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.pink-purple .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.pink-purple .mat-calendar-table-header,
.pink-purple .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.pink-purple .mat-calendar-body-cell-content,
.pink-purple .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.pink-purple .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.pink-purple .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.pink-purple .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.pink-purple .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.pink-purple .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.pink-purple .mat-calendar-body-in-range::before {
  background: rgba(233, 30, 99, 0.2);
}
.pink-purple .mat-calendar-body-comparison-identical,
.pink-purple .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.pink-purple .mat-calendar-body-comparison-bridge-start::before,
.pink-purple [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(233, 30, 99, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.pink-purple .mat-calendar-body-comparison-bridge-end::before,
.pink-purple [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(233, 30, 99, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.pink-purple .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.pink-purple .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.pink-purple .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.pink-purple .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.pink-purple .mat-calendar-body-selected {
  background-color: #e91e63;
  color: white;
}
.pink-purple .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(233, 30, 99, 0.4);
}
.pink-purple .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.pink-purple .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.pink-purple .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(233, 30, 99, 0.3);
}
@media (hover: hover) {
  .pink-purple .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(233, 30, 99, 0.3);
  }
}
.pink-purple .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(156, 39, 176, 0.2);
}
.pink-purple .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.pink-purple .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.pink-purple .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.pink-purple .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(156, 39, 176, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.pink-purple .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.pink-purple .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(156, 39, 176, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.pink-purple .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.pink-purple .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.pink-purple .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.pink-purple .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.pink-purple .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #9c27b0;
  color: white;
}
.pink-purple .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(156, 39, 176, 0.4);
}
.pink-purple .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.pink-purple .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.pink-purple .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(156, 39, 176, 0.3);
}
@media (hover: hover) {
  .pink-purple .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(156, 39, 176, 0.3);
  }
}
.pink-purple .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}
.pink-purple .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.pink-purple .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.pink-purple .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.pink-purple .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.pink-purple .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.pink-purple .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.pink-purple .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.pink-purple .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.pink-purple .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.pink-purple .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.pink-purple .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: white;
}
.pink-purple .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}
.pink-purple .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.pink-purple .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.pink-purple .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(244, 67, 54, 0.3);
}
@media (hover: hover) {
  .pink-purple .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(244, 67, 54, 0.3);
  }
}
.pink-purple .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.pink-purple .mat-datepicker-toggle-active {
  color: #e91e63;
}
.pink-purple .mat-datepicker-toggle-active.mat-accent {
  color: #9c27b0;
}
.pink-purple .mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}
.pink-purple .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.pink-purple .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.pink-purple .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.pink-purple .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.pink-purple .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.pink-purple .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .pink-purple .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .pink-purple .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .pink-purple .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.pink-purple .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple .mat-expansion-panel-header-description,
.pink-purple .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.pink-purple .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.pink-purple .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.pink-purple .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.pink-purple .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.pink-purple .mat-hint {
  color: rgba(0, 0, 0, 0.6);
}
.pink-purple .mat-form-field.mat-focused .mat-form-field-label {
  color: #e91e63;
}
.pink-purple .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #9c27b0;
}
.pink-purple .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #f44336;
}
.pink-purple .mat-focused .mat-form-field-required-marker {
  color: #9c27b0;
}
.pink-purple .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.pink-purple .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #e91e63;
}
.pink-purple .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #9c27b0;
}
.pink-purple .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #f44336;
}
.pink-purple .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #e91e63;
}
.pink-purple .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #9c27b0;
}
.pink-purple .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #f44336;
}
.pink-purple .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #f44336;
}
.pink-purple .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.pink-purple .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #f44336;
}
.pink-purple .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.pink-purple .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #f44336;
}
.pink-purple .mat-error {
  color: #f44336;
}
.pink-purple .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.pink-purple .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.pink-purple .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.pink-purple .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.pink-purple .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.pink-purple .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.pink-purple .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.pink-purple .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.pink-purple .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.pink-purple .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.pink-purple .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.pink-purple .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.pink-purple .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #e91e63;
}
.pink-purple .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #9c27b0;
}
.pink-purple .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #f44336;
}
.pink-purple .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #f44336;
}
.pink-purple .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.pink-purple .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.pink-purple .mat-icon.mat-primary {
  color: #e91e63;
}
.pink-purple .mat-icon.mat-accent {
  color: #9c27b0;
}
.pink-purple .mat-icon.mat-warn {
  color: #f44336;
}
.pink-purple .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.pink-purple .mat-input-element:disabled,
.pink-purple .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.pink-purple .mat-input-element {
  caret-color: #e91e63;
}
.pink-purple .mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.pink-purple .mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.pink-purple .mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.pink-purple .mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.pink-purple .mat-form-field.mat-accent .mat-input-element {
  caret-color: #9c27b0;
}
.pink-purple .mat-form-field.mat-warn .mat-input-element,
.pink-purple .mat-form-field-invalid .mat-input-element {
  caret-color: #f44336;
}
.pink-purple .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #f44336;
}
.pink-purple .mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple .mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple .mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.pink-purple .mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}
.pink-purple .mat-list-option:hover, .pink-purple .mat-list-option:focus,
.pink-purple .mat-nav-list .mat-list-item:hover,
.pink-purple .mat-nav-list .mat-list-item:focus,
.pink-purple .mat-action-list .mat-list-item:hover,
.pink-purple .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.pink-purple .mat-list-single-selected-option, .pink-purple .mat-list-single-selected-option:hover, .pink-purple .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.pink-purple .mat-menu-panel {
  background: white;
}
.pink-purple .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.pink-purple .mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple .mat-menu-item[disabled],
.pink-purple .mat-menu-item[disabled] .mat-menu-submenu-icon,
.pink-purple .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}
.pink-purple .mat-menu-item .mat-icon-no-color,
.pink-purple .mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}
.pink-purple .mat-menu-item:hover:not([disabled]),
.pink-purple .mat-menu-item.cdk-program-focused:not([disabled]),
.pink-purple .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.pink-purple .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.pink-purple .mat-paginator {
  background: white;
}
.pink-purple .mat-paginator,
.pink-purple .mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}
.pink-purple .mat-paginator-decrement,
.pink-purple .mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.pink-purple .mat-paginator-first,
.pink-purple .mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.pink-purple .mat-icon-button[disabled] .mat-paginator-decrement,
.pink-purple .mat-icon-button[disabled] .mat-paginator-increment,
.pink-purple .mat-icon-button[disabled] .mat-paginator-first,
.pink-purple .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.pink-purple .mat-progress-bar-background {
  fill: #f6c3d4;
}
.pink-purple .mat-progress-bar-buffer {
  background-color: #f6c3d4;
}
.pink-purple .mat-progress-bar-fill::after {
  background-color: #e91e63;
}
.pink-purple .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #e3c5e8;
}
.pink-purple .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #e3c5e8;
}
.pink-purple .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #9c27b0;
}
.pink-purple .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f9ccc9;
}
.pink-purple .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f9ccc9;
}
.pink-purple .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #f44336;
}
.pink-purple .mat-progress-spinner circle, .pink-purple .mat-spinner circle {
  stroke: #e91e63;
}
.pink-purple .mat-progress-spinner.mat-accent circle, .pink-purple .mat-spinner.mat-accent circle {
  stroke: #9c27b0;
}
.pink-purple .mat-progress-spinner.mat-warn circle, .pink-purple .mat-spinner.mat-warn circle {
  stroke: #f44336;
}
.pink-purple .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.pink-purple .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e91e63;
}
.pink-purple .mat-radio-button.mat-primary .mat-radio-inner-circle,
.pink-purple .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .pink-purple .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .pink-purple .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #e91e63;
}
.pink-purple .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #9c27b0;
}
.pink-purple .mat-radio-button.mat-accent .mat-radio-inner-circle,
.pink-purple .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .pink-purple .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .pink-purple .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #9c27b0;
}
.pink-purple .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f44336;
}
.pink-purple .mat-radio-button.mat-warn .mat-radio-inner-circle,
.pink-purple .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .pink-purple .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .pink-purple .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #f44336;
}
.pink-purple .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.pink-purple .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.pink-purple .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.pink-purple .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.pink-purple .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.pink-purple .mat-radio-button .mat-ripple-element {
  background-color: black;
}
.pink-purple .mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple .mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.pink-purple .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.pink-purple .mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.pink-purple .mat-select-panel {
  background: white;
}
.pink-purple .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.pink-purple .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.pink-purple .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #e91e63;
}
.pink-purple .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #9c27b0;
}
.pink-purple .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #f44336;
}
.pink-purple .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #f44336;
}
.pink-purple .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.pink-purple .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple .mat-drawer.mat-drawer-push {
  background-color: white;
}
.pink-purple .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.pink-purple .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.pink-purple .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.pink-purple [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.pink-purple [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.pink-purple .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.pink-purple .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #9c27b0;
}
.pink-purple .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(156, 39, 176, 0.54);
}
.pink-purple .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #9c27b0;
}
.pink-purple .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #e91e63;
}
.pink-purple .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(233, 30, 99, 0.54);
}
.pink-purple .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #e91e63;
}
.pink-purple .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #f44336;
}
.pink-purple .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(244, 67, 54, 0.54);
}
.pink-purple .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #f44336;
}
.pink-purple .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
.pink-purple .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
.pink-purple .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.pink-purple .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.pink-purple .mat-slider.mat-primary .mat-slider-track-fill,
.pink-purple .mat-slider.mat-primary .mat-slider-thumb,
.pink-purple .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #e91e63;
}
.pink-purple .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.pink-purple .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(233, 30, 99, 0.2);
}
.pink-purple .mat-slider.mat-accent .mat-slider-track-fill,
.pink-purple .mat-slider.mat-accent .mat-slider-thumb,
.pink-purple .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #9c27b0;
}
.pink-purple .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.pink-purple .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(156, 39, 176, 0.2);
}
.pink-purple .mat-slider.mat-warn .mat-slider-track-fill,
.pink-purple .mat-slider.mat-warn .mat-slider-thumb,
.pink-purple .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #f44336;
}
.pink-purple .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.pink-purple .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(244, 67, 54, 0.2);
}
.pink-purple .mat-slider:hover .mat-slider-track-background,
.pink-purple .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}
.pink-purple .mat-slider.mat-slider-disabled .mat-slider-track-background,
.pink-purple .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.pink-purple .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.pink-purple .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.pink-purple .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.pink-purple .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.pink-purple .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.pink-purple .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.pink-purple .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.pink-purple .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.pink-purple .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .pink-purple .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.pink-purple .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .pink-purple .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}
.pink-purple .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.pink-purple .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.pink-purple .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.pink-purple .mat-step-header.cdk-keyboard-focused, .pink-purple .mat-step-header.cdk-program-focused, .pink-purple .mat-step-header:hover:not([aria-disabled]), .pink-purple .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.pink-purple .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .pink-purple .mat-step-header:hover {
    background: none;
  }
}
.pink-purple .mat-step-header .mat-step-label,
.pink-purple .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.pink-purple .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.pink-purple .mat-step-header .mat-step-icon-selected,
.pink-purple .mat-step-header .mat-step-icon-state-done,
.pink-purple .mat-step-header .mat-step-icon-state-edit {
  background-color: #e91e63;
  color: white;
}
.pink-purple .mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.pink-purple .mat-step-header.mat-accent .mat-step-icon-selected,
.pink-purple .mat-step-header.mat-accent .mat-step-icon-state-done,
.pink-purple .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #9c27b0;
  color: white;
}
.pink-purple .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.pink-purple .mat-step-header.mat-warn .mat-step-icon-selected,
.pink-purple .mat-step-header.mat-warn .mat-step-icon-state-done,
.pink-purple .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #f44336;
  color: white;
}
.pink-purple .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336;
}
.pink-purple .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple .mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336;
}
.pink-purple .mat-stepper-horizontal, .pink-purple .mat-stepper-vertical {
  background-color: white;
}
.pink-purple .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.pink-purple .mat-horizontal-stepper-header::before,
.pink-purple .mat-horizontal-stepper-header::after,
.pink-purple .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.pink-purple .mat-sort-header-arrow {
  color: #757575;
}
.pink-purple .mat-tab-nav-bar,
.pink-purple .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple .mat-tab-group-inverted-header .mat-tab-nav-bar,
.pink-purple .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.pink-purple .mat-tab-label, .pink-purple .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple .mat-tab-label.mat-tab-disabled, .pink-purple .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.pink-purple .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.pink-purple .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.pink-purple .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.pink-purple .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.pink-purple .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-purple .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-purple .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-purple .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .pink-purple .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-purple .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-purple .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-purple .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 187, 208, 0.3);
}
.pink-purple .mat-tab-group.mat-primary .mat-ink-bar, .pink-purple .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #e91e63;
}
.pink-purple .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .pink-purple .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .pink-purple .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .pink-purple .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.pink-purple .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-purple .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-purple .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-purple .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .pink-purple .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-purple .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-purple .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-purple .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(225, 190, 231, 0.3);
}
.pink-purple .mat-tab-group.mat-accent .mat-ink-bar, .pink-purple .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #9c27b0;
}
.pink-purple .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .pink-purple .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .pink-purple .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .pink-purple .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.pink-purple .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-purple .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-purple .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-purple .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .pink-purple .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-purple .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-purple .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-purple .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.pink-purple .mat-tab-group.mat-warn .mat-ink-bar, .pink-purple .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #f44336;
}
.pink-purple .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .pink-purple .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .pink-purple .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .pink-purple .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.pink-purple .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-purple .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-purple .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-purple .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .pink-purple .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-purple .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-purple .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-purple .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 187, 208, 0.3);
}
.pink-purple .mat-tab-group.mat-background-primary > .mat-tab-header, .pink-purple .mat-tab-group.mat-background-primary > .mat-tab-link-container, .pink-purple .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .pink-purple .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .pink-purple .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .pink-purple .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #e91e63;
}
.pink-purple .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .pink-purple .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .pink-purple .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .pink-purple .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.pink-purple .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .pink-purple .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .pink-purple .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .pink-purple .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.pink-purple .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.pink-purple .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.pink-purple .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.pink-purple .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .pink-purple .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.pink-purple .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.pink-purple .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.pink-purple .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.pink-purple .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.pink-purple .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .pink-purple .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.pink-purple .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.pink-purple .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.pink-purple .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.pink-purple .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .pink-purple .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.pink-purple .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.pink-purple .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.pink-purple .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-purple .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-purple .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-purple .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .pink-purple .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-purple .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-purple .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-purple .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(225, 190, 231, 0.3);
}
.pink-purple .mat-tab-group.mat-background-accent > .mat-tab-header, .pink-purple .mat-tab-group.mat-background-accent > .mat-tab-link-container, .pink-purple .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .pink-purple .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .pink-purple .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .pink-purple .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #9c27b0;
}
.pink-purple .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .pink-purple .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .pink-purple .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .pink-purple .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.pink-purple .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .pink-purple .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .pink-purple .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .pink-purple .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.pink-purple .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.pink-purple .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.pink-purple .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.pink-purple .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .pink-purple .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.pink-purple .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.pink-purple .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.pink-purple .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.pink-purple .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.pink-purple .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .pink-purple .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.pink-purple .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.pink-purple .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.pink-purple .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.pink-purple .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .pink-purple .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.pink-purple .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.pink-purple .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.pink-purple .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-purple .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-purple .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-purple .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .pink-purple .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-purple .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-purple .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-purple .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.pink-purple .mat-tab-group.mat-background-warn > .mat-tab-header, .pink-purple .mat-tab-group.mat-background-warn > .mat-tab-link-container, .pink-purple .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .pink-purple .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .pink-purple .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .pink-purple .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #f44336;
}
.pink-purple .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .pink-purple .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .pink-purple .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .pink-purple .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.pink-purple .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .pink-purple .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .pink-purple .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .pink-purple .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.pink-purple .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.pink-purple .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.pink-purple .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.pink-purple .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .pink-purple .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.pink-purple .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.pink-purple .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.pink-purple .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.pink-purple .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.pink-purple .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .pink-purple .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.pink-purple .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.pink-purple .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.pink-purple .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.pink-purple .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .pink-purple .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.pink-purple .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.pink-purple .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.pink-purple .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple .mat-toolbar.mat-primary {
  background: #e91e63;
  color: white;
}
.pink-purple .mat-toolbar.mat-accent {
  background: #9c27b0;
  color: white;
}
.pink-purple .mat-toolbar.mat-warn {
  background: #f44336;
  color: white;
}
.pink-purple .mat-toolbar .mat-form-field-underline,
.pink-purple .mat-toolbar .mat-form-field-ripple,
.pink-purple .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.pink-purple .mat-toolbar .mat-form-field-label,
.pink-purple .mat-toolbar .mat-focused .mat-form-field-label,
.pink-purple .mat-toolbar .mat-select-value,
.pink-purple .mat-toolbar .mat-select-arrow,
.pink-purple .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.pink-purple .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.pink-purple .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.pink-purple .mat-tree {
  background: white;
}
.pink-purple .mat-tree-node,
.pink-purple .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.pink-purple .mat-simple-snackbar-action {
  color: #9c27b0;
}
.pink-purple *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.pink-purple *::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}
.pink-purple *::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}
.pink-purple .toolbar-line.container-ou {
  background: #e91e63;
  color: white;
}
.pink-purple .toolbar-line.container-persona {
  background: #e91e63 !important;
}
.pink-purple .toolbar-line.container-persona .persona-title {
  background: #e91e63;
}
.pink-purple .toolbar-line.container-persona .invisible-container.persona-selector {
  background: #e91e63 !important;
}
.pink-purple button.mat-icon-button:not([disabled]):hover {
  background: rgba(0, 0, 0, 0.04);
}
.pink-purple .side-bar {
  background: #eeeeee;
}
.pink-purple .icon-stacked {
  color: white !important;
}
.pink-purple .icon-stacked mat-icon + mat-icon {
  text-shadow: -1px 1px 0px #e91e63, 1px -1px 0px #e91e63;
}
.pink-purple .divider-vert {
  background: rgba(0, 0, 0, 0.12);
}
.pink-purple .avatar {
  background: #c2185b !important;
}
.pink-purple ngx-avatar .avatar-content {
  background: #c2185b !important;
}
.pink-purple .mat-loading-window {
  background: white;
}
.pink-purple .window-spinner {
  background: white;
}
.pink-purple .load-window {
  background: white;
}
@media (min-width: 600px) and (max-width: 1300px) {
  .pink-purple mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .load-window,
.pink-purple mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .load-window.new {
    background: white !important;
  }
  .pink-purple mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card {
    background: white !important;
  }
  .pink-purple mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .inbox-container mat-toolbar {
    background: #e91e63;
    color: white;
  }
  .pink-purple mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .inbox-container mat-list-item.mat-list-item {
    border: 1px solid rgba(0, 0, 0, 0.12);
  }
  .pink-purple mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .detail {
    background: white !important;
  }
  .pink-purple mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .detail mat-toolbar {
    background: white !important;
  }
  .pink-purple mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .main.w-detail + .new-detail-container .load-window {
    background: #fafafa !important;
  }
  .pink-purple mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .main.w-detail + .new-detail-container:before {
    background: rgba(0, 0, 0, 0.6);
  }
  .pink-purple mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .main.side-open + .side {
    border-left: 1px solid rgba(0, 0, 0, 0.12);
  }
  .pink-purple mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .side .container .mat-list-base {
    background: white !important;
    border: 1px solid rgba(0, 0, 0, 0.12);
  }
  .pink-purple mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .new-table .mat-header-row,
.pink-purple mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .new-table .mat-table,
.pink-purple mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .document-table .mat-header-row,
.pink-purple mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .document-table .mat-table,
.pink-purple mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .status-table .mat-header-row,
.pink-purple mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .status-table .mat-table {
    background: white !important;
  }
  .pink-purple mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .audit-filter .filter-container.is-floating {
    background: #fafafa !important;
    border: 1px solid rgba(0, 0, 0, 0.12);
  }
  .pink-purple mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .audit-filter .filter-container.is-floating .form-body {
    background: #fafafa !important;
  }
  .pink-purple mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .audit-result {
    background: white;
  }
  .pink-purple mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .audit-detail-container {
    background: #fafafa !important;
  }
}
@media (max-width: 599px) {
  .pink-purple app-document-detail .mat-toolbar {
    background: #c2185b !important;
    color: white !important;
  }
  .pink-purple app-document-detail .mat-toolbar .mat-toolbar-row .toolbar-actions {
    background: #c2185b !important;
    color: white !important;
  }
  .pink-purple .login-card {
    background: white !important;
  }
  .pink-purple .inbox-toolbar {
    background: #c2185b !important;
    color: white !important;
  }
  .pink-purple .detail {
    background: white url("../img/spinner_mobile.gif") no-repeat center center !important;
  }
  .pink-purple .toolbar-line-container .toolbar-line:first-child {
    background: #c2185b !important;
    color: white !important;
  }
  .pink-purple .toolbar-actions {
    background: #c2185b !important;
    color: white !important;
  }
  .pink-purple mat-drawer.side-nav.enter {
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12) !important;
  }
  .pink-purple .side-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0) !important;
  }
  .pink-purple .filter {
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.55) 16%, rgba(0, 0, 0, 0) 100%) !important;
  }
  .pink-purple button.side-open-mobile {
    color: white !important;
  }
  .pink-purple .side-nav .side-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0);
  }
  .pink-purple .side-nav .filter {
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.55) 16%, rgba(0, 0, 0, 0) 100%);
  }
  .pink-purple .side-nav .side-nav-container .links {
    background: white;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
  }
  .pink-purple .side-nav .side-nav-container .version {
    background: white !important;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
  }
  .pink-purple app-file-document-view .view-container {
    background: white !important;
  }
  .pink-purple app-file-document-view .view-container .side-bar {
    background: #c2185b !important;
    color: white !important;
  }
  .pink-purple app-file-document-view .view-container .view-content app-pdf-wrapper.floating {
    background: white;
  }
  .pink-purple app-file-document-view .view-container .view-content .upload-indications {
    background: #eeeeee;
  }
  .pink-purple app-file-document-view .view-container .view-content app-file-document-sign .sign-cycle mat-horizontal-stepper.mat-stepper-horizontal {
    background-color: rgba(0, 0, 0, 0) !important;
  }
  .pink-purple app-file-document-view .view-container .view-content app-file-document-sign .sign-cycle .sign-cycle-content .cycle-content-action .mat-ripple-element {
    background-color: rgba(255, 255, 255, 0.1) !important;
  }
  .pink-purple app-pending .floating-container .inbox-container .inbox-toolbar .mat-toolbar-row .mat-avatar h3 {
    color: white !important;
  }
  .pink-purple app-pending .floating-container .detail {
    background: white url("../img/spinner_mobile.gif") no-repeat center center !important;
  }
  .pink-purple app-document-sign-massive .inbox-container .pending-container app-chapa.minimized .info-empty {
    background: #9c27b0;
  }
  .pink-purple app-document-sign-massive .inbox-container .pending-container app-chapa.minimized .info-empty > span h2 {
    color: white !important;
  }
  .pink-purple app-document-sign-massive .inbox-container .signer.mobile {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    background: white;
  }
  .pink-purple app-document-sign-massive .detail app-file-document-detail .view-container .mat-toolbar .mat-toolbar-row .view-actions {
    color: white !important;
  }
  .pink-purple app-add-documentation .floating-container .employee-add-documentation .inbox-container .inbox-toolbar .mat-toolbar-row .mat-avatar h3 {
    color: white !important;
  }
  .pink-purple app-add-documentation .floating-container .employee-add-documentation .detail {
    background: white url("../img/spinner_mobile.gif") no-repeat center center !important;
  }
  .pink-purple app-add-documentation .floating-container .employee-add-documentation .detail app-file-document-view .view-container .view-content .new-container app-file-document-upload {
    background: white;
  }
}
.pink-purple .mat-card .mat-drawer-container {
  background: white !important;
}
.pink-purple .mat-drawer-container {
  background: #e0e0e0 !important;
}
.pink-purple .mat-list .mat-list-item.finished-process-inbox.active {
  background: #4DB6AC !important;
}
.pink-purple .mat-list .mat-list-item.finished-process-inbox .mat-icon {
  background: #4DB6AC;
}
.pink-purple .mat-list .mat-list-item.erased-process-inbox.active {
  background: #837C80 !important;
}
.pink-purple .mat-list .mat-list-item.limbo-process-inbox.active {
  background: #FFD54F !important;
}
.pink-purple .mat-list .mat-list-item.limbo-process-inbox .mat-icon {
  background: #FFD54F;
}
.pink-purple .mat-list .mat-list-item.in-process-inbox.active {
  background: #FFD54F !important;
}
.pink-purple .mat-list .mat-list-item.in-process-inbox .mat-icon {
  background: #FFD54F;
}
.pink-purple .mat-list .mat-list-item.error-process-inbox.active {
  background: #F48FB1 !important;
}
.pink-purple .mat-list .mat-list-item.error-process-inbox .mat-icon {
  background: #F48FB1;
}
.pink-purple .mat-list .mat-list-item.active .mat-line b {
  color: #e91e63;
}
.pink-purple .mat-list .mat-list-item.active .mat-list-avatar {
  color: #e91e63;
}
.pink-purple .mat-list .mat-list-item:hover:not(.active) {
  background: rgba(0, 0, 0, 0.04);
}
.pink-purple span.mat-badge-content {
  color: #f44336 !important;
}
.pink-purple mat-expansion-panel.mat-expanded {
  background: #fafafa !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple mat-expansion-panel.virtual-scrolling .mat-expansion-panel-content .get-more {
  color: #e91e63;
}
.pink-purple mat-expansion-panel.virtual-scrolling .mat-expansion-panel-content app-cs-paginator.get-more {
  background: white;
}
.pink-purple mat-expansion-panel-header.mat-expanded {
  background: white !important;
}
.pink-purple .bottom-container {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple .load-more * {
  color: #e91e63;
}
.pink-purple .welcome-card {
  background: white !important;
}
.pink-purple .welcome-card .img-divider {
  background: #c2185b;
}
.pink-purple .mat-header-row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background: white;
}
.pink-purple .mat-row {
  background: white;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.pink-purple .mat-row .mat-cell.status-cell mat-icon.pending {
  color: #FF9800 !important;
}
.pink-purple .mat-row .mat-cell.status-cell mat-icon.ok {
  color: #009688 !important;
}
.pink-purple .mat-row .mat-cell.status-cell mat-icon.not-ok {
  color: #9C27B0 !important;
}
.pink-purple .mat-row .mat-cell.status-cell mat-icon.cancelled {
  color: #795548 !important;
}
.pink-purple .mat-row .mat-cell.status-cell mat-icon.rejected {
  color: #df4d4c !important;
}
.pink-purple .mat-row .mat-cell.status-cell mat-icon.draft {
  color: #dedede !important;
}
.pink-purple .mat-row.active, .pink-purple .mat-row.active:hover {
  background: #e91e63 !important;
}
.pink-purple .mat-row.active div, .pink-purple .mat-row.active:hover div {
  color: white;
}
.pink-purple .mat-row.active .mat-cell.status-cell mat-icon.pending, .pink-purple .mat-row.active .mat-cell.status-cell mat-icon.ok, .pink-purple .mat-row.active .mat-cell.status-cell mat-icon.not-ok, .pink-purple .mat-row.active:hover .mat-cell.status-cell mat-icon.pending, .pink-purple .mat-row.active:hover .mat-cell.status-cell mat-icon.ok, .pink-purple .mat-row.active:hover .mat-cell.status-cell mat-icon.not-ok {
  text-shadow: -1px 1px 0 white, 1px 1px 0 white, 1px -1px 0 white, -1px -1px 0 white;
}
.pink-purple .mat-row.active .sticky-actions button, .pink-purple .mat-row.active:hover .sticky-actions button {
  background: #e91e63 !important;
}
.pink-purple .mat-row.active .sticky-actions button.ok mat-icon, .pink-purple .mat-row.active .sticky-actions button.not-ok mat-icon, .pink-purple .mat-row.active:hover .sticky-actions button.ok mat-icon, .pink-purple .mat-row.active:hover .sticky-actions button.not-ok mat-icon {
  text-shadow: -1px 1px 0 white, 1px 1px 0 white, 1px -1px 0 white, -1px -1px 0 white;
}
.pink-purple .mat-row:nth-child(odd) {
  background: #fafafa;
}
.pink-purple .mat-row.selected div {
  color: white;
}
.pink-purple .mat-row.selected:nth-child(even) {
  background: #f48fb1 !important;
}
.pink-purple .mat-row.selected:nth-child(even) .sticky-actions button {
  background: #f48fb1 !important;
}
.pink-purple .mat-row.selected:nth-child(odd) {
  background: #f8bbd0 !important;
}
.pink-purple .mat-row.selected:nth-child(odd) .sticky-actions button {
  background: #f8bbd0 !important;
}
.pink-purple .mat-row.selected.active, .pink-purple .mat-row.selected.active:hover {
  background: #e91e63 !important;
}
.pink-purple .mat-row.selected.active .sticky-actions button, .pink-purple .mat-row.selected.active:hover .sticky-actions button {
  background: #e91e63 !important;
}
.pink-purple .mat-row:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-purple .mat-row:hover .sticky-actions button {
  background: white !important;
}
.pink-purple .mat-row.canceled * {
  color: #795548;
}
.pink-purple .mat-row.disabled div {
  color: #607D8B;
}
.pink-purple .mat-row.disabled.active, .pink-purple .mat-row.disabled.active:hover {
  background: #607D8B !important;
}
.pink-purple .mat-row.disabled.active div, .pink-purple .mat-row.disabled.active:hover div {
  color: white;
}
.pink-purple .mat-row.disabled.active .sticky-actions button, .pink-purple .mat-row.disabled.active:hover .sticky-actions button {
  background: #607D8B !important;
}
.pink-purple .mat-row.disabled.selected.active:hover .sticky-actions button {
  background: #607D8B !important;
}
.pink-purple .mat-row .sticky-actions button.ok mat-icon {
  color: #009688 !important;
}
.pink-purple .mat-row .sticky-actions button.not-ok mat-icon {
  color: #9C27B0 !important;
}
.pink-purple .status-table .mat-header-row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background: white;
}
.pink-purple .icon-info-off,
.pink-purple .certificate-off {
  color: #607D8B;
}
.pink-purple .documents-search-table .mat-header-row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background: white;
}
.pink-purple mat-toolbar.employee-search-sub-toolbar {
  background: white;
}
.pink-purple .mat-search-field {
  border: 1px solid rgba(0, 0, 0, 0.12);
  background: #eeeeee;
}
.pink-purple .mat-search-field input[type=search] {
  background: rgba(0, 0, 0, 0);
}
.pink-purple .mat-search-field input[type=search]::placeholder {
  color: rgba(0, 0, 0, 0.38);
}
.pink-purple .search-adv-menu .mat-search-field {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple mat-toolbar.document-search-sub-toolbar {
  background: white;
}
.pink-purple app-advanced-employee-search .mat-chip {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-purple .side-nav .side-trigger {
  background: white;
}
.pink-purple .side-nav .menu-trigger mat-icon {
  color: rgba(0, 0, 0, 0.54);
  background: white;
}
.pink-purple .side-nav .side-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0);
}
.pink-purple .side-nav .filter {
  background: white;
}
.pink-purple .side-nav .side-nav-container .links mat-icon span.mat-badge-content {
  color: white !important;
}
.pink-purple .side-nav .side-nav-container .mat-list-base .mat-list-item:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-purple .side-nav .side-nav-container .mat-list-base .mat-list-item.active {
  background: #e91e63 !important;
}
.pink-purple .side-nav .side-nav-container .mat-list-base .mat-list-item.active * {
  color: white;
}
.pink-purple .side-nav .side-nav-container .mat-list-base .mat-list-item.active .mat-list-item.active:hover {
  background: #e91e63 !important;
}
.pink-purple .side-nav .side-nav-container .mat-list-base .mat-list-item.active .mat-list-item.active:hover * {
  color: white;
}
.pink-purple .side-nav.open .side-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple .side-nav.open .filter {
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.55) 16%, rgba(0, 0, 0, 0) 100%);
}
.pink-purple .side-nav.open .side-nav-container .links {
  background: white;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple .side-nav.open .side-nav-container .version {
  background: white !important;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple .side-nav.enter .side-nav-container .links {
  background: white;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple .side-nav.enter .side-nav-container .version {
  background: white !important;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple .mat-menu-panel:not(.metadata-radio-menu) .navigation-list .mat-list-item-content {
  color: rgba(0, 0, 0, 0.54);
}
.pink-purple table.mat-calendar-table {
  background: white !important;
}
.pink-purple .mat-calendar-body-label {
  color: white !important;
}
.pink-purple .mat-calendar-arrow {
  border-top-color: white !important;
}
.pink-purple .mat-datepicker-content .mat-calendar-next-button,
.pink-purple .mat-datepicker-content .mat-calendar-previous-button {
  color: white !important;
}
.pink-purple .mat-calendar-header {
  background: #c2185b !important;
}
.pink-purple mat-datepicker-content {
  background-color: #c2185b !important;
  color: white !important;
}
.pink-purple .mat-calendar-content {
  background: white !important;
}
.pink-purple .mat-simple-snackbar-action button.mat-button {
  color: #FFE082;
}
.pink-purple .mat-simple-snackbar-action .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.pink-purple mat-bottom-sheet-container mat-toolbar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}
.pink-purple app-generic-bottom-sheet h4.accent {
  color: #9c27b0;
}
.pink-purple app-generic-bottom-sheet .verification-code {
  background: #e91e63;
  color: white;
}
.pink-purple app-generic-bottom-sheet .verification-code h2 {
  background: #e91e63;
}
.pink-purple app-generic-bottom-sheet .load-window.message .info-container h3.primary-text {
  color: #e91e63;
}
.pink-purple app-generic-bottom-sheet .bottom_sheet-content.ok .bottom_sheet-title .bottom_sheet-icon,
.pink-purple app-generic-bottom-sheet .bottom_sheet-content.ok .bottom_sheet-title h3 {
  color: #009688;
}
.pink-purple app-generic-bottom-sheet .bottom_sheet-content.ok .bottom_sheet-main_btn:not(:disabled) {
  background: #009688;
  color: #fff;
}
.pink-purple app-generic-bottom-sheet .bottom_sheet-content.not-ok .bottom_sheet-title .bottom_sheet-icon,
.pink-purple app-generic-bottom-sheet .bottom_sheet-content.not-ok .bottom_sheet-title h3 {
  color: #9C27B0;
}
.pink-purple app-generic-bottom-sheet .bottom_sheet-content.not-ok .bottom_sheet-main_btn:not(:disabled) {
  background: #9C27B0;
  color: #fff;
}
.pink-purple app-generic-bottom-sheet .bottom_sheet-content.cancel .bottom_sheet-title .bottom_sheet-icon,
.pink-purple app-generic-bottom-sheet .bottom_sheet-content.cancel .bottom_sheet-title h3 {
  color: #795548;
}
.pink-purple app-generic-bottom-sheet .bottom_sheet-content.cancel .bottom_sheet-main_btn:not(:disabled) {
  background: #795548;
  color: #fff;
}
.pink-purple app-generic-bottom-sheet .bottom_sheet-content .bottom_sheet_exp_btn {
  background-color: #e91e63;
  color: #fff;
}
.pink-purple app-generic-bottom-sheet .bottom_sheet-content .bottom_sheet_canc_btn {
  background-color: lightgrey;
  color: black;
}
.pink-purple app-generic-bottom-sheet .bottom_sheet-content .bottom_sheet-exp-h3 {
  color: #e91e63;
  margin-bottom: -10px !important;
}
.pink-purple app-generic-bottom-sheet .bottom_sheet-content .mat-input-element-export {
  display: flex !important;
  padding-left: 15px;
}
.pink-purple app-generic-bottom-sheet .bottom_sheet-content .mat-form-field-infix-export-right {
  width: 100% !important;
  padding: 0px !important;
  display: flex !important;
}
.pink-purple app-generic-bottom-sheet .bottom_sheet-content .mat-form-field-infix-export-left {
  width: 100% !important;
  padding: 0px;
  display: flex !important;
}
.pink-purple app-generic-bottom-sheet .bottom_sheet-content .mat-form-field-infix {
  display: flex;
}
.pink-purple app-generic-bottom-sheet .bottom_sheet-content .mat-checkbox-layout .mat-checkbox-label {
  color: #9c27b0;
}
.pink-purple app-generic-bottom-sheet .bottom_sheet-content .choose-forms {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  margin-left: 10px !important;
}
.pink-purple app-generic-bottom-sheet .bottom_sheet-content .card-container {
  max-height: 150px;
  overflow-y: auto;
}
.pink-purple .mat-list .mat-list-item.active .mat-line {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple .mat-list .mat-list-item.active .mat-line b {
  color: #e91e63;
}
.pink-purple .mat-list .mat-list-item.active .mat-list-avatar {
  color: #e91e63;
}
.pink-purple .mat-list .mat-list-item .mat-line {
  color: rgba(0, 0, 0, 0.54);
}
.pink-purple .mat-list .mat-list-item .mat-list-avatar {
  color: rgba(0, 0, 0, 0.54);
}
.pink-purple .mat-list-item:hover:not(.active) {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-purple mat-list-item:nth-child(even),
.pink-purple app-file-document-inbox-item:nth-child(even) mat-list-item,
.pink-purple app-file-document-inbox-item-documentation:nth-child(even) mat-list-item {
  background: #fafafa !important;
}
.pink-purple mat-list-item:nth-child(odd),
.pink-purple app-file-document-inbox-item:nth-child(odd) mat-list-item,
.pink-purple app-file-document-inbox-item-documentation:nth-child(odd) mat-list-item {
  background: white !important;
}
.pink-purple .mat-list-avatar.mat-icon {
  background: #eeeeee;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple .card-filter {
  background: white;
}
.pink-purple .login mat-horizontal-stepper {
  background: white !important;
}
.pink-purple .login .login-card {
  background: white !important;
}
.pink-purple .login .login-card .mat-card-content .mat-card-footer a[color=accent] {
  color: #9c27b0;
}
.pink-purple .login .help-stepper h3.align-16 {
  color: #9c27b0;
}
.pink-purple .login .help-stepper .help-options mat-icon {
  color: #9c27b0;
}
.pink-purple .ux-modal {
  background: rgba(0, 0, 0, 0.32);
}
.pink-purple .ux-modal .card {
  background: white;
}
.pink-purple .main-title {
  background: #607D8B;
  color: white;
}
.pink-purple .sub-title {
  color: rgba(0, 0, 0, 0.54);
}
.pink-purple .card-footer a {
  color: white;
}
.pink-purple .profile-toolbar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple app-profile .logout-btn:hover {
  background: #eeeeee;
}
.pink-purple app-profile .cropper {
  background: white;
}
.pink-purple app-profile .cropper .cropper {
  background: unset;
}
.pink-purple app-add-documentation .employee-add-documentation .inbox-toolbar {
  background: #fafafa;
}
.pink-purple app-add-documentation .employee-add-documentation .inbox-toolbar .toolbar-line mat-icon {
  color: #e91e63;
}
.pink-purple app-add-documentation .employee-add-documentation .inbox-toolbar .toolbar-line h3 {
  color: #e91e63;
}
.pink-purple app-add-documentation .employee-add-documentation mat-tab-group {
  background: #fafafa;
}
.pink-purple app-add-documentation .employee-add-documentation mat-tab-group mat-tab-header .mat-tab-label-active {
  color: #e91e63;
}
.pink-purple app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content:hover {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12) !important;
}
.pink-purple app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content.selected {
  border-left: 6px solid #e91e63 !important;
}
.pink-purple app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content.selected mat-icon {
  color: #e91e63 !important;
}
.pink-purple app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content.selected .item-data {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-purple app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content mat-icon {
  color: rgba(0, 0, 0, 0.54);
}
.pink-purple app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content .item-data {
  color: rgba(0, 0, 0, 0.54);
}
.pink-purple app-add-documentation .employee-add-documentation .detail app-file-document-view .new-container app-file-document-upload .metadata-list .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
  background: white !important;
}
.pink-purple app-pending .inbox-toolbar {
  background: #fafafa;
}
.pink-purple app-pending .pending-container {
  background: #fafafa;
}
.pink-purple app-pending .pending-container .pending-items .pending-item .content:hover {
  background: rgba(0, 0, 0, 0.04) !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12) !important;
}
.pink-purple app-pending .pending-container .pending-items .pending-item .content.selected {
  border-left: 6px solid #9c27b0 !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12) !important;
}
.pink-purple app-pending .pending-container .pending-items .pending-item .content.selected mat-icon {
  color: #9c27b0 !important;
}
.pink-purple app-pending .pending-container .pending-items .pending-item .content.selected .item-data {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-purple app-pending .pending-container .pending-items .pending-item .content mat-icon {
  color: rgba(0, 0, 0, 0.54);
}
.pink-purple app-pending .pending-container .pending-items .pending-item .content .item-data {
  color: rgba(0, 0, 0, 0.54);
}
.pink-purple app-document-sign-massive .inbox-container .inbox-toolbar {
  background: #fafafa;
}
.pink-purple app-document-sign-massive .inbox-container .pending-container {
  background: #fafafa;
}
.pink-purple app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content:hover {
  background: rgba(0, 0, 0, 0.04) !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12) !important;
}
.pink-purple app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content.selected {
  border-left: 6px solid #9c27b0 !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12) !important;
}
.pink-purple app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content.selected mat-icon {
  color: #9c27b0 !important;
}
.pink-purple app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content.selected .item-data {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-purple app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content mat-icon {
  color: rgba(0, 0, 0, 0.54);
}
.pink-purple app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content .item-data {
  color: rgba(0, 0, 0, 0.54);
}
.pink-purple app-document-sign-massive .detail app-file-document-detail .view-container {
  background: #607D8B;
}
.pink-purple app-document-sign-massive .detail app-file-document-detail .view-container .mat-toolbar {
  background: #607D8B;
  color: white;
  background: #607D8B;
}
.pink-purple app-document-sign-massive .detail app-chapa.minimized .info-empty {
  background: #9c27b0;
}
.pink-purple app-document-sign-massive .detail app-chapa.minimized .info-empty > span mat-icon {
  color: white !important;
}
.pink-purple app-document-sign-massive .detail app-chapa.minimized .info-empty > span h2 {
  color: white !important;
}
.pink-purple app-document-sign-massive .signer.desktop {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple app-document-sign-massive .signer .signer-btn {
  color: white !important;
}
.pink-purple app-document-sign-massive .signer .signer-btn[disabled] {
  background-color: rgba(0, 0, 0, 0.12);
}
.pink-purple app-document-detail app-sign-document .firmador button.mat-raised-button.mat-accent {
  color: white !important;
}
.pink-purple mat-toolbar-row.firmado-conforme .mat-icon {
  background: #8BC34A;
  color: white;
}
.pink-purple mat-toolbar-row.firmado-no-conforme .mat-icon {
  background: #f44336;
  color: white;
}
.pink-purple .secondary-toolbar {
  background: white;
}
.pink-purple .new-detail-container {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple .new-detail-container.cancelled .detail_head-body {
  background: #607D8B !important;
}
.pink-purple .new-detail-container .employee-detail-toolbar {
  background: #eeeeee;
}
.pink-purple .new-detail-container .employee-detail-subbar {
  background: white;
}
.pink-purple app-employee-detail .load-window,
.pink-purple app-employee-rrhh-detail .load-window {
  background: #fafafa;
}
.pink-purple app-employee-detail .employee_detail-container,
.pink-purple app-employee-rrhh-detail .employee_detail-container {
  background: #fafafa;
}
.pink-purple app-employee-detail .employee_detail-container.is-editing .edition-line,
.pink-purple app-employee-rrhh-detail .employee_detail-container.is-editing .edition-line {
  background: #eeeeee;
}
.pink-purple app-employee-detail .employee_detail-container .detail_head-body,
.pink-purple app-employee-rrhh-detail .employee_detail-container .detail_head-body {
  background: #e91e63;
}
.pink-purple app-employee-detail .employee_detail-container .detail_head-body .head_body-status .icon-stacked + mat-icon,
.pink-purple app-employee-rrhh-detail .employee_detail-container .detail_head-body .head_body-status .icon-stacked + mat-icon {
  color: white !important;
}
.pink-purple app-employee-detail .employee_detail-container .detail_head-body .head_body-title .body_title-name,
.pink-purple app-employee-rrhh-detail .employee_detail-container .detail_head-body .head_body-title .body_title-name {
  color: white;
}
.pink-purple app-employee-detail .employee_detail-container .detail_head-body .head_body-title .body_title-user,
.pink-purple app-employee-rrhh-detail .employee_detail-container .detail_head-body .head_body-title .body_title-user {
  color: white;
}
.pink-purple app-employee-detail .employee_detail-container .data .data-line,
.pink-purple app-employee-rrhh-detail .employee_detail-container .data .data-line {
  background: white;
}
.pink-purple app-employee-detail .employee_detail-container .data .data-line .icon-complex mat-icon.complex,
.pink-purple app-employee-rrhh-detail .employee_detail-container .data .data-line .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px white, 0 -2px 0px white, 2px 0 0px white, 0 2px 0px white, -2px -2px 0px white, 2px 2px 0px white, 2px -2px 0px white, -2px 2px 0px white;
}
.pink-purple app-employee-detail .employee_detail-container .data .data-line .line_text-main,
.pink-purple app-employee-rrhh-detail .employee_detail-container .data .data-line .line_text-main {
  color: #e91e63;
}
.pink-purple app-employee-detail .employee_detail-container .data .data-line.clickable:hover,
.pink-purple app-employee-rrhh-detail .employee_detail-container .data .data-line.clickable:hover {
  background: #fafafa !important;
}
.pink-purple app-employee-detail .employee_detail-container .data .data-line button.mat-icon-button,
.pink-purple app-employee-rrhh-detail .employee_detail-container .data .data-line button.mat-icon-button {
  background: #eeeeee;
}
.pink-purple app-employee-detail .employee_detail-container .employee-title .title-text,
.pink-purple app-employee-rrhh-detail .employee_detail-container .employee-title .title-text {
  color: #e91e63;
}
.pink-purple app-employee-detail .employee_detail-container .employee-title .title-text mat-icon,
.pink-purple app-employee-rrhh-detail .employee_detail-container .employee-title .title-text mat-icon {
  color: #e91e63;
}
.pink-purple app-employee-detail .employee_detail-container .employee-title .title-text .title_text-main,
.pink-purple app-employee-rrhh-detail .employee_detail-container .employee-title .title-text .title_text-main {
  color: #e91e63;
}
.pink-purple .UI-V2 .icon-complex mat-icon.complex {
  color: #e91e63;
  text-shadow: -2px 0 0px white, 0 -2px 0px white, 2px 0 0px white, 0 2px 0px white, -2px -2px 0px white, 2px 2px 0px white, 2px -2px 0px white, -2px 2px 0px white;
}
.pink-purple .UI-V2 .icon-complex mat-icon.complex.mat-accent {
  color: #9c27b0;
}
.pink-purple .UI-V2 .main .toolbar .header .content .module-title {
  color: #e91e63;
}
.pink-purple .UI-V2 .main .toolbar .header .content .module-title.selectable:hover {
  background: rgba(0, 0, 0, 0.04);
}
.pink-purple .UI-V2 .main .toolbar .header .content .module-title .info h5 {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-purple .UI-V2 .main .toolbar .header .content .ou-select {
  color: white;
  background: #e91e63;
}
.pink-purple .UI-V2 .main .toolbar .search-container .search .content {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple .UI-V2 .main .toolbar .search-container .search .content:hover, .pink-purple .UI-V2 .main .toolbar .search-container .search .content:active, .pink-purple .UI-V2 .main .toolbar .search-container .search .content:focus, .pink-purple .UI-V2 .main .toolbar .search-container .search .content:focus-within {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-purple .UI-V2 .main .toolbar .search-container .search .content input {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple .UI-V2 .main .helper {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple .UI-V2 .main .helper mat-hint h4 {
  color: #e91e63;
}
.pink-purple .UI-V2 .main .new-table app-cs-grid-control datatable-header * {
  color: #e91e63;
}
.pink-purple .UI-V2 .main .new-table .mat-header-row.sticky * {
  color: #e91e63 !important;
}
.pink-purple .UI-V2 .main .main-action {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple .menu-options.help .primary {
  color: #e91e63;
}
.pink-purple .menu-options.help .accent {
  color: #9c27b0;
}
.pink-purple .menu-options .icon-complex mat-icon.complex {
  color: #e91e63;
  text-shadow: -2px 0 0px white, 0 -2px 0px white, 2px 0 0px white, 0 2px 0px white, -2px -2px 0px white, 2px 2px 0px white, 2px -2px 0px white, -2px 2px 0px white;
}
.pink-purple .menu-options .mat-menu-content .container mat-list .mat-list-item:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-purple .menu-options .mat-menu-content .container mat-list .mat-list-item .disabled {
  color: rgba(0, 0, 0, 0.54) !important;
}
.pink-purple .menu-options .mat-menu-content .container .slide-group mat-radio-group mat-radio-button:hover {
  background: rgba(0, 0, 0, 0.04);
}
.pink-purple .menu-options .mat-menu-content .container .slide-group mat-form-field .mat-form-field-flex {
  background: #fafafa;
}
.pink-purple .menu-options .mat-menu-content .container .slide-group mat-slide-toggle:hover {
  background: rgba(0, 0, 0, 0.04);
}
.pink-purple .mat-menu-panel.notifications-panel .mat-menu-content .notifications-body {
  background: #fafafa;
}
.pink-purple .mat-menu-panel.notifications-panel .mat-menu-content .notification .primary mat-icon {
  color: #e91e63;
}
.pink-purple .mat-menu-panel.notifications-panel .mat-menu-content .notification .primary h3 {
  color: #e91e63;
}
.pink-purple .mat-menu-panel.notifications-panel .mat-menu-content .notification.top {
  background: #fafafa;
}
.pink-purple .mat-menu-panel.notifications-panel .mat-menu-content .notification.top .header h3 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple .mat-menu-panel.notifications-panel .mat-menu-content .notification.bottom {
  background: #fafafa;
}
.pink-purple .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable:nth-child(even) {
  background: #fafafa;
}
.pink-purple .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable:nth-child(odd) {
  background: white;
}
.pink-purple .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable:hover {
  background: rgba(0, 0, 0, 0.04);
}
.pink-purple .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable mat-icon {
  color: #e91e63;
}
.pink-purple .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable h3 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable h5 {
  color: rgba(0, 0, 0, 0.38);
}
.pink-purple .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable .subtitle h5 {
  color: #e91e63;
  background: #eeeeee;
}
.pink-purple .info-empty .large-icon {
  background: #f8bbd0;
  color: #e91e63;
}
.pink-purple .info-empty h2,
.pink-purple .info-empty h3 {
  color: #e91e63;
}
.pink-purple .info-empty h4,
.pink-purple .info-empty h5 {
  color: rgba(0, 0, 0, 0.54);
}
.pink-purple .info-empty.accent mat-icon {
  background: #e1bee7;
  color: #9c27b0;
}
.pink-purple .info-empty.accent h2 {
  color: #9c27b0;
}
.pink-purple .info-empty .actions {
  padding: 16px;
  height: 96px;
  column-gap: 16px;
}
.pink-purple .info-empty .actions button {
  height: 100%;
  border-radius: 40px;
}
.pink-purple .info-empty .actions button.ok {
  background: #009688 !important;
  color: white;
}
.pink-purple .info-empty .actions button.not-ok {
  color: #9C27B0 !important;
}
.pink-purple .side.edition .info-empty .large-icon {
  background: #e1bee7;
  color: #9c27b0;
}
.pink-purple .side.edition .info-empty h2 {
  color: #9c27b0;
}
.pink-purple .side.edition .info-empty h4 {
  color: rgba(0, 0, 0, 0.54);
}
.pink-purple .UI-V2 .deferred-process-list .header h3 {
  color: #e91e63;
}
.pink-purple .UI-V2 .deferred-process-list .search-container .search .content {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple .UI-V2 .deferred-process-list .search-container .search .content:hover, .pink-purple .UI-V2 .deferred-process-list .search-container .search .content:active, .pink-purple .UI-V2 .deferred-process-list .search-container .search .content:focus, .pink-purple .UI-V2 .deferred-process-list .search-container .search .content:focus-within {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-purple .UI-V2 .deferred-process-list .search-container .search .content input {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple .UI-V2 .deferred-process-list .text-success,
.pink-purple .UI-V2 .deferred-process-details .text-success {
  color: #8BC34A;
}
.pink-purple .UI-V2 .deferred-process-list .text-warn,
.pink-purple .UI-V2 .deferred-process-details .text-warn {
  color: #f44336;
}
.pink-purple .UI-V2 .deferred-process-list .text-primary,
.pink-purple .UI-V2 .deferred-process-details .text-primary {
  color: #e91e63;
}
.pink-purple .UI-V2 .deferred-process-list .text-atention,
.pink-purple .UI-V2 .deferred-process-details .text-atention {
  color: #DB7703;
}
.pink-purple .UI-V2 .deferred-process-list .table-paginator-container,
.pink-purple .UI-V2 .deferred-process-details .table-paginator-container {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
}
.pink-purple .UI-V2 .deferred-process-list .table-paginator-container .mat-table,
.pink-purple .UI-V2 .deferred-process-details .table-paginator-container .mat-table {
  border-bottom: none;
  border-radius: 10px 10px 0 0;
}
.pink-purple .UI-V2 .deferred-process-list .table-paginator-container .paginator-container,
.pink-purple .UI-V2 .deferred-process-details .table-paginator-container .paginator-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: #f2f2f2;
  padding: 0 1.5rem;
  height: 3rem;
}
.pink-purple .UI-V2 .deferred-process-list .mat-table,
.pink-purple .UI-V2 .deferred-process-details .mat-table {
  border: 1px solid #f2f2f2;
  border-radius: 10px;
  overflow: hidden;
}
.pink-purple .UI-V2 .deferred-process-list .mat-table .mat-header-row .mat-header-cell,
.pink-purple .UI-V2 .deferred-process-details .mat-table .mat-header-row .mat-header-cell {
  font-size: 14px;
  font-weight: 700;
}
.pink-purple .UI-V2 .deferred-process-list .mat-table .mat-header-row,
.pink-purple .UI-V2 .deferred-process-details .mat-table .mat-header-row {
  background: #f2f2f2;
}
.pink-purple .UI-V2 .deferred-process-list .mat-table .mat-header-row .mat-header-cell,
.pink-purple .UI-V2 .deferred-process-details .mat-table .mat-header-row .mat-header-cell {
  color: #e91e63;
}
.pink-purple .UI-V2 .deferred-process-list .mat-table .mat-row,
.pink-purple .UI-V2 .deferred-process-details .mat-table .mat-row {
  background: white;
}
.pink-purple .UI-V2 .progress-bar-container {
  position: relative;
}
.pink-purple .UI-V2 .deferred-process-details .header h3 {
  color: #e91e63;
}
.pink-purple .UI-V2 .deferred-process-details .header .back-link:visited {
  color: #e91e63;
}
.pink-purple .process-card,
.pink-purple .process-card-detail,
.pink-purple .process-card-stages {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple .process-card mat-card-subtitle,
.pink-purple .process-card-detail mat-card-subtitle,
.pink-purple .process-card-stages mat-card-subtitle {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple .process-card .stages-container .stage.completed mat-icon,
.pink-purple .process-card-detail .stages-container .stage.completed mat-icon,
.pink-purple .process-card-stages .stages-container .stage.completed mat-icon {
  color: #499719;
}
.pink-purple .process-card .stages-container .stage.completed .icon-container,
.pink-purple .process-card-detail .stages-container .stage.completed .icon-container,
.pink-purple .process-card-stages .stages-container .stage.completed .icon-container {
  background-color: rgba(78, 160, 28, 0.1490196078);
}
.pink-purple .process-card .stages-container .stage.completed .line,
.pink-purple .process-card-detail .stages-container .stage.completed .line,
.pink-purple .process-card-stages .stages-container .stage.completed .line {
  background-color: #499719;
}
.pink-purple .process-card .stages-container .stage.on-going mat-icon,
.pink-purple .process-card-detail .stages-container .stage.on-going mat-icon,
.pink-purple .process-card-stages .stages-container .stage.on-going mat-icon {
  color: #e91e63;
}
.pink-purple .process-card .stages-container .stage.on-going .icon-container,
.pink-purple .process-card-detail .stages-container .stage.on-going .icon-container,
.pink-purple .process-card-stages .stages-container .stage.on-going .icon-container {
  background-color: rgba(66, 48, 159, 0.2);
}
.pink-purple .process-card .stages-container .stage.on-going .line,
.pink-purple .process-card-detail .stages-container .stage.on-going .line,
.pink-purple .process-card-stages .stages-container .stage.on-going .line {
  background-color: #949494;
}
.pink-purple .process-card .stages-container .stage.pending,
.pink-purple .process-card-detail .stages-container .stage.pending,
.pink-purple .process-card-stages .stages-container .stage.pending {
  color: #949494;
}
.pink-purple .process-card .stages-container .stage.pending mat-icon,
.pink-purple .process-card-detail .stages-container .stage.pending mat-icon,
.pink-purple .process-card-stages .stages-container .stage.pending mat-icon {
  color: #949494;
}
.pink-purple .process-card .stages-container .stage.pending .icon-container,
.pink-purple .process-card-detail .stages-container .stage.pending .icon-container,
.pink-purple .process-card-stages .stages-container .stage.pending .icon-container {
  background-color: rgba(174, 174, 174, 0.2);
}
.pink-purple .process-card .stages-container .stage.pending .line,
.pink-purple .process-card-detail .stages-container .stage.pending .line,
.pink-purple .process-card-stages .stages-container .stage.pending .line {
  background-color: #949494;
}
.pink-purple .process-card .stages-container .stage.has-error mat-icon,
.pink-purple .process-card-detail .stages-container .stage.has-error mat-icon,
.pink-purple .process-card-stages .stages-container .stage.has-error mat-icon {
  color: #f44336;
}
.pink-purple .process-card .stages-container .stage.has-error .icon-container,
.pink-purple .process-card-detail .stages-container .stage.has-error .icon-container,
.pink-purple .process-card-stages .stages-container .stage.has-error .icon-container {
  background-color: rgba(188, 4, 4, 0.2);
}
.pink-purple .process-card .stages-container .stage-description .button-link,
.pink-purple .process-card-detail .stages-container .stage-description .button-link,
.pink-purple .process-card-stages .stages-container .stage-description .button-link {
  color: #e91e63;
}
.pink-purple app-custom-search-input .custom-search-container {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
  transition: color 0.1s ease, background 0.15s ease;
}
.pink-purple app-custom-search-input .custom-search-container mat-icon.mat-icon {
  color: rgba(0, 0, 0, 0.38);
}
.pink-purple app-custom-search-input .custom-search-container input.custom-search-input {
  color: rgba(0, 0, 0, 0.87);
  background: unset;
}
.pink-purple app-custom-search-input .custom-search-container:hover, .pink-purple app-custom-search-input .custom-search-container:active, .pink-purple app-custom-search-input .custom-search-container:focus, .pink-purple app-custom-search-input .custom-search-container:focus-within {
  background: rgba(0, 0, 0, 0.04) !important;
  transition: background 0.15s ease;
}
.pink-purple .UI-V2 .audit-filter {
  background: white;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple .UI-V2 .audit-filter .toolbar {
  background: #e91e63;
  color: white;
}
.pink-purple .UI-V2 .audit-filter .toolbar .header .content .module-title {
  color: #e91e63;
}
.pink-purple .UI-V2 .audit-filter .toolbar .header .content .module-title.selectable:hover {
  background: rgba(0, 0, 0, 0.04);
}
.pink-purple .UI-V2 .audit-filter .toolbar .header .content .module-title .info * {
  color: white !important;
}
.pink-purple .UI-V2 .audit-filter .toolbar .header .content .ou-select {
  color: white;
  background: #e91e63;
}
.pink-purple .UI-V2 .audit-filter .toolbar .search-container .search .content {
  background: #fafafa;
}
.pink-purple .UI-V2 .audit-filter .toolbar .search-container .search .content:hover, .pink-purple .UI-V2 .audit-filter .toolbar .search-container .search .content:active, .pink-purple .UI-V2 .audit-filter .toolbar .search-container .search .content:focus, .pink-purple .UI-V2 .audit-filter .toolbar .search-container .search .content:focus-within {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-purple .UI-V2 .audit-filter .toolbar .search-container .search .content input {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple .UI-V2 .audit-filter .filter-container {
  background: white !important;
}
.pink-purple .UI-V2 .audit-filter .filter-container .form-body {
  background: #fafafa !important;
}
.pink-purple .UI-V2 .audit-filter .filter-container .form-body .line h4 {
  color: #e91e63;
}
.pink-purple .UI-V2 .audit-filter .filter-container .form-body .line mat-icon {
  color: #e91e63;
}
.pink-purple .UI-V2 .audit-result .result-container .toolbar {
  color: white;
}
.pink-purple .UI-V2 .audit-result .result-container .toolbar .header .content .module-title {
  color: #e91e63;
}
.pink-purple .UI-V2 .audit-result .result-container .toolbar .header .content .module-title.selectable:hover {
  background: rgba(0, 0, 0, 0.04);
}
.pink-purple .UI-V2 .audit-result .result-container .toolbar .header .content .module-title .info h5 {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-purple .UI-V2 .audit-result .result-container .toolbar .header .content .ou-select {
  color: white;
  background: #e91e63;
}
.pink-purple .UI-V2 .audit-result .result-container .toolbar .search-container .search .content {
  background: #fafafa;
}
.pink-purple .UI-V2 .audit-result .result-container .toolbar .search-container .search .content:hover, .pink-purple .UI-V2 .audit-result .result-container .toolbar .search-container .search .content:active, .pink-purple .UI-V2 .audit-result .result-container .toolbar .search-container .search .content:focus, .pink-purple .UI-V2 .audit-result .result-container .toolbar .search-container .search .content:focus-within {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-purple .UI-V2 .audit-result .result-container .toolbar .search-container .search .content input {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple .UI-V2 .audit-result .result-container .new-table app-cs-grid-control datatable-header * {
  color: #e91e63;
}
.pink-purple .UI-V2 .audit-result .result-container .new-table .mat-header-row.sticky * {
  color: #e91e63 !important;
}
.pink-purple .UI-V2 .audit-detail-container {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  background: #eeeeee !important;
}
.pink-purple .UI-V2 .audit-detail-container .side .toolbar .header {
  color: #e91e63;
}
.pink-purple .UI-V2 .audit-detail-container .side .container .mat-list-base {
  background: #fafafa !important;
}
.pink-purple .UI-V2 .audit-detail-container .side .container .mat-list-base mat-list-item .mat-line b {
  color: #e91e63;
}
.pink-purple .UI-V2 .inbox-container {
  background: white;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple .UI-V2 .inbox-container *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.pink-purple .UI-V2 .inbox-container *::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}
.pink-purple .UI-V2 .inbox-container *::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}
.pink-purple .UI-V2 .inbox-container .load-window {
  background: white;
}
.pink-purple .UI-V2 .inbox-container .toolbar {
  background: #e91e63;
  color: white;
}
.pink-purple .UI-V2 .inbox-container .toolbar.reverse {
  background: white;
  color: rgba(0, 0, 0, 0.54);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel {
  background: #eeeeee !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel.mat-expanded {
  background: #fafafa !important;
}
.pink-purple .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel.mat-expanded mat-expansion-panel-header {
  background: #fafafa !important;
}
.pink-purple .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel.mat-expanded mat-expansion-panel-header:hover {
  background: none !important;
}
.pink-purple .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel mat-expansion-panel-header {
  background: #fafafa !important;
}
.pink-purple .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel mat-expansion-panel-header:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-purple .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel mat-expansion-panel-header .signature-inbox-header .mat-icon,
.pink-purple .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel mat-expansion-panel-header .signature-inbox-header b {
  color: #9c27b0;
}
.pink-purple .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel .mat-expansion-panel-body .item {
  background: white;
  color: rgba(0, 0, 0, 0.54);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel .mat-expansion-panel-body .item:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-purple .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel .mat-expansion-panel-body .item.active {
  border-left: 6px solid #9c27b0;
}
.pink-purple .UI-V2 .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.pink-purple .UI-V2 .work-space .main .load-window {
  background: white !important;
}
.pink-purple .UI-V2 .work-space .main-action {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple mat-radio-button.disabled .mat-radio-label-content {
  color: #9c27b0;
}
.pink-purple .detail app-automatic-process-detail app-chapa.minimize,
.pink-purple .detail app-process-detail app-chapa.minimize {
  background: #FFD54F !important;
}
.pink-purple .detail app-automatic-process-detail app-chapa.minimize.purged,
.pink-purple .detail app-process-detail app-chapa.minimize.purged {
  background: #b2adb0 !important;
}
.pink-purple .detail .detail-toolbar {
  background: #eeeeee;
}
.pink-purple .detail .processes-status .status-bar .bar-graph {
  background-color: #FFD54F;
}
.pink-purple .detail .processes-status .status-bar .bar-graph .graph-ok {
  background-color: #4DB6AC;
}
.pink-purple .detail .processes-status .status-bar .bar-graph .graph-not-ok {
  background-color: #F48FB1;
}
.pink-purple .detail .processes-status .status-resume b {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple .detail .processes-status .status-resume .resume-total b {
  background-color: #FFD54F;
}
.pink-purple .detail .processes-status .status-resume .resume-ok b {
  background-color: #4DB6AC;
}
.pink-purple .detail .processes-status .status-resume .resume-not-ok b {
  background-color: #F48FB1;
}
.pink-purple .detail .detail-toolbar .mat-avatar h3,
.pink-purple .detail .detail-toolbar .mat-avatar h5 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple .detail .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple .detail .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple .detail .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple .detail .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple .detail .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  color: white;
}
.pink-purple .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  background: #4DB6AC !important;
}
.pink-purple .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  background: #b2dfdb !important;
}
.pink-purple .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  background: #b2dfdb !important;
}
.pink-purple .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  background: #4DB6AC !important;
}
.pink-purple .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  background: #b2dfdb !important;
}
.pink-purple .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  background: #837C80 !important;
}
.pink-purple .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  background: #b2adb0 !important;
}
.pink-purple .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  background: #b2adb0 !important;
}
.pink-purple .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  background: #837C80 !important;
}
.pink-purple .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  background: #b2dfdb !important;
}
.pink-purple .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  background: #FFD54F !important;
}
.pink-purple .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  background: #ffecb3 !important;
}
.pink-purple .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  background: #ffecb3 !important;
}
.pink-purple .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  background: #FFD54F !important;
}
.pink-purple .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  background: #ffecb3 !important;
}
.pink-purple .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  background: #F48FB1 !important;
}
.pink-purple .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  background: #f8bbd0 !important;
}
.pink-purple .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  background: #f8bbd0 !important;
}
.pink-purple .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  background: #F48FB1 !important;
}
.pink-purple .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  background: #f8bbd0 !important;
}
.pink-purple app-analytics-document-set .sets-container .mat-list-base.sets-list mat-list-item {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple app-analytics-document-set .sets-container .mat-list-base.sets-list mat-list-item .mat-list-item-content .set-status .value-percentile .undone-value {
  background: #673AB7;
}
.pink-purple app-analytics-document-set .sets-container .mat-list-base.sets-list mat-list-item .mat-list-item-content .set-status .value-percentile .not-value {
  background: #eeeeee;
}
.pink-purple app-analytics-document-set .sets-container .mat-list-base.sets-list mat-list-item .mat-list-item-content .set-status .value-percentile .done-value {
  background: #009688;
}
.pink-purple app-analytics-document-set .work-space .main .toolbar .header .content .module-title .info .info-text .set-status {
  cursor: help;
}
.pink-purple app-analytics-document-set .work-space .main .toolbar .header .content .module-title .info .info-text .set-status .value-percentile .undone-value {
  background: #673AB7;
}
.pink-purple app-analytics-document-set .work-space .main .toolbar .header .content .module-title .info .info-text .set-status .value-percentile .not-value {
  background: #eeeeee;
}
.pink-purple app-analytics-document-set .work-space .main .toolbar .header .content .module-title .info .info-text .set-status .value-percentile .done-value {
  background: #009688;
}
.pink-purple app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar .mat-progress-bar-buffer {
  background-color: #eeeeee;
}
.pink-purple app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar.class1 .mat-progress-bar-fill::after {
  background-color: #f44336;
}
.pink-purple app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar.class2 .mat-progress-bar-fill::after {
  background-color: #ff9800;
}
.pink-purple app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar.class3 .mat-progress-bar-fill::after {
  background-color: #ffeb3b;
}
.pink-purple app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar.class4 .mat-progress-bar-fill::after {
  background-color: #8bc34a;
}
.pink-purple app-analytics-document-set .work-space .side .container mat-list {
  background: white !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple app-analytics-document-set .work-space .side .container mat-list mat-list-item .mat-line b {
  color: #e91e63;
}
.pink-purple .catcher {
  background: rgba(0, 0, 0, 0.6);
}
.pink-purple .catcher.clear {
  background: rgba(0, 0, 0, 0);
}
.pink-purple .add-menu-form-body .mat-radio-group .mat-radio-button:hover {
  background: rgba(0, 0, 0, 0.04);
}
.pink-purple .add-menu-form-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple .add-menu-form-body.w-table .helper .search-bar {
  background: #fafafa;
}
.pink-purple .add-menu-form-body.w-table .helper .search-bar:hover, .pink-purple .add-menu-form-body.w-table .helper .search-bar:active, .pink-purple .add-menu-form-body.w-table .helper .search-bar:focus, .pink-purple .add-menu-form-body.w-table .helper .search-bar:focus-within {
  background: #fafafa !important;
}
.pink-purple .add-menu-form-body.w-table .helper .search-bar input {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple .add-menu-form-actions {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple .file-upload-list mat-list-item {
  background: white;
}
.pink-purple .mat-horizontal-stepper-content[aria-expanded=true] app-file-document-view {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple .mat-horizontal-stepper-content[aria-expanded=true] app-file-document-view .pdf-view-container {
  background: #eeeeee;
}
.pink-purple .add-docs-drop .docs-drop-preview app-file-document-detail {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple .add-docs-drop .docs-drop-preview app-file-document-detail .view-toolbar {
  background: white;
}
.pink-purple .add-docs-drop .load-window.message h3.primary-text {
  color: #e91e63;
}
.pink-purple mat-dialog-container app-add-menu-dialog .load-window.message h3.primary-text {
  color: #e91e63;
}
.pink-purple .asisted-input.aligned-icon.subtitle * {
  color: rgba(0, 0, 0, 0.38) !important;
}
.pink-purple .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble {
  background: #e91e63;
}
.pink-purple .asisted-input .mat-form-field-invalid + .indicator > .bubble {
  background: #f44336;
}
.pink-purple .asisted-input .mat-form-field-disabled + .indicator > .bubble {
  color: rgba(0, 0, 0, 0.38);
  background: rgba(0, 0, 0, 0.12);
}
.pink-purple .asisted-input .indicator .bubble {
  background: rgba(0, 0, 0, 0.38);
  color: white;
}
.pink-purple .asisted-input .indicator .guide {
  border-color: rgba(0, 0, 0, 0.12);
}
.pink-purple .asisted-input.icon-only .bubble {
  color: rgba(0, 0, 0, 0.54);
}
.pink-purple .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble {
  color: #e91e63;
}
.pink-purple .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble {
  color: #f44336;
}
.pink-purple .UI-V2 .main-overlay {
  background: rgba(0, 0, 0, 0.32);
}
.pink-purple .UI-V2 .icon-complex mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.pink-purple .UI-V2 .icon-complex mat-icon.complex {
  color: #e91e63 !important;
}
.pink-purple .UI-V2 .navigation {
  background: #fafafa;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple .UI-V2 .navigation *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.pink-purple .UI-V2 .navigation *::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}
.pink-purple .UI-V2 .navigation .load-window {
  background: #eeeeee;
}
.pink-purple .UI-V2 .navigation .toolbar {
  background: #e91e63;
  color: white;
}
.pink-purple .UI-V2 .navigation .toolbar.reverse {
  background: white;
  color: rgba(0, 0, 0, 0.54);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple .UI-V2 .navigation .container .helper {
  color: rgba(0, 0, 0, 0.54);
}
.pink-purple .UI-V2 .navigation .container .item {
  background: white;
  color: rgba(0, 0, 0, 0.54);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple .UI-V2 .navigation .container .item:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-purple .UI-V2 .navigation .container .item.active {
  background: #e91e63 !important;
  color: white;
  border: 1px solid #e91e63;
}
.pink-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .separator .subtitle,
.pink-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .separator .subtitle,
.pink-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .separator .subtitle,
.pink-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .separator .subtitle,
.pink-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .separator .subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.pink-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .img-editior-container .img-editor .separator .subtitle,
.pink-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .img-editior-container .img-editor .separator .subtitle,
.pink-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .img-editior-container .img-editor .separator .subtitle,
.pink-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .img-editior-container .img-editor .separator .subtitle,
.pink-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .img-editior-container .img-editor .separator .subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.pink-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme,
.pink-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme,
.pink-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme,
.pink-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme,
.pink-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .main-color-container .main-color,
.pink-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .main-color-container .main-color,
.pink-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .main-color-container .main-color,
.pink-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .main-color-container .main-color,
.pink-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .main-color-container .main-color {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .main-color-container .main-color:before,
.pink-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .main-color-container .main-color:before,
.pink-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .main-color-container .main-color:before,
.pink-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .main-color-container .main-color:before,
.pink-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .main-color-container .main-color:before {
  border-left: 10px solid rgba(0, 0, 0, 0);
  border-right: 10px solid rgba(0, 0, 0, 0);
}
.pink-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example h6.required,
.pink-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example h6.required,
.pink-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example h6.required,
.pink-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example h6.required,
.pink-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example h6.required {
  color: #f44336 !important;
}
.pink-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle,
.pink-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle,
.pink-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle,
.pink-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle,
.pink-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle {
  border: 2px solid rgba(0, 0, 0, 0);
}
.pink-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .side-color-container .side-color,
.pink-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .side-color-container .side-color,
.pink-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .side-color-container .side-color,
.pink-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .side-color-container .side-color,
.pink-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .side-color-container .side-color {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .side-color-container .side-color:before,
.pink-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .side-color-container .side-color:before,
.pink-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .side-color-container .side-color:before,
.pink-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .side-color-container .side-color:before,
.pink-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .side-color-container .side-color:before {
  border-top: 10px solid rgba(0, 0, 0, 0);
  border-bottom: 10px solid rgba(0, 0, 0, 0);
}
.pink-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .side-color-container .side-color h6.required,
.pink-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .side-color-container .side-color h6.required,
.pink-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .side-color-container .side-color h6.required,
.pink-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .side-color-container .side-color h6.required,
.pink-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .side-color-container .side-color h6.required {
  color: #f44336 !important;
}
.pink-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .permission-settings-cards .card,
.pink-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .permission-settings-cards .card,
.pink-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .permission-settings-cards .card,
.pink-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .permission-settings-cards .card,
.pink-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .permission-settings-cards .card {
  background: #fafafa;
}
.pink-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .permission-settings-cards .card .slide-sub-group,
.pink-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .permission-settings-cards .card .slide-sub-group,
.pink-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .permission-settings-cards .card .slide-sub-group,
.pink-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .permission-settings-cards .card .slide-sub-group,
.pink-purple .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .permission-settings-cards .card .slide-sub-group {
  background: rgba(0, 0, 0, 0.12);
}
.pink-purple .UI-V2 .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.pink-purple .UI-V2 .work-space .main .load-window {
  background: white !important;
}
.pink-purple .UI-V2 .work-space .main.side-open {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item mat-icon.complex {
  text-shadow: -2px 0 0px #eeeeee, 0 -2px 0px #eeeeee, 2px 0 0px #eeeeee, 0 2px 0px #eeeeee, -2px -2px 0px #eeeeee, 2px 2px 0px #eeeeee, 2px -2px 0px #eeeeee, -2px 2px 0px #eeeeee !important;
}
.pink-purple .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item h5 {
  color: rgba(0, 0, 0, 0.38);
}
.pink-purple .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item h3 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item .actions {
  pointer-events: none;
}
.pink-purple .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item .actions button {
  pointer-events: all;
}
.pink-purple .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled {
  background: #eeeeee;
}
.pink-purple .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled h3 {
  color: #607D8B !important;
}
.pink-purple .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled mat-icon {
  color: #607D8B !important;
}
.pink-purple .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled.selected {
  background: #607D8B;
  color: white !important;
}
.pink-purple .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled.selected h3 {
  color: white !important;
}
.pink-purple .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled.selected mat-icon {
  color: white !important;
}
.pink-purple .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled.selected mat-icon.complex {
  color: white !important;
  text-shadow: -2px 0 0px #607D8B, 0 -2px 0px #607D8B, 2px 0 0px #607D8B, 0 2px 0px #607D8B, -2px -2px 0px #607D8B, 2px 2px 0px #607D8B, 2px -2px 0px #607D8B, -2px 2px 0px #607D8B !important;
}
.pink-purple .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item:hover {
  background: rgba(0, 0, 0, 0.04);
}
.pink-purple .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected {
  background: #e91e63;
  color: white;
}
.pink-purple .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected mat-icon {
  color: white;
}
.pink-purple .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected mat-icon.complex {
  color: white !important;
  text-shadow: -2px 0 0px #e91e63, 0 -2px 0px #e91e63, 2px 0 0px #e91e63, 0 2px 0px #e91e63, -2px -2px 0px #e91e63, 2px 2px 0px #e91e63, 2px -2px 0px #e91e63, -2px 2px 0px #e91e63 !important;
}
.pink-purple .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected h5,
.pink-purple .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected h3 {
  color: white;
}
.pink-purple .UI-V2 .work-space .main .main-action {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple .UI-V2 .work-space .side {
  background: #fafafa;
}
.pink-purple .UI-V2 .work-space .side .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #fafafa, 0 -2px 0px #fafafa, 2px 0 0px #fafafa, 0 2px 0px #fafafa, -2px -2px 0px #fafafa, 2px 2px 0px #fafafa, 2px -2px 0px #fafafa, -2px 2px 0px #fafafa;
}
.pink-purple .UI-V2 .work-space .side *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.pink-purple .UI-V2 .work-space .side *::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}
.pink-purple .UI-V2 .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #fafafa !important;
}
.pink-purple .UI-V2 .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(0, 0, 0, 0.38);
}
.pink-purple .UI-V2 .work-space .side mat-form-field .mat-form-field-flex {
  background: #fafafa;
}
.pink-purple .UI-V2 .work-space .side mat-checkbox:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-purple .UI-V2 .work-space .side .hint.all {
  color: #9c27b0;
}
.pink-purple .UI-V2 .work-space .side .toolbar .header {
  color: #e91e63;
}
.pink-purple .UI-V2 .work-space .side .toolbar .header h3 {
  color: #e91e63;
}
.pink-purple .UI-V2 .work-space .side .container .role-resume {
  background: #e91e63;
}
.pink-purple .UI-V2 .work-space .side .container .role-resume .line {
  color: white;
}
.pink-purple .UI-V2 .work-space .side .container .role-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.1);
}
.pink-purple .UI-V2 .work-space .side .container .role-resume .line.editable button {
  background: #e91e63;
}
.pink-purple .UI-V2 .work-space .side .container .section-title {
  color: #e91e63;
}
.pink-purple .UI-V2 .work-space .side .container .section-resume {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple .UI-V2 .work-space .side .container .section-resume .line.editable:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-purple .UI-V2 .work-space .side .container .section-resume .line.editable:hover .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px white, 0 -2px 0px white, 2px 0 0px white, 0 2px 0px white, -2px -2px 0px white, 2px 2px 0px white, 2px -2px 0px white, -2px 2px 0px white !important;
}
.pink-purple .UI-V2 .work-space .side .container .section-resume .line.editable button {
  background: #fafafa;
}
.pink-purple .UI-V2 .work-space .side .container .section-resume .line .header .icon-complex mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.pink-purple .UI-V2 .work-space .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #e91e63 !important;
}
.pink-purple .UI-V2 .work-space .side .container .section-resume .line .header mat-icon:not(.complex) {
  color: rgba(0, 0, 0, 0.54);
}
.pink-purple .UI-V2 .work-space .side .container .section-resume .line .header .info h4 {
  color: #e91e63;
}
.pink-purple .UI-V2 .work-space .side .container .section-resume .line ul {
  color: #e91e63;
}
.pink-purple .UI-V2 .work-space .side .container .section-resume .line.head {
  background: #eeeeee;
}
.pink-purple .UI-V2 .work-space .side .container .section-resume .line.head mat-icon {
  color: #9c27b0;
}
.pink-purple .UI-V2 .work-space .side .container .section-resume .line.head h4 {
  color: white;
}
.pink-purple .UI-V2 .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body {
  background: white;
}
.pink-purple .UI-V2 .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: rgba(0, 0, 0, 0.54);
}
.pink-purple .UI-V2 .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #e91e63;
}
.pink-purple .UI-V2 .work-space .side.edition .toolbar .header {
  color: white;
}
.pink-purple .UI-V2 .work-space .side.edition .toolbar .header mat-icon {
  color: #9c27b0;
}
.pink-purple .UI-V2 .work-space .side.edition .toolbar .header h3 {
  color: #9c27b0;
}
.pink-purple .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body {
  background: white !important;
}
.pink-purple .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header mat-icon:not(.complex) {
  color: #e91e63;
}
.pink-purple .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header h4 {
  color: #e91e63;
}
.pink-purple .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-actions {
  background: #fafafa;
}
.pink-purple .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.pink-purple .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header .icon-complex mat-icon.complex {
  color: #9c27b0 !important;
}
.pink-purple .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header h4 {
  color: #9c27b0;
}
.pink-purple .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line ul {
  color: #9c27b0;
  font-weight: 400;
}
.pink-purple .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head mat-icon {
  color: #9c27b0 !important;
}
.pink-purple .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head h4 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .section-title {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-purple .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume h6.hint {
  background: #e1bee7;
}
.pink-purple app-role-user-find .main-overlay {
  background: rgba(0, 0, 0, 0.32);
}
.pink-purple app-role-user-find .icon-complex mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.pink-purple app-role-user-find .icon-complex mat-icon.complex {
  color: #e91e63 !important;
}
.pink-purple app-role-user-find .navigation {
  background: #fafafa;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple app-role-user-find .navigation *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.pink-purple app-role-user-find .navigation *::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}
.pink-purple app-role-user-find .navigation .load-window {
  background: #eeeeee;
}
.pink-purple app-role-user-find .navigation .toolbar {
  background: #e91e63;
  color: white;
}
.pink-purple app-role-user-find .navigation .toolbar.reverse {
  background: white;
  color: rgba(0, 0, 0, 0.54);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple app-role-user-find .navigation .container .helper {
  color: rgba(0, 0, 0, 0.54);
}
.pink-purple app-role-user-find .navigation .container .item {
  background: white;
  color: rgba(0, 0, 0, 0.54);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple app-role-user-find .navigation .container .item:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-purple app-role-user-find .navigation .container .item.active {
  background: #e91e63 !important;
  color: white;
  border: 1px solid #e91e63;
}
.pink-purple app-role-user-find .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.pink-purple app-role-user-find .work-space .main .load-window {
  background: white !important;
}
.pink-purple app-role-user-find .work-space .main.side-open {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple app-role-user-find .work-space .main .container .item {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple app-role-user-find .work-space .main .container .item .subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.pink-purple app-role-user-find .work-space .main .container .item.disabled {
  background: #eeeeee;
  color: #607D8B !important;
}
.pink-purple app-role-user-find .work-space .main .container .item.disabled h3 {
  color: #607D8B !important;
}
.pink-purple app-role-user-find .work-space .main .container .item.disabled mat-icon {
  color: #607D8B !important;
}
.pink-purple app-role-user-find .work-space .main .container .item.disabled.selected {
  background: #607D8B;
  color: white !important;
}
.pink-purple app-role-user-find .work-space .main .container .item.disabled.selected h3 {
  color: white !important;
}
.pink-purple app-role-user-find .work-space .main .container .item.disabled.selected mat-icon {
  color: white !important;
}
.pink-purple app-role-user-find .work-space .main .container .item.disabled.selected mat-icon.complex {
  color: white !important;
  text-shadow: -2px 0 0px #607D8B, 0 -2px 0px #607D8B, 2px 0 0px #607D8B, 0 2px 0px #607D8B, -2px -2px 0px #607D8B, 2px 2px 0px #607D8B, 2px -2px 0px #607D8B, -2px 2px 0px #607D8B !important;
}
.pink-purple app-role-user-find .work-space .main .container .item mat-icon.complex {
  text-shadow: -2px 0 0px #eeeeee, 0 -2px 0px #eeeeee, 2px 0 0px #eeeeee, 0 2px 0px #eeeeee, -2px -2px 0px #eeeeee, 2px 2px 0px #eeeeee, 2px -2px 0px #eeeeee, -2px 2px 0px #eeeeee !important;
}
.pink-purple app-role-user-find .work-space .main .container .item h5 {
  color: rgba(0, 0, 0, 0.38);
}
.pink-purple app-role-user-find .work-space .main .container .item h3 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple app-role-user-find .work-space .main .container .item:hover {
  background: rgba(0, 0, 0, 0.04);
}
.pink-purple app-role-user-find .work-space .main .container .item.selected {
  background: #e91e63;
  color: white;
}
.pink-purple app-role-user-find .work-space .main .container .item.selected mat-icon {
  color: white;
}
.pink-purple app-role-user-find .work-space .main .container .item.selected mat-icon.complex {
  color: white !important;
  text-shadow: -2px 0 0px #e91e63, 0 -2px 0px #e91e63, 2px 0 0px #e91e63, 0 2px 0px #e91e63, -2px -2px 0px #e91e63, 2px 2px 0px #e91e63, 2px -2px 0px #e91e63, -2px 2px 0px #e91e63 !important;
}
.pink-purple app-role-user-find .work-space .main .container .item.selected h5,
.pink-purple app-role-user-find .work-space .main .container .item.selected h3 {
  color: white;
}
.pink-purple app-role-user-find .work-space .main .main-action {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple app-role-user-find .work-space .side {
  background: #fafafa;
}
.pink-purple app-role-user-find .work-space .side .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #fafafa, 0 -2px 0px #fafafa, 2px 0 0px #fafafa, 0 2px 0px #fafafa, -2px -2px 0px #fafafa, 2px 2px 0px #fafafa, 2px -2px 0px #fafafa, -2px 2px 0px #fafafa;
}
.pink-purple app-role-user-find .work-space .side *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.pink-purple app-role-user-find .work-space .side *::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}
.pink-purple app-role-user-find .work-space .side .edition-line {
  background: #eeeeee;
}
.pink-purple app-role-user-find .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #fafafa !important;
}
.pink-purple app-role-user-find .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(0, 0, 0, 0.38);
}
.pink-purple app-role-user-find .work-space .side mat-form-field .mat-form-field-flex {
  background: #fafafa;
}
.pink-purple app-role-user-find .work-space .side mat-checkbox:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-purple app-role-user-find .work-space .side .hint.all {
  color: #9c27b0;
}
.pink-purple app-role-user-find .work-space .side .toolbar .header {
  color: #e91e63;
}
.pink-purple app-role-user-find .work-space .side .toolbar .header h3 {
  color: #e91e63;
}
.pink-purple app-role-user-find .work-space .side .container .role-resume {
  background: #e91e63;
}
.pink-purple app-role-user-find .work-space .side .container .role-resume .line {
  color: white;
}
.pink-purple app-role-user-find .work-space .side .container .role-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.1);
}
.pink-purple app-role-user-find .work-space .side .container .role-resume .line.editable button {
  background: #e91e63;
}
.pink-purple app-role-user-find .work-space .side .container .section-title {
  color: #e91e63;
}
.pink-purple app-role-user-find .work-space .side .container .section-resume {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple app-role-user-find .work-space .side .container .section-resume .line.editable:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-purple app-role-user-find .work-space .side .container .section-resume .line.editable:hover .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px white, 0 -2px 0px white, 2px 0 0px white, 0 2px 0px white, -2px -2px 0px white, 2px 2px 0px white, 2px -2px 0px white, -2px 2px 0px white !important;
}
.pink-purple app-role-user-find .work-space .side .container .section-resume .line.editable button {
  background: #fafafa;
}
.pink-purple app-role-user-find .work-space .side .container .section-resume .line .header .icon-complex mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.pink-purple app-role-user-find .work-space .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #e91e63 !important;
}
.pink-purple app-role-user-find .work-space .side .container .section-resume .line .header mat-icon:not(.complex) {
  color: rgba(0, 0, 0, 0.54);
}
.pink-purple app-role-user-find .work-space .side .container .section-resume .line .header .info h4 {
  color: #e91e63;
}
.pink-purple app-role-user-find .work-space .side .container .section-resume .line ul {
  color: #e91e63;
}
.pink-purple app-role-user-find .work-space .side .container .section-resume .line.head {
  background: #eeeeee;
}
.pink-purple app-role-user-find .work-space .side .container .section-resume .line.head mat-icon {
  color: #9c27b0;
}
.pink-purple app-role-user-find .work-space .side .container .section-resume .line.head h4 {
  color: white;
}
.pink-purple app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee-title {
  color: #e91e63;
}
.pink-purple app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body {
  background: white;
}
.pink-purple app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: rgba(0, 0, 0, 0.54);
}
.pink-purple app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #e91e63;
}
.pink-purple app-role-user-find .work-space .side.edition .toolbar .header {
  color: white;
}
.pink-purple app-role-user-find .work-space .side.edition .toolbar .header mat-icon {
  color: #9c27b0;
}
.pink-purple app-role-user-find .work-space .side.edition .toolbar .header h3 {
  color: #9c27b0;
}
.pink-purple app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body {
  background: white !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header mat-icon:not(.complex) {
  color: #e91e63;
}
.pink-purple app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header h4 {
  color: #e91e63;
}
.pink-purple app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-actions {
  background: #fafafa;
}
.pink-purple app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.pink-purple app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header .icon-complex mat-icon.complex {
  color: #9c27b0 !important;
}
.pink-purple app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header h4 {
  color: #9c27b0;
}
.pink-purple app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line ul {
  color: #9c27b0;
}
.pink-purple app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head mat-icon {
  color: #9c27b0 !important;
}
.pink-purple app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head h4 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .section-title {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-purple app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume h6.hint {
  background: #e1bee7;
}
.pink-purple app-role-user-find .work-space app-role-user-detail.disabled .side .toolbar .header {
  color: #607D8B !important;
}
.pink-purple app-role-user-find .work-space app-role-user-detail.disabled .side .container .role-resume {
  background: #607D8B !important;
}
.pink-purple app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-title {
  color: #607D8B !important;
}
.pink-purple app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line ul {
  color: #607D8B !important;
}
.pink-purple app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header mat-icon {
  color: #607D8B;
}
.pink-purple app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .icon-complex mat-icon {
  color: #607D8B !important;
}
.pink-purple app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #607D8B !important;
}
.pink-purple app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .info h4 {
  color: #607D8B !important;
}
.pink-purple app-role-user-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee-title {
  color: #607D8B;
}
.pink-purple app-role-user-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: #607D8B;
}
.pink-purple app-role-user-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #607D8B;
}
.pink-purple app-actor-find .main-overlay {
  background: rgba(0, 0, 0, 0.32);
}
.pink-purple app-actor-find .icon-complex mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.pink-purple app-actor-find .icon-complex mat-icon.complex {
  color: #e91e63 !important;
}
.pink-purple app-actor-find .navigation {
  background: #fafafa;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple app-actor-find .navigation *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.pink-purple app-actor-find .navigation *::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}
.pink-purple app-actor-find .navigation .load-window {
  background: #eeeeee;
}
.pink-purple app-actor-find .navigation .toolbar {
  background: #e91e63;
  color: white;
}
.pink-purple app-actor-find .navigation .toolbar.reverse {
  background: white;
  color: rgba(0, 0, 0, 0.54);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple app-actor-find .navigation .container .helper {
  color: rgba(0, 0, 0, 0.54);
}
.pink-purple app-actor-find .navigation .container .item {
  background: white;
  color: rgba(0, 0, 0, 0.54);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple app-actor-find .navigation .container .item:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-purple app-actor-find .navigation .container .item.active {
  background: #e91e63 !important;
  color: white;
  border: 1px solid #e91e63;
}
.pink-purple app-actor-find .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.pink-purple app-actor-find .work-space .main .load-window {
  background: white !important;
}
.pink-purple app-actor-find .work-space .main.side-open {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple app-actor-find .work-space .main .container .item {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple app-actor-find .work-space .main .container .item .subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.pink-purple app-actor-find .work-space .main .container .item.disabled {
  background: #eeeeee;
  color: #607D8B !important;
}
.pink-purple app-actor-find .work-space .main .container .item.disabled h3 {
  color: #607D8B !important;
}
.pink-purple app-actor-find .work-space .main .container .item.disabled mat-icon {
  color: #607D8B !important;
}
.pink-purple app-actor-find .work-space .main .container .item.disabled.selected {
  background: #607D8B;
  color: white !important;
}
.pink-purple app-actor-find .work-space .main .container .item.disabled.selected h3 {
  color: white !important;
}
.pink-purple app-actor-find .work-space .main .container .item.disabled.selected mat-icon {
  color: white !important;
}
.pink-purple app-actor-find .work-space .main .container .item.disabled.selected mat-icon.complex {
  color: white !important;
  text-shadow: -2px 0 0px #607D8B, 0 -2px 0px #607D8B, 2px 0 0px #607D8B, 0 2px 0px #607D8B, -2px -2px 0px #607D8B, 2px 2px 0px #607D8B, 2px -2px 0px #607D8B, -2px 2px 0px #607D8B !important;
}
.pink-purple app-actor-find .work-space .main .container .item mat-icon.complex {
  text-shadow: -2px 0 0px #eeeeee, 0 -2px 0px #eeeeee, 2px 0 0px #eeeeee, 0 2px 0px #eeeeee, -2px -2px 0px #eeeeee, 2px 2px 0px #eeeeee, 2px -2px 0px #eeeeee, -2px 2px 0px #eeeeee !important;
}
.pink-purple app-actor-find .work-space .main .container .item h5 {
  color: rgba(0, 0, 0, 0.38);
}
.pink-purple app-actor-find .work-space .main .container .item h3 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple app-actor-find .work-space .main .container .item:hover {
  background: rgba(0, 0, 0, 0.04);
}
.pink-purple app-actor-find .work-space .main .container .item.selected {
  background: #e91e63;
  color: white;
}
.pink-purple app-actor-find .work-space .main .container .item.selected mat-icon {
  color: white;
}
.pink-purple app-actor-find .work-space .main .container .item.selected mat-icon.complex {
  color: white !important;
  text-shadow: -2px 0 0px #e91e63, 0 -2px 0px #e91e63, 2px 0 0px #e91e63, 0 2px 0px #e91e63, -2px -2px 0px #e91e63, 2px 2px 0px #e91e63, 2px -2px 0px #e91e63, -2px 2px 0px #e91e63 !important;
}
.pink-purple app-actor-find .work-space .main .container .item.selected h5,
.pink-purple app-actor-find .work-space .main .container .item.selected h3 {
  color: white;
}
.pink-purple app-actor-find .work-space .main .main-action {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple app-actor-find .work-space .side {
  background: #fafafa;
}
.pink-purple app-actor-find .work-space .side .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #fafafa, 0 -2px 0px #fafafa, 2px 0 0px #fafafa, 0 2px 0px #fafafa, -2px -2px 0px #fafafa, 2px 2px 0px #fafafa, 2px -2px 0px #fafafa, -2px 2px 0px #fafafa;
}
.pink-purple app-actor-find .work-space .side *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.pink-purple app-actor-find .work-space .side *::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}
.pink-purple app-actor-find .work-space .side .edition-line {
  background: #eeeeee;
}
.pink-purple app-actor-find .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #fafafa !important;
}
.pink-purple app-actor-find .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(0, 0, 0, 0.38);
}
.pink-purple app-actor-find .work-space .side mat-form-field .mat-form-field-flex {
  background: #fafafa;
}
.pink-purple app-actor-find .work-space .side mat-checkbox:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-purple app-actor-find .work-space .side .hint.all {
  color: #9c27b0;
}
.pink-purple app-actor-find .work-space .side .toolbar .header {
  color: #e91e63;
}
.pink-purple app-actor-find .work-space .side .toolbar .header h3 {
  color: #e91e63;
}
.pink-purple app-actor-find .work-space .side .container .role-resume {
  background: #e91e63;
}
.pink-purple app-actor-find .work-space .side .container .role-resume .line {
  color: white;
}
.pink-purple app-actor-find .work-space .side .container .role-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.1);
}
.pink-purple app-actor-find .work-space .side .container .role-resume .line.editable button {
  background: #e91e63;
}
.pink-purple app-actor-find .work-space .side .container .section-title {
  color: #e91e63;
}
.pink-purple app-actor-find .work-space .side .container .section-resume {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple app-actor-find .work-space .side .container .section-resume .line.editable:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-purple app-actor-find .work-space .side .container .section-resume .line.editable:hover .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px white, 0 -2px 0px white, 2px 0 0px white, 0 2px 0px white, -2px -2px 0px white, 2px 2px 0px white, 2px -2px 0px white, -2px 2px 0px white !important;
}
.pink-purple app-actor-find .work-space .side .container .section-resume .line.editable button {
  background: #fafafa;
}
.pink-purple app-actor-find .work-space .side .container .section-resume .line .header .icon-complex mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.pink-purple app-actor-find .work-space .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #e91e63 !important;
}
.pink-purple app-actor-find .work-space .side .container .section-resume .line .header mat-icon:not(.complex) {
  color: rgba(0, 0, 0, 0.54);
}
.pink-purple app-actor-find .work-space .side .container .section-resume .line .header .info h4 {
  color: #e91e63;
}
.pink-purple app-actor-find .work-space .side .container .section-resume .line ul {
  color: #e91e63;
}
.pink-purple app-actor-find .work-space .side .container .section-resume .line.head {
  background: #eeeeee;
}
.pink-purple app-actor-find .work-space .side .container .section-resume .line.head mat-icon {
  color: #9c27b0;
}
.pink-purple app-actor-find .work-space .side .container .section-resume .line.head h4 {
  color: white;
}
.pink-purple app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee-title {
  color: #e91e63;
}
.pink-purple app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body {
  background: white;
}
.pink-purple app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: rgba(0, 0, 0, 0.54);
}
.pink-purple app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #e91e63;
}
.pink-purple app-actor-find .work-space .side.edition .toolbar .header {
  color: white;
}
.pink-purple app-actor-find .work-space .side.edition .toolbar .header mat-icon {
  color: #9c27b0;
}
.pink-purple app-actor-find .work-space .side.edition .toolbar .header h3 {
  color: #9c27b0;
}
.pink-purple app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body {
  background: white !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header mat-icon:not(.complex) {
  color: #e91e63;
}
.pink-purple app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header h4 {
  color: #e91e63;
}
.pink-purple app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-actions {
  background: #fafafa;
}
.pink-purple app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.pink-purple app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header .icon-complex mat-icon.complex {
  color: #9c27b0 !important;
}
.pink-purple app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header h4 {
  color: #9c27b0;
}
.pink-purple app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line ul {
  color: #9c27b0;
}
.pink-purple app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head mat-icon {
  color: #9c27b0 !important;
}
.pink-purple app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head h4 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .section-title {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-purple app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume h6.hint {
  background: #e1bee7;
}
.pink-purple app-actor-find .work-space app-role-user-detail.disabled .side .toolbar .header {
  color: #607D8B !important;
}
.pink-purple app-actor-find .work-space app-role-user-detail.disabled .side .container .role-resume {
  background: #607D8B !important;
}
.pink-purple app-actor-find .work-space app-role-user-detail.disabled .side .container .section-title {
  color: #607D8B !important;
}
.pink-purple app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line ul {
  color: #607D8B !important;
}
.pink-purple app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header mat-icon {
  color: #607D8B;
}
.pink-purple app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .icon-complex mat-icon {
  color: #607D8B !important;
}
.pink-purple app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #607D8B !important;
}
.pink-purple app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .info h4 {
  color: #607D8B !important;
}
.pink-purple app-actor-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee-title {
  color: #607D8B;
}
.pink-purple app-actor-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: #607D8B;
}
.pink-purple app-actor-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #607D8B;
}
.pink-purple .dropzone {
  border: 2px dashed #607D8B;
  background: #eeeeee !important;
}
.pink-purple .dropzone.hovering {
  border: 2px solid #607D8B;
  color: rgba(0, 0, 0, 0.38) !important;
}
.pink-purple .dropzone button.mat-stroked-button {
  background: white;
}
.pink-purple .firmador .content-action-main {
  color: white !important;
}
.pink-purple .firmador .content-action-main.disabled {
  color: rgba(0, 0, 0, 0.38) !important;
  background: rgba(0, 0, 0, 0.12);
}
.pink-purple .sign-cycle {
  background: white;
}
.pink-purple .sign-cycle button.success {
  background-color: #009688;
}
.pink-purple .sign-cycle button.warning {
  background-color: #673AB7;
}
.pink-purple .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-title-container .cycle-content-title h4 {
  color: #607D8B;
}
.pink-purple .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-action .content-action-main {
  color: white !important;
}
.pink-purple .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-action .content-action-main.mat-stroked-button:disabled {
  color: #9e9e9e !important;
}
.pink-purple .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-action .content-action-main.mat-stroked-button:not(:disabled) {
  color: #9c27b0 !important;
}
.pink-purple .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-action .content-action-side u {
  color: #9e9e9e;
}
.pink-purple .resume-status {
  background: white;
}
.pink-purple .help-hoverable mat-icon {
  color: rgba(0, 0, 0, 0.54);
}
.pink-purple .help-hoverable mat-hint {
  background: white;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.pink-purple .help-hoverable mat-hint b {
  color: #e91e63 !important;
}
.pink-purple app-file-document-sign-massive-dialog .dialog-content,
.pink-purple app-share-person-dialog .dialog-content {
  background: #fafafa;
}
.pink-purple app-file-document-sign-massive-dialog .dialog-content mat-toolbar mat-toolbar-row .mat-avatar h3,
.pink-purple app-share-person-dialog .dialog-content mat-toolbar mat-toolbar-row .mat-avatar h3 {
  color: #e91e63;
}
.pink-purple app-file-document-sign-massive-dialog .dialog-content .dialog-body p b,
.pink-purple app-share-person-dialog .dialog-content .dialog-body p b {
  color: #9c27b0;
}
.pink-purple app-file-document-sign-massive-dialog .dialog-content app-file-document-sign,
.pink-purple app-share-person-dialog .dialog-content app-file-document-sign {
  background: white;
}
.pink-purple .mat-basic-chip {
  background: #eeeeee !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple .mat-basic-chip.seen {
  border-color: #2196F3 !important;
  color: #2196F3 !important;
}
.pink-purple .mat-basic-chip.unseen {
  border-color: #607D8B !important;
  color: #607D8B !important;
}
.pink-purple .mat-basic-chip.signed-ok {
  border-color: #009688 !important;
  color: #009688 !important;
}
.pink-purple .mat-basic-chip.signed-ok.cancelled {
  border-color: #795548 !important;
  color: #795548 !important;
}
.pink-purple .mat-basic-chip.signed-not-ok {
  border-color: #673AB7 !important;
  color: #673AB7 !important;
}
.pink-purple .mat-basic-chip.not-signed {
  border-color: #ff4081 !important;
  color: #ff4081 !important;
}
.pink-purple .mat-basic-chip.not-signed-employer {
  border-color: #ff4081 !important;
  color: #ff4081 !important;
}
.pink-purple app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip {
  background: #eeeeee !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.seen {
  border-color: #2196F3 !important;
  color: #2196F3 !important;
}
.pink-purple app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.unseen {
  border-color: #607D8B !important;
  color: #607D8B !important;
}
.pink-purple app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.signed-ok {
  border-color: #009688 !important;
  color: #009688 !important;
}
.pink-purple app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.signed-ok.cancelled {
  border-color: #795548 !important;
  color: #795548 !important;
}
.pink-purple app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.signed-not-ok {
  border-color: #673AB7 !important;
  color: #673AB7 !important;
}
.pink-purple app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.not-signed {
  border-color: #ff4081 !important;
  color: #ff4081 !important;
}
.pink-purple app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.not-signed-employer {
  border-color: #ff4081 !important;
  color: #ff4081 !important;
}
.pink-purple .inbox-container.employee.my-documents .chip-line.mat-line .mat-chip-list-wrapper .mat-basic-chip.seen {
  color: #607D8B !important;
}
.pink-purple .resume-status .mat-basic-chip {
  background: #eeeeee !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple .resume-status .mat-basic-chip.signed-ok {
  border-color: #009688 !important;
  background-color: #009688 !important;
  color: white !important;
}
.pink-purple .resume-status .mat-basic-chip.signed-ok.cancelled {
  border-color: #795548 !important;
  background-color: #795548 !important;
  color: white !important;
}
.pink-purple .resume-status .mat-basic-chip.signed-not-ok {
  border-color: #673AB7 !important;
  background-color: #673AB7 !important;
  color: white !important;
}
.pink-purple app-file-document-view .new-container app-file-document-upload {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple app-file-document-view .new-container app-upload-form .file-upload-list .mat-subheader {
  background: white;
}
.pink-purple app-file-document-view .new-container app-upload-form .file-upload-list mat-list-item {
  background: #fafafa !important;
}
.pink-purple app-file-document-view .preview-content {
  background: #607D8B;
}
.pink-purple app-file-document-view .preview-content h4 {
  color: white;
}
.pink-purple app-file-document-view .view-container {
  background: white;
}
.pink-purple app-file-document-view .view-container mat-toolbar.side-bar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple .formio-hint {
  background: #e91e63;
  color: white;
}
.pink-purple mat-dialog-container app-file-document-state-chips {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple app-candidate-promotion .load-window.message h3.primary-text {
  color: #e91e63;
}
.pink-purple app-multiple-documentation-add-dialog .icon-complex mat-icon,
.pink-purple app-documentation-type-set-configuration-dialog .icon-complex mat-icon,
.pink-purple app-add-candidate-dialog .icon-complex mat-icon,
.pink-purple app-share-person-dialog .icon-complex mat-icon,
.pink-purple app-share-person-response-dialog .icon-complex mat-icon {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-purple app-multiple-documentation-add-dialog .icon-complex mat-icon.complex,
.pink-purple app-documentation-type-set-configuration-dialog .icon-complex mat-icon.complex,
.pink-purple app-add-candidate-dialog .icon-complex mat-icon.complex,
.pink-purple app-share-person-dialog .icon-complex mat-icon.complex,
.pink-purple app-share-person-response-dialog .icon-complex mat-icon.complex {
  color: #e91e63 !important;
}
.pink-purple app-multiple-documentation-add-dialog .icon-complex mat-icon.complex.mat-accent,
.pink-purple app-documentation-type-set-configuration-dialog .icon-complex mat-icon.complex.mat-accent,
.pink-purple app-add-candidate-dialog .icon-complex mat-icon.complex.mat-accent,
.pink-purple app-share-person-dialog .icon-complex mat-icon.complex.mat-accent,
.pink-purple app-share-person-response-dialog .icon-complex mat-icon.complex.mat-accent {
  color: #9c27b0;
}
.pink-purple app-multiple-documentation-add-dialog .icon-complex mat-icon.complex.side,
.pink-purple app-documentation-type-set-configuration-dialog .icon-complex mat-icon.complex.side,
.pink-purple app-add-candidate-dialog .icon-complex mat-icon.complex.side,
.pink-purple app-share-person-dialog .icon-complex mat-icon.complex.side,
.pink-purple app-share-person-response-dialog .icon-complex mat-icon.complex.side {
  color: rgba(0, 0, 0, 0.54);
}
.pink-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection,
.pink-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection,
.pink-purple app-add-candidate-dialog .dialog-content .document-template-container .document-selection,
.pink-purple app-share-person-dialog .dialog-content .document-template-container .document-selection,
.pink-purple app-share-person-response-dialog .dialog-content .document-template-container .document-selection {
  background: #fafafa;
}
.pink-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.pink-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.pink-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3,
.pink-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.pink-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.pink-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3,
.pink-purple app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.pink-purple app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.pink-purple app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3,
.pink-purple app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.pink-purple app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.pink-purple app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3,
.pink-purple app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.pink-purple app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.pink-purple app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3 {
  color: #9c27b0 !important;
}
.pink-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon,
.pink-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon,
.pink-purple app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon,
.pink-purple app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon,
.pink-purple app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon {
  color: #e91e63;
}
.pink-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3,
.pink-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3,
.pink-purple app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3,
.pink-purple app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3,
.pink-purple app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3 {
  color: #e91e63;
}
.pink-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex,
.pink-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex,
.pink-purple app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex,
.pink-purple app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex,
.pink-purple app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex {
  color: #9c27b0 !important;
  text-shadow: -2px 0 0px #eeeeee, 0 -2px 0px #eeeeee, 2px 0 0px #eeeeee, 0 2px 0px #eeeeee, -2px -2px 0px #eeeeee, 2px 2px 0px #eeeeee, 2px -2px 0px #eeeeee, -2px 2px 0px white;
}
.pink-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex,
.pink-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex,
.pink-purple app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex,
.pink-purple app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex,
.pink-purple app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex {
  color: rgba(0, 0, 0, 0.38) !important;
}
.pink-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b,
.pink-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b,
.pink-purple app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b,
.pink-purple app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b,
.pink-purple app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex,
.pink-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex,
.pink-purple app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex,
.pink-purple app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex,
.pink-purple app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex {
  color: rgba(0, 0, 0, 0.38) !important;
}
.pink-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex,
.pink-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex,
.pink-purple app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex,
.pink-purple app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex,
.pink-purple app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex {
  color: rgba(0, 0, 0, 0.38) !important;
}
.pink-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover,
.pink-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover,
.pink-purple app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover,
.pink-purple app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover,
.pink-purple app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover {
  background: rgba(0, 0, 0, 0.04);
}
.pink-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b,
.pink-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b,
.pink-purple app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b,
.pink-purple app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b,
.pink-purple app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.pink-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.pink-purple app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.pink-purple app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.pink-purple app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble {
  background: #e91e63;
}
.pink-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble,
.pink-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble,
.pink-purple app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble,
.pink-purple app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble,
.pink-purple app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble {
  background: #f44336;
}
.pink-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble,
.pink-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble,
.pink-purple app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble,
.pink-purple app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble,
.pink-purple app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble {
  color: rgba(0, 0, 0, 0.38);
  background: rgba(0, 0, 0, 0.12);
}
.pink-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble,
.pink-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble,
.pink-purple app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble,
.pink-purple app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble,
.pink-purple app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble {
  background: rgba(0, 0, 0, 0.38);
  color: white;
}
.pink-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide,
.pink-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide,
.pink-purple app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide,
.pink-purple app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide,
.pink-purple app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide {
  border-color: rgba(0, 0, 0, 0.12);
}
.pink-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble,
.pink-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble,
.pink-purple app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble,
.pink-purple app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble,
.pink-purple app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble {
  color: rgba(0, 0, 0, 0.54);
}
.pink-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.pink-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.pink-purple app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.pink-purple app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.pink-purple app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble {
  color: #e91e63;
}
.pink-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble,
.pink-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble,
.pink-purple app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble,
.pink-purple app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble,
.pink-purple app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble {
  color: #f44336;
}
.pink-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar,
.pink-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar,
.pink-purple app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar,
.pink-purple app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar,
.pink-purple app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar {
  background: #607D8B;
}
.pink-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row,
.pink-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row,
.pink-purple app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row,
.pink-purple app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row,
.pink-purple app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row {
  color: white;
}
.pink-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper,
.pink-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper,
.pink-purple app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper,
.pink-purple app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper,
.pink-purple app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper {
  background: #607D8B;
}
.pink-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop,
.pink-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop,
.pink-purple app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop,
.pink-purple app-share-person-dialog .dialog-content .document-template-container .add-docs-drop,
.pink-purple app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body,
.pink-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body,
.pink-purple app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body,
.pink-purple app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body,
.pink-purple app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3,
.pink-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3,
.pink-purple app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3,
.pink-purple app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3,
.pink-purple app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3 {
  color: #9c27b0;
}
.pink-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5,
.pink-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5,
.pink-purple app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5,
.pink-purple app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5,
.pink-purple app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5 {
  color: rgba(0, 0, 0, 0.54);
}
.pink-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5,
.pink-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5,
.pink-purple app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5,
.pink-purple app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5,
.pink-purple app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5 {
  color: rgba(0, 0, 0, 0.54);
}
.pink-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator,
.pink-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator,
.pink-purple app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator,
.pink-purple app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator,
.pink-purple app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background: #fafafa;
}
.pink-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip,
.pink-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip,
.pink-purple app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip,
.pink-purple app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip,
.pink-purple app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip {
  background: white !important;
}
.pink-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon,
.pink-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon,
.pink-purple app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon,
.pink-purple app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon,
.pink-purple app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon {
  color: #9c27b0 !important;
}
.pink-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3,
.pink-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3,
.pink-purple app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3,
.pink-purple app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3,
.pink-purple app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3 {
  color: #9c27b0 !important;
}
.pink-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5,
.pink-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5,
.pink-purple app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5,
.pink-purple app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5,
.pink-purple app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5 {
  color: rgba(0, 0, 0, 0.54) !important;
}
.pink-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon,
.pink-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon,
.pink-purple app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon,
.pink-purple app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon,
.pink-purple app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon {
  color: #e91e63 !important;
}
.pink-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3,
.pink-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3,
.pink-purple app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3,
.pink-purple app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3,
.pink-purple app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3 {
  color: #e91e63 !important;
}
.pink-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5,
.pink-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5,
.pink-purple app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5,
.pink-purple app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5,
.pink-purple app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5 {
  color: #e91e63 !important;
}
.pink-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled,
.pink-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled,
.pink-purple app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled,
.pink-purple app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled,
.pink-purple app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled {
  background: #fafafa !important;
  color: rgba(0, 0, 0, 0.38);
}
.pink-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove,
.pink-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove,
.pink-purple app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove,
.pink-purple app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove,
.pink-purple app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove {
  color: rgba(0, 0, 0, 0.54);
}
.pink-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove,
.pink-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove,
.pink-purple app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove,
.pink-purple app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove,
.pink-purple app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove {
  color: rgba(0, 0, 0, 0.54) !important;
}
.pink-purple app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .preview-action,
.pink-purple app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .preview-action,
.pink-purple app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .preview-action,
.pink-purple app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .preview-action,
.pink-purple app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .preview-action {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple .tyc .sticky {
  background: white;
}
.pink-purple .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper:nth-child(odd) datatable-body-row {
  background: #fafafa;
}
.pink-purple .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active * {
  color: white !important;
}
.pink-purple .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active:hover .datatable-body-cell-label {
  background: #e91e63;
}
.pink-purple .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active:hover .cs-floating-action {
  background: #e91e63 !important;
}
.pink-purple .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active.row-cancel * {
  color: rgba(0, 0, 0, 0.54) !important;
}
.pink-purple .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active.row-cancel .datatable-row-group {
  background: #607D8B !important;
}
.pink-purple .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active.row-cancel:hover .datatable-body-cell-label {
  background: #607D8B;
}
.pink-purple .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.row-cancel * {
  color: #b0bec5 !important;
  transition-property: color;
  transition-duration: 0.3s;
  transition-timing-function: linear;
}
.pink-purple .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row:hover .datatable-body-cell-label button {
  background: #eeeeee;
}
.pink-purple .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row datatable-body-cell.candidate-status_bar mat-progress-bar .mat-progress-bar-buffer {
  background-color: #eeeeee;
}
.pink-purple .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row datatable-body-cell.candidate-status_bar .status_bar-count {
  color: rgba(0, 0, 0, 0.54);
}
.pink-purple .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row datatable-body-cell.candidate-status_bar .inactive {
  color: rgba(0, 0, 0, 0.54);
}
.pink-purple .cs-grid-test .info {
  background: white;
}
.pink-purple .cs-grid-test .selected-column {
  background: white;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
}
.pink-purple .cs-grid-test .selection-cell .datatable-body-cell.active {
  background: #e91e63 !important;
  color: white !important;
}
.pink-purple .cs-grid-test nav {
  background: white;
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 13px 19px 2px rgba(0, 0, 0, 0.14), 0 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.pink-purple .cs-grid-test .ngx-datatable.material {
  background: white;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.pink-purple .cs-grid-test .ngx-datatable.material.striped .datatable-row-odd {
  background: #fafafa;
}
.pink-purple .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active, .pink-purple .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active .datatable-row-group, .pink-purple .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active, .pink-purple .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active .datatable-row-group, .pink-purple .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active, .pink-purple .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active .datatable-row-group {
  background: #e91e63;
  color: white;
}
.pink-purple .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active:hover, .pink-purple .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active:hover .datatable-row-group, .pink-purple .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active:hover, .pink-purple .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active:hover .datatable-row-group, .pink-purple .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active:hover, .pink-purple .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active:hover .datatable-row-group {
  background: #e91e63;
  color: white;
}
.pink-purple .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active:focus, .pink-purple .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active:focus .datatable-row-group, .pink-purple .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active:focus, .pink-purple .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active:focus .datatable-row-group, .pink-purple .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active:focus, .pink-purple .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active:focus .datatable-row-group {
  background: #e91e63;
  color: white;
}
.pink-purple .cs-grid-test .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover, .pink-purple .cs-grid-test .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: rgba(0, 0, 0, 0.04);
}
.pink-purple .cs-grid-test .ngx-datatable.material:not(.cell-selection) .datatable-body-row:focus, .pink-purple .cs-grid-test .ngx-datatable.material:not(.cell-selection) .datatable-body-row:focus .datatable-row-group {
  background: rgba(0, 0, 0, 0.04);
}
.pink-purple .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell:hover, .pink-purple .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell:hover .datatable-row-group {
  background: rgba(0, 0, 0, 0.04);
}
.pink-purple .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell:focus, .pink-purple .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell:focus .datatable-row-group {
  background: rgba(0, 0, 0, 0.04);
}
.pink-purple .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active, .pink-purple .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active .datatable-row-group {
  background: #e91e63;
  color: white;
}
.pink-purple .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active:hover, .pink-purple .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active:hover .datatable-row-group {
  background: #e91e63;
  color: white;
}
.pink-purple .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active:focus, .pink-purple .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active:focus .datatable-row-group {
  background: #e91e63;
  color: white;
}
.pink-purple .cs-grid-test .ngx-datatable.material .datatable-header .datatable-row-left,
.pink-purple .cs-grid-test .ngx-datatable.material .datatable-body .datatable-row-left {
  background-color: white;
}
.pink-purple .cs-grid-test .ngx-datatable.material .datatable-header .datatable-row-right,
.pink-purple .cs-grid-test .ngx-datatable.material .datatable-body .datatable-row-right {
  background-color: white;
}
.pink-purple .cs-grid-test .ngx-datatable.material .datatable-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.38);
}
.pink-purple .cs-grid-test .ngx-datatable.material .datatable-header .datatable-header-cell {
  background-color: white;
  color: rgba(0, 0, 0, 0.54);
}
.pink-purple .cs-grid-test .ngx-datatable.material .datatable-header .resize-handle {
  border-right: solid 1px rgba(0, 0, 0, 0.04);
}
.pink-purple .cs-grid-test .ngx-datatable.material .datatable-body .datatable-row-detail {
  background: #eeeeee;
}
.pink-purple .cs-grid-test .ngx-datatable.material .datatable-body .datatable-group-header {
  background: #eeeeee;
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
  border-top: solid 1px rgba(0, 0, 0, 0.12);
}
.pink-purple .cs-grid-test .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple .cs-grid-test .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-group-cell {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple .cs-grid-test .ngx-datatable.material .datatable-body .progress-linear .container {
  background-color: white;
}
.pink-purple .cs-grid-test .ngx-datatable.material .datatable-body .progress-linear .container .bar {
  background-color: #e91e63;
}
.pink-purple .cs-grid-test .ngx-datatable.material .datatable-footer {
  color: rgba(0, 0, 0, 0.54);
}
.pink-purple .cs-grid-test .ngx-datatable.material .datatable-footer .datatable-pager li.disabled a {
  color: rgba(0, 0, 0, 0.38) !important;
  background-color: rgba(0, 0, 0, 0) !important;
}
.pink-purple .cs-grid-test .ngx-datatable.material .datatable-footer .datatable-pager li.active a {
  background-color: #e91e63;
}
.pink-purple .cs-grid-test .ngx-datatable.material .datatable-footer .datatable-pager a {
  color: rgba(0, 0, 0, 0.54);
}
.pink-purple .cs-grid-test .ngx-datatable.material .datatable-footer .datatable-pager a:hover {
  color: rgba(0, 0, 0, 0.04);
  background-color: rgba(0, 0, 0, 0.04);
}
.pink-purple .cs-grid-test .ngx-datatable.material .datatable-summary-row .datatable-body-row {
  background-color: #fafafa;
}
.pink-purple .cs-grid-test .ngx-datatable.material .datatable-summary-row .datatable-body-row:hover {
  background-color: #fafafa;
}
.pink-purple .cs-grid-test .datatable-checkbox input[type=checkbox]:after {
  background: white;
}
.pink-purple .view-container .view-toolbar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal.new.image-opened .help-content img {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card {
  background: white;
}
.pink-purple app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container .aditional-info {
  color: rgba(0, 0, 0, 0.54);
}
.pink-purple app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container .mat-card-footer {
  background: white;
}
.pink-purple app-help-index mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card {
  background: white;
}
.pink-purple app-help-index mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card .mat-card-footer {
  background: white;
}
.pink-purple .cropper .employee-title .title-text {
  color: white !important;
}
.pink-purple .cropper app-cs-upload-cropper-control .preview h5 {
  color: rgba(0, 0, 0, 0.54);
}
.pink-purple app-file-document-upload .preview-container {
  background: #607D8B !important;
}
.pink-purple app-file-document-upload .preview-container h4 {
  color: #eeeeee;
}
.pink-purple .preview-container {
  background: #607D8B !important;
}
.pink-purple .preview-container h4 {
  color: #eeeeee;
}
.pink-purple .clean-autocomple mat-list {
  background: white;
}
.pink-purple .clean-autocomple mat-list .mat-list-item:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-purple .clean-autocomple mat-list .mat-list-item .mat-list-item-content .mat-list-text {
  color: #e91e63;
}
.pink-purple app-leave .inbox-toolbar {
  background: #fafafa;
}
.pink-purple app-leave .mat-tab-body-wrapper {
  position: relative;
  overflow: hidden;
  display: flex;
  transition: height 500ms cubic-bezier(0.35, 0, 0.25, 1);
  height: 95% !important;
}
.pink-purple app-leave .leave_container {
  background: #fafafa;
}
.pink-purple app-leave .leave_container .leave_items .leave_items-item.ok .content .leave_item-status_icon {
  color: #009688;
}
.pink-purple app-leave .leave_container .leave_items .leave_items-item.not-ok .content .leave_item-status_icon {
  color: #9C27B0;
}
.pink-purple app-leave .leave_container .leave_items .leave_items-item.pending .content .leave_item-status_icon {
  color: #FF9800;
}
.pink-purple app-leave .leave_container .leave_items .leave_items-item.draft .content .leave_item-status_icon {
  color: rgba(0, 0, 0, 0.54);
}
.pink-purple app-leave .leave_container .leave_items .leave_items-item.cancel .content .leave_item-status_icon {
  color: #795548;
}
.pink-purple app-leave .leave_container .leave_items .leave_items-item .content:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-purple app-leave .leave_container .leave_items .leave_items-item .content.selected {
  border-left: 6px solid #9c27b0 !important;
}
.pink-purple app-leave .leave_container .leave_items .leave_items-item .content.selected mat-icon {
  color: #9c27b0 !important;
}
.pink-purple app-leave .leave_container .leave_items .leave_items-item .content.selected .item-data {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-purple app-leave .leave_container .leave_items .leave_items-item .content .leave_item-icon {
  color: rgba(0, 0, 0, 0.54);
}
.pink-purple app-leave .leave_container .leave_items .leave_items-item .content .item-data {
  color: rgba(0, 0, 0, 0.54);
}
.pink-purple app-leave .leave_container .window-spinner {
  background: #fafafa !important;
}
.pink-purple app-leave-detail .leave_detail .leave_detail-title .info h3,
.pink-purple app-leave-detail .leave_detail .leave_detail-title .info mat-icon {
  color: #e91e63;
}
.pink-purple app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .ok .large-icon {
  background: #b2dfdb;
  color: #009688;
}
.pink-purple app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .ok h2 {
  color: #009688;
}
.pink-purple app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .not-ok .large-icon {
  background: #e1bee7;
  color: #9C27B0;
}
.pink-purple app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .not-ok h2 {
  color: #9C27B0;
}
.pink-purple app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .pending .large-icon {
  background: #FFE0B2;
  color: #FF9800;
}
.pink-purple app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .pending h2 {
  color: #FF9800;
}
.pink-purple app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .draft .large-icon {
  background: #f2f2f2;
  color: #dedede;
}
.pink-purple app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .draft h2 {
  color: #dedede;
}
.pink-purple app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .cancelled .large-icon {
  background: #BCAAA4;
  color: #795548;
}
.pink-purple app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .cancelled h2 {
  color: #795548;
}
.pink-purple app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .actions {
  padding: 16px;
  height: 96px;
  column-gap: 16px;
}
.pink-purple app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .actions button {
  height: 100%;
  border-radius: 40px;
}
.pink-purple app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .actions button.ok {
  background: #009688 !important;
  color: white;
}
.pink-purple app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .actions button.not-ok {
  color: #9C27B0 !important;
}
.pink-purple app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning mat-basic-chip {
  background: #eeeeee !important;
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
  border-radius: 40px !important;
  height: 35px !important;
}
.pink-purple app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning mat-basic-chip.signed-ok {
  border-color: #009688 !important;
  color: #009688 !important;
}
.pink-purple app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning mat-basic-chip.signed-not-ok {
  border-color: #9C27B0 !important;
  color: #9C27B0 !important;
}
.pink-purple app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning mat-basic-chip.draft {
  border-color: #dedede !important;
  color: #dedede !important;
}
.pink-purple app-leave-request-detail .load-window {
  background: #fafafa;
}
.pink-purple app-leave-request-detail form .leave_detail-container {
  background: #fafafa;
}
.pink-purple app-leave-request-detail form .leave_detail-container .toolbar .header {
  color: #e91e63;
}
.pink-purple app-leave-request-detail form .leave_detail-container .toolbar .header .text h3 {
  color: #e91e63;
}
.pink-purple app-leave-request-detail form .leave_detail-container .toolbar .header .text h5 {
  color: rgba(0, 0, 0, 0.54);
}
.pink-purple app-leave-request-detail form .leave_detail-container .leave_detail-container-content .leave_resume {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple app-leave-request-detail form .leave_detail-container .leave_detail-container-content .line h6 {
  color: rgba(0, 0, 0, 0.54);
}
.pink-purple app-leave-request-detail form .leave_detail-container .actions button.ok {
  background: #009688 !important;
  color: white;
}
.pink-purple app-leave-request-detail form .leave_detail-container .actions button.not-ok {
  color: #9C27B0 !important;
}
.pink-purple .new_leave_modal-steps {
  display: unset !important;
}
.pink-purple .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button .mat-radio-label .mat-radio-label-content .side-text {
  color: rgba(0, 0, 0, 0.54);
}
.pink-purple .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button .mat-radio-label .mat-radio-label-content .side-text.muted {
  color: rgba(0, 0, 0, 0.38);
}
.pink-purple .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button .mat-radio-label .mat-radio-label-content .accent mat-icon,
.pink-purple .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button .mat-radio-label .mat-radio-label-content .accent .main-text {
  color: #9c27b0;
}
.pink-purple .config_leave-container .config_leave-card {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple .config_leave-container .config_leave-card .config_leave-auto_chip mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #fafafa !important;
}
.pink-purple .config_leave-container .config_leave-card .config_leave-auto_chip mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(0, 0, 0, 0.38);
}
.pink-purple .config_leave-container .config_leave-card .config_leave-auto_chip mat-form-field .mat-form-field-flex {
  background: #fafafa;
}
.pink-purple *[color=success] {
  color: #4DB6AC;
}
.pink-purple .primary {
  color: #e91e63 !important;
}
.pink-purple .accent {
  color: #9c27b0 !important;
}
.pink-purple app-lsd .icon-complex mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.pink-purple app-lsd .icon-complex mat-icon.complex {
  color: #e91e63 !important;
}
.pink-purple app-lsd .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.pink-purple app-lsd .work-space .main .load-window {
  background: white !important;
}
.pink-purple app-lsd .work-space .main.side-open {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple app-lsd .work-space .main .container .item {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple app-lsd .work-space .main .container .item h5 {
  color: rgba(0, 0, 0, 0.38);
}
.pink-purple app-lsd .work-space .main .container .item h3 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple app-lsd .work-space .main .container .item:hover {
  background: rgba(0, 0, 0, 0.04);
}
.pink-purple app-lsd .work-space .main .container .item.selected {
  background: #e91e63;
  color: white !important;
}
.pink-purple app-lsd .work-space .main .container .item.selected h3 {
  color: white !important;
}
.pink-purple app-lsd .work-space .main .container .item.selected.pending .status {
  background: rgba(0, 0, 0, 0.12) !important;
  color: #ffe082;
}
.pink-purple app-lsd .work-space .main .container .item.selected.ok .status {
  background: rgba(0, 0, 0, 0.12) !important;
  color: #a5d6a7;
}
.pink-purple app-lsd .work-space .main .container .item.selected.not-ok .status {
  background: rgba(0, 0, 0, 0.12) !important;
  color: #e57373;
}
.pink-purple app-lsd .work-space .main .main-action {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple app-lsd .work-space .side {
  background: #fafafa;
}
.pink-purple app-lsd .work-space .side .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #fafafa, 0 -2px 0px #fafafa, 2px 0 0px #fafafa, 0 2px 0px #fafafa, -2px -2px 0px #fafafa, 2px 2px 0px #fafafa, 2px -2px 0px #fafafa, -2px 2px 0px #fafafa;
}
.pink-purple app-lsd .work-space .side *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.pink-purple app-lsd .work-space .side *::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}
.pink-purple app-lsd .work-space .side .edition-line {
  background: #eeeeee;
}
.pink-purple app-lsd .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #fafafa !important;
}
.pink-purple app-lsd .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(0, 0, 0, 0.38);
}
.pink-purple app-lsd .work-space .side mat-form-field .mat-form-field-flex {
  background: #fafafa;
}
.pink-purple app-lsd .work-space .side mat-checkbox:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-purple app-lsd .work-space .side .hint.all {
  color: #9c27b0;
}
.pink-purple app-lsd .work-space .side .toolbar .header {
  color: #e91e63;
}
.pink-purple app-lsd .work-space .side .toolbar .header h3 {
  color: #e91e63;
}
.pink-purple app-lsd .work-space .side .container .doc-resume {
  background: #e91e63;
}
.pink-purple app-lsd .work-space .side .container .doc-resume .line {
  color: white;
}
.pink-purple app-lsd .work-space .side .container .doc-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.1);
}
.pink-purple app-lsd .work-space .side .container .doc-resume .line.editable button {
  background: #e91e63;
}
.pink-purple app-lsd .work-space .side .container .section-title {
  color: #e91e63;
}
.pink-purple app-lsd .work-space .side .container .section-resume {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple app-lsd .work-space .side .container .section-resume .line.editable:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-purple app-lsd .work-space .side .container .section-resume .line.editable:hover .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px white, 0 -2px 0px white, 2px 0 0px white, 0 2px 0px white, -2px -2px 0px white, 2px 2px 0px white, 2px -2px 0px white, -2px 2px 0px white !important;
}
.pink-purple app-lsd .work-space .side .container .section-resume .line.editable button {
  background: #fafafa;
}
.pink-purple app-lsd .work-space .side .container .section-resume .line .header .icon-complex mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.pink-purple app-lsd .work-space .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #e91e63 !important;
}
.pink-purple app-lsd .work-space .side .container .section-resume .line .header mat-icon:not(.complex) {
  color: rgba(0, 0, 0, 0.54);
}
.pink-purple app-lsd .work-space .side .container .section-resume .line .header .info h4 {
  color: #e91e63;
}
.pink-purple app-lsd .work-space .side .container .section-resume .line ul {
  color: #e91e63;
}
.pink-purple app-lsd .work-space .side .container .section-resume .line.head {
  background: #eeeeee;
}
.pink-purple app-lsd .work-space .side .container .section-resume .line.head mat-icon {
  color: #9c27b0;
}
.pink-purple app-lsd .work-space .side .container .section-resume .line.head h4 {
  color: white;
}
.pink-purple app-lsd .work-space .side.edition .toolbar .header {
  color: white;
}
.pink-purple app-lsd .work-space .side.edition .toolbar .header mat-icon {
  color: #9c27b0;
}
.pink-purple app-lsd .work-space .side.edition .toolbar .header h3 {
  color: #9c27b0;
}
.pink-purple .dialog-content {
  max-height: 90vh;
}
.pink-purple .dialog-content mat-toolbar {
  background: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple .dialog-content .dialog-container .document-template-container .document-selection mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #fafafa !important;
}
.pink-purple .dialog-content .dialog-container .document-template-container .document-selection mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(0, 0, 0, 0.38);
}
.pink-purple .dialog-content .dialog-container .document-template-container .document-selection mat-form-field .mat-form-field-flex {
  background: #fafafa;
}
.pink-purple .dialog-content .dialog-container .document-template-container .document-selection app-upload-form .file-upload-list .mat-subheader::before {
  color: #e91e63;
}
.pink-purple .dialog-content .dialog-container .view-container {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-purple .dialog-content .form-actions {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.pink-purple.dark .mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-purple.dark .mat-badge-small .mat-badge-content {
  font-size: 9px;
}
.pink-purple.dark .mat-badge-large .mat-badge-content {
  font-size: 24px;
}
.pink-purple.dark .mat-h1,
.pink-purple.dark .mat-headline,
.pink-purple.dark .mat-typography .mat-h1,
.pink-purple.dark .mat-typography .mat-headline,
.pink-purple.dark .mat-typography h1 {
  font: 400 24px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.pink-purple.dark .mat-h2,
.pink-purple.dark .mat-title,
.pink-purple.dark .mat-typography .mat-h2,
.pink-purple.dark .mat-typography .mat-title,
.pink-purple.dark .mat-typography h2 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.pink-purple.dark .mat-h3,
.pink-purple.dark .mat-subheading-2,
.pink-purple.dark .mat-typography .mat-h3,
.pink-purple.dark .mat-typography .mat-subheading-2,
.pink-purple.dark .mat-typography h3 {
  font: 400 16px / 28px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.pink-purple.dark .mat-h4,
.pink-purple.dark .mat-subheading-1,
.pink-purple.dark .mat-typography .mat-h4,
.pink-purple.dark .mat-typography .mat-subheading-1,
.pink-purple.dark .mat-typography h4 {
  font: 400 15px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.pink-purple.dark .mat-h5,
.pink-purple.dark .mat-typography .mat-h5,
.pink-purple.dark .mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}
.pink-purple.dark .mat-h6,
.pink-purple.dark .mat-typography .mat-h6,
.pink-purple.dark .mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}
.pink-purple.dark .mat-body-strong,
.pink-purple.dark .mat-body-2,
.pink-purple.dark .mat-typography .mat-body-strong,
.pink-purple.dark .mat-typography .mat-body-2 {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.pink-purple.dark .mat-body,
.pink-purple.dark .mat-body-1,
.pink-purple.dark .mat-typography .mat-body,
.pink-purple.dark .mat-typography .mat-body-1,
.pink-purple.dark .mat-typography {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.pink-purple.dark .mat-body p,
.pink-purple.dark .mat-body-1 p,
.pink-purple.dark .mat-typography .mat-body p,
.pink-purple.dark .mat-typography .mat-body-1 p,
.pink-purple.dark .mat-typography p {
  margin: 0 0 12px;
}
.pink-purple.dark .mat-small,
.pink-purple.dark .mat-caption,
.pink-purple.dark .mat-typography .mat-small,
.pink-purple.dark .mat-typography .mat-caption {
  font: 400 12px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.pink-purple.dark .mat-display-4,
.pink-purple.dark .mat-typography .mat-display-4 {
  font: 300 112px / 112px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}
.pink-purple.dark .mat-display-3,
.pink-purple.dark .mat-typography .mat-display-3 {
  font: 400 56px / 56px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}
.pink-purple.dark .mat-display-2,
.pink-purple.dark .mat-typography .mat-display-2 {
  font: 400 45px / 48px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}
.pink-purple.dark .mat-display-1,
.pink-purple.dark .mat-typography .mat-display-1 {
  font: 400 34px / 40px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}
.pink-purple.dark .mat-bottom-sheet-container {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.pink-purple.dark .mat-button, .pink-purple.dark .mat-raised-button, .pink-purple.dark .mat-icon-button, .pink-purple.dark .mat-stroked-button,
.pink-purple.dark .mat-flat-button, .pink-purple.dark .mat-fab, .pink-purple.dark .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.pink-purple.dark .mat-button-toggle {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-purple.dark .mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-purple.dark .mat-card-title {
  font-size: 24px;
  font-weight: 500;
}
.pink-purple.dark .mat-card-header .mat-card-title {
  font-size: 20px;
}
.pink-purple.dark .mat-card-subtitle,
.pink-purple.dark .mat-card-content {
  font-size: 14px;
}
.pink-purple.dark .mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-purple.dark .mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}
.pink-purple.dark .mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.pink-purple.dark .mat-chip .mat-chip-trailing-icon.mat-icon,
.pink-purple.dark .mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}
.pink-purple.dark .mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-purple.dark .mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}
.pink-purple.dark .mat-cell, .pink-purple.dark .mat-footer-cell {
  font-size: 14px;
}
.pink-purple.dark .mat-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-purple.dark .mat-calendar-body {
  font-size: 13px;
}
.pink-purple.dark .mat-calendar-body-label,
.pink-purple.dark .mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}
.pink-purple.dark .mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}
.pink-purple.dark .mat-dialog-title {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.pink-purple.dark .mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400;
}
.pink-purple.dark .mat-expansion-panel-content {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.pink-purple.dark .mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.pink-purple.dark .mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}
.pink-purple.dark .mat-form-field-prefix .mat-icon,
.pink-purple.dark .mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.pink-purple.dark .mat-form-field-prefix .mat-icon-button,
.pink-purple.dark .mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.pink-purple.dark .mat-form-field-prefix .mat-icon-button .mat-icon,
.pink-purple.dark .mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}
.pink-purple.dark .mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}
.pink-purple.dark .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.pink-purple.dark .mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34353em) scale(0.75);
  width: 133.3335533333%;
}
.pink-purple.dark .mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34352em) scale(0.75);
  width: 133.3335633333%;
}
.pink-purple.dark .mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}
.pink-purple.dark .mat-form-field-label {
  top: 1.34375em;
}
.pink-purple.dark .mat-form-field-underline {
  bottom: 1.34375em;
}
.pink-purple.dark .mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}
.pink-purple.dark .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.pink-purple.dark .mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.pink-purple.dark .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.pink-purple.dark .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00166px);
  width: 133.3339933333%;
}
.pink-purple.dark .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00167px);
  width: 133.3340033333%;
}
.pink-purple.dark .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00168px);
  width: 133.3340133333%;
}
.pink-purple.dark .mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.pink-purple.dark .mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.pink-purple.dark .mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}
@media print {
  .pink-purple.dark .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.pink-purple.dark .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28056em) scale(0.75);
  }
  .pink-purple.dark .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28055em) scale(0.75);
  }
  .pink-purple.dark .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28054em) scale(0.75);
  }
}
.pink-purple.dark .mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.pink-purple.dark .mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.pink-purple.dark .mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.pink-purple.dark .mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59353em) scale(0.75);
  width: 133.3335533333%;
}
.pink-purple.dark .mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59352em) scale(0.75);
  width: 133.3335633333%;
}
.pink-purple.dark .mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.pink-purple.dark .mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.pink-purple.dark .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.pink-purple.dark .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59353em) scale(0.75);
  width: 133.3335533333%;
}
.pink-purple.dark .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59352em) scale(0.75);
  width: 133.3335633333%;
}
.pink-purple.dark .mat-grid-tile-header,
.pink-purple.dark .mat-grid-tile-footer {
  font-size: 14px;
}
.pink-purple.dark .mat-grid-tile-header .mat-line,
.pink-purple.dark .mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.pink-purple.dark .mat-grid-tile-header .mat-line:nth-child(n+2),
.pink-purple.dark .mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.pink-purple.dark input.mat-input-element {
  margin-top: -0.0625em;
}
.pink-purple.dark .mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
}
.pink-purple.dark .mat-paginator,
.pink-purple.dark .mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
}
.pink-purple.dark .mat-radio-button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-purple.dark .mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-purple.dark .mat-select-trigger {
  height: 1.125em;
}
.pink-purple.dark .mat-slide-toggle-content {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-purple.dark .mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}
.pink-purple.dark .mat-stepper-vertical, .pink-purple.dark .mat-stepper-horizontal {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-purple.dark .mat-step-label {
  font-size: 14px;
  font-weight: 400;
}
.pink-purple.dark .mat-step-sub-label-error {
  font-weight: normal;
}
.pink-purple.dark .mat-step-label-error {
  font-size: 14px;
}
.pink-purple.dark .mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}
.pink-purple.dark .mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-purple.dark .mat-tab-label, .pink-purple.dark .mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.pink-purple.dark .mat-toolbar,
.pink-purple.dark .mat-toolbar h1,
.pink-purple.dark .mat-toolbar h2,
.pink-purple.dark .mat-toolbar h3,
.pink-purple.dark .mat-toolbar h4,
.pink-purple.dark .mat-toolbar h5,
.pink-purple.dark .mat-toolbar h6 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0;
}
.pink-purple.dark .mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.pink-purple.dark .mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.pink-purple.dark .mat-list-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-purple.dark .mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-purple.dark .mat-list-base .mat-list-item {
  font-size: 16px;
}
.pink-purple.dark .mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.pink-purple.dark .mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.pink-purple.dark .mat-list-base .mat-list-option {
  font-size: 16px;
}
.pink-purple.dark .mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.pink-purple.dark .mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.pink-purple.dark .mat-list-base .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.pink-purple.dark .mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.pink-purple.dark .mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.pink-purple.dark .mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.pink-purple.dark .mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.pink-purple.dark .mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.pink-purple.dark .mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.pink-purple.dark .mat-list-base[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}
.pink-purple.dark .mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
}
.pink-purple.dark .mat-optgroup-label {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.pink-purple.dark .mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
}
.pink-purple.dark .mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}
.pink-purple.dark .mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-purple.dark .mat-tree-node,
.pink-purple.dark .mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}
.pink-purple.dark .mat-ripple {
  overflow: hidden;
  position: relative;
}
.pink-purple.dark .mat-ripple:not(:empty) {
  transform: translateZ(0);
}
.pink-purple.dark .mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}
.pink-purple.dark .mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .pink-purple.dark .mat-ripple-element {
  display: none;
}

.pink-purple.dark .cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .pink-purple.dark .cdk-visually-hidden {
  left: auto;
  right: 0;
}
.pink-purple.dark .cdk-overlay-container, .pink-purple.dark .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.pink-purple.dark .cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.pink-purple.dark .cdk-overlay-container:empty {
  display: none;
}
.pink-purple.dark .cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}
.pink-purple.dark .cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}
.pink-purple.dark .cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.pink-purple.dark .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .pink-purple.dark .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}
.pink-purple.dark .cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}
.pink-purple.dark .cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.pink-purple.dark .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}
.pink-purple.dark .cdk-overlay-backdrop-noop-animation {
  transition: none;
}
.pink-purple.dark .cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}
.pink-purple.dark .cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}
.pink-purple.dark textarea.cdk-textarea-autosize {
  resize: none;
}
.pink-purple.dark textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}
.pink-purple.dark textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}
@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.pink-purple.dark .cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}
.pink-purple.dark .cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}
.pink-purple.dark .mat-focus-indicator {
  position: relative;
}
.pink-purple.dark .mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.pink-purple.dark .mat-focus-indicator:focus::before {
  content: "";
}
.cdk-high-contrast-active .pink-purple.dark {
  --mat-focus-indicator-display: block;
}

.pink-purple.dark .mat-mdc-focus-indicator {
  position: relative;
}
.pink-purple.dark .mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.pink-purple.dark .mat-mdc-focus-indicator:focus::before {
  content: "";
}
.cdk-high-contrast-active .pink-purple.dark {
  --mat-mdc-focus-indicator-display: block;
}

.pink-purple.dark .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.pink-purple.dark .mat-option {
  color: white;
}
.pink-purple.dark .mat-option:hover:not(.mat-option-disabled), .pink-purple.dark .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.pink-purple.dark .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.pink-purple.dark .mat-option.mat-active {
  background: rgba(255, 255, 255, 0.04);
  color: white;
}
.pink-purple.dark .mat-option.mat-option-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.pink-purple.dark .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f48fb1;
}
.pink-purple.dark .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ce93d8;
}
.pink-purple.dark .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #e57373;
}
.pink-purple.dark .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.7);
}
.pink-purple.dark .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.5);
}
.pink-purple.dark .mat-pseudo-checkbox {
  color: rgba(255, 255, 255, 0.7);
}
.pink-purple.dark .mat-pseudo-checkbox::after {
  color: #303030;
}
.pink-purple.dark .mat-pseudo-checkbox-disabled {
  color: #686868;
}
.pink-purple.dark .mat-primary .mat-pseudo-checkbox-checked,
.pink-purple.dark .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #f48fb1;
}
.pink-purple.dark .mat-pseudo-checkbox-checked,
.pink-purple.dark .mat-pseudo-checkbox-indeterminate,
.pink-purple.dark .mat-accent .mat-pseudo-checkbox-checked,
.pink-purple.dark .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #ce93d8;
}
.pink-purple.dark .mat-warn .mat-pseudo-checkbox-checked,
.pink-purple.dark .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #e57373;
}
.pink-purple.dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.pink-purple.dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #686868;
}
.pink-purple.dark .mat-app-background, .pink-purple.dark.mat-app-background {
  background-color: #303030;
  color: white;
}
.pink-purple.dark .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.pink-purple.dark .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.pink-purple.dark .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.pink-purple.dark .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.pink-purple.dark .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.pink-purple.dark .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.pink-purple.dark .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.pink-purple.dark .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.pink-purple.dark .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.pink-purple.dark .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.pink-purple.dark .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.pink-purple.dark .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.pink-purple.dark .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.pink-purple.dark .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.pink-purple.dark .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.pink-purple.dark .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.pink-purple.dark .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.pink-purple.dark .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.pink-purple.dark .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.pink-purple.dark .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.pink-purple.dark .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.pink-purple.dark .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.pink-purple.dark .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.pink-purple.dark .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.pink-purple.dark .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.pink-purple.dark .mat-autocomplete-panel {
  background: #424242;
  color: white;
}
.pink-purple.dark .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.pink-purple.dark .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: #424242;
}
.pink-purple.dark .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: white;
}
.pink-purple.dark .mat-badge-content {
  color: rgba(0, 0, 0, 0.87);
  background: #f48fb1;
}
.cdk-high-contrast-active .pink-purple.dark .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.pink-purple.dark .mat-badge-accent .mat-badge-content {
  background: #ce93d8;
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark .mat-badge-warn .mat-badge-content {
  color: rgba(0, 0, 0, 0.87);
  background: #e57373;
}
.pink-purple.dark .mat-badge-disabled .mat-badge-content {
  background: #6e6e6e;
  color: rgba(255, 255, 255, 0.5);
}
.pink-purple.dark .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.pink-purple.dark .mat-button, .pink-purple.dark .mat-icon-button, .pink-purple.dark .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.pink-purple.dark .mat-button.mat-primary, .pink-purple.dark .mat-icon-button.mat-primary, .pink-purple.dark .mat-stroked-button.mat-primary {
  color: #f48fb1;
}
.pink-purple.dark .mat-button.mat-accent, .pink-purple.dark .mat-icon-button.mat-accent, .pink-purple.dark .mat-stroked-button.mat-accent {
  color: #ce93d8;
}
.pink-purple.dark .mat-button.mat-warn, .pink-purple.dark .mat-icon-button.mat-warn, .pink-purple.dark .mat-stroked-button.mat-warn {
  color: #e57373;
}
.pink-purple.dark .mat-button.mat-primary.mat-button-disabled, .pink-purple.dark .mat-button.mat-accent.mat-button-disabled, .pink-purple.dark .mat-button.mat-warn.mat-button-disabled, .pink-purple.dark .mat-button.mat-button-disabled.mat-button-disabled, .pink-purple.dark .mat-icon-button.mat-primary.mat-button-disabled, .pink-purple.dark .mat-icon-button.mat-accent.mat-button-disabled, .pink-purple.dark .mat-icon-button.mat-warn.mat-button-disabled, .pink-purple.dark .mat-icon-button.mat-button-disabled.mat-button-disabled, .pink-purple.dark .mat-stroked-button.mat-primary.mat-button-disabled, .pink-purple.dark .mat-stroked-button.mat-accent.mat-button-disabled, .pink-purple.dark .mat-stroked-button.mat-warn.mat-button-disabled, .pink-purple.dark .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.pink-purple.dark .mat-button.mat-primary .mat-button-focus-overlay, .pink-purple.dark .mat-icon-button.mat-primary .mat-button-focus-overlay, .pink-purple.dark .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #f48fb1;
}
.pink-purple.dark .mat-button.mat-accent .mat-button-focus-overlay, .pink-purple.dark .mat-icon-button.mat-accent .mat-button-focus-overlay, .pink-purple.dark .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #ce93d8;
}
.pink-purple.dark .mat-button.mat-warn .mat-button-focus-overlay, .pink-purple.dark .mat-icon-button.mat-warn .mat-button-focus-overlay, .pink-purple.dark .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #e57373;
}
.pink-purple.dark .mat-button.mat-button-disabled .mat-button-focus-overlay, .pink-purple.dark .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .pink-purple.dark .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.pink-purple.dark .mat-button .mat-ripple-element, .pink-purple.dark .mat-icon-button .mat-ripple-element, .pink-purple.dark .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.pink-purple.dark .mat-button-focus-overlay {
  background: white;
}
.pink-purple.dark .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(255, 255, 255, 0.12);
}
.pink-purple.dark .mat-flat-button, .pink-purple.dark .mat-raised-button, .pink-purple.dark .mat-fab, .pink-purple.dark .mat-mini-fab {
  color: white;
  background-color: #424242;
}
.pink-purple.dark .mat-flat-button.mat-primary, .pink-purple.dark .mat-raised-button.mat-primary, .pink-purple.dark .mat-fab.mat-primary, .pink-purple.dark .mat-mini-fab.mat-primary {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark .mat-flat-button.mat-accent, .pink-purple.dark .mat-raised-button.mat-accent, .pink-purple.dark .mat-fab.mat-accent, .pink-purple.dark .mat-mini-fab.mat-accent {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark .mat-flat-button.mat-warn, .pink-purple.dark .mat-raised-button.mat-warn, .pink-purple.dark .mat-fab.mat-warn, .pink-purple.dark .mat-mini-fab.mat-warn {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark .mat-flat-button.mat-primary.mat-button-disabled, .pink-purple.dark .mat-flat-button.mat-accent.mat-button-disabled, .pink-purple.dark .mat-flat-button.mat-warn.mat-button-disabled, .pink-purple.dark .mat-flat-button.mat-button-disabled.mat-button-disabled, .pink-purple.dark .mat-raised-button.mat-primary.mat-button-disabled, .pink-purple.dark .mat-raised-button.mat-accent.mat-button-disabled, .pink-purple.dark .mat-raised-button.mat-warn.mat-button-disabled, .pink-purple.dark .mat-raised-button.mat-button-disabled.mat-button-disabled, .pink-purple.dark .mat-fab.mat-primary.mat-button-disabled, .pink-purple.dark .mat-fab.mat-accent.mat-button-disabled, .pink-purple.dark .mat-fab.mat-warn.mat-button-disabled, .pink-purple.dark .mat-fab.mat-button-disabled.mat-button-disabled, .pink-purple.dark .mat-mini-fab.mat-primary.mat-button-disabled, .pink-purple.dark .mat-mini-fab.mat-accent.mat-button-disabled, .pink-purple.dark .mat-mini-fab.mat-warn.mat-button-disabled, .pink-purple.dark .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.pink-purple.dark .mat-flat-button.mat-primary, .pink-purple.dark .mat-raised-button.mat-primary, .pink-purple.dark .mat-fab.mat-primary, .pink-purple.dark .mat-mini-fab.mat-primary {
  background-color: #f48fb1;
}
.pink-purple.dark .mat-flat-button.mat-accent, .pink-purple.dark .mat-raised-button.mat-accent, .pink-purple.dark .mat-fab.mat-accent, .pink-purple.dark .mat-mini-fab.mat-accent {
  background-color: #ce93d8;
}
.pink-purple.dark .mat-flat-button.mat-warn, .pink-purple.dark .mat-raised-button.mat-warn, .pink-purple.dark .mat-fab.mat-warn, .pink-purple.dark .mat-mini-fab.mat-warn {
  background-color: #e57373;
}
.pink-purple.dark .mat-flat-button.mat-primary.mat-button-disabled, .pink-purple.dark .mat-flat-button.mat-accent.mat-button-disabled, .pink-purple.dark .mat-flat-button.mat-warn.mat-button-disabled, .pink-purple.dark .mat-flat-button.mat-button-disabled.mat-button-disabled, .pink-purple.dark .mat-raised-button.mat-primary.mat-button-disabled, .pink-purple.dark .mat-raised-button.mat-accent.mat-button-disabled, .pink-purple.dark .mat-raised-button.mat-warn.mat-button-disabled, .pink-purple.dark .mat-raised-button.mat-button-disabled.mat-button-disabled, .pink-purple.dark .mat-fab.mat-primary.mat-button-disabled, .pink-purple.dark .mat-fab.mat-accent.mat-button-disabled, .pink-purple.dark .mat-fab.mat-warn.mat-button-disabled, .pink-purple.dark .mat-fab.mat-button-disabled.mat-button-disabled, .pink-purple.dark .mat-mini-fab.mat-primary.mat-button-disabled, .pink-purple.dark .mat-mini-fab.mat-accent.mat-button-disabled, .pink-purple.dark .mat-mini-fab.mat-warn.mat-button-disabled, .pink-purple.dark .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(255, 255, 255, 0.12);
}
.pink-purple.dark .mat-flat-button.mat-primary .mat-ripple-element, .pink-purple.dark .mat-raised-button.mat-primary .mat-ripple-element, .pink-purple.dark .mat-fab.mat-primary .mat-ripple-element, .pink-purple.dark .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.pink-purple.dark .mat-flat-button.mat-accent .mat-ripple-element, .pink-purple.dark .mat-raised-button.mat-accent .mat-ripple-element, .pink-purple.dark .mat-fab.mat-accent .mat-ripple-element, .pink-purple.dark .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.pink-purple.dark .mat-flat-button.mat-warn .mat-ripple-element, .pink-purple.dark .mat-raised-button.mat-warn .mat-ripple-element, .pink-purple.dark .mat-fab.mat-warn .mat-ripple-element, .pink-purple.dark .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.pink-purple.dark .mat-stroked-button:not([class*=mat-elevation-z]), .pink-purple.dark .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.pink-purple.dark .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.pink-purple.dark .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.pink-purple.dark .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.pink-purple.dark .mat-fab:not([class*=mat-elevation-z]), .pink-purple.dark .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.pink-purple.dark .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .pink-purple.dark .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.pink-purple.dark .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .pink-purple.dark .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.pink-purple.dark .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.pink-purple.dark .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.pink-purple.dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.pink-purple.dark .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.pink-purple.dark .mat-button-toggle {
  color: rgba(255, 255, 255, 0.5);
}
.pink-purple.dark .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(255, 255, 255, 0.12);
}
.pink-purple.dark .mat-button-toggle-appearance-standard {
  color: white;
  background: #424242;
}
.pink-purple.dark .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: white;
}
.pink-purple.dark .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #595959;
}
.pink-purple.dark [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #595959;
}
.pink-purple.dark .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #595959;
}
.pink-purple.dark .mat-button-toggle-checked {
  background-color: #212121;
  color: rgba(255, 255, 255, 0.7);
}
.pink-purple.dark .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: white;
}
.pink-purple.dark .mat-button-toggle-disabled {
  color: rgba(255, 255, 255, 0.3);
  background-color: black;
}
.pink-purple.dark .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: #424242;
}
.pink-purple.dark .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #424242;
}
.pink-purple.dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.pink-purple.dark .mat-button-toggle-group-appearance-standard {
  border: solid 1px #595959;
}
.pink-purple.dark .mat-card {
  background: #424242;
  color: white;
}
.pink-purple.dark .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.pink-purple.dark .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.pink-purple.dark .mat-card-subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.pink-purple.dark .mat-checkbox-frame {
  border-color: rgba(255, 255, 255, 0.7);
}
.pink-purple.dark .mat-checkbox-checkmark {
  fill: #303030;
}
.pink-purple.dark .mat-checkbox-checkmark-path {
  stroke: #303030 !important;
}
.pink-purple.dark .mat-checkbox-mixedmark {
  background-color: #303030;
}
.pink-purple.dark .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .pink-purple.dark .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #f48fb1;
}
.pink-purple.dark .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .pink-purple.dark .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #ce93d8;
}
.pink-purple.dark .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .pink-purple.dark .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #e57373;
}
.pink-purple.dark .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .pink-purple.dark .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #686868;
}
.pink-purple.dark .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #686868;
}
.pink-purple.dark .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(255, 255, 255, 0.5);
}
.pink-purple.dark .mat-checkbox .mat-ripple-element {
  background-color: white;
}
.pink-purple.dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.pink-purple.dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #f48fb1;
}
.pink-purple.dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.pink-purple.dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #ce93d8;
}
.pink-purple.dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.pink-purple.dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #e57373;
}
.pink-purple.dark .mat-chip.mat-standard-chip {
  background-color: #616161;
  color: white;
}
.pink-purple.dark .mat-chip.mat-standard-chip .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.pink-purple.dark .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.pink-purple.dark .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.pink-purple.dark .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.pink-purple.dark .mat-chip.mat-standard-chip::after {
  background: white;
}
.pink-purple.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #f48fb1;
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.pink-purple.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.pink-purple.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #e57373;
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.pink-purple.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.pink-purple.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #ce93d8;
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.pink-purple.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.pink-purple.dark .mat-table {
  background: #424242;
}
.pink-purple.dark .mat-table thead, .pink-purple.dark .mat-table tbody, .pink-purple.dark .mat-table tfoot,
.pink-purple.dark mat-header-row, .pink-purple.dark mat-row, .pink-purple.dark mat-footer-row,
.pink-purple.dark [mat-header-row], .pink-purple.dark [mat-row], .pink-purple.dark [mat-footer-row],
.pink-purple.dark .mat-table-sticky {
  background: inherit;
}
.pink-purple.dark mat-row, .pink-purple.dark mat-header-row, .pink-purple.dark mat-footer-row,
.pink-purple.dark th.mat-header-cell, .pink-purple.dark td.mat-cell, .pink-purple.dark td.mat-footer-cell {
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
.pink-purple.dark .mat-header-cell {
  color: rgba(255, 255, 255, 0.7);
}
.pink-purple.dark .mat-cell, .pink-purple.dark .mat-footer-cell {
  color: white;
}
.pink-purple.dark .mat-calendar-arrow {
  fill: white;
}
.pink-purple.dark .mat-datepicker-toggle,
.pink-purple.dark .mat-datepicker-content .mat-calendar-next-button,
.pink-purple.dark .mat-datepicker-content .mat-calendar-previous-button {
  color: white;
}
.pink-purple.dark .mat-calendar-table-header-divider::after {
  background: rgba(255, 255, 255, 0.12);
}
.pink-purple.dark .mat-calendar-table-header,
.pink-purple.dark .mat-calendar-body-label {
  color: rgba(255, 255, 255, 0.7);
}
.pink-purple.dark .mat-calendar-body-cell-content,
.pink-purple.dark .mat-date-range-input-separator {
  color: white;
  border-color: transparent;
}
.pink-purple.dark .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(255, 255, 255, 0.5);
}
.pink-purple.dark .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(255, 255, 255, 0.5);
}
.pink-purple.dark .mat-calendar-body-in-preview {
  color: rgba(255, 255, 255, 0.24);
}
.pink-purple.dark .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.5);
}
.pink-purple.dark .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.3);
}
.pink-purple.dark .mat-calendar-body-in-range::before {
  background: rgba(244, 143, 177, 0.2);
}
.pink-purple.dark .mat-calendar-body-comparison-identical,
.pink-purple.dark .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.pink-purple.dark .mat-calendar-body-comparison-bridge-start::before,
.pink-purple.dark [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 143, 177, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.pink-purple.dark .mat-calendar-body-comparison-bridge-end::before,
.pink-purple.dark [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 143, 177, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.pink-purple.dark .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.pink-purple.dark .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.pink-purple.dark .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.pink-purple.dark .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.pink-purple.dark .mat-calendar-body-selected {
  background-color: #f48fb1;
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 143, 177, 0.4);
}
.pink-purple.dark .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.pink-purple.dark .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.pink-purple.dark .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(244, 143, 177, 0.3);
}
@media (hover: hover) {
  .pink-purple.dark .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(244, 143, 177, 0.3);
  }
}
.pink-purple.dark .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #424242;
  color: white;
}
.pink-purple.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(206, 147, 216, 0.2);
}
.pink-purple.dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.pink-purple.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.pink-purple.dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.pink-purple.dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(206, 147, 216, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.pink-purple.dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.pink-purple.dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(206, 147, 216, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.pink-purple.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.pink-purple.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.pink-purple.dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.pink-purple.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.pink-purple.dark .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #ce93d8;
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(206, 147, 216, 0.4);
}
.pink-purple.dark .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.pink-purple.dark .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.pink-purple.dark .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(206, 147, 216, 0.3);
}
@media (hover: hover) {
  .pink-purple.dark .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(206, 147, 216, 0.3);
  }
}
.pink-purple.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(229, 115, 115, 0.2);
}
.pink-purple.dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.pink-purple.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.pink-purple.dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.pink-purple.dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(229, 115, 115, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.pink-purple.dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.pink-purple.dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(229, 115, 115, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.pink-purple.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.pink-purple.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.pink-purple.dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.pink-purple.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.pink-purple.dark .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #e57373;
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(229, 115, 115, 0.4);
}
.pink-purple.dark .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.pink-purple.dark .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.pink-purple.dark .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(229, 115, 115, 0.3);
}
@media (hover: hover) {
  .pink-purple.dark .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(229, 115, 115, 0.3);
  }
}
.pink-purple.dark .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.pink-purple.dark .mat-datepicker-toggle-active {
  color: #f48fb1;
}
.pink-purple.dark .mat-datepicker-toggle-active.mat-accent {
  color: #ce93d8;
}
.pink-purple.dark .mat-datepicker-toggle-active.mat-warn {
  color: #e57373;
}
.pink-purple.dark .mat-date-range-input-inner[disabled] {
  color: rgba(255, 255, 255, 0.5);
}
.pink-purple.dark .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.pink-purple.dark .mat-divider {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.pink-purple.dark .mat-divider-vertical {
  border-right-color: rgba(255, 255, 255, 0.12);
}
.pink-purple.dark .mat-expansion-panel {
  background: #424242;
  color: white;
}
.pink-purple.dark .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.pink-purple.dark .mat-action-row {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.pink-purple.dark .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .pink-purple.dark .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .pink-purple.dark .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(255, 255, 255, 0.04);
}
@media (hover: none) {
  .pink-purple.dark .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: #424242;
  }
}
.pink-purple.dark .mat-expansion-panel-header-title {
  color: white;
}
.pink-purple.dark .mat-expansion-panel-header-description,
.pink-purple.dark .mat-expansion-indicator::after {
  color: rgba(255, 255, 255, 0.7);
}
.pink-purple.dark .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(255, 255, 255, 0.3);
}
.pink-purple.dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.pink-purple.dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.pink-purple.dark .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.pink-purple.dark .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.pink-purple.dark .mat-form-field.mat-focused .mat-form-field-label {
  color: #f48fb1;
}
.pink-purple.dark .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #ce93d8;
}
.pink-purple.dark .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #e57373;
}
.pink-purple.dark .mat-focused .mat-form-field-required-marker {
  color: #ce93d8;
}
.pink-purple.dark .mat-form-field-ripple {
  background-color: white;
}
.pink-purple.dark .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #f48fb1;
}
.pink-purple.dark .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #ce93d8;
}
.pink-purple.dark .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #e57373;
}
.pink-purple.dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #f48fb1;
}
.pink-purple.dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #ce93d8;
}
.pink-purple.dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #e57373;
}
.pink-purple.dark .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #e57373;
}
.pink-purple.dark .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.pink-purple.dark .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #e57373;
}
.pink-purple.dark .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.pink-purple.dark .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #e57373;
}
.pink-purple.dark .mat-error {
  color: #e57373;
}
.pink-purple.dark .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.pink-purple.dark .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.pink-purple.dark .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.pink-purple.dark .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.pink-purple.dark .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.pink-purple.dark .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.pink-purple.dark .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.1);
}
.pink-purple.dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.05);
}
.pink-purple.dark .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(255, 255, 255, 0.5);
}
.pink-purple.dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.pink-purple.dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.pink-purple.dark .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.3);
}
.pink-purple.dark .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: white;
}
.pink-purple.dark .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #f48fb1;
}
.pink-purple.dark .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #ce93d8;
}
.pink-purple.dark .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #e57373;
}
.pink-purple.dark .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #e57373;
}
.pink-purple.dark .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.pink-purple.dark .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.15);
}
.pink-purple.dark .mat-icon.mat-primary {
  color: #f48fb1;
}
.pink-purple.dark .mat-icon.mat-accent {
  color: #ce93d8;
}
.pink-purple.dark .mat-icon.mat-warn {
  color: #e57373;
}
.pink-purple.dark .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.7);
}
.pink-purple.dark .mat-input-element:disabled,
.pink-purple.dark .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.5);
}
.pink-purple.dark .mat-input-element {
  caret-color: #f48fb1;
}
.pink-purple.dark .mat-input-element::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.pink-purple.dark .mat-input-element::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.pink-purple.dark .mat-input-element::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.pink-purple.dark .mat-input-element:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.pink-purple.dark .mat-input-element:not(.mat-native-select-inline) option {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark .mat-input-element:not(.mat-native-select-inline) option:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.pink-purple.dark .mat-form-field.mat-accent .mat-input-element {
  caret-color: #ce93d8;
}
.pink-purple.dark .mat-form-field.mat-warn .mat-input-element,
.pink-purple.dark .mat-form-field-invalid .mat-input-element {
  caret-color: #e57373;
}
.pink-purple.dark .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #e57373;
}
.pink-purple.dark .mat-list-base .mat-list-item {
  color: white;
}
.pink-purple.dark .mat-list-base .mat-list-option {
  color: white;
}
.pink-purple.dark .mat-list-base .mat-subheader {
  color: rgba(255, 255, 255, 0.7);
}
.pink-purple.dark .mat-list-base .mat-list-item-disabled {
  background-color: rgba(255, 255, 255, 0.12);
  color: rgba(255, 255, 255, 0.5);
}
.pink-purple.dark .mat-list-option:hover, .pink-purple.dark .mat-list-option:focus,
.pink-purple.dark .mat-nav-list .mat-list-item:hover,
.pink-purple.dark .mat-nav-list .mat-list-item:focus,
.pink-purple.dark .mat-action-list .mat-list-item:hover,
.pink-purple.dark .mat-action-list .mat-list-item:focus {
  background: rgba(255, 255, 255, 0.04);
}
.pink-purple.dark .mat-list-single-selected-option, .pink-purple.dark .mat-list-single-selected-option:hover, .pink-purple.dark .mat-list-single-selected-option:focus {
  background: rgba(255, 255, 255, 0.12);
}
.pink-purple.dark .mat-menu-panel {
  background: #424242;
}
.pink-purple.dark .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.pink-purple.dark .mat-menu-item {
  background: transparent;
  color: white;
}
.pink-purple.dark .mat-menu-item[disabled],
.pink-purple.dark .mat-menu-item[disabled] .mat-menu-submenu-icon,
.pink-purple.dark .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(255, 255, 255, 0.5);
}
.pink-purple.dark .mat-menu-item .mat-icon-no-color,
.pink-purple.dark .mat-menu-submenu-icon {
  color: white;
}
.pink-purple.dark .mat-menu-item:hover:not([disabled]),
.pink-purple.dark .mat-menu-item.cdk-program-focused:not([disabled]),
.pink-purple.dark .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.pink-purple.dark .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(255, 255, 255, 0.04);
}
.pink-purple.dark .mat-paginator {
  background: #424242;
}
.pink-purple.dark .mat-paginator,
.pink-purple.dark .mat-paginator-page-size .mat-select-trigger {
  color: rgba(255, 255, 255, 0.7);
}
.pink-purple.dark .mat-paginator-decrement,
.pink-purple.dark .mat-paginator-increment {
  border-top: 2px solid white;
  border-right: 2px solid white;
}
.pink-purple.dark .mat-paginator-first,
.pink-purple.dark .mat-paginator-last {
  border-top: 2px solid white;
}
.pink-purple.dark .mat-icon-button[disabled] .mat-paginator-decrement,
.pink-purple.dark .mat-icon-button[disabled] .mat-paginator-increment,
.pink-purple.dark .mat-icon-button[disabled] .mat-paginator-first,
.pink-purple.dark .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(255, 255, 255, 0.5);
}
.pink-purple.dark .mat-progress-bar-background {
  fill: #614850;
}
.pink-purple.dark .mat-progress-bar-buffer {
  background-color: #614850;
}
.pink-purple.dark .mat-progress-bar-fill::after {
  background-color: #f48fb1;
}
.pink-purple.dark .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #58495a;
}
.pink-purple.dark .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #58495a;
}
.pink-purple.dark .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #ce93d8;
}
.pink-purple.dark .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #5d4141;
}
.pink-purple.dark .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #5d4141;
}
.pink-purple.dark .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #e57373;
}
.pink-purple.dark .mat-progress-spinner circle, .pink-purple.dark .mat-spinner circle {
  stroke: #f48fb1;
}
.pink-purple.dark .mat-progress-spinner.mat-accent circle, .pink-purple.dark .mat-spinner.mat-accent circle {
  stroke: #ce93d8;
}
.pink-purple.dark .mat-progress-spinner.mat-warn circle, .pink-purple.dark .mat-spinner.mat-warn circle {
  stroke: #e57373;
}
.pink-purple.dark .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.7);
}
.pink-purple.dark .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f48fb1;
}
.pink-purple.dark .mat-radio-button.mat-primary .mat-radio-inner-circle,
.pink-purple.dark .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .pink-purple.dark .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .pink-purple.dark .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #f48fb1;
}
.pink-purple.dark .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ce93d8;
}
.pink-purple.dark .mat-radio-button.mat-accent .mat-radio-inner-circle,
.pink-purple.dark .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .pink-purple.dark .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .pink-purple.dark .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #ce93d8;
}
.pink-purple.dark .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e57373;
}
.pink-purple.dark .mat-radio-button.mat-warn .mat-radio-inner-circle,
.pink-purple.dark .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .pink-purple.dark .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .pink-purple.dark .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #e57373;
}
.pink-purple.dark .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.pink-purple.dark .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.5);
}
.pink-purple.dark .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.pink-purple.dark .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(255, 255, 255, 0.5);
}
.pink-purple.dark .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(255, 255, 255, 0.5);
}
.pink-purple.dark .mat-radio-button .mat-ripple-element {
  background-color: white;
}
.pink-purple.dark .mat-select-value {
  color: white;
}
.pink-purple.dark .mat-select-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.pink-purple.dark .mat-select-disabled .mat-select-value {
  color: rgba(255, 255, 255, 0.5);
}
.pink-purple.dark .mat-select-arrow {
  color: rgba(255, 255, 255, 0.7);
}
.pink-purple.dark .mat-select-panel {
  background: #424242;
}
.pink-purple.dark .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.pink-purple.dark .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(255, 255, 255, 0.12);
}
.pink-purple.dark .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #f48fb1;
}
.pink-purple.dark .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #ce93d8;
}
.pink-purple.dark .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #e57373;
}
.pink-purple.dark .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #e57373;
}
.pink-purple.dark .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(255, 255, 255, 0.5);
}
.pink-purple.dark .mat-drawer-container {
  background-color: #303030;
  color: white;
}
.pink-purple.dark .mat-drawer {
  background-color: #424242;
  color: white;
}
.pink-purple.dark .mat-drawer.mat-drawer-push {
  background-color: #424242;
}
.pink-purple.dark .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.pink-purple.dark .mat-drawer-side {
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.pink-purple.dark .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.pink-purple.dark [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.pink-purple.dark [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.pink-purple.dark .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(189, 189, 189, 0.6);
}
.pink-purple.dark .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #ce93d8;
}
.pink-purple.dark .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(206, 147, 216, 0.54);
}
.pink-purple.dark .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #ce93d8;
}
.pink-purple.dark .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #f48fb1;
}
.pink-purple.dark .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(244, 143, 177, 0.54);
}
.pink-purple.dark .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #f48fb1;
}
.pink-purple.dark .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #e57373;
}
.pink-purple.dark .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(229, 115, 115, 0.54);
}
.pink-purple.dark .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #e57373;
}
.pink-purple.dark .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: white;
}
.pink-purple.dark .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #bdbdbd;
}
.pink-purple.dark .mat-slide-toggle-bar {
  background-color: rgba(255, 255, 255, 0.5);
}
.pink-purple.dark .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.pink-purple.dark .mat-slider.mat-primary .mat-slider-track-fill,
.pink-purple.dark .mat-slider.mat-primary .mat-slider-thumb,
.pink-purple.dark .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #f48fb1;
}
.pink-purple.dark .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(244, 143, 177, 0.2);
}
.pink-purple.dark .mat-slider.mat-accent .mat-slider-track-fill,
.pink-purple.dark .mat-slider.mat-accent .mat-slider-thumb,
.pink-purple.dark .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #ce93d8;
}
.pink-purple.dark .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(206, 147, 216, 0.2);
}
.pink-purple.dark .mat-slider.mat-warn .mat-slider-track-fill,
.pink-purple.dark .mat-slider.mat-warn .mat-slider-thumb,
.pink-purple.dark .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #e57373;
}
.pink-purple.dark .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(229, 115, 115, 0.2);
}
.pink-purple.dark .mat-slider:hover .mat-slider-track-background,
.pink-purple.dark .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.pink-purple.dark .mat-slider.mat-slider-disabled .mat-slider-track-background,
.pink-purple.dark .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.pink-purple.dark .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(255, 255, 255, 0.3);
}
.pink-purple.dark .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.pink-purple.dark .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(255, 255, 255, 0.12);
}
.pink-purple.dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.pink-purple.dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: white;
}
.pink-purple.dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.pink-purple.dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(255, 255, 255, 0.3);
}
.pink-purple.dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
  background-color: transparent;
}
.pink-purple.dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .pink-purple.dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.pink-purple.dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .pink-purple.dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.pink-purple.dark .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(255, 255, 255, 0.7);
}
.pink-purple.dark .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.pink-purple.dark .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.pink-purple.dark .mat-step-header.cdk-keyboard-focused, .pink-purple.dark .mat-step-header.cdk-program-focused, .pink-purple.dark .mat-step-header:hover:not([aria-disabled]), .pink-purple.dark .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(255, 255, 255, 0.04);
}
.pink-purple.dark .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .pink-purple.dark .mat-step-header:hover {
    background: none;
  }
}
.pink-purple.dark .mat-step-header .mat-step-label,
.pink-purple.dark .mat-step-header .mat-step-optional {
  color: rgba(255, 255, 255, 0.7);
}
.pink-purple.dark .mat-step-header .mat-step-icon {
  background-color: rgba(255, 255, 255, 0.7);
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark .mat-step-header .mat-step-icon-selected,
.pink-purple.dark .mat-step-header .mat-step-icon-state-done,
.pink-purple.dark .mat-step-header .mat-step-icon-state-edit {
  background-color: #f48fb1;
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark .mat-step-header.mat-accent .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark .mat-step-header.mat-accent .mat-step-icon-selected,
.pink-purple.dark .mat-step-header.mat-accent .mat-step-icon-state-done,
.pink-purple.dark .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #ce93d8;
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark .mat-step-header.mat-warn .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark .mat-step-header.mat-warn .mat-step-icon-selected,
.pink-purple.dark .mat-step-header.mat-warn .mat-step-icon-state-done,
.pink-purple.dark .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #e57373;
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #e57373;
}
.pink-purple.dark .mat-step-header .mat-step-label.mat-step-label-active {
  color: white;
}
.pink-purple.dark .mat-step-header .mat-step-label.mat-step-label-error {
  color: #e57373;
}
.pink-purple.dark .mat-stepper-horizontal, .pink-purple.dark .mat-stepper-vertical {
  background-color: #424242;
}
.pink-purple.dark .mat-stepper-vertical-line::before {
  border-left-color: rgba(255, 255, 255, 0.12);
}
.pink-purple.dark .mat-horizontal-stepper-header::before,
.pink-purple.dark .mat-horizontal-stepper-header::after,
.pink-purple.dark .mat-stepper-horizontal-line {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.pink-purple.dark .mat-sort-header-arrow {
  color: #c6c6c6;
}
.pink-purple.dark .mat-tab-nav-bar,
.pink-purple.dark .mat-tab-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark .mat-tab-group-inverted-header .mat-tab-nav-bar,
.pink-purple.dark .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  border-bottom: none;
}
.pink-purple.dark .mat-tab-label, .pink-purple.dark .mat-tab-link {
  color: white;
}
.pink-purple.dark .mat-tab-label.mat-tab-disabled, .pink-purple.dark .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.pink-purple.dark .mat-tab-header-pagination-chevron {
  border-color: white;
}
.pink-purple.dark .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.5);
}
.pink-purple.dark .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.pink-purple.dark .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.pink-purple.dark .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-purple.dark .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-purple.dark .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-purple.dark .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .pink-purple.dark .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-purple.dark .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-purple.dark .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-purple.dark .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 187, 208, 0.3);
}
.pink-purple.dark .mat-tab-group.mat-primary .mat-ink-bar, .pink-purple.dark .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #f48fb1;
}
.pink-purple.dark .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .pink-purple.dark .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .pink-purple.dark .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .pink-purple.dark .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-purple.dark .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-purple.dark .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-purple.dark .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .pink-purple.dark .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-purple.dark .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-purple.dark .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-purple.dark .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(225, 190, 231, 0.3);
}
.pink-purple.dark .mat-tab-group.mat-accent .mat-ink-bar, .pink-purple.dark .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #ce93d8;
}
.pink-purple.dark .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .pink-purple.dark .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .pink-purple.dark .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .pink-purple.dark .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-purple.dark .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-purple.dark .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-purple.dark .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .pink-purple.dark .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-purple.dark .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-purple.dark .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-purple.dark .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.pink-purple.dark .mat-tab-group.mat-warn .mat-ink-bar, .pink-purple.dark .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #e57373;
}
.pink-purple.dark .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .pink-purple.dark .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .pink-purple.dark .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .pink-purple.dark .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-purple.dark .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-purple.dark .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-purple.dark .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .pink-purple.dark .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-purple.dark .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-purple.dark .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-purple.dark .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 187, 208, 0.3);
}
.pink-purple.dark .mat-tab-group.mat-background-primary > .mat-tab-header, .pink-purple.dark .mat-tab-group.mat-background-primary > .mat-tab-link-container, .pink-purple.dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .pink-purple.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .pink-purple.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .pink-purple.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #f48fb1;
}
.pink-purple.dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .pink-purple.dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .pink-purple.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .pink-purple.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .pink-purple.dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .pink-purple.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .pink-purple.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.pink-purple.dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.pink-purple.dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.pink-purple.dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.pink-purple.dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .pink-purple.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.pink-purple.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.pink-purple.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.pink-purple.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.pink-purple.dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .pink-purple.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.pink-purple.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.pink-purple.dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.pink-purple.dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.pink-purple.dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .pink-purple.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.pink-purple.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.pink-purple.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.pink-purple.dark .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-purple.dark .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-purple.dark .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-purple.dark .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .pink-purple.dark .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-purple.dark .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-purple.dark .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-purple.dark .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(225, 190, 231, 0.3);
}
.pink-purple.dark .mat-tab-group.mat-background-accent > .mat-tab-header, .pink-purple.dark .mat-tab-group.mat-background-accent > .mat-tab-link-container, .pink-purple.dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .pink-purple.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .pink-purple.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .pink-purple.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #ce93d8;
}
.pink-purple.dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .pink-purple.dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .pink-purple.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .pink-purple.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .pink-purple.dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .pink-purple.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .pink-purple.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.pink-purple.dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.pink-purple.dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.pink-purple.dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.pink-purple.dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .pink-purple.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.pink-purple.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.pink-purple.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.pink-purple.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.pink-purple.dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .pink-purple.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.pink-purple.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.pink-purple.dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.pink-purple.dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.pink-purple.dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .pink-purple.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.pink-purple.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.pink-purple.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.pink-purple.dark .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-purple.dark .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-purple.dark .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-purple.dark .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .pink-purple.dark .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-purple.dark .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-purple.dark .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-purple.dark .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.pink-purple.dark .mat-tab-group.mat-background-warn > .mat-tab-header, .pink-purple.dark .mat-tab-group.mat-background-warn > .mat-tab-link-container, .pink-purple.dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .pink-purple.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .pink-purple.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .pink-purple.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #e57373;
}
.pink-purple.dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .pink-purple.dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .pink-purple.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .pink-purple.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .pink-purple.dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .pink-purple.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .pink-purple.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.pink-purple.dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.pink-purple.dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.pink-purple.dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.pink-purple.dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .pink-purple.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.pink-purple.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.pink-purple.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.pink-purple.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.pink-purple.dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .pink-purple.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.pink-purple.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.pink-purple.dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.pink-purple.dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.pink-purple.dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .pink-purple.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.pink-purple.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.pink-purple.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.pink-purple.dark .mat-toolbar {
  background: #212121;
  color: white;
}
.pink-purple.dark .mat-toolbar.mat-primary {
  background: #f48fb1;
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark .mat-toolbar.mat-accent {
  background: #ce93d8;
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark .mat-toolbar.mat-warn {
  background: #e57373;
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark .mat-toolbar .mat-form-field-underline,
.pink-purple.dark .mat-toolbar .mat-form-field-ripple,
.pink-purple.dark .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.pink-purple.dark .mat-toolbar .mat-form-field-label,
.pink-purple.dark .mat-toolbar .mat-focused .mat-form-field-label,
.pink-purple.dark .mat-toolbar .mat-select-value,
.pink-purple.dark .mat-toolbar .mat-select-arrow,
.pink-purple.dark .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.pink-purple.dark .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.pink-purple.dark .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.pink-purple.dark .mat-tree {
  background: #424242;
}
.pink-purple.dark .mat-tree-node,
.pink-purple.dark .mat-nested-tree-node {
  color: white;
}
.pink-purple.dark .mat-snack-bar-container {
  color: rgba(0, 0, 0, 0.87);
  background: #fafafa;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.pink-purple.dark .mat-simple-snackbar-action {
  color: inherit;
}
.pink-purple.dark *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.pink-purple.dark *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
.pink-purple.dark *::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}
.pink-purple.dark .toolbar-line.container-ou {
  background: #f48fb1;
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark .toolbar-line.container-persona {
  background: #f48fb1 !important;
}
.pink-purple.dark .toolbar-line.container-persona .persona-title {
  background: #f48fb1;
}
.pink-purple.dark .toolbar-line.container-persona .invisible-container.persona-selector {
  background: #f48fb1 !important;
}
.pink-purple.dark button.mat-icon-button:not([disabled]):hover {
  background: rgba(255, 255, 255, 0.04);
}
.pink-purple.dark .side-bar {
  background: black;
}
.pink-purple.dark .icon-stacked {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-purple.dark .icon-stacked mat-icon + mat-icon {
  text-shadow: -1px 1px 0px #f48fb1, 1px -1px 0px #f48fb1;
}
.pink-purple.dark .divider-vert {
  background: rgba(255, 255, 255, 0.12);
}
.pink-purple.dark .avatar {
  background: #c51162 !important;
}
.pink-purple.dark ngx-avatar .avatar-content {
  background: #c51162 !important;
}
.pink-purple.dark .mat-loading-window {
  background: #424242;
}
.pink-purple.dark .window-spinner {
  background: #424242;
}
.pink-purple.dark .load-window {
  background: #424242;
}
@media (min-width: 600px) and (max-width: 1300px) {
  .pink-purple.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .load-window,
.pink-purple.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .load-window.new {
    background: #424242 !important;
  }
  .pink-purple.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card {
    background: #424242 !important;
  }
  .pink-purple.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .inbox-container mat-toolbar {
    background: #f48fb1;
    color: rgba(0, 0, 0, 0.87);
  }
  .pink-purple.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .inbox-container mat-list-item.mat-list-item {
    border: 1px solid rgba(255, 255, 255, 0.12);
  }
  .pink-purple.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .detail {
    background: #424242 !important;
  }
  .pink-purple.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .detail mat-toolbar {
    background: #424242 !important;
  }
  .pink-purple.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .main.w-detail + .new-detail-container .load-window {
    background: #303030 !important;
  }
  .pink-purple.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .main.w-detail + .new-detail-container:before {
    background: rgba(0, 0, 0, 0.6);
  }
  .pink-purple.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .main.side-open + .side {
    border-left: 1px solid rgba(255, 255, 255, 0.12);
  }
  .pink-purple.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .side .container .mat-list-base {
    background: #424242 !important;
    border: 1px solid rgba(255, 255, 255, 0.12);
  }
  .pink-purple.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .new-table .mat-header-row,
.pink-purple.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .new-table .mat-table,
.pink-purple.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .document-table .mat-header-row,
.pink-purple.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .document-table .mat-table,
.pink-purple.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .status-table .mat-header-row,
.pink-purple.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .status-table .mat-table {
    background: #424242 !important;
  }
  .pink-purple.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .audit-filter .filter-container.is-floating {
    background: #303030 !important;
    border: 1px solid rgba(255, 255, 255, 0.12);
  }
  .pink-purple.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .audit-filter .filter-container.is-floating .form-body {
    background: #303030 !important;
  }
  .pink-purple.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .audit-result {
    background: #424242;
  }
  .pink-purple.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .audit-detail-container {
    background: #303030 !important;
  }
}
@media (max-width: 599px) {
  .pink-purple.dark app-document-detail .mat-toolbar {
    background: #c51162 !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .pink-purple.dark app-document-detail .mat-toolbar .mat-toolbar-row .toolbar-actions {
    background: #c51162 !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .pink-purple.dark .login-card {
    background: #424242 !important;
  }
  .pink-purple.dark .inbox-toolbar {
    background: #c51162 !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .pink-purple.dark .detail {
    background: #424242 url("../img/spinner_mobile.gif") no-repeat center center !important;
  }
  .pink-purple.dark .toolbar-line-container .toolbar-line:first-child {
    background: #c51162 !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .pink-purple.dark .toolbar-actions {
    background: #c51162 !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .pink-purple.dark mat-drawer.side-nav.enter {
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(255, 255, 255, 0.12) !important;
  }
  .pink-purple.dark .side-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0) !important;
  }
  .pink-purple.dark .filter {
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.55) 16%, rgba(0, 0, 0, 0) 100%) !important;
  }
  .pink-purple.dark button.side-open-mobile {
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .pink-purple.dark .side-nav .side-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0);
  }
  .pink-purple.dark .side-nav .filter {
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.55) 16%, rgba(0, 0, 0, 0) 100%);
  }
  .pink-purple.dark .side-nav .side-nav-container .links {
    background: #424242;
    border-top: 1px solid rgba(255, 255, 255, 0.12);
  }
  .pink-purple.dark .side-nav .side-nav-container .version {
    background: #424242 !important;
    border-top: 1px solid rgba(255, 255, 255, 0.12);
  }
  .pink-purple.dark app-file-document-view .view-container {
    background: #424242 !important;
  }
  .pink-purple.dark app-file-document-view .view-container .side-bar {
    background: #c51162 !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .pink-purple.dark app-file-document-view .view-container .view-content app-pdf-wrapper.floating {
    background: #424242;
  }
  .pink-purple.dark app-file-document-view .view-container .view-content .upload-indications {
    background: black;
  }
  .pink-purple.dark app-file-document-view .view-container .view-content app-file-document-sign .sign-cycle mat-horizontal-stepper.mat-stepper-horizontal {
    background-color: rgba(0, 0, 0, 0) !important;
  }
  .pink-purple.dark app-file-document-view .view-container .view-content app-file-document-sign .sign-cycle .sign-cycle-content .cycle-content-action .mat-ripple-element {
    background-color: rgba(255, 255, 255, 0.1) !important;
  }
  .pink-purple.dark app-pending .floating-container .inbox-container .inbox-toolbar .mat-toolbar-row .mat-avatar h3 {
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .pink-purple.dark app-pending .floating-container .detail {
    background: #424242 url("../img/spinner_mobile.gif") no-repeat center center !important;
  }
  .pink-purple.dark app-document-sign-massive .inbox-container .pending-container app-chapa.minimized .info-empty {
    background: #ce93d8;
  }
  .pink-purple.dark app-document-sign-massive .inbox-container .pending-container app-chapa.minimized .info-empty > span h2 {
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .pink-purple.dark app-document-sign-massive .inbox-container .signer.mobile {
    border-top: 1px solid rgba(255, 255, 255, 0.12);
    background: #424242;
  }
  .pink-purple.dark app-document-sign-massive .detail app-file-document-detail .view-container .mat-toolbar .mat-toolbar-row .view-actions {
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .pink-purple.dark app-add-documentation .floating-container .employee-add-documentation .inbox-container .inbox-toolbar .mat-toolbar-row .mat-avatar h3 {
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .pink-purple.dark app-add-documentation .floating-container .employee-add-documentation .detail {
    background: #424242 url("../img/spinner_mobile.gif") no-repeat center center !important;
  }
  .pink-purple.dark app-add-documentation .floating-container .employee-add-documentation .detail app-file-document-view .view-container .view-content .new-container app-file-document-upload {
    background: #424242;
  }
}
.pink-purple.dark .mat-card .mat-drawer-container {
  background: #424242 !important;
}
.pink-purple.dark .mat-drawer-container {
  background: #212121 !important;
}
.pink-purple.dark .mat-list .mat-list-item.finished-process-inbox.active {
  background: #009688 !important;
}
.pink-purple.dark .mat-list .mat-list-item.finished-process-inbox .mat-icon {
  background: #009688;
}
.pink-purple.dark .mat-list .mat-list-item.erased-process-inbox.active {
  background: #837C80 !important;
}
.pink-purple.dark .mat-list .mat-list-item.limbo-process-inbox.active {
  background: #ffc107 !important;
}
.pink-purple.dark .mat-list .mat-list-item.limbo-process-inbox .mat-icon {
  background: #ffc107;
}
.pink-purple.dark .mat-list .mat-list-item.in-process-inbox.active {
  background: #ffc107 !important;
}
.pink-purple.dark .mat-list .mat-list-item.in-process-inbox .mat-icon {
  background: #ffc107;
}
.pink-purple.dark .mat-list .mat-list-item.error-process-inbox.active {
  background: #e91e63 !important;
}
.pink-purple.dark .mat-list .mat-list-item.error-process-inbox .mat-icon {
  background: #e91e63;
}
.pink-purple.dark .mat-list .mat-list-item.active .mat-line b {
  color: #f48fb1;
}
.pink-purple.dark .mat-list .mat-list-item.active .mat-list-avatar {
  color: #f48fb1;
}
.pink-purple.dark .mat-list .mat-list-item:hover:not(.active) {
  background: rgba(255, 255, 255, 0.04);
}
.pink-purple.dark span.mat-badge-content {
  color: #e57373 !important;
}
.pink-purple.dark mat-expansion-panel.mat-expanded {
  background: #303030 !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark mat-expansion-panel.virtual-scrolling .mat-expansion-panel-content .get-more {
  color: #f48fb1;
}
.pink-purple.dark mat-expansion-panel.virtual-scrolling .mat-expansion-panel-content app-cs-paginator.get-more {
  background: #424242;
}
.pink-purple.dark mat-expansion-panel-header.mat-expanded {
  background: #424242 !important;
}
.pink-purple.dark .bottom-container {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark .load-more * {
  color: #f48fb1;
}
.pink-purple.dark .welcome-card {
  background: #424242 !important;
}
.pink-purple.dark .welcome-card .img-divider {
  background: #c51162;
}
.pink-purple.dark .mat-header-row {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  background: #424242;
}
.pink-purple.dark .mat-row {
  background: #424242;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.pink-purple.dark .mat-row .mat-cell.status-cell mat-icon.pending {
  color: #ffb74d !important;
}
.pink-purple.dark .mat-row .mat-cell.status-cell mat-icon.ok {
  color: #4db6ac !important;
}
.pink-purple.dark .mat-row .mat-cell.status-cell mat-icon.not-ok {
  color: #ba68c8 !important;
}
.pink-purple.dark .mat-row .mat-cell.status-cell mat-icon.cancelled {
  color: #795548 !important;
}
.pink-purple.dark .mat-row .mat-cell.status-cell mat-icon.rejected {
  color: #df4d4c !important;
}
.pink-purple.dark .mat-row .mat-cell.status-cell mat-icon.draft {
  color: #dedede !important;
}
.pink-purple.dark .mat-row.active, .pink-purple.dark .mat-row.active:hover {
  background: #f48fb1 !important;
}
.pink-purple.dark .mat-row.active div, .pink-purple.dark .mat-row.active:hover div {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark .mat-row.active .mat-cell.status-cell mat-icon.pending, .pink-purple.dark .mat-row.active .mat-cell.status-cell mat-icon.ok, .pink-purple.dark .mat-row.active .mat-cell.status-cell mat-icon.not-ok, .pink-purple.dark .mat-row.active:hover .mat-cell.status-cell mat-icon.pending, .pink-purple.dark .mat-row.active:hover .mat-cell.status-cell mat-icon.ok, .pink-purple.dark .mat-row.active:hover .mat-cell.status-cell mat-icon.not-ok {
  text-shadow: -1px 1px 0 rgba(0, 0, 0, 0.87), 1px 1px 0 rgba(0, 0, 0, 0.87), 1px -1px 0 rgba(0, 0, 0, 0.87), -1px -1px 0 rgba(0, 0, 0, 0.87);
}
.pink-purple.dark .mat-row.active .sticky-actions button, .pink-purple.dark .mat-row.active:hover .sticky-actions button {
  background: #f48fb1 !important;
}
.pink-purple.dark .mat-row.active .sticky-actions button.ok mat-icon, .pink-purple.dark .mat-row.active .sticky-actions button.not-ok mat-icon, .pink-purple.dark .mat-row.active:hover .sticky-actions button.ok mat-icon, .pink-purple.dark .mat-row.active:hover .sticky-actions button.not-ok mat-icon {
  text-shadow: -1px 1px 0 rgba(0, 0, 0, 0.87), 1px 1px 0 rgba(0, 0, 0, 0.87), 1px -1px 0 rgba(0, 0, 0, 0.87), -1px -1px 0 rgba(0, 0, 0, 0.87);
}
.pink-purple.dark .mat-row:nth-child(odd) {
  background: #303030;
}
.pink-purple.dark .mat-row.selected div {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark .mat-row.selected:nth-child(even) {
  background: #f48fb1 !important;
}
.pink-purple.dark .mat-row.selected:nth-child(even) .sticky-actions button {
  background: #f48fb1 !important;
}
.pink-purple.dark .mat-row.selected:nth-child(odd) {
  background: #f8bbd0 !important;
}
.pink-purple.dark .mat-row.selected:nth-child(odd) .sticky-actions button {
  background: #f8bbd0 !important;
}
.pink-purple.dark .mat-row.selected.active, .pink-purple.dark .mat-row.selected.active:hover {
  background: #f48fb1 !important;
}
.pink-purple.dark .mat-row.selected.active .sticky-actions button, .pink-purple.dark .mat-row.selected.active:hover .sticky-actions button {
  background: #f48fb1 !important;
}
.pink-purple.dark .mat-row:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-purple.dark .mat-row:hover .sticky-actions button {
  background: #424242 !important;
}
.pink-purple.dark .mat-row.canceled * {
  color: #795548;
}
.pink-purple.dark .mat-row.disabled div {
  color: #607D8B;
}
.pink-purple.dark .mat-row.disabled.active, .pink-purple.dark .mat-row.disabled.active:hover {
  background: #607D8B !important;
}
.pink-purple.dark .mat-row.disabled.active div, .pink-purple.dark .mat-row.disabled.active:hover div {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark .mat-row.disabled.active .sticky-actions button, .pink-purple.dark .mat-row.disabled.active:hover .sticky-actions button {
  background: #607D8B !important;
}
.pink-purple.dark .mat-row.disabled.selected.active:hover .sticky-actions button {
  background: #607D8B !important;
}
.pink-purple.dark .mat-row .sticky-actions button.ok mat-icon {
  color: #4db6ac !important;
}
.pink-purple.dark .mat-row .sticky-actions button.not-ok mat-icon {
  color: #ba68c8 !important;
}
.pink-purple.dark .status-table .mat-header-row {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  background: #424242;
}
.pink-purple.dark .icon-info-off,
.pink-purple.dark .certificate-off {
  color: #607D8B;
}
.pink-purple.dark .documents-search-table .mat-header-row {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  background: #424242;
}
.pink-purple.dark mat-toolbar.employee-search-sub-toolbar {
  background: #424242;
}
.pink-purple.dark .mat-search-field {
  border: 1px solid rgba(255, 255, 255, 0.12);
  background: black;
}
.pink-purple.dark .mat-search-field input[type=search] {
  background: rgba(0, 0, 0, 0);
}
.pink-purple.dark .mat-search-field input[type=search]::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.pink-purple.dark .search-adv-menu .mat-search-field {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark mat-toolbar.document-search-sub-toolbar {
  background: #424242;
}
.pink-purple.dark app-advanced-employee-search .mat-chip {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-purple.dark .side-nav .side-trigger {
  background: #424242;
}
.pink-purple.dark .side-nav .menu-trigger mat-icon {
  color: rgba(255, 255, 255, 0.7);
  background: #424242;
}
.pink-purple.dark .side-nav .side-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0);
}
.pink-purple.dark .side-nav .filter {
  background: #424242;
}
.pink-purple.dark .side-nav .side-nav-container .links mat-icon span.mat-badge-content {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-purple.dark .side-nav .side-nav-container .mat-list-base .mat-list-item:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-purple.dark .side-nav .side-nav-container .mat-list-base .mat-list-item.active {
  background: #f48fb1 !important;
}
.pink-purple.dark .side-nav .side-nav-container .mat-list-base .mat-list-item.active * {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark .side-nav .side-nav-container .mat-list-base .mat-list-item.active .mat-list-item.active:hover {
  background: #f48fb1 !important;
}
.pink-purple.dark .side-nav .side-nav-container .mat-list-base .mat-list-item.active .mat-list-item.active:hover * {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark .side-nav.open .side-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark .side-nav.open .filter {
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.55) 16%, rgba(0, 0, 0, 0) 100%);
}
.pink-purple.dark .side-nav.open .side-nav-container .links {
  background: #424242;
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark .side-nav.open .side-nav-container .version {
  background: #424242 !important;
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark .side-nav.enter .side-nav-container .links {
  background: #424242;
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark .side-nav.enter .side-nav-container .version {
  background: #424242 !important;
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark .mat-menu-panel:not(.metadata-radio-menu) .navigation-list .mat-list-item-content {
  color: rgba(255, 255, 255, 0.7);
}
.pink-purple.dark table.mat-calendar-table {
  background: #424242 !important;
}
.pink-purple.dark .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-purple.dark .mat-calendar-arrow {
  border-top-color: #424242 !important;
}
.pink-purple.dark .mat-datepicker-content .mat-calendar-next-button,
.pink-purple.dark .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-purple.dark .mat-calendar-header {
  background: #c51162 !important;
}
.pink-purple.dark mat-datepicker-content {
  background-color: #c51162 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-purple.dark .mat-calendar-content {
  background: #424242 !important;
}
.pink-purple.dark .mat-simple-snackbar-action button.mat-button {
  color: #ff9800;
}
.pink-purple.dark .mat-simple-snackbar-action .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.pink-purple.dark mat-bottom-sheet-container mat-toolbar {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12) !important;
}
.pink-purple.dark app-generic-bottom-sheet h4.accent {
  color: #ce93d8;
}
.pink-purple.dark app-generic-bottom-sheet .verification-code {
  background: #f48fb1;
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark app-generic-bottom-sheet .verification-code h2 {
  background: #f48fb1;
}
.pink-purple.dark app-generic-bottom-sheet .load-window.message .info-container h3.primary-text {
  color: #f48fb1;
}
.pink-purple.dark app-generic-bottom-sheet .bottom_sheet-content.ok .bottom_sheet-title .bottom_sheet-icon,
.pink-purple.dark app-generic-bottom-sheet .bottom_sheet-content.ok .bottom_sheet-title h3 {
  color: #009688;
}
.pink-purple.dark app-generic-bottom-sheet .bottom_sheet-content.ok .bottom_sheet-main_btn:not(:disabled) {
  background: #009688;
  color: #fff;
}
.pink-purple.dark app-generic-bottom-sheet .bottom_sheet-content.not-ok .bottom_sheet-title .bottom_sheet-icon,
.pink-purple.dark app-generic-bottom-sheet .bottom_sheet-content.not-ok .bottom_sheet-title h3 {
  color: #9C27B0;
}
.pink-purple.dark app-generic-bottom-sheet .bottom_sheet-content.not-ok .bottom_sheet-main_btn:not(:disabled) {
  background: #9C27B0;
  color: #fff;
}
.pink-purple.dark app-generic-bottom-sheet .bottom_sheet-content.cancel .bottom_sheet-title .bottom_sheet-icon,
.pink-purple.dark app-generic-bottom-sheet .bottom_sheet-content.cancel .bottom_sheet-title h3 {
  color: #795548;
}
.pink-purple.dark app-generic-bottom-sheet .bottom_sheet-content.cancel .bottom_sheet-main_btn:not(:disabled) {
  background: #795548;
  color: #fff;
}
.pink-purple.dark app-generic-bottom-sheet .bottom_sheet-content .bottom_sheet_exp_btn {
  background-color: #f48fb1;
  color: #fff;
}
.pink-purple.dark app-generic-bottom-sheet .bottom_sheet-content .bottom_sheet_canc_btn {
  background-color: lightgrey;
  color: black;
}
.pink-purple.dark app-generic-bottom-sheet .bottom_sheet-content .bottom_sheet-exp-h3 {
  color: #f48fb1;
  margin-bottom: -10px !important;
}
.pink-purple.dark app-generic-bottom-sheet .bottom_sheet-content .mat-input-element-export {
  display: flex !important;
  padding-left: 15px;
}
.pink-purple.dark app-generic-bottom-sheet .bottom_sheet-content .mat-form-field-infix-export-right {
  width: 100% !important;
  padding: 0px !important;
  display: flex !important;
}
.pink-purple.dark app-generic-bottom-sheet .bottom_sheet-content .mat-form-field-infix-export-left {
  width: 100% !important;
  padding: 0px;
  display: flex !important;
}
.pink-purple.dark app-generic-bottom-sheet .bottom_sheet-content .mat-form-field-infix {
  display: flex;
}
.pink-purple.dark app-generic-bottom-sheet .bottom_sheet-content .mat-checkbox-layout .mat-checkbox-label {
  color: #ce93d8;
}
.pink-purple.dark app-generic-bottom-sheet .bottom_sheet-content .choose-forms {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  margin-left: 10px !important;
}
.pink-purple.dark app-generic-bottom-sheet .bottom_sheet-content .card-container {
  max-height: 150px;
  overflow-y: auto;
}
.pink-purple.dark .mat-list .mat-list-item.active .mat-line {
  color: white;
}
.pink-purple.dark .mat-list .mat-list-item.active .mat-line b {
  color: #f48fb1;
}
.pink-purple.dark .mat-list .mat-list-item.active .mat-list-avatar {
  color: #f48fb1;
}
.pink-purple.dark .mat-list .mat-list-item .mat-line {
  color: rgba(255, 255, 255, 0.7);
}
.pink-purple.dark .mat-list .mat-list-item .mat-list-avatar {
  color: rgba(255, 255, 255, 0.7);
}
.pink-purple.dark .mat-list-item:hover:not(.active) {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-purple.dark mat-list-item:nth-child(even),
.pink-purple.dark app-file-document-inbox-item:nth-child(even) mat-list-item,
.pink-purple.dark app-file-document-inbox-item-documentation:nth-child(even) mat-list-item {
  background: #303030 !important;
}
.pink-purple.dark mat-list-item:nth-child(odd),
.pink-purple.dark app-file-document-inbox-item:nth-child(odd) mat-list-item,
.pink-purple.dark app-file-document-inbox-item-documentation:nth-child(odd) mat-list-item {
  background: #424242 !important;
}
.pink-purple.dark .mat-list-avatar.mat-icon {
  background: black;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark .card-filter {
  background: #424242;
}
.pink-purple.dark .login mat-horizontal-stepper {
  background: #424242 !important;
}
.pink-purple.dark .login .login-card {
  background: #424242 !important;
}
.pink-purple.dark .login .login-card .mat-card-content .mat-card-footer a[color=accent] {
  color: #ce93d8;
}
.pink-purple.dark .login .help-stepper h3.align-16 {
  color: #ce93d8;
}
.pink-purple.dark .login .help-stepper .help-options mat-icon {
  color: #ce93d8;
}
.pink-purple.dark .ux-modal {
  background: rgba(0, 0, 0, 0.32);
}
.pink-purple.dark .ux-modal .card {
  background: #424242;
}
.pink-purple.dark .main-title {
  background: #607D8B;
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark .sub-title {
  color: rgba(255, 255, 255, 0.7);
}
.pink-purple.dark .card-footer a {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark .profile-toolbar {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark app-profile .logout-btn:hover {
  background: black;
}
.pink-purple.dark app-profile .cropper {
  background: #424242;
}
.pink-purple.dark app-profile .cropper .cropper {
  background: unset;
}
.pink-purple.dark app-add-documentation .employee-add-documentation .inbox-toolbar {
  background: #303030;
}
.pink-purple.dark app-add-documentation .employee-add-documentation .inbox-toolbar .toolbar-line mat-icon {
  color: #f48fb1;
}
.pink-purple.dark app-add-documentation .employee-add-documentation .inbox-toolbar .toolbar-line h3 {
  color: #f48fb1;
}
.pink-purple.dark app-add-documentation .employee-add-documentation mat-tab-group {
  background: #303030;
}
.pink-purple.dark app-add-documentation .employee-add-documentation mat-tab-group mat-tab-header .mat-tab-label-active {
  color: #f48fb1;
}
.pink-purple.dark app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content:hover {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(255, 255, 255, 0.12) !important;
}
.pink-purple.dark app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content.selected {
  border-left: 6px solid #f48fb1 !important;
}
.pink-purple.dark app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content.selected mat-icon {
  color: #f48fb1 !important;
}
.pink-purple.dark app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content.selected .item-data {
  color: white !important;
}
.pink-purple.dark app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content mat-icon {
  color: rgba(255, 255, 255, 0.7);
}
.pink-purple.dark app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content .item-data {
  color: rgba(255, 255, 255, 0.7);
}
.pink-purple.dark app-add-documentation .employee-add-documentation .detail app-file-document-view .new-container app-file-document-upload .metadata-list .mat-subheader {
  color: rgba(255, 255, 255, 0.7);
  background: #424242 !important;
}
.pink-purple.dark app-pending .inbox-toolbar {
  background: #303030;
}
.pink-purple.dark app-pending .pending-container {
  background: #303030;
}
.pink-purple.dark app-pending .pending-container .pending-items .pending-item .content:hover {
  background: rgba(255, 255, 255, 0.04) !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(255, 255, 255, 0.12) !important;
}
.pink-purple.dark app-pending .pending-container .pending-items .pending-item .content.selected {
  border-left: 6px solid #ce93d8 !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(255, 255, 255, 0.12) !important;
}
.pink-purple.dark app-pending .pending-container .pending-items .pending-item .content.selected mat-icon {
  color: #ce93d8 !important;
}
.pink-purple.dark app-pending .pending-container .pending-items .pending-item .content.selected .item-data {
  color: white !important;
}
.pink-purple.dark app-pending .pending-container .pending-items .pending-item .content mat-icon {
  color: rgba(255, 255, 255, 0.7);
}
.pink-purple.dark app-pending .pending-container .pending-items .pending-item .content .item-data {
  color: rgba(255, 255, 255, 0.7);
}
.pink-purple.dark app-document-sign-massive .inbox-container .inbox-toolbar {
  background: #303030;
}
.pink-purple.dark app-document-sign-massive .inbox-container .pending-container {
  background: #303030;
}
.pink-purple.dark app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content:hover {
  background: rgba(255, 255, 255, 0.04) !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(255, 255, 255, 0.12) !important;
}
.pink-purple.dark app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content.selected {
  border-left: 6px solid #ce93d8 !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(255, 255, 255, 0.12) !important;
}
.pink-purple.dark app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content.selected mat-icon {
  color: #ce93d8 !important;
}
.pink-purple.dark app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content.selected .item-data {
  color: white !important;
}
.pink-purple.dark app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content mat-icon {
  color: rgba(255, 255, 255, 0.7);
}
.pink-purple.dark app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content .item-data {
  color: rgba(255, 255, 255, 0.7);
}
.pink-purple.dark app-document-sign-massive .detail app-file-document-detail .view-container {
  background: #607D8B;
}
.pink-purple.dark app-document-sign-massive .detail app-file-document-detail .view-container .mat-toolbar {
  background: #607D8B;
  color: rgba(0, 0, 0, 0.87);
  background: #607D8B;
}
.pink-purple.dark app-document-sign-massive .detail app-chapa.minimized .info-empty {
  background: #ce93d8;
}
.pink-purple.dark app-document-sign-massive .detail app-chapa.minimized .info-empty > span mat-icon {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-purple.dark app-document-sign-massive .detail app-chapa.minimized .info-empty > span h2 {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-purple.dark app-document-sign-massive .signer.desktop {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark app-document-sign-massive .signer .signer-btn {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-purple.dark app-document-sign-massive .signer .signer-btn[disabled] {
  background-color: rgba(255, 255, 255, 0.12);
}
.pink-purple.dark app-document-detail app-sign-document .firmador button.mat-raised-button.mat-accent {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-purple.dark mat-toolbar-row.firmado-conforme .mat-icon {
  background: #4caf50;
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark mat-toolbar-row.firmado-no-conforme .mat-icon {
  background: #e57373;
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark .secondary-toolbar {
  background: #424242;
}
.pink-purple.dark .new-detail-container {
  border-left: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark .new-detail-container.cancelled .detail_head-body {
  background: #607D8B !important;
}
.pink-purple.dark .new-detail-container .employee-detail-toolbar {
  background: black;
}
.pink-purple.dark .new-detail-container .employee-detail-subbar {
  background: #424242;
}
.pink-purple.dark app-employee-detail .load-window,
.pink-purple.dark app-employee-rrhh-detail .load-window {
  background: #303030;
}
.pink-purple.dark app-employee-detail .employee_detail-container,
.pink-purple.dark app-employee-rrhh-detail .employee_detail-container {
  background: #303030;
}
.pink-purple.dark app-employee-detail .employee_detail-container.is-editing .edition-line,
.pink-purple.dark app-employee-rrhh-detail .employee_detail-container.is-editing .edition-line {
  background: black;
}
.pink-purple.dark app-employee-detail .employee_detail-container .detail_head-body,
.pink-purple.dark app-employee-rrhh-detail .employee_detail-container .detail_head-body {
  background: #f48fb1;
}
.pink-purple.dark app-employee-detail .employee_detail-container .detail_head-body .head_body-status .icon-stacked + mat-icon,
.pink-purple.dark app-employee-rrhh-detail .employee_detail-container .detail_head-body .head_body-status .icon-stacked + mat-icon {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-purple.dark app-employee-detail .employee_detail-container .detail_head-body .head_body-title .body_title-name,
.pink-purple.dark app-employee-rrhh-detail .employee_detail-container .detail_head-body .head_body-title .body_title-name {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark app-employee-detail .employee_detail-container .detail_head-body .head_body-title .body_title-user,
.pink-purple.dark app-employee-rrhh-detail .employee_detail-container .detail_head-body .head_body-title .body_title-user {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark app-employee-detail .employee_detail-container .data .data-line,
.pink-purple.dark app-employee-rrhh-detail .employee_detail-container .data .data-line {
  background: #424242;
}
.pink-purple.dark app-employee-detail .employee_detail-container .data .data-line .icon-complex mat-icon.complex,
.pink-purple.dark app-employee-rrhh-detail .employee_detail-container .data .data-line .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #424242, 0 -2px 0px #424242, 2px 0 0px #424242, 0 2px 0px #424242, -2px -2px 0px #424242, 2px 2px 0px #424242, 2px -2px 0px #424242, -2px 2px 0px #424242;
}
.pink-purple.dark app-employee-detail .employee_detail-container .data .data-line .line_text-main,
.pink-purple.dark app-employee-rrhh-detail .employee_detail-container .data .data-line .line_text-main {
  color: #f48fb1;
}
.pink-purple.dark app-employee-detail .employee_detail-container .data .data-line.clickable:hover,
.pink-purple.dark app-employee-rrhh-detail .employee_detail-container .data .data-line.clickable:hover {
  background: #303030 !important;
}
.pink-purple.dark app-employee-detail .employee_detail-container .data .data-line button.mat-icon-button,
.pink-purple.dark app-employee-rrhh-detail .employee_detail-container .data .data-line button.mat-icon-button {
  background: black;
}
.pink-purple.dark app-employee-detail .employee_detail-container .employee-title .title-text,
.pink-purple.dark app-employee-rrhh-detail .employee_detail-container .employee-title .title-text {
  color: #f48fb1;
}
.pink-purple.dark app-employee-detail .employee_detail-container .employee-title .title-text mat-icon,
.pink-purple.dark app-employee-rrhh-detail .employee_detail-container .employee-title .title-text mat-icon {
  color: #f48fb1;
}
.pink-purple.dark app-employee-detail .employee_detail-container .employee-title .title-text .title_text-main,
.pink-purple.dark app-employee-rrhh-detail .employee_detail-container .employee-title .title-text .title_text-main {
  color: #f48fb1;
}
.pink-purple.dark .UI-V2 .icon-complex mat-icon.complex {
  color: #f48fb1;
  text-shadow: -2px 0 0px #424242, 0 -2px 0px #424242, 2px 0 0px #424242, 0 2px 0px #424242, -2px -2px 0px #424242, 2px 2px 0px #424242, 2px -2px 0px #424242, -2px 2px 0px #424242;
}
.pink-purple.dark .UI-V2 .icon-complex mat-icon.complex.mat-accent {
  color: #ce93d8;
}
.pink-purple.dark .UI-V2 .main .toolbar .header .content .module-title {
  color: #f48fb1;
}
.pink-purple.dark .UI-V2 .main .toolbar .header .content .module-title.selectable:hover {
  background: rgba(255, 255, 255, 0.04);
}
.pink-purple.dark .UI-V2 .main .toolbar .header .content .module-title .info h5 {
  color: white !important;
}
.pink-purple.dark .UI-V2 .main .toolbar .header .content .ou-select {
  color: rgba(0, 0, 0, 0.87);
  background: #f48fb1;
}
.pink-purple.dark .UI-V2 .main .toolbar .search-container .search .content {
  background: #303030;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark .UI-V2 .main .toolbar .search-container .search .content:hover, .pink-purple.dark .UI-V2 .main .toolbar .search-container .search .content:active, .pink-purple.dark .UI-V2 .main .toolbar .search-container .search .content:focus, .pink-purple.dark .UI-V2 .main .toolbar .search-container .search .content:focus-within {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-purple.dark .UI-V2 .main .toolbar .search-container .search .content input {
  color: white;
}
.pink-purple.dark .UI-V2 .main .helper {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark .UI-V2 .main .helper mat-hint h4 {
  color: #f48fb1;
}
.pink-purple.dark .UI-V2 .main .new-table app-cs-grid-control datatable-header * {
  color: #f48fb1;
}
.pink-purple.dark .UI-V2 .main .new-table .mat-header-row.sticky * {
  color: #f48fb1 !important;
}
.pink-purple.dark .UI-V2 .main .main-action {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark .menu-options.help .primary {
  color: #f48fb1;
}
.pink-purple.dark .menu-options.help .accent {
  color: #ce93d8;
}
.pink-purple.dark .menu-options .icon-complex mat-icon.complex {
  color: #f48fb1;
  text-shadow: -2px 0 0px #424242, 0 -2px 0px #424242, 2px 0 0px #424242, 0 2px 0px #424242, -2px -2px 0px #424242, 2px 2px 0px #424242, 2px -2px 0px #424242, -2px 2px 0px #424242;
}
.pink-purple.dark .menu-options .mat-menu-content .container mat-list .mat-list-item:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-purple.dark .menu-options .mat-menu-content .container mat-list .mat-list-item .disabled {
  color: rgba(255, 255, 255, 0.7) !important;
}
.pink-purple.dark .menu-options .mat-menu-content .container .slide-group mat-radio-group mat-radio-button:hover {
  background: rgba(255, 255, 255, 0.04);
}
.pink-purple.dark .menu-options .mat-menu-content .container .slide-group mat-form-field .mat-form-field-flex {
  background: #303030;
}
.pink-purple.dark .menu-options .mat-menu-content .container .slide-group mat-slide-toggle:hover {
  background: rgba(255, 255, 255, 0.04);
}
.pink-purple.dark .mat-menu-panel.notifications-panel .mat-menu-content .notifications-body {
  background: #303030;
}
.pink-purple.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification .primary mat-icon {
  color: #f48fb1;
}
.pink-purple.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification .primary h3 {
  color: #f48fb1;
}
.pink-purple.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.top {
  background: #303030;
}
.pink-purple.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.top .header h3 {
  color: white;
}
.pink-purple.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.bottom {
  background: #303030;
}
.pink-purple.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable {
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable:nth-child(even) {
  background: #303030;
}
.pink-purple.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable:nth-child(odd) {
  background: #424242;
}
.pink-purple.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable:hover {
  background: rgba(255, 255, 255, 0.04);
}
.pink-purple.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable mat-icon {
  color: #f48fb1;
}
.pink-purple.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable h3 {
  color: white;
}
.pink-purple.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable h5 {
  color: rgba(255, 255, 255, 0.5);
}
.pink-purple.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable .subtitle h5 {
  color: #f48fb1;
  background: black;
}
.pink-purple.dark .info-empty .large-icon {
  background: #f06292;
  color: #f48fb1;
}
.pink-purple.dark .info-empty h2,
.pink-purple.dark .info-empty h3 {
  color: #f48fb1;
}
.pink-purple.dark .info-empty h4,
.pink-purple.dark .info-empty h5 {
  color: rgba(255, 255, 255, 0.7);
}
.pink-purple.dark .info-empty.accent mat-icon {
  background: #ce93d8;
  color: #ce93d8;
}
.pink-purple.dark .info-empty.accent h2 {
  color: #ce93d8;
}
.pink-purple.dark .info-empty .actions {
  padding: 16px;
  height: 96px;
  column-gap: 16px;
}
.pink-purple.dark .info-empty .actions button {
  height: 100%;
  border-radius: 40px;
}
.pink-purple.dark .info-empty .actions button.ok {
  background: #4db6ac !important;
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark .info-empty .actions button.not-ok {
  color: #ba68c8 !important;
}
.pink-purple.dark .side.edition .info-empty .large-icon {
  background: #ce93d8;
  color: #ce93d8;
}
.pink-purple.dark .side.edition .info-empty h2 {
  color: #ce93d8;
}
.pink-purple.dark .side.edition .info-empty h4 {
  color: rgba(255, 255, 255, 0.7);
}
.pink-purple.dark .UI-V2 .deferred-process-list .header h3 {
  color: #f48fb1;
}
.pink-purple.dark .UI-V2 .deferred-process-list .search-container .search .content {
  background: #303030;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark .UI-V2 .deferred-process-list .search-container .search .content:hover, .pink-purple.dark .UI-V2 .deferred-process-list .search-container .search .content:active, .pink-purple.dark .UI-V2 .deferred-process-list .search-container .search .content:focus, .pink-purple.dark .UI-V2 .deferred-process-list .search-container .search .content:focus-within {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-purple.dark .UI-V2 .deferred-process-list .search-container .search .content input {
  color: white;
}
.pink-purple.dark .UI-V2 .deferred-process-list .text-success,
.pink-purple.dark .UI-V2 .deferred-process-details .text-success {
  color: #4caf50;
}
.pink-purple.dark .UI-V2 .deferred-process-list .text-warn,
.pink-purple.dark .UI-V2 .deferred-process-details .text-warn {
  color: #e57373;
}
.pink-purple.dark .UI-V2 .deferred-process-list .text-primary,
.pink-purple.dark .UI-V2 .deferred-process-details .text-primary {
  color: #f48fb1;
}
.pink-purple.dark .UI-V2 .deferred-process-list .text-atention,
.pink-purple.dark .UI-V2 .deferred-process-details .text-atention {
  color: #DB7703;
}
.pink-purple.dark .UI-V2 .deferred-process-list .table-paginator-container,
.pink-purple.dark .UI-V2 .deferred-process-details .table-paginator-container {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
}
.pink-purple.dark .UI-V2 .deferred-process-list .table-paginator-container .mat-table,
.pink-purple.dark .UI-V2 .deferred-process-details .table-paginator-container .mat-table {
  border-bottom: none;
  border-radius: 10px 10px 0 0;
}
.pink-purple.dark .UI-V2 .deferred-process-list .table-paginator-container .paginator-container,
.pink-purple.dark .UI-V2 .deferred-process-details .table-paginator-container .paginator-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: #f2f2f2;
  padding: 0 1.5rem;
  height: 3rem;
}
.pink-purple.dark .UI-V2 .deferred-process-list .mat-table,
.pink-purple.dark .UI-V2 .deferred-process-details .mat-table {
  border: 1px solid #f2f2f2;
  border-radius: 10px;
  overflow: hidden;
}
.pink-purple.dark .UI-V2 .deferred-process-list .mat-table .mat-header-row .mat-header-cell,
.pink-purple.dark .UI-V2 .deferred-process-details .mat-table .mat-header-row .mat-header-cell {
  font-size: 14px;
  font-weight: 700;
}
.pink-purple.dark .UI-V2 .deferred-process-list .mat-table .mat-header-row,
.pink-purple.dark .UI-V2 .deferred-process-details .mat-table .mat-header-row {
  background: #f2f2f2;
}
.pink-purple.dark .UI-V2 .deferred-process-list .mat-table .mat-header-row .mat-header-cell,
.pink-purple.dark .UI-V2 .deferred-process-details .mat-table .mat-header-row .mat-header-cell {
  color: #f48fb1;
}
.pink-purple.dark .UI-V2 .deferred-process-list .mat-table .mat-row,
.pink-purple.dark .UI-V2 .deferred-process-details .mat-table .mat-row {
  background: #424242;
}
.pink-purple.dark .UI-V2 .progress-bar-container {
  position: relative;
}
.pink-purple.dark .UI-V2 .deferred-process-details .header h3 {
  color: #f48fb1;
}
.pink-purple.dark .UI-V2 .deferred-process-details .header .back-link:visited {
  color: #f48fb1;
}
.pink-purple.dark .process-card,
.pink-purple.dark .process-card-detail,
.pink-purple.dark .process-card-stages {
  background: #303030;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark .process-card mat-card-subtitle,
.pink-purple.dark .process-card-detail mat-card-subtitle,
.pink-purple.dark .process-card-stages mat-card-subtitle {
  color: white;
}
.pink-purple.dark .process-card .stages-container .stage.completed mat-icon,
.pink-purple.dark .process-card-detail .stages-container .stage.completed mat-icon,
.pink-purple.dark .process-card-stages .stages-container .stage.completed mat-icon {
  color: #499719;
}
.pink-purple.dark .process-card .stages-container .stage.completed .icon-container,
.pink-purple.dark .process-card-detail .stages-container .stage.completed .icon-container,
.pink-purple.dark .process-card-stages .stages-container .stage.completed .icon-container {
  background-color: rgba(78, 160, 28, 0.1490196078);
}
.pink-purple.dark .process-card .stages-container .stage.completed .line,
.pink-purple.dark .process-card-detail .stages-container .stage.completed .line,
.pink-purple.dark .process-card-stages .stages-container .stage.completed .line {
  background-color: #499719;
}
.pink-purple.dark .process-card .stages-container .stage.on-going mat-icon,
.pink-purple.dark .process-card-detail .stages-container .stage.on-going mat-icon,
.pink-purple.dark .process-card-stages .stages-container .stage.on-going mat-icon {
  color: #f48fb1;
}
.pink-purple.dark .process-card .stages-container .stage.on-going .icon-container,
.pink-purple.dark .process-card-detail .stages-container .stage.on-going .icon-container,
.pink-purple.dark .process-card-stages .stages-container .stage.on-going .icon-container {
  background-color: rgba(66, 48, 159, 0.2);
}
.pink-purple.dark .process-card .stages-container .stage.on-going .line,
.pink-purple.dark .process-card-detail .stages-container .stage.on-going .line,
.pink-purple.dark .process-card-stages .stages-container .stage.on-going .line {
  background-color: #949494;
}
.pink-purple.dark .process-card .stages-container .stage.pending,
.pink-purple.dark .process-card-detail .stages-container .stage.pending,
.pink-purple.dark .process-card-stages .stages-container .stage.pending {
  color: #949494;
}
.pink-purple.dark .process-card .stages-container .stage.pending mat-icon,
.pink-purple.dark .process-card-detail .stages-container .stage.pending mat-icon,
.pink-purple.dark .process-card-stages .stages-container .stage.pending mat-icon {
  color: #949494;
}
.pink-purple.dark .process-card .stages-container .stage.pending .icon-container,
.pink-purple.dark .process-card-detail .stages-container .stage.pending .icon-container,
.pink-purple.dark .process-card-stages .stages-container .stage.pending .icon-container {
  background-color: rgba(174, 174, 174, 0.2);
}
.pink-purple.dark .process-card .stages-container .stage.pending .line,
.pink-purple.dark .process-card-detail .stages-container .stage.pending .line,
.pink-purple.dark .process-card-stages .stages-container .stage.pending .line {
  background-color: #949494;
}
.pink-purple.dark .process-card .stages-container .stage.has-error mat-icon,
.pink-purple.dark .process-card-detail .stages-container .stage.has-error mat-icon,
.pink-purple.dark .process-card-stages .stages-container .stage.has-error mat-icon {
  color: #e57373;
}
.pink-purple.dark .process-card .stages-container .stage.has-error .icon-container,
.pink-purple.dark .process-card-detail .stages-container .stage.has-error .icon-container,
.pink-purple.dark .process-card-stages .stages-container .stage.has-error .icon-container {
  background-color: rgba(188, 4, 4, 0.2);
}
.pink-purple.dark .process-card .stages-container .stage-description .button-link,
.pink-purple.dark .process-card-detail .stages-container .stage-description .button-link,
.pink-purple.dark .process-card-stages .stages-container .stage-description .button-link {
  color: #f48fb1;
}
.pink-purple.dark app-custom-search-input .custom-search-container {
  background: #303030;
  border: 1px solid rgba(255, 255, 255, 0.12);
  transition: color 0.1s ease, background 0.15s ease;
}
.pink-purple.dark app-custom-search-input .custom-search-container mat-icon.mat-icon {
  color: rgba(255, 255, 255, 0.5);
}
.pink-purple.dark app-custom-search-input .custom-search-container input.custom-search-input {
  color: white;
  background: unset;
}
.pink-purple.dark app-custom-search-input .custom-search-container:hover, .pink-purple.dark app-custom-search-input .custom-search-container:active, .pink-purple.dark app-custom-search-input .custom-search-container:focus, .pink-purple.dark app-custom-search-input .custom-search-container:focus-within {
  background: rgba(255, 255, 255, 0.04) !important;
  transition: background 0.15s ease;
}
.pink-purple.dark .UI-V2 .audit-filter {
  background: #424242;
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark .UI-V2 .audit-filter .toolbar {
  background: #f48fb1;
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark .UI-V2 .audit-filter .toolbar .header .content .module-title {
  color: #f48fb1;
}
.pink-purple.dark .UI-V2 .audit-filter .toolbar .header .content .module-title.selectable:hover {
  background: rgba(255, 255, 255, 0.04);
}
.pink-purple.dark .UI-V2 .audit-filter .toolbar .header .content .module-title .info * {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-purple.dark .UI-V2 .audit-filter .toolbar .header .content .ou-select {
  color: rgba(0, 0, 0, 0.87);
  background: #f48fb1;
}
.pink-purple.dark .UI-V2 .audit-filter .toolbar .search-container .search .content {
  background: #303030;
}
.pink-purple.dark .UI-V2 .audit-filter .toolbar .search-container .search .content:hover, .pink-purple.dark .UI-V2 .audit-filter .toolbar .search-container .search .content:active, .pink-purple.dark .UI-V2 .audit-filter .toolbar .search-container .search .content:focus, .pink-purple.dark .UI-V2 .audit-filter .toolbar .search-container .search .content:focus-within {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-purple.dark .UI-V2 .audit-filter .toolbar .search-container .search .content input {
  color: white;
}
.pink-purple.dark .UI-V2 .audit-filter .filter-container {
  background: #424242 !important;
}
.pink-purple.dark .UI-V2 .audit-filter .filter-container .form-body {
  background: #303030 !important;
}
.pink-purple.dark .UI-V2 .audit-filter .filter-container .form-body .line h4 {
  color: #f48fb1;
}
.pink-purple.dark .UI-V2 .audit-filter .filter-container .form-body .line mat-icon {
  color: #f48fb1;
}
.pink-purple.dark .UI-V2 .audit-result .result-container .toolbar {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark .UI-V2 .audit-result .result-container .toolbar .header .content .module-title {
  color: #f48fb1;
}
.pink-purple.dark .UI-V2 .audit-result .result-container .toolbar .header .content .module-title.selectable:hover {
  background: rgba(255, 255, 255, 0.04);
}
.pink-purple.dark .UI-V2 .audit-result .result-container .toolbar .header .content .module-title .info h5 {
  color: white !important;
}
.pink-purple.dark .UI-V2 .audit-result .result-container .toolbar .header .content .ou-select {
  color: rgba(0, 0, 0, 0.87);
  background: #f48fb1;
}
.pink-purple.dark .UI-V2 .audit-result .result-container .toolbar .search-container .search .content {
  background: #303030;
}
.pink-purple.dark .UI-V2 .audit-result .result-container .toolbar .search-container .search .content:hover, .pink-purple.dark .UI-V2 .audit-result .result-container .toolbar .search-container .search .content:active, .pink-purple.dark .UI-V2 .audit-result .result-container .toolbar .search-container .search .content:focus, .pink-purple.dark .UI-V2 .audit-result .result-container .toolbar .search-container .search .content:focus-within {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-purple.dark .UI-V2 .audit-result .result-container .toolbar .search-container .search .content input {
  color: white;
}
.pink-purple.dark .UI-V2 .audit-result .result-container .new-table app-cs-grid-control datatable-header * {
  color: #f48fb1;
}
.pink-purple.dark .UI-V2 .audit-result .result-container .new-table .mat-header-row.sticky * {
  color: #f48fb1 !important;
}
.pink-purple.dark .UI-V2 .audit-detail-container {
  border-left: 1px solid rgba(255, 255, 255, 0.12);
  background: black !important;
}
.pink-purple.dark .UI-V2 .audit-detail-container .side .toolbar .header {
  color: #f48fb1;
}
.pink-purple.dark .UI-V2 .audit-detail-container .side .container .mat-list-base {
  background: #303030 !important;
}
.pink-purple.dark .UI-V2 .audit-detail-container .side .container .mat-list-base mat-list-item .mat-line b {
  color: #f48fb1;
}
.pink-purple.dark .UI-V2 .inbox-container {
  background: #424242;
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark .UI-V2 .inbox-container *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.pink-purple.dark .UI-V2 .inbox-container *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
.pink-purple.dark .UI-V2 .inbox-container *::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}
.pink-purple.dark .UI-V2 .inbox-container .load-window {
  background: #424242;
}
.pink-purple.dark .UI-V2 .inbox-container .toolbar {
  background: #f48fb1;
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark .UI-V2 .inbox-container .toolbar.reverse {
  background: #424242;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel {
  background: black !important;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel.mat-expanded {
  background: #303030 !important;
}
.pink-purple.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel.mat-expanded mat-expansion-panel-header {
  background: #303030 !important;
}
.pink-purple.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel.mat-expanded mat-expansion-panel-header:hover {
  background: none !important;
}
.pink-purple.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel mat-expansion-panel-header {
  background: #303030 !important;
}
.pink-purple.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel mat-expansion-panel-header:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-purple.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel mat-expansion-panel-header .signature-inbox-header .mat-icon,
.pink-purple.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel mat-expansion-panel-header .signature-inbox-header b {
  color: #ce93d8;
}
.pink-purple.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel .mat-expansion-panel-body .item {
  background: #424242;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel .mat-expansion-panel-body .item:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-purple.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel .mat-expansion-panel-body .item.active {
  border-left: 6px solid #ce93d8;
}
.pink-purple.dark .UI-V2 .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.pink-purple.dark .UI-V2 .work-space .main .load-window {
  background: #424242 !important;
}
.pink-purple.dark .UI-V2 .work-space .main-action {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark mat-radio-button.disabled .mat-radio-label-content {
  color: #ce93d8;
}
.pink-purple.dark .detail app-automatic-process-detail app-chapa.minimize,
.pink-purple.dark .detail app-process-detail app-chapa.minimize {
  background: #ffc107 !important;
}
.pink-purple.dark .detail app-automatic-process-detail app-chapa.minimize.purged,
.pink-purple.dark .detail app-process-detail app-chapa.minimize.purged {
  background: #b2adb0 !important;
}
.pink-purple.dark .detail .detail-toolbar {
  background: black;
}
.pink-purple.dark .detail .processes-status .status-bar .bar-graph {
  background-color: #ffc107;
}
.pink-purple.dark .detail .processes-status .status-bar .bar-graph .graph-ok {
  background-color: #009688;
}
.pink-purple.dark .detail .processes-status .status-bar .bar-graph .graph-not-ok {
  background-color: #e91e63;
}
.pink-purple.dark .detail .processes-status .status-resume b {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark .detail .processes-status .status-resume .resume-total b {
  background-color: #ffc107;
}
.pink-purple.dark .detail .processes-status .status-resume .resume-ok b {
  background-color: #009688;
}
.pink-purple.dark .detail .processes-status .status-resume .resume-not-ok b {
  background-color: #e91e63;
}
.pink-purple.dark .detail .detail-toolbar .mat-avatar h3,
.pink-purple.dark .detail .detail-toolbar .mat-avatar h5 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark .detail .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark .detail .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark .detail .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark .detail .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark .detail .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  background: #009688 !important;
}
.pink-purple.dark .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  background: #b2dfdb !important;
}
.pink-purple.dark .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  background: #b2dfdb !important;
}
.pink-purple.dark .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  background: #009688 !important;
}
.pink-purple.dark .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  background: #b2dfdb !important;
}
.pink-purple.dark .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  background: #837C80 !important;
}
.pink-purple.dark .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  background: #b2adb0 !important;
}
.pink-purple.dark .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  background: #b2adb0 !important;
}
.pink-purple.dark .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  background: #837C80 !important;
}
.pink-purple.dark .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  background: #b2dfdb !important;
}
.pink-purple.dark .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  background: #ffc107 !important;
}
.pink-purple.dark .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  background: #ffecb3 !important;
}
.pink-purple.dark .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  background: #ffecb3 !important;
}
.pink-purple.dark .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  background: #ffc107 !important;
}
.pink-purple.dark .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  background: #ffecb3 !important;
}
.pink-purple.dark .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  background: #e91e63 !important;
}
.pink-purple.dark .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  background: #f8bbd0 !important;
}
.pink-purple.dark .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  background: #f8bbd0 !important;
}
.pink-purple.dark .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  background: #e91e63 !important;
}
.pink-purple.dark .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  background: #f8bbd0 !important;
}
.pink-purple.dark app-analytics-document-set .sets-container .mat-list-base.sets-list mat-list-item {
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark app-analytics-document-set .sets-container .mat-list-base.sets-list mat-list-item .mat-list-item-content .set-status .value-percentile .undone-value {
  background: #673AB7;
}
.pink-purple.dark app-analytics-document-set .sets-container .mat-list-base.sets-list mat-list-item .mat-list-item-content .set-status .value-percentile .not-value {
  background: black;
}
.pink-purple.dark app-analytics-document-set .sets-container .mat-list-base.sets-list mat-list-item .mat-list-item-content .set-status .value-percentile .done-value {
  background: #009688;
}
.pink-purple.dark app-analytics-document-set .work-space .main .toolbar .header .content .module-title .info .info-text .set-status {
  cursor: help;
}
.pink-purple.dark app-analytics-document-set .work-space .main .toolbar .header .content .module-title .info .info-text .set-status .value-percentile .undone-value {
  background: #673AB7;
}
.pink-purple.dark app-analytics-document-set .work-space .main .toolbar .header .content .module-title .info .info-text .set-status .value-percentile .not-value {
  background: black;
}
.pink-purple.dark app-analytics-document-set .work-space .main .toolbar .header .content .module-title .info .info-text .set-status .value-percentile .done-value {
  background: #009688;
}
.pink-purple.dark app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar .mat-progress-bar-buffer {
  background-color: black;
}
.pink-purple.dark app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar.class1 .mat-progress-bar-fill::after {
  background-color: #f44336;
}
.pink-purple.dark app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar.class2 .mat-progress-bar-fill::after {
  background-color: #ff9800;
}
.pink-purple.dark app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar.class3 .mat-progress-bar-fill::after {
  background-color: #ffeb3b;
}
.pink-purple.dark app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar.class4 .mat-progress-bar-fill::after {
  background-color: #8bc34a;
}
.pink-purple.dark app-analytics-document-set .work-space .side .container mat-list {
  background: #424242 !important;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark app-analytics-document-set .work-space .side .container mat-list mat-list-item .mat-line b {
  color: #f48fb1;
}
.pink-purple.dark .catcher {
  background: rgba(0, 0, 0, 0.6);
}
.pink-purple.dark .catcher.clear {
  background: rgba(0, 0, 0, 0);
}
.pink-purple.dark .add-menu-form-body .mat-radio-group .mat-radio-button:hover {
  background: rgba(255, 255, 255, 0.04);
}
.pink-purple.dark .add-menu-form-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b {
  color: white;
}
.pink-purple.dark .add-menu-form-body.w-table .helper .search-bar {
  background: #303030;
}
.pink-purple.dark .add-menu-form-body.w-table .helper .search-bar:hover, .pink-purple.dark .add-menu-form-body.w-table .helper .search-bar:active, .pink-purple.dark .add-menu-form-body.w-table .helper .search-bar:focus, .pink-purple.dark .add-menu-form-body.w-table .helper .search-bar:focus-within {
  background: #303030 !important;
}
.pink-purple.dark .add-menu-form-body.w-table .helper .search-bar input {
  color: white;
}
.pink-purple.dark .add-menu-form-actions {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark .file-upload-list mat-list-item {
  background: #424242;
}
.pink-purple.dark .mat-horizontal-stepper-content[aria-expanded=true] app-file-document-view {
  border-left: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark .mat-horizontal-stepper-content[aria-expanded=true] app-file-document-view .pdf-view-container {
  background: black;
}
.pink-purple.dark .add-docs-drop .docs-drop-preview app-file-document-detail {
  border-left: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark .add-docs-drop .docs-drop-preview app-file-document-detail .view-toolbar {
  background: #424242;
}
.pink-purple.dark .add-docs-drop .load-window.message h3.primary-text {
  color: #f48fb1;
}
.pink-purple.dark mat-dialog-container app-add-menu-dialog .load-window.message h3.primary-text {
  color: #f48fb1;
}
.pink-purple.dark .asisted-input.aligned-icon.subtitle * {
  color: rgba(255, 255, 255, 0.5) !important;
}
.pink-purple.dark .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble {
  background: #f48fb1;
}
.pink-purple.dark .asisted-input .mat-form-field-invalid + .indicator > .bubble {
  background: #e57373;
}
.pink-purple.dark .asisted-input .mat-form-field-disabled + .indicator > .bubble {
  color: rgba(255, 255, 255, 0.5);
  background: rgba(255, 255, 255, 0.12);
}
.pink-purple.dark .asisted-input .indicator .bubble {
  background: rgba(255, 255, 255, 0.5);
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark .asisted-input .indicator .guide {
  border-color: rgba(255, 255, 255, 0.12);
}
.pink-purple.dark .asisted-input.icon-only .bubble {
  color: rgba(255, 255, 255, 0.7);
}
.pink-purple.dark .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble {
  color: #f48fb1;
}
.pink-purple.dark .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble {
  color: #e57373;
}
.pink-purple.dark .UI-V2 .main-overlay {
  background: rgba(0, 0, 0, 0.32);
}
.pink-purple.dark .UI-V2 .icon-complex mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.pink-purple.dark .UI-V2 .icon-complex mat-icon.complex {
  color: #f48fb1 !important;
}
.pink-purple.dark .UI-V2 .navigation {
  background: #303030;
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark .UI-V2 .navigation *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.pink-purple.dark .UI-V2 .navigation *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
.pink-purple.dark .UI-V2 .navigation .load-window {
  background: black;
}
.pink-purple.dark .UI-V2 .navigation .toolbar {
  background: #f48fb1;
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark .UI-V2 .navigation .toolbar.reverse {
  background: #424242;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark .UI-V2 .navigation .container .helper {
  color: rgba(255, 255, 255, 0.7);
}
.pink-purple.dark .UI-V2 .navigation .container .item {
  background: #424242;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark .UI-V2 .navigation .container .item:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-purple.dark .UI-V2 .navigation .container .item.active {
  background: #f48fb1 !important;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #f48fb1;
}
.pink-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .separator .subtitle,
.pink-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .separator .subtitle,
.pink-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .separator .subtitle,
.pink-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .separator .subtitle,
.pink-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .separator .subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.pink-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .img-editior-container .img-editor .separator .subtitle,
.pink-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .img-editior-container .img-editor .separator .subtitle,
.pink-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .img-editior-container .img-editor .separator .subtitle,
.pink-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .img-editior-container .img-editor .separator .subtitle,
.pink-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .img-editior-container .img-editor .separator .subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.pink-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme,
.pink-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme,
.pink-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme,
.pink-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme,
.pink-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .main-color-container .main-color,
.pink-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .main-color-container .main-color,
.pink-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .main-color-container .main-color,
.pink-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .main-color-container .main-color,
.pink-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .main-color-container .main-color {
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .main-color-container .main-color:before,
.pink-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .main-color-container .main-color:before,
.pink-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .main-color-container .main-color:before,
.pink-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .main-color-container .main-color:before,
.pink-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .main-color-container .main-color:before {
  border-left: 10px solid rgba(0, 0, 0, 0);
  border-right: 10px solid rgba(0, 0, 0, 0);
}
.pink-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example h6.required,
.pink-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example h6.required,
.pink-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example h6.required,
.pink-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example h6.required,
.pink-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example h6.required {
  color: #e57373 !important;
}
.pink-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle,
.pink-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle,
.pink-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle,
.pink-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle,
.pink-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle {
  border: 2px solid rgba(0, 0, 0, 0);
}
.pink-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .side-color-container .side-color,
.pink-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .side-color-container .side-color,
.pink-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .side-color-container .side-color,
.pink-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .side-color-container .side-color,
.pink-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .side-color-container .side-color {
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .side-color-container .side-color:before,
.pink-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .side-color-container .side-color:before,
.pink-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .side-color-container .side-color:before,
.pink-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .side-color-container .side-color:before,
.pink-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .side-color-container .side-color:before {
  border-top: 10px solid rgba(0, 0, 0, 0);
  border-bottom: 10px solid rgba(0, 0, 0, 0);
}
.pink-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .side-color-container .side-color h6.required,
.pink-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .side-color-container .side-color h6.required,
.pink-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .side-color-container .side-color h6.required,
.pink-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .side-color-container .side-color h6.required,
.pink-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .side-color-container .side-color h6.required {
  color: #e57373 !important;
}
.pink-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .permission-settings-cards .card,
.pink-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .permission-settings-cards .card,
.pink-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .permission-settings-cards .card,
.pink-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .permission-settings-cards .card,
.pink-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .permission-settings-cards .card {
  background: #303030;
}
.pink-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .permission-settings-cards .card .slide-sub-group,
.pink-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .permission-settings-cards .card .slide-sub-group,
.pink-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .permission-settings-cards .card .slide-sub-group,
.pink-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .permission-settings-cards .card .slide-sub-group,
.pink-purple.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .permission-settings-cards .card .slide-sub-group {
  background: rgba(255, 255, 255, 0.12);
}
.pink-purple.dark .UI-V2 .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.pink-purple.dark .UI-V2 .work-space .main .load-window {
  background: #424242 !important;
}
.pink-purple.dark .UI-V2 .work-space .main.side-open {
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item {
  background: #303030;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item mat-icon.complex {
  text-shadow: -2px 0 0px black, 0 -2px 0px black, 2px 0 0px black, 0 2px 0px black, -2px -2px 0px black, 2px 2px 0px black, 2px -2px 0px black, -2px 2px 0px black !important;
}
.pink-purple.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item h5 {
  color: rgba(255, 255, 255, 0.5);
}
.pink-purple.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item h3 {
  color: white;
}
.pink-purple.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item .actions {
  pointer-events: none;
}
.pink-purple.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item .actions button {
  pointer-events: all;
}
.pink-purple.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled {
  background: black;
}
.pink-purple.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled h3 {
  color: #607D8B !important;
}
.pink-purple.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled mat-icon {
  color: #607D8B !important;
}
.pink-purple.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled.selected {
  background: #607D8B;
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-purple.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled.selected h3 {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-purple.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled.selected mat-icon {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-purple.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled.selected mat-icon.complex {
  color: rgba(0, 0, 0, 0.87) !important;
  text-shadow: -2px 0 0px #607D8B, 0 -2px 0px #607D8B, 2px 0 0px #607D8B, 0 2px 0px #607D8B, -2px -2px 0px #607D8B, 2px 2px 0px #607D8B, 2px -2px 0px #607D8B, -2px 2px 0px #607D8B !important;
}
.pink-purple.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item:hover {
  background: rgba(255, 255, 255, 0.04);
}
.pink-purple.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected {
  background: #f48fb1;
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected mat-icon {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected mat-icon.complex {
  color: rgba(0, 0, 0, 0.87) !important;
  text-shadow: -2px 0 0px #f48fb1, 0 -2px 0px #f48fb1, 2px 0 0px #f48fb1, 0 2px 0px #f48fb1, -2px -2px 0px #f48fb1, 2px 2px 0px #f48fb1, 2px -2px 0px #f48fb1, -2px 2px 0px #f48fb1 !important;
}
.pink-purple.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected h5,
.pink-purple.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected h3 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark .UI-V2 .work-space .main .main-action {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark .UI-V2 .work-space .side {
  background: #303030;
}
.pink-purple.dark .UI-V2 .work-space .side .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #303030, 0 -2px 0px #303030, 2px 0 0px #303030, 0 2px 0px #303030, -2px -2px 0px #303030, 2px 2px 0px #303030, 2px -2px 0px #303030, -2px 2px 0px #303030;
}
.pink-purple.dark .UI-V2 .work-space .side *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.pink-purple.dark .UI-V2 .work-space .side *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
.pink-purple.dark .UI-V2 .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #303030 !important;
}
.pink-purple.dark .UI-V2 .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(255, 255, 255, 0.5);
}
.pink-purple.dark .UI-V2 .work-space .side mat-form-field .mat-form-field-flex {
  background: #303030;
}
.pink-purple.dark .UI-V2 .work-space .side mat-checkbox:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-purple.dark .UI-V2 .work-space .side .hint.all {
  color: #ce93d8;
}
.pink-purple.dark .UI-V2 .work-space .side .toolbar .header {
  color: #f48fb1;
}
.pink-purple.dark .UI-V2 .work-space .side .toolbar .header h3 {
  color: #f48fb1;
}
.pink-purple.dark .UI-V2 .work-space .side .container .role-resume {
  background: #f48fb1;
}
.pink-purple.dark .UI-V2 .work-space .side .container .role-resume .line {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark .UI-V2 .work-space .side .container .role-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.1);
}
.pink-purple.dark .UI-V2 .work-space .side .container .role-resume .line.editable button {
  background: #f48fb1;
}
.pink-purple.dark .UI-V2 .work-space .side .container .section-title {
  color: #f48fb1;
}
.pink-purple.dark .UI-V2 .work-space .side .container .section-resume {
  background: #424242;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark .UI-V2 .work-space .side .container .section-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-purple.dark .UI-V2 .work-space .side .container .section-resume .line.editable:hover .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #424242, 0 -2px 0px #424242, 2px 0 0px #424242, 0 2px 0px #424242, -2px -2px 0px #424242, 2px 2px 0px #424242, 2px -2px 0px #424242, -2px 2px 0px #424242 !important;
}
.pink-purple.dark .UI-V2 .work-space .side .container .section-resume .line.editable button {
  background: #303030;
}
.pink-purple.dark .UI-V2 .work-space .side .container .section-resume .line .header .icon-complex mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.pink-purple.dark .UI-V2 .work-space .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #f48fb1 !important;
}
.pink-purple.dark .UI-V2 .work-space .side .container .section-resume .line .header mat-icon:not(.complex) {
  color: rgba(255, 255, 255, 0.7);
}
.pink-purple.dark .UI-V2 .work-space .side .container .section-resume .line .header .info h4 {
  color: #f48fb1;
}
.pink-purple.dark .UI-V2 .work-space .side .container .section-resume .line ul {
  color: #f48fb1;
}
.pink-purple.dark .UI-V2 .work-space .side .container .section-resume .line.head {
  background: black;
}
.pink-purple.dark .UI-V2 .work-space .side .container .section-resume .line.head mat-icon {
  color: #ce93d8;
}
.pink-purple.dark .UI-V2 .work-space .side .container .section-resume .line.head h4 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark .UI-V2 .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body {
  background: #424242;
}
.pink-purple.dark .UI-V2 .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: rgba(255, 255, 255, 0.7);
}
.pink-purple.dark .UI-V2 .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #f48fb1;
}
.pink-purple.dark .UI-V2 .work-space .side.edition .toolbar .header {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark .UI-V2 .work-space .side.edition .toolbar .header mat-icon {
  color: #ce93d8;
}
.pink-purple.dark .UI-V2 .work-space .side.edition .toolbar .header h3 {
  color: #ce93d8;
}
.pink-purple.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body {
  background: #424242 !important;
}
.pink-purple.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header mat-icon:not(.complex) {
  color: #f48fb1;
}
.pink-purple.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header h4 {
  color: #f48fb1;
}
.pink-purple.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-actions {
  background: #303030;
}
.pink-purple.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.pink-purple.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header .icon-complex mat-icon.complex {
  color: #ce93d8 !important;
}
.pink-purple.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header h4 {
  color: #ce93d8;
}
.pink-purple.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line ul {
  color: #ce93d8;
  font-weight: 400;
}
.pink-purple.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head mat-icon {
  color: #ce93d8 !important;
}
.pink-purple.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head h4 {
  color: white;
}
.pink-purple.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .section-title {
  color: white !important;
}
.pink-purple.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume h6.hint {
  background: #ce93d8;
}
.pink-purple.dark app-role-user-find .main-overlay {
  background: rgba(0, 0, 0, 0.32);
}
.pink-purple.dark app-role-user-find .icon-complex mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.pink-purple.dark app-role-user-find .icon-complex mat-icon.complex {
  color: #f48fb1 !important;
}
.pink-purple.dark app-role-user-find .navigation {
  background: #303030;
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark app-role-user-find .navigation *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.pink-purple.dark app-role-user-find .navigation *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
.pink-purple.dark app-role-user-find .navigation .load-window {
  background: black;
}
.pink-purple.dark app-role-user-find .navigation .toolbar {
  background: #f48fb1;
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark app-role-user-find .navigation .toolbar.reverse {
  background: #424242;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark app-role-user-find .navigation .container .helper {
  color: rgba(255, 255, 255, 0.7);
}
.pink-purple.dark app-role-user-find .navigation .container .item {
  background: #424242;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark app-role-user-find .navigation .container .item:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-purple.dark app-role-user-find .navigation .container .item.active {
  background: #f48fb1 !important;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #f48fb1;
}
.pink-purple.dark app-role-user-find .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.pink-purple.dark app-role-user-find .work-space .main .load-window {
  background: #424242 !important;
}
.pink-purple.dark app-role-user-find .work-space .main.side-open {
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark app-role-user-find .work-space .main .container .item {
  background: #303030;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark app-role-user-find .work-space .main .container .item .subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.pink-purple.dark app-role-user-find .work-space .main .container .item.disabled {
  background: black;
  color: #607D8B !important;
}
.pink-purple.dark app-role-user-find .work-space .main .container .item.disabled h3 {
  color: #607D8B !important;
}
.pink-purple.dark app-role-user-find .work-space .main .container .item.disabled mat-icon {
  color: #607D8B !important;
}
.pink-purple.dark app-role-user-find .work-space .main .container .item.disabled.selected {
  background: #607D8B;
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-purple.dark app-role-user-find .work-space .main .container .item.disabled.selected h3 {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-purple.dark app-role-user-find .work-space .main .container .item.disabled.selected mat-icon {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-purple.dark app-role-user-find .work-space .main .container .item.disabled.selected mat-icon.complex {
  color: rgba(0, 0, 0, 0.87) !important;
  text-shadow: -2px 0 0px #607D8B, 0 -2px 0px #607D8B, 2px 0 0px #607D8B, 0 2px 0px #607D8B, -2px -2px 0px #607D8B, 2px 2px 0px #607D8B, 2px -2px 0px #607D8B, -2px 2px 0px #607D8B !important;
}
.pink-purple.dark app-role-user-find .work-space .main .container .item mat-icon.complex {
  text-shadow: -2px 0 0px black, 0 -2px 0px black, 2px 0 0px black, 0 2px 0px black, -2px -2px 0px black, 2px 2px 0px black, 2px -2px 0px black, -2px 2px 0px black !important;
}
.pink-purple.dark app-role-user-find .work-space .main .container .item h5 {
  color: rgba(255, 255, 255, 0.5);
}
.pink-purple.dark app-role-user-find .work-space .main .container .item h3 {
  color: white;
}
.pink-purple.dark app-role-user-find .work-space .main .container .item:hover {
  background: rgba(255, 255, 255, 0.04);
}
.pink-purple.dark app-role-user-find .work-space .main .container .item.selected {
  background: #f48fb1;
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark app-role-user-find .work-space .main .container .item.selected mat-icon {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark app-role-user-find .work-space .main .container .item.selected mat-icon.complex {
  color: rgba(0, 0, 0, 0.87) !important;
  text-shadow: -2px 0 0px #f48fb1, 0 -2px 0px #f48fb1, 2px 0 0px #f48fb1, 0 2px 0px #f48fb1, -2px -2px 0px #f48fb1, 2px 2px 0px #f48fb1, 2px -2px 0px #f48fb1, -2px 2px 0px #f48fb1 !important;
}
.pink-purple.dark app-role-user-find .work-space .main .container .item.selected h5,
.pink-purple.dark app-role-user-find .work-space .main .container .item.selected h3 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark app-role-user-find .work-space .main .main-action {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark app-role-user-find .work-space .side {
  background: #303030;
}
.pink-purple.dark app-role-user-find .work-space .side .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #303030, 0 -2px 0px #303030, 2px 0 0px #303030, 0 2px 0px #303030, -2px -2px 0px #303030, 2px 2px 0px #303030, 2px -2px 0px #303030, -2px 2px 0px #303030;
}
.pink-purple.dark app-role-user-find .work-space .side *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.pink-purple.dark app-role-user-find .work-space .side *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
.pink-purple.dark app-role-user-find .work-space .side .edition-line {
  background: black;
}
.pink-purple.dark app-role-user-find .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #303030 !important;
}
.pink-purple.dark app-role-user-find .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(255, 255, 255, 0.5);
}
.pink-purple.dark app-role-user-find .work-space .side mat-form-field .mat-form-field-flex {
  background: #303030;
}
.pink-purple.dark app-role-user-find .work-space .side mat-checkbox:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-purple.dark app-role-user-find .work-space .side .hint.all {
  color: #ce93d8;
}
.pink-purple.dark app-role-user-find .work-space .side .toolbar .header {
  color: #f48fb1;
}
.pink-purple.dark app-role-user-find .work-space .side .toolbar .header h3 {
  color: #f48fb1;
}
.pink-purple.dark app-role-user-find .work-space .side .container .role-resume {
  background: #f48fb1;
}
.pink-purple.dark app-role-user-find .work-space .side .container .role-resume .line {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark app-role-user-find .work-space .side .container .role-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.1);
}
.pink-purple.dark app-role-user-find .work-space .side .container .role-resume .line.editable button {
  background: #f48fb1;
}
.pink-purple.dark app-role-user-find .work-space .side .container .section-title {
  color: #f48fb1;
}
.pink-purple.dark app-role-user-find .work-space .side .container .section-resume {
  background: #424242;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark app-role-user-find .work-space .side .container .section-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-purple.dark app-role-user-find .work-space .side .container .section-resume .line.editable:hover .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #424242, 0 -2px 0px #424242, 2px 0 0px #424242, 0 2px 0px #424242, -2px -2px 0px #424242, 2px 2px 0px #424242, 2px -2px 0px #424242, -2px 2px 0px #424242 !important;
}
.pink-purple.dark app-role-user-find .work-space .side .container .section-resume .line.editable button {
  background: #303030;
}
.pink-purple.dark app-role-user-find .work-space .side .container .section-resume .line .header .icon-complex mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.pink-purple.dark app-role-user-find .work-space .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #f48fb1 !important;
}
.pink-purple.dark app-role-user-find .work-space .side .container .section-resume .line .header mat-icon:not(.complex) {
  color: rgba(255, 255, 255, 0.7);
}
.pink-purple.dark app-role-user-find .work-space .side .container .section-resume .line .header .info h4 {
  color: #f48fb1;
}
.pink-purple.dark app-role-user-find .work-space .side .container .section-resume .line ul {
  color: #f48fb1;
}
.pink-purple.dark app-role-user-find .work-space .side .container .section-resume .line.head {
  background: black;
}
.pink-purple.dark app-role-user-find .work-space .side .container .section-resume .line.head mat-icon {
  color: #ce93d8;
}
.pink-purple.dark app-role-user-find .work-space .side .container .section-resume .line.head h4 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee-title {
  color: #f48fb1;
}
.pink-purple.dark app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body {
  background: #424242;
}
.pink-purple.dark app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: rgba(255, 255, 255, 0.7);
}
.pink-purple.dark app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #f48fb1;
}
.pink-purple.dark app-role-user-find .work-space .side.edition .toolbar .header {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark app-role-user-find .work-space .side.edition .toolbar .header mat-icon {
  color: #ce93d8;
}
.pink-purple.dark app-role-user-find .work-space .side.edition .toolbar .header h3 {
  color: #ce93d8;
}
.pink-purple.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body {
  background: #424242 !important;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header mat-icon:not(.complex) {
  color: #f48fb1;
}
.pink-purple.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header h4 {
  color: #f48fb1;
}
.pink-purple.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-actions {
  background: #303030;
}
.pink-purple.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.pink-purple.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header .icon-complex mat-icon.complex {
  color: #ce93d8 !important;
}
.pink-purple.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header h4 {
  color: #ce93d8;
}
.pink-purple.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line ul {
  color: #ce93d8;
}
.pink-purple.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head mat-icon {
  color: #ce93d8 !important;
}
.pink-purple.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head h4 {
  color: white;
}
.pink-purple.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .section-title {
  color: white !important;
}
.pink-purple.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume h6.hint {
  background: #ce93d8;
}
.pink-purple.dark app-role-user-find .work-space app-role-user-detail.disabled .side .toolbar .header {
  color: #607D8B !important;
}
.pink-purple.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container .role-resume {
  background: #607D8B !important;
}
.pink-purple.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-title {
  color: #607D8B !important;
}
.pink-purple.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line ul {
  color: #607D8B !important;
}
.pink-purple.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header mat-icon {
  color: #607D8B;
}
.pink-purple.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .icon-complex mat-icon {
  color: #607D8B !important;
}
.pink-purple.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #607D8B !important;
}
.pink-purple.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .info h4 {
  color: #607D8B !important;
}
.pink-purple.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee-title {
  color: #607D8B;
}
.pink-purple.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: #607D8B;
}
.pink-purple.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #607D8B;
}
.pink-purple.dark app-actor-find .main-overlay {
  background: rgba(0, 0, 0, 0.32);
}
.pink-purple.dark app-actor-find .icon-complex mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.pink-purple.dark app-actor-find .icon-complex mat-icon.complex {
  color: #f48fb1 !important;
}
.pink-purple.dark app-actor-find .navigation {
  background: #303030;
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark app-actor-find .navigation *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.pink-purple.dark app-actor-find .navigation *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
.pink-purple.dark app-actor-find .navigation .load-window {
  background: black;
}
.pink-purple.dark app-actor-find .navigation .toolbar {
  background: #f48fb1;
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark app-actor-find .navigation .toolbar.reverse {
  background: #424242;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark app-actor-find .navigation .container .helper {
  color: rgba(255, 255, 255, 0.7);
}
.pink-purple.dark app-actor-find .navigation .container .item {
  background: #424242;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark app-actor-find .navigation .container .item:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-purple.dark app-actor-find .navigation .container .item.active {
  background: #f48fb1 !important;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #f48fb1;
}
.pink-purple.dark app-actor-find .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.pink-purple.dark app-actor-find .work-space .main .load-window {
  background: #424242 !important;
}
.pink-purple.dark app-actor-find .work-space .main.side-open {
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark app-actor-find .work-space .main .container .item {
  background: #303030;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark app-actor-find .work-space .main .container .item .subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.pink-purple.dark app-actor-find .work-space .main .container .item.disabled {
  background: black;
  color: #607D8B !important;
}
.pink-purple.dark app-actor-find .work-space .main .container .item.disabled h3 {
  color: #607D8B !important;
}
.pink-purple.dark app-actor-find .work-space .main .container .item.disabled mat-icon {
  color: #607D8B !important;
}
.pink-purple.dark app-actor-find .work-space .main .container .item.disabled.selected {
  background: #607D8B;
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-purple.dark app-actor-find .work-space .main .container .item.disabled.selected h3 {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-purple.dark app-actor-find .work-space .main .container .item.disabled.selected mat-icon {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-purple.dark app-actor-find .work-space .main .container .item.disabled.selected mat-icon.complex {
  color: rgba(0, 0, 0, 0.87) !important;
  text-shadow: -2px 0 0px #607D8B, 0 -2px 0px #607D8B, 2px 0 0px #607D8B, 0 2px 0px #607D8B, -2px -2px 0px #607D8B, 2px 2px 0px #607D8B, 2px -2px 0px #607D8B, -2px 2px 0px #607D8B !important;
}
.pink-purple.dark app-actor-find .work-space .main .container .item mat-icon.complex {
  text-shadow: -2px 0 0px black, 0 -2px 0px black, 2px 0 0px black, 0 2px 0px black, -2px -2px 0px black, 2px 2px 0px black, 2px -2px 0px black, -2px 2px 0px black !important;
}
.pink-purple.dark app-actor-find .work-space .main .container .item h5 {
  color: rgba(255, 255, 255, 0.5);
}
.pink-purple.dark app-actor-find .work-space .main .container .item h3 {
  color: white;
}
.pink-purple.dark app-actor-find .work-space .main .container .item:hover {
  background: rgba(255, 255, 255, 0.04);
}
.pink-purple.dark app-actor-find .work-space .main .container .item.selected {
  background: #f48fb1;
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark app-actor-find .work-space .main .container .item.selected mat-icon {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark app-actor-find .work-space .main .container .item.selected mat-icon.complex {
  color: rgba(0, 0, 0, 0.87) !important;
  text-shadow: -2px 0 0px #f48fb1, 0 -2px 0px #f48fb1, 2px 0 0px #f48fb1, 0 2px 0px #f48fb1, -2px -2px 0px #f48fb1, 2px 2px 0px #f48fb1, 2px -2px 0px #f48fb1, -2px 2px 0px #f48fb1 !important;
}
.pink-purple.dark app-actor-find .work-space .main .container .item.selected h5,
.pink-purple.dark app-actor-find .work-space .main .container .item.selected h3 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark app-actor-find .work-space .main .main-action {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark app-actor-find .work-space .side {
  background: #303030;
}
.pink-purple.dark app-actor-find .work-space .side .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #303030, 0 -2px 0px #303030, 2px 0 0px #303030, 0 2px 0px #303030, -2px -2px 0px #303030, 2px 2px 0px #303030, 2px -2px 0px #303030, -2px 2px 0px #303030;
}
.pink-purple.dark app-actor-find .work-space .side *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.pink-purple.dark app-actor-find .work-space .side *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
.pink-purple.dark app-actor-find .work-space .side .edition-line {
  background: black;
}
.pink-purple.dark app-actor-find .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #303030 !important;
}
.pink-purple.dark app-actor-find .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(255, 255, 255, 0.5);
}
.pink-purple.dark app-actor-find .work-space .side mat-form-field .mat-form-field-flex {
  background: #303030;
}
.pink-purple.dark app-actor-find .work-space .side mat-checkbox:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-purple.dark app-actor-find .work-space .side .hint.all {
  color: #ce93d8;
}
.pink-purple.dark app-actor-find .work-space .side .toolbar .header {
  color: #f48fb1;
}
.pink-purple.dark app-actor-find .work-space .side .toolbar .header h3 {
  color: #f48fb1;
}
.pink-purple.dark app-actor-find .work-space .side .container .role-resume {
  background: #f48fb1;
}
.pink-purple.dark app-actor-find .work-space .side .container .role-resume .line {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark app-actor-find .work-space .side .container .role-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.1);
}
.pink-purple.dark app-actor-find .work-space .side .container .role-resume .line.editable button {
  background: #f48fb1;
}
.pink-purple.dark app-actor-find .work-space .side .container .section-title {
  color: #f48fb1;
}
.pink-purple.dark app-actor-find .work-space .side .container .section-resume {
  background: #424242;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark app-actor-find .work-space .side .container .section-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-purple.dark app-actor-find .work-space .side .container .section-resume .line.editable:hover .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #424242, 0 -2px 0px #424242, 2px 0 0px #424242, 0 2px 0px #424242, -2px -2px 0px #424242, 2px 2px 0px #424242, 2px -2px 0px #424242, -2px 2px 0px #424242 !important;
}
.pink-purple.dark app-actor-find .work-space .side .container .section-resume .line.editable button {
  background: #303030;
}
.pink-purple.dark app-actor-find .work-space .side .container .section-resume .line .header .icon-complex mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.pink-purple.dark app-actor-find .work-space .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #f48fb1 !important;
}
.pink-purple.dark app-actor-find .work-space .side .container .section-resume .line .header mat-icon:not(.complex) {
  color: rgba(255, 255, 255, 0.7);
}
.pink-purple.dark app-actor-find .work-space .side .container .section-resume .line .header .info h4 {
  color: #f48fb1;
}
.pink-purple.dark app-actor-find .work-space .side .container .section-resume .line ul {
  color: #f48fb1;
}
.pink-purple.dark app-actor-find .work-space .side .container .section-resume .line.head {
  background: black;
}
.pink-purple.dark app-actor-find .work-space .side .container .section-resume .line.head mat-icon {
  color: #ce93d8;
}
.pink-purple.dark app-actor-find .work-space .side .container .section-resume .line.head h4 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee-title {
  color: #f48fb1;
}
.pink-purple.dark app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body {
  background: #424242;
}
.pink-purple.dark app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: rgba(255, 255, 255, 0.7);
}
.pink-purple.dark app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #f48fb1;
}
.pink-purple.dark app-actor-find .work-space .side.edition .toolbar .header {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark app-actor-find .work-space .side.edition .toolbar .header mat-icon {
  color: #ce93d8;
}
.pink-purple.dark app-actor-find .work-space .side.edition .toolbar .header h3 {
  color: #ce93d8;
}
.pink-purple.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body {
  background: #424242 !important;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header mat-icon:not(.complex) {
  color: #f48fb1;
}
.pink-purple.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header h4 {
  color: #f48fb1;
}
.pink-purple.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-actions {
  background: #303030;
}
.pink-purple.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.pink-purple.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header .icon-complex mat-icon.complex {
  color: #ce93d8 !important;
}
.pink-purple.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header h4 {
  color: #ce93d8;
}
.pink-purple.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line ul {
  color: #ce93d8;
}
.pink-purple.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head mat-icon {
  color: #ce93d8 !important;
}
.pink-purple.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head h4 {
  color: white;
}
.pink-purple.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .section-title {
  color: white !important;
}
.pink-purple.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume h6.hint {
  background: #ce93d8;
}
.pink-purple.dark app-actor-find .work-space app-role-user-detail.disabled .side .toolbar .header {
  color: #607D8B !important;
}
.pink-purple.dark app-actor-find .work-space app-role-user-detail.disabled .side .container .role-resume {
  background: #607D8B !important;
}
.pink-purple.dark app-actor-find .work-space app-role-user-detail.disabled .side .container .section-title {
  color: #607D8B !important;
}
.pink-purple.dark app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line ul {
  color: #607D8B !important;
}
.pink-purple.dark app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header mat-icon {
  color: #607D8B;
}
.pink-purple.dark app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .icon-complex mat-icon {
  color: #607D8B !important;
}
.pink-purple.dark app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #607D8B !important;
}
.pink-purple.dark app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .info h4 {
  color: #607D8B !important;
}
.pink-purple.dark app-actor-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee-title {
  color: #607D8B;
}
.pink-purple.dark app-actor-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: #607D8B;
}
.pink-purple.dark app-actor-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #607D8B;
}
.pink-purple.dark .dropzone {
  border: 2px dashed #607D8B;
  background: black !important;
}
.pink-purple.dark .dropzone.hovering {
  border: 2px solid #607D8B;
  color: rgba(255, 255, 255, 0.5) !important;
}
.pink-purple.dark .dropzone button.mat-stroked-button {
  background: #424242;
}
.pink-purple.dark .firmador .content-action-main {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-purple.dark .firmador .content-action-main.disabled {
  color: rgba(255, 255, 255, 0.5) !important;
  background: rgba(255, 255, 255, 0.12);
}
.pink-purple.dark .sign-cycle {
  background: #424242;
}
.pink-purple.dark .sign-cycle button.success {
  background-color: #009688;
}
.pink-purple.dark .sign-cycle button.warning {
  background-color: #673AB7;
}
.pink-purple.dark .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-title-container .cycle-content-title h4 {
  color: #607D8B;
}
.pink-purple.dark .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-action .content-action-main {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-purple.dark .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-action .content-action-main.mat-stroked-button:disabled {
  color: rgba(255, 255, 255, 0.5) !important;
}
.pink-purple.dark .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-action .content-action-main.mat-stroked-button:not(:disabled) {
  color: #ce93d8 !important;
}
.pink-purple.dark .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-action .content-action-side u {
  color: rgba(255, 255, 255, 0.5);
}
.pink-purple.dark .resume-status {
  background: #424242;
}
.pink-purple.dark .help-hoverable mat-icon {
  color: rgba(255, 255, 255, 0.7);
}
.pink-purple.dark .help-hoverable mat-hint {
  background: #424242;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(255, 255, 255, 0.12);
}
.pink-purple.dark .help-hoverable mat-hint b {
  color: #f48fb1 !important;
}
.pink-purple.dark app-file-document-sign-massive-dialog .dialog-content,
.pink-purple.dark app-share-person-dialog .dialog-content {
  background: #303030;
}
.pink-purple.dark app-file-document-sign-massive-dialog .dialog-content mat-toolbar mat-toolbar-row .mat-avatar h3,
.pink-purple.dark app-share-person-dialog .dialog-content mat-toolbar mat-toolbar-row .mat-avatar h3 {
  color: #f48fb1;
}
.pink-purple.dark app-file-document-sign-massive-dialog .dialog-content .dialog-body p b,
.pink-purple.dark app-share-person-dialog .dialog-content .dialog-body p b {
  color: #ce93d8;
}
.pink-purple.dark app-file-document-sign-massive-dialog .dialog-content app-file-document-sign,
.pink-purple.dark app-share-person-dialog .dialog-content app-file-document-sign {
  background: #424242;
}
.pink-purple.dark .mat-basic-chip {
  background: black !important;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark .mat-basic-chip.seen {
  border-color: #2196F3 !important;
  color: #2196F3 !important;
}
.pink-purple.dark .mat-basic-chip.unseen {
  border-color: #607D8B !important;
  color: #607D8B !important;
}
.pink-purple.dark .mat-basic-chip.signed-ok {
  border-color: #009688 !important;
  color: #009688 !important;
}
.pink-purple.dark .mat-basic-chip.signed-ok.cancelled {
  border-color: #795548 !important;
  color: #795548 !important;
}
.pink-purple.dark .mat-basic-chip.signed-not-ok {
  border-color: #673AB7 !important;
  color: #673AB7 !important;
}
.pink-purple.dark .mat-basic-chip.not-signed {
  border-color: #ff4081 !important;
  color: #ff4081 !important;
}
.pink-purple.dark .mat-basic-chip.not-signed-employer {
  border-color: #ff4081 !important;
  color: #ff4081 !important;
}
.pink-purple.dark app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip {
  background: black !important;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.seen {
  border-color: #2196F3 !important;
  color: #2196F3 !important;
}
.pink-purple.dark app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.unseen {
  border-color: #607D8B !important;
  color: #607D8B !important;
}
.pink-purple.dark app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.signed-ok {
  border-color: #009688 !important;
  color: #009688 !important;
}
.pink-purple.dark app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.signed-ok.cancelled {
  border-color: #795548 !important;
  color: #795548 !important;
}
.pink-purple.dark app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.signed-not-ok {
  border-color: #673AB7 !important;
  color: #673AB7 !important;
}
.pink-purple.dark app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.not-signed {
  border-color: #ff4081 !important;
  color: #ff4081 !important;
}
.pink-purple.dark app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.not-signed-employer {
  border-color: #ff4081 !important;
  color: #ff4081 !important;
}
.pink-purple.dark .inbox-container.employee.my-documents .chip-line.mat-line .mat-chip-list-wrapper .mat-basic-chip.seen {
  color: #607D8B !important;
}
.pink-purple.dark .resume-status .mat-basic-chip {
  background: black !important;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark .resume-status .mat-basic-chip.signed-ok {
  border-color: #009688 !important;
  background-color: #009688 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-purple.dark .resume-status .mat-basic-chip.signed-ok.cancelled {
  border-color: #795548 !important;
  background-color: #795548 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-purple.dark .resume-status .mat-basic-chip.signed-not-ok {
  border-color: #673AB7 !important;
  background-color: #673AB7 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-purple.dark app-file-document-view .new-container app-file-document-upload {
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark app-file-document-view .new-container app-upload-form .file-upload-list .mat-subheader {
  background: #424242;
}
.pink-purple.dark app-file-document-view .new-container app-upload-form .file-upload-list mat-list-item {
  background: #303030 !important;
}
.pink-purple.dark app-file-document-view .preview-content {
  background: #607D8B;
}
.pink-purple.dark app-file-document-view .preview-content h4 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark app-file-document-view .view-container {
  background: #424242;
}
.pink-purple.dark app-file-document-view .view-container mat-toolbar.side-bar {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark .formio-hint {
  background: #f48fb1;
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark mat-dialog-container app-file-document-state-chips {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark app-candidate-promotion .load-window.message h3.primary-text {
  color: #f48fb1;
}
.pink-purple.dark app-multiple-documentation-add-dialog .icon-complex mat-icon,
.pink-purple.dark app-documentation-type-set-configuration-dialog .icon-complex mat-icon,
.pink-purple.dark app-add-candidate-dialog .icon-complex mat-icon,
.pink-purple.dark app-share-person-dialog .icon-complex mat-icon,
.pink-purple.dark app-share-person-response-dialog .icon-complex mat-icon {
  color: white !important;
}
.pink-purple.dark app-multiple-documentation-add-dialog .icon-complex mat-icon.complex,
.pink-purple.dark app-documentation-type-set-configuration-dialog .icon-complex mat-icon.complex,
.pink-purple.dark app-add-candidate-dialog .icon-complex mat-icon.complex,
.pink-purple.dark app-share-person-dialog .icon-complex mat-icon.complex,
.pink-purple.dark app-share-person-response-dialog .icon-complex mat-icon.complex {
  color: #f48fb1 !important;
}
.pink-purple.dark app-multiple-documentation-add-dialog .icon-complex mat-icon.complex.mat-accent,
.pink-purple.dark app-documentation-type-set-configuration-dialog .icon-complex mat-icon.complex.mat-accent,
.pink-purple.dark app-add-candidate-dialog .icon-complex mat-icon.complex.mat-accent,
.pink-purple.dark app-share-person-dialog .icon-complex mat-icon.complex.mat-accent,
.pink-purple.dark app-share-person-response-dialog .icon-complex mat-icon.complex.mat-accent {
  color: #ce93d8;
}
.pink-purple.dark app-multiple-documentation-add-dialog .icon-complex mat-icon.complex.side,
.pink-purple.dark app-documentation-type-set-configuration-dialog .icon-complex mat-icon.complex.side,
.pink-purple.dark app-add-candidate-dialog .icon-complex mat-icon.complex.side,
.pink-purple.dark app-share-person-dialog .icon-complex mat-icon.complex.side,
.pink-purple.dark app-share-person-response-dialog .icon-complex mat-icon.complex.side {
  color: rgba(255, 255, 255, 0.7);
}
.pink-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection,
.pink-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection,
.pink-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection,
.pink-purple.dark app-share-person-dialog .dialog-content .document-template-container .document-selection,
.pink-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection {
  background: #303030;
}
.pink-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.pink-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.pink-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3,
.pink-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.pink-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.pink-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3,
.pink-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.pink-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.pink-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3,
.pink-purple.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.pink-purple.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.pink-purple.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3,
.pink-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.pink-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.pink-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3 {
  color: #ce93d8 !important;
}
.pink-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon,
.pink-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon,
.pink-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon,
.pink-purple.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon,
.pink-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon {
  color: #f48fb1;
}
.pink-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3,
.pink-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3,
.pink-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3,
.pink-purple.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3,
.pink-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3 {
  color: #f48fb1;
}
.pink-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex,
.pink-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex,
.pink-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex,
.pink-purple.dark app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex,
.pink-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex {
  color: #ce93d8 !important;
  text-shadow: -2px 0 0px black, 0 -2px 0px black, 2px 0 0px black, 0 2px 0px black, -2px -2px 0px black, 2px 2px 0px black, 2px -2px 0px black, -2px 2px 0px #424242;
}
.pink-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex,
.pink-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex,
.pink-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex,
.pink-purple.dark app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex,
.pink-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex {
  color: rgba(255, 255, 255, 0.5) !important;
}
.pink-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b,
.pink-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b,
.pink-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b,
.pink-purple.dark app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b,
.pink-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b {
  color: white;
}
.pink-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex,
.pink-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex,
.pink-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex,
.pink-purple.dark app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex,
.pink-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex {
  color: rgba(255, 255, 255, 0.5) !important;
}
.pink-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex,
.pink-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex,
.pink-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex,
.pink-purple.dark app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex,
.pink-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex {
  color: rgba(255, 255, 255, 0.5) !important;
}
.pink-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover,
.pink-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover,
.pink-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover,
.pink-purple.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover,
.pink-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover {
  background: rgba(255, 255, 255, 0.04);
}
.pink-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b,
.pink-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b,
.pink-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b,
.pink-purple.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b,
.pink-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b {
  color: white;
}
.pink-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.pink-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.pink-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.pink-purple.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.pink-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble {
  background: #f48fb1;
}
.pink-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble,
.pink-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble,
.pink-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble,
.pink-purple.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble,
.pink-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble {
  background: #e57373;
}
.pink-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble,
.pink-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble,
.pink-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble,
.pink-purple.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble,
.pink-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble {
  color: rgba(255, 255, 255, 0.5);
  background: rgba(255, 255, 255, 0.12);
}
.pink-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble,
.pink-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble,
.pink-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble,
.pink-purple.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble,
.pink-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble {
  background: rgba(255, 255, 255, 0.5);
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide,
.pink-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide,
.pink-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide,
.pink-purple.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide,
.pink-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide {
  border-color: rgba(255, 255, 255, 0.12);
}
.pink-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble,
.pink-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble,
.pink-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble,
.pink-purple.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble,
.pink-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble {
  color: rgba(255, 255, 255, 0.7);
}
.pink-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.pink-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.pink-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.pink-purple.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.pink-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble {
  color: #f48fb1;
}
.pink-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble,
.pink-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble,
.pink-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble,
.pink-purple.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble,
.pink-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble {
  color: #e57373;
}
.pink-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar,
.pink-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar,
.pink-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar,
.pink-purple.dark app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar,
.pink-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar {
  background: #607D8B;
}
.pink-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row,
.pink-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row,
.pink-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row,
.pink-purple.dark app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row,
.pink-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper,
.pink-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper,
.pink-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper,
.pink-purple.dark app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper,
.pink-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper {
  background: #607D8B;
}
.pink-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop,
.pink-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop,
.pink-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop,
.pink-purple.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop,
.pink-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop {
  border-left: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body,
.pink-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body,
.pink-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body,
.pink-purple.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body,
.pink-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body {
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3,
.pink-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3,
.pink-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3,
.pink-purple.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3,
.pink-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3 {
  color: #ce93d8;
}
.pink-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5,
.pink-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5,
.pink-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5,
.pink-purple.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5,
.pink-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5 {
  color: rgba(255, 255, 255, 0.7);
}
.pink-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5,
.pink-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5,
.pink-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5,
.pink-purple.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5,
.pink-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5 {
  color: rgba(255, 255, 255, 0.7);
}
.pink-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator,
.pink-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator,
.pink-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator,
.pink-purple.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator,
.pink-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  background: #303030;
}
.pink-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip,
.pink-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip,
.pink-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip,
.pink-purple.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip,
.pink-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip {
  background: #424242 !important;
}
.pink-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon,
.pink-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon,
.pink-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon,
.pink-purple.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon,
.pink-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon {
  color: #ce93d8 !important;
}
.pink-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3,
.pink-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3,
.pink-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3,
.pink-purple.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3,
.pink-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3 {
  color: #ce93d8 !important;
}
.pink-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5,
.pink-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5,
.pink-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5,
.pink-purple.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5,
.pink-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5 {
  color: rgba(255, 255, 255, 0.7) !important;
}
.pink-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon,
.pink-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon,
.pink-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon,
.pink-purple.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon,
.pink-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon {
  color: #f48fb1 !important;
}
.pink-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3,
.pink-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3,
.pink-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3,
.pink-purple.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3,
.pink-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3 {
  color: #f48fb1 !important;
}
.pink-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5,
.pink-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5,
.pink-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5,
.pink-purple.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5,
.pink-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5 {
  color: #f48fb1 !important;
}
.pink-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled,
.pink-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled,
.pink-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled,
.pink-purple.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled,
.pink-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled {
  background: #303030 !important;
  color: rgba(255, 255, 255, 0.5);
}
.pink-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove,
.pink-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove,
.pink-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove,
.pink-purple.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove,
.pink-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove {
  color: rgba(255, 255, 255, 0.7);
}
.pink-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove,
.pink-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove,
.pink-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove,
.pink-purple.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove,
.pink-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove {
  color: rgba(255, 255, 255, 0.7) !important;
}
.pink-purple.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .preview-action,
.pink-purple.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .preview-action,
.pink-purple.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .preview-action,
.pink-purple.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .preview-action,
.pink-purple.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .preview-action {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark .tyc .sticky {
  background: #424242;
}
.pink-purple.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper:nth-child(odd) datatable-body-row {
  background: #303030;
}
.pink-purple.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active * {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-purple.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active:hover .datatable-body-cell-label {
  background: #f48fb1;
}
.pink-purple.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active:hover .cs-floating-action {
  background: #f48fb1 !important;
}
.pink-purple.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active.row-cancel * {
  color: rgba(255, 255, 255, 0.7) !important;
}
.pink-purple.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active.row-cancel .datatable-row-group {
  background: #607D8B !important;
}
.pink-purple.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active.row-cancel:hover .datatable-body-cell-label {
  background: #607D8B;
}
.pink-purple.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.row-cancel * {
  color: #b0bec5 !important;
  transition-property: color;
  transition-duration: 0.3s;
  transition-timing-function: linear;
}
.pink-purple.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row:hover .datatable-body-cell-label button {
  background: black;
}
.pink-purple.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row datatable-body-cell.candidate-status_bar mat-progress-bar .mat-progress-bar-buffer {
  background-color: black;
}
.pink-purple.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row datatable-body-cell.candidate-status_bar .status_bar-count {
  color: rgba(255, 255, 255, 0.7);
}
.pink-purple.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row datatable-body-cell.candidate-status_bar .inactive {
  color: rgba(255, 255, 255, 0.7);
}
.pink-purple.dark .cs-grid-test .info {
  background: #424242;
}
.pink-purple.dark .cs-grid-test .selected-column {
  background: #424242;
  -webkit-box-shadow: 0 1px 3px 0 rgba(255, 255, 255, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0 1px 3px 0 rgba(255, 255, 255, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 3px 0 rgba(255, 255, 255, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
}
.pink-purple.dark .cs-grid-test .selection-cell .datatable-body-cell.active {
  background: #f48fb1 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-purple.dark .cs-grid-test nav {
  background: #424242;
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 13px 19px 2px rgba(0, 0, 0, 0.14), 0 5px 24px 4px rgba(255, 255, 255, 0.12);
}
.pink-purple.dark .cs-grid-test .ngx-datatable.material {
  background: #424242;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(255, 255, 255, 0.12);
}
.pink-purple.dark .cs-grid-test .ngx-datatable.material.striped .datatable-row-odd {
  background: #303030;
}
.pink-purple.dark .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active, .pink-purple.dark .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active .datatable-row-group, .pink-purple.dark .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active, .pink-purple.dark .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active .datatable-row-group, .pink-purple.dark .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active, .pink-purple.dark .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active .datatable-row-group {
  background: #f48fb1;
  color: #424242;
}
.pink-purple.dark .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active:hover, .pink-purple.dark .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active:hover .datatable-row-group, .pink-purple.dark .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active:hover, .pink-purple.dark .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active:hover .datatable-row-group, .pink-purple.dark .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active:hover, .pink-purple.dark .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active:hover .datatable-row-group {
  background: #f48fb1;
  color: #424242;
}
.pink-purple.dark .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active:focus, .pink-purple.dark .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active:focus .datatable-row-group, .pink-purple.dark .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active:focus, .pink-purple.dark .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active:focus .datatable-row-group, .pink-purple.dark .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active:focus, .pink-purple.dark .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active:focus .datatable-row-group {
  background: #f48fb1;
  color: #424242;
}
.pink-purple.dark .cs-grid-test .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover, .pink-purple.dark .cs-grid-test .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: rgba(255, 255, 255, 0.04);
}
.pink-purple.dark .cs-grid-test .ngx-datatable.material:not(.cell-selection) .datatable-body-row:focus, .pink-purple.dark .cs-grid-test .ngx-datatable.material:not(.cell-selection) .datatable-body-row:focus .datatable-row-group {
  background: rgba(255, 255, 255, 0.04);
}
.pink-purple.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell:hover, .pink-purple.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell:hover .datatable-row-group {
  background: rgba(255, 255, 255, 0.04);
}
.pink-purple.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell:focus, .pink-purple.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell:focus .datatable-row-group {
  background: rgba(255, 255, 255, 0.04);
}
.pink-purple.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active, .pink-purple.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active .datatable-row-group {
  background: #f48fb1;
  color: #424242;
}
.pink-purple.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active:hover, .pink-purple.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active:hover .datatable-row-group {
  background: #f48fb1;
  color: #424242;
}
.pink-purple.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active:focus, .pink-purple.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active:focus .datatable-row-group {
  background: #f48fb1;
  color: #424242;
}
.pink-purple.dark .cs-grid-test .ngx-datatable.material .datatable-header .datatable-row-left,
.pink-purple.dark .cs-grid-test .ngx-datatable.material .datatable-body .datatable-row-left {
  background-color: #424242;
}
.pink-purple.dark .cs-grid-test .ngx-datatable.material .datatable-header .datatable-row-right,
.pink-purple.dark .cs-grid-test .ngx-datatable.material .datatable-body .datatable-row-right {
  background-color: #424242;
}
.pink-purple.dark .cs-grid-test .ngx-datatable.material .datatable-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.pink-purple.dark .cs-grid-test .ngx-datatable.material .datatable-header .datatable-header-cell {
  background-color: #424242;
  color: rgba(255, 255, 255, 0.7);
}
.pink-purple.dark .cs-grid-test .ngx-datatable.material .datatable-header .resize-handle {
  border-right: solid 1px rgba(255, 255, 255, 0.04);
}
.pink-purple.dark .cs-grid-test .ngx-datatable.material .datatable-body .datatable-row-detail {
  background: black;
}
.pink-purple.dark .cs-grid-test .ngx-datatable.material .datatable-body .datatable-group-header {
  background: black;
  border-bottom: solid 1px rgba(255, 255, 255, 0.12);
  border-top: solid 1px rgba(255, 255, 255, 0.12);
}
.pink-purple.dark .cs-grid-test .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
  color: white;
}
.pink-purple.dark .cs-grid-test .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-group-cell {
  color: white;
}
.pink-purple.dark .cs-grid-test .ngx-datatable.material .datatable-body .progress-linear .container {
  background-color: #424242;
}
.pink-purple.dark .cs-grid-test .ngx-datatable.material .datatable-body .progress-linear .container .bar {
  background-color: #f48fb1;
}
.pink-purple.dark .cs-grid-test .ngx-datatable.material .datatable-footer {
  color: rgba(255, 255, 255, 0.7);
}
.pink-purple.dark .cs-grid-test .ngx-datatable.material .datatable-footer .datatable-pager li.disabled a {
  color: rgba(255, 255, 255, 0.5) !important;
  background-color: rgba(0, 0, 0, 0) !important;
}
.pink-purple.dark .cs-grid-test .ngx-datatable.material .datatable-footer .datatable-pager li.active a {
  background-color: #f48fb1;
}
.pink-purple.dark .cs-grid-test .ngx-datatable.material .datatable-footer .datatable-pager a {
  color: rgba(255, 255, 255, 0.7);
}
.pink-purple.dark .cs-grid-test .ngx-datatable.material .datatable-footer .datatable-pager a:hover {
  color: rgba(255, 255, 255, 0.04);
  background-color: rgba(255, 255, 255, 0.04);
}
.pink-purple.dark .cs-grid-test .ngx-datatable.material .datatable-summary-row .datatable-body-row {
  background-color: #303030;
}
.pink-purple.dark .cs-grid-test .ngx-datatable.material .datatable-summary-row .datatable-body-row:hover {
  background-color: #303030;
}
.pink-purple.dark .cs-grid-test .datatable-checkbox input[type=checkbox]:after {
  background: #424242;
}
.pink-purple.dark .view-container .view-toolbar {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal.new.image-opened .help-content img {
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card {
  background: #424242;
}
.pink-purple.dark app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container .aditional-info {
  color: rgba(255, 255, 255, 0.7);
}
.pink-purple.dark app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container .mat-card-footer {
  background: #424242;
}
.pink-purple.dark app-help-index mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card {
  background: #424242;
}
.pink-purple.dark app-help-index mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card .mat-card-footer {
  background: #424242;
}
.pink-purple.dark .cropper .employee-title .title-text {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-purple.dark .cropper app-cs-upload-cropper-control .preview h5 {
  color: rgba(255, 255, 255, 0.7);
}
.pink-purple.dark app-file-document-upload .preview-container {
  background: #607D8B !important;
}
.pink-purple.dark app-file-document-upload .preview-container h4 {
  color: black;
}
.pink-purple.dark .preview-container {
  background: #607D8B !important;
}
.pink-purple.dark .preview-container h4 {
  color: black;
}
.pink-purple.dark .clean-autocomple mat-list {
  background: #424242;
}
.pink-purple.dark .clean-autocomple mat-list .mat-list-item:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-purple.dark .clean-autocomple mat-list .mat-list-item .mat-list-item-content .mat-list-text {
  color: #f48fb1;
}
.pink-purple.dark app-leave .inbox-toolbar {
  background: #303030;
}
.pink-purple.dark app-leave .mat-tab-body-wrapper {
  position: relative;
  overflow: hidden;
  display: flex;
  transition: height 500ms cubic-bezier(0.35, 0, 0.25, 1);
  height: 95% !important;
}
.pink-purple.dark app-leave .leave_container {
  background: #303030;
}
.pink-purple.dark app-leave .leave_container .leave_items .leave_items-item.ok .content .leave_item-status_icon {
  color: #4db6ac;
}
.pink-purple.dark app-leave .leave_container .leave_items .leave_items-item.not-ok .content .leave_item-status_icon {
  color: #ba68c8;
}
.pink-purple.dark app-leave .leave_container .leave_items .leave_items-item.pending .content .leave_item-status_icon {
  color: #ffb74d;
}
.pink-purple.dark app-leave .leave_container .leave_items .leave_items-item.draft .content .leave_item-status_icon {
  color: rgba(255, 255, 255, 0.7);
}
.pink-purple.dark app-leave .leave_container .leave_items .leave_items-item.cancel .content .leave_item-status_icon {
  color: #795548;
}
.pink-purple.dark app-leave .leave_container .leave_items .leave_items-item .content:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-purple.dark app-leave .leave_container .leave_items .leave_items-item .content.selected {
  border-left: 6px solid #ce93d8 !important;
}
.pink-purple.dark app-leave .leave_container .leave_items .leave_items-item .content.selected mat-icon {
  color: #ce93d8 !important;
}
.pink-purple.dark app-leave .leave_container .leave_items .leave_items-item .content.selected .item-data {
  color: white !important;
}
.pink-purple.dark app-leave .leave_container .leave_items .leave_items-item .content .leave_item-icon {
  color: rgba(255, 255, 255, 0.7);
}
.pink-purple.dark app-leave .leave_container .leave_items .leave_items-item .content .item-data {
  color: rgba(255, 255, 255, 0.7);
}
.pink-purple.dark app-leave .leave_container .window-spinner {
  background: #303030 !important;
}
.pink-purple.dark app-leave-detail .leave_detail .leave_detail-title .info h3,
.pink-purple.dark app-leave-detail .leave_detail .leave_detail-title .info mat-icon {
  color: #f48fb1;
}
.pink-purple.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .ok .large-icon {
  background: #e0f2f1;
  color: #4db6ac;
}
.pink-purple.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .ok h2 {
  color: #4db6ac;
}
.pink-purple.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .not-ok .large-icon {
  background: #f3e5f5;
  color: #ba68c8;
}
.pink-purple.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .not-ok h2 {
  color: #ba68c8;
}
.pink-purple.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .pending .large-icon {
  background: #fff3e0;
  color: #ffb74d;
}
.pink-purple.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .pending h2 {
  color: #ffb74d;
}
.pink-purple.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .draft .large-icon {
  background: #f2f2f2;
  color: #dedede;
}
.pink-purple.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .draft h2 {
  color: #dedede;
}
.pink-purple.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .cancelled .large-icon {
  background: #BCAAA4;
  color: #795548;
}
.pink-purple.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .cancelled h2 {
  color: #795548;
}
.pink-purple.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .actions {
  padding: 16px;
  height: 96px;
  column-gap: 16px;
}
.pink-purple.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .actions button {
  height: 100%;
  border-radius: 40px;
}
.pink-purple.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .actions button.ok {
  background: #4db6ac !important;
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .actions button.not-ok {
  color: #ba68c8 !important;
}
.pink-purple.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning mat-basic-chip {
  background: black !important;
  border: 1px solid rgba(255, 255, 255, 0.12) !important;
  border-radius: 40px !important;
  height: 35px !important;
}
.pink-purple.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning mat-basic-chip.signed-ok {
  border-color: #4db6ac !important;
  color: #4db6ac !important;
}
.pink-purple.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning mat-basic-chip.signed-not-ok {
  border-color: #ba68c8 !important;
  color: #ba68c8 !important;
}
.pink-purple.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning mat-basic-chip.draft {
  border-color: #dedede !important;
  color: #dedede !important;
}
.pink-purple.dark app-leave-request-detail .load-window {
  background: #303030;
}
.pink-purple.dark app-leave-request-detail form .leave_detail-container {
  background: #303030;
}
.pink-purple.dark app-leave-request-detail form .leave_detail-container .toolbar .header {
  color: #f48fb1;
}
.pink-purple.dark app-leave-request-detail form .leave_detail-container .toolbar .header .text h3 {
  color: #f48fb1;
}
.pink-purple.dark app-leave-request-detail form .leave_detail-container .toolbar .header .text h5 {
  color: rgba(255, 255, 255, 0.7);
}
.pink-purple.dark app-leave-request-detail form .leave_detail-container .leave_detail-container-content .leave_resume {
  background: #424242;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark app-leave-request-detail form .leave_detail-container .leave_detail-container-content .line h6 {
  color: rgba(255, 255, 255, 0.7);
}
.pink-purple.dark app-leave-request-detail form .leave_detail-container .actions button.ok {
  background: #4db6ac !important;
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark app-leave-request-detail form .leave_detail-container .actions button.not-ok {
  color: #ba68c8 !important;
}
.pink-purple.dark .new_leave_modal-steps {
  display: unset !important;
}
.pink-purple.dark .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button {
  background: #303030;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button .mat-radio-label .mat-radio-label-content .side-text {
  color: rgba(255, 255, 255, 0.7);
}
.pink-purple.dark .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button .mat-radio-label .mat-radio-label-content .side-text.muted {
  color: rgba(255, 255, 255, 0.5);
}
.pink-purple.dark .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button .mat-radio-label .mat-radio-label-content .accent mat-icon,
.pink-purple.dark .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button .mat-radio-label .mat-radio-label-content .accent .main-text {
  color: #ce93d8;
}
.pink-purple.dark .config_leave-container .config_leave-card {
  background: #424242;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark .config_leave-container .config_leave-card .config_leave-auto_chip mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #303030 !important;
}
.pink-purple.dark .config_leave-container .config_leave-card .config_leave-auto_chip mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(255, 255, 255, 0.5);
}
.pink-purple.dark .config_leave-container .config_leave-card .config_leave-auto_chip mat-form-field .mat-form-field-flex {
  background: #303030;
}
.pink-purple.dark *[color=success] {
  color: #009688;
}
.pink-purple.dark .primary {
  color: #f48fb1 !important;
}
.pink-purple.dark .accent {
  color: #ce93d8 !important;
}
.pink-purple.dark app-lsd .icon-complex mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.pink-purple.dark app-lsd .icon-complex mat-icon.complex {
  color: #f48fb1 !important;
}
.pink-purple.dark app-lsd .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.pink-purple.dark app-lsd .work-space .main .load-window {
  background: #424242 !important;
}
.pink-purple.dark app-lsd .work-space .main.side-open {
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark app-lsd .work-space .main .container .item {
  background: #303030;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark app-lsd .work-space .main .container .item h5 {
  color: rgba(255, 255, 255, 0.5);
}
.pink-purple.dark app-lsd .work-space .main .container .item h3 {
  color: white;
}
.pink-purple.dark app-lsd .work-space .main .container .item:hover {
  background: rgba(255, 255, 255, 0.04);
}
.pink-purple.dark app-lsd .work-space .main .container .item.selected {
  background: #f48fb1;
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-purple.dark app-lsd .work-space .main .container .item.selected h3 {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-purple.dark app-lsd .work-space .main .container .item.selected.pending .status {
  background: rgba(255, 255, 255, 0.12) !important;
  color: #ffe082;
}
.pink-purple.dark app-lsd .work-space .main .container .item.selected.ok .status {
  background: rgba(255, 255, 255, 0.12) !important;
  color: #a5d6a7;
}
.pink-purple.dark app-lsd .work-space .main .container .item.selected.not-ok .status {
  background: rgba(255, 255, 255, 0.12) !important;
  color: #e57373;
}
.pink-purple.dark app-lsd .work-space .main .main-action {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark app-lsd .work-space .side {
  background: #303030;
}
.pink-purple.dark app-lsd .work-space .side .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #303030, 0 -2px 0px #303030, 2px 0 0px #303030, 0 2px 0px #303030, -2px -2px 0px #303030, 2px 2px 0px #303030, 2px -2px 0px #303030, -2px 2px 0px #303030;
}
.pink-purple.dark app-lsd .work-space .side *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.pink-purple.dark app-lsd .work-space .side *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
.pink-purple.dark app-lsd .work-space .side .edition-line {
  background: black;
}
.pink-purple.dark app-lsd .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #303030 !important;
}
.pink-purple.dark app-lsd .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(255, 255, 255, 0.5);
}
.pink-purple.dark app-lsd .work-space .side mat-form-field .mat-form-field-flex {
  background: #303030;
}
.pink-purple.dark app-lsd .work-space .side mat-checkbox:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-purple.dark app-lsd .work-space .side .hint.all {
  color: #ce93d8;
}
.pink-purple.dark app-lsd .work-space .side .toolbar .header {
  color: #f48fb1;
}
.pink-purple.dark app-lsd .work-space .side .toolbar .header h3 {
  color: #f48fb1;
}
.pink-purple.dark app-lsd .work-space .side .container .doc-resume {
  background: #f48fb1;
}
.pink-purple.dark app-lsd .work-space .side .container .doc-resume .line {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark app-lsd .work-space .side .container .doc-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.1);
}
.pink-purple.dark app-lsd .work-space .side .container .doc-resume .line.editable button {
  background: #f48fb1;
}
.pink-purple.dark app-lsd .work-space .side .container .section-title {
  color: #f48fb1;
}
.pink-purple.dark app-lsd .work-space .side .container .section-resume {
  background: #424242;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark app-lsd .work-space .side .container .section-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-purple.dark app-lsd .work-space .side .container .section-resume .line.editable:hover .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #424242, 0 -2px 0px #424242, 2px 0 0px #424242, 0 2px 0px #424242, -2px -2px 0px #424242, 2px 2px 0px #424242, 2px -2px 0px #424242, -2px 2px 0px #424242 !important;
}
.pink-purple.dark app-lsd .work-space .side .container .section-resume .line.editable button {
  background: #303030;
}
.pink-purple.dark app-lsd .work-space .side .container .section-resume .line .header .icon-complex mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.pink-purple.dark app-lsd .work-space .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #f48fb1 !important;
}
.pink-purple.dark app-lsd .work-space .side .container .section-resume .line .header mat-icon:not(.complex) {
  color: rgba(255, 255, 255, 0.7);
}
.pink-purple.dark app-lsd .work-space .side .container .section-resume .line .header .info h4 {
  color: #f48fb1;
}
.pink-purple.dark app-lsd .work-space .side .container .section-resume .line ul {
  color: #f48fb1;
}
.pink-purple.dark app-lsd .work-space .side .container .section-resume .line.head {
  background: black;
}
.pink-purple.dark app-lsd .work-space .side .container .section-resume .line.head mat-icon {
  color: #ce93d8;
}
.pink-purple.dark app-lsd .work-space .side .container .section-resume .line.head h4 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark app-lsd .work-space .side.edition .toolbar .header {
  color: rgba(0, 0, 0, 0.87);
}
.pink-purple.dark app-lsd .work-space .side.edition .toolbar .header mat-icon {
  color: #ce93d8;
}
.pink-purple.dark app-lsd .work-space .side.edition .toolbar .header h3 {
  color: #ce93d8;
}
.pink-purple.dark .dialog-content {
  max-height: 90vh;
}
.pink-purple.dark .dialog-content mat-toolbar {
  background: #424242;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark .dialog-content .dialog-container .document-template-container .document-selection mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #303030 !important;
}
.pink-purple.dark .dialog-content .dialog-container .document-template-container .document-selection mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(255, 255, 255, 0.5);
}
.pink-purple.dark .dialog-content .dialog-container .document-template-container .document-selection mat-form-field .mat-form-field-flex {
  background: #303030;
}
.pink-purple.dark .dialog-content .dialog-container .document-template-container .document-selection app-upload-form .file-upload-list .mat-subheader::before {
  color: #f48fb1;
}
.pink-purple.dark .dialog-content .dialog-container .view-container {
  border-left: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-purple.dark .dialog-content .form-actions {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}

.pink-amber .mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-amber .mat-badge-small .mat-badge-content {
  font-size: 9px;
}
.pink-amber .mat-badge-large .mat-badge-content {
  font-size: 24px;
}
.pink-amber .mat-h1,
.pink-amber .mat-headline,
.pink-amber .mat-typography .mat-h1,
.pink-amber .mat-typography .mat-headline,
.pink-amber .mat-typography h1 {
  font: 400 24px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.pink-amber .mat-h2,
.pink-amber .mat-title,
.pink-amber .mat-typography .mat-h2,
.pink-amber .mat-typography .mat-title,
.pink-amber .mat-typography h2 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.pink-amber .mat-h3,
.pink-amber .mat-subheading-2,
.pink-amber .mat-typography .mat-h3,
.pink-amber .mat-typography .mat-subheading-2,
.pink-amber .mat-typography h3 {
  font: 400 16px / 28px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.pink-amber .mat-h4,
.pink-amber .mat-subheading-1,
.pink-amber .mat-typography .mat-h4,
.pink-amber .mat-typography .mat-subheading-1,
.pink-amber .mat-typography h4 {
  font: 400 15px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.pink-amber .mat-h5,
.pink-amber .mat-typography .mat-h5,
.pink-amber .mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}
.pink-amber .mat-h6,
.pink-amber .mat-typography .mat-h6,
.pink-amber .mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}
.pink-amber .mat-body-strong,
.pink-amber .mat-body-2,
.pink-amber .mat-typography .mat-body-strong,
.pink-amber .mat-typography .mat-body-2 {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.pink-amber .mat-body,
.pink-amber .mat-body-1,
.pink-amber .mat-typography .mat-body,
.pink-amber .mat-typography .mat-body-1,
.pink-amber .mat-typography {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.pink-amber .mat-body p,
.pink-amber .mat-body-1 p,
.pink-amber .mat-typography .mat-body p,
.pink-amber .mat-typography .mat-body-1 p,
.pink-amber .mat-typography p {
  margin: 0 0 12px;
}
.pink-amber .mat-small,
.pink-amber .mat-caption,
.pink-amber .mat-typography .mat-small,
.pink-amber .mat-typography .mat-caption {
  font: 400 12px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.pink-amber .mat-display-4,
.pink-amber .mat-typography .mat-display-4 {
  font: 300 112px / 112px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}
.pink-amber .mat-display-3,
.pink-amber .mat-typography .mat-display-3 {
  font: 400 56px / 56px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}
.pink-amber .mat-display-2,
.pink-amber .mat-typography .mat-display-2 {
  font: 400 45px / 48px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}
.pink-amber .mat-display-1,
.pink-amber .mat-typography .mat-display-1 {
  font: 400 34px / 40px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}
.pink-amber .mat-bottom-sheet-container {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.pink-amber .mat-button, .pink-amber .mat-raised-button, .pink-amber .mat-icon-button, .pink-amber .mat-stroked-button,
.pink-amber .mat-flat-button, .pink-amber .mat-fab, .pink-amber .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.pink-amber .mat-button-toggle {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-amber .mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-amber .mat-card-title {
  font-size: 24px;
  font-weight: 500;
}
.pink-amber .mat-card-header .mat-card-title {
  font-size: 20px;
}
.pink-amber .mat-card-subtitle,
.pink-amber .mat-card-content {
  font-size: 14px;
}
.pink-amber .mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-amber .mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}
.pink-amber .mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.pink-amber .mat-chip .mat-chip-trailing-icon.mat-icon,
.pink-amber .mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}
.pink-amber .mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-amber .mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}
.pink-amber .mat-cell, .pink-amber .mat-footer-cell {
  font-size: 14px;
}
.pink-amber .mat-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-amber .mat-calendar-body {
  font-size: 13px;
}
.pink-amber .mat-calendar-body-label,
.pink-amber .mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}
.pink-amber .mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}
.pink-amber .mat-dialog-title {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.pink-amber .mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400;
}
.pink-amber .mat-expansion-panel-content {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.pink-amber .mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.pink-amber .mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}
.pink-amber .mat-form-field-prefix .mat-icon,
.pink-amber .mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.pink-amber .mat-form-field-prefix .mat-icon-button,
.pink-amber .mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.pink-amber .mat-form-field-prefix .mat-icon-button .mat-icon,
.pink-amber .mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}
.pink-amber .mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}
.pink-amber .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.pink-amber .mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34351em) scale(0.75);
  width: 133.3335733333%;
}
.pink-amber .mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.3435em) scale(0.75);
  width: 133.3335833333%;
}
.pink-amber .mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}
.pink-amber .mat-form-field-label {
  top: 1.34375em;
}
.pink-amber .mat-form-field-underline {
  bottom: 1.34375em;
}
.pink-amber .mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}
.pink-amber .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.pink-amber .mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.pink-amber .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.pink-amber .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00172px);
  width: 133.3340533333%;
}
.pink-amber .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00173px);
  width: 133.3340633333%;
}
.pink-amber .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00174px);
  width: 133.3340733333%;
}
.pink-amber .mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.pink-amber .mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.pink-amber .mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}
@media print {
  .pink-amber .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.pink-amber .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2805em) scale(0.75);
  }
  .pink-amber .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28049em) scale(0.75);
  }
  .pink-amber .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28048em) scale(0.75);
  }
}
.pink-amber .mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.pink-amber .mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.pink-amber .mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.pink-amber .mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59351em) scale(0.75);
  width: 133.3335733333%;
}
.pink-amber .mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.5935em) scale(0.75);
  width: 133.3335833333%;
}
.pink-amber .mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.pink-amber .mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.pink-amber .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.pink-amber .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59351em) scale(0.75);
  width: 133.3335733333%;
}
.pink-amber .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.5935em) scale(0.75);
  width: 133.3335833333%;
}
.pink-amber .mat-grid-tile-header,
.pink-amber .mat-grid-tile-footer {
  font-size: 14px;
}
.pink-amber .mat-grid-tile-header .mat-line,
.pink-amber .mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.pink-amber .mat-grid-tile-header .mat-line:nth-child(n+2),
.pink-amber .mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.pink-amber input.mat-input-element {
  margin-top: -0.0625em;
}
.pink-amber .mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
}
.pink-amber .mat-paginator,
.pink-amber .mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
}
.pink-amber .mat-radio-button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-amber .mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-amber .mat-select-trigger {
  height: 1.125em;
}
.pink-amber .mat-slide-toggle-content {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-amber .mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}
.pink-amber .mat-stepper-vertical, .pink-amber .mat-stepper-horizontal {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-amber .mat-step-label {
  font-size: 14px;
  font-weight: 400;
}
.pink-amber .mat-step-sub-label-error {
  font-weight: normal;
}
.pink-amber .mat-step-label-error {
  font-size: 14px;
}
.pink-amber .mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}
.pink-amber .mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-amber .mat-tab-label, .pink-amber .mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.pink-amber .mat-toolbar,
.pink-amber .mat-toolbar h1,
.pink-amber .mat-toolbar h2,
.pink-amber .mat-toolbar h3,
.pink-amber .mat-toolbar h4,
.pink-amber .mat-toolbar h5,
.pink-amber .mat-toolbar h6 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0;
}
.pink-amber .mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.pink-amber .mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.pink-amber .mat-list-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-amber .mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-amber .mat-list-base .mat-list-item {
  font-size: 16px;
}
.pink-amber .mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.pink-amber .mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.pink-amber .mat-list-base .mat-list-option {
  font-size: 16px;
}
.pink-amber .mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.pink-amber .mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.pink-amber .mat-list-base .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.pink-amber .mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.pink-amber .mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.pink-amber .mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.pink-amber .mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.pink-amber .mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.pink-amber .mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.pink-amber .mat-list-base[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}
.pink-amber .mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
}
.pink-amber .mat-optgroup-label {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.pink-amber .mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
}
.pink-amber .mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}
.pink-amber .mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-amber .mat-tree-node,
.pink-amber .mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}
.pink-amber .mat-ripple {
  overflow: hidden;
  position: relative;
}
.pink-amber .mat-ripple:not(:empty) {
  transform: translateZ(0);
}
.pink-amber .mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}
.pink-amber .mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .pink-amber .mat-ripple-element {
  display: none;
}

.pink-amber .cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .pink-amber .cdk-visually-hidden {
  left: auto;
  right: 0;
}
.pink-amber .cdk-overlay-container, .pink-amber .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.pink-amber .cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.pink-amber .cdk-overlay-container:empty {
  display: none;
}
.pink-amber .cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}
.pink-amber .cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}
.pink-amber .cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.pink-amber .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .pink-amber .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}
.pink-amber .cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}
.pink-amber .cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.pink-amber .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}
.pink-amber .cdk-overlay-backdrop-noop-animation {
  transition: none;
}
.pink-amber .cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}
.pink-amber .cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}
.pink-amber textarea.cdk-textarea-autosize {
  resize: none;
}
.pink-amber textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}
.pink-amber textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}
@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.pink-amber .cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}
.pink-amber .cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}
.pink-amber .mat-focus-indicator {
  position: relative;
}
.pink-amber .mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.pink-amber .mat-focus-indicator:focus::before {
  content: "";
}
.cdk-high-contrast-active .pink-amber {
  --mat-focus-indicator-display: block;
}

.pink-amber .mat-mdc-focus-indicator {
  position: relative;
}
.pink-amber .mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.pink-amber .mat-mdc-focus-indicator:focus::before {
  content: "";
}
.cdk-high-contrast-active .pink-amber {
  --mat-mdc-focus-indicator-display: block;
}

.pink-amber .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.pink-amber .mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber .mat-option:hover:not(.mat-option-disabled), .pink-amber .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.pink-amber .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.pink-amber .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.pink-amber .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #e91e63;
}
.pink-amber .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ffc107;
}
.pink-amber .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f44336;
}
.pink-amber .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}
.pink-amber .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.pink-amber .mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.pink-amber .mat-pseudo-checkbox::after {
  color: #fafafa;
}
.pink-amber .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.pink-amber .mat-primary .mat-pseudo-checkbox-checked,
.pink-amber .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #e91e63;
}
.pink-amber .mat-pseudo-checkbox-checked,
.pink-amber .mat-pseudo-checkbox-indeterminate,
.pink-amber .mat-accent .mat-pseudo-checkbox-checked,
.pink-amber .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #ffc107;
}
.pink-amber .mat-warn .mat-pseudo-checkbox-checked,
.pink-amber .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #f44336;
}
.pink-amber .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.pink-amber .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.pink-amber .mat-app-background, .pink-amber.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.pink-amber .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.pink-amber .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.pink-amber .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.pink-amber .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.pink-amber .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.pink-amber .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.pink-amber .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.pink-amber .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.pink-amber .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.pink-amber .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.pink-amber .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.pink-amber .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.pink-amber .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.pink-amber .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.pink-amber .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.pink-amber .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.pink-amber .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.pink-amber .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.pink-amber .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.pink-amber .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.pink-amber .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.pink-amber .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.pink-amber .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.pink-amber .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.pink-amber .mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.pink-amber .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.pink-amber .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber .mat-badge-content {
  color: white;
  background: #e91e63;
}
.cdk-high-contrast-active .pink-amber .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.pink-amber .mat-badge-accent .mat-badge-content {
  background: #ffc107;
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber .mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336;
}
.pink-amber .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.pink-amber .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber .mat-button, .pink-amber .mat-icon-button, .pink-amber .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.pink-amber .mat-button.mat-primary, .pink-amber .mat-icon-button.mat-primary, .pink-amber .mat-stroked-button.mat-primary {
  color: #e91e63;
}
.pink-amber .mat-button.mat-accent, .pink-amber .mat-icon-button.mat-accent, .pink-amber .mat-stroked-button.mat-accent {
  color: #ffc107;
}
.pink-amber .mat-button.mat-warn, .pink-amber .mat-icon-button.mat-warn, .pink-amber .mat-stroked-button.mat-warn {
  color: #f44336;
}
.pink-amber .mat-button.mat-primary.mat-button-disabled, .pink-amber .mat-button.mat-accent.mat-button-disabled, .pink-amber .mat-button.mat-warn.mat-button-disabled, .pink-amber .mat-button.mat-button-disabled.mat-button-disabled, .pink-amber .mat-icon-button.mat-primary.mat-button-disabled, .pink-amber .mat-icon-button.mat-accent.mat-button-disabled, .pink-amber .mat-icon-button.mat-warn.mat-button-disabled, .pink-amber .mat-icon-button.mat-button-disabled.mat-button-disabled, .pink-amber .mat-stroked-button.mat-primary.mat-button-disabled, .pink-amber .mat-stroked-button.mat-accent.mat-button-disabled, .pink-amber .mat-stroked-button.mat-warn.mat-button-disabled, .pink-amber .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.pink-amber .mat-button.mat-primary .mat-button-focus-overlay, .pink-amber .mat-icon-button.mat-primary .mat-button-focus-overlay, .pink-amber .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #e91e63;
}
.pink-amber .mat-button.mat-accent .mat-button-focus-overlay, .pink-amber .mat-icon-button.mat-accent .mat-button-focus-overlay, .pink-amber .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #ffc107;
}
.pink-amber .mat-button.mat-warn .mat-button-focus-overlay, .pink-amber .mat-icon-button.mat-warn .mat-button-focus-overlay, .pink-amber .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #f44336;
}
.pink-amber .mat-button.mat-button-disabled .mat-button-focus-overlay, .pink-amber .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .pink-amber .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.pink-amber .mat-button .mat-ripple-element, .pink-amber .mat-icon-button .mat-ripple-element, .pink-amber .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.pink-amber .mat-button-focus-overlay {
  background: black;
}
.pink-amber .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.pink-amber .mat-flat-button, .pink-amber .mat-raised-button, .pink-amber .mat-fab, .pink-amber .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.pink-amber .mat-flat-button.mat-primary, .pink-amber .mat-raised-button.mat-primary, .pink-amber .mat-fab.mat-primary, .pink-amber .mat-mini-fab.mat-primary {
  color: white;
}
.pink-amber .mat-flat-button.mat-accent, .pink-amber .mat-raised-button.mat-accent, .pink-amber .mat-fab.mat-accent, .pink-amber .mat-mini-fab.mat-accent {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber .mat-flat-button.mat-warn, .pink-amber .mat-raised-button.mat-warn, .pink-amber .mat-fab.mat-warn, .pink-amber .mat-mini-fab.mat-warn {
  color: white;
}
.pink-amber .mat-flat-button.mat-primary.mat-button-disabled, .pink-amber .mat-flat-button.mat-accent.mat-button-disabled, .pink-amber .mat-flat-button.mat-warn.mat-button-disabled, .pink-amber .mat-flat-button.mat-button-disabled.mat-button-disabled, .pink-amber .mat-raised-button.mat-primary.mat-button-disabled, .pink-amber .mat-raised-button.mat-accent.mat-button-disabled, .pink-amber .mat-raised-button.mat-warn.mat-button-disabled, .pink-amber .mat-raised-button.mat-button-disabled.mat-button-disabled, .pink-amber .mat-fab.mat-primary.mat-button-disabled, .pink-amber .mat-fab.mat-accent.mat-button-disabled, .pink-amber .mat-fab.mat-warn.mat-button-disabled, .pink-amber .mat-fab.mat-button-disabled.mat-button-disabled, .pink-amber .mat-mini-fab.mat-primary.mat-button-disabled, .pink-amber .mat-mini-fab.mat-accent.mat-button-disabled, .pink-amber .mat-mini-fab.mat-warn.mat-button-disabled, .pink-amber .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.pink-amber .mat-flat-button.mat-primary, .pink-amber .mat-raised-button.mat-primary, .pink-amber .mat-fab.mat-primary, .pink-amber .mat-mini-fab.mat-primary {
  background-color: #e91e63;
}
.pink-amber .mat-flat-button.mat-accent, .pink-amber .mat-raised-button.mat-accent, .pink-amber .mat-fab.mat-accent, .pink-amber .mat-mini-fab.mat-accent {
  background-color: #ffc107;
}
.pink-amber .mat-flat-button.mat-warn, .pink-amber .mat-raised-button.mat-warn, .pink-amber .mat-fab.mat-warn, .pink-amber .mat-mini-fab.mat-warn {
  background-color: #f44336;
}
.pink-amber .mat-flat-button.mat-primary.mat-button-disabled, .pink-amber .mat-flat-button.mat-accent.mat-button-disabled, .pink-amber .mat-flat-button.mat-warn.mat-button-disabled, .pink-amber .mat-flat-button.mat-button-disabled.mat-button-disabled, .pink-amber .mat-raised-button.mat-primary.mat-button-disabled, .pink-amber .mat-raised-button.mat-accent.mat-button-disabled, .pink-amber .mat-raised-button.mat-warn.mat-button-disabled, .pink-amber .mat-raised-button.mat-button-disabled.mat-button-disabled, .pink-amber .mat-fab.mat-primary.mat-button-disabled, .pink-amber .mat-fab.mat-accent.mat-button-disabled, .pink-amber .mat-fab.mat-warn.mat-button-disabled, .pink-amber .mat-fab.mat-button-disabled.mat-button-disabled, .pink-amber .mat-mini-fab.mat-primary.mat-button-disabled, .pink-amber .mat-mini-fab.mat-accent.mat-button-disabled, .pink-amber .mat-mini-fab.mat-warn.mat-button-disabled, .pink-amber .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.pink-amber .mat-flat-button.mat-primary .mat-ripple-element, .pink-amber .mat-raised-button.mat-primary .mat-ripple-element, .pink-amber .mat-fab.mat-primary .mat-ripple-element, .pink-amber .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.pink-amber .mat-flat-button.mat-accent .mat-ripple-element, .pink-amber .mat-raised-button.mat-accent .mat-ripple-element, .pink-amber .mat-fab.mat-accent .mat-ripple-element, .pink-amber .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.pink-amber .mat-flat-button.mat-warn .mat-ripple-element, .pink-amber .mat-raised-button.mat-warn .mat-ripple-element, .pink-amber .mat-fab.mat-warn .mat-ripple-element, .pink-amber .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.pink-amber .mat-stroked-button:not([class*=mat-elevation-z]), .pink-amber .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.pink-amber .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.pink-amber .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.pink-amber .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.pink-amber .mat-fab:not([class*=mat-elevation-z]), .pink-amber .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.pink-amber .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .pink-amber .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.pink-amber .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .pink-amber .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.pink-amber .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.pink-amber .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.pink-amber .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.pink-amber .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.pink-amber .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.pink-amber .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.pink-amber .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.pink-amber .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.pink-amber .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
.pink-amber [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.pink-amber .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
.pink-amber .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.pink-amber .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.pink-amber .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.pink-amber .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.pink-amber .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.pink-amber .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
.pink-amber .mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.pink-amber .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.pink-amber .mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.pink-amber .mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}
.pink-amber .mat-checkbox-checkmark {
  fill: #fafafa;
}
.pink-amber .mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
.pink-amber .mat-checkbox-mixedmark {
  background-color: #fafafa;
}
.pink-amber .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .pink-amber .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #e91e63;
}
.pink-amber .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .pink-amber .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #ffc107;
}
.pink-amber .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .pink-amber .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #f44336;
}
.pink-amber .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .pink-amber .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.pink-amber .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.pink-amber .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}
.pink-amber .mat-checkbox .mat-ripple-element {
  background-color: black;
}
.pink-amber .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.pink-amber .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #e91e63;
}
.pink-amber .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.pink-amber .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #ffc107;
}
.pink-amber .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.pink-amber .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #f44336;
}
.pink-amber .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber .mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.pink-amber .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.pink-amber .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.pink-amber .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.pink-amber .mat-chip.mat-standard-chip::after {
  background: black;
}
.pink-amber .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #e91e63;
  color: white;
}
.pink-amber .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.pink-amber .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.pink-amber .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #f44336;
  color: white;
}
.pink-amber .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.pink-amber .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.pink-amber .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #ffc107;
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.pink-amber .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.pink-amber .mat-table {
  background: white;
}
.pink-amber .mat-table thead, .pink-amber .mat-table tbody, .pink-amber .mat-table tfoot,
.pink-amber mat-header-row, .pink-amber mat-row, .pink-amber mat-footer-row,
.pink-amber [mat-header-row], .pink-amber [mat-row], .pink-amber [mat-footer-row],
.pink-amber .mat-table-sticky {
  background: inherit;
}
.pink-amber mat-row, .pink-amber mat-header-row, .pink-amber mat-footer-row,
.pink-amber th.mat-header-cell, .pink-amber td.mat-cell, .pink-amber td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.pink-amber .mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}
.pink-amber .mat-cell, .pink-amber .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber .mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}
.pink-amber .mat-datepicker-toggle,
.pink-amber .mat-datepicker-content .mat-calendar-next-button,
.pink-amber .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.pink-amber .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.pink-amber .mat-calendar-table-header,
.pink-amber .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.pink-amber .mat-calendar-body-cell-content,
.pink-amber .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.pink-amber .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.pink-amber .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.pink-amber .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.pink-amber .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.pink-amber .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.pink-amber .mat-calendar-body-in-range::before {
  background: rgba(233, 30, 99, 0.2);
}
.pink-amber .mat-calendar-body-comparison-identical,
.pink-amber .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.pink-amber .mat-calendar-body-comparison-bridge-start::before,
.pink-amber [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(233, 30, 99, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.pink-amber .mat-calendar-body-comparison-bridge-end::before,
.pink-amber [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(233, 30, 99, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.pink-amber .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.pink-amber .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.pink-amber .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.pink-amber .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.pink-amber .mat-calendar-body-selected {
  background-color: #e91e63;
  color: white;
}
.pink-amber .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(233, 30, 99, 0.4);
}
.pink-amber .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.pink-amber .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.pink-amber .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(233, 30, 99, 0.3);
}
@media (hover: hover) {
  .pink-amber .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(233, 30, 99, 0.3);
  }
}
.pink-amber .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(255, 193, 7, 0.2);
}
.pink-amber .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.pink-amber .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.pink-amber .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.pink-amber .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 193, 7, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.pink-amber .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.pink-amber .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 193, 7, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.pink-amber .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.pink-amber .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.pink-amber .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.pink-amber .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.pink-amber .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #ffc107;
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 193, 7, 0.4);
}
.pink-amber .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.pink-amber .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.pink-amber .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 193, 7, 0.3);
}
@media (hover: hover) {
  .pink-amber .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(255, 193, 7, 0.3);
  }
}
.pink-amber .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}
.pink-amber .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.pink-amber .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.pink-amber .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.pink-amber .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.pink-amber .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.pink-amber .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.pink-amber .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.pink-amber .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.pink-amber .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.pink-amber .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.pink-amber .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: white;
}
.pink-amber .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}
.pink-amber .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.pink-amber .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.pink-amber .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(244, 67, 54, 0.3);
}
@media (hover: hover) {
  .pink-amber .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(244, 67, 54, 0.3);
  }
}
.pink-amber .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.pink-amber .mat-datepicker-toggle-active {
  color: #e91e63;
}
.pink-amber .mat-datepicker-toggle-active.mat-accent {
  color: #ffc107;
}
.pink-amber .mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}
.pink-amber .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.pink-amber .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.pink-amber .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.pink-amber .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.pink-amber .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.pink-amber .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .pink-amber .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .pink-amber .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .pink-amber .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.pink-amber .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber .mat-expansion-panel-header-description,
.pink-amber .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.pink-amber .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.pink-amber .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.pink-amber .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.pink-amber .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.pink-amber .mat-hint {
  color: rgba(0, 0, 0, 0.6);
}
.pink-amber .mat-form-field.mat-focused .mat-form-field-label {
  color: #e91e63;
}
.pink-amber .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #ffc107;
}
.pink-amber .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #f44336;
}
.pink-amber .mat-focused .mat-form-field-required-marker {
  color: #ffc107;
}
.pink-amber .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.pink-amber .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #e91e63;
}
.pink-amber .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #ffc107;
}
.pink-amber .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #f44336;
}
.pink-amber .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #e91e63;
}
.pink-amber .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #ffc107;
}
.pink-amber .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #f44336;
}
.pink-amber .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #f44336;
}
.pink-amber .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.pink-amber .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #f44336;
}
.pink-amber .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.pink-amber .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #f44336;
}
.pink-amber .mat-error {
  color: #f44336;
}
.pink-amber .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.pink-amber .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.pink-amber .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.pink-amber .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.pink-amber .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.pink-amber .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.pink-amber .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.pink-amber .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.pink-amber .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.pink-amber .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.pink-amber .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.pink-amber .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.pink-amber .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #e91e63;
}
.pink-amber .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #ffc107;
}
.pink-amber .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #f44336;
}
.pink-amber .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #f44336;
}
.pink-amber .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.pink-amber .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.pink-amber .mat-icon.mat-primary {
  color: #e91e63;
}
.pink-amber .mat-icon.mat-accent {
  color: #ffc107;
}
.pink-amber .mat-icon.mat-warn {
  color: #f44336;
}
.pink-amber .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.pink-amber .mat-input-element:disabled,
.pink-amber .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.pink-amber .mat-input-element {
  caret-color: #e91e63;
}
.pink-amber .mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.pink-amber .mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.pink-amber .mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.pink-amber .mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.pink-amber .mat-form-field.mat-accent .mat-input-element {
  caret-color: #ffc107;
}
.pink-amber .mat-form-field.mat-warn .mat-input-element,
.pink-amber .mat-form-field-invalid .mat-input-element {
  caret-color: #f44336;
}
.pink-amber .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #f44336;
}
.pink-amber .mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber .mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber .mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.pink-amber .mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}
.pink-amber .mat-list-option:hover, .pink-amber .mat-list-option:focus,
.pink-amber .mat-nav-list .mat-list-item:hover,
.pink-amber .mat-nav-list .mat-list-item:focus,
.pink-amber .mat-action-list .mat-list-item:hover,
.pink-amber .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.pink-amber .mat-list-single-selected-option, .pink-amber .mat-list-single-selected-option:hover, .pink-amber .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.pink-amber .mat-menu-panel {
  background: white;
}
.pink-amber .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.pink-amber .mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber .mat-menu-item[disabled],
.pink-amber .mat-menu-item[disabled] .mat-menu-submenu-icon,
.pink-amber .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}
.pink-amber .mat-menu-item .mat-icon-no-color,
.pink-amber .mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}
.pink-amber .mat-menu-item:hover:not([disabled]),
.pink-amber .mat-menu-item.cdk-program-focused:not([disabled]),
.pink-amber .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.pink-amber .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.pink-amber .mat-paginator {
  background: white;
}
.pink-amber .mat-paginator,
.pink-amber .mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}
.pink-amber .mat-paginator-decrement,
.pink-amber .mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.pink-amber .mat-paginator-first,
.pink-amber .mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.pink-amber .mat-icon-button[disabled] .mat-paginator-decrement,
.pink-amber .mat-icon-button[disabled] .mat-paginator-increment,
.pink-amber .mat-icon-button[disabled] .mat-paginator-first,
.pink-amber .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.pink-amber .mat-progress-bar-background {
  fill: #f6c3d4;
}
.pink-amber .mat-progress-bar-buffer {
  background-color: #f6c3d4;
}
.pink-amber .mat-progress-bar-fill::after {
  background-color: #e91e63;
}
.pink-amber .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #fbecbd;
}
.pink-amber .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #fbecbd;
}
.pink-amber .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #ffc107;
}
.pink-amber .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f9ccc9;
}
.pink-amber .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f9ccc9;
}
.pink-amber .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #f44336;
}
.pink-amber .mat-progress-spinner circle, .pink-amber .mat-spinner circle {
  stroke: #e91e63;
}
.pink-amber .mat-progress-spinner.mat-accent circle, .pink-amber .mat-spinner.mat-accent circle {
  stroke: #ffc107;
}
.pink-amber .mat-progress-spinner.mat-warn circle, .pink-amber .mat-spinner.mat-warn circle {
  stroke: #f44336;
}
.pink-amber .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.pink-amber .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e91e63;
}
.pink-amber .mat-radio-button.mat-primary .mat-radio-inner-circle,
.pink-amber .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .pink-amber .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .pink-amber .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #e91e63;
}
.pink-amber .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ffc107;
}
.pink-amber .mat-radio-button.mat-accent .mat-radio-inner-circle,
.pink-amber .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .pink-amber .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .pink-amber .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #ffc107;
}
.pink-amber .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f44336;
}
.pink-amber .mat-radio-button.mat-warn .mat-radio-inner-circle,
.pink-amber .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .pink-amber .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .pink-amber .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #f44336;
}
.pink-amber .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.pink-amber .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.pink-amber .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.pink-amber .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.pink-amber .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.pink-amber .mat-radio-button .mat-ripple-element {
  background-color: black;
}
.pink-amber .mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber .mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.pink-amber .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.pink-amber .mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.pink-amber .mat-select-panel {
  background: white;
}
.pink-amber .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.pink-amber .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.pink-amber .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #e91e63;
}
.pink-amber .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #ffc107;
}
.pink-amber .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #f44336;
}
.pink-amber .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #f44336;
}
.pink-amber .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.pink-amber .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber .mat-drawer.mat-drawer-push {
  background-color: white;
}
.pink-amber .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.pink-amber .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.pink-amber .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.pink-amber [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.pink-amber [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.pink-amber .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.pink-amber .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #ffc107;
}
.pink-amber .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 193, 7, 0.54);
}
.pink-amber .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #ffc107;
}
.pink-amber .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #e91e63;
}
.pink-amber .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(233, 30, 99, 0.54);
}
.pink-amber .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #e91e63;
}
.pink-amber .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #f44336;
}
.pink-amber .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(244, 67, 54, 0.54);
}
.pink-amber .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #f44336;
}
.pink-amber .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
.pink-amber .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
.pink-amber .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.pink-amber .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.pink-amber .mat-slider.mat-primary .mat-slider-track-fill,
.pink-amber .mat-slider.mat-primary .mat-slider-thumb,
.pink-amber .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #e91e63;
}
.pink-amber .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.pink-amber .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(233, 30, 99, 0.2);
}
.pink-amber .mat-slider.mat-accent .mat-slider-track-fill,
.pink-amber .mat-slider.mat-accent .mat-slider-thumb,
.pink-amber .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #ffc107;
}
.pink-amber .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(255, 193, 7, 0.2);
}
.pink-amber .mat-slider.mat-warn .mat-slider-track-fill,
.pink-amber .mat-slider.mat-warn .mat-slider-thumb,
.pink-amber .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #f44336;
}
.pink-amber .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.pink-amber .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(244, 67, 54, 0.2);
}
.pink-amber .mat-slider:hover .mat-slider-track-background,
.pink-amber .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}
.pink-amber .mat-slider.mat-slider-disabled .mat-slider-track-background,
.pink-amber .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.pink-amber .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.pink-amber .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.pink-amber .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.pink-amber .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.pink-amber .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.pink-amber .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.pink-amber .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.pink-amber .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.pink-amber .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .pink-amber .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.pink-amber .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .pink-amber .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}
.pink-amber .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.pink-amber .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.pink-amber .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.pink-amber .mat-step-header.cdk-keyboard-focused, .pink-amber .mat-step-header.cdk-program-focused, .pink-amber .mat-step-header:hover:not([aria-disabled]), .pink-amber .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.pink-amber .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .pink-amber .mat-step-header:hover {
    background: none;
  }
}
.pink-amber .mat-step-header .mat-step-label,
.pink-amber .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.pink-amber .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.pink-amber .mat-step-header .mat-step-icon-selected,
.pink-amber .mat-step-header .mat-step-icon-state-done,
.pink-amber .mat-step-header .mat-step-icon-state-edit {
  background-color: #e91e63;
  color: white;
}
.pink-amber .mat-step-header.mat-accent .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber .mat-step-header.mat-accent .mat-step-icon-selected,
.pink-amber .mat-step-header.mat-accent .mat-step-icon-state-done,
.pink-amber .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #ffc107;
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.pink-amber .mat-step-header.mat-warn .mat-step-icon-selected,
.pink-amber .mat-step-header.mat-warn .mat-step-icon-state-done,
.pink-amber .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #f44336;
  color: white;
}
.pink-amber .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336;
}
.pink-amber .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber .mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336;
}
.pink-amber .mat-stepper-horizontal, .pink-amber .mat-stepper-vertical {
  background-color: white;
}
.pink-amber .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.pink-amber .mat-horizontal-stepper-header::before,
.pink-amber .mat-horizontal-stepper-header::after,
.pink-amber .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.pink-amber .mat-sort-header-arrow {
  color: #757575;
}
.pink-amber .mat-tab-nav-bar,
.pink-amber .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber .mat-tab-group-inverted-header .mat-tab-nav-bar,
.pink-amber .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.pink-amber .mat-tab-label, .pink-amber .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber .mat-tab-label.mat-tab-disabled, .pink-amber .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.pink-amber .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.pink-amber .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.pink-amber .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.pink-amber .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.pink-amber .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-amber .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-amber .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-amber .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .pink-amber .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-amber .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-amber .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-amber .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 187, 208, 0.3);
}
.pink-amber .mat-tab-group.mat-primary .mat-ink-bar, .pink-amber .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #e91e63;
}
.pink-amber .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .pink-amber .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .pink-amber .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .pink-amber .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.pink-amber .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-amber .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-amber .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-amber .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .pink-amber .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-amber .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-amber .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-amber .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 236, 179, 0.3);
}
.pink-amber .mat-tab-group.mat-accent .mat-ink-bar, .pink-amber .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #ffc107;
}
.pink-amber .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .pink-amber .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .pink-amber .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .pink-amber .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.pink-amber .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-amber .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-amber .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-amber .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .pink-amber .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-amber .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-amber .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-amber .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.pink-amber .mat-tab-group.mat-warn .mat-ink-bar, .pink-amber .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #f44336;
}
.pink-amber .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .pink-amber .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .pink-amber .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .pink-amber .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.pink-amber .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-amber .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-amber .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-amber .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .pink-amber .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-amber .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-amber .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-amber .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 187, 208, 0.3);
}
.pink-amber .mat-tab-group.mat-background-primary > .mat-tab-header, .pink-amber .mat-tab-group.mat-background-primary > .mat-tab-link-container, .pink-amber .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .pink-amber .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .pink-amber .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .pink-amber .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #e91e63;
}
.pink-amber .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .pink-amber .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .pink-amber .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .pink-amber .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.pink-amber .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .pink-amber .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .pink-amber .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .pink-amber .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.pink-amber .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.pink-amber .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.pink-amber .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.pink-amber .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .pink-amber .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.pink-amber .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.pink-amber .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.pink-amber .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.pink-amber .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.pink-amber .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .pink-amber .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.pink-amber .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.pink-amber .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.pink-amber .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.pink-amber .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .pink-amber .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.pink-amber .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.pink-amber .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.pink-amber .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-amber .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-amber .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-amber .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .pink-amber .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-amber .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-amber .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-amber .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 236, 179, 0.3);
}
.pink-amber .mat-tab-group.mat-background-accent > .mat-tab-header, .pink-amber .mat-tab-group.mat-background-accent > .mat-tab-link-container, .pink-amber .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .pink-amber .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .pink-amber .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .pink-amber .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #ffc107;
}
.pink-amber .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .pink-amber .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .pink-amber .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .pink-amber .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .pink-amber .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .pink-amber .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .pink-amber .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.pink-amber .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.pink-amber .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.pink-amber .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.pink-amber .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .pink-amber .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.pink-amber .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.pink-amber .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.pink-amber .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.pink-amber .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.pink-amber .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .pink-amber .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.pink-amber .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.pink-amber .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.pink-amber .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.pink-amber .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .pink-amber .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.pink-amber .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.pink-amber .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.pink-amber .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-amber .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-amber .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-amber .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .pink-amber .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-amber .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-amber .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-amber .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.pink-amber .mat-tab-group.mat-background-warn > .mat-tab-header, .pink-amber .mat-tab-group.mat-background-warn > .mat-tab-link-container, .pink-amber .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .pink-amber .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .pink-amber .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .pink-amber .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #f44336;
}
.pink-amber .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .pink-amber .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .pink-amber .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .pink-amber .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.pink-amber .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .pink-amber .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .pink-amber .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .pink-amber .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.pink-amber .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.pink-amber .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.pink-amber .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.pink-amber .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .pink-amber .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.pink-amber .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.pink-amber .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.pink-amber .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.pink-amber .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.pink-amber .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .pink-amber .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.pink-amber .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.pink-amber .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.pink-amber .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.pink-amber .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .pink-amber .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.pink-amber .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.pink-amber .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.pink-amber .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber .mat-toolbar.mat-primary {
  background: #e91e63;
  color: white;
}
.pink-amber .mat-toolbar.mat-accent {
  background: #ffc107;
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber .mat-toolbar.mat-warn {
  background: #f44336;
  color: white;
}
.pink-amber .mat-toolbar .mat-form-field-underline,
.pink-amber .mat-toolbar .mat-form-field-ripple,
.pink-amber .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.pink-amber .mat-toolbar .mat-form-field-label,
.pink-amber .mat-toolbar .mat-focused .mat-form-field-label,
.pink-amber .mat-toolbar .mat-select-value,
.pink-amber .mat-toolbar .mat-select-arrow,
.pink-amber .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.pink-amber .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.pink-amber .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.pink-amber .mat-tree {
  background: white;
}
.pink-amber .mat-tree-node,
.pink-amber .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.pink-amber .mat-simple-snackbar-action {
  color: #ffc107;
}
.pink-amber *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.pink-amber *::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}
.pink-amber *::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}
.pink-amber .toolbar-line.container-ou {
  background: #e91e63;
  color: white;
}
.pink-amber .toolbar-line.container-persona {
  background: #e91e63 !important;
}
.pink-amber .toolbar-line.container-persona .persona-title {
  background: #e91e63;
}
.pink-amber .toolbar-line.container-persona .invisible-container.persona-selector {
  background: #e91e63 !important;
}
.pink-amber button.mat-icon-button:not([disabled]):hover {
  background: rgba(0, 0, 0, 0.04);
}
.pink-amber .side-bar {
  background: #eeeeee;
}
.pink-amber .icon-stacked {
  color: white !important;
}
.pink-amber .icon-stacked mat-icon + mat-icon {
  text-shadow: -1px 1px 0px #e91e63, 1px -1px 0px #e91e63;
}
.pink-amber .divider-vert {
  background: rgba(0, 0, 0, 0.12);
}
.pink-amber .avatar {
  background: #c2185b !important;
}
.pink-amber ngx-avatar .avatar-content {
  background: #c2185b !important;
}
.pink-amber .mat-loading-window {
  background: white;
}
.pink-amber .window-spinner {
  background: white;
}
.pink-amber .load-window {
  background: white;
}
@media (min-width: 600px) and (max-width: 1300px) {
  .pink-amber mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .load-window,
.pink-amber mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .load-window.new {
    background: white !important;
  }
  .pink-amber mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card {
    background: white !important;
  }
  .pink-amber mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .inbox-container mat-toolbar {
    background: #e91e63;
    color: white;
  }
  .pink-amber mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .inbox-container mat-list-item.mat-list-item {
    border: 1px solid rgba(0, 0, 0, 0.12);
  }
  .pink-amber mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .detail {
    background: white !important;
  }
  .pink-amber mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .detail mat-toolbar {
    background: white !important;
  }
  .pink-amber mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .main.w-detail + .new-detail-container .load-window {
    background: #fafafa !important;
  }
  .pink-amber mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .main.w-detail + .new-detail-container:before {
    background: rgba(0, 0, 0, 0.6);
  }
  .pink-amber mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .main.side-open + .side {
    border-left: 1px solid rgba(0, 0, 0, 0.12);
  }
  .pink-amber mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .side .container .mat-list-base {
    background: white !important;
    border: 1px solid rgba(0, 0, 0, 0.12);
  }
  .pink-amber mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .new-table .mat-header-row,
.pink-amber mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .new-table .mat-table,
.pink-amber mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .document-table .mat-header-row,
.pink-amber mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .document-table .mat-table,
.pink-amber mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .status-table .mat-header-row,
.pink-amber mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .status-table .mat-table {
    background: white !important;
  }
  .pink-amber mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .audit-filter .filter-container.is-floating {
    background: #fafafa !important;
    border: 1px solid rgba(0, 0, 0, 0.12);
  }
  .pink-amber mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .audit-filter .filter-container.is-floating .form-body {
    background: #fafafa !important;
  }
  .pink-amber mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .audit-result {
    background: white;
  }
  .pink-amber mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .audit-detail-container {
    background: #fafafa !important;
  }
}
@media (max-width: 599px) {
  .pink-amber app-document-detail .mat-toolbar {
    background: #c2185b !important;
    color: white !important;
  }
  .pink-amber app-document-detail .mat-toolbar .mat-toolbar-row .toolbar-actions {
    background: #c2185b !important;
    color: white !important;
  }
  .pink-amber .login-card {
    background: white !important;
  }
  .pink-amber .inbox-toolbar {
    background: #c2185b !important;
    color: white !important;
  }
  .pink-amber .detail {
    background: white url("../img/spinner_mobile.gif") no-repeat center center !important;
  }
  .pink-amber .toolbar-line-container .toolbar-line:first-child {
    background: #c2185b !important;
    color: white !important;
  }
  .pink-amber .toolbar-actions {
    background: #c2185b !important;
    color: white !important;
  }
  .pink-amber mat-drawer.side-nav.enter {
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12) !important;
  }
  .pink-amber .side-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0) !important;
  }
  .pink-amber .filter {
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.55) 16%, rgba(0, 0, 0, 0) 100%) !important;
  }
  .pink-amber button.side-open-mobile {
    color: white !important;
  }
  .pink-amber .side-nav .side-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0);
  }
  .pink-amber .side-nav .filter {
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.55) 16%, rgba(0, 0, 0, 0) 100%);
  }
  .pink-amber .side-nav .side-nav-container .links {
    background: white;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
  }
  .pink-amber .side-nav .side-nav-container .version {
    background: white !important;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
  }
  .pink-amber app-file-document-view .view-container {
    background: white !important;
  }
  .pink-amber app-file-document-view .view-container .side-bar {
    background: #c2185b !important;
    color: white !important;
  }
  .pink-amber app-file-document-view .view-container .view-content app-pdf-wrapper.floating {
    background: white;
  }
  .pink-amber app-file-document-view .view-container .view-content .upload-indications {
    background: #eeeeee;
  }
  .pink-amber app-file-document-view .view-container .view-content app-file-document-sign .sign-cycle mat-horizontal-stepper.mat-stepper-horizontal {
    background-color: rgba(0, 0, 0, 0) !important;
  }
  .pink-amber app-file-document-view .view-container .view-content app-file-document-sign .sign-cycle .sign-cycle-content .cycle-content-action .mat-ripple-element {
    background-color: rgba(255, 255, 255, 0.1) !important;
  }
  .pink-amber app-pending .floating-container .inbox-container .inbox-toolbar .mat-toolbar-row .mat-avatar h3 {
    color: white !important;
  }
  .pink-amber app-pending .floating-container .detail {
    background: white url("../img/spinner_mobile.gif") no-repeat center center !important;
  }
  .pink-amber app-document-sign-massive .inbox-container .pending-container app-chapa.minimized .info-empty {
    background: #ffc107;
  }
  .pink-amber app-document-sign-massive .inbox-container .pending-container app-chapa.minimized .info-empty > span h2 {
    color: white !important;
  }
  .pink-amber app-document-sign-massive .inbox-container .signer.mobile {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    background: white;
  }
  .pink-amber app-document-sign-massive .detail app-file-document-detail .view-container .mat-toolbar .mat-toolbar-row .view-actions {
    color: white !important;
  }
  .pink-amber app-add-documentation .floating-container .employee-add-documentation .inbox-container .inbox-toolbar .mat-toolbar-row .mat-avatar h3 {
    color: white !important;
  }
  .pink-amber app-add-documentation .floating-container .employee-add-documentation .detail {
    background: white url("../img/spinner_mobile.gif") no-repeat center center !important;
  }
  .pink-amber app-add-documentation .floating-container .employee-add-documentation .detail app-file-document-view .view-container .view-content .new-container app-file-document-upload {
    background: white;
  }
}
.pink-amber .mat-card .mat-drawer-container {
  background: white !important;
}
.pink-amber .mat-drawer-container {
  background: #e0e0e0 !important;
}
.pink-amber .mat-list .mat-list-item.finished-process-inbox.active {
  background: #4DB6AC !important;
}
.pink-amber .mat-list .mat-list-item.finished-process-inbox .mat-icon {
  background: #4DB6AC;
}
.pink-amber .mat-list .mat-list-item.erased-process-inbox.active {
  background: #837C80 !important;
}
.pink-amber .mat-list .mat-list-item.limbo-process-inbox.active {
  background: #FFD54F !important;
}
.pink-amber .mat-list .mat-list-item.limbo-process-inbox .mat-icon {
  background: #FFD54F;
}
.pink-amber .mat-list .mat-list-item.in-process-inbox.active {
  background: #FFD54F !important;
}
.pink-amber .mat-list .mat-list-item.in-process-inbox .mat-icon {
  background: #FFD54F;
}
.pink-amber .mat-list .mat-list-item.error-process-inbox.active {
  background: #F48FB1 !important;
}
.pink-amber .mat-list .mat-list-item.error-process-inbox .mat-icon {
  background: #F48FB1;
}
.pink-amber .mat-list .mat-list-item.active .mat-line b {
  color: #e91e63;
}
.pink-amber .mat-list .mat-list-item.active .mat-list-avatar {
  color: #e91e63;
}
.pink-amber .mat-list .mat-list-item:hover:not(.active) {
  background: rgba(0, 0, 0, 0.04);
}
.pink-amber span.mat-badge-content {
  color: #f44336 !important;
}
.pink-amber mat-expansion-panel.mat-expanded {
  background: #fafafa !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber mat-expansion-panel.virtual-scrolling .mat-expansion-panel-content .get-more {
  color: #e91e63;
}
.pink-amber mat-expansion-panel.virtual-scrolling .mat-expansion-panel-content app-cs-paginator.get-more {
  background: white;
}
.pink-amber mat-expansion-panel-header.mat-expanded {
  background: white !important;
}
.pink-amber .bottom-container {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber .load-more * {
  color: #e91e63;
}
.pink-amber .welcome-card {
  background: white !important;
}
.pink-amber .welcome-card .img-divider {
  background: #c2185b;
}
.pink-amber .mat-header-row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background: white;
}
.pink-amber .mat-row {
  background: white;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.pink-amber .mat-row .mat-cell.status-cell mat-icon.pending {
  color: #FF9800 !important;
}
.pink-amber .mat-row .mat-cell.status-cell mat-icon.ok {
  color: #009688 !important;
}
.pink-amber .mat-row .mat-cell.status-cell mat-icon.not-ok {
  color: #9C27B0 !important;
}
.pink-amber .mat-row .mat-cell.status-cell mat-icon.cancelled {
  color: #795548 !important;
}
.pink-amber .mat-row .mat-cell.status-cell mat-icon.rejected {
  color: #df4d4c !important;
}
.pink-amber .mat-row .mat-cell.status-cell mat-icon.draft {
  color: #dedede !important;
}
.pink-amber .mat-row.active, .pink-amber .mat-row.active:hover {
  background: #e91e63 !important;
}
.pink-amber .mat-row.active div, .pink-amber .mat-row.active:hover div {
  color: white;
}
.pink-amber .mat-row.active .mat-cell.status-cell mat-icon.pending, .pink-amber .mat-row.active .mat-cell.status-cell mat-icon.ok, .pink-amber .mat-row.active .mat-cell.status-cell mat-icon.not-ok, .pink-amber .mat-row.active:hover .mat-cell.status-cell mat-icon.pending, .pink-amber .mat-row.active:hover .mat-cell.status-cell mat-icon.ok, .pink-amber .mat-row.active:hover .mat-cell.status-cell mat-icon.not-ok {
  text-shadow: -1px 1px 0 white, 1px 1px 0 white, 1px -1px 0 white, -1px -1px 0 white;
}
.pink-amber .mat-row.active .sticky-actions button, .pink-amber .mat-row.active:hover .sticky-actions button {
  background: #e91e63 !important;
}
.pink-amber .mat-row.active .sticky-actions button.ok mat-icon, .pink-amber .mat-row.active .sticky-actions button.not-ok mat-icon, .pink-amber .mat-row.active:hover .sticky-actions button.ok mat-icon, .pink-amber .mat-row.active:hover .sticky-actions button.not-ok mat-icon {
  text-shadow: -1px 1px 0 white, 1px 1px 0 white, 1px -1px 0 white, -1px -1px 0 white;
}
.pink-amber .mat-row:nth-child(odd) {
  background: #fafafa;
}
.pink-amber .mat-row.selected div {
  color: white;
}
.pink-amber .mat-row.selected:nth-child(even) {
  background: #f48fb1 !important;
}
.pink-amber .mat-row.selected:nth-child(even) .sticky-actions button {
  background: #f48fb1 !important;
}
.pink-amber .mat-row.selected:nth-child(odd) {
  background: #f8bbd0 !important;
}
.pink-amber .mat-row.selected:nth-child(odd) .sticky-actions button {
  background: #f8bbd0 !important;
}
.pink-amber .mat-row.selected.active, .pink-amber .mat-row.selected.active:hover {
  background: #e91e63 !important;
}
.pink-amber .mat-row.selected.active .sticky-actions button, .pink-amber .mat-row.selected.active:hover .sticky-actions button {
  background: #e91e63 !important;
}
.pink-amber .mat-row:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-amber .mat-row:hover .sticky-actions button {
  background: white !important;
}
.pink-amber .mat-row.canceled * {
  color: #795548;
}
.pink-amber .mat-row.disabled div {
  color: #607D8B;
}
.pink-amber .mat-row.disabled.active, .pink-amber .mat-row.disabled.active:hover {
  background: #607D8B !important;
}
.pink-amber .mat-row.disabled.active div, .pink-amber .mat-row.disabled.active:hover div {
  color: white;
}
.pink-amber .mat-row.disabled.active .sticky-actions button, .pink-amber .mat-row.disabled.active:hover .sticky-actions button {
  background: #607D8B !important;
}
.pink-amber .mat-row.disabled.selected.active:hover .sticky-actions button {
  background: #607D8B !important;
}
.pink-amber .mat-row .sticky-actions button.ok mat-icon {
  color: #009688 !important;
}
.pink-amber .mat-row .sticky-actions button.not-ok mat-icon {
  color: #9C27B0 !important;
}
.pink-amber .status-table .mat-header-row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background: white;
}
.pink-amber .icon-info-off,
.pink-amber .certificate-off {
  color: #607D8B;
}
.pink-amber .documents-search-table .mat-header-row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background: white;
}
.pink-amber mat-toolbar.employee-search-sub-toolbar {
  background: white;
}
.pink-amber .mat-search-field {
  border: 1px solid rgba(0, 0, 0, 0.12);
  background: #eeeeee;
}
.pink-amber .mat-search-field input[type=search] {
  background: rgba(0, 0, 0, 0);
}
.pink-amber .mat-search-field input[type=search]::placeholder {
  color: rgba(0, 0, 0, 0.38);
}
.pink-amber .search-adv-menu .mat-search-field {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber mat-toolbar.document-search-sub-toolbar {
  background: white;
}
.pink-amber app-advanced-employee-search .mat-chip {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-amber .side-nav .side-trigger {
  background: white;
}
.pink-amber .side-nav .menu-trigger mat-icon {
  color: rgba(0, 0, 0, 0.54);
  background: white;
}
.pink-amber .side-nav .side-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0);
}
.pink-amber .side-nav .filter {
  background: white;
}
.pink-amber .side-nav .side-nav-container .links mat-icon span.mat-badge-content {
  color: white !important;
}
.pink-amber .side-nav .side-nav-container .mat-list-base .mat-list-item:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-amber .side-nav .side-nav-container .mat-list-base .mat-list-item.active {
  background: #e91e63 !important;
}
.pink-amber .side-nav .side-nav-container .mat-list-base .mat-list-item.active * {
  color: white;
}
.pink-amber .side-nav .side-nav-container .mat-list-base .mat-list-item.active .mat-list-item.active:hover {
  background: #e91e63 !important;
}
.pink-amber .side-nav .side-nav-container .mat-list-base .mat-list-item.active .mat-list-item.active:hover * {
  color: white;
}
.pink-amber .side-nav.open .side-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber .side-nav.open .filter {
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.55) 16%, rgba(0, 0, 0, 0) 100%);
}
.pink-amber .side-nav.open .side-nav-container .links {
  background: white;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber .side-nav.open .side-nav-container .version {
  background: white !important;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber .side-nav.enter .side-nav-container .links {
  background: white;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber .side-nav.enter .side-nav-container .version {
  background: white !important;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber .mat-menu-panel:not(.metadata-radio-menu) .navigation-list .mat-list-item-content {
  color: rgba(0, 0, 0, 0.54);
}
.pink-amber table.mat-calendar-table {
  background: white !important;
}
.pink-amber .mat-calendar-body-label {
  color: white !important;
}
.pink-amber .mat-calendar-arrow {
  border-top-color: white !important;
}
.pink-amber .mat-datepicker-content .mat-calendar-next-button,
.pink-amber .mat-datepicker-content .mat-calendar-previous-button {
  color: white !important;
}
.pink-amber .mat-calendar-header {
  background: #c2185b !important;
}
.pink-amber mat-datepicker-content {
  background-color: #c2185b !important;
  color: white !important;
}
.pink-amber .mat-calendar-content {
  background: white !important;
}
.pink-amber .mat-simple-snackbar-action button.mat-button {
  color: #FFE082;
}
.pink-amber .mat-simple-snackbar-action .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.pink-amber mat-bottom-sheet-container mat-toolbar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}
.pink-amber app-generic-bottom-sheet h4.accent {
  color: #ffc107;
}
.pink-amber app-generic-bottom-sheet .verification-code {
  background: #e91e63;
  color: white;
}
.pink-amber app-generic-bottom-sheet .verification-code h2 {
  background: #e91e63;
}
.pink-amber app-generic-bottom-sheet .load-window.message .info-container h3.primary-text {
  color: #e91e63;
}
.pink-amber app-generic-bottom-sheet .bottom_sheet-content.ok .bottom_sheet-title .bottom_sheet-icon,
.pink-amber app-generic-bottom-sheet .bottom_sheet-content.ok .bottom_sheet-title h3 {
  color: #009688;
}
.pink-amber app-generic-bottom-sheet .bottom_sheet-content.ok .bottom_sheet-main_btn:not(:disabled) {
  background: #009688;
  color: #fff;
}
.pink-amber app-generic-bottom-sheet .bottom_sheet-content.not-ok .bottom_sheet-title .bottom_sheet-icon,
.pink-amber app-generic-bottom-sheet .bottom_sheet-content.not-ok .bottom_sheet-title h3 {
  color: #9C27B0;
}
.pink-amber app-generic-bottom-sheet .bottom_sheet-content.not-ok .bottom_sheet-main_btn:not(:disabled) {
  background: #9C27B0;
  color: #fff;
}
.pink-amber app-generic-bottom-sheet .bottom_sheet-content.cancel .bottom_sheet-title .bottom_sheet-icon,
.pink-amber app-generic-bottom-sheet .bottom_sheet-content.cancel .bottom_sheet-title h3 {
  color: #795548;
}
.pink-amber app-generic-bottom-sheet .bottom_sheet-content.cancel .bottom_sheet-main_btn:not(:disabled) {
  background: #795548;
  color: #fff;
}
.pink-amber app-generic-bottom-sheet .bottom_sheet-content .bottom_sheet_exp_btn {
  background-color: #e91e63;
  color: #fff;
}
.pink-amber app-generic-bottom-sheet .bottom_sheet-content .bottom_sheet_canc_btn {
  background-color: lightgrey;
  color: black;
}
.pink-amber app-generic-bottom-sheet .bottom_sheet-content .bottom_sheet-exp-h3 {
  color: #e91e63;
  margin-bottom: -10px !important;
}
.pink-amber app-generic-bottom-sheet .bottom_sheet-content .mat-input-element-export {
  display: flex !important;
  padding-left: 15px;
}
.pink-amber app-generic-bottom-sheet .bottom_sheet-content .mat-form-field-infix-export-right {
  width: 100% !important;
  padding: 0px !important;
  display: flex !important;
}
.pink-amber app-generic-bottom-sheet .bottom_sheet-content .mat-form-field-infix-export-left {
  width: 100% !important;
  padding: 0px;
  display: flex !important;
}
.pink-amber app-generic-bottom-sheet .bottom_sheet-content .mat-form-field-infix {
  display: flex;
}
.pink-amber app-generic-bottom-sheet .bottom_sheet-content .mat-checkbox-layout .mat-checkbox-label {
  color: #ffc107;
}
.pink-amber app-generic-bottom-sheet .bottom_sheet-content .choose-forms {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  margin-left: 10px !important;
}
.pink-amber app-generic-bottom-sheet .bottom_sheet-content .card-container {
  max-height: 150px;
  overflow-y: auto;
}
.pink-amber .mat-list .mat-list-item.active .mat-line {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber .mat-list .mat-list-item.active .mat-line b {
  color: #e91e63;
}
.pink-amber .mat-list .mat-list-item.active .mat-list-avatar {
  color: #e91e63;
}
.pink-amber .mat-list .mat-list-item .mat-line {
  color: rgba(0, 0, 0, 0.54);
}
.pink-amber .mat-list .mat-list-item .mat-list-avatar {
  color: rgba(0, 0, 0, 0.54);
}
.pink-amber .mat-list-item:hover:not(.active) {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-amber mat-list-item:nth-child(even),
.pink-amber app-file-document-inbox-item:nth-child(even) mat-list-item,
.pink-amber app-file-document-inbox-item-documentation:nth-child(even) mat-list-item {
  background: #fafafa !important;
}
.pink-amber mat-list-item:nth-child(odd),
.pink-amber app-file-document-inbox-item:nth-child(odd) mat-list-item,
.pink-amber app-file-document-inbox-item-documentation:nth-child(odd) mat-list-item {
  background: white !important;
}
.pink-amber .mat-list-avatar.mat-icon {
  background: #eeeeee;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber .card-filter {
  background: white;
}
.pink-amber .login mat-horizontal-stepper {
  background: white !important;
}
.pink-amber .login .login-card {
  background: white !important;
}
.pink-amber .login .login-card .mat-card-content .mat-card-footer a[color=accent] {
  color: #ffc107;
}
.pink-amber .login .help-stepper h3.align-16 {
  color: #ffc107;
}
.pink-amber .login .help-stepper .help-options mat-icon {
  color: #ffc107;
}
.pink-amber .ux-modal {
  background: rgba(0, 0, 0, 0.32);
}
.pink-amber .ux-modal .card {
  background: white;
}
.pink-amber .main-title {
  background: #607D8B;
  color: white;
}
.pink-amber .sub-title {
  color: rgba(0, 0, 0, 0.54);
}
.pink-amber .card-footer a {
  color: white;
}
.pink-amber .profile-toolbar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber app-profile .logout-btn:hover {
  background: #eeeeee;
}
.pink-amber app-profile .cropper {
  background: white;
}
.pink-amber app-profile .cropper .cropper {
  background: unset;
}
.pink-amber app-add-documentation .employee-add-documentation .inbox-toolbar {
  background: #fafafa;
}
.pink-amber app-add-documentation .employee-add-documentation .inbox-toolbar .toolbar-line mat-icon {
  color: #e91e63;
}
.pink-amber app-add-documentation .employee-add-documentation .inbox-toolbar .toolbar-line h3 {
  color: #e91e63;
}
.pink-amber app-add-documentation .employee-add-documentation mat-tab-group {
  background: #fafafa;
}
.pink-amber app-add-documentation .employee-add-documentation mat-tab-group mat-tab-header .mat-tab-label-active {
  color: #e91e63;
}
.pink-amber app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content:hover {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12) !important;
}
.pink-amber app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content.selected {
  border-left: 6px solid #e91e63 !important;
}
.pink-amber app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content.selected mat-icon {
  color: #e91e63 !important;
}
.pink-amber app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content.selected .item-data {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-amber app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content mat-icon {
  color: rgba(0, 0, 0, 0.54);
}
.pink-amber app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content .item-data {
  color: rgba(0, 0, 0, 0.54);
}
.pink-amber app-add-documentation .employee-add-documentation .detail app-file-document-view .new-container app-file-document-upload .metadata-list .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
  background: white !important;
}
.pink-amber app-pending .inbox-toolbar {
  background: #fafafa;
}
.pink-amber app-pending .pending-container {
  background: #fafafa;
}
.pink-amber app-pending .pending-container .pending-items .pending-item .content:hover {
  background: rgba(0, 0, 0, 0.04) !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12) !important;
}
.pink-amber app-pending .pending-container .pending-items .pending-item .content.selected {
  border-left: 6px solid #ffc107 !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12) !important;
}
.pink-amber app-pending .pending-container .pending-items .pending-item .content.selected mat-icon {
  color: #ffc107 !important;
}
.pink-amber app-pending .pending-container .pending-items .pending-item .content.selected .item-data {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-amber app-pending .pending-container .pending-items .pending-item .content mat-icon {
  color: rgba(0, 0, 0, 0.54);
}
.pink-amber app-pending .pending-container .pending-items .pending-item .content .item-data {
  color: rgba(0, 0, 0, 0.54);
}
.pink-amber app-document-sign-massive .inbox-container .inbox-toolbar {
  background: #fafafa;
}
.pink-amber app-document-sign-massive .inbox-container .pending-container {
  background: #fafafa;
}
.pink-amber app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content:hover {
  background: rgba(0, 0, 0, 0.04) !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12) !important;
}
.pink-amber app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content.selected {
  border-left: 6px solid #ffc107 !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12) !important;
}
.pink-amber app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content.selected mat-icon {
  color: #ffc107 !important;
}
.pink-amber app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content.selected .item-data {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-amber app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content mat-icon {
  color: rgba(0, 0, 0, 0.54);
}
.pink-amber app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content .item-data {
  color: rgba(0, 0, 0, 0.54);
}
.pink-amber app-document-sign-massive .detail app-file-document-detail .view-container {
  background: #607D8B;
}
.pink-amber app-document-sign-massive .detail app-file-document-detail .view-container .mat-toolbar {
  background: #607D8B;
  color: white;
  background: #607D8B;
}
.pink-amber app-document-sign-massive .detail app-chapa.minimized .info-empty {
  background: #ffc107;
}
.pink-amber app-document-sign-massive .detail app-chapa.minimized .info-empty > span mat-icon {
  color: white !important;
}
.pink-amber app-document-sign-massive .detail app-chapa.minimized .info-empty > span h2 {
  color: white !important;
}
.pink-amber app-document-sign-massive .signer.desktop {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber app-document-sign-massive .signer .signer-btn {
  color: white !important;
}
.pink-amber app-document-sign-massive .signer .signer-btn[disabled] {
  background-color: rgba(0, 0, 0, 0.12);
}
.pink-amber app-document-detail app-sign-document .firmador button.mat-raised-button.mat-accent {
  color: white !important;
}
.pink-amber mat-toolbar-row.firmado-conforme .mat-icon {
  background: #8BC34A;
  color: white;
}
.pink-amber mat-toolbar-row.firmado-no-conforme .mat-icon {
  background: #f44336;
  color: white;
}
.pink-amber .secondary-toolbar {
  background: white;
}
.pink-amber .new-detail-container {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber .new-detail-container.cancelled .detail_head-body {
  background: #607D8B !important;
}
.pink-amber .new-detail-container .employee-detail-toolbar {
  background: #eeeeee;
}
.pink-amber .new-detail-container .employee-detail-subbar {
  background: white;
}
.pink-amber app-employee-detail .load-window,
.pink-amber app-employee-rrhh-detail .load-window {
  background: #fafafa;
}
.pink-amber app-employee-detail .employee_detail-container,
.pink-amber app-employee-rrhh-detail .employee_detail-container {
  background: #fafafa;
}
.pink-amber app-employee-detail .employee_detail-container.is-editing .edition-line,
.pink-amber app-employee-rrhh-detail .employee_detail-container.is-editing .edition-line {
  background: #eeeeee;
}
.pink-amber app-employee-detail .employee_detail-container .detail_head-body,
.pink-amber app-employee-rrhh-detail .employee_detail-container .detail_head-body {
  background: #e91e63;
}
.pink-amber app-employee-detail .employee_detail-container .detail_head-body .head_body-status .icon-stacked + mat-icon,
.pink-amber app-employee-rrhh-detail .employee_detail-container .detail_head-body .head_body-status .icon-stacked + mat-icon {
  color: white !important;
}
.pink-amber app-employee-detail .employee_detail-container .detail_head-body .head_body-title .body_title-name,
.pink-amber app-employee-rrhh-detail .employee_detail-container .detail_head-body .head_body-title .body_title-name {
  color: white;
}
.pink-amber app-employee-detail .employee_detail-container .detail_head-body .head_body-title .body_title-user,
.pink-amber app-employee-rrhh-detail .employee_detail-container .detail_head-body .head_body-title .body_title-user {
  color: white;
}
.pink-amber app-employee-detail .employee_detail-container .data .data-line,
.pink-amber app-employee-rrhh-detail .employee_detail-container .data .data-line {
  background: white;
}
.pink-amber app-employee-detail .employee_detail-container .data .data-line .icon-complex mat-icon.complex,
.pink-amber app-employee-rrhh-detail .employee_detail-container .data .data-line .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px white, 0 -2px 0px white, 2px 0 0px white, 0 2px 0px white, -2px -2px 0px white, 2px 2px 0px white, 2px -2px 0px white, -2px 2px 0px white;
}
.pink-amber app-employee-detail .employee_detail-container .data .data-line .line_text-main,
.pink-amber app-employee-rrhh-detail .employee_detail-container .data .data-line .line_text-main {
  color: #e91e63;
}
.pink-amber app-employee-detail .employee_detail-container .data .data-line.clickable:hover,
.pink-amber app-employee-rrhh-detail .employee_detail-container .data .data-line.clickable:hover {
  background: #fafafa !important;
}
.pink-amber app-employee-detail .employee_detail-container .data .data-line button.mat-icon-button,
.pink-amber app-employee-rrhh-detail .employee_detail-container .data .data-line button.mat-icon-button {
  background: #eeeeee;
}
.pink-amber app-employee-detail .employee_detail-container .employee-title .title-text,
.pink-amber app-employee-rrhh-detail .employee_detail-container .employee-title .title-text {
  color: #e91e63;
}
.pink-amber app-employee-detail .employee_detail-container .employee-title .title-text mat-icon,
.pink-amber app-employee-rrhh-detail .employee_detail-container .employee-title .title-text mat-icon {
  color: #e91e63;
}
.pink-amber app-employee-detail .employee_detail-container .employee-title .title-text .title_text-main,
.pink-amber app-employee-rrhh-detail .employee_detail-container .employee-title .title-text .title_text-main {
  color: #e91e63;
}
.pink-amber .UI-V2 .icon-complex mat-icon.complex {
  color: #e91e63;
  text-shadow: -2px 0 0px white, 0 -2px 0px white, 2px 0 0px white, 0 2px 0px white, -2px -2px 0px white, 2px 2px 0px white, 2px -2px 0px white, -2px 2px 0px white;
}
.pink-amber .UI-V2 .icon-complex mat-icon.complex.mat-accent {
  color: #ffc107;
}
.pink-amber .UI-V2 .main .toolbar .header .content .module-title {
  color: #e91e63;
}
.pink-amber .UI-V2 .main .toolbar .header .content .module-title.selectable:hover {
  background: rgba(0, 0, 0, 0.04);
}
.pink-amber .UI-V2 .main .toolbar .header .content .module-title .info h5 {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-amber .UI-V2 .main .toolbar .header .content .ou-select {
  color: white;
  background: #e91e63;
}
.pink-amber .UI-V2 .main .toolbar .search-container .search .content {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber .UI-V2 .main .toolbar .search-container .search .content:hover, .pink-amber .UI-V2 .main .toolbar .search-container .search .content:active, .pink-amber .UI-V2 .main .toolbar .search-container .search .content:focus, .pink-amber .UI-V2 .main .toolbar .search-container .search .content:focus-within {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-amber .UI-V2 .main .toolbar .search-container .search .content input {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber .UI-V2 .main .helper {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber .UI-V2 .main .helper mat-hint h4 {
  color: #e91e63;
}
.pink-amber .UI-V2 .main .new-table app-cs-grid-control datatable-header * {
  color: #e91e63;
}
.pink-amber .UI-V2 .main .new-table .mat-header-row.sticky * {
  color: #e91e63 !important;
}
.pink-amber .UI-V2 .main .main-action {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber .menu-options.help .primary {
  color: #e91e63;
}
.pink-amber .menu-options.help .accent {
  color: #ffc107;
}
.pink-amber .menu-options .icon-complex mat-icon.complex {
  color: #e91e63;
  text-shadow: -2px 0 0px white, 0 -2px 0px white, 2px 0 0px white, 0 2px 0px white, -2px -2px 0px white, 2px 2px 0px white, 2px -2px 0px white, -2px 2px 0px white;
}
.pink-amber .menu-options .mat-menu-content .container mat-list .mat-list-item:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-amber .menu-options .mat-menu-content .container mat-list .mat-list-item .disabled {
  color: rgba(0, 0, 0, 0.54) !important;
}
.pink-amber .menu-options .mat-menu-content .container .slide-group mat-radio-group mat-radio-button:hover {
  background: rgba(0, 0, 0, 0.04);
}
.pink-amber .menu-options .mat-menu-content .container .slide-group mat-form-field .mat-form-field-flex {
  background: #fafafa;
}
.pink-amber .menu-options .mat-menu-content .container .slide-group mat-slide-toggle:hover {
  background: rgba(0, 0, 0, 0.04);
}
.pink-amber .mat-menu-panel.notifications-panel .mat-menu-content .notifications-body {
  background: #fafafa;
}
.pink-amber .mat-menu-panel.notifications-panel .mat-menu-content .notification .primary mat-icon {
  color: #e91e63;
}
.pink-amber .mat-menu-panel.notifications-panel .mat-menu-content .notification .primary h3 {
  color: #e91e63;
}
.pink-amber .mat-menu-panel.notifications-panel .mat-menu-content .notification.top {
  background: #fafafa;
}
.pink-amber .mat-menu-panel.notifications-panel .mat-menu-content .notification.top .header h3 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber .mat-menu-panel.notifications-panel .mat-menu-content .notification.bottom {
  background: #fafafa;
}
.pink-amber .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable:nth-child(even) {
  background: #fafafa;
}
.pink-amber .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable:nth-child(odd) {
  background: white;
}
.pink-amber .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable:hover {
  background: rgba(0, 0, 0, 0.04);
}
.pink-amber .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable mat-icon {
  color: #e91e63;
}
.pink-amber .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable h3 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable h5 {
  color: rgba(0, 0, 0, 0.38);
}
.pink-amber .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable .subtitle h5 {
  color: #e91e63;
  background: #eeeeee;
}
.pink-amber .info-empty .large-icon {
  background: #f8bbd0;
  color: #e91e63;
}
.pink-amber .info-empty h2,
.pink-amber .info-empty h3 {
  color: #e91e63;
}
.pink-amber .info-empty h4,
.pink-amber .info-empty h5 {
  color: rgba(0, 0, 0, 0.54);
}
.pink-amber .info-empty.accent mat-icon {
  background: #ffecb3;
  color: #ffc107;
}
.pink-amber .info-empty.accent h2 {
  color: #ffc107;
}
.pink-amber .info-empty .actions {
  padding: 16px;
  height: 96px;
  column-gap: 16px;
}
.pink-amber .info-empty .actions button {
  height: 100%;
  border-radius: 40px;
}
.pink-amber .info-empty .actions button.ok {
  background: #009688 !important;
  color: white;
}
.pink-amber .info-empty .actions button.not-ok {
  color: #9C27B0 !important;
}
.pink-amber .side.edition .info-empty .large-icon {
  background: #ffecb3;
  color: #ffc107;
}
.pink-amber .side.edition .info-empty h2 {
  color: #ffc107;
}
.pink-amber .side.edition .info-empty h4 {
  color: rgba(0, 0, 0, 0.54);
}
.pink-amber .UI-V2 .deferred-process-list .header h3 {
  color: #e91e63;
}
.pink-amber .UI-V2 .deferred-process-list .search-container .search .content {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber .UI-V2 .deferred-process-list .search-container .search .content:hover, .pink-amber .UI-V2 .deferred-process-list .search-container .search .content:active, .pink-amber .UI-V2 .deferred-process-list .search-container .search .content:focus, .pink-amber .UI-V2 .deferred-process-list .search-container .search .content:focus-within {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-amber .UI-V2 .deferred-process-list .search-container .search .content input {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber .UI-V2 .deferred-process-list .text-success,
.pink-amber .UI-V2 .deferred-process-details .text-success {
  color: #8BC34A;
}
.pink-amber .UI-V2 .deferred-process-list .text-warn,
.pink-amber .UI-V2 .deferred-process-details .text-warn {
  color: #f44336;
}
.pink-amber .UI-V2 .deferred-process-list .text-primary,
.pink-amber .UI-V2 .deferred-process-details .text-primary {
  color: #e91e63;
}
.pink-amber .UI-V2 .deferred-process-list .text-atention,
.pink-amber .UI-V2 .deferred-process-details .text-atention {
  color: #DB7703;
}
.pink-amber .UI-V2 .deferred-process-list .table-paginator-container,
.pink-amber .UI-V2 .deferred-process-details .table-paginator-container {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
}
.pink-amber .UI-V2 .deferred-process-list .table-paginator-container .mat-table,
.pink-amber .UI-V2 .deferred-process-details .table-paginator-container .mat-table {
  border-bottom: none;
  border-radius: 10px 10px 0 0;
}
.pink-amber .UI-V2 .deferred-process-list .table-paginator-container .paginator-container,
.pink-amber .UI-V2 .deferred-process-details .table-paginator-container .paginator-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: #f2f2f2;
  padding: 0 1.5rem;
  height: 3rem;
}
.pink-amber .UI-V2 .deferred-process-list .mat-table,
.pink-amber .UI-V2 .deferred-process-details .mat-table {
  border: 1px solid #f2f2f2;
  border-radius: 10px;
  overflow: hidden;
}
.pink-amber .UI-V2 .deferred-process-list .mat-table .mat-header-row .mat-header-cell,
.pink-amber .UI-V2 .deferred-process-details .mat-table .mat-header-row .mat-header-cell {
  font-size: 14px;
  font-weight: 700;
}
.pink-amber .UI-V2 .deferred-process-list .mat-table .mat-header-row,
.pink-amber .UI-V2 .deferred-process-details .mat-table .mat-header-row {
  background: #f2f2f2;
}
.pink-amber .UI-V2 .deferred-process-list .mat-table .mat-header-row .mat-header-cell,
.pink-amber .UI-V2 .deferred-process-details .mat-table .mat-header-row .mat-header-cell {
  color: #e91e63;
}
.pink-amber .UI-V2 .deferred-process-list .mat-table .mat-row,
.pink-amber .UI-V2 .deferred-process-details .mat-table .mat-row {
  background: white;
}
.pink-amber .UI-V2 .progress-bar-container {
  position: relative;
}
.pink-amber .UI-V2 .deferred-process-details .header h3 {
  color: #e91e63;
}
.pink-amber .UI-V2 .deferred-process-details .header .back-link:visited {
  color: #e91e63;
}
.pink-amber .process-card,
.pink-amber .process-card-detail,
.pink-amber .process-card-stages {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber .process-card mat-card-subtitle,
.pink-amber .process-card-detail mat-card-subtitle,
.pink-amber .process-card-stages mat-card-subtitle {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber .process-card .stages-container .stage.completed mat-icon,
.pink-amber .process-card-detail .stages-container .stage.completed mat-icon,
.pink-amber .process-card-stages .stages-container .stage.completed mat-icon {
  color: #499719;
}
.pink-amber .process-card .stages-container .stage.completed .icon-container,
.pink-amber .process-card-detail .stages-container .stage.completed .icon-container,
.pink-amber .process-card-stages .stages-container .stage.completed .icon-container {
  background-color: rgba(78, 160, 28, 0.1490196078);
}
.pink-amber .process-card .stages-container .stage.completed .line,
.pink-amber .process-card-detail .stages-container .stage.completed .line,
.pink-amber .process-card-stages .stages-container .stage.completed .line {
  background-color: #499719;
}
.pink-amber .process-card .stages-container .stage.on-going mat-icon,
.pink-amber .process-card-detail .stages-container .stage.on-going mat-icon,
.pink-amber .process-card-stages .stages-container .stage.on-going mat-icon {
  color: #e91e63;
}
.pink-amber .process-card .stages-container .stage.on-going .icon-container,
.pink-amber .process-card-detail .stages-container .stage.on-going .icon-container,
.pink-amber .process-card-stages .stages-container .stage.on-going .icon-container {
  background-color: rgba(66, 48, 159, 0.2);
}
.pink-amber .process-card .stages-container .stage.on-going .line,
.pink-amber .process-card-detail .stages-container .stage.on-going .line,
.pink-amber .process-card-stages .stages-container .stage.on-going .line {
  background-color: #949494;
}
.pink-amber .process-card .stages-container .stage.pending,
.pink-amber .process-card-detail .stages-container .stage.pending,
.pink-amber .process-card-stages .stages-container .stage.pending {
  color: #949494;
}
.pink-amber .process-card .stages-container .stage.pending mat-icon,
.pink-amber .process-card-detail .stages-container .stage.pending mat-icon,
.pink-amber .process-card-stages .stages-container .stage.pending mat-icon {
  color: #949494;
}
.pink-amber .process-card .stages-container .stage.pending .icon-container,
.pink-amber .process-card-detail .stages-container .stage.pending .icon-container,
.pink-amber .process-card-stages .stages-container .stage.pending .icon-container {
  background-color: rgba(174, 174, 174, 0.2);
}
.pink-amber .process-card .stages-container .stage.pending .line,
.pink-amber .process-card-detail .stages-container .stage.pending .line,
.pink-amber .process-card-stages .stages-container .stage.pending .line {
  background-color: #949494;
}
.pink-amber .process-card .stages-container .stage.has-error mat-icon,
.pink-amber .process-card-detail .stages-container .stage.has-error mat-icon,
.pink-amber .process-card-stages .stages-container .stage.has-error mat-icon {
  color: #f44336;
}
.pink-amber .process-card .stages-container .stage.has-error .icon-container,
.pink-amber .process-card-detail .stages-container .stage.has-error .icon-container,
.pink-amber .process-card-stages .stages-container .stage.has-error .icon-container {
  background-color: rgba(188, 4, 4, 0.2);
}
.pink-amber .process-card .stages-container .stage-description .button-link,
.pink-amber .process-card-detail .stages-container .stage-description .button-link,
.pink-amber .process-card-stages .stages-container .stage-description .button-link {
  color: #e91e63;
}
.pink-amber app-custom-search-input .custom-search-container {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
  transition: color 0.1s ease, background 0.15s ease;
}
.pink-amber app-custom-search-input .custom-search-container mat-icon.mat-icon {
  color: rgba(0, 0, 0, 0.38);
}
.pink-amber app-custom-search-input .custom-search-container input.custom-search-input {
  color: rgba(0, 0, 0, 0.87);
  background: unset;
}
.pink-amber app-custom-search-input .custom-search-container:hover, .pink-amber app-custom-search-input .custom-search-container:active, .pink-amber app-custom-search-input .custom-search-container:focus, .pink-amber app-custom-search-input .custom-search-container:focus-within {
  background: rgba(0, 0, 0, 0.04) !important;
  transition: background 0.15s ease;
}
.pink-amber .UI-V2 .audit-filter {
  background: white;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber .UI-V2 .audit-filter .toolbar {
  background: #e91e63;
  color: white;
}
.pink-amber .UI-V2 .audit-filter .toolbar .header .content .module-title {
  color: #e91e63;
}
.pink-amber .UI-V2 .audit-filter .toolbar .header .content .module-title.selectable:hover {
  background: rgba(0, 0, 0, 0.04);
}
.pink-amber .UI-V2 .audit-filter .toolbar .header .content .module-title .info * {
  color: white !important;
}
.pink-amber .UI-V2 .audit-filter .toolbar .header .content .ou-select {
  color: white;
  background: #e91e63;
}
.pink-amber .UI-V2 .audit-filter .toolbar .search-container .search .content {
  background: #fafafa;
}
.pink-amber .UI-V2 .audit-filter .toolbar .search-container .search .content:hover, .pink-amber .UI-V2 .audit-filter .toolbar .search-container .search .content:active, .pink-amber .UI-V2 .audit-filter .toolbar .search-container .search .content:focus, .pink-amber .UI-V2 .audit-filter .toolbar .search-container .search .content:focus-within {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-amber .UI-V2 .audit-filter .toolbar .search-container .search .content input {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber .UI-V2 .audit-filter .filter-container {
  background: white !important;
}
.pink-amber .UI-V2 .audit-filter .filter-container .form-body {
  background: #fafafa !important;
}
.pink-amber .UI-V2 .audit-filter .filter-container .form-body .line h4 {
  color: #e91e63;
}
.pink-amber .UI-V2 .audit-filter .filter-container .form-body .line mat-icon {
  color: #e91e63;
}
.pink-amber .UI-V2 .audit-result .result-container .toolbar {
  color: white;
}
.pink-amber .UI-V2 .audit-result .result-container .toolbar .header .content .module-title {
  color: #e91e63;
}
.pink-amber .UI-V2 .audit-result .result-container .toolbar .header .content .module-title.selectable:hover {
  background: rgba(0, 0, 0, 0.04);
}
.pink-amber .UI-V2 .audit-result .result-container .toolbar .header .content .module-title .info h5 {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-amber .UI-V2 .audit-result .result-container .toolbar .header .content .ou-select {
  color: white;
  background: #e91e63;
}
.pink-amber .UI-V2 .audit-result .result-container .toolbar .search-container .search .content {
  background: #fafafa;
}
.pink-amber .UI-V2 .audit-result .result-container .toolbar .search-container .search .content:hover, .pink-amber .UI-V2 .audit-result .result-container .toolbar .search-container .search .content:active, .pink-amber .UI-V2 .audit-result .result-container .toolbar .search-container .search .content:focus, .pink-amber .UI-V2 .audit-result .result-container .toolbar .search-container .search .content:focus-within {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-amber .UI-V2 .audit-result .result-container .toolbar .search-container .search .content input {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber .UI-V2 .audit-result .result-container .new-table app-cs-grid-control datatable-header * {
  color: #e91e63;
}
.pink-amber .UI-V2 .audit-result .result-container .new-table .mat-header-row.sticky * {
  color: #e91e63 !important;
}
.pink-amber .UI-V2 .audit-detail-container {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  background: #eeeeee !important;
}
.pink-amber .UI-V2 .audit-detail-container .side .toolbar .header {
  color: #e91e63;
}
.pink-amber .UI-V2 .audit-detail-container .side .container .mat-list-base {
  background: #fafafa !important;
}
.pink-amber .UI-V2 .audit-detail-container .side .container .mat-list-base mat-list-item .mat-line b {
  color: #e91e63;
}
.pink-amber .UI-V2 .inbox-container {
  background: white;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber .UI-V2 .inbox-container *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.pink-amber .UI-V2 .inbox-container *::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}
.pink-amber .UI-V2 .inbox-container *::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}
.pink-amber .UI-V2 .inbox-container .load-window {
  background: white;
}
.pink-amber .UI-V2 .inbox-container .toolbar {
  background: #e91e63;
  color: white;
}
.pink-amber .UI-V2 .inbox-container .toolbar.reverse {
  background: white;
  color: rgba(0, 0, 0, 0.54);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel {
  background: #eeeeee !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel.mat-expanded {
  background: #fafafa !important;
}
.pink-amber .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel.mat-expanded mat-expansion-panel-header {
  background: #fafafa !important;
}
.pink-amber .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel.mat-expanded mat-expansion-panel-header:hover {
  background: none !important;
}
.pink-amber .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel mat-expansion-panel-header {
  background: #fafafa !important;
}
.pink-amber .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel mat-expansion-panel-header:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-amber .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel mat-expansion-panel-header .signature-inbox-header .mat-icon,
.pink-amber .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel mat-expansion-panel-header .signature-inbox-header b {
  color: #ffc107;
}
.pink-amber .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel .mat-expansion-panel-body .item {
  background: white;
  color: rgba(0, 0, 0, 0.54);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel .mat-expansion-panel-body .item:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-amber .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel .mat-expansion-panel-body .item.active {
  border-left: 6px solid #ffc107;
}
.pink-amber .UI-V2 .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.pink-amber .UI-V2 .work-space .main .load-window {
  background: white !important;
}
.pink-amber .UI-V2 .work-space .main-action {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber mat-radio-button.disabled .mat-radio-label-content {
  color: #ffc107;
}
.pink-amber .detail app-automatic-process-detail app-chapa.minimize,
.pink-amber .detail app-process-detail app-chapa.minimize {
  background: #FFD54F !important;
}
.pink-amber .detail app-automatic-process-detail app-chapa.minimize.purged,
.pink-amber .detail app-process-detail app-chapa.minimize.purged {
  background: #b2adb0 !important;
}
.pink-amber .detail .detail-toolbar {
  background: #eeeeee;
}
.pink-amber .detail .processes-status .status-bar .bar-graph {
  background-color: #FFD54F;
}
.pink-amber .detail .processes-status .status-bar .bar-graph .graph-ok {
  background-color: #4DB6AC;
}
.pink-amber .detail .processes-status .status-bar .bar-graph .graph-not-ok {
  background-color: #F48FB1;
}
.pink-amber .detail .processes-status .status-resume b {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber .detail .processes-status .status-resume .resume-total b {
  background-color: #FFD54F;
}
.pink-amber .detail .processes-status .status-resume .resume-ok b {
  background-color: #4DB6AC;
}
.pink-amber .detail .processes-status .status-resume .resume-not-ok b {
  background-color: #F48FB1;
}
.pink-amber .detail .detail-toolbar .mat-avatar h3,
.pink-amber .detail .detail-toolbar .mat-avatar h5 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber .detail .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber .detail .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber .detail .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber .detail .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber .detail .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  color: white;
}
.pink-amber .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  background: #4DB6AC !important;
}
.pink-amber .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  background: #b2dfdb !important;
}
.pink-amber .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  background: #b2dfdb !important;
}
.pink-amber .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  background: #4DB6AC !important;
}
.pink-amber .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  background: #b2dfdb !important;
}
.pink-amber .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  background: #837C80 !important;
}
.pink-amber .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  background: #b2adb0 !important;
}
.pink-amber .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  background: #b2adb0 !important;
}
.pink-amber .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  background: #837C80 !important;
}
.pink-amber .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  background: #b2dfdb !important;
}
.pink-amber .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  background: #FFD54F !important;
}
.pink-amber .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  background: #ffecb3 !important;
}
.pink-amber .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  background: #ffecb3 !important;
}
.pink-amber .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  background: #FFD54F !important;
}
.pink-amber .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  background: #ffecb3 !important;
}
.pink-amber .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  background: #F48FB1 !important;
}
.pink-amber .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  background: #f8bbd0 !important;
}
.pink-amber .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  background: #f8bbd0 !important;
}
.pink-amber .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  background: #F48FB1 !important;
}
.pink-amber .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  background: #f8bbd0 !important;
}
.pink-amber app-analytics-document-set .sets-container .mat-list-base.sets-list mat-list-item {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber app-analytics-document-set .sets-container .mat-list-base.sets-list mat-list-item .mat-list-item-content .set-status .value-percentile .undone-value {
  background: #673AB7;
}
.pink-amber app-analytics-document-set .sets-container .mat-list-base.sets-list mat-list-item .mat-list-item-content .set-status .value-percentile .not-value {
  background: #eeeeee;
}
.pink-amber app-analytics-document-set .sets-container .mat-list-base.sets-list mat-list-item .mat-list-item-content .set-status .value-percentile .done-value {
  background: #009688;
}
.pink-amber app-analytics-document-set .work-space .main .toolbar .header .content .module-title .info .info-text .set-status {
  cursor: help;
}
.pink-amber app-analytics-document-set .work-space .main .toolbar .header .content .module-title .info .info-text .set-status .value-percentile .undone-value {
  background: #673AB7;
}
.pink-amber app-analytics-document-set .work-space .main .toolbar .header .content .module-title .info .info-text .set-status .value-percentile .not-value {
  background: #eeeeee;
}
.pink-amber app-analytics-document-set .work-space .main .toolbar .header .content .module-title .info .info-text .set-status .value-percentile .done-value {
  background: #009688;
}
.pink-amber app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar .mat-progress-bar-buffer {
  background-color: #eeeeee;
}
.pink-amber app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar.class1 .mat-progress-bar-fill::after {
  background-color: #f44336;
}
.pink-amber app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar.class2 .mat-progress-bar-fill::after {
  background-color: #ff9800;
}
.pink-amber app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar.class3 .mat-progress-bar-fill::after {
  background-color: #ffeb3b;
}
.pink-amber app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar.class4 .mat-progress-bar-fill::after {
  background-color: #8bc34a;
}
.pink-amber app-analytics-document-set .work-space .side .container mat-list {
  background: white !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber app-analytics-document-set .work-space .side .container mat-list mat-list-item .mat-line b {
  color: #e91e63;
}
.pink-amber .catcher {
  background: rgba(0, 0, 0, 0.6);
}
.pink-amber .catcher.clear {
  background: rgba(0, 0, 0, 0);
}
.pink-amber .add-menu-form-body .mat-radio-group .mat-radio-button:hover {
  background: rgba(0, 0, 0, 0.04);
}
.pink-amber .add-menu-form-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber .add-menu-form-body.w-table .helper .search-bar {
  background: #fafafa;
}
.pink-amber .add-menu-form-body.w-table .helper .search-bar:hover, .pink-amber .add-menu-form-body.w-table .helper .search-bar:active, .pink-amber .add-menu-form-body.w-table .helper .search-bar:focus, .pink-amber .add-menu-form-body.w-table .helper .search-bar:focus-within {
  background: #fafafa !important;
}
.pink-amber .add-menu-form-body.w-table .helper .search-bar input {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber .add-menu-form-actions {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber .file-upload-list mat-list-item {
  background: white;
}
.pink-amber .mat-horizontal-stepper-content[aria-expanded=true] app-file-document-view {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber .mat-horizontal-stepper-content[aria-expanded=true] app-file-document-view .pdf-view-container {
  background: #eeeeee;
}
.pink-amber .add-docs-drop .docs-drop-preview app-file-document-detail {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber .add-docs-drop .docs-drop-preview app-file-document-detail .view-toolbar {
  background: white;
}
.pink-amber .add-docs-drop .load-window.message h3.primary-text {
  color: #e91e63;
}
.pink-amber mat-dialog-container app-add-menu-dialog .load-window.message h3.primary-text {
  color: #e91e63;
}
.pink-amber .asisted-input.aligned-icon.subtitle * {
  color: rgba(0, 0, 0, 0.38) !important;
}
.pink-amber .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble {
  background: #e91e63;
}
.pink-amber .asisted-input .mat-form-field-invalid + .indicator > .bubble {
  background: #f44336;
}
.pink-amber .asisted-input .mat-form-field-disabled + .indicator > .bubble {
  color: rgba(0, 0, 0, 0.38);
  background: rgba(0, 0, 0, 0.12);
}
.pink-amber .asisted-input .indicator .bubble {
  background: rgba(0, 0, 0, 0.38);
  color: white;
}
.pink-amber .asisted-input .indicator .guide {
  border-color: rgba(0, 0, 0, 0.12);
}
.pink-amber .asisted-input.icon-only .bubble {
  color: rgba(0, 0, 0, 0.54);
}
.pink-amber .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble {
  color: #e91e63;
}
.pink-amber .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble {
  color: #f44336;
}
.pink-amber .UI-V2 .main-overlay {
  background: rgba(0, 0, 0, 0.32);
}
.pink-amber .UI-V2 .icon-complex mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.pink-amber .UI-V2 .icon-complex mat-icon.complex {
  color: #e91e63 !important;
}
.pink-amber .UI-V2 .navigation {
  background: #fafafa;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber .UI-V2 .navigation *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.pink-amber .UI-V2 .navigation *::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}
.pink-amber .UI-V2 .navigation .load-window {
  background: #eeeeee;
}
.pink-amber .UI-V2 .navigation .toolbar {
  background: #e91e63;
  color: white;
}
.pink-amber .UI-V2 .navigation .toolbar.reverse {
  background: white;
  color: rgba(0, 0, 0, 0.54);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber .UI-V2 .navigation .container .helper {
  color: rgba(0, 0, 0, 0.54);
}
.pink-amber .UI-V2 .navigation .container .item {
  background: white;
  color: rgba(0, 0, 0, 0.54);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber .UI-V2 .navigation .container .item:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-amber .UI-V2 .navigation .container .item.active {
  background: #e91e63 !important;
  color: white;
  border: 1px solid #e91e63;
}
.pink-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .separator .subtitle,
.pink-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .separator .subtitle,
.pink-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .separator .subtitle,
.pink-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .separator .subtitle,
.pink-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .separator .subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.pink-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .img-editior-container .img-editor .separator .subtitle,
.pink-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .img-editior-container .img-editor .separator .subtitle,
.pink-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .img-editior-container .img-editor .separator .subtitle,
.pink-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .img-editior-container .img-editor .separator .subtitle,
.pink-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .img-editior-container .img-editor .separator .subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.pink-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme,
.pink-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme,
.pink-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme,
.pink-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme,
.pink-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .main-color-container .main-color,
.pink-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .main-color-container .main-color,
.pink-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .main-color-container .main-color,
.pink-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .main-color-container .main-color,
.pink-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .main-color-container .main-color {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .main-color-container .main-color:before,
.pink-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .main-color-container .main-color:before,
.pink-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .main-color-container .main-color:before,
.pink-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .main-color-container .main-color:before,
.pink-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .main-color-container .main-color:before {
  border-left: 10px solid rgba(0, 0, 0, 0);
  border-right: 10px solid rgba(0, 0, 0, 0);
}
.pink-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example h6.required,
.pink-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example h6.required,
.pink-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example h6.required,
.pink-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example h6.required,
.pink-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example h6.required {
  color: #f44336 !important;
}
.pink-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle,
.pink-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle,
.pink-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle,
.pink-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle,
.pink-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle {
  border: 2px solid rgba(0, 0, 0, 0);
}
.pink-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .side-color-container .side-color,
.pink-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .side-color-container .side-color,
.pink-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .side-color-container .side-color,
.pink-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .side-color-container .side-color,
.pink-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .side-color-container .side-color {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .side-color-container .side-color:before,
.pink-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .side-color-container .side-color:before,
.pink-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .side-color-container .side-color:before,
.pink-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .side-color-container .side-color:before,
.pink-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .side-color-container .side-color:before {
  border-top: 10px solid rgba(0, 0, 0, 0);
  border-bottom: 10px solid rgba(0, 0, 0, 0);
}
.pink-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .side-color-container .side-color h6.required,
.pink-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .side-color-container .side-color h6.required,
.pink-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .side-color-container .side-color h6.required,
.pink-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .side-color-container .side-color h6.required,
.pink-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .side-color-container .side-color h6.required {
  color: #f44336 !important;
}
.pink-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .permission-settings-cards .card,
.pink-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .permission-settings-cards .card,
.pink-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .permission-settings-cards .card,
.pink-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .permission-settings-cards .card,
.pink-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .permission-settings-cards .card {
  background: #fafafa;
}
.pink-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .permission-settings-cards .card .slide-sub-group,
.pink-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .permission-settings-cards .card .slide-sub-group,
.pink-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .permission-settings-cards .card .slide-sub-group,
.pink-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .permission-settings-cards .card .slide-sub-group,
.pink-amber .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .permission-settings-cards .card .slide-sub-group {
  background: rgba(0, 0, 0, 0.12);
}
.pink-amber .UI-V2 .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.pink-amber .UI-V2 .work-space .main .load-window {
  background: white !important;
}
.pink-amber .UI-V2 .work-space .main.side-open {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item mat-icon.complex {
  text-shadow: -2px 0 0px #eeeeee, 0 -2px 0px #eeeeee, 2px 0 0px #eeeeee, 0 2px 0px #eeeeee, -2px -2px 0px #eeeeee, 2px 2px 0px #eeeeee, 2px -2px 0px #eeeeee, -2px 2px 0px #eeeeee !important;
}
.pink-amber .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item h5 {
  color: rgba(0, 0, 0, 0.38);
}
.pink-amber .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item h3 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item .actions {
  pointer-events: none;
}
.pink-amber .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item .actions button {
  pointer-events: all;
}
.pink-amber .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled {
  background: #eeeeee;
}
.pink-amber .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled h3 {
  color: #607D8B !important;
}
.pink-amber .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled mat-icon {
  color: #607D8B !important;
}
.pink-amber .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled.selected {
  background: #607D8B;
  color: white !important;
}
.pink-amber .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled.selected h3 {
  color: white !important;
}
.pink-amber .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled.selected mat-icon {
  color: white !important;
}
.pink-amber .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled.selected mat-icon.complex {
  color: white !important;
  text-shadow: -2px 0 0px #607D8B, 0 -2px 0px #607D8B, 2px 0 0px #607D8B, 0 2px 0px #607D8B, -2px -2px 0px #607D8B, 2px 2px 0px #607D8B, 2px -2px 0px #607D8B, -2px 2px 0px #607D8B !important;
}
.pink-amber .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item:hover {
  background: rgba(0, 0, 0, 0.04);
}
.pink-amber .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected {
  background: #e91e63;
  color: white;
}
.pink-amber .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected mat-icon {
  color: white;
}
.pink-amber .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected mat-icon.complex {
  color: white !important;
  text-shadow: -2px 0 0px #e91e63, 0 -2px 0px #e91e63, 2px 0 0px #e91e63, 0 2px 0px #e91e63, -2px -2px 0px #e91e63, 2px 2px 0px #e91e63, 2px -2px 0px #e91e63, -2px 2px 0px #e91e63 !important;
}
.pink-amber .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected h5,
.pink-amber .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected h3 {
  color: white;
}
.pink-amber .UI-V2 .work-space .main .main-action {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber .UI-V2 .work-space .side {
  background: #fafafa;
}
.pink-amber .UI-V2 .work-space .side .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #fafafa, 0 -2px 0px #fafafa, 2px 0 0px #fafafa, 0 2px 0px #fafafa, -2px -2px 0px #fafafa, 2px 2px 0px #fafafa, 2px -2px 0px #fafafa, -2px 2px 0px #fafafa;
}
.pink-amber .UI-V2 .work-space .side *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.pink-amber .UI-V2 .work-space .side *::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}
.pink-amber .UI-V2 .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #fafafa !important;
}
.pink-amber .UI-V2 .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(0, 0, 0, 0.38);
}
.pink-amber .UI-V2 .work-space .side mat-form-field .mat-form-field-flex {
  background: #fafafa;
}
.pink-amber .UI-V2 .work-space .side mat-checkbox:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-amber .UI-V2 .work-space .side .hint.all {
  color: #ffc107;
}
.pink-amber .UI-V2 .work-space .side .toolbar .header {
  color: #e91e63;
}
.pink-amber .UI-V2 .work-space .side .toolbar .header h3 {
  color: #e91e63;
}
.pink-amber .UI-V2 .work-space .side .container .role-resume {
  background: #e91e63;
}
.pink-amber .UI-V2 .work-space .side .container .role-resume .line {
  color: white;
}
.pink-amber .UI-V2 .work-space .side .container .role-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.1);
}
.pink-amber .UI-V2 .work-space .side .container .role-resume .line.editable button {
  background: #e91e63;
}
.pink-amber .UI-V2 .work-space .side .container .section-title {
  color: #e91e63;
}
.pink-amber .UI-V2 .work-space .side .container .section-resume {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber .UI-V2 .work-space .side .container .section-resume .line.editable:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-amber .UI-V2 .work-space .side .container .section-resume .line.editable:hover .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px white, 0 -2px 0px white, 2px 0 0px white, 0 2px 0px white, -2px -2px 0px white, 2px 2px 0px white, 2px -2px 0px white, -2px 2px 0px white !important;
}
.pink-amber .UI-V2 .work-space .side .container .section-resume .line.editable button {
  background: #fafafa;
}
.pink-amber .UI-V2 .work-space .side .container .section-resume .line .header .icon-complex mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.pink-amber .UI-V2 .work-space .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #e91e63 !important;
}
.pink-amber .UI-V2 .work-space .side .container .section-resume .line .header mat-icon:not(.complex) {
  color: rgba(0, 0, 0, 0.54);
}
.pink-amber .UI-V2 .work-space .side .container .section-resume .line .header .info h4 {
  color: #e91e63;
}
.pink-amber .UI-V2 .work-space .side .container .section-resume .line ul {
  color: #e91e63;
}
.pink-amber .UI-V2 .work-space .side .container .section-resume .line.head {
  background: #eeeeee;
}
.pink-amber .UI-V2 .work-space .side .container .section-resume .line.head mat-icon {
  color: #ffc107;
}
.pink-amber .UI-V2 .work-space .side .container .section-resume .line.head h4 {
  color: white;
}
.pink-amber .UI-V2 .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body {
  background: white;
}
.pink-amber .UI-V2 .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: rgba(0, 0, 0, 0.54);
}
.pink-amber .UI-V2 .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #e91e63;
}
.pink-amber .UI-V2 .work-space .side.edition .toolbar .header {
  color: white;
}
.pink-amber .UI-V2 .work-space .side.edition .toolbar .header mat-icon {
  color: #ffc107;
}
.pink-amber .UI-V2 .work-space .side.edition .toolbar .header h3 {
  color: #ffc107;
}
.pink-amber .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body {
  background: white !important;
}
.pink-amber .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header mat-icon:not(.complex) {
  color: #e91e63;
}
.pink-amber .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header h4 {
  color: #e91e63;
}
.pink-amber .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-actions {
  background: #fafafa;
}
.pink-amber .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.pink-amber .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header .icon-complex mat-icon.complex {
  color: #ffc107 !important;
}
.pink-amber .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header h4 {
  color: #ffc107;
}
.pink-amber .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line ul {
  color: #ffc107;
  font-weight: 400;
}
.pink-amber .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head mat-icon {
  color: #ffc107 !important;
}
.pink-amber .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head h4 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .section-title {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-amber .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume h6.hint {
  background: #ffecb3;
}
.pink-amber app-role-user-find .main-overlay {
  background: rgba(0, 0, 0, 0.32);
}
.pink-amber app-role-user-find .icon-complex mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.pink-amber app-role-user-find .icon-complex mat-icon.complex {
  color: #e91e63 !important;
}
.pink-amber app-role-user-find .navigation {
  background: #fafafa;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber app-role-user-find .navigation *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.pink-amber app-role-user-find .navigation *::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}
.pink-amber app-role-user-find .navigation .load-window {
  background: #eeeeee;
}
.pink-amber app-role-user-find .navigation .toolbar {
  background: #e91e63;
  color: white;
}
.pink-amber app-role-user-find .navigation .toolbar.reverse {
  background: white;
  color: rgba(0, 0, 0, 0.54);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber app-role-user-find .navigation .container .helper {
  color: rgba(0, 0, 0, 0.54);
}
.pink-amber app-role-user-find .navigation .container .item {
  background: white;
  color: rgba(0, 0, 0, 0.54);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber app-role-user-find .navigation .container .item:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-amber app-role-user-find .navigation .container .item.active {
  background: #e91e63 !important;
  color: white;
  border: 1px solid #e91e63;
}
.pink-amber app-role-user-find .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.pink-amber app-role-user-find .work-space .main .load-window {
  background: white !important;
}
.pink-amber app-role-user-find .work-space .main.side-open {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber app-role-user-find .work-space .main .container .item {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber app-role-user-find .work-space .main .container .item .subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.pink-amber app-role-user-find .work-space .main .container .item.disabled {
  background: #eeeeee;
  color: #607D8B !important;
}
.pink-amber app-role-user-find .work-space .main .container .item.disabled h3 {
  color: #607D8B !important;
}
.pink-amber app-role-user-find .work-space .main .container .item.disabled mat-icon {
  color: #607D8B !important;
}
.pink-amber app-role-user-find .work-space .main .container .item.disabled.selected {
  background: #607D8B;
  color: white !important;
}
.pink-amber app-role-user-find .work-space .main .container .item.disabled.selected h3 {
  color: white !important;
}
.pink-amber app-role-user-find .work-space .main .container .item.disabled.selected mat-icon {
  color: white !important;
}
.pink-amber app-role-user-find .work-space .main .container .item.disabled.selected mat-icon.complex {
  color: white !important;
  text-shadow: -2px 0 0px #607D8B, 0 -2px 0px #607D8B, 2px 0 0px #607D8B, 0 2px 0px #607D8B, -2px -2px 0px #607D8B, 2px 2px 0px #607D8B, 2px -2px 0px #607D8B, -2px 2px 0px #607D8B !important;
}
.pink-amber app-role-user-find .work-space .main .container .item mat-icon.complex {
  text-shadow: -2px 0 0px #eeeeee, 0 -2px 0px #eeeeee, 2px 0 0px #eeeeee, 0 2px 0px #eeeeee, -2px -2px 0px #eeeeee, 2px 2px 0px #eeeeee, 2px -2px 0px #eeeeee, -2px 2px 0px #eeeeee !important;
}
.pink-amber app-role-user-find .work-space .main .container .item h5 {
  color: rgba(0, 0, 0, 0.38);
}
.pink-amber app-role-user-find .work-space .main .container .item h3 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber app-role-user-find .work-space .main .container .item:hover {
  background: rgba(0, 0, 0, 0.04);
}
.pink-amber app-role-user-find .work-space .main .container .item.selected {
  background: #e91e63;
  color: white;
}
.pink-amber app-role-user-find .work-space .main .container .item.selected mat-icon {
  color: white;
}
.pink-amber app-role-user-find .work-space .main .container .item.selected mat-icon.complex {
  color: white !important;
  text-shadow: -2px 0 0px #e91e63, 0 -2px 0px #e91e63, 2px 0 0px #e91e63, 0 2px 0px #e91e63, -2px -2px 0px #e91e63, 2px 2px 0px #e91e63, 2px -2px 0px #e91e63, -2px 2px 0px #e91e63 !important;
}
.pink-amber app-role-user-find .work-space .main .container .item.selected h5,
.pink-amber app-role-user-find .work-space .main .container .item.selected h3 {
  color: white;
}
.pink-amber app-role-user-find .work-space .main .main-action {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber app-role-user-find .work-space .side {
  background: #fafafa;
}
.pink-amber app-role-user-find .work-space .side .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #fafafa, 0 -2px 0px #fafafa, 2px 0 0px #fafafa, 0 2px 0px #fafafa, -2px -2px 0px #fafafa, 2px 2px 0px #fafafa, 2px -2px 0px #fafafa, -2px 2px 0px #fafafa;
}
.pink-amber app-role-user-find .work-space .side *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.pink-amber app-role-user-find .work-space .side *::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}
.pink-amber app-role-user-find .work-space .side .edition-line {
  background: #eeeeee;
}
.pink-amber app-role-user-find .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #fafafa !important;
}
.pink-amber app-role-user-find .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(0, 0, 0, 0.38);
}
.pink-amber app-role-user-find .work-space .side mat-form-field .mat-form-field-flex {
  background: #fafafa;
}
.pink-amber app-role-user-find .work-space .side mat-checkbox:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-amber app-role-user-find .work-space .side .hint.all {
  color: #ffc107;
}
.pink-amber app-role-user-find .work-space .side .toolbar .header {
  color: #e91e63;
}
.pink-amber app-role-user-find .work-space .side .toolbar .header h3 {
  color: #e91e63;
}
.pink-amber app-role-user-find .work-space .side .container .role-resume {
  background: #e91e63;
}
.pink-amber app-role-user-find .work-space .side .container .role-resume .line {
  color: white;
}
.pink-amber app-role-user-find .work-space .side .container .role-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.1);
}
.pink-amber app-role-user-find .work-space .side .container .role-resume .line.editable button {
  background: #e91e63;
}
.pink-amber app-role-user-find .work-space .side .container .section-title {
  color: #e91e63;
}
.pink-amber app-role-user-find .work-space .side .container .section-resume {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber app-role-user-find .work-space .side .container .section-resume .line.editable:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-amber app-role-user-find .work-space .side .container .section-resume .line.editable:hover .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px white, 0 -2px 0px white, 2px 0 0px white, 0 2px 0px white, -2px -2px 0px white, 2px 2px 0px white, 2px -2px 0px white, -2px 2px 0px white !important;
}
.pink-amber app-role-user-find .work-space .side .container .section-resume .line.editable button {
  background: #fafafa;
}
.pink-amber app-role-user-find .work-space .side .container .section-resume .line .header .icon-complex mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.pink-amber app-role-user-find .work-space .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #e91e63 !important;
}
.pink-amber app-role-user-find .work-space .side .container .section-resume .line .header mat-icon:not(.complex) {
  color: rgba(0, 0, 0, 0.54);
}
.pink-amber app-role-user-find .work-space .side .container .section-resume .line .header .info h4 {
  color: #e91e63;
}
.pink-amber app-role-user-find .work-space .side .container .section-resume .line ul {
  color: #e91e63;
}
.pink-amber app-role-user-find .work-space .side .container .section-resume .line.head {
  background: #eeeeee;
}
.pink-amber app-role-user-find .work-space .side .container .section-resume .line.head mat-icon {
  color: #ffc107;
}
.pink-amber app-role-user-find .work-space .side .container .section-resume .line.head h4 {
  color: white;
}
.pink-amber app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee-title {
  color: #e91e63;
}
.pink-amber app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body {
  background: white;
}
.pink-amber app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: rgba(0, 0, 0, 0.54);
}
.pink-amber app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #e91e63;
}
.pink-amber app-role-user-find .work-space .side.edition .toolbar .header {
  color: white;
}
.pink-amber app-role-user-find .work-space .side.edition .toolbar .header mat-icon {
  color: #ffc107;
}
.pink-amber app-role-user-find .work-space .side.edition .toolbar .header h3 {
  color: #ffc107;
}
.pink-amber app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body {
  background: white !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header mat-icon:not(.complex) {
  color: #e91e63;
}
.pink-amber app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header h4 {
  color: #e91e63;
}
.pink-amber app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-actions {
  background: #fafafa;
}
.pink-amber app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.pink-amber app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header .icon-complex mat-icon.complex {
  color: #ffc107 !important;
}
.pink-amber app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header h4 {
  color: #ffc107;
}
.pink-amber app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line ul {
  color: #ffc107;
}
.pink-amber app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head mat-icon {
  color: #ffc107 !important;
}
.pink-amber app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head h4 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .section-title {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-amber app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume h6.hint {
  background: #ffecb3;
}
.pink-amber app-role-user-find .work-space app-role-user-detail.disabled .side .toolbar .header {
  color: #607D8B !important;
}
.pink-amber app-role-user-find .work-space app-role-user-detail.disabled .side .container .role-resume {
  background: #607D8B !important;
}
.pink-amber app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-title {
  color: #607D8B !important;
}
.pink-amber app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line ul {
  color: #607D8B !important;
}
.pink-amber app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header mat-icon {
  color: #607D8B;
}
.pink-amber app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .icon-complex mat-icon {
  color: #607D8B !important;
}
.pink-amber app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #607D8B !important;
}
.pink-amber app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .info h4 {
  color: #607D8B !important;
}
.pink-amber app-role-user-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee-title {
  color: #607D8B;
}
.pink-amber app-role-user-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: #607D8B;
}
.pink-amber app-role-user-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #607D8B;
}
.pink-amber app-actor-find .main-overlay {
  background: rgba(0, 0, 0, 0.32);
}
.pink-amber app-actor-find .icon-complex mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.pink-amber app-actor-find .icon-complex mat-icon.complex {
  color: #e91e63 !important;
}
.pink-amber app-actor-find .navigation {
  background: #fafafa;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber app-actor-find .navigation *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.pink-amber app-actor-find .navigation *::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}
.pink-amber app-actor-find .navigation .load-window {
  background: #eeeeee;
}
.pink-amber app-actor-find .navigation .toolbar {
  background: #e91e63;
  color: white;
}
.pink-amber app-actor-find .navigation .toolbar.reverse {
  background: white;
  color: rgba(0, 0, 0, 0.54);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber app-actor-find .navigation .container .helper {
  color: rgba(0, 0, 0, 0.54);
}
.pink-amber app-actor-find .navigation .container .item {
  background: white;
  color: rgba(0, 0, 0, 0.54);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber app-actor-find .navigation .container .item:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-amber app-actor-find .navigation .container .item.active {
  background: #e91e63 !important;
  color: white;
  border: 1px solid #e91e63;
}
.pink-amber app-actor-find .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.pink-amber app-actor-find .work-space .main .load-window {
  background: white !important;
}
.pink-amber app-actor-find .work-space .main.side-open {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber app-actor-find .work-space .main .container .item {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber app-actor-find .work-space .main .container .item .subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.pink-amber app-actor-find .work-space .main .container .item.disabled {
  background: #eeeeee;
  color: #607D8B !important;
}
.pink-amber app-actor-find .work-space .main .container .item.disabled h3 {
  color: #607D8B !important;
}
.pink-amber app-actor-find .work-space .main .container .item.disabled mat-icon {
  color: #607D8B !important;
}
.pink-amber app-actor-find .work-space .main .container .item.disabled.selected {
  background: #607D8B;
  color: white !important;
}
.pink-amber app-actor-find .work-space .main .container .item.disabled.selected h3 {
  color: white !important;
}
.pink-amber app-actor-find .work-space .main .container .item.disabled.selected mat-icon {
  color: white !important;
}
.pink-amber app-actor-find .work-space .main .container .item.disabled.selected mat-icon.complex {
  color: white !important;
  text-shadow: -2px 0 0px #607D8B, 0 -2px 0px #607D8B, 2px 0 0px #607D8B, 0 2px 0px #607D8B, -2px -2px 0px #607D8B, 2px 2px 0px #607D8B, 2px -2px 0px #607D8B, -2px 2px 0px #607D8B !important;
}
.pink-amber app-actor-find .work-space .main .container .item mat-icon.complex {
  text-shadow: -2px 0 0px #eeeeee, 0 -2px 0px #eeeeee, 2px 0 0px #eeeeee, 0 2px 0px #eeeeee, -2px -2px 0px #eeeeee, 2px 2px 0px #eeeeee, 2px -2px 0px #eeeeee, -2px 2px 0px #eeeeee !important;
}
.pink-amber app-actor-find .work-space .main .container .item h5 {
  color: rgba(0, 0, 0, 0.38);
}
.pink-amber app-actor-find .work-space .main .container .item h3 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber app-actor-find .work-space .main .container .item:hover {
  background: rgba(0, 0, 0, 0.04);
}
.pink-amber app-actor-find .work-space .main .container .item.selected {
  background: #e91e63;
  color: white;
}
.pink-amber app-actor-find .work-space .main .container .item.selected mat-icon {
  color: white;
}
.pink-amber app-actor-find .work-space .main .container .item.selected mat-icon.complex {
  color: white !important;
  text-shadow: -2px 0 0px #e91e63, 0 -2px 0px #e91e63, 2px 0 0px #e91e63, 0 2px 0px #e91e63, -2px -2px 0px #e91e63, 2px 2px 0px #e91e63, 2px -2px 0px #e91e63, -2px 2px 0px #e91e63 !important;
}
.pink-amber app-actor-find .work-space .main .container .item.selected h5,
.pink-amber app-actor-find .work-space .main .container .item.selected h3 {
  color: white;
}
.pink-amber app-actor-find .work-space .main .main-action {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber app-actor-find .work-space .side {
  background: #fafafa;
}
.pink-amber app-actor-find .work-space .side .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #fafafa, 0 -2px 0px #fafafa, 2px 0 0px #fafafa, 0 2px 0px #fafafa, -2px -2px 0px #fafafa, 2px 2px 0px #fafafa, 2px -2px 0px #fafafa, -2px 2px 0px #fafafa;
}
.pink-amber app-actor-find .work-space .side *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.pink-amber app-actor-find .work-space .side *::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}
.pink-amber app-actor-find .work-space .side .edition-line {
  background: #eeeeee;
}
.pink-amber app-actor-find .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #fafafa !important;
}
.pink-amber app-actor-find .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(0, 0, 0, 0.38);
}
.pink-amber app-actor-find .work-space .side mat-form-field .mat-form-field-flex {
  background: #fafafa;
}
.pink-amber app-actor-find .work-space .side mat-checkbox:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-amber app-actor-find .work-space .side .hint.all {
  color: #ffc107;
}
.pink-amber app-actor-find .work-space .side .toolbar .header {
  color: #e91e63;
}
.pink-amber app-actor-find .work-space .side .toolbar .header h3 {
  color: #e91e63;
}
.pink-amber app-actor-find .work-space .side .container .role-resume {
  background: #e91e63;
}
.pink-amber app-actor-find .work-space .side .container .role-resume .line {
  color: white;
}
.pink-amber app-actor-find .work-space .side .container .role-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.1);
}
.pink-amber app-actor-find .work-space .side .container .role-resume .line.editable button {
  background: #e91e63;
}
.pink-amber app-actor-find .work-space .side .container .section-title {
  color: #e91e63;
}
.pink-amber app-actor-find .work-space .side .container .section-resume {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber app-actor-find .work-space .side .container .section-resume .line.editable:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-amber app-actor-find .work-space .side .container .section-resume .line.editable:hover .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px white, 0 -2px 0px white, 2px 0 0px white, 0 2px 0px white, -2px -2px 0px white, 2px 2px 0px white, 2px -2px 0px white, -2px 2px 0px white !important;
}
.pink-amber app-actor-find .work-space .side .container .section-resume .line.editable button {
  background: #fafafa;
}
.pink-amber app-actor-find .work-space .side .container .section-resume .line .header .icon-complex mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.pink-amber app-actor-find .work-space .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #e91e63 !important;
}
.pink-amber app-actor-find .work-space .side .container .section-resume .line .header mat-icon:not(.complex) {
  color: rgba(0, 0, 0, 0.54);
}
.pink-amber app-actor-find .work-space .side .container .section-resume .line .header .info h4 {
  color: #e91e63;
}
.pink-amber app-actor-find .work-space .side .container .section-resume .line ul {
  color: #e91e63;
}
.pink-amber app-actor-find .work-space .side .container .section-resume .line.head {
  background: #eeeeee;
}
.pink-amber app-actor-find .work-space .side .container .section-resume .line.head mat-icon {
  color: #ffc107;
}
.pink-amber app-actor-find .work-space .side .container .section-resume .line.head h4 {
  color: white;
}
.pink-amber app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee-title {
  color: #e91e63;
}
.pink-amber app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body {
  background: white;
}
.pink-amber app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: rgba(0, 0, 0, 0.54);
}
.pink-amber app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #e91e63;
}
.pink-amber app-actor-find .work-space .side.edition .toolbar .header {
  color: white;
}
.pink-amber app-actor-find .work-space .side.edition .toolbar .header mat-icon {
  color: #ffc107;
}
.pink-amber app-actor-find .work-space .side.edition .toolbar .header h3 {
  color: #ffc107;
}
.pink-amber app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body {
  background: white !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header mat-icon:not(.complex) {
  color: #e91e63;
}
.pink-amber app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header h4 {
  color: #e91e63;
}
.pink-amber app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-actions {
  background: #fafafa;
}
.pink-amber app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.pink-amber app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header .icon-complex mat-icon.complex {
  color: #ffc107 !important;
}
.pink-amber app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header h4 {
  color: #ffc107;
}
.pink-amber app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line ul {
  color: #ffc107;
}
.pink-amber app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head mat-icon {
  color: #ffc107 !important;
}
.pink-amber app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head h4 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .section-title {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-amber app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume h6.hint {
  background: #ffecb3;
}
.pink-amber app-actor-find .work-space app-role-user-detail.disabled .side .toolbar .header {
  color: #607D8B !important;
}
.pink-amber app-actor-find .work-space app-role-user-detail.disabled .side .container .role-resume {
  background: #607D8B !important;
}
.pink-amber app-actor-find .work-space app-role-user-detail.disabled .side .container .section-title {
  color: #607D8B !important;
}
.pink-amber app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line ul {
  color: #607D8B !important;
}
.pink-amber app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header mat-icon {
  color: #607D8B;
}
.pink-amber app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .icon-complex mat-icon {
  color: #607D8B !important;
}
.pink-amber app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #607D8B !important;
}
.pink-amber app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .info h4 {
  color: #607D8B !important;
}
.pink-amber app-actor-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee-title {
  color: #607D8B;
}
.pink-amber app-actor-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: #607D8B;
}
.pink-amber app-actor-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #607D8B;
}
.pink-amber .dropzone {
  border: 2px dashed #607D8B;
  background: #eeeeee !important;
}
.pink-amber .dropzone.hovering {
  border: 2px solid #607D8B;
  color: rgba(0, 0, 0, 0.38) !important;
}
.pink-amber .dropzone button.mat-stroked-button {
  background: white;
}
.pink-amber .firmador .content-action-main {
  color: white !important;
}
.pink-amber .firmador .content-action-main.disabled {
  color: rgba(0, 0, 0, 0.38) !important;
  background: rgba(0, 0, 0, 0.12);
}
.pink-amber .sign-cycle {
  background: white;
}
.pink-amber .sign-cycle button.success {
  background-color: #009688;
}
.pink-amber .sign-cycle button.warning {
  background-color: #673AB7;
}
.pink-amber .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-title-container .cycle-content-title h4 {
  color: #607D8B;
}
.pink-amber .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-action .content-action-main {
  color: white !important;
}
.pink-amber .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-action .content-action-main.mat-stroked-button:disabled {
  color: #9e9e9e !important;
}
.pink-amber .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-action .content-action-main.mat-stroked-button:not(:disabled) {
  color: #ffc107 !important;
}
.pink-amber .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-action .content-action-side u {
  color: #9e9e9e;
}
.pink-amber .resume-status {
  background: white;
}
.pink-amber .help-hoverable mat-icon {
  color: rgba(0, 0, 0, 0.54);
}
.pink-amber .help-hoverable mat-hint {
  background: white;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.pink-amber .help-hoverable mat-hint b {
  color: #e91e63 !important;
}
.pink-amber app-file-document-sign-massive-dialog .dialog-content,
.pink-amber app-share-person-dialog .dialog-content {
  background: #fafafa;
}
.pink-amber app-file-document-sign-massive-dialog .dialog-content mat-toolbar mat-toolbar-row .mat-avatar h3,
.pink-amber app-share-person-dialog .dialog-content mat-toolbar mat-toolbar-row .mat-avatar h3 {
  color: #e91e63;
}
.pink-amber app-file-document-sign-massive-dialog .dialog-content .dialog-body p b,
.pink-amber app-share-person-dialog .dialog-content .dialog-body p b {
  color: #ffc107;
}
.pink-amber app-file-document-sign-massive-dialog .dialog-content app-file-document-sign,
.pink-amber app-share-person-dialog .dialog-content app-file-document-sign {
  background: white;
}
.pink-amber .mat-basic-chip {
  background: #eeeeee !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber .mat-basic-chip.seen {
  border-color: #2196F3 !important;
  color: #2196F3 !important;
}
.pink-amber .mat-basic-chip.unseen {
  border-color: #607D8B !important;
  color: #607D8B !important;
}
.pink-amber .mat-basic-chip.signed-ok {
  border-color: #009688 !important;
  color: #009688 !important;
}
.pink-amber .mat-basic-chip.signed-ok.cancelled {
  border-color: #795548 !important;
  color: #795548 !important;
}
.pink-amber .mat-basic-chip.signed-not-ok {
  border-color: #673AB7 !important;
  color: #673AB7 !important;
}
.pink-amber .mat-basic-chip.not-signed {
  border-color: #ff4081 !important;
  color: #ff4081 !important;
}
.pink-amber .mat-basic-chip.not-signed-employer {
  border-color: #ff4081 !important;
  color: #ff4081 !important;
}
.pink-amber app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip {
  background: #eeeeee !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.seen {
  border-color: #2196F3 !important;
  color: #2196F3 !important;
}
.pink-amber app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.unseen {
  border-color: #607D8B !important;
  color: #607D8B !important;
}
.pink-amber app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.signed-ok {
  border-color: #009688 !important;
  color: #009688 !important;
}
.pink-amber app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.signed-ok.cancelled {
  border-color: #795548 !important;
  color: #795548 !important;
}
.pink-amber app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.signed-not-ok {
  border-color: #673AB7 !important;
  color: #673AB7 !important;
}
.pink-amber app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.not-signed {
  border-color: #ff4081 !important;
  color: #ff4081 !important;
}
.pink-amber app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.not-signed-employer {
  border-color: #ff4081 !important;
  color: #ff4081 !important;
}
.pink-amber .inbox-container.employee.my-documents .chip-line.mat-line .mat-chip-list-wrapper .mat-basic-chip.seen {
  color: #607D8B !important;
}
.pink-amber .resume-status .mat-basic-chip {
  background: #eeeeee !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber .resume-status .mat-basic-chip.signed-ok {
  border-color: #009688 !important;
  background-color: #009688 !important;
  color: white !important;
}
.pink-amber .resume-status .mat-basic-chip.signed-ok.cancelled {
  border-color: #795548 !important;
  background-color: #795548 !important;
  color: white !important;
}
.pink-amber .resume-status .mat-basic-chip.signed-not-ok {
  border-color: #673AB7 !important;
  background-color: #673AB7 !important;
  color: white !important;
}
.pink-amber app-file-document-view .new-container app-file-document-upload {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber app-file-document-view .new-container app-upload-form .file-upload-list .mat-subheader {
  background: white;
}
.pink-amber app-file-document-view .new-container app-upload-form .file-upload-list mat-list-item {
  background: #fafafa !important;
}
.pink-amber app-file-document-view .preview-content {
  background: #607D8B;
}
.pink-amber app-file-document-view .preview-content h4 {
  color: white;
}
.pink-amber app-file-document-view .view-container {
  background: white;
}
.pink-amber app-file-document-view .view-container mat-toolbar.side-bar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber .formio-hint {
  background: #e91e63;
  color: white;
}
.pink-amber mat-dialog-container app-file-document-state-chips {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber app-candidate-promotion .load-window.message h3.primary-text {
  color: #e91e63;
}
.pink-amber app-multiple-documentation-add-dialog .icon-complex mat-icon,
.pink-amber app-documentation-type-set-configuration-dialog .icon-complex mat-icon,
.pink-amber app-add-candidate-dialog .icon-complex mat-icon,
.pink-amber app-share-person-dialog .icon-complex mat-icon,
.pink-amber app-share-person-response-dialog .icon-complex mat-icon {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-amber app-multiple-documentation-add-dialog .icon-complex mat-icon.complex,
.pink-amber app-documentation-type-set-configuration-dialog .icon-complex mat-icon.complex,
.pink-amber app-add-candidate-dialog .icon-complex mat-icon.complex,
.pink-amber app-share-person-dialog .icon-complex mat-icon.complex,
.pink-amber app-share-person-response-dialog .icon-complex mat-icon.complex {
  color: #e91e63 !important;
}
.pink-amber app-multiple-documentation-add-dialog .icon-complex mat-icon.complex.mat-accent,
.pink-amber app-documentation-type-set-configuration-dialog .icon-complex mat-icon.complex.mat-accent,
.pink-amber app-add-candidate-dialog .icon-complex mat-icon.complex.mat-accent,
.pink-amber app-share-person-dialog .icon-complex mat-icon.complex.mat-accent,
.pink-amber app-share-person-response-dialog .icon-complex mat-icon.complex.mat-accent {
  color: #ffc107;
}
.pink-amber app-multiple-documentation-add-dialog .icon-complex mat-icon.complex.side,
.pink-amber app-documentation-type-set-configuration-dialog .icon-complex mat-icon.complex.side,
.pink-amber app-add-candidate-dialog .icon-complex mat-icon.complex.side,
.pink-amber app-share-person-dialog .icon-complex mat-icon.complex.side,
.pink-amber app-share-person-response-dialog .icon-complex mat-icon.complex.side {
  color: rgba(0, 0, 0, 0.54);
}
.pink-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection,
.pink-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection,
.pink-amber app-add-candidate-dialog .dialog-content .document-template-container .document-selection,
.pink-amber app-share-person-dialog .dialog-content .document-template-container .document-selection,
.pink-amber app-share-person-response-dialog .dialog-content .document-template-container .document-selection {
  background: #fafafa;
}
.pink-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.pink-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.pink-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3,
.pink-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.pink-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.pink-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3,
.pink-amber app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.pink-amber app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.pink-amber app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3,
.pink-amber app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.pink-amber app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.pink-amber app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3,
.pink-amber app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.pink-amber app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.pink-amber app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3 {
  color: #ffc107 !important;
}
.pink-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon,
.pink-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon,
.pink-amber app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon,
.pink-amber app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon,
.pink-amber app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon {
  color: #e91e63;
}
.pink-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3,
.pink-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3,
.pink-amber app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3,
.pink-amber app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3,
.pink-amber app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3 {
  color: #e91e63;
}
.pink-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex,
.pink-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex,
.pink-amber app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex,
.pink-amber app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex,
.pink-amber app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex {
  color: #ffc107 !important;
  text-shadow: -2px 0 0px #eeeeee, 0 -2px 0px #eeeeee, 2px 0 0px #eeeeee, 0 2px 0px #eeeeee, -2px -2px 0px #eeeeee, 2px 2px 0px #eeeeee, 2px -2px 0px #eeeeee, -2px 2px 0px white;
}
.pink-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex,
.pink-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex,
.pink-amber app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex,
.pink-amber app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex,
.pink-amber app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex {
  color: rgba(0, 0, 0, 0.38) !important;
}
.pink-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b,
.pink-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b,
.pink-amber app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b,
.pink-amber app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b,
.pink-amber app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex,
.pink-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex,
.pink-amber app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex,
.pink-amber app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex,
.pink-amber app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex {
  color: rgba(0, 0, 0, 0.38) !important;
}
.pink-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex,
.pink-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex,
.pink-amber app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex,
.pink-amber app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex,
.pink-amber app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex {
  color: rgba(0, 0, 0, 0.38) !important;
}
.pink-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover,
.pink-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover,
.pink-amber app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover,
.pink-amber app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover,
.pink-amber app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover {
  background: rgba(0, 0, 0, 0.04);
}
.pink-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b,
.pink-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b,
.pink-amber app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b,
.pink-amber app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b,
.pink-amber app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.pink-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.pink-amber app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.pink-amber app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.pink-amber app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble {
  background: #e91e63;
}
.pink-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble,
.pink-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble,
.pink-amber app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble,
.pink-amber app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble,
.pink-amber app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble {
  background: #f44336;
}
.pink-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble,
.pink-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble,
.pink-amber app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble,
.pink-amber app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble,
.pink-amber app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble {
  color: rgba(0, 0, 0, 0.38);
  background: rgba(0, 0, 0, 0.12);
}
.pink-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble,
.pink-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble,
.pink-amber app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble,
.pink-amber app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble,
.pink-amber app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble {
  background: rgba(0, 0, 0, 0.38);
  color: white;
}
.pink-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide,
.pink-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide,
.pink-amber app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide,
.pink-amber app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide,
.pink-amber app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide {
  border-color: rgba(0, 0, 0, 0.12);
}
.pink-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble,
.pink-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble,
.pink-amber app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble,
.pink-amber app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble,
.pink-amber app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble {
  color: rgba(0, 0, 0, 0.54);
}
.pink-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.pink-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.pink-amber app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.pink-amber app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.pink-amber app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble {
  color: #e91e63;
}
.pink-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble,
.pink-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble,
.pink-amber app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble,
.pink-amber app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble,
.pink-amber app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble {
  color: #f44336;
}
.pink-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar,
.pink-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar,
.pink-amber app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar,
.pink-amber app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar,
.pink-amber app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar {
  background: #607D8B;
}
.pink-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row,
.pink-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row,
.pink-amber app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row,
.pink-amber app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row,
.pink-amber app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row {
  color: white;
}
.pink-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper,
.pink-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper,
.pink-amber app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper,
.pink-amber app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper,
.pink-amber app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper {
  background: #607D8B;
}
.pink-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop,
.pink-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop,
.pink-amber app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop,
.pink-amber app-share-person-dialog .dialog-content .document-template-container .add-docs-drop,
.pink-amber app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body,
.pink-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body,
.pink-amber app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body,
.pink-amber app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body,
.pink-amber app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3,
.pink-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3,
.pink-amber app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3,
.pink-amber app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3,
.pink-amber app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3 {
  color: #ffc107;
}
.pink-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5,
.pink-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5,
.pink-amber app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5,
.pink-amber app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5,
.pink-amber app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5 {
  color: rgba(0, 0, 0, 0.54);
}
.pink-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5,
.pink-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5,
.pink-amber app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5,
.pink-amber app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5,
.pink-amber app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5 {
  color: rgba(0, 0, 0, 0.54);
}
.pink-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator,
.pink-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator,
.pink-amber app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator,
.pink-amber app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator,
.pink-amber app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background: #fafafa;
}
.pink-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip,
.pink-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip,
.pink-amber app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip,
.pink-amber app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip,
.pink-amber app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip {
  background: white !important;
}
.pink-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon,
.pink-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon,
.pink-amber app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon,
.pink-amber app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon,
.pink-amber app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon {
  color: #ffc107 !important;
}
.pink-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3,
.pink-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3,
.pink-amber app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3,
.pink-amber app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3,
.pink-amber app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3 {
  color: #ffc107 !important;
}
.pink-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5,
.pink-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5,
.pink-amber app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5,
.pink-amber app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5,
.pink-amber app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5 {
  color: rgba(0, 0, 0, 0.54) !important;
}
.pink-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon,
.pink-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon,
.pink-amber app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon,
.pink-amber app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon,
.pink-amber app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon {
  color: #e91e63 !important;
}
.pink-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3,
.pink-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3,
.pink-amber app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3,
.pink-amber app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3,
.pink-amber app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3 {
  color: #e91e63 !important;
}
.pink-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5,
.pink-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5,
.pink-amber app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5,
.pink-amber app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5,
.pink-amber app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5 {
  color: #e91e63 !important;
}
.pink-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled,
.pink-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled,
.pink-amber app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled,
.pink-amber app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled,
.pink-amber app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled {
  background: #fafafa !important;
  color: rgba(0, 0, 0, 0.38);
}
.pink-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove,
.pink-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove,
.pink-amber app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove,
.pink-amber app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove,
.pink-amber app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove {
  color: rgba(0, 0, 0, 0.54);
}
.pink-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove,
.pink-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove,
.pink-amber app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove,
.pink-amber app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove,
.pink-amber app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove {
  color: rgba(0, 0, 0, 0.54) !important;
}
.pink-amber app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .preview-action,
.pink-amber app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .preview-action,
.pink-amber app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .preview-action,
.pink-amber app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .preview-action,
.pink-amber app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .preview-action {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber .tyc .sticky {
  background: white;
}
.pink-amber .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper:nth-child(odd) datatable-body-row {
  background: #fafafa;
}
.pink-amber .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active * {
  color: white !important;
}
.pink-amber .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active:hover .datatable-body-cell-label {
  background: #e91e63;
}
.pink-amber .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active:hover .cs-floating-action {
  background: #e91e63 !important;
}
.pink-amber .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active.row-cancel * {
  color: rgba(0, 0, 0, 0.54) !important;
}
.pink-amber .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active.row-cancel .datatable-row-group {
  background: #607D8B !important;
}
.pink-amber .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active.row-cancel:hover .datatable-body-cell-label {
  background: #607D8B;
}
.pink-amber .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.row-cancel * {
  color: #b0bec5 !important;
  transition-property: color;
  transition-duration: 0.3s;
  transition-timing-function: linear;
}
.pink-amber .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row:hover .datatable-body-cell-label button {
  background: #eeeeee;
}
.pink-amber .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row datatable-body-cell.candidate-status_bar mat-progress-bar .mat-progress-bar-buffer {
  background-color: #eeeeee;
}
.pink-amber .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row datatable-body-cell.candidate-status_bar .status_bar-count {
  color: rgba(0, 0, 0, 0.54);
}
.pink-amber .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row datatable-body-cell.candidate-status_bar .inactive {
  color: rgba(0, 0, 0, 0.54);
}
.pink-amber .cs-grid-test .info {
  background: white;
}
.pink-amber .cs-grid-test .selected-column {
  background: white;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
}
.pink-amber .cs-grid-test .selection-cell .datatable-body-cell.active {
  background: #e91e63 !important;
  color: white !important;
}
.pink-amber .cs-grid-test nav {
  background: white;
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 13px 19px 2px rgba(0, 0, 0, 0.14), 0 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.pink-amber .cs-grid-test .ngx-datatable.material {
  background: white;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.pink-amber .cs-grid-test .ngx-datatable.material.striped .datatable-row-odd {
  background: #fafafa;
}
.pink-amber .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active, .pink-amber .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active .datatable-row-group, .pink-amber .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active, .pink-amber .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active .datatable-row-group, .pink-amber .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active, .pink-amber .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active .datatable-row-group {
  background: #e91e63;
  color: white;
}
.pink-amber .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active:hover, .pink-amber .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active:hover .datatable-row-group, .pink-amber .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active:hover, .pink-amber .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active:hover .datatable-row-group, .pink-amber .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active:hover, .pink-amber .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active:hover .datatable-row-group {
  background: #e91e63;
  color: white;
}
.pink-amber .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active:focus, .pink-amber .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active:focus .datatable-row-group, .pink-amber .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active:focus, .pink-amber .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active:focus .datatable-row-group, .pink-amber .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active:focus, .pink-amber .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active:focus .datatable-row-group {
  background: #e91e63;
  color: white;
}
.pink-amber .cs-grid-test .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover, .pink-amber .cs-grid-test .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: rgba(0, 0, 0, 0.04);
}
.pink-amber .cs-grid-test .ngx-datatable.material:not(.cell-selection) .datatable-body-row:focus, .pink-amber .cs-grid-test .ngx-datatable.material:not(.cell-selection) .datatable-body-row:focus .datatable-row-group {
  background: rgba(0, 0, 0, 0.04);
}
.pink-amber .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell:hover, .pink-amber .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell:hover .datatable-row-group {
  background: rgba(0, 0, 0, 0.04);
}
.pink-amber .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell:focus, .pink-amber .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell:focus .datatable-row-group {
  background: rgba(0, 0, 0, 0.04);
}
.pink-amber .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active, .pink-amber .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active .datatable-row-group {
  background: #e91e63;
  color: white;
}
.pink-amber .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active:hover, .pink-amber .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active:hover .datatable-row-group {
  background: #e91e63;
  color: white;
}
.pink-amber .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active:focus, .pink-amber .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active:focus .datatable-row-group {
  background: #e91e63;
  color: white;
}
.pink-amber .cs-grid-test .ngx-datatable.material .datatable-header .datatable-row-left,
.pink-amber .cs-grid-test .ngx-datatable.material .datatable-body .datatable-row-left {
  background-color: white;
}
.pink-amber .cs-grid-test .ngx-datatable.material .datatable-header .datatable-row-right,
.pink-amber .cs-grid-test .ngx-datatable.material .datatable-body .datatable-row-right {
  background-color: white;
}
.pink-amber .cs-grid-test .ngx-datatable.material .datatable-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.38);
}
.pink-amber .cs-grid-test .ngx-datatable.material .datatable-header .datatable-header-cell {
  background-color: white;
  color: rgba(0, 0, 0, 0.54);
}
.pink-amber .cs-grid-test .ngx-datatable.material .datatable-header .resize-handle {
  border-right: solid 1px rgba(0, 0, 0, 0.04);
}
.pink-amber .cs-grid-test .ngx-datatable.material .datatable-body .datatable-row-detail {
  background: #eeeeee;
}
.pink-amber .cs-grid-test .ngx-datatable.material .datatable-body .datatable-group-header {
  background: #eeeeee;
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
  border-top: solid 1px rgba(0, 0, 0, 0.12);
}
.pink-amber .cs-grid-test .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber .cs-grid-test .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-group-cell {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber .cs-grid-test .ngx-datatable.material .datatable-body .progress-linear .container {
  background-color: white;
}
.pink-amber .cs-grid-test .ngx-datatable.material .datatable-body .progress-linear .container .bar {
  background-color: #e91e63;
}
.pink-amber .cs-grid-test .ngx-datatable.material .datatable-footer {
  color: rgba(0, 0, 0, 0.54);
}
.pink-amber .cs-grid-test .ngx-datatable.material .datatable-footer .datatable-pager li.disabled a {
  color: rgba(0, 0, 0, 0.38) !important;
  background-color: rgba(0, 0, 0, 0) !important;
}
.pink-amber .cs-grid-test .ngx-datatable.material .datatable-footer .datatable-pager li.active a {
  background-color: #e91e63;
}
.pink-amber .cs-grid-test .ngx-datatable.material .datatable-footer .datatable-pager a {
  color: rgba(0, 0, 0, 0.54);
}
.pink-amber .cs-grid-test .ngx-datatable.material .datatable-footer .datatable-pager a:hover {
  color: rgba(0, 0, 0, 0.04);
  background-color: rgba(0, 0, 0, 0.04);
}
.pink-amber .cs-grid-test .ngx-datatable.material .datatable-summary-row .datatable-body-row {
  background-color: #fafafa;
}
.pink-amber .cs-grid-test .ngx-datatable.material .datatable-summary-row .datatable-body-row:hover {
  background-color: #fafafa;
}
.pink-amber .cs-grid-test .datatable-checkbox input[type=checkbox]:after {
  background: white;
}
.pink-amber .view-container .view-toolbar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal.new.image-opened .help-content img {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card {
  background: white;
}
.pink-amber app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container .aditional-info {
  color: rgba(0, 0, 0, 0.54);
}
.pink-amber app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container .mat-card-footer {
  background: white;
}
.pink-amber app-help-index mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card {
  background: white;
}
.pink-amber app-help-index mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card .mat-card-footer {
  background: white;
}
.pink-amber .cropper .employee-title .title-text {
  color: white !important;
}
.pink-amber .cropper app-cs-upload-cropper-control .preview h5 {
  color: rgba(0, 0, 0, 0.54);
}
.pink-amber app-file-document-upload .preview-container {
  background: #607D8B !important;
}
.pink-amber app-file-document-upload .preview-container h4 {
  color: #eeeeee;
}
.pink-amber .preview-container {
  background: #607D8B !important;
}
.pink-amber .preview-container h4 {
  color: #eeeeee;
}
.pink-amber .clean-autocomple mat-list {
  background: white;
}
.pink-amber .clean-autocomple mat-list .mat-list-item:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-amber .clean-autocomple mat-list .mat-list-item .mat-list-item-content .mat-list-text {
  color: #e91e63;
}
.pink-amber app-leave .inbox-toolbar {
  background: #fafafa;
}
.pink-amber app-leave .mat-tab-body-wrapper {
  position: relative;
  overflow: hidden;
  display: flex;
  transition: height 500ms cubic-bezier(0.35, 0, 0.25, 1);
  height: 95% !important;
}
.pink-amber app-leave .leave_container {
  background: #fafafa;
}
.pink-amber app-leave .leave_container .leave_items .leave_items-item.ok .content .leave_item-status_icon {
  color: #009688;
}
.pink-amber app-leave .leave_container .leave_items .leave_items-item.not-ok .content .leave_item-status_icon {
  color: #9C27B0;
}
.pink-amber app-leave .leave_container .leave_items .leave_items-item.pending .content .leave_item-status_icon {
  color: #FF9800;
}
.pink-amber app-leave .leave_container .leave_items .leave_items-item.draft .content .leave_item-status_icon {
  color: rgba(0, 0, 0, 0.54);
}
.pink-amber app-leave .leave_container .leave_items .leave_items-item.cancel .content .leave_item-status_icon {
  color: #795548;
}
.pink-amber app-leave .leave_container .leave_items .leave_items-item .content:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-amber app-leave .leave_container .leave_items .leave_items-item .content.selected {
  border-left: 6px solid #ffc107 !important;
}
.pink-amber app-leave .leave_container .leave_items .leave_items-item .content.selected mat-icon {
  color: #ffc107 !important;
}
.pink-amber app-leave .leave_container .leave_items .leave_items-item .content.selected .item-data {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-amber app-leave .leave_container .leave_items .leave_items-item .content .leave_item-icon {
  color: rgba(0, 0, 0, 0.54);
}
.pink-amber app-leave .leave_container .leave_items .leave_items-item .content .item-data {
  color: rgba(0, 0, 0, 0.54);
}
.pink-amber app-leave .leave_container .window-spinner {
  background: #fafafa !important;
}
.pink-amber app-leave-detail .leave_detail .leave_detail-title .info h3,
.pink-amber app-leave-detail .leave_detail .leave_detail-title .info mat-icon {
  color: #e91e63;
}
.pink-amber app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .ok .large-icon {
  background: #b2dfdb;
  color: #009688;
}
.pink-amber app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .ok h2 {
  color: #009688;
}
.pink-amber app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .not-ok .large-icon {
  background: #e1bee7;
  color: #9C27B0;
}
.pink-amber app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .not-ok h2 {
  color: #9C27B0;
}
.pink-amber app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .pending .large-icon {
  background: #FFE0B2;
  color: #FF9800;
}
.pink-amber app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .pending h2 {
  color: #FF9800;
}
.pink-amber app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .draft .large-icon {
  background: #f2f2f2;
  color: #dedede;
}
.pink-amber app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .draft h2 {
  color: #dedede;
}
.pink-amber app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .cancelled .large-icon {
  background: #BCAAA4;
  color: #795548;
}
.pink-amber app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .cancelled h2 {
  color: #795548;
}
.pink-amber app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .actions {
  padding: 16px;
  height: 96px;
  column-gap: 16px;
}
.pink-amber app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .actions button {
  height: 100%;
  border-radius: 40px;
}
.pink-amber app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .actions button.ok {
  background: #009688 !important;
  color: white;
}
.pink-amber app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .actions button.not-ok {
  color: #9C27B0 !important;
}
.pink-amber app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning mat-basic-chip {
  background: #eeeeee !important;
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
  border-radius: 40px !important;
  height: 35px !important;
}
.pink-amber app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning mat-basic-chip.signed-ok {
  border-color: #009688 !important;
  color: #009688 !important;
}
.pink-amber app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning mat-basic-chip.signed-not-ok {
  border-color: #9C27B0 !important;
  color: #9C27B0 !important;
}
.pink-amber app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning mat-basic-chip.draft {
  border-color: #dedede !important;
  color: #dedede !important;
}
.pink-amber app-leave-request-detail .load-window {
  background: #fafafa;
}
.pink-amber app-leave-request-detail form .leave_detail-container {
  background: #fafafa;
}
.pink-amber app-leave-request-detail form .leave_detail-container .toolbar .header {
  color: #e91e63;
}
.pink-amber app-leave-request-detail form .leave_detail-container .toolbar .header .text h3 {
  color: #e91e63;
}
.pink-amber app-leave-request-detail form .leave_detail-container .toolbar .header .text h5 {
  color: rgba(0, 0, 0, 0.54);
}
.pink-amber app-leave-request-detail form .leave_detail-container .leave_detail-container-content .leave_resume {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber app-leave-request-detail form .leave_detail-container .leave_detail-container-content .line h6 {
  color: rgba(0, 0, 0, 0.54);
}
.pink-amber app-leave-request-detail form .leave_detail-container .actions button.ok {
  background: #009688 !important;
  color: white;
}
.pink-amber app-leave-request-detail form .leave_detail-container .actions button.not-ok {
  color: #9C27B0 !important;
}
.pink-amber .new_leave_modal-steps {
  display: unset !important;
}
.pink-amber .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button .mat-radio-label .mat-radio-label-content .side-text {
  color: rgba(0, 0, 0, 0.54);
}
.pink-amber .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button .mat-radio-label .mat-radio-label-content .side-text.muted {
  color: rgba(0, 0, 0, 0.38);
}
.pink-amber .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button .mat-radio-label .mat-radio-label-content .accent mat-icon,
.pink-amber .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button .mat-radio-label .mat-radio-label-content .accent .main-text {
  color: #ffc107;
}
.pink-amber .config_leave-container .config_leave-card {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber .config_leave-container .config_leave-card .config_leave-auto_chip mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #fafafa !important;
}
.pink-amber .config_leave-container .config_leave-card .config_leave-auto_chip mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(0, 0, 0, 0.38);
}
.pink-amber .config_leave-container .config_leave-card .config_leave-auto_chip mat-form-field .mat-form-field-flex {
  background: #fafafa;
}
.pink-amber *[color=success] {
  color: #4DB6AC;
}
.pink-amber .primary {
  color: #e91e63 !important;
}
.pink-amber .accent {
  color: #ffc107 !important;
}
.pink-amber app-lsd .icon-complex mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.pink-amber app-lsd .icon-complex mat-icon.complex {
  color: #e91e63 !important;
}
.pink-amber app-lsd .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.pink-amber app-lsd .work-space .main .load-window {
  background: white !important;
}
.pink-amber app-lsd .work-space .main.side-open {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber app-lsd .work-space .main .container .item {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber app-lsd .work-space .main .container .item h5 {
  color: rgba(0, 0, 0, 0.38);
}
.pink-amber app-lsd .work-space .main .container .item h3 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber app-lsd .work-space .main .container .item:hover {
  background: rgba(0, 0, 0, 0.04);
}
.pink-amber app-lsd .work-space .main .container .item.selected {
  background: #e91e63;
  color: white !important;
}
.pink-amber app-lsd .work-space .main .container .item.selected h3 {
  color: white !important;
}
.pink-amber app-lsd .work-space .main .container .item.selected.pending .status {
  background: rgba(0, 0, 0, 0.12) !important;
  color: #ffe082;
}
.pink-amber app-lsd .work-space .main .container .item.selected.ok .status {
  background: rgba(0, 0, 0, 0.12) !important;
  color: #a5d6a7;
}
.pink-amber app-lsd .work-space .main .container .item.selected.not-ok .status {
  background: rgba(0, 0, 0, 0.12) !important;
  color: #e57373;
}
.pink-amber app-lsd .work-space .main .main-action {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber app-lsd .work-space .side {
  background: #fafafa;
}
.pink-amber app-lsd .work-space .side .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #fafafa, 0 -2px 0px #fafafa, 2px 0 0px #fafafa, 0 2px 0px #fafafa, -2px -2px 0px #fafafa, 2px 2px 0px #fafafa, 2px -2px 0px #fafafa, -2px 2px 0px #fafafa;
}
.pink-amber app-lsd .work-space .side *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.pink-amber app-lsd .work-space .side *::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}
.pink-amber app-lsd .work-space .side .edition-line {
  background: #eeeeee;
}
.pink-amber app-lsd .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #fafafa !important;
}
.pink-amber app-lsd .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(0, 0, 0, 0.38);
}
.pink-amber app-lsd .work-space .side mat-form-field .mat-form-field-flex {
  background: #fafafa;
}
.pink-amber app-lsd .work-space .side mat-checkbox:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-amber app-lsd .work-space .side .hint.all {
  color: #ffc107;
}
.pink-amber app-lsd .work-space .side .toolbar .header {
  color: #e91e63;
}
.pink-amber app-lsd .work-space .side .toolbar .header h3 {
  color: #e91e63;
}
.pink-amber app-lsd .work-space .side .container .doc-resume {
  background: #e91e63;
}
.pink-amber app-lsd .work-space .side .container .doc-resume .line {
  color: white;
}
.pink-amber app-lsd .work-space .side .container .doc-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.1);
}
.pink-amber app-lsd .work-space .side .container .doc-resume .line.editable button {
  background: #e91e63;
}
.pink-amber app-lsd .work-space .side .container .section-title {
  color: #e91e63;
}
.pink-amber app-lsd .work-space .side .container .section-resume {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber app-lsd .work-space .side .container .section-resume .line.editable:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-amber app-lsd .work-space .side .container .section-resume .line.editable:hover .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px white, 0 -2px 0px white, 2px 0 0px white, 0 2px 0px white, -2px -2px 0px white, 2px 2px 0px white, 2px -2px 0px white, -2px 2px 0px white !important;
}
.pink-amber app-lsd .work-space .side .container .section-resume .line.editable button {
  background: #fafafa;
}
.pink-amber app-lsd .work-space .side .container .section-resume .line .header .icon-complex mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.pink-amber app-lsd .work-space .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #e91e63 !important;
}
.pink-amber app-lsd .work-space .side .container .section-resume .line .header mat-icon:not(.complex) {
  color: rgba(0, 0, 0, 0.54);
}
.pink-amber app-lsd .work-space .side .container .section-resume .line .header .info h4 {
  color: #e91e63;
}
.pink-amber app-lsd .work-space .side .container .section-resume .line ul {
  color: #e91e63;
}
.pink-amber app-lsd .work-space .side .container .section-resume .line.head {
  background: #eeeeee;
}
.pink-amber app-lsd .work-space .side .container .section-resume .line.head mat-icon {
  color: #ffc107;
}
.pink-amber app-lsd .work-space .side .container .section-resume .line.head h4 {
  color: white;
}
.pink-amber app-lsd .work-space .side.edition .toolbar .header {
  color: white;
}
.pink-amber app-lsd .work-space .side.edition .toolbar .header mat-icon {
  color: #ffc107;
}
.pink-amber app-lsd .work-space .side.edition .toolbar .header h3 {
  color: #ffc107;
}
.pink-amber .dialog-content {
  max-height: 90vh;
}
.pink-amber .dialog-content mat-toolbar {
  background: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber .dialog-content .dialog-container .document-template-container .document-selection mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #fafafa !important;
}
.pink-amber .dialog-content .dialog-container .document-template-container .document-selection mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(0, 0, 0, 0.38);
}
.pink-amber .dialog-content .dialog-container .document-template-container .document-selection mat-form-field .mat-form-field-flex {
  background: #fafafa;
}
.pink-amber .dialog-content .dialog-container .document-template-container .document-selection app-upload-form .file-upload-list .mat-subheader::before {
  color: #e91e63;
}
.pink-amber .dialog-content .dialog-container .view-container {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-amber .dialog-content .form-actions {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.pink-amber.dark .mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-amber.dark .mat-badge-small .mat-badge-content {
  font-size: 9px;
}
.pink-amber.dark .mat-badge-large .mat-badge-content {
  font-size: 24px;
}
.pink-amber.dark .mat-h1,
.pink-amber.dark .mat-headline,
.pink-amber.dark .mat-typography .mat-h1,
.pink-amber.dark .mat-typography .mat-headline,
.pink-amber.dark .mat-typography h1 {
  font: 400 24px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.pink-amber.dark .mat-h2,
.pink-amber.dark .mat-title,
.pink-amber.dark .mat-typography .mat-h2,
.pink-amber.dark .mat-typography .mat-title,
.pink-amber.dark .mat-typography h2 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.pink-amber.dark .mat-h3,
.pink-amber.dark .mat-subheading-2,
.pink-amber.dark .mat-typography .mat-h3,
.pink-amber.dark .mat-typography .mat-subheading-2,
.pink-amber.dark .mat-typography h3 {
  font: 400 16px / 28px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.pink-amber.dark .mat-h4,
.pink-amber.dark .mat-subheading-1,
.pink-amber.dark .mat-typography .mat-h4,
.pink-amber.dark .mat-typography .mat-subheading-1,
.pink-amber.dark .mat-typography h4 {
  font: 400 15px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.pink-amber.dark .mat-h5,
.pink-amber.dark .mat-typography .mat-h5,
.pink-amber.dark .mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}
.pink-amber.dark .mat-h6,
.pink-amber.dark .mat-typography .mat-h6,
.pink-amber.dark .mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}
.pink-amber.dark .mat-body-strong,
.pink-amber.dark .mat-body-2,
.pink-amber.dark .mat-typography .mat-body-strong,
.pink-amber.dark .mat-typography .mat-body-2 {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.pink-amber.dark .mat-body,
.pink-amber.dark .mat-body-1,
.pink-amber.dark .mat-typography .mat-body,
.pink-amber.dark .mat-typography .mat-body-1,
.pink-amber.dark .mat-typography {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.pink-amber.dark .mat-body p,
.pink-amber.dark .mat-body-1 p,
.pink-amber.dark .mat-typography .mat-body p,
.pink-amber.dark .mat-typography .mat-body-1 p,
.pink-amber.dark .mat-typography p {
  margin: 0 0 12px;
}
.pink-amber.dark .mat-small,
.pink-amber.dark .mat-caption,
.pink-amber.dark .mat-typography .mat-small,
.pink-amber.dark .mat-typography .mat-caption {
  font: 400 12px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.pink-amber.dark .mat-display-4,
.pink-amber.dark .mat-typography .mat-display-4 {
  font: 300 112px / 112px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}
.pink-amber.dark .mat-display-3,
.pink-amber.dark .mat-typography .mat-display-3 {
  font: 400 56px / 56px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}
.pink-amber.dark .mat-display-2,
.pink-amber.dark .mat-typography .mat-display-2 {
  font: 400 45px / 48px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}
.pink-amber.dark .mat-display-1,
.pink-amber.dark .mat-typography .mat-display-1 {
  font: 400 34px / 40px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}
.pink-amber.dark .mat-bottom-sheet-container {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.pink-amber.dark .mat-button, .pink-amber.dark .mat-raised-button, .pink-amber.dark .mat-icon-button, .pink-amber.dark .mat-stroked-button,
.pink-amber.dark .mat-flat-button, .pink-amber.dark .mat-fab, .pink-amber.dark .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.pink-amber.dark .mat-button-toggle {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-amber.dark .mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-amber.dark .mat-card-title {
  font-size: 24px;
  font-weight: 500;
}
.pink-amber.dark .mat-card-header .mat-card-title {
  font-size: 20px;
}
.pink-amber.dark .mat-card-subtitle,
.pink-amber.dark .mat-card-content {
  font-size: 14px;
}
.pink-amber.dark .mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-amber.dark .mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}
.pink-amber.dark .mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.pink-amber.dark .mat-chip .mat-chip-trailing-icon.mat-icon,
.pink-amber.dark .mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}
.pink-amber.dark .mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-amber.dark .mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}
.pink-amber.dark .mat-cell, .pink-amber.dark .mat-footer-cell {
  font-size: 14px;
}
.pink-amber.dark .mat-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-amber.dark .mat-calendar-body {
  font-size: 13px;
}
.pink-amber.dark .mat-calendar-body-label,
.pink-amber.dark .mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}
.pink-amber.dark .mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}
.pink-amber.dark .mat-dialog-title {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.pink-amber.dark .mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400;
}
.pink-amber.dark .mat-expansion-panel-content {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.pink-amber.dark .mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.pink-amber.dark .mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}
.pink-amber.dark .mat-form-field-prefix .mat-icon,
.pink-amber.dark .mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.pink-amber.dark .mat-form-field-prefix .mat-icon-button,
.pink-amber.dark .mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.pink-amber.dark .mat-form-field-prefix .mat-icon-button .mat-icon,
.pink-amber.dark .mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}
.pink-amber.dark .mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}
.pink-amber.dark .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.pink-amber.dark .mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34349em) scale(0.75);
  width: 133.3335933333%;
}
.pink-amber.dark .mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34348em) scale(0.75);
  width: 133.3336033333%;
}
.pink-amber.dark .mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}
.pink-amber.dark .mat-form-field-label {
  top: 1.34375em;
}
.pink-amber.dark .mat-form-field-underline {
  bottom: 1.34375em;
}
.pink-amber.dark .mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}
.pink-amber.dark .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.pink-amber.dark .mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.pink-amber.dark .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.pink-amber.dark .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00178px);
  width: 133.3341133333%;
}
.pink-amber.dark .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00179px);
  width: 133.3341233333%;
}
.pink-amber.dark .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.0018px);
  width: 133.3341333333%;
}
.pink-amber.dark .mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.pink-amber.dark .mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.pink-amber.dark .mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}
@media print {
  .pink-amber.dark .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.pink-amber.dark .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28044em) scale(0.75);
  }
  .pink-amber.dark .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28043em) scale(0.75);
  }
  .pink-amber.dark .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28042em) scale(0.75);
  }
}
.pink-amber.dark .mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.pink-amber.dark .mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.pink-amber.dark .mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.pink-amber.dark .mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59349em) scale(0.75);
  width: 133.3335933333%;
}
.pink-amber.dark .mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59348em) scale(0.75);
  width: 133.3336033333%;
}
.pink-amber.dark .mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.pink-amber.dark .mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.pink-amber.dark .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.pink-amber.dark .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59349em) scale(0.75);
  width: 133.3335933333%;
}
.pink-amber.dark .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59348em) scale(0.75);
  width: 133.3336033333%;
}
.pink-amber.dark .mat-grid-tile-header,
.pink-amber.dark .mat-grid-tile-footer {
  font-size: 14px;
}
.pink-amber.dark .mat-grid-tile-header .mat-line,
.pink-amber.dark .mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.pink-amber.dark .mat-grid-tile-header .mat-line:nth-child(n+2),
.pink-amber.dark .mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.pink-amber.dark input.mat-input-element {
  margin-top: -0.0625em;
}
.pink-amber.dark .mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
}
.pink-amber.dark .mat-paginator,
.pink-amber.dark .mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
}
.pink-amber.dark .mat-radio-button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-amber.dark .mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-amber.dark .mat-select-trigger {
  height: 1.125em;
}
.pink-amber.dark .mat-slide-toggle-content {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-amber.dark .mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}
.pink-amber.dark .mat-stepper-vertical, .pink-amber.dark .mat-stepper-horizontal {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-amber.dark .mat-step-label {
  font-size: 14px;
  font-weight: 400;
}
.pink-amber.dark .mat-step-sub-label-error {
  font-weight: normal;
}
.pink-amber.dark .mat-step-label-error {
  font-size: 14px;
}
.pink-amber.dark .mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}
.pink-amber.dark .mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-amber.dark .mat-tab-label, .pink-amber.dark .mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.pink-amber.dark .mat-toolbar,
.pink-amber.dark .mat-toolbar h1,
.pink-amber.dark .mat-toolbar h2,
.pink-amber.dark .mat-toolbar h3,
.pink-amber.dark .mat-toolbar h4,
.pink-amber.dark .mat-toolbar h5,
.pink-amber.dark .mat-toolbar h6 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0;
}
.pink-amber.dark .mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.pink-amber.dark .mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.pink-amber.dark .mat-list-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-amber.dark .mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-amber.dark .mat-list-base .mat-list-item {
  font-size: 16px;
}
.pink-amber.dark .mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.pink-amber.dark .mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.pink-amber.dark .mat-list-base .mat-list-option {
  font-size: 16px;
}
.pink-amber.dark .mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.pink-amber.dark .mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.pink-amber.dark .mat-list-base .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.pink-amber.dark .mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.pink-amber.dark .mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.pink-amber.dark .mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.pink-amber.dark .mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.pink-amber.dark .mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.pink-amber.dark .mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.pink-amber.dark .mat-list-base[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}
.pink-amber.dark .mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
}
.pink-amber.dark .mat-optgroup-label {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.pink-amber.dark .mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
}
.pink-amber.dark .mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}
.pink-amber.dark .mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-amber.dark .mat-tree-node,
.pink-amber.dark .mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}
.pink-amber.dark .mat-ripple {
  overflow: hidden;
  position: relative;
}
.pink-amber.dark .mat-ripple:not(:empty) {
  transform: translateZ(0);
}
.pink-amber.dark .mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}
.pink-amber.dark .mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .pink-amber.dark .mat-ripple-element {
  display: none;
}

.pink-amber.dark .cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .pink-amber.dark .cdk-visually-hidden {
  left: auto;
  right: 0;
}
.pink-amber.dark .cdk-overlay-container, .pink-amber.dark .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.pink-amber.dark .cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.pink-amber.dark .cdk-overlay-container:empty {
  display: none;
}
.pink-amber.dark .cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}
.pink-amber.dark .cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}
.pink-amber.dark .cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.pink-amber.dark .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .pink-amber.dark .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}
.pink-amber.dark .cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}
.pink-amber.dark .cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.pink-amber.dark .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}
.pink-amber.dark .cdk-overlay-backdrop-noop-animation {
  transition: none;
}
.pink-amber.dark .cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}
.pink-amber.dark .cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}
.pink-amber.dark textarea.cdk-textarea-autosize {
  resize: none;
}
.pink-amber.dark textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}
.pink-amber.dark textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}
@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.pink-amber.dark .cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}
.pink-amber.dark .cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}
.pink-amber.dark .mat-focus-indicator {
  position: relative;
}
.pink-amber.dark .mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.pink-amber.dark .mat-focus-indicator:focus::before {
  content: "";
}
.cdk-high-contrast-active .pink-amber.dark {
  --mat-focus-indicator-display: block;
}

.pink-amber.dark .mat-mdc-focus-indicator {
  position: relative;
}
.pink-amber.dark .mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.pink-amber.dark .mat-mdc-focus-indicator:focus::before {
  content: "";
}
.cdk-high-contrast-active .pink-amber.dark {
  --mat-mdc-focus-indicator-display: block;
}

.pink-amber.dark .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.pink-amber.dark .mat-option {
  color: white;
}
.pink-amber.dark .mat-option:hover:not(.mat-option-disabled), .pink-amber.dark .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.pink-amber.dark .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.pink-amber.dark .mat-option.mat-active {
  background: rgba(255, 255, 255, 0.04);
  color: white;
}
.pink-amber.dark .mat-option.mat-option-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.pink-amber.dark .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f48fb1;
}
.pink-amber.dark .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ffe082;
}
.pink-amber.dark .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #e57373;
}
.pink-amber.dark .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.7);
}
.pink-amber.dark .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.5);
}
.pink-amber.dark .mat-pseudo-checkbox {
  color: rgba(255, 255, 255, 0.7);
}
.pink-amber.dark .mat-pseudo-checkbox::after {
  color: #303030;
}
.pink-amber.dark .mat-pseudo-checkbox-disabled {
  color: #686868;
}
.pink-amber.dark .mat-primary .mat-pseudo-checkbox-checked,
.pink-amber.dark .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #f48fb1;
}
.pink-amber.dark .mat-pseudo-checkbox-checked,
.pink-amber.dark .mat-pseudo-checkbox-indeterminate,
.pink-amber.dark .mat-accent .mat-pseudo-checkbox-checked,
.pink-amber.dark .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #ffe082;
}
.pink-amber.dark .mat-warn .mat-pseudo-checkbox-checked,
.pink-amber.dark .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #e57373;
}
.pink-amber.dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.pink-amber.dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #686868;
}
.pink-amber.dark .mat-app-background, .pink-amber.dark.mat-app-background {
  background-color: #303030;
  color: white;
}
.pink-amber.dark .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.pink-amber.dark .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.pink-amber.dark .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.pink-amber.dark .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.pink-amber.dark .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.pink-amber.dark .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.pink-amber.dark .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.pink-amber.dark .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.pink-amber.dark .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.pink-amber.dark .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.pink-amber.dark .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.pink-amber.dark .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.pink-amber.dark .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.pink-amber.dark .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.pink-amber.dark .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.pink-amber.dark .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.pink-amber.dark .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.pink-amber.dark .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.pink-amber.dark .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.pink-amber.dark .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.pink-amber.dark .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.pink-amber.dark .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.pink-amber.dark .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.pink-amber.dark .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.pink-amber.dark .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.pink-amber.dark .mat-autocomplete-panel {
  background: #424242;
  color: white;
}
.pink-amber.dark .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.pink-amber.dark .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: #424242;
}
.pink-amber.dark .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: white;
}
.pink-amber.dark .mat-badge-content {
  color: rgba(0, 0, 0, 0.87);
  background: #f48fb1;
}
.cdk-high-contrast-active .pink-amber.dark .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.pink-amber.dark .mat-badge-accent .mat-badge-content {
  background: #ffe082;
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark .mat-badge-warn .mat-badge-content {
  color: rgba(0, 0, 0, 0.87);
  background: #e57373;
}
.pink-amber.dark .mat-badge-disabled .mat-badge-content {
  background: #6e6e6e;
  color: rgba(255, 255, 255, 0.5);
}
.pink-amber.dark .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.pink-amber.dark .mat-button, .pink-amber.dark .mat-icon-button, .pink-amber.dark .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.pink-amber.dark .mat-button.mat-primary, .pink-amber.dark .mat-icon-button.mat-primary, .pink-amber.dark .mat-stroked-button.mat-primary {
  color: #f48fb1;
}
.pink-amber.dark .mat-button.mat-accent, .pink-amber.dark .mat-icon-button.mat-accent, .pink-amber.dark .mat-stroked-button.mat-accent {
  color: #ffe082;
}
.pink-amber.dark .mat-button.mat-warn, .pink-amber.dark .mat-icon-button.mat-warn, .pink-amber.dark .mat-stroked-button.mat-warn {
  color: #e57373;
}
.pink-amber.dark .mat-button.mat-primary.mat-button-disabled, .pink-amber.dark .mat-button.mat-accent.mat-button-disabled, .pink-amber.dark .mat-button.mat-warn.mat-button-disabled, .pink-amber.dark .mat-button.mat-button-disabled.mat-button-disabled, .pink-amber.dark .mat-icon-button.mat-primary.mat-button-disabled, .pink-amber.dark .mat-icon-button.mat-accent.mat-button-disabled, .pink-amber.dark .mat-icon-button.mat-warn.mat-button-disabled, .pink-amber.dark .mat-icon-button.mat-button-disabled.mat-button-disabled, .pink-amber.dark .mat-stroked-button.mat-primary.mat-button-disabled, .pink-amber.dark .mat-stroked-button.mat-accent.mat-button-disabled, .pink-amber.dark .mat-stroked-button.mat-warn.mat-button-disabled, .pink-amber.dark .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.pink-amber.dark .mat-button.mat-primary .mat-button-focus-overlay, .pink-amber.dark .mat-icon-button.mat-primary .mat-button-focus-overlay, .pink-amber.dark .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #f48fb1;
}
.pink-amber.dark .mat-button.mat-accent .mat-button-focus-overlay, .pink-amber.dark .mat-icon-button.mat-accent .mat-button-focus-overlay, .pink-amber.dark .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #ffe082;
}
.pink-amber.dark .mat-button.mat-warn .mat-button-focus-overlay, .pink-amber.dark .mat-icon-button.mat-warn .mat-button-focus-overlay, .pink-amber.dark .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #e57373;
}
.pink-amber.dark .mat-button.mat-button-disabled .mat-button-focus-overlay, .pink-amber.dark .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .pink-amber.dark .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.pink-amber.dark .mat-button .mat-ripple-element, .pink-amber.dark .mat-icon-button .mat-ripple-element, .pink-amber.dark .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.pink-amber.dark .mat-button-focus-overlay {
  background: white;
}
.pink-amber.dark .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(255, 255, 255, 0.12);
}
.pink-amber.dark .mat-flat-button, .pink-amber.dark .mat-raised-button, .pink-amber.dark .mat-fab, .pink-amber.dark .mat-mini-fab {
  color: white;
  background-color: #424242;
}
.pink-amber.dark .mat-flat-button.mat-primary, .pink-amber.dark .mat-raised-button.mat-primary, .pink-amber.dark .mat-fab.mat-primary, .pink-amber.dark .mat-mini-fab.mat-primary {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark .mat-flat-button.mat-accent, .pink-amber.dark .mat-raised-button.mat-accent, .pink-amber.dark .mat-fab.mat-accent, .pink-amber.dark .mat-mini-fab.mat-accent {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark .mat-flat-button.mat-warn, .pink-amber.dark .mat-raised-button.mat-warn, .pink-amber.dark .mat-fab.mat-warn, .pink-amber.dark .mat-mini-fab.mat-warn {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark .mat-flat-button.mat-primary.mat-button-disabled, .pink-amber.dark .mat-flat-button.mat-accent.mat-button-disabled, .pink-amber.dark .mat-flat-button.mat-warn.mat-button-disabled, .pink-amber.dark .mat-flat-button.mat-button-disabled.mat-button-disabled, .pink-amber.dark .mat-raised-button.mat-primary.mat-button-disabled, .pink-amber.dark .mat-raised-button.mat-accent.mat-button-disabled, .pink-amber.dark .mat-raised-button.mat-warn.mat-button-disabled, .pink-amber.dark .mat-raised-button.mat-button-disabled.mat-button-disabled, .pink-amber.dark .mat-fab.mat-primary.mat-button-disabled, .pink-amber.dark .mat-fab.mat-accent.mat-button-disabled, .pink-amber.dark .mat-fab.mat-warn.mat-button-disabled, .pink-amber.dark .mat-fab.mat-button-disabled.mat-button-disabled, .pink-amber.dark .mat-mini-fab.mat-primary.mat-button-disabled, .pink-amber.dark .mat-mini-fab.mat-accent.mat-button-disabled, .pink-amber.dark .mat-mini-fab.mat-warn.mat-button-disabled, .pink-amber.dark .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.pink-amber.dark .mat-flat-button.mat-primary, .pink-amber.dark .mat-raised-button.mat-primary, .pink-amber.dark .mat-fab.mat-primary, .pink-amber.dark .mat-mini-fab.mat-primary {
  background-color: #f48fb1;
}
.pink-amber.dark .mat-flat-button.mat-accent, .pink-amber.dark .mat-raised-button.mat-accent, .pink-amber.dark .mat-fab.mat-accent, .pink-amber.dark .mat-mini-fab.mat-accent {
  background-color: #ffe082;
}
.pink-amber.dark .mat-flat-button.mat-warn, .pink-amber.dark .mat-raised-button.mat-warn, .pink-amber.dark .mat-fab.mat-warn, .pink-amber.dark .mat-mini-fab.mat-warn {
  background-color: #e57373;
}
.pink-amber.dark .mat-flat-button.mat-primary.mat-button-disabled, .pink-amber.dark .mat-flat-button.mat-accent.mat-button-disabled, .pink-amber.dark .mat-flat-button.mat-warn.mat-button-disabled, .pink-amber.dark .mat-flat-button.mat-button-disabled.mat-button-disabled, .pink-amber.dark .mat-raised-button.mat-primary.mat-button-disabled, .pink-amber.dark .mat-raised-button.mat-accent.mat-button-disabled, .pink-amber.dark .mat-raised-button.mat-warn.mat-button-disabled, .pink-amber.dark .mat-raised-button.mat-button-disabled.mat-button-disabled, .pink-amber.dark .mat-fab.mat-primary.mat-button-disabled, .pink-amber.dark .mat-fab.mat-accent.mat-button-disabled, .pink-amber.dark .mat-fab.mat-warn.mat-button-disabled, .pink-amber.dark .mat-fab.mat-button-disabled.mat-button-disabled, .pink-amber.dark .mat-mini-fab.mat-primary.mat-button-disabled, .pink-amber.dark .mat-mini-fab.mat-accent.mat-button-disabled, .pink-amber.dark .mat-mini-fab.mat-warn.mat-button-disabled, .pink-amber.dark .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(255, 255, 255, 0.12);
}
.pink-amber.dark .mat-flat-button.mat-primary .mat-ripple-element, .pink-amber.dark .mat-raised-button.mat-primary .mat-ripple-element, .pink-amber.dark .mat-fab.mat-primary .mat-ripple-element, .pink-amber.dark .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.pink-amber.dark .mat-flat-button.mat-accent .mat-ripple-element, .pink-amber.dark .mat-raised-button.mat-accent .mat-ripple-element, .pink-amber.dark .mat-fab.mat-accent .mat-ripple-element, .pink-amber.dark .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.pink-amber.dark .mat-flat-button.mat-warn .mat-ripple-element, .pink-amber.dark .mat-raised-button.mat-warn .mat-ripple-element, .pink-amber.dark .mat-fab.mat-warn .mat-ripple-element, .pink-amber.dark .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.pink-amber.dark .mat-stroked-button:not([class*=mat-elevation-z]), .pink-amber.dark .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.pink-amber.dark .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.pink-amber.dark .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.pink-amber.dark .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.pink-amber.dark .mat-fab:not([class*=mat-elevation-z]), .pink-amber.dark .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.pink-amber.dark .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .pink-amber.dark .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.pink-amber.dark .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .pink-amber.dark .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.pink-amber.dark .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.pink-amber.dark .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.pink-amber.dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.pink-amber.dark .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.pink-amber.dark .mat-button-toggle {
  color: rgba(255, 255, 255, 0.5);
}
.pink-amber.dark .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(255, 255, 255, 0.12);
}
.pink-amber.dark .mat-button-toggle-appearance-standard {
  color: white;
  background: #424242;
}
.pink-amber.dark .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: white;
}
.pink-amber.dark .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #595959;
}
.pink-amber.dark [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #595959;
}
.pink-amber.dark .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #595959;
}
.pink-amber.dark .mat-button-toggle-checked {
  background-color: #212121;
  color: rgba(255, 255, 255, 0.7);
}
.pink-amber.dark .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: white;
}
.pink-amber.dark .mat-button-toggle-disabled {
  color: rgba(255, 255, 255, 0.3);
  background-color: black;
}
.pink-amber.dark .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: #424242;
}
.pink-amber.dark .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #424242;
}
.pink-amber.dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.pink-amber.dark .mat-button-toggle-group-appearance-standard {
  border: solid 1px #595959;
}
.pink-amber.dark .mat-card {
  background: #424242;
  color: white;
}
.pink-amber.dark .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.pink-amber.dark .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.pink-amber.dark .mat-card-subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.pink-amber.dark .mat-checkbox-frame {
  border-color: rgba(255, 255, 255, 0.7);
}
.pink-amber.dark .mat-checkbox-checkmark {
  fill: #303030;
}
.pink-amber.dark .mat-checkbox-checkmark-path {
  stroke: #303030 !important;
}
.pink-amber.dark .mat-checkbox-mixedmark {
  background-color: #303030;
}
.pink-amber.dark .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .pink-amber.dark .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #f48fb1;
}
.pink-amber.dark .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .pink-amber.dark .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #ffe082;
}
.pink-amber.dark .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .pink-amber.dark .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #e57373;
}
.pink-amber.dark .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .pink-amber.dark .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #686868;
}
.pink-amber.dark .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #686868;
}
.pink-amber.dark .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(255, 255, 255, 0.5);
}
.pink-amber.dark .mat-checkbox .mat-ripple-element {
  background-color: white;
}
.pink-amber.dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.pink-amber.dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #f48fb1;
}
.pink-amber.dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.pink-amber.dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #ffe082;
}
.pink-amber.dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.pink-amber.dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #e57373;
}
.pink-amber.dark .mat-chip.mat-standard-chip {
  background-color: #616161;
  color: white;
}
.pink-amber.dark .mat-chip.mat-standard-chip .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.pink-amber.dark .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.pink-amber.dark .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.pink-amber.dark .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.pink-amber.dark .mat-chip.mat-standard-chip::after {
  background: white;
}
.pink-amber.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #f48fb1;
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.pink-amber.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.pink-amber.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #e57373;
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.pink-amber.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.pink-amber.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #ffe082;
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.pink-amber.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.pink-amber.dark .mat-table {
  background: #424242;
}
.pink-amber.dark .mat-table thead, .pink-amber.dark .mat-table tbody, .pink-amber.dark .mat-table tfoot,
.pink-amber.dark mat-header-row, .pink-amber.dark mat-row, .pink-amber.dark mat-footer-row,
.pink-amber.dark [mat-header-row], .pink-amber.dark [mat-row], .pink-amber.dark [mat-footer-row],
.pink-amber.dark .mat-table-sticky {
  background: inherit;
}
.pink-amber.dark mat-row, .pink-amber.dark mat-header-row, .pink-amber.dark mat-footer-row,
.pink-amber.dark th.mat-header-cell, .pink-amber.dark td.mat-cell, .pink-amber.dark td.mat-footer-cell {
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
.pink-amber.dark .mat-header-cell {
  color: rgba(255, 255, 255, 0.7);
}
.pink-amber.dark .mat-cell, .pink-amber.dark .mat-footer-cell {
  color: white;
}
.pink-amber.dark .mat-calendar-arrow {
  fill: white;
}
.pink-amber.dark .mat-datepicker-toggle,
.pink-amber.dark .mat-datepicker-content .mat-calendar-next-button,
.pink-amber.dark .mat-datepicker-content .mat-calendar-previous-button {
  color: white;
}
.pink-amber.dark .mat-calendar-table-header-divider::after {
  background: rgba(255, 255, 255, 0.12);
}
.pink-amber.dark .mat-calendar-table-header,
.pink-amber.dark .mat-calendar-body-label {
  color: rgba(255, 255, 255, 0.7);
}
.pink-amber.dark .mat-calendar-body-cell-content,
.pink-amber.dark .mat-date-range-input-separator {
  color: white;
  border-color: transparent;
}
.pink-amber.dark .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(255, 255, 255, 0.5);
}
.pink-amber.dark .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(255, 255, 255, 0.5);
}
.pink-amber.dark .mat-calendar-body-in-preview {
  color: rgba(255, 255, 255, 0.24);
}
.pink-amber.dark .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.5);
}
.pink-amber.dark .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.3);
}
.pink-amber.dark .mat-calendar-body-in-range::before {
  background: rgba(244, 143, 177, 0.2);
}
.pink-amber.dark .mat-calendar-body-comparison-identical,
.pink-amber.dark .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.pink-amber.dark .mat-calendar-body-comparison-bridge-start::before,
.pink-amber.dark [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 143, 177, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.pink-amber.dark .mat-calendar-body-comparison-bridge-end::before,
.pink-amber.dark [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 143, 177, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.pink-amber.dark .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.pink-amber.dark .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.pink-amber.dark .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.pink-amber.dark .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.pink-amber.dark .mat-calendar-body-selected {
  background-color: #f48fb1;
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 143, 177, 0.4);
}
.pink-amber.dark .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.pink-amber.dark .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.pink-amber.dark .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(244, 143, 177, 0.3);
}
@media (hover: hover) {
  .pink-amber.dark .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(244, 143, 177, 0.3);
  }
}
.pink-amber.dark .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #424242;
  color: white;
}
.pink-amber.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(255, 224, 130, 0.2);
}
.pink-amber.dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.pink-amber.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.pink-amber.dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.pink-amber.dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 224, 130, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.pink-amber.dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.pink-amber.dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 224, 130, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.pink-amber.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.pink-amber.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.pink-amber.dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.pink-amber.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.pink-amber.dark .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #ffe082;
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 224, 130, 0.4);
}
.pink-amber.dark .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.pink-amber.dark .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.pink-amber.dark .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 224, 130, 0.3);
}
@media (hover: hover) {
  .pink-amber.dark .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(255, 224, 130, 0.3);
  }
}
.pink-amber.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(229, 115, 115, 0.2);
}
.pink-amber.dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.pink-amber.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.pink-amber.dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.pink-amber.dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(229, 115, 115, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.pink-amber.dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.pink-amber.dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(229, 115, 115, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.pink-amber.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.pink-amber.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.pink-amber.dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.pink-amber.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.pink-amber.dark .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #e57373;
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(229, 115, 115, 0.4);
}
.pink-amber.dark .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.pink-amber.dark .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.pink-amber.dark .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(229, 115, 115, 0.3);
}
@media (hover: hover) {
  .pink-amber.dark .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(229, 115, 115, 0.3);
  }
}
.pink-amber.dark .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.pink-amber.dark .mat-datepicker-toggle-active {
  color: #f48fb1;
}
.pink-amber.dark .mat-datepicker-toggle-active.mat-accent {
  color: #ffe082;
}
.pink-amber.dark .mat-datepicker-toggle-active.mat-warn {
  color: #e57373;
}
.pink-amber.dark .mat-date-range-input-inner[disabled] {
  color: rgba(255, 255, 255, 0.5);
}
.pink-amber.dark .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.pink-amber.dark .mat-divider {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.pink-amber.dark .mat-divider-vertical {
  border-right-color: rgba(255, 255, 255, 0.12);
}
.pink-amber.dark .mat-expansion-panel {
  background: #424242;
  color: white;
}
.pink-amber.dark .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.pink-amber.dark .mat-action-row {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.pink-amber.dark .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .pink-amber.dark .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .pink-amber.dark .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(255, 255, 255, 0.04);
}
@media (hover: none) {
  .pink-amber.dark .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: #424242;
  }
}
.pink-amber.dark .mat-expansion-panel-header-title {
  color: white;
}
.pink-amber.dark .mat-expansion-panel-header-description,
.pink-amber.dark .mat-expansion-indicator::after {
  color: rgba(255, 255, 255, 0.7);
}
.pink-amber.dark .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(255, 255, 255, 0.3);
}
.pink-amber.dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.pink-amber.dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.pink-amber.dark .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.pink-amber.dark .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.pink-amber.dark .mat-form-field.mat-focused .mat-form-field-label {
  color: #f48fb1;
}
.pink-amber.dark .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #ffe082;
}
.pink-amber.dark .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #e57373;
}
.pink-amber.dark .mat-focused .mat-form-field-required-marker {
  color: #ffe082;
}
.pink-amber.dark .mat-form-field-ripple {
  background-color: white;
}
.pink-amber.dark .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #f48fb1;
}
.pink-amber.dark .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #ffe082;
}
.pink-amber.dark .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #e57373;
}
.pink-amber.dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #f48fb1;
}
.pink-amber.dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #ffe082;
}
.pink-amber.dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #e57373;
}
.pink-amber.dark .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #e57373;
}
.pink-amber.dark .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.pink-amber.dark .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #e57373;
}
.pink-amber.dark .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.pink-amber.dark .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #e57373;
}
.pink-amber.dark .mat-error {
  color: #e57373;
}
.pink-amber.dark .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.pink-amber.dark .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.pink-amber.dark .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.pink-amber.dark .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.pink-amber.dark .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.pink-amber.dark .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.pink-amber.dark .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.1);
}
.pink-amber.dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.05);
}
.pink-amber.dark .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(255, 255, 255, 0.5);
}
.pink-amber.dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.pink-amber.dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.pink-amber.dark .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.3);
}
.pink-amber.dark .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: white;
}
.pink-amber.dark .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #f48fb1;
}
.pink-amber.dark .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #ffe082;
}
.pink-amber.dark .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #e57373;
}
.pink-amber.dark .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #e57373;
}
.pink-amber.dark .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.pink-amber.dark .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.15);
}
.pink-amber.dark .mat-icon.mat-primary {
  color: #f48fb1;
}
.pink-amber.dark .mat-icon.mat-accent {
  color: #ffe082;
}
.pink-amber.dark .mat-icon.mat-warn {
  color: #e57373;
}
.pink-amber.dark .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.7);
}
.pink-amber.dark .mat-input-element:disabled,
.pink-amber.dark .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.5);
}
.pink-amber.dark .mat-input-element {
  caret-color: #f48fb1;
}
.pink-amber.dark .mat-input-element::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.pink-amber.dark .mat-input-element::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.pink-amber.dark .mat-input-element::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.pink-amber.dark .mat-input-element:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.pink-amber.dark .mat-input-element:not(.mat-native-select-inline) option {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark .mat-input-element:not(.mat-native-select-inline) option:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.pink-amber.dark .mat-form-field.mat-accent .mat-input-element {
  caret-color: #ffe082;
}
.pink-amber.dark .mat-form-field.mat-warn .mat-input-element,
.pink-amber.dark .mat-form-field-invalid .mat-input-element {
  caret-color: #e57373;
}
.pink-amber.dark .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #e57373;
}
.pink-amber.dark .mat-list-base .mat-list-item {
  color: white;
}
.pink-amber.dark .mat-list-base .mat-list-option {
  color: white;
}
.pink-amber.dark .mat-list-base .mat-subheader {
  color: rgba(255, 255, 255, 0.7);
}
.pink-amber.dark .mat-list-base .mat-list-item-disabled {
  background-color: rgba(255, 255, 255, 0.12);
  color: rgba(255, 255, 255, 0.5);
}
.pink-amber.dark .mat-list-option:hover, .pink-amber.dark .mat-list-option:focus,
.pink-amber.dark .mat-nav-list .mat-list-item:hover,
.pink-amber.dark .mat-nav-list .mat-list-item:focus,
.pink-amber.dark .mat-action-list .mat-list-item:hover,
.pink-amber.dark .mat-action-list .mat-list-item:focus {
  background: rgba(255, 255, 255, 0.04);
}
.pink-amber.dark .mat-list-single-selected-option, .pink-amber.dark .mat-list-single-selected-option:hover, .pink-amber.dark .mat-list-single-selected-option:focus {
  background: rgba(255, 255, 255, 0.12);
}
.pink-amber.dark .mat-menu-panel {
  background: #424242;
}
.pink-amber.dark .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.pink-amber.dark .mat-menu-item {
  background: transparent;
  color: white;
}
.pink-amber.dark .mat-menu-item[disabled],
.pink-amber.dark .mat-menu-item[disabled] .mat-menu-submenu-icon,
.pink-amber.dark .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(255, 255, 255, 0.5);
}
.pink-amber.dark .mat-menu-item .mat-icon-no-color,
.pink-amber.dark .mat-menu-submenu-icon {
  color: white;
}
.pink-amber.dark .mat-menu-item:hover:not([disabled]),
.pink-amber.dark .mat-menu-item.cdk-program-focused:not([disabled]),
.pink-amber.dark .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.pink-amber.dark .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(255, 255, 255, 0.04);
}
.pink-amber.dark .mat-paginator {
  background: #424242;
}
.pink-amber.dark .mat-paginator,
.pink-amber.dark .mat-paginator-page-size .mat-select-trigger {
  color: rgba(255, 255, 255, 0.7);
}
.pink-amber.dark .mat-paginator-decrement,
.pink-amber.dark .mat-paginator-increment {
  border-top: 2px solid white;
  border-right: 2px solid white;
}
.pink-amber.dark .mat-paginator-first,
.pink-amber.dark .mat-paginator-last {
  border-top: 2px solid white;
}
.pink-amber.dark .mat-icon-button[disabled] .mat-paginator-decrement,
.pink-amber.dark .mat-icon-button[disabled] .mat-paginator-increment,
.pink-amber.dark .mat-icon-button[disabled] .mat-paginator-first,
.pink-amber.dark .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(255, 255, 255, 0.5);
}
.pink-amber.dark .mat-progress-bar-background {
  fill: #614850;
}
.pink-amber.dark .mat-progress-bar-buffer {
  background-color: #614850;
}
.pink-amber.dark .mat-progress-bar-fill::after {
  background-color: #f48fb1;
}
.pink-amber.dark .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #645c45;
}
.pink-amber.dark .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #645c45;
}
.pink-amber.dark .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #ffe082;
}
.pink-amber.dark .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #5d4141;
}
.pink-amber.dark .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #5d4141;
}
.pink-amber.dark .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #e57373;
}
.pink-amber.dark .mat-progress-spinner circle, .pink-amber.dark .mat-spinner circle {
  stroke: #f48fb1;
}
.pink-amber.dark .mat-progress-spinner.mat-accent circle, .pink-amber.dark .mat-spinner.mat-accent circle {
  stroke: #ffe082;
}
.pink-amber.dark .mat-progress-spinner.mat-warn circle, .pink-amber.dark .mat-spinner.mat-warn circle {
  stroke: #e57373;
}
.pink-amber.dark .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.7);
}
.pink-amber.dark .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f48fb1;
}
.pink-amber.dark .mat-radio-button.mat-primary .mat-radio-inner-circle,
.pink-amber.dark .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .pink-amber.dark .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .pink-amber.dark .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #f48fb1;
}
.pink-amber.dark .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ffe082;
}
.pink-amber.dark .mat-radio-button.mat-accent .mat-radio-inner-circle,
.pink-amber.dark .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .pink-amber.dark .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .pink-amber.dark .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #ffe082;
}
.pink-amber.dark .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e57373;
}
.pink-amber.dark .mat-radio-button.mat-warn .mat-radio-inner-circle,
.pink-amber.dark .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .pink-amber.dark .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .pink-amber.dark .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #e57373;
}
.pink-amber.dark .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.pink-amber.dark .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.5);
}
.pink-amber.dark .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.pink-amber.dark .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(255, 255, 255, 0.5);
}
.pink-amber.dark .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(255, 255, 255, 0.5);
}
.pink-amber.dark .mat-radio-button .mat-ripple-element {
  background-color: white;
}
.pink-amber.dark .mat-select-value {
  color: white;
}
.pink-amber.dark .mat-select-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.pink-amber.dark .mat-select-disabled .mat-select-value {
  color: rgba(255, 255, 255, 0.5);
}
.pink-amber.dark .mat-select-arrow {
  color: rgba(255, 255, 255, 0.7);
}
.pink-amber.dark .mat-select-panel {
  background: #424242;
}
.pink-amber.dark .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.pink-amber.dark .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(255, 255, 255, 0.12);
}
.pink-amber.dark .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #f48fb1;
}
.pink-amber.dark .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #ffe082;
}
.pink-amber.dark .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #e57373;
}
.pink-amber.dark .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #e57373;
}
.pink-amber.dark .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(255, 255, 255, 0.5);
}
.pink-amber.dark .mat-drawer-container {
  background-color: #303030;
  color: white;
}
.pink-amber.dark .mat-drawer {
  background-color: #424242;
  color: white;
}
.pink-amber.dark .mat-drawer.mat-drawer-push {
  background-color: #424242;
}
.pink-amber.dark .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.pink-amber.dark .mat-drawer-side {
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.pink-amber.dark .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.pink-amber.dark [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.pink-amber.dark [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.pink-amber.dark .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(189, 189, 189, 0.6);
}
.pink-amber.dark .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #ffe082;
}
.pink-amber.dark .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 224, 130, 0.54);
}
.pink-amber.dark .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #ffe082;
}
.pink-amber.dark .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #f48fb1;
}
.pink-amber.dark .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(244, 143, 177, 0.54);
}
.pink-amber.dark .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #f48fb1;
}
.pink-amber.dark .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #e57373;
}
.pink-amber.dark .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(229, 115, 115, 0.54);
}
.pink-amber.dark .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #e57373;
}
.pink-amber.dark .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: white;
}
.pink-amber.dark .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #bdbdbd;
}
.pink-amber.dark .mat-slide-toggle-bar {
  background-color: rgba(255, 255, 255, 0.5);
}
.pink-amber.dark .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.pink-amber.dark .mat-slider.mat-primary .mat-slider-track-fill,
.pink-amber.dark .mat-slider.mat-primary .mat-slider-thumb,
.pink-amber.dark .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #f48fb1;
}
.pink-amber.dark .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(244, 143, 177, 0.2);
}
.pink-amber.dark .mat-slider.mat-accent .mat-slider-track-fill,
.pink-amber.dark .mat-slider.mat-accent .mat-slider-thumb,
.pink-amber.dark .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #ffe082;
}
.pink-amber.dark .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(255, 224, 130, 0.2);
}
.pink-amber.dark .mat-slider.mat-warn .mat-slider-track-fill,
.pink-amber.dark .mat-slider.mat-warn .mat-slider-thumb,
.pink-amber.dark .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #e57373;
}
.pink-amber.dark .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(229, 115, 115, 0.2);
}
.pink-amber.dark .mat-slider:hover .mat-slider-track-background,
.pink-amber.dark .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.pink-amber.dark .mat-slider.mat-slider-disabled .mat-slider-track-background,
.pink-amber.dark .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.pink-amber.dark .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(255, 255, 255, 0.3);
}
.pink-amber.dark .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.pink-amber.dark .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(255, 255, 255, 0.12);
}
.pink-amber.dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.pink-amber.dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: white;
}
.pink-amber.dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.pink-amber.dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(255, 255, 255, 0.3);
}
.pink-amber.dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
  background-color: transparent;
}
.pink-amber.dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .pink-amber.dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.pink-amber.dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .pink-amber.dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.pink-amber.dark .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(255, 255, 255, 0.7);
}
.pink-amber.dark .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.pink-amber.dark .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.pink-amber.dark .mat-step-header.cdk-keyboard-focused, .pink-amber.dark .mat-step-header.cdk-program-focused, .pink-amber.dark .mat-step-header:hover:not([aria-disabled]), .pink-amber.dark .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(255, 255, 255, 0.04);
}
.pink-amber.dark .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .pink-amber.dark .mat-step-header:hover {
    background: none;
  }
}
.pink-amber.dark .mat-step-header .mat-step-label,
.pink-amber.dark .mat-step-header .mat-step-optional {
  color: rgba(255, 255, 255, 0.7);
}
.pink-amber.dark .mat-step-header .mat-step-icon {
  background-color: rgba(255, 255, 255, 0.7);
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark .mat-step-header .mat-step-icon-selected,
.pink-amber.dark .mat-step-header .mat-step-icon-state-done,
.pink-amber.dark .mat-step-header .mat-step-icon-state-edit {
  background-color: #f48fb1;
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark .mat-step-header.mat-accent .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark .mat-step-header.mat-accent .mat-step-icon-selected,
.pink-amber.dark .mat-step-header.mat-accent .mat-step-icon-state-done,
.pink-amber.dark .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #ffe082;
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark .mat-step-header.mat-warn .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark .mat-step-header.mat-warn .mat-step-icon-selected,
.pink-amber.dark .mat-step-header.mat-warn .mat-step-icon-state-done,
.pink-amber.dark .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #e57373;
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #e57373;
}
.pink-amber.dark .mat-step-header .mat-step-label.mat-step-label-active {
  color: white;
}
.pink-amber.dark .mat-step-header .mat-step-label.mat-step-label-error {
  color: #e57373;
}
.pink-amber.dark .mat-stepper-horizontal, .pink-amber.dark .mat-stepper-vertical {
  background-color: #424242;
}
.pink-amber.dark .mat-stepper-vertical-line::before {
  border-left-color: rgba(255, 255, 255, 0.12);
}
.pink-amber.dark .mat-horizontal-stepper-header::before,
.pink-amber.dark .mat-horizontal-stepper-header::after,
.pink-amber.dark .mat-stepper-horizontal-line {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.pink-amber.dark .mat-sort-header-arrow {
  color: #c6c6c6;
}
.pink-amber.dark .mat-tab-nav-bar,
.pink-amber.dark .mat-tab-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark .mat-tab-group-inverted-header .mat-tab-nav-bar,
.pink-amber.dark .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  border-bottom: none;
}
.pink-amber.dark .mat-tab-label, .pink-amber.dark .mat-tab-link {
  color: white;
}
.pink-amber.dark .mat-tab-label.mat-tab-disabled, .pink-amber.dark .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.pink-amber.dark .mat-tab-header-pagination-chevron {
  border-color: white;
}
.pink-amber.dark .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.5);
}
.pink-amber.dark .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.pink-amber.dark .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.pink-amber.dark .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-amber.dark .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-amber.dark .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-amber.dark .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .pink-amber.dark .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-amber.dark .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-amber.dark .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-amber.dark .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 187, 208, 0.3);
}
.pink-amber.dark .mat-tab-group.mat-primary .mat-ink-bar, .pink-amber.dark .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #f48fb1;
}
.pink-amber.dark .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .pink-amber.dark .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .pink-amber.dark .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .pink-amber.dark .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-amber.dark .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-amber.dark .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-amber.dark .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .pink-amber.dark .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-amber.dark .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-amber.dark .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-amber.dark .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 236, 179, 0.3);
}
.pink-amber.dark .mat-tab-group.mat-accent .mat-ink-bar, .pink-amber.dark .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #ffe082;
}
.pink-amber.dark .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .pink-amber.dark .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .pink-amber.dark .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .pink-amber.dark .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-amber.dark .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-amber.dark .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-amber.dark .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .pink-amber.dark .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-amber.dark .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-amber.dark .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-amber.dark .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.pink-amber.dark .mat-tab-group.mat-warn .mat-ink-bar, .pink-amber.dark .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #e57373;
}
.pink-amber.dark .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .pink-amber.dark .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .pink-amber.dark .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .pink-amber.dark .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-amber.dark .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-amber.dark .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-amber.dark .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .pink-amber.dark .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-amber.dark .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-amber.dark .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-amber.dark .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 187, 208, 0.3);
}
.pink-amber.dark .mat-tab-group.mat-background-primary > .mat-tab-header, .pink-amber.dark .mat-tab-group.mat-background-primary > .mat-tab-link-container, .pink-amber.dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .pink-amber.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .pink-amber.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .pink-amber.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #f48fb1;
}
.pink-amber.dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .pink-amber.dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .pink-amber.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .pink-amber.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .pink-amber.dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .pink-amber.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .pink-amber.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.pink-amber.dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.pink-amber.dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.pink-amber.dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.pink-amber.dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .pink-amber.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.pink-amber.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.pink-amber.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.pink-amber.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.pink-amber.dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .pink-amber.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.pink-amber.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.pink-amber.dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.pink-amber.dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.pink-amber.dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .pink-amber.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.pink-amber.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.pink-amber.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.pink-amber.dark .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-amber.dark .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-amber.dark .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-amber.dark .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .pink-amber.dark .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-amber.dark .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-amber.dark .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-amber.dark .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 236, 179, 0.3);
}
.pink-amber.dark .mat-tab-group.mat-background-accent > .mat-tab-header, .pink-amber.dark .mat-tab-group.mat-background-accent > .mat-tab-link-container, .pink-amber.dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .pink-amber.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .pink-amber.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .pink-amber.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #ffe082;
}
.pink-amber.dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .pink-amber.dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .pink-amber.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .pink-amber.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .pink-amber.dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .pink-amber.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .pink-amber.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.pink-amber.dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.pink-amber.dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.pink-amber.dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.pink-amber.dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .pink-amber.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.pink-amber.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.pink-amber.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.pink-amber.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.pink-amber.dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .pink-amber.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.pink-amber.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.pink-amber.dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.pink-amber.dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.pink-amber.dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .pink-amber.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.pink-amber.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.pink-amber.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.pink-amber.dark .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-amber.dark .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-amber.dark .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-amber.dark .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .pink-amber.dark .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-amber.dark .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-amber.dark .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-amber.dark .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.pink-amber.dark .mat-tab-group.mat-background-warn > .mat-tab-header, .pink-amber.dark .mat-tab-group.mat-background-warn > .mat-tab-link-container, .pink-amber.dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .pink-amber.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .pink-amber.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .pink-amber.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #e57373;
}
.pink-amber.dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .pink-amber.dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .pink-amber.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .pink-amber.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .pink-amber.dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .pink-amber.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .pink-amber.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.pink-amber.dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.pink-amber.dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.pink-amber.dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.pink-amber.dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .pink-amber.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.pink-amber.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.pink-amber.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.pink-amber.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.pink-amber.dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .pink-amber.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.pink-amber.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.pink-amber.dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.pink-amber.dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.pink-amber.dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .pink-amber.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.pink-amber.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.pink-amber.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.pink-amber.dark .mat-toolbar {
  background: #212121;
  color: white;
}
.pink-amber.dark .mat-toolbar.mat-primary {
  background: #f48fb1;
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark .mat-toolbar.mat-accent {
  background: #ffe082;
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark .mat-toolbar.mat-warn {
  background: #e57373;
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark .mat-toolbar .mat-form-field-underline,
.pink-amber.dark .mat-toolbar .mat-form-field-ripple,
.pink-amber.dark .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.pink-amber.dark .mat-toolbar .mat-form-field-label,
.pink-amber.dark .mat-toolbar .mat-focused .mat-form-field-label,
.pink-amber.dark .mat-toolbar .mat-select-value,
.pink-amber.dark .mat-toolbar .mat-select-arrow,
.pink-amber.dark .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.pink-amber.dark .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.pink-amber.dark .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.pink-amber.dark .mat-tree {
  background: #424242;
}
.pink-amber.dark .mat-tree-node,
.pink-amber.dark .mat-nested-tree-node {
  color: white;
}
.pink-amber.dark .mat-snack-bar-container {
  color: rgba(0, 0, 0, 0.87);
  background: #fafafa;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.pink-amber.dark .mat-simple-snackbar-action {
  color: inherit;
}
.pink-amber.dark *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.pink-amber.dark *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
.pink-amber.dark *::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}
.pink-amber.dark .toolbar-line.container-ou {
  background: #f48fb1;
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark .toolbar-line.container-persona {
  background: #f48fb1 !important;
}
.pink-amber.dark .toolbar-line.container-persona .persona-title {
  background: #f48fb1;
}
.pink-amber.dark .toolbar-line.container-persona .invisible-container.persona-selector {
  background: #f48fb1 !important;
}
.pink-amber.dark button.mat-icon-button:not([disabled]):hover {
  background: rgba(255, 255, 255, 0.04);
}
.pink-amber.dark .side-bar {
  background: black;
}
.pink-amber.dark .icon-stacked {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-amber.dark .icon-stacked mat-icon + mat-icon {
  text-shadow: -1px 1px 0px #f48fb1, 1px -1px 0px #f48fb1;
}
.pink-amber.dark .divider-vert {
  background: rgba(255, 255, 255, 0.12);
}
.pink-amber.dark .avatar {
  background: #c51162 !important;
}
.pink-amber.dark ngx-avatar .avatar-content {
  background: #c51162 !important;
}
.pink-amber.dark .mat-loading-window {
  background: #424242;
}
.pink-amber.dark .window-spinner {
  background: #424242;
}
.pink-amber.dark .load-window {
  background: #424242;
}
@media (min-width: 600px) and (max-width: 1300px) {
  .pink-amber.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .load-window,
.pink-amber.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .load-window.new {
    background: #424242 !important;
  }
  .pink-amber.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card {
    background: #424242 !important;
  }
  .pink-amber.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .inbox-container mat-toolbar {
    background: #f48fb1;
    color: rgba(0, 0, 0, 0.87);
  }
  .pink-amber.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .inbox-container mat-list-item.mat-list-item {
    border: 1px solid rgba(255, 255, 255, 0.12);
  }
  .pink-amber.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .detail {
    background: #424242 !important;
  }
  .pink-amber.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .detail mat-toolbar {
    background: #424242 !important;
  }
  .pink-amber.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .main.w-detail + .new-detail-container .load-window {
    background: #303030 !important;
  }
  .pink-amber.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .main.w-detail + .new-detail-container:before {
    background: rgba(0, 0, 0, 0.6);
  }
  .pink-amber.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .main.side-open + .side {
    border-left: 1px solid rgba(255, 255, 255, 0.12);
  }
  .pink-amber.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .side .container .mat-list-base {
    background: #424242 !important;
    border: 1px solid rgba(255, 255, 255, 0.12);
  }
  .pink-amber.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .new-table .mat-header-row,
.pink-amber.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .new-table .mat-table,
.pink-amber.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .document-table .mat-header-row,
.pink-amber.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .document-table .mat-table,
.pink-amber.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .status-table .mat-header-row,
.pink-amber.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .status-table .mat-table {
    background: #424242 !important;
  }
  .pink-amber.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .audit-filter .filter-container.is-floating {
    background: #303030 !important;
    border: 1px solid rgba(255, 255, 255, 0.12);
  }
  .pink-amber.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .audit-filter .filter-container.is-floating .form-body {
    background: #303030 !important;
  }
  .pink-amber.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .audit-result {
    background: #424242;
  }
  .pink-amber.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .audit-detail-container {
    background: #303030 !important;
  }
}
@media (max-width: 599px) {
  .pink-amber.dark app-document-detail .mat-toolbar {
    background: #c51162 !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .pink-amber.dark app-document-detail .mat-toolbar .mat-toolbar-row .toolbar-actions {
    background: #c51162 !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .pink-amber.dark .login-card {
    background: #424242 !important;
  }
  .pink-amber.dark .inbox-toolbar {
    background: #c51162 !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .pink-amber.dark .detail {
    background: #424242 url("../img/spinner_mobile.gif") no-repeat center center !important;
  }
  .pink-amber.dark .toolbar-line-container .toolbar-line:first-child {
    background: #c51162 !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .pink-amber.dark .toolbar-actions {
    background: #c51162 !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .pink-amber.dark mat-drawer.side-nav.enter {
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(255, 255, 255, 0.12) !important;
  }
  .pink-amber.dark .side-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0) !important;
  }
  .pink-amber.dark .filter {
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.55) 16%, rgba(0, 0, 0, 0) 100%) !important;
  }
  .pink-amber.dark button.side-open-mobile {
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .pink-amber.dark .side-nav .side-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0);
  }
  .pink-amber.dark .side-nav .filter {
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.55) 16%, rgba(0, 0, 0, 0) 100%);
  }
  .pink-amber.dark .side-nav .side-nav-container .links {
    background: #424242;
    border-top: 1px solid rgba(255, 255, 255, 0.12);
  }
  .pink-amber.dark .side-nav .side-nav-container .version {
    background: #424242 !important;
    border-top: 1px solid rgba(255, 255, 255, 0.12);
  }
  .pink-amber.dark app-file-document-view .view-container {
    background: #424242 !important;
  }
  .pink-amber.dark app-file-document-view .view-container .side-bar {
    background: #c51162 !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .pink-amber.dark app-file-document-view .view-container .view-content app-pdf-wrapper.floating {
    background: #424242;
  }
  .pink-amber.dark app-file-document-view .view-container .view-content .upload-indications {
    background: black;
  }
  .pink-amber.dark app-file-document-view .view-container .view-content app-file-document-sign .sign-cycle mat-horizontal-stepper.mat-stepper-horizontal {
    background-color: rgba(0, 0, 0, 0) !important;
  }
  .pink-amber.dark app-file-document-view .view-container .view-content app-file-document-sign .sign-cycle .sign-cycle-content .cycle-content-action .mat-ripple-element {
    background-color: rgba(255, 255, 255, 0.1) !important;
  }
  .pink-amber.dark app-pending .floating-container .inbox-container .inbox-toolbar .mat-toolbar-row .mat-avatar h3 {
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .pink-amber.dark app-pending .floating-container .detail {
    background: #424242 url("../img/spinner_mobile.gif") no-repeat center center !important;
  }
  .pink-amber.dark app-document-sign-massive .inbox-container .pending-container app-chapa.minimized .info-empty {
    background: #ffe082;
  }
  .pink-amber.dark app-document-sign-massive .inbox-container .pending-container app-chapa.minimized .info-empty > span h2 {
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .pink-amber.dark app-document-sign-massive .inbox-container .signer.mobile {
    border-top: 1px solid rgba(255, 255, 255, 0.12);
    background: #424242;
  }
  .pink-amber.dark app-document-sign-massive .detail app-file-document-detail .view-container .mat-toolbar .mat-toolbar-row .view-actions {
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .pink-amber.dark app-add-documentation .floating-container .employee-add-documentation .inbox-container .inbox-toolbar .mat-toolbar-row .mat-avatar h3 {
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .pink-amber.dark app-add-documentation .floating-container .employee-add-documentation .detail {
    background: #424242 url("../img/spinner_mobile.gif") no-repeat center center !important;
  }
  .pink-amber.dark app-add-documentation .floating-container .employee-add-documentation .detail app-file-document-view .view-container .view-content .new-container app-file-document-upload {
    background: #424242;
  }
}
.pink-amber.dark .mat-card .mat-drawer-container {
  background: #424242 !important;
}
.pink-amber.dark .mat-drawer-container {
  background: #212121 !important;
}
.pink-amber.dark .mat-list .mat-list-item.finished-process-inbox.active {
  background: #009688 !important;
}
.pink-amber.dark .mat-list .mat-list-item.finished-process-inbox .mat-icon {
  background: #009688;
}
.pink-amber.dark .mat-list .mat-list-item.erased-process-inbox.active {
  background: #837C80 !important;
}
.pink-amber.dark .mat-list .mat-list-item.limbo-process-inbox.active {
  background: #ffc107 !important;
}
.pink-amber.dark .mat-list .mat-list-item.limbo-process-inbox .mat-icon {
  background: #ffc107;
}
.pink-amber.dark .mat-list .mat-list-item.in-process-inbox.active {
  background: #ffc107 !important;
}
.pink-amber.dark .mat-list .mat-list-item.in-process-inbox .mat-icon {
  background: #ffc107;
}
.pink-amber.dark .mat-list .mat-list-item.error-process-inbox.active {
  background: #e91e63 !important;
}
.pink-amber.dark .mat-list .mat-list-item.error-process-inbox .mat-icon {
  background: #e91e63;
}
.pink-amber.dark .mat-list .mat-list-item.active .mat-line b {
  color: #f48fb1;
}
.pink-amber.dark .mat-list .mat-list-item.active .mat-list-avatar {
  color: #f48fb1;
}
.pink-amber.dark .mat-list .mat-list-item:hover:not(.active) {
  background: rgba(255, 255, 255, 0.04);
}
.pink-amber.dark span.mat-badge-content {
  color: #e57373 !important;
}
.pink-amber.dark mat-expansion-panel.mat-expanded {
  background: #303030 !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark mat-expansion-panel.virtual-scrolling .mat-expansion-panel-content .get-more {
  color: #f48fb1;
}
.pink-amber.dark mat-expansion-panel.virtual-scrolling .mat-expansion-panel-content app-cs-paginator.get-more {
  background: #424242;
}
.pink-amber.dark mat-expansion-panel-header.mat-expanded {
  background: #424242 !important;
}
.pink-amber.dark .bottom-container {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark .load-more * {
  color: #f48fb1;
}
.pink-amber.dark .welcome-card {
  background: #424242 !important;
}
.pink-amber.dark .welcome-card .img-divider {
  background: #c51162;
}
.pink-amber.dark .mat-header-row {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  background: #424242;
}
.pink-amber.dark .mat-row {
  background: #424242;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.pink-amber.dark .mat-row .mat-cell.status-cell mat-icon.pending {
  color: #ffb74d !important;
}
.pink-amber.dark .mat-row .mat-cell.status-cell mat-icon.ok {
  color: #4db6ac !important;
}
.pink-amber.dark .mat-row .mat-cell.status-cell mat-icon.not-ok {
  color: #ba68c8 !important;
}
.pink-amber.dark .mat-row .mat-cell.status-cell mat-icon.cancelled {
  color: #795548 !important;
}
.pink-amber.dark .mat-row .mat-cell.status-cell mat-icon.rejected {
  color: #df4d4c !important;
}
.pink-amber.dark .mat-row .mat-cell.status-cell mat-icon.draft {
  color: #dedede !important;
}
.pink-amber.dark .mat-row.active, .pink-amber.dark .mat-row.active:hover {
  background: #f48fb1 !important;
}
.pink-amber.dark .mat-row.active div, .pink-amber.dark .mat-row.active:hover div {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark .mat-row.active .mat-cell.status-cell mat-icon.pending, .pink-amber.dark .mat-row.active .mat-cell.status-cell mat-icon.ok, .pink-amber.dark .mat-row.active .mat-cell.status-cell mat-icon.not-ok, .pink-amber.dark .mat-row.active:hover .mat-cell.status-cell mat-icon.pending, .pink-amber.dark .mat-row.active:hover .mat-cell.status-cell mat-icon.ok, .pink-amber.dark .mat-row.active:hover .mat-cell.status-cell mat-icon.not-ok {
  text-shadow: -1px 1px 0 rgba(0, 0, 0, 0.87), 1px 1px 0 rgba(0, 0, 0, 0.87), 1px -1px 0 rgba(0, 0, 0, 0.87), -1px -1px 0 rgba(0, 0, 0, 0.87);
}
.pink-amber.dark .mat-row.active .sticky-actions button, .pink-amber.dark .mat-row.active:hover .sticky-actions button {
  background: #f48fb1 !important;
}
.pink-amber.dark .mat-row.active .sticky-actions button.ok mat-icon, .pink-amber.dark .mat-row.active .sticky-actions button.not-ok mat-icon, .pink-amber.dark .mat-row.active:hover .sticky-actions button.ok mat-icon, .pink-amber.dark .mat-row.active:hover .sticky-actions button.not-ok mat-icon {
  text-shadow: -1px 1px 0 rgba(0, 0, 0, 0.87), 1px 1px 0 rgba(0, 0, 0, 0.87), 1px -1px 0 rgba(0, 0, 0, 0.87), -1px -1px 0 rgba(0, 0, 0, 0.87);
}
.pink-amber.dark .mat-row:nth-child(odd) {
  background: #303030;
}
.pink-amber.dark .mat-row.selected div {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark .mat-row.selected:nth-child(even) {
  background: #f48fb1 !important;
}
.pink-amber.dark .mat-row.selected:nth-child(even) .sticky-actions button {
  background: #f48fb1 !important;
}
.pink-amber.dark .mat-row.selected:nth-child(odd) {
  background: #f8bbd0 !important;
}
.pink-amber.dark .mat-row.selected:nth-child(odd) .sticky-actions button {
  background: #f8bbd0 !important;
}
.pink-amber.dark .mat-row.selected.active, .pink-amber.dark .mat-row.selected.active:hover {
  background: #f48fb1 !important;
}
.pink-amber.dark .mat-row.selected.active .sticky-actions button, .pink-amber.dark .mat-row.selected.active:hover .sticky-actions button {
  background: #f48fb1 !important;
}
.pink-amber.dark .mat-row:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-amber.dark .mat-row:hover .sticky-actions button {
  background: #424242 !important;
}
.pink-amber.dark .mat-row.canceled * {
  color: #795548;
}
.pink-amber.dark .mat-row.disabled div {
  color: #607D8B;
}
.pink-amber.dark .mat-row.disabled.active, .pink-amber.dark .mat-row.disabled.active:hover {
  background: #607D8B !important;
}
.pink-amber.dark .mat-row.disabled.active div, .pink-amber.dark .mat-row.disabled.active:hover div {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark .mat-row.disabled.active .sticky-actions button, .pink-amber.dark .mat-row.disabled.active:hover .sticky-actions button {
  background: #607D8B !important;
}
.pink-amber.dark .mat-row.disabled.selected.active:hover .sticky-actions button {
  background: #607D8B !important;
}
.pink-amber.dark .mat-row .sticky-actions button.ok mat-icon {
  color: #4db6ac !important;
}
.pink-amber.dark .mat-row .sticky-actions button.not-ok mat-icon {
  color: #ba68c8 !important;
}
.pink-amber.dark .status-table .mat-header-row {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  background: #424242;
}
.pink-amber.dark .icon-info-off,
.pink-amber.dark .certificate-off {
  color: #607D8B;
}
.pink-amber.dark .documents-search-table .mat-header-row {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  background: #424242;
}
.pink-amber.dark mat-toolbar.employee-search-sub-toolbar {
  background: #424242;
}
.pink-amber.dark .mat-search-field {
  border: 1px solid rgba(255, 255, 255, 0.12);
  background: black;
}
.pink-amber.dark .mat-search-field input[type=search] {
  background: rgba(0, 0, 0, 0);
}
.pink-amber.dark .mat-search-field input[type=search]::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.pink-amber.dark .search-adv-menu .mat-search-field {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark mat-toolbar.document-search-sub-toolbar {
  background: #424242;
}
.pink-amber.dark app-advanced-employee-search .mat-chip {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-amber.dark .side-nav .side-trigger {
  background: #424242;
}
.pink-amber.dark .side-nav .menu-trigger mat-icon {
  color: rgba(255, 255, 255, 0.7);
  background: #424242;
}
.pink-amber.dark .side-nav .side-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0);
}
.pink-amber.dark .side-nav .filter {
  background: #424242;
}
.pink-amber.dark .side-nav .side-nav-container .links mat-icon span.mat-badge-content {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-amber.dark .side-nav .side-nav-container .mat-list-base .mat-list-item:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-amber.dark .side-nav .side-nav-container .mat-list-base .mat-list-item.active {
  background: #f48fb1 !important;
}
.pink-amber.dark .side-nav .side-nav-container .mat-list-base .mat-list-item.active * {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark .side-nav .side-nav-container .mat-list-base .mat-list-item.active .mat-list-item.active:hover {
  background: #f48fb1 !important;
}
.pink-amber.dark .side-nav .side-nav-container .mat-list-base .mat-list-item.active .mat-list-item.active:hover * {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark .side-nav.open .side-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark .side-nav.open .filter {
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.55) 16%, rgba(0, 0, 0, 0) 100%);
}
.pink-amber.dark .side-nav.open .side-nav-container .links {
  background: #424242;
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark .side-nav.open .side-nav-container .version {
  background: #424242 !important;
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark .side-nav.enter .side-nav-container .links {
  background: #424242;
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark .side-nav.enter .side-nav-container .version {
  background: #424242 !important;
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark .mat-menu-panel:not(.metadata-radio-menu) .navigation-list .mat-list-item-content {
  color: rgba(255, 255, 255, 0.7);
}
.pink-amber.dark table.mat-calendar-table {
  background: #424242 !important;
}
.pink-amber.dark .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-amber.dark .mat-calendar-arrow {
  border-top-color: #424242 !important;
}
.pink-amber.dark .mat-datepicker-content .mat-calendar-next-button,
.pink-amber.dark .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-amber.dark .mat-calendar-header {
  background: #c51162 !important;
}
.pink-amber.dark mat-datepicker-content {
  background-color: #c51162 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-amber.dark .mat-calendar-content {
  background: #424242 !important;
}
.pink-amber.dark .mat-simple-snackbar-action button.mat-button {
  color: #ff9800;
}
.pink-amber.dark .mat-simple-snackbar-action .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.pink-amber.dark mat-bottom-sheet-container mat-toolbar {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12) !important;
}
.pink-amber.dark app-generic-bottom-sheet h4.accent {
  color: #ffe082;
}
.pink-amber.dark app-generic-bottom-sheet .verification-code {
  background: #f48fb1;
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark app-generic-bottom-sheet .verification-code h2 {
  background: #f48fb1;
}
.pink-amber.dark app-generic-bottom-sheet .load-window.message .info-container h3.primary-text {
  color: #f48fb1;
}
.pink-amber.dark app-generic-bottom-sheet .bottom_sheet-content.ok .bottom_sheet-title .bottom_sheet-icon,
.pink-amber.dark app-generic-bottom-sheet .bottom_sheet-content.ok .bottom_sheet-title h3 {
  color: #009688;
}
.pink-amber.dark app-generic-bottom-sheet .bottom_sheet-content.ok .bottom_sheet-main_btn:not(:disabled) {
  background: #009688;
  color: #fff;
}
.pink-amber.dark app-generic-bottom-sheet .bottom_sheet-content.not-ok .bottom_sheet-title .bottom_sheet-icon,
.pink-amber.dark app-generic-bottom-sheet .bottom_sheet-content.not-ok .bottom_sheet-title h3 {
  color: #9C27B0;
}
.pink-amber.dark app-generic-bottom-sheet .bottom_sheet-content.not-ok .bottom_sheet-main_btn:not(:disabled) {
  background: #9C27B0;
  color: #fff;
}
.pink-amber.dark app-generic-bottom-sheet .bottom_sheet-content.cancel .bottom_sheet-title .bottom_sheet-icon,
.pink-amber.dark app-generic-bottom-sheet .bottom_sheet-content.cancel .bottom_sheet-title h3 {
  color: #795548;
}
.pink-amber.dark app-generic-bottom-sheet .bottom_sheet-content.cancel .bottom_sheet-main_btn:not(:disabled) {
  background: #795548;
  color: #fff;
}
.pink-amber.dark app-generic-bottom-sheet .bottom_sheet-content .bottom_sheet_exp_btn {
  background-color: #f48fb1;
  color: #fff;
}
.pink-amber.dark app-generic-bottom-sheet .bottom_sheet-content .bottom_sheet_canc_btn {
  background-color: lightgrey;
  color: black;
}
.pink-amber.dark app-generic-bottom-sheet .bottom_sheet-content .bottom_sheet-exp-h3 {
  color: #f48fb1;
  margin-bottom: -10px !important;
}
.pink-amber.dark app-generic-bottom-sheet .bottom_sheet-content .mat-input-element-export {
  display: flex !important;
  padding-left: 15px;
}
.pink-amber.dark app-generic-bottom-sheet .bottom_sheet-content .mat-form-field-infix-export-right {
  width: 100% !important;
  padding: 0px !important;
  display: flex !important;
}
.pink-amber.dark app-generic-bottom-sheet .bottom_sheet-content .mat-form-field-infix-export-left {
  width: 100% !important;
  padding: 0px;
  display: flex !important;
}
.pink-amber.dark app-generic-bottom-sheet .bottom_sheet-content .mat-form-field-infix {
  display: flex;
}
.pink-amber.dark app-generic-bottom-sheet .bottom_sheet-content .mat-checkbox-layout .mat-checkbox-label {
  color: #ffe082;
}
.pink-amber.dark app-generic-bottom-sheet .bottom_sheet-content .choose-forms {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  margin-left: 10px !important;
}
.pink-amber.dark app-generic-bottom-sheet .bottom_sheet-content .card-container {
  max-height: 150px;
  overflow-y: auto;
}
.pink-amber.dark .mat-list .mat-list-item.active .mat-line {
  color: white;
}
.pink-amber.dark .mat-list .mat-list-item.active .mat-line b {
  color: #f48fb1;
}
.pink-amber.dark .mat-list .mat-list-item.active .mat-list-avatar {
  color: #f48fb1;
}
.pink-amber.dark .mat-list .mat-list-item .mat-line {
  color: rgba(255, 255, 255, 0.7);
}
.pink-amber.dark .mat-list .mat-list-item .mat-list-avatar {
  color: rgba(255, 255, 255, 0.7);
}
.pink-amber.dark .mat-list-item:hover:not(.active) {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-amber.dark mat-list-item:nth-child(even),
.pink-amber.dark app-file-document-inbox-item:nth-child(even) mat-list-item,
.pink-amber.dark app-file-document-inbox-item-documentation:nth-child(even) mat-list-item {
  background: #303030 !important;
}
.pink-amber.dark mat-list-item:nth-child(odd),
.pink-amber.dark app-file-document-inbox-item:nth-child(odd) mat-list-item,
.pink-amber.dark app-file-document-inbox-item-documentation:nth-child(odd) mat-list-item {
  background: #424242 !important;
}
.pink-amber.dark .mat-list-avatar.mat-icon {
  background: black;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark .card-filter {
  background: #424242;
}
.pink-amber.dark .login mat-horizontal-stepper {
  background: #424242 !important;
}
.pink-amber.dark .login .login-card {
  background: #424242 !important;
}
.pink-amber.dark .login .login-card .mat-card-content .mat-card-footer a[color=accent] {
  color: #ffe082;
}
.pink-amber.dark .login .help-stepper h3.align-16 {
  color: #ffe082;
}
.pink-amber.dark .login .help-stepper .help-options mat-icon {
  color: #ffe082;
}
.pink-amber.dark .ux-modal {
  background: rgba(0, 0, 0, 0.32);
}
.pink-amber.dark .ux-modal .card {
  background: #424242;
}
.pink-amber.dark .main-title {
  background: #607D8B;
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark .sub-title {
  color: rgba(255, 255, 255, 0.7);
}
.pink-amber.dark .card-footer a {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark .profile-toolbar {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark app-profile .logout-btn:hover {
  background: black;
}
.pink-amber.dark app-profile .cropper {
  background: #424242;
}
.pink-amber.dark app-profile .cropper .cropper {
  background: unset;
}
.pink-amber.dark app-add-documentation .employee-add-documentation .inbox-toolbar {
  background: #303030;
}
.pink-amber.dark app-add-documentation .employee-add-documentation .inbox-toolbar .toolbar-line mat-icon {
  color: #f48fb1;
}
.pink-amber.dark app-add-documentation .employee-add-documentation .inbox-toolbar .toolbar-line h3 {
  color: #f48fb1;
}
.pink-amber.dark app-add-documentation .employee-add-documentation mat-tab-group {
  background: #303030;
}
.pink-amber.dark app-add-documentation .employee-add-documentation mat-tab-group mat-tab-header .mat-tab-label-active {
  color: #f48fb1;
}
.pink-amber.dark app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content:hover {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(255, 255, 255, 0.12) !important;
}
.pink-amber.dark app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content.selected {
  border-left: 6px solid #f48fb1 !important;
}
.pink-amber.dark app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content.selected mat-icon {
  color: #f48fb1 !important;
}
.pink-amber.dark app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content.selected .item-data {
  color: white !important;
}
.pink-amber.dark app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content mat-icon {
  color: rgba(255, 255, 255, 0.7);
}
.pink-amber.dark app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content .item-data {
  color: rgba(255, 255, 255, 0.7);
}
.pink-amber.dark app-add-documentation .employee-add-documentation .detail app-file-document-view .new-container app-file-document-upload .metadata-list .mat-subheader {
  color: rgba(255, 255, 255, 0.7);
  background: #424242 !important;
}
.pink-amber.dark app-pending .inbox-toolbar {
  background: #303030;
}
.pink-amber.dark app-pending .pending-container {
  background: #303030;
}
.pink-amber.dark app-pending .pending-container .pending-items .pending-item .content:hover {
  background: rgba(255, 255, 255, 0.04) !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(255, 255, 255, 0.12) !important;
}
.pink-amber.dark app-pending .pending-container .pending-items .pending-item .content.selected {
  border-left: 6px solid #ffe082 !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(255, 255, 255, 0.12) !important;
}
.pink-amber.dark app-pending .pending-container .pending-items .pending-item .content.selected mat-icon {
  color: #ffe082 !important;
}
.pink-amber.dark app-pending .pending-container .pending-items .pending-item .content.selected .item-data {
  color: white !important;
}
.pink-amber.dark app-pending .pending-container .pending-items .pending-item .content mat-icon {
  color: rgba(255, 255, 255, 0.7);
}
.pink-amber.dark app-pending .pending-container .pending-items .pending-item .content .item-data {
  color: rgba(255, 255, 255, 0.7);
}
.pink-amber.dark app-document-sign-massive .inbox-container .inbox-toolbar {
  background: #303030;
}
.pink-amber.dark app-document-sign-massive .inbox-container .pending-container {
  background: #303030;
}
.pink-amber.dark app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content:hover {
  background: rgba(255, 255, 255, 0.04) !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(255, 255, 255, 0.12) !important;
}
.pink-amber.dark app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content.selected {
  border-left: 6px solid #ffe082 !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(255, 255, 255, 0.12) !important;
}
.pink-amber.dark app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content.selected mat-icon {
  color: #ffe082 !important;
}
.pink-amber.dark app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content.selected .item-data {
  color: white !important;
}
.pink-amber.dark app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content mat-icon {
  color: rgba(255, 255, 255, 0.7);
}
.pink-amber.dark app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content .item-data {
  color: rgba(255, 255, 255, 0.7);
}
.pink-amber.dark app-document-sign-massive .detail app-file-document-detail .view-container {
  background: #607D8B;
}
.pink-amber.dark app-document-sign-massive .detail app-file-document-detail .view-container .mat-toolbar {
  background: #607D8B;
  color: rgba(0, 0, 0, 0.87);
  background: #607D8B;
}
.pink-amber.dark app-document-sign-massive .detail app-chapa.minimized .info-empty {
  background: #ffe082;
}
.pink-amber.dark app-document-sign-massive .detail app-chapa.minimized .info-empty > span mat-icon {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-amber.dark app-document-sign-massive .detail app-chapa.minimized .info-empty > span h2 {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-amber.dark app-document-sign-massive .signer.desktop {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark app-document-sign-massive .signer .signer-btn {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-amber.dark app-document-sign-massive .signer .signer-btn[disabled] {
  background-color: rgba(255, 255, 255, 0.12);
}
.pink-amber.dark app-document-detail app-sign-document .firmador button.mat-raised-button.mat-accent {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-amber.dark mat-toolbar-row.firmado-conforme .mat-icon {
  background: #4caf50;
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark mat-toolbar-row.firmado-no-conforme .mat-icon {
  background: #e57373;
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark .secondary-toolbar {
  background: #424242;
}
.pink-amber.dark .new-detail-container {
  border-left: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark .new-detail-container.cancelled .detail_head-body {
  background: #607D8B !important;
}
.pink-amber.dark .new-detail-container .employee-detail-toolbar {
  background: black;
}
.pink-amber.dark .new-detail-container .employee-detail-subbar {
  background: #424242;
}
.pink-amber.dark app-employee-detail .load-window,
.pink-amber.dark app-employee-rrhh-detail .load-window {
  background: #303030;
}
.pink-amber.dark app-employee-detail .employee_detail-container,
.pink-amber.dark app-employee-rrhh-detail .employee_detail-container {
  background: #303030;
}
.pink-amber.dark app-employee-detail .employee_detail-container.is-editing .edition-line,
.pink-amber.dark app-employee-rrhh-detail .employee_detail-container.is-editing .edition-line {
  background: black;
}
.pink-amber.dark app-employee-detail .employee_detail-container .detail_head-body,
.pink-amber.dark app-employee-rrhh-detail .employee_detail-container .detail_head-body {
  background: #f48fb1;
}
.pink-amber.dark app-employee-detail .employee_detail-container .detail_head-body .head_body-status .icon-stacked + mat-icon,
.pink-amber.dark app-employee-rrhh-detail .employee_detail-container .detail_head-body .head_body-status .icon-stacked + mat-icon {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-amber.dark app-employee-detail .employee_detail-container .detail_head-body .head_body-title .body_title-name,
.pink-amber.dark app-employee-rrhh-detail .employee_detail-container .detail_head-body .head_body-title .body_title-name {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark app-employee-detail .employee_detail-container .detail_head-body .head_body-title .body_title-user,
.pink-amber.dark app-employee-rrhh-detail .employee_detail-container .detail_head-body .head_body-title .body_title-user {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark app-employee-detail .employee_detail-container .data .data-line,
.pink-amber.dark app-employee-rrhh-detail .employee_detail-container .data .data-line {
  background: #424242;
}
.pink-amber.dark app-employee-detail .employee_detail-container .data .data-line .icon-complex mat-icon.complex,
.pink-amber.dark app-employee-rrhh-detail .employee_detail-container .data .data-line .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #424242, 0 -2px 0px #424242, 2px 0 0px #424242, 0 2px 0px #424242, -2px -2px 0px #424242, 2px 2px 0px #424242, 2px -2px 0px #424242, -2px 2px 0px #424242;
}
.pink-amber.dark app-employee-detail .employee_detail-container .data .data-line .line_text-main,
.pink-amber.dark app-employee-rrhh-detail .employee_detail-container .data .data-line .line_text-main {
  color: #f48fb1;
}
.pink-amber.dark app-employee-detail .employee_detail-container .data .data-line.clickable:hover,
.pink-amber.dark app-employee-rrhh-detail .employee_detail-container .data .data-line.clickable:hover {
  background: #303030 !important;
}
.pink-amber.dark app-employee-detail .employee_detail-container .data .data-line button.mat-icon-button,
.pink-amber.dark app-employee-rrhh-detail .employee_detail-container .data .data-line button.mat-icon-button {
  background: black;
}
.pink-amber.dark app-employee-detail .employee_detail-container .employee-title .title-text,
.pink-amber.dark app-employee-rrhh-detail .employee_detail-container .employee-title .title-text {
  color: #f48fb1;
}
.pink-amber.dark app-employee-detail .employee_detail-container .employee-title .title-text mat-icon,
.pink-amber.dark app-employee-rrhh-detail .employee_detail-container .employee-title .title-text mat-icon {
  color: #f48fb1;
}
.pink-amber.dark app-employee-detail .employee_detail-container .employee-title .title-text .title_text-main,
.pink-amber.dark app-employee-rrhh-detail .employee_detail-container .employee-title .title-text .title_text-main {
  color: #f48fb1;
}
.pink-amber.dark .UI-V2 .icon-complex mat-icon.complex {
  color: #f48fb1;
  text-shadow: -2px 0 0px #424242, 0 -2px 0px #424242, 2px 0 0px #424242, 0 2px 0px #424242, -2px -2px 0px #424242, 2px 2px 0px #424242, 2px -2px 0px #424242, -2px 2px 0px #424242;
}
.pink-amber.dark .UI-V2 .icon-complex mat-icon.complex.mat-accent {
  color: #ffe082;
}
.pink-amber.dark .UI-V2 .main .toolbar .header .content .module-title {
  color: #f48fb1;
}
.pink-amber.dark .UI-V2 .main .toolbar .header .content .module-title.selectable:hover {
  background: rgba(255, 255, 255, 0.04);
}
.pink-amber.dark .UI-V2 .main .toolbar .header .content .module-title .info h5 {
  color: white !important;
}
.pink-amber.dark .UI-V2 .main .toolbar .header .content .ou-select {
  color: rgba(0, 0, 0, 0.87);
  background: #f48fb1;
}
.pink-amber.dark .UI-V2 .main .toolbar .search-container .search .content {
  background: #303030;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark .UI-V2 .main .toolbar .search-container .search .content:hover, .pink-amber.dark .UI-V2 .main .toolbar .search-container .search .content:active, .pink-amber.dark .UI-V2 .main .toolbar .search-container .search .content:focus, .pink-amber.dark .UI-V2 .main .toolbar .search-container .search .content:focus-within {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-amber.dark .UI-V2 .main .toolbar .search-container .search .content input {
  color: white;
}
.pink-amber.dark .UI-V2 .main .helper {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark .UI-V2 .main .helper mat-hint h4 {
  color: #f48fb1;
}
.pink-amber.dark .UI-V2 .main .new-table app-cs-grid-control datatable-header * {
  color: #f48fb1;
}
.pink-amber.dark .UI-V2 .main .new-table .mat-header-row.sticky * {
  color: #f48fb1 !important;
}
.pink-amber.dark .UI-V2 .main .main-action {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark .menu-options.help .primary {
  color: #f48fb1;
}
.pink-amber.dark .menu-options.help .accent {
  color: #ffe082;
}
.pink-amber.dark .menu-options .icon-complex mat-icon.complex {
  color: #f48fb1;
  text-shadow: -2px 0 0px #424242, 0 -2px 0px #424242, 2px 0 0px #424242, 0 2px 0px #424242, -2px -2px 0px #424242, 2px 2px 0px #424242, 2px -2px 0px #424242, -2px 2px 0px #424242;
}
.pink-amber.dark .menu-options .mat-menu-content .container mat-list .mat-list-item:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-amber.dark .menu-options .mat-menu-content .container mat-list .mat-list-item .disabled {
  color: rgba(255, 255, 255, 0.7) !important;
}
.pink-amber.dark .menu-options .mat-menu-content .container .slide-group mat-radio-group mat-radio-button:hover {
  background: rgba(255, 255, 255, 0.04);
}
.pink-amber.dark .menu-options .mat-menu-content .container .slide-group mat-form-field .mat-form-field-flex {
  background: #303030;
}
.pink-amber.dark .menu-options .mat-menu-content .container .slide-group mat-slide-toggle:hover {
  background: rgba(255, 255, 255, 0.04);
}
.pink-amber.dark .mat-menu-panel.notifications-panel .mat-menu-content .notifications-body {
  background: #303030;
}
.pink-amber.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification .primary mat-icon {
  color: #f48fb1;
}
.pink-amber.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification .primary h3 {
  color: #f48fb1;
}
.pink-amber.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.top {
  background: #303030;
}
.pink-amber.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.top .header h3 {
  color: white;
}
.pink-amber.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.bottom {
  background: #303030;
}
.pink-amber.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable {
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable:nth-child(even) {
  background: #303030;
}
.pink-amber.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable:nth-child(odd) {
  background: #424242;
}
.pink-amber.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable:hover {
  background: rgba(255, 255, 255, 0.04);
}
.pink-amber.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable mat-icon {
  color: #f48fb1;
}
.pink-amber.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable h3 {
  color: white;
}
.pink-amber.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable h5 {
  color: rgba(255, 255, 255, 0.5);
}
.pink-amber.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable .subtitle h5 {
  color: #f48fb1;
  background: black;
}
.pink-amber.dark .info-empty .large-icon {
  background: #f06292;
  color: #f48fb1;
}
.pink-amber.dark .info-empty h2,
.pink-amber.dark .info-empty h3 {
  color: #f48fb1;
}
.pink-amber.dark .info-empty h4,
.pink-amber.dark .info-empty h5 {
  color: rgba(255, 255, 255, 0.7);
}
.pink-amber.dark .info-empty.accent mat-icon {
  background: #ffe082;
  color: #ffe082;
}
.pink-amber.dark .info-empty.accent h2 {
  color: #ffe082;
}
.pink-amber.dark .info-empty .actions {
  padding: 16px;
  height: 96px;
  column-gap: 16px;
}
.pink-amber.dark .info-empty .actions button {
  height: 100%;
  border-radius: 40px;
}
.pink-amber.dark .info-empty .actions button.ok {
  background: #4db6ac !important;
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark .info-empty .actions button.not-ok {
  color: #ba68c8 !important;
}
.pink-amber.dark .side.edition .info-empty .large-icon {
  background: #ffe082;
  color: #ffe082;
}
.pink-amber.dark .side.edition .info-empty h2 {
  color: #ffe082;
}
.pink-amber.dark .side.edition .info-empty h4 {
  color: rgba(255, 255, 255, 0.7);
}
.pink-amber.dark .UI-V2 .deferred-process-list .header h3 {
  color: #f48fb1;
}
.pink-amber.dark .UI-V2 .deferred-process-list .search-container .search .content {
  background: #303030;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark .UI-V2 .deferred-process-list .search-container .search .content:hover, .pink-amber.dark .UI-V2 .deferred-process-list .search-container .search .content:active, .pink-amber.dark .UI-V2 .deferred-process-list .search-container .search .content:focus, .pink-amber.dark .UI-V2 .deferred-process-list .search-container .search .content:focus-within {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-amber.dark .UI-V2 .deferred-process-list .search-container .search .content input {
  color: white;
}
.pink-amber.dark .UI-V2 .deferred-process-list .text-success,
.pink-amber.dark .UI-V2 .deferred-process-details .text-success {
  color: #4caf50;
}
.pink-amber.dark .UI-V2 .deferred-process-list .text-warn,
.pink-amber.dark .UI-V2 .deferred-process-details .text-warn {
  color: #e57373;
}
.pink-amber.dark .UI-V2 .deferred-process-list .text-primary,
.pink-amber.dark .UI-V2 .deferred-process-details .text-primary {
  color: #f48fb1;
}
.pink-amber.dark .UI-V2 .deferred-process-list .text-atention,
.pink-amber.dark .UI-V2 .deferred-process-details .text-atention {
  color: #DB7703;
}
.pink-amber.dark .UI-V2 .deferred-process-list .table-paginator-container,
.pink-amber.dark .UI-V2 .deferred-process-details .table-paginator-container {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
}
.pink-amber.dark .UI-V2 .deferred-process-list .table-paginator-container .mat-table,
.pink-amber.dark .UI-V2 .deferred-process-details .table-paginator-container .mat-table {
  border-bottom: none;
  border-radius: 10px 10px 0 0;
}
.pink-amber.dark .UI-V2 .deferred-process-list .table-paginator-container .paginator-container,
.pink-amber.dark .UI-V2 .deferred-process-details .table-paginator-container .paginator-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: #f2f2f2;
  padding: 0 1.5rem;
  height: 3rem;
}
.pink-amber.dark .UI-V2 .deferred-process-list .mat-table,
.pink-amber.dark .UI-V2 .deferred-process-details .mat-table {
  border: 1px solid #f2f2f2;
  border-radius: 10px;
  overflow: hidden;
}
.pink-amber.dark .UI-V2 .deferred-process-list .mat-table .mat-header-row .mat-header-cell,
.pink-amber.dark .UI-V2 .deferred-process-details .mat-table .mat-header-row .mat-header-cell {
  font-size: 14px;
  font-weight: 700;
}
.pink-amber.dark .UI-V2 .deferred-process-list .mat-table .mat-header-row,
.pink-amber.dark .UI-V2 .deferred-process-details .mat-table .mat-header-row {
  background: #f2f2f2;
}
.pink-amber.dark .UI-V2 .deferred-process-list .mat-table .mat-header-row .mat-header-cell,
.pink-amber.dark .UI-V2 .deferred-process-details .mat-table .mat-header-row .mat-header-cell {
  color: #f48fb1;
}
.pink-amber.dark .UI-V2 .deferred-process-list .mat-table .mat-row,
.pink-amber.dark .UI-V2 .deferred-process-details .mat-table .mat-row {
  background: #424242;
}
.pink-amber.dark .UI-V2 .progress-bar-container {
  position: relative;
}
.pink-amber.dark .UI-V2 .deferred-process-details .header h3 {
  color: #f48fb1;
}
.pink-amber.dark .UI-V2 .deferred-process-details .header .back-link:visited {
  color: #f48fb1;
}
.pink-amber.dark .process-card,
.pink-amber.dark .process-card-detail,
.pink-amber.dark .process-card-stages {
  background: #303030;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark .process-card mat-card-subtitle,
.pink-amber.dark .process-card-detail mat-card-subtitle,
.pink-amber.dark .process-card-stages mat-card-subtitle {
  color: white;
}
.pink-amber.dark .process-card .stages-container .stage.completed mat-icon,
.pink-amber.dark .process-card-detail .stages-container .stage.completed mat-icon,
.pink-amber.dark .process-card-stages .stages-container .stage.completed mat-icon {
  color: #499719;
}
.pink-amber.dark .process-card .stages-container .stage.completed .icon-container,
.pink-amber.dark .process-card-detail .stages-container .stage.completed .icon-container,
.pink-amber.dark .process-card-stages .stages-container .stage.completed .icon-container {
  background-color: rgba(78, 160, 28, 0.1490196078);
}
.pink-amber.dark .process-card .stages-container .stage.completed .line,
.pink-amber.dark .process-card-detail .stages-container .stage.completed .line,
.pink-amber.dark .process-card-stages .stages-container .stage.completed .line {
  background-color: #499719;
}
.pink-amber.dark .process-card .stages-container .stage.on-going mat-icon,
.pink-amber.dark .process-card-detail .stages-container .stage.on-going mat-icon,
.pink-amber.dark .process-card-stages .stages-container .stage.on-going mat-icon {
  color: #f48fb1;
}
.pink-amber.dark .process-card .stages-container .stage.on-going .icon-container,
.pink-amber.dark .process-card-detail .stages-container .stage.on-going .icon-container,
.pink-amber.dark .process-card-stages .stages-container .stage.on-going .icon-container {
  background-color: rgba(66, 48, 159, 0.2);
}
.pink-amber.dark .process-card .stages-container .stage.on-going .line,
.pink-amber.dark .process-card-detail .stages-container .stage.on-going .line,
.pink-amber.dark .process-card-stages .stages-container .stage.on-going .line {
  background-color: #949494;
}
.pink-amber.dark .process-card .stages-container .stage.pending,
.pink-amber.dark .process-card-detail .stages-container .stage.pending,
.pink-amber.dark .process-card-stages .stages-container .stage.pending {
  color: #949494;
}
.pink-amber.dark .process-card .stages-container .stage.pending mat-icon,
.pink-amber.dark .process-card-detail .stages-container .stage.pending mat-icon,
.pink-amber.dark .process-card-stages .stages-container .stage.pending mat-icon {
  color: #949494;
}
.pink-amber.dark .process-card .stages-container .stage.pending .icon-container,
.pink-amber.dark .process-card-detail .stages-container .stage.pending .icon-container,
.pink-amber.dark .process-card-stages .stages-container .stage.pending .icon-container {
  background-color: rgba(174, 174, 174, 0.2);
}
.pink-amber.dark .process-card .stages-container .stage.pending .line,
.pink-amber.dark .process-card-detail .stages-container .stage.pending .line,
.pink-amber.dark .process-card-stages .stages-container .stage.pending .line {
  background-color: #949494;
}
.pink-amber.dark .process-card .stages-container .stage.has-error mat-icon,
.pink-amber.dark .process-card-detail .stages-container .stage.has-error mat-icon,
.pink-amber.dark .process-card-stages .stages-container .stage.has-error mat-icon {
  color: #e57373;
}
.pink-amber.dark .process-card .stages-container .stage.has-error .icon-container,
.pink-amber.dark .process-card-detail .stages-container .stage.has-error .icon-container,
.pink-amber.dark .process-card-stages .stages-container .stage.has-error .icon-container {
  background-color: rgba(188, 4, 4, 0.2);
}
.pink-amber.dark .process-card .stages-container .stage-description .button-link,
.pink-amber.dark .process-card-detail .stages-container .stage-description .button-link,
.pink-amber.dark .process-card-stages .stages-container .stage-description .button-link {
  color: #f48fb1;
}
.pink-amber.dark app-custom-search-input .custom-search-container {
  background: #303030;
  border: 1px solid rgba(255, 255, 255, 0.12);
  transition: color 0.1s ease, background 0.15s ease;
}
.pink-amber.dark app-custom-search-input .custom-search-container mat-icon.mat-icon {
  color: rgba(255, 255, 255, 0.5);
}
.pink-amber.dark app-custom-search-input .custom-search-container input.custom-search-input {
  color: white;
  background: unset;
}
.pink-amber.dark app-custom-search-input .custom-search-container:hover, .pink-amber.dark app-custom-search-input .custom-search-container:active, .pink-amber.dark app-custom-search-input .custom-search-container:focus, .pink-amber.dark app-custom-search-input .custom-search-container:focus-within {
  background: rgba(255, 255, 255, 0.04) !important;
  transition: background 0.15s ease;
}
.pink-amber.dark .UI-V2 .audit-filter {
  background: #424242;
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark .UI-V2 .audit-filter .toolbar {
  background: #f48fb1;
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark .UI-V2 .audit-filter .toolbar .header .content .module-title {
  color: #f48fb1;
}
.pink-amber.dark .UI-V2 .audit-filter .toolbar .header .content .module-title.selectable:hover {
  background: rgba(255, 255, 255, 0.04);
}
.pink-amber.dark .UI-V2 .audit-filter .toolbar .header .content .module-title .info * {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-amber.dark .UI-V2 .audit-filter .toolbar .header .content .ou-select {
  color: rgba(0, 0, 0, 0.87);
  background: #f48fb1;
}
.pink-amber.dark .UI-V2 .audit-filter .toolbar .search-container .search .content {
  background: #303030;
}
.pink-amber.dark .UI-V2 .audit-filter .toolbar .search-container .search .content:hover, .pink-amber.dark .UI-V2 .audit-filter .toolbar .search-container .search .content:active, .pink-amber.dark .UI-V2 .audit-filter .toolbar .search-container .search .content:focus, .pink-amber.dark .UI-V2 .audit-filter .toolbar .search-container .search .content:focus-within {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-amber.dark .UI-V2 .audit-filter .toolbar .search-container .search .content input {
  color: white;
}
.pink-amber.dark .UI-V2 .audit-filter .filter-container {
  background: #424242 !important;
}
.pink-amber.dark .UI-V2 .audit-filter .filter-container .form-body {
  background: #303030 !important;
}
.pink-amber.dark .UI-V2 .audit-filter .filter-container .form-body .line h4 {
  color: #f48fb1;
}
.pink-amber.dark .UI-V2 .audit-filter .filter-container .form-body .line mat-icon {
  color: #f48fb1;
}
.pink-amber.dark .UI-V2 .audit-result .result-container .toolbar {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark .UI-V2 .audit-result .result-container .toolbar .header .content .module-title {
  color: #f48fb1;
}
.pink-amber.dark .UI-V2 .audit-result .result-container .toolbar .header .content .module-title.selectable:hover {
  background: rgba(255, 255, 255, 0.04);
}
.pink-amber.dark .UI-V2 .audit-result .result-container .toolbar .header .content .module-title .info h5 {
  color: white !important;
}
.pink-amber.dark .UI-V2 .audit-result .result-container .toolbar .header .content .ou-select {
  color: rgba(0, 0, 0, 0.87);
  background: #f48fb1;
}
.pink-amber.dark .UI-V2 .audit-result .result-container .toolbar .search-container .search .content {
  background: #303030;
}
.pink-amber.dark .UI-V2 .audit-result .result-container .toolbar .search-container .search .content:hover, .pink-amber.dark .UI-V2 .audit-result .result-container .toolbar .search-container .search .content:active, .pink-amber.dark .UI-V2 .audit-result .result-container .toolbar .search-container .search .content:focus, .pink-amber.dark .UI-V2 .audit-result .result-container .toolbar .search-container .search .content:focus-within {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-amber.dark .UI-V2 .audit-result .result-container .toolbar .search-container .search .content input {
  color: white;
}
.pink-amber.dark .UI-V2 .audit-result .result-container .new-table app-cs-grid-control datatable-header * {
  color: #f48fb1;
}
.pink-amber.dark .UI-V2 .audit-result .result-container .new-table .mat-header-row.sticky * {
  color: #f48fb1 !important;
}
.pink-amber.dark .UI-V2 .audit-detail-container {
  border-left: 1px solid rgba(255, 255, 255, 0.12);
  background: black !important;
}
.pink-amber.dark .UI-V2 .audit-detail-container .side .toolbar .header {
  color: #f48fb1;
}
.pink-amber.dark .UI-V2 .audit-detail-container .side .container .mat-list-base {
  background: #303030 !important;
}
.pink-amber.dark .UI-V2 .audit-detail-container .side .container .mat-list-base mat-list-item .mat-line b {
  color: #f48fb1;
}
.pink-amber.dark .UI-V2 .inbox-container {
  background: #424242;
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark .UI-V2 .inbox-container *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.pink-amber.dark .UI-V2 .inbox-container *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
.pink-amber.dark .UI-V2 .inbox-container *::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}
.pink-amber.dark .UI-V2 .inbox-container .load-window {
  background: #424242;
}
.pink-amber.dark .UI-V2 .inbox-container .toolbar {
  background: #f48fb1;
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark .UI-V2 .inbox-container .toolbar.reverse {
  background: #424242;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel {
  background: black !important;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel.mat-expanded {
  background: #303030 !important;
}
.pink-amber.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel.mat-expanded mat-expansion-panel-header {
  background: #303030 !important;
}
.pink-amber.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel.mat-expanded mat-expansion-panel-header:hover {
  background: none !important;
}
.pink-amber.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel mat-expansion-panel-header {
  background: #303030 !important;
}
.pink-amber.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel mat-expansion-panel-header:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-amber.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel mat-expansion-panel-header .signature-inbox-header .mat-icon,
.pink-amber.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel mat-expansion-panel-header .signature-inbox-header b {
  color: #ffe082;
}
.pink-amber.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel .mat-expansion-panel-body .item {
  background: #424242;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel .mat-expansion-panel-body .item:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-amber.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel .mat-expansion-panel-body .item.active {
  border-left: 6px solid #ffe082;
}
.pink-amber.dark .UI-V2 .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.pink-amber.dark .UI-V2 .work-space .main .load-window {
  background: #424242 !important;
}
.pink-amber.dark .UI-V2 .work-space .main-action {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark mat-radio-button.disabled .mat-radio-label-content {
  color: #ffe082;
}
.pink-amber.dark .detail app-automatic-process-detail app-chapa.minimize,
.pink-amber.dark .detail app-process-detail app-chapa.minimize {
  background: #ffc107 !important;
}
.pink-amber.dark .detail app-automatic-process-detail app-chapa.minimize.purged,
.pink-amber.dark .detail app-process-detail app-chapa.minimize.purged {
  background: #b2adb0 !important;
}
.pink-amber.dark .detail .detail-toolbar {
  background: black;
}
.pink-amber.dark .detail .processes-status .status-bar .bar-graph {
  background-color: #ffc107;
}
.pink-amber.dark .detail .processes-status .status-bar .bar-graph .graph-ok {
  background-color: #009688;
}
.pink-amber.dark .detail .processes-status .status-bar .bar-graph .graph-not-ok {
  background-color: #e91e63;
}
.pink-amber.dark .detail .processes-status .status-resume b {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark .detail .processes-status .status-resume .resume-total b {
  background-color: #ffc107;
}
.pink-amber.dark .detail .processes-status .status-resume .resume-ok b {
  background-color: #009688;
}
.pink-amber.dark .detail .processes-status .status-resume .resume-not-ok b {
  background-color: #e91e63;
}
.pink-amber.dark .detail .detail-toolbar .mat-avatar h3,
.pink-amber.dark .detail .detail-toolbar .mat-avatar h5 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark .detail .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark .detail .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark .detail .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark .detail .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark .detail .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  background: #009688 !important;
}
.pink-amber.dark .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  background: #b2dfdb !important;
}
.pink-amber.dark .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  background: #b2dfdb !important;
}
.pink-amber.dark .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  background: #009688 !important;
}
.pink-amber.dark .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  background: #b2dfdb !important;
}
.pink-amber.dark .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  background: #837C80 !important;
}
.pink-amber.dark .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  background: #b2adb0 !important;
}
.pink-amber.dark .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  background: #b2adb0 !important;
}
.pink-amber.dark .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  background: #837C80 !important;
}
.pink-amber.dark .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  background: #b2dfdb !important;
}
.pink-amber.dark .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  background: #ffc107 !important;
}
.pink-amber.dark .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  background: #ffecb3 !important;
}
.pink-amber.dark .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  background: #ffecb3 !important;
}
.pink-amber.dark .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  background: #ffc107 !important;
}
.pink-amber.dark .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  background: #ffecb3 !important;
}
.pink-amber.dark .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  background: #e91e63 !important;
}
.pink-amber.dark .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  background: #f8bbd0 !important;
}
.pink-amber.dark .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  background: #f8bbd0 !important;
}
.pink-amber.dark .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  background: #e91e63 !important;
}
.pink-amber.dark .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  background: #f8bbd0 !important;
}
.pink-amber.dark app-analytics-document-set .sets-container .mat-list-base.sets-list mat-list-item {
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark app-analytics-document-set .sets-container .mat-list-base.sets-list mat-list-item .mat-list-item-content .set-status .value-percentile .undone-value {
  background: #673AB7;
}
.pink-amber.dark app-analytics-document-set .sets-container .mat-list-base.sets-list mat-list-item .mat-list-item-content .set-status .value-percentile .not-value {
  background: black;
}
.pink-amber.dark app-analytics-document-set .sets-container .mat-list-base.sets-list mat-list-item .mat-list-item-content .set-status .value-percentile .done-value {
  background: #009688;
}
.pink-amber.dark app-analytics-document-set .work-space .main .toolbar .header .content .module-title .info .info-text .set-status {
  cursor: help;
}
.pink-amber.dark app-analytics-document-set .work-space .main .toolbar .header .content .module-title .info .info-text .set-status .value-percentile .undone-value {
  background: #673AB7;
}
.pink-amber.dark app-analytics-document-set .work-space .main .toolbar .header .content .module-title .info .info-text .set-status .value-percentile .not-value {
  background: black;
}
.pink-amber.dark app-analytics-document-set .work-space .main .toolbar .header .content .module-title .info .info-text .set-status .value-percentile .done-value {
  background: #009688;
}
.pink-amber.dark app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar .mat-progress-bar-buffer {
  background-color: black;
}
.pink-amber.dark app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar.class1 .mat-progress-bar-fill::after {
  background-color: #f44336;
}
.pink-amber.dark app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar.class2 .mat-progress-bar-fill::after {
  background-color: #ff9800;
}
.pink-amber.dark app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar.class3 .mat-progress-bar-fill::after {
  background-color: #ffeb3b;
}
.pink-amber.dark app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar.class4 .mat-progress-bar-fill::after {
  background-color: #8bc34a;
}
.pink-amber.dark app-analytics-document-set .work-space .side .container mat-list {
  background: #424242 !important;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark app-analytics-document-set .work-space .side .container mat-list mat-list-item .mat-line b {
  color: #f48fb1;
}
.pink-amber.dark .catcher {
  background: rgba(0, 0, 0, 0.6);
}
.pink-amber.dark .catcher.clear {
  background: rgba(0, 0, 0, 0);
}
.pink-amber.dark .add-menu-form-body .mat-radio-group .mat-radio-button:hover {
  background: rgba(255, 255, 255, 0.04);
}
.pink-amber.dark .add-menu-form-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b {
  color: white;
}
.pink-amber.dark .add-menu-form-body.w-table .helper .search-bar {
  background: #303030;
}
.pink-amber.dark .add-menu-form-body.w-table .helper .search-bar:hover, .pink-amber.dark .add-menu-form-body.w-table .helper .search-bar:active, .pink-amber.dark .add-menu-form-body.w-table .helper .search-bar:focus, .pink-amber.dark .add-menu-form-body.w-table .helper .search-bar:focus-within {
  background: #303030 !important;
}
.pink-amber.dark .add-menu-form-body.w-table .helper .search-bar input {
  color: white;
}
.pink-amber.dark .add-menu-form-actions {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark .file-upload-list mat-list-item {
  background: #424242;
}
.pink-amber.dark .mat-horizontal-stepper-content[aria-expanded=true] app-file-document-view {
  border-left: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark .mat-horizontal-stepper-content[aria-expanded=true] app-file-document-view .pdf-view-container {
  background: black;
}
.pink-amber.dark .add-docs-drop .docs-drop-preview app-file-document-detail {
  border-left: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark .add-docs-drop .docs-drop-preview app-file-document-detail .view-toolbar {
  background: #424242;
}
.pink-amber.dark .add-docs-drop .load-window.message h3.primary-text {
  color: #f48fb1;
}
.pink-amber.dark mat-dialog-container app-add-menu-dialog .load-window.message h3.primary-text {
  color: #f48fb1;
}
.pink-amber.dark .asisted-input.aligned-icon.subtitle * {
  color: rgba(255, 255, 255, 0.5) !important;
}
.pink-amber.dark .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble {
  background: #f48fb1;
}
.pink-amber.dark .asisted-input .mat-form-field-invalid + .indicator > .bubble {
  background: #e57373;
}
.pink-amber.dark .asisted-input .mat-form-field-disabled + .indicator > .bubble {
  color: rgba(255, 255, 255, 0.5);
  background: rgba(255, 255, 255, 0.12);
}
.pink-amber.dark .asisted-input .indicator .bubble {
  background: rgba(255, 255, 255, 0.5);
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark .asisted-input .indicator .guide {
  border-color: rgba(255, 255, 255, 0.12);
}
.pink-amber.dark .asisted-input.icon-only .bubble {
  color: rgba(255, 255, 255, 0.7);
}
.pink-amber.dark .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble {
  color: #f48fb1;
}
.pink-amber.dark .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble {
  color: #e57373;
}
.pink-amber.dark .UI-V2 .main-overlay {
  background: rgba(0, 0, 0, 0.32);
}
.pink-amber.dark .UI-V2 .icon-complex mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.pink-amber.dark .UI-V2 .icon-complex mat-icon.complex {
  color: #f48fb1 !important;
}
.pink-amber.dark .UI-V2 .navigation {
  background: #303030;
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark .UI-V2 .navigation *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.pink-amber.dark .UI-V2 .navigation *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
.pink-amber.dark .UI-V2 .navigation .load-window {
  background: black;
}
.pink-amber.dark .UI-V2 .navigation .toolbar {
  background: #f48fb1;
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark .UI-V2 .navigation .toolbar.reverse {
  background: #424242;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark .UI-V2 .navigation .container .helper {
  color: rgba(255, 255, 255, 0.7);
}
.pink-amber.dark .UI-V2 .navigation .container .item {
  background: #424242;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark .UI-V2 .navigation .container .item:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-amber.dark .UI-V2 .navigation .container .item.active {
  background: #f48fb1 !important;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #f48fb1;
}
.pink-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .separator .subtitle,
.pink-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .separator .subtitle,
.pink-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .separator .subtitle,
.pink-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .separator .subtitle,
.pink-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .separator .subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.pink-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .img-editior-container .img-editor .separator .subtitle,
.pink-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .img-editior-container .img-editor .separator .subtitle,
.pink-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .img-editior-container .img-editor .separator .subtitle,
.pink-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .img-editior-container .img-editor .separator .subtitle,
.pink-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .img-editior-container .img-editor .separator .subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.pink-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme,
.pink-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme,
.pink-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme,
.pink-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme,
.pink-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .main-color-container .main-color,
.pink-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .main-color-container .main-color,
.pink-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .main-color-container .main-color,
.pink-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .main-color-container .main-color,
.pink-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .main-color-container .main-color {
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .main-color-container .main-color:before,
.pink-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .main-color-container .main-color:before,
.pink-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .main-color-container .main-color:before,
.pink-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .main-color-container .main-color:before,
.pink-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .main-color-container .main-color:before {
  border-left: 10px solid rgba(0, 0, 0, 0);
  border-right: 10px solid rgba(0, 0, 0, 0);
}
.pink-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example h6.required,
.pink-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example h6.required,
.pink-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example h6.required,
.pink-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example h6.required,
.pink-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example h6.required {
  color: #e57373 !important;
}
.pink-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle,
.pink-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle,
.pink-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle,
.pink-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle,
.pink-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle {
  border: 2px solid rgba(0, 0, 0, 0);
}
.pink-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .side-color-container .side-color,
.pink-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .side-color-container .side-color,
.pink-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .side-color-container .side-color,
.pink-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .side-color-container .side-color,
.pink-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .side-color-container .side-color {
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .side-color-container .side-color:before,
.pink-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .side-color-container .side-color:before,
.pink-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .side-color-container .side-color:before,
.pink-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .side-color-container .side-color:before,
.pink-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .side-color-container .side-color:before {
  border-top: 10px solid rgba(0, 0, 0, 0);
  border-bottom: 10px solid rgba(0, 0, 0, 0);
}
.pink-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .side-color-container .side-color h6.required,
.pink-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .side-color-container .side-color h6.required,
.pink-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .side-color-container .side-color h6.required,
.pink-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .side-color-container .side-color h6.required,
.pink-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .side-color-container .side-color h6.required {
  color: #e57373 !important;
}
.pink-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .permission-settings-cards .card,
.pink-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .permission-settings-cards .card,
.pink-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .permission-settings-cards .card,
.pink-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .permission-settings-cards .card,
.pink-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .permission-settings-cards .card {
  background: #303030;
}
.pink-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .permission-settings-cards .card .slide-sub-group,
.pink-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .permission-settings-cards .card .slide-sub-group,
.pink-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .permission-settings-cards .card .slide-sub-group,
.pink-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .permission-settings-cards .card .slide-sub-group,
.pink-amber.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .permission-settings-cards .card .slide-sub-group {
  background: rgba(255, 255, 255, 0.12);
}
.pink-amber.dark .UI-V2 .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.pink-amber.dark .UI-V2 .work-space .main .load-window {
  background: #424242 !important;
}
.pink-amber.dark .UI-V2 .work-space .main.side-open {
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item {
  background: #303030;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item mat-icon.complex {
  text-shadow: -2px 0 0px black, 0 -2px 0px black, 2px 0 0px black, 0 2px 0px black, -2px -2px 0px black, 2px 2px 0px black, 2px -2px 0px black, -2px 2px 0px black !important;
}
.pink-amber.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item h5 {
  color: rgba(255, 255, 255, 0.5);
}
.pink-amber.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item h3 {
  color: white;
}
.pink-amber.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item .actions {
  pointer-events: none;
}
.pink-amber.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item .actions button {
  pointer-events: all;
}
.pink-amber.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled {
  background: black;
}
.pink-amber.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled h3 {
  color: #607D8B !important;
}
.pink-amber.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled mat-icon {
  color: #607D8B !important;
}
.pink-amber.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled.selected {
  background: #607D8B;
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-amber.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled.selected h3 {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-amber.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled.selected mat-icon {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-amber.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled.selected mat-icon.complex {
  color: rgba(0, 0, 0, 0.87) !important;
  text-shadow: -2px 0 0px #607D8B, 0 -2px 0px #607D8B, 2px 0 0px #607D8B, 0 2px 0px #607D8B, -2px -2px 0px #607D8B, 2px 2px 0px #607D8B, 2px -2px 0px #607D8B, -2px 2px 0px #607D8B !important;
}
.pink-amber.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item:hover {
  background: rgba(255, 255, 255, 0.04);
}
.pink-amber.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected {
  background: #f48fb1;
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected mat-icon {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected mat-icon.complex {
  color: rgba(0, 0, 0, 0.87) !important;
  text-shadow: -2px 0 0px #f48fb1, 0 -2px 0px #f48fb1, 2px 0 0px #f48fb1, 0 2px 0px #f48fb1, -2px -2px 0px #f48fb1, 2px 2px 0px #f48fb1, 2px -2px 0px #f48fb1, -2px 2px 0px #f48fb1 !important;
}
.pink-amber.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected h5,
.pink-amber.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected h3 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark .UI-V2 .work-space .main .main-action {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark .UI-V2 .work-space .side {
  background: #303030;
}
.pink-amber.dark .UI-V2 .work-space .side .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #303030, 0 -2px 0px #303030, 2px 0 0px #303030, 0 2px 0px #303030, -2px -2px 0px #303030, 2px 2px 0px #303030, 2px -2px 0px #303030, -2px 2px 0px #303030;
}
.pink-amber.dark .UI-V2 .work-space .side *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.pink-amber.dark .UI-V2 .work-space .side *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
.pink-amber.dark .UI-V2 .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #303030 !important;
}
.pink-amber.dark .UI-V2 .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(255, 255, 255, 0.5);
}
.pink-amber.dark .UI-V2 .work-space .side mat-form-field .mat-form-field-flex {
  background: #303030;
}
.pink-amber.dark .UI-V2 .work-space .side mat-checkbox:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-amber.dark .UI-V2 .work-space .side .hint.all {
  color: #ffe082;
}
.pink-amber.dark .UI-V2 .work-space .side .toolbar .header {
  color: #f48fb1;
}
.pink-amber.dark .UI-V2 .work-space .side .toolbar .header h3 {
  color: #f48fb1;
}
.pink-amber.dark .UI-V2 .work-space .side .container .role-resume {
  background: #f48fb1;
}
.pink-amber.dark .UI-V2 .work-space .side .container .role-resume .line {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark .UI-V2 .work-space .side .container .role-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.1);
}
.pink-amber.dark .UI-V2 .work-space .side .container .role-resume .line.editable button {
  background: #f48fb1;
}
.pink-amber.dark .UI-V2 .work-space .side .container .section-title {
  color: #f48fb1;
}
.pink-amber.dark .UI-V2 .work-space .side .container .section-resume {
  background: #424242;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark .UI-V2 .work-space .side .container .section-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-amber.dark .UI-V2 .work-space .side .container .section-resume .line.editable:hover .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #424242, 0 -2px 0px #424242, 2px 0 0px #424242, 0 2px 0px #424242, -2px -2px 0px #424242, 2px 2px 0px #424242, 2px -2px 0px #424242, -2px 2px 0px #424242 !important;
}
.pink-amber.dark .UI-V2 .work-space .side .container .section-resume .line.editable button {
  background: #303030;
}
.pink-amber.dark .UI-V2 .work-space .side .container .section-resume .line .header .icon-complex mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.pink-amber.dark .UI-V2 .work-space .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #f48fb1 !important;
}
.pink-amber.dark .UI-V2 .work-space .side .container .section-resume .line .header mat-icon:not(.complex) {
  color: rgba(255, 255, 255, 0.7);
}
.pink-amber.dark .UI-V2 .work-space .side .container .section-resume .line .header .info h4 {
  color: #f48fb1;
}
.pink-amber.dark .UI-V2 .work-space .side .container .section-resume .line ul {
  color: #f48fb1;
}
.pink-amber.dark .UI-V2 .work-space .side .container .section-resume .line.head {
  background: black;
}
.pink-amber.dark .UI-V2 .work-space .side .container .section-resume .line.head mat-icon {
  color: #ffe082;
}
.pink-amber.dark .UI-V2 .work-space .side .container .section-resume .line.head h4 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark .UI-V2 .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body {
  background: #424242;
}
.pink-amber.dark .UI-V2 .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: rgba(255, 255, 255, 0.7);
}
.pink-amber.dark .UI-V2 .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #f48fb1;
}
.pink-amber.dark .UI-V2 .work-space .side.edition .toolbar .header {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark .UI-V2 .work-space .side.edition .toolbar .header mat-icon {
  color: #ffe082;
}
.pink-amber.dark .UI-V2 .work-space .side.edition .toolbar .header h3 {
  color: #ffe082;
}
.pink-amber.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body {
  background: #424242 !important;
}
.pink-amber.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header mat-icon:not(.complex) {
  color: #f48fb1;
}
.pink-amber.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header h4 {
  color: #f48fb1;
}
.pink-amber.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-actions {
  background: #303030;
}
.pink-amber.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.pink-amber.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header .icon-complex mat-icon.complex {
  color: #ffe082 !important;
}
.pink-amber.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header h4 {
  color: #ffe082;
}
.pink-amber.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line ul {
  color: #ffe082;
  font-weight: 400;
}
.pink-amber.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head mat-icon {
  color: #ffe082 !important;
}
.pink-amber.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head h4 {
  color: white;
}
.pink-amber.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .section-title {
  color: white !important;
}
.pink-amber.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume h6.hint {
  background: #ffe082;
}
.pink-amber.dark app-role-user-find .main-overlay {
  background: rgba(0, 0, 0, 0.32);
}
.pink-amber.dark app-role-user-find .icon-complex mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.pink-amber.dark app-role-user-find .icon-complex mat-icon.complex {
  color: #f48fb1 !important;
}
.pink-amber.dark app-role-user-find .navigation {
  background: #303030;
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark app-role-user-find .navigation *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.pink-amber.dark app-role-user-find .navigation *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
.pink-amber.dark app-role-user-find .navigation .load-window {
  background: black;
}
.pink-amber.dark app-role-user-find .navigation .toolbar {
  background: #f48fb1;
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark app-role-user-find .navigation .toolbar.reverse {
  background: #424242;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark app-role-user-find .navigation .container .helper {
  color: rgba(255, 255, 255, 0.7);
}
.pink-amber.dark app-role-user-find .navigation .container .item {
  background: #424242;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark app-role-user-find .navigation .container .item:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-amber.dark app-role-user-find .navigation .container .item.active {
  background: #f48fb1 !important;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #f48fb1;
}
.pink-amber.dark app-role-user-find .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.pink-amber.dark app-role-user-find .work-space .main .load-window {
  background: #424242 !important;
}
.pink-amber.dark app-role-user-find .work-space .main.side-open {
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark app-role-user-find .work-space .main .container .item {
  background: #303030;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark app-role-user-find .work-space .main .container .item .subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.pink-amber.dark app-role-user-find .work-space .main .container .item.disabled {
  background: black;
  color: #607D8B !important;
}
.pink-amber.dark app-role-user-find .work-space .main .container .item.disabled h3 {
  color: #607D8B !important;
}
.pink-amber.dark app-role-user-find .work-space .main .container .item.disabled mat-icon {
  color: #607D8B !important;
}
.pink-amber.dark app-role-user-find .work-space .main .container .item.disabled.selected {
  background: #607D8B;
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-amber.dark app-role-user-find .work-space .main .container .item.disabled.selected h3 {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-amber.dark app-role-user-find .work-space .main .container .item.disabled.selected mat-icon {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-amber.dark app-role-user-find .work-space .main .container .item.disabled.selected mat-icon.complex {
  color: rgba(0, 0, 0, 0.87) !important;
  text-shadow: -2px 0 0px #607D8B, 0 -2px 0px #607D8B, 2px 0 0px #607D8B, 0 2px 0px #607D8B, -2px -2px 0px #607D8B, 2px 2px 0px #607D8B, 2px -2px 0px #607D8B, -2px 2px 0px #607D8B !important;
}
.pink-amber.dark app-role-user-find .work-space .main .container .item mat-icon.complex {
  text-shadow: -2px 0 0px black, 0 -2px 0px black, 2px 0 0px black, 0 2px 0px black, -2px -2px 0px black, 2px 2px 0px black, 2px -2px 0px black, -2px 2px 0px black !important;
}
.pink-amber.dark app-role-user-find .work-space .main .container .item h5 {
  color: rgba(255, 255, 255, 0.5);
}
.pink-amber.dark app-role-user-find .work-space .main .container .item h3 {
  color: white;
}
.pink-amber.dark app-role-user-find .work-space .main .container .item:hover {
  background: rgba(255, 255, 255, 0.04);
}
.pink-amber.dark app-role-user-find .work-space .main .container .item.selected {
  background: #f48fb1;
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark app-role-user-find .work-space .main .container .item.selected mat-icon {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark app-role-user-find .work-space .main .container .item.selected mat-icon.complex {
  color: rgba(0, 0, 0, 0.87) !important;
  text-shadow: -2px 0 0px #f48fb1, 0 -2px 0px #f48fb1, 2px 0 0px #f48fb1, 0 2px 0px #f48fb1, -2px -2px 0px #f48fb1, 2px 2px 0px #f48fb1, 2px -2px 0px #f48fb1, -2px 2px 0px #f48fb1 !important;
}
.pink-amber.dark app-role-user-find .work-space .main .container .item.selected h5,
.pink-amber.dark app-role-user-find .work-space .main .container .item.selected h3 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark app-role-user-find .work-space .main .main-action {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark app-role-user-find .work-space .side {
  background: #303030;
}
.pink-amber.dark app-role-user-find .work-space .side .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #303030, 0 -2px 0px #303030, 2px 0 0px #303030, 0 2px 0px #303030, -2px -2px 0px #303030, 2px 2px 0px #303030, 2px -2px 0px #303030, -2px 2px 0px #303030;
}
.pink-amber.dark app-role-user-find .work-space .side *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.pink-amber.dark app-role-user-find .work-space .side *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
.pink-amber.dark app-role-user-find .work-space .side .edition-line {
  background: black;
}
.pink-amber.dark app-role-user-find .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #303030 !important;
}
.pink-amber.dark app-role-user-find .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(255, 255, 255, 0.5);
}
.pink-amber.dark app-role-user-find .work-space .side mat-form-field .mat-form-field-flex {
  background: #303030;
}
.pink-amber.dark app-role-user-find .work-space .side mat-checkbox:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-amber.dark app-role-user-find .work-space .side .hint.all {
  color: #ffe082;
}
.pink-amber.dark app-role-user-find .work-space .side .toolbar .header {
  color: #f48fb1;
}
.pink-amber.dark app-role-user-find .work-space .side .toolbar .header h3 {
  color: #f48fb1;
}
.pink-amber.dark app-role-user-find .work-space .side .container .role-resume {
  background: #f48fb1;
}
.pink-amber.dark app-role-user-find .work-space .side .container .role-resume .line {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark app-role-user-find .work-space .side .container .role-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.1);
}
.pink-amber.dark app-role-user-find .work-space .side .container .role-resume .line.editable button {
  background: #f48fb1;
}
.pink-amber.dark app-role-user-find .work-space .side .container .section-title {
  color: #f48fb1;
}
.pink-amber.dark app-role-user-find .work-space .side .container .section-resume {
  background: #424242;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark app-role-user-find .work-space .side .container .section-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-amber.dark app-role-user-find .work-space .side .container .section-resume .line.editable:hover .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #424242, 0 -2px 0px #424242, 2px 0 0px #424242, 0 2px 0px #424242, -2px -2px 0px #424242, 2px 2px 0px #424242, 2px -2px 0px #424242, -2px 2px 0px #424242 !important;
}
.pink-amber.dark app-role-user-find .work-space .side .container .section-resume .line.editable button {
  background: #303030;
}
.pink-amber.dark app-role-user-find .work-space .side .container .section-resume .line .header .icon-complex mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.pink-amber.dark app-role-user-find .work-space .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #f48fb1 !important;
}
.pink-amber.dark app-role-user-find .work-space .side .container .section-resume .line .header mat-icon:not(.complex) {
  color: rgba(255, 255, 255, 0.7);
}
.pink-amber.dark app-role-user-find .work-space .side .container .section-resume .line .header .info h4 {
  color: #f48fb1;
}
.pink-amber.dark app-role-user-find .work-space .side .container .section-resume .line ul {
  color: #f48fb1;
}
.pink-amber.dark app-role-user-find .work-space .side .container .section-resume .line.head {
  background: black;
}
.pink-amber.dark app-role-user-find .work-space .side .container .section-resume .line.head mat-icon {
  color: #ffe082;
}
.pink-amber.dark app-role-user-find .work-space .side .container .section-resume .line.head h4 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee-title {
  color: #f48fb1;
}
.pink-amber.dark app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body {
  background: #424242;
}
.pink-amber.dark app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: rgba(255, 255, 255, 0.7);
}
.pink-amber.dark app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #f48fb1;
}
.pink-amber.dark app-role-user-find .work-space .side.edition .toolbar .header {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark app-role-user-find .work-space .side.edition .toolbar .header mat-icon {
  color: #ffe082;
}
.pink-amber.dark app-role-user-find .work-space .side.edition .toolbar .header h3 {
  color: #ffe082;
}
.pink-amber.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body {
  background: #424242 !important;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header mat-icon:not(.complex) {
  color: #f48fb1;
}
.pink-amber.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header h4 {
  color: #f48fb1;
}
.pink-amber.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-actions {
  background: #303030;
}
.pink-amber.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.pink-amber.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header .icon-complex mat-icon.complex {
  color: #ffe082 !important;
}
.pink-amber.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header h4 {
  color: #ffe082;
}
.pink-amber.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line ul {
  color: #ffe082;
}
.pink-amber.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head mat-icon {
  color: #ffe082 !important;
}
.pink-amber.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head h4 {
  color: white;
}
.pink-amber.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .section-title {
  color: white !important;
}
.pink-amber.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume h6.hint {
  background: #ffe082;
}
.pink-amber.dark app-role-user-find .work-space app-role-user-detail.disabled .side .toolbar .header {
  color: #607D8B !important;
}
.pink-amber.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container .role-resume {
  background: #607D8B !important;
}
.pink-amber.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-title {
  color: #607D8B !important;
}
.pink-amber.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line ul {
  color: #607D8B !important;
}
.pink-amber.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header mat-icon {
  color: #607D8B;
}
.pink-amber.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .icon-complex mat-icon {
  color: #607D8B !important;
}
.pink-amber.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #607D8B !important;
}
.pink-amber.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .info h4 {
  color: #607D8B !important;
}
.pink-amber.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee-title {
  color: #607D8B;
}
.pink-amber.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: #607D8B;
}
.pink-amber.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #607D8B;
}
.pink-amber.dark app-actor-find .main-overlay {
  background: rgba(0, 0, 0, 0.32);
}
.pink-amber.dark app-actor-find .icon-complex mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.pink-amber.dark app-actor-find .icon-complex mat-icon.complex {
  color: #f48fb1 !important;
}
.pink-amber.dark app-actor-find .navigation {
  background: #303030;
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark app-actor-find .navigation *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.pink-amber.dark app-actor-find .navigation *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
.pink-amber.dark app-actor-find .navigation .load-window {
  background: black;
}
.pink-amber.dark app-actor-find .navigation .toolbar {
  background: #f48fb1;
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark app-actor-find .navigation .toolbar.reverse {
  background: #424242;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark app-actor-find .navigation .container .helper {
  color: rgba(255, 255, 255, 0.7);
}
.pink-amber.dark app-actor-find .navigation .container .item {
  background: #424242;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark app-actor-find .navigation .container .item:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-amber.dark app-actor-find .navigation .container .item.active {
  background: #f48fb1 !important;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #f48fb1;
}
.pink-amber.dark app-actor-find .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.pink-amber.dark app-actor-find .work-space .main .load-window {
  background: #424242 !important;
}
.pink-amber.dark app-actor-find .work-space .main.side-open {
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark app-actor-find .work-space .main .container .item {
  background: #303030;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark app-actor-find .work-space .main .container .item .subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.pink-amber.dark app-actor-find .work-space .main .container .item.disabled {
  background: black;
  color: #607D8B !important;
}
.pink-amber.dark app-actor-find .work-space .main .container .item.disabled h3 {
  color: #607D8B !important;
}
.pink-amber.dark app-actor-find .work-space .main .container .item.disabled mat-icon {
  color: #607D8B !important;
}
.pink-amber.dark app-actor-find .work-space .main .container .item.disabled.selected {
  background: #607D8B;
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-amber.dark app-actor-find .work-space .main .container .item.disabled.selected h3 {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-amber.dark app-actor-find .work-space .main .container .item.disabled.selected mat-icon {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-amber.dark app-actor-find .work-space .main .container .item.disabled.selected mat-icon.complex {
  color: rgba(0, 0, 0, 0.87) !important;
  text-shadow: -2px 0 0px #607D8B, 0 -2px 0px #607D8B, 2px 0 0px #607D8B, 0 2px 0px #607D8B, -2px -2px 0px #607D8B, 2px 2px 0px #607D8B, 2px -2px 0px #607D8B, -2px 2px 0px #607D8B !important;
}
.pink-amber.dark app-actor-find .work-space .main .container .item mat-icon.complex {
  text-shadow: -2px 0 0px black, 0 -2px 0px black, 2px 0 0px black, 0 2px 0px black, -2px -2px 0px black, 2px 2px 0px black, 2px -2px 0px black, -2px 2px 0px black !important;
}
.pink-amber.dark app-actor-find .work-space .main .container .item h5 {
  color: rgba(255, 255, 255, 0.5);
}
.pink-amber.dark app-actor-find .work-space .main .container .item h3 {
  color: white;
}
.pink-amber.dark app-actor-find .work-space .main .container .item:hover {
  background: rgba(255, 255, 255, 0.04);
}
.pink-amber.dark app-actor-find .work-space .main .container .item.selected {
  background: #f48fb1;
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark app-actor-find .work-space .main .container .item.selected mat-icon {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark app-actor-find .work-space .main .container .item.selected mat-icon.complex {
  color: rgba(0, 0, 0, 0.87) !important;
  text-shadow: -2px 0 0px #f48fb1, 0 -2px 0px #f48fb1, 2px 0 0px #f48fb1, 0 2px 0px #f48fb1, -2px -2px 0px #f48fb1, 2px 2px 0px #f48fb1, 2px -2px 0px #f48fb1, -2px 2px 0px #f48fb1 !important;
}
.pink-amber.dark app-actor-find .work-space .main .container .item.selected h5,
.pink-amber.dark app-actor-find .work-space .main .container .item.selected h3 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark app-actor-find .work-space .main .main-action {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark app-actor-find .work-space .side {
  background: #303030;
}
.pink-amber.dark app-actor-find .work-space .side .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #303030, 0 -2px 0px #303030, 2px 0 0px #303030, 0 2px 0px #303030, -2px -2px 0px #303030, 2px 2px 0px #303030, 2px -2px 0px #303030, -2px 2px 0px #303030;
}
.pink-amber.dark app-actor-find .work-space .side *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.pink-amber.dark app-actor-find .work-space .side *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
.pink-amber.dark app-actor-find .work-space .side .edition-line {
  background: black;
}
.pink-amber.dark app-actor-find .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #303030 !important;
}
.pink-amber.dark app-actor-find .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(255, 255, 255, 0.5);
}
.pink-amber.dark app-actor-find .work-space .side mat-form-field .mat-form-field-flex {
  background: #303030;
}
.pink-amber.dark app-actor-find .work-space .side mat-checkbox:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-amber.dark app-actor-find .work-space .side .hint.all {
  color: #ffe082;
}
.pink-amber.dark app-actor-find .work-space .side .toolbar .header {
  color: #f48fb1;
}
.pink-amber.dark app-actor-find .work-space .side .toolbar .header h3 {
  color: #f48fb1;
}
.pink-amber.dark app-actor-find .work-space .side .container .role-resume {
  background: #f48fb1;
}
.pink-amber.dark app-actor-find .work-space .side .container .role-resume .line {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark app-actor-find .work-space .side .container .role-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.1);
}
.pink-amber.dark app-actor-find .work-space .side .container .role-resume .line.editable button {
  background: #f48fb1;
}
.pink-amber.dark app-actor-find .work-space .side .container .section-title {
  color: #f48fb1;
}
.pink-amber.dark app-actor-find .work-space .side .container .section-resume {
  background: #424242;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark app-actor-find .work-space .side .container .section-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-amber.dark app-actor-find .work-space .side .container .section-resume .line.editable:hover .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #424242, 0 -2px 0px #424242, 2px 0 0px #424242, 0 2px 0px #424242, -2px -2px 0px #424242, 2px 2px 0px #424242, 2px -2px 0px #424242, -2px 2px 0px #424242 !important;
}
.pink-amber.dark app-actor-find .work-space .side .container .section-resume .line.editable button {
  background: #303030;
}
.pink-amber.dark app-actor-find .work-space .side .container .section-resume .line .header .icon-complex mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.pink-amber.dark app-actor-find .work-space .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #f48fb1 !important;
}
.pink-amber.dark app-actor-find .work-space .side .container .section-resume .line .header mat-icon:not(.complex) {
  color: rgba(255, 255, 255, 0.7);
}
.pink-amber.dark app-actor-find .work-space .side .container .section-resume .line .header .info h4 {
  color: #f48fb1;
}
.pink-amber.dark app-actor-find .work-space .side .container .section-resume .line ul {
  color: #f48fb1;
}
.pink-amber.dark app-actor-find .work-space .side .container .section-resume .line.head {
  background: black;
}
.pink-amber.dark app-actor-find .work-space .side .container .section-resume .line.head mat-icon {
  color: #ffe082;
}
.pink-amber.dark app-actor-find .work-space .side .container .section-resume .line.head h4 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee-title {
  color: #f48fb1;
}
.pink-amber.dark app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body {
  background: #424242;
}
.pink-amber.dark app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: rgba(255, 255, 255, 0.7);
}
.pink-amber.dark app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #f48fb1;
}
.pink-amber.dark app-actor-find .work-space .side.edition .toolbar .header {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark app-actor-find .work-space .side.edition .toolbar .header mat-icon {
  color: #ffe082;
}
.pink-amber.dark app-actor-find .work-space .side.edition .toolbar .header h3 {
  color: #ffe082;
}
.pink-amber.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body {
  background: #424242 !important;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header mat-icon:not(.complex) {
  color: #f48fb1;
}
.pink-amber.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header h4 {
  color: #f48fb1;
}
.pink-amber.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-actions {
  background: #303030;
}
.pink-amber.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.pink-amber.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header .icon-complex mat-icon.complex {
  color: #ffe082 !important;
}
.pink-amber.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header h4 {
  color: #ffe082;
}
.pink-amber.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line ul {
  color: #ffe082;
}
.pink-amber.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head mat-icon {
  color: #ffe082 !important;
}
.pink-amber.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head h4 {
  color: white;
}
.pink-amber.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .section-title {
  color: white !important;
}
.pink-amber.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume h6.hint {
  background: #ffe082;
}
.pink-amber.dark app-actor-find .work-space app-role-user-detail.disabled .side .toolbar .header {
  color: #607D8B !important;
}
.pink-amber.dark app-actor-find .work-space app-role-user-detail.disabled .side .container .role-resume {
  background: #607D8B !important;
}
.pink-amber.dark app-actor-find .work-space app-role-user-detail.disabled .side .container .section-title {
  color: #607D8B !important;
}
.pink-amber.dark app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line ul {
  color: #607D8B !important;
}
.pink-amber.dark app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header mat-icon {
  color: #607D8B;
}
.pink-amber.dark app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .icon-complex mat-icon {
  color: #607D8B !important;
}
.pink-amber.dark app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #607D8B !important;
}
.pink-amber.dark app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .info h4 {
  color: #607D8B !important;
}
.pink-amber.dark app-actor-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee-title {
  color: #607D8B;
}
.pink-amber.dark app-actor-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: #607D8B;
}
.pink-amber.dark app-actor-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #607D8B;
}
.pink-amber.dark .dropzone {
  border: 2px dashed #607D8B;
  background: black !important;
}
.pink-amber.dark .dropzone.hovering {
  border: 2px solid #607D8B;
  color: rgba(255, 255, 255, 0.5) !important;
}
.pink-amber.dark .dropzone button.mat-stroked-button {
  background: #424242;
}
.pink-amber.dark .firmador .content-action-main {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-amber.dark .firmador .content-action-main.disabled {
  color: rgba(255, 255, 255, 0.5) !important;
  background: rgba(255, 255, 255, 0.12);
}
.pink-amber.dark .sign-cycle {
  background: #424242;
}
.pink-amber.dark .sign-cycle button.success {
  background-color: #009688;
}
.pink-amber.dark .sign-cycle button.warning {
  background-color: #673AB7;
}
.pink-amber.dark .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-title-container .cycle-content-title h4 {
  color: #607D8B;
}
.pink-amber.dark .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-action .content-action-main {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-amber.dark .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-action .content-action-main.mat-stroked-button:disabled {
  color: rgba(255, 255, 255, 0.5) !important;
}
.pink-amber.dark .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-action .content-action-main.mat-stroked-button:not(:disabled) {
  color: #ffe082 !important;
}
.pink-amber.dark .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-action .content-action-side u {
  color: rgba(255, 255, 255, 0.5);
}
.pink-amber.dark .resume-status {
  background: #424242;
}
.pink-amber.dark .help-hoverable mat-icon {
  color: rgba(255, 255, 255, 0.7);
}
.pink-amber.dark .help-hoverable mat-hint {
  background: #424242;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(255, 255, 255, 0.12);
}
.pink-amber.dark .help-hoverable mat-hint b {
  color: #f48fb1 !important;
}
.pink-amber.dark app-file-document-sign-massive-dialog .dialog-content,
.pink-amber.dark app-share-person-dialog .dialog-content {
  background: #303030;
}
.pink-amber.dark app-file-document-sign-massive-dialog .dialog-content mat-toolbar mat-toolbar-row .mat-avatar h3,
.pink-amber.dark app-share-person-dialog .dialog-content mat-toolbar mat-toolbar-row .mat-avatar h3 {
  color: #f48fb1;
}
.pink-amber.dark app-file-document-sign-massive-dialog .dialog-content .dialog-body p b,
.pink-amber.dark app-share-person-dialog .dialog-content .dialog-body p b {
  color: #ffe082;
}
.pink-amber.dark app-file-document-sign-massive-dialog .dialog-content app-file-document-sign,
.pink-amber.dark app-share-person-dialog .dialog-content app-file-document-sign {
  background: #424242;
}
.pink-amber.dark .mat-basic-chip {
  background: black !important;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark .mat-basic-chip.seen {
  border-color: #2196F3 !important;
  color: #2196F3 !important;
}
.pink-amber.dark .mat-basic-chip.unseen {
  border-color: #607D8B !important;
  color: #607D8B !important;
}
.pink-amber.dark .mat-basic-chip.signed-ok {
  border-color: #009688 !important;
  color: #009688 !important;
}
.pink-amber.dark .mat-basic-chip.signed-ok.cancelled {
  border-color: #795548 !important;
  color: #795548 !important;
}
.pink-amber.dark .mat-basic-chip.signed-not-ok {
  border-color: #673AB7 !important;
  color: #673AB7 !important;
}
.pink-amber.dark .mat-basic-chip.not-signed {
  border-color: #ff4081 !important;
  color: #ff4081 !important;
}
.pink-amber.dark .mat-basic-chip.not-signed-employer {
  border-color: #ff4081 !important;
  color: #ff4081 !important;
}
.pink-amber.dark app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip {
  background: black !important;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.seen {
  border-color: #2196F3 !important;
  color: #2196F3 !important;
}
.pink-amber.dark app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.unseen {
  border-color: #607D8B !important;
  color: #607D8B !important;
}
.pink-amber.dark app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.signed-ok {
  border-color: #009688 !important;
  color: #009688 !important;
}
.pink-amber.dark app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.signed-ok.cancelled {
  border-color: #795548 !important;
  color: #795548 !important;
}
.pink-amber.dark app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.signed-not-ok {
  border-color: #673AB7 !important;
  color: #673AB7 !important;
}
.pink-amber.dark app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.not-signed {
  border-color: #ff4081 !important;
  color: #ff4081 !important;
}
.pink-amber.dark app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.not-signed-employer {
  border-color: #ff4081 !important;
  color: #ff4081 !important;
}
.pink-amber.dark .inbox-container.employee.my-documents .chip-line.mat-line .mat-chip-list-wrapper .mat-basic-chip.seen {
  color: #607D8B !important;
}
.pink-amber.dark .resume-status .mat-basic-chip {
  background: black !important;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark .resume-status .mat-basic-chip.signed-ok {
  border-color: #009688 !important;
  background-color: #009688 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-amber.dark .resume-status .mat-basic-chip.signed-ok.cancelled {
  border-color: #795548 !important;
  background-color: #795548 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-amber.dark .resume-status .mat-basic-chip.signed-not-ok {
  border-color: #673AB7 !important;
  background-color: #673AB7 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-amber.dark app-file-document-view .new-container app-file-document-upload {
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark app-file-document-view .new-container app-upload-form .file-upload-list .mat-subheader {
  background: #424242;
}
.pink-amber.dark app-file-document-view .new-container app-upload-form .file-upload-list mat-list-item {
  background: #303030 !important;
}
.pink-amber.dark app-file-document-view .preview-content {
  background: #607D8B;
}
.pink-amber.dark app-file-document-view .preview-content h4 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark app-file-document-view .view-container {
  background: #424242;
}
.pink-amber.dark app-file-document-view .view-container mat-toolbar.side-bar {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark .formio-hint {
  background: #f48fb1;
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark mat-dialog-container app-file-document-state-chips {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark app-candidate-promotion .load-window.message h3.primary-text {
  color: #f48fb1;
}
.pink-amber.dark app-multiple-documentation-add-dialog .icon-complex mat-icon,
.pink-amber.dark app-documentation-type-set-configuration-dialog .icon-complex mat-icon,
.pink-amber.dark app-add-candidate-dialog .icon-complex mat-icon,
.pink-amber.dark app-share-person-dialog .icon-complex mat-icon,
.pink-amber.dark app-share-person-response-dialog .icon-complex mat-icon {
  color: white !important;
}
.pink-amber.dark app-multiple-documentation-add-dialog .icon-complex mat-icon.complex,
.pink-amber.dark app-documentation-type-set-configuration-dialog .icon-complex mat-icon.complex,
.pink-amber.dark app-add-candidate-dialog .icon-complex mat-icon.complex,
.pink-amber.dark app-share-person-dialog .icon-complex mat-icon.complex,
.pink-amber.dark app-share-person-response-dialog .icon-complex mat-icon.complex {
  color: #f48fb1 !important;
}
.pink-amber.dark app-multiple-documentation-add-dialog .icon-complex mat-icon.complex.mat-accent,
.pink-amber.dark app-documentation-type-set-configuration-dialog .icon-complex mat-icon.complex.mat-accent,
.pink-amber.dark app-add-candidate-dialog .icon-complex mat-icon.complex.mat-accent,
.pink-amber.dark app-share-person-dialog .icon-complex mat-icon.complex.mat-accent,
.pink-amber.dark app-share-person-response-dialog .icon-complex mat-icon.complex.mat-accent {
  color: #ffe082;
}
.pink-amber.dark app-multiple-documentation-add-dialog .icon-complex mat-icon.complex.side,
.pink-amber.dark app-documentation-type-set-configuration-dialog .icon-complex mat-icon.complex.side,
.pink-amber.dark app-add-candidate-dialog .icon-complex mat-icon.complex.side,
.pink-amber.dark app-share-person-dialog .icon-complex mat-icon.complex.side,
.pink-amber.dark app-share-person-response-dialog .icon-complex mat-icon.complex.side {
  color: rgba(255, 255, 255, 0.7);
}
.pink-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection,
.pink-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection,
.pink-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection,
.pink-amber.dark app-share-person-dialog .dialog-content .document-template-container .document-selection,
.pink-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection {
  background: #303030;
}
.pink-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.pink-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.pink-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3,
.pink-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.pink-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.pink-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3,
.pink-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.pink-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.pink-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3,
.pink-amber.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.pink-amber.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.pink-amber.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3,
.pink-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.pink-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.pink-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3 {
  color: #ffe082 !important;
}
.pink-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon,
.pink-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon,
.pink-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon,
.pink-amber.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon,
.pink-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon {
  color: #f48fb1;
}
.pink-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3,
.pink-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3,
.pink-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3,
.pink-amber.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3,
.pink-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3 {
  color: #f48fb1;
}
.pink-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex,
.pink-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex,
.pink-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex,
.pink-amber.dark app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex,
.pink-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex {
  color: #ffe082 !important;
  text-shadow: -2px 0 0px black, 0 -2px 0px black, 2px 0 0px black, 0 2px 0px black, -2px -2px 0px black, 2px 2px 0px black, 2px -2px 0px black, -2px 2px 0px #424242;
}
.pink-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex,
.pink-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex,
.pink-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex,
.pink-amber.dark app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex,
.pink-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex {
  color: rgba(255, 255, 255, 0.5) !important;
}
.pink-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b,
.pink-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b,
.pink-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b,
.pink-amber.dark app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b,
.pink-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b {
  color: white;
}
.pink-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex,
.pink-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex,
.pink-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex,
.pink-amber.dark app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex,
.pink-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex {
  color: rgba(255, 255, 255, 0.5) !important;
}
.pink-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex,
.pink-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex,
.pink-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex,
.pink-amber.dark app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex,
.pink-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex {
  color: rgba(255, 255, 255, 0.5) !important;
}
.pink-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover,
.pink-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover,
.pink-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover,
.pink-amber.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover,
.pink-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover {
  background: rgba(255, 255, 255, 0.04);
}
.pink-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b,
.pink-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b,
.pink-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b,
.pink-amber.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b,
.pink-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b {
  color: white;
}
.pink-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.pink-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.pink-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.pink-amber.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.pink-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble {
  background: #f48fb1;
}
.pink-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble,
.pink-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble,
.pink-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble,
.pink-amber.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble,
.pink-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble {
  background: #e57373;
}
.pink-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble,
.pink-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble,
.pink-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble,
.pink-amber.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble,
.pink-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble {
  color: rgba(255, 255, 255, 0.5);
  background: rgba(255, 255, 255, 0.12);
}
.pink-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble,
.pink-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble,
.pink-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble,
.pink-amber.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble,
.pink-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble {
  background: rgba(255, 255, 255, 0.5);
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide,
.pink-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide,
.pink-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide,
.pink-amber.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide,
.pink-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide {
  border-color: rgba(255, 255, 255, 0.12);
}
.pink-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble,
.pink-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble,
.pink-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble,
.pink-amber.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble,
.pink-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble {
  color: rgba(255, 255, 255, 0.7);
}
.pink-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.pink-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.pink-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.pink-amber.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.pink-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble {
  color: #f48fb1;
}
.pink-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble,
.pink-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble,
.pink-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble,
.pink-amber.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble,
.pink-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble {
  color: #e57373;
}
.pink-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar,
.pink-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar,
.pink-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar,
.pink-amber.dark app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar,
.pink-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar {
  background: #607D8B;
}
.pink-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row,
.pink-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row,
.pink-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row,
.pink-amber.dark app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row,
.pink-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper,
.pink-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper,
.pink-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper,
.pink-amber.dark app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper,
.pink-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper {
  background: #607D8B;
}
.pink-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop,
.pink-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop,
.pink-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop,
.pink-amber.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop,
.pink-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop {
  border-left: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body,
.pink-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body,
.pink-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body,
.pink-amber.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body,
.pink-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body {
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3,
.pink-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3,
.pink-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3,
.pink-amber.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3,
.pink-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3 {
  color: #ffe082;
}
.pink-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5,
.pink-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5,
.pink-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5,
.pink-amber.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5,
.pink-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5 {
  color: rgba(255, 255, 255, 0.7);
}
.pink-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5,
.pink-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5,
.pink-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5,
.pink-amber.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5,
.pink-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5 {
  color: rgba(255, 255, 255, 0.7);
}
.pink-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator,
.pink-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator,
.pink-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator,
.pink-amber.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator,
.pink-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  background: #303030;
}
.pink-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip,
.pink-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip,
.pink-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip,
.pink-amber.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip,
.pink-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip {
  background: #424242 !important;
}
.pink-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon,
.pink-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon,
.pink-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon,
.pink-amber.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon,
.pink-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon {
  color: #ffe082 !important;
}
.pink-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3,
.pink-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3,
.pink-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3,
.pink-amber.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3,
.pink-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3 {
  color: #ffe082 !important;
}
.pink-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5,
.pink-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5,
.pink-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5,
.pink-amber.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5,
.pink-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5 {
  color: rgba(255, 255, 255, 0.7) !important;
}
.pink-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon,
.pink-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon,
.pink-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon,
.pink-amber.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon,
.pink-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon {
  color: #f48fb1 !important;
}
.pink-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3,
.pink-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3,
.pink-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3,
.pink-amber.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3,
.pink-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3 {
  color: #f48fb1 !important;
}
.pink-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5,
.pink-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5,
.pink-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5,
.pink-amber.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5,
.pink-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5 {
  color: #f48fb1 !important;
}
.pink-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled,
.pink-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled,
.pink-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled,
.pink-amber.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled,
.pink-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled {
  background: #303030 !important;
  color: rgba(255, 255, 255, 0.5);
}
.pink-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove,
.pink-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove,
.pink-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove,
.pink-amber.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove,
.pink-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove {
  color: rgba(255, 255, 255, 0.7);
}
.pink-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove,
.pink-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove,
.pink-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove,
.pink-amber.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove,
.pink-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove {
  color: rgba(255, 255, 255, 0.7) !important;
}
.pink-amber.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .preview-action,
.pink-amber.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .preview-action,
.pink-amber.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .preview-action,
.pink-amber.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .preview-action,
.pink-amber.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .preview-action {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark .tyc .sticky {
  background: #424242;
}
.pink-amber.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper:nth-child(odd) datatable-body-row {
  background: #303030;
}
.pink-amber.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active * {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-amber.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active:hover .datatable-body-cell-label {
  background: #f48fb1;
}
.pink-amber.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active:hover .cs-floating-action {
  background: #f48fb1 !important;
}
.pink-amber.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active.row-cancel * {
  color: rgba(255, 255, 255, 0.7) !important;
}
.pink-amber.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active.row-cancel .datatable-row-group {
  background: #607D8B !important;
}
.pink-amber.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active.row-cancel:hover .datatable-body-cell-label {
  background: #607D8B;
}
.pink-amber.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.row-cancel * {
  color: #b0bec5 !important;
  transition-property: color;
  transition-duration: 0.3s;
  transition-timing-function: linear;
}
.pink-amber.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row:hover .datatable-body-cell-label button {
  background: black;
}
.pink-amber.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row datatable-body-cell.candidate-status_bar mat-progress-bar .mat-progress-bar-buffer {
  background-color: black;
}
.pink-amber.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row datatable-body-cell.candidate-status_bar .status_bar-count {
  color: rgba(255, 255, 255, 0.7);
}
.pink-amber.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row datatable-body-cell.candidate-status_bar .inactive {
  color: rgba(255, 255, 255, 0.7);
}
.pink-amber.dark .cs-grid-test .info {
  background: #424242;
}
.pink-amber.dark .cs-grid-test .selected-column {
  background: #424242;
  -webkit-box-shadow: 0 1px 3px 0 rgba(255, 255, 255, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0 1px 3px 0 rgba(255, 255, 255, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 3px 0 rgba(255, 255, 255, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
}
.pink-amber.dark .cs-grid-test .selection-cell .datatable-body-cell.active {
  background: #f48fb1 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-amber.dark .cs-grid-test nav {
  background: #424242;
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 13px 19px 2px rgba(0, 0, 0, 0.14), 0 5px 24px 4px rgba(255, 255, 255, 0.12);
}
.pink-amber.dark .cs-grid-test .ngx-datatable.material {
  background: #424242;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(255, 255, 255, 0.12);
}
.pink-amber.dark .cs-grid-test .ngx-datatable.material.striped .datatable-row-odd {
  background: #303030;
}
.pink-amber.dark .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active, .pink-amber.dark .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active .datatable-row-group, .pink-amber.dark .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active, .pink-amber.dark .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active .datatable-row-group, .pink-amber.dark .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active, .pink-amber.dark .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active .datatable-row-group {
  background: #f48fb1;
  color: #424242;
}
.pink-amber.dark .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active:hover, .pink-amber.dark .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active:hover .datatable-row-group, .pink-amber.dark .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active:hover, .pink-amber.dark .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active:hover .datatable-row-group, .pink-amber.dark .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active:hover, .pink-amber.dark .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active:hover .datatable-row-group {
  background: #f48fb1;
  color: #424242;
}
.pink-amber.dark .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active:focus, .pink-amber.dark .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active:focus .datatable-row-group, .pink-amber.dark .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active:focus, .pink-amber.dark .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active:focus .datatable-row-group, .pink-amber.dark .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active:focus, .pink-amber.dark .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active:focus .datatable-row-group {
  background: #f48fb1;
  color: #424242;
}
.pink-amber.dark .cs-grid-test .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover, .pink-amber.dark .cs-grid-test .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: rgba(255, 255, 255, 0.04);
}
.pink-amber.dark .cs-grid-test .ngx-datatable.material:not(.cell-selection) .datatable-body-row:focus, .pink-amber.dark .cs-grid-test .ngx-datatable.material:not(.cell-selection) .datatable-body-row:focus .datatable-row-group {
  background: rgba(255, 255, 255, 0.04);
}
.pink-amber.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell:hover, .pink-amber.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell:hover .datatable-row-group {
  background: rgba(255, 255, 255, 0.04);
}
.pink-amber.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell:focus, .pink-amber.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell:focus .datatable-row-group {
  background: rgba(255, 255, 255, 0.04);
}
.pink-amber.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active, .pink-amber.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active .datatable-row-group {
  background: #f48fb1;
  color: #424242;
}
.pink-amber.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active:hover, .pink-amber.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active:hover .datatable-row-group {
  background: #f48fb1;
  color: #424242;
}
.pink-amber.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active:focus, .pink-amber.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active:focus .datatable-row-group {
  background: #f48fb1;
  color: #424242;
}
.pink-amber.dark .cs-grid-test .ngx-datatable.material .datatable-header .datatable-row-left,
.pink-amber.dark .cs-grid-test .ngx-datatable.material .datatable-body .datatable-row-left {
  background-color: #424242;
}
.pink-amber.dark .cs-grid-test .ngx-datatable.material .datatable-header .datatable-row-right,
.pink-amber.dark .cs-grid-test .ngx-datatable.material .datatable-body .datatable-row-right {
  background-color: #424242;
}
.pink-amber.dark .cs-grid-test .ngx-datatable.material .datatable-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.pink-amber.dark .cs-grid-test .ngx-datatable.material .datatable-header .datatable-header-cell {
  background-color: #424242;
  color: rgba(255, 255, 255, 0.7);
}
.pink-amber.dark .cs-grid-test .ngx-datatable.material .datatable-header .resize-handle {
  border-right: solid 1px rgba(255, 255, 255, 0.04);
}
.pink-amber.dark .cs-grid-test .ngx-datatable.material .datatable-body .datatable-row-detail {
  background: black;
}
.pink-amber.dark .cs-grid-test .ngx-datatable.material .datatable-body .datatable-group-header {
  background: black;
  border-bottom: solid 1px rgba(255, 255, 255, 0.12);
  border-top: solid 1px rgba(255, 255, 255, 0.12);
}
.pink-amber.dark .cs-grid-test .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
  color: white;
}
.pink-amber.dark .cs-grid-test .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-group-cell {
  color: white;
}
.pink-amber.dark .cs-grid-test .ngx-datatable.material .datatable-body .progress-linear .container {
  background-color: #424242;
}
.pink-amber.dark .cs-grid-test .ngx-datatable.material .datatable-body .progress-linear .container .bar {
  background-color: #f48fb1;
}
.pink-amber.dark .cs-grid-test .ngx-datatable.material .datatable-footer {
  color: rgba(255, 255, 255, 0.7);
}
.pink-amber.dark .cs-grid-test .ngx-datatable.material .datatable-footer .datatable-pager li.disabled a {
  color: rgba(255, 255, 255, 0.5) !important;
  background-color: rgba(0, 0, 0, 0) !important;
}
.pink-amber.dark .cs-grid-test .ngx-datatable.material .datatable-footer .datatable-pager li.active a {
  background-color: #f48fb1;
}
.pink-amber.dark .cs-grid-test .ngx-datatable.material .datatable-footer .datatable-pager a {
  color: rgba(255, 255, 255, 0.7);
}
.pink-amber.dark .cs-grid-test .ngx-datatable.material .datatable-footer .datatable-pager a:hover {
  color: rgba(255, 255, 255, 0.04);
  background-color: rgba(255, 255, 255, 0.04);
}
.pink-amber.dark .cs-grid-test .ngx-datatable.material .datatable-summary-row .datatable-body-row {
  background-color: #303030;
}
.pink-amber.dark .cs-grid-test .ngx-datatable.material .datatable-summary-row .datatable-body-row:hover {
  background-color: #303030;
}
.pink-amber.dark .cs-grid-test .datatable-checkbox input[type=checkbox]:after {
  background: #424242;
}
.pink-amber.dark .view-container .view-toolbar {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal.new.image-opened .help-content img {
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card {
  background: #424242;
}
.pink-amber.dark app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container .aditional-info {
  color: rgba(255, 255, 255, 0.7);
}
.pink-amber.dark app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container .mat-card-footer {
  background: #424242;
}
.pink-amber.dark app-help-index mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card {
  background: #424242;
}
.pink-amber.dark app-help-index mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card .mat-card-footer {
  background: #424242;
}
.pink-amber.dark .cropper .employee-title .title-text {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-amber.dark .cropper app-cs-upload-cropper-control .preview h5 {
  color: rgba(255, 255, 255, 0.7);
}
.pink-amber.dark app-file-document-upload .preview-container {
  background: #607D8B !important;
}
.pink-amber.dark app-file-document-upload .preview-container h4 {
  color: black;
}
.pink-amber.dark .preview-container {
  background: #607D8B !important;
}
.pink-amber.dark .preview-container h4 {
  color: black;
}
.pink-amber.dark .clean-autocomple mat-list {
  background: #424242;
}
.pink-amber.dark .clean-autocomple mat-list .mat-list-item:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-amber.dark .clean-autocomple mat-list .mat-list-item .mat-list-item-content .mat-list-text {
  color: #f48fb1;
}
.pink-amber.dark app-leave .inbox-toolbar {
  background: #303030;
}
.pink-amber.dark app-leave .mat-tab-body-wrapper {
  position: relative;
  overflow: hidden;
  display: flex;
  transition: height 500ms cubic-bezier(0.35, 0, 0.25, 1);
  height: 95% !important;
}
.pink-amber.dark app-leave .leave_container {
  background: #303030;
}
.pink-amber.dark app-leave .leave_container .leave_items .leave_items-item.ok .content .leave_item-status_icon {
  color: #4db6ac;
}
.pink-amber.dark app-leave .leave_container .leave_items .leave_items-item.not-ok .content .leave_item-status_icon {
  color: #ba68c8;
}
.pink-amber.dark app-leave .leave_container .leave_items .leave_items-item.pending .content .leave_item-status_icon {
  color: #ffb74d;
}
.pink-amber.dark app-leave .leave_container .leave_items .leave_items-item.draft .content .leave_item-status_icon {
  color: rgba(255, 255, 255, 0.7);
}
.pink-amber.dark app-leave .leave_container .leave_items .leave_items-item.cancel .content .leave_item-status_icon {
  color: #795548;
}
.pink-amber.dark app-leave .leave_container .leave_items .leave_items-item .content:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-amber.dark app-leave .leave_container .leave_items .leave_items-item .content.selected {
  border-left: 6px solid #ffe082 !important;
}
.pink-amber.dark app-leave .leave_container .leave_items .leave_items-item .content.selected mat-icon {
  color: #ffe082 !important;
}
.pink-amber.dark app-leave .leave_container .leave_items .leave_items-item .content.selected .item-data {
  color: white !important;
}
.pink-amber.dark app-leave .leave_container .leave_items .leave_items-item .content .leave_item-icon {
  color: rgba(255, 255, 255, 0.7);
}
.pink-amber.dark app-leave .leave_container .leave_items .leave_items-item .content .item-data {
  color: rgba(255, 255, 255, 0.7);
}
.pink-amber.dark app-leave .leave_container .window-spinner {
  background: #303030 !important;
}
.pink-amber.dark app-leave-detail .leave_detail .leave_detail-title .info h3,
.pink-amber.dark app-leave-detail .leave_detail .leave_detail-title .info mat-icon {
  color: #f48fb1;
}
.pink-amber.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .ok .large-icon {
  background: #e0f2f1;
  color: #4db6ac;
}
.pink-amber.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .ok h2 {
  color: #4db6ac;
}
.pink-amber.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .not-ok .large-icon {
  background: #f3e5f5;
  color: #ba68c8;
}
.pink-amber.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .not-ok h2 {
  color: #ba68c8;
}
.pink-amber.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .pending .large-icon {
  background: #fff3e0;
  color: #ffb74d;
}
.pink-amber.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .pending h2 {
  color: #ffb74d;
}
.pink-amber.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .draft .large-icon {
  background: #f2f2f2;
  color: #dedede;
}
.pink-amber.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .draft h2 {
  color: #dedede;
}
.pink-amber.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .cancelled .large-icon {
  background: #BCAAA4;
  color: #795548;
}
.pink-amber.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .cancelled h2 {
  color: #795548;
}
.pink-amber.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .actions {
  padding: 16px;
  height: 96px;
  column-gap: 16px;
}
.pink-amber.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .actions button {
  height: 100%;
  border-radius: 40px;
}
.pink-amber.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .actions button.ok {
  background: #4db6ac !important;
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .actions button.not-ok {
  color: #ba68c8 !important;
}
.pink-amber.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning mat-basic-chip {
  background: black !important;
  border: 1px solid rgba(255, 255, 255, 0.12) !important;
  border-radius: 40px !important;
  height: 35px !important;
}
.pink-amber.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning mat-basic-chip.signed-ok {
  border-color: #4db6ac !important;
  color: #4db6ac !important;
}
.pink-amber.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning mat-basic-chip.signed-not-ok {
  border-color: #ba68c8 !important;
  color: #ba68c8 !important;
}
.pink-amber.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning mat-basic-chip.draft {
  border-color: #dedede !important;
  color: #dedede !important;
}
.pink-amber.dark app-leave-request-detail .load-window {
  background: #303030;
}
.pink-amber.dark app-leave-request-detail form .leave_detail-container {
  background: #303030;
}
.pink-amber.dark app-leave-request-detail form .leave_detail-container .toolbar .header {
  color: #f48fb1;
}
.pink-amber.dark app-leave-request-detail form .leave_detail-container .toolbar .header .text h3 {
  color: #f48fb1;
}
.pink-amber.dark app-leave-request-detail form .leave_detail-container .toolbar .header .text h5 {
  color: rgba(255, 255, 255, 0.7);
}
.pink-amber.dark app-leave-request-detail form .leave_detail-container .leave_detail-container-content .leave_resume {
  background: #424242;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark app-leave-request-detail form .leave_detail-container .leave_detail-container-content .line h6 {
  color: rgba(255, 255, 255, 0.7);
}
.pink-amber.dark app-leave-request-detail form .leave_detail-container .actions button.ok {
  background: #4db6ac !important;
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark app-leave-request-detail form .leave_detail-container .actions button.not-ok {
  color: #ba68c8 !important;
}
.pink-amber.dark .new_leave_modal-steps {
  display: unset !important;
}
.pink-amber.dark .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button {
  background: #303030;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button .mat-radio-label .mat-radio-label-content .side-text {
  color: rgba(255, 255, 255, 0.7);
}
.pink-amber.dark .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button .mat-radio-label .mat-radio-label-content .side-text.muted {
  color: rgba(255, 255, 255, 0.5);
}
.pink-amber.dark .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button .mat-radio-label .mat-radio-label-content .accent mat-icon,
.pink-amber.dark .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button .mat-radio-label .mat-radio-label-content .accent .main-text {
  color: #ffe082;
}
.pink-amber.dark .config_leave-container .config_leave-card {
  background: #424242;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark .config_leave-container .config_leave-card .config_leave-auto_chip mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #303030 !important;
}
.pink-amber.dark .config_leave-container .config_leave-card .config_leave-auto_chip mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(255, 255, 255, 0.5);
}
.pink-amber.dark .config_leave-container .config_leave-card .config_leave-auto_chip mat-form-field .mat-form-field-flex {
  background: #303030;
}
.pink-amber.dark *[color=success] {
  color: #009688;
}
.pink-amber.dark .primary {
  color: #f48fb1 !important;
}
.pink-amber.dark .accent {
  color: #ffe082 !important;
}
.pink-amber.dark app-lsd .icon-complex mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.pink-amber.dark app-lsd .icon-complex mat-icon.complex {
  color: #f48fb1 !important;
}
.pink-amber.dark app-lsd .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.pink-amber.dark app-lsd .work-space .main .load-window {
  background: #424242 !important;
}
.pink-amber.dark app-lsd .work-space .main.side-open {
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark app-lsd .work-space .main .container .item {
  background: #303030;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark app-lsd .work-space .main .container .item h5 {
  color: rgba(255, 255, 255, 0.5);
}
.pink-amber.dark app-lsd .work-space .main .container .item h3 {
  color: white;
}
.pink-amber.dark app-lsd .work-space .main .container .item:hover {
  background: rgba(255, 255, 255, 0.04);
}
.pink-amber.dark app-lsd .work-space .main .container .item.selected {
  background: #f48fb1;
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-amber.dark app-lsd .work-space .main .container .item.selected h3 {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-amber.dark app-lsd .work-space .main .container .item.selected.pending .status {
  background: rgba(255, 255, 255, 0.12) !important;
  color: #ffe082;
}
.pink-amber.dark app-lsd .work-space .main .container .item.selected.ok .status {
  background: rgba(255, 255, 255, 0.12) !important;
  color: #a5d6a7;
}
.pink-amber.dark app-lsd .work-space .main .container .item.selected.not-ok .status {
  background: rgba(255, 255, 255, 0.12) !important;
  color: #e57373;
}
.pink-amber.dark app-lsd .work-space .main .main-action {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark app-lsd .work-space .side {
  background: #303030;
}
.pink-amber.dark app-lsd .work-space .side .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #303030, 0 -2px 0px #303030, 2px 0 0px #303030, 0 2px 0px #303030, -2px -2px 0px #303030, 2px 2px 0px #303030, 2px -2px 0px #303030, -2px 2px 0px #303030;
}
.pink-amber.dark app-lsd .work-space .side *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.pink-amber.dark app-lsd .work-space .side *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
.pink-amber.dark app-lsd .work-space .side .edition-line {
  background: black;
}
.pink-amber.dark app-lsd .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #303030 !important;
}
.pink-amber.dark app-lsd .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(255, 255, 255, 0.5);
}
.pink-amber.dark app-lsd .work-space .side mat-form-field .mat-form-field-flex {
  background: #303030;
}
.pink-amber.dark app-lsd .work-space .side mat-checkbox:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-amber.dark app-lsd .work-space .side .hint.all {
  color: #ffe082;
}
.pink-amber.dark app-lsd .work-space .side .toolbar .header {
  color: #f48fb1;
}
.pink-amber.dark app-lsd .work-space .side .toolbar .header h3 {
  color: #f48fb1;
}
.pink-amber.dark app-lsd .work-space .side .container .doc-resume {
  background: #f48fb1;
}
.pink-amber.dark app-lsd .work-space .side .container .doc-resume .line {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark app-lsd .work-space .side .container .doc-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.1);
}
.pink-amber.dark app-lsd .work-space .side .container .doc-resume .line.editable button {
  background: #f48fb1;
}
.pink-amber.dark app-lsd .work-space .side .container .section-title {
  color: #f48fb1;
}
.pink-amber.dark app-lsd .work-space .side .container .section-resume {
  background: #424242;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark app-lsd .work-space .side .container .section-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-amber.dark app-lsd .work-space .side .container .section-resume .line.editable:hover .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #424242, 0 -2px 0px #424242, 2px 0 0px #424242, 0 2px 0px #424242, -2px -2px 0px #424242, 2px 2px 0px #424242, 2px -2px 0px #424242, -2px 2px 0px #424242 !important;
}
.pink-amber.dark app-lsd .work-space .side .container .section-resume .line.editable button {
  background: #303030;
}
.pink-amber.dark app-lsd .work-space .side .container .section-resume .line .header .icon-complex mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.pink-amber.dark app-lsd .work-space .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #f48fb1 !important;
}
.pink-amber.dark app-lsd .work-space .side .container .section-resume .line .header mat-icon:not(.complex) {
  color: rgba(255, 255, 255, 0.7);
}
.pink-amber.dark app-lsd .work-space .side .container .section-resume .line .header .info h4 {
  color: #f48fb1;
}
.pink-amber.dark app-lsd .work-space .side .container .section-resume .line ul {
  color: #f48fb1;
}
.pink-amber.dark app-lsd .work-space .side .container .section-resume .line.head {
  background: black;
}
.pink-amber.dark app-lsd .work-space .side .container .section-resume .line.head mat-icon {
  color: #ffe082;
}
.pink-amber.dark app-lsd .work-space .side .container .section-resume .line.head h4 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark app-lsd .work-space .side.edition .toolbar .header {
  color: rgba(0, 0, 0, 0.87);
}
.pink-amber.dark app-lsd .work-space .side.edition .toolbar .header mat-icon {
  color: #ffe082;
}
.pink-amber.dark app-lsd .work-space .side.edition .toolbar .header h3 {
  color: #ffe082;
}
.pink-amber.dark .dialog-content {
  max-height: 90vh;
}
.pink-amber.dark .dialog-content mat-toolbar {
  background: #424242;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark .dialog-content .dialog-container .document-template-container .document-selection mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #303030 !important;
}
.pink-amber.dark .dialog-content .dialog-container .document-template-container .document-selection mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(255, 255, 255, 0.5);
}
.pink-amber.dark .dialog-content .dialog-container .document-template-container .document-selection mat-form-field .mat-form-field-flex {
  background: #303030;
}
.pink-amber.dark .dialog-content .dialog-container .document-template-container .document-selection app-upload-form .file-upload-list .mat-subheader::before {
  color: #f48fb1;
}
.pink-amber.dark .dialog-content .dialog-container .view-container {
  border-left: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-amber.dark .dialog-content .form-actions {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}

.pink-blueGrey .mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-blueGrey .mat-badge-small .mat-badge-content {
  font-size: 9px;
}
.pink-blueGrey .mat-badge-large .mat-badge-content {
  font-size: 24px;
}
.pink-blueGrey .mat-h1,
.pink-blueGrey .mat-headline,
.pink-blueGrey .mat-typography .mat-h1,
.pink-blueGrey .mat-typography .mat-headline,
.pink-blueGrey .mat-typography h1 {
  font: 400 24px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.pink-blueGrey .mat-h2,
.pink-blueGrey .mat-title,
.pink-blueGrey .mat-typography .mat-h2,
.pink-blueGrey .mat-typography .mat-title,
.pink-blueGrey .mat-typography h2 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.pink-blueGrey .mat-h3,
.pink-blueGrey .mat-subheading-2,
.pink-blueGrey .mat-typography .mat-h3,
.pink-blueGrey .mat-typography .mat-subheading-2,
.pink-blueGrey .mat-typography h3 {
  font: 400 16px / 28px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.pink-blueGrey .mat-h4,
.pink-blueGrey .mat-subheading-1,
.pink-blueGrey .mat-typography .mat-h4,
.pink-blueGrey .mat-typography .mat-subheading-1,
.pink-blueGrey .mat-typography h4 {
  font: 400 15px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.pink-blueGrey .mat-h5,
.pink-blueGrey .mat-typography .mat-h5,
.pink-blueGrey .mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}
.pink-blueGrey .mat-h6,
.pink-blueGrey .mat-typography .mat-h6,
.pink-blueGrey .mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}
.pink-blueGrey .mat-body-strong,
.pink-blueGrey .mat-body-2,
.pink-blueGrey .mat-typography .mat-body-strong,
.pink-blueGrey .mat-typography .mat-body-2 {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.pink-blueGrey .mat-body,
.pink-blueGrey .mat-body-1,
.pink-blueGrey .mat-typography .mat-body,
.pink-blueGrey .mat-typography .mat-body-1,
.pink-blueGrey .mat-typography {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.pink-blueGrey .mat-body p,
.pink-blueGrey .mat-body-1 p,
.pink-blueGrey .mat-typography .mat-body p,
.pink-blueGrey .mat-typography .mat-body-1 p,
.pink-blueGrey .mat-typography p {
  margin: 0 0 12px;
}
.pink-blueGrey .mat-small,
.pink-blueGrey .mat-caption,
.pink-blueGrey .mat-typography .mat-small,
.pink-blueGrey .mat-typography .mat-caption {
  font: 400 12px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.pink-blueGrey .mat-display-4,
.pink-blueGrey .mat-typography .mat-display-4 {
  font: 300 112px / 112px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}
.pink-blueGrey .mat-display-3,
.pink-blueGrey .mat-typography .mat-display-3 {
  font: 400 56px / 56px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}
.pink-blueGrey .mat-display-2,
.pink-blueGrey .mat-typography .mat-display-2 {
  font: 400 45px / 48px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}
.pink-blueGrey .mat-display-1,
.pink-blueGrey .mat-typography .mat-display-1 {
  font: 400 34px / 40px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}
.pink-blueGrey .mat-bottom-sheet-container {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.pink-blueGrey .mat-button, .pink-blueGrey .mat-raised-button, .pink-blueGrey .mat-icon-button, .pink-blueGrey .mat-stroked-button,
.pink-blueGrey .mat-flat-button, .pink-blueGrey .mat-fab, .pink-blueGrey .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.pink-blueGrey .mat-button-toggle {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-blueGrey .mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-blueGrey .mat-card-title {
  font-size: 24px;
  font-weight: 500;
}
.pink-blueGrey .mat-card-header .mat-card-title {
  font-size: 20px;
}
.pink-blueGrey .mat-card-subtitle,
.pink-blueGrey .mat-card-content {
  font-size: 14px;
}
.pink-blueGrey .mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-blueGrey .mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}
.pink-blueGrey .mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.pink-blueGrey .mat-chip .mat-chip-trailing-icon.mat-icon,
.pink-blueGrey .mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}
.pink-blueGrey .mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-blueGrey .mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}
.pink-blueGrey .mat-cell, .pink-blueGrey .mat-footer-cell {
  font-size: 14px;
}
.pink-blueGrey .mat-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-blueGrey .mat-calendar-body {
  font-size: 13px;
}
.pink-blueGrey .mat-calendar-body-label,
.pink-blueGrey .mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}
.pink-blueGrey .mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}
.pink-blueGrey .mat-dialog-title {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.pink-blueGrey .mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400;
}
.pink-blueGrey .mat-expansion-panel-content {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.pink-blueGrey .mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.pink-blueGrey .mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}
.pink-blueGrey .mat-form-field-prefix .mat-icon,
.pink-blueGrey .mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.pink-blueGrey .mat-form-field-prefix .mat-icon-button,
.pink-blueGrey .mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.pink-blueGrey .mat-form-field-prefix .mat-icon-button .mat-icon,
.pink-blueGrey .mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}
.pink-blueGrey .mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}
.pink-blueGrey .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.pink-blueGrey .mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34347em) scale(0.75);
  width: 133.3336133333%;
}
.pink-blueGrey .mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34346em) scale(0.75);
  width: 133.3336233333%;
}
.pink-blueGrey .mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}
.pink-blueGrey .mat-form-field-label {
  top: 1.34375em;
}
.pink-blueGrey .mat-form-field-underline {
  bottom: 1.34375em;
}
.pink-blueGrey .mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}
.pink-blueGrey .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.pink-blueGrey .mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.pink-blueGrey .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.pink-blueGrey .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00184px);
  width: 133.3341733333%;
}
.pink-blueGrey .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00185px);
  width: 133.3341833333%;
}
.pink-blueGrey .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00186px);
  width: 133.3341933333%;
}
.pink-blueGrey .mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.pink-blueGrey .mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.pink-blueGrey .mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}
@media print {
  .pink-blueGrey .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.pink-blueGrey .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28038em) scale(0.75);
  }
  .pink-blueGrey .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28037em) scale(0.75);
  }
  .pink-blueGrey .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28036em) scale(0.75);
  }
}
.pink-blueGrey .mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.pink-blueGrey .mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.pink-blueGrey .mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.pink-blueGrey .mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59347em) scale(0.75);
  width: 133.3336133333%;
}
.pink-blueGrey .mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59346em) scale(0.75);
  width: 133.3336233333%;
}
.pink-blueGrey .mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.pink-blueGrey .mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.pink-blueGrey .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.pink-blueGrey .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59347em) scale(0.75);
  width: 133.3336133333%;
}
.pink-blueGrey .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59346em) scale(0.75);
  width: 133.3336233333%;
}
.pink-blueGrey .mat-grid-tile-header,
.pink-blueGrey .mat-grid-tile-footer {
  font-size: 14px;
}
.pink-blueGrey .mat-grid-tile-header .mat-line,
.pink-blueGrey .mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.pink-blueGrey .mat-grid-tile-header .mat-line:nth-child(n+2),
.pink-blueGrey .mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.pink-blueGrey input.mat-input-element {
  margin-top: -0.0625em;
}
.pink-blueGrey .mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
}
.pink-blueGrey .mat-paginator,
.pink-blueGrey .mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
}
.pink-blueGrey .mat-radio-button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-blueGrey .mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-blueGrey .mat-select-trigger {
  height: 1.125em;
}
.pink-blueGrey .mat-slide-toggle-content {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-blueGrey .mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}
.pink-blueGrey .mat-stepper-vertical, .pink-blueGrey .mat-stepper-horizontal {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-blueGrey .mat-step-label {
  font-size: 14px;
  font-weight: 400;
}
.pink-blueGrey .mat-step-sub-label-error {
  font-weight: normal;
}
.pink-blueGrey .mat-step-label-error {
  font-size: 14px;
}
.pink-blueGrey .mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}
.pink-blueGrey .mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-blueGrey .mat-tab-label, .pink-blueGrey .mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.pink-blueGrey .mat-toolbar,
.pink-blueGrey .mat-toolbar h1,
.pink-blueGrey .mat-toolbar h2,
.pink-blueGrey .mat-toolbar h3,
.pink-blueGrey .mat-toolbar h4,
.pink-blueGrey .mat-toolbar h5,
.pink-blueGrey .mat-toolbar h6 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0;
}
.pink-blueGrey .mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.pink-blueGrey .mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.pink-blueGrey .mat-list-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-blueGrey .mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-blueGrey .mat-list-base .mat-list-item {
  font-size: 16px;
}
.pink-blueGrey .mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.pink-blueGrey .mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.pink-blueGrey .mat-list-base .mat-list-option {
  font-size: 16px;
}
.pink-blueGrey .mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.pink-blueGrey .mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.pink-blueGrey .mat-list-base .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.pink-blueGrey .mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.pink-blueGrey .mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.pink-blueGrey .mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.pink-blueGrey .mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.pink-blueGrey .mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.pink-blueGrey .mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.pink-blueGrey .mat-list-base[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}
.pink-blueGrey .mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
}
.pink-blueGrey .mat-optgroup-label {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.pink-blueGrey .mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
}
.pink-blueGrey .mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}
.pink-blueGrey .mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-blueGrey .mat-tree-node,
.pink-blueGrey .mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}
.pink-blueGrey .mat-ripple {
  overflow: hidden;
  position: relative;
}
.pink-blueGrey .mat-ripple:not(:empty) {
  transform: translateZ(0);
}
.pink-blueGrey .mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}
.pink-blueGrey .mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .pink-blueGrey .mat-ripple-element {
  display: none;
}

.pink-blueGrey .cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .pink-blueGrey .cdk-visually-hidden {
  left: auto;
  right: 0;
}
.pink-blueGrey .cdk-overlay-container, .pink-blueGrey .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.pink-blueGrey .cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.pink-blueGrey .cdk-overlay-container:empty {
  display: none;
}
.pink-blueGrey .cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}
.pink-blueGrey .cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}
.pink-blueGrey .cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.pink-blueGrey .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .pink-blueGrey .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}
.pink-blueGrey .cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}
.pink-blueGrey .cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.pink-blueGrey .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}
.pink-blueGrey .cdk-overlay-backdrop-noop-animation {
  transition: none;
}
.pink-blueGrey .cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}
.pink-blueGrey .cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}
.pink-blueGrey textarea.cdk-textarea-autosize {
  resize: none;
}
.pink-blueGrey textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}
.pink-blueGrey textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}
@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.pink-blueGrey .cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}
.pink-blueGrey .cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}
.pink-blueGrey .mat-focus-indicator {
  position: relative;
}
.pink-blueGrey .mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.pink-blueGrey .mat-focus-indicator:focus::before {
  content: "";
}
.cdk-high-contrast-active .pink-blueGrey {
  --mat-focus-indicator-display: block;
}

.pink-blueGrey .mat-mdc-focus-indicator {
  position: relative;
}
.pink-blueGrey .mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.pink-blueGrey .mat-mdc-focus-indicator:focus::before {
  content: "";
}
.cdk-high-contrast-active .pink-blueGrey {
  --mat-mdc-focus-indicator-display: block;
}

.pink-blueGrey .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.pink-blueGrey .mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey .mat-option:hover:not(.mat-option-disabled), .pink-blueGrey .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.pink-blueGrey .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.pink-blueGrey .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.pink-blueGrey .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #e91e63;
}
.pink-blueGrey .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #607d8b;
}
.pink-blueGrey .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f44336;
}
.pink-blueGrey .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}
.pink-blueGrey .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.pink-blueGrey .mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.pink-blueGrey .mat-pseudo-checkbox::after {
  color: #fafafa;
}
.pink-blueGrey .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.pink-blueGrey .mat-primary .mat-pseudo-checkbox-checked,
.pink-blueGrey .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #e91e63;
}
.pink-blueGrey .mat-pseudo-checkbox-checked,
.pink-blueGrey .mat-pseudo-checkbox-indeterminate,
.pink-blueGrey .mat-accent .mat-pseudo-checkbox-checked,
.pink-blueGrey .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #607d8b;
}
.pink-blueGrey .mat-warn .mat-pseudo-checkbox-checked,
.pink-blueGrey .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #f44336;
}
.pink-blueGrey .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.pink-blueGrey .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.pink-blueGrey .mat-app-background, .pink-blueGrey.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.pink-blueGrey .mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.pink-blueGrey .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey .mat-badge-content {
  color: white;
  background: #e91e63;
}
.cdk-high-contrast-active .pink-blueGrey .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.pink-blueGrey .mat-badge-accent .mat-badge-content {
  background: #607d8b;
  color: white;
}
.pink-blueGrey .mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336;
}
.pink-blueGrey .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.pink-blueGrey .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey .mat-button, .pink-blueGrey .mat-icon-button, .pink-blueGrey .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.pink-blueGrey .mat-button.mat-primary, .pink-blueGrey .mat-icon-button.mat-primary, .pink-blueGrey .mat-stroked-button.mat-primary {
  color: #e91e63;
}
.pink-blueGrey .mat-button.mat-accent, .pink-blueGrey .mat-icon-button.mat-accent, .pink-blueGrey .mat-stroked-button.mat-accent {
  color: #607d8b;
}
.pink-blueGrey .mat-button.mat-warn, .pink-blueGrey .mat-icon-button.mat-warn, .pink-blueGrey .mat-stroked-button.mat-warn {
  color: #f44336;
}
.pink-blueGrey .mat-button.mat-primary.mat-button-disabled, .pink-blueGrey .mat-button.mat-accent.mat-button-disabled, .pink-blueGrey .mat-button.mat-warn.mat-button-disabled, .pink-blueGrey .mat-button.mat-button-disabled.mat-button-disabled, .pink-blueGrey .mat-icon-button.mat-primary.mat-button-disabled, .pink-blueGrey .mat-icon-button.mat-accent.mat-button-disabled, .pink-blueGrey .mat-icon-button.mat-warn.mat-button-disabled, .pink-blueGrey .mat-icon-button.mat-button-disabled.mat-button-disabled, .pink-blueGrey .mat-stroked-button.mat-primary.mat-button-disabled, .pink-blueGrey .mat-stroked-button.mat-accent.mat-button-disabled, .pink-blueGrey .mat-stroked-button.mat-warn.mat-button-disabled, .pink-blueGrey .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.pink-blueGrey .mat-button.mat-primary .mat-button-focus-overlay, .pink-blueGrey .mat-icon-button.mat-primary .mat-button-focus-overlay, .pink-blueGrey .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #e91e63;
}
.pink-blueGrey .mat-button.mat-accent .mat-button-focus-overlay, .pink-blueGrey .mat-icon-button.mat-accent .mat-button-focus-overlay, .pink-blueGrey .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #607d8b;
}
.pink-blueGrey .mat-button.mat-warn .mat-button-focus-overlay, .pink-blueGrey .mat-icon-button.mat-warn .mat-button-focus-overlay, .pink-blueGrey .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #f44336;
}
.pink-blueGrey .mat-button.mat-button-disabled .mat-button-focus-overlay, .pink-blueGrey .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .pink-blueGrey .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.pink-blueGrey .mat-button .mat-ripple-element, .pink-blueGrey .mat-icon-button .mat-ripple-element, .pink-blueGrey .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.pink-blueGrey .mat-button-focus-overlay {
  background: black;
}
.pink-blueGrey .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .mat-flat-button, .pink-blueGrey .mat-raised-button, .pink-blueGrey .mat-fab, .pink-blueGrey .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.pink-blueGrey .mat-flat-button.mat-primary, .pink-blueGrey .mat-raised-button.mat-primary, .pink-blueGrey .mat-fab.mat-primary, .pink-blueGrey .mat-mini-fab.mat-primary {
  color: white;
}
.pink-blueGrey .mat-flat-button.mat-accent, .pink-blueGrey .mat-raised-button.mat-accent, .pink-blueGrey .mat-fab.mat-accent, .pink-blueGrey .mat-mini-fab.mat-accent {
  color: white;
}
.pink-blueGrey .mat-flat-button.mat-warn, .pink-blueGrey .mat-raised-button.mat-warn, .pink-blueGrey .mat-fab.mat-warn, .pink-blueGrey .mat-mini-fab.mat-warn {
  color: white;
}
.pink-blueGrey .mat-flat-button.mat-primary.mat-button-disabled, .pink-blueGrey .mat-flat-button.mat-accent.mat-button-disabled, .pink-blueGrey .mat-flat-button.mat-warn.mat-button-disabled, .pink-blueGrey .mat-flat-button.mat-button-disabled.mat-button-disabled, .pink-blueGrey .mat-raised-button.mat-primary.mat-button-disabled, .pink-blueGrey .mat-raised-button.mat-accent.mat-button-disabled, .pink-blueGrey .mat-raised-button.mat-warn.mat-button-disabled, .pink-blueGrey .mat-raised-button.mat-button-disabled.mat-button-disabled, .pink-blueGrey .mat-fab.mat-primary.mat-button-disabled, .pink-blueGrey .mat-fab.mat-accent.mat-button-disabled, .pink-blueGrey .mat-fab.mat-warn.mat-button-disabled, .pink-blueGrey .mat-fab.mat-button-disabled.mat-button-disabled, .pink-blueGrey .mat-mini-fab.mat-primary.mat-button-disabled, .pink-blueGrey .mat-mini-fab.mat-accent.mat-button-disabled, .pink-blueGrey .mat-mini-fab.mat-warn.mat-button-disabled, .pink-blueGrey .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.pink-blueGrey .mat-flat-button.mat-primary, .pink-blueGrey .mat-raised-button.mat-primary, .pink-blueGrey .mat-fab.mat-primary, .pink-blueGrey .mat-mini-fab.mat-primary {
  background-color: #e91e63;
}
.pink-blueGrey .mat-flat-button.mat-accent, .pink-blueGrey .mat-raised-button.mat-accent, .pink-blueGrey .mat-fab.mat-accent, .pink-blueGrey .mat-mini-fab.mat-accent {
  background-color: #607d8b;
}
.pink-blueGrey .mat-flat-button.mat-warn, .pink-blueGrey .mat-raised-button.mat-warn, .pink-blueGrey .mat-fab.mat-warn, .pink-blueGrey .mat-mini-fab.mat-warn {
  background-color: #f44336;
}
.pink-blueGrey .mat-flat-button.mat-primary.mat-button-disabled, .pink-blueGrey .mat-flat-button.mat-accent.mat-button-disabled, .pink-blueGrey .mat-flat-button.mat-warn.mat-button-disabled, .pink-blueGrey .mat-flat-button.mat-button-disabled.mat-button-disabled, .pink-blueGrey .mat-raised-button.mat-primary.mat-button-disabled, .pink-blueGrey .mat-raised-button.mat-accent.mat-button-disabled, .pink-blueGrey .mat-raised-button.mat-warn.mat-button-disabled, .pink-blueGrey .mat-raised-button.mat-button-disabled.mat-button-disabled, .pink-blueGrey .mat-fab.mat-primary.mat-button-disabled, .pink-blueGrey .mat-fab.mat-accent.mat-button-disabled, .pink-blueGrey .mat-fab.mat-warn.mat-button-disabled, .pink-blueGrey .mat-fab.mat-button-disabled.mat-button-disabled, .pink-blueGrey .mat-mini-fab.mat-primary.mat-button-disabled, .pink-blueGrey .mat-mini-fab.mat-accent.mat-button-disabled, .pink-blueGrey .mat-mini-fab.mat-warn.mat-button-disabled, .pink-blueGrey .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .mat-flat-button.mat-primary .mat-ripple-element, .pink-blueGrey .mat-raised-button.mat-primary .mat-ripple-element, .pink-blueGrey .mat-fab.mat-primary .mat-ripple-element, .pink-blueGrey .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.pink-blueGrey .mat-flat-button.mat-accent .mat-ripple-element, .pink-blueGrey .mat-raised-button.mat-accent .mat-ripple-element, .pink-blueGrey .mat-fab.mat-accent .mat-ripple-element, .pink-blueGrey .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.pink-blueGrey .mat-flat-button.mat-warn .mat-ripple-element, .pink-blueGrey .mat-raised-button.mat-warn .mat-ripple-element, .pink-blueGrey .mat-fab.mat-warn .mat-ripple-element, .pink-blueGrey .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.pink-blueGrey .mat-stroked-button:not([class*=mat-elevation-z]), .pink-blueGrey .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .mat-fab:not([class*=mat-elevation-z]), .pink-blueGrey .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .pink-blueGrey .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .pink-blueGrey .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.pink-blueGrey .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.pink-blueGrey .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.pink-blueGrey .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.pink-blueGrey .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.pink-blueGrey .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.pink-blueGrey .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
.pink-blueGrey [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.pink-blueGrey .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
.pink-blueGrey .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.pink-blueGrey .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.pink-blueGrey .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.pink-blueGrey .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.pink-blueGrey .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.pink-blueGrey .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
.pink-blueGrey .mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.pink-blueGrey .mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}
.pink-blueGrey .mat-checkbox-checkmark {
  fill: #fafafa;
}
.pink-blueGrey .mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
.pink-blueGrey .mat-checkbox-mixedmark {
  background-color: #fafafa;
}
.pink-blueGrey .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .pink-blueGrey .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #e91e63;
}
.pink-blueGrey .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .pink-blueGrey .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #607d8b;
}
.pink-blueGrey .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .pink-blueGrey .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #f44336;
}
.pink-blueGrey .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .pink-blueGrey .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.pink-blueGrey .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.pink-blueGrey .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}
.pink-blueGrey .mat-checkbox .mat-ripple-element {
  background-color: black;
}
.pink-blueGrey .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.pink-blueGrey .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #e91e63;
}
.pink-blueGrey .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.pink-blueGrey .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #607d8b;
}
.pink-blueGrey .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.pink-blueGrey .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #f44336;
}
.pink-blueGrey .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey .mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.pink-blueGrey .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.pink-blueGrey .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.pink-blueGrey .mat-chip.mat-standard-chip::after {
  background: black;
}
.pink-blueGrey .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #e91e63;
  color: white;
}
.pink-blueGrey .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.pink-blueGrey .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.pink-blueGrey .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #f44336;
  color: white;
}
.pink-blueGrey .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.pink-blueGrey .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.pink-blueGrey .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #607d8b;
  color: white;
}
.pink-blueGrey .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.pink-blueGrey .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.pink-blueGrey .mat-table {
  background: white;
}
.pink-blueGrey .mat-table thead, .pink-blueGrey .mat-table tbody, .pink-blueGrey .mat-table tfoot,
.pink-blueGrey mat-header-row, .pink-blueGrey mat-row, .pink-blueGrey mat-footer-row,
.pink-blueGrey [mat-header-row], .pink-blueGrey [mat-row], .pink-blueGrey [mat-footer-row],
.pink-blueGrey .mat-table-sticky {
  background: inherit;
}
.pink-blueGrey mat-row, .pink-blueGrey mat-header-row, .pink-blueGrey mat-footer-row,
.pink-blueGrey th.mat-header-cell, .pink-blueGrey td.mat-cell, .pink-blueGrey td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}
.pink-blueGrey .mat-cell, .pink-blueGrey .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey .mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}
.pink-blueGrey .mat-datepicker-toggle,
.pink-blueGrey .mat-datepicker-content .mat-calendar-next-button,
.pink-blueGrey .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.pink-blueGrey .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .mat-calendar-table-header,
.pink-blueGrey .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.pink-blueGrey .mat-calendar-body-cell-content,
.pink-blueGrey .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.pink-blueGrey .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.pink-blueGrey .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.pink-blueGrey .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.pink-blueGrey .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.pink-blueGrey .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.pink-blueGrey .mat-calendar-body-in-range::before {
  background: rgba(233, 30, 99, 0.2);
}
.pink-blueGrey .mat-calendar-body-comparison-identical,
.pink-blueGrey .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.pink-blueGrey .mat-calendar-body-comparison-bridge-start::before,
.pink-blueGrey [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(233, 30, 99, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.pink-blueGrey .mat-calendar-body-comparison-bridge-end::before,
.pink-blueGrey [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(233, 30, 99, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.pink-blueGrey .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.pink-blueGrey .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.pink-blueGrey .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.pink-blueGrey .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.pink-blueGrey .mat-calendar-body-selected {
  background-color: #e91e63;
  color: white;
}
.pink-blueGrey .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(233, 30, 99, 0.4);
}
.pink-blueGrey .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.pink-blueGrey .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.pink-blueGrey .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(233, 30, 99, 0.3);
}
@media (hover: hover) {
  .pink-blueGrey .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(233, 30, 99, 0.3);
  }
}
.pink-blueGrey .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(96, 125, 139, 0.2);
}
.pink-blueGrey .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.pink-blueGrey .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.pink-blueGrey .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.pink-blueGrey .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(96, 125, 139, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.pink-blueGrey .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.pink-blueGrey .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(96, 125, 139, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.pink-blueGrey .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.pink-blueGrey .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.pink-blueGrey .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.pink-blueGrey .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.pink-blueGrey .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #607d8b;
  color: white;
}
.pink-blueGrey .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(96, 125, 139, 0.4);
}
.pink-blueGrey .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.pink-blueGrey .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.pink-blueGrey .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(96, 125, 139, 0.3);
}
@media (hover: hover) {
  .pink-blueGrey .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(96, 125, 139, 0.3);
  }
}
.pink-blueGrey .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}
.pink-blueGrey .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.pink-blueGrey .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.pink-blueGrey .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.pink-blueGrey .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.pink-blueGrey .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.pink-blueGrey .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.pink-blueGrey .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.pink-blueGrey .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.pink-blueGrey .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.pink-blueGrey .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.pink-blueGrey .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: white;
}
.pink-blueGrey .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}
.pink-blueGrey .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.pink-blueGrey .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.pink-blueGrey .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(244, 67, 54, 0.3);
}
@media (hover: hover) {
  .pink-blueGrey .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(244, 67, 54, 0.3);
  }
}
.pink-blueGrey .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .mat-datepicker-toggle-active {
  color: #e91e63;
}
.pink-blueGrey .mat-datepicker-toggle-active.mat-accent {
  color: #607d8b;
}
.pink-blueGrey .mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}
.pink-blueGrey .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.pink-blueGrey .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .pink-blueGrey .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .pink-blueGrey .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .pink-blueGrey .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.pink-blueGrey .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey .mat-expansion-panel-header-description,
.pink-blueGrey .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.pink-blueGrey .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.pink-blueGrey .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.pink-blueGrey .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.pink-blueGrey .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.pink-blueGrey .mat-hint {
  color: rgba(0, 0, 0, 0.6);
}
.pink-blueGrey .mat-form-field.mat-focused .mat-form-field-label {
  color: #e91e63;
}
.pink-blueGrey .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #607d8b;
}
.pink-blueGrey .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #f44336;
}
.pink-blueGrey .mat-focused .mat-form-field-required-marker {
  color: #607d8b;
}
.pink-blueGrey .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #e91e63;
}
.pink-blueGrey .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #607d8b;
}
.pink-blueGrey .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #f44336;
}
.pink-blueGrey .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #e91e63;
}
.pink-blueGrey .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #607d8b;
}
.pink-blueGrey .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #f44336;
}
.pink-blueGrey .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #f44336;
}
.pink-blueGrey .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.pink-blueGrey .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #f44336;
}
.pink-blueGrey .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.pink-blueGrey .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #f44336;
}
.pink-blueGrey .mat-error {
  color: #f44336;
}
.pink-blueGrey .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.pink-blueGrey .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.pink-blueGrey .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.pink-blueGrey .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.pink-blueGrey .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.pink-blueGrey .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.pink-blueGrey .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.pink-blueGrey .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.pink-blueGrey .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.pink-blueGrey .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.pink-blueGrey .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.pink-blueGrey .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #e91e63;
}
.pink-blueGrey .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #607d8b;
}
.pink-blueGrey .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #f44336;
}
.pink-blueGrey .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #f44336;
}
.pink-blueGrey .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.pink-blueGrey .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.pink-blueGrey .mat-icon.mat-primary {
  color: #e91e63;
}
.pink-blueGrey .mat-icon.mat-accent {
  color: #607d8b;
}
.pink-blueGrey .mat-icon.mat-warn {
  color: #f44336;
}
.pink-blueGrey .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.pink-blueGrey .mat-input-element:disabled,
.pink-blueGrey .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.pink-blueGrey .mat-input-element {
  caret-color: #e91e63;
}
.pink-blueGrey .mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.pink-blueGrey .mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.pink-blueGrey .mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.pink-blueGrey .mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.pink-blueGrey .mat-form-field.mat-accent .mat-input-element {
  caret-color: #607d8b;
}
.pink-blueGrey .mat-form-field.mat-warn .mat-input-element,
.pink-blueGrey .mat-form-field-invalid .mat-input-element {
  caret-color: #f44336;
}
.pink-blueGrey .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #f44336;
}
.pink-blueGrey .mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey .mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey .mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.pink-blueGrey .mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}
.pink-blueGrey .mat-list-option:hover, .pink-blueGrey .mat-list-option:focus,
.pink-blueGrey .mat-nav-list .mat-list-item:hover,
.pink-blueGrey .mat-nav-list .mat-list-item:focus,
.pink-blueGrey .mat-action-list .mat-list-item:hover,
.pink-blueGrey .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.pink-blueGrey .mat-list-single-selected-option, .pink-blueGrey .mat-list-single-selected-option:hover, .pink-blueGrey .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .mat-menu-panel {
  background: white;
}
.pink-blueGrey .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey .mat-menu-item[disabled],
.pink-blueGrey .mat-menu-item[disabled] .mat-menu-submenu-icon,
.pink-blueGrey .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}
.pink-blueGrey .mat-menu-item .mat-icon-no-color,
.pink-blueGrey .mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}
.pink-blueGrey .mat-menu-item:hover:not([disabled]),
.pink-blueGrey .mat-menu-item.cdk-program-focused:not([disabled]),
.pink-blueGrey .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.pink-blueGrey .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.pink-blueGrey .mat-paginator {
  background: white;
}
.pink-blueGrey .mat-paginator,
.pink-blueGrey .mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}
.pink-blueGrey .mat-paginator-decrement,
.pink-blueGrey .mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.pink-blueGrey .mat-paginator-first,
.pink-blueGrey .mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.pink-blueGrey .mat-icon-button[disabled] .mat-paginator-decrement,
.pink-blueGrey .mat-icon-button[disabled] .mat-paginator-increment,
.pink-blueGrey .mat-icon-button[disabled] .mat-paginator-first,
.pink-blueGrey .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.pink-blueGrey .mat-progress-bar-background {
  fill: #f6c3d4;
}
.pink-blueGrey .mat-progress-bar-buffer {
  background-color: #f6c3d4;
}
.pink-blueGrey .mat-progress-bar-fill::after {
  background-color: #e91e63;
}
.pink-blueGrey .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #d4dbde;
}
.pink-blueGrey .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #d4dbde;
}
.pink-blueGrey .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #607d8b;
}
.pink-blueGrey .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f9ccc9;
}
.pink-blueGrey .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f9ccc9;
}
.pink-blueGrey .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #f44336;
}
.pink-blueGrey .mat-progress-spinner circle, .pink-blueGrey .mat-spinner circle {
  stroke: #e91e63;
}
.pink-blueGrey .mat-progress-spinner.mat-accent circle, .pink-blueGrey .mat-spinner.mat-accent circle {
  stroke: #607d8b;
}
.pink-blueGrey .mat-progress-spinner.mat-warn circle, .pink-blueGrey .mat-spinner.mat-warn circle {
  stroke: #f44336;
}
.pink-blueGrey .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.pink-blueGrey .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e91e63;
}
.pink-blueGrey .mat-radio-button.mat-primary .mat-radio-inner-circle,
.pink-blueGrey .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .pink-blueGrey .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .pink-blueGrey .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #e91e63;
}
.pink-blueGrey .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #607d8b;
}
.pink-blueGrey .mat-radio-button.mat-accent .mat-radio-inner-circle,
.pink-blueGrey .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .pink-blueGrey .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .pink-blueGrey .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #607d8b;
}
.pink-blueGrey .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f44336;
}
.pink-blueGrey .mat-radio-button.mat-warn .mat-radio-inner-circle,
.pink-blueGrey .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .pink-blueGrey .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .pink-blueGrey .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #f44336;
}
.pink-blueGrey .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.pink-blueGrey .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.pink-blueGrey .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.pink-blueGrey .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.pink-blueGrey .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.pink-blueGrey .mat-radio-button .mat-ripple-element {
  background-color: black;
}
.pink-blueGrey .mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey .mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.pink-blueGrey .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.pink-blueGrey .mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.pink-blueGrey .mat-select-panel {
  background: white;
}
.pink-blueGrey .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #e91e63;
}
.pink-blueGrey .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #607d8b;
}
.pink-blueGrey .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #f44336;
}
.pink-blueGrey .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #f44336;
}
.pink-blueGrey .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.pink-blueGrey .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey .mat-drawer.mat-drawer-push {
  background-color: white;
}
.pink-blueGrey .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.pink-blueGrey [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.pink-blueGrey [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.pink-blueGrey .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #607d8b;
}
.pink-blueGrey .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(96, 125, 139, 0.54);
}
.pink-blueGrey .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #607d8b;
}
.pink-blueGrey .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #e91e63;
}
.pink-blueGrey .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(233, 30, 99, 0.54);
}
.pink-blueGrey .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #e91e63;
}
.pink-blueGrey .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #f44336;
}
.pink-blueGrey .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(244, 67, 54, 0.54);
}
.pink-blueGrey .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #f44336;
}
.pink-blueGrey .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
.pink-blueGrey .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
.pink-blueGrey .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.pink-blueGrey .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.pink-blueGrey .mat-slider.mat-primary .mat-slider-track-fill,
.pink-blueGrey .mat-slider.mat-primary .mat-slider-thumb,
.pink-blueGrey .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #e91e63;
}
.pink-blueGrey .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.pink-blueGrey .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(233, 30, 99, 0.2);
}
.pink-blueGrey .mat-slider.mat-accent .mat-slider-track-fill,
.pink-blueGrey .mat-slider.mat-accent .mat-slider-thumb,
.pink-blueGrey .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #607d8b;
}
.pink-blueGrey .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.pink-blueGrey .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(96, 125, 139, 0.2);
}
.pink-blueGrey .mat-slider.mat-warn .mat-slider-track-fill,
.pink-blueGrey .mat-slider.mat-warn .mat-slider-thumb,
.pink-blueGrey .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #f44336;
}
.pink-blueGrey .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.pink-blueGrey .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(244, 67, 54, 0.2);
}
.pink-blueGrey .mat-slider:hover .mat-slider-track-background,
.pink-blueGrey .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}
.pink-blueGrey .mat-slider.mat-slider-disabled .mat-slider-track-background,
.pink-blueGrey .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.pink-blueGrey .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.pink-blueGrey .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.pink-blueGrey .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.pink-blueGrey .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.pink-blueGrey .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.pink-blueGrey .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.pink-blueGrey .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .pink-blueGrey .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.pink-blueGrey .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .pink-blueGrey .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}
.pink-blueGrey .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.pink-blueGrey .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.pink-blueGrey .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.pink-blueGrey .mat-step-header.cdk-keyboard-focused, .pink-blueGrey .mat-step-header.cdk-program-focused, .pink-blueGrey .mat-step-header:hover:not([aria-disabled]), .pink-blueGrey .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.pink-blueGrey .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .pink-blueGrey .mat-step-header:hover {
    background: none;
  }
}
.pink-blueGrey .mat-step-header .mat-step-label,
.pink-blueGrey .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.pink-blueGrey .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.pink-blueGrey .mat-step-header .mat-step-icon-selected,
.pink-blueGrey .mat-step-header .mat-step-icon-state-done,
.pink-blueGrey .mat-step-header .mat-step-icon-state-edit {
  background-color: #e91e63;
  color: white;
}
.pink-blueGrey .mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.pink-blueGrey .mat-step-header.mat-accent .mat-step-icon-selected,
.pink-blueGrey .mat-step-header.mat-accent .mat-step-icon-state-done,
.pink-blueGrey .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #607d8b;
  color: white;
}
.pink-blueGrey .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.pink-blueGrey .mat-step-header.mat-warn .mat-step-icon-selected,
.pink-blueGrey .mat-step-header.mat-warn .mat-step-icon-state-done,
.pink-blueGrey .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #f44336;
  color: white;
}
.pink-blueGrey .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336;
}
.pink-blueGrey .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey .mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336;
}
.pink-blueGrey .mat-stepper-horizontal, .pink-blueGrey .mat-stepper-vertical {
  background-color: white;
}
.pink-blueGrey .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .mat-horizontal-stepper-header::before,
.pink-blueGrey .mat-horizontal-stepper-header::after,
.pink-blueGrey .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .mat-sort-header-arrow {
  color: #757575;
}
.pink-blueGrey .mat-tab-nav-bar,
.pink-blueGrey .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .mat-tab-group-inverted-header .mat-tab-nav-bar,
.pink-blueGrey .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.pink-blueGrey .mat-tab-label, .pink-blueGrey .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey .mat-tab-label.mat-tab-disabled, .pink-blueGrey .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.pink-blueGrey .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.pink-blueGrey .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.pink-blueGrey .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.pink-blueGrey .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-blueGrey .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-blueGrey .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-blueGrey .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .pink-blueGrey .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-blueGrey .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-blueGrey .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-blueGrey .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 187, 208, 0.3);
}
.pink-blueGrey .mat-tab-group.mat-primary .mat-ink-bar, .pink-blueGrey .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #e91e63;
}
.pink-blueGrey .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .pink-blueGrey .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .pink-blueGrey .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .pink-blueGrey .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.pink-blueGrey .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-blueGrey .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-blueGrey .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-blueGrey .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .pink-blueGrey .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-blueGrey .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-blueGrey .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-blueGrey .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(207, 216, 220, 0.3);
}
.pink-blueGrey .mat-tab-group.mat-accent .mat-ink-bar, .pink-blueGrey .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #607d8b;
}
.pink-blueGrey .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .pink-blueGrey .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .pink-blueGrey .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .pink-blueGrey .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.pink-blueGrey .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-blueGrey .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-blueGrey .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-blueGrey .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .pink-blueGrey .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-blueGrey .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-blueGrey .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-blueGrey .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.pink-blueGrey .mat-tab-group.mat-warn .mat-ink-bar, .pink-blueGrey .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #f44336;
}
.pink-blueGrey .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .pink-blueGrey .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .pink-blueGrey .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .pink-blueGrey .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.pink-blueGrey .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-blueGrey .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-blueGrey .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-blueGrey .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .pink-blueGrey .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-blueGrey .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-blueGrey .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-blueGrey .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 187, 208, 0.3);
}
.pink-blueGrey .mat-tab-group.mat-background-primary > .mat-tab-header, .pink-blueGrey .mat-tab-group.mat-background-primary > .mat-tab-link-container, .pink-blueGrey .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .pink-blueGrey .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .pink-blueGrey .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .pink-blueGrey .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #e91e63;
}
.pink-blueGrey .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .pink-blueGrey .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .pink-blueGrey .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .pink-blueGrey .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.pink-blueGrey .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .pink-blueGrey .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .pink-blueGrey .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .pink-blueGrey .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.pink-blueGrey .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.pink-blueGrey .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.pink-blueGrey .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.pink-blueGrey .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .pink-blueGrey .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.pink-blueGrey .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.pink-blueGrey .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.pink-blueGrey .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.pink-blueGrey .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.pink-blueGrey .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .pink-blueGrey .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.pink-blueGrey .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.pink-blueGrey .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.pink-blueGrey .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.pink-blueGrey .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .pink-blueGrey .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.pink-blueGrey .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.pink-blueGrey .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.pink-blueGrey .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-blueGrey .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-blueGrey .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-blueGrey .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .pink-blueGrey .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-blueGrey .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-blueGrey .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-blueGrey .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(207, 216, 220, 0.3);
}
.pink-blueGrey .mat-tab-group.mat-background-accent > .mat-tab-header, .pink-blueGrey .mat-tab-group.mat-background-accent > .mat-tab-link-container, .pink-blueGrey .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .pink-blueGrey .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .pink-blueGrey .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .pink-blueGrey .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #607d8b;
}
.pink-blueGrey .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .pink-blueGrey .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .pink-blueGrey .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .pink-blueGrey .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.pink-blueGrey .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .pink-blueGrey .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .pink-blueGrey .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .pink-blueGrey .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.pink-blueGrey .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.pink-blueGrey .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.pink-blueGrey .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.pink-blueGrey .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .pink-blueGrey .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.pink-blueGrey .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.pink-blueGrey .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.pink-blueGrey .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.pink-blueGrey .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.pink-blueGrey .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .pink-blueGrey .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.pink-blueGrey .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.pink-blueGrey .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.pink-blueGrey .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.pink-blueGrey .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .pink-blueGrey .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.pink-blueGrey .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.pink-blueGrey .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.pink-blueGrey .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-blueGrey .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-blueGrey .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-blueGrey .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .pink-blueGrey .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-blueGrey .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-blueGrey .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-blueGrey .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.pink-blueGrey .mat-tab-group.mat-background-warn > .mat-tab-header, .pink-blueGrey .mat-tab-group.mat-background-warn > .mat-tab-link-container, .pink-blueGrey .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .pink-blueGrey .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .pink-blueGrey .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .pink-blueGrey .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #f44336;
}
.pink-blueGrey .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .pink-blueGrey .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .pink-blueGrey .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .pink-blueGrey .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.pink-blueGrey .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .pink-blueGrey .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .pink-blueGrey .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .pink-blueGrey .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.pink-blueGrey .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.pink-blueGrey .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.pink-blueGrey .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.pink-blueGrey .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .pink-blueGrey .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.pink-blueGrey .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.pink-blueGrey .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.pink-blueGrey .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.pink-blueGrey .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.pink-blueGrey .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .pink-blueGrey .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.pink-blueGrey .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.pink-blueGrey .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.pink-blueGrey .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.pink-blueGrey .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .pink-blueGrey .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.pink-blueGrey .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.pink-blueGrey .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.pink-blueGrey .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey .mat-toolbar.mat-primary {
  background: #e91e63;
  color: white;
}
.pink-blueGrey .mat-toolbar.mat-accent {
  background: #607d8b;
  color: white;
}
.pink-blueGrey .mat-toolbar.mat-warn {
  background: #f44336;
  color: white;
}
.pink-blueGrey .mat-toolbar .mat-form-field-underline,
.pink-blueGrey .mat-toolbar .mat-form-field-ripple,
.pink-blueGrey .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.pink-blueGrey .mat-toolbar .mat-form-field-label,
.pink-blueGrey .mat-toolbar .mat-focused .mat-form-field-label,
.pink-blueGrey .mat-toolbar .mat-select-value,
.pink-blueGrey .mat-toolbar .mat-select-arrow,
.pink-blueGrey .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.pink-blueGrey .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.pink-blueGrey .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.pink-blueGrey .mat-tree {
  background: white;
}
.pink-blueGrey .mat-tree-node,
.pink-blueGrey .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .mat-simple-snackbar-action {
  color: #607d8b;
}
.pink-blueGrey *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.pink-blueGrey *::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}
.pink-blueGrey *::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}
.pink-blueGrey .toolbar-line.container-ou {
  background: #e91e63;
  color: white;
}
.pink-blueGrey .toolbar-line.container-persona {
  background: #e91e63 !important;
}
.pink-blueGrey .toolbar-line.container-persona .persona-title {
  background: #e91e63;
}
.pink-blueGrey .toolbar-line.container-persona .invisible-container.persona-selector {
  background: #e91e63 !important;
}
.pink-blueGrey button.mat-icon-button:not([disabled]):hover {
  background: rgba(0, 0, 0, 0.04);
}
.pink-blueGrey .side-bar {
  background: #eeeeee;
}
.pink-blueGrey .icon-stacked {
  color: white !important;
}
.pink-blueGrey .icon-stacked mat-icon + mat-icon {
  text-shadow: -1px 1px 0px #e91e63, 1px -1px 0px #e91e63;
}
.pink-blueGrey .divider-vert {
  background: rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .avatar {
  background: #c2185b !important;
}
.pink-blueGrey ngx-avatar .avatar-content {
  background: #c2185b !important;
}
.pink-blueGrey .mat-loading-window {
  background: white;
}
.pink-blueGrey .window-spinner {
  background: white;
}
.pink-blueGrey .load-window {
  background: white;
}
@media (min-width: 600px) and (max-width: 1300px) {
  .pink-blueGrey mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .load-window,
.pink-blueGrey mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .load-window.new {
    background: white !important;
  }
  .pink-blueGrey mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card {
    background: white !important;
  }
  .pink-blueGrey mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .inbox-container mat-toolbar {
    background: #e91e63;
    color: white;
  }
  .pink-blueGrey mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .inbox-container mat-list-item.mat-list-item {
    border: 1px solid rgba(0, 0, 0, 0.12);
  }
  .pink-blueGrey mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .detail {
    background: white !important;
  }
  .pink-blueGrey mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .detail mat-toolbar {
    background: white !important;
  }
  .pink-blueGrey mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .main.w-detail + .new-detail-container .load-window {
    background: #fafafa !important;
  }
  .pink-blueGrey mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .main.w-detail + .new-detail-container:before {
    background: rgba(0, 0, 0, 0.6);
  }
  .pink-blueGrey mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .main.side-open + .side {
    border-left: 1px solid rgba(0, 0, 0, 0.12);
  }
  .pink-blueGrey mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .side .container .mat-list-base {
    background: white !important;
    border: 1px solid rgba(0, 0, 0, 0.12);
  }
  .pink-blueGrey mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .new-table .mat-header-row,
.pink-blueGrey mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .new-table .mat-table,
.pink-blueGrey mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .document-table .mat-header-row,
.pink-blueGrey mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .document-table .mat-table,
.pink-blueGrey mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .status-table .mat-header-row,
.pink-blueGrey mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .status-table .mat-table {
    background: white !important;
  }
  .pink-blueGrey mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .audit-filter .filter-container.is-floating {
    background: #fafafa !important;
    border: 1px solid rgba(0, 0, 0, 0.12);
  }
  .pink-blueGrey mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .audit-filter .filter-container.is-floating .form-body {
    background: #fafafa !important;
  }
  .pink-blueGrey mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .audit-result {
    background: white;
  }
  .pink-blueGrey mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .audit-detail-container {
    background: #fafafa !important;
  }
}
@media (max-width: 599px) {
  .pink-blueGrey app-document-detail .mat-toolbar {
    background: #c2185b !important;
    color: white !important;
  }
  .pink-blueGrey app-document-detail .mat-toolbar .mat-toolbar-row .toolbar-actions {
    background: #c2185b !important;
    color: white !important;
  }
  .pink-blueGrey .login-card {
    background: white !important;
  }
  .pink-blueGrey .inbox-toolbar {
    background: #c2185b !important;
    color: white !important;
  }
  .pink-blueGrey .detail {
    background: white url("../img/spinner_mobile.gif") no-repeat center center !important;
  }
  .pink-blueGrey .toolbar-line-container .toolbar-line:first-child {
    background: #c2185b !important;
    color: white !important;
  }
  .pink-blueGrey .toolbar-actions {
    background: #c2185b !important;
    color: white !important;
  }
  .pink-blueGrey mat-drawer.side-nav.enter {
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12) !important;
  }
  .pink-blueGrey .side-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0) !important;
  }
  .pink-blueGrey .filter {
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.55) 16%, rgba(0, 0, 0, 0) 100%) !important;
  }
  .pink-blueGrey button.side-open-mobile {
    color: white !important;
  }
  .pink-blueGrey .side-nav .side-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0);
  }
  .pink-blueGrey .side-nav .filter {
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.55) 16%, rgba(0, 0, 0, 0) 100%);
  }
  .pink-blueGrey .side-nav .side-nav-container .links {
    background: white;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
  }
  .pink-blueGrey .side-nav .side-nav-container .version {
    background: white !important;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
  }
  .pink-blueGrey app-file-document-view .view-container {
    background: white !important;
  }
  .pink-blueGrey app-file-document-view .view-container .side-bar {
    background: #c2185b !important;
    color: white !important;
  }
  .pink-blueGrey app-file-document-view .view-container .view-content app-pdf-wrapper.floating {
    background: white;
  }
  .pink-blueGrey app-file-document-view .view-container .view-content .upload-indications {
    background: #eeeeee;
  }
  .pink-blueGrey app-file-document-view .view-container .view-content app-file-document-sign .sign-cycle mat-horizontal-stepper.mat-stepper-horizontal {
    background-color: rgba(0, 0, 0, 0) !important;
  }
  .pink-blueGrey app-file-document-view .view-container .view-content app-file-document-sign .sign-cycle .sign-cycle-content .cycle-content-action .mat-ripple-element {
    background-color: rgba(255, 255, 255, 0.1) !important;
  }
  .pink-blueGrey app-pending .floating-container .inbox-container .inbox-toolbar .mat-toolbar-row .mat-avatar h3 {
    color: white !important;
  }
  .pink-blueGrey app-pending .floating-container .detail {
    background: white url("../img/spinner_mobile.gif") no-repeat center center !important;
  }
  .pink-blueGrey app-document-sign-massive .inbox-container .pending-container app-chapa.minimized .info-empty {
    background: #607d8b;
  }
  .pink-blueGrey app-document-sign-massive .inbox-container .pending-container app-chapa.minimized .info-empty > span h2 {
    color: white !important;
  }
  .pink-blueGrey app-document-sign-massive .inbox-container .signer.mobile {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    background: white;
  }
  .pink-blueGrey app-document-sign-massive .detail app-file-document-detail .view-container .mat-toolbar .mat-toolbar-row .view-actions {
    color: white !important;
  }
  .pink-blueGrey app-add-documentation .floating-container .employee-add-documentation .inbox-container .inbox-toolbar .mat-toolbar-row .mat-avatar h3 {
    color: white !important;
  }
  .pink-blueGrey app-add-documentation .floating-container .employee-add-documentation .detail {
    background: white url("../img/spinner_mobile.gif") no-repeat center center !important;
  }
  .pink-blueGrey app-add-documentation .floating-container .employee-add-documentation .detail app-file-document-view .view-container .view-content .new-container app-file-document-upload {
    background: white;
  }
}
.pink-blueGrey .mat-card .mat-drawer-container {
  background: white !important;
}
.pink-blueGrey .mat-drawer-container {
  background: #e0e0e0 !important;
}
.pink-blueGrey .mat-list .mat-list-item.finished-process-inbox.active {
  background: #4DB6AC !important;
}
.pink-blueGrey .mat-list .mat-list-item.finished-process-inbox .mat-icon {
  background: #4DB6AC;
}
.pink-blueGrey .mat-list .mat-list-item.erased-process-inbox.active {
  background: #837C80 !important;
}
.pink-blueGrey .mat-list .mat-list-item.limbo-process-inbox.active {
  background: #FFD54F !important;
}
.pink-blueGrey .mat-list .mat-list-item.limbo-process-inbox .mat-icon {
  background: #FFD54F;
}
.pink-blueGrey .mat-list .mat-list-item.in-process-inbox.active {
  background: #FFD54F !important;
}
.pink-blueGrey .mat-list .mat-list-item.in-process-inbox .mat-icon {
  background: #FFD54F;
}
.pink-blueGrey .mat-list .mat-list-item.error-process-inbox.active {
  background: #F48FB1 !important;
}
.pink-blueGrey .mat-list .mat-list-item.error-process-inbox .mat-icon {
  background: #F48FB1;
}
.pink-blueGrey .mat-list .mat-list-item.active .mat-line b {
  color: #e91e63;
}
.pink-blueGrey .mat-list .mat-list-item.active .mat-list-avatar {
  color: #e91e63;
}
.pink-blueGrey .mat-list .mat-list-item:hover:not(.active) {
  background: rgba(0, 0, 0, 0.04);
}
.pink-blueGrey span.mat-badge-content {
  color: #f44336 !important;
}
.pink-blueGrey mat-expansion-panel.mat-expanded {
  background: #fafafa !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey mat-expansion-panel.virtual-scrolling .mat-expansion-panel-content .get-more {
  color: #e91e63;
}
.pink-blueGrey mat-expansion-panel.virtual-scrolling .mat-expansion-panel-content app-cs-paginator.get-more {
  background: white;
}
.pink-blueGrey mat-expansion-panel-header.mat-expanded {
  background: white !important;
}
.pink-blueGrey .bottom-container {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .load-more * {
  color: #e91e63;
}
.pink-blueGrey .welcome-card {
  background: white !important;
}
.pink-blueGrey .welcome-card .img-divider {
  background: #c2185b;
}
.pink-blueGrey .mat-header-row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background: white;
}
.pink-blueGrey .mat-row {
  background: white;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.pink-blueGrey .mat-row .mat-cell.status-cell mat-icon.pending {
  color: #FF9800 !important;
}
.pink-blueGrey .mat-row .mat-cell.status-cell mat-icon.ok {
  color: #009688 !important;
}
.pink-blueGrey .mat-row .mat-cell.status-cell mat-icon.not-ok {
  color: #9C27B0 !important;
}
.pink-blueGrey .mat-row .mat-cell.status-cell mat-icon.cancelled {
  color: #795548 !important;
}
.pink-blueGrey .mat-row .mat-cell.status-cell mat-icon.rejected {
  color: #df4d4c !important;
}
.pink-blueGrey .mat-row .mat-cell.status-cell mat-icon.draft {
  color: #dedede !important;
}
.pink-blueGrey .mat-row.active, .pink-blueGrey .mat-row.active:hover {
  background: #e91e63 !important;
}
.pink-blueGrey .mat-row.active div, .pink-blueGrey .mat-row.active:hover div {
  color: white;
}
.pink-blueGrey .mat-row.active .mat-cell.status-cell mat-icon.pending, .pink-blueGrey .mat-row.active .mat-cell.status-cell mat-icon.ok, .pink-blueGrey .mat-row.active .mat-cell.status-cell mat-icon.not-ok, .pink-blueGrey .mat-row.active:hover .mat-cell.status-cell mat-icon.pending, .pink-blueGrey .mat-row.active:hover .mat-cell.status-cell mat-icon.ok, .pink-blueGrey .mat-row.active:hover .mat-cell.status-cell mat-icon.not-ok {
  text-shadow: -1px 1px 0 white, 1px 1px 0 white, 1px -1px 0 white, -1px -1px 0 white;
}
.pink-blueGrey .mat-row.active .sticky-actions button, .pink-blueGrey .mat-row.active:hover .sticky-actions button {
  background: #e91e63 !important;
}
.pink-blueGrey .mat-row.active .sticky-actions button.ok mat-icon, .pink-blueGrey .mat-row.active .sticky-actions button.not-ok mat-icon, .pink-blueGrey .mat-row.active:hover .sticky-actions button.ok mat-icon, .pink-blueGrey .mat-row.active:hover .sticky-actions button.not-ok mat-icon {
  text-shadow: -1px 1px 0 white, 1px 1px 0 white, 1px -1px 0 white, -1px -1px 0 white;
}
.pink-blueGrey .mat-row:nth-child(odd) {
  background: #fafafa;
}
.pink-blueGrey .mat-row.selected div {
  color: white;
}
.pink-blueGrey .mat-row.selected:nth-child(even) {
  background: #f48fb1 !important;
}
.pink-blueGrey .mat-row.selected:nth-child(even) .sticky-actions button {
  background: #f48fb1 !important;
}
.pink-blueGrey .mat-row.selected:nth-child(odd) {
  background: #f8bbd0 !important;
}
.pink-blueGrey .mat-row.selected:nth-child(odd) .sticky-actions button {
  background: #f8bbd0 !important;
}
.pink-blueGrey .mat-row.selected.active, .pink-blueGrey .mat-row.selected.active:hover {
  background: #e91e63 !important;
}
.pink-blueGrey .mat-row.selected.active .sticky-actions button, .pink-blueGrey .mat-row.selected.active:hover .sticky-actions button {
  background: #e91e63 !important;
}
.pink-blueGrey .mat-row:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-blueGrey .mat-row:hover .sticky-actions button {
  background: white !important;
}
.pink-blueGrey .mat-row.canceled * {
  color: #795548;
}
.pink-blueGrey .mat-row.disabled div {
  color: #607D8B;
}
.pink-blueGrey .mat-row.disabled.active, .pink-blueGrey .mat-row.disabled.active:hover {
  background: #607D8B !important;
}
.pink-blueGrey .mat-row.disabled.active div, .pink-blueGrey .mat-row.disabled.active:hover div {
  color: white;
}
.pink-blueGrey .mat-row.disabled.active .sticky-actions button, .pink-blueGrey .mat-row.disabled.active:hover .sticky-actions button {
  background: #607D8B !important;
}
.pink-blueGrey .mat-row.disabled.selected.active:hover .sticky-actions button {
  background: #607D8B !important;
}
.pink-blueGrey .mat-row .sticky-actions button.ok mat-icon {
  color: #009688 !important;
}
.pink-blueGrey .mat-row .sticky-actions button.not-ok mat-icon {
  color: #9C27B0 !important;
}
.pink-blueGrey .status-table .mat-header-row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background: white;
}
.pink-blueGrey .icon-info-off,
.pink-blueGrey .certificate-off {
  color: #607D8B;
}
.pink-blueGrey .documents-search-table .mat-header-row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background: white;
}
.pink-blueGrey mat-toolbar.employee-search-sub-toolbar {
  background: white;
}
.pink-blueGrey .mat-search-field {
  border: 1px solid rgba(0, 0, 0, 0.12);
  background: #eeeeee;
}
.pink-blueGrey .mat-search-field input[type=search] {
  background: rgba(0, 0, 0, 0);
}
.pink-blueGrey .mat-search-field input[type=search]::placeholder {
  color: rgba(0, 0, 0, 0.38);
}
.pink-blueGrey .search-adv-menu .mat-search-field {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey mat-toolbar.document-search-sub-toolbar {
  background: white;
}
.pink-blueGrey app-advanced-employee-search .mat-chip {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-blueGrey .side-nav .side-trigger {
  background: white;
}
.pink-blueGrey .side-nav .menu-trigger mat-icon {
  color: rgba(0, 0, 0, 0.54);
  background: white;
}
.pink-blueGrey .side-nav .side-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0);
}
.pink-blueGrey .side-nav .filter {
  background: white;
}
.pink-blueGrey .side-nav .side-nav-container .links mat-icon span.mat-badge-content {
  color: white !important;
}
.pink-blueGrey .side-nav .side-nav-container .mat-list-base .mat-list-item:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-blueGrey .side-nav .side-nav-container .mat-list-base .mat-list-item.active {
  background: #e91e63 !important;
}
.pink-blueGrey .side-nav .side-nav-container .mat-list-base .mat-list-item.active * {
  color: white;
}
.pink-blueGrey .side-nav .side-nav-container .mat-list-base .mat-list-item.active .mat-list-item.active:hover {
  background: #e91e63 !important;
}
.pink-blueGrey .side-nav .side-nav-container .mat-list-base .mat-list-item.active .mat-list-item.active:hover * {
  color: white;
}
.pink-blueGrey .side-nav.open .side-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .side-nav.open .filter {
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.55) 16%, rgba(0, 0, 0, 0) 100%);
}
.pink-blueGrey .side-nav.open .side-nav-container .links {
  background: white;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .side-nav.open .side-nav-container .version {
  background: white !important;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .side-nav.enter .side-nav-container .links {
  background: white;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .side-nav.enter .side-nav-container .version {
  background: white !important;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .mat-menu-panel:not(.metadata-radio-menu) .navigation-list .mat-list-item-content {
  color: rgba(0, 0, 0, 0.54);
}
.pink-blueGrey table.mat-calendar-table {
  background: white !important;
}
.pink-blueGrey .mat-calendar-body-label {
  color: white !important;
}
.pink-blueGrey .mat-calendar-arrow {
  border-top-color: white !important;
}
.pink-blueGrey .mat-datepicker-content .mat-calendar-next-button,
.pink-blueGrey .mat-datepicker-content .mat-calendar-previous-button {
  color: white !important;
}
.pink-blueGrey .mat-calendar-header {
  background: #c2185b !important;
}
.pink-blueGrey mat-datepicker-content {
  background-color: #c2185b !important;
  color: white !important;
}
.pink-blueGrey .mat-calendar-content {
  background: white !important;
}
.pink-blueGrey .mat-simple-snackbar-action button.mat-button {
  color: #FFE082;
}
.pink-blueGrey .mat-simple-snackbar-action .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.pink-blueGrey mat-bottom-sheet-container mat-toolbar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}
.pink-blueGrey app-generic-bottom-sheet h4.accent {
  color: #607d8b;
}
.pink-blueGrey app-generic-bottom-sheet .verification-code {
  background: #e91e63;
  color: white;
}
.pink-blueGrey app-generic-bottom-sheet .verification-code h2 {
  background: #e91e63;
}
.pink-blueGrey app-generic-bottom-sheet .load-window.message .info-container h3.primary-text {
  color: #e91e63;
}
.pink-blueGrey app-generic-bottom-sheet .bottom_sheet-content.ok .bottom_sheet-title .bottom_sheet-icon,
.pink-blueGrey app-generic-bottom-sheet .bottom_sheet-content.ok .bottom_sheet-title h3 {
  color: #009688;
}
.pink-blueGrey app-generic-bottom-sheet .bottom_sheet-content.ok .bottom_sheet-main_btn:not(:disabled) {
  background: #009688;
  color: #fff;
}
.pink-blueGrey app-generic-bottom-sheet .bottom_sheet-content.not-ok .bottom_sheet-title .bottom_sheet-icon,
.pink-blueGrey app-generic-bottom-sheet .bottom_sheet-content.not-ok .bottom_sheet-title h3 {
  color: #9C27B0;
}
.pink-blueGrey app-generic-bottom-sheet .bottom_sheet-content.not-ok .bottom_sheet-main_btn:not(:disabled) {
  background: #9C27B0;
  color: #fff;
}
.pink-blueGrey app-generic-bottom-sheet .bottom_sheet-content.cancel .bottom_sheet-title .bottom_sheet-icon,
.pink-blueGrey app-generic-bottom-sheet .bottom_sheet-content.cancel .bottom_sheet-title h3 {
  color: #795548;
}
.pink-blueGrey app-generic-bottom-sheet .bottom_sheet-content.cancel .bottom_sheet-main_btn:not(:disabled) {
  background: #795548;
  color: #fff;
}
.pink-blueGrey app-generic-bottom-sheet .bottom_sheet-content .bottom_sheet_exp_btn {
  background-color: #e91e63;
  color: #fff;
}
.pink-blueGrey app-generic-bottom-sheet .bottom_sheet-content .bottom_sheet_canc_btn {
  background-color: lightgrey;
  color: black;
}
.pink-blueGrey app-generic-bottom-sheet .bottom_sheet-content .bottom_sheet-exp-h3 {
  color: #e91e63;
  margin-bottom: -10px !important;
}
.pink-blueGrey app-generic-bottom-sheet .bottom_sheet-content .mat-input-element-export {
  display: flex !important;
  padding-left: 15px;
}
.pink-blueGrey app-generic-bottom-sheet .bottom_sheet-content .mat-form-field-infix-export-right {
  width: 100% !important;
  padding: 0px !important;
  display: flex !important;
}
.pink-blueGrey app-generic-bottom-sheet .bottom_sheet-content .mat-form-field-infix-export-left {
  width: 100% !important;
  padding: 0px;
  display: flex !important;
}
.pink-blueGrey app-generic-bottom-sheet .bottom_sheet-content .mat-form-field-infix {
  display: flex;
}
.pink-blueGrey app-generic-bottom-sheet .bottom_sheet-content .mat-checkbox-layout .mat-checkbox-label {
  color: #607d8b;
}
.pink-blueGrey app-generic-bottom-sheet .bottom_sheet-content .choose-forms {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  margin-left: 10px !important;
}
.pink-blueGrey app-generic-bottom-sheet .bottom_sheet-content .card-container {
  max-height: 150px;
  overflow-y: auto;
}
.pink-blueGrey .mat-list .mat-list-item.active .mat-line {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey .mat-list .mat-list-item.active .mat-line b {
  color: #e91e63;
}
.pink-blueGrey .mat-list .mat-list-item.active .mat-list-avatar {
  color: #e91e63;
}
.pink-blueGrey .mat-list .mat-list-item .mat-line {
  color: rgba(0, 0, 0, 0.54);
}
.pink-blueGrey .mat-list .mat-list-item .mat-list-avatar {
  color: rgba(0, 0, 0, 0.54);
}
.pink-blueGrey .mat-list-item:hover:not(.active) {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-blueGrey mat-list-item:nth-child(even),
.pink-blueGrey app-file-document-inbox-item:nth-child(even) mat-list-item,
.pink-blueGrey app-file-document-inbox-item-documentation:nth-child(even) mat-list-item {
  background: #fafafa !important;
}
.pink-blueGrey mat-list-item:nth-child(odd),
.pink-blueGrey app-file-document-inbox-item:nth-child(odd) mat-list-item,
.pink-blueGrey app-file-document-inbox-item-documentation:nth-child(odd) mat-list-item {
  background: white !important;
}
.pink-blueGrey .mat-list-avatar.mat-icon {
  background: #eeeeee;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .card-filter {
  background: white;
}
.pink-blueGrey .login mat-horizontal-stepper {
  background: white !important;
}
.pink-blueGrey .login .login-card {
  background: white !important;
}
.pink-blueGrey .login .login-card .mat-card-content .mat-card-footer a[color=accent] {
  color: #607d8b;
}
.pink-blueGrey .login .help-stepper h3.align-16 {
  color: #607d8b;
}
.pink-blueGrey .login .help-stepper .help-options mat-icon {
  color: #607d8b;
}
.pink-blueGrey .ux-modal {
  background: rgba(0, 0, 0, 0.32);
}
.pink-blueGrey .ux-modal .card {
  background: white;
}
.pink-blueGrey .main-title {
  background: #607D8B;
  color: white;
}
.pink-blueGrey .sub-title {
  color: rgba(0, 0, 0, 0.54);
}
.pink-blueGrey .card-footer a {
  color: white;
}
.pink-blueGrey .profile-toolbar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey app-profile .logout-btn:hover {
  background: #eeeeee;
}
.pink-blueGrey app-profile .cropper {
  background: white;
}
.pink-blueGrey app-profile .cropper .cropper {
  background: unset;
}
.pink-blueGrey app-add-documentation .employee-add-documentation .inbox-toolbar {
  background: #fafafa;
}
.pink-blueGrey app-add-documentation .employee-add-documentation .inbox-toolbar .toolbar-line mat-icon {
  color: #e91e63;
}
.pink-blueGrey app-add-documentation .employee-add-documentation .inbox-toolbar .toolbar-line h3 {
  color: #e91e63;
}
.pink-blueGrey app-add-documentation .employee-add-documentation mat-tab-group {
  background: #fafafa;
}
.pink-blueGrey app-add-documentation .employee-add-documentation mat-tab-group mat-tab-header .mat-tab-label-active {
  color: #e91e63;
}
.pink-blueGrey app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content:hover {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12) !important;
}
.pink-blueGrey app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content.selected {
  border-left: 6px solid #e91e63 !important;
}
.pink-blueGrey app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content.selected mat-icon {
  color: #e91e63 !important;
}
.pink-blueGrey app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content.selected .item-data {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-blueGrey app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content mat-icon {
  color: rgba(0, 0, 0, 0.54);
}
.pink-blueGrey app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content .item-data {
  color: rgba(0, 0, 0, 0.54);
}
.pink-blueGrey app-add-documentation .employee-add-documentation .detail app-file-document-view .new-container app-file-document-upload .metadata-list .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
  background: white !important;
}
.pink-blueGrey app-pending .inbox-toolbar {
  background: #fafafa;
}
.pink-blueGrey app-pending .pending-container {
  background: #fafafa;
}
.pink-blueGrey app-pending .pending-container .pending-items .pending-item .content:hover {
  background: rgba(0, 0, 0, 0.04) !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12) !important;
}
.pink-blueGrey app-pending .pending-container .pending-items .pending-item .content.selected {
  border-left: 6px solid #607d8b !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12) !important;
}
.pink-blueGrey app-pending .pending-container .pending-items .pending-item .content.selected mat-icon {
  color: #607d8b !important;
}
.pink-blueGrey app-pending .pending-container .pending-items .pending-item .content.selected .item-data {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-blueGrey app-pending .pending-container .pending-items .pending-item .content mat-icon {
  color: rgba(0, 0, 0, 0.54);
}
.pink-blueGrey app-pending .pending-container .pending-items .pending-item .content .item-data {
  color: rgba(0, 0, 0, 0.54);
}
.pink-blueGrey app-document-sign-massive .inbox-container .inbox-toolbar {
  background: #fafafa;
}
.pink-blueGrey app-document-sign-massive .inbox-container .pending-container {
  background: #fafafa;
}
.pink-blueGrey app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content:hover {
  background: rgba(0, 0, 0, 0.04) !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12) !important;
}
.pink-blueGrey app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content.selected {
  border-left: 6px solid #607d8b !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12) !important;
}
.pink-blueGrey app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content.selected mat-icon {
  color: #607d8b !important;
}
.pink-blueGrey app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content.selected .item-data {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-blueGrey app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content mat-icon {
  color: rgba(0, 0, 0, 0.54);
}
.pink-blueGrey app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content .item-data {
  color: rgba(0, 0, 0, 0.54);
}
.pink-blueGrey app-document-sign-massive .detail app-file-document-detail .view-container {
  background: #607D8B;
}
.pink-blueGrey app-document-sign-massive .detail app-file-document-detail .view-container .mat-toolbar {
  background: #607D8B;
  color: white;
  background: #607D8B;
}
.pink-blueGrey app-document-sign-massive .detail app-chapa.minimized .info-empty {
  background: #607d8b;
}
.pink-blueGrey app-document-sign-massive .detail app-chapa.minimized .info-empty > span mat-icon {
  color: white !important;
}
.pink-blueGrey app-document-sign-massive .detail app-chapa.minimized .info-empty > span h2 {
  color: white !important;
}
.pink-blueGrey app-document-sign-massive .signer.desktop {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey app-document-sign-massive .signer .signer-btn {
  color: white !important;
}
.pink-blueGrey app-document-sign-massive .signer .signer-btn[disabled] {
  background-color: rgba(0, 0, 0, 0.12);
}
.pink-blueGrey app-document-detail app-sign-document .firmador button.mat-raised-button.mat-accent {
  color: white !important;
}
.pink-blueGrey mat-toolbar-row.firmado-conforme .mat-icon {
  background: #8BC34A;
  color: white;
}
.pink-blueGrey mat-toolbar-row.firmado-no-conforme .mat-icon {
  background: #f44336;
  color: white;
}
.pink-blueGrey .secondary-toolbar {
  background: white;
}
.pink-blueGrey .new-detail-container {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .new-detail-container.cancelled .detail_head-body {
  background: #607D8B !important;
}
.pink-blueGrey .new-detail-container .employee-detail-toolbar {
  background: #eeeeee;
}
.pink-blueGrey .new-detail-container .employee-detail-subbar {
  background: white;
}
.pink-blueGrey app-employee-detail .load-window,
.pink-blueGrey app-employee-rrhh-detail .load-window {
  background: #fafafa;
}
.pink-blueGrey app-employee-detail .employee_detail-container,
.pink-blueGrey app-employee-rrhh-detail .employee_detail-container {
  background: #fafafa;
}
.pink-blueGrey app-employee-detail .employee_detail-container.is-editing .edition-line,
.pink-blueGrey app-employee-rrhh-detail .employee_detail-container.is-editing .edition-line {
  background: #eeeeee;
}
.pink-blueGrey app-employee-detail .employee_detail-container .detail_head-body,
.pink-blueGrey app-employee-rrhh-detail .employee_detail-container .detail_head-body {
  background: #e91e63;
}
.pink-blueGrey app-employee-detail .employee_detail-container .detail_head-body .head_body-status .icon-stacked + mat-icon,
.pink-blueGrey app-employee-rrhh-detail .employee_detail-container .detail_head-body .head_body-status .icon-stacked + mat-icon {
  color: white !important;
}
.pink-blueGrey app-employee-detail .employee_detail-container .detail_head-body .head_body-title .body_title-name,
.pink-blueGrey app-employee-rrhh-detail .employee_detail-container .detail_head-body .head_body-title .body_title-name {
  color: white;
}
.pink-blueGrey app-employee-detail .employee_detail-container .detail_head-body .head_body-title .body_title-user,
.pink-blueGrey app-employee-rrhh-detail .employee_detail-container .detail_head-body .head_body-title .body_title-user {
  color: white;
}
.pink-blueGrey app-employee-detail .employee_detail-container .data .data-line,
.pink-blueGrey app-employee-rrhh-detail .employee_detail-container .data .data-line {
  background: white;
}
.pink-blueGrey app-employee-detail .employee_detail-container .data .data-line .icon-complex mat-icon.complex,
.pink-blueGrey app-employee-rrhh-detail .employee_detail-container .data .data-line .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px white, 0 -2px 0px white, 2px 0 0px white, 0 2px 0px white, -2px -2px 0px white, 2px 2px 0px white, 2px -2px 0px white, -2px 2px 0px white;
}
.pink-blueGrey app-employee-detail .employee_detail-container .data .data-line .line_text-main,
.pink-blueGrey app-employee-rrhh-detail .employee_detail-container .data .data-line .line_text-main {
  color: #e91e63;
}
.pink-blueGrey app-employee-detail .employee_detail-container .data .data-line.clickable:hover,
.pink-blueGrey app-employee-rrhh-detail .employee_detail-container .data .data-line.clickable:hover {
  background: #fafafa !important;
}
.pink-blueGrey app-employee-detail .employee_detail-container .data .data-line button.mat-icon-button,
.pink-blueGrey app-employee-rrhh-detail .employee_detail-container .data .data-line button.mat-icon-button {
  background: #eeeeee;
}
.pink-blueGrey app-employee-detail .employee_detail-container .employee-title .title-text,
.pink-blueGrey app-employee-rrhh-detail .employee_detail-container .employee-title .title-text {
  color: #e91e63;
}
.pink-blueGrey app-employee-detail .employee_detail-container .employee-title .title-text mat-icon,
.pink-blueGrey app-employee-rrhh-detail .employee_detail-container .employee-title .title-text mat-icon {
  color: #e91e63;
}
.pink-blueGrey app-employee-detail .employee_detail-container .employee-title .title-text .title_text-main,
.pink-blueGrey app-employee-rrhh-detail .employee_detail-container .employee-title .title-text .title_text-main {
  color: #e91e63;
}
.pink-blueGrey .UI-V2 .icon-complex mat-icon.complex {
  color: #e91e63;
  text-shadow: -2px 0 0px white, 0 -2px 0px white, 2px 0 0px white, 0 2px 0px white, -2px -2px 0px white, 2px 2px 0px white, 2px -2px 0px white, -2px 2px 0px white;
}
.pink-blueGrey .UI-V2 .icon-complex mat-icon.complex.mat-accent {
  color: #607d8b;
}
.pink-blueGrey .UI-V2 .main .toolbar .header .content .module-title {
  color: #e91e63;
}
.pink-blueGrey .UI-V2 .main .toolbar .header .content .module-title.selectable:hover {
  background: rgba(0, 0, 0, 0.04);
}
.pink-blueGrey .UI-V2 .main .toolbar .header .content .module-title .info h5 {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-blueGrey .UI-V2 .main .toolbar .header .content .ou-select {
  color: white;
  background: #e91e63;
}
.pink-blueGrey .UI-V2 .main .toolbar .search-container .search .content {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .UI-V2 .main .toolbar .search-container .search .content:hover, .pink-blueGrey .UI-V2 .main .toolbar .search-container .search .content:active, .pink-blueGrey .UI-V2 .main .toolbar .search-container .search .content:focus, .pink-blueGrey .UI-V2 .main .toolbar .search-container .search .content:focus-within {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-blueGrey .UI-V2 .main .toolbar .search-container .search .content input {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey .UI-V2 .main .helper {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .UI-V2 .main .helper mat-hint h4 {
  color: #e91e63;
}
.pink-blueGrey .UI-V2 .main .new-table app-cs-grid-control datatable-header * {
  color: #e91e63;
}
.pink-blueGrey .UI-V2 .main .new-table .mat-header-row.sticky * {
  color: #e91e63 !important;
}
.pink-blueGrey .UI-V2 .main .main-action {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .menu-options.help .primary {
  color: #e91e63;
}
.pink-blueGrey .menu-options.help .accent {
  color: #607d8b;
}
.pink-blueGrey .menu-options .icon-complex mat-icon.complex {
  color: #e91e63;
  text-shadow: -2px 0 0px white, 0 -2px 0px white, 2px 0 0px white, 0 2px 0px white, -2px -2px 0px white, 2px 2px 0px white, 2px -2px 0px white, -2px 2px 0px white;
}
.pink-blueGrey .menu-options .mat-menu-content .container mat-list .mat-list-item:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-blueGrey .menu-options .mat-menu-content .container mat-list .mat-list-item .disabled {
  color: rgba(0, 0, 0, 0.54) !important;
}
.pink-blueGrey .menu-options .mat-menu-content .container .slide-group mat-radio-group mat-radio-button:hover {
  background: rgba(0, 0, 0, 0.04);
}
.pink-blueGrey .menu-options .mat-menu-content .container .slide-group mat-form-field .mat-form-field-flex {
  background: #fafafa;
}
.pink-blueGrey .menu-options .mat-menu-content .container .slide-group mat-slide-toggle:hover {
  background: rgba(0, 0, 0, 0.04);
}
.pink-blueGrey .mat-menu-panel.notifications-panel .mat-menu-content .notifications-body {
  background: #fafafa;
}
.pink-blueGrey .mat-menu-panel.notifications-panel .mat-menu-content .notification .primary mat-icon {
  color: #e91e63;
}
.pink-blueGrey .mat-menu-panel.notifications-panel .mat-menu-content .notification .primary h3 {
  color: #e91e63;
}
.pink-blueGrey .mat-menu-panel.notifications-panel .mat-menu-content .notification.top {
  background: #fafafa;
}
.pink-blueGrey .mat-menu-panel.notifications-panel .mat-menu-content .notification.top .header h3 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey .mat-menu-panel.notifications-panel .mat-menu-content .notification.bottom {
  background: #fafafa;
}
.pink-blueGrey .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable:nth-child(even) {
  background: #fafafa;
}
.pink-blueGrey .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable:nth-child(odd) {
  background: white;
}
.pink-blueGrey .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable:hover {
  background: rgba(0, 0, 0, 0.04);
}
.pink-blueGrey .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable mat-icon {
  color: #e91e63;
}
.pink-blueGrey .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable h3 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable h5 {
  color: rgba(0, 0, 0, 0.38);
}
.pink-blueGrey .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable .subtitle h5 {
  color: #e91e63;
  background: #eeeeee;
}
.pink-blueGrey .info-empty .large-icon {
  background: #f8bbd0;
  color: #e91e63;
}
.pink-blueGrey .info-empty h2,
.pink-blueGrey .info-empty h3 {
  color: #e91e63;
}
.pink-blueGrey .info-empty h4,
.pink-blueGrey .info-empty h5 {
  color: rgba(0, 0, 0, 0.54);
}
.pink-blueGrey .info-empty.accent mat-icon {
  background: #cfd8dc;
  color: #607d8b;
}
.pink-blueGrey .info-empty.accent h2 {
  color: #607d8b;
}
.pink-blueGrey .info-empty .actions {
  padding: 16px;
  height: 96px;
  column-gap: 16px;
}
.pink-blueGrey .info-empty .actions button {
  height: 100%;
  border-radius: 40px;
}
.pink-blueGrey .info-empty .actions button.ok {
  background: #009688 !important;
  color: white;
}
.pink-blueGrey .info-empty .actions button.not-ok {
  color: #9C27B0 !important;
}
.pink-blueGrey .side.edition .info-empty .large-icon {
  background: #cfd8dc;
  color: #607d8b;
}
.pink-blueGrey .side.edition .info-empty h2 {
  color: #607d8b;
}
.pink-blueGrey .side.edition .info-empty h4 {
  color: rgba(0, 0, 0, 0.54);
}
.pink-blueGrey .UI-V2 .deferred-process-list .header h3 {
  color: #e91e63;
}
.pink-blueGrey .UI-V2 .deferred-process-list .search-container .search .content {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .UI-V2 .deferred-process-list .search-container .search .content:hover, .pink-blueGrey .UI-V2 .deferred-process-list .search-container .search .content:active, .pink-blueGrey .UI-V2 .deferred-process-list .search-container .search .content:focus, .pink-blueGrey .UI-V2 .deferred-process-list .search-container .search .content:focus-within {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-blueGrey .UI-V2 .deferred-process-list .search-container .search .content input {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey .UI-V2 .deferred-process-list .text-success,
.pink-blueGrey .UI-V2 .deferred-process-details .text-success {
  color: #8BC34A;
}
.pink-blueGrey .UI-V2 .deferred-process-list .text-warn,
.pink-blueGrey .UI-V2 .deferred-process-details .text-warn {
  color: #f44336;
}
.pink-blueGrey .UI-V2 .deferred-process-list .text-primary,
.pink-blueGrey .UI-V2 .deferred-process-details .text-primary {
  color: #e91e63;
}
.pink-blueGrey .UI-V2 .deferred-process-list .text-atention,
.pink-blueGrey .UI-V2 .deferred-process-details .text-atention {
  color: #DB7703;
}
.pink-blueGrey .UI-V2 .deferred-process-list .table-paginator-container,
.pink-blueGrey .UI-V2 .deferred-process-details .table-paginator-container {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
}
.pink-blueGrey .UI-V2 .deferred-process-list .table-paginator-container .mat-table,
.pink-blueGrey .UI-V2 .deferred-process-details .table-paginator-container .mat-table {
  border-bottom: none;
  border-radius: 10px 10px 0 0;
}
.pink-blueGrey .UI-V2 .deferred-process-list .table-paginator-container .paginator-container,
.pink-blueGrey .UI-V2 .deferred-process-details .table-paginator-container .paginator-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: #f2f2f2;
  padding: 0 1.5rem;
  height: 3rem;
}
.pink-blueGrey .UI-V2 .deferred-process-list .mat-table,
.pink-blueGrey .UI-V2 .deferred-process-details .mat-table {
  border: 1px solid #f2f2f2;
  border-radius: 10px;
  overflow: hidden;
}
.pink-blueGrey .UI-V2 .deferred-process-list .mat-table .mat-header-row .mat-header-cell,
.pink-blueGrey .UI-V2 .deferred-process-details .mat-table .mat-header-row .mat-header-cell {
  font-size: 14px;
  font-weight: 700;
}
.pink-blueGrey .UI-V2 .deferred-process-list .mat-table .mat-header-row,
.pink-blueGrey .UI-V2 .deferred-process-details .mat-table .mat-header-row {
  background: #f2f2f2;
}
.pink-blueGrey .UI-V2 .deferred-process-list .mat-table .mat-header-row .mat-header-cell,
.pink-blueGrey .UI-V2 .deferred-process-details .mat-table .mat-header-row .mat-header-cell {
  color: #e91e63;
}
.pink-blueGrey .UI-V2 .deferred-process-list .mat-table .mat-row,
.pink-blueGrey .UI-V2 .deferred-process-details .mat-table .mat-row {
  background: white;
}
.pink-blueGrey .UI-V2 .progress-bar-container {
  position: relative;
}
.pink-blueGrey .UI-V2 .deferred-process-details .header h3 {
  color: #e91e63;
}
.pink-blueGrey .UI-V2 .deferred-process-details .header .back-link:visited {
  color: #e91e63;
}
.pink-blueGrey .process-card,
.pink-blueGrey .process-card-detail,
.pink-blueGrey .process-card-stages {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .process-card mat-card-subtitle,
.pink-blueGrey .process-card-detail mat-card-subtitle,
.pink-blueGrey .process-card-stages mat-card-subtitle {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey .process-card .stages-container .stage.completed mat-icon,
.pink-blueGrey .process-card-detail .stages-container .stage.completed mat-icon,
.pink-blueGrey .process-card-stages .stages-container .stage.completed mat-icon {
  color: #499719;
}
.pink-blueGrey .process-card .stages-container .stage.completed .icon-container,
.pink-blueGrey .process-card-detail .stages-container .stage.completed .icon-container,
.pink-blueGrey .process-card-stages .stages-container .stage.completed .icon-container {
  background-color: rgba(78, 160, 28, 0.1490196078);
}
.pink-blueGrey .process-card .stages-container .stage.completed .line,
.pink-blueGrey .process-card-detail .stages-container .stage.completed .line,
.pink-blueGrey .process-card-stages .stages-container .stage.completed .line {
  background-color: #499719;
}
.pink-blueGrey .process-card .stages-container .stage.on-going mat-icon,
.pink-blueGrey .process-card-detail .stages-container .stage.on-going mat-icon,
.pink-blueGrey .process-card-stages .stages-container .stage.on-going mat-icon {
  color: #e91e63;
}
.pink-blueGrey .process-card .stages-container .stage.on-going .icon-container,
.pink-blueGrey .process-card-detail .stages-container .stage.on-going .icon-container,
.pink-blueGrey .process-card-stages .stages-container .stage.on-going .icon-container {
  background-color: rgba(66, 48, 159, 0.2);
}
.pink-blueGrey .process-card .stages-container .stage.on-going .line,
.pink-blueGrey .process-card-detail .stages-container .stage.on-going .line,
.pink-blueGrey .process-card-stages .stages-container .stage.on-going .line {
  background-color: #949494;
}
.pink-blueGrey .process-card .stages-container .stage.pending,
.pink-blueGrey .process-card-detail .stages-container .stage.pending,
.pink-blueGrey .process-card-stages .stages-container .stage.pending {
  color: #949494;
}
.pink-blueGrey .process-card .stages-container .stage.pending mat-icon,
.pink-blueGrey .process-card-detail .stages-container .stage.pending mat-icon,
.pink-blueGrey .process-card-stages .stages-container .stage.pending mat-icon {
  color: #949494;
}
.pink-blueGrey .process-card .stages-container .stage.pending .icon-container,
.pink-blueGrey .process-card-detail .stages-container .stage.pending .icon-container,
.pink-blueGrey .process-card-stages .stages-container .stage.pending .icon-container {
  background-color: rgba(174, 174, 174, 0.2);
}
.pink-blueGrey .process-card .stages-container .stage.pending .line,
.pink-blueGrey .process-card-detail .stages-container .stage.pending .line,
.pink-blueGrey .process-card-stages .stages-container .stage.pending .line {
  background-color: #949494;
}
.pink-blueGrey .process-card .stages-container .stage.has-error mat-icon,
.pink-blueGrey .process-card-detail .stages-container .stage.has-error mat-icon,
.pink-blueGrey .process-card-stages .stages-container .stage.has-error mat-icon {
  color: #f44336;
}
.pink-blueGrey .process-card .stages-container .stage.has-error .icon-container,
.pink-blueGrey .process-card-detail .stages-container .stage.has-error .icon-container,
.pink-blueGrey .process-card-stages .stages-container .stage.has-error .icon-container {
  background-color: rgba(188, 4, 4, 0.2);
}
.pink-blueGrey .process-card .stages-container .stage-description .button-link,
.pink-blueGrey .process-card-detail .stages-container .stage-description .button-link,
.pink-blueGrey .process-card-stages .stages-container .stage-description .button-link {
  color: #e91e63;
}
.pink-blueGrey app-custom-search-input .custom-search-container {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
  transition: color 0.1s ease, background 0.15s ease;
}
.pink-blueGrey app-custom-search-input .custom-search-container mat-icon.mat-icon {
  color: rgba(0, 0, 0, 0.38);
}
.pink-blueGrey app-custom-search-input .custom-search-container input.custom-search-input {
  color: rgba(0, 0, 0, 0.87);
  background: unset;
}
.pink-blueGrey app-custom-search-input .custom-search-container:hover, .pink-blueGrey app-custom-search-input .custom-search-container:active, .pink-blueGrey app-custom-search-input .custom-search-container:focus, .pink-blueGrey app-custom-search-input .custom-search-container:focus-within {
  background: rgba(0, 0, 0, 0.04) !important;
  transition: background 0.15s ease;
}
.pink-blueGrey .UI-V2 .audit-filter {
  background: white;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .UI-V2 .audit-filter .toolbar {
  background: #e91e63;
  color: white;
}
.pink-blueGrey .UI-V2 .audit-filter .toolbar .header .content .module-title {
  color: #e91e63;
}
.pink-blueGrey .UI-V2 .audit-filter .toolbar .header .content .module-title.selectable:hover {
  background: rgba(0, 0, 0, 0.04);
}
.pink-blueGrey .UI-V2 .audit-filter .toolbar .header .content .module-title .info * {
  color: white !important;
}
.pink-blueGrey .UI-V2 .audit-filter .toolbar .header .content .ou-select {
  color: white;
  background: #e91e63;
}
.pink-blueGrey .UI-V2 .audit-filter .toolbar .search-container .search .content {
  background: #fafafa;
}
.pink-blueGrey .UI-V2 .audit-filter .toolbar .search-container .search .content:hover, .pink-blueGrey .UI-V2 .audit-filter .toolbar .search-container .search .content:active, .pink-blueGrey .UI-V2 .audit-filter .toolbar .search-container .search .content:focus, .pink-blueGrey .UI-V2 .audit-filter .toolbar .search-container .search .content:focus-within {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-blueGrey .UI-V2 .audit-filter .toolbar .search-container .search .content input {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey .UI-V2 .audit-filter .filter-container {
  background: white !important;
}
.pink-blueGrey .UI-V2 .audit-filter .filter-container .form-body {
  background: #fafafa !important;
}
.pink-blueGrey .UI-V2 .audit-filter .filter-container .form-body .line h4 {
  color: #e91e63;
}
.pink-blueGrey .UI-V2 .audit-filter .filter-container .form-body .line mat-icon {
  color: #e91e63;
}
.pink-blueGrey .UI-V2 .audit-result .result-container .toolbar {
  color: white;
}
.pink-blueGrey .UI-V2 .audit-result .result-container .toolbar .header .content .module-title {
  color: #e91e63;
}
.pink-blueGrey .UI-V2 .audit-result .result-container .toolbar .header .content .module-title.selectable:hover {
  background: rgba(0, 0, 0, 0.04);
}
.pink-blueGrey .UI-V2 .audit-result .result-container .toolbar .header .content .module-title .info h5 {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-blueGrey .UI-V2 .audit-result .result-container .toolbar .header .content .ou-select {
  color: white;
  background: #e91e63;
}
.pink-blueGrey .UI-V2 .audit-result .result-container .toolbar .search-container .search .content {
  background: #fafafa;
}
.pink-blueGrey .UI-V2 .audit-result .result-container .toolbar .search-container .search .content:hover, .pink-blueGrey .UI-V2 .audit-result .result-container .toolbar .search-container .search .content:active, .pink-blueGrey .UI-V2 .audit-result .result-container .toolbar .search-container .search .content:focus, .pink-blueGrey .UI-V2 .audit-result .result-container .toolbar .search-container .search .content:focus-within {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-blueGrey .UI-V2 .audit-result .result-container .toolbar .search-container .search .content input {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey .UI-V2 .audit-result .result-container .new-table app-cs-grid-control datatable-header * {
  color: #e91e63;
}
.pink-blueGrey .UI-V2 .audit-result .result-container .new-table .mat-header-row.sticky * {
  color: #e91e63 !important;
}
.pink-blueGrey .UI-V2 .audit-detail-container {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  background: #eeeeee !important;
}
.pink-blueGrey .UI-V2 .audit-detail-container .side .toolbar .header {
  color: #e91e63;
}
.pink-blueGrey .UI-V2 .audit-detail-container .side .container .mat-list-base {
  background: #fafafa !important;
}
.pink-blueGrey .UI-V2 .audit-detail-container .side .container .mat-list-base mat-list-item .mat-line b {
  color: #e91e63;
}
.pink-blueGrey .UI-V2 .inbox-container {
  background: white;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .UI-V2 .inbox-container *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.pink-blueGrey .UI-V2 .inbox-container *::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}
.pink-blueGrey .UI-V2 .inbox-container *::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}
.pink-blueGrey .UI-V2 .inbox-container .load-window {
  background: white;
}
.pink-blueGrey .UI-V2 .inbox-container .toolbar {
  background: #e91e63;
  color: white;
}
.pink-blueGrey .UI-V2 .inbox-container .toolbar.reverse {
  background: white;
  color: rgba(0, 0, 0, 0.54);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel {
  background: #eeeeee !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel.mat-expanded {
  background: #fafafa !important;
}
.pink-blueGrey .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel.mat-expanded mat-expansion-panel-header {
  background: #fafafa !important;
}
.pink-blueGrey .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel.mat-expanded mat-expansion-panel-header:hover {
  background: none !important;
}
.pink-blueGrey .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel mat-expansion-panel-header {
  background: #fafafa !important;
}
.pink-blueGrey .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel mat-expansion-panel-header:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-blueGrey .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel mat-expansion-panel-header .signature-inbox-header .mat-icon,
.pink-blueGrey .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel mat-expansion-panel-header .signature-inbox-header b {
  color: #607d8b;
}
.pink-blueGrey .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel .mat-expansion-panel-body .item {
  background: white;
  color: rgba(0, 0, 0, 0.54);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel .mat-expansion-panel-body .item:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-blueGrey .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel .mat-expansion-panel-body .item.active {
  border-left: 6px solid #607d8b;
}
.pink-blueGrey .UI-V2 .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.pink-blueGrey .UI-V2 .work-space .main .load-window {
  background: white !important;
}
.pink-blueGrey .UI-V2 .work-space .main-action {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey mat-radio-button.disabled .mat-radio-label-content {
  color: #607d8b;
}
.pink-blueGrey .detail app-automatic-process-detail app-chapa.minimize,
.pink-blueGrey .detail app-process-detail app-chapa.minimize {
  background: #FFD54F !important;
}
.pink-blueGrey .detail app-automatic-process-detail app-chapa.minimize.purged,
.pink-blueGrey .detail app-process-detail app-chapa.minimize.purged {
  background: #b2adb0 !important;
}
.pink-blueGrey .detail .detail-toolbar {
  background: #eeeeee;
}
.pink-blueGrey .detail .processes-status .status-bar .bar-graph {
  background-color: #FFD54F;
}
.pink-blueGrey .detail .processes-status .status-bar .bar-graph .graph-ok {
  background-color: #4DB6AC;
}
.pink-blueGrey .detail .processes-status .status-bar .bar-graph .graph-not-ok {
  background-color: #F48FB1;
}
.pink-blueGrey .detail .processes-status .status-resume b {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey .detail .processes-status .status-resume .resume-total b {
  background-color: #FFD54F;
}
.pink-blueGrey .detail .processes-status .status-resume .resume-ok b {
  background-color: #4DB6AC;
}
.pink-blueGrey .detail .processes-status .status-resume .resume-not-ok b {
  background-color: #F48FB1;
}
.pink-blueGrey .detail .detail-toolbar .mat-avatar h3,
.pink-blueGrey .detail .detail-toolbar .mat-avatar h5 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey .detail .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey .detail .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey .detail .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey .detail .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey .detail .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  color: white;
}
.pink-blueGrey .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  background: #4DB6AC !important;
}
.pink-blueGrey .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  background: #b2dfdb !important;
}
.pink-blueGrey .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  background: #b2dfdb !important;
}
.pink-blueGrey .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  background: #4DB6AC !important;
}
.pink-blueGrey .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  background: #b2dfdb !important;
}
.pink-blueGrey .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  background: #837C80 !important;
}
.pink-blueGrey .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  background: #b2adb0 !important;
}
.pink-blueGrey .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  background: #b2adb0 !important;
}
.pink-blueGrey .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  background: #837C80 !important;
}
.pink-blueGrey .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  background: #b2dfdb !important;
}
.pink-blueGrey .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  background: #FFD54F !important;
}
.pink-blueGrey .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  background: #ffecb3 !important;
}
.pink-blueGrey .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  background: #ffecb3 !important;
}
.pink-blueGrey .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  background: #FFD54F !important;
}
.pink-blueGrey .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  background: #ffecb3 !important;
}
.pink-blueGrey .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  background: #F48FB1 !important;
}
.pink-blueGrey .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  background: #f8bbd0 !important;
}
.pink-blueGrey .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  background: #f8bbd0 !important;
}
.pink-blueGrey .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  background: #F48FB1 !important;
}
.pink-blueGrey .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  background: #f8bbd0 !important;
}
.pink-blueGrey app-analytics-document-set .sets-container .mat-list-base.sets-list mat-list-item {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey app-analytics-document-set .sets-container .mat-list-base.sets-list mat-list-item .mat-list-item-content .set-status .value-percentile .undone-value {
  background: #673AB7;
}
.pink-blueGrey app-analytics-document-set .sets-container .mat-list-base.sets-list mat-list-item .mat-list-item-content .set-status .value-percentile .not-value {
  background: #eeeeee;
}
.pink-blueGrey app-analytics-document-set .sets-container .mat-list-base.sets-list mat-list-item .mat-list-item-content .set-status .value-percentile .done-value {
  background: #009688;
}
.pink-blueGrey app-analytics-document-set .work-space .main .toolbar .header .content .module-title .info .info-text .set-status {
  cursor: help;
}
.pink-blueGrey app-analytics-document-set .work-space .main .toolbar .header .content .module-title .info .info-text .set-status .value-percentile .undone-value {
  background: #673AB7;
}
.pink-blueGrey app-analytics-document-set .work-space .main .toolbar .header .content .module-title .info .info-text .set-status .value-percentile .not-value {
  background: #eeeeee;
}
.pink-blueGrey app-analytics-document-set .work-space .main .toolbar .header .content .module-title .info .info-text .set-status .value-percentile .done-value {
  background: #009688;
}
.pink-blueGrey app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar .mat-progress-bar-buffer {
  background-color: #eeeeee;
}
.pink-blueGrey app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar.class1 .mat-progress-bar-fill::after {
  background-color: #f44336;
}
.pink-blueGrey app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar.class2 .mat-progress-bar-fill::after {
  background-color: #ff9800;
}
.pink-blueGrey app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar.class3 .mat-progress-bar-fill::after {
  background-color: #ffeb3b;
}
.pink-blueGrey app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar.class4 .mat-progress-bar-fill::after {
  background-color: #8bc34a;
}
.pink-blueGrey app-analytics-document-set .work-space .side .container mat-list {
  background: white !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey app-analytics-document-set .work-space .side .container mat-list mat-list-item .mat-line b {
  color: #e91e63;
}
.pink-blueGrey .catcher {
  background: rgba(0, 0, 0, 0.6);
}
.pink-blueGrey .catcher.clear {
  background: rgba(0, 0, 0, 0);
}
.pink-blueGrey .add-menu-form-body .mat-radio-group .mat-radio-button:hover {
  background: rgba(0, 0, 0, 0.04);
}
.pink-blueGrey .add-menu-form-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey .add-menu-form-body.w-table .helper .search-bar {
  background: #fafafa;
}
.pink-blueGrey .add-menu-form-body.w-table .helper .search-bar:hover, .pink-blueGrey .add-menu-form-body.w-table .helper .search-bar:active, .pink-blueGrey .add-menu-form-body.w-table .helper .search-bar:focus, .pink-blueGrey .add-menu-form-body.w-table .helper .search-bar:focus-within {
  background: #fafafa !important;
}
.pink-blueGrey .add-menu-form-body.w-table .helper .search-bar input {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey .add-menu-form-actions {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .file-upload-list mat-list-item {
  background: white;
}
.pink-blueGrey .mat-horizontal-stepper-content[aria-expanded=true] app-file-document-view {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .mat-horizontal-stepper-content[aria-expanded=true] app-file-document-view .pdf-view-container {
  background: #eeeeee;
}
.pink-blueGrey .add-docs-drop .docs-drop-preview app-file-document-detail {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .add-docs-drop .docs-drop-preview app-file-document-detail .view-toolbar {
  background: white;
}
.pink-blueGrey .add-docs-drop .load-window.message h3.primary-text {
  color: #e91e63;
}
.pink-blueGrey mat-dialog-container app-add-menu-dialog .load-window.message h3.primary-text {
  color: #e91e63;
}
.pink-blueGrey .asisted-input.aligned-icon.subtitle * {
  color: rgba(0, 0, 0, 0.38) !important;
}
.pink-blueGrey .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble {
  background: #e91e63;
}
.pink-blueGrey .asisted-input .mat-form-field-invalid + .indicator > .bubble {
  background: #f44336;
}
.pink-blueGrey .asisted-input .mat-form-field-disabled + .indicator > .bubble {
  color: rgba(0, 0, 0, 0.38);
  background: rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .asisted-input .indicator .bubble {
  background: rgba(0, 0, 0, 0.38);
  color: white;
}
.pink-blueGrey .asisted-input .indicator .guide {
  border-color: rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .asisted-input.icon-only .bubble {
  color: rgba(0, 0, 0, 0.54);
}
.pink-blueGrey .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble {
  color: #e91e63;
}
.pink-blueGrey .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble {
  color: #f44336;
}
.pink-blueGrey .UI-V2 .main-overlay {
  background: rgba(0, 0, 0, 0.32);
}
.pink-blueGrey .UI-V2 .icon-complex mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.pink-blueGrey .UI-V2 .icon-complex mat-icon.complex {
  color: #e91e63 !important;
}
.pink-blueGrey .UI-V2 .navigation {
  background: #fafafa;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .UI-V2 .navigation *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.pink-blueGrey .UI-V2 .navigation *::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}
.pink-blueGrey .UI-V2 .navigation .load-window {
  background: #eeeeee;
}
.pink-blueGrey .UI-V2 .navigation .toolbar {
  background: #e91e63;
  color: white;
}
.pink-blueGrey .UI-V2 .navigation .toolbar.reverse {
  background: white;
  color: rgba(0, 0, 0, 0.54);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .UI-V2 .navigation .container .helper {
  color: rgba(0, 0, 0, 0.54);
}
.pink-blueGrey .UI-V2 .navigation .container .item {
  background: white;
  color: rgba(0, 0, 0, 0.54);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .UI-V2 .navigation .container .item:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-blueGrey .UI-V2 .navigation .container .item.active {
  background: #e91e63 !important;
  color: white;
  border: 1px solid #e91e63;
}
.pink-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .separator .subtitle,
.pink-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .separator .subtitle,
.pink-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .separator .subtitle,
.pink-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .separator .subtitle,
.pink-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .separator .subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.pink-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .img-editior-container .img-editor .separator .subtitle,
.pink-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .img-editior-container .img-editor .separator .subtitle,
.pink-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .img-editior-container .img-editor .separator .subtitle,
.pink-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .img-editior-container .img-editor .separator .subtitle,
.pink-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .img-editior-container .img-editor .separator .subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.pink-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme,
.pink-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme,
.pink-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme,
.pink-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme,
.pink-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .main-color-container .main-color,
.pink-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .main-color-container .main-color,
.pink-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .main-color-container .main-color,
.pink-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .main-color-container .main-color,
.pink-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .main-color-container .main-color {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .main-color-container .main-color:before,
.pink-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .main-color-container .main-color:before,
.pink-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .main-color-container .main-color:before,
.pink-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .main-color-container .main-color:before,
.pink-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .main-color-container .main-color:before {
  border-left: 10px solid rgba(0, 0, 0, 0);
  border-right: 10px solid rgba(0, 0, 0, 0);
}
.pink-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example h6.required,
.pink-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example h6.required,
.pink-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example h6.required,
.pink-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example h6.required,
.pink-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example h6.required {
  color: #f44336 !important;
}
.pink-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle,
.pink-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle,
.pink-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle,
.pink-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle,
.pink-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle {
  border: 2px solid rgba(0, 0, 0, 0);
}
.pink-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .side-color-container .side-color,
.pink-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .side-color-container .side-color,
.pink-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .side-color-container .side-color,
.pink-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .side-color-container .side-color,
.pink-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .side-color-container .side-color {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .side-color-container .side-color:before,
.pink-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .side-color-container .side-color:before,
.pink-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .side-color-container .side-color:before,
.pink-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .side-color-container .side-color:before,
.pink-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .side-color-container .side-color:before {
  border-top: 10px solid rgba(0, 0, 0, 0);
  border-bottom: 10px solid rgba(0, 0, 0, 0);
}
.pink-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .side-color-container .side-color h6.required,
.pink-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .side-color-container .side-color h6.required,
.pink-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .side-color-container .side-color h6.required,
.pink-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .side-color-container .side-color h6.required,
.pink-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .side-color-container .side-color h6.required {
  color: #f44336 !important;
}
.pink-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .permission-settings-cards .card,
.pink-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .permission-settings-cards .card,
.pink-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .permission-settings-cards .card,
.pink-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .permission-settings-cards .card,
.pink-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .permission-settings-cards .card {
  background: #fafafa;
}
.pink-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .permission-settings-cards .card .slide-sub-group,
.pink-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .permission-settings-cards .card .slide-sub-group,
.pink-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .permission-settings-cards .card .slide-sub-group,
.pink-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .permission-settings-cards .card .slide-sub-group,
.pink-blueGrey .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .permission-settings-cards .card .slide-sub-group {
  background: rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .UI-V2 .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.pink-blueGrey .UI-V2 .work-space .main .load-window {
  background: white !important;
}
.pink-blueGrey .UI-V2 .work-space .main.side-open {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item mat-icon.complex {
  text-shadow: -2px 0 0px #eeeeee, 0 -2px 0px #eeeeee, 2px 0 0px #eeeeee, 0 2px 0px #eeeeee, -2px -2px 0px #eeeeee, 2px 2px 0px #eeeeee, 2px -2px 0px #eeeeee, -2px 2px 0px #eeeeee !important;
}
.pink-blueGrey .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item h5 {
  color: rgba(0, 0, 0, 0.38);
}
.pink-blueGrey .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item h3 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item .actions {
  pointer-events: none;
}
.pink-blueGrey .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item .actions button {
  pointer-events: all;
}
.pink-blueGrey .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled {
  background: #eeeeee;
}
.pink-blueGrey .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled h3 {
  color: #607D8B !important;
}
.pink-blueGrey .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled mat-icon {
  color: #607D8B !important;
}
.pink-blueGrey .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled.selected {
  background: #607D8B;
  color: white !important;
}
.pink-blueGrey .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled.selected h3 {
  color: white !important;
}
.pink-blueGrey .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled.selected mat-icon {
  color: white !important;
}
.pink-blueGrey .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled.selected mat-icon.complex {
  color: white !important;
  text-shadow: -2px 0 0px #607D8B, 0 -2px 0px #607D8B, 2px 0 0px #607D8B, 0 2px 0px #607D8B, -2px -2px 0px #607D8B, 2px 2px 0px #607D8B, 2px -2px 0px #607D8B, -2px 2px 0px #607D8B !important;
}
.pink-blueGrey .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item:hover {
  background: rgba(0, 0, 0, 0.04);
}
.pink-blueGrey .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected {
  background: #e91e63;
  color: white;
}
.pink-blueGrey .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected mat-icon {
  color: white;
}
.pink-blueGrey .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected mat-icon.complex {
  color: white !important;
  text-shadow: -2px 0 0px #e91e63, 0 -2px 0px #e91e63, 2px 0 0px #e91e63, 0 2px 0px #e91e63, -2px -2px 0px #e91e63, 2px 2px 0px #e91e63, 2px -2px 0px #e91e63, -2px 2px 0px #e91e63 !important;
}
.pink-blueGrey .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected h5,
.pink-blueGrey .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected h3 {
  color: white;
}
.pink-blueGrey .UI-V2 .work-space .main .main-action {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .UI-V2 .work-space .side {
  background: #fafafa;
}
.pink-blueGrey .UI-V2 .work-space .side .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #fafafa, 0 -2px 0px #fafafa, 2px 0 0px #fafafa, 0 2px 0px #fafafa, -2px -2px 0px #fafafa, 2px 2px 0px #fafafa, 2px -2px 0px #fafafa, -2px 2px 0px #fafafa;
}
.pink-blueGrey .UI-V2 .work-space .side *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.pink-blueGrey .UI-V2 .work-space .side *::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}
.pink-blueGrey .UI-V2 .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #fafafa !important;
}
.pink-blueGrey .UI-V2 .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(0, 0, 0, 0.38);
}
.pink-blueGrey .UI-V2 .work-space .side mat-form-field .mat-form-field-flex {
  background: #fafafa;
}
.pink-blueGrey .UI-V2 .work-space .side mat-checkbox:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-blueGrey .UI-V2 .work-space .side .hint.all {
  color: #607d8b;
}
.pink-blueGrey .UI-V2 .work-space .side .toolbar .header {
  color: #e91e63;
}
.pink-blueGrey .UI-V2 .work-space .side .toolbar .header h3 {
  color: #e91e63;
}
.pink-blueGrey .UI-V2 .work-space .side .container .role-resume {
  background: #e91e63;
}
.pink-blueGrey .UI-V2 .work-space .side .container .role-resume .line {
  color: white;
}
.pink-blueGrey .UI-V2 .work-space .side .container .role-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.1);
}
.pink-blueGrey .UI-V2 .work-space .side .container .role-resume .line.editable button {
  background: #e91e63;
}
.pink-blueGrey .UI-V2 .work-space .side .container .section-title {
  color: #e91e63;
}
.pink-blueGrey .UI-V2 .work-space .side .container .section-resume {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .UI-V2 .work-space .side .container .section-resume .line.editable:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-blueGrey .UI-V2 .work-space .side .container .section-resume .line.editable:hover .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px white, 0 -2px 0px white, 2px 0 0px white, 0 2px 0px white, -2px -2px 0px white, 2px 2px 0px white, 2px -2px 0px white, -2px 2px 0px white !important;
}
.pink-blueGrey .UI-V2 .work-space .side .container .section-resume .line.editable button {
  background: #fafafa;
}
.pink-blueGrey .UI-V2 .work-space .side .container .section-resume .line .header .icon-complex mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.pink-blueGrey .UI-V2 .work-space .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #e91e63 !important;
}
.pink-blueGrey .UI-V2 .work-space .side .container .section-resume .line .header mat-icon:not(.complex) {
  color: rgba(0, 0, 0, 0.54);
}
.pink-blueGrey .UI-V2 .work-space .side .container .section-resume .line .header .info h4 {
  color: #e91e63;
}
.pink-blueGrey .UI-V2 .work-space .side .container .section-resume .line ul {
  color: #e91e63;
}
.pink-blueGrey .UI-V2 .work-space .side .container .section-resume .line.head {
  background: #eeeeee;
}
.pink-blueGrey .UI-V2 .work-space .side .container .section-resume .line.head mat-icon {
  color: #607d8b;
}
.pink-blueGrey .UI-V2 .work-space .side .container .section-resume .line.head h4 {
  color: white;
}
.pink-blueGrey .UI-V2 .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body {
  background: white;
}
.pink-blueGrey .UI-V2 .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: rgba(0, 0, 0, 0.54);
}
.pink-blueGrey .UI-V2 .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #e91e63;
}
.pink-blueGrey .UI-V2 .work-space .side.edition .toolbar .header {
  color: white;
}
.pink-blueGrey .UI-V2 .work-space .side.edition .toolbar .header mat-icon {
  color: #607d8b;
}
.pink-blueGrey .UI-V2 .work-space .side.edition .toolbar .header h3 {
  color: #607d8b;
}
.pink-blueGrey .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body {
  background: white !important;
}
.pink-blueGrey .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header mat-icon:not(.complex) {
  color: #e91e63;
}
.pink-blueGrey .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header h4 {
  color: #e91e63;
}
.pink-blueGrey .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-actions {
  background: #fafafa;
}
.pink-blueGrey .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.pink-blueGrey .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header .icon-complex mat-icon.complex {
  color: #607d8b !important;
}
.pink-blueGrey .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header h4 {
  color: #607d8b;
}
.pink-blueGrey .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line ul {
  color: #607d8b;
  font-weight: 400;
}
.pink-blueGrey .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head mat-icon {
  color: #607d8b !important;
}
.pink-blueGrey .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head h4 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .section-title {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-blueGrey .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume h6.hint {
  background: #cfd8dc;
}
.pink-blueGrey app-role-user-find .main-overlay {
  background: rgba(0, 0, 0, 0.32);
}
.pink-blueGrey app-role-user-find .icon-complex mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.pink-blueGrey app-role-user-find .icon-complex mat-icon.complex {
  color: #e91e63 !important;
}
.pink-blueGrey app-role-user-find .navigation {
  background: #fafafa;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey app-role-user-find .navigation *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.pink-blueGrey app-role-user-find .navigation *::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}
.pink-blueGrey app-role-user-find .navigation .load-window {
  background: #eeeeee;
}
.pink-blueGrey app-role-user-find .navigation .toolbar {
  background: #e91e63;
  color: white;
}
.pink-blueGrey app-role-user-find .navigation .toolbar.reverse {
  background: white;
  color: rgba(0, 0, 0, 0.54);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey app-role-user-find .navigation .container .helper {
  color: rgba(0, 0, 0, 0.54);
}
.pink-blueGrey app-role-user-find .navigation .container .item {
  background: white;
  color: rgba(0, 0, 0, 0.54);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey app-role-user-find .navigation .container .item:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-blueGrey app-role-user-find .navigation .container .item.active {
  background: #e91e63 !important;
  color: white;
  border: 1px solid #e91e63;
}
.pink-blueGrey app-role-user-find .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.pink-blueGrey app-role-user-find .work-space .main .load-window {
  background: white !important;
}
.pink-blueGrey app-role-user-find .work-space .main.side-open {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey app-role-user-find .work-space .main .container .item {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey app-role-user-find .work-space .main .container .item .subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.pink-blueGrey app-role-user-find .work-space .main .container .item.disabled {
  background: #eeeeee;
  color: #607D8B !important;
}
.pink-blueGrey app-role-user-find .work-space .main .container .item.disabled h3 {
  color: #607D8B !important;
}
.pink-blueGrey app-role-user-find .work-space .main .container .item.disabled mat-icon {
  color: #607D8B !important;
}
.pink-blueGrey app-role-user-find .work-space .main .container .item.disabled.selected {
  background: #607D8B;
  color: white !important;
}
.pink-blueGrey app-role-user-find .work-space .main .container .item.disabled.selected h3 {
  color: white !important;
}
.pink-blueGrey app-role-user-find .work-space .main .container .item.disabled.selected mat-icon {
  color: white !important;
}
.pink-blueGrey app-role-user-find .work-space .main .container .item.disabled.selected mat-icon.complex {
  color: white !important;
  text-shadow: -2px 0 0px #607D8B, 0 -2px 0px #607D8B, 2px 0 0px #607D8B, 0 2px 0px #607D8B, -2px -2px 0px #607D8B, 2px 2px 0px #607D8B, 2px -2px 0px #607D8B, -2px 2px 0px #607D8B !important;
}
.pink-blueGrey app-role-user-find .work-space .main .container .item mat-icon.complex {
  text-shadow: -2px 0 0px #eeeeee, 0 -2px 0px #eeeeee, 2px 0 0px #eeeeee, 0 2px 0px #eeeeee, -2px -2px 0px #eeeeee, 2px 2px 0px #eeeeee, 2px -2px 0px #eeeeee, -2px 2px 0px #eeeeee !important;
}
.pink-blueGrey app-role-user-find .work-space .main .container .item h5 {
  color: rgba(0, 0, 0, 0.38);
}
.pink-blueGrey app-role-user-find .work-space .main .container .item h3 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey app-role-user-find .work-space .main .container .item:hover {
  background: rgba(0, 0, 0, 0.04);
}
.pink-blueGrey app-role-user-find .work-space .main .container .item.selected {
  background: #e91e63;
  color: white;
}
.pink-blueGrey app-role-user-find .work-space .main .container .item.selected mat-icon {
  color: white;
}
.pink-blueGrey app-role-user-find .work-space .main .container .item.selected mat-icon.complex {
  color: white !important;
  text-shadow: -2px 0 0px #e91e63, 0 -2px 0px #e91e63, 2px 0 0px #e91e63, 0 2px 0px #e91e63, -2px -2px 0px #e91e63, 2px 2px 0px #e91e63, 2px -2px 0px #e91e63, -2px 2px 0px #e91e63 !important;
}
.pink-blueGrey app-role-user-find .work-space .main .container .item.selected h5,
.pink-blueGrey app-role-user-find .work-space .main .container .item.selected h3 {
  color: white;
}
.pink-blueGrey app-role-user-find .work-space .main .main-action {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey app-role-user-find .work-space .side {
  background: #fafafa;
}
.pink-blueGrey app-role-user-find .work-space .side .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #fafafa, 0 -2px 0px #fafafa, 2px 0 0px #fafafa, 0 2px 0px #fafafa, -2px -2px 0px #fafafa, 2px 2px 0px #fafafa, 2px -2px 0px #fafafa, -2px 2px 0px #fafafa;
}
.pink-blueGrey app-role-user-find .work-space .side *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.pink-blueGrey app-role-user-find .work-space .side *::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}
.pink-blueGrey app-role-user-find .work-space .side .edition-line {
  background: #eeeeee;
}
.pink-blueGrey app-role-user-find .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #fafafa !important;
}
.pink-blueGrey app-role-user-find .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(0, 0, 0, 0.38);
}
.pink-blueGrey app-role-user-find .work-space .side mat-form-field .mat-form-field-flex {
  background: #fafafa;
}
.pink-blueGrey app-role-user-find .work-space .side mat-checkbox:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-blueGrey app-role-user-find .work-space .side .hint.all {
  color: #607d8b;
}
.pink-blueGrey app-role-user-find .work-space .side .toolbar .header {
  color: #e91e63;
}
.pink-blueGrey app-role-user-find .work-space .side .toolbar .header h3 {
  color: #e91e63;
}
.pink-blueGrey app-role-user-find .work-space .side .container .role-resume {
  background: #e91e63;
}
.pink-blueGrey app-role-user-find .work-space .side .container .role-resume .line {
  color: white;
}
.pink-blueGrey app-role-user-find .work-space .side .container .role-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.1);
}
.pink-blueGrey app-role-user-find .work-space .side .container .role-resume .line.editable button {
  background: #e91e63;
}
.pink-blueGrey app-role-user-find .work-space .side .container .section-title {
  color: #e91e63;
}
.pink-blueGrey app-role-user-find .work-space .side .container .section-resume {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey app-role-user-find .work-space .side .container .section-resume .line.editable:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-blueGrey app-role-user-find .work-space .side .container .section-resume .line.editable:hover .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px white, 0 -2px 0px white, 2px 0 0px white, 0 2px 0px white, -2px -2px 0px white, 2px 2px 0px white, 2px -2px 0px white, -2px 2px 0px white !important;
}
.pink-blueGrey app-role-user-find .work-space .side .container .section-resume .line.editable button {
  background: #fafafa;
}
.pink-blueGrey app-role-user-find .work-space .side .container .section-resume .line .header .icon-complex mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.pink-blueGrey app-role-user-find .work-space .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #e91e63 !important;
}
.pink-blueGrey app-role-user-find .work-space .side .container .section-resume .line .header mat-icon:not(.complex) {
  color: rgba(0, 0, 0, 0.54);
}
.pink-blueGrey app-role-user-find .work-space .side .container .section-resume .line .header .info h4 {
  color: #e91e63;
}
.pink-blueGrey app-role-user-find .work-space .side .container .section-resume .line ul {
  color: #e91e63;
}
.pink-blueGrey app-role-user-find .work-space .side .container .section-resume .line.head {
  background: #eeeeee;
}
.pink-blueGrey app-role-user-find .work-space .side .container .section-resume .line.head mat-icon {
  color: #607d8b;
}
.pink-blueGrey app-role-user-find .work-space .side .container .section-resume .line.head h4 {
  color: white;
}
.pink-blueGrey app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee-title {
  color: #e91e63;
}
.pink-blueGrey app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body {
  background: white;
}
.pink-blueGrey app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: rgba(0, 0, 0, 0.54);
}
.pink-blueGrey app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #e91e63;
}
.pink-blueGrey app-role-user-find .work-space .side.edition .toolbar .header {
  color: white;
}
.pink-blueGrey app-role-user-find .work-space .side.edition .toolbar .header mat-icon {
  color: #607d8b;
}
.pink-blueGrey app-role-user-find .work-space .side.edition .toolbar .header h3 {
  color: #607d8b;
}
.pink-blueGrey app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body {
  background: white !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header mat-icon:not(.complex) {
  color: #e91e63;
}
.pink-blueGrey app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header h4 {
  color: #e91e63;
}
.pink-blueGrey app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-actions {
  background: #fafafa;
}
.pink-blueGrey app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.pink-blueGrey app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header .icon-complex mat-icon.complex {
  color: #607d8b !important;
}
.pink-blueGrey app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header h4 {
  color: #607d8b;
}
.pink-blueGrey app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line ul {
  color: #607d8b;
}
.pink-blueGrey app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head mat-icon {
  color: #607d8b !important;
}
.pink-blueGrey app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head h4 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .section-title {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-blueGrey app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume h6.hint {
  background: #cfd8dc;
}
.pink-blueGrey app-role-user-find .work-space app-role-user-detail.disabled .side .toolbar .header {
  color: #607D8B !important;
}
.pink-blueGrey app-role-user-find .work-space app-role-user-detail.disabled .side .container .role-resume {
  background: #607D8B !important;
}
.pink-blueGrey app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-title {
  color: #607D8B !important;
}
.pink-blueGrey app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line ul {
  color: #607D8B !important;
}
.pink-blueGrey app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header mat-icon {
  color: #607D8B;
}
.pink-blueGrey app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .icon-complex mat-icon {
  color: #607D8B !important;
}
.pink-blueGrey app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #607D8B !important;
}
.pink-blueGrey app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .info h4 {
  color: #607D8B !important;
}
.pink-blueGrey app-role-user-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee-title {
  color: #607D8B;
}
.pink-blueGrey app-role-user-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: #607D8B;
}
.pink-blueGrey app-role-user-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #607D8B;
}
.pink-blueGrey app-actor-find .main-overlay {
  background: rgba(0, 0, 0, 0.32);
}
.pink-blueGrey app-actor-find .icon-complex mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.pink-blueGrey app-actor-find .icon-complex mat-icon.complex {
  color: #e91e63 !important;
}
.pink-blueGrey app-actor-find .navigation {
  background: #fafafa;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey app-actor-find .navigation *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.pink-blueGrey app-actor-find .navigation *::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}
.pink-blueGrey app-actor-find .navigation .load-window {
  background: #eeeeee;
}
.pink-blueGrey app-actor-find .navigation .toolbar {
  background: #e91e63;
  color: white;
}
.pink-blueGrey app-actor-find .navigation .toolbar.reverse {
  background: white;
  color: rgba(0, 0, 0, 0.54);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey app-actor-find .navigation .container .helper {
  color: rgba(0, 0, 0, 0.54);
}
.pink-blueGrey app-actor-find .navigation .container .item {
  background: white;
  color: rgba(0, 0, 0, 0.54);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey app-actor-find .navigation .container .item:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-blueGrey app-actor-find .navigation .container .item.active {
  background: #e91e63 !important;
  color: white;
  border: 1px solid #e91e63;
}
.pink-blueGrey app-actor-find .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.pink-blueGrey app-actor-find .work-space .main .load-window {
  background: white !important;
}
.pink-blueGrey app-actor-find .work-space .main.side-open {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey app-actor-find .work-space .main .container .item {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey app-actor-find .work-space .main .container .item .subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.pink-blueGrey app-actor-find .work-space .main .container .item.disabled {
  background: #eeeeee;
  color: #607D8B !important;
}
.pink-blueGrey app-actor-find .work-space .main .container .item.disabled h3 {
  color: #607D8B !important;
}
.pink-blueGrey app-actor-find .work-space .main .container .item.disabled mat-icon {
  color: #607D8B !important;
}
.pink-blueGrey app-actor-find .work-space .main .container .item.disabled.selected {
  background: #607D8B;
  color: white !important;
}
.pink-blueGrey app-actor-find .work-space .main .container .item.disabled.selected h3 {
  color: white !important;
}
.pink-blueGrey app-actor-find .work-space .main .container .item.disabled.selected mat-icon {
  color: white !important;
}
.pink-blueGrey app-actor-find .work-space .main .container .item.disabled.selected mat-icon.complex {
  color: white !important;
  text-shadow: -2px 0 0px #607D8B, 0 -2px 0px #607D8B, 2px 0 0px #607D8B, 0 2px 0px #607D8B, -2px -2px 0px #607D8B, 2px 2px 0px #607D8B, 2px -2px 0px #607D8B, -2px 2px 0px #607D8B !important;
}
.pink-blueGrey app-actor-find .work-space .main .container .item mat-icon.complex {
  text-shadow: -2px 0 0px #eeeeee, 0 -2px 0px #eeeeee, 2px 0 0px #eeeeee, 0 2px 0px #eeeeee, -2px -2px 0px #eeeeee, 2px 2px 0px #eeeeee, 2px -2px 0px #eeeeee, -2px 2px 0px #eeeeee !important;
}
.pink-blueGrey app-actor-find .work-space .main .container .item h5 {
  color: rgba(0, 0, 0, 0.38);
}
.pink-blueGrey app-actor-find .work-space .main .container .item h3 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey app-actor-find .work-space .main .container .item:hover {
  background: rgba(0, 0, 0, 0.04);
}
.pink-blueGrey app-actor-find .work-space .main .container .item.selected {
  background: #e91e63;
  color: white;
}
.pink-blueGrey app-actor-find .work-space .main .container .item.selected mat-icon {
  color: white;
}
.pink-blueGrey app-actor-find .work-space .main .container .item.selected mat-icon.complex {
  color: white !important;
  text-shadow: -2px 0 0px #e91e63, 0 -2px 0px #e91e63, 2px 0 0px #e91e63, 0 2px 0px #e91e63, -2px -2px 0px #e91e63, 2px 2px 0px #e91e63, 2px -2px 0px #e91e63, -2px 2px 0px #e91e63 !important;
}
.pink-blueGrey app-actor-find .work-space .main .container .item.selected h5,
.pink-blueGrey app-actor-find .work-space .main .container .item.selected h3 {
  color: white;
}
.pink-blueGrey app-actor-find .work-space .main .main-action {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey app-actor-find .work-space .side {
  background: #fafafa;
}
.pink-blueGrey app-actor-find .work-space .side .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #fafafa, 0 -2px 0px #fafafa, 2px 0 0px #fafafa, 0 2px 0px #fafafa, -2px -2px 0px #fafafa, 2px 2px 0px #fafafa, 2px -2px 0px #fafafa, -2px 2px 0px #fafafa;
}
.pink-blueGrey app-actor-find .work-space .side *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.pink-blueGrey app-actor-find .work-space .side *::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}
.pink-blueGrey app-actor-find .work-space .side .edition-line {
  background: #eeeeee;
}
.pink-blueGrey app-actor-find .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #fafafa !important;
}
.pink-blueGrey app-actor-find .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(0, 0, 0, 0.38);
}
.pink-blueGrey app-actor-find .work-space .side mat-form-field .mat-form-field-flex {
  background: #fafafa;
}
.pink-blueGrey app-actor-find .work-space .side mat-checkbox:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-blueGrey app-actor-find .work-space .side .hint.all {
  color: #607d8b;
}
.pink-blueGrey app-actor-find .work-space .side .toolbar .header {
  color: #e91e63;
}
.pink-blueGrey app-actor-find .work-space .side .toolbar .header h3 {
  color: #e91e63;
}
.pink-blueGrey app-actor-find .work-space .side .container .role-resume {
  background: #e91e63;
}
.pink-blueGrey app-actor-find .work-space .side .container .role-resume .line {
  color: white;
}
.pink-blueGrey app-actor-find .work-space .side .container .role-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.1);
}
.pink-blueGrey app-actor-find .work-space .side .container .role-resume .line.editable button {
  background: #e91e63;
}
.pink-blueGrey app-actor-find .work-space .side .container .section-title {
  color: #e91e63;
}
.pink-blueGrey app-actor-find .work-space .side .container .section-resume {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey app-actor-find .work-space .side .container .section-resume .line.editable:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-blueGrey app-actor-find .work-space .side .container .section-resume .line.editable:hover .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px white, 0 -2px 0px white, 2px 0 0px white, 0 2px 0px white, -2px -2px 0px white, 2px 2px 0px white, 2px -2px 0px white, -2px 2px 0px white !important;
}
.pink-blueGrey app-actor-find .work-space .side .container .section-resume .line.editable button {
  background: #fafafa;
}
.pink-blueGrey app-actor-find .work-space .side .container .section-resume .line .header .icon-complex mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.pink-blueGrey app-actor-find .work-space .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #e91e63 !important;
}
.pink-blueGrey app-actor-find .work-space .side .container .section-resume .line .header mat-icon:not(.complex) {
  color: rgba(0, 0, 0, 0.54);
}
.pink-blueGrey app-actor-find .work-space .side .container .section-resume .line .header .info h4 {
  color: #e91e63;
}
.pink-blueGrey app-actor-find .work-space .side .container .section-resume .line ul {
  color: #e91e63;
}
.pink-blueGrey app-actor-find .work-space .side .container .section-resume .line.head {
  background: #eeeeee;
}
.pink-blueGrey app-actor-find .work-space .side .container .section-resume .line.head mat-icon {
  color: #607d8b;
}
.pink-blueGrey app-actor-find .work-space .side .container .section-resume .line.head h4 {
  color: white;
}
.pink-blueGrey app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee-title {
  color: #e91e63;
}
.pink-blueGrey app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body {
  background: white;
}
.pink-blueGrey app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: rgba(0, 0, 0, 0.54);
}
.pink-blueGrey app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #e91e63;
}
.pink-blueGrey app-actor-find .work-space .side.edition .toolbar .header {
  color: white;
}
.pink-blueGrey app-actor-find .work-space .side.edition .toolbar .header mat-icon {
  color: #607d8b;
}
.pink-blueGrey app-actor-find .work-space .side.edition .toolbar .header h3 {
  color: #607d8b;
}
.pink-blueGrey app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body {
  background: white !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header mat-icon:not(.complex) {
  color: #e91e63;
}
.pink-blueGrey app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header h4 {
  color: #e91e63;
}
.pink-blueGrey app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-actions {
  background: #fafafa;
}
.pink-blueGrey app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.pink-blueGrey app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header .icon-complex mat-icon.complex {
  color: #607d8b !important;
}
.pink-blueGrey app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header h4 {
  color: #607d8b;
}
.pink-blueGrey app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line ul {
  color: #607d8b;
}
.pink-blueGrey app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head mat-icon {
  color: #607d8b !important;
}
.pink-blueGrey app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head h4 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .section-title {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-blueGrey app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume h6.hint {
  background: #cfd8dc;
}
.pink-blueGrey app-actor-find .work-space app-role-user-detail.disabled .side .toolbar .header {
  color: #607D8B !important;
}
.pink-blueGrey app-actor-find .work-space app-role-user-detail.disabled .side .container .role-resume {
  background: #607D8B !important;
}
.pink-blueGrey app-actor-find .work-space app-role-user-detail.disabled .side .container .section-title {
  color: #607D8B !important;
}
.pink-blueGrey app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line ul {
  color: #607D8B !important;
}
.pink-blueGrey app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header mat-icon {
  color: #607D8B;
}
.pink-blueGrey app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .icon-complex mat-icon {
  color: #607D8B !important;
}
.pink-blueGrey app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #607D8B !important;
}
.pink-blueGrey app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .info h4 {
  color: #607D8B !important;
}
.pink-blueGrey app-actor-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee-title {
  color: #607D8B;
}
.pink-blueGrey app-actor-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: #607D8B;
}
.pink-blueGrey app-actor-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #607D8B;
}
.pink-blueGrey .dropzone {
  border: 2px dashed #607D8B;
  background: #eeeeee !important;
}
.pink-blueGrey .dropzone.hovering {
  border: 2px solid #607D8B;
  color: rgba(0, 0, 0, 0.38) !important;
}
.pink-blueGrey .dropzone button.mat-stroked-button {
  background: white;
}
.pink-blueGrey .firmador .content-action-main {
  color: white !important;
}
.pink-blueGrey .firmador .content-action-main.disabled {
  color: rgba(0, 0, 0, 0.38) !important;
  background: rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .sign-cycle {
  background: white;
}
.pink-blueGrey .sign-cycle button.success {
  background-color: #009688;
}
.pink-blueGrey .sign-cycle button.warning {
  background-color: #673AB7;
}
.pink-blueGrey .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-title-container .cycle-content-title h4 {
  color: #607D8B;
}
.pink-blueGrey .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-action .content-action-main {
  color: white !important;
}
.pink-blueGrey .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-action .content-action-main.mat-stroked-button:disabled {
  color: #9e9e9e !important;
}
.pink-blueGrey .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-action .content-action-main.mat-stroked-button:not(:disabled) {
  color: #607d8b !important;
}
.pink-blueGrey .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-action .content-action-side u {
  color: #9e9e9e;
}
.pink-blueGrey .resume-status {
  background: white;
}
.pink-blueGrey .help-hoverable mat-icon {
  color: rgba(0, 0, 0, 0.54);
}
.pink-blueGrey .help-hoverable mat-hint {
  background: white;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .help-hoverable mat-hint b {
  color: #e91e63 !important;
}
.pink-blueGrey app-file-document-sign-massive-dialog .dialog-content,
.pink-blueGrey app-share-person-dialog .dialog-content {
  background: #fafafa;
}
.pink-blueGrey app-file-document-sign-massive-dialog .dialog-content mat-toolbar mat-toolbar-row .mat-avatar h3,
.pink-blueGrey app-share-person-dialog .dialog-content mat-toolbar mat-toolbar-row .mat-avatar h3 {
  color: #e91e63;
}
.pink-blueGrey app-file-document-sign-massive-dialog .dialog-content .dialog-body p b,
.pink-blueGrey app-share-person-dialog .dialog-content .dialog-body p b {
  color: #607d8b;
}
.pink-blueGrey app-file-document-sign-massive-dialog .dialog-content app-file-document-sign,
.pink-blueGrey app-share-person-dialog .dialog-content app-file-document-sign {
  background: white;
}
.pink-blueGrey .mat-basic-chip {
  background: #eeeeee !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .mat-basic-chip.seen {
  border-color: #2196F3 !important;
  color: #2196F3 !important;
}
.pink-blueGrey .mat-basic-chip.unseen {
  border-color: #607D8B !important;
  color: #607D8B !important;
}
.pink-blueGrey .mat-basic-chip.signed-ok {
  border-color: #009688 !important;
  color: #009688 !important;
}
.pink-blueGrey .mat-basic-chip.signed-ok.cancelled {
  border-color: #795548 !important;
  color: #795548 !important;
}
.pink-blueGrey .mat-basic-chip.signed-not-ok {
  border-color: #673AB7 !important;
  color: #673AB7 !important;
}
.pink-blueGrey .mat-basic-chip.not-signed {
  border-color: #ff4081 !important;
  color: #ff4081 !important;
}
.pink-blueGrey .mat-basic-chip.not-signed-employer {
  border-color: #ff4081 !important;
  color: #ff4081 !important;
}
.pink-blueGrey app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip {
  background: #eeeeee !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.seen {
  border-color: #2196F3 !important;
  color: #2196F3 !important;
}
.pink-blueGrey app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.unseen {
  border-color: #607D8B !important;
  color: #607D8B !important;
}
.pink-blueGrey app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.signed-ok {
  border-color: #009688 !important;
  color: #009688 !important;
}
.pink-blueGrey app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.signed-ok.cancelled {
  border-color: #795548 !important;
  color: #795548 !important;
}
.pink-blueGrey app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.signed-not-ok {
  border-color: #673AB7 !important;
  color: #673AB7 !important;
}
.pink-blueGrey app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.not-signed {
  border-color: #ff4081 !important;
  color: #ff4081 !important;
}
.pink-blueGrey app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.not-signed-employer {
  border-color: #ff4081 !important;
  color: #ff4081 !important;
}
.pink-blueGrey .inbox-container.employee.my-documents .chip-line.mat-line .mat-chip-list-wrapper .mat-basic-chip.seen {
  color: #607D8B !important;
}
.pink-blueGrey .resume-status .mat-basic-chip {
  background: #eeeeee !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .resume-status .mat-basic-chip.signed-ok {
  border-color: #009688 !important;
  background-color: #009688 !important;
  color: white !important;
}
.pink-blueGrey .resume-status .mat-basic-chip.signed-ok.cancelled {
  border-color: #795548 !important;
  background-color: #795548 !important;
  color: white !important;
}
.pink-blueGrey .resume-status .mat-basic-chip.signed-not-ok {
  border-color: #673AB7 !important;
  background-color: #673AB7 !important;
  color: white !important;
}
.pink-blueGrey app-file-document-view .new-container app-file-document-upload {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey app-file-document-view .new-container app-upload-form .file-upload-list .mat-subheader {
  background: white;
}
.pink-blueGrey app-file-document-view .new-container app-upload-form .file-upload-list mat-list-item {
  background: #fafafa !important;
}
.pink-blueGrey app-file-document-view .preview-content {
  background: #607D8B;
}
.pink-blueGrey app-file-document-view .preview-content h4 {
  color: white;
}
.pink-blueGrey app-file-document-view .view-container {
  background: white;
}
.pink-blueGrey app-file-document-view .view-container mat-toolbar.side-bar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .formio-hint {
  background: #e91e63;
  color: white;
}
.pink-blueGrey mat-dialog-container app-file-document-state-chips {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey app-candidate-promotion .load-window.message h3.primary-text {
  color: #e91e63;
}
.pink-blueGrey app-multiple-documentation-add-dialog .icon-complex mat-icon,
.pink-blueGrey app-documentation-type-set-configuration-dialog .icon-complex mat-icon,
.pink-blueGrey app-add-candidate-dialog .icon-complex mat-icon,
.pink-blueGrey app-share-person-dialog .icon-complex mat-icon,
.pink-blueGrey app-share-person-response-dialog .icon-complex mat-icon {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-blueGrey app-multiple-documentation-add-dialog .icon-complex mat-icon.complex,
.pink-blueGrey app-documentation-type-set-configuration-dialog .icon-complex mat-icon.complex,
.pink-blueGrey app-add-candidate-dialog .icon-complex mat-icon.complex,
.pink-blueGrey app-share-person-dialog .icon-complex mat-icon.complex,
.pink-blueGrey app-share-person-response-dialog .icon-complex mat-icon.complex {
  color: #e91e63 !important;
}
.pink-blueGrey app-multiple-documentation-add-dialog .icon-complex mat-icon.complex.mat-accent,
.pink-blueGrey app-documentation-type-set-configuration-dialog .icon-complex mat-icon.complex.mat-accent,
.pink-blueGrey app-add-candidate-dialog .icon-complex mat-icon.complex.mat-accent,
.pink-blueGrey app-share-person-dialog .icon-complex mat-icon.complex.mat-accent,
.pink-blueGrey app-share-person-response-dialog .icon-complex mat-icon.complex.mat-accent {
  color: #607d8b;
}
.pink-blueGrey app-multiple-documentation-add-dialog .icon-complex mat-icon.complex.side,
.pink-blueGrey app-documentation-type-set-configuration-dialog .icon-complex mat-icon.complex.side,
.pink-blueGrey app-add-candidate-dialog .icon-complex mat-icon.complex.side,
.pink-blueGrey app-share-person-dialog .icon-complex mat-icon.complex.side,
.pink-blueGrey app-share-person-response-dialog .icon-complex mat-icon.complex.side {
  color: rgba(0, 0, 0, 0.54);
}
.pink-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection,
.pink-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection,
.pink-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .document-selection,
.pink-blueGrey app-share-person-dialog .dialog-content .document-template-container .document-selection,
.pink-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .document-selection {
  background: #fafafa;
}
.pink-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.pink-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.pink-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3,
.pink-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.pink-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.pink-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3,
.pink-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.pink-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.pink-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3,
.pink-blueGrey app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.pink-blueGrey app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.pink-blueGrey app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3,
.pink-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.pink-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.pink-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3 {
  color: #607d8b !important;
}
.pink-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon,
.pink-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon,
.pink-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon,
.pink-blueGrey app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon,
.pink-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon {
  color: #e91e63;
}
.pink-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3,
.pink-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3,
.pink-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3,
.pink-blueGrey app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3,
.pink-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3 {
  color: #e91e63;
}
.pink-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex,
.pink-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex,
.pink-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex,
.pink-blueGrey app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex,
.pink-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex {
  color: #607d8b !important;
  text-shadow: -2px 0 0px #eeeeee, 0 -2px 0px #eeeeee, 2px 0 0px #eeeeee, 0 2px 0px #eeeeee, -2px -2px 0px #eeeeee, 2px 2px 0px #eeeeee, 2px -2px 0px #eeeeee, -2px 2px 0px white;
}
.pink-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex,
.pink-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex,
.pink-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex,
.pink-blueGrey app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex,
.pink-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex {
  color: rgba(0, 0, 0, 0.38) !important;
}
.pink-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b,
.pink-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b,
.pink-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b,
.pink-blueGrey app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b,
.pink-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex,
.pink-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex,
.pink-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex,
.pink-blueGrey app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex,
.pink-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex {
  color: rgba(0, 0, 0, 0.38) !important;
}
.pink-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex,
.pink-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex,
.pink-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex,
.pink-blueGrey app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex,
.pink-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex {
  color: rgba(0, 0, 0, 0.38) !important;
}
.pink-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover,
.pink-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover,
.pink-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover,
.pink-blueGrey app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover,
.pink-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover {
  background: rgba(0, 0, 0, 0.04);
}
.pink-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b,
.pink-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b,
.pink-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b,
.pink-blueGrey app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b,
.pink-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.pink-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.pink-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.pink-blueGrey app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.pink-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble {
  background: #e91e63;
}
.pink-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble,
.pink-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble,
.pink-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble,
.pink-blueGrey app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble,
.pink-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble {
  background: #f44336;
}
.pink-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble,
.pink-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble,
.pink-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble,
.pink-blueGrey app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble,
.pink-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble {
  color: rgba(0, 0, 0, 0.38);
  background: rgba(0, 0, 0, 0.12);
}
.pink-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble,
.pink-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble,
.pink-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble,
.pink-blueGrey app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble,
.pink-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble {
  background: rgba(0, 0, 0, 0.38);
  color: white;
}
.pink-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide,
.pink-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide,
.pink-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide,
.pink-blueGrey app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide,
.pink-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide {
  border-color: rgba(0, 0, 0, 0.12);
}
.pink-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble,
.pink-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble,
.pink-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble,
.pink-blueGrey app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble,
.pink-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble {
  color: rgba(0, 0, 0, 0.54);
}
.pink-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.pink-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.pink-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.pink-blueGrey app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.pink-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble {
  color: #e91e63;
}
.pink-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble,
.pink-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble,
.pink-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble,
.pink-blueGrey app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble,
.pink-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble {
  color: #f44336;
}
.pink-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar,
.pink-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar,
.pink-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar,
.pink-blueGrey app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar,
.pink-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar {
  background: #607D8B;
}
.pink-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row,
.pink-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row,
.pink-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row,
.pink-blueGrey app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row,
.pink-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row {
  color: white;
}
.pink-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper,
.pink-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper,
.pink-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper,
.pink-blueGrey app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper,
.pink-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper {
  background: #607D8B;
}
.pink-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop,
.pink-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop,
.pink-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop,
.pink-blueGrey app-share-person-dialog .dialog-content .document-template-container .add-docs-drop,
.pink-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body,
.pink-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body,
.pink-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body,
.pink-blueGrey app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body,
.pink-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3,
.pink-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3,
.pink-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3,
.pink-blueGrey app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3,
.pink-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3 {
  color: #607d8b;
}
.pink-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5,
.pink-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5,
.pink-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5,
.pink-blueGrey app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5,
.pink-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5 {
  color: rgba(0, 0, 0, 0.54);
}
.pink-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5,
.pink-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5,
.pink-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5,
.pink-blueGrey app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5,
.pink-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5 {
  color: rgba(0, 0, 0, 0.54);
}
.pink-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator,
.pink-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator,
.pink-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator,
.pink-blueGrey app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator,
.pink-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background: #fafafa;
}
.pink-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip,
.pink-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip,
.pink-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip,
.pink-blueGrey app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip,
.pink-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip {
  background: white !important;
}
.pink-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon,
.pink-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon,
.pink-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon,
.pink-blueGrey app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon,
.pink-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon {
  color: #607d8b !important;
}
.pink-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3,
.pink-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3,
.pink-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3,
.pink-blueGrey app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3,
.pink-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3 {
  color: #607d8b !important;
}
.pink-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5,
.pink-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5,
.pink-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5,
.pink-blueGrey app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5,
.pink-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5 {
  color: rgba(0, 0, 0, 0.54) !important;
}
.pink-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon,
.pink-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon,
.pink-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon,
.pink-blueGrey app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon,
.pink-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon {
  color: #e91e63 !important;
}
.pink-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3,
.pink-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3,
.pink-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3,
.pink-blueGrey app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3,
.pink-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3 {
  color: #e91e63 !important;
}
.pink-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5,
.pink-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5,
.pink-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5,
.pink-blueGrey app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5,
.pink-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5 {
  color: #e91e63 !important;
}
.pink-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled,
.pink-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled,
.pink-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled,
.pink-blueGrey app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled,
.pink-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled {
  background: #fafafa !important;
  color: rgba(0, 0, 0, 0.38);
}
.pink-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove,
.pink-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove,
.pink-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove,
.pink-blueGrey app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove,
.pink-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove {
  color: rgba(0, 0, 0, 0.54);
}
.pink-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove,
.pink-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove,
.pink-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove,
.pink-blueGrey app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove,
.pink-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove {
  color: rgba(0, 0, 0, 0.54) !important;
}
.pink-blueGrey app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .preview-action,
.pink-blueGrey app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .preview-action,
.pink-blueGrey app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .preview-action,
.pink-blueGrey app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .preview-action,
.pink-blueGrey app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .preview-action {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .tyc .sticky {
  background: white;
}
.pink-blueGrey .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper:nth-child(odd) datatable-body-row {
  background: #fafafa;
}
.pink-blueGrey .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active * {
  color: white !important;
}
.pink-blueGrey .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active:hover .datatable-body-cell-label {
  background: #e91e63;
}
.pink-blueGrey .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active:hover .cs-floating-action {
  background: #e91e63 !important;
}
.pink-blueGrey .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active.row-cancel * {
  color: rgba(0, 0, 0, 0.54) !important;
}
.pink-blueGrey .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active.row-cancel .datatable-row-group {
  background: #607D8B !important;
}
.pink-blueGrey .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active.row-cancel:hover .datatable-body-cell-label {
  background: #607D8B;
}
.pink-blueGrey .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.row-cancel * {
  color: #b0bec5 !important;
  transition-property: color;
  transition-duration: 0.3s;
  transition-timing-function: linear;
}
.pink-blueGrey .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row:hover .datatable-body-cell-label button {
  background: #eeeeee;
}
.pink-blueGrey .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row datatable-body-cell.candidate-status_bar mat-progress-bar .mat-progress-bar-buffer {
  background-color: #eeeeee;
}
.pink-blueGrey .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row datatable-body-cell.candidate-status_bar .status_bar-count {
  color: rgba(0, 0, 0, 0.54);
}
.pink-blueGrey .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row datatable-body-cell.candidate-status_bar .inactive {
  color: rgba(0, 0, 0, 0.54);
}
.pink-blueGrey .cs-grid-test .info {
  background: white;
}
.pink-blueGrey .cs-grid-test .selected-column {
  background: white;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
}
.pink-blueGrey .cs-grid-test .selection-cell .datatable-body-cell.active {
  background: #e91e63 !important;
  color: white !important;
}
.pink-blueGrey .cs-grid-test nav {
  background: white;
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 13px 19px 2px rgba(0, 0, 0, 0.14), 0 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .cs-grid-test .ngx-datatable.material {
  background: white;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .cs-grid-test .ngx-datatable.material.striped .datatable-row-odd {
  background: #fafafa;
}
.pink-blueGrey .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active, .pink-blueGrey .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active .datatable-row-group, .pink-blueGrey .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active, .pink-blueGrey .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active .datatable-row-group, .pink-blueGrey .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active, .pink-blueGrey .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active .datatable-row-group {
  background: #e91e63;
  color: white;
}
.pink-blueGrey .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active:hover, .pink-blueGrey .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active:hover .datatable-row-group, .pink-blueGrey .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active:hover, .pink-blueGrey .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active:hover .datatable-row-group, .pink-blueGrey .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active:hover, .pink-blueGrey .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active:hover .datatable-row-group {
  background: #e91e63;
  color: white;
}
.pink-blueGrey .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active:focus, .pink-blueGrey .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active:focus .datatable-row-group, .pink-blueGrey .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active:focus, .pink-blueGrey .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active:focus .datatable-row-group, .pink-blueGrey .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active:focus, .pink-blueGrey .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active:focus .datatable-row-group {
  background: #e91e63;
  color: white;
}
.pink-blueGrey .cs-grid-test .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover, .pink-blueGrey .cs-grid-test .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: rgba(0, 0, 0, 0.04);
}
.pink-blueGrey .cs-grid-test .ngx-datatable.material:not(.cell-selection) .datatable-body-row:focus, .pink-blueGrey .cs-grid-test .ngx-datatable.material:not(.cell-selection) .datatable-body-row:focus .datatable-row-group {
  background: rgba(0, 0, 0, 0.04);
}
.pink-blueGrey .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell:hover, .pink-blueGrey .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell:hover .datatable-row-group {
  background: rgba(0, 0, 0, 0.04);
}
.pink-blueGrey .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell:focus, .pink-blueGrey .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell:focus .datatable-row-group {
  background: rgba(0, 0, 0, 0.04);
}
.pink-blueGrey .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active, .pink-blueGrey .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active .datatable-row-group {
  background: #e91e63;
  color: white;
}
.pink-blueGrey .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active:hover, .pink-blueGrey .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active:hover .datatable-row-group {
  background: #e91e63;
  color: white;
}
.pink-blueGrey .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active:focus, .pink-blueGrey .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active:focus .datatable-row-group {
  background: #e91e63;
  color: white;
}
.pink-blueGrey .cs-grid-test .ngx-datatable.material .datatable-header .datatable-row-left,
.pink-blueGrey .cs-grid-test .ngx-datatable.material .datatable-body .datatable-row-left {
  background-color: white;
}
.pink-blueGrey .cs-grid-test .ngx-datatable.material .datatable-header .datatable-row-right,
.pink-blueGrey .cs-grid-test .ngx-datatable.material .datatable-body .datatable-row-right {
  background-color: white;
}
.pink-blueGrey .cs-grid-test .ngx-datatable.material .datatable-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.38);
}
.pink-blueGrey .cs-grid-test .ngx-datatable.material .datatable-header .datatable-header-cell {
  background-color: white;
  color: rgba(0, 0, 0, 0.54);
}
.pink-blueGrey .cs-grid-test .ngx-datatable.material .datatable-header .resize-handle {
  border-right: solid 1px rgba(0, 0, 0, 0.04);
}
.pink-blueGrey .cs-grid-test .ngx-datatable.material .datatable-body .datatable-row-detail {
  background: #eeeeee;
}
.pink-blueGrey .cs-grid-test .ngx-datatable.material .datatable-body .datatable-group-header {
  background: #eeeeee;
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
  border-top: solid 1px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .cs-grid-test .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey .cs-grid-test .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-group-cell {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey .cs-grid-test .ngx-datatable.material .datatable-body .progress-linear .container {
  background-color: white;
}
.pink-blueGrey .cs-grid-test .ngx-datatable.material .datatable-body .progress-linear .container .bar {
  background-color: #e91e63;
}
.pink-blueGrey .cs-grid-test .ngx-datatable.material .datatable-footer {
  color: rgba(0, 0, 0, 0.54);
}
.pink-blueGrey .cs-grid-test .ngx-datatable.material .datatable-footer .datatable-pager li.disabled a {
  color: rgba(0, 0, 0, 0.38) !important;
  background-color: rgba(0, 0, 0, 0) !important;
}
.pink-blueGrey .cs-grid-test .ngx-datatable.material .datatable-footer .datatable-pager li.active a {
  background-color: #e91e63;
}
.pink-blueGrey .cs-grid-test .ngx-datatable.material .datatable-footer .datatable-pager a {
  color: rgba(0, 0, 0, 0.54);
}
.pink-blueGrey .cs-grid-test .ngx-datatable.material .datatable-footer .datatable-pager a:hover {
  color: rgba(0, 0, 0, 0.04);
  background-color: rgba(0, 0, 0, 0.04);
}
.pink-blueGrey .cs-grid-test .ngx-datatable.material .datatable-summary-row .datatable-body-row {
  background-color: #fafafa;
}
.pink-blueGrey .cs-grid-test .ngx-datatable.material .datatable-summary-row .datatable-body-row:hover {
  background-color: #fafafa;
}
.pink-blueGrey .cs-grid-test .datatable-checkbox input[type=checkbox]:after {
  background: white;
}
.pink-blueGrey .view-container .view-toolbar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal.new.image-opened .help-content img {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card {
  background: white;
}
.pink-blueGrey app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container .aditional-info {
  color: rgba(0, 0, 0, 0.54);
}
.pink-blueGrey app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container .mat-card-footer {
  background: white;
}
.pink-blueGrey app-help-index mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card {
  background: white;
}
.pink-blueGrey app-help-index mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card .mat-card-footer {
  background: white;
}
.pink-blueGrey .cropper .employee-title .title-text {
  color: white !important;
}
.pink-blueGrey .cropper app-cs-upload-cropper-control .preview h5 {
  color: rgba(0, 0, 0, 0.54);
}
.pink-blueGrey app-file-document-upload .preview-container {
  background: #607D8B !important;
}
.pink-blueGrey app-file-document-upload .preview-container h4 {
  color: #eeeeee;
}
.pink-blueGrey .preview-container {
  background: #607D8B !important;
}
.pink-blueGrey .preview-container h4 {
  color: #eeeeee;
}
.pink-blueGrey .clean-autocomple mat-list {
  background: white;
}
.pink-blueGrey .clean-autocomple mat-list .mat-list-item:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-blueGrey .clean-autocomple mat-list .mat-list-item .mat-list-item-content .mat-list-text {
  color: #e91e63;
}
.pink-blueGrey app-leave .inbox-toolbar {
  background: #fafafa;
}
.pink-blueGrey app-leave .mat-tab-body-wrapper {
  position: relative;
  overflow: hidden;
  display: flex;
  transition: height 500ms cubic-bezier(0.35, 0, 0.25, 1);
  height: 95% !important;
}
.pink-blueGrey app-leave .leave_container {
  background: #fafafa;
}
.pink-blueGrey app-leave .leave_container .leave_items .leave_items-item.ok .content .leave_item-status_icon {
  color: #009688;
}
.pink-blueGrey app-leave .leave_container .leave_items .leave_items-item.not-ok .content .leave_item-status_icon {
  color: #9C27B0;
}
.pink-blueGrey app-leave .leave_container .leave_items .leave_items-item.pending .content .leave_item-status_icon {
  color: #FF9800;
}
.pink-blueGrey app-leave .leave_container .leave_items .leave_items-item.draft .content .leave_item-status_icon {
  color: rgba(0, 0, 0, 0.54);
}
.pink-blueGrey app-leave .leave_container .leave_items .leave_items-item.cancel .content .leave_item-status_icon {
  color: #795548;
}
.pink-blueGrey app-leave .leave_container .leave_items .leave_items-item .content:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-blueGrey app-leave .leave_container .leave_items .leave_items-item .content.selected {
  border-left: 6px solid #607d8b !important;
}
.pink-blueGrey app-leave .leave_container .leave_items .leave_items-item .content.selected mat-icon {
  color: #607d8b !important;
}
.pink-blueGrey app-leave .leave_container .leave_items .leave_items-item .content.selected .item-data {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-blueGrey app-leave .leave_container .leave_items .leave_items-item .content .leave_item-icon {
  color: rgba(0, 0, 0, 0.54);
}
.pink-blueGrey app-leave .leave_container .leave_items .leave_items-item .content .item-data {
  color: rgba(0, 0, 0, 0.54);
}
.pink-blueGrey app-leave .leave_container .window-spinner {
  background: #fafafa !important;
}
.pink-blueGrey app-leave-detail .leave_detail .leave_detail-title .info h3,
.pink-blueGrey app-leave-detail .leave_detail .leave_detail-title .info mat-icon {
  color: #e91e63;
}
.pink-blueGrey app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .ok .large-icon {
  background: #b2dfdb;
  color: #009688;
}
.pink-blueGrey app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .ok h2 {
  color: #009688;
}
.pink-blueGrey app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .not-ok .large-icon {
  background: #e1bee7;
  color: #9C27B0;
}
.pink-blueGrey app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .not-ok h2 {
  color: #9C27B0;
}
.pink-blueGrey app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .pending .large-icon {
  background: #FFE0B2;
  color: #FF9800;
}
.pink-blueGrey app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .pending h2 {
  color: #FF9800;
}
.pink-blueGrey app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .draft .large-icon {
  background: #f2f2f2;
  color: #dedede;
}
.pink-blueGrey app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .draft h2 {
  color: #dedede;
}
.pink-blueGrey app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .cancelled .large-icon {
  background: #BCAAA4;
  color: #795548;
}
.pink-blueGrey app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .cancelled h2 {
  color: #795548;
}
.pink-blueGrey app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .actions {
  padding: 16px;
  height: 96px;
  column-gap: 16px;
}
.pink-blueGrey app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .actions button {
  height: 100%;
  border-radius: 40px;
}
.pink-blueGrey app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .actions button.ok {
  background: #009688 !important;
  color: white;
}
.pink-blueGrey app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .actions button.not-ok {
  color: #9C27B0 !important;
}
.pink-blueGrey app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning mat-basic-chip {
  background: #eeeeee !important;
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
  border-radius: 40px !important;
  height: 35px !important;
}
.pink-blueGrey app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning mat-basic-chip.signed-ok {
  border-color: #009688 !important;
  color: #009688 !important;
}
.pink-blueGrey app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning mat-basic-chip.signed-not-ok {
  border-color: #9C27B0 !important;
  color: #9C27B0 !important;
}
.pink-blueGrey app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning mat-basic-chip.draft {
  border-color: #dedede !important;
  color: #dedede !important;
}
.pink-blueGrey app-leave-request-detail .load-window {
  background: #fafafa;
}
.pink-blueGrey app-leave-request-detail form .leave_detail-container {
  background: #fafafa;
}
.pink-blueGrey app-leave-request-detail form .leave_detail-container .toolbar .header {
  color: #e91e63;
}
.pink-blueGrey app-leave-request-detail form .leave_detail-container .toolbar .header .text h3 {
  color: #e91e63;
}
.pink-blueGrey app-leave-request-detail form .leave_detail-container .toolbar .header .text h5 {
  color: rgba(0, 0, 0, 0.54);
}
.pink-blueGrey app-leave-request-detail form .leave_detail-container .leave_detail-container-content .leave_resume {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey app-leave-request-detail form .leave_detail-container .leave_detail-container-content .line h6 {
  color: rgba(0, 0, 0, 0.54);
}
.pink-blueGrey app-leave-request-detail form .leave_detail-container .actions button.ok {
  background: #009688 !important;
  color: white;
}
.pink-blueGrey app-leave-request-detail form .leave_detail-container .actions button.not-ok {
  color: #9C27B0 !important;
}
.pink-blueGrey .new_leave_modal-steps {
  display: unset !important;
}
.pink-blueGrey .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button .mat-radio-label .mat-radio-label-content .side-text {
  color: rgba(0, 0, 0, 0.54);
}
.pink-blueGrey .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button .mat-radio-label .mat-radio-label-content .side-text.muted {
  color: rgba(0, 0, 0, 0.38);
}
.pink-blueGrey .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button .mat-radio-label .mat-radio-label-content .accent mat-icon,
.pink-blueGrey .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button .mat-radio-label .mat-radio-label-content .accent .main-text {
  color: #607d8b;
}
.pink-blueGrey .config_leave-container .config_leave-card {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .config_leave-container .config_leave-card .config_leave-auto_chip mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #fafafa !important;
}
.pink-blueGrey .config_leave-container .config_leave-card .config_leave-auto_chip mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(0, 0, 0, 0.38);
}
.pink-blueGrey .config_leave-container .config_leave-card .config_leave-auto_chip mat-form-field .mat-form-field-flex {
  background: #fafafa;
}
.pink-blueGrey *[color=success] {
  color: #4DB6AC;
}
.pink-blueGrey .primary {
  color: #e91e63 !important;
}
.pink-blueGrey .accent {
  color: #607d8b !important;
}
.pink-blueGrey app-lsd .icon-complex mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.pink-blueGrey app-lsd .icon-complex mat-icon.complex {
  color: #e91e63 !important;
}
.pink-blueGrey app-lsd .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.pink-blueGrey app-lsd .work-space .main .load-window {
  background: white !important;
}
.pink-blueGrey app-lsd .work-space .main.side-open {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey app-lsd .work-space .main .container .item {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey app-lsd .work-space .main .container .item h5 {
  color: rgba(0, 0, 0, 0.38);
}
.pink-blueGrey app-lsd .work-space .main .container .item h3 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey app-lsd .work-space .main .container .item:hover {
  background: rgba(0, 0, 0, 0.04);
}
.pink-blueGrey app-lsd .work-space .main .container .item.selected {
  background: #e91e63;
  color: white !important;
}
.pink-blueGrey app-lsd .work-space .main .container .item.selected h3 {
  color: white !important;
}
.pink-blueGrey app-lsd .work-space .main .container .item.selected.pending .status {
  background: rgba(0, 0, 0, 0.12) !important;
  color: #ffe082;
}
.pink-blueGrey app-lsd .work-space .main .container .item.selected.ok .status {
  background: rgba(0, 0, 0, 0.12) !important;
  color: #a5d6a7;
}
.pink-blueGrey app-lsd .work-space .main .container .item.selected.not-ok .status {
  background: rgba(0, 0, 0, 0.12) !important;
  color: #e57373;
}
.pink-blueGrey app-lsd .work-space .main .main-action {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey app-lsd .work-space .side {
  background: #fafafa;
}
.pink-blueGrey app-lsd .work-space .side .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #fafafa, 0 -2px 0px #fafafa, 2px 0 0px #fafafa, 0 2px 0px #fafafa, -2px -2px 0px #fafafa, 2px 2px 0px #fafafa, 2px -2px 0px #fafafa, -2px 2px 0px #fafafa;
}
.pink-blueGrey app-lsd .work-space .side *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.pink-blueGrey app-lsd .work-space .side *::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}
.pink-blueGrey app-lsd .work-space .side .edition-line {
  background: #eeeeee;
}
.pink-blueGrey app-lsd .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #fafafa !important;
}
.pink-blueGrey app-lsd .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(0, 0, 0, 0.38);
}
.pink-blueGrey app-lsd .work-space .side mat-form-field .mat-form-field-flex {
  background: #fafafa;
}
.pink-blueGrey app-lsd .work-space .side mat-checkbox:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-blueGrey app-lsd .work-space .side .hint.all {
  color: #607d8b;
}
.pink-blueGrey app-lsd .work-space .side .toolbar .header {
  color: #e91e63;
}
.pink-blueGrey app-lsd .work-space .side .toolbar .header h3 {
  color: #e91e63;
}
.pink-blueGrey app-lsd .work-space .side .container .doc-resume {
  background: #e91e63;
}
.pink-blueGrey app-lsd .work-space .side .container .doc-resume .line {
  color: white;
}
.pink-blueGrey app-lsd .work-space .side .container .doc-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.1);
}
.pink-blueGrey app-lsd .work-space .side .container .doc-resume .line.editable button {
  background: #e91e63;
}
.pink-blueGrey app-lsd .work-space .side .container .section-title {
  color: #e91e63;
}
.pink-blueGrey app-lsd .work-space .side .container .section-resume {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey app-lsd .work-space .side .container .section-resume .line.editable:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.pink-blueGrey app-lsd .work-space .side .container .section-resume .line.editable:hover .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px white, 0 -2px 0px white, 2px 0 0px white, 0 2px 0px white, -2px -2px 0px white, 2px 2px 0px white, 2px -2px 0px white, -2px 2px 0px white !important;
}
.pink-blueGrey app-lsd .work-space .side .container .section-resume .line.editable button {
  background: #fafafa;
}
.pink-blueGrey app-lsd .work-space .side .container .section-resume .line .header .icon-complex mat-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.pink-blueGrey app-lsd .work-space .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #e91e63 !important;
}
.pink-blueGrey app-lsd .work-space .side .container .section-resume .line .header mat-icon:not(.complex) {
  color: rgba(0, 0, 0, 0.54);
}
.pink-blueGrey app-lsd .work-space .side .container .section-resume .line .header .info h4 {
  color: #e91e63;
}
.pink-blueGrey app-lsd .work-space .side .container .section-resume .line ul {
  color: #e91e63;
}
.pink-blueGrey app-lsd .work-space .side .container .section-resume .line.head {
  background: #eeeeee;
}
.pink-blueGrey app-lsd .work-space .side .container .section-resume .line.head mat-icon {
  color: #607d8b;
}
.pink-blueGrey app-lsd .work-space .side .container .section-resume .line.head h4 {
  color: white;
}
.pink-blueGrey app-lsd .work-space .side.edition .toolbar .header {
  color: white;
}
.pink-blueGrey app-lsd .work-space .side.edition .toolbar .header mat-icon {
  color: #607d8b;
}
.pink-blueGrey app-lsd .work-space .side.edition .toolbar .header h3 {
  color: #607d8b;
}
.pink-blueGrey .dialog-content {
  max-height: 90vh;
}
.pink-blueGrey .dialog-content mat-toolbar {
  background: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .dialog-content .dialog-container .document-template-container .document-selection mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #fafafa !important;
}
.pink-blueGrey .dialog-content .dialog-container .document-template-container .document-selection mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(0, 0, 0, 0.38);
}
.pink-blueGrey .dialog-content .dialog-container .document-template-container .document-selection mat-form-field .mat-form-field-flex {
  background: #fafafa;
}
.pink-blueGrey .dialog-content .dialog-container .document-template-container .document-selection app-upload-form .file-upload-list .mat-subheader::before {
  color: #e91e63;
}
.pink-blueGrey .dialog-content .dialog-container .view-container {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}
.pink-blueGrey .dialog-content .form-actions {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.pink-blueGrey.dark .mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-blueGrey.dark .mat-badge-small .mat-badge-content {
  font-size: 9px;
}
.pink-blueGrey.dark .mat-badge-large .mat-badge-content {
  font-size: 24px;
}
.pink-blueGrey.dark .mat-h1,
.pink-blueGrey.dark .mat-headline,
.pink-blueGrey.dark .mat-typography .mat-h1,
.pink-blueGrey.dark .mat-typography .mat-headline,
.pink-blueGrey.dark .mat-typography h1 {
  font: 400 24px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.pink-blueGrey.dark .mat-h2,
.pink-blueGrey.dark .mat-title,
.pink-blueGrey.dark .mat-typography .mat-h2,
.pink-blueGrey.dark .mat-typography .mat-title,
.pink-blueGrey.dark .mat-typography h2 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.pink-blueGrey.dark .mat-h3,
.pink-blueGrey.dark .mat-subheading-2,
.pink-blueGrey.dark .mat-typography .mat-h3,
.pink-blueGrey.dark .mat-typography .mat-subheading-2,
.pink-blueGrey.dark .mat-typography h3 {
  font: 400 16px / 28px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.pink-blueGrey.dark .mat-h4,
.pink-blueGrey.dark .mat-subheading-1,
.pink-blueGrey.dark .mat-typography .mat-h4,
.pink-blueGrey.dark .mat-typography .mat-subheading-1,
.pink-blueGrey.dark .mat-typography h4 {
  font: 400 15px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.pink-blueGrey.dark .mat-h5,
.pink-blueGrey.dark .mat-typography .mat-h5,
.pink-blueGrey.dark .mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}
.pink-blueGrey.dark .mat-h6,
.pink-blueGrey.dark .mat-typography .mat-h6,
.pink-blueGrey.dark .mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}
.pink-blueGrey.dark .mat-body-strong,
.pink-blueGrey.dark .mat-body-2,
.pink-blueGrey.dark .mat-typography .mat-body-strong,
.pink-blueGrey.dark .mat-typography .mat-body-2 {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.pink-blueGrey.dark .mat-body,
.pink-blueGrey.dark .mat-body-1,
.pink-blueGrey.dark .mat-typography .mat-body,
.pink-blueGrey.dark .mat-typography .mat-body-1,
.pink-blueGrey.dark .mat-typography {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.pink-blueGrey.dark .mat-body p,
.pink-blueGrey.dark .mat-body-1 p,
.pink-blueGrey.dark .mat-typography .mat-body p,
.pink-blueGrey.dark .mat-typography .mat-body-1 p,
.pink-blueGrey.dark .mat-typography p {
  margin: 0 0 12px;
}
.pink-blueGrey.dark .mat-small,
.pink-blueGrey.dark .mat-caption,
.pink-blueGrey.dark .mat-typography .mat-small,
.pink-blueGrey.dark .mat-typography .mat-caption {
  font: 400 12px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.pink-blueGrey.dark .mat-display-4,
.pink-blueGrey.dark .mat-typography .mat-display-4 {
  font: 300 112px / 112px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}
.pink-blueGrey.dark .mat-display-3,
.pink-blueGrey.dark .mat-typography .mat-display-3 {
  font: 400 56px / 56px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}
.pink-blueGrey.dark .mat-display-2,
.pink-blueGrey.dark .mat-typography .mat-display-2 {
  font: 400 45px / 48px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}
.pink-blueGrey.dark .mat-display-1,
.pink-blueGrey.dark .mat-typography .mat-display-1 {
  font: 400 34px / 40px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}
.pink-blueGrey.dark .mat-bottom-sheet-container {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.pink-blueGrey.dark .mat-button, .pink-blueGrey.dark .mat-raised-button, .pink-blueGrey.dark .mat-icon-button, .pink-blueGrey.dark .mat-stroked-button,
.pink-blueGrey.dark .mat-flat-button, .pink-blueGrey.dark .mat-fab, .pink-blueGrey.dark .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.pink-blueGrey.dark .mat-button-toggle {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-blueGrey.dark .mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-blueGrey.dark .mat-card-title {
  font-size: 24px;
  font-weight: 500;
}
.pink-blueGrey.dark .mat-card-header .mat-card-title {
  font-size: 20px;
}
.pink-blueGrey.dark .mat-card-subtitle,
.pink-blueGrey.dark .mat-card-content {
  font-size: 14px;
}
.pink-blueGrey.dark .mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-blueGrey.dark .mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}
.pink-blueGrey.dark .mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.pink-blueGrey.dark .mat-chip .mat-chip-trailing-icon.mat-icon,
.pink-blueGrey.dark .mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}
.pink-blueGrey.dark .mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-blueGrey.dark .mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}
.pink-blueGrey.dark .mat-cell, .pink-blueGrey.dark .mat-footer-cell {
  font-size: 14px;
}
.pink-blueGrey.dark .mat-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-blueGrey.dark .mat-calendar-body {
  font-size: 13px;
}
.pink-blueGrey.dark .mat-calendar-body-label,
.pink-blueGrey.dark .mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}
.pink-blueGrey.dark .mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}
.pink-blueGrey.dark .mat-dialog-title {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.pink-blueGrey.dark .mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400;
}
.pink-blueGrey.dark .mat-expansion-panel-content {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.pink-blueGrey.dark .mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.pink-blueGrey.dark .mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}
.pink-blueGrey.dark .mat-form-field-prefix .mat-icon,
.pink-blueGrey.dark .mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.pink-blueGrey.dark .mat-form-field-prefix .mat-icon-button,
.pink-blueGrey.dark .mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.pink-blueGrey.dark .mat-form-field-prefix .mat-icon-button .mat-icon,
.pink-blueGrey.dark .mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}
.pink-blueGrey.dark .mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}
.pink-blueGrey.dark .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.pink-blueGrey.dark .mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34345em) scale(0.75);
  width: 133.3336333333%;
}
.pink-blueGrey.dark .mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34344em) scale(0.75);
  width: 133.3336433333%;
}
.pink-blueGrey.dark .mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}
.pink-blueGrey.dark .mat-form-field-label {
  top: 1.34375em;
}
.pink-blueGrey.dark .mat-form-field-underline {
  bottom: 1.34375em;
}
.pink-blueGrey.dark .mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}
.pink-blueGrey.dark .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.pink-blueGrey.dark .mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.pink-blueGrey.dark .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.pink-blueGrey.dark .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.0019px);
  width: 133.3342333333%;
}
.pink-blueGrey.dark .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00191px);
  width: 133.3342433333%;
}
.pink-blueGrey.dark .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00192px);
  width: 133.3342533333%;
}
.pink-blueGrey.dark .mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.pink-blueGrey.dark .mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.pink-blueGrey.dark .mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}
@media print {
  .pink-blueGrey.dark .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.pink-blueGrey.dark .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28032em) scale(0.75);
  }
  .pink-blueGrey.dark .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28031em) scale(0.75);
  }
  .pink-blueGrey.dark .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2803em) scale(0.75);
  }
}
.pink-blueGrey.dark .mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.pink-blueGrey.dark .mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.pink-blueGrey.dark .mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.pink-blueGrey.dark .mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59345em) scale(0.75);
  width: 133.3336333333%;
}
.pink-blueGrey.dark .mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59344em) scale(0.75);
  width: 133.3336433333%;
}
.pink-blueGrey.dark .mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.pink-blueGrey.dark .mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.pink-blueGrey.dark .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.pink-blueGrey.dark .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59345em) scale(0.75);
  width: 133.3336333333%;
}
.pink-blueGrey.dark .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59344em) scale(0.75);
  width: 133.3336433333%;
}
.pink-blueGrey.dark .mat-grid-tile-header,
.pink-blueGrey.dark .mat-grid-tile-footer {
  font-size: 14px;
}
.pink-blueGrey.dark .mat-grid-tile-header .mat-line,
.pink-blueGrey.dark .mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.pink-blueGrey.dark .mat-grid-tile-header .mat-line:nth-child(n+2),
.pink-blueGrey.dark .mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.pink-blueGrey.dark input.mat-input-element {
  margin-top: -0.0625em;
}
.pink-blueGrey.dark .mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
}
.pink-blueGrey.dark .mat-paginator,
.pink-blueGrey.dark .mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
}
.pink-blueGrey.dark .mat-radio-button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-blueGrey.dark .mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-blueGrey.dark .mat-select-trigger {
  height: 1.125em;
}
.pink-blueGrey.dark .mat-slide-toggle-content {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-blueGrey.dark .mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}
.pink-blueGrey.dark .mat-stepper-vertical, .pink-blueGrey.dark .mat-stepper-horizontal {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-blueGrey.dark .mat-step-label {
  font-size: 14px;
  font-weight: 400;
}
.pink-blueGrey.dark .mat-step-sub-label-error {
  font-weight: normal;
}
.pink-blueGrey.dark .mat-step-label-error {
  font-size: 14px;
}
.pink-blueGrey.dark .mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}
.pink-blueGrey.dark .mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-blueGrey.dark .mat-tab-label, .pink-blueGrey.dark .mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.pink-blueGrey.dark .mat-toolbar,
.pink-blueGrey.dark .mat-toolbar h1,
.pink-blueGrey.dark .mat-toolbar h2,
.pink-blueGrey.dark .mat-toolbar h3,
.pink-blueGrey.dark .mat-toolbar h4,
.pink-blueGrey.dark .mat-toolbar h5,
.pink-blueGrey.dark .mat-toolbar h6 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0;
}
.pink-blueGrey.dark .mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.pink-blueGrey.dark .mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.pink-blueGrey.dark .mat-list-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-blueGrey.dark .mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-blueGrey.dark .mat-list-base .mat-list-item {
  font-size: 16px;
}
.pink-blueGrey.dark .mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.pink-blueGrey.dark .mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.pink-blueGrey.dark .mat-list-base .mat-list-option {
  font-size: 16px;
}
.pink-blueGrey.dark .mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.pink-blueGrey.dark .mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.pink-blueGrey.dark .mat-list-base .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.pink-blueGrey.dark .mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.pink-blueGrey.dark .mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.pink-blueGrey.dark .mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.pink-blueGrey.dark .mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.pink-blueGrey.dark .mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.pink-blueGrey.dark .mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.pink-blueGrey.dark .mat-list-base[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}
.pink-blueGrey.dark .mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
}
.pink-blueGrey.dark .mat-optgroup-label {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.pink-blueGrey.dark .mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
}
.pink-blueGrey.dark .mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}
.pink-blueGrey.dark .mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pink-blueGrey.dark .mat-tree-node,
.pink-blueGrey.dark .mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}
.pink-blueGrey.dark .mat-ripple {
  overflow: hidden;
  position: relative;
}
.pink-blueGrey.dark .mat-ripple:not(:empty) {
  transform: translateZ(0);
}
.pink-blueGrey.dark .mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}
.pink-blueGrey.dark .mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .pink-blueGrey.dark .mat-ripple-element {
  display: none;
}

.pink-blueGrey.dark .cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .pink-blueGrey.dark .cdk-visually-hidden {
  left: auto;
  right: 0;
}
.pink-blueGrey.dark .cdk-overlay-container, .pink-blueGrey.dark .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.pink-blueGrey.dark .cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.pink-blueGrey.dark .cdk-overlay-container:empty {
  display: none;
}
.pink-blueGrey.dark .cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}
.pink-blueGrey.dark .cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}
.pink-blueGrey.dark .cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.pink-blueGrey.dark .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .pink-blueGrey.dark .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}
.pink-blueGrey.dark .cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}
.pink-blueGrey.dark .cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.pink-blueGrey.dark .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}
.pink-blueGrey.dark .cdk-overlay-backdrop-noop-animation {
  transition: none;
}
.pink-blueGrey.dark .cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}
.pink-blueGrey.dark .cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}
.pink-blueGrey.dark textarea.cdk-textarea-autosize {
  resize: none;
}
.pink-blueGrey.dark textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}
.pink-blueGrey.dark textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}
@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.pink-blueGrey.dark .cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}
.pink-blueGrey.dark .cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}
.pink-blueGrey.dark .mat-focus-indicator {
  position: relative;
}
.pink-blueGrey.dark .mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.pink-blueGrey.dark .mat-focus-indicator:focus::before {
  content: "";
}
.cdk-high-contrast-active .pink-blueGrey.dark {
  --mat-focus-indicator-display: block;
}

.pink-blueGrey.dark .mat-mdc-focus-indicator {
  position: relative;
}
.pink-blueGrey.dark .mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.pink-blueGrey.dark .mat-mdc-focus-indicator:focus::before {
  content: "";
}
.cdk-high-contrast-active .pink-blueGrey.dark {
  --mat-mdc-focus-indicator-display: block;
}

.pink-blueGrey.dark .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.pink-blueGrey.dark .mat-option {
  color: white;
}
.pink-blueGrey.dark .mat-option:hover:not(.mat-option-disabled), .pink-blueGrey.dark .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.pink-blueGrey.dark .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.pink-blueGrey.dark .mat-option.mat-active {
  background: rgba(255, 255, 255, 0.04);
  color: white;
}
.pink-blueGrey.dark .mat-option.mat-option-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.pink-blueGrey.dark .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f48fb1;
}
.pink-blueGrey.dark .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #b0bec5;
}
.pink-blueGrey.dark .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #e57373;
}
.pink-blueGrey.dark .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.7);
}
.pink-blueGrey.dark .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.5);
}
.pink-blueGrey.dark .mat-pseudo-checkbox {
  color: rgba(255, 255, 255, 0.7);
}
.pink-blueGrey.dark .mat-pseudo-checkbox::after {
  color: #303030;
}
.pink-blueGrey.dark .mat-pseudo-checkbox-disabled {
  color: #686868;
}
.pink-blueGrey.dark .mat-primary .mat-pseudo-checkbox-checked,
.pink-blueGrey.dark .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #f48fb1;
}
.pink-blueGrey.dark .mat-pseudo-checkbox-checked,
.pink-blueGrey.dark .mat-pseudo-checkbox-indeterminate,
.pink-blueGrey.dark .mat-accent .mat-pseudo-checkbox-checked,
.pink-blueGrey.dark .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #b0bec5;
}
.pink-blueGrey.dark .mat-warn .mat-pseudo-checkbox-checked,
.pink-blueGrey.dark .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #e57373;
}
.pink-blueGrey.dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.pink-blueGrey.dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #686868;
}
.pink-blueGrey.dark .mat-app-background, .pink-blueGrey.dark.mat-app-background {
  background-color: #303030;
  color: white;
}
.pink-blueGrey.dark .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey.dark .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey.dark .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey.dark .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey.dark .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey.dark .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey.dark .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey.dark .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey.dark .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey.dark .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey.dark .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey.dark .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey.dark .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey.dark .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey.dark .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey.dark .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey.dark .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey.dark .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey.dark .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey.dark .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey.dark .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey.dark .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey.dark .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey.dark .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey.dark .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.pink-blueGrey.dark .mat-autocomplete-panel {
  background: #424242;
  color: white;
}
.pink-blueGrey.dark .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey.dark .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: #424242;
}
.pink-blueGrey.dark .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: white;
}
.pink-blueGrey.dark .mat-badge-content {
  color: rgba(0, 0, 0, 0.87);
  background: #f48fb1;
}
.cdk-high-contrast-active .pink-blueGrey.dark .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.pink-blueGrey.dark .mat-badge-accent .mat-badge-content {
  background: #b0bec5;
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark .mat-badge-warn .mat-badge-content {
  color: rgba(0, 0, 0, 0.87);
  background: #e57373;
}
.pink-blueGrey.dark .mat-badge-disabled .mat-badge-content {
  background: #6e6e6e;
  color: rgba(255, 255, 255, 0.5);
}
.pink-blueGrey.dark .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.pink-blueGrey.dark .mat-button, .pink-blueGrey.dark .mat-icon-button, .pink-blueGrey.dark .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.pink-blueGrey.dark .mat-button.mat-primary, .pink-blueGrey.dark .mat-icon-button.mat-primary, .pink-blueGrey.dark .mat-stroked-button.mat-primary {
  color: #f48fb1;
}
.pink-blueGrey.dark .mat-button.mat-accent, .pink-blueGrey.dark .mat-icon-button.mat-accent, .pink-blueGrey.dark .mat-stroked-button.mat-accent {
  color: #b0bec5;
}
.pink-blueGrey.dark .mat-button.mat-warn, .pink-blueGrey.dark .mat-icon-button.mat-warn, .pink-blueGrey.dark .mat-stroked-button.mat-warn {
  color: #e57373;
}
.pink-blueGrey.dark .mat-button.mat-primary.mat-button-disabled, .pink-blueGrey.dark .mat-button.mat-accent.mat-button-disabled, .pink-blueGrey.dark .mat-button.mat-warn.mat-button-disabled, .pink-blueGrey.dark .mat-button.mat-button-disabled.mat-button-disabled, .pink-blueGrey.dark .mat-icon-button.mat-primary.mat-button-disabled, .pink-blueGrey.dark .mat-icon-button.mat-accent.mat-button-disabled, .pink-blueGrey.dark .mat-icon-button.mat-warn.mat-button-disabled, .pink-blueGrey.dark .mat-icon-button.mat-button-disabled.mat-button-disabled, .pink-blueGrey.dark .mat-stroked-button.mat-primary.mat-button-disabled, .pink-blueGrey.dark .mat-stroked-button.mat-accent.mat-button-disabled, .pink-blueGrey.dark .mat-stroked-button.mat-warn.mat-button-disabled, .pink-blueGrey.dark .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.pink-blueGrey.dark .mat-button.mat-primary .mat-button-focus-overlay, .pink-blueGrey.dark .mat-icon-button.mat-primary .mat-button-focus-overlay, .pink-blueGrey.dark .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #f48fb1;
}
.pink-blueGrey.dark .mat-button.mat-accent .mat-button-focus-overlay, .pink-blueGrey.dark .mat-icon-button.mat-accent .mat-button-focus-overlay, .pink-blueGrey.dark .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #b0bec5;
}
.pink-blueGrey.dark .mat-button.mat-warn .mat-button-focus-overlay, .pink-blueGrey.dark .mat-icon-button.mat-warn .mat-button-focus-overlay, .pink-blueGrey.dark .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #e57373;
}
.pink-blueGrey.dark .mat-button.mat-button-disabled .mat-button-focus-overlay, .pink-blueGrey.dark .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .pink-blueGrey.dark .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.pink-blueGrey.dark .mat-button .mat-ripple-element, .pink-blueGrey.dark .mat-icon-button .mat-ripple-element, .pink-blueGrey.dark .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.pink-blueGrey.dark .mat-button-focus-overlay {
  background: white;
}
.pink-blueGrey.dark .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark .mat-flat-button, .pink-blueGrey.dark .mat-raised-button, .pink-blueGrey.dark .mat-fab, .pink-blueGrey.dark .mat-mini-fab {
  color: white;
  background-color: #424242;
}
.pink-blueGrey.dark .mat-flat-button.mat-primary, .pink-blueGrey.dark .mat-raised-button.mat-primary, .pink-blueGrey.dark .mat-fab.mat-primary, .pink-blueGrey.dark .mat-mini-fab.mat-primary {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark .mat-flat-button.mat-accent, .pink-blueGrey.dark .mat-raised-button.mat-accent, .pink-blueGrey.dark .mat-fab.mat-accent, .pink-blueGrey.dark .mat-mini-fab.mat-accent {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark .mat-flat-button.mat-warn, .pink-blueGrey.dark .mat-raised-button.mat-warn, .pink-blueGrey.dark .mat-fab.mat-warn, .pink-blueGrey.dark .mat-mini-fab.mat-warn {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark .mat-flat-button.mat-primary.mat-button-disabled, .pink-blueGrey.dark .mat-flat-button.mat-accent.mat-button-disabled, .pink-blueGrey.dark .mat-flat-button.mat-warn.mat-button-disabled, .pink-blueGrey.dark .mat-flat-button.mat-button-disabled.mat-button-disabled, .pink-blueGrey.dark .mat-raised-button.mat-primary.mat-button-disabled, .pink-blueGrey.dark .mat-raised-button.mat-accent.mat-button-disabled, .pink-blueGrey.dark .mat-raised-button.mat-warn.mat-button-disabled, .pink-blueGrey.dark .mat-raised-button.mat-button-disabled.mat-button-disabled, .pink-blueGrey.dark .mat-fab.mat-primary.mat-button-disabled, .pink-blueGrey.dark .mat-fab.mat-accent.mat-button-disabled, .pink-blueGrey.dark .mat-fab.mat-warn.mat-button-disabled, .pink-blueGrey.dark .mat-fab.mat-button-disabled.mat-button-disabled, .pink-blueGrey.dark .mat-mini-fab.mat-primary.mat-button-disabled, .pink-blueGrey.dark .mat-mini-fab.mat-accent.mat-button-disabled, .pink-blueGrey.dark .mat-mini-fab.mat-warn.mat-button-disabled, .pink-blueGrey.dark .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.pink-blueGrey.dark .mat-flat-button.mat-primary, .pink-blueGrey.dark .mat-raised-button.mat-primary, .pink-blueGrey.dark .mat-fab.mat-primary, .pink-blueGrey.dark .mat-mini-fab.mat-primary {
  background-color: #f48fb1;
}
.pink-blueGrey.dark .mat-flat-button.mat-accent, .pink-blueGrey.dark .mat-raised-button.mat-accent, .pink-blueGrey.dark .mat-fab.mat-accent, .pink-blueGrey.dark .mat-mini-fab.mat-accent {
  background-color: #b0bec5;
}
.pink-blueGrey.dark .mat-flat-button.mat-warn, .pink-blueGrey.dark .mat-raised-button.mat-warn, .pink-blueGrey.dark .mat-fab.mat-warn, .pink-blueGrey.dark .mat-mini-fab.mat-warn {
  background-color: #e57373;
}
.pink-blueGrey.dark .mat-flat-button.mat-primary.mat-button-disabled, .pink-blueGrey.dark .mat-flat-button.mat-accent.mat-button-disabled, .pink-blueGrey.dark .mat-flat-button.mat-warn.mat-button-disabled, .pink-blueGrey.dark .mat-flat-button.mat-button-disabled.mat-button-disabled, .pink-blueGrey.dark .mat-raised-button.mat-primary.mat-button-disabled, .pink-blueGrey.dark .mat-raised-button.mat-accent.mat-button-disabled, .pink-blueGrey.dark .mat-raised-button.mat-warn.mat-button-disabled, .pink-blueGrey.dark .mat-raised-button.mat-button-disabled.mat-button-disabled, .pink-blueGrey.dark .mat-fab.mat-primary.mat-button-disabled, .pink-blueGrey.dark .mat-fab.mat-accent.mat-button-disabled, .pink-blueGrey.dark .mat-fab.mat-warn.mat-button-disabled, .pink-blueGrey.dark .mat-fab.mat-button-disabled.mat-button-disabled, .pink-blueGrey.dark .mat-mini-fab.mat-primary.mat-button-disabled, .pink-blueGrey.dark .mat-mini-fab.mat-accent.mat-button-disabled, .pink-blueGrey.dark .mat-mini-fab.mat-warn.mat-button-disabled, .pink-blueGrey.dark .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark .mat-flat-button.mat-primary .mat-ripple-element, .pink-blueGrey.dark .mat-raised-button.mat-primary .mat-ripple-element, .pink-blueGrey.dark .mat-fab.mat-primary .mat-ripple-element, .pink-blueGrey.dark .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.pink-blueGrey.dark .mat-flat-button.mat-accent .mat-ripple-element, .pink-blueGrey.dark .mat-raised-button.mat-accent .mat-ripple-element, .pink-blueGrey.dark .mat-fab.mat-accent .mat-ripple-element, .pink-blueGrey.dark .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.pink-blueGrey.dark .mat-flat-button.mat-warn .mat-ripple-element, .pink-blueGrey.dark .mat-raised-button.mat-warn .mat-ripple-element, .pink-blueGrey.dark .mat-fab.mat-warn .mat-ripple-element, .pink-blueGrey.dark .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.pink-blueGrey.dark .mat-stroked-button:not([class*=mat-elevation-z]), .pink-blueGrey.dark .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey.dark .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey.dark .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey.dark .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey.dark .mat-fab:not([class*=mat-elevation-z]), .pink-blueGrey.dark .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey.dark .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .pink-blueGrey.dark .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey.dark .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .pink-blueGrey.dark .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey.dark .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.pink-blueGrey.dark .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey.dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.pink-blueGrey.dark .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.pink-blueGrey.dark .mat-button-toggle {
  color: rgba(255, 255, 255, 0.5);
}
.pink-blueGrey.dark .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark .mat-button-toggle-appearance-standard {
  color: white;
  background: #424242;
}
.pink-blueGrey.dark .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: white;
}
.pink-blueGrey.dark .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #595959;
}
.pink-blueGrey.dark [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #595959;
}
.pink-blueGrey.dark .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #595959;
}
.pink-blueGrey.dark .mat-button-toggle-checked {
  background-color: #212121;
  color: rgba(255, 255, 255, 0.7);
}
.pink-blueGrey.dark .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: white;
}
.pink-blueGrey.dark .mat-button-toggle-disabled {
  color: rgba(255, 255, 255, 0.3);
  background-color: black;
}
.pink-blueGrey.dark .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: #424242;
}
.pink-blueGrey.dark .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #424242;
}
.pink-blueGrey.dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.pink-blueGrey.dark .mat-button-toggle-group-appearance-standard {
  border: solid 1px #595959;
}
.pink-blueGrey.dark .mat-card {
  background: #424242;
  color: white;
}
.pink-blueGrey.dark .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey.dark .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey.dark .mat-card-subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.pink-blueGrey.dark .mat-checkbox-frame {
  border-color: rgba(255, 255, 255, 0.7);
}
.pink-blueGrey.dark .mat-checkbox-checkmark {
  fill: #303030;
}
.pink-blueGrey.dark .mat-checkbox-checkmark-path {
  stroke: #303030 !important;
}
.pink-blueGrey.dark .mat-checkbox-mixedmark {
  background-color: #303030;
}
.pink-blueGrey.dark .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .pink-blueGrey.dark .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #f48fb1;
}
.pink-blueGrey.dark .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .pink-blueGrey.dark .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #b0bec5;
}
.pink-blueGrey.dark .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .pink-blueGrey.dark .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #e57373;
}
.pink-blueGrey.dark .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .pink-blueGrey.dark .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #686868;
}
.pink-blueGrey.dark .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #686868;
}
.pink-blueGrey.dark .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(255, 255, 255, 0.5);
}
.pink-blueGrey.dark .mat-checkbox .mat-ripple-element {
  background-color: white;
}
.pink-blueGrey.dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.pink-blueGrey.dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #f48fb1;
}
.pink-blueGrey.dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.pink-blueGrey.dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #b0bec5;
}
.pink-blueGrey.dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.pink-blueGrey.dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #e57373;
}
.pink-blueGrey.dark .mat-chip.mat-standard-chip {
  background-color: #616161;
  color: white;
}
.pink-blueGrey.dark .mat-chip.mat-standard-chip .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.pink-blueGrey.dark .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey.dark .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.pink-blueGrey.dark .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.pink-blueGrey.dark .mat-chip.mat-standard-chip::after {
  background: white;
}
.pink-blueGrey.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #f48fb1;
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.pink-blueGrey.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.pink-blueGrey.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #e57373;
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.pink-blueGrey.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.pink-blueGrey.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #b0bec5;
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.pink-blueGrey.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.pink-blueGrey.dark .mat-table {
  background: #424242;
}
.pink-blueGrey.dark .mat-table thead, .pink-blueGrey.dark .mat-table tbody, .pink-blueGrey.dark .mat-table tfoot,
.pink-blueGrey.dark mat-header-row, .pink-blueGrey.dark mat-row, .pink-blueGrey.dark mat-footer-row,
.pink-blueGrey.dark [mat-header-row], .pink-blueGrey.dark [mat-row], .pink-blueGrey.dark [mat-footer-row],
.pink-blueGrey.dark .mat-table-sticky {
  background: inherit;
}
.pink-blueGrey.dark mat-row, .pink-blueGrey.dark mat-header-row, .pink-blueGrey.dark mat-footer-row,
.pink-blueGrey.dark th.mat-header-cell, .pink-blueGrey.dark td.mat-cell, .pink-blueGrey.dark td.mat-footer-cell {
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark .mat-header-cell {
  color: rgba(255, 255, 255, 0.7);
}
.pink-blueGrey.dark .mat-cell, .pink-blueGrey.dark .mat-footer-cell {
  color: white;
}
.pink-blueGrey.dark .mat-calendar-arrow {
  fill: white;
}
.pink-blueGrey.dark .mat-datepicker-toggle,
.pink-blueGrey.dark .mat-datepicker-content .mat-calendar-next-button,
.pink-blueGrey.dark .mat-datepicker-content .mat-calendar-previous-button {
  color: white;
}
.pink-blueGrey.dark .mat-calendar-table-header-divider::after {
  background: rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark .mat-calendar-table-header,
.pink-blueGrey.dark .mat-calendar-body-label {
  color: rgba(255, 255, 255, 0.7);
}
.pink-blueGrey.dark .mat-calendar-body-cell-content,
.pink-blueGrey.dark .mat-date-range-input-separator {
  color: white;
  border-color: transparent;
}
.pink-blueGrey.dark .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(255, 255, 255, 0.5);
}
.pink-blueGrey.dark .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(255, 255, 255, 0.5);
}
.pink-blueGrey.dark .mat-calendar-body-in-preview {
  color: rgba(255, 255, 255, 0.24);
}
.pink-blueGrey.dark .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.5);
}
.pink-blueGrey.dark .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.3);
}
.pink-blueGrey.dark .mat-calendar-body-in-range::before {
  background: rgba(244, 143, 177, 0.2);
}
.pink-blueGrey.dark .mat-calendar-body-comparison-identical,
.pink-blueGrey.dark .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.pink-blueGrey.dark .mat-calendar-body-comparison-bridge-start::before,
.pink-blueGrey.dark [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 143, 177, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.pink-blueGrey.dark .mat-calendar-body-comparison-bridge-end::before,
.pink-blueGrey.dark [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 143, 177, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.pink-blueGrey.dark .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.pink-blueGrey.dark .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.pink-blueGrey.dark .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.pink-blueGrey.dark .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.pink-blueGrey.dark .mat-calendar-body-selected {
  background-color: #f48fb1;
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 143, 177, 0.4);
}
.pink-blueGrey.dark .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.pink-blueGrey.dark .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(244, 143, 177, 0.3);
}
@media (hover: hover) {
  .pink-blueGrey.dark .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(244, 143, 177, 0.3);
  }
}
.pink-blueGrey.dark .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #424242;
  color: white;
}
.pink-blueGrey.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(176, 190, 197, 0.2);
}
.pink-blueGrey.dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.pink-blueGrey.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.pink-blueGrey.dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.pink-blueGrey.dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(176, 190, 197, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.pink-blueGrey.dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.pink-blueGrey.dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(176, 190, 197, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.pink-blueGrey.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.pink-blueGrey.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.pink-blueGrey.dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.pink-blueGrey.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.pink-blueGrey.dark .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #b0bec5;
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(176, 190, 197, 0.4);
}
.pink-blueGrey.dark .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.pink-blueGrey.dark .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(176, 190, 197, 0.3);
}
@media (hover: hover) {
  .pink-blueGrey.dark .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(176, 190, 197, 0.3);
  }
}
.pink-blueGrey.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(229, 115, 115, 0.2);
}
.pink-blueGrey.dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.pink-blueGrey.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.pink-blueGrey.dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.pink-blueGrey.dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(229, 115, 115, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.pink-blueGrey.dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.pink-blueGrey.dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(229, 115, 115, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.pink-blueGrey.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.pink-blueGrey.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.pink-blueGrey.dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.pink-blueGrey.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.pink-blueGrey.dark .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #e57373;
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(229, 115, 115, 0.4);
}
.pink-blueGrey.dark .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.pink-blueGrey.dark .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(229, 115, 115, 0.3);
}
@media (hover: hover) {
  .pink-blueGrey.dark .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(229, 115, 115, 0.3);
  }
}
.pink-blueGrey.dark .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey.dark .mat-datepicker-toggle-active {
  color: #f48fb1;
}
.pink-blueGrey.dark .mat-datepicker-toggle-active.mat-accent {
  color: #b0bec5;
}
.pink-blueGrey.dark .mat-datepicker-toggle-active.mat-warn {
  color: #e57373;
}
.pink-blueGrey.dark .mat-date-range-input-inner[disabled] {
  color: rgba(255, 255, 255, 0.5);
}
.pink-blueGrey.dark .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.pink-blueGrey.dark .mat-divider {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark .mat-divider-vertical {
  border-right-color: rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark .mat-expansion-panel {
  background: #424242;
  color: white;
}
.pink-blueGrey.dark .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey.dark .mat-action-row {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .pink-blueGrey.dark .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .pink-blueGrey.dark .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(255, 255, 255, 0.04);
}
@media (hover: none) {
  .pink-blueGrey.dark .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: #424242;
  }
}
.pink-blueGrey.dark .mat-expansion-panel-header-title {
  color: white;
}
.pink-blueGrey.dark .mat-expansion-panel-header-description,
.pink-blueGrey.dark .mat-expansion-indicator::after {
  color: rgba(255, 255, 255, 0.7);
}
.pink-blueGrey.dark .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(255, 255, 255, 0.3);
}
.pink-blueGrey.dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.pink-blueGrey.dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.pink-blueGrey.dark .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.pink-blueGrey.dark .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.pink-blueGrey.dark .mat-form-field.mat-focused .mat-form-field-label {
  color: #f48fb1;
}
.pink-blueGrey.dark .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #b0bec5;
}
.pink-blueGrey.dark .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #e57373;
}
.pink-blueGrey.dark .mat-focused .mat-form-field-required-marker {
  color: #b0bec5;
}
.pink-blueGrey.dark .mat-form-field-ripple {
  background-color: white;
}
.pink-blueGrey.dark .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #f48fb1;
}
.pink-blueGrey.dark .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #b0bec5;
}
.pink-blueGrey.dark .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #e57373;
}
.pink-blueGrey.dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #f48fb1;
}
.pink-blueGrey.dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #b0bec5;
}
.pink-blueGrey.dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #e57373;
}
.pink-blueGrey.dark .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #e57373;
}
.pink-blueGrey.dark .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.pink-blueGrey.dark .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #e57373;
}
.pink-blueGrey.dark .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.pink-blueGrey.dark .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #e57373;
}
.pink-blueGrey.dark .mat-error {
  color: #e57373;
}
.pink-blueGrey.dark .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.pink-blueGrey.dark .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.pink-blueGrey.dark .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.pink-blueGrey.dark .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.pink-blueGrey.dark .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.pink-blueGrey.dark .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.pink-blueGrey.dark .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.1);
}
.pink-blueGrey.dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.05);
}
.pink-blueGrey.dark .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(255, 255, 255, 0.5);
}
.pink-blueGrey.dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.pink-blueGrey.dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.pink-blueGrey.dark .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.3);
}
.pink-blueGrey.dark .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: white;
}
.pink-blueGrey.dark .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #f48fb1;
}
.pink-blueGrey.dark .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #b0bec5;
}
.pink-blueGrey.dark .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #e57373;
}
.pink-blueGrey.dark .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #e57373;
}
.pink-blueGrey.dark .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.pink-blueGrey.dark .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.15);
}
.pink-blueGrey.dark .mat-icon.mat-primary {
  color: #f48fb1;
}
.pink-blueGrey.dark .mat-icon.mat-accent {
  color: #b0bec5;
}
.pink-blueGrey.dark .mat-icon.mat-warn {
  color: #e57373;
}
.pink-blueGrey.dark .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.7);
}
.pink-blueGrey.dark .mat-input-element:disabled,
.pink-blueGrey.dark .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.5);
}
.pink-blueGrey.dark .mat-input-element {
  caret-color: #f48fb1;
}
.pink-blueGrey.dark .mat-input-element::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.pink-blueGrey.dark .mat-input-element::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.pink-blueGrey.dark .mat-input-element::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.pink-blueGrey.dark .mat-input-element:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.pink-blueGrey.dark .mat-input-element:not(.mat-native-select-inline) option {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark .mat-input-element:not(.mat-native-select-inline) option:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.pink-blueGrey.dark .mat-form-field.mat-accent .mat-input-element {
  caret-color: #b0bec5;
}
.pink-blueGrey.dark .mat-form-field.mat-warn .mat-input-element,
.pink-blueGrey.dark .mat-form-field-invalid .mat-input-element {
  caret-color: #e57373;
}
.pink-blueGrey.dark .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #e57373;
}
.pink-blueGrey.dark .mat-list-base .mat-list-item {
  color: white;
}
.pink-blueGrey.dark .mat-list-base .mat-list-option {
  color: white;
}
.pink-blueGrey.dark .mat-list-base .mat-subheader {
  color: rgba(255, 255, 255, 0.7);
}
.pink-blueGrey.dark .mat-list-base .mat-list-item-disabled {
  background-color: rgba(255, 255, 255, 0.12);
  color: rgba(255, 255, 255, 0.5);
}
.pink-blueGrey.dark .mat-list-option:hover, .pink-blueGrey.dark .mat-list-option:focus,
.pink-blueGrey.dark .mat-nav-list .mat-list-item:hover,
.pink-blueGrey.dark .mat-nav-list .mat-list-item:focus,
.pink-blueGrey.dark .mat-action-list .mat-list-item:hover,
.pink-blueGrey.dark .mat-action-list .mat-list-item:focus {
  background: rgba(255, 255, 255, 0.04);
}
.pink-blueGrey.dark .mat-list-single-selected-option, .pink-blueGrey.dark .mat-list-single-selected-option:hover, .pink-blueGrey.dark .mat-list-single-selected-option:focus {
  background: rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark .mat-menu-panel {
  background: #424242;
}
.pink-blueGrey.dark .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey.dark .mat-menu-item {
  background: transparent;
  color: white;
}
.pink-blueGrey.dark .mat-menu-item[disabled],
.pink-blueGrey.dark .mat-menu-item[disabled] .mat-menu-submenu-icon,
.pink-blueGrey.dark .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(255, 255, 255, 0.5);
}
.pink-blueGrey.dark .mat-menu-item .mat-icon-no-color,
.pink-blueGrey.dark .mat-menu-submenu-icon {
  color: white;
}
.pink-blueGrey.dark .mat-menu-item:hover:not([disabled]),
.pink-blueGrey.dark .mat-menu-item.cdk-program-focused:not([disabled]),
.pink-blueGrey.dark .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.pink-blueGrey.dark .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(255, 255, 255, 0.04);
}
.pink-blueGrey.dark .mat-paginator {
  background: #424242;
}
.pink-blueGrey.dark .mat-paginator,
.pink-blueGrey.dark .mat-paginator-page-size .mat-select-trigger {
  color: rgba(255, 255, 255, 0.7);
}
.pink-blueGrey.dark .mat-paginator-decrement,
.pink-blueGrey.dark .mat-paginator-increment {
  border-top: 2px solid white;
  border-right: 2px solid white;
}
.pink-blueGrey.dark .mat-paginator-first,
.pink-blueGrey.dark .mat-paginator-last {
  border-top: 2px solid white;
}
.pink-blueGrey.dark .mat-icon-button[disabled] .mat-paginator-decrement,
.pink-blueGrey.dark .mat-icon-button[disabled] .mat-paginator-increment,
.pink-blueGrey.dark .mat-icon-button[disabled] .mat-paginator-first,
.pink-blueGrey.dark .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(255, 255, 255, 0.5);
}
.pink-blueGrey.dark .mat-progress-bar-background {
  fill: #614850;
}
.pink-blueGrey.dark .mat-progress-bar-buffer {
  background-color: #614850;
}
.pink-blueGrey.dark .mat-progress-bar-fill::after {
  background-color: #f48fb1;
}
.pink-blueGrey.dark .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #505455;
}
.pink-blueGrey.dark .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #505455;
}
.pink-blueGrey.dark .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #b0bec5;
}
.pink-blueGrey.dark .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #5d4141;
}
.pink-blueGrey.dark .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #5d4141;
}
.pink-blueGrey.dark .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #e57373;
}
.pink-blueGrey.dark .mat-progress-spinner circle, .pink-blueGrey.dark .mat-spinner circle {
  stroke: #f48fb1;
}
.pink-blueGrey.dark .mat-progress-spinner.mat-accent circle, .pink-blueGrey.dark .mat-spinner.mat-accent circle {
  stroke: #b0bec5;
}
.pink-blueGrey.dark .mat-progress-spinner.mat-warn circle, .pink-blueGrey.dark .mat-spinner.mat-warn circle {
  stroke: #e57373;
}
.pink-blueGrey.dark .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.7);
}
.pink-blueGrey.dark .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f48fb1;
}
.pink-blueGrey.dark .mat-radio-button.mat-primary .mat-radio-inner-circle,
.pink-blueGrey.dark .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .pink-blueGrey.dark .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .pink-blueGrey.dark .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #f48fb1;
}
.pink-blueGrey.dark .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #b0bec5;
}
.pink-blueGrey.dark .mat-radio-button.mat-accent .mat-radio-inner-circle,
.pink-blueGrey.dark .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .pink-blueGrey.dark .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .pink-blueGrey.dark .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #b0bec5;
}
.pink-blueGrey.dark .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e57373;
}
.pink-blueGrey.dark .mat-radio-button.mat-warn .mat-radio-inner-circle,
.pink-blueGrey.dark .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .pink-blueGrey.dark .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .pink-blueGrey.dark .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #e57373;
}
.pink-blueGrey.dark .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.pink-blueGrey.dark .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.5);
}
.pink-blueGrey.dark .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.pink-blueGrey.dark .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(255, 255, 255, 0.5);
}
.pink-blueGrey.dark .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(255, 255, 255, 0.5);
}
.pink-blueGrey.dark .mat-radio-button .mat-ripple-element {
  background-color: white;
}
.pink-blueGrey.dark .mat-select-value {
  color: white;
}
.pink-blueGrey.dark .mat-select-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.pink-blueGrey.dark .mat-select-disabled .mat-select-value {
  color: rgba(255, 255, 255, 0.5);
}
.pink-blueGrey.dark .mat-select-arrow {
  color: rgba(255, 255, 255, 0.7);
}
.pink-blueGrey.dark .mat-select-panel {
  background: #424242;
}
.pink-blueGrey.dark .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey.dark .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #f48fb1;
}
.pink-blueGrey.dark .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #b0bec5;
}
.pink-blueGrey.dark .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #e57373;
}
.pink-blueGrey.dark .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #e57373;
}
.pink-blueGrey.dark .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(255, 255, 255, 0.5);
}
.pink-blueGrey.dark .mat-drawer-container {
  background-color: #303030;
  color: white;
}
.pink-blueGrey.dark .mat-drawer {
  background-color: #424242;
  color: white;
}
.pink-blueGrey.dark .mat-drawer.mat-drawer-push {
  background-color: #424242;
}
.pink-blueGrey.dark .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey.dark .mat-drawer-side {
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.pink-blueGrey.dark [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.pink-blueGrey.dark [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(189, 189, 189, 0.6);
}
.pink-blueGrey.dark .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #b0bec5;
}
.pink-blueGrey.dark .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(176, 190, 197, 0.54);
}
.pink-blueGrey.dark .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #b0bec5;
}
.pink-blueGrey.dark .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #f48fb1;
}
.pink-blueGrey.dark .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(244, 143, 177, 0.54);
}
.pink-blueGrey.dark .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #f48fb1;
}
.pink-blueGrey.dark .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #e57373;
}
.pink-blueGrey.dark .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(229, 115, 115, 0.54);
}
.pink-blueGrey.dark .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #e57373;
}
.pink-blueGrey.dark .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: white;
}
.pink-blueGrey.dark .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #bdbdbd;
}
.pink-blueGrey.dark .mat-slide-toggle-bar {
  background-color: rgba(255, 255, 255, 0.5);
}
.pink-blueGrey.dark .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.pink-blueGrey.dark .mat-slider.mat-primary .mat-slider-track-fill,
.pink-blueGrey.dark .mat-slider.mat-primary .mat-slider-thumb,
.pink-blueGrey.dark .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #f48fb1;
}
.pink-blueGrey.dark .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(244, 143, 177, 0.2);
}
.pink-blueGrey.dark .mat-slider.mat-accent .mat-slider-track-fill,
.pink-blueGrey.dark .mat-slider.mat-accent .mat-slider-thumb,
.pink-blueGrey.dark .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #b0bec5;
}
.pink-blueGrey.dark .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(176, 190, 197, 0.2);
}
.pink-blueGrey.dark .mat-slider.mat-warn .mat-slider-track-fill,
.pink-blueGrey.dark .mat-slider.mat-warn .mat-slider-thumb,
.pink-blueGrey.dark .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #e57373;
}
.pink-blueGrey.dark .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(229, 115, 115, 0.2);
}
.pink-blueGrey.dark .mat-slider:hover .mat-slider-track-background,
.pink-blueGrey.dark .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.pink-blueGrey.dark .mat-slider.mat-slider-disabled .mat-slider-track-background,
.pink-blueGrey.dark .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.pink-blueGrey.dark .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(255, 255, 255, 0.3);
}
.pink-blueGrey.dark .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.pink-blueGrey.dark .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.pink-blueGrey.dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: white;
}
.pink-blueGrey.dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.pink-blueGrey.dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(255, 255, 255, 0.3);
}
.pink-blueGrey.dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
  background-color: transparent;
}
.pink-blueGrey.dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .pink-blueGrey.dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.pink-blueGrey.dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .pink-blueGrey.dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.pink-blueGrey.dark .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(255, 255, 255, 0.7);
}
.pink-blueGrey.dark .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.pink-blueGrey.dark .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.pink-blueGrey.dark .mat-step-header.cdk-keyboard-focused, .pink-blueGrey.dark .mat-step-header.cdk-program-focused, .pink-blueGrey.dark .mat-step-header:hover:not([aria-disabled]), .pink-blueGrey.dark .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(255, 255, 255, 0.04);
}
.pink-blueGrey.dark .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .pink-blueGrey.dark .mat-step-header:hover {
    background: none;
  }
}
.pink-blueGrey.dark .mat-step-header .mat-step-label,
.pink-blueGrey.dark .mat-step-header .mat-step-optional {
  color: rgba(255, 255, 255, 0.7);
}
.pink-blueGrey.dark .mat-step-header .mat-step-icon {
  background-color: rgba(255, 255, 255, 0.7);
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark .mat-step-header .mat-step-icon-selected,
.pink-blueGrey.dark .mat-step-header .mat-step-icon-state-done,
.pink-blueGrey.dark .mat-step-header .mat-step-icon-state-edit {
  background-color: #f48fb1;
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark .mat-step-header.mat-accent .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark .mat-step-header.mat-accent .mat-step-icon-selected,
.pink-blueGrey.dark .mat-step-header.mat-accent .mat-step-icon-state-done,
.pink-blueGrey.dark .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #b0bec5;
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark .mat-step-header.mat-warn .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark .mat-step-header.mat-warn .mat-step-icon-selected,
.pink-blueGrey.dark .mat-step-header.mat-warn .mat-step-icon-state-done,
.pink-blueGrey.dark .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #e57373;
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #e57373;
}
.pink-blueGrey.dark .mat-step-header .mat-step-label.mat-step-label-active {
  color: white;
}
.pink-blueGrey.dark .mat-step-header .mat-step-label.mat-step-label-error {
  color: #e57373;
}
.pink-blueGrey.dark .mat-stepper-horizontal, .pink-blueGrey.dark .mat-stepper-vertical {
  background-color: #424242;
}
.pink-blueGrey.dark .mat-stepper-vertical-line::before {
  border-left-color: rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark .mat-horizontal-stepper-header::before,
.pink-blueGrey.dark .mat-horizontal-stepper-header::after,
.pink-blueGrey.dark .mat-stepper-horizontal-line {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark .mat-sort-header-arrow {
  color: #c6c6c6;
}
.pink-blueGrey.dark .mat-tab-nav-bar,
.pink-blueGrey.dark .mat-tab-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark .mat-tab-group-inverted-header .mat-tab-nav-bar,
.pink-blueGrey.dark .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  border-bottom: none;
}
.pink-blueGrey.dark .mat-tab-label, .pink-blueGrey.dark .mat-tab-link {
  color: white;
}
.pink-blueGrey.dark .mat-tab-label.mat-tab-disabled, .pink-blueGrey.dark .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.pink-blueGrey.dark .mat-tab-header-pagination-chevron {
  border-color: white;
}
.pink-blueGrey.dark .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.5);
}
.pink-blueGrey.dark .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.pink-blueGrey.dark .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.pink-blueGrey.dark .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-blueGrey.dark .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-blueGrey.dark .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-blueGrey.dark .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .pink-blueGrey.dark .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-blueGrey.dark .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-blueGrey.dark .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-blueGrey.dark .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 187, 208, 0.3);
}
.pink-blueGrey.dark .mat-tab-group.mat-primary .mat-ink-bar, .pink-blueGrey.dark .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #f48fb1;
}
.pink-blueGrey.dark .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .pink-blueGrey.dark .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .pink-blueGrey.dark .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .pink-blueGrey.dark .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-blueGrey.dark .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-blueGrey.dark .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-blueGrey.dark .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .pink-blueGrey.dark .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-blueGrey.dark .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-blueGrey.dark .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-blueGrey.dark .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(207, 216, 220, 0.3);
}
.pink-blueGrey.dark .mat-tab-group.mat-accent .mat-ink-bar, .pink-blueGrey.dark .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #b0bec5;
}
.pink-blueGrey.dark .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .pink-blueGrey.dark .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .pink-blueGrey.dark .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .pink-blueGrey.dark .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-blueGrey.dark .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-blueGrey.dark .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-blueGrey.dark .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .pink-blueGrey.dark .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-blueGrey.dark .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-blueGrey.dark .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-blueGrey.dark .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.pink-blueGrey.dark .mat-tab-group.mat-warn .mat-ink-bar, .pink-blueGrey.dark .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #e57373;
}
.pink-blueGrey.dark .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .pink-blueGrey.dark .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .pink-blueGrey.dark .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .pink-blueGrey.dark .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-blueGrey.dark .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-blueGrey.dark .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-blueGrey.dark .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .pink-blueGrey.dark .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-blueGrey.dark .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-blueGrey.dark .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-blueGrey.dark .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 187, 208, 0.3);
}
.pink-blueGrey.dark .mat-tab-group.mat-background-primary > .mat-tab-header, .pink-blueGrey.dark .mat-tab-group.mat-background-primary > .mat-tab-link-container, .pink-blueGrey.dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .pink-blueGrey.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .pink-blueGrey.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .pink-blueGrey.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #f48fb1;
}
.pink-blueGrey.dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .pink-blueGrey.dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .pink-blueGrey.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .pink-blueGrey.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .pink-blueGrey.dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .pink-blueGrey.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .pink-blueGrey.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.pink-blueGrey.dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.pink-blueGrey.dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.pink-blueGrey.dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.pink-blueGrey.dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .pink-blueGrey.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.pink-blueGrey.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.pink-blueGrey.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.pink-blueGrey.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.pink-blueGrey.dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .pink-blueGrey.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.pink-blueGrey.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.pink-blueGrey.dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.pink-blueGrey.dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.pink-blueGrey.dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .pink-blueGrey.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.pink-blueGrey.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.pink-blueGrey.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.pink-blueGrey.dark .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-blueGrey.dark .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-blueGrey.dark .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-blueGrey.dark .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .pink-blueGrey.dark .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-blueGrey.dark .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-blueGrey.dark .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-blueGrey.dark .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(207, 216, 220, 0.3);
}
.pink-blueGrey.dark .mat-tab-group.mat-background-accent > .mat-tab-header, .pink-blueGrey.dark .mat-tab-group.mat-background-accent > .mat-tab-link-container, .pink-blueGrey.dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .pink-blueGrey.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .pink-blueGrey.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .pink-blueGrey.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #b0bec5;
}
.pink-blueGrey.dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .pink-blueGrey.dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .pink-blueGrey.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .pink-blueGrey.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .pink-blueGrey.dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .pink-blueGrey.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .pink-blueGrey.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.pink-blueGrey.dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.pink-blueGrey.dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.pink-blueGrey.dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.pink-blueGrey.dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .pink-blueGrey.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.pink-blueGrey.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.pink-blueGrey.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.pink-blueGrey.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.pink-blueGrey.dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .pink-blueGrey.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.pink-blueGrey.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.pink-blueGrey.dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.pink-blueGrey.dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.pink-blueGrey.dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .pink-blueGrey.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.pink-blueGrey.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.pink-blueGrey.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.pink-blueGrey.dark .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-blueGrey.dark .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-blueGrey.dark .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-blueGrey.dark .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .pink-blueGrey.dark .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .pink-blueGrey.dark .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.pink-blueGrey.dark .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.pink-blueGrey.dark .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.pink-blueGrey.dark .mat-tab-group.mat-background-warn > .mat-tab-header, .pink-blueGrey.dark .mat-tab-group.mat-background-warn > .mat-tab-link-container, .pink-blueGrey.dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .pink-blueGrey.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .pink-blueGrey.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .pink-blueGrey.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #e57373;
}
.pink-blueGrey.dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .pink-blueGrey.dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .pink-blueGrey.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .pink-blueGrey.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .pink-blueGrey.dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .pink-blueGrey.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .pink-blueGrey.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.pink-blueGrey.dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.pink-blueGrey.dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.pink-blueGrey.dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.pink-blueGrey.dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .pink-blueGrey.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.pink-blueGrey.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.pink-blueGrey.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.pink-blueGrey.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.pink-blueGrey.dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .pink-blueGrey.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.pink-blueGrey.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.pink-blueGrey.dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.pink-blueGrey.dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.pink-blueGrey.dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .pink-blueGrey.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.pink-blueGrey.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.pink-blueGrey.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.pink-blueGrey.dark .mat-toolbar {
  background: #212121;
  color: white;
}
.pink-blueGrey.dark .mat-toolbar.mat-primary {
  background: #f48fb1;
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark .mat-toolbar.mat-accent {
  background: #b0bec5;
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark .mat-toolbar.mat-warn {
  background: #e57373;
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark .mat-toolbar .mat-form-field-underline,
.pink-blueGrey.dark .mat-toolbar .mat-form-field-ripple,
.pink-blueGrey.dark .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.pink-blueGrey.dark .mat-toolbar .mat-form-field-label,
.pink-blueGrey.dark .mat-toolbar .mat-focused .mat-form-field-label,
.pink-blueGrey.dark .mat-toolbar .mat-select-value,
.pink-blueGrey.dark .mat-toolbar .mat-select-arrow,
.pink-blueGrey.dark .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.pink-blueGrey.dark .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.pink-blueGrey.dark .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.pink-blueGrey.dark .mat-tree {
  background: #424242;
}
.pink-blueGrey.dark .mat-tree-node,
.pink-blueGrey.dark .mat-nested-tree-node {
  color: white;
}
.pink-blueGrey.dark .mat-snack-bar-container {
  color: rgba(0, 0, 0, 0.87);
  background: #fafafa;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.pink-blueGrey.dark .mat-simple-snackbar-action {
  color: inherit;
}
.pink-blueGrey.dark *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.pink-blueGrey.dark *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
.pink-blueGrey.dark *::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}
.pink-blueGrey.dark .toolbar-line.container-ou {
  background: #f48fb1;
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark .toolbar-line.container-persona {
  background: #f48fb1 !important;
}
.pink-blueGrey.dark .toolbar-line.container-persona .persona-title {
  background: #f48fb1;
}
.pink-blueGrey.dark .toolbar-line.container-persona .invisible-container.persona-selector {
  background: #f48fb1 !important;
}
.pink-blueGrey.dark button.mat-icon-button:not([disabled]):hover {
  background: rgba(255, 255, 255, 0.04);
}
.pink-blueGrey.dark .side-bar {
  background: black;
}
.pink-blueGrey.dark .icon-stacked {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-blueGrey.dark .icon-stacked mat-icon + mat-icon {
  text-shadow: -1px 1px 0px #f48fb1, 1px -1px 0px #f48fb1;
}
.pink-blueGrey.dark .divider-vert {
  background: rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark .avatar {
  background: #c51162 !important;
}
.pink-blueGrey.dark ngx-avatar .avatar-content {
  background: #c51162 !important;
}
.pink-blueGrey.dark .mat-loading-window {
  background: #424242;
}
.pink-blueGrey.dark .window-spinner {
  background: #424242;
}
.pink-blueGrey.dark .load-window {
  background: #424242;
}
@media (min-width: 600px) and (max-width: 1300px) {
  .pink-blueGrey.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .load-window,
.pink-blueGrey.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .load-window.new {
    background: #424242 !important;
  }
  .pink-blueGrey.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card {
    background: #424242 !important;
  }
  .pink-blueGrey.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .inbox-container mat-toolbar {
    background: #f48fb1;
    color: rgba(0, 0, 0, 0.87);
  }
  .pink-blueGrey.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .inbox-container mat-list-item.mat-list-item {
    border: 1px solid rgba(255, 255, 255, 0.12);
  }
  .pink-blueGrey.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .detail {
    background: #424242 !important;
  }
  .pink-blueGrey.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .detail mat-toolbar {
    background: #424242 !important;
  }
  .pink-blueGrey.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .main.w-detail + .new-detail-container .load-window {
    background: #303030 !important;
  }
  .pink-blueGrey.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .main.w-detail + .new-detail-container:before {
    background: rgba(0, 0, 0, 0.6);
  }
  .pink-blueGrey.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .main.side-open + .side {
    border-left: 1px solid rgba(255, 255, 255, 0.12);
  }
  .pink-blueGrey.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .side .container .mat-list-base {
    background: #424242 !important;
    border: 1px solid rgba(255, 255, 255, 0.12);
  }
  .pink-blueGrey.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .new-table .mat-header-row,
.pink-blueGrey.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .new-table .mat-table,
.pink-blueGrey.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .document-table .mat-header-row,
.pink-blueGrey.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .document-table .mat-table,
.pink-blueGrey.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .status-table .mat-header-row,
.pink-blueGrey.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .status-table .mat-table {
    background: #424242 !important;
  }
  .pink-blueGrey.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .audit-filter .filter-container.is-floating {
    background: #303030 !important;
    border: 1px solid rgba(255, 255, 255, 0.12);
  }
  .pink-blueGrey.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .audit-filter .filter-container.is-floating .form-body {
    background: #303030 !important;
  }
  .pink-blueGrey.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .audit-result {
    background: #424242;
  }
  .pink-blueGrey.dark mat-drawer-container mat-drawer-content.app-container mat-drawer-container mat-drawer-content.app-container .floating-container.UI-V2 .card-1-1.frame-card .audit-detail-container {
    background: #303030 !important;
  }
}
@media (max-width: 599px) {
  .pink-blueGrey.dark app-document-detail .mat-toolbar {
    background: #c51162 !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .pink-blueGrey.dark app-document-detail .mat-toolbar .mat-toolbar-row .toolbar-actions {
    background: #c51162 !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .pink-blueGrey.dark .login-card {
    background: #424242 !important;
  }
  .pink-blueGrey.dark .inbox-toolbar {
    background: #c51162 !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .pink-blueGrey.dark .detail {
    background: #424242 url("../img/spinner_mobile.gif") no-repeat center center !important;
  }
  .pink-blueGrey.dark .toolbar-line-container .toolbar-line:first-child {
    background: #c51162 !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .pink-blueGrey.dark .toolbar-actions {
    background: #c51162 !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .pink-blueGrey.dark mat-drawer.side-nav.enter {
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(255, 255, 255, 0.12) !important;
  }
  .pink-blueGrey.dark .side-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0) !important;
  }
  .pink-blueGrey.dark .filter {
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.55) 16%, rgba(0, 0, 0, 0) 100%) !important;
  }
  .pink-blueGrey.dark button.side-open-mobile {
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .pink-blueGrey.dark .side-nav .side-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0);
  }
  .pink-blueGrey.dark .side-nav .filter {
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.55) 16%, rgba(0, 0, 0, 0) 100%);
  }
  .pink-blueGrey.dark .side-nav .side-nav-container .links {
    background: #424242;
    border-top: 1px solid rgba(255, 255, 255, 0.12);
  }
  .pink-blueGrey.dark .side-nav .side-nav-container .version {
    background: #424242 !important;
    border-top: 1px solid rgba(255, 255, 255, 0.12);
  }
  .pink-blueGrey.dark app-file-document-view .view-container {
    background: #424242 !important;
  }
  .pink-blueGrey.dark app-file-document-view .view-container .side-bar {
    background: #c51162 !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .pink-blueGrey.dark app-file-document-view .view-container .view-content app-pdf-wrapper.floating {
    background: #424242;
  }
  .pink-blueGrey.dark app-file-document-view .view-container .view-content .upload-indications {
    background: black;
  }
  .pink-blueGrey.dark app-file-document-view .view-container .view-content app-file-document-sign .sign-cycle mat-horizontal-stepper.mat-stepper-horizontal {
    background-color: rgba(0, 0, 0, 0) !important;
  }
  .pink-blueGrey.dark app-file-document-view .view-container .view-content app-file-document-sign .sign-cycle .sign-cycle-content .cycle-content-action .mat-ripple-element {
    background-color: rgba(255, 255, 255, 0.1) !important;
  }
  .pink-blueGrey.dark app-pending .floating-container .inbox-container .inbox-toolbar .mat-toolbar-row .mat-avatar h3 {
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .pink-blueGrey.dark app-pending .floating-container .detail {
    background: #424242 url("../img/spinner_mobile.gif") no-repeat center center !important;
  }
  .pink-blueGrey.dark app-document-sign-massive .inbox-container .pending-container app-chapa.minimized .info-empty {
    background: #b0bec5;
  }
  .pink-blueGrey.dark app-document-sign-massive .inbox-container .pending-container app-chapa.minimized .info-empty > span h2 {
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .pink-blueGrey.dark app-document-sign-massive .inbox-container .signer.mobile {
    border-top: 1px solid rgba(255, 255, 255, 0.12);
    background: #424242;
  }
  .pink-blueGrey.dark app-document-sign-massive .detail app-file-document-detail .view-container .mat-toolbar .mat-toolbar-row .view-actions {
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .pink-blueGrey.dark app-add-documentation .floating-container .employee-add-documentation .inbox-container .inbox-toolbar .mat-toolbar-row .mat-avatar h3 {
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .pink-blueGrey.dark app-add-documentation .floating-container .employee-add-documentation .detail {
    background: #424242 url("../img/spinner_mobile.gif") no-repeat center center !important;
  }
  .pink-blueGrey.dark app-add-documentation .floating-container .employee-add-documentation .detail app-file-document-view .view-container .view-content .new-container app-file-document-upload {
    background: #424242;
  }
}
.pink-blueGrey.dark .mat-card .mat-drawer-container {
  background: #424242 !important;
}
.pink-blueGrey.dark .mat-drawer-container {
  background: #212121 !important;
}
.pink-blueGrey.dark .mat-list .mat-list-item.finished-process-inbox.active {
  background: #009688 !important;
}
.pink-blueGrey.dark .mat-list .mat-list-item.finished-process-inbox .mat-icon {
  background: #009688;
}
.pink-blueGrey.dark .mat-list .mat-list-item.erased-process-inbox.active {
  background: #837C80 !important;
}
.pink-blueGrey.dark .mat-list .mat-list-item.limbo-process-inbox.active {
  background: #ffc107 !important;
}
.pink-blueGrey.dark .mat-list .mat-list-item.limbo-process-inbox .mat-icon {
  background: #ffc107;
}
.pink-blueGrey.dark .mat-list .mat-list-item.in-process-inbox.active {
  background: #ffc107 !important;
}
.pink-blueGrey.dark .mat-list .mat-list-item.in-process-inbox .mat-icon {
  background: #ffc107;
}
.pink-blueGrey.dark .mat-list .mat-list-item.error-process-inbox.active {
  background: #e91e63 !important;
}
.pink-blueGrey.dark .mat-list .mat-list-item.error-process-inbox .mat-icon {
  background: #e91e63;
}
.pink-blueGrey.dark .mat-list .mat-list-item.active .mat-line b {
  color: #f48fb1;
}
.pink-blueGrey.dark .mat-list .mat-list-item.active .mat-list-avatar {
  color: #f48fb1;
}
.pink-blueGrey.dark .mat-list .mat-list-item:hover:not(.active) {
  background: rgba(255, 255, 255, 0.04);
}
.pink-blueGrey.dark span.mat-badge-content {
  color: #e57373 !important;
}
.pink-blueGrey.dark mat-expansion-panel.mat-expanded {
  background: #303030 !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark mat-expansion-panel.virtual-scrolling .mat-expansion-panel-content .get-more {
  color: #f48fb1;
}
.pink-blueGrey.dark mat-expansion-panel.virtual-scrolling .mat-expansion-panel-content app-cs-paginator.get-more {
  background: #424242;
}
.pink-blueGrey.dark mat-expansion-panel-header.mat-expanded {
  background: #424242 !important;
}
.pink-blueGrey.dark .bottom-container {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark .load-more * {
  color: #f48fb1;
}
.pink-blueGrey.dark .welcome-card {
  background: #424242 !important;
}
.pink-blueGrey.dark .welcome-card .img-divider {
  background: #c51162;
}
.pink-blueGrey.dark .mat-header-row {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  background: #424242;
}
.pink-blueGrey.dark .mat-row {
  background: #424242;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.pink-blueGrey.dark .mat-row .mat-cell.status-cell mat-icon.pending {
  color: #ffb74d !important;
}
.pink-blueGrey.dark .mat-row .mat-cell.status-cell mat-icon.ok {
  color: #4db6ac !important;
}
.pink-blueGrey.dark .mat-row .mat-cell.status-cell mat-icon.not-ok {
  color: #ba68c8 !important;
}
.pink-blueGrey.dark .mat-row .mat-cell.status-cell mat-icon.cancelled {
  color: #795548 !important;
}
.pink-blueGrey.dark .mat-row .mat-cell.status-cell mat-icon.rejected {
  color: #df4d4c !important;
}
.pink-blueGrey.dark .mat-row .mat-cell.status-cell mat-icon.draft {
  color: #dedede !important;
}
.pink-blueGrey.dark .mat-row.active, .pink-blueGrey.dark .mat-row.active:hover {
  background: #f48fb1 !important;
}
.pink-blueGrey.dark .mat-row.active div, .pink-blueGrey.dark .mat-row.active:hover div {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark .mat-row.active .mat-cell.status-cell mat-icon.pending, .pink-blueGrey.dark .mat-row.active .mat-cell.status-cell mat-icon.ok, .pink-blueGrey.dark .mat-row.active .mat-cell.status-cell mat-icon.not-ok, .pink-blueGrey.dark .mat-row.active:hover .mat-cell.status-cell mat-icon.pending, .pink-blueGrey.dark .mat-row.active:hover .mat-cell.status-cell mat-icon.ok, .pink-blueGrey.dark .mat-row.active:hover .mat-cell.status-cell mat-icon.not-ok {
  text-shadow: -1px 1px 0 rgba(0, 0, 0, 0.87), 1px 1px 0 rgba(0, 0, 0, 0.87), 1px -1px 0 rgba(0, 0, 0, 0.87), -1px -1px 0 rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark .mat-row.active .sticky-actions button, .pink-blueGrey.dark .mat-row.active:hover .sticky-actions button {
  background: #f48fb1 !important;
}
.pink-blueGrey.dark .mat-row.active .sticky-actions button.ok mat-icon, .pink-blueGrey.dark .mat-row.active .sticky-actions button.not-ok mat-icon, .pink-blueGrey.dark .mat-row.active:hover .sticky-actions button.ok mat-icon, .pink-blueGrey.dark .mat-row.active:hover .sticky-actions button.not-ok mat-icon {
  text-shadow: -1px 1px 0 rgba(0, 0, 0, 0.87), 1px 1px 0 rgba(0, 0, 0, 0.87), 1px -1px 0 rgba(0, 0, 0, 0.87), -1px -1px 0 rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark .mat-row:nth-child(odd) {
  background: #303030;
}
.pink-blueGrey.dark .mat-row.selected div {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark .mat-row.selected:nth-child(even) {
  background: #f48fb1 !important;
}
.pink-blueGrey.dark .mat-row.selected:nth-child(even) .sticky-actions button {
  background: #f48fb1 !important;
}
.pink-blueGrey.dark .mat-row.selected:nth-child(odd) {
  background: #f8bbd0 !important;
}
.pink-blueGrey.dark .mat-row.selected:nth-child(odd) .sticky-actions button {
  background: #f8bbd0 !important;
}
.pink-blueGrey.dark .mat-row.selected.active, .pink-blueGrey.dark .mat-row.selected.active:hover {
  background: #f48fb1 !important;
}
.pink-blueGrey.dark .mat-row.selected.active .sticky-actions button, .pink-blueGrey.dark .mat-row.selected.active:hover .sticky-actions button {
  background: #f48fb1 !important;
}
.pink-blueGrey.dark .mat-row:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-blueGrey.dark .mat-row:hover .sticky-actions button {
  background: #424242 !important;
}
.pink-blueGrey.dark .mat-row.canceled * {
  color: #795548;
}
.pink-blueGrey.dark .mat-row.disabled div {
  color: #607D8B;
}
.pink-blueGrey.dark .mat-row.disabled.active, .pink-blueGrey.dark .mat-row.disabled.active:hover {
  background: #607D8B !important;
}
.pink-blueGrey.dark .mat-row.disabled.active div, .pink-blueGrey.dark .mat-row.disabled.active:hover div {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark .mat-row.disabled.active .sticky-actions button, .pink-blueGrey.dark .mat-row.disabled.active:hover .sticky-actions button {
  background: #607D8B !important;
}
.pink-blueGrey.dark .mat-row.disabled.selected.active:hover .sticky-actions button {
  background: #607D8B !important;
}
.pink-blueGrey.dark .mat-row .sticky-actions button.ok mat-icon {
  color: #4db6ac !important;
}
.pink-blueGrey.dark .mat-row .sticky-actions button.not-ok mat-icon {
  color: #ba68c8 !important;
}
.pink-blueGrey.dark .status-table .mat-header-row {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  background: #424242;
}
.pink-blueGrey.dark .icon-info-off,
.pink-blueGrey.dark .certificate-off {
  color: #607D8B;
}
.pink-blueGrey.dark .documents-search-table .mat-header-row {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  background: #424242;
}
.pink-blueGrey.dark mat-toolbar.employee-search-sub-toolbar {
  background: #424242;
}
.pink-blueGrey.dark .mat-search-field {
  border: 1px solid rgba(255, 255, 255, 0.12);
  background: black;
}
.pink-blueGrey.dark .mat-search-field input[type=search] {
  background: rgba(0, 0, 0, 0);
}
.pink-blueGrey.dark .mat-search-field input[type=search]::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.pink-blueGrey.dark .search-adv-menu .mat-search-field {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark mat-toolbar.document-search-sub-toolbar {
  background: #424242;
}
.pink-blueGrey.dark app-advanced-employee-search .mat-chip {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-blueGrey.dark .side-nav .side-trigger {
  background: #424242;
}
.pink-blueGrey.dark .side-nav .menu-trigger mat-icon {
  color: rgba(255, 255, 255, 0.7);
  background: #424242;
}
.pink-blueGrey.dark .side-nav .side-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0);
}
.pink-blueGrey.dark .side-nav .filter {
  background: #424242;
}
.pink-blueGrey.dark .side-nav .side-nav-container .links mat-icon span.mat-badge-content {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-blueGrey.dark .side-nav .side-nav-container .mat-list-base .mat-list-item:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-blueGrey.dark .side-nav .side-nav-container .mat-list-base .mat-list-item.active {
  background: #f48fb1 !important;
}
.pink-blueGrey.dark .side-nav .side-nav-container .mat-list-base .mat-list-item.active * {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark .side-nav .side-nav-container .mat-list-base .mat-list-item.active .mat-list-item.active:hover {
  background: #f48fb1 !important;
}
.pink-blueGrey.dark .side-nav .side-nav-container .mat-list-base .mat-list-item.active .mat-list-item.active:hover * {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark .side-nav.open .side-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark .side-nav.open .filter {
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.55) 16%, rgba(0, 0, 0, 0) 100%);
}
.pink-blueGrey.dark .side-nav.open .side-nav-container .links {
  background: #424242;
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark .side-nav.open .side-nav-container .version {
  background: #424242 !important;
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark .side-nav.enter .side-nav-container .links {
  background: #424242;
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark .side-nav.enter .side-nav-container .version {
  background: #424242 !important;
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark .mat-menu-panel:not(.metadata-radio-menu) .navigation-list .mat-list-item-content {
  color: rgba(255, 255, 255, 0.7);
}
.pink-blueGrey.dark table.mat-calendar-table {
  background: #424242 !important;
}
.pink-blueGrey.dark .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-blueGrey.dark .mat-calendar-arrow {
  border-top-color: #424242 !important;
}
.pink-blueGrey.dark .mat-datepicker-content .mat-calendar-next-button,
.pink-blueGrey.dark .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-blueGrey.dark .mat-calendar-header {
  background: #c51162 !important;
}
.pink-blueGrey.dark mat-datepicker-content {
  background-color: #c51162 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-blueGrey.dark .mat-calendar-content {
  background: #424242 !important;
}
.pink-blueGrey.dark .mat-simple-snackbar-action button.mat-button {
  color: #ff9800;
}
.pink-blueGrey.dark .mat-simple-snackbar-action .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.pink-blueGrey.dark mat-bottom-sheet-container mat-toolbar {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12) !important;
}
.pink-blueGrey.dark app-generic-bottom-sheet h4.accent {
  color: #b0bec5;
}
.pink-blueGrey.dark app-generic-bottom-sheet .verification-code {
  background: #f48fb1;
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark app-generic-bottom-sheet .verification-code h2 {
  background: #f48fb1;
}
.pink-blueGrey.dark app-generic-bottom-sheet .load-window.message .info-container h3.primary-text {
  color: #f48fb1;
}
.pink-blueGrey.dark app-generic-bottom-sheet .bottom_sheet-content.ok .bottom_sheet-title .bottom_sheet-icon,
.pink-blueGrey.dark app-generic-bottom-sheet .bottom_sheet-content.ok .bottom_sheet-title h3 {
  color: #009688;
}
.pink-blueGrey.dark app-generic-bottom-sheet .bottom_sheet-content.ok .bottom_sheet-main_btn:not(:disabled) {
  background: #009688;
  color: #fff;
}
.pink-blueGrey.dark app-generic-bottom-sheet .bottom_sheet-content.not-ok .bottom_sheet-title .bottom_sheet-icon,
.pink-blueGrey.dark app-generic-bottom-sheet .bottom_sheet-content.not-ok .bottom_sheet-title h3 {
  color: #9C27B0;
}
.pink-blueGrey.dark app-generic-bottom-sheet .bottom_sheet-content.not-ok .bottom_sheet-main_btn:not(:disabled) {
  background: #9C27B0;
  color: #fff;
}
.pink-blueGrey.dark app-generic-bottom-sheet .bottom_sheet-content.cancel .bottom_sheet-title .bottom_sheet-icon,
.pink-blueGrey.dark app-generic-bottom-sheet .bottom_sheet-content.cancel .bottom_sheet-title h3 {
  color: #795548;
}
.pink-blueGrey.dark app-generic-bottom-sheet .bottom_sheet-content.cancel .bottom_sheet-main_btn:not(:disabled) {
  background: #795548;
  color: #fff;
}
.pink-blueGrey.dark app-generic-bottom-sheet .bottom_sheet-content .bottom_sheet_exp_btn {
  background-color: #f48fb1;
  color: #fff;
}
.pink-blueGrey.dark app-generic-bottom-sheet .bottom_sheet-content .bottom_sheet_canc_btn {
  background-color: lightgrey;
  color: black;
}
.pink-blueGrey.dark app-generic-bottom-sheet .bottom_sheet-content .bottom_sheet-exp-h3 {
  color: #f48fb1;
  margin-bottom: -10px !important;
}
.pink-blueGrey.dark app-generic-bottom-sheet .bottom_sheet-content .mat-input-element-export {
  display: flex !important;
  padding-left: 15px;
}
.pink-blueGrey.dark app-generic-bottom-sheet .bottom_sheet-content .mat-form-field-infix-export-right {
  width: 100% !important;
  padding: 0px !important;
  display: flex !important;
}
.pink-blueGrey.dark app-generic-bottom-sheet .bottom_sheet-content .mat-form-field-infix-export-left {
  width: 100% !important;
  padding: 0px;
  display: flex !important;
}
.pink-blueGrey.dark app-generic-bottom-sheet .bottom_sheet-content .mat-form-field-infix {
  display: flex;
}
.pink-blueGrey.dark app-generic-bottom-sheet .bottom_sheet-content .mat-checkbox-layout .mat-checkbox-label {
  color: #b0bec5;
}
.pink-blueGrey.dark app-generic-bottom-sheet .bottom_sheet-content .choose-forms {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  margin-left: 10px !important;
}
.pink-blueGrey.dark app-generic-bottom-sheet .bottom_sheet-content .card-container {
  max-height: 150px;
  overflow-y: auto;
}
.pink-blueGrey.dark .mat-list .mat-list-item.active .mat-line {
  color: white;
}
.pink-blueGrey.dark .mat-list .mat-list-item.active .mat-line b {
  color: #f48fb1;
}
.pink-blueGrey.dark .mat-list .mat-list-item.active .mat-list-avatar {
  color: #f48fb1;
}
.pink-blueGrey.dark .mat-list .mat-list-item .mat-line {
  color: rgba(255, 255, 255, 0.7);
}
.pink-blueGrey.dark .mat-list .mat-list-item .mat-list-avatar {
  color: rgba(255, 255, 255, 0.7);
}
.pink-blueGrey.dark .mat-list-item:hover:not(.active) {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-blueGrey.dark mat-list-item:nth-child(even),
.pink-blueGrey.dark app-file-document-inbox-item:nth-child(even) mat-list-item,
.pink-blueGrey.dark app-file-document-inbox-item-documentation:nth-child(even) mat-list-item {
  background: #303030 !important;
}
.pink-blueGrey.dark mat-list-item:nth-child(odd),
.pink-blueGrey.dark app-file-document-inbox-item:nth-child(odd) mat-list-item,
.pink-blueGrey.dark app-file-document-inbox-item-documentation:nth-child(odd) mat-list-item {
  background: #424242 !important;
}
.pink-blueGrey.dark .mat-list-avatar.mat-icon {
  background: black;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark .card-filter {
  background: #424242;
}
.pink-blueGrey.dark .login mat-horizontal-stepper {
  background: #424242 !important;
}
.pink-blueGrey.dark .login .login-card {
  background: #424242 !important;
}
.pink-blueGrey.dark .login .login-card .mat-card-content .mat-card-footer a[color=accent] {
  color: #b0bec5;
}
.pink-blueGrey.dark .login .help-stepper h3.align-16 {
  color: #b0bec5;
}
.pink-blueGrey.dark .login .help-stepper .help-options mat-icon {
  color: #b0bec5;
}
.pink-blueGrey.dark .ux-modal {
  background: rgba(0, 0, 0, 0.32);
}
.pink-blueGrey.dark .ux-modal .card {
  background: #424242;
}
.pink-blueGrey.dark .main-title {
  background: #607D8B;
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark .sub-title {
  color: rgba(255, 255, 255, 0.7);
}
.pink-blueGrey.dark .card-footer a {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark .profile-toolbar {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark app-profile .logout-btn:hover {
  background: black;
}
.pink-blueGrey.dark app-profile .cropper {
  background: #424242;
}
.pink-blueGrey.dark app-profile .cropper .cropper {
  background: unset;
}
.pink-blueGrey.dark app-add-documentation .employee-add-documentation .inbox-toolbar {
  background: #303030;
}
.pink-blueGrey.dark app-add-documentation .employee-add-documentation .inbox-toolbar .toolbar-line mat-icon {
  color: #f48fb1;
}
.pink-blueGrey.dark app-add-documentation .employee-add-documentation .inbox-toolbar .toolbar-line h3 {
  color: #f48fb1;
}
.pink-blueGrey.dark app-add-documentation .employee-add-documentation mat-tab-group {
  background: #303030;
}
.pink-blueGrey.dark app-add-documentation .employee-add-documentation mat-tab-group mat-tab-header .mat-tab-label-active {
  color: #f48fb1;
}
.pink-blueGrey.dark app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content:hover {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(255, 255, 255, 0.12) !important;
}
.pink-blueGrey.dark app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content.selected {
  border-left: 6px solid #f48fb1 !important;
}
.pink-blueGrey.dark app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content.selected mat-icon {
  color: #f48fb1 !important;
}
.pink-blueGrey.dark app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content.selected .item-data {
  color: white !important;
}
.pink-blueGrey.dark app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content mat-icon {
  color: rgba(255, 255, 255, 0.7);
}
.pink-blueGrey.dark app-add-documentation .employee-add-documentation mat-tab-group .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .pending-container .pending-items .pending-item .content .item-data {
  color: rgba(255, 255, 255, 0.7);
}
.pink-blueGrey.dark app-add-documentation .employee-add-documentation .detail app-file-document-view .new-container app-file-document-upload .metadata-list .mat-subheader {
  color: rgba(255, 255, 255, 0.7);
  background: #424242 !important;
}
.pink-blueGrey.dark app-pending .inbox-toolbar {
  background: #303030;
}
.pink-blueGrey.dark app-pending .pending-container {
  background: #303030;
}
.pink-blueGrey.dark app-pending .pending-container .pending-items .pending-item .content:hover {
  background: rgba(255, 255, 255, 0.04) !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(255, 255, 255, 0.12) !important;
}
.pink-blueGrey.dark app-pending .pending-container .pending-items .pending-item .content.selected {
  border-left: 6px solid #b0bec5 !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(255, 255, 255, 0.12) !important;
}
.pink-blueGrey.dark app-pending .pending-container .pending-items .pending-item .content.selected mat-icon {
  color: #b0bec5 !important;
}
.pink-blueGrey.dark app-pending .pending-container .pending-items .pending-item .content.selected .item-data {
  color: white !important;
}
.pink-blueGrey.dark app-pending .pending-container .pending-items .pending-item .content mat-icon {
  color: rgba(255, 255, 255, 0.7);
}
.pink-blueGrey.dark app-pending .pending-container .pending-items .pending-item .content .item-data {
  color: rgba(255, 255, 255, 0.7);
}
.pink-blueGrey.dark app-document-sign-massive .inbox-container .inbox-toolbar {
  background: #303030;
}
.pink-blueGrey.dark app-document-sign-massive .inbox-container .pending-container {
  background: #303030;
}
.pink-blueGrey.dark app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content:hover {
  background: rgba(255, 255, 255, 0.04) !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(255, 255, 255, 0.12) !important;
}
.pink-blueGrey.dark app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content.selected {
  border-left: 6px solid #b0bec5 !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(255, 255, 255, 0.12) !important;
}
.pink-blueGrey.dark app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content.selected mat-icon {
  color: #b0bec5 !important;
}
.pink-blueGrey.dark app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content.selected .item-data {
  color: white !important;
}
.pink-blueGrey.dark app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content mat-icon {
  color: rgba(255, 255, 255, 0.7);
}
.pink-blueGrey.dark app-document-sign-massive .inbox-container .pending-container .pending-items .pending-item .content .item-data {
  color: rgba(255, 255, 255, 0.7);
}
.pink-blueGrey.dark app-document-sign-massive .detail app-file-document-detail .view-container {
  background: #607D8B;
}
.pink-blueGrey.dark app-document-sign-massive .detail app-file-document-detail .view-container .mat-toolbar {
  background: #607D8B;
  color: rgba(0, 0, 0, 0.87);
  background: #607D8B;
}
.pink-blueGrey.dark app-document-sign-massive .detail app-chapa.minimized .info-empty {
  background: #b0bec5;
}
.pink-blueGrey.dark app-document-sign-massive .detail app-chapa.minimized .info-empty > span mat-icon {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-blueGrey.dark app-document-sign-massive .detail app-chapa.minimized .info-empty > span h2 {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-blueGrey.dark app-document-sign-massive .signer.desktop {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark app-document-sign-massive .signer .signer-btn {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-blueGrey.dark app-document-sign-massive .signer .signer-btn[disabled] {
  background-color: rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark app-document-detail app-sign-document .firmador button.mat-raised-button.mat-accent {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-blueGrey.dark mat-toolbar-row.firmado-conforme .mat-icon {
  background: #4caf50;
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark mat-toolbar-row.firmado-no-conforme .mat-icon {
  background: #e57373;
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark .secondary-toolbar {
  background: #424242;
}
.pink-blueGrey.dark .new-detail-container {
  border-left: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark .new-detail-container.cancelled .detail_head-body {
  background: #607D8B !important;
}
.pink-blueGrey.dark .new-detail-container .employee-detail-toolbar {
  background: black;
}
.pink-blueGrey.dark .new-detail-container .employee-detail-subbar {
  background: #424242;
}
.pink-blueGrey.dark app-employee-detail .load-window,
.pink-blueGrey.dark app-employee-rrhh-detail .load-window {
  background: #303030;
}
.pink-blueGrey.dark app-employee-detail .employee_detail-container,
.pink-blueGrey.dark app-employee-rrhh-detail .employee_detail-container {
  background: #303030;
}
.pink-blueGrey.dark app-employee-detail .employee_detail-container.is-editing .edition-line,
.pink-blueGrey.dark app-employee-rrhh-detail .employee_detail-container.is-editing .edition-line {
  background: black;
}
.pink-blueGrey.dark app-employee-detail .employee_detail-container .detail_head-body,
.pink-blueGrey.dark app-employee-rrhh-detail .employee_detail-container .detail_head-body {
  background: #f48fb1;
}
.pink-blueGrey.dark app-employee-detail .employee_detail-container .detail_head-body .head_body-status .icon-stacked + mat-icon,
.pink-blueGrey.dark app-employee-rrhh-detail .employee_detail-container .detail_head-body .head_body-status .icon-stacked + mat-icon {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-blueGrey.dark app-employee-detail .employee_detail-container .detail_head-body .head_body-title .body_title-name,
.pink-blueGrey.dark app-employee-rrhh-detail .employee_detail-container .detail_head-body .head_body-title .body_title-name {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark app-employee-detail .employee_detail-container .detail_head-body .head_body-title .body_title-user,
.pink-blueGrey.dark app-employee-rrhh-detail .employee_detail-container .detail_head-body .head_body-title .body_title-user {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark app-employee-detail .employee_detail-container .data .data-line,
.pink-blueGrey.dark app-employee-rrhh-detail .employee_detail-container .data .data-line {
  background: #424242;
}
.pink-blueGrey.dark app-employee-detail .employee_detail-container .data .data-line .icon-complex mat-icon.complex,
.pink-blueGrey.dark app-employee-rrhh-detail .employee_detail-container .data .data-line .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #424242, 0 -2px 0px #424242, 2px 0 0px #424242, 0 2px 0px #424242, -2px -2px 0px #424242, 2px 2px 0px #424242, 2px -2px 0px #424242, -2px 2px 0px #424242;
}
.pink-blueGrey.dark app-employee-detail .employee_detail-container .data .data-line .line_text-main,
.pink-blueGrey.dark app-employee-rrhh-detail .employee_detail-container .data .data-line .line_text-main {
  color: #f48fb1;
}
.pink-blueGrey.dark app-employee-detail .employee_detail-container .data .data-line.clickable:hover,
.pink-blueGrey.dark app-employee-rrhh-detail .employee_detail-container .data .data-line.clickable:hover {
  background: #303030 !important;
}
.pink-blueGrey.dark app-employee-detail .employee_detail-container .data .data-line button.mat-icon-button,
.pink-blueGrey.dark app-employee-rrhh-detail .employee_detail-container .data .data-line button.mat-icon-button {
  background: black;
}
.pink-blueGrey.dark app-employee-detail .employee_detail-container .employee-title .title-text,
.pink-blueGrey.dark app-employee-rrhh-detail .employee_detail-container .employee-title .title-text {
  color: #f48fb1;
}
.pink-blueGrey.dark app-employee-detail .employee_detail-container .employee-title .title-text mat-icon,
.pink-blueGrey.dark app-employee-rrhh-detail .employee_detail-container .employee-title .title-text mat-icon {
  color: #f48fb1;
}
.pink-blueGrey.dark app-employee-detail .employee_detail-container .employee-title .title-text .title_text-main,
.pink-blueGrey.dark app-employee-rrhh-detail .employee_detail-container .employee-title .title-text .title_text-main {
  color: #f48fb1;
}
.pink-blueGrey.dark .UI-V2 .icon-complex mat-icon.complex {
  color: #f48fb1;
  text-shadow: -2px 0 0px #424242, 0 -2px 0px #424242, 2px 0 0px #424242, 0 2px 0px #424242, -2px -2px 0px #424242, 2px 2px 0px #424242, 2px -2px 0px #424242, -2px 2px 0px #424242;
}
.pink-blueGrey.dark .UI-V2 .icon-complex mat-icon.complex.mat-accent {
  color: #b0bec5;
}
.pink-blueGrey.dark .UI-V2 .main .toolbar .header .content .module-title {
  color: #f48fb1;
}
.pink-blueGrey.dark .UI-V2 .main .toolbar .header .content .module-title.selectable:hover {
  background: rgba(255, 255, 255, 0.04);
}
.pink-blueGrey.dark .UI-V2 .main .toolbar .header .content .module-title .info h5 {
  color: white !important;
}
.pink-blueGrey.dark .UI-V2 .main .toolbar .header .content .ou-select {
  color: rgba(0, 0, 0, 0.87);
  background: #f48fb1;
}
.pink-blueGrey.dark .UI-V2 .main .toolbar .search-container .search .content {
  background: #303030;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark .UI-V2 .main .toolbar .search-container .search .content:hover, .pink-blueGrey.dark .UI-V2 .main .toolbar .search-container .search .content:active, .pink-blueGrey.dark .UI-V2 .main .toolbar .search-container .search .content:focus, .pink-blueGrey.dark .UI-V2 .main .toolbar .search-container .search .content:focus-within {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-blueGrey.dark .UI-V2 .main .toolbar .search-container .search .content input {
  color: white;
}
.pink-blueGrey.dark .UI-V2 .main .helper {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark .UI-V2 .main .helper mat-hint h4 {
  color: #f48fb1;
}
.pink-blueGrey.dark .UI-V2 .main .new-table app-cs-grid-control datatable-header * {
  color: #f48fb1;
}
.pink-blueGrey.dark .UI-V2 .main .new-table .mat-header-row.sticky * {
  color: #f48fb1 !important;
}
.pink-blueGrey.dark .UI-V2 .main .main-action {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark .menu-options.help .primary {
  color: #f48fb1;
}
.pink-blueGrey.dark .menu-options.help .accent {
  color: #b0bec5;
}
.pink-blueGrey.dark .menu-options .icon-complex mat-icon.complex {
  color: #f48fb1;
  text-shadow: -2px 0 0px #424242, 0 -2px 0px #424242, 2px 0 0px #424242, 0 2px 0px #424242, -2px -2px 0px #424242, 2px 2px 0px #424242, 2px -2px 0px #424242, -2px 2px 0px #424242;
}
.pink-blueGrey.dark .menu-options .mat-menu-content .container mat-list .mat-list-item:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-blueGrey.dark .menu-options .mat-menu-content .container mat-list .mat-list-item .disabled {
  color: rgba(255, 255, 255, 0.7) !important;
}
.pink-blueGrey.dark .menu-options .mat-menu-content .container .slide-group mat-radio-group mat-radio-button:hover {
  background: rgba(255, 255, 255, 0.04);
}
.pink-blueGrey.dark .menu-options .mat-menu-content .container .slide-group mat-form-field .mat-form-field-flex {
  background: #303030;
}
.pink-blueGrey.dark .menu-options .mat-menu-content .container .slide-group mat-slide-toggle:hover {
  background: rgba(255, 255, 255, 0.04);
}
.pink-blueGrey.dark .mat-menu-panel.notifications-panel .mat-menu-content .notifications-body {
  background: #303030;
}
.pink-blueGrey.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification .primary mat-icon {
  color: #f48fb1;
}
.pink-blueGrey.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification .primary h3 {
  color: #f48fb1;
}
.pink-blueGrey.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.top {
  background: #303030;
}
.pink-blueGrey.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.top .header h3 {
  color: white;
}
.pink-blueGrey.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.bottom {
  background: #303030;
}
.pink-blueGrey.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable {
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable:nth-child(even) {
  background: #303030;
}
.pink-blueGrey.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable:nth-child(odd) {
  background: #424242;
}
.pink-blueGrey.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable:hover {
  background: rgba(255, 255, 255, 0.04);
}
.pink-blueGrey.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable mat-icon {
  color: #f48fb1;
}
.pink-blueGrey.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable h3 {
  color: white;
}
.pink-blueGrey.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable h5 {
  color: rgba(255, 255, 255, 0.5);
}
.pink-blueGrey.dark .mat-menu-panel.notifications-panel .mat-menu-content .notification.selectable .subtitle h5 {
  color: #f48fb1;
  background: black;
}
.pink-blueGrey.dark .info-empty .large-icon {
  background: #f06292;
  color: #f48fb1;
}
.pink-blueGrey.dark .info-empty h2,
.pink-blueGrey.dark .info-empty h3 {
  color: #f48fb1;
}
.pink-blueGrey.dark .info-empty h4,
.pink-blueGrey.dark .info-empty h5 {
  color: rgba(255, 255, 255, 0.7);
}
.pink-blueGrey.dark .info-empty.accent mat-icon {
  background: #b0bec5;
  color: #b0bec5;
}
.pink-blueGrey.dark .info-empty.accent h2 {
  color: #b0bec5;
}
.pink-blueGrey.dark .info-empty .actions {
  padding: 16px;
  height: 96px;
  column-gap: 16px;
}
.pink-blueGrey.dark .info-empty .actions button {
  height: 100%;
  border-radius: 40px;
}
.pink-blueGrey.dark .info-empty .actions button.ok {
  background: #4db6ac !important;
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark .info-empty .actions button.not-ok {
  color: #ba68c8 !important;
}
.pink-blueGrey.dark .side.edition .info-empty .large-icon {
  background: #b0bec5;
  color: #b0bec5;
}
.pink-blueGrey.dark .side.edition .info-empty h2 {
  color: #b0bec5;
}
.pink-blueGrey.dark .side.edition .info-empty h4 {
  color: rgba(255, 255, 255, 0.7);
}
.pink-blueGrey.dark .UI-V2 .deferred-process-list .header h3 {
  color: #f48fb1;
}
.pink-blueGrey.dark .UI-V2 .deferred-process-list .search-container .search .content {
  background: #303030;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark .UI-V2 .deferred-process-list .search-container .search .content:hover, .pink-blueGrey.dark .UI-V2 .deferred-process-list .search-container .search .content:active, .pink-blueGrey.dark .UI-V2 .deferred-process-list .search-container .search .content:focus, .pink-blueGrey.dark .UI-V2 .deferred-process-list .search-container .search .content:focus-within {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-blueGrey.dark .UI-V2 .deferred-process-list .search-container .search .content input {
  color: white;
}
.pink-blueGrey.dark .UI-V2 .deferred-process-list .text-success,
.pink-blueGrey.dark .UI-V2 .deferred-process-details .text-success {
  color: #4caf50;
}
.pink-blueGrey.dark .UI-V2 .deferred-process-list .text-warn,
.pink-blueGrey.dark .UI-V2 .deferred-process-details .text-warn {
  color: #e57373;
}
.pink-blueGrey.dark .UI-V2 .deferred-process-list .text-primary,
.pink-blueGrey.dark .UI-V2 .deferred-process-details .text-primary {
  color: #f48fb1;
}
.pink-blueGrey.dark .UI-V2 .deferred-process-list .text-atention,
.pink-blueGrey.dark .UI-V2 .deferred-process-details .text-atention {
  color: #DB7703;
}
.pink-blueGrey.dark .UI-V2 .deferred-process-list .table-paginator-container,
.pink-blueGrey.dark .UI-V2 .deferred-process-details .table-paginator-container {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
}
.pink-blueGrey.dark .UI-V2 .deferred-process-list .table-paginator-container .mat-table,
.pink-blueGrey.dark .UI-V2 .deferred-process-details .table-paginator-container .mat-table {
  border-bottom: none;
  border-radius: 10px 10px 0 0;
}
.pink-blueGrey.dark .UI-V2 .deferred-process-list .table-paginator-container .paginator-container,
.pink-blueGrey.dark .UI-V2 .deferred-process-details .table-paginator-container .paginator-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: #f2f2f2;
  padding: 0 1.5rem;
  height: 3rem;
}
.pink-blueGrey.dark .UI-V2 .deferred-process-list .mat-table,
.pink-blueGrey.dark .UI-V2 .deferred-process-details .mat-table {
  border: 1px solid #f2f2f2;
  border-radius: 10px;
  overflow: hidden;
}
.pink-blueGrey.dark .UI-V2 .deferred-process-list .mat-table .mat-header-row .mat-header-cell,
.pink-blueGrey.dark .UI-V2 .deferred-process-details .mat-table .mat-header-row .mat-header-cell {
  font-size: 14px;
  font-weight: 700;
}
.pink-blueGrey.dark .UI-V2 .deferred-process-list .mat-table .mat-header-row,
.pink-blueGrey.dark .UI-V2 .deferred-process-details .mat-table .mat-header-row {
  background: #f2f2f2;
}
.pink-blueGrey.dark .UI-V2 .deferred-process-list .mat-table .mat-header-row .mat-header-cell,
.pink-blueGrey.dark .UI-V2 .deferred-process-details .mat-table .mat-header-row .mat-header-cell {
  color: #f48fb1;
}
.pink-blueGrey.dark .UI-V2 .deferred-process-list .mat-table .mat-row,
.pink-blueGrey.dark .UI-V2 .deferred-process-details .mat-table .mat-row {
  background: #424242;
}
.pink-blueGrey.dark .UI-V2 .progress-bar-container {
  position: relative;
}
.pink-blueGrey.dark .UI-V2 .deferred-process-details .header h3 {
  color: #f48fb1;
}
.pink-blueGrey.dark .UI-V2 .deferred-process-details .header .back-link:visited {
  color: #f48fb1;
}
.pink-blueGrey.dark .process-card,
.pink-blueGrey.dark .process-card-detail,
.pink-blueGrey.dark .process-card-stages {
  background: #303030;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark .process-card mat-card-subtitle,
.pink-blueGrey.dark .process-card-detail mat-card-subtitle,
.pink-blueGrey.dark .process-card-stages mat-card-subtitle {
  color: white;
}
.pink-blueGrey.dark .process-card .stages-container .stage.completed mat-icon,
.pink-blueGrey.dark .process-card-detail .stages-container .stage.completed mat-icon,
.pink-blueGrey.dark .process-card-stages .stages-container .stage.completed mat-icon {
  color: #499719;
}
.pink-blueGrey.dark .process-card .stages-container .stage.completed .icon-container,
.pink-blueGrey.dark .process-card-detail .stages-container .stage.completed .icon-container,
.pink-blueGrey.dark .process-card-stages .stages-container .stage.completed .icon-container {
  background-color: rgba(78, 160, 28, 0.1490196078);
}
.pink-blueGrey.dark .process-card .stages-container .stage.completed .line,
.pink-blueGrey.dark .process-card-detail .stages-container .stage.completed .line,
.pink-blueGrey.dark .process-card-stages .stages-container .stage.completed .line {
  background-color: #499719;
}
.pink-blueGrey.dark .process-card .stages-container .stage.on-going mat-icon,
.pink-blueGrey.dark .process-card-detail .stages-container .stage.on-going mat-icon,
.pink-blueGrey.dark .process-card-stages .stages-container .stage.on-going mat-icon {
  color: #f48fb1;
}
.pink-blueGrey.dark .process-card .stages-container .stage.on-going .icon-container,
.pink-blueGrey.dark .process-card-detail .stages-container .stage.on-going .icon-container,
.pink-blueGrey.dark .process-card-stages .stages-container .stage.on-going .icon-container {
  background-color: rgba(66, 48, 159, 0.2);
}
.pink-blueGrey.dark .process-card .stages-container .stage.on-going .line,
.pink-blueGrey.dark .process-card-detail .stages-container .stage.on-going .line,
.pink-blueGrey.dark .process-card-stages .stages-container .stage.on-going .line {
  background-color: #949494;
}
.pink-blueGrey.dark .process-card .stages-container .stage.pending,
.pink-blueGrey.dark .process-card-detail .stages-container .stage.pending,
.pink-blueGrey.dark .process-card-stages .stages-container .stage.pending {
  color: #949494;
}
.pink-blueGrey.dark .process-card .stages-container .stage.pending mat-icon,
.pink-blueGrey.dark .process-card-detail .stages-container .stage.pending mat-icon,
.pink-blueGrey.dark .process-card-stages .stages-container .stage.pending mat-icon {
  color: #949494;
}
.pink-blueGrey.dark .process-card .stages-container .stage.pending .icon-container,
.pink-blueGrey.dark .process-card-detail .stages-container .stage.pending .icon-container,
.pink-blueGrey.dark .process-card-stages .stages-container .stage.pending .icon-container {
  background-color: rgba(174, 174, 174, 0.2);
}
.pink-blueGrey.dark .process-card .stages-container .stage.pending .line,
.pink-blueGrey.dark .process-card-detail .stages-container .stage.pending .line,
.pink-blueGrey.dark .process-card-stages .stages-container .stage.pending .line {
  background-color: #949494;
}
.pink-blueGrey.dark .process-card .stages-container .stage.has-error mat-icon,
.pink-blueGrey.dark .process-card-detail .stages-container .stage.has-error mat-icon,
.pink-blueGrey.dark .process-card-stages .stages-container .stage.has-error mat-icon {
  color: #e57373;
}
.pink-blueGrey.dark .process-card .stages-container .stage.has-error .icon-container,
.pink-blueGrey.dark .process-card-detail .stages-container .stage.has-error .icon-container,
.pink-blueGrey.dark .process-card-stages .stages-container .stage.has-error .icon-container {
  background-color: rgba(188, 4, 4, 0.2);
}
.pink-blueGrey.dark .process-card .stages-container .stage-description .button-link,
.pink-blueGrey.dark .process-card-detail .stages-container .stage-description .button-link,
.pink-blueGrey.dark .process-card-stages .stages-container .stage-description .button-link {
  color: #f48fb1;
}
.pink-blueGrey.dark app-custom-search-input .custom-search-container {
  background: #303030;
  border: 1px solid rgba(255, 255, 255, 0.12);
  transition: color 0.1s ease, background 0.15s ease;
}
.pink-blueGrey.dark app-custom-search-input .custom-search-container mat-icon.mat-icon {
  color: rgba(255, 255, 255, 0.5);
}
.pink-blueGrey.dark app-custom-search-input .custom-search-container input.custom-search-input {
  color: white;
  background: unset;
}
.pink-blueGrey.dark app-custom-search-input .custom-search-container:hover, .pink-blueGrey.dark app-custom-search-input .custom-search-container:active, .pink-blueGrey.dark app-custom-search-input .custom-search-container:focus, .pink-blueGrey.dark app-custom-search-input .custom-search-container:focus-within {
  background: rgba(255, 255, 255, 0.04) !important;
  transition: background 0.15s ease;
}
.pink-blueGrey.dark .UI-V2 .audit-filter {
  background: #424242;
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark .UI-V2 .audit-filter .toolbar {
  background: #f48fb1;
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark .UI-V2 .audit-filter .toolbar .header .content .module-title {
  color: #f48fb1;
}
.pink-blueGrey.dark .UI-V2 .audit-filter .toolbar .header .content .module-title.selectable:hover {
  background: rgba(255, 255, 255, 0.04);
}
.pink-blueGrey.dark .UI-V2 .audit-filter .toolbar .header .content .module-title .info * {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-blueGrey.dark .UI-V2 .audit-filter .toolbar .header .content .ou-select {
  color: rgba(0, 0, 0, 0.87);
  background: #f48fb1;
}
.pink-blueGrey.dark .UI-V2 .audit-filter .toolbar .search-container .search .content {
  background: #303030;
}
.pink-blueGrey.dark .UI-V2 .audit-filter .toolbar .search-container .search .content:hover, .pink-blueGrey.dark .UI-V2 .audit-filter .toolbar .search-container .search .content:active, .pink-blueGrey.dark .UI-V2 .audit-filter .toolbar .search-container .search .content:focus, .pink-blueGrey.dark .UI-V2 .audit-filter .toolbar .search-container .search .content:focus-within {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-blueGrey.dark .UI-V2 .audit-filter .toolbar .search-container .search .content input {
  color: white;
}
.pink-blueGrey.dark .UI-V2 .audit-filter .filter-container {
  background: #424242 !important;
}
.pink-blueGrey.dark .UI-V2 .audit-filter .filter-container .form-body {
  background: #303030 !important;
}
.pink-blueGrey.dark .UI-V2 .audit-filter .filter-container .form-body .line h4 {
  color: #f48fb1;
}
.pink-blueGrey.dark .UI-V2 .audit-filter .filter-container .form-body .line mat-icon {
  color: #f48fb1;
}
.pink-blueGrey.dark .UI-V2 .audit-result .result-container .toolbar {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark .UI-V2 .audit-result .result-container .toolbar .header .content .module-title {
  color: #f48fb1;
}
.pink-blueGrey.dark .UI-V2 .audit-result .result-container .toolbar .header .content .module-title.selectable:hover {
  background: rgba(255, 255, 255, 0.04);
}
.pink-blueGrey.dark .UI-V2 .audit-result .result-container .toolbar .header .content .module-title .info h5 {
  color: white !important;
}
.pink-blueGrey.dark .UI-V2 .audit-result .result-container .toolbar .header .content .ou-select {
  color: rgba(0, 0, 0, 0.87);
  background: #f48fb1;
}
.pink-blueGrey.dark .UI-V2 .audit-result .result-container .toolbar .search-container .search .content {
  background: #303030;
}
.pink-blueGrey.dark .UI-V2 .audit-result .result-container .toolbar .search-container .search .content:hover, .pink-blueGrey.dark .UI-V2 .audit-result .result-container .toolbar .search-container .search .content:active, .pink-blueGrey.dark .UI-V2 .audit-result .result-container .toolbar .search-container .search .content:focus, .pink-blueGrey.dark .UI-V2 .audit-result .result-container .toolbar .search-container .search .content:focus-within {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-blueGrey.dark .UI-V2 .audit-result .result-container .toolbar .search-container .search .content input {
  color: white;
}
.pink-blueGrey.dark .UI-V2 .audit-result .result-container .new-table app-cs-grid-control datatable-header * {
  color: #f48fb1;
}
.pink-blueGrey.dark .UI-V2 .audit-result .result-container .new-table .mat-header-row.sticky * {
  color: #f48fb1 !important;
}
.pink-blueGrey.dark .UI-V2 .audit-detail-container {
  border-left: 1px solid rgba(255, 255, 255, 0.12);
  background: black !important;
}
.pink-blueGrey.dark .UI-V2 .audit-detail-container .side .toolbar .header {
  color: #f48fb1;
}
.pink-blueGrey.dark .UI-V2 .audit-detail-container .side .container .mat-list-base {
  background: #303030 !important;
}
.pink-blueGrey.dark .UI-V2 .audit-detail-container .side .container .mat-list-base mat-list-item .mat-line b {
  color: #f48fb1;
}
.pink-blueGrey.dark .UI-V2 .inbox-container {
  background: #424242;
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark .UI-V2 .inbox-container *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.pink-blueGrey.dark .UI-V2 .inbox-container *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
.pink-blueGrey.dark .UI-V2 .inbox-container *::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}
.pink-blueGrey.dark .UI-V2 .inbox-container .load-window {
  background: #424242;
}
.pink-blueGrey.dark .UI-V2 .inbox-container .toolbar {
  background: #f48fb1;
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark .UI-V2 .inbox-container .toolbar.reverse {
  background: #424242;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel {
  background: black !important;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel.mat-expanded {
  background: #303030 !important;
}
.pink-blueGrey.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel.mat-expanded mat-expansion-panel-header {
  background: #303030 !important;
}
.pink-blueGrey.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel.mat-expanded mat-expansion-panel-header:hover {
  background: none !important;
}
.pink-blueGrey.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel mat-expansion-panel-header {
  background: #303030 !important;
}
.pink-blueGrey.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel mat-expansion-panel-header:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-blueGrey.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel mat-expansion-panel-header .signature-inbox-header .mat-icon,
.pink-blueGrey.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel mat-expansion-panel-header .signature-inbox-header b {
  color: #b0bec5;
}
.pink-blueGrey.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel .mat-expansion-panel-body .item {
  background: #424242;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel .mat-expansion-panel-body .item:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-blueGrey.dark .UI-V2 .inbox-container .container mat-accordion mat-expansion-panel .mat-expansion-panel-body .item.active {
  border-left: 6px solid #b0bec5;
}
.pink-blueGrey.dark .UI-V2 .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.pink-blueGrey.dark .UI-V2 .work-space .main .load-window {
  background: #424242 !important;
}
.pink-blueGrey.dark .UI-V2 .work-space .main-action {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark mat-radio-button.disabled .mat-radio-label-content {
  color: #b0bec5;
}
.pink-blueGrey.dark .detail app-automatic-process-detail app-chapa.minimize,
.pink-blueGrey.dark .detail app-process-detail app-chapa.minimize {
  background: #ffc107 !important;
}
.pink-blueGrey.dark .detail app-automatic-process-detail app-chapa.minimize.purged,
.pink-blueGrey.dark .detail app-process-detail app-chapa.minimize.purged {
  background: #b2adb0 !important;
}
.pink-blueGrey.dark .detail .detail-toolbar {
  background: black;
}
.pink-blueGrey.dark .detail .processes-status .status-bar .bar-graph {
  background-color: #ffc107;
}
.pink-blueGrey.dark .detail .processes-status .status-bar .bar-graph .graph-ok {
  background-color: #009688;
}
.pink-blueGrey.dark .detail .processes-status .status-bar .bar-graph .graph-not-ok {
  background-color: #e91e63;
}
.pink-blueGrey.dark .detail .processes-status .status-resume b {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark .detail .processes-status .status-resume .resume-total b {
  background-color: #ffc107;
}
.pink-blueGrey.dark .detail .processes-status .status-resume .resume-ok b {
  background-color: #009688;
}
.pink-blueGrey.dark .detail .processes-status .status-resume .resume-not-ok b {
  background-color: #e91e63;
}
.pink-blueGrey.dark .detail .detail-toolbar .mat-avatar h3,
.pink-blueGrey.dark .detail .detail-toolbar .mat-avatar h5 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark .detail .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark .detail .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark .detail .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark .detail .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark .detail .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  background: #009688 !important;
}
.pink-blueGrey.dark .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  background: #b2dfdb !important;
}
.pink-blueGrey.dark .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  background: #b2dfdb !important;
}
.pink-blueGrey.dark .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  background: #009688 !important;
}
.pink-blueGrey.dark .detail.finished-process-inbox .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  background: #b2dfdb !important;
}
.pink-blueGrey.dark .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  background: #837C80 !important;
}
.pink-blueGrey.dark .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  background: #b2adb0 !important;
}
.pink-blueGrey.dark .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  background: #b2adb0 !important;
}
.pink-blueGrey.dark .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  background: #837C80 !important;
}
.pink-blueGrey.dark .detail.erased-process-inbox .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  background: #b2dfdb !important;
}
.pink-blueGrey.dark .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  background: #ffc107 !important;
}
.pink-blueGrey.dark .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  background: #ffecb3 !important;
}
.pink-blueGrey.dark .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  background: #ffecb3 !important;
}
.pink-blueGrey.dark .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  background: #ffc107 !important;
}
.pink-blueGrey.dark .detail.in-process-inbox .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  background: #ffecb3 !important;
}
.pink-blueGrey.dark .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou {
  background: #e91e63 !important;
}
.pink-blueGrey.dark .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector {
  background: #f8bbd0 !important;
}
.pink-blueGrey.dark .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .ou-selector .mat-icon {
  background: #f8bbd0 !important;
}
.pink-blueGrey.dark .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line.container-ou .mat-icon {
  background: #e91e63 !important;
}
.pink-blueGrey.dark .detail.error-process-inbox .detail-toolbar .mat-avatar .toolbar-line .mat-icon {
  background: #f8bbd0 !important;
}
.pink-blueGrey.dark app-analytics-document-set .sets-container .mat-list-base.sets-list mat-list-item {
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark app-analytics-document-set .sets-container .mat-list-base.sets-list mat-list-item .mat-list-item-content .set-status .value-percentile .undone-value {
  background: #673AB7;
}
.pink-blueGrey.dark app-analytics-document-set .sets-container .mat-list-base.sets-list mat-list-item .mat-list-item-content .set-status .value-percentile .not-value {
  background: black;
}
.pink-blueGrey.dark app-analytics-document-set .sets-container .mat-list-base.sets-list mat-list-item .mat-list-item-content .set-status .value-percentile .done-value {
  background: #009688;
}
.pink-blueGrey.dark app-analytics-document-set .work-space .main .toolbar .header .content .module-title .info .info-text .set-status {
  cursor: help;
}
.pink-blueGrey.dark app-analytics-document-set .work-space .main .toolbar .header .content .module-title .info .info-text .set-status .value-percentile .undone-value {
  background: #673AB7;
}
.pink-blueGrey.dark app-analytics-document-set .work-space .main .toolbar .header .content .module-title .info .info-text .set-status .value-percentile .not-value {
  background: black;
}
.pink-blueGrey.dark app-analytics-document-set .work-space .main .toolbar .header .content .module-title .info .info-text .set-status .value-percentile .done-value {
  background: #009688;
}
.pink-blueGrey.dark app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar .mat-progress-bar-buffer {
  background-color: black;
}
.pink-blueGrey.dark app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar.class1 .mat-progress-bar-fill::after {
  background-color: #f44336;
}
.pink-blueGrey.dark app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar.class2 .mat-progress-bar-fill::after {
  background-color: #ff9800;
}
.pink-blueGrey.dark app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar.class3 .mat-progress-bar-fill::after {
  background-color: #ffeb3b;
}
.pink-blueGrey.dark app-analytics-document-set .work-space .main .new-table .mat-table mat-progress-bar.status_bar-bar.class4 .mat-progress-bar-fill::after {
  background-color: #8bc34a;
}
.pink-blueGrey.dark app-analytics-document-set .work-space .side .container mat-list {
  background: #424242 !important;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark app-analytics-document-set .work-space .side .container mat-list mat-list-item .mat-line b {
  color: #f48fb1;
}
.pink-blueGrey.dark .catcher {
  background: rgba(0, 0, 0, 0.6);
}
.pink-blueGrey.dark .catcher.clear {
  background: rgba(0, 0, 0, 0);
}
.pink-blueGrey.dark .add-menu-form-body .mat-radio-group .mat-radio-button:hover {
  background: rgba(255, 255, 255, 0.04);
}
.pink-blueGrey.dark .add-menu-form-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b {
  color: white;
}
.pink-blueGrey.dark .add-menu-form-body.w-table .helper .search-bar {
  background: #303030;
}
.pink-blueGrey.dark .add-menu-form-body.w-table .helper .search-bar:hover, .pink-blueGrey.dark .add-menu-form-body.w-table .helper .search-bar:active, .pink-blueGrey.dark .add-menu-form-body.w-table .helper .search-bar:focus, .pink-blueGrey.dark .add-menu-form-body.w-table .helper .search-bar:focus-within {
  background: #303030 !important;
}
.pink-blueGrey.dark .add-menu-form-body.w-table .helper .search-bar input {
  color: white;
}
.pink-blueGrey.dark .add-menu-form-actions {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark .file-upload-list mat-list-item {
  background: #424242;
}
.pink-blueGrey.dark .mat-horizontal-stepper-content[aria-expanded=true] app-file-document-view {
  border-left: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark .mat-horizontal-stepper-content[aria-expanded=true] app-file-document-view .pdf-view-container {
  background: black;
}
.pink-blueGrey.dark .add-docs-drop .docs-drop-preview app-file-document-detail {
  border-left: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark .add-docs-drop .docs-drop-preview app-file-document-detail .view-toolbar {
  background: #424242;
}
.pink-blueGrey.dark .add-docs-drop .load-window.message h3.primary-text {
  color: #f48fb1;
}
.pink-blueGrey.dark mat-dialog-container app-add-menu-dialog .load-window.message h3.primary-text {
  color: #f48fb1;
}
.pink-blueGrey.dark .asisted-input.aligned-icon.subtitle * {
  color: rgba(255, 255, 255, 0.5) !important;
}
.pink-blueGrey.dark .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble {
  background: #f48fb1;
}
.pink-blueGrey.dark .asisted-input .mat-form-field-invalid + .indicator > .bubble {
  background: #e57373;
}
.pink-blueGrey.dark .asisted-input .mat-form-field-disabled + .indicator > .bubble {
  color: rgba(255, 255, 255, 0.5);
  background: rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark .asisted-input .indicator .bubble {
  background: rgba(255, 255, 255, 0.5);
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark .asisted-input .indicator .guide {
  border-color: rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark .asisted-input.icon-only .bubble {
  color: rgba(255, 255, 255, 0.7);
}
.pink-blueGrey.dark .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble {
  color: #f48fb1;
}
.pink-blueGrey.dark .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble {
  color: #e57373;
}
.pink-blueGrey.dark .UI-V2 .main-overlay {
  background: rgba(0, 0, 0, 0.32);
}
.pink-blueGrey.dark .UI-V2 .icon-complex mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.pink-blueGrey.dark .UI-V2 .icon-complex mat-icon.complex {
  color: #f48fb1 !important;
}
.pink-blueGrey.dark .UI-V2 .navigation {
  background: #303030;
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark .UI-V2 .navigation *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.pink-blueGrey.dark .UI-V2 .navigation *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
.pink-blueGrey.dark .UI-V2 .navigation .load-window {
  background: black;
}
.pink-blueGrey.dark .UI-V2 .navigation .toolbar {
  background: #f48fb1;
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark .UI-V2 .navigation .toolbar.reverse {
  background: #424242;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark .UI-V2 .navigation .container .helper {
  color: rgba(255, 255, 255, 0.7);
}
.pink-blueGrey.dark .UI-V2 .navigation .container .item {
  background: #424242;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark .UI-V2 .navigation .container .item:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-blueGrey.dark .UI-V2 .navigation .container .item.active {
  background: #f48fb1 !important;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #f48fb1;
}
.pink-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .separator .subtitle,
.pink-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .separator .subtitle,
.pink-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .separator .subtitle,
.pink-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .separator .subtitle,
.pink-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .separator .subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.pink-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .img-editior-container .img-editor .separator .subtitle,
.pink-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .img-editior-container .img-editor .separator .subtitle,
.pink-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .img-editior-container .img-editor .separator .subtitle,
.pink-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .img-editior-container .img-editor .separator .subtitle,
.pink-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .img-editior-container .img-editor .separator .subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.pink-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme,
.pink-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme,
.pink-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme,
.pink-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme,
.pink-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .main-color-container .main-color,
.pink-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .main-color-container .main-color,
.pink-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .main-color-container .main-color,
.pink-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .main-color-container .main-color,
.pink-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .main-color-container .main-color {
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .main-color-container .main-color:before,
.pink-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .main-color-container .main-color:before,
.pink-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .main-color-container .main-color:before,
.pink-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .main-color-container .main-color:before,
.pink-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .main-color-container .main-color:before {
  border-left: 10px solid rgba(0, 0, 0, 0);
  border-right: 10px solid rgba(0, 0, 0, 0);
}
.pink-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example h6.required,
.pink-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example h6.required,
.pink-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example h6.required,
.pink-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example h6.required,
.pink-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example h6.required {
  color: #e57373 !important;
}
.pink-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle,
.pink-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle,
.pink-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle,
.pink-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle,
.pink-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example mat-button-toggle-group mat-button-toggle {
  border: 2px solid rgba(0, 0, 0, 0);
}
.pink-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .side-color-container .side-color,
.pink-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .side-color-container .side-color,
.pink-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .side-color-container .side-color,
.pink-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .side-color-container .side-color,
.pink-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .side-color-container .side-color {
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .side-color-container .side-color:before,
.pink-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .side-color-container .side-color:before,
.pink-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .side-color-container .side-color:before,
.pink-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .side-color-container .side-color:before,
.pink-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .side-color-container .side-color:before {
  border-top: 10px solid rgba(0, 0, 0, 0);
  border-bottom: 10px solid rgba(0, 0, 0, 0);
}
.pink-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .edit-theme mat-card.example .side-color-container .side-color h6.required,
.pink-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .edit-theme mat-card.example .side-color-container .side-color h6.required,
.pink-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .edit-theme mat-card.example .side-color-container .side-color h6.required,
.pink-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .edit-theme mat-card.example .side-color-container .side-color h6.required,
.pink-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .edit-theme mat-card.example .side-color-container .side-color h6.required {
  color: #e57373 !important;
}
.pink-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .permission-settings-cards .card,
.pink-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .permission-settings-cards .card,
.pink-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .permission-settings-cards .card,
.pink-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .permission-settings-cards .card,
.pink-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .permission-settings-cards .card {
  background: #303030;
}
.pink-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-permissions .permission-settings-cards .card .slide-sub-group,
.pink-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-appearance .permission-settings-cards .card .slide-sub-group,
.pink-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-email-config .permission-settings-cards .card .slide-sub-group,
.pink-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-help-link-config .permission-settings-cards .card .slide-sub-group,
.pink-blueGrey.dark .UI-V2 .work-space mat-tab-group .mat-tab-body-wrapper mat-tab-body .content-scroll app-ou-description-config .permission-settings-cards .card .slide-sub-group {
  background: rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark .UI-V2 .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.pink-blueGrey.dark .UI-V2 .work-space .main .load-window {
  background: #424242 !important;
}
.pink-blueGrey.dark .UI-V2 .work-space .main.side-open {
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item {
  background: #303030;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item mat-icon.complex {
  text-shadow: -2px 0 0px black, 0 -2px 0px black, 2px 0 0px black, 0 2px 0px black, -2px -2px 0px black, 2px 2px 0px black, 2px -2px 0px black, -2px 2px 0px black !important;
}
.pink-blueGrey.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item h5 {
  color: rgba(255, 255, 255, 0.5);
}
.pink-blueGrey.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item h3 {
  color: white;
}
.pink-blueGrey.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item .actions {
  pointer-events: none;
}
.pink-blueGrey.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item .actions button {
  pointer-events: all;
}
.pink-blueGrey.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled {
  background: black;
}
.pink-blueGrey.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled h3 {
  color: #607D8B !important;
}
.pink-blueGrey.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled mat-icon {
  color: #607D8B !important;
}
.pink-blueGrey.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled.selected {
  background: #607D8B;
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-blueGrey.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled.selected h3 {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-blueGrey.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled.selected mat-icon {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-blueGrey.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.disabled.selected mat-icon.complex {
  color: rgba(0, 0, 0, 0.87) !important;
  text-shadow: -2px 0 0px #607D8B, 0 -2px 0px #607D8B, 2px 0 0px #607D8B, 0 2px 0px #607D8B, -2px -2px 0px #607D8B, 2px 2px 0px #607D8B, 2px -2px 0px #607D8B, -2px 2px 0px #607D8B !important;
}
.pink-blueGrey.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item:hover {
  background: rgba(255, 255, 255, 0.04);
}
.pink-blueGrey.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected {
  background: #f48fb1;
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected mat-icon {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected mat-icon.complex {
  color: rgba(0, 0, 0, 0.87) !important;
  text-shadow: -2px 0 0px #f48fb1, 0 -2px 0px #f48fb1, 2px 0 0px #f48fb1, 0 2px 0px #f48fb1, -2px -2px 0px #f48fb1, 2px 2px 0px #f48fb1, 2px -2px 0px #f48fb1, -2px 2px 0px #f48fb1 !important;
}
.pink-blueGrey.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected h5,
.pink-blueGrey.dark .UI-V2 .work-space .main .container.file-configuration .file-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body-content .content-scroll .item.selected h3 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark .UI-V2 .work-space .main .main-action {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark .UI-V2 .work-space .side {
  background: #303030;
}
.pink-blueGrey.dark .UI-V2 .work-space .side .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #303030, 0 -2px 0px #303030, 2px 0 0px #303030, 0 2px 0px #303030, -2px -2px 0px #303030, 2px 2px 0px #303030, 2px -2px 0px #303030, -2px 2px 0px #303030;
}
.pink-blueGrey.dark .UI-V2 .work-space .side *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.pink-blueGrey.dark .UI-V2 .work-space .side *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
.pink-blueGrey.dark .UI-V2 .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #303030 !important;
}
.pink-blueGrey.dark .UI-V2 .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(255, 255, 255, 0.5);
}
.pink-blueGrey.dark .UI-V2 .work-space .side mat-form-field .mat-form-field-flex {
  background: #303030;
}
.pink-blueGrey.dark .UI-V2 .work-space .side mat-checkbox:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-blueGrey.dark .UI-V2 .work-space .side .hint.all {
  color: #b0bec5;
}
.pink-blueGrey.dark .UI-V2 .work-space .side .toolbar .header {
  color: #f48fb1;
}
.pink-blueGrey.dark .UI-V2 .work-space .side .toolbar .header h3 {
  color: #f48fb1;
}
.pink-blueGrey.dark .UI-V2 .work-space .side .container .role-resume {
  background: #f48fb1;
}
.pink-blueGrey.dark .UI-V2 .work-space .side .container .role-resume .line {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark .UI-V2 .work-space .side .container .role-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.1);
}
.pink-blueGrey.dark .UI-V2 .work-space .side .container .role-resume .line.editable button {
  background: #f48fb1;
}
.pink-blueGrey.dark .UI-V2 .work-space .side .container .section-title {
  color: #f48fb1;
}
.pink-blueGrey.dark .UI-V2 .work-space .side .container .section-resume {
  background: #424242;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark .UI-V2 .work-space .side .container .section-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-blueGrey.dark .UI-V2 .work-space .side .container .section-resume .line.editable:hover .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #424242, 0 -2px 0px #424242, 2px 0 0px #424242, 0 2px 0px #424242, -2px -2px 0px #424242, 2px 2px 0px #424242, 2px -2px 0px #424242, -2px 2px 0px #424242 !important;
}
.pink-blueGrey.dark .UI-V2 .work-space .side .container .section-resume .line.editable button {
  background: #303030;
}
.pink-blueGrey.dark .UI-V2 .work-space .side .container .section-resume .line .header .icon-complex mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.pink-blueGrey.dark .UI-V2 .work-space .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #f48fb1 !important;
}
.pink-blueGrey.dark .UI-V2 .work-space .side .container .section-resume .line .header mat-icon:not(.complex) {
  color: rgba(255, 255, 255, 0.7);
}
.pink-blueGrey.dark .UI-V2 .work-space .side .container .section-resume .line .header .info h4 {
  color: #f48fb1;
}
.pink-blueGrey.dark .UI-V2 .work-space .side .container .section-resume .line ul {
  color: #f48fb1;
}
.pink-blueGrey.dark .UI-V2 .work-space .side .container .section-resume .line.head {
  background: black;
}
.pink-blueGrey.dark .UI-V2 .work-space .side .container .section-resume .line.head mat-icon {
  color: #b0bec5;
}
.pink-blueGrey.dark .UI-V2 .work-space .side .container .section-resume .line.head h4 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark .UI-V2 .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body {
  background: #424242;
}
.pink-blueGrey.dark .UI-V2 .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: rgba(255, 255, 255, 0.7);
}
.pink-blueGrey.dark .UI-V2 .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #f48fb1;
}
.pink-blueGrey.dark .UI-V2 .work-space .side.edition .toolbar .header {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark .UI-V2 .work-space .side.edition .toolbar .header mat-icon {
  color: #b0bec5;
}
.pink-blueGrey.dark .UI-V2 .work-space .side.edition .toolbar .header h3 {
  color: #b0bec5;
}
.pink-blueGrey.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body {
  background: #424242 !important;
}
.pink-blueGrey.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header mat-icon:not(.complex) {
  color: #f48fb1;
}
.pink-blueGrey.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header h4 {
  color: #f48fb1;
}
.pink-blueGrey.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-actions {
  background: #303030;
}
.pink-blueGrey.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.pink-blueGrey.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header .icon-complex mat-icon.complex {
  color: #b0bec5 !important;
}
.pink-blueGrey.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header h4 {
  color: #b0bec5;
}
.pink-blueGrey.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line ul {
  color: #b0bec5;
  font-weight: 400;
}
.pink-blueGrey.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head mat-icon {
  color: #b0bec5 !important;
}
.pink-blueGrey.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head h4 {
  color: white;
}
.pink-blueGrey.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .section-title {
  color: white !important;
}
.pink-blueGrey.dark .UI-V2 .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume h6.hint {
  background: #b0bec5;
}
.pink-blueGrey.dark app-role-user-find .main-overlay {
  background: rgba(0, 0, 0, 0.32);
}
.pink-blueGrey.dark app-role-user-find .icon-complex mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.pink-blueGrey.dark app-role-user-find .icon-complex mat-icon.complex {
  color: #f48fb1 !important;
}
.pink-blueGrey.dark app-role-user-find .navigation {
  background: #303030;
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark app-role-user-find .navigation *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.pink-blueGrey.dark app-role-user-find .navigation *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
.pink-blueGrey.dark app-role-user-find .navigation .load-window {
  background: black;
}
.pink-blueGrey.dark app-role-user-find .navigation .toolbar {
  background: #f48fb1;
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark app-role-user-find .navigation .toolbar.reverse {
  background: #424242;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark app-role-user-find .navigation .container .helper {
  color: rgba(255, 255, 255, 0.7);
}
.pink-blueGrey.dark app-role-user-find .navigation .container .item {
  background: #424242;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark app-role-user-find .navigation .container .item:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-blueGrey.dark app-role-user-find .navigation .container .item.active {
  background: #f48fb1 !important;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #f48fb1;
}
.pink-blueGrey.dark app-role-user-find .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.pink-blueGrey.dark app-role-user-find .work-space .main .load-window {
  background: #424242 !important;
}
.pink-blueGrey.dark app-role-user-find .work-space .main.side-open {
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark app-role-user-find .work-space .main .container .item {
  background: #303030;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark app-role-user-find .work-space .main .container .item .subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.pink-blueGrey.dark app-role-user-find .work-space .main .container .item.disabled {
  background: black;
  color: #607D8B !important;
}
.pink-blueGrey.dark app-role-user-find .work-space .main .container .item.disabled h3 {
  color: #607D8B !important;
}
.pink-blueGrey.dark app-role-user-find .work-space .main .container .item.disabled mat-icon {
  color: #607D8B !important;
}
.pink-blueGrey.dark app-role-user-find .work-space .main .container .item.disabled.selected {
  background: #607D8B;
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-blueGrey.dark app-role-user-find .work-space .main .container .item.disabled.selected h3 {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-blueGrey.dark app-role-user-find .work-space .main .container .item.disabled.selected mat-icon {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-blueGrey.dark app-role-user-find .work-space .main .container .item.disabled.selected mat-icon.complex {
  color: rgba(0, 0, 0, 0.87) !important;
  text-shadow: -2px 0 0px #607D8B, 0 -2px 0px #607D8B, 2px 0 0px #607D8B, 0 2px 0px #607D8B, -2px -2px 0px #607D8B, 2px 2px 0px #607D8B, 2px -2px 0px #607D8B, -2px 2px 0px #607D8B !important;
}
.pink-blueGrey.dark app-role-user-find .work-space .main .container .item mat-icon.complex {
  text-shadow: -2px 0 0px black, 0 -2px 0px black, 2px 0 0px black, 0 2px 0px black, -2px -2px 0px black, 2px 2px 0px black, 2px -2px 0px black, -2px 2px 0px black !important;
}
.pink-blueGrey.dark app-role-user-find .work-space .main .container .item h5 {
  color: rgba(255, 255, 255, 0.5);
}
.pink-blueGrey.dark app-role-user-find .work-space .main .container .item h3 {
  color: white;
}
.pink-blueGrey.dark app-role-user-find .work-space .main .container .item:hover {
  background: rgba(255, 255, 255, 0.04);
}
.pink-blueGrey.dark app-role-user-find .work-space .main .container .item.selected {
  background: #f48fb1;
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark app-role-user-find .work-space .main .container .item.selected mat-icon {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark app-role-user-find .work-space .main .container .item.selected mat-icon.complex {
  color: rgba(0, 0, 0, 0.87) !important;
  text-shadow: -2px 0 0px #f48fb1, 0 -2px 0px #f48fb1, 2px 0 0px #f48fb1, 0 2px 0px #f48fb1, -2px -2px 0px #f48fb1, 2px 2px 0px #f48fb1, 2px -2px 0px #f48fb1, -2px 2px 0px #f48fb1 !important;
}
.pink-blueGrey.dark app-role-user-find .work-space .main .container .item.selected h5,
.pink-blueGrey.dark app-role-user-find .work-space .main .container .item.selected h3 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark app-role-user-find .work-space .main .main-action {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark app-role-user-find .work-space .side {
  background: #303030;
}
.pink-blueGrey.dark app-role-user-find .work-space .side .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #303030, 0 -2px 0px #303030, 2px 0 0px #303030, 0 2px 0px #303030, -2px -2px 0px #303030, 2px 2px 0px #303030, 2px -2px 0px #303030, -2px 2px 0px #303030;
}
.pink-blueGrey.dark app-role-user-find .work-space .side *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.pink-blueGrey.dark app-role-user-find .work-space .side *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
.pink-blueGrey.dark app-role-user-find .work-space .side .edition-line {
  background: black;
}
.pink-blueGrey.dark app-role-user-find .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #303030 !important;
}
.pink-blueGrey.dark app-role-user-find .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(255, 255, 255, 0.5);
}
.pink-blueGrey.dark app-role-user-find .work-space .side mat-form-field .mat-form-field-flex {
  background: #303030;
}
.pink-blueGrey.dark app-role-user-find .work-space .side mat-checkbox:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-blueGrey.dark app-role-user-find .work-space .side .hint.all {
  color: #b0bec5;
}
.pink-blueGrey.dark app-role-user-find .work-space .side .toolbar .header {
  color: #f48fb1;
}
.pink-blueGrey.dark app-role-user-find .work-space .side .toolbar .header h3 {
  color: #f48fb1;
}
.pink-blueGrey.dark app-role-user-find .work-space .side .container .role-resume {
  background: #f48fb1;
}
.pink-blueGrey.dark app-role-user-find .work-space .side .container .role-resume .line {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark app-role-user-find .work-space .side .container .role-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.1);
}
.pink-blueGrey.dark app-role-user-find .work-space .side .container .role-resume .line.editable button {
  background: #f48fb1;
}
.pink-blueGrey.dark app-role-user-find .work-space .side .container .section-title {
  color: #f48fb1;
}
.pink-blueGrey.dark app-role-user-find .work-space .side .container .section-resume {
  background: #424242;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark app-role-user-find .work-space .side .container .section-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-blueGrey.dark app-role-user-find .work-space .side .container .section-resume .line.editable:hover .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #424242, 0 -2px 0px #424242, 2px 0 0px #424242, 0 2px 0px #424242, -2px -2px 0px #424242, 2px 2px 0px #424242, 2px -2px 0px #424242, -2px 2px 0px #424242 !important;
}
.pink-blueGrey.dark app-role-user-find .work-space .side .container .section-resume .line.editable button {
  background: #303030;
}
.pink-blueGrey.dark app-role-user-find .work-space .side .container .section-resume .line .header .icon-complex mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.pink-blueGrey.dark app-role-user-find .work-space .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #f48fb1 !important;
}
.pink-blueGrey.dark app-role-user-find .work-space .side .container .section-resume .line .header mat-icon:not(.complex) {
  color: rgba(255, 255, 255, 0.7);
}
.pink-blueGrey.dark app-role-user-find .work-space .side .container .section-resume .line .header .info h4 {
  color: #f48fb1;
}
.pink-blueGrey.dark app-role-user-find .work-space .side .container .section-resume .line ul {
  color: #f48fb1;
}
.pink-blueGrey.dark app-role-user-find .work-space .side .container .section-resume .line.head {
  background: black;
}
.pink-blueGrey.dark app-role-user-find .work-space .side .container .section-resume .line.head mat-icon {
  color: #b0bec5;
}
.pink-blueGrey.dark app-role-user-find .work-space .side .container .section-resume .line.head h4 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee-title {
  color: #f48fb1;
}
.pink-blueGrey.dark app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body {
  background: #424242;
}
.pink-blueGrey.dark app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: rgba(255, 255, 255, 0.7);
}
.pink-blueGrey.dark app-role-user-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #f48fb1;
}
.pink-blueGrey.dark app-role-user-find .work-space .side.edition .toolbar .header {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark app-role-user-find .work-space .side.edition .toolbar .header mat-icon {
  color: #b0bec5;
}
.pink-blueGrey.dark app-role-user-find .work-space .side.edition .toolbar .header h3 {
  color: #b0bec5;
}
.pink-blueGrey.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body {
  background: #424242 !important;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header mat-icon:not(.complex) {
  color: #f48fb1;
}
.pink-blueGrey.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header h4 {
  color: #f48fb1;
}
.pink-blueGrey.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-actions {
  background: #303030;
}
.pink-blueGrey.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.pink-blueGrey.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header .icon-complex mat-icon.complex {
  color: #b0bec5 !important;
}
.pink-blueGrey.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header h4 {
  color: #b0bec5;
}
.pink-blueGrey.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line ul {
  color: #b0bec5;
}
.pink-blueGrey.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head mat-icon {
  color: #b0bec5 !important;
}
.pink-blueGrey.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head h4 {
  color: white;
}
.pink-blueGrey.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .section-title {
  color: white !important;
}
.pink-blueGrey.dark app-role-user-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume h6.hint {
  background: #b0bec5;
}
.pink-blueGrey.dark app-role-user-find .work-space app-role-user-detail.disabled .side .toolbar .header {
  color: #607D8B !important;
}
.pink-blueGrey.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container .role-resume {
  background: #607D8B !important;
}
.pink-blueGrey.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-title {
  color: #607D8B !important;
}
.pink-blueGrey.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line ul {
  color: #607D8B !important;
}
.pink-blueGrey.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header mat-icon {
  color: #607D8B;
}
.pink-blueGrey.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .icon-complex mat-icon {
  color: #607D8B !important;
}
.pink-blueGrey.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #607D8B !important;
}
.pink-blueGrey.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .info h4 {
  color: #607D8B !important;
}
.pink-blueGrey.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee-title {
  color: #607D8B;
}
.pink-blueGrey.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: #607D8B;
}
.pink-blueGrey.dark app-role-user-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #607D8B;
}
.pink-blueGrey.dark app-actor-find .main-overlay {
  background: rgba(0, 0, 0, 0.32);
}
.pink-blueGrey.dark app-actor-find .icon-complex mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.pink-blueGrey.dark app-actor-find .icon-complex mat-icon.complex {
  color: #f48fb1 !important;
}
.pink-blueGrey.dark app-actor-find .navigation {
  background: #303030;
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark app-actor-find .navigation *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.pink-blueGrey.dark app-actor-find .navigation *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
.pink-blueGrey.dark app-actor-find .navigation .load-window {
  background: black;
}
.pink-blueGrey.dark app-actor-find .navigation .toolbar {
  background: #f48fb1;
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark app-actor-find .navigation .toolbar.reverse {
  background: #424242;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark app-actor-find .navigation .container .helper {
  color: rgba(255, 255, 255, 0.7);
}
.pink-blueGrey.dark app-actor-find .navigation .container .item {
  background: #424242;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark app-actor-find .navigation .container .item:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-blueGrey.dark app-actor-find .navigation .container .item.active {
  background: #f48fb1 !important;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #f48fb1;
}
.pink-blueGrey.dark app-actor-find .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.pink-blueGrey.dark app-actor-find .work-space .main .load-window {
  background: #424242 !important;
}
.pink-blueGrey.dark app-actor-find .work-space .main.side-open {
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark app-actor-find .work-space .main .container .item {
  background: #303030;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark app-actor-find .work-space .main .container .item .subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.pink-blueGrey.dark app-actor-find .work-space .main .container .item.disabled {
  background: black;
  color: #607D8B !important;
}
.pink-blueGrey.dark app-actor-find .work-space .main .container .item.disabled h3 {
  color: #607D8B !important;
}
.pink-blueGrey.dark app-actor-find .work-space .main .container .item.disabled mat-icon {
  color: #607D8B !important;
}
.pink-blueGrey.dark app-actor-find .work-space .main .container .item.disabled.selected {
  background: #607D8B;
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-blueGrey.dark app-actor-find .work-space .main .container .item.disabled.selected h3 {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-blueGrey.dark app-actor-find .work-space .main .container .item.disabled.selected mat-icon {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-blueGrey.dark app-actor-find .work-space .main .container .item.disabled.selected mat-icon.complex {
  color: rgba(0, 0, 0, 0.87) !important;
  text-shadow: -2px 0 0px #607D8B, 0 -2px 0px #607D8B, 2px 0 0px #607D8B, 0 2px 0px #607D8B, -2px -2px 0px #607D8B, 2px 2px 0px #607D8B, 2px -2px 0px #607D8B, -2px 2px 0px #607D8B !important;
}
.pink-blueGrey.dark app-actor-find .work-space .main .container .item mat-icon.complex {
  text-shadow: -2px 0 0px black, 0 -2px 0px black, 2px 0 0px black, 0 2px 0px black, -2px -2px 0px black, 2px 2px 0px black, 2px -2px 0px black, -2px 2px 0px black !important;
}
.pink-blueGrey.dark app-actor-find .work-space .main .container .item h5 {
  color: rgba(255, 255, 255, 0.5);
}
.pink-blueGrey.dark app-actor-find .work-space .main .container .item h3 {
  color: white;
}
.pink-blueGrey.dark app-actor-find .work-space .main .container .item:hover {
  background: rgba(255, 255, 255, 0.04);
}
.pink-blueGrey.dark app-actor-find .work-space .main .container .item.selected {
  background: #f48fb1;
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark app-actor-find .work-space .main .container .item.selected mat-icon {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark app-actor-find .work-space .main .container .item.selected mat-icon.complex {
  color: rgba(0, 0, 0, 0.87) !important;
  text-shadow: -2px 0 0px #f48fb1, 0 -2px 0px #f48fb1, 2px 0 0px #f48fb1, 0 2px 0px #f48fb1, -2px -2px 0px #f48fb1, 2px 2px 0px #f48fb1, 2px -2px 0px #f48fb1, -2px 2px 0px #f48fb1 !important;
}
.pink-blueGrey.dark app-actor-find .work-space .main .container .item.selected h5,
.pink-blueGrey.dark app-actor-find .work-space .main .container .item.selected h3 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark app-actor-find .work-space .main .main-action {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark app-actor-find .work-space .side {
  background: #303030;
}
.pink-blueGrey.dark app-actor-find .work-space .side .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #303030, 0 -2px 0px #303030, 2px 0 0px #303030, 0 2px 0px #303030, -2px -2px 0px #303030, 2px 2px 0px #303030, 2px -2px 0px #303030, -2px 2px 0px #303030;
}
.pink-blueGrey.dark app-actor-find .work-space .side *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.pink-blueGrey.dark app-actor-find .work-space .side *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
.pink-blueGrey.dark app-actor-find .work-space .side .edition-line {
  background: black;
}
.pink-blueGrey.dark app-actor-find .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #303030 !important;
}
.pink-blueGrey.dark app-actor-find .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(255, 255, 255, 0.5);
}
.pink-blueGrey.dark app-actor-find .work-space .side mat-form-field .mat-form-field-flex {
  background: #303030;
}
.pink-blueGrey.dark app-actor-find .work-space .side mat-checkbox:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-blueGrey.dark app-actor-find .work-space .side .hint.all {
  color: #b0bec5;
}
.pink-blueGrey.dark app-actor-find .work-space .side .toolbar .header {
  color: #f48fb1;
}
.pink-blueGrey.dark app-actor-find .work-space .side .toolbar .header h3 {
  color: #f48fb1;
}
.pink-blueGrey.dark app-actor-find .work-space .side .container .role-resume {
  background: #f48fb1;
}
.pink-blueGrey.dark app-actor-find .work-space .side .container .role-resume .line {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark app-actor-find .work-space .side .container .role-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.1);
}
.pink-blueGrey.dark app-actor-find .work-space .side .container .role-resume .line.editable button {
  background: #f48fb1;
}
.pink-blueGrey.dark app-actor-find .work-space .side .container .section-title {
  color: #f48fb1;
}
.pink-blueGrey.dark app-actor-find .work-space .side .container .section-resume {
  background: #424242;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark app-actor-find .work-space .side .container .section-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-blueGrey.dark app-actor-find .work-space .side .container .section-resume .line.editable:hover .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #424242, 0 -2px 0px #424242, 2px 0 0px #424242, 0 2px 0px #424242, -2px -2px 0px #424242, 2px 2px 0px #424242, 2px -2px 0px #424242, -2px 2px 0px #424242 !important;
}
.pink-blueGrey.dark app-actor-find .work-space .side .container .section-resume .line.editable button {
  background: #303030;
}
.pink-blueGrey.dark app-actor-find .work-space .side .container .section-resume .line .header .icon-complex mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.pink-blueGrey.dark app-actor-find .work-space .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #f48fb1 !important;
}
.pink-blueGrey.dark app-actor-find .work-space .side .container .section-resume .line .header mat-icon:not(.complex) {
  color: rgba(255, 255, 255, 0.7);
}
.pink-blueGrey.dark app-actor-find .work-space .side .container .section-resume .line .header .info h4 {
  color: #f48fb1;
}
.pink-blueGrey.dark app-actor-find .work-space .side .container .section-resume .line ul {
  color: #f48fb1;
}
.pink-blueGrey.dark app-actor-find .work-space .side .container .section-resume .line.head {
  background: black;
}
.pink-blueGrey.dark app-actor-find .work-space .side .container .section-resume .line.head mat-icon {
  color: #b0bec5;
}
.pink-blueGrey.dark app-actor-find .work-space .side .container .section-resume .line.head h4 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee-title {
  color: #f48fb1;
}
.pink-blueGrey.dark app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body {
  background: #424242;
}
.pink-blueGrey.dark app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: rgba(255, 255, 255, 0.7);
}
.pink-blueGrey.dark app-actor-find .work-space .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #f48fb1;
}
.pink-blueGrey.dark app-actor-find .work-space .side.edition .toolbar .header {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark app-actor-find .work-space .side.edition .toolbar .header mat-icon {
  color: #b0bec5;
}
.pink-blueGrey.dark app-actor-find .work-space .side.edition .toolbar .header h3 {
  color: #b0bec5;
}
.pink-blueGrey.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body {
  background: #424242 !important;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header mat-icon:not(.complex) {
  color: #f48fb1;
}
.pink-blueGrey.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-body .line .header h4 {
  color: #f48fb1;
}
.pink-blueGrey.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .form-actions {
  background: #303030;
}
.pink-blueGrey.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.pink-blueGrey.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header .icon-complex mat-icon.complex {
  color: #b0bec5 !important;
}
.pink-blueGrey.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line .header h4 {
  color: #b0bec5;
}
.pink-blueGrey.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line ul {
  color: #b0bec5;
}
.pink-blueGrey.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head mat-icon {
  color: #b0bec5 !important;
}
.pink-blueGrey.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .form-body .line.head h4 {
  color: white;
}
.pink-blueGrey.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume .section-title {
  color: white !important;
}
.pink-blueGrey.dark app-actor-find .work-space .side.edition .container app-role-user-add mat-horizontal-stepper .mat-horizontal-content-container .resume h6.hint {
  background: #b0bec5;
}
.pink-blueGrey.dark app-actor-find .work-space app-role-user-detail.disabled .side .toolbar .header {
  color: #607D8B !important;
}
.pink-blueGrey.dark app-actor-find .work-space app-role-user-detail.disabled .side .container .role-resume {
  background: #607D8B !important;
}
.pink-blueGrey.dark app-actor-find .work-space app-role-user-detail.disabled .side .container .section-title {
  color: #607D8B !important;
}
.pink-blueGrey.dark app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line ul {
  color: #607D8B !important;
}
.pink-blueGrey.dark app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header mat-icon {
  color: #607D8B;
}
.pink-blueGrey.dark app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .icon-complex mat-icon {
  color: #607D8B !important;
}
.pink-blueGrey.dark app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #607D8B !important;
}
.pink-blueGrey.dark app-actor-find .work-space app-role-user-detail.disabled .side .container .section-resume .line .header .info h4 {
  color: #607D8B !important;
}
.pink-blueGrey.dark app-actor-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee-title {
  color: #607D8B;
}
.pink-blueGrey.dark app-actor-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text mat-icon {
  color: #607D8B;
}
.pink-blueGrey.dark app-actor-find .work-space app-role-user-detail.disabled .side .container app-certificates .employee_detail-sign .employee_detail-side-body .data .data-line .data_line-text .line_text-container .line_text-main {
  color: #607D8B;
}
.pink-blueGrey.dark .dropzone {
  border: 2px dashed #607D8B;
  background: black !important;
}
.pink-blueGrey.dark .dropzone.hovering {
  border: 2px solid #607D8B;
  color: rgba(255, 255, 255, 0.5) !important;
}
.pink-blueGrey.dark .dropzone button.mat-stroked-button {
  background: #424242;
}
.pink-blueGrey.dark .firmador .content-action-main {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-blueGrey.dark .firmador .content-action-main.disabled {
  color: rgba(255, 255, 255, 0.5) !important;
  background: rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark .sign-cycle {
  background: #424242;
}
.pink-blueGrey.dark .sign-cycle button.success {
  background-color: #009688;
}
.pink-blueGrey.dark .sign-cycle button.warning {
  background-color: #673AB7;
}
.pink-blueGrey.dark .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-title-container .cycle-content-title h4 {
  color: #607D8B;
}
.pink-blueGrey.dark .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-action .content-action-main {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-blueGrey.dark .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-action .content-action-main.mat-stroked-button:disabled {
  color: rgba(255, 255, 255, 0.5) !important;
}
.pink-blueGrey.dark .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-action .content-action-main.mat-stroked-button:not(:disabled) {
  color: #b0bec5 !important;
}
.pink-blueGrey.dark .sign-cycle mat-horizontal-stepper .mat-horizontal-content-container .mat-horizontal-stepper-content .sign-cycle-content .cycle-content-action .content-action-side u {
  color: rgba(255, 255, 255, 0.5);
}
.pink-blueGrey.dark .resume-status {
  background: #424242;
}
.pink-blueGrey.dark .help-hoverable mat-icon {
  color: rgba(255, 255, 255, 0.7);
}
.pink-blueGrey.dark .help-hoverable mat-hint {
  background: #424242;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark .help-hoverable mat-hint b {
  color: #f48fb1 !important;
}
.pink-blueGrey.dark app-file-document-sign-massive-dialog .dialog-content,
.pink-blueGrey.dark app-share-person-dialog .dialog-content {
  background: #303030;
}
.pink-blueGrey.dark app-file-document-sign-massive-dialog .dialog-content mat-toolbar mat-toolbar-row .mat-avatar h3,
.pink-blueGrey.dark app-share-person-dialog .dialog-content mat-toolbar mat-toolbar-row .mat-avatar h3 {
  color: #f48fb1;
}
.pink-blueGrey.dark app-file-document-sign-massive-dialog .dialog-content .dialog-body p b,
.pink-blueGrey.dark app-share-person-dialog .dialog-content .dialog-body p b {
  color: #b0bec5;
}
.pink-blueGrey.dark app-file-document-sign-massive-dialog .dialog-content app-file-document-sign,
.pink-blueGrey.dark app-share-person-dialog .dialog-content app-file-document-sign {
  background: #424242;
}
.pink-blueGrey.dark .mat-basic-chip {
  background: black !important;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark .mat-basic-chip.seen {
  border-color: #2196F3 !important;
  color: #2196F3 !important;
}
.pink-blueGrey.dark .mat-basic-chip.unseen {
  border-color: #607D8B !important;
  color: #607D8B !important;
}
.pink-blueGrey.dark .mat-basic-chip.signed-ok {
  border-color: #009688 !important;
  color: #009688 !important;
}
.pink-blueGrey.dark .mat-basic-chip.signed-ok.cancelled {
  border-color: #795548 !important;
  color: #795548 !important;
}
.pink-blueGrey.dark .mat-basic-chip.signed-not-ok {
  border-color: #673AB7 !important;
  color: #673AB7 !important;
}
.pink-blueGrey.dark .mat-basic-chip.not-signed {
  border-color: #ff4081 !important;
  color: #ff4081 !important;
}
.pink-blueGrey.dark .mat-basic-chip.not-signed-employer {
  border-color: #ff4081 !important;
  color: #ff4081 !important;
}
.pink-blueGrey.dark app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip {
  background: black !important;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.seen {
  border-color: #2196F3 !important;
  color: #2196F3 !important;
}
.pink-blueGrey.dark app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.unseen {
  border-color: #607D8B !important;
  color: #607D8B !important;
}
.pink-blueGrey.dark app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.signed-ok {
  border-color: #009688 !important;
  color: #009688 !important;
}
.pink-blueGrey.dark app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.signed-ok.cancelled {
  border-color: #795548 !important;
  color: #795548 !important;
}
.pink-blueGrey.dark app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.signed-not-ok {
  border-color: #673AB7 !important;
  color: #673AB7 !important;
}
.pink-blueGrey.dark app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.not-signed {
  border-color: #ff4081 !important;
  color: #ff4081 !important;
}
.pink-blueGrey.dark app-file-document-state mat-chip-list .mat-chip-list-wrapper .mat-basic-chip.not-signed-employer {
  border-color: #ff4081 !important;
  color: #ff4081 !important;
}
.pink-blueGrey.dark .inbox-container.employee.my-documents .chip-line.mat-line .mat-chip-list-wrapper .mat-basic-chip.seen {
  color: #607D8B !important;
}
.pink-blueGrey.dark .resume-status .mat-basic-chip {
  background: black !important;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark .resume-status .mat-basic-chip.signed-ok {
  border-color: #009688 !important;
  background-color: #009688 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-blueGrey.dark .resume-status .mat-basic-chip.signed-ok.cancelled {
  border-color: #795548 !important;
  background-color: #795548 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-blueGrey.dark .resume-status .mat-basic-chip.signed-not-ok {
  border-color: #673AB7 !important;
  background-color: #673AB7 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-blueGrey.dark app-file-document-view .new-container app-file-document-upload {
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark app-file-document-view .new-container app-upload-form .file-upload-list .mat-subheader {
  background: #424242;
}
.pink-blueGrey.dark app-file-document-view .new-container app-upload-form .file-upload-list mat-list-item {
  background: #303030 !important;
}
.pink-blueGrey.dark app-file-document-view .preview-content {
  background: #607D8B;
}
.pink-blueGrey.dark app-file-document-view .preview-content h4 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark app-file-document-view .view-container {
  background: #424242;
}
.pink-blueGrey.dark app-file-document-view .view-container mat-toolbar.side-bar {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark .formio-hint {
  background: #f48fb1;
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark mat-dialog-container app-file-document-state-chips {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark app-candidate-promotion .load-window.message h3.primary-text {
  color: #f48fb1;
}
.pink-blueGrey.dark app-multiple-documentation-add-dialog .icon-complex mat-icon,
.pink-blueGrey.dark app-documentation-type-set-configuration-dialog .icon-complex mat-icon,
.pink-blueGrey.dark app-add-candidate-dialog .icon-complex mat-icon,
.pink-blueGrey.dark app-share-person-dialog .icon-complex mat-icon,
.pink-blueGrey.dark app-share-person-response-dialog .icon-complex mat-icon {
  color: white !important;
}
.pink-blueGrey.dark app-multiple-documentation-add-dialog .icon-complex mat-icon.complex,
.pink-blueGrey.dark app-documentation-type-set-configuration-dialog .icon-complex mat-icon.complex,
.pink-blueGrey.dark app-add-candidate-dialog .icon-complex mat-icon.complex,
.pink-blueGrey.dark app-share-person-dialog .icon-complex mat-icon.complex,
.pink-blueGrey.dark app-share-person-response-dialog .icon-complex mat-icon.complex {
  color: #f48fb1 !important;
}
.pink-blueGrey.dark app-multiple-documentation-add-dialog .icon-complex mat-icon.complex.mat-accent,
.pink-blueGrey.dark app-documentation-type-set-configuration-dialog .icon-complex mat-icon.complex.mat-accent,
.pink-blueGrey.dark app-add-candidate-dialog .icon-complex mat-icon.complex.mat-accent,
.pink-blueGrey.dark app-share-person-dialog .icon-complex mat-icon.complex.mat-accent,
.pink-blueGrey.dark app-share-person-response-dialog .icon-complex mat-icon.complex.mat-accent {
  color: #b0bec5;
}
.pink-blueGrey.dark app-multiple-documentation-add-dialog .icon-complex mat-icon.complex.side,
.pink-blueGrey.dark app-documentation-type-set-configuration-dialog .icon-complex mat-icon.complex.side,
.pink-blueGrey.dark app-add-candidate-dialog .icon-complex mat-icon.complex.side,
.pink-blueGrey.dark app-share-person-dialog .icon-complex mat-icon.complex.side,
.pink-blueGrey.dark app-share-person-response-dialog .icon-complex mat-icon.complex.side {
  color: rgba(255, 255, 255, 0.7);
}
.pink-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection,
.pink-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection,
.pink-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection,
.pink-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .document-selection,
.pink-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection {
  background: #303030;
}
.pink-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.pink-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.pink-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3,
.pink-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.pink-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.pink-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3,
.pink-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.pink-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.pink-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3,
.pink-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.pink-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.pink-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3,
.pink-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon,
.pink-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text mat-icon.complex,
.pink-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.accent-text h3 {
  color: #b0bec5 !important;
}
.pink-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon,
.pink-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon,
.pink-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon,
.pink-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon,
.pink-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header mat-icon {
  color: #f48fb1;
}
.pink-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3,
.pink-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3,
.pink-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3,
.pink-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3,
.pink-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .title-description.primary-text .header h3 {
  color: #f48fb1;
}
.pink-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex,
.pink-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex,
.pink-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex,
.pink-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex,
.pink-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content:hover .floating-action mat-icon.complex {
  color: #b0bec5 !important;
  text-shadow: -2px 0 0px black, 0 -2px 0px black, 2px 0 0px black, 0 2px 0px black, -2px -2px 0px black, 2px 2px 0px black, 2px -2px 0px black, -2px 2px 0px #424242;
}
.pink-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex,
.pink-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex,
.pink-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex,
.pink-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex,
.pink-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-list-icon.icon-complex mat-icon.complex {
  color: rgba(255, 255, 255, 0.5) !important;
}
.pink-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b,
.pink-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b,
.pink-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b,
.pink-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b,
.pink-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .mat-list-item-content .mat-line b {
  color: white;
}
.pink-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex,
.pink-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex,
.pink-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex,
.pink-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex,
.pink-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex {
  color: rgba(255, 255, 255, 0.5) !important;
}
.pink-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex,
.pink-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex,
.pink-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex,
.pink-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex,
.pink-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection mat-list .floating-action .icon-complex mat-icon.complex {
  color: rgba(255, 255, 255, 0.5) !important;
}
.pink-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover,
.pink-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover,
.pink-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover,
.pink-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover,
.pink-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button:hover {
  background: rgba(255, 255, 255, 0.04);
}
.pink-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b,
.pink-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b,
.pink-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b,
.pink-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b,
.pink-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .mat-radio-group .mat-radio-button .mat-radio-label .mat-radio-label-content b {
  color: white;
}
.pink-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.pink-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.pink-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.pink-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.pink-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble {
  background: #f48fb1;
}
.pink-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble,
.pink-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble,
.pink-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble,
.pink-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble,
.pink-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-invalid + .indicator > .bubble {
  background: #e57373;
}
.pink-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble,
.pink-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble,
.pink-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble,
.pink-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble,
.pink-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .mat-form-field-disabled + .indicator > .bubble {
  color: rgba(255, 255, 255, 0.5);
  background: rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble,
.pink-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble,
.pink-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble,
.pink-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble,
.pink-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .bubble {
  background: rgba(255, 255, 255, 0.5);
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide,
.pink-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide,
.pink-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide,
.pink-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide,
.pink-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input .indicator .guide {
  border-color: rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble,
.pink-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble,
.pink-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble,
.pink-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble,
.pink-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .bubble {
  color: rgba(255, 255, 255, 0.7);
}
.pink-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.pink-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.pink-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.pink-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble,
.pink-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-focused:not(.mat-form-field-invalid) + .indicator > .bubble {
  color: #f48fb1;
}
.pink-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble,
.pink-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble,
.pink-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble,
.pink-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble,
.pink-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection .form-steped .form-steped-body .asisted-input.icon-only .mat-form-field-invalid + .indicator > .bubble {
  color: #e57373;
}
.pink-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar,
.pink-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar,
.pink-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar,
.pink-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar,
.pink-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar {
  background: #607D8B;
}
.pink-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row,
.pink-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row,
.pink-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row,
.pink-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row,
.pink-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .mat-toolbar mat-toolbar-row {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper,
.pink-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper,
.pink-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper,
.pink-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper,
.pink-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .document-selection app-documentation-type-set-edit .upload-content app-file-document-detail .view-container .view-content app-pdf-wrapper {
  background: #607D8B;
}
.pink-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop,
.pink-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop,
.pink-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop,
.pink-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop,
.pink-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop {
  border-left: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body,
.pink-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body,
.pink-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body,
.pink-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body,
.pink-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body {
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3,
.pink-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3,
.pink-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3,
.pink-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3,
.pink-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h3 {
  color: #b0bec5;
}
.pink-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5,
.pink-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5,
.pink-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5,
.pink-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5,
.pink-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .add-menu-form-body .title-description .header h5 {
  color: rgba(255, 255, 255, 0.7);
}
.pink-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5,
.pink-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5,
.pink-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5,
.pink-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5,
.pink-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .docs-drop-preview .scroller h5 {
  color: rgba(255, 255, 255, 0.7);
}
.pink-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator,
.pink-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator,
.pink-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator,
.pink-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator,
.pink-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  background: #303030;
}
.pink-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip,
.pink-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip,
.pink-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip,
.pink-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip,
.pink-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip {
  background: #424242 !important;
}
.pink-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon,
.pink-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon,
.pink-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon,
.pink-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon,
.pink-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header mat-icon {
  color: #b0bec5 !important;
}
.pink-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3,
.pink-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3,
.pink-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3,
.pink-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3,
.pink-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h3 {
  color: #b0bec5 !important;
}
.pink-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5,
.pink-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5,
.pink-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5,
.pink-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5,
.pink-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-accent .header h5 {
  color: rgba(255, 255, 255, 0.7) !important;
}
.pink-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon,
.pink-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon,
.pink-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon,
.pink-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon,
.pink-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header mat-icon {
  color: #f48fb1 !important;
}
.pink-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3,
.pink-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3,
.pink-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3,
.pink-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3,
.pink-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h3 {
  color: #f48fb1 !important;
}
.pink-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5,
.pink-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5,
.pink-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5,
.pink-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5,
.pink-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-primary .header h5 {
  color: #f48fb1 !important;
}
.pink-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled,
.pink-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled,
.pink-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled,
.pink-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled,
.pink-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled {
  background: #303030 !important;
  color: rgba(255, 255, 255, 0.5);
}
.pink-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove,
.pink-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove,
.pink-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove,
.pink-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove,
.pink-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip.mat-disabled mat-icon.mat-chip-remove {
  color: rgba(255, 255, 255, 0.7);
}
.pink-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove,
.pink-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove,
.pink-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove,
.pink-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove,
.pink-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .template-selection-indicator mat-chip-list .mat-chip-list-wrapper mat-chip mat-icon.mat-chip-remove {
  color: rgba(255, 255, 255, 0.7) !important;
}
.pink-blueGrey.dark app-multiple-documentation-add-dialog .dialog-content .document-template-container .add-docs-drop .preview-action,
.pink-blueGrey.dark app-documentation-type-set-configuration-dialog .dialog-content .document-template-container .add-docs-drop .preview-action,
.pink-blueGrey.dark app-add-candidate-dialog .dialog-content .document-template-container .add-docs-drop .preview-action,
.pink-blueGrey.dark app-share-person-dialog .dialog-content .document-template-container .add-docs-drop .preview-action,
.pink-blueGrey.dark app-share-person-response-dialog .dialog-content .document-template-container .add-docs-drop .preview-action {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark .tyc .sticky {
  background: #424242;
}
.pink-blueGrey.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper:nth-child(odd) datatable-body-row {
  background: #303030;
}
.pink-blueGrey.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active * {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-blueGrey.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active:hover .datatable-body-cell-label {
  background: #f48fb1;
}
.pink-blueGrey.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active:hover .cs-floating-action {
  background: #f48fb1 !important;
}
.pink-blueGrey.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active.row-cancel * {
  color: rgba(255, 255, 255, 0.7) !important;
}
.pink-blueGrey.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active.row-cancel .datatable-row-group {
  background: #607D8B !important;
}
.pink-blueGrey.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.active.row-cancel:hover .datatable-body-cell-label {
  background: #607D8B;
}
.pink-blueGrey.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row.row-cancel * {
  color: #b0bec5 !important;
  transition-property: color;
  transition-duration: 0.3s;
  transition-timing-function: linear;
}
.pink-blueGrey.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row:hover .datatable-body-cell-label button {
  background: black;
}
.pink-blueGrey.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row datatable-body-cell.candidate-status_bar mat-progress-bar .mat-progress-bar-buffer {
  background-color: black;
}
.pink-blueGrey.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row datatable-body-cell.candidate-status_bar .status_bar-count {
  color: rgba(255, 255, 255, 0.7);
}
.pink-blueGrey.dark .cs-grid-test ngx-datatable datatable-body datatable-selection datatable-scroller datatable-row-wrapper datatable-body-row datatable-body-cell.candidate-status_bar .inactive {
  color: rgba(255, 255, 255, 0.7);
}
.pink-blueGrey.dark .cs-grid-test .info {
  background: #424242;
}
.pink-blueGrey.dark .cs-grid-test .selected-column {
  background: #424242;
  -webkit-box-shadow: 0 1px 3px 0 rgba(255, 255, 255, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0 1px 3px 0 rgba(255, 255, 255, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 3px 0 rgba(255, 255, 255, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
}
.pink-blueGrey.dark .cs-grid-test .selection-cell .datatable-body-cell.active {
  background: #f48fb1 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-blueGrey.dark .cs-grid-test nav {
  background: #424242;
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 13px 19px 2px rgba(0, 0, 0, 0.14), 0 5px 24px 4px rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark .cs-grid-test .ngx-datatable.material {
  background: #424242;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark .cs-grid-test .ngx-datatable.material.striped .datatable-row-odd {
  background: #303030;
}
.pink-blueGrey.dark .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active, .pink-blueGrey.dark .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active .datatable-row-group, .pink-blueGrey.dark .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active, .pink-blueGrey.dark .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active .datatable-row-group, .pink-blueGrey.dark .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active, .pink-blueGrey.dark .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active .datatable-row-group {
  background: #f48fb1;
  color: #424242;
}
.pink-blueGrey.dark .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active:hover, .pink-blueGrey.dark .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active:hover .datatable-row-group, .pink-blueGrey.dark .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active:hover, .pink-blueGrey.dark .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active:hover .datatable-row-group, .pink-blueGrey.dark .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active:hover, .pink-blueGrey.dark .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active:hover .datatable-row-group {
  background: #f48fb1;
  color: #424242;
}
.pink-blueGrey.dark .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active:focus, .pink-blueGrey.dark .cs-grid-test .ngx-datatable.material.single-selection .datatable-body-row.active:focus .datatable-row-group, .pink-blueGrey.dark .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active:focus, .pink-blueGrey.dark .cs-grid-test .ngx-datatable.material.multi-selection .datatable-body-row.active:focus .datatable-row-group, .pink-blueGrey.dark .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active:focus, .pink-blueGrey.dark .cs-grid-test .ngx-datatable.material.multi-click-selection .datatable-body-row.active:focus .datatable-row-group {
  background: #f48fb1;
  color: #424242;
}
.pink-blueGrey.dark .cs-grid-test .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover, .pink-blueGrey.dark .cs-grid-test .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: rgba(255, 255, 255, 0.04);
}
.pink-blueGrey.dark .cs-grid-test .ngx-datatable.material:not(.cell-selection) .datatable-body-row:focus, .pink-blueGrey.dark .cs-grid-test .ngx-datatable.material:not(.cell-selection) .datatable-body-row:focus .datatable-row-group {
  background: rgba(255, 255, 255, 0.04);
}
.pink-blueGrey.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell:hover, .pink-blueGrey.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell:hover .datatable-row-group {
  background: rgba(255, 255, 255, 0.04);
}
.pink-blueGrey.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell:focus, .pink-blueGrey.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell:focus .datatable-row-group {
  background: rgba(255, 255, 255, 0.04);
}
.pink-blueGrey.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active, .pink-blueGrey.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active .datatable-row-group {
  background: #f48fb1;
  color: #424242;
}
.pink-blueGrey.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active:hover, .pink-blueGrey.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active:hover .datatable-row-group {
  background: #f48fb1;
  color: #424242;
}
.pink-blueGrey.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active:focus, .pink-blueGrey.dark .cs-grid-test .ngx-datatable.material.cell-selection .datatable-body-cell.active:focus .datatable-row-group {
  background: #f48fb1;
  color: #424242;
}
.pink-blueGrey.dark .cs-grid-test .ngx-datatable.material .datatable-header .datatable-row-left,
.pink-blueGrey.dark .cs-grid-test .ngx-datatable.material .datatable-body .datatable-row-left {
  background-color: #424242;
}
.pink-blueGrey.dark .cs-grid-test .ngx-datatable.material .datatable-header .datatable-row-right,
.pink-blueGrey.dark .cs-grid-test .ngx-datatable.material .datatable-body .datatable-row-right {
  background-color: #424242;
}
.pink-blueGrey.dark .cs-grid-test .ngx-datatable.material .datatable-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.pink-blueGrey.dark .cs-grid-test .ngx-datatable.material .datatable-header .datatable-header-cell {
  background-color: #424242;
  color: rgba(255, 255, 255, 0.7);
}
.pink-blueGrey.dark .cs-grid-test .ngx-datatable.material .datatable-header .resize-handle {
  border-right: solid 1px rgba(255, 255, 255, 0.04);
}
.pink-blueGrey.dark .cs-grid-test .ngx-datatable.material .datatable-body .datatable-row-detail {
  background: black;
}
.pink-blueGrey.dark .cs-grid-test .ngx-datatable.material .datatable-body .datatable-group-header {
  background: black;
  border-bottom: solid 1px rgba(255, 255, 255, 0.12);
  border-top: solid 1px rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark .cs-grid-test .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
  color: white;
}
.pink-blueGrey.dark .cs-grid-test .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-group-cell {
  color: white;
}
.pink-blueGrey.dark .cs-grid-test .ngx-datatable.material .datatable-body .progress-linear .container {
  background-color: #424242;
}
.pink-blueGrey.dark .cs-grid-test .ngx-datatable.material .datatable-body .progress-linear .container .bar {
  background-color: #f48fb1;
}
.pink-blueGrey.dark .cs-grid-test .ngx-datatable.material .datatable-footer {
  color: rgba(255, 255, 255, 0.7);
}
.pink-blueGrey.dark .cs-grid-test .ngx-datatable.material .datatable-footer .datatable-pager li.disabled a {
  color: rgba(255, 255, 255, 0.5) !important;
  background-color: rgba(0, 0, 0, 0) !important;
}
.pink-blueGrey.dark .cs-grid-test .ngx-datatable.material .datatable-footer .datatable-pager li.active a {
  background-color: #f48fb1;
}
.pink-blueGrey.dark .cs-grid-test .ngx-datatable.material .datatable-footer .datatable-pager a {
  color: rgba(255, 255, 255, 0.7);
}
.pink-blueGrey.dark .cs-grid-test .ngx-datatable.material .datatable-footer .datatable-pager a:hover {
  color: rgba(255, 255, 255, 0.04);
  background-color: rgba(255, 255, 255, 0.04);
}
.pink-blueGrey.dark .cs-grid-test .ngx-datatable.material .datatable-summary-row .datatable-body-row {
  background-color: #303030;
}
.pink-blueGrey.dark .cs-grid-test .ngx-datatable.material .datatable-summary-row .datatable-body-row:hover {
  background-color: #303030;
}
.pink-blueGrey.dark .cs-grid-test .datatable-checkbox input[type=checkbox]:after {
  background: #424242;
}
.pink-blueGrey.dark .view-container .view-toolbar {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal.new.image-opened .help-content img {
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card {
  background: #424242;
}
.pink-blueGrey.dark app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container .aditional-info {
  color: rgba(255, 255, 255, 0.7);
}
.pink-blueGrey.dark app-help-stepper mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container .mat-card-footer {
  background: #424242;
}
.pink-blueGrey.dark app-help-index mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card {
  background: #424242;
}
.pink-blueGrey.dark app-help-index mat-horizontal-stepper.help-stepper.mat-stepper-horizontal .mat-horizontal-content-container mat-card.mat-card.help-card .mat-card-footer {
  background: #424242;
}
.pink-blueGrey.dark .cropper .employee-title .title-text {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-blueGrey.dark .cropper app-cs-upload-cropper-control .preview h5 {
  color: rgba(255, 255, 255, 0.7);
}
.pink-blueGrey.dark app-file-document-upload .preview-container {
  background: #607D8B !important;
}
.pink-blueGrey.dark app-file-document-upload .preview-container h4 {
  color: black;
}
.pink-blueGrey.dark .preview-container {
  background: #607D8B !important;
}
.pink-blueGrey.dark .preview-container h4 {
  color: black;
}
.pink-blueGrey.dark .clean-autocomple mat-list {
  background: #424242;
}
.pink-blueGrey.dark .clean-autocomple mat-list .mat-list-item:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-blueGrey.dark .clean-autocomple mat-list .mat-list-item .mat-list-item-content .mat-list-text {
  color: #f48fb1;
}
.pink-blueGrey.dark app-leave .inbox-toolbar {
  background: #303030;
}
.pink-blueGrey.dark app-leave .mat-tab-body-wrapper {
  position: relative;
  overflow: hidden;
  display: flex;
  transition: height 500ms cubic-bezier(0.35, 0, 0.25, 1);
  height: 95% !important;
}
.pink-blueGrey.dark app-leave .leave_container {
  background: #303030;
}
.pink-blueGrey.dark app-leave .leave_container .leave_items .leave_items-item.ok .content .leave_item-status_icon {
  color: #4db6ac;
}
.pink-blueGrey.dark app-leave .leave_container .leave_items .leave_items-item.not-ok .content .leave_item-status_icon {
  color: #ba68c8;
}
.pink-blueGrey.dark app-leave .leave_container .leave_items .leave_items-item.pending .content .leave_item-status_icon {
  color: #ffb74d;
}
.pink-blueGrey.dark app-leave .leave_container .leave_items .leave_items-item.draft .content .leave_item-status_icon {
  color: rgba(255, 255, 255, 0.7);
}
.pink-blueGrey.dark app-leave .leave_container .leave_items .leave_items-item.cancel .content .leave_item-status_icon {
  color: #795548;
}
.pink-blueGrey.dark app-leave .leave_container .leave_items .leave_items-item .content:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-blueGrey.dark app-leave .leave_container .leave_items .leave_items-item .content.selected {
  border-left: 6px solid #b0bec5 !important;
}
.pink-blueGrey.dark app-leave .leave_container .leave_items .leave_items-item .content.selected mat-icon {
  color: #b0bec5 !important;
}
.pink-blueGrey.dark app-leave .leave_container .leave_items .leave_items-item .content.selected .item-data {
  color: white !important;
}
.pink-blueGrey.dark app-leave .leave_container .leave_items .leave_items-item .content .leave_item-icon {
  color: rgba(255, 255, 255, 0.7);
}
.pink-blueGrey.dark app-leave .leave_container .leave_items .leave_items-item .content .item-data {
  color: rgba(255, 255, 255, 0.7);
}
.pink-blueGrey.dark app-leave .leave_container .window-spinner {
  background: #303030 !important;
}
.pink-blueGrey.dark app-leave-detail .leave_detail .leave_detail-title .info h3,
.pink-blueGrey.dark app-leave-detail .leave_detail .leave_detail-title .info mat-icon {
  color: #f48fb1;
}
.pink-blueGrey.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .ok .large-icon {
  background: #e0f2f1;
  color: #4db6ac;
}
.pink-blueGrey.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .ok h2 {
  color: #4db6ac;
}
.pink-blueGrey.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .not-ok .large-icon {
  background: #f3e5f5;
  color: #ba68c8;
}
.pink-blueGrey.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .not-ok h2 {
  color: #ba68c8;
}
.pink-blueGrey.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .pending .large-icon {
  background: #fff3e0;
  color: #ffb74d;
}
.pink-blueGrey.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .pending h2 {
  color: #ffb74d;
}
.pink-blueGrey.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .draft .large-icon {
  background: #f2f2f2;
  color: #dedede;
}
.pink-blueGrey.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .draft h2 {
  color: #dedede;
}
.pink-blueGrey.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .cancelled .large-icon {
  background: #BCAAA4;
  color: #795548;
}
.pink-blueGrey.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .cancelled h2 {
  color: #795548;
}
.pink-blueGrey.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .actions {
  padding: 16px;
  height: 96px;
  column-gap: 16px;
}
.pink-blueGrey.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .actions button {
  height: 100%;
  border-radius: 40px;
}
.pink-blueGrey.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .actions button.ok {
  background: #4db6ac !important;
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning .actions button.not-ok {
  color: #ba68c8 !important;
}
.pink-blueGrey.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning mat-basic-chip {
  background: black !important;
  border: 1px solid rgba(255, 255, 255, 0.12) !important;
  border-radius: 40px !important;
  height: 35px !important;
}
.pink-blueGrey.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning mat-basic-chip.signed-ok {
  border-color: #4db6ac !important;
  color: #4db6ac !important;
}
.pink-blueGrey.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning mat-basic-chip.signed-not-ok {
  border-color: #ba68c8 !important;
  color: #ba68c8 !important;
}
.pink-blueGrey.dark app-leave-detail .leave_detail .leave_detail-container app-chapa .upload-warning mat-basic-chip.draft {
  border-color: #dedede !important;
  color: #dedede !important;
}
.pink-blueGrey.dark app-leave-request-detail .load-window {
  background: #303030;
}
.pink-blueGrey.dark app-leave-request-detail form .leave_detail-container {
  background: #303030;
}
.pink-blueGrey.dark app-leave-request-detail form .leave_detail-container .toolbar .header {
  color: #f48fb1;
}
.pink-blueGrey.dark app-leave-request-detail form .leave_detail-container .toolbar .header .text h3 {
  color: #f48fb1;
}
.pink-blueGrey.dark app-leave-request-detail form .leave_detail-container .toolbar .header .text h5 {
  color: rgba(255, 255, 255, 0.7);
}
.pink-blueGrey.dark app-leave-request-detail form .leave_detail-container .leave_detail-container-content .leave_resume {
  background: #424242;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark app-leave-request-detail form .leave_detail-container .leave_detail-container-content .line h6 {
  color: rgba(255, 255, 255, 0.7);
}
.pink-blueGrey.dark app-leave-request-detail form .leave_detail-container .actions button.ok {
  background: #4db6ac !important;
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark app-leave-request-detail form .leave_detail-container .actions button.not-ok {
  color: #ba68c8 !important;
}
.pink-blueGrey.dark .new_leave_modal-steps {
  display: unset !important;
}
.pink-blueGrey.dark .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button {
  background: #303030;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button .mat-radio-label .mat-radio-label-content .side-text {
  color: rgba(255, 255, 255, 0.7);
}
.pink-blueGrey.dark .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button .mat-radio-label .mat-radio-label-content .side-text.muted {
  color: rgba(255, 255, 255, 0.5);
}
.pink-blueGrey.dark .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button .mat-radio-label .mat-radio-label-content .accent mat-icon,
.pink-blueGrey.dark .new_leave_modal-steps .mat-horizontal-content-container form .new_leave_modal-options mat-radio-button .mat-radio-label .mat-radio-label-content .accent .main-text {
  color: #b0bec5;
}
.pink-blueGrey.dark .config_leave-container .config_leave-card {
  background: #424242;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark .config_leave-container .config_leave-card .config_leave-auto_chip mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #303030 !important;
}
.pink-blueGrey.dark .config_leave-container .config_leave-card .config_leave-auto_chip mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(255, 255, 255, 0.5);
}
.pink-blueGrey.dark .config_leave-container .config_leave-card .config_leave-auto_chip mat-form-field .mat-form-field-flex {
  background: #303030;
}
.pink-blueGrey.dark *[color=success] {
  color: #009688;
}
.pink-blueGrey.dark .primary {
  color: #f48fb1 !important;
}
.pink-blueGrey.dark .accent {
  color: #b0bec5 !important;
}
.pink-blueGrey.dark app-lsd .icon-complex mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.pink-blueGrey.dark app-lsd .icon-complex mat-icon.complex {
  color: #f48fb1 !important;
}
.pink-blueGrey.dark app-lsd .work-space .main *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.pink-blueGrey.dark app-lsd .work-space .main .load-window {
  background: #424242 !important;
}
.pink-blueGrey.dark app-lsd .work-space .main.side-open {
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark app-lsd .work-space .main .container .item {
  background: #303030;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark app-lsd .work-space .main .container .item h5 {
  color: rgba(255, 255, 255, 0.5);
}
.pink-blueGrey.dark app-lsd .work-space .main .container .item h3 {
  color: white;
}
.pink-blueGrey.dark app-lsd .work-space .main .container .item:hover {
  background: rgba(255, 255, 255, 0.04);
}
.pink-blueGrey.dark app-lsd .work-space .main .container .item.selected {
  background: #f48fb1;
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-blueGrey.dark app-lsd .work-space .main .container .item.selected h3 {
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-blueGrey.dark app-lsd .work-space .main .container .item.selected.pending .status {
  background: rgba(255, 255, 255, 0.12) !important;
  color: #ffe082;
}
.pink-blueGrey.dark app-lsd .work-space .main .container .item.selected.ok .status {
  background: rgba(255, 255, 255, 0.12) !important;
  color: #a5d6a7;
}
.pink-blueGrey.dark app-lsd .work-space .main .container .item.selected.not-ok .status {
  background: rgba(255, 255, 255, 0.12) !important;
  color: #e57373;
}
.pink-blueGrey.dark app-lsd .work-space .main .main-action {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark app-lsd .work-space .side {
  background: #303030;
}
.pink-blueGrey.dark app-lsd .work-space .side .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #303030, 0 -2px 0px #303030, 2px 0 0px #303030, 0 2px 0px #303030, -2px -2px 0px #303030, 2px 2px 0px #303030, 2px -2px 0px #303030, -2px 2px 0px #303030;
}
.pink-blueGrey.dark app-lsd .work-space .side *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}
.pink-blueGrey.dark app-lsd .work-space .side *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
.pink-blueGrey.dark app-lsd .work-space .side .edition-line {
  background: black;
}
.pink-blueGrey.dark app-lsd .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #303030 !important;
}
.pink-blueGrey.dark app-lsd .work-space .side mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(255, 255, 255, 0.5);
}
.pink-blueGrey.dark app-lsd .work-space .side mat-form-field .mat-form-field-flex {
  background: #303030;
}
.pink-blueGrey.dark app-lsd .work-space .side mat-checkbox:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-blueGrey.dark app-lsd .work-space .side .hint.all {
  color: #b0bec5;
}
.pink-blueGrey.dark app-lsd .work-space .side .toolbar .header {
  color: #f48fb1;
}
.pink-blueGrey.dark app-lsd .work-space .side .toolbar .header h3 {
  color: #f48fb1;
}
.pink-blueGrey.dark app-lsd .work-space .side .container .doc-resume {
  background: #f48fb1;
}
.pink-blueGrey.dark app-lsd .work-space .side .container .doc-resume .line {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark app-lsd .work-space .side .container .doc-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.1);
}
.pink-blueGrey.dark app-lsd .work-space .side .container .doc-resume .line.editable button {
  background: #f48fb1;
}
.pink-blueGrey.dark app-lsd .work-space .side .container .section-title {
  color: #f48fb1;
}
.pink-blueGrey.dark app-lsd .work-space .side .container .section-resume {
  background: #424242;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark app-lsd .work-space .side .container .section-resume .line.editable:hover {
  background: rgba(255, 255, 255, 0.04) !important;
}
.pink-blueGrey.dark app-lsd .work-space .side .container .section-resume .line.editable:hover .icon-complex mat-icon.complex {
  text-shadow: -2px 0 0px #424242, 0 -2px 0px #424242, 2px 0 0px #424242, 0 2px 0px #424242, -2px -2px 0px #424242, 2px 2px 0px #424242, 2px -2px 0px #424242, -2px 2px 0px #424242 !important;
}
.pink-blueGrey.dark app-lsd .work-space .side .container .section-resume .line.editable button {
  background: #303030;
}
.pink-blueGrey.dark app-lsd .work-space .side .container .section-resume .line .header .icon-complex mat-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.pink-blueGrey.dark app-lsd .work-space .side .container .section-resume .line .header .icon-complex mat-icon.complex {
  color: #f48fb1 !important;
}
.pink-blueGrey.dark app-lsd .work-space .side .container .section-resume .line .header mat-icon:not(.complex) {
  color: rgba(255, 255, 255, 0.7);
}
.pink-blueGrey.dark app-lsd .work-space .side .container .section-resume .line .header .info h4 {
  color: #f48fb1;
}
.pink-blueGrey.dark app-lsd .work-space .side .container .section-resume .line ul {
  color: #f48fb1;
}
.pink-blueGrey.dark app-lsd .work-space .side .container .section-resume .line.head {
  background: black;
}
.pink-blueGrey.dark app-lsd .work-space .side .container .section-resume .line.head mat-icon {
  color: #b0bec5;
}
.pink-blueGrey.dark app-lsd .work-space .side .container .section-resume .line.head h4 {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark app-lsd .work-space .side.edition .toolbar .header {
  color: rgba(0, 0, 0, 0.87);
}
.pink-blueGrey.dark app-lsd .work-space .side.edition .toolbar .header mat-icon {
  color: #b0bec5;
}
.pink-blueGrey.dark app-lsd .work-space .side.edition .toolbar .header h3 {
  color: #b0bec5;
}
.pink-blueGrey.dark .dialog-content {
  max-height: 90vh;
}
.pink-blueGrey.dark .dialog-content mat-toolbar {
  background: #424242;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark .dialog-content .dialog-container .document-template-container .document-selection mat-form-field.mat-form-field-disabled .mat-form-field-flex {
  background: #303030 !important;
}
.pink-blueGrey.dark .dialog-content .dialog-container .document-template-container .document-selection mat-form-field.mat-form-field-disabled .mat-form-field-flex .mat-form-field-prefix {
  color: rgba(255, 255, 255, 0.5);
}
.pink-blueGrey.dark .dialog-content .dialog-container .document-template-container .document-selection mat-form-field .mat-form-field-flex {
  background: #303030;
}
.pink-blueGrey.dark .dialog-content .dialog-container .document-template-container .document-selection app-upload-form .file-upload-list .mat-subheader::before {
  color: #f48fb1;
}
.pink-blueGrey.dark .dialog-content .dialog-container .view-container {
  border-left: 1px solid rgba(255, 255, 255, 0.12);
}
.pink-blueGrey.dark .dialog-content .form-actions {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}