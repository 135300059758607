.config_leave-container {
  .config_leave-container-title {}

  .config_leave-card {
    padding: 16px;
    border-radius: 8px;

    .config_leave-card_title {}

    .config_leave-card_subtitle {}

    .config_leave-card_item {}

    .config_leave-card_item-edit {}

    .config_leave-auto_chip {
      mat-form-field {
        width: 100%;
        
        &.mat-form-field-disabled {
          * {
            cursor: not-allowed;
          }
          
          .mat-form-field-underline {
            height: 2px !important;
          }
        }
        
        .mat-form-field-flex {
          border-radius: 4px 4px 0 0 !important;
          padding: 8px 8px 0 !important;
          min-height: 56px !important;
          align-items: unset !important;
          
          .mat-form-field-prefix {
            padding: 0 !important;
            display: flex;
            align-items: center;
          }
        }
      }
    }

    mat-divider {}
  }
}


@mixin leaveConfigEmploye($bg-card, $bg-background, $primary-text, $primary, $text-main, $row-even, $divider, $text-side, $leave-ok, $leave-not-ok,$leave-draft, $text-sub, $row-odd) {
  .config_leave-container {
    .config_leave-container-title {}

    .config_leave-card {
      background: $bg-card;
      border: 1px solid $divider;

      .config_leave-card_title {}

      .config_leave-card_subtitle {}

      .config_leave-card_item {}

      .config_leave-card_item-edit {}

      mat-divider {}

      .config_leave-auto_chip{
        mat-form-field {
          &.mat-form-field-disabled {
            .mat-form-field-flex {
              background: $bg-background !important;

              .mat-form-field-prefix {
                color: $text-sub;
              }
            }
          }

          .mat-form-field-flex {
            background: $row-odd;
          }
        }
      }
    }
  }
}
