@import '../../../../assets/styles/img-management';


app-actor-find {
  .main-overlay {
    position: absolute;

    bottom: 0;
    height: 100%;
    opacity: 0;
    z-index: 2;

    &.enabled {
      width: 100% !important;
      opacity: 1
    }
  }


  .icon-complex {
    position: relative;
    height: 40px;
    width: 40px;
    padding: 0 !important;

    mat-icon {
      &.complex {
        position: absolute;
        bottom: 0;
        right: 0;
        font-size: 16px !important;
        height: 20px !important;
        width: 20px !important;
        padding: 0 !important;
      }
    }
  }

  .navigation {
    position: relative;

    &.detail-open {
      overflow: hidden;
      width: 0 !important;
      flex: 0 !important;
    }

    .load-window {
      left: 0;
      top: 112px;
      height: calc(100% - 112px) !important;
    }

    h3 {
      font-weight: 500;
    }

    h5 {
      font-weight: 300;
    }

    .toolbar {
      height: 80px;
      margin: 16px;
      border-radius: 8px;
      padding: 0 16px;

      h3,
      h5 {
        margin: 0
      }
    }

    .container {
      padding: 0 8px 16px 16px;
      overflow-y: scroll;
      max-height: calc(100% - 112px);
      box-sizing: border-box;

      .helper {
        padding: 0 16px;
        height: 56px;
        margin-bottom: 16px;
      }

      .item {
        padding: 16px;
        border-radius: 8px;
        cursor: pointer;

        h3 {
          margin: 0;
        }

        &+.item {
          margin-top: 16px;
        }

        &.active {
          cursor: default;
        }
      }
    }
  }

  .work-space {

    h5,
    h6 {
      font-weight: 500;
    }

    .main {
      position: relative;

      .load-window {
        left: 0;
        top: 0;
        height: 100%;
      }

      &.side-open {
        .container {
          .content-scroll {
            .item {
              width: calc((100% / 3) - 16px);
            }
          }
        }
      }

      .container {
        min-height: calc(100vh - 32px - 192px - 97px);
        flex-grow: 1;
        flex-shrink: 1;

        .content-scroll {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          overflow-y: scroll;
          max-height: calc(100% - 56px);
          box-sizing: border-box;
          padding: 8px 0 8px 8px;
        }

        .item {
          margin: 8px;
          padding: 16px;
          border-radius: 8px;
          width: calc((100% / 3) - 16px);
          cursor: pointer;
          
          .subtitle {
            text-transform: lowercase;
          }

          &.full-page {
            width: calc((100% / 4) - 16px);
          }

          &.disabled {
            opacity: 0.7;

            &.selected {
              opacity: 1;
              cursor: default;
            }
          }

          mat-icon {
            &.complex {
              bottom: 0;
              right: 0;
            }
          }



          h5 {
            margin: 0;
          }

          h3 {
            margin: 0;
          }


          &.selected {
            cursor: default;
          }
        }
      }

    }

    .side {
      height: 100%;

      .edition-line {
        border-radius: 8px;
        padding: 16px;
        margin: 16px 0;
        position: relative;
        overflow: hidden;

        mat-progress-bar {
          bottom: 0;
          left: 0;
        }
      }
      .adj-container{
        padding: 0 24px !important; 
      } 
      mat-form-field {
        width: 100%;

        &.mat-form-field-disabled {
          * {
            cursor: not-allowed;
          }

          .mat-form-field-underline {
            height: 2px !important;
          }
        }

        .mat-form-field-flex {
          border-radius: 4px 4px 0 0 !important;
          padding: 8px 8px 0 !important;
          min-height: 56px !important;
          align-items: unset !important;

          .mat-form-field-prefix {
            padding: 0 !important;
            display: flex;
            align-items: center;

          }
        }
      }

      mat-checkbox {
        align-items: center;
        height: 56px;
        display: flex;
        margin-bottom: 16px;
        border-radius: 4px;
        padding: 0 8px;

        .mat-checkbox-layout {
          width: 100%;
          justify-content: flex-start;
          height: 56px;
          align-items: center;

          .mat-checkbox-inner-container {
            margin: 12px;
          }

          .mat-checkbox-label {
            width: 100%;
            padding-left: 16px;
          }
        }
      }

      .hint {
        margin: 0 0 16px;
        font-weight: 700;
        width: 100%;
        text-align: center;
      }

      .toolbar {
        height: 112px;
        padding: 16px 32px;

        .header {
          width: 100%;

          h3 {
            margin: 0;
          }

          h5 {
            margin: 0;
          }
        }
      }

      .container {
        padding: 0 0 16px 8px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        overflow-y: scroll;
        max-height: calc(100% - 112px);
        box-sizing: border-box;

        .role-resume {
          width: calc(100% - 16px);
          padding: 16px;
          border-radius: 8px;
          margin: 0 8px;
          position: relative;


          .line {
            padding: 8px 16px;
            position: relative;
            border-radius: 4px;

            &.editable {
              &:hover {
                button {
                  opacity: 1
                }
              }

              button {
                opacity: 0;
                position: absolute;
                right: 16px;
                top: 4px;
              }
            }

            .header {
              width: 100%;

              .info {
                max-width: calc(100% - 40px - 16px - 16px);

                h4,
                h6 {
                  margin: 0;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
            }
          }
        }

        .section-title {
          width: 100%;
          height: 56px;
          margin: 8px;
          padding: 16px;

          h3 {
            margin: 0;
          }

          h5 {
            margin: 0;
            font-size: 18px;
            font-weight: 700;
          }
        }

        .section-resume {
          width: 100%;
          padding: 16px;
          border-radius: 8px;
          margin: 0 8px;
          max-width: calc(100% - 50px);

          .line {
            padding: 8px 16px;
            border-radius: 4px;
            position: relative;


            &.editable {
              &:hover {
                button {
                  opacity: 1
                }
              }

              button {
                opacity: 0;
                position: absolute;
                right: 16px;
                top: 4px;
              }
            }

            .header {
              width: 100%;

              .icon-complex {

                mat-icon {
                  width: 40px;
                  height: 40px;
                }
              }

              .info {
                max-width: calc(100% - 40px - 16px - 16px);

                h4 {
                  margin: 0;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }

                h6 {
                  font-weight: 700;
                  margin: 0;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
            }

            ul {
              margin-top: 0;
              margin-bottom: 0;
              padding-inline-start: 16px;
              font-weight: 400;

              &+ul {
                margin-top: 16px;
              }
            }

            &.head {
              padding: 8px 16px;
            }
          }
        }

        app-certificates {
          width: 100%;

          .employee_detail-sign {
            .employee-title {
              height: 56px;
              margin: 8px;
              padding: 16px;
              box-sizing: border-box;

              .title-text {
                display: flex;
                height: 100%;
                align-items: center;

                mat-icon {
                  height: 40px;
                  margin-right: 16px;
                  font-size: 20px !important;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }

                .title_text-main {
                  font-size: 18px;
                  font-weight: 700;
                }
              }
            }

            .employee_detail-side-body {
              padding: 16px;
              border-radius: 8px;
              box-sizing: border-box;
              margin: 0 8px;

              .data {
                .data-line {
                  display: flex;
                  flex-wrap: nowrap;
                  align-items: center;

                  .data_line-text {
                    padding: 8px 16px;
                    border-radius: 4px;
                    position: relative;
                    flex-direction: row;
                    box-sizing: border-box;
                    display: flex;
                    place-content: center flex-start;
                    align-items: center;

                    mat-icon {
                      margin-right: 16px;
                    }

                    .line_text-container {

                      .line_text-side {
                        font-weight: 700;
                        margin: 0;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        font-size: 0.67em;

                        u {
                          cursor: help;
                        }
                      }

                      .line_text-main {
                        margin: 0;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        font-weight: bold;
                      }
                    }
                  }

                  mat-basic-chip {
                    padding: 4px 12px 4px 8px;

                    mat-icon {
                      width: 20px !important;
                      min-width: 20px !important;
                    }
                  }
                }
              }
            }
          }
        }
      }

      &.edition {
        z-index: 2;

        .toolbar {
          .header {
            width: 100%;
          }
        }

        .container {
          padding: 0;
          overflow-y: unset !important;

          app-role-user-add {
            display: block !important;
            width: 100%;
            box-sizing: border-box !important;

            mat-horizontal-stepper {
              width: 100% !important;
              min-width: unset;
              background-color: unset !important;

              .mat-horizontal-content-container {
                padding: 0;

                form {
                  max-height: calc(100vh - 32px - 112px);
                  overflow-y: scroll;
                  padding: 0 8px 0 16px;
                  width: 100%;
                  position: relative;
                  box-sizing: border-box;
                }

                .form-body {
                  padding: 0 16px !important;
                  border-radius: 8px !important;
                  margin: 0 auto;
                  box-sizing: border-box;

                  &+.form-body {
                    margin-top: 16px;
                  }

                  .line {
                    padding: 16px 8px;

                    .header {
                      width: 100%;

                      h4 {
                        margin: 0;
                      }

                      h6 {
                        margin: 0;
                      }
                    }
                  }

                  mat-radio-group {
                    padding-bottom: 24px;

                    mat-radio-button {
                      height: 40px;
                      display: flex !important;
                      align-items: center;

                      .mat-radio-container {
                        margin-left: 18px;
                      }

                      .mat-radio-label-content {
                        padding-left: 26px;
                      }
                    }
                  }
                }

                .form-actions {
                  border-top: unset !important;
                  padding: 16px 0 !important;
                  display: flex;
                  flex-direction: row-reverse;
                  position: sticky;
                  bottom: 0;

                  button+button {
                    margin-right: 12px;
                  }
                }

                .resume {
                  max-height: calc(100vh - 32px - 112px);
                  overflow-y: scroll;
                  padding: 0 8px 0 16px;
                  width: 100%;
                  position: relative;

                  .form-body {
                    padding: 0 !important;
                    overflow: hidden;

                    .line {
                      padding: 16px 40px;

                      .header {
                        h6 {
                          font-weight: 700;
                        }

                        &+.header {
                          margin-top: 16px;
                        }
                      }

                      ul {
                        margin-top: 0;
                        margin-bottom: 0;
                        padding-inline-start: 16px;
                        font-weight: 400;

                        &+ul {
                          margin-top: 16px;
                        }
                      }

                      &.head {
                        padding: 8px 16px;
                      }
                    }
                  }

                  .section-title {
                    margin: 8px 0 !important;

                    h5 {
                      font-weight: 500 !important;
                    }
                  }

                  h6.hint {
                    margin: 16px 0 !important;
                    padding: 16px;
                    width: 100%;
                    box-sizing: border-box;
                    text-align: start !important;
                    font-size: 14px;
                    border-radius: 8px;
                  }
                }
              }
            }
          }
        }
      }
    }

    app-role-user-detail.disabled {
      .side {
        .container {
          .role-resume {
            .line {
              &.editable {
                pointer-events: none;
              }
            }
          }

          .section-resume {
            .line {
              &.editable {
                pointer-events: none;
              }

              .mat-icon-button {
                opacity: 0;
                pointer-events: none;
              }
            }
          }

          app-certificates {
            width: 100%;

            .employee_detail-sign {
              .employee_detail-side-body {
                .data {
                  .data-line {
                    .data_line-text {
                      mat-icon {
                        margin-right: 16px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@mixin actorFind($bg-card, $bg-card-side, $bg-background, $bg-overlay, $bg-dark-blue, $bg-ripple-light, $scroll-body, $scroll-thumb, $primary, $accent, $accent-light, $divider, $text-main-solid, $text-main, $text-side, $text-sub, $primary-text, $hover, $row-odd, $row-even) {

  app-actor-find {
    .main-overlay {
      background: $bg-overlay;
    }

    .icon-complex {
      mat-icon {
        color: $text-side !important;

        &.complex {
          color: $primary-text !important;
        }
      }
    }

    .navigation {
      background: $row-odd;
      border-right: 1px solid $divider;

      *::-webkit-scrollbar-track {
        background: $scroll-body;
      }

      *::-webkit-scrollbar-thumb {
        background: $scroll-thumb;
      }

      .load-window {
        background: $bg-card-side;
      }

      .toolbar {
        background: $primary;
        color: $text-main;

        &.reverse {
          background: $bg-card;
          color: $text-side;
          border: 1px solid $divider;
        }
      }

      .container {
        .helper {
          color: $text-side;
        }

        .item {
          background: $bg-card;
          color: $text-side;
          border: 1px solid $divider;

          &:hover {
            background: $hover !important;
          }

          &.active {
            background: $primary !important;
            color: $text-main;
            border: 1px solid $primary;
          }
        }
      }
    }

    .work-space {
      .main {
        *::-webkit-scrollbar-track {
          background: $scroll-body !important;
        }

        .load-window {
          background: $bg-card !important;
        }

        &.side-open {
          border-right: 1px solid $divider;
        }

        .container {
          .item {
            background: $row-odd;
            border: 1px solid $divider;

            .subtitle {
              color: $text-side;
            }

            &.disabled {
              background: $bg-card-side;
              color: $bg-dark-blue !important;

              h3 {
                color: $bg-dark-blue !important;
              }

              mat-icon {
                color: $bg-dark-blue !important;
              }

              &.selected {
                background: $bg-dark-blue;
                color: $text-main !important;

                h3 {
                  color: $text-main !important;

                }

                mat-icon {
                  color: $text-main !important;

                  &.complex {
                    color: $text-main !important;
                    text-shadow: -2px 0 0px $bg-dark-blue, 0 -2px 0px $bg-dark-blue, 2px 0 0px $bg-dark-blue, 0 2px 0px $bg-dark-blue, -2px -2px 0px $bg-dark-blue, 2px 2px 0px $bg-dark-blue, 2px -2px 0px $bg-dark-blue, -2px 2px 0px $bg-dark-blue !important;
                  }
                }
              }
            }

            mat-icon {
              &.complex {
                text-shadow: -2px 0 0px $bg-card-side, 0 -2px 0px $bg-card-side, 2px 0 0px $bg-card-side, 0 2px 0px $bg-card-side, -2px -2px 0px $bg-card-side, 2px 2px 0px $bg-card-side, 2px -2px 0px $bg-card-side, -2px 2px 0px $bg-card-side !important;
              }
            }

            h5 {
              color: $text-sub;
            }

            h3 {
              color: $text-main-solid;
            }

            &:hover {
              background: $hover;
            }

            &.selected {
              background: $primary;
              color: $text-main;

              mat-icon {
                color: $text-main;

                &.complex {
                  color: $text-main !important;
                  text-shadow: -2px 0 0px $primary, 0 -2px 0px $primary, 2px 0 0px $primary, 0 2px 0px $primary, -2px -2px 0px $primary, 2px 2px 0px $primary, 2px -2px 0px $primary, -2px 2px 0px $primary !important;
                }
              }

              h5,
              h3 {
                color: $text-main;
              }
            }
          }
        }

        .main-action {
          border-top: 1px solid $divider;
        }
      }

      .side {
        background: $bg-background;

        .icon-complex {
          mat-icon {
            &.complex {
              text-shadow: -2px 0 0px $bg-background, 0 -2px 0px $bg-background, 2px 0 0px $bg-background, 0 2px 0px $bg-background, -2px -2px 0px $bg-background, 2px 2px 0px $bg-background, 2px -2px 0px $bg-background, -2px 2px 0px $bg-background;
            }
          }
        }

        *::-webkit-scrollbar-track {
          background: $scroll-body !important;
        }

        *::-webkit-scrollbar-thumb {
          background: $scroll-thumb;
        }

        .edition-line {
          background: $bg-card-side;
        }

        mat-form-field {
          &.mat-form-field-disabled {
            .mat-form-field-flex {
              background: $bg-background !important;

              .mat-form-field-prefix {
                color: $text-sub;
              }
            }
          }

          .mat-form-field-flex {
            background: $row-odd;
          }
        }

        mat-checkbox {
          &:hover {
            background: $hover !important;
          }
        }

        .hint {
          &.all {
            color: $accent;
          }
        }

        .toolbar {
          .header {
            color: $primary-text;

            h3 {
              color: $primary-text;
            }
          }
        }

        .container {
          .role-resume {
            background: $primary;

            .line {
              color: $text-main;

              &.editable {
                &:hover {
                  background: $bg-ripple-light;
                }

                button {
                  background: $primary;
                }
              }
            }
          }

          .section-title {
            color: $primary-text;
          }

          .section-resume {
            background: $row-even;
            border: 1px solid $divider;

            .line {
              &.editable {
                &:hover {
                  background: $hover !important;

                  .icon-complex {
                    mat-icon {
                      &.complex {
                        text-shadow: -2px 0 0px $row-even, 0 -2px 0px $row-even, 2px 0 0px $row-even, 0 2px 0px $row-even, -2px -2px 0px $row-even, 2px 2px 0px $row-even, 2px -2px 0px $row-even, -2px 2px 0px $row-even !important;
                      }
                    }
                  }
                }

                button {
                  background: $bg-background;
                }
              }

              .header {
                .icon-complex {
                  mat-icon {
                    color: $text-side !important;

                    &.complex {
                      color: $primary-text !important;
                    }
                  }
                }

                mat-icon:not(.complex) {
                  color: $text-side;
                }


                .info {
                  h4 {
                    color: $primary-text;
                  }
                }
              }

              ul {
                color: $primary-text;

              }

              &.head {
                background: $bg-card-side;

                mat-icon {
                  color: $accent;
                }

                h4 {
                  color: $text-main;
                }
              }
            }
          }

          app-certificates {
            .employee_detail-sign {
              .employee-title {
                color: $primary-text;
              }

              .employee_detail-side-body {
                background: $row-even;

                .data {
                  .data-line {
                    .data_line-text {
                      mat-icon {
                        color: $text-side;
                      }

                      .line_text-container {
                        .line_text-main {
                          color: $primary-text;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        &.edition {
          .toolbar {
            .header {
              color: $text-main;

              mat-icon {
                color: $accent;
              }

              h3 {
                color: $accent;
              }
            }
          }

          .container {
            app-role-user-add {
              mat-horizontal-stepper {
                .mat-horizontal-content-container {
                  .form-body {
                    background: $row-even !important;
                    border: 1px solid $divider;

                    .line {
                      .header {
                        mat-icon:not(.complex) {
                          color: $primary-text;
                        }

                        h4 {
                          color: $primary-text;
                        }
                      }
                    }
                  }

                  .form-actions {
                    background: $bg-background;
                  }

                  .resume {
                    .form-body {
                      .line {
                        .header {
                          mat-icon {
                            color: $text-side !important;
                          }

                          .icon-complex {
                            mat-icon {
                              &.complex {
                                color: $accent !important;
                              }
                            }
                          }

                          h4 {
                            color: $accent;
                          }
                        }

                        ul {
                          color: $accent;
                        }

                        &.head {
                          border-bottom: 1px solid $divider;

                          mat-icon {
                            color: $accent !important;
                          }

                          h4 {
                            color: $text-main-solid;
                          }
                        }
                      }
                    }

                    .section-title {
                      color: $text-main-solid !important;
                    }

                    h6.hint {
                      background: $accent-light;
                    }
                  }
                }
              }
            }
          }
        }
      }

      app-role-user-detail.disabled {
        .side {
          .toolbar .header {
            color: $bg-dark-blue !important;
          }

          .container {
            .role-resume {
              background: $bg-dark-blue !important;
            }

            .section-title {
              color: $bg-dark-blue !important;
            }

            .section-resume {
              .line {
                ul {
                  color: $bg-dark-blue !important;
                }

                .header {
                  mat-icon {
                    color: $bg-dark-blue;
                  }

                  .icon-complex mat-icon {
                    color: $bg-dark-blue !important;

                    &.complex {
                      color: $bg-dark-blue !important;
                    }
                  }

                  .info h4 {
                    color: $bg-dark-blue !important;
                  }
                }
              }
            }

            app-certificates {
              .employee_detail-sign {
                .employee-title {
                  color: $bg-dark-blue;
                }

                .employee_detail-side-body {
                  .data {
                    .data-line {
                      .data_line-text {
                        mat-icon {
                          color: $bg-dark-blue;
                        }

                        .line_text-container {
                          .line_text-main {
                            color: $bg-dark-blue;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}