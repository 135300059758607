@import "img-management";

.new-table {
  min-height: calc(100% - 65px - 92px);
}

.document-table {
  height: calc(100% - 92px - 64px);
  overflow: auto;
}

.mat-table {
  height: 100%;
  width: 100%;
  overflow: auto;
}

.mat-header-row {
  min-height: 56px;
  width: 100%;
  z-index: 100 !important;
}

.mat-row {
  min-height: 40px;
  width: 100%;
  position: relative;
  cursor: default;
  transition: background 0.2s ease;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-drag: none;

  &.active,
  &.active:hover {
    transition: background 0.2s ease;
  }

  &:nth-child(odd) {
    transition: background 0.2s ease;
  }

  &.selected {
    transition: background 0.2s ease;

    &.active,
    &.active:hover {
      transition: background 0.2s ease;
    }
  }

  &:hover {
    transition: background 0.2s ease;
  }

  &:focus,
  &:focus:hover,
  &:focus:hover .sticky-actions {
    outline: none;
  }
}

.mat-header-cell {
  padding: 0;
  box-sizing: border-box;
}

.mat-header-cell {
  text-align: left;
}

.mat-table .sticky {
  top: 0px;
  position: sticky;
  z-index: 10;
}

.mat-table .sticky-actions {
  right: 0px;
  position: absolute;
  z-index: 99;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  padding: 0 16px;

  button.mat-icon-button {
    height: 32px !important;
    width: 32px !important;
    line-height: 32px !important;
  }
}

.mat-row:hover>.sticky-actions {
  visibility: visible;
  opacity: 1;
}

.w-12 {
  height: 100%;
  width: 100%;
  min-width: 100%;
}

.mat-cell {
  padding-top: 6px;
  padding-bottom: 6px;
}

span.center {
  text-align: center;
}

.mat-cell.w-1+.w-12,
.mat-header-cell.w-1+.w-12 {
  height: 100%;
  width: calc(100% - 72px);
  min-width: calc(100% - 72px);
}

.mat-cell.w-1+.mat-cell.w-1.icon+.w-12,
.mat-header-cell.w-1+.mat-header-cell.w-1.icon+.w-12 {
  height: 100%;
  width: calc(100% - 72px - 40px);
  min-width: calc(100% - 72px - 40px);
}

.mat-cell.w-1+.mat-cell.w-1.icon+.mat-cell.w-1.icon+.w-12,
.mat-header-cell.w-1+.mat-header-cell.w-1.icon+.mat-header-cell.w-1.icon+.w-12 {
  width: calc(100% - 72px - 40px - 40px);
  min-width: calc(100% - 72px - 40px - 40px);
}

.mat-cell.w-1+.mat-cell.w-1.icon+.mat-cell.w-1.icon.double+.w-12,
.mat-header-cell.w-1+.mat-header-cell.w-1.icon+.mat-header-cell.w-1.icon.double+.w-12 {
  width: calc(100% - 72px - 40px - 80px);
  min-width: calc(100% - 72px - 40px - 80px);
}

.mat-cell.w-1.icon.double,
.mat-header-cell.w-1.icon.double {
  width: 80px;
  min-width: 80px;
}

.mat-cell.w-1-5,
.mat-header-cell.w-1-5 {
  height: 100%;
  width: calc((100% / 12) * 1.5);
  min-width: calc((100% / 12) * 1.5);
  padding-left: 24px;
  padding-right: 12px;
  position: relative;
}

.mat-cell.w-1-5.icon,
.mat-header-cell.w-1-5.icon {
  height: 100%;
  width: calc((100% / 12) * 1.5);
  min-width: calc((100% / 12) * 1.5);
}

.w-1 {
  height: 100%;
  width: 72px;
  min-width: 72px;
}

.w-0-8 {
  height: 100%;
  width: 35px;
  min-width: 35px;
}
.w-1.icon {
  height: 100%;
  width: 40px;
  min-width: 40px;
}

.w-1.icon .mat-icon {
  padding: 8px !important;
}

.w-2 {
  height: 100%;
  width: calc((100% / 12) * 2);
  min-width: calc((100% / 12) * 2);
  padding-left: 24px;
  padding-right: 12px;
  position: relative;
}

.w-24 {
  height: 100%;
  width: calc((100% / 12) * 2.4);
  min-width: calc((100% / 12) * 2.4);
  padding-left: 24px;
  padding-right: 12px;
  position: relative;
}

.w-3 {
  height: 100%;
  width: calc((100% / 12) * 3);
  min-width: calc((100% / 12) * 3);
  padding-left: 24px;
  padding-right: 12px;
  position: relative;
}

.w-4 {
  height: 100%;
  width: calc((100% / 12) * 4);
  min-width: calc((100% / 12) * 4);
  padding-left: 24px !important;
  padding-right: 12px !important;
  position: relative;
}

.w-5 {
  height: 100%;
  width: calc((100% / 12) * 5);
  min-width: calc((100% / 12) * 5);
  padding-left: 24px !important;
  padding-right: 12px !important;
  position: relative;
}

.w-6 {
  height: 100%;
  width: calc((100% / 12) * 6);
  min-width: calc((100% / 12) * 6);
  padding-left: 24px !important;
  padding-right: 12px !important;
  position: relative;
}

.w-7 {
  height: 100%;
  width: calc((100% / 12) * 7);
  min-width: calc((100% / 12) * 7);
  padding-left: 24px !important;
  padding-right: 12px !important;
  position: relative;
}

.w-8 {
  height: 100%;
  width: calc((100% / 12) * 8);
  min-width: calc((100% / 12) * 8);
  padding-left: 24px !important;
  padding-right: 12px !important;
  position: relative;
}

.mat-table span {
  text-overflow: ellipsis;

  &:not(.mat-checkbox-inner-container) {
    overflow: hidden;
  }
}

.mat-table .sort-icon {
  position: absolute;
  left: 0;
}

.mat-table .sort-icon .mat-icon {
  font-size: 15px !important;
  min-width: unset;
}

.mat-table .sorteable {
  cursor: pointer;
}

.mat-row:focus {
  outline: none;
}

.mat-row:focus:hover {
  outline: none;
}

.mat-row:focus:hover .sticky-actions {
  outline: none;
}

app-process-state-history-dialog .status-table {
  min-width: 35vw;
  max-height: 50vw;
  overflow: auto;
}

app-process-state-history-dialog .status-table .w-12 .mat-header-cell,
app-process-state-history-dialog .status-table .w-12 .mat-cell {
  padding-left: 16px !important;
}

.status-table {
  min-width: 800px;
  height: calc(100% - 56px - 65px);
}

.status-table .mat-table {
  height: 100%;
}

.status-table * {
  font-size: 12px;
}

.status-table span {
  line-height: 22px;
}

.status-table .mat-header-row {
  min-height: 56px;
  width: 100%;
  z-index: 100 !important;
}

.status-table .mat-row {
  height: 56px;
  min-height: 56px;
  width: 100%;
  position: relative;
  cursor: default;
}

.mat-cell.w-1 {
  position: relative;
}

td.mat-cell,
td.mat-footer-cell,
th.mat-header-cell {
  padding: 0 16px !important;
}

.status-table .w-1 {
  height: 100%;
  width: 72px;
  min-width: 72px;
}

.status-table .w-2 {
  height: 100%;
  width: calc((100% / 12) * 2);
  min-width: calc((100% / 12) * 2);
  padding-left: 24px;
  padding-right: 12px;
  position: relative;
}

.status-table .w-3 {
  height: 100%;
  width: calc((100% / 12) * 3);
  min-width: calc((100% / 12) * 3);
  padding-left: 24px;
  padding-right: 12px;
  position: relative;
}

.status-table .w-4 {
  height: 100%;
  width: calc((100% / 12) * 4);
  min-width: calc((100% / 12) * 4);
  padding-left: 24px;
  padding-right: 12px;
  position: relative;
}

.status-table .mat-header-cell:not(.w-1) {
  text-align: left;
  box-sizing: border-box;
  padding: 0 16px 0 0 !important;
}

.status-table .mat-cell:not(.w-1) {
  text-align: left;
  box-sizing: border-box;
  padding: 0 16px 0 0 !important;
}

.mat-cell {
  &.w-1 {
    ngx-avatar {
      position: absolute;
      top: calc(50% - 15px);
      left: calc(50% - 15px);
      cursor: pointer;
    }

    mat-checkbox {
      position: absolute;
      left: calc(50% - 8px);
    }
  }

  &.off {
    mat-checkbox {
      visibility: hidden;
    }

    ngx-avatar {
      visibility: visible;
      opacity: 1;
      backface-visibility: hidden;
      transform: rotateY(0deg);
      transition: transform 0.2s ease;
    }
  }

  &.turn.off {
    mat-checkbox {
      visibility: visible;
      transition: visibility 0.2s ease;
      transition-delay: 0.2s;
    }

    ngx-avatar {
      visibility: hidden;
      opacity: 0;
      backface-visibility: visible;
      transform: rotateY(180deg);
      transition: all 0.2s ease;
    }
  }
}



.status-table .sticky {
  top: 0px;
  position: sticky;
  z-index: 10;
}

//documents search table

.documents-search-table {
  height: calc(100% - 92px - 64px) !important;
  min-height: calc(100% - 92px - 64px);
  overflow: auto;
}

.documents-search-table .mat-table {
  height: 100%;
  width: 100%;
}

.documents-search-table .mat-header-row {
  min-height: 56px;
  width: 100%;
  z-index: 100 !important;
}

.documents-search-table .mat-header-cell {
  text-align: left;
}

.documents-search-table .sticky {
  top: 0px;
  position: sticky;
  z-index: 10;
}

.documents-search-table .sticky-actions {
  right: 0px;
  position: absolute;
  z-index: 99;
  height: 100%;
  visibility: hidden;
  transition: all 0.2s ease;
  transition-delay: 0.05s;
  opacity: 0;
  padding: 0 16px;
}

.documents-search-table .mat-row:hover>.sticky-actions {
  visibility: visible;
  opacity: 1;
  transition: all 0.2s ease;
  transition-delay: 0.05s;
}

.documents-search-table .w-12 {
  height: 100%;
  width: 100%;
  min-width: 100%;
}

.documents-search-table .w-12.multiple-selection {
  height: 100%;
  width: calc(100% - 72px);
  min-width: calc(100% - 72px);
  padding: 0;
}

.documents-search-table .w-12.multiple-selection.icon {
  height: 100%;
  width: calc(100% - 72px - 40px);
  min-width: calc(100% - 72px - 40px);
  padding: 0;
}

.documents-search-table .w-12.multiple-selection.dynamic-table {
  height: 100%;
  width: calc(100% - (72px * 3));
  min-width: calc(100% - (72px * 3));
  padding: 0;
}

.documents-search-table .w-1 {
  height: 100%;
  width: 72px;
  min-width: 72px;
}

.documents-search-table .w-12 .w-1 {
  height: 100%;
  width: calc(100% / 12);
  min-width: calc(100% / 12);
  padding-left: 24px;
  padding-right: 12px;
  position: relative;
}

.documents-search-table .w-12 .w-24 {
  height: 100%;
  width: calc((100% / 12) * 2.4);
  min-width: calc((100% / 12) * 2.4);
  padding-left: 24px;
  padding-right: 12px;
  position: relative;
}

.documents-search-table .w-1.icon {
  height: 100%;
  width: 40px;
  min-width: 40px;
}

.documents-search-table .w-1.icon .mat-icon {
  padding: 8px !important;
}

.documents-search-table .w-2 {
  height: 100%;
  width: calc((100% / 12) * 2);
  min-width: calc((100% / 12) * 2);
  padding-left: 24px;
  padding-right: 12px;
  position: relative;
}

.documents-search-table .w-3 {
  height: 100%;
  width: calc((100% / 12) * 3);
  min-width: calc((100% / 12) * 3);
  padding-left: 24px;
  padding-right: 12px;
  position: relative;
}

.documents-search-table span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.documents-search-table .sort-icon {
  position: absolute;
  left: 0;
}

.documents-search-table .sort-icon .mat-icon {
  font-size: 15px !important;
}

.documents-search-table .sorteable {
  cursor: pointer;
}

.dynamic_fields-scroller {
  height: calc(5vh * 5);
  overflow: auto;
  overflow-y: overlay;
}

@mixin table($bg-card, $bg-glass, $primary, $divider, $text-main, $cancelled, $row-header, $row-divider, $row-odd, $row-even, $row-hover, $row-disabled, $multiple-row-odd, $multiple-row-even, $leave-pending, $leave-ok, $leave-not-ok,$leave-cancelled,$leave-draft) {
  .mat-header-row {
    border-bottom: 1px solid $row-divider;
    background: $row-header;
  }

  .mat-row {
    background: $row-even;
    -webkit-tap-highlight-color: $bg-glass;

    .mat-cell {
      &.status-cell {
        mat-icon {

          &.pending {
            color: $leave-pending !important;
          }

          &.ok {
            color: $leave-ok !important
          }

          &.not-ok {
            color: $leave-not-ok !important;
          }
          &.cancelled {
            color: $leave-cancelled !important;
          }
          &.rejected {
            color: #df4d4c !important;
          }
          &.draft {
            color: $leave-draft !important;
          }
        }
      }
    }

    &.active,
    &.active:hover {
      background: $primary !important;

      div {
        color: $text-main;
      }

      .mat-cell {
        &.status-cell {
          mat-icon {

            &.pending,
            &.ok,
            &.not-ok {
              text-shadow: -1px 1px 0 $text-main,
                1px 1px 0 $text-main,
                1px -1px 0 $text-main,
                -1px -1px 0 $text-main;
            }
          }
        }
      }

      .sticky-actions {
        button {
          background: $primary !important;

          &.ok,
          &.not-ok {
            mat-icon {
              text-shadow: -1px 1px 0 $text-main,
                1px 1px 0 $text-main,
                1px -1px 0 $text-main,
                -1px -1px 0 $text-main;
            }
          }
        }
      }
    }

    &:nth-child(odd) {
      background: $row-odd;
    }

    &.selected {
      div {
        color: $text-main;
      }

      &:nth-child(even) {
        background: $multiple-row-even !important;

        .sticky-actions {
          button {
            background: $multiple-row-even !important;
          }
        }
      }

      &:nth-child(odd) {
        background: $multiple-row-odd !important;

        .sticky-actions {
          button {
            background: $multiple-row-odd !important;
          }
        }
      }

      &.active,
      &.active:hover {
        background: $primary !important;

        .sticky-actions {
          button {
            background: $primary !important;
          }
        }
      }
    }

    &:hover {
      background: $row-hover !important;

      .sticky-actions {
        button {
          background: $row-even !important;
        }
      }
    }

    &.canceled {
      * {
        color: $cancelled;
      }
    }

    &.disabled {
      div {
        color: $row-disabled;
      }

      &.active,
      &.active:hover {
        background: $row-disabled !important;

        div {
          color: $text-main;
        }

        .sticky-actions {
          button {
            background: $row-disabled !important;
          }
        }
      }

      &.selected.active:hover .sticky-actions {
        button {
          background: $row-disabled !important;
        }
      }
    }

    .sticky-actions {

      button {

        &.ok {
          mat-icon {
            color: $leave-ok !important;
          }
        }

        &.not-ok {
          mat-icon {
            color: $leave-not-ok !important;
          }
        }
      }
    }
  }

  .status-table .mat-header-row {
    border-bottom: 1px solid $row-divider;
    background: $bg-card;
  }

  .icon-info-off,
  .certificate-off {
    color: $row-disabled;
  }

  .documents-search-table .mat-header-row {
    border-bottom: 1px solid $divider;
    background: $row-even;
  }
}
