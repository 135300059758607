@import '../../../../assets/styles/img-management';


app-file-document-detail {
  display: block;
  height: 100%;
}

.upload-warning {
  height: 100% !important;
}

.view-toolbar {
  padding-left: 8px;

  &+.view-content {
    display: block;
    height: calc(100% - 65px);
  }


  mat-toolbar-row {
    display: grid;
    grid-template-columns: minmax(0px, 1fr) auto;
    grid-template-areas: "content sidebar ";

    .view-description {
      padding-right: 16px;
      grid-area: content;
      display: flex;
      align-items: center;

      mat-icon.mat-icon {
        margin-right: 16px;
      }

      .view-description-text {
        width: calc(100% - 56px);

        h3 {
          font-size: 20px;
          line-height: 1.25;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100%;
        }

        h5 {
          font-size: 12px;
          line-height: 1.25;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100%;
        }
      }
    }

    .view-actions {
      grid-area: sidebar;

      .divider-vert {
        margin: 0 16px;
      }
    }
  }
}

.view-container {
  display: block;
  height: 100%;

  .view-toolbar {
    padding-left: 8px;

    &+.view-content {
      display: block;
      height: calc(100% - 65px);
    }


    mat-toolbar-row {
      display: grid;
      grid-template-columns: minmax(0px, 1fr) auto;
      grid-template-areas: "content sidebar ";

      .view-description {
        padding-right: 16px;
        grid-area: content;
        display: flex;
        align-items: center;

        mat-icon.mat-icon {
          margin-right: 16px;
        }

        .view-description-text {
          width: calc(100% - 56px);

          h3 {
            font-size: 20px;
            line-height: 1.25;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 100%;
          }

          h5 {
            font-size: 12px;
            line-height: 1.25;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 100%;
          }
        }
      }

      .view-actions {
        grid-area: sidebar;

        .divider-vert {
          margin: 0 16px;
        }
      }
    }
  }

  .view-content {
    display: block;
    height: 100%;

    .toggle-buttons-container {
      height: 64px;
      padding: 0 8px;
    }
  }
}


@mixin fileDocumentDetail($divider) {
  .view-container {
    .view-toolbar {
      border-bottom: 1px solid $divider;
    }
  }
}